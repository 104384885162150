<div class="website" id="website-top" tabindex="0" [attr.aria-label]="homeAccessibilityHeaderLabel">
    <section
        class="top-carousel"
        [class.isAuthenticated]="isAuthenticated"
        [class.sec-margin-h]="screenInnerWidth <= 1080"
    >
        <div class="left-container">
            <div
                class="left-content"
                [class.banner-position]="bannersConfigData?.showHomePageBanner"
                *ngIf="!isAuthenticated"
            >
                <h3 tabindex="0" class="heading">{{ 'website_home.top_title' | translate }}</h3>
                <p tabindex="0" class="desc">{{ 'website_home.top_desc' | translate }}</p>
                <div class="action-btns">
                    <div class="quick-links">
                        <div
                            class="quick-link main-focus"
                            (click)="goToStartMeeting()"
                            tabindex="0"
                            aria-label="Start a meeting"
                            role="button"
                            (keyup.enter)="goToStartMeeting()"
                        >
                            <img aria-hidden="true" alt="" src="assets/img/start_a_meeting.svg" />
                            <div aria-hidden="true">
                                {{ 'landing_or_home_page.header.start_a_meeting' | translate }}
                            </div>
                        </div>
                        <div
                            class="quick-link"
                            (click)="goToJoinMeeting()"
                            tabindex="0"
                            aria-label="Join a meeting"
                            role="button"
                            (keyup.enter)="goToJoinMeeting()"
                        >
                            <img aria-hidden="true" alt="" src="assets/img/join_meeting.svg" />
                            <div aria-hidden="true">{{ 'landing_or_home_page.header.join_meeting' | translate }}</div>
                        </div>
                        <div
                            class="quick-link"
                            (click)="goToPlanMeeting()"
                            tabindex="0"
                            aria-label="Plan a meeting"
                            role="button"
                            (keyup.enter)="goToPlanMeeting()"
                        >
                            <img aria-hidden="true" alt="" src="assets/img/plan_a_meeting.svg" />
                            <div aria-hidden="true">{{ 'landing_or_home_page.header.plan_a_meeting' | translate }}</div>
                        </div>
                    </div>
                </div>
                <app-cta-button *ngIf="!isMobile"></app-cta-button>
            </div>
            <div class="left-content auth" *ngIf="isAuthenticated">
                <h3
                    class="heading"
                    title="{{ currentUser.name + ' ' + currentUser.lname }}"
                    [class.truncate-text]="!isMobile"
                    tabindex="0"
                >
                    {{ 'aftersignin_or_introduction.hello' | translate }}
                    {{ currentUser.name + ' ' + currentUser.lname }},
                </h3>
                <p class="desc" tabindex="0">
                    {{ 'aftersignin_or_introduction.what_would' | translate }}
                </p>
                <div class="action-btns">
                    <div class="quick-links p-bot-20">
                        <div
                            class="quick-link main-focus"
                            (click)="goToMeetingHost()"
                            tabindex="0"
                            role="button"
                            aria-label="Start a meeting"
                            role="button"
                            (keyup.enter)="goToMeetingHost()"
                        >
                            <img aria-hidden="true" alt="" src="assets/img/start_a_meeting.svg" />
                            <div aria-hidden="true">
                                {{ 'landing_or_home_page.header.start_a_meeting' | translate }}
                            </div>
                        </div>
                        <div
                            class="quick-link"
                            tabindex="0"
                            aria-label="Join a meeting"
                            role="button"
                            (keyup.enter)="goToJoinMeeting()"
                            (click)="goToJoinMeeting()"
                            role="button"
                        >
                            <img aria-hidden="true" alt="" src="assets/img/join_meeting.svg" />
                            <div aria-hidden="true">{{ 'landing_or_home_page.header.join_meeting' | translate }}</div>
                        </div>
                        <div
                            class="quick-link"
                            (click)="openPlanPopup()"
                            tabindex="0"
                            role="button"
                            aria-label="Plan a Meeting"
                            role="button"
                            (keyup.enter)="openPlanPopup()"
                        >
                            <img aria-hidden="true" alt="" src="assets/img/plan_a_meeting.svg" />
                            <div aria-hidden="true">{{ 'landing_or_home_page.header.plan_a_meeting' | translate }}</div>
                        </div>
                    </div>
                </div>
                <!-- //Go to Dashboard  -->
                <div class="bottom-desc" *ngIf="language === 'en' && isMobile">
                    {{ 'aftersignin_or_introduction.go_to' | translate }}&nbsp;
                    <span class="pointer" (click)="goToDashboard()">
                        {{ 'aftersignin_or_introduction.my_dashboard' | translate }}</span
                    >
                    &nbsp;{{ 'aftersignin_or_introduction.review_meeting' | translate }}
                </div>
                <div class="bottom-desc" *ngIf="language === 'hi' && isMobile">
                    {{ 'aftersignin_or_introduction.review_meeting' | translate }}&nbsp;
                    <span class="pointer" (click)="goToDashboard()">
                        {{ 'aftersignin_or_introduction.my_dashboard' | translate }}</span
                    >
                    &nbsp;{{ 'aftersignin_or_introduction.go_to' | translate }}
                </div>
                <app-cta-button *ngIf="!isMobile"></app-cta-button>
            </div>
        </div>
        <div class="right-content">
            <app-lottie-renderer
                path="assets/lottie/Hero_Animation_v2.json"
                *ngIf="!isAuthenticated && !bannersConfigData?.showHomePageBanner"
            ></app-lottie-renderer>
            <app-lottie-renderer
                *ngIf="!isAuthenticated && bannersConfigData?.showHomePageBanner"
                [path]="bannersConfigData?.homePageBannerURL"
                [class.banner-image]="showFestivalUIChanges"
            ></app-lottie-renderer>
            <img
                [src]="
                    isMobile
                        ? 'assets/img/website/hero_animation_loggedin.svg'
                        : 'assets/img/website/hero_animation_loggedin_desktop.svg'
                "
                alt="Colaboration of group of people having group call on jiomeet"
                *ngIf="isAuthenticated && !bannersConfigData?.showHomePageBanner"
            />
            <app-lottie-renderer
                *ngIf="isAuthenticated && bannersConfigData?.showHomePageBanner"
                [path]="bannersConfigData?.homePageBannerURL"
                [class.banner-image]="showFestivalUIChanges"
            ></app-lottie-renderer>
        </div>

        <div class="no-support px-3" *ngIf="showNotSupportedMessage">
            <span>{{ 'landing_or_home_page.section.website_best_viewed' | translate }}</span>
            <div class="close-icon">
                <img src="assets/images/add_icn.svg" (click)="showNotSupportedMessage = false" alt="" />
            </div>
        </div>
    </section>

    <section class="website-features" *ngIf="!isAuthenticated">
        <section class="header-container" [class.sec-margin-h-44]="isMobile">
            <h4 tabindex="0">{{ 'website_home.for_all_needs' | translate }},</h4>
            <h2 tabindex="0">{{ 'website_home.got_you_covered' | translate }}</h2>
        </section>
        <div *ngFor="let productFeature of websiteProductFeatures; let i = index" [class.sec-margin-h]="isMobile">
            <app-website-product-features
                [productFeature]="productFeature"
                [reverse]="i % 2 != 0"
            ></app-website-product-features>
        </div>
    </section>

    <section class="website-solutions" [class.sec-margin-h]="isMobile" [class.sec]="!isMobile" *ngIf="!isAuthenticated">
        <h4 tabindex="0" [class.sec-margin-h-28]="isMobile">
            {{ 'website_home.delightful_solutions' | translate }}
        </h4>
        <section class="solution-content">
            <div *ngFor="let solution of websiteSolutions.slice(0, 1)" class="solutions-wrapper">
                <label tabindex="0" [ngStyle]="{ color: solution.color }">{{ solution?.tlabel | translate }}</label>
                <ng-container *ngFor="let solutionInfo of solution?.data">
                    <div *ngIf="!isMobile || solutionInfo?.title || solutionInfo?.desc">
                        <app-website-solution [solutionInfo]="solutionInfo"></app-website-solution>
                    </div>
                </ng-container>
            </div>
            <div *ngFor="let solution of websiteSolutions.slice(1, 2)" class="solutions-wrapper">
                <div class="entrepreneurs">
                    <label tabindex="0" [ngStyle]="{ color: solution.color }">{{ solution?.tlabel | translate }}</label>
                </div>
                <div class="inner-solution-content">
                    <div *ngFor="let innerSolution of websiteSolutions.slice(1, 3)" class="solutions-wrapper">
                        <ng-container *ngFor="let solutionInfo of innerSolution?.data">
                            <div *ngIf="!isMobile || solutionInfo?.title || solutionInfo?.desc">
                                <app-website-solution [solutionInfo]="solutionInfo"></app-website-solution>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section
        class="website-content partners"
        appForceFullWidth
        [class.padding-h-16]="isMobile"
        *ngIf="!isAuthenticated"
        [style.display]="homePageBranding?.length == 0 ? 'none' : 'block'"
    >
        <h4 tabindex="0" class="heading">{{ 'website_home.trusted_partners' | translate }}</h4>
        <div class="partners-list">
            <ng-container *ngFor="let partner of homePageBranding">
                <img src="{{ partner.path }}" alt="" />
            </ng-container>
        </div>
    </section>
    <section class="carving-milstones" appForceFullWidth *ngIf="!isAuthenticated">
        <div class="map">
            <img src="assets/img/website/India.svg" alt="" />
        </div>
        <div class="info" appObserveVisibility (visible)="onVisibleCarnival($event)">
            <h4 tabindex="0">{{ 'website_home.milestones_india' | translate }}</h4>
            <div class="carving-data" *ngIf="websiteMetaInfo?.milestones">
                <span tabindex="0" class="count">
                    <span
                        ><span class="value">{{ milestone_downloads }}</span
                        ><span>Million</span>
                    </span>
                    <span class="count-label">{{ 'website_home.downloads' | translate }}</span></span
                >
                <span tabindex="0" class="count">
                    <span
                        ><span class="milestone-meeting-minute">{{ milestone_meeting_minutes }}</span
                        ><span>Billion</span>
                    </span>

                    <span class="count-label">{{ 'website_home.meeting_minutes' | translate }}</span></span
                >
                <span tabindex="0" class="count">
                    <span
                        ><span class="value">{{ milestone_webinar_minutes }}</span
                        ><span>Billion</span>
                    </span>
                    <span class="count-label">{{ 'website_home.webinar_minutes' | translate }}</span></span
                >
            </div>
        </div>
    </section>

    <!-- <section class="upcoming-events" [class.sec-margin-h-44]="isMobile">
    <h3 class="header">{{ 'website_home.upcoming_events' | translate }}</h3>
    <app-website-upcoming-events></app-website-upcoming-events>
  </section> -->
    <section class="website-content got-questions" [class.sec-margin-h-44]="isMobile" *ngIf="!isAuthenticated">
        <app-explore-meet
            bottomButtons="homePage"
            title="{{ 'website_home.got_question' | translate }}"
            desc=""
            btnText1="{{ 'website_home.contact_us' | translate }}"
            btnText2="{{ 'website_home.contact_sales' | translate }}"
            btnText1Link="/contact-us"
            btnText2Link="/contactsales"
        >
            <span>{{ 'website_home.jiomeet_for_business' | translate }}</span
            ><span>{{ 'website_home.integrate_jiomeet' | translate }}</span
            ><span>{{ 'website_home.plan_a_conference' | translate }}</span>
        </app-explore-meet>
    </section>
    <section class="website-content tips-updates" [class.sec-margin-h-44]="isMobile" *ngIf="!isAuthenticated">
        <app-jiomeet-subscribe [subscribeInfo]="HomeSubscribe"></app-jiomeet-subscribe>
    </section>
</div>

<div class="plan-meeting-popup" *ngIf="showMeetingForm">
    <app-meeting-form (close)="toggleMeetingForm()"></app-meeting-form>
</div>
<div class="popup-component" *ngIf="showPlanPopup">
    <div class="bg" (click)="closePlanSelection()"></div>
    <div class="popup planSelection">
        <div class="popup-header">
            <h3>
                {{ 'dashboard_home.plan' | translate }}
            </h3>
            <div class="close" (click)="closePlanSelection()">
                <img alt="" src="assets/img/close_icon.svg" />
            </div>
        </div>
        <div class="body">
            {{ 'dashboard_home.please_select' | translate }}
        </div>
        <div class="actions d-flex justify-content-center mt-5">
            <button class="custom-button bg-secondary mr-3" (click)="toggleMeetingForm()">
                {{ 'dashboard_home.meeting' | translate }}
            </button>
            <button class="custom-button bg-secondary mr-3" (click)="navigateToWebinars()">
                {{ 'dashboard_home.event' | translate }}
            </button>
        </div>
    </div>
</div>

<div *ngIf="iosMacDeeplink" [ngStyle]="{ visibility: 'hidden' }">
    <iframe title="" ref="iframe" [src]="iosMacDeeplink | safe"></iframe>
</div>
