import { Injectable } from '@angular/core';

import { forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { UserService } from './user.service';
import { RtcService } from './rtc.service';
import { AuthService } from './auth.service';
import { EventEmitterService } from './event-emitter.service';

import { APP_EVENTS, SOCKET_EVENTS } from '../../constants';
import { PermissionService } from './permission.service';

@Injectable({
    providedIn: 'root'
})
export class HistoryCallerService {
    constructor(
        private userService: UserService,
        private authService: AuthService,
        private rtcService: RtcService,
        private eventEmitterService: EventEmitterService,
        private permissionService: PermissionService,
        private toastrService: ToastrService
    ) {}

    getBaseMeetingObj(history, room: any = {}) {
        return {
            callurl: history.roomUrl || history.roomurl,
            room: history.room || history.roomkey,
            room_id: history.roomId || history.roomid,
            extension: history.extension,
            gateway_ip: history.gateway_ip || history.gatewayip || room.gateway_ip || room.gatewayip,
            isInitiater: false,
            participantCount: 0,
            token: this.authService.getAuthInfo().jwt
        };
    }

    joinAsGuest(history) {
        forkJoin([this.userService.getUser(), this.userService.getUserRoom()]).subscribe(([user, room]) => {
            const meetingObj: any = this.getBaseMeetingObj(history, room);
            Object.assign(meetingObj, {
                name: history.ownerName,
                group_id: history.groupId,
                is_disconnect: 'false',
                owner: history.ownerName,
                owner_name: history.ownerName,
                owner_id: history.userId,
                owner_phoneNo: history.ownerPhoneNo,
                historyId: history._id,
                title: history.title,
                isAlreadyActive: history.isActive,
                guest_name: user.name,
                tenantId: user.roles[0].tenantId
            });
            this.activateConference(meetingObj);
            this.rtcService.sendEvent({
                event: SOCKET_EVENTS.JOIN_FROM_HISTORY,
                data: meetingObj
            });
        });
    }

    makeCall(history) {
        if (history.isActive) {
            this.joinAsGuest(history);
        } else {
            this.permissionService.checkForUser(['normal']).subscribe((isANormalUser) => {
                if (isANormalUser && history.members.length + history.manualIps.length > 1) {
                    this.toastrService.warning("You don't have permission to call multiple users.");
                    return;
                }
                forkJoin([this.userService.getUser(), this.userService.getUserRoom()]).subscribe(([user, room]) => {
                    const participants = history.members;
                    const manualIps = history.manualIps || [];
                    const groups = history.members.filter((participant) => !!participant.members);
                    const vcs = history.members.filter((participant) => !!participant.ipaddress);
                    const users = history.members.filter((participant) => !!participant.phoneNo);

                    const targetIds = [];
                    const targetVcs = [];

                    this.rtcService.setRoomInfo(room);
                    participants.forEach((participant) => {
                        if (participant.email) {
                            targetIds.push({ userId: participant.userId });
                        } else if (participant.ipaddress) {
                            targetVcs.push({ vcId: participant.vcId });
                        } else if (participant.members) {
                            participant.members.forEach((member) => targetIds.push({ userId: member.userId }));
                        }
                    });

                    this.permissionService.checkForUser(['normal']).subscribe((bool) => {
                        if (bool && targetIds.length + targetVcs.length + manualIps.length > 1) {
                            this.toastrService.warning("You don't have permission to call multiple users.");
                            return;
                        }
                    });

                    if (vcs.length) {
                        this.sendVCInivite(vcs, room).subscribe();
                    }
                    if (history && history.manualIps && history.manualIps.length) {
                        this.sendVCInivite(history.manualIps, room);
                    }

                    const title = participants.map((p) => p.title || p.name).join(',');
                    const meetingObj: any = this.getBaseMeetingObj(room);
                    Object.assign(meetingObj, {
                        // participants
                        users: targetIds,
                        vcs: targetVcs,
                        // owner details
                        owner: user.name,
                        owner_id: user._id,
                        owner_name: user.name,
                        owner_phoneNo: user.phoneNo,
                        owner_emailId: user.email,
                        owner_tenantId: user.roles[0].tenantId,
                        // conference info
                        title: history.title || this.getMeetingTitle(groups, users, vcs, manualIps),
                        is_disconnect: 'false',
                        group_id: groups.length ? groups[0]._id : '0',
                        token: this.authService.getAuthInfo().jwt,
                        time: new Date().valueOf(),
                        isInitiater: true,
                        isSelfCall: false,
                        participantCount: 0,
                        tenantId: user.roles[0].tenantId
                    });

                    if (manualIps.length) {
                        meetingObj.manualIps = manualIps.map((mi) => ({
                            type: mi.type,
                            ipaddress: mi.ipaddress
                        }));
                    }
                    this.activateConference(meetingObj);
                });
            });
        }
    }

    private activateConference(meetingObj) {
        this.eventEmitterService.emit({ type: APP_EVENTS.ACTIVATE_CONFERENCE, data: meetingObj });
    }

    private sendVCInivite(vcs, room) {
        const payload: any = {};
        payload.roomID = '' + room.roomid;
        payload.vcIds = vcs.map((vc) => vc.vcId || vc._id);
        payload.manualIps = [];
        vcs.map((vc) => {
            if (vc.manualIps && vc.manualIps.length) {
                payload.manualIps.push({ type: vc.type.toLowerCase(), ipaddress: vc.ipaddress });
            }
        });
        return this.rtcService.inviteVC(payload);
    }

    private getMeetingTitle(groups = [], users = [], vcs = [], manualIps = []) {
        return `${[...groups, ...users, ...vcs, ...manualIps].slice(0, 3).join(' ,')}`;
    }
}
