<div class="page container-fluid">
    <div class="row h-100">
        <div class="left-content col-xl-7 col-lg-7 col-md-12 col-sm-12">
            <div class="img-wrap">
                <app-lottie-renderer path="assets/lottie/Laptop Animation.json"></app-lottie-renderer>
            </div>
        </div>
        <div class="right-content col-xl-5 col-lg-5 col-md-12 col-sm-12 py-5">
            <div class="component-wrap">
                <div class="form-wrap">
                    <section>
                        <h3>{{ 'activate_component.welcome' | translate }}</h3>
                        <form [formGroup]="accountInfoForm" (submit)="submit()" class="custom-form">
                            <div class="form-group">
                                <label class="m-0 mb-2">{{ 'activate_component.firstname' | translate }}</label>
                                <input
                                    type="text"
                                    class="form-control text-left"
                                    id="name"
                                    formControlName="firstName"
                                    placeholder=" {{ 'activate_component.firstname' | translate }}"
                                />
                                <div class="mt-2 text-error" *ngIf="errors.firstName">{{ errors.firstName }}</div>
                            </div>
                            <div class="form-group">
                                <label class="m-0 mb-2">{{ 'activate_component.lastname' | translate }}</label>
                                <input
                                    type="text"
                                    class="form-control text-left"
                                    id="lname"
                                    formControlName="lastName"
                                    placeholder="{{ 'activate_component.lastname' | translate }}"
                                />
                                <div class="mt-2 text-error" *ngIf="errors.lastName">{{ errors.lastName }}</div>
                            </div>
                            <div class="form-group">
                                <label class="m-0 mb-2">{{ 'activate_component.password' | translate }}</label>
                                <input
                                    [type]="showPassword ? 'text' : 'password'"
                                    (keydown.space)="$event.preventDefault()"
                                    class="form-control text-left"
                                    id="password"
                                    formControlName="password"
                                    placeholder="{{ 'activate_component.password' | translate }}"
                                    maxlength="20"
                                />
                                <div class="eye position-relative">
                                    <img
                                        alt=""
                                        src="{{
                                            showPassword
                                                ? 'assets/img/show_password.svg'
                                                : 'assets/img/hide_password.svg'
                                        }}"
                                        (click)="togglePassword()"
                                        class="eye"
                                    />
                                </div>
                            </div>

                            <ng-container *ngIf="accountInfoForm.value.password">
                                <div class="password-hint">
                                    <p class="password-info">{{ 'password_criteria.must_have' | translate }}</p>
                                    <ul>
                                        <li
                                            [ngClass]="{
                                                'is-invalid': errors?.password?.includes('lengthError'),
                                                valid: !errors?.password?.includes('lengthError')
                                            }"
                                        >
                                            {{ 'password_criteria.characters' | translate: txParams?.characters }}
                                        </li>
                                        <li
                                            [ngClass]="{
                                                'is-invalid': errors?.password?.includes('alphabetError'),
                                                valid: !errors?.password?.includes('alphabetError')
                                            }"
                                        >
                                            {{ 'password_criteria.letter' | translate: txParams?.alphabets }}
                                        </li>
                                        <li
                                            [ngClass]="{
                                                'is-invalid': errors?.password?.includes('numberError'),
                                                valid: !errors?.password?.includes('numberError')
                                            }"
                                        >
                                            {{ 'password_criteria.number' | translate: txParams?.numbers }}
                                        </li>
                                        <li
                                            [ngClass]="{
                                                'is-invalid': errors?.password?.includes('dualError'),
                                                valid: !errors?.password?.includes('dualError')
                                            }"
                                        >
                                            {{ 'password_criteria.case' | translate }}
                                        </li>
                                        <li
                                            [ngClass]="{
                                                'is-invalid': errors?.password?.includes('specialCharsError'),
                                                valid: !errors?.password?.includes('specialCharsError')
                                            }"
                                        >
                                            {{ 'password_criteria.symbol' | translate }}
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                            <div class="form-group">
                                <label class="m-0 mb-2">{{ 'activate_component.confirmpassword' | translate }}</label>
                                <input
                                    type="password"
                                    (keydown.space)="$event.preventDefault()"
                                    class="form-control text-left"
                                    id="confirmPassword"
                                    formControlName="confirmPassword"
                                    placeholder="{{ 'activate_component.confirmpassword' | translate }}"
                                    maxlength="20"
                                />
                                <div class="mt-2 text-error" *ngIf="errors.confirmPassword">
                                    {{ errors.confirmPassword }}
                                </div>
                            </div>
                            <div class="mt-2 text-error" *ngIf="errors.global">{{ errors.global }}</div>
                            <div class="agreement-note mt-3">
                                {{ 'activate_component.by' | translate }}
                                <a class="pointer blue" (click)="launchPrivacy()">
                                    {{ 'activate_component.privacypolicy' | translate }}</a
                                >
                                {{ 'activate_component.and' | translate }}
                                <a class="pointer blue" (click)="launchTnc()">{{
                                    'activate_component.terms' | translate
                                }}</a>
                            </div>
                            <div class="text-right">
                                <button
                                    type="submit"
                                    class="custom-button bg-primary mt-3 col-lg-7 col-md-12"
                                    [class.disabled]="!validPassword || !accountInfoForm.valid || !enableProceedBtn"
                                >
                                    {{ 'activate_component.continue' | translate }}
                                </button>
                            </div>
                        </form>
                    </section>
                    <section class="welcome-dialog">
                        <p-dialog
                            [(visible)]="showDialog"
                            [modal]="true"
                            [style]="{ width: '50vw' }"
                            [baseZIndex]="10000"
                            [draggable]="false"
                            [resizable]="false"
                        >
                            <ng-template pTemplate="header">
                                <span class="close pointer">
                                    <img
                                        alt=""
                                        src="assets/img/website/icon_close_welcome.svg"
                                        (click)="showDialog = !showDialog"
                                    />
                                </span>
                            </ng-template>
                            <div class="img"></div>
                            <main class="text-center">
                                <div class="title">
                                    {{ 'activate_component.welcome_voucher.title' | translate }}
                                </div>
                                <div class="subtitle">
                                    {{ 'activate_component.welcome_voucher.subtitle' | translate }}
                                </div>
                                <p class="p-m-0 content">
                                    {{ 'activate_component.welcome_voucher.content' | translate }}
                                </p>
                            </main>

                            <ng-template pTemplate="footer">
                                <div class="d-flex justify-content-center footer">
                                    <button class="btn primary" (click)="goToOnboarding()">
                                        {{ 'activate_component.welcome_voucher.continue' | translate }}
                                    </button>
                                </div>
                            </ng-template>
                        </p-dialog>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>
