export const GA_EVENTS = {
    PLAN_A_MEETING_DAILY_RECURRING_CLICK: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Recurring meeting',
        event_label: 'Daily'
    },
    PLAN_A_MEETING_WEEKLY_RECURRING_CLICK: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Recurring meeting',
        event_label: 'Weekly'
    },
    PLAN_A_MEETING_MONTHLY_RECURRING_CLICK: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Recurring meeting',
        event_label: 'Monthly'
    },
    PLAN_A_MEETING_RESTRICTED_ENABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Restricted meeting',
        event_label: 'Enabled'
    },
    PLAN_A_MEETING_RESTRICTED_DISABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Restricted meeting',
        event_label: 'Disabled'
    },
    PLAN_A_MEETING_TRANSLATION_ENABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'translate meeting',
        event_label: 'Enabled'
    },
    PLAN_A_MEETING_TRANSLATION_DISABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'translate meeting',
        event_label: 'Disabled'
    },
    PLAN_A_MEETING_PARTICIPANT_MY_ORG_ENABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Allow participants only from my organisation',
        event_label: 'Enabled'
    },
    PLAN_A_MEETING_PARTICIPANT_MY_ORG_DISABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Allow participants only from my organisation',
        event_label: 'Disabled'
    },
    PLAN_A_MEETING_DONT_ALLOW_GUEST_USER_ENABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: "Don't allow guest users",
        event_label: 'Enabled'
    },
    PLAN_A_MEETING_DONT_ALLOW_GUEST_USER_DISABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: "Don't allow guest users",
        event_label: 'Disabled'
    },
    PLAN_A_MEETING_CLASSROOM_MODE_ENABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Classroom mode',
        event_label: 'Enabled'
    },
    PLAN_A_MEETING_CLASSROOM_MODE_DISABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Classroom mode',
        event_label: 'Disabled'
    },
    PLAN_A_MEETING_LARGE_MEETING_ENABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Large Meetings',
        event_label: 'Enabled'
    },
    PLAN_A_MEETING_LARGE_MEETING_DISABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Large Meetings',
        event_label: 'Disabled'
    },
    PLAN_A_MEETING_WAITING_ROOM_ENABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Enable waiting room',
        event_label: 'Enabled'
    },
    PLAN_A_MEETING_WAITING_ROOM_DISABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Enable waiting room',
        event_label: 'Disabled'
    },
    PLAN_A_MEETING_JOIN_BEFORE_HOST_ENABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Allow join before host',
        event_label: 'Enabled'
    },
    PLAN_A_MEETING_JOIN_BEFORE_HOST_DISABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Allow join before host',
        event_label: 'Disabled'
    },
    PLAN_A_MEETING_FORCE_MUTE_ENTRY_ENABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Force mute participants on entry',
        event_label: 'Enabled'
    },
    PLAN_A_MEETING_FORCE_MUTE_ENTRY_DISABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Force mute participants on entry',
        event_label: 'Disabled'
    },
    PLAN_A_MEETING_SOFT_MUTE_ENTRY_ENABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Soft mute participants on entry',
        event_label: 'Enabled'
    },
    PLAN_A_MEETING_SOFT_MUTE_ENTRY_DISABLED: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Soft mute participants on entry',
        event_label: 'Disabled'
    },
    PLAN_A_MEETING_POPUP_CANCEL: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Cancel',
        event_label: 'Success'
    },
    PLAN_A_MEETING_POPUP_SUBMIT: {
        event: 'jiomeet',
        event_category: 'Plan a meeting',
        event_action: 'Schedule',
        event_label: 'Success'
    }
};
