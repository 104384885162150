<div class="join-meeting-component">
    <div id="content" class="container">
        <div class="layout">
            <div class="d-flex justify-content-center">
                <div class="w-100">
                    <section>
                        <h2 class="text-center">Join a Meeting</h2>
                        <form [formGroup]="joinMeetingForm" (submit)="submit()">
                            <div class="form-group">
                                <input
                                    type="text"
                                    class="form-control text-center"
                                    id="email"
                                    (input)="validateNumberPattern($event)"
                                    (paste)="onPaste($event)"
                                    (keydown.backspace)="handleBackspace($event)"
                                    formControlName="meetingId"
                                    aria-describedby="emailHelp"
                                    placeholder="Meeting ID or Meeting Link"
                                    maxlength="200"
                                />
                                <span class="invalid-error">{{ errors.meetingId }}</span>
                            </div>
                            <span class="invalid-error" *ngIf="errors.global == 'Invalid ID'">
                                PLEASE ENTER VALID MEETING ID
                            </span>
                            <span class="invalid-error" *ngIf="errors.global != 'Invalid ID'">
                                {{ errors.global }}
                            </span>
                            <div class="col p-0 w-100 mt-3">
                                <button
                                    type="submit"
                                    class="btn primary"
                                    [class.disabled]="!joinMeetingForm.valid || working"
                                >
                                    Join
                                </button>
                            </div>
                        </form>
                        <!-- <ng-container *ngIf="toggleCaptchaUI">
              <form>
                <div class="form-group">
                  <label class="mt-2" for="name"
                    >Meeting ID or Personal Link Name</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="email"
                    aria-describedby="emailHelp"
                    placeholder="Meeting ID or Personal Link Name"
                  />
                </div>
                <div class="form-group">
                  <label class="mt-2" for="name">Your Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    aria-describedby="emailHelp"
                    placeholder="Your Name"
                  />
                </div>
                <div class="captcha-container d-flex flex-column mt-3">
                  <div class="text-center title">
                    As a protection against automated spam and join the meeting,
                    you will need to type in the words that appear in this
                    image.
                  </div>
                  <div class="d-flex mt-3 captcha-area justify-content-around">
                    <input type="text" placeholder="Captcha Code" />
                    <input type="text" value="A213DD" />
                  </div>
                </div>
                <div class="col p-0 w-100 mt-3">
                  <button type="submit" class="btn primary">
                    Join
                  </button>
                </div>
              </form>
            </ng-container> -->
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>
