<div
    class="menu-item"
    tabindex="0"
    (keydown.enter)="handleGoTo(menuInfo)"
    (click)="handleGoTo(menuInfo)"
    [class.no-hover]="!menuInfo.path"
    (keydown.tab)="handleTabPress($event)"
>
    <div class="img" *ngIf="menuInfo?.icon">
        <img [src]="menuInfo?.icon" alt="JioMeet" />
    </div>
    <div class="menu-info">
        <div class="menu-header">
            <span class="title">{{ menuInfo?.ttitle | translate }}</span>
            <span
                class="btn btn-primary"
                [class.coming-soon]="menuInfo.type == 2"
                [class.new]="menuInfo.type == 1"
                *ngIf="menuInfo.type"
                >{{ menuInfo.ttag | translate }}</span
            >
        </div>
        <div class="desc">{{ menuInfo?.tdesc | translate }}</div>
    </div>
    <span class="right-arrow"><img src="assets/img/website/right_arrow_hover.svg" alt="" /></span>
</div>
