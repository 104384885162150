import { Pipe, PipeTransform } from '@angular/core';

import filter from 'lodash-es/filter';
import values from 'lodash-es/values';
import pick from 'lodash-es/pick';

@Pipe({
    name: 'filterContactBySearchKey',
    pure: false
})
export class FilterContactBySearchKeyPipe implements PipeTransform {
    transform(input: any[], searchQuery: string, key: string | Array<string>): any {
        const keys = Array.isArray(key) ? key : [key];
        searchQuery = searchQuery.trim().split(' ').join('');
        return filter(
            input,
            (val) =>
                !!filter(values(pick(val, keys)), (str) =>
                    str.trim().split(' ').join('').toLowerCase().startsWith(searchQuery.toLowerCase())
                ).length
        );
    }
}
