import { Component, OnInit } from '@angular/core';

import { GoogleTagManagerService, UtilService } from 'src/app/core';
import { GA_PAGEVIEW } from '../website/ga-pageview';

@Component({
    selector: 'app-get-client',
    templateUrl: './get-client.component.html',
    styleUrls: ['./get-client.component.scss']
})
export class GetClientComponent implements OnInit {
    constructor(private utilService: UtilService, private gTagService: GoogleTagManagerService) {}

    ngOnInit() {
        this.utilService.downloadJioMeet(true);
        this.gTagService.pushOnDataLayer(GA_PAGEVIEW.GET_CLIENT_PAGE_VIEW);
    }
}
