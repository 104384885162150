import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'alphabeticalSort'
})
export class AlphabeticalSortPipe implements PipeTransform {
    transform(value: any[], key: string): any {
        return (value || []).sort((a, b) => {
            if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) {
                return -1;
            }
            if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) {
                return 1;
            }
            return 0;
        });
    }
}
