import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {
    private prefix = 'jmc-s';
    private sessionInMemoryStorage = {};
    private isInisideIframe = false;

    constructor() {
        this.isInisideIframe = window.self !== window.top;
    }

    addItem(key, value) {
        try {
            const storageKey = `${this.prefix}.${key}`;
            if (!this.isInisideIframe) {
                sessionStorage.setItem(storageKey, JSON.stringify(value));
            } else {
                this.sessionInMemoryStorage[storageKey] = JSON.stringify(value);
            }
        } catch (e) {
            console.error('Error adding item to session storage', e);
        }
    }

    removeItem(key) {
        try {
            const storageKey = `${this.prefix}.${key}`;
            if (!this.isInisideIframe) {
                sessionStorage.removeItem(storageKey);
            } else {
                delete this.sessionInMemoryStorage[storageKey];
            }
        } catch (e) {
            console.error('Error removing item to session storage', e);
        }
    }

    getItem(key) {
        try {
            const storageKey = `${this.prefix}.${key}`;
            if (!this.isInisideIframe) {
                const item = sessionStorage.getItem(storageKey);
                return item ? JSON.parse(item) : null;
            } else {
                const item = this.sessionInMemoryStorage[storageKey];
                return item ? JSON.parse(item) : null;
            }
        } catch (error) {
            console.error('Error getting item from session storage', error);

            return null;
        }
    }

    clear() {
        sessionStorage.clear();
    }
}
