<div class="website-events" id="website-top">
    <section [class.padding-h-0]="isMobile" class="full-width" appForceFullWidth>
        <div class="events-top" [class.padding-h-0]="isMobile" [class.width-1366]="!isMobile">
            <div class="left-content" [class.padding-h-16]="isMobile">
                <div class="heading">
                    {{ 'website_events.jiomeet' | translate }}
                    <span class="events-highlight-0">{{ 'website_events.events' | translate }}</span>
                </div>
                <div class="desc">
                    <span>{{ 'website_events.top-desc-0' | translate }}</span>
                    <span class="events-highlight-1">{{ 'website_events.top-desc-1' | translate }}</span>
                </div>
                <div class="action-btns">
                    <button class="custom-action-btn" (click)="goToContactSales()">
                        {{ 'website_events.contact_sales' | translate }}
                    </button>
                </div>
            </div>
            <div class="right-content">
                <!-- <img src="assets/img/events/events-top.png" *ngIf="!isMobile" alt="" /> -->
            </div>
        </div>
    </section>
    <section class="sec-1" [class.padding-h-44]="isMobile">
        <div class="heading-0">{{ 'website_events.interact' | translate }}</div>
        <div class="subheading">{{ 'website_events.choose_life' | translate }}</div>
    </section>
    <section class="sec-2" [class.padding-h-16]="isMobile">
        <div *ngFor="let productFeature of websiteEventFeatures; let i = index">
            <app-website-product-features
                [productFeature]="productFeature"
                [reverse]="i % 2 != 0"
            ></app-website-product-features>
        </div>
    </section>
    <section class="sec-3 sec" [class.padding-h-16]="isMobile" appForceFullWidth>
        <div [ngStyle]="{ margin: 'auto' }">
            <div class="sec-header">
                <div class="subheading">{{ 'website_events.what_special_for_you' | translate }}</div>
                <div class="heading-2">{{ 'website_events.got_you_covered' | translate }}</div>
            </div>

            <div class="meet-event-stats">
                <div class="event-statistics jiomeet-platform active" [class.padding-h-28]="isMobile">
                    <h2 class="heading">{{ 'website_events.jiomeet_discover' | translate }}</h2>
                    <ul class="event-stats">
                        <li
                            *ngFor="let eventState of eventStats"
                            [ngClass]="{ subheading: isMobile === true, 'heading-1': isMobile === false }"
                        >
                            <span><img src="assets/img/enterprise/active-sign.svg" alt="" /></span>
                            <span>{{ eventState.tlabel | translate }}</span>
                        </li>
                    </ul>
                    <!-- <button
            class="custom-action-btn"
            [routerLink]="['/jiomeetDownload/']"
            [queryParams]="{ scrollTo: 'mobile' }"
          >
            {{ 'website_events.watch_free' | translate }}
          </button> -->
                </div>
                <div [class.display-inlineflex]="!isMobile" #productSlider>
                    <div class="event-statistics other-platform active">
                        <h2 class="heading">{{ 'website_events.other_event_platforms' | translate }}</h2>
                        <ul class="event-stats">
                            <li
                                *ngFor="let eventState of eventStats; let i = index"
                                [ngClass]="{ subheading: isMobile === true, 'heading-1': isMobile === false }"
                            >
                                <span>
                                    <img
                                        src="assets/img/enterprise/active-sign.svg"
                                        alt=""
                                        *ngIf="i == 0 || i == eventStats.length - 1"
                                    />
                                    <img
                                        src="assets/img/enterprise/not-active-sign.svg"
                                        alt=""
                                        *ngIf="i !== 0 && i !== eventStats.length - 1"
                                    />
                                </span>
                                <span>{{ eventState.tlabel | translate }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="event-statistics other-platform active" [class.padding-h-28]="isMobile">
                        <h2 class="heading">{{ 'website_events.other_video_conf_tools' | translate }}</h2>
                        <ul class="event-stats">
                            <li
                                *ngFor="let eventState of eventStats; let i = index"
                                [ngClass]="{ subheading: isMobile === true, 'heading-1': isMobile === false }"
                            >
                                <span>
                                    <img src="assets/img/enterprise/active-sign.svg" alt="" *ngIf="(i + 1) % 2 == 0" />
                                    <img
                                        src="assets/img/enterprise/not-active-sign.svg"
                                        alt=""
                                        *ngIf="(i + 1) % 2 !== 0"
                                    />
                                </span>
                                <span>{{ eventState.tlabel | translate }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="got-questions sec" [class.sec-padding-h-44]="isMobile">
        <app-explore-meet
            title="{{ 'website_home.got_question' | translate }}"
            desc=""
            btnText1="{{ 'website_events.contact_sales' | translate }}"
            btnText2="{{ 'website_events.contact_us' | translate }}"
            btnText1Link="/contactsales"
            btnText2Link="/contact-us"
        >
            <span>{{ 'website_home.jiomeet_for_business' | translate }} </span>
            <span class="margin-bottom-0">{{ 'website_home.integrate_jiomeet' | translate }} </span>
            <span class="margin-bottom-0">{{ 'website_home.plan_a_conference' | translate }}</span></app-explore-meet
        >
    </section>

    <section class="website-content tips-updates" [class.sec-padding-h-44]="isMobile">
        <app-jiomeet-subscribe></app-jiomeet-subscribe>
    </section>
</div>
