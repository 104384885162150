import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APP_EVENTS } from 'src/app/constants';
import {
    EventData,
    EventEmitterService,
    UserService,
    UtilService,
    ValidationService,
    GoogleTagManagerService
} from 'src/app/core';
import { GA_NEW } from 'src/app/shared/ga-new';
import { GA_EVENTS } from '../ga-events';
import { GA4_EVENTS } from '../ga4-events';

@Component({
    selector: 'app-jiomeet-subscribe',
    templateUrl: './jiomeet-subscribe.component.html',
    styleUrls: ['./jiomeet-subscribe.component.scss']
})
export class JiomeetSubscribeComponent implements OnInit {
    subscribeForm: FormGroup;
    submitted = false;
    error;
    errors: any = {};
    emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    @Input() subscribeInfo: string;
    @Input() SubcribePage: string;
    @Output() subscribeSuccess: EventEmitter<any> = new EventEmitter();
    jiomeetSubscription = false;
    subscribedEmail = null;
    isMobile = false;
    alreadySubscribed = false;
    constructor(
        private fb: FormBuilder,
        private validationService: ValidationService,
        private userService: UserService,
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private gTagService: GoogleTagManagerService
    ) {}

    ngOnInit(): void {
        this.subscribeForm = this.fb.group({
            email: ['', [Validators.required, this.validationService.isFormControlValueAnEmail]]
        });
        this.isMobile = this.utilService.isMobileBrowser(1080);
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 1080 ? false : true;
            }
        });
    }

    subscribe() {
        switch (this.SubcribePage) {
            case 'productEnterprise': {
                this.gTagService.sendEvent(GA_EVENTS.PRODUCT_ENTERPRISE_SUBSCRIBE);
                this.gTagService.sendEvent(GA_NEW.PRODUCT_ENTERPRISE_SUBSCRIBE);
                break;
            }
            case 'productCpass': {
                this.gTagService.sendEvent(GA_EVENTS.PRODUCT_PLATFORM_SUBSCRIBE);
                break;
            }
            case 'productJiomeet': {
                this.gTagService.sendEvent(GA_EVENTS.PRODUCT_JIOMEET_SUBSCRIBE);
                this.gTagService.sendEvent(GA_NEW.PRODUCT_JIOMEET_SUBSCRIBE);
                break;
            }
        }
        this.alreadySubscribed = false;
        if (!this.subscribeForm.valid) {
            return;
        }
        this.errors = {};
        this.error = false;

        this.userService.subscribeToJioMeet(this.subscribeForm.value).subscribe(
            (res: any) => {
                this.submitted = true;
                this.afterSubscription(this.subscribeForm.value);
                if (!res.success) {
                    // it means user has already subscribed
                    this.alreadySubscribed = true;
                    setTimeout(() => {
                        const subscibeElement: any = document.getElementsByClassName('subscribeheaderfocus')[0];
                        if (subscibeElement) {
                            subscibeElement?.focus();
                        }
                    }, 0);
                }
                if (res.success) {
                    setTimeout(() => {
                        const subscibeElement: any = document.getElementsByClassName('subscribeheader')[0];
                        if (subscibeElement) {
                            subscibeElement?.focus();
                        }
                    }, 0);
                }

                // emit subscribe success
                // this.subscribeSuccess.emit({ email: this.subscribeForm.value.email });
                if (this.subscribeInfo === 'JioMeetDownload') {
                    this.gTagService.sendEvent(GA_EVENTS.SUBSCRIBE_JIOMEET_DOWNLOAD);
                    this.gTagService.sendEvent(GA4_EVENTS.SUBSCRIBE_JIOMEET_DOWNLOAD);
                }
                if (this.subscribeInfo === 'HomeSubscribe') {
                    this.gTagService.pushOnDataLayer(GA_EVENTS.PRODUCT_SUBSCRIBE_COMMON);
                    this.gTagService.sendEvent(GA4_EVENTS.PRODUCT_SUBSCRIBE_COMMON);
                }
                if (this.subscribeInfo === 'contact-us') {
                    this.gTagService.sendEvent(GA4_EVENTS.CONTACT_US_SUBSCRIBE);
                }
            },
            (err) => {
                if (this.subscribeInfo === 'contact-us') {
                    this.gTagService.sendEvent(GA4_EVENTS.CONTACT_US_SUBSCRIBE_FAILED);
                }
                this.error =
                    err && err.error && err.error.message
                        ? err.error.message === 'REGISTRATION_LIMIT_EXCEEDED'
                            ? 'You have already subscribed.'
                            : err.error.message
                        : 'Something went wrong, please try again later.';
            }
        );
    }

    afterSubscription(data) {
        this.jiomeetSubscription = true;
        this.subscribedEmail = data.email;
        setTimeout(() => {
            this.jiomeetSubscription = false;
            this.subscribeForm.get('email').setValue('');
        }, 5000);
    }
}
