export const GA_EVENTS = {
    PREPARATORY_SCREEN_JOIN_MEETING_SETTINGS: {
        event: 'jiomeet',
        event_action: 'Settings',
        event_category: 'Join meeting preparatory screen',
        event_label: 'Success'
    },
    PREPARATORY_SCREEN_JOIN_MUTE: {
        event: 'jiomeet',
        event_action: 'Mute',
        event_category: 'Join meeting preparatory screen',
        event_label: 'Success'
    },
    PREPARATORY_SCREEN_JOIN_UNMUTE: {
        event: 'jiomeet',
        event_action: 'Unmute',
        event_category: 'Join meeting preparatory screen',
        event_label: 'Success'
    },
    PREPARATORY_SCREEN_UNMUTE_FAILURE: {
        event: 'jiomeet',
        event_action: 'Unmute',
        event_category: 'Join meeting preparatory screen',
        event_label: 'Failure'
    },
    PREPARATORY_SCREEN_JOIN_STOP_VIDEO: {
        event: 'jiomeet',
        event_action: 'Stop video',
        event_category: 'Join meeting preparatory screen',
        event_label: 'Success'
    },
    PREPARATORY_SCREEN_JOIN_START_VIDEO: {
        event: 'jiomeet',
        event_action: 'Start video',
        event_category: 'Join meeting preparatory screen',
        event_label: 'Success'
    },
    PREPARATORY_SCREEN_START_VIDEO_FAILURE: {
        event: 'jiomeet',
        event_action: 'Start video',
        event_category: 'Join meeting preparatory screen',
        event_label: 'Failure'
    },
    PREPARATORY_SCREEN_JOIN_VIRTUAL_BACKGROUND: {
        event: 'jiomeet',
        event_action: 'Virtual background',
        event_category: 'Join meeting preparatory screen',
        event_label: 'Success'
    },
    PREPARATORY_SCREEN_START_MEETING_SETTINGS: {
        event: 'jiomeet',
        event_action: 'settings',
        event_category: 'start_meeting_preparatory_screen',
        event_label: 'success'
    },
    PREPARATORY_SCREEN_START_MUTE: {
        event: 'jiomeet',
        event_action: 'mute',
        event_category: 'start_meeting_preparatory_screen',
        event_label: 'success'
    },
    PREPARATORY_SCREEN_START_UNMUTE: {
        event: 'jiomeet',
        event_action: 'unmute',
        event_category: 'start_meeting_preparatory_screen',
        event_label: 'success'
    },
    PREPARATORY_SCREEN_START_STOP_VIDEO: {
        event: 'jiomeet',
        event_action: 'stop_video',
        event_category: 'start_meeting_preparatory_screen',
        event_label: 'success'
    },
    PREPARATORY_SCREEN_START_START_VIDEO: {
        event: 'jiomeet',
        event_action: 'start_video',
        event_category: 'start_meeting_preparatory_screen',
        event_label: 'success'
    },
    PREPARATORY_SCREEN_START_VIRTUAL_BACKGROUND: {
        event: 'jiomeet',
        event_action: 'virtual_background',
        event_category: 'start_meeting_preparatory_screen',
        event_label: 'success'
    }
};
