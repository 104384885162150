export const GA4_EVENTS = {
    SIGNIN_WITH_EMAIL: {
        event: 'category_input',
        current_page: 'sign_in',
        clicked_text: 'email',
        outcome: 'succeeded'
    },
    SIGNIN_WITH_EMAIL_FAIL: {
        event: 'category_input',
        current_page: 'sign_in',
        clicked_text: 'email',
        outcome: 'failed'
    },
    SIGNIN_WITH_MOBILE: {
        event: 'category_input',
        current_page: 'sign_in',
        clicked_text: 'mobile',
        outcome: 'succeeded'
    },
    SIGNIN_WITH_MOBILE_FAIL: {
        event: 'category_input',
        current_page: 'sign_in',
        clicked_text: 'mobile',
        outcome: 'failed'
    },
    SIGNIN_WITH_FACEBOOK: {
        event: 'sign_in_with_facebook',
        current_page: 'sign_in',
        clicked_text: '',
        outcome: 'succeeded'
    },
    SIGNIN_WITH_GOOGLE: {
        event: 'sign_in_with_google',
        current_page: 'sign_in',
        clicked_text: '',
        outcome: 'succeeded'
    },
    SIGNUP_FROM_SIGNIN: {
        event: 'button_click',
        current_page: 'sign_in',
        clicked_text: 'sign_up_from_sign_in',
        outcome: 'succeeded'
    },
    SIGNIN_WITH_EMAIL_PASSWORD_PAGE: {
        event: 'sign_in_with_email',
        current_page: 'sign_in',
        clicked_text: 'verify_password',
        outcome: 'succeeded'
    },
    SIGNIN_WITH_EMAIL_PASSWORD_PAGE_FAILED: {
        event: 'sign_in_with_email',
        current_page: 'sign_in',
        clicked_text: 'verify_password',
        outcome: 'failed'
    },
    FORGOT_PASSWORD: {
        event: 'sign_in_with_email',
        current_page: 'sign_in',
        clicked_text: 'forgot_password',
        outcome: 'succeeded'
    },
    VERIFY_OTP: {
        event: 'sign_in_with_mobile',
        current_page: 'sign_in',
        clicked_text: 'verify_otp',
        outcome: 'succeeded'
    },
    VERIFY_OTP_FAILED: {
        event: 'sign_in_with_mobile',
        current_page: 'sign_in',
        clicked_text: 'verify_otp',
        outcome: 'failed'
    },
    RESEND_OTP: {
        event: 'sign_in_with_mobile',
        current_page: 'sign_in',
        clicked_text: 'resend_otp',
        outcome: 'succeeded'
    },
    SIGNIN_WITH_COMPANY_DOMAIN: {
        event: 'sign_in_with_company_domain',
        current_page: 'sign_in',
        clicked_text: 'sign_in',
        outcome: 'succeeded'
    }
};
