<div class="contact-us-component">
    <div class="container">
        <div class="layout">
            <div class="row">
                <div class="col-md-7 col-sm-12 header">
                    <div class="page-header">
                        <h1>{{ 'contact-us.head' | translate }}</h1>
                    </div>
                    <div class="header-info">
                        <p>
                            {{ 'contact-us.content' | translate }}
                        </p>
                    </div>
                </div>
                <div class="col-md-5 col-sm-12">
                    <div class="contactus-image">
                        <img class="img-responsive" src="assets/img/contactus.svg" alt="JioMeet ContactUs" />
                    </div>
                </div>
            </div>
            <div class="contacts">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <a href="{{ whatsAppBotURL }}" target="_blank" (click)="whatsappNumberClicked()">
                            <div class="whatsapp border-style hover-image">
                                <img
                                    class="img-responsive"
                                    src="assets/img/whatsapp.svg"
                                    alt="JioMeet Solution Overview"
                                />
                                <div>
                                    <span class="content">{{ 'contact-us.whatsapp' | translate }} </span> <br /><span
                                        class="number"
                                        >{{ 'contact-us.whatsapp_number' | translate }}
                                    </span>
                                </div>
                                <div class="hide-img"><img src="assets/img/right-active-arrow.svg" /></div>
                            </div>
                        </a>
                        <div class="chat border-style pointer hover-image" (click)="openChat()">
                            <img
                                class="img-responsive alignment"
                                src="assets/img/chat.svg"
                                alt="JioMeet Solution Overview"
                            />
                            <div class="align">
                                <span class="alignment content">{{ 'contact-us.chat' | translate }} </span>
                            </div>
                            <div class="hide-img"><img src="assets/img/right-active-arrow.svg" /></div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <a href="mailto:jiomeet.support@jio.com" (click)="emailClicked()">
                            <div class="email border-style pointer hover-image">
                                <img
                                    class="img-responsive"
                                    src="assets/img/email.svg"
                                    alt="JioMeet Solution Overview"
                                />
                                <div>
                                    <span class="content">{{ 'contact-us.email' | translate }} </span>
                                    <br /><span class="mail">{{ 'contact-us.email_id' | translate }} </span>
                                </div>
                                <div class="hide-img"><img src="assets/img/right-active-arrow.svg" /></div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="branch">
                <div class="page-header">
                    <h1>{{ 'contact-us.branches' | translate }}</h1>
                </div>
                <div class="show">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="map">
                                <img class="img-responsive" src="assets/img/map.png" alt="JioMeet Solution Overview" />
                            </div>
                            <div class="address">
                                <div class="address-slider" #addressSlider>
                                    <div class="slide" *ngFor="let addressInfo of address">
                                        <app-address-slider [addressInfo]="addressInfo"></app-address-slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hide">
                    <div class="row">
                        <div class="col-md-6 col-sm-12 address-desk-slider">
                            <div class="address-faded">
                                <div class="address-container" *ngFor="let addressInfo of address">
                                    <app-address-slider [addressInfo]="addressInfo"></app-address-slider>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <div class="map">
                                <img class="img-responsive" src="assets/img/map.png" alt="JioMeet Solution Overview" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="branch resource">
                <div class="page-header">
                    <h1>{{ 'contact-us.resources' | translate }}</h1>
                </div>
                <div class="cards">
                    <div *ngFor="let solutionInfo of solutions" class="slide">
                        <app-website-solution
                            [solutionInfo]="solutionInfo"
                            (click)="handleGoTo(solutionInfo)"
                        ></app-website-solution>
                    </div>
                </div>
            </div>
            <div class="subscribe">
                <app-jiomeet-subscribe subscribeInfo="contact-us"></app-jiomeet-subscribe>
            </div>
        </div>
    </div>
</div>
