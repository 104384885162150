import { participantsRelated } from './../../conference/components/participants-related/index';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';

import { AuthService } from './auth.service';
import { LoggerService } from './logger.service';
import { AppService } from './app.service';
import { EventEmitterService } from './event-emitter.service';
import { RC_EVENTS } from './../../constants';
import { LocalStorageService } from './local-storage.service';
import { ThirdPartyExternalIntegrationService } from './third-party-external-integration.service';

import { APP_EVENTS } from '../../constants';

declare const window: any;
declare const webkit: any;

@Injectable({
    providedIn: 'root'
})
export class ExternalInterfaceService {
    public subject$: Subject<any> = new Subject();
    public isEmbibe = false;
    public askPermission = true;
    public cameraLabel = '';
    public micLabel = '';
    public speakerLabel = '';
    openIframeInfo = {
        isEnabled: false,
        url: '',
        hideParticipantsEnabled: false,
        presenterViewEnabled: false,
        presenterData: null
    };
    mediaVolume = 0;
    watchpartyVolume = 0;
    hideAudience = false;
    isWatchPartyHost = false;
    hideParticipantsCount = false;

    constructor(
        private zone: NgZone,
        private router: Router,
        private authService: AuthService,
        private logger: LoggerService,
        private eventEmitterService: EventEmitterService,
        private localStorageService: LocalStorageService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        private appService: AppService
    ) {}

    share(data) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'share',
                    desc: data
                })
            )
        );
    }

    setMediaVolume(level: number, type: 'volume_media' | 'volume_watchparty') {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type,
                    level
                })
            )
        );
    }

    getHideParticipantsCount() {
        return this.hideParticipantsCount;
    }

    setHideParticipantsCount(bool: boolean) {
        this.hideParticipantsCount = bool;
    }

    getHideAudience() {
        return this.hideAudience;
    }

    setHideAudience(bool: boolean) {
        this.hideAudience = bool;
    }

    setIsWatchPartyHost(isHost: boolean) {
        this.isWatchPartyHost = isHost;
    }

    getIsWatchPartyHost() {
        return this.isWatchPartyHost;
    }

    help(data) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'help',
                    desc: data
                })
            )
        );
    }

    playSound(audioObj) {
        this.stopSound();
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'playinternalsound',
                    path: audioObj.path || null,
                    loop: audioObj.loop || false,
                    name: audioObj.name || null
                })
            )
        );
    }

    stopSound() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'stopsound'
                })
            )
        );
    }

    launchBrowser(url) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'launchbrowser',
                    value: url
                })
            )
        );
    }

    sendLoadComplete() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'loadingCompleted'
                })
            )
        );
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage('loadingCompleted', '*');
        }
    }

    close() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'close'
                })
            )
        );
    }

    closeTab() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'close-tab'
                })
            )
        );
    }

    clickOnParticipantPanel() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'clickOnParticipantPanel'
                })
            )
        );
    }

    hideHeader() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'hideHeader'
                })
            )
        );
    }

    showHeader() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'showHeader'
                })
            )
        );
    }

    installJiomeet() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'installOrOpenJiomeet'
                })
            )
        );
    }

    speakerSelection(speakerType) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: speakerType
                })
            )
        );
    }

    requestJWT() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'jwt'
                })
            )
        );
    }

    requestMeetingDetails() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'meetingdetails'
                })
            )
        );
    }

    sendMoveToWR() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'sendmovetowr'
                })
            )
        );
    }

    requestMicrophone() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'microphone',
                    config: {
                        type: 'speech',
                        language: 'en'
                    }
                })
            )
        );
    }

    makeCall(value) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'makeCall',
                    value
                })
            )
        );
    }

    requestScreenshot() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'screenshot'
                })
            )
        );
    }

    requestForUserPermission(permission) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'requestForUserPermission',
                    value: permission
                })
            )
        );
    }

    requestAdParams() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'adparams'
                })
            )
        );
    }

    getNativeVersion(permission) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'getNativeVersion'
                })
            )
        );
    }

    shareReports() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'shareReports'
                })
            )
        );
    }

    showMyReports() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'showMyReports'
                })
            )
        );
    }

    showMyDetails() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'showMyDetails'
                })
            )
        );
    }

    showParticipants() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'showParticipants'
                })
            )
        );
    }

    requestForCameraAndMicrophone() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'requestForCameraAndMicrophone'
                })
            )
        );
    }

    requestForCreateWatchParty() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'requestForCreateWatchParty'
                })
            )
        );
    }

    sendEventToRefreshJWT() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'refreshJWT'
                })
            )
        );
    }

    sendVolumeDetails(data) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'userVolumeStatus',
                    data
                })
            )
        );
    }

    sendMeetingIdPin(data) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'meetingIdPin',
                    data
                })
            )
        );
    }

    sendWatchPartyLeaveUpdate(data) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'wpEnded',
                    data
                })
            )
        );
    }

    sendWatchPartyJoinUpdate(data) {
        console.log('watch party joined event triggered');
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'wpJoined',
                    data
                })
            )
        );
    }

    sendHideLoader(data) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'hideLoader',
                    data
                })
            )
        );
    }

    private externalCall(data) {
        try {
            if (window.chrome?.webview) {
                // windows
                window.chrome?.webview?.postMessage(data);
            } else {
                if (window.android && window.android.__externalCall) {
                    // android
                    window.android.__externalCall(data);
                }
                if (window.__externalCall) {
                    // iOS
                    window.__externalCall(data);
                }
                // Commenting it due to more logs hitting everytime, Getting error - webkit is not defined, Suspecting this is not required anymore
                // webkit.messageHandlers.callback.postMessage(data);
            }
        } catch (e) {
            this.logger.error('external call failed', e);
        }
    }

    sendNativeBackControl(data) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'handleWebviewBack',
                    value: data
                })
            )
        );
    }

    endCallFunctionality() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'endmeeting'
                })
            )
        );
        window.onCallEnd();
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage('callEnded', '*');
        }
    }

    sendCallError(err) {
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage(
                {
                    type: 'joinFailed',
                    value: err
                },
                '*'
            );
        }
    }

    sendCallEnd() {
        const isThirdPartyExternalIntegration =
            this.thirdPartyExternalIntegrationService.getThirdPartyExternalIntegration();
        const isCallEndSent = this.thirdPartyExternalIntegrationService.getIsCallEndSent();
        // Ensure that send call event is sent once
        if (!isThirdPartyExternalIntegration) {
            this.endCallFunctionality();
        } else {
            if (!isCallEndSent) {
                this.endCallFunctionality();
            } else {
                this.thirdPartyExternalIntegrationService.setIsCallEndSent();
            }
        }
    }

    sendParticipantListUpdatedEvent() {
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage('participantListUpdated', '*');
        }
    }

    sendUserStatusEvent(userStatus: 'joined' | 'left') {
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage({ type: 'userupdate', data: userStatus }, '*');
        }
    }

    sendOnlyOneCameraAvailableError() {
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage('onlyOneCameraAvailable', '*');
        }
    }

    sendMicStatus(micStatus) {
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage(JSON.stringify({ micStatus }), '*');
        }
    }

    sendCameraStatus(cameraStatus) {
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage(JSON.stringify({ cameraStatus }), '*');
        }
    }

    sendHandRaiseStatus(handRaiseStatus) {
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage(JSON.stringify({ handRaiseStatus }), '*');
        }
    }

    sendScreenShotURI(dataURI) {
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage({ type: 'screenshot', data: dataURI }, '*');
        }
    }
    sendSwitchCameraLog(message) {
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage({ data: 'switchCamera', message: message }, '*');
        }
    }

    sendCameraFacingMode(mode) {
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage({ type: 'cameraMode', data: mode }, '*');
        }
    }

    sendScreenShareStatus(screenShareStatus) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'screensharestatus',
                    data: screenShareStatus
                })
            )
        );
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage(JSON.stringify({ screenShareStatus }), '*');
        }
    }

    sendHardMuteAudiostatus(isForceMute) {
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage(JSON.stringify({ isForceMute }), '*');
        }
    }

    sendErrorOccuredOnJoined(errorType, error) {
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage('errorOccurred', '*');

            window.parent.postMessage(JSON.stringify({ errorType, error }), '*');
        }
    }

    sendLocalParticipantUserId(jiomeetUserId) {
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage(JSON.stringify({ jiomeetUserId }), '*');
        }
    }

    sendChatMessageRecieved() {
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage('chatMessageRecieved', '*');
        }
    }

    sendCallEndWatchParty(meetingId, password) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'endmeeting',
                    data: { meetingId: meetingId, password: password }
                })
            )
        );
        window.onCallEnd();
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage('callEnded', '*');
        }
    }

    sendCallStarted(userId = {}) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'callstarted'
                })
            )
        );
        window.onCallJoined();
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage('callStarted', '*');
            if (userId) {
                window.parent.postMessage(
                    JSON.stringify({
                        type: 'userIdJoined',
                        userId: userId
                    }),
                    '*'
                );
            }
        }
    }

    sendInvite(meetingId, password) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'invite',
                    data: { meetingId: meetingId, password: password }
                })
            )
        );
    }

    cycleNativeSpeaker() {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'cyclespeaker'
                })
            )
        );
    }

    toggleFullScreen(enable = false) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'togglefullscreen',
                    value: enable
                })
            )
        );
    }

    sendHistoryId(id) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'sendHistoryId',
                    value: id
                })
            )
        );
    }

    bindEvent(element, eventName, eventHandler) {
        if (element.addEventListener) {
            element.addEventListener(eventName, eventHandler, false);
        } else if (element.attachEvent) {
            element.attachEvent('on' + eventName, eventHandler);
        }
    }

    sendUserStatus(isAudience) {
        this.externalCall(
            btoa(
                JSON.stringify({
                    type: 'userStatus',
                    status: isAudience ? 'audience' : 'speaker'
                })
            )
        );
        if (window.parent && window.parent.postMessage) {
            window.parent.postMessage(
                JSON.stringify({
                    type: 'userStatus',
                    status: isAudience ? 'audience' : 'speaker'
                }),
                '*'
            );
        }
    }

    public setupCallbacksFromNative() {
        window.windowsTest = () => {
            this.externalCall(
                btoa(
                    JSON.stringify({
                        type: 'windowsTest'
                    })
                )
            );
        };

        window.sendJwt = (jwt) => {
            this.zone.run(() => {
                this.authService.setAuthInfo({ jwt });
                this.router.navigate(['/']);
            });
        };

        window.sendWatchPartyVolume = (type = 'volume_watchparty', level) => {
            this.zone.run(() => {
                this.watchpartyVolume = Number(level);
            });
        };

        window.sendMediaVolume = (type = 'volume_media', level) => {
            this.zone.run(() => {
                this.mediaVolume = Number(level);
            });
        };

        window.getWpDeepLink = (deeplink) => {
            this.eventEmitterService.emit({
                type: 'DEEPLINK_WP',
                data: JSON.parse(deeplink)
            });
        };

        window.leaveWpSession = () => {
            this.eventEmitterService.emit({
                type: 'LEAVE_WP',
                data: null
            });
        };

        window.hhMeetingDetails = (data) => {
            //alert(data);
            // take "phoneNo", "historyId" and "token"
            // send token for login
            // then historyId and phoneNo to API call
            this.eventEmitterService.emit({
                type: 'JOIN_WITH_HISTORY_ID',
                data: JSON.parse(data)
            });
        };

        window.joinMeetingWithMeetingId = (data) => {
            this.eventEmitterService.emit({
                type: 'JOIN_WITH_MEETING_ID',
                data: JSON.parse(data)
            });
        };

        window.permissionGranted = () => {
            this.eventEmitterService.emit({
                type: 'USER_PERMISSION_GRANTED',
                data: {}
            });
        };

        window.printAgoraDetails = () => {
            this.eventEmitterService.emit({
                type: 'PRINT_AGORA_DETAIL',
                data: {}
            });
        };

        window.toggleCameraAndAudioPrivacyForJanus = () => {
            this.eventEmitterService.emit({
                type: 'JANUS_TOGGLE_CAMERA_AND_AUDIO_PRIVACY',
                data: {}
            });
        };

        window.sendJwtWebinar = (jwt) => {
            this.authService.setAuthInfo(JSON.parse(jwt));
            this.eventEmitterService.emit({
                type: 'WEBINAR_JWT_UPDATED',
                data: {}
            });
        };
        window.leaveWebinar = () => {
            this.eventEmitterService.emit({
                type: 'LEAVE_WEBINAR',
                data: {}
            });
        };

        window.endOrLeaveMeeting = (data) => {
            data = JSON.parse(data);
            let details = { skipPopup: false };
            if (data?.skipPopup === 'true') {
                details.skipPopup = true;
            }
            let endOrLeave = data?.type || 'LEAVE_MEETING';
            this.eventEmitterService.emit({
                type: endOrLeave,
                data: details
            });
        };

        window.reloadPage = () => {
            this.appService.reloadWebContent();
        };

        window.receiveNativeNavigation = (data) => {
            const { navigationType } = JSON.parse(data);
            switch (navigationType) {
                case 'FORWARD': {
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.NATIVE_FORWARD_BUTTON,
                        data: JSON.parse(data)
                    });
                    break;
                }
                default: {
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.NATIVE_BACK_BUTTON,
                        data: JSON.parse(data)
                    });
                }
            }
        };

        window.receiveAppInBackground = (data) => {
            this.eventEmitterService.emit({
                type: APP_EVENTS.APP_IN_BACKGROUND,
                data: JSON.parse(data)
            });
        };

        window.sendJwtWithoutNavigation = (data) => {
            let payload = data;
            if (typeof data === 'string') payload = JSON.parse(data);
            this.eventEmitterService.emit({
                type: APP_EVENTS.JWT_TOKEN,
                data: payload
            });
        };

        window.sendMeetingDetails = (data) => {
            let payload = data;
            if (typeof data === 'string') payload = JSON.parse(data);
            this.eventEmitterService.emit({
                type: APP_EVENTS.CLIENT_MEETING_DETAILS,
                data: payload
            });
        };

        window.takeScreenShot = (data) => {
            let payload = data;
            if (typeof data === 'string') payload = JSON.parse(data);
            this.eventEmitterService.emit({
                type: APP_EVENTS.TAKE_SCREENSHOT,
                data: payload
            });
        };

        window.setRemoteAudio = (data) => {
            let payload = JSON.parse(data);
            this.eventEmitterService.emit({
                type: APP_EVENTS.SET_REMOTE_AUDIO,
                data: payload
            });
        };

        window.redirectToUrl = (data) => {
            let payload = data;
            if (typeof data === 'string') {
                payload = JSON.parse(data);
                this.zone.run(() => {
                    this.router.navigate([payload?.url]);
                    // this.appService.reloadWebContent();
                });
            }
        };

        window.activePipMode = (data) => {
            let payload = data;
            if (typeof data === 'string') payload = JSON.parse(data);
            this.eventEmitterService.emit({
                type: APP_EVENTS.ACTIVE_PIP_MODE,
                data: payload
            });
        };

        window.ADSDKEXT = {};
        window.ADSDKEXT.click_handler = (url) => this.launchBrowser(url);
        window.onCallEnd = () => {};
        window.onCallJoined = () => {};
        window.sendRCEvent = (event) => this.handleRCEvents(event);

        this.bindEvent(window, 'message', (e) => {
            let data: any = {};
            try {
                data = typeof e.data === 'string' ? JSON.parse(e.data) : e.data;
            } catch (error) {
                console.log('[NON-JSON]data received over postMessage ', e.data);
            }

            if (this.handleRCMessage(data)) {
                return;
            }

            if (data.type === 'SEND_JWT') {
                this.authService.setAuthInfo({ jwt: data.jwt });
            } else if (data.type === 'JOIN_WITH_MEETING_ID') {
                this.eventEmitterService.emit({
                    type: 'JOIN_WITH_MEETING_ID',
                    data: data
                });
            } else if (data.type === 'lockMeeting') {
                this.eventEmitterService.emit({
                    type: 'TOGGLE_LOCK',
                    data: {}
                });
            } else if (data.type === 'toggleAudio') {
                this.eventEmitterService.emit({
                    type: 'TOGGLE_AUDIO',
                    data: {}
                });
            } else if (data.type === 'toggleVideo') {
                this.eventEmitterService.emit({
                    type: 'TOGGLE_VIDEO',
                    data: {}
                });
            } else if (data.type === 'takeScreenShot') {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.TAKE_SCREENSHOT,
                    data: {}
                });
            } else if (data.type === 'captureLocalFeed') {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.CAPTURE_LOCAL_VIDEO_FEED,
                    data: {}
                });
            } else if (data.type === 'showSelfView') {
                this.eventEmitterService.emit({
                    type: 'SHOW_SELF_VIEW',
                    data: data.show
                });
            } else if (data.type === 'toggleParticipantList') {
                this.eventEmitterService.emit({
                    type: 'TOGGLE_PARTICIPANT_LIST',
                    data: {}
                });
            } else if (data.type === 'toggleChat') {
                this.eventEmitterService.emit({
                    type: 'TOGGLE_CHAT',
                    data: {}
                });
            } else if (data.type === 'startScreenShare') {
                this.eventEmitterService.emit({
                    type: 'START_SCREEN_SHARE',
                    data: {}
                });
            } else if (data.type === 'startWhiteboardShare') {
                this.eventEmitterService.emit({
                    type: 'START_WHITEBOARD_SHARE',
                    data: {}
                });
            } else if (data.type === 'stopSharing') {
                this.eventEmitterService.emit({
                    type: 'STOP_SHARING',
                    data: {}
                });
            } else if (data.type === 'toggleHand') {
                this.eventEmitterService.emit({
                    type: 'TOGGLE_HAND',
                    data: {}
                });
            } else if (data.type === 'leaveMeeting') {
                let details = { skipPopup: false };
                if (data?.data?.skipPopup) {
                    details.skipPopup = true;
                }
                this.eventEmitterService.emit({
                    type: 'LEAVE_MEETING',
                    data: details
                });
            } else if (data.type === 'endMeeting') {
                let details = { skipPopup: false };
                if (data?.data?.skipPopup) {
                    details.skipPopup = true;
                }
                this.eventEmitterService.emit({
                    type: 'END_MEETING',
                    data: details
                });
            } else if (data.type === 'makeSpeaker') {
                this.eventEmitterService.emit({
                    type: 'MAKE_SPEAKER',
                    data: data.participant
                });
            } else if (data.type === 'makeAudience') {
                this.eventEmitterService.emit({
                    type: 'MAKE_AUDIENCE',
                    data: data.participant
                });
            } else if (data.type === 'enablePresenterLayout') {
                this.openIframeInfo = {
                    ...this.openIframeInfo,
                    presenterViewEnabled: true,
                    presenterData: data?.participant
                };
                this.eventEmitterService.emit({
                    type: 'REQUEST_PRESENTER_LAYOUT',
                    data: data?.participant
                });
            } else if (data.type === 'changePresenter') {
                this.eventEmitterService.emit({
                    type: 'CHANGE_PRESENTER',
                    data: data?.participant
                });
            } else if (data.type === 'disablePresenterLayout') {
                this.openIframeInfo = {
                    isEnabled: false,
                    url: '',
                    hideParticipantsEnabled: false,
                    presenterViewEnabled: false,
                    presenterData: null
                };
                this.eventEmitterService.emit({
                    type: 'DISABLE_PRESENTER_LAYOUT',
                    data: data?.participant
                });
            } else if (data.type === 'openIframe') {
                this.openIframeInfo = { ...this.openIframeInfo, isEnabled: true, url: data.url };
                this.eventEmitterService.emit({
                    type: 'OPEN_IFRAME',
                    data: data.url
                });
            } else if (data.type === 'closeIframe') {
                this.openIframeInfo = {
                    isEnabled: false,
                    url: '',
                    hideParticipantsEnabled: false,
                    presenterViewEnabled: false,
                    presenterData: null
                };
                this.eventEmitterService.emit({
                    type: 'CLOSE_IFRAME',
                    data: data.url
                });
            } else if (data.type === 'toggleParticipantsView') {
                this.eventEmitterService.emit({
                    type: 'TOGGLE_PARTICIPANT_VIEW',
                    data: data.show
                });
            } else if (data.type === 'openIframeHideParticipant') {
                this.openIframeInfo = {
                    ...this.openIframeInfo,
                    isEnabled: true,
                    url: data.url,
                    hideParticipantsEnabled: true
                };
                this.eventEmitterService.emit({
                    type: 'OPENIFRAMEHIDEPARTICIPANT',
                    data: data.url
                });
            } else if (data.type === 'requestLocalParticipantData') {
                this.eventEmitterService.emit({
                    type: 'REQUEST_LOCAL_PARTICIPANT_DATA',
                    data: {}
                });
            } else if (data.type === 'requestAudioVideoPermission') {
                this.eventEmitterService.emit({
                    type: 'REQUEST_AUDIO_VIDEO_PERMISSION',
                    data: {}
                });
            } else if (data.type === 'flipCamera') {
                this.eventEmitterService.emit({
                    type: 'FLIP_CAMERA',
                    data: data?.togglePreviousCamera ? true : false
                });
            } else if (data.type === 'switchCamera') {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.SWITCH_CAMERA,
                    data: ''
                });
            } else if (data.type === 'switchToFrontCamera') {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.SWITCH_FRONT_CAMERA,
                    data: ''
                });
            } else if (data.type === 'switchToBackCamera') {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.SWITCH_BACK_CAMERA,
                    data: ''
                });
            } else if (data.type === 'openVirtualBgSetting') {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.ACTIVATE_ROOM_SETTINGS,
                    data: {
                        setting: 'background_only'
                    }
                });
            } else if (data.type === 'toggleSelfView') {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.TOGGLE_SELF_VIEW,
                    data: {}
                });
            }
        });

        const isOutlookOrTeamsOrWB =
            window.location.href.includes('/outlook') ||
            window.location.href.includes('/mst') ||
            window.location.href.includes('/whiteboard');
        if (!isOutlookOrTeamsOrWB) {
            if (window.location !== window.parent.location) {
                //clear user info from local storage if it is inside an iframe
                window.onbeforeunload = () => {
                    this.localStorageService.removeItem('userInfo');
                };
            }
        }

        if (window.chrome?.webview) {
            this.bindEvent(window.chrome?.webview, 'message', (e) => {
                this.handleRCEvents(e.data);
            });
        }
    }

    private handleRCMessage(data) {
        // check for message types and return true if handled
        if (
            [
                'unread-changed-by-subscription',
                'unread-changed',
                'startup',
                'status-changed',
                'Custom_Script_Logged_In'
            ].includes(data.eventName)
        ) {
            return true;
        }
        return false;
    }

    private handleRCEvents(e) {
        let data: any = {};
        try {
            data = typeof e === 'string' ? JSON.parse(e) : e;
        } catch (error) {
            console.log('[NON-JSON]data received over native bridge ', e.data);
        }

        switch (data.type) {
            case RC_EVENTS.RC_INIT_CONFIG: // { type: 'RC_INIT_CONFIG', data: { jwt, embedded, threadId } }
                this.authService.setAuthInfo({ jwt: data.data?.jwt });
                this.eventEmitterService.emit({
                    type: RC_EVENTS.RC_INIT_CONFIG,
                    data: data.data
                });
                break;
        }
    }
}
