<div class="website-usecases" id="website-top">
    <section class="section-top" [class.padding-h-16]="isMobile">
        <div
            class="left-content First-Element-Focus"
            tabindex="0"
            aria-label="The JioMeet for Hybrid Woekplace Page has been opened! 
             To navigate through the page, please utilize the tab key on your keyboard"
        >
            <div class="subheading-1" tabindex="0">
                {{ 'website_hybrid_workspace.top-title-0' | translate }}
            </div>
            <div class="heading" tabindex="0">
                {{ 'website_hybrid_workspace.top-title-1' | translate }}
            </div>
            <div class="desc subheading-0" tabindex="0">
                {{ 'website_hybrid_workspace.top-desc' | translate }}
            </div>
            <div class="action-btns">
                <button
                    class="custom-action-btn"
                    (click)="navigateToSignUp()"
                    (keydown.enter)="navigateToSignUp()"
                    tabindex="0"
                >
                    {{ 'website_hybrid_workspace.start_free' | translate }}
                </button>
                <button
                    class="custom-action-btn secondary"
                    (click)="goToDownloadApp()"
                    (keydown.enter)="goToDownloadApp()"
                    tabindex="0"
                >
                    {{ 'website_hybrid_workspace.download' | translate }}
                </button>
            </div>
        </div>
        <div class="right-content">
            <img
                src="assets/img/hybridWorkplace/hybridWorkplace-top.png"
                *ngIf="!isMobile"
                alt="Collaboration of people enjoying hybrid workplace"
            />
            <img src="assets/img/hybridWorkplace/hybridWorkplace-top-mobile.png" *ngIf="isMobile" alt="" />
        </div>
    </section>

    <div *ngIf="!isMobile">
        <section class="sec-1 sec">
            <div class="img">
                <img [src]="hybridWorkplaceFeatures[0].imgDesktop" [alt]="hybridWorkplaceFeatures[0].altText" />
            </div>
            <div class="sec-info padding-h-140-150">
                <div class="heading-0" tabindex="0" [class.padding-h-44]="featureIndex === 0">
                    {{ hybridWorkplaceFeatures[0].ttitle | translate }}
                </div>
            </div>
            <div class="sec-info padding-h-140-150 margin-top-0" [ngStyle]="{ 'align-items': 'start' }">
                <div>
                    <p class="subheading-2" tabindex="0">
                        {{ hybridWorkplaceFeatures[0].desc[0].ttext | translate }}
                    </p>
                </div>
                <div>
                    <p class="subheading-2" tabindex="0">
                        {{ hybridWorkplaceFeatures[0].desc[1].ttext | translate }}
                    </p>
                </div>
            </div>
        </section>

        <section class="sec-2 sec padding-h-140-150">
            <app-usecase-features
                [useCaseFeature]="hybridWorkplaceFeatures[1]"
                [featureIndex]="1"
            ></app-usecase-features>
        </section>

        <section class="sec-3 sec padding-h-140-150">
            <app-usecase-features
                [useCaseFeature]="hybridWorkplaceFeatures[2]"
                [featureIndex]="2"
            ></app-usecase-features>
        </section>

        <section class="sec-4 sec padding-h-140-150 hybrid-workplace">
            <div class="img-sec">
                <img [src]="hybridWorkplaceFeatures[3].imgDesktop" alt="" />
            </div>
            <div class="sec-info">
                <div class="heading-0" tabindex="0" [class.padding-h-44]="featureIndex === 0">
                    {{ hybridWorkplaceFeatures[3].ttitle | translate }}
                </div>
                <p class="subheading-2" tabindex="0">
                    {{ hybridWorkplaceFeatures[3].desc[0].ttext | translate }}
                </p>
                <p class="subheading-2" tabindex="0">
                    {{ hybridWorkplaceFeatures[3].desc[1].ttext | translate }}
                </p>
            </div>
        </section>

        <section class="sec-1 sec">
            <div class="img">
                <img [src]="hybridWorkplaceFeatures[4].imgDesktop" alt="" />
            </div>
            <div class="sec-info padding-h-140-150">
                <div class="heading-0" tabindex="0" [class.padding-h-44]="featureIndex === 0">
                    {{ hybridWorkplaceFeatures[4].ttitle | translate }}
                </div>
            </div>
            <div class="sec-info padding-h-140-150 margin-top-0" [ngStyle]="{ 'align-items': 'start' }">
                <div>
                    <p class="subheading-2" tabindex="0">
                        {{ hybridWorkplaceFeatures[4].desc[0].ttext | translate }}
                    </p>
                </div>
                <div>
                    <p class="subheading-2" tabindex="0">
                        {{ hybridWorkplaceFeatures[4].desc[1].ttext | translate }}
                    </p>
                </div>
            </div>
        </section>

        <section class="sec-6 sec padding-h-140-150">
            <app-usecase-features
                [useCaseFeature]="hybridWorkplaceFeatures[5]"
                [featureIndex]="5"
            ></app-usecase-features>
        </section>

        <section class="sec-7 sec padding-h-140-150">
            <app-usecase-features
                [useCaseFeature]="hybridWorkplaceFeatures[6]"
                [featureIndex]="6"
            ></app-usecase-features>
        </section>
    </div>
    <ng-container *ngIf="isMobile">
        <section *ngFor="let feature of hybridWorkplaceFeatures; let i = index" class="sec">
            <app-usecase-features [useCaseFeature]="feature" [featureIndex]="i"></app-usecase-features>
        </section>
    </ng-container>

    <section class="got-questions" [class.padding-h-44]="isMobile">
        <app-explore-meet
            bottomButtons="hybrid-workplace"
            title="{{ 'website_hybrid_workspace.explore_title' | translate }}"
            desc="{{ 'website_hybrid_workspace.explore_desc' | translate }}"
            btnText1="{{ 'website_hybrid_workspace.start_free' | translate }}"
            btnText2="{{ 'website_hybrid_workspace.download_now' | translate }}"
            btnText1Link="/signup"
            btnText2Link="/jiomeetDownload"
        ></app-explore-meet>
    </section>
</div>
