import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Inject, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';
import { HotkeyModule } from 'angular2-hotkeys';
import {
    CoreModule,
    DesktopAppApiInterceptor,
    TokenInterceptor,
    ErrorInterceptor,
    AppService,
    RestService,
    errorHandler,
    LoggerService
} from './core';
import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApmService } from '@elastic/apm-rum-angular';

import * as fromComponents from './components';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { MenuAccordionComponent } from './components/website/menu-accordion/menu-accordion.component';
import { AddressSliderComponent } from './components/contact-us/address-slider/address-slider.component';
import { AppLoggerService } from './core/services/app-logger.service';
import { GoogleTagManagerService } from './core/services/google-tag-manager.service';
import { DownloadIcsfileComponent } from './components/download-icsfile/download-icsfile.component';
import { MattermostComponent } from './components/mattermost/mattermost.component';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { CallDialerComponent } from './components/call-dialer/call-dialer.component';
import { ScreenCaptureSelectionComponent } from './components/screen-capture-selection/screen-capture-selection.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ProfileMenuOptionsComponent } from './components/website/profile-menu-options/profile-menu-options.component';
import { ProgressTrackerComponent } from './pre-call-testing/components/progress-tracker/progress-tracker.component';
import { CtaButtonComponent } from './pre-call-testing/components/cta-button/cta-button.component';
import { RecorderBotComponent } from './components/recorder-bot/recorder-bot.component';

export function loadConfig(
    appService: AppService,
    restService: RestService,
    logService: AppLoggerService,
    gTagManagerService: GoogleTagManagerService
) {
    return () =>
        restService
            .get(`api/config/v1?os_type=webrtc`)
            .toPromise()
            .then((config) => {
                appService.setConfig(config);
                logService.initialize();
                gTagManagerService.initialize(config);
            });
}
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        MattermostComponent,
        AppComponent,
        ...fromComponents.components,
        MenuAccordionComponent,
        AddressSliderComponent,
        DownloadIcsfileComponent,
        ThankyouComponent,
        CallDialerComponent,
        ScreenCaptureSelectionComponent,
        ProfileMenuOptionsComponent,
        CtaButtonComponent,
        RecorderBotComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        SharedModule,
        AppRoutingModule,
        HotkeyModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: true,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],

    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DesktopAppApiInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfig,
            multi: true,
            deps: [AppService, RestService, AppLoggerService, GoogleTagManagerService]
        },
        {
            provide: ApmService,
            useClass: ApmService,
            deps: [Router]
        },
        { provide: ErrorHandler, useClass: errorHandler }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
