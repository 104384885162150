export const environment = {
    production: true,
    local: false,
    HOST_URL: 'https://jiomeetstage.jiomeet.com/',
    BASE_URL: 'https://jiomeetstage.jiomeet.com/api',
    EVENTS_URL: 'https://stage1.jioevents.com/',
    JIO_CLOUD: {
        HOST: 'https://sit-apis.tejdrive.com',
        HOST_UPLOAD: 'https://sit-upload.tejdrive.com'
    },
    PAYMENT_GATEWAY_URL: 'https://www.techjockey.com/ordernow/jiomeet'
};
