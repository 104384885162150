import { Directive, Input, AfterViewInit, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { UtilService } from 'src/app/core';
import { ProfilePhotoService } from 'src/app/core/services/profile-photo.service';

@Directive({
    selector: '[appAvatarWithInitials]'
})
export class AvatarWithInitialsDirective implements AfterViewInit, OnChanges {
    @Input() style = 'rectangle';
    @Input() name = '';
    colors = {
        a: '#F38282',
        b: '#DEB018',
        c: '#79CB09',
        d: '#41C6AF',
        e: '#FA47AD',
        f: '#8395FC',
        g: '#039BE5',
        h: '#F38282',
        i: '#DEB018',
        j: '#79CB09',
        k: '#41C6AF',
        l: '#FA47AD',
        m: '#8395FC',
        n: '#039BE5',
        o: '#F38282',
        p: '#DEB018',
        q: '#79CB09',
        r: '#41C6AF',
        s: '#FA47AD',
        t: '#8395FC',
        u: '#039BE5',
        v: '#F38282',
        w: '#DEB018',
        x: '#79CB09',
        y: '#41C6AF',
        z: '#FA47AD'
    };

    constructor(private elRef: ElementRef, private profilePhotoService: ProfilePhotoService) {}

    ngAfterViewInit() {
        this.createAvatar();
    }

    createAvatar() {
        if (!this.name) {
            return;
        }

        let result = this.profilePhotoService.getAvatarInitials(this.name);
        this.elRef.nativeElement.innerHTML = result;
        this.elRef.nativeElement.style.backgroundColor = this.profilePhotoService.getColorForInitials(this.name.trim());
        this.elRef.nativeElement.style.textTransform = 'uppercase';
        this.elRef.nativeElement.style.color = 'rgba(0, 0, 0, 0.59)';
        // this.elRef.nativeElement.style.fontSize = '1rem';
        this.elRef.nativeElement.style.fontFamily = 'JioMedium';
        this.elRef.nativeElement.style.borderRadius = this.style === 'circle' ? '50%' : '3px';
        this.elRef.nativeElement.style.height = '100%';
        this.elRef.nativeElement.style.width = '100%';
        this.elRef.nativeElement.style.textAlign = 'center';
        this.elRef.nativeElement.style.display = 'flex';
        this.elRef.nativeElement.style.alignItems = 'center';
        this.elRef.nativeElement.style.justifyContent = 'center';
    }

    ngOnChanges(changes: SimpleChanges) {
        this.name = changes.name.currentValue;
        this.createAvatar();
    }
}
