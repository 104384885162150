import { Directive, ViewContainerRef, TemplateRef, Input } from '@angular/core';

import { UserService, AppService } from 'src/app/core';

@Directive({
    selector: '[hasPermission]'
})
export class HasPermissionDirective {
    private permissions = [];

    constructor(
        private appService: AppService,
        private userService: UserService,
        private viewContainer: ViewContainerRef,
        private templateRef: TemplateRef<any>
    ) {}

    private updateView() {
        this.userService.getUser().subscribe((user) => {
            if (this.checkPermission(user)) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
        });
    }

    @Input()
    set hasPermission(val) {
        this.permissions = val;
        this.updateView();
    }

    private checkPermission(user) {
        if (this.appService.getConfigVariable('BYPASS_PERMISSIONS_CHECK')) {
            return true;
        }
        if (!this.permissions.length) {
            return true;
        }

        if (this.userService.getGuestUser()) {
            return false;
        }

        const hasPermission = false;
        if (user && user.roles.length) {
            const userRoles = user.roles.map((role) => role.name);
            return this.permissions.some((permission) => this.existPermission(userRoles, permission));
        }

        return hasPermission;
    }

    existPermission(userRoles, permission): boolean {
        // assuming single role, change incase of multi role in future
        return userRoles.find((x) => x.toUpperCase() === permission.toUpperCase());
    }
}
