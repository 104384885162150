import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { RestService } from './rest.service';
import { SystemService } from './system.service';

import * as _ from 'lodash-es';

@Injectable({
    providedIn: 'root'
})
export class MeetingService {
    private scheduleMeetingDetails = null;
    private datePipe = new DatePipe('en');

    constructor(private restService: RestService, private systemService: SystemService) {}

    getDashboardMeetings(date) {
        return this.restService.get(`api/dashboardmeetings/v1?date=${date}&fetchWebinar=false`);
    }

    getMeetings({ userId, from, to, searchQuery = '' }) {
        return this.restService.get(`api/meeting/${userId}?from=${from}&to=${to}&keyword=${searchQuery}`);
    }

    getPastMeetings(user, from = null, to = null, all = false) {
        return this.restService.get(`api/meeting/${user._id}?from=${from}&to=${to}&past=true&limit=500`);
        // return this.restService.get(`api/meeting/${user._id}?past=true`);
    }

    getRunningMeetings({ userId, searchQuery = '' }) {
        return this.restService.get(`api/meeting/${userId}?running=true`);
    }

    deleteMeeting(meetingId, payload, cancel = null) {
        let body = {};
        if (cancel) {
            body = {
                cancel
            };
        }
        return this.restService.post(`api/meeting/cancel/${meetingId}`, body);
    }
    cancelOnBehalfMeeting(meetingId, payload) {
        return this.restService.post(`api/meeting/onBehalf/cancel/${meetingId}`, payload);
    }

    declineMeeting(meeting, payload, cancel = null) {
        let body = {};
        if (cancel) {
            if (cancel === 'current') {
                body = {
                    decline: cancel,
                    declinedOn: payload.startTime
                };
            } else {
                body = {
                    decline: cancel
                };
            }
        }
        if (meeting.meetingId) {
            return this.restService.post(`api/meeting/${meeting.meetingId}/decline`, body);
        } else {
            return this.restService.post(`api/meeting/${meeting._id}/decline`, body);
        }
    }

    createMeeting(payload) {
        return this.restService.post(`api/meeting`, payload);
    }

    updateMeeting(meetingId, payload) {
        return this.restService.put(`api/meeting/meetingDetails/${meetingId}`, payload);
    }
    updateonBehalfMeeting(meetingId, payload) {
        return this.restService.put(`api/meeting/onBehalf/meetingDetails/${meetingId}`, payload);
    }

    updateExternalCalendarDetails(meetingId, payload) {
        return this.restService.put(`api/meeting/externalcalendar/${meetingId}`, payload);
    }

    scheduleMeeting(payload) {
        return this.restService.post(`api/meeting`, payload);
    }
    scheduleOnBehlafMeeting(payload) {
        return this.restService.post(`api/meeting/onBehalf`, payload);
    }

    addToJiomeet({ hash = '', extension = '', pin = '' }) {
        const payload: any = {
            ...(extension && { extension }),
            ...(pin && { pin }),
            ...(hash && { hash })
        };
        return this.restService.post(`api/shorturl/addtojiomeet`, payload);
    }

    cancelMeeting(meetingId, payload) {
        return this.restService.post(`api/meeting/cancel/${meetingId}`, payload);
    }

    getContacts(filters) {
        return this.restService.get(`api/contacts?keyword=${filters.keyword}`);
    }

    getMeetingDetails(meetingId) {
        return this.restService
            .get(`api/meeting/meetingDetails/${meetingId}?source=webrtc`)
            .pipe(map((details) => (Array.isArray(details) ? details[0] : details)));
    }

    getMeetingDetailsForSelectedDate(meetingId, selectedDate) {
        return this.restService
            .get(`api/meeting/meetingDetails/${meetingId}?date=${selectedDate}`)
            .pipe(map((details) => (Array.isArray(details) ? details[0] : details)));
    }

    getScheduleMeetingDetails() {
        return this.scheduleMeetingDetails;
    }

    setScheduleMeetingDetails(meeting) {
        this.scheduleMeetingDetails = meeting;
    }

    getRoomDetails(historyId) {
        return this.restService.get(`api/calls/${historyId}`);
    }

    groupMeetingsByDate(meetings) {
        if (!meetings.length) {
            return [];
        }
        // group chats by date
        const chatGroups = _.groupBy(meetings, (chat) => this.datePipe.transform(chat.startTime, 'd MMM y'));
        // sort date keys
        const ordered = _.orderBy(_.keys(chatGroups), [(key) => new Date(key)], ['desc']);
        // sorted map by dates
        const map: Map<string, any[]> = ordered.reduce((result, key) => {
            result.set(key, chatGroups[key]);
            return result;
        }, new Map());

        const array = [];
        map.forEach((value, key) => {
            array.push({ key, value });
        });
        return array;
    }

    addParticipantsInMeeting(users, ownerName, topic, historyId, isClassroomMode) {
        return this.restService.post(`api/call`, {
            data: {
                owner: ownerName,
                is_disconnect: 'false',
                group_id: '0',
                participantEndPointType: this.systemService.getClientDeviceType(),
                participantEndPointName: 'web',
                machineIp: this.systemService.getDeviceUUID(),
                historyId,
                users,
                time: +new Date() - +new Date('1970-01-01'),
                title: topic,
                isClassroomMode
            },
            event: 'call'
        });
    }

    getMeetingType({ jiomeetId = '', pin = '' }) {
        const payload: any = {
            jiomeetId,
            ...(pin && { pin })
        };
        return this.restService.post(`api/meeting/isWebinar`, payload);
    }

    getActiveCalls({ offset, limit, keyword }) {
        return this.restService.get(`api/calls?offset=${offset}&limit=${limit}&keyword=${keyword}`);
    }

    getMeetingRecording(id, chis) {
        return chis
            ? this.restService.get(`api/recording/bymeeting/${id}?historyId=${chis}`)
            : this.restService.get(`api/recording/bymeeting/${id}`);
    }

    getVideoStream(hash) {
        return this.restService.get(`api/shorturl/getstreamurl?hash=${hash}`);
    }

    getRecordingDetailsByHash(hash) {
        return this.restService.get(`api/recording/byhash?hash=${hash}`);
    }
}
