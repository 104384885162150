import { Injectable, Inject } from '@angular/core';
import { LocalStorageService } from 'src/app/core';

import { of, Observable, BehaviorSubject } from 'rxjs';
import { tap, switchMap, map } from 'rxjs/operators';

import { AppService } from './app.service';
import { RestService } from './rest.service';
import { EventEmitterService } from './event-emitter.service';
import { UtilService } from './util.service';
import { APP_EVENTS } from 'src/app/constants';
import { AppLoggerService } from './app-logger.service';
import values from 'lodash-es/values';
import pick from 'lodash-es/pick';
import * as _ from 'lodash-es';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private user;
    private userRoom;
    private guestUser;
    private isHrPortalFlow = false;
    private guestLogin = false;
    private guestNameRoomOwnerInfo;
    reactionInit = 'None';
    reactionSelf: BehaviorSubject<any>;
    proAlertBanner: BehaviorSubject<any>;
    recorderId = 0;
    localParticipantId: any;
    localSearches = [];
    membersCount = 0;
    translationLanguage: string | null = null;

    constructor(
        private appService: AppService,
        private restService: RestService,
        private eventEmitterService: EventEmitterService,
        private utilService: UtilService,
        private appLoggerService: AppLoggerService,
        private localStorageService: LocalStorageService
    ) {
        this.reactionSelf = new BehaviorSubject(this.reactionInit);
        this.proAlertBanner = new BehaviorSubject(false);
    }

    setReaction(reaction) {
        this.reactionSelf.next(reaction);
    }

    setProBannerVisibility(visibility) {
        this.proAlertBanner.next(visibility);
    }

    getUser() {
        if (this.guestLogin) {
            return of({});
        }
        return Object.keys(this.getUserSync()).length ? of(this.getUserSync()) : this.updateLocalUserData();
    }

    getSelfProfilePicPath(user) {
        if (user.imageMeta?.path) {
            var path = user.imageMeta?.path.substring(1);
            return this.getOwnProfilePicPath(path);
        } else {
            return this.getProfilePicPath(user._id);
        }
    }

    checkAndGrantJioCloudProvision() {
        return this.restService.get(`api/jc`);
    }

    getUserSync(): any {
        return this.user || {};
    }

    setTranslationLanguage(translationLanguage) {
        this.translationLanguage = translationLanguage;
    }
    getTranslationLanguage() {
        return this.translationLanguage;
    }

    updateLocalUserData() {
        return this.restService.get(`api/my_profile`).pipe(
            tap((user) => {
                this.user = user;
            }),
            switchMap((user) => {
                if (!user.jcData) {
                    return this.checkAndGrantJioCloudProvision().pipe(
                        map((data) => {
                            return Object.assign(this.user, { jcData: data });
                        })
                    );
                } else {
                    return of(user);
                }
            }),
            tap((user) => {
                if (user.isOTT) {
                    user.meetingId = user.jiomeetId;
                } else {
                    user.isRil = this.appService
                        .getConfigVariable('SAML_DOMAINS')
                        .includes(this.utilService.getDomainFromEmail(user.email));
                }

                if (user.phoneNo) {
                    user.displayPhoneNo = user.phoneNo.replace('+91', '');
                }

                this.user = user;
                this.eventEmitterService.emit({
                    type: APP_EVENTS.UPDATE_PROFILE,
                    data: null
                });
                return user;
            }),

            tap((user) => {
                if (this.appService.getConfigVariable('elasticApm')?.ENABLE) {
                    this.appService.getApm().setUserContext({
                        email: user.email,
                        id: user._id
                    });
                }
                this.appLoggerService.setUserInfo(user);
            })
        );
    }

    getUserRoom() {
        return this.userRoom
            ? of(this.userRoom)
            : this.restService
                  .post(`api/getroom`, {
                      usePersonal: true
                  })
                  .pipe(tap((room) => (this.userRoom = room)));
    }

    getOtherUserRoom(userId): Observable<any> {
        return this.restService.post(`api/getUserRoom`, {
            userId
        });
    }

    getUserRole(roleId) {
        return this.restService.get(`api/roles/${roleId}`);
    }

    getParticipantsCount() {
        if (this.userRoom) {
            return this.restService.post(`api/getParticipants`, {
                roomID: '' + this.userRoom.roomid
            });
        } else {
            return this.getUserRoom().pipe(switchMap((room) => this.getParticipantsCount()));
        }
    }

    getCallHistory({ offset, limit, keyword }) {
        return this.restService.get(`api/calls?offset=${offset}&limit=${limit}&keyword=${keyword}`);
    }

    updateUserProfile({ name, lname }) {
        return this.restService
            .put(`api/my_profile`, {
                name,
                lname
            })
            .pipe(tap(() => (this.user = null)));
    }

    updateUserProfileWithAdditionalProps(payload) {
        return this.restService.put(`api/my_profile/update`, payload).pipe(tap(() => (this.user = null)));
    }

    getPersonalRoomPrefernces() {
        return this.restService.get(`api/user/personalroompreferences`);
    }

    updatePersonalRoomPreferences({ isPinEnabled, roomPIN }) {
        const payload = roomPIN ? { isPinEnabled, roomPIN: roomPIN.toString() } : { isPinEnabled };
        return this.restService
            .put(`api/user/personalroompreferences`, payload)
            .pipe(tap(() => Object.assign(this.user, payload)));
    }

    getMeetingPrefernces() {
        return this.restService.get(`api/user/meetingpreferences`);
    }
    getOnBehalfUsersList() {
        return this.restService.get(`api/onBehalf/allowedUsers`);
    }
    getScheduleForUsersList() {
        return this.restService.get(`api/onBehalf/scheduleForUsers`);
    }
    enableOnBehalfSchedule(isEnabled) {
        return this.restService.post(`api/onBehalf/enableScheduleOnBehalf`, {
            enable: isEnabled
        });
    }
    updateOnBehalfUsersList(usersList) {
        return this.restService.post(`api/onBehalf/updateAllowedUsers`, {
            users: usersList
        });
    }
    resolveUserList(usersList) {
        return this.restService.post(`api/onBehalf/resolveUsersList`, {
            users: usersList
        });
    }

    updateMeetingPrefernces({
        hostAudio,
        participantAudio,
        hostVideo,
        participantVideo,
        waitingRoom,
        participantHardAudioMute,
        colleagueOnly,
        loggedInOnly,
        joinBeforeHost
    }) {
        return this.restService.put(`api/user/meetingpreferences`, {
            hostAudio,
            participantAudio,
            hostVideo,
            participantVideo,
            waitingRoom,
            participantHardAudioMute,
            colleagueOnly,
            loggedInOnly,
            joinBeforeHost
        });
    }

    getProfilePicPath(userId) {
        return `${this.appService.getEnvVariable(
            'HOST_URL'
        )}api/profilepic/${userId}/profile.png?random=${Math.random()}`;
    }

    getOwnProfilePicPath(path) {
        return `${this.appService.getEnvVariable('HOST_URL')}${path}`;
    }

    uploadProfilePicture(payload) {
        return this.restService
            .post(`api/my_profile/uploadPic`, payload)
            .pipe(tap(() => Object.assign(this.user, payload)));
    }

    deleteProfilePicture() {
        return this.restService.delete(`api/my_profile/unsetPic`).pipe(tap(() => Object.assign(this.user)));
    }

    setOTTPassword(password, hash, name, lname) {
        return this.restService.post(`api/setottpassword`, {
            password,
            confirm_password: password,
            hash,
            name,
            lname
        });
    }

    setUserStatusService(userStatus) {
        const payload = {
            status: userStatus
        };
        return this.restService.put(`api/notifications/setuserstatus`, payload);
    }

    updateSchoolInfo(props) {
        return this.restService.post(`api/my_profile/schooltnc`, props).pipe(tap(() => (this.user = null)));
    }

    updateProfile(props) {
        return this.restService.put(`api/my_profile/update`, props).pipe(tap(() => Object.assign(this.user, props)));
    }

    inviteColleagues(emails) {
        return this.restService.put(`api/contacts/invite/email`, emails);
    }

    resetPassword(email, password, hash) {
        return this.restService.post(`api/resetpassword`, {
            email,
            password,
            confirm_password: password,
            hash
        });
    }

    getWaitingRoomList({ jiomeetId }) {
        return this.restService.get(`api/shorturl/waitingroom/list?jiomeetId=${jiomeetId}&status=pending`);
    }
    acceptWaitingUserWithWaitingRoomId({ waitingRoomId, jiomeetId }) {
        const payload = {
            waitingRoomIds: [waitingRoomId],
            jiomeetId
        };
        return this.restService.post(`api/shorturl/waitingroom/accept`, payload);
    }

    rejectWaitingUserWithWaitingRoomId({ waitingRoomId, jiomeetId }) {
        const payload = {
            waitingRoomIds: [waitingRoomId],
            jiomeetId
        };
        return this.restService.post(`api/shorturl/waitingroom/reject`, payload);
    }

    stopWaitingWithWaitingRoomID({ waitingRoomId, jiomeetId }) {
        const payload = {
            waitingRoomId,
            jiomeetId
        };
        return this.restService.post(`api/shorturl/waitingroom/stopwaiting`, payload);
    }

    acceptWaitingUser({ memberId, jiomeetId }) {
        const payload = {
            memberIds: [memberId],
            jiomeetId
        };
        return this.restService.post(`api/shorturl/waitingroom/accept`, payload);
    }

    rejectWaitingUser({ memberId, jiomeetId }) {
        const payload = {
            memberIds: [memberId],
            jiomeetId
        };
        return this.restService.post(`api/shorturl/waitingroom/reject`, payload);
    }

    stopWaiting({ memberId, jiomeetId }) {
        const payload = {
            memberIds: [memberId],
            jiomeetId
        };
        return this.restService.post(`api/shorturl/waitingroom/stopwaiting`, payload);
    }

    updateHostConnectionStatus(roomID, status: 'connected' | 'disconnected') {
        return this.restService.post(`api/roomConnectionStatus`, { roomID, status });
    }

    getConferenceDetails(id) {
        return this.restService.get(`api/getconferencedetails?historyId=${id}`);
    }

    getRemainingInvitationCont() {
        return this.restService.get(`api/external/invite`);
    }

    isValidInviteCode(code) {
        return this.restService.post(`api/external/isvalid`, {
            code
        });
    }

    invite(payload) {
        return this.restService.post(`api/external/invite`, payload);
    }

    registerForEnterprise(payload) {
        return this.restService.post(`api/jiomeetenterprise/register`, payload);
    }

    contactForSales(payload) {
        return this.restService.post(`api/contacts/contactsales`, payload);
    }

    subscribeToJioMeet(payload) {
        return this.restService.post(`api/user/subscribe`, payload);
    }

    getFinalTempAuthUrl(payload) {
        return this.restService.post(`api/shorturl/shortlivedurl`, payload);
    }

    setGuestUser(name) {
        this.guestUser = { name };
        this.appLoggerService.setGuestUserInfo(this.guestUser);
    }

    getGuestUser() {
        return this.guestUser;
    }

    setMicrophoneOff(state) {
        this.user.microphoneOff = state;
    }

    setVideoOff(state) {
        this.user.videoOff = state;
    }

    setHRPortal(bool) {
        this.isHrPortalFlow = bool;
    }

    isHRPortal() {
        return this.isHrPortalFlow;
    }

    isGuestLogin(bool) {
        this.guestLogin = bool;
    }

    setGuestNameRoomOwnerInfo(info) {
        this.guestNameRoomOwnerInfo = info;
    }

    getGuestNameRoomOwnerInfo() {
        return this.guestNameRoomOwnerInfo;
    }

    addPhoneNumber(payload) {
        return this.restService.post(`api/profile/whatsapp/addnumber`, payload);
    }

    subscribeToWhatsapp(payload) {
        return this.restService.post(`api/profile/whatsapp`, payload).pipe(
            tap(() => {
                Object.assign(this.user.whatsappNotification, payload);
            })
        );
    }

    toggleNotifications(payload) {
        return this.restService.post(`api/user/notifications`, payload).pipe(
            tap(() => {
                if (payload.notificationType === 'sms') {
                    this.user.notifications.enabledOnSMS = payload.enable;
                } else {
                    this.user.notifications.enabledOnEmail = payload.enable;
                }
            })
        );
    }

    verifyPhone(payload) {
        return this.restService.post(`api/profile/whatsapp/verifynumber`, payload);
    }

    cleanup() {
        this.user = null;
        this.userRoom = null;
    }

    getWebsiteMetaInfo() {
        return this.restService.get(`api/config/webrtcmeta?lang=en`);
    }

    uploadPartnersLogo(payload) {
        return this.restService.post(`api/my_profile/uploadPic`, payload);
    }

    updateWebsiteMetaInfo(payload) {
        return this.restService.post(`api/config/webrtcmeta`, payload);
    }

    isDeleteAccountUrl(url: string): boolean {
        return url.indexOf('deleteaccount') > -1;
    }

    deleteUser(payload) {
        return this.restService.delete(`api/user/deleteaccount`, payload);
    }

    downloadIcsFile(jiomeetId, roomId) {
        return this.restService.get(`api/external/downloadICSFile?jiomeetId=${jiomeetId}&roomPIN=${roomId}`, {
            responseType: 'text',
            observe: 'response'
        });
    }

    getRecorderId(id) {
        this.recorderId = id;
    }
    showRecorderId() {
        return this.recorderId;
    }

    checkForOptionsEmail(email) {
        return email === this.getUserSync().email;
    }

    isJioCloudLoginRequired() {
        if (!this.guestLogin && this.isJioCloudEnabled()) {
            return true;
        }
        return false;
    }

    isJioCloudEnabled() {
        if (
            this.guestLogin ||
            (!this.appService.isDesktopApp() && !this.appService.getConfigVariable('ENABLE_JIOCLOUD_ATTACHMENTS'))
        )
            return false;

        return this.user?.featuresEnabled?.fileSharing ?? false;
    }

    public getServerCurrentTime() {
        return this.restService.get(`api/sync_time`);
    }

    addGlobalSearchItem(items) {
        if (this.localStorageService.getItem('globalSearches')) {
            if (items?.length > 1) {
                items.forEach((item) => {
                    this.localStorageService.addItem('globalSearches', this.updateGlobalSearchList(item));
                });
            } else {
                this.localStorageService.addItem(
                    'globalSearches',
                    this.updateGlobalSearchList(items?.length ? items[0] : items)
                );
            }
        } else {
            this.localStorageService.addItem('globalSearches', items?.length ? items : [items]);
        }
        this.localSearches = this.localStorageService.getItem('globalSearches');
    }

    updateGlobalSearchList(item) {
        let recentSearches = this.localStorageService.getItem('globalSearches');
        const index = _.findIndex(recentSearches, ['_id', item?._id]);
        if (index !== -1) {
            recentSearches.unshift(recentSearches.splice(index, 1)[0]);
        } else {
            recentSearches.unshift(item);
        }
        return recentSearches.slice(0, 10);
    }

    getLocalSearchResults(searchQuery, key) {
        if (!this.localSearches) {
            return [];
        }
        const keys = Array.isArray(key) ? key : [key];
        searchQuery = searchQuery.trim().split(' ').join('');
        return this.localSearches?.filter((val) => {
            return !!values(pick(val, keys)).filter((str: string) => {
                return str.trim().split(' ').join('').toLowerCase().startsWith(searchQuery.toLowerCase());
            }).length;
        });
    }
    setMembersCount(count: number) {
        this.membersCount = count;
    }

    getMembersCount() {
        return this.membersCount;
    }
}
