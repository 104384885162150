<div class="upgrade-popup-component">
    <div class="bg"></div>
    <div class="upgrade-popup">
        <div class="popup-body">
            <div
                tabindex="0"
                (keyup.enter)="cancel()"
                class="close"
                aria-label="Close upgrade dialog box"
                (click)="cancel()"
            >
                <img alt="" src="assets/img/close_icon.svg" />
            </div>
            <div class="popup-left">
                <ng-container *ngFor="let gif of userData; index as indexOfelement">
                    <img [src]="gif.gifurl" *ngIf="indexOfelement == index" />
                </ng-container>
            </div>
            <div class="popup-right">
                <div class="plan-list">
                    <div class="list-heading">{{ 'upgradepopup.upgrade_plan' | translate }}</div>
                    <div class="list-container">
                        <li
                            *ngFor="let feature of userData; index as indexOfelement"
                            [ngStyle]="
                                indexOfelement == index
                                    ? { color: '#224696', opacity: '1' }
                                    : { color: '#000000', opacity: '0.6' }
                            "
                            (click)="changeListIndex(indexOfelement)"
                        >
                            {{ feature.featureInfo }}
                        </li>
                    </div>
                </div>
                <div class="form-group">
                    <button type="button" class="custom-button" (click)="buyNow()">
                        {{ 'upgradepopup.buynow' | translate }}
                    </button>
                </div>
            </div>
        </div>
        <!-- <div class="popup-body">
            <div tabindex="0" class="invite-span"></div>
            
        </div> -->
    </div>
</div>
