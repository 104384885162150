<div class="website-education website-usecases website-govt" id="website-top">
    <section class="section-top" [class.padding-h-16]="isMobile">
        <div
            class="left-content First-Element-Focus"
            tabindex="0"
            aria-label="The JioMeet for Government Sectors Page has been opened!
             To navigate through the page, please utilize the tab key on your keyboard"
        >
            <div class="subheading-1" tabindex="0">
                {{ 'website_govt_sector.top-title-0' | translate }}
            </div>
            <div class="heading" tabindex="0">
                {{ 'website_govt_sector.top-title-1' | translate }}
            </div>
            <div class="desc subheading-0" tabindex="0">
                {{ 'website_govt_sector.top-desc' | translate }}
            </div>
            <div class="action-btns">
                <button
                    class="custom-action-btn"
                    (click)="navigateToSignUp()"
                    (keydown.enter)="navigateToSignUp()"
                    tabindex="0"
                >
                    {{ 'website_govt_sector.start_free' | translate }}
                </button>
                <button
                    class="custom-action-btn secondary"
                    (click)="goToDownloadApp()"
                    (keydown.enter)="goToDownloadApp()"
                    tabindex="0"
                >
                    {{ 'website_govt_sector.download' | translate }}
                </button>
            </div>
        </div>
        <div class="right-content">
            <img
                src="assets/img/govtSector/govtSector-top.png"
                *ngIf="!isMobile"
                alt="Transformation in-office administration to its real time video conferencing finesse."
            />
            <img src="assets/img/govtSector/govtSector-top-mobile.png" *ngIf="isMobile" alt="" />
        </div>
    </section>

    <div *ngIf="!isMobile">
        <section class="sec-1 sec">
            <div class="img">
                <img [src]="govtSectorsFeatures[0].imgDesktop" [alt]="govtSectorsFeatures[0].altText" />
            </div>
            <div class="sec-info padding-h-140-150">
                <div>
                    <div class="heading-0" tabindex="0" [class.padding-h-44]="featureIndex === 0">
                        {{ govtSectorsFeatures[0].ttitle | translate }}
                    </div>
                    <p class="subheading-2" tabindex="0">
                        {{ govtSectorsFeatures[0].desc[0].ttext | translate }}
                    </p>
                </div>
                <div></div>
            </div>
        </section>

        <section class="sec-2 sec padding-h-140-150">
            <app-usecase-features [useCaseFeature]="govtSectorsFeatures[1]" [featureIndex]="1"></app-usecase-features>
        </section>

        <section class="sec padding-h-140-150">
            <app-usecase-features [useCaseFeature]="govtSectorsFeatures[2]" [featureIndex]="2"></app-usecase-features>
        </section>

        <section class="sec padding-h-140-150">
            <app-usecase-features [useCaseFeature]="govtSectorsFeatures[3]" [featureIndex]="3"></app-usecase-features>
        </section>

        <section class="sec padding-h-140-150">
            <app-usecase-features [useCaseFeature]="govtSectorsFeatures[4]" [featureIndex]="4"></app-usecase-features>
        </section>
    </div>
    <ng-container *ngIf="isMobile">
        <section *ngFor="let feature of govtSectorsFeatures; let i = index" class="sec">
            <div *ngIf="i === 0" class="mobileview img">
                <img src="assets/img/govtSector/digital-judiciary-mobile.png" alt="" />
            </div>
            <app-usecase-features [useCaseFeature]="feature" [featureIndex]="i"></app-usecase-features>
        </section>
    </ng-container>

    <section class="got-questions" [class.padding-h-44]="isMobile">
        <app-explore-meet
            bottomButtons="government"
            title="{{ 'website_govt_sector.explore_title' | translate }}"
            desc="{{ 'website_govt_sector.explore_desc' | translate }}"
            btnText1="{{ 'website_govt_sector.start_free' | translate }}"
            btnText2="{{ 'website_govt_sector.download_now' | translate }}"
            btnText1Link="/signup"
            btnText2Link="/jiomeetDownload"
        ></app-explore-meet>
    </section>
</div>
