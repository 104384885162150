<div [id]="id || 'meeting-invitation'" class="meeting-head">
    <p tabindex="{{ insideMeeting ? -1 : 0 }}">
        <ng-container *ngIf="meeting?.topic">
            {{ hostName }}
            {{ 'meeting_invitation.hasinvited_with_topic' | translate : { value: '"' + meeting?.topic + '"' } }}
        </ng-container>
        <ng-container *ngIf="!meeting?.topic">
            "{{ hostName }} {{ 'meeting_invitation.hasinvited' | translate }}"
        </ng-container>
    </p>

    <div class="meeting-details">
        {{ 'meeting_invitation.meetingdetails' | translate }}
        <br />
        <div class="meeting-details-brief">
            <div id="topic">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label tabindex="{{ insideMeeting ? -1 : 0 }}" aria-label="Topic">{{
                    'meeting_invitation.topic' | translate
                }}</label>
                <label tabindex="{{ insideMeeting ? -1 : 0 }}" class="meeting-light"> &nbsp;{{ meeting?.topic }}</label>
            </div>
            <!-- <div *ngIf="meeting.startTime && duration"> {{'meeting_invitation.starttime' | translate}} {{ meeting.startTime | date: 'hh:mm a, d MMM y' }}</div>
            <p *ngIf="meeting.startTime && !duration"> {{'meeting_invitation.starttime' | translate}} {{ meeting.startTime | date: 'hh:mm a, d MMM y' }}</p> -->
            <div *ngIf="meeting.startTime && duration">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label
                    tabindex="{{ insideMeeting ? -1 : 0 }}"
                    aria-label=" {{ 'meeting_invitation.datetime' | translate }}"
                    >{{ 'meeting_invitation.datetime' | translate }}</label
                >
                <label tabindex="{{ insideMeeting ? -1 : 0 }}" class="meeting-light">
                    &nbsp;{{ meeting.startTime | convertToLocaleDate : 'EEEE, d MMMM y' }} |
                    {{ formatAMPM(meeting.startTime) }}
                </label>
            </div>
            <p *ngIf="meeting.startTime && !duration">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'meeting_invitation.datetime' | translate }}
                <label class="meeting-light"
                    >&nbsp;{{ meeting.startTime | convertToLocaleDate : 'EEEE, d MMMM y' }} |
                    {{ formatAMPM(meeting.startTime) }}
                </label>
            </p>
            <div *ngIf="duration">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label
                    tabindex="{{ insideMeeting ? -1 : 0 }}"
                    aria-label="{{ 'meeting_invitation.duration' | translate }}"
                    >{{ 'meeting_invitation.duration' | translate }}</label
                >
                <label tabindex="{{ insideMeeting ? -1 : 0 }}" class="meeting-light">
                    &nbsp;{{ formatDuration(duration) }}</label
                >
            </div>
            <div *ngIf="endDate">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label
                    tabindex="{{ insideMeeting ? -1 : 0 }}"
                    aria-label="{{ 'meeting_invitation.tilldate' | translate }}"
                    >{{ 'meeting_invitation.tilldate' | translate }}</label
                >
                <b
                    ><label tabindex="{{ insideMeeting ? -1 : 0 }}" class="meeting-light">
                        &nbsp;{{ endDate | date : 'MMM d, y' }}</label
                    ></b
                >
            </div>
            <div
                *ngIf="isScheduledMeeting"
                tabindex="{{ insideMeeting ? -1 : 0 }}"
                aria-label="{{ 'meeting_invitation.no_of_participants_invited' | translate }}"
            >
                &nbsp;&nbsp;&nbsp;&nbsp;<span>
                    {{ 'meeting_invitation.no_of_participants_invited' | translate }}
                </span>
                <span class="meeting-light" id="no-of-participants">
                    {{ allParticipants }}
                </span>
            </div>
        </div>
    </div>

    <p *ngIf="!meeting.startTime || !meeting.endTime || !duration"></p>
    <br />
    <div class="join">
        <label tabindex="{{ insideMeeting ? -1 : 0 }}" aria-label=" More ways to join the meeting">
            {{ 'meeting_invitation.more_ways_to_join' | translate }}</label
        >
    </div>
    <div tabindex="0">
        &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;{{ 'meeting_invitation.JointhroughLink' | translate }}
        <div class="options meeting-link">
            <div class="link">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a
                    class="link-pointer pointer"
                    target="_blank"
                    href="{{ meeting?.url || meeting?.meetingUrl }}"
                    (click)="JointhroughLink()"
                    >{{ meeting?.url || meeting?.meetingUrl }}
                </a>
                <div
                    class="ml-3 copy-wrap pointer"
                    (click)="copyLink()"
                    [class.disabled]="meeting?.advancedOptions?.isRestrictedMeeting"
                >
                    <img
                        #copyLinkIcon
                        src="assets/images/copy.svg"
                        alt=""
                        [attr.tabindex]="ischildofpmicomponent ? -1 : 0"
                        aria-label="Select icon to copy meeting link"
                        (keyup.enter)="performCopy($event)"
                    />
                </div>
                <div class="arrow-container">
                    <div class="arrow" [hidden]="!copiedUrlToClipboard">
                        <div class="outer"></div>
                        <div class="inner"></div>
                    </div>
                    <div class="message-body" [hidden]="!copiedUrlToClipboard">
                        <p>{{ 'inside_call.call_component.copied' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="option">
            <label tabindex="{{ insideMeeting ? -1 : 0 }}" aria-label="Join using meeting ID"
                >&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;{{ 'meeting_invitation.join_using_meeting_id' | translate }}
            </label>
            <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label
                    tabindex="{{ insideMeeting ? -1 : 0 }}"
                    aria-label="Meeting ID"
                    >{{ 'meeting_invitation.meetingid' | translate }}</label
                >
                &nbsp;<label tabindex="{{ insideMeeting ? -1 : 0 }}" class="meeting-light">{{
                    meeting?.jiomeetId | meetingidFormat
                }}</label>
            </div>
            <div *ngIf="meeting?.pin || host?.roomPIN">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label
                    tabindex="{{ insideMeeting ? -1 : 0 }}"
                    aria-label="Password"
                    >{{ 'meeting_invitation.password' | translate }}</label
                >
                &nbsp;<label tabindex="{{ insideMeeting ? -1 : 0 }}" class="meeting-light">{{
                    meeting?.pin || host?.roomPIN
                }}</label>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                tabindex="{{ insideMeeting ? -1 : 0 }}"
                class="meeting-light"
                aria-label="Join using desktop, mobile application or through web and use above details"
            >
                {{ 'meeting_invitation.join_using_desktop_mobile_web' | translate }}</span
            >
        </div>
        <br />
        <div
            class="option"
            *ngIf="
                (meeting?.legacyConnect || meeting?.legacyConnectInfo) &&
                !(isIntranetIpHidden && isInternetIpHidden && isDnsAddressHidden)
            "
            (click)="joinThroughSip()"
        >
            &nbsp;&nbsp;&nbsp;&nbsp;<label
                tabindex="{{ insideMeeting ? -1 : 0 }}"
                aria-label="Join from video system or SIP device"
                >•&nbsp;&nbsp;{{ 'meeting_invitation.join_from_sip' | translate }}</label
            >
            <div *ngIf="!isInternetIpHidden || !isIntranetIpHidden">
                <span tabindex="{{ insideMeeting ? -1 : 0 }}" aria-label="Dial">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                        'inside_call.call_info.dial2' | translate
                    }}&nbsp;</span
                >
                <span tabindex="{{ insideMeeting ? -1 : 0 }}" *ngIf="!isInternetIpHidden" class="meeting-light">
                    {{
                        meeting.legacyConnect?.settings?.internetIp?.value ||
                            meeting.legacyConnectInfo?.settings?.internetIp?.value
                    }}
                    {{ 'inside_call.call_info.internet' | translate }}
                </span>
                <br *ngIf="!isInternetIpHidden" />
                <span *ngIf="!isIntranetIpHidden && isIntranetIpValuePresent" class="meeting-light">
                    <span *ngIf="!isInternetIpHidden"
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
                    >
                    {{
                        meeting.legacyConnect?.settings?.intranetIp?.value ||
                            meeting.legacyConnectInfo?.settings?.intranetIp?.value
                    }}
                    {{ 'inside_call.call_info.intranet' | translate }}
                </span>
                <!-- <span *ngIf="currentLanguage === 'hi'">
                    {{ 'inside_call.call_info.dial2' | translate }}
                </span> -->
            </div>
            <div *ngIf="!isInternetIpHidden || !isIntranetIpHidden" tabindex="0">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="meeting-light">
                    {{ 'meeting_invitation.and_enter_meeting_details' | translate }}:
                </span>
                <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'meeting_invitation.meetingid' | translate }}
                    <span class="meeting-light">
                        {{ meeting.mainRoomJiomeetId || meeting.jiomeetId }}
                    </span>
                </div>
                <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                        'meeting_invitation.meeting_passcode' | translate
                    }}
                    <span class="meeting-light">
                        {{ meeting.legacyConnectInfo?.SIP?.pin || meeting.legacyConnect?.SIP?.pin || meeting?.vpin }}
                    </span>
                </div>
            </div>
            <div *ngIf="!isDnsAddressHidden" class="meeting-light" tabindex="0">
                <span class="font-weight-bold" *ngIf="!isInternetIpHidden || !isIntranetIpHidden">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'meeting_invitation.ortext' | translate }}
                </span>
                <div tabindex="0">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                        'inside_call.call_info.you_can_also_dial' | translate
                    }}
                    {{ meeting.legacyConnectInfo?.SIP?.url || meeting.legacyConnect?.SIP?.url }}
                </div>
            </div>
        </div>
        <br />
        <div *ngIf="!myjioIntegration">
            <div *ngIf="meeting?.addToJmtUrl">
                <div tabindex="0">{{ 'meeting_invitation.usethe' | translate }}</div>
                <div>
                    <a
                        class="pointer"
                        target="_blank"
                        href="{{ meeting?.addToJmtUrl }}"
                        tabindex="{{ insideMeeting ? -1 : 0 }}"
                        aria-label="{{ meeting?.addToJmtUrl }}"
                        (keyup.enter)="handleAddtoCalendarevent($event)"
                        (click)="addToJiomeetCalender()"
                        >{{ meeting?.addToJmtUrl }}</a
                    >
                </div>
            </div>
            <br />
            <div *ngIf="showIcsInvi && meeting?.jiomeetId && meeting?.pin" (click)="downloadIcs()">
                <div tabindex="0">{{ 'meeting_invitation.downloadIcsFile' | translate }}</div>
                <div>
                    <a
                        class="pointer"
                        (click)="downloadICSMeeting()"
                        tabindex="{{ insideMeeting ? -1 : 0 }}"
                        href="{{ addToIcsCalenderUrl }}"
                        aria-label="{{ addToIcsCalenderUrl }}"
                        (keyup.enter)="handleDownloadFileevent($event)"
                        >{{ addToIcsCalenderUrl }}</a
                    >
                </div>
            </div>
            <br />
            <div tabindex="0">
                {{ 'meeting_invitation.need_help_go_to' | translate }}
                <a
                    class="pointer"
                    target="_blank"
                    href="{{ webhelpUrl }}"
                    tabindex="{{ insideMeeting ? -1 : 0 }}"
                    aria-label="jiomeet webHelp"
                    (keyup.enter)="handleGoToHelpEvent($event)"
                >
                    {{ webhelpUrl }}
                </a>
            </div>
            <br />
            <div tabindex="{{ insideMeeting ? -1 : 0 }}">{{ 'meeting_invitation.regards' | translate }}</div>
            <div tabindex="{{ insideMeeting ? -1 : 0 }}">{{ 'meeting_invitation.jiomeetteam' | translate }}</div>
        </div>
    </div>

    <!-- <p *ngIf="!meeting?.addToJmtUrl"></p> -->

    <!-- <div>
            <strong>{{ 'meeting_invitation.howto' | translate }}</strong>
        </div> -->
    <!-- <div>
            {{ 'meeting_invitation.option1' | translate }} &nbsp;&nbsp;{{ 'meeting_invitation.download' | translate }}
        </div> -->
    <!-- <div>
            {{ 'meeting_invitation.option2' | translate }} &nbsp;&nbsp;{{
                'meeting_invitation.tojoinchrome1' | translate
            }}<a class="pointer" target="_blank" href="{{ meeting?.url || meeting?.meetingUrl }}">{{
                'meeting_invitation.clickhere' | translate
            }}</a>
            {{ 'meeting_invitation.tojoinchrome2' | translate }}
        </div> -->
    <!-- <div *ngIf="!meeting?.legacyConnect?.H323 && !isAgoraMeeting">
            {{ 'meeting_invitation.option3' | translate }} &nbsp;&nbsp;{{
                'meeting_invitation.tojoinsiporh323' | translate
            }}
        </div> -->
    <!-- <div *ngIf="meeting?.legacyConnect?.H323">
            <div>
                {{ 'meeting_invitation.option3' | translate }} &nbsp;&nbsp;{{
                    'meeting_invitation.tojointhroughh323-1' | translate
                }}<strong>{{ 'meeting_invitation.h323' | translate }}</strong>
                {{ 'meeting_invitation.tojointhroughh323-2' | translate }} –
            </div>
            <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {{ 'meeting_invitation.using_intranet' | translate }} {{ meeting?.legacyConnect?.H323?.private }}
                {{ 'meeting_invitation.or' | translate }}
            </div>
            <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {{ 'meeting_invitation.using_internet' | translate }} {{ meeting?.legacyConnect?.H323?.public }}
            </div>
            <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                    'meeting_invitation.extension' | translate
                }}
                {{ meeting?.legacyConnect?.H323?.extension }} {{ 'meeting_invitation.followedby' | translate }}
            </div>
            <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                    'meeting_invitation.enterroompin' | translate
                }}
                {{ meeting?.legacyConnect?.H323?.pin }} {{ 'meeting_invitation.conference' | translate }}
            </div>
        </div>
        <div *ngIf="meeting?.legacyConnect?.SIP">
            <div>
                {{ 'meeting_invitation.option4' | translate }} &nbsp;&nbsp;{{
                    'meeting_invitation.tojointhroughsip-1' | translate
                }}<strong>{{ 'meeting_invitation.sip' | translate }}</strong
                >{{ 'meeting_invitation.tojointhroughsip-2' | translate }}
                {{ 'meeting_invitation.enterurl' | translate }} {{ meeting?.legacyConnect?.SIP?.url }}
                {{ 'meeting_invitation.andenterroompin' | translate }} {{ meeting?.legacyConnect?.SIP?.pin }}
                {{ 'meeting_invitation.conference' | translate }}
            </div>
        </div>
        <br /> -->
    <!-- <div>{{ 'meeting_invitation.regards' | translate }}</div>
        <p>{{ 'meeting_invitation.jiomeetteam' | translate }}</p> -->
</div>
