<div class="loader" *ngIf="loading">
    <app-loader></app-loader>
</div>
<div class="call-preview-div" *ngIf="!loading">
    <div class="back-btn" (click)="navigateToDashboard()">
        <img alt="" src="assets/images/mobile_back_btn.svg" /> {{ 'host_meeting.back' | translate }}
    </div>
    <div class="text">{{ 'joinmeeting_page_or_joincall.title' | translate }}</div>
    <div class="video-audio-options" *ngIf="host">
        <app-call-preview (showsettings)="openSettingsPopup($event)" [hostMeeting]="true"></app-call-preview>
    </div>
    <div class="video-audio-options" *ngIf="!host">
        <app-call-preview (showsettings)="openSettingsPopup($event)" [hostMeeting]="false"></app-call-preview>
    </div>
    <div class="join-btn" *ngIf="!showWaitingRoomPopup">
        <div class="button-group">
            <img
                alt=""
                src="assets/img/mobile-button-secondary-icon-only-medium.svg"
                (click)="launch($event)"
                *ngIf="roomType === 'personal'"
            />
            <button class="custom-button bg-primary" (click)="joinMeeting()">{{ startText }}</button>
        </div>
    </div>
    <div *ngIf="showWaitingRoomPopup" class="err-scenario">
        <h4>{{ 'joinmeeting_page_or_joincall.please_wait_while' | translate }}</h4>
        <div class="loader">
            <app-loader></app-loader>
        </div>
        <button type="button" class="btn primary leave-button custom-button" (click)="leaveWaitingRoom()">
            {{ 'joinmeeting_page_or_joincall.leave' | translate }}
        </button>
    </div>
</div>

<ng-template viewHost></ng-template>
