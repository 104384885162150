import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AuthService,
    ExternalInterfaceService,
    GuestAuthService,
    RestService,
    RtcService,
    ThirdPartyExternalIntegrationService,
    UserService,
    VideoWrapperService,
    LocalStorageService
} from 'src/app/core';

@Component({
    selector: 'app-mattermost',
    templateUrl: './mattermost.component.html',
    styleUrls: ['./mattermost.component.scss']
})
export class MattermostComponent implements OnInit {
    isAuthenticated;
    loader = true;
    userToken;
    userTokenFlow = false;
    autoJoin: boolean;
    hidePreviewScreen;
    meetingId;
    hash;
    pwd;
    name: string;
    enableMic;
    enableVideo;
    isMattermost;
    isThirdPartyExternalIntegration;
    cameraLabel;
    micLabel;
    speakerLabel;
    chatDisabled: boolean;
    hideMoreCallControls: boolean;
    hideAllCallControls: boolean;
    isFullScreenShare: boolean;
    hostToken: string;
    errors: any = {};
    working = false;
    currentUser: any = {};
    videoService;

    constructor(
        private authService: AuthService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        private activatedRoute: ActivatedRoute,
        private rtcService: RtcService,
        private externalIntegrationService: ExternalInterfaceService,
        private zone: NgZone,
        private restService: RestService,
        private userService: UserService,
        private videoWrapperService: VideoWrapperService,
        private router: Router,
        private localStorageService: LocalStorageService,
        private guestAuthService: GuestAuthService,
        private externalInterfaceService: ExternalInterfaceService
    ) {
        this.autoJoin = false;
        this.hideMoreCallControls = false;
        this.videoService = this.videoWrapperService.getVideoService();
    }

    ngOnInit() {
        this.isAuthenticated = this.authService.getIsAuthenticated() || false;
        this.thirdPartyExternalIntegrationService.hasJoinedFromGuestURL = true;
        this.activatedRoute.snapshot.queryParams?.isMattermost ? this.fetchParams() : '';

        if (
            this.activatedRoute.snapshot.queryParams?.page == 'dashboard' ||
            this.activatedRoute.snapshot.queryParams?.isMattermost
        ) {
            const userInfo = {
                jwt: this.activatedRoute.snapshot.queryParams?.userToken,
                refToken: this.activatedRoute.snapshot.queryParams?.userToken
            };
            this.activatedRoute.snapshot.queryParams?.page == 'dashboard'
                ? this.authService.setAuthInfo(userInfo, true)
                : this.authService.setAuthInfo(userInfo);
            this.isAuthenticated = this.authService.getIsAuthenticated() || false;

            if (this.isAuthenticated) {
                this.restService
                    .get(`api/my_profile`, {
                        header: { Authorization: this.activatedRoute.snapshot.queryParams?.userToken }
                    })
                    .subscribe((user) => {
                        this.currentUser = user;
                        this.name =
                            this.currentUser.name + ' ' + (this.currentUser.lname ? this.currentUser.lname : '');
                        if (this.activatedRoute.snapshot.queryParams?.page == 'dashboard') {
                            this.router.navigateByUrl('/dashboard');
                        } else {
                            // for meeting joining
                            if (this.autoJoin) {
                                if (this.hidePreviewScreen) {
                                    this.loader = true;
                                }
                                this.joinMeeting();
                            }

                            this.rtcService.setCameraMicPreference(
                                this.enableVideo ? true : false,
                                this.enableMic ? true : false
                            );
                        }
                    });
            }
        } else {
            console.log('mattermost queryParams ', this.activatedRoute.snapshot.queryParams);
        }
    }

    fetchParams() {
        this.meetingId = this.activatedRoute.snapshot.queryParams.meetingId;
        this.hash = this.activatedRoute.snapshot.queryParams.hash;
        this.pwd = this.activatedRoute.snapshot.queryParams.pwd;
        this.name = this.activatedRoute.snapshot.queryParams.name;
        this.autoJoin = this.activatedRoute.snapshot.queryParams.autoJoin === 'true' ? true : false;
        this.enableMic = this.activatedRoute.snapshot.queryParams.enableMic === 'true' ? true : false;
        this.enableVideo = this.activatedRoute.snapshot.queryParams.enableVideo === 'true' ? true : false;
        this.hidePreviewScreen = this.activatedRoute.snapshot.queryParams.hidePreviewScreen === 'true' ? true : false;
        this.isMattermost = this.activatedRoute.snapshot.queryParams.isMattermost === 'true' ? true : false;
        this.isThirdPartyExternalIntegration =
            this.activatedRoute.snapshot.queryParams.isThirdPartyExternalIntegration === 'true' ? true : false;
        this.cameraLabel = this.activatedRoute.snapshot.queryParams.cameraLabel;
        this.micLabel = this.activatedRoute.snapshot.queryParams.micLabel;
        this.speakerLabel = this.activatedRoute.snapshot.queryParams.speakerLabel;
        this.chatDisabled = this.activatedRoute.snapshot.queryParams.chatDisabled === 'true' ? true : false;

        if (this.activatedRoute.snapshot.queryParams.askPermission) {
            this.externalIntegrationService.askPermission =
                this.activatedRoute.snapshot.queryParams.askPermission === 'true' ? true : false;
        }

        if (this.chatDisabled) {
            this.thirdPartyExternalIntegrationService.setIsChatDisabled();
        }

        if (this.cameraLabel?.length > 0) {
            this.externalIntegrationService.cameraLabel = this.cameraLabel;
        }

        if (this.micLabel?.length > 0) {
            this.externalIntegrationService.micLabel = this.micLabel;
        }

        if (this.speakerLabel?.length > 0) {
            this.externalIntegrationService.speakerLabel = this.speakerLabel;
        }

        if (this.activatedRoute.snapshot.queryParams.setProxyType) {
            this.thirdPartyExternalIntegrationService.setProxyServer = Number(
                this.activatedRoute.snapshot.queryParams.setProxyType
            );
        }

        if (this.isMattermost) {
            this.autoJoin = true;
            this.enableMic = false;
            this.enableVideo = false;
            this.hidePreviewScreen = true;
            // this.isThirdPartyExternalIntegration = true;
            // this.thirdPartyExternalIntegrationService.enableThirdPartyIntegration();
            this.thirdPartyExternalIntegrationService.enableMattermostIntegration();
            this.thirdPartyExternalIntegrationService.disableFooterForExternalIntegration();
            this.thirdPartyExternalIntegrationService.disableNavbarForExternalIntegration();
            this.thirdPartyExternalIntegrationService.setIsChatDisabled();
            // this.thirdPartyExternalIntegrationService.enableJioHealthHubIntegration();
        }

        this.hideMoreCallControls = this.activatedRoute.snapshot.queryParams.hideMoreCallControls === 'true';
        this.hostToken = this.activatedRoute.snapshot.queryParams.hostToken
            ? this.activatedRoute.snapshot.queryParams.hostToken
            : '';
        this.hideAllCallControls = this.activatedRoute.snapshot.queryParams.hideAllCallControls === 'true';
        this.isFullScreenShare = this.activatedRoute.snapshot.queryParams.isFullScreenShare === 'true';

        if (this.hostToken?.length > 0) {
            this.thirdPartyExternalIntegrationService.isHostTokenUser = true;
        }
    }

    joinMeeting() {
        this.errors = {};

        if (!this.hidePreviewScreen) {
            this.working = true;
        }
        this.rtcService
            .getRoomDetailsGuest({
                extension: this.meetingId,
                pin: this.pwd,
                hash: this.hash,
                userId: this.currentUser._id,
                isAuthenticated: this.isAuthenticated,
                memberName: this.name.trim()
            })
            .subscribe(
                (res: any) => {
                    if (res.roomDetails) {
                        this.joinAs(res);
                    }
                },
                (err) => {
                    this.errors.global = err.error.errors;
                    this.working = false;
                    this.loader = false;
                    this.zone.run(() => {
                        this.externalInterfaceService.sendCallError(err.error);
                    });
                }
            );
    }

    joinAs(room, data?: any) {
        this.setHostOrCoHost(room);
        if (this.isAuthenticated && this.currentUser) {
            const commonConferenceInfo = {
                isInitiater: false,
                joiningName: data?.memberName ? data.memberName : this.name.trim(),
                room: room.roomDetails,
                ownerDetails: room.ownerDetails,
                hideMoreCallControls: this.hideMoreCallControls,
                hideAllCallControls: this.hideAllCallControls,
                isFullScreenShare: this.isFullScreenShare
            };
            const additionalConferenceInfo = this.videoWrapperService.getAdditionalConferenceInfo(room, true);
            const conferenceInfo = { ...commonConferenceInfo, ...additionalConferenceInfo };
            this.rtcService.setConferenceInfo(conferenceInfo);
            this.zone.run(() => {
                this.router.navigate(['conference/call']);
            });
        } else {
            if (this.hostToken === '') {
                this.guestAuthService
                    .login({
                        jiomeetId: room.roomDetails.jiomeetId,
                        roomID: room.roomDetails.roomID,
                        roomPin: room.roomDetails.roomPin || room.roomDetails.roomPIN,
                        name: this.name
                            ? this.name
                            : (this.name.length <= 17 ? this.name : this.name.substr(0, 17) + '...') + ' (Guest)',
                        phoneNo: '',
                        emailId: '',
                        recorderToken: ''
                    })
                    .subscribe((res) => {
                        this.userService.isGuestLogin(true);
                        this.userService.setGuestUser(name);
                        const commonConferenceInfo = {
                            isInitiater: false,
                            joiningName: this.name.trim() + ' (Guest)',
                            room: room.roomDetails,
                            ownerDetails: room.ownerDetails,
                            hideMoreCallControls: this.hideMoreCallControls,
                            hideAllCallControls: this.hideAllCallControls,
                            isFullScreenShare: this.isFullScreenShare
                        };
                        const additionalConferenceInfo = this.videoWrapperService.getAdditionalConferenceInfo(
                            room,
                            true
                        );
                        const conferenceInfo = { ...commonConferenceInfo, ...additionalConferenceInfo };
                        this.rtcService.setConferenceInfo(conferenceInfo);
                        this.zone.run(() => {
                            this.router.navigate(['conference/call']);
                        });
                    });
            } else {
                this.guestAuthService
                    .loginWithHostToken({
                        jiomeetId: room.roomDetails.jiomeetId,
                        roomID: room.roomDetails.roomID,
                        roomPin: room.roomDetails.roomPin || room.roomDetails.roomPIN,
                        name: this.name.length <= 17 ? this.name : this.name.substr(0, 17) + '...',
                        phoneNo: '',
                        emailId: '',
                        hostToken: this.hostToken
                    })
                    .subscribe((res) => {
                        this.userService.isGuestLogin(true);
                        this.userService.setGuestUser(name);
                        const commonConferenceInfo = {
                            isInitiater: false,
                            joiningName: this.name.trim(),
                            room: room.roomDetails,
                            ownerDetails: room.ownerDetails,
                            hideMoreCallControls: this.hideMoreCallControls,
                            hideAllCallControls: this.hideAllCallControls,
                            isFullScreenShare: this.isFullScreenShare
                        };
                        const additionalConferenceInfo = this.videoWrapperService.getAdditionalConferenceInfo(
                            room,
                            true
                        );
                        const conferenceInfo = { ...commonConferenceInfo, ...additionalConferenceInfo };
                        this.rtcService.setConferenceInfo(conferenceInfo);
                        this.zone.run(() => {
                            this.router.navigate(['conference/call']);
                        });
                    });
            }
        }
    }
    setHostOrCoHost(room) {
        const { ownerDetails, roomDetails } = room;
        const { coHosts } = roomDetails;

        const isCurrentUserHost = ownerDetails?.userId === this.currentUser._id;
        const isCurrentUserCoHost = coHosts?.some((coHost) => coHost?.userId === this.currentUser._id);

        this.rtcService.isHostOrCoHostOfMeeting = isCurrentUserHost || isCurrentUserCoHost;
    }
}
