<video
    #target
    id="my-player"
    class="video-js vjs-default-skin vjs-big-play-centered vjs-show-big-play-button-on-pause"
    playsinline
>
    <source *ngIf="isSourceAvailable" src="{{ options.sources[0].src }}" type="{{ options.sources[0].type }}" />
    <p class="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a web browser that
        <a href="https://videojs.com/html5-video-support/" target="_blank"> supports HTML5 video </a>
    </p>
</video>
