import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { APP_EVENTS } from 'src/app/constants';
import {
    AppService,
    AuthService,
    DiagnosticService,
    EventData,
    EventEmitterService,
    GoogleTagManagerService,
    UtilService,
    DownloadAppService
} from 'src/app/core';
import { GA_EVENTS } from '../ga-events';
import { GA_PAGEVIEW } from '../ga-pageview';
import { GA4_EVENTS } from '../ga4-events';

@Component({
    selector: 'app-website-download',
    templateUrl: './website-download.component.html',
    styleUrls: ['../website.usecases.scss', './website-download.component.scss']
})
export class WebsiteDownloadComponent implements OnInit, AfterViewInit {
    isMobile = false;
    isAuthenticated = false;
    currentLang = '';
    JioMeetDownload = 'JioMeetDownload';
    downloadableApps = [
        {
            title: 'JioMeet for Desktop',
            ttitle: 'website_download.jiomeet_for_desktop',
            imgMob: 'assets/img/download/jiomeet-desktop-v2.png',
            imgDesktop: 'assets/img/download/jiomeet-desktop-v2.png',
            altText: 'JioMeet for Desktop',
            id: 'desktop',
            appTypes: [
                {
                    title: 'Download for Windows',
                    ttitle: 'website_download.download_for_windows',
                    os: 'window'
                },
                { title: 'Download for Mac', ttitle: 'website_download.download_for_mac', os: 'mac' }
            ],
            desc: [
                {
                    text: 'The Desktop versions of JioMeet supports - all the Windows versions from Windows version 10 and above with min. 4GB RAM, all Mac versions 10.13 and above with min. 2GB RAM. ',
                    ttext: 'website_download.jiomeet_for_desktop_desc'
                }
            ]
        },
        {
            title: 'JioMeet Mobile App',
            ttitle: 'website_download.jiomeet_for_app',
            imgDesktop: 'assets/img/download/jiomeet-mobile-v2.png',
            imgMob: 'assets/img/download/jiomeet-mobile-v2.png',
            altText: 'Jiomeet for Mobile App',
            id: 'mobile',
            appTypes: [
                {
                    title: 'Download for Android',
                    ttitle: 'website_download.download_for_android',
                    os: 'android'
                },
                { title: 'Download for iOS', ttitle: 'website_download.download_for_ios', os: 'ios' }
            ],
            desc: [
                {
                    text: 'We support IOS and Android version– 5.0 and above with minimum 2GB RAM.',
                    ttext: 'website_download.jiomeet_for_app_desc'
                }
            ]
        },
        {
            title: 'JioMeet for Web Browser',
            ttitle: 'website_download.jiomeet_for_web',
            imgDesktop: 'assets/img/download/jiomeet-web-browser.png',
            imgMob: 'assets/img/download/jiomeet-web-browser.png',
            altText: 'JioMeet for Web Browser',
            id: 'web',
            appTypes: [{ title: 'Open in my Browser', ttitle: 'website_download.open_in_web', os: 'web' }],

            desc: [
                {
                    text: 'JioMeet is supported on browsers – Chrome and Safari.<br />Google Chrome – Version 83 and later.<br />Safari – Version 13.1.1 and later.',
                    ttext: 'website_download.jiomeet_for_web_desc'
                }
            ]
        }
    ];

    addIns = [
        {
            title: 'JioMeet Bot for Teams',
            ttitle: 'website_download.jiomeet_bot_for_teams',
            appTypes: [{ title: 'Download', ttitle: 'website_download.download', os: 'team' }],
            img: 'assets/img/download/jiomeet-bot-team.svg',
            imgMobile: 'assets/img/download/jiomeet-bot-team-mob.svg',
            desc: [
                {
                    text: 'Start or join JioMeet instantly with easy sign in options, add-ins for Microsoft Teams.',
                    ttext: 'website_download.jiomeet_bot_for_teams_desc'
                }
            ]
        },
        {
            title: 'JioMeet for Outlook',
            ttitle: 'website_download.jiomeet_for_outlook',
            appTypes: [{ title: 'Download', ttitle: 'website_download.download', os: 'outlook' }],
            img: 'assets/img/download/jiomeet-bot-outlook.svg',
            imgMobile: 'assets/img/download/jiomeet-bot-outlook-mob.svg',
            desc: [
                {
                    text: 'Designed to schedule and manage meetings within the Outlook web and other Desktop Apps.',
                    ttext: 'website_download.jiomeet_for_outlook_desc'
                }
            ]
        }
    ];
    queryParams: any = {};

    constructor(
        private utilService: UtilService,
        private router: Router,
        private authService: AuthService,
        private appService: AppService,
        private diagnosticService: DiagnosticService,
        private activatedRoute: ActivatedRoute,
        private eventEmitterService: EventEmitterService,
        private translateService: TranslateService,
        private gTagService: GoogleTagManagerService,
        private downloadAppService: DownloadAppService
    ) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser(1080);
        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
        });
        this.activatedRoute.queryParams.subscribe((params) => (this.queryParams = params));
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 1080 ? false : true;
            }
        });
        this.currentLang = this.appService.getAndSaveUserPreferredLang();
        this.translateService.onLangChange.subscribe(() => {
            this.currentLang = this.appService.getAndSaveUserPreferredLang();
        });
        this.gTagService.pushOnDataLayer(GA_PAGEVIEW.DOWNLOAD_JIOMEET_PAGE_VIEW);
    }

    ngAfterViewInit() {
        if (this.queryParams.scrollTo)
            this.utilService.scrollToAnchor(this.queryParams.scrollTo, 1000, { block: 'end' });
    }

    handlePlatformClick(os) {
        switch (os) {
            case 'web': {
                this.gTagService.sendEvent(GA_EVENTS.OPEN_IN_BROWSER);
                this.gTagService.sendEvent(GA4_EVENTS.OPEN_IN_BROWSER);
                this.navToWebApp();
                break;
            }
            case 'android': {
                this.gTagService.sendEvent(GA_EVENTS.DOWNLOAD_ANDROID);
                this.gTagService.sendEvent(GA4_EVENTS.DOWNLOAD_ANDROID);
                this.downloadAppService.downloadJioMeetForOS('Android');
                break;
            }
            case 'ios': {
                this.gTagService.sendEvent(GA_EVENTS.DOWNLOAD_IOS);
                this.gTagService.sendEvent(GA4_EVENTS.DOWNLOAD_IOS);
                this.downloadAppService.downloadJioMeetForOS('iOS');
                break;
            }
            case 'window': {
                this.gTagService.sendEvent(GA_EVENTS.DOWNLOAD_WINDOWS);
                this.gTagService.sendEvent(GA4_EVENTS.DOWNLOAD_WINDOWS);
                this.downloadAppService.downloadJioMeetForOS('Windows');
                break;
            }
            case 'mac': {
                this.gTagService.sendEvent(GA_EVENTS.DOWNLOAD_MAC);
                this.gTagService.sendEvent(GA4_EVENTS.DOWNLOAD_MAC);
                this.downloadAppService.downloadJioMeetForOS('Mac OS');
                break;
            }
            case 'outlook': {
                this.gTagService.sendEvent(GA_EVENTS.DOWNLOAD_JIOMEET_OUTLOOK);
                this.gTagService.sendEvent(GA4_EVENTS.DOWNLOAD_JIOMEET_OUTLOOK);
                window.open('https://appsource.microsoft.com/en-us/product/office/wa200002842?tab=overview', '_blank');
                break;
            }
            case 'team': {
                this.gTagService.sendEvent(GA_EVENTS.DOWNLOAD_JIOMEET_TEAMS);
                this.gTagService.sendEvent(GA4_EVENTS.DOWNLOAD_JIOMEET_TEAMS);
                window.open('https://appsource.microsoft.com/en-us/product/office/wa200002809?tab=overview', '_blank');
                break;
            }
            default:
                break;
        }
    }

    navToWebApp() {
        if (this.isAuthenticated) {
            this.router.navigate(['/meetings']);
        } else {
            this.navToHome();
        }
    }

    navToHome() {
        this.router.navigate(['home']);
    }

    navToSignIn() {
        this.router.navigate(['login']);
    }

    navigateToAppStore(os) {
        let url;
        if (os === 'android') {
            url = this.appService.getConfigVariable('ANDROID_INSTALLER_URL');
            this.diagnosticService
                .sendEvent({
                    eventAction: 'Download on Google Play',
                    eventCategory: 'Download App',
                    eventType: 'app_event',
                    status: 'success'
                })
                .subscribe();
        } else if (os === 'ios') {
            url = this.appService.getConfigVariable('IOS_INSTALLER_URL');
            this.diagnosticService
                .sendEvent({
                    eventAction: 'Download on iOS',
                    eventCategory: 'Download App',
                    eventType: 'app_event',
                    status: 'success'
                })
                .subscribe();
        }

        if (url) {
            const appQueryParams = this.appService.getAppQueryParams();
            const utmSource = appQueryParams.get('utm_source');
            const utmMedium = appQueryParams.get('utm_medium');
            const utmCampaign = appQueryParams.get('utm_campaign');
            if (utmSource) {
                url += `&utm_source=${utmSource}`;
            }
            if (utmMedium) {
                url += `&utm_medium=${utmMedium}`;
            }
            if (utmCampaign) {
                url += `&utm_campaign=${utmCampaign}`;
            }
            window.open(url, '_blank');
        }
    }

    navigateToDownload() {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Download App',
                eventType: 'app_event',
                eventAction: 'Download on Windows',
                status: 'success'
            })
            .subscribe();
        window.open(this.utilService.getWindowsClient());
    }

    navigateToMacAppStore() {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Download App',
                eventType: 'app_event',
                eventAction: 'Download on App Store',
                status: 'success'
            })
            .subscribe();
        window.open(this.appService.getConfigVariable('MAC_INSTALLER_URL'), '_blank');
    }
}
