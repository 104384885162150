import { Injectable } from '@angular/core';

import { Howl, Howler } from 'howler';

import { LoggerService } from './logger.service';

@Injectable({
    providedIn: 'root'
})
export class AudioService {
    private filePathPrefix = `assets/audio/`;
    private audioMap: Map<string, any> = new Map();
    disable = true;

    constructor(private logger: LoggerService) {}

    loadAudio({ name, loop, files }) {
        if (!this.audioMap.get(name)) {
            this.audioMap.set(
                name,
                new Howl({
                    src: files.map((file) => `${this.filePathPrefix}/${file}`),
                    loop
                })
            );
        }
    }

    setVolume(value: number) {
        Howler.volume(value);
    }

    play({ name }) {
        if (this.disable) {
            return;
        }
        const sound = this.audioMap.get(name);
        if (sound) {
            return sound.play();
        } else {
            this.logger.error(`No sound found with name ${name}`);
            return null;
        }
    }

    stop({ name }) {
        if (this.disable) {
            return;
        }
        const sound = this.audioMap.get(name);
        if (sound) {
            return sound.stop();
        } else {
            this.logger.error(`No sound found with name ${name}`);
            return null;
        }
    }
}
