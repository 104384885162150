export const environment = {
    production: true,
    local: false,
    HOST_URL: 'https://jiomeetpro.jio.com/',
    BASE_URL: 'https://jiomeetpro.jio.com/api',
    CPASS_HOST_URL: 'https://platform.jiomeet.com/',
    EVENTS_URL: 'https://jioevents.com/',
    WS_URL: 'wss://jiomeetpro.jio.com/ws',
    JIO_CLOUD: {
        HOST: 'https://api.jiocloud.com',
        HOST_UPLOAD: 'https://upload.jiocloud.com'
    },
    PAYMENT_GATEWAY_URL: 'https://www.techjockey.com/ordernow/jiomeet'
};
