import { Pipe, PipeTransform } from '@angular/core';
interface Participant {
    id: string;
    participantData: {
        hasVideo: boolean;
    };
}
@Pipe({
    name: 'filterVideo',
    pure: false
})
export class FilterVideoPipe implements PipeTransform {
    transform(galleryParticipants: Participant[]): Participant[] {
        const filteredParticipants: Participant[] = [];
        for (const participant of galleryParticipants) {
            if (participant.participantData.hasVideo === true) {
                filteredParticipants.push(participant);
            }
        }
        return filteredParticipants.slice(0, 9);
    }
}
