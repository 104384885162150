import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    AppService,
    EventEmitterService,
    RoomConnectionService,
    VideoWrapperService,
    UtilService,
    ThirdPartyExternalIntegrationService,
    RtcService,
    EventData
} from 'src/app/core';
import { APP_EVENTS } from 'src/app/constants';
@Component({
    selector: 'app-call-speaking-on-mute',
    templateUrl: './call-speaking-on-mute.component.html',
    styleUrls: ['./call-speaking-on-mute.component.scss']
})
export class CallSpeakingOnMuteComponent implements OnInit {
    @Output() hideInCallBanner = new EventEmitter();
    @Output() makeAudioOnlyMode = new EventEmitter();
    @Input() type;
    showUnmuteCameraPopup = false;
    videoService;
    roomStatus;
    isMobileDevice;
    isThirdPartyExternalIntegration;
    isJMMeeting = false;
    yPos = '50px';
    constructor(
        private appService: AppService,
        private eventEmitterService: EventEmitterService,
        private videoWrapperService: VideoWrapperService,
        public rtcService: RtcService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        public utilService: UtilService,
        private roomConnectionService: RoomConnectionService
    ) {
        this.videoService = this.videoWrapperService.getVideoService();
    }

    ngOnInit(): void {
        this.showUnmuteCameraPopup = this.appService.cameraOnBeforeAudioOnlyMode;

        this.roomConnectionService.getRoomConnectionStatus$().subscribe((roomStatus) => {
            if (!roomStatus || roomStatus?.success === false) {
                return;
            }
            this.roomStatus = roomStatus;
        });
        this.isThirdPartyExternalIntegration =
            this.thirdPartyExternalIntegrationService.getThirdPartyExternalIntegration();
        this.isMobileDevice = this.isThirdPartyExternalIntegration || this.utilService.isMobileBrowser();
        this.isJMMeeting = this.rtcService.getIsJMMeeting();
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.FULL_SCREEN_EVENT) {
                this.yPos = event.data ? '75px' : '50px';
            }
        });
    }

    closeBanner() {
        this.hideInCallBanner.emit();
        this.appService.cameraOnBeforeAudioOnlyMode = false;
    }

    turnOnCamera() {
        if (this.videoService?.hideNonVideoSelected) {
            this.eventEmitterService.emit({
                type: APP_EVENTS.DISABLE_HIDE_NON_VIDEO_MESSAGE,
                data: {}
            });
            return;
        }
        this.eventEmitterService.emit({
            type: APP_EVENTS.TOGGLE_CAMERA,
            data: {}
        });
        this.appService.cameraOnBeforeAudioOnlyMode = false;
        this.hideInCallBanner.emit();
    }

    unmuteUser() {
        this.eventEmitterService.emit({
            type: APP_EVENTS.JM_UNMUTE,
            data: {}
        });
        this.hideInCallBanner.emit();
        return;
    }
    enableAudioOnlyMode() {
        if (this.videoService?.hideNonVideoSelected) {
            this.eventEmitterService.emit({
                type: APP_EVENTS.DISABLE_HIDE_NON_VIDEO_MESSAGE,
                data: {}
            });
            this.hideInCallBanner.emit();
            return;
        }
        this.hideInCallBanner.emit();
        this.makeAudioOnlyMode.emit();
    }
}
