import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { EventEmitterService } from 'src/app/core';
import { Subscription } from 'rxjs';
import { RoomConnectionService } from 'src/app/core/services/room-connection.service';

@Component({
    selector: 'app-popoup-chat-v2',
    templateUrl: './popoup-chat-v2.component.html',
    styleUrls: ['./popoup-chat-v2.component.scss']
})
export class PopoupChatV2Component implements OnInit, OnDestroy {
    @Input() meetingObj;
    show = false;
    subscriptions: Subscription[] = [];
    constructor(
        private eventEmitterService: EventEmitterService,
        private roomConnectionService: RoomConnectionService
    ) {}

    ngOnInit() {
        this.eventEmitterService.subscribe((event) => {
            switch (event.type) {
                case 'POPOUT_CHAT':
                    this.show = event.data;
                    var controls = this.roomConnectionService.getHightlightedControls();
                    controls = { ...controls, popoutchat: this.show };
                    this.roomConnectionService.updateHighlightController(controls);
                    break;
            }
        });
    }

    close(event) {
        var controls = this.roomConnectionService.getHightlightedControls();
        controls = { ...controls, popoutchat: false };
        this.roomConnectionService.updateHighlightController(controls);
        this.show = false;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
