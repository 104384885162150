import { InsideCallChatComponent } from './inside-call-chat/inside-call-chat.component';
import { PopoutChatComponent } from './popout-chat/popout-chat.component';
import { EmojiBoxComponent } from './emoji-box/emoji-box.component';
import { PrivateChatComponent } from './private-chat/private-chat.component';
import { AttachmentListComponent } from './attachment-list/attachment-list.component';
import { ChatMarkdownComponent } from './chat-markdown/chat-markdown.component';
import { InsideCallChatV2Component } from './inside-call-chat-v2/inside-call-chat-v2.component';
import { PrivateChatV2Component } from './private-chat-v2/private-chat-v2.component';
import { PopoupChatV2Component } from './popoup-chat-v2/popoup-chat-v2.component';

export * from './audio-clappr/audio-clappr.component';
export * from './video-clappr/video-clappr.component';
export * from './doc-viewer/doc-viewer.component';
export * from './image-viewer/image-viewer.component';
export * from './inside-call-chat/inside-call-chat.component';
export * from './viewer/viewer.component';
export * from './upload-attachment/upload-attachment.component';
export * from './chat-attachment/chat-attachment.component';
export * from './popout-chat/popout-chat.component';
export * from './private-chat/private-chat.component';
export * from './attachment-list/attachment-list.component';
export * from './chat-markdown/chat-markdown.component';
export * from './inside-call-chat-v2/inside-call-chat-v2.component';
export * from './private-chat-v2/private-chat-v2.component';
export * from './popoup-chat-v2/popoup-chat-v2.component';

export const chatRelatedComponents = [
    InsideCallChatComponent,
    PopoutChatComponent,
    EmojiBoxComponent,
    PrivateChatComponent,
    AttachmentListComponent,
    ChatMarkdownComponent,
    InsideCallChatV2Component,
    PrivateChatV2Component,
    PopoupChatV2Component
];
