import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { AppService } from './app.service';
import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root'
})
export class VcRoomService {
    constructor(private appService: AppService, private restService: RestService) {}

    getVCRooms({ offset = 0, limit = 500, query = '' }) {
        return this.restService.get(`api/vc?keyword=${query}&offset=${offset}&limit=${limit}`);
    }

    getPrivateIp() {
        return this.restService.get('api/config/tenantconfig');
    }
}
