import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { LocalStorageService } from './local-storage.service';
import { AppService } from './app.service';
import { tap } from 'rxjs/operators';
import { APP_EVENTS } from 'src/app/constants';
import { EventEmitterService } from './event-emitter.service';
import { RoomConnectionService } from './room-connection.service';
import { SystemService } from './system.service';

@Injectable({
    providedIn: 'root'
})
export class GuestAuthService {
    guestAuthInfo;
    constructor(
        private restService: RestService,
        private localstorageService: LocalStorageService,
        private appService: AppService,
        private eventEmitterService: EventEmitterService,
        private roomConnectionService: RoomConnectionService,
        private systemService: SystemService
    ) {}

    login({ jiomeetId, roomID, roomPin, name, phoneNo, emailId, recorderToken = '', isLiveStreaming = false }) {
        const payload = {
            jiomeetId,
            roomID,
            roomPin,
            name,
            phoneNo,
            emailId,
            ...(recorderToken && { recorderToken }),
            machineIp: '1.1.1.1',
            participantEndPointName: 'web',
            participantEndPointType: this.systemService.getClientDeviceType(),
            isLiveStreaming
        };
        return this.restService.post(`api/roomconnectionstatus/login`, payload).pipe(
            tap((authInfo) => {
                this.setAuthInfo(authInfo);
            })
        );
    }

    loginWithHostToken({ jiomeetId, roomID, roomPin, name, phoneNo, emailId, hostToken }) {
        const payload = {
            jiomeetId,
            roomID,
            roomPin,
            name,
            phoneNo,
            emailId,
            machineIp: '1.1.1.1',
            participantEndPointName: 'web',
            participantEndPointType: this.systemService.getClientDeviceType(),
            hostToken
        };
        return this.restService.post(`api/roomconnectionstatus/login`, payload).pipe(
            tap((authInfo) => {
                this.setAuthInfo(authInfo);
                this.setAuthInfoForHost(authInfo);
            })
        );
    }

    setAuthInfoForHost(authInfo) {
        this.localstorageService.addItem('userInfo', authInfo);
    }

    setAuthInfo(authInfo) {
        // this.localstorageService.addItem('guestAuthInfo', authInfo);
        this.guestAuthInfo = authInfo;
        this.appService.setGuestUserId(authInfo?.guestUserId);
        this.eventEmitterService.emit({
            type: APP_EVENTS.AUTHORIZED,
            data: null
        });
    }

    getAuthInfo() {
        return this.guestAuthInfo || this.localstorageService.getItem('userInfo');
    }

    isGuestRequest(request) {
        return [
            '/guest',
            // '/chat/getThreadForChat/postmessage/guest',
            // '/chat/getThreadForChat/messages/guest',
            // '/chat/getThreadForChat/guest',
            '/chat/thread',
            '/leave'
        ].filter((path) => request.url.indexOf(path) > -1).length;
    }

    cleanup() {
        this.guestAuthInfo = null;
        this.localstorageService.removeItem('userInfo');
    }
}
