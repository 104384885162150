import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from 'src/app/core';

@Pipe({
    name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {
    constructor(private utilService: UtilService) {}
    urlRegex =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9](\.[^\s]{2,}|:[0-9]{1,5}[^\s]{0,})|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+(\.[^\s]{2,}|:[0-9]{1,5}[^\s]{0,})|http?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9](\.|:[0-9]{1,5})[^\s]{0,}|http?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+(\.|:[0-9]{1,5})[^\s]{0,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    emailRegex = /^(?!\.)(?!.*\.$)(?!.*?\.\.\-)[a-zA-Z0-9.-_][a-zA-Z0-9.\-_]*\@[a-zA-Z0-9.\-_]+$/;
    tagsToReplace = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;'
    };

    public instanceValue(value): string {
        return value.includes('http') || value.includes('https') ? value : `http://${value}`;
    }
    public transformer(value: string, replaceProtocol): string {
        return this.linkifyEmail(this.linkifyUrl(value));
    }
    linkifyUrl(text) {
        return text.replace(this.urlRegex, function (url) {
            return '<a href="' + url + '" target="_blank">' + url + '</a>';
        });
    }
    linkifyEmail(text) {
        return text.replace(this.emailRegex, function (email) {
            return '<a href="mailto:' + email + '">' + email + '</a>';
        });
    }
    transform(value: string, replaceProtocol = false): any {
        //to prevent html tags from escaping in innerHtml attr
        value = this.utilService.htmlDecode(value);
        if (value.includes(' ')) {
            const valueArr = value.split(' ');
            return valueArr
                .map((value) => {
                    return this.transformer(value, replaceProtocol);
                })
                .join(' ');
        } else {
            value = value.replace(/&amp;/g, '&');
            if (/[&<>]/g.test(value)) {
                value = value.replace(/[&<>]/g, (tag) => {
                    return this.tagsToReplace[tag] || tag;
                });
            }
            // detect url in string
            return this.transformer(value, replaceProtocol) || '';
        }
    }
}
