import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard, CheckForServerDownGuard, AuthGuardChat, AuthGuardContacts } from './core';

import * as fromComponents from './components';

import { sharedRoutes } from './shared-routes';
import { CallPreviewMobileComponent } from './shared/components/call-preview-mobile/call-preview-mobile.component';

const routes: Routes = [
    ...sharedRoutes,
    {
        path: 'login',
        component: fromComponents.LoginComponent,
        data: { showHeader: true, showFooter: false },
        canActivate: []
    },
    {
        path: 'thankyou',
        component: fromComponents.ThankyouComponent,
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'signup',
        component: fromComponents.SignUpComponent,
        data: { showHeader: true, showFooter: false },
        canActivate: [AuthGuard]
    },
    {
        path: 'feedback',
        component: fromComponents.CustomerFeedbackComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'press-release',
        component: fromComponents.WebsitePressReleaseComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'host',
        component: fromComponents.HostMeetingComponent,
        data: { showHeader: true, showFooter: false },
        canActivate: [AuthGuard]
    },
    {
        path: 'platform/join',
        component: fromComponents.JoinCallComponent,
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'join',
        component: fromComponents.JoinCallComponent,
        data: { showHeader: true, showFooter: false }
    },
    {
        path: 'call-preview-screen',
        component: CallPreviewMobileComponent,
        data: { showHeader: true, showFooter: false },
        canActivate: [AuthGuard]
    },
    {
        path: 'join-meeting',
        component: fromComponents.JoinMeetingComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'guest',
        component: fromComponents.GuestFormComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'mattermost',
        component: fromComponents.MattermostComponent,
        data: { showHeader: false, showFooter: false },
        canActivate: []
    },
    {
        path: 'verification',
        component: fromComponents.VerificationComponent,
        data: { showHeader: false, showFooter: false },
        canActivate: []
    },
    {
        path: 'reset-password',
        component: fromComponents.ResetPasswordComponent,
        data: { showHeader: true, showFooter: false },
        canActivate: []
    },
    {
        path: 'forgot-password',
        component: fromComponents.ForgotPasswordComponent,
        data: { showHeader: true, showFooter: false },
        canActivate: []
    },
    {
        path: 'server-down',
        component: fromComponents.ServerDownComponent,
        data: { showHeader: false, showFooter: false },
        canActivate: [CheckForServerDownGuard]
    },
    {
        path: 'webhelp',
        loadChildren: () => import('./help/help.module').then((m) => m.HelpModule),
        data: { showHeader: true, showFooter: false },
        canActivate: []
    },
    {
        path: 'home',
        component: fromComponents.WebsiteComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: [AuthGuard]
    },
    {
        path: 'main',
        component: fromComponents.WebsiteComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
    },
    {
        path: 'contactsales',
        component: fromComponents.ContactSalesComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'pricing',
        component: fromComponents.PricingComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'contact-us',
        component: fromComponents.ContactUsComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'jiomeet',
        component: fromComponents.WebsiteRetailsComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'enterprise',
        component: fromComponents.WebsiteEnterpriseComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'register/conference',
        component: fromComponents.WebsiteConferenceComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'events',
        component: fromComponents.WebsiteEventsComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'education',
        component: fromComponents.WebsiteEducationComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'healthcare',
        component: fromComponents.WebsiteHealthcareComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'hybridWorkplace',
        component: fromComponents.WebsiteHybridWorkplaceComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'platformServices',
        component: fromComponents.WebsitePlatformServicesComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'govtSectors',
        component: fromComponents.WebsiteGovtSectorsComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'cpaas',
        component: fromComponents.WebsiteCpaasComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'updateMetaData',
        component: fromComponents.WebsiteConfigComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'jiomeetDownload',
        component: fromComponents.WebsiteDownloadComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'conference',
        loadChildren: () => import('./conference/conference.module').then((m) => m.ConferenceModule),
        canActivate: []
    },

    {
        path: 'onboarding',
        component: fromComponents.OnboardingComponent,
        data: { showHeader: true, showFooter: false },
        canActivate: [AuthGuard]
    },
    {
        path: 'webinar/:id',
        loadChildren: () => import('./webinar-attendee/webinar-attendee.module').then((m) => m.WebinarAttendeeModule),
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'integrations',
        loadChildren: () => import('./integrations/integrations.module').then((m) => m.IntegrationsModule),
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'mst',
        loadChildren: () => import('./mst-integration/mst-integration.module').then((m) => m.MstIntegrationModule),
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'meetings',
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
        data: { showHeader: true }
    },
    {
        path: 'chat',
        loadChildren: () => import('./chat-commons/chat-commons.module').then((m) => m.ChatCommonsModule),
        canActivate: [AuthGuardChat],
        data: { showHeader: true }
    },
    {
        path: 'contacts',
        loadChildren: () => import('./contacts/contacts.module').then((m) => m.ContactsModule),
        canActivate: [AuthGuardContacts],
        data: { showHeader: true }
    },
    {
        path: 'dashboard',
        pathMatch: 'full',
        redirectTo: 'meetings'
    },
    {
        path: 'getClient',
        component: fromComponents.GetClientComponent,
        data: { showHeader: false, showFooter: false }
    },
    {
        // component for playing recording
        path: 'mediaplayer',
        component: fromComponents.RecordingPlayerComponent,
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'downloadICSFile',
        component: fromComponents.DownloadIcsfileComponent,
        data: { showHeader: false, showFooter: false },
        canActivate: []
    },
    {
        path: 'delete-account',
        component: fromComponents.DeleteAccountComponent,
        data: { showHeader: true, showFooter: true },
        canActivate: []
    },
    {
        path: 'subscription',
        component: fromComponents.PaymentSuccessFailureComponent,
        data: { showHeader: false, showFooter: false },
        canActivate: []
    },
    {
        path: 'precalltesting',
        loadChildren: () => import('./pre-call-testing/pre-call-testing.module').then((m) => m.PreCallTestingModule),
        canActivate: [],
        data: { showHeader: true }
    },
    {
        path: 'watch-party',
        loadChildren: () => import('./watch-party/watch-party.module').then((m) => m.WatchPartyModule)
    },
    {
        path: 'recorder-bot',
        component: fromComponents.RecorderBotComponent,
        data: { showHeader: false, showFooter: false }
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            anchorScrolling: 'enabled',
            onSameUrlNavigation: 'reload',
            relativeLinkResolution: 'legacy'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
