<div class="notif-logger" [ngClass]="{ 'is-large-meeting': isLargeMeeting }">
    <strong class="pointer" *ngIf="messageType === 'NEW_MESSAGE'" (click)="onClick('NEW_MESSAGE')">
        {{ 'inside_call.call_controls.new_public_msg' | translate }}
    </strong>
    <strong *ngIf="messageType === 'GROUP_CHAT'"
        >{{ 'inside_call.call_controls.new_message_in_group_chat' | translate }}
        <span (click)="onClick('GROUP_CHAT', null)"> {{ 'inside_call.call_controls.group_chat' | translate }}</span>
        {{ 'inside_call.call_controls.in' | translate }}
    </strong>
    <strong *ngIf="messageType === 'PRIVATE_CHAT' && unreadParticipantCount <= 1">
        {{ 'inside_call.call_controls.new_msg' | translate }}
        {{ 'inside_call.call_controls.new_msg_hi' | translate }}
        <span (click)="onClick('PRIVATE_CHAT', toastObject)">{{ toastObject.name }}</span>
        {{ 'inside_call.call_controls.new_private_msg' | translate }}
    </strong>
    <strong *ngIf="messageType === 'PRIVATE_CHAT' && unreadParticipantCount > 1">
        {{ 'inside_call.call_controls.new_msg' | translate }}
        {{ 'inside_call.call_controls.new_msg_hi' | translate }}
        <span (click)="onClick('PRIVATE_CHAT', toastObject)">{{ toastObject.name }}</span>
        {{ 'inside_call.call_controls.new_private_msg' | translate }} + {{ unreadParticipantCount - 1 }}
        {{ 'inside_call.call_controls.others' | translate }}
    </strong>
    <img alt="" src="assets/img/close_chat_logger.svg" alt="" (click)="close()" />
</div>
