import { OnInit, Component, ElementRef, Input, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
    VideoWrapperService,
    RtcService,
    EventEmitterService,
    GoogleTagManagerService,
    UtilService
} from 'src/app/core';
import { Microphone, Speaker } from 'src/app/shared/interfaces/audio-settings';
import DecibelMeter from 'decibel-meter';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UserService, AuthService, AppService } from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';

import { APP_EVENTS } from '../../../../constants';
import { GA_EVENTS } from '../ga-events';
import { GA_NEW } from 'src/app/shared/ga-new';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';

export enum DEFAULT_IO {
    DEFAULT = 'default',
    COMMUNICATIONS = 'communications'
}
@Component({
    selector: 'app-audio-settings',
    templateUrl: './audio-settings.component.html',
    styleUrls: ['./audio-settings.component.scss']
})
export class AudioSettingsComponent implements OnInit, OnDestroy {
    @Input() isMobile;
    private videoService;
    public availableAudio: {
        speakers: Speaker[];
        mics: Microphone[];
    } = {
        speakers: [],
        mics: []
    };
    public selectedSpeakerId;
    public selectedMicId;
    public selectedSpeaker: Speaker;
    public selectedMic: Microphone;
    public meter = new DecibelMeter();

    public testingControl = {
        speaker: false,
        mic: false
    };

    private timeoutId;
    turnOffAudio = this.userService.getUserSync()?.microphoneOff;
    enableNoiseSuppression: boolean;
    isGuestUser;
    showPermissionUI: boolean = false;
    isMobileDevice = false;
    isJMMeeting = false;
    isNoiseSuppressionLoading = false;
    noiseSuppressionEnabled;
    currentUser: any = {};

    @ViewChild('audioOutput') audioOutputSelect: ElementRef<HTMLSelectElement>;
    @ViewChildren('level') micLevels: QueryList<ElementRef>;
    subsciption: Subscription[] = [];
    isnoiseSuppression: any;
    constructor(
        private toastrService: ToastrService,
        private videoWrapperService: VideoWrapperService,
        public rtcService: RtcService,
        private userService: UserService,
        private translateService: TranslateService,
        private authService: AuthService,
        private appService: AppService,
        private eventEmitterService: EventEmitterService,
        private gTagService: GoogleTagManagerService,
        private utilService: UtilService,
        private appLoggerService: AppLoggerService
    ) {
        this.videoService = this.videoWrapperService.getVideoServiceForPreview();
    }

    ngOnInit() {
        this.noiseSuppressionEnabled = this.appService.getConfigVariable('ENABLE_NOISE_DENOISER');
        this.subsciption.push(
            this.videoService.getParticipantStatus().subscribe((status) => {
                if (!status) return;
                this.timeoutId = setTimeout(() => {
                    this.availableAudio = {
                        speakers: this.videoService.speakers.map((speaker, idx) => {
                            if (!speaker.name) speaker.name = `Speaker ${idx + 1}`;
                            return speaker;
                        }),
                        mics: this.videoService.microphones.map((mic, idx) => {
                            if (!mic.name) mic.name = `Microphone ${idx + 1}`;
                            return mic;
                        })
                    };
                    this.selectedMicId = this.videoService.selectedLocalMicrophone;
                    this.selectedSpeakerId = this.videoService.selectedLocalSpeaker;
                    this.selectedSpeaker = this.availableAudio.speakers.find(
                        (speaker) => speaker.id === this.selectedSpeakerId
                    );
                    this.selectedMic = this.availableAudio.mics.find((mic) => mic.id === this.selectedMicId);
                }, 100);
            })
        );
        this.isMobileDevice = this.utilService.isMobileBrowser();
        this.isJMMeeting = this.rtcService.getIsJMMeeting();
        this.isGuestUser = !this.authService.getIsAuthenticated();
        this.eventEmitterService.subscribe((data) => {
            if (data?.type === APP_EVENTS.SHOW_PERMISSION_UI) {
                this.showPermissionUI = data?.data;
            }
        });
        this.currentUser = this.userService.getUserSync();
        this.enableNoiseSuppression =
            this.appService.getIsNoiseSuppression &&
            (this.currentUser?.mediaEnginePreference === 'agora' ||
                this.currentUser?.mediaEnginePreference === 'jm-media');
    }

    inputVolume(state) {
        let amountOfLevels;
        let range;
        this.micLevels.map((level) => {
            level.nativeElement.style.backgroundColor = '#e6e7e8';
        });
        amountOfLevels = Math.round(state / 10);
        range = this.micLevels.toArray().slice(0, amountOfLevels);
        range.map((el: ElementRef) => {
            el.nativeElement.style.backgroundColor = '#69ce2b';
        });
    }

    selectSpeaker() {
        if (this.testingControl.speaker) {
            var audio = document.getElementById('testAudio') as HTMLAudioElement;
            audio.pause();
            this.testingControl.speaker = false;
        }
        this.videoService.manuallyChangeSpeaker(this.selectedSpeaker);
    }
    selectMicrophone() {
        this.testingControl = { ...this.testingControl, mic: false };
        this.videoService.manuallyChangeMicrophone(this.selectedMic);
    }
    testMic() {
        this.gTagService.sendEvent(GA_EVENTS.TEST_MIC);
        this.gTagService.sendEvent(GA_NEW.TEST_MIC);
        if (!this.availableAudio.mics.length) {
            this.showPermissionUI = true;
            return;
        }
        if (this.testingControl.mic) {
            this.gTagService.sendEvent(GA_NEW.SETTINGS_STOP_MIC);
            this.testingControl = { ...this.testingControl, mic: false };
            this.meter.disconnect();
            return;
        }
        this.meter.stopListening();
        this.testingControl = { ...this.testingControl, mic: true };
        let decibals = 0;
        this.meter = new DecibelMeter('mictest');
        this.meter.connectTo(this.selectedMic.id);
        this.meter.listenTo(this.selectedMic.id, (dB, percent) => {
            decibals = Number(`${dB}`) + 100;
            this.inputVolume(decibals);
        });
    }

    testSpeaker() {
        this.gTagService.sendEvent(GA_EVENTS.TEST_SPEAKER);
        this.gTagService.sendEvent(GA_NEW.TEST_SPEAKER);
        if (!this.availableAudio.speakers.length) {
            this.showPermissionUI = true;
            return;
        }
        var audio = document.getElementById('testAudio') as HTMLAudioElement;
        if (this.testingControl.speaker) {
            audio.pause();
            this.testingControl.speaker = false;
            this.gTagService.sendEvent(GA_NEW.SETTINGS_STOP_SPEAKER);
            return;
        }
        audio.play();
        this.testingControl.speaker = true;
    }

    onTurnOffAudio(event) {
        const el = event.target;
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translateService.instant('tostrs.something_went_right'));
            el.checked = !el.checked;
            return;
        }
        const isTurnOffAudio = el.checked;
        const audioCheckBox = isTurnOffAudio ? GA_EVENTS.AUDIO_ON_WHILE_CALL : GA_EVENTS.AUDIO_OFF_WHILE_CALL;
        const audioCheckBoxNew = isTurnOffAudio ? GA_NEW.AUDIO_ON_WHILE_CALL : GA_NEW.AUDIO_OFF_WHILE_CALL;
        this.gTagService.sendEvent(audioCheckBox);
        this.gTagService.sendEvent(audioCheckBoxNew);
        this.userService
            .updateProfile({
                microphoneOff: isTurnOffAudio
            })
            .subscribe(() => {});
    }

    onChangeNoiseSuppression(event) {
        this.isnoiseSuppression = event.target.checked;
        if (this.isnoiseSuppression) {
            this.gTagService.sendEvent(GA_NEW.SETTINGS_NOISE_SUPPRESSION_ENABLED);
        } else {
            this.gTagService.sendEvent(GA_NEW.SETTINGS_NOISE_SUPPRESSION_DISABLED);
        }
        this.appService.setIsNoiseSuppression = this.isnoiseSuppression;
        this.enableNoiseSuppression = this.appService.getIsNoiseSuppression;
    }
    async onChangeNoiseSuppressionAdv(event) {
        if (this.isNoiseSuppressionLoading) {
            return;
        }
        if (this.isJMMeeting) {
            try {
                this.isNoiseSuppressionLoading = true;
                await this.videoService.toggleNoiseSuppression();
                this.appService.setIsNoiseSuppression = !this.appService.getIsNoiseSuppression;
            } catch (error) {
                this.toastrService.error(this.translateService.instant('tostrs.something_went_wrong_please_try_again'));
                this.appLoggerService.error('Participant noise cancellation toggle error', error);
                return;
            } finally {
                this.isNoiseSuppressionLoading = false;
            }
        }
    }

    ngOnDestroy() {
        this.meter.stopListening();
        this.subsciption.forEach((s) => s.unsubscribe());
        clearTimeout(this.timeoutId);
    }
    //Accessability changes
    setFocusOnElement() {
        setTimeout(() => {
            const element: any = document.getElementById('profile');
            element.focus();
        }, 100);
    }
}
