<div class="branch-address">
    <div class="btn-card">
        <button type="button" class="custom-action-btn btn">
            <span> {{ addressInfo.head }} </span>
        </button>
        {{ addressInfo.office }}
    </div>
    <div class="card-content">
        <span>{{ addressInfo.landmark }}</span>
        <span> {{ addressInfo.number }}</span>
        <span>{{ addressInfo.area }} </span>
        <span> {{ addressInfo.city }}</span>
        <div class="available">{{ addressInfo.hours }}</div>
    </div>
</div>
