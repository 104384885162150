import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import {
    AuthService,
    ValidationService,
    AppService,
    RememberMeService,
    LocalStorageService,
    UtilService,
    EventEmitterService,
    GaDiagnosticService,
    DiagnosticService,
    UserService,
    OauthService
} from './../../core';

import { APP_EVENTS, REG_EX } from 'src/app/constants';

@Component({
    selector: 'app-oauth',
    templateUrl: './oauth.component.html',
    styleUrls: ['./oauth.component.scss']
})
export class OauthComponent implements OnInit {
    constructor(
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private router: Router,
        private appService: AppService,
        private authService: AuthService,
        private validationService: ValidationService,
        private rememberMeService: RememberMeService,
        private localStorageService: LocalStorageService,
        private gaService: GaDiagnosticService,
        private eventEmitterService: EventEmitterService,
        private diagnosticService: DiagnosticService,
        private utilService: UtilService,
        private oauthService: OauthService,
        private userService: UserService
    ) {}
    usernameForm: FormGroup;
    loginForm: FormGroup;
    isCpass: boolean = false;
    ottForm: FormGroup;
    errors: any = {};
    rememberMe = false;
    loggingIn = false;
    showPassword = false;
    childWindow = null;
    samlLoginInProgress = false;
    isDomainEmail = false;
    emailLogin = false;
    ottLogin = false;
    requestingOTP = false;
    sessionId;
    token;
    success;
    enableProceedBtn = false;
    bot;
    returnUrl;
    loading = true;
    clientId;
    state;
    scope;
    responseType;
    redirect_uri;
    showConsentForm = false;
    showErrorForm = false;
    showUserForm = false;
    clientDetails;
    currentUser;
    profilePicPath;
    scopes = {};
    scopeData = '';
    errMsg = `Sorry the client doesn't exist.`;

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params) => {
            let url = this.router.url;
            this.isCpass = url.includes('oauth2/authorize');
            this.sessionId = params.sessionId;
            this.token = params.token;
            this.success = params.success;
            this.bot = params.bot;
            if (this.bot === 'true') {
                sessionStorage.setItem('bot', 'true');
            }
            this.returnUrl = params.returnUrl;
            if (this.returnUrl) {
                sessionStorage.setItem('returnUrl', this.returnUrl);
            }
            this.clientId = params.clientId || params.client_id;
            this.redirect_uri = params.redirect_uri;
            this.state = params.state;
            this.scope = params?.scope;
            this.responseType = params?.response_type;
        });

        this.usernameForm = this.fb.group({
            username: ['', [Validators.required]]
        });
        this.loginForm = this.fb.group({
            email: [null, [Validators.required]],
            password: [null, [Validators.required]],
            rememberMe: [false]
        });
        this.ottForm = this.fb.group({
            phoneNo: [null, [Validators.required, Validators.pattern(/^\d{10}$/)]],
            otp: [null],
            rememberMe: [false]
        });
        this.getClientDetails();

        if (this.sessionId) {
            if (!this.success || this.success === 'false') {
                this.loading = false;
                return;
            }
            if (this.authService.getIsAuthenticated()) {
                this.getUserDetails();
                return;
            }
            this.samlLoginInProgress = true;
            this.authService.getSamlAccessToken(this.sessionId, this.token).subscribe(
                () => {
                    this.checkAuthentication();

                    // this.navigateToLandingPage();
                },
                (err) => {
                    if (err.error && err.error.errors) {
                        this.errors.global = err.error.errors;
                    } else {
                        this.errors.global = 'Something went wrong. Please try again.';
                    }
                    this.samlLoginInProgress = false;
                    this.loggingIn = false;
                }
            );
            return;
        }

        if (this.authService.getIsAuthenticated()) {
            this.getUserDetails();

            return;
        }

        const rememberedUserData = this.rememberMeService.get('rememberMe');
        if (rememberedUserData) {
            // this.loginForm.get('email').setValue(rememberedUserData.email);
            this.usernameForm.get('username').setValue(rememberedUserData.username);
            this.loginForm.get('rememberMe').setValue(true);

            this.checkForDomainEmail(rememberedUserData.email);
        }

        this.loginForm.get('email').valueChanges.subscribe((email) => {
            this.checkForDomainEmail(email);
        });

        this.usernameForm
            .get('username')
            .valueChanges // .pipe(pairwise())
            .subscribe((next) => {
                this.checkForDomainEmail(next);
                this.enableProceedBtn = this.validateUsername(next, false);
            });

        this.gaService.sendPageView({
            page_title: 'login_page',
            page_path: '/login'
        });

        this.loading = false;
    }

    checkForDomainEmail(email) {
        this.isDomainEmail = false;
        if (this.validationService.isEmail(email)) {
            const providerName = this.appService
                .getConfigVariable('SAML_DOMAINS')
                .includes(this.utilService.getDomainFromEmail(email))
                ? 'saml'
                : this.utilService.getDomainFromEmail(email);
            this.authService.isDomainUser({ providerName }).subscribe(() => {
                this.isDomainEmail = true;
            });
        }
    }

    validateUsername(value = '', reportError = true) {
        const val = value || this.usernameForm.value.username;
        if (!(this.validationService.isEmail(val) || val.match(/^\d{10}$/))) {
            if (reportError) {
                this.errors.username = 'Please enter valid email or phoneNo.';
            }
            return false;
        }
        return true;
    }

    submitUsername() {
        this.errors = {};
        if (!this.validateUsername()) {
            return;
        }
        if (this.validationService.isEmail(this.usernameForm.value.username)) {
            if (this.isDomainEmail) {
                if (
                    this.appService
                        .getConfigVariable('SAML_DOMAINS')
                        .includes(this.utilService.getDomainFromEmail(this.usernameForm.value.username))
                ) {
                    this.authService
                        .doSSOLogin({
                            redirectRoute: `oauth?clientId=${this.clientId}&redirect_uri=${this.redirect_uri}${
                                this.state ? '&state=' + this.state : ''
                            }`,
                            queryParams: {
                                email: this.usernameForm.value.username,
                                bot: this.bot,
                                hostInfo: null,
                                outlook: false
                            }
                        })
                        .subscribe((res) => {
                            window.location.href = res.authUrl;
                        });
                } else {
                    this.authService
                        .doSSOLogin({
                            providerName: this.utilService.getDomainFromEmail(this.usernameForm.value.username),
                            redirectRoute: `oauth?clientId=${this.clientId}&redirect_uri=${this.redirect_uri}${
                                this.state ? '&state=' + this.state : ''
                            } `,
                            queryParams: {
                                email: null,
                                bot: this.bot,
                                hostInfo: null,
                                outlook: false
                            }
                        })
                        .subscribe((res) => {
                            window.location.href = res.authUrl;
                        });
                }
                return;
            }

            this.authService.isOTTUser({ email: this.usernameForm.value.username }).subscribe(
                (res: any) => {
                    // if (res.isOTT) {
                    this.emailLogin = true;
                    this.loginForm.patchValue({
                        email: this.usernameForm.value.username
                    });
                    // }
                },
                (err) => {
                    if (err.error.message === 'USERNOTFOUND') {
                        this.errors.global = 'You are not registered. Please signup.';
                    } else {
                        this.errors.global = err.error.errors;
                    }
                }
            );

            return;
        }

        if (this.usernameForm.value.username.match(/^\d{10}$/)) {
            this.authService.isOTTUser({ phoneNo: this.usernameForm.value.username }).subscribe(
                (res: any) => {
                    // if (res.isOTT) {
                    this.sendOTP(this.usernameForm.value.username);
                    // }
                },
                (err) => {
                    if (err.error.message === 'USERNOTFOUND') {
                        this.errors.global = 'You are not registered. Please signup.';
                    } else {
                        this.errors.global = err.error.errors;
                    }
                }
            );
        }
    }

    validate() {
        this.errors = {};

        if (!this.validationService.isEmail(this.loginForm.value.email)) {
            this.errors.email = 'Please enter a valid email';
            return false;
        }
        if (!this.isDomainEmail && !this.loginForm.value.password) {
            this.errors.password = 'Password cannot be blank';
            return false;
        }
        return true;
    }

    submitEmailPassword() {
        if (!this.validate()) {
            return;
        }

        if (this.loginForm.value.rememberMe) {
            this.rememberMeService.set('rememberMe', {
                username: this.loginForm.value.email
            });
        } else {
            this.rememberMeService.remove('rememberMe');
        }

        if (this.isDomainEmail) {
            if (
                this.appService
                    .getConfigVariable('SAML_DOMAINS')
                    .includes(this.utilService.getDomainFromEmail(this.loginForm.value.email))
            ) {
                this.authService
                    .doSSOLogin({
                        redirectRoute: `oauth?clientId=${this.clientId}&redirect_uri=${this.redirect_uri}${
                            this.state ? '&state=' + this.state : ''
                        } `,

                        queryParams: {
                            email: this.loginForm.value.email,
                            bot: this.bot,
                            hostInfo: null,
                            outlook: false
                        }
                    })
                    .subscribe((res) => {
                        window.location.href = res.authUrl;
                    });
            } else {
                this.authService
                    .doSSOLogin({
                        providerName: this.utilService.getDomainFromEmail(this.loginForm.value.email),
                        redirectRoute: `oauth?clientId=${this.clientId}&redirect_uri=${this.redirect_uri}${
                            this.state ? '&state=' + this.state : ''
                        } `,
                        queryParams: {
                            email: null,
                            bot: this.bot,
                            hostInfo: null,
                            outlook: false
                        }
                    })
                    .subscribe((res) => {
                        window.location.href = res.authUrl;
                    });
            }
        } else {
            this.loggingIn = true;
            this.authService.login(this.loginForm.value).subscribe(
                (res) => {
                    this.loggingIn = false;
                    if (res.success === false) {
                        this.errors.global = res.errors;
                        this.diagnosticService
                            .sendEvent({
                                eventCategory: 'Sign In',
                                eventAction: 'Sign In with Email',
                                eventType: 'api_failure',
                                endpoint: '/api/user/login',
                                status: 'failed',
                                data: res
                            })
                            .subscribe();
                        return;
                    }
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign In',
                            eventAction: 'Sign In with Email',
                            eventType: 'app_event',
                            endpoint: '/api/user/login',
                            status: 'success',
                            data: { email: this.loginForm.value.email }
                        })
                        .subscribe();
                    this.checkAuthentication();
                    // this.navigateToLandingPage();
                },
                (err) => {
                    this.errors.global = 'Incorrect Email Address or Password.';
                    // this.errors.global = err.error.errors;
                    this.loggingIn = false;
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign In',
                            eventAction: 'Sign In with Email',
                            eventType: 'api_failure',
                            endpoint: '/api/user/login',
                            status: 'failed'
                        })
                        .subscribe();
                }
            );
        }
    }

    disableOttLoginForm() {
        if (!(this.ottForm.value.otp && (this.ottForm.value.otp || '').match(/^\d{6}$/))) {
            return true;
        }
        return false;
    }

    verifyOTP() {
        if (this.ottForm.value.rememberMe) {
            this.rememberMeService.set('rememberMe', {
                username: this.ottForm.value.phoneNo
            });
        } else {
            this.rememberMeService.remove('rememberMe');
        }

        this.loggingIn = true;
        this.authService
            .verifyOTP({
                phoneNo: this.ottForm.value.phoneNo,
                otp: this.ottForm.value.otp
            })
            .subscribe(
                (res: any) => {
                    this.loggingIn = false;
                    if (res.success === false || res.errors || res.message === 'OTPAUTHERROR') {
                        this.errors.global = res.errors;
                        this.diagnosticService
                            .sendEvent({
                                eventCategory: 'Sign In',
                                eventAction: 'Verify OTP',
                                eventType: 'api_failure',
                                endpoint: '/api/verifyotp',
                                status: 'failed',
                                data: res
                            })
                            .subscribe();
                        return;
                    }
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign In',
                            eventAction: 'Verify OTP',
                            eventType: 'app_event',
                            endpoint: '/api/verifyotp',
                            status: 'success',
                            data: { phoneNo: res.phoneNo }
                        })
                        .subscribe();
                    if (res.token) {
                        this.authService
                            .verifyOtpDualLogin({
                                phoneNo: this.ottForm.value.phoneNo,
                                otp: this.ottForm.value.otp,
                                token: res.token
                            })
                            .subscribe(() => {
                                this.checkAuthentication();

                                // this.navigateToLandingPage();
                            });
                    } else {
                        this.checkAuthentication();

                        // this.navigateToLandingPage();
                    }
                },
                (err) => {
                    this.errors.global = 'Incorrect OTP';
                    this.loggingIn = false;
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign In',
                            eventAction: 'Verify OTP',
                            eventType: 'api_failure',
                            endpoint: '/api/verifyotp',
                            status: 'failed'
                        })
                        .subscribe();
                }
            );
    }

    resendOTP() {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Sign In',
                eventAction: 'Resend OTP',
                eventType: 'app_event',
                endpoint: '/api/getotp',
                status: 'success'
            })
            .subscribe();
        this.getOTP();
    }

    changePhoneNo() {
        this.ottForm.reset();
        this.requestingOTP = true;
    }

    getOTP() {
        this.errors = {};
        if (!this.ottForm.value.phoneNo.match(/^\d{10}$/)) {
            this.errors.phoneNo = 'Please enter valid 10 digit mibile no.';
            return;
        }

        this.sendOTP(this.ottForm.value.phoneNo);
    }

    private sendOTP(phoneNo) {
        this.loggingIn = true;
        this.authService
            .getOtp({
                phoneNo
            })
            .subscribe(
                (res: any) => {
                    this.loggingIn = false;
                    if (res.status === 'success') {
                        this.ottLogin = true;
                        this.requestingOTP = false;
                        this.ottForm.patchValue({
                            phoneNo
                        });
                    }
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign In',
                            eventAction: 'Sign In with Mobile',
                            eventType: 'app_event',
                            endpoint: '/api/getotp',
                            status: 'success',
                            data: { phoneNo: res.phoneNo }
                        })
                        .subscribe();
                },
                (err) => {
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign In',
                            eventAction: 'Sign In with Mobile',
                            eventType: 'api_failure',
                            endpoint: '/api/getotp',
                            status: 'failed',
                            data: err.error
                        })
                        .subscribe();
                    if (err.error.customCode === 400) {
                        this.errors.global = 'Please try again after 15 minutes.';
                        this.loggingIn = false;
                        return;
                    }

                    if (err.error && err.error.errors) {
                        this.errors.global = err.error.errors;
                    } else {
                        this.errors.global = err.error;
                    }
                    this.loggingIn = false;
                }
            );
    }

    showInviteCodePopup() {
        this.router.navigate(['/signup'], { relativeTo: this.activatedRoute });
    }

    navToForgotPassword() {
        this.router.navigate(['forgot-password']);
    }

    loginWithSSO(providerName) {
        this.errors = {};
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Sign In',
                eventType: 'app_event',
                eventAction: `Sign In with ${providerName}`,
                endpoint: `/api/authorize/${providerName}`,
                status: 'success'
            })
            .subscribe();

        this.authService
            .doSSOLogin({
                providerName,
                redirectRoute: `oauth?clientId=${this.clientId}&redirect_uri=${this.redirect_uri}${
                    this.state ? '&state=' + this.state : ''
                } `,
                queryParams: {
                    email: null,
                    bot: this.bot,
                    hostInfo: null,
                    outlook: false
                }
            })
            .subscribe((res) => {
                window.location.href = res.authUrl;
            });
    }

    launchTnc() {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Website Home Options',
                eventType: 'app_event',
                eventAction: 'Terms and Conditions',
                status: 'success'
            })
            .subscribe();
        window.open(this.appService.getEnvVariable('HOST_URL') + 'terms-condition-business');
    }

    launchPrivacy() {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Website Home Options',
                eventType: 'app_event',
                eventAction: 'Privacy Policy',
                status: 'success'
            })
            .subscribe();
        window.open(this.appService.getEnvVariable('HOST_URL') + 'privacy-policy-business');
    }

    navigateToLandingPage() {
        if (sessionStorage.getItem('bot') === 'true') {
            sessionStorage.removeItem('bot');
            this.router.navigate(['teams-bot']);
        } else {
            if (sessionStorage.getItem('returnUrl')) {
                this.returnUrl = sessionStorage.getItem('returnUrl');
                sessionStorage.removeItem('returnUrl');
                this.router.navigateByUrl(this.returnUrl);
            } else {
                this.router.navigate(['/']);
            }
        }
    }

    navigateToSignUp() {
        this.router.navigate(['signup']);
    }

    getUserDetails() {
        this.currentUser = this.userService.getUserSync();
        this.profilePicPath = this.userService.getProfilePicPath(this.currentUser._id);
        this.showUserForm = true;
        this.loading = false;
    }

    getClientDetails() {
        if (!this.clientId) {
            this.showErrorForm = true;
            this.loading = false;
            return;
        }
        this.oauthService.getClient(this.clientId).subscribe(
            (res) => {
                // if (!res.redirectUri.length || !res.redirectUri.includes(this.isValidRedirectUri(this.redirect_uri))) {
                if (!this.isValidRedirectURI(this.redirect_uri, res.redirectUri)) {
                    if (!(this.redirect_uri == res.redirectUri)) {
                        this.showErrorForm = true;
                        this.errMsg = 'Invalid Redirect_uri';
                    }
                }
                this.clientDetails = res;
                let scopesData = res.scopes;
                scopesData.forEach((scope) => {
                    this.scopeData = this.scopeData + scope + ' ';
                    const [resource, action] = scope.split(':');
                    const str2 = resource.charAt(0).toUpperCase() + resource.slice(1);
                    const str3 = action.charAt(0).toUpperCase() + action.slice(1);
                    if (!this.scopes[str2]) {
                        this.scopes[str2] = [str3];
                    } else {
                        this.scopes[str2].push(str3);
                    }
                });
            },
            (err) => {
                this.showErrorForm = true;
                this.loading = false;
            }
        );
    }
    checkAuthentication() {
        this.loading = true;
        this.oauthService.checkAuthentication(this.clientId).subscribe((res: any) => {
            if (res.authorized) {
                this.saveConsentAndRedirect();
            } else {
                this.showConsentForm = true;
                this.loading = false;
                this.samlLoginInProgress = false;
            }
        });
    }
    saveConsentAndRedirect() {
        let url = this.router.url;
        if (url.includes('oauth2/authorize') || this.state === 'jiorooms') {
            console.log('Ving oauth2/authorize ');
            let data = {
                client_id: this.clientId,
                redirect_uri: this.redirect_uri,
                state: this.state,
                scope: this.scopeData,
                response_type: this.state === 'jiorooms' ? 'code' : this.responseType
            };
            this.oauthService.saveUserConsent2(data).subscribe(
                (res: any) => {
                    window.location.href = res?.redirect_uri;
                    this.loading = false;
                    this.samlLoginInProgress = false;
                },
                (err) => {
                    console.log('outh2 response error  ', err);
                }
            );
        } else {
            this.oauthService.saveUserConsent(this.clientId, this.redirect_uri, this.state).subscribe((res: any) => {
                window.location.href = res.redirect_uri;
                this.loading = false;
                this.samlLoginInProgress = false;
            });
        }
    }

    handleManualRedirection() {
        let redirect_uri = this.redirect_uri + '?state=declined';
        window.location.href = redirect_uri;
    }
    openPrivacyPolicy() {
        window.open(this.clientDetails.policyUrl);
    }

    isValidRedirectURI(redirectURI = '', redirectURIs = []) {
        try {
            let rUri = decodeURIComponent(redirectURI).split('?')[0];

            rUri = rUri.endsWith('/') ? rUri.slice(0, rUri.length - 1) : rUri;

            return redirectURIs

                .map((uri) => (uri.endsWith('/') ? uri.slice(0, uri.length - 1) : uri))

                .reduce((acc, uri) => acc || uri === rUri, false);
        } catch (e) {
            return false;
        }
    }
}
