import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './../services/auth.service';
import {
    UserService,
    ThirdPartyExternalIntegrationService,
    RestService,
    AppService,
    DesktopAppService
} from '../services';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable()
export class AuthGuard implements CanActivate {
    isThirdPartyExternalIntegration;
    constructor(
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private restService: RestService,
        private appService: AppService,
        private desktopAppService: DesktopAppService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, snapshot: RouterStateSnapshot): Observable<boolean> {
        const isDesktopApp = this.desktopAppService.isDesktopApp;
        // check for addtojiomeet and if webinar meeting then redirect to jioconf app
        if (route.queryParams?.isWebinar === 'true') {
            window.location.href = `${window.location.href.replace('addtojiomeet', 'jioconf/addtojiomeet')}`;
            return of(false);
        }
        this.isThirdPartyExternalIntegration =
            this.thirdPartyExternalIntegrationService.getThirdPartyExternalIntegration();
        if (route?.url[0]?.path === 'signup') {
            if (this.authService.getIsAuthenticated()) {
                this.router.navigate(['/meetings']);
                return of(false);
            } else {
                return of(true);
            }
        }
        if (!this.authService.getIsAuthenticated()) {
            if (isDesktopApp) {
                this.router.navigate(['/login']);
                return of(false);
            } else {
                this.router.navigate(['/main']);
                return of(false);
            }
        }
        if (this.authService.getIsAuthenticated()) {
            this.restService
                .get(`api/config/v1?os_type=webrtc`)
                .toPromise()
                .then((config) => {
                    this.appService.setConfig(config);
                });
            return this.hasProfile();
        }
        if (this.isThirdPartyExternalIntegration) {
            const userInfo = this.thirdPartyExternalIntegrationService.getUserInfo();
            if (typeof userInfo === 'string') return of(true);
            else return of(false);
        }
        if (route?.data?.redirectToLogin) {
            this.router.navigate(['/login']);
            this.toastrService.clear();
            this.toastrService.error(this.translateService.instant('tostrs.login_first'));
            return of(false);
        }
        this.router.navigate(['/home']);
        return of(false);
    }

    hasProfile() {
        return this.userService.getUser().pipe(
            map((res) => {
                return true;
            })
        );
    }
}
