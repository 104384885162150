import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from './../../../environments/environment';
import * as prestageEnvironment from './../../../environments/environment.prestage';
import * as rcEnvironment from './../../../environments/environment.rc';
import * as prodEnvironment from './../../../environments/environment.pro';
import { LocalStorageService } from './local-storage.service';
import { DesktopAppService } from './desktop-app.service';

const THUMBNAIL_SIZE = {
    width: 1244,
    height: 681
};

const FULL_SIZE = {
    width: 1280,
    height: 720
};

@Injectable({
    providedIn: 'root'
})
export class DesktopAppUpdaterService {
    public showElectronUpdatePopup: boolean = false;
    public isDesktopApp: boolean = false;
    public showLoader: Boolean = false;
    public showProgressLoader: Boolean = false;
    public downloadAvailable: any;
    public isDownloadInProgress: Boolean = false;
    public checkingForUpdates: Boolean = false;
    public isDownloadComplete: Boolean = true;
    public downloadingProgress: any = false;
    public isDownloadAvailable: any = false;
    public downloadComplete;
    public downloadProgress = 0;
    public updateCheckInProgress: Boolean = true;
    constructor(private desktopAppService: DesktopAppService) {
        this.isDesktopApp = this.desktopAppService.isDesktopApp;
        console.log(this.isDesktopApp);
    }

    startCheckForUpdates() {
        if (this.desktopAppService.electronBridge) {
            return this.desktopAppService.electronBridge.appUpdate.startCheckForUpdates();
        }
    }

    updateCheckStatus(callback) {
        if (this.desktopAppService.electronBridge) {
            return this.desktopAppService.electronBridge.appUpdate.updateCheckStatus(callback);
        }
    }

    downloadUpdate() {
        if (this.desktopAppService.electronBridge) {
            return this.desktopAppService.electronBridge.appUpdate.downloadUpdate();
        }
    }

    updateAvailable(callback) {
        if (this.desktopAppService.electronBridge) {
            return this.desktopAppService.electronBridge.appUpdate.updateAvailable(callback);
        }
    }

    downloadProgressComplete(callback) {
        if (this.desktopAppService.electronBridge) {
            return this.desktopAppService.electronBridge.appUpdate.downloadProgressComplete(callback);
        }
    }

    fetchDownloadProgress(callBack) {
        if (this.desktopAppService.electronBridge) {
            return this.desktopAppService.electronBridge.appUpdate.downloadProgressUpdate(callBack);
        }
    }

    installAppUpdate(opts) {
        if (this.desktopAppService.electronBridge) {
            console.log('triggered installing app from web');
            return this.desktopAppService.electronBridge.appUpdate.installAppUpdate(opts);
        }
    }
}
