<div class="private-chat-container" (animationend)="animationDone($event)">
    <div class="header" [class.popout]="popout">
        <div class="back-wrapper">
            <div class="back" (click)="close()">
                <img alt="" src="assets/images/arrow-back.png" />
            </div>
            <span class="text-truncate">{{ getRecipientName(recipient) }}</span>
        </div>

        <div *ngIf="isAuthenticated && enableJioCloud" (click)="switchToAttachmentSection()">
            <button class="fileCount btn btn-light" [class.selected]="showAttachments">
                {{ 'inside_call.private_chat.files' | translate }} ({{ getAttachedFilesCount() }})
            </button>
        </div>
    </div>
    <div class="messages full" #messages>
        <!-- [style.bottom]="bottomStyleReducer()" -->
        <div class="chat">
            <div class="chat-header">
                <span class="text-truncate" [title]="recipient?.name"
                    >{{ 'inside_call.private_chat.private_chat_with' | translate }}
                    {{ getRecipientName(recipient) }}</span
                >
            </div>
            <div class="chat-group" *ngFor="let group of chatGroupsByDate">
                <div class="date p-3">
                    <span>{{ group.key | dateLabel }}</span>
                </div>
                <div *ngFor="let message of group?.value?.slice()?.reverse(); trackBy: trackMessages">
                    <div
                        class="chat-bubble"
                        *ngIf="
                            !message?.messageComponent?.attachments?.length ||
                            (message.type === 'message' && this.userService.isJioCloudEnabled())
                        "
                        [class.right-aligned]="message.createdBy === senderId"
                    >
                        <div class="content">
                            <div class="avatar" *ngIf="message.createdBy !== senderId">
                                <span *ngIf="message?.createdBy.includes('gu')">
                                    <div appAvatarWithInitials [name]="message?.creatorsName"></div>
                                </span>
                                <img
                                    alt=""
                                    [src]="getProfilePic(message?.createdBy) | imageSrcTransformer"
                                    *ngIf="!message?.createdBy.includes('gu')"
                                />
                            </div>
                            <div
                                class="block position-relative"
                                (mouseenter)="show_Options(message)"
                                (mouseleave)="hideOptions(1)"
                                (outside)="resetSettings(message)"
                            >
                                <div class="text px-3 pt-3 pb-3" [class.self]="senderId === message?.createdBy">
                                    <div class="name-wrap">
                                        <div class="name">
                                            <span class="text-in" *ngIf="senderId === message?.createdBy"
                                                >{{ 'inside_call.private_chat.you' | translate }}
                                            </span>
                                            <span class="text-in" *ngIf="senderId !== message?.createdBy">
                                                <div
                                                    class="text-truncate"
                                                    *ngIf="message?.createdBy?.includes('gu-')"
                                                    title="{{ message?.creatorsName }} {{ message?.creatorsLName }}"
                                                >
                                                    {{ message?.creatorsName }} {{ message?.creatorsLName }}
                                                </div>
                                                <div
                                                    class="text-truncate"
                                                    *ngIf="!message?.createdBy?.includes('gu-')"
                                                    title="{{ message?.creatorsName }} {{ message?.creatorsLName }}"
                                                >
                                                    {{ message?.creatorsName }} {{ message?.creatorsLName }}
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="show_delete && message?._id === messageId"
                                        class="delete-container"
                                        (click)="deleteMessagesFromChat(message)"
                                    >
                                        <img alt="" src="assets/images/deletIcon.svg" class="img-size flex-item1 img" />
                                        <span class="flex-item2 delete_text">{{
                                            'inside_call.chat.delete' | translate
                                        }}</span>
                                    </div>
                                    <div
                                        *ngIf="
                                            senderId === message?.createdBy &&
                                            show_options &&
                                            message?._id === messageId &&
                                            message.status === 'active'
                                        "
                                        class="flex-item options"
                                        (click)="showDelete()"
                                        (mouseenter)="toggleDeleteImage()"
                                        (mouseleave)="toggleDeleteImageAgain()"
                                    >
                                        <img
                                            alt=""
                                            [src]="
                                                showWhiteDots
                                                    ? 'assets/images/3_dots_white.svg'
                                                    : 'assets/images/3_dots.svg'
                                            "
                                        />
                                    </div>
                                    <!-- <div class="time">{{message?.cOn | date: 'HH:mm' | convert24to12format}}</div> -->
                                    <div
                                        *ngIf="!deletedMessages.includes(message?._id) && message.status === 'active'"
                                        [innerHTML]="message?.messageComponent?.text?.trim() | linkify | showdown"
                                        id="msg-area"
                                        class="msg-area"
                                    ></div>
                                    <div
                                        *ngIf="
                                            message.status !== 'active' ||
                                            (isMsgDeleted && deletedMessages.includes(message?._id))
                                        "
                                    >
                                        <div>
                                            <i id="msg-area" style="word-break: break-word">{{
                                                'inside_call.chat.this_message_has_been_deleted' | translate
                                            }}</i>
                                        </div>
                                    </div>
                                    <div class="time p-3" *ngIf="message.status !== 'active'">
                                        {{ message?.cOn | date : 'HH:mm' | convert24to12format }}
                                    </div>
                                    <div
                                        class="time p-3"
                                        tabindex="0"
                                        *ngIf="
                                            justDeleted &&
                                            message.status === 'active' &&
                                            deletedMessages.includes(message?._id)
                                        "
                                    >
                                        {{ currentTime | date : 'HH:mm' | convert24to12format }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="attachment-bubble"
                            *ngIf="message?.messageComponent?.attachments?.length"
                            [class.right-aligned]="message.createdBy === senderId"
                        >
                            <button id="downloadAnchor" #downloadAnchor style="display: none"></button>
                            <div class="content">
                                <div class="avatar" *ngIf="message.createdBy !== senderId">
                                    <span *ngIf="message?.createdBy.includes('gu')">
                                        <div appAvatarWithInitials [name]="message?.creatorsName"></div>
                                    </span>
                                    <img
                                        alt=""
                                        [src]="getProfilePic(message?.createdBy) | imageSrcTransformer"
                                        *ngIf="!message?.createdBy.includes('gu')"
                                    />
                                </div>
                                <div class="block position-relative">
                                    <div class="text px-3 pt-3 pb-3" [class.self]="senderId === message?.createdBy">
                                        <div class="name-wrap">
                                            <div class="name">
                                                <span class="text-in" *ngIf="senderId === message?.createdBy"
                                                    >{{ 'inside_call.private_chat.you' | translate }}
                                                </span>
                                                <span class="text-in" *ngIf="senderId !== message?.createdBy">
                                                    <div
                                                        class="text-truncate"
                                                        title="{{ message?.creatorsName }} {{ message?.creatorsLName }}"
                                                    >
                                                        {{ message?.creatorsName }} {{ message?.creatorsLName }}
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <!-- <div class="time">{{message?.cOn | date: 'HH:mm' | convert24to12format }}</div> -->
                                        <div
                                            class="moreOptions pointer"
                                            *ngIf="isAuthenticated && enableDownloadPreview && !message?.msgDeleted"
                                            (click)="toggleMoreOptions(message)"
                                        >
                                            <div class="dropdown" outside (outside)="clickOutside(message)">
                                                <img alt="" src="assets/images/moreOptionsIcon.svg" />
                                                <div class="custom-dropdown-menu" *ngIf="message?.showMoreOptions">
                                                    <div class="dropdown-item pointer" (click)="fileInfo(message)">
                                                        <img
                                                            alt=""
                                                            style="width: 20px; height: 20px; margin-right: 2px"
                                                            src="assets/images/file_info.svg"
                                                        />
                                                        {{ 'inside_call.private_chat.files_info' | translate }}
                                                    </div>
                                                    <div
                                                        class="dropdown-item pointer"
                                                        (click)="deleteItem(message)"
                                                        *ngIf="senderId === message?.createdBy"
                                                    >
                                                        <img alt="" src="assets/images/deletIcon.svg" />
                                                        {{ 'inside_call.private_chat.delete' | translate }}
                                                    </div>
                                                    <div
                                                        class="dropdown-item pointer"
                                                        (click)="downloadFile(message, $event)"
                                                    >
                                                        <img alt="" src="assets/images/downloadIcon.svg" />
                                                        {{ 'inside_call.private_chat.download' | translate }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="msg-area" [class.self]="senderId === message?.createdBy">
                                            <div *ngIf="message?.msgDeleted">
                                                <i id="msg-area" style="word-break: break-word">{{
                                                    'inside_call.private_chat.this_message_has_been_deleted' | translate
                                                }}</i>
                                            </div>
                                            <div *ngIf="!message?.msgDeleted">
                                                <div
                                                    [innerHTML]="
                                                        message?.messageComponent?.text?.trim() | linkify | showdown
                                                    "
                                                    id="msg-area"
                                                ></div>
                                            </div>

                                            <div *ngIf="!message?.msgDeleted">
                                                <div
                                                    *ngFor="
                                                        let attachmnt of message?.messageComponent?.attachments;
                                                        trackBy: trackByFuntion
                                                    "
                                                >
                                                    <app-chat-attachment
                                                        [message]="message"
                                                        [showAttachments]="isDesktopApp()"
                                                        [item]="attachmnt"
                                                        [jiomeetId]="currentThreadId"
                                                    ></app-chat-attachment>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="time p-3">
                                        {{ message?.cOn | date : 'HH:mm' | convert24to12format }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="attachments private-attachments"
        (resized)="onResized($event)"
        [class.disabled]="isChatDisabled"
        *ngIf="
            !showAttachments &&
            !(
                room?.advancedOptions?.isClassroomMode &&
                !(roomStatus?.localParticipant?.isHost || roomStatus?.localParticipant?.isCoHost)
            )
        "
    >
        <div class="to-panel" *ngIf="roomStatus?.participants?.length > 2">
            <strong>{{ 'inside_call.private_chat.to' | translate }}</strong>
            <div class="participants-dropdown pointer" outside (outside)="onOutsideClick($event)">
                <div
                    class="selected position-relative"
                    (click)="toggleDropdown()"
                    (window:resize)="setDropdownHeight()"
                >
                    <span>{{ getRecipientName(recipient) }}</span>
                    <img alt="" src="assets/images/arrow-down-icon.png" alt="" />
                </div>
                <div class="dropdown" *ngIf="dropdown">
                    <div class="search-box">
                        <input
                            type="text"
                            name=""
                            id=""
                            placeholder="{{ 'inside_call.chat.search_participants' | translate }}"
                            #participantSearchInput
                        />
                        <img alt="" src="assets/images/search_icn.svg" />
                    </div>
                    <ng-container
                        *ngIf="
                            roomParticipants
                                | filterContactByKey
                                    : participantSearchInput.value
                                    : ['participantName'] as participants
                        "
                    >
                        <div class="position-relative" *ngFor="let user of participants; trackBy: trackByFuntion">
                            <div
                                class="participant-item"
                                *ngIf="user.userId === null"
                                (click)="close(); dropdown = !dropdown"
                            >
                                <span>{{ 'inside_call.private_chat.all' | translate }}</span>
                            </div>
                            <div
                                class="participant-item"
                                [class.selected]="recipient?._id === user?.userId"
                                *ngIf="user.userId !== null && senderId !== user.userId"
                                (click)="toAnotherPrivateChat({ _id: user.userId, name: user.participantName }, $event)"
                            >
                                <span class="text-truncate" [title]="user.participantName">{{
                                    getParticipantName(user)
                                }}</span>
                                <img
                                    alt=""
                                    src="assets/img/revamp/tick_black.svg"
                                    alt=""
                                    style="opacity: 1"
                                    *ngIf="recipient?._id === user?.userId"
                                />
                                <div
                                    class="new-message"
                                    *ngIf="
                                        unreadChatParticipantIds.includes(user.userId) &&
                                        user?.userId !== recipient?._id
                                    "
                                ></div>
                            </div>
                        </div>
                        <div class="no-data" *ngIf="!participants.length">
                            <em>{{ 'inside_call.private_chat.no_participants_found' | translate }}</em>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="chatAttachments">
            <app-chat-markdown
                [showMarkdown]="showMarkdown"
                [selectedEmoji]="selectedEmoji"
                (sendmessage)="sendMessage(null, null)"
            >
            </app-chat-markdown>
        </div>

        <div class="uploaded-docs">
            <div class="attached-files-container" *ngFor="let attachmentObj of uploadedItems | keyvalue">
                <app-upload-attachment
                    [item]="attachmentObj?.value"
                    (remove)="removeAttchment($event, attachmentObj?.value)"
                >
                </app-upload-attachment>
                <div
                    class="progres"
                    *ngIf="attachmentObj?.value?.progress > 0 && attachmentObj?.value?.progress < 100"
                    [style.width.%]="attachmentObj?.value?.progress"
                ></div>
            </div>
        </div>
        <label for="emoji" class="pointer ml-3 emoji-switch" *ngIf="showEmojiBar">
            <img src="assets/images/emoji-icon.svg" alt="" *ngIf="!selectedEmoji" (click)="toggleEmojis($event)" />
            <img src="assets/images/emoji-clicked.svg" alt="" *ngIf="selectedEmoji" (click)="toggleEmojis($event)" />
        </label>
        <label for="privateUpload" *ngIf="isAuthenticated && enableJioCloud" class="ml-3">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.08"
                height="11.65"
                viewBox="0 0 13.08 11.65"
                id="attachmentIcon"
                #attachmentIcon
                aria-hidden="true"
                class="pointer"
            >
                <defs>
                    <style>
                        .a {
                            fill: #666666;
                        }
                    </style>
                </defs>
                <path
                    class="a"
                    d="M1669.861,7164.041l3.859-3.858a3.916,3.916,0,0,0-.145-5.657,4.192,4.192,0,0,0-2.9-1.294h-.047a3.809,3.809,0,0,0-2.7,1.149l-5.229,5.229a2.946,2.946,0,0,0,.048,4.306,3.067,3.067,0,0,0,2.183.966h.034a3.012,3.012,0,0,0,2.089-.918l3.974-3.975a2.186,2.186,0,0,0,.686-1.416,2.175,2.175,0,0,0-2.175-2.176,2.2,2.2,0,0,0-1.417.686l-2.6,2.6a.349.349,0,0,0,.247.6h0a.349.349,0,0,0,.246-.1l2.6-2.6a1.536,1.536,0,0,1,.964-.483,1.28,1.28,0,0,1,1,.434,1.3,1.3,0,0,1,.434,1.005,1.527,1.527,0,0,1-.483.962l-3.974,3.976a2.317,2.317,0,0,1-1.6.712h-.025a2.385,2.385,0,0,1-1.69-.762,2.223,2.223,0,0,1-.048-3.319l5.229-5.229a3.134,3.134,0,0,1,2.221-.944h.031a3.458,3.458,0,0,1,2.419,1.089,3.2,3.2,0,0,1,.144,4.671l-3.859,3.859a.348.348,0,0,0,0,.493A.348.348,0,0,0,1669.861,7164.041Z"
                    transform="translate(-1661.778 -7153.231)"
                />
            </svg>
            <input
                #privateUpload
                type="file"
                id="privateUpload"
                style="display: none"
                (change)="fileChangeEvent($event)"
                (click)="resetLastSelectedFileValue($event)"
            />
        </label>
        <label for="markdown" class="pointer" *ngIf="enableMarkdown">
            <span *ngIf="!showMarkdown"
                ><img alt="" src="assets/images/markdown-icon.svg" (click)="toggleMarkdown()"
            /></span>
            <span *ngIf="showMarkdown"
                ><img alt="" src="assets/images/markdown-clicked.svg" (click)="toggleMarkdown()"
            /></span>
        </label>

        <div class="sendBtn pointer" (click)="sendMessage(null, messageToSend)">
            <img alt="" src="assets/images/send-message.svg" alt="" />
        </div>
    </div>

    <div
        class="attachments private-attachments"
        (resized)="onResized($event)"
        *ngIf="
            room?.advancedOptions?.isClassroomMode &&
            !(roomStatus?.localParticipant?.isHost || roomStatus?.localParticipant?.isCoHost)
        "
    >
        <div class="restriction-msg text-center">
            {{ 'inside_call.private_chat.restricted_chatting' | translate }}
        </div>
    </div>
    <ng-template viewHost></ng-template>
</div>
