import { Injectable, OnDestroy } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { DomSanitizer } from '@angular/platform-browser';

import * as Bowser from 'bowser';

import { ClipboardService } from 'ngx-clipboard';
import { TranslateService } from '@ngx-translate/core';

import { sha256 } from 'js-sha256';

import { isToday, isYesterday, isTomorrow, isBefore, isAfter, addDays, subDays } from 'date-fns';

import { AppService } from './app.service';

import { environment } from 'src/environments/environment';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { APP_EVENTS } from 'src/app/constants';
import { EventEmitterService } from './event-emitter.service';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { RestService } from './rest.service';
import { DesktopAppService } from 'src/app/core';

declare const window: any;
declare const document: any;

const platform_win = 'desktop-windows';
const platform_mac = 'desktop-mac';
const platform_web = 'web';
const platform_chromebook = 'chromebook';

@Injectable({
    providedIn: 'root'
})
export class UtilService implements OnDestroy {
    resizeObservable$: Observable<Event>;
    resizeSubscription$: Subscription;
    pageResizeTimeoutRef;
    selectedIncomingaudio = {
        name: 'JioMeet Tune',
        audioPath: './assets/audio/call.mp3'
    };
    platforms = { platform_mac, platform_chromebook, platform_web, platform_win };

    private specialCharacters = /([`~!@#$%^&*()_+=\[\]{}\\|'";:\/?.>,<])/g;
    private timer;
    #ENJSON; //Creting private variable
    #traslationData = {}; //Creting private variable
    private attachmentLoadedSubject = new Subject<void>();
    attachmentLoaded$ = this.attachmentLoadedSubject.asObservable();

    constructor(
        private appService: AppService,
        private clipboardService: ClipboardService,
        private sanitizer: DomSanitizer,
        private translateService: TranslateService,
        private eventEmitterService: EventEmitterService,
        private localStorageService: LocalStorageService,
        private restService: RestService,
        private router: Router,
        private desktopAppService: DesktopAppService
    ) {
        this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeSubscription$ = this.resizeObservable$.subscribe((e) => {
            // trigger resize event
            if (!this.pageResizeTimeoutRef) this.emitPageResize();
        });
        this.emitPageResize();
    }

    emitPageResize() {
        this.pageResizeTimeoutRef = setTimeout(() => {
            this.eventEmitterService.emit({
                type: APP_EVENTS.PAGE_RESIZED,
                data: { innerWidth: window.innerWidth }
            });
            this.pageResizeTimeoutRef = 0;
        }, 1000);
    }
    toggleMirrorView() {
        this.localStorageService.addItem(
            'isMirrorViewEnabled',
            !this.localStorageService.getItem('isMirrorViewEnabled')
        );
        return this.localStorageService.getItem('isMirrorViewEnabled');
    }
    getParameterByName(name, url?) {
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results) {
            return '';
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    sanitize(input: string) {
        return input.replace(this.specialCharacters, '');
    }

    clone(val) {
        return JSON.parse(JSON.stringify(val));
    }

    parseJWT(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

    inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }
    isMattermost() {
        return window.location?.ancestorOrigins?.length > 0
            ? window.location?.origin != window.location?.ancestorOrigins[0]
            : false;
    }

    isIE() {
        const ua = navigator.userAgent;
        return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
    }

    isChrome() {
        // This method returns true if the running browser is Google Chrome version 42+
        // if (navigator.userAgent.toLowerCase().indexOf('chrome') !== -1 &&
        // 	navigator.userAgent.substr(navigator.userAgent.lastIndexOf('Chrome/') + 7, 10).split('.')[0] >= 42) {
        if (
            (navigator.appVersion.indexOf('Win') !== -1 &&
                navigator.userAgent.toLowerCase().indexOf('chrome') !== -1) ||
            (navigator.appVersion.indexOf('Mac') !== -1 &&
                navigator.userAgent.toLowerCase().indexOf('chrome') !== -1 &&
                +navigator.userAgent.substr(navigator.userAgent.lastIndexOf('Chrome/') + 7, 10).split('.')[0] >= 42)
        ) {
            return true;
        } else {
            return false;
        }
    }

    isTablet() {
        const userAgent = navigator?.userAgent || navigator?.vendor || window?.opera;
        const isIpad = !!(
            navigator.userAgent.match(/(iPad)/) ||
            (navigator.platform === 'MacIntel' && typeof (navigator as any).standalone !== 'undefined')
        );
        const isAndroidTablet = /Android/i.test(userAgent) && !/Mobile/i.test(userAgent);
        return isIpad || isAndroidTablet;
    }

    fetchPlatformType() {
        var userAgent = window.navigator.userAgent;
        if (userAgent.indexOf('Mac') != -1 && userAgent.indexOf('Electron') != -1) {
            return platform_mac;
        } else if (userAgent.indexOf('Win') != -1 && userAgent.indexOf('Electron') != -1) {
            return platform_win;
        } else {
            return window.navigator.userAgent.indexOf('CrOS') != -1 ? platform_chromebook : platform_web;
        }
    }

    isToday(date: Date) {
        return isToday(date);
    }

    isYesterday(date: Date) {
        return isYesterday(date);
    }

    isEarlierThanYesterday(date: Date) {
        const yesterday = subDays(new Date(), 1);
        return isBefore(date, yesterday);
    }

    isTableData(data: string): boolean {
        return data.includes('<table>') && data.includes('</table>');
    }
    isTomorrow(date) {
        return isTomorrow(date);
    }

    isLaterThanTomorrow(date: Date) {
        const tomorrow = addDays(new Date(), 1);
        return isAfter(date, tomorrow);
    }

    translatedDate(dateStr) {
        const dateArray = dateStr.split(' ');
        if (dateArray.length === 3) {
            let monthStr = '';
            if (dateArray[1].length === 3) {
                switch (dateArray[1]) {
                    case 'Jan':
                    case 'Feb':
                    case 'Mar':
                    case 'Apr':
                    case 'May':
                    case 'Jun':
                    case 'Jul':
                    case 'Aug':
                    case 'Sep':
                    case 'Oct':
                    case 'Nov':
                    case 'Dec':
                        monthStr = this.translateService.instant(`chat.month.${dateArray[1].toLowerCase()}`);
                        break;
                    default:
                        monthStr = dateStr[1];
                        break;
                }
            } else {
                switch (dateArray[1]) {
                    case 'January':
                    case 'February':
                    case 'March':
                    case 'April':
                    case 'May':
                    case 'June':
                    case 'July':
                    case 'August':
                    case 'September':
                    case 'October':
                    case 'November':
                    case 'December':
                        monthStr = this.translateService.instant(`payment-page.month.${dateArray[1]}`);
                        break;
                    default:
                        monthStr = dateStr[1];
                        break;
                }
            }

            return dateArray[0] + ' ' + monthStr + ' ' + dateArray[2];
        } else {
            return dateStr;
        }
    }

    translateDateWithTime(currentDate: any) {
        const dateArray = currentDate.split(' ');
        if (dateArray.length === 5) {
            let monthStr = '',
                session = '';
            dateArray[1] = dateArray[1].replace(/,/g, '');
            session = dateArray[4];
            switch (dateArray[1]) {
                case 'Jan':
                case 'Feb':
                case 'Mar':
                case 'Apr':
                case 'May':
                case 'Jun':
                case 'Jul':
                case 'Aug':
                case 'Sep':
                case 'Oct':
                case 'Nov':
                case 'Dec':
                    monthStr = this.translateService.instant(`chat.month.${dateArray[1].toLowerCase()}`) + ',';
                    break;
                default:
                    monthStr = currentDate[1];
                    break;
            }
            session === this.translateService.instant(`payment-page.${session}`);
            return dateArray[0] + ' ' + monthStr + ' ' + dateArray[2] + ' ' + dateArray[3] + ' ' + dateArray[4];
        } else {
            return currentDate;
        }
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    dataURItoFile(dataURI): File {
        // convert base64/URLEncoded data component to raw binary data held in a string
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = unescape(dataURI.split(',')[1]);
        }

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        const b: any = new Blob([ia], { type: mimeString });
        b.lastModifiedDate = new Date();
        b.name = 'profile.png';

        return b as File;
    }

    getProfilePic(userId) {
        const random = '12345';
        return this.appService.getEnvVariable('HOST_URL') + `api/profilepic/${userId}/profile.png?random=${random}`;
    }

    getOS() {
        const userAgent = window.navigator.userAgent;
        const platform = window.navigator.platform;
        const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
        const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        let os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'Linux';
        }

        return os;
    }

    getOsForGtag() {
        const userAgent = window.navigator.userAgent;
        const platform = window.navigator.platform;
        const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
        const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        let os = null;
        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'mac';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'ios';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'windows';
        } else if (/Android/.test(userAgent)) {
            os = 'android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'linux';
        }
        return os;
    }

    getOsVersion() {
        var osVersion = window.navigator.userAgent;

        return osVersion;
    }

    detectBrowser() {
        const ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/ipad/i) !== null) {
            return 'ipad';
        } else if (ua.match(/iphone/i) !== null) {
            return 'iphone';
        } else if (ua.match(/android/i) !== null) {
            return 'android';
        } else if (ua.match(/mac/gi) !== null) {
            return 'mac';
        } else {
            return null;
        }
    }

    isMobileUserAgent() {
        return ['iphone', 'android'].indexOf(this.detectBrowser()) !== -1;
    }
    isMobileAndroidOrIphone() {
        const userAgent = this.detectBrowser().toLowerCase(); // Ensure consistent casing
        if (userAgent.includes('iphone') || userAgent.includes('ipad') || userAgent.includes('ipod')) {
            return 'iOS';
        } else if (userAgent.includes('android')) {
            return 'Android';
        } else {
            return 'Unknown';
        }
    }

    isMobileBrowser(breakPoint = 600): boolean {
        // first check if browser is in mobile mode in web
        if (window.innerWidth < breakPoint) {
            return true;
        }
        return ['iphone', 'android'].indexOf(this.detectBrowser()) !== -1;
    }

    copy(text: string) {
        this.clipboardService.copyFromContent(text);
    }

    copyToClipboardFromHTMLElement(containerid) {
        try {
            if (document.selection) {
                var range = document.body.createTextRange();
                range.moveToElementText(document.getElementById(containerid));
                range.select().createTextRange();
                document.execCommand('copy');
            } else if (window.getSelection) {
                var range = document.createRange();
                range.selectNode(document.getElementById(containerid));
                window.getSelection().removeAllRanges(range);
                window.getSelection().addRange(range);
                document.execCommand('copy');
                window.getSelection().removeRange(range);
            }
        } catch (e) {}
    }

    getTextFromHTMLElement(containerid) {
        try {
            if (document.selection) {
                var range = document.body.createTextRange();
                range.moveToElementText(document.getElementById(containerid));
                range.select().createTextRange();
                document.execCommand('copy');
            } else if (window.getSelection) {
                var range = document.createRange();
                range.selectNode(document.getElementById(containerid));
                window.getSelection().removeAllRanges(range);
                window.getSelection().addRange(range);
                const string = window.getSelection().toString();
                window.getSelection().removeRange(range);
                return string;
            }
        } catch (e) {}
    }

    isIpad() {
        const ua = window.navigator.userAgent;
        if (ua.indexOf('iPad') > -1) {
            return true;
        }
        if (ua.indexOf('Macintosh') > -1) {
            try {
                document.createEvent('TouchEvent');
                return true;
            } catch (e) {}
        }
        return false;
    }

    generateRandomAlphanumericString(length) {
        const charset = 'abcdefghjkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ123456789';
        let retVal = '';
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    generatePassword(length) {
        // one small, one capital, one numeric
        const result =
            this.getRandomAlpha(1) +
            this.getRandomAlpha(1).toUpperCase() +
            (Math.floor(Math.random() * 9) + 1) +
            this.generateRandomAlphanumericString(length - 3);
        return this.shuffleString(result);
    }

    getWindowsClient() {
        if (navigator.userAgent.indexOf('WOW64') !== -1 || navigator.userAgent.indexOf('Win64') !== -1) {
            return this.appService.getConfigVariable('WINDOWS_INSTALLER_URL_64');
        } else {
            return this.appService.getConfigVariable('WINDOWS_INSTALLER_URL_32');
        }
    }

    private getRandomAlpha(length) {
        const charset = 'abcdefghjkmnpqrstuvwxyz';
        let retVal = '';
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    private shuffleString(str) {
        const a = str.split('');
        const n = a.length;

        for (let i = n - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const tmp = a[i];
            a[i] = a[j];
            a[j] = tmp;
        }
        return a.join('');
    }

    isRTCSupportedBrowser() {
        const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        // const isFirefox = typeof window.InstallTrigger !== 'undefined';
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (this.isEdgeBrowser()) {
            return false;
        }
        return isChrome || isSafari;
    }

    throttle(func, delay) {
        if (this.timer) {
            return;
        }
        this.timer = setTimeout(() => {
            func();
            this.timer = undefined;
        }, delay);
    }

    getSHA256(msg, key) {
        return sha256.hmac(key, msg).toString();
    }

    bypassSecurityTrustResourceUrl(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    getDomainFromEmail = (email) => {
        let emailDomain = null;
        const pos = email.search('@');
        if (pos > 0) {
            emailDomain = email.slice(pos + 1);
        }
        return emailDomain;
    };

    downloadJioMeet(redirect = false, selectedOS = '') {
        var os = selectedOS.length > 0 ? selectedOS : this.getOS();
        const browser = this.detectBrowser();
        let supportedPlatform = false;
        switch (os) {
            case 'Windows':
                {
                    supportedPlatform = true;
                    const url = this.getWindowsClient();
                    if (redirect) {
                        location.href = url;
                        setTimeout(() => (location.href = environment.HOST_URL), 1000);
                    } else {
                        window.open(url, '_blank');
                    }
                    // window.open(this.getWindowsClient());
                }
                break;
            case 'Mac OS':
                {
                    // check for ipad
                    if (this.isIpad()) {
                        supportedPlatform = true;
                        let url;
                        url = this.appService.getConfigVariable('IOS_INSTALLER_URL');
                        redirect ? (location.href = url) : window.open(url, '_blank');
                        return;
                    }
                    supportedPlatform = true;
                    const url = this.appService.getConfigVariable('MAC_INSTALLER_URL');
                    redirect ? (location.href = url) : window.open(url, '_blank');
                    // window.open(this.appService.getConfigVariable('MAC_INSTALLER_URL'), '_blank');
                }
                break;
            case 'iOS':
                {
                    supportedPlatform = true;
                    const url = this.appService.getConfigVariable('IOS_INSTALLER_URL');
                    redirect ? (location.href = url) : window.open(url, '_blank');
                    // window.open(this.appService.getConfigVariable('IOS_INSTALLER_URL'), '_blank');
                }
                break;
            case 'Android':
                {
                    supportedPlatform = true;
                    const url = this.appService.getConfigVariable('ANDROID_INSTALLER_URL');
                    redirect ? (location.href = url) : window.open(url, '_blank');
                    // window.open(this.appService.getConfigVariable('ANDROID_INSTALLER_URL'), '_blank');
                }
                break;
            default:
                let url;
                if (browser === 'android') {
                    supportedPlatform = true;
                    url = this.appService.getConfigVariable('ANDROID_INSTALLER_URL');
                    redirect ? (location.href = url) : window.open(url, '_blank');
                    // window.open(this.appService.getConfigVariable('ANDROID_INSTALLER_URL'), '_blank');
                } else if (['ipad', 'iphone'].includes(browser)) {
                    supportedPlatform = true;
                    url = this.appService.getConfigVariable('IOS_INSTALLER_URL');
                    redirect ? (location.href = url) : window.open(url, '_blank');
                    // window.open(this.appService.getConfigVariable('IOS_INSTALLER_URL'), '_blank');
                }
                break;
        }
        return supportedPlatform;
    }

    scrollToAnchor(location: string, wait: number, scrollIntoViewOptions = {}): void {
        const element = document.querySelector('#' + location);
        if (element) {
            setTimeout(() => {
                element.scrollIntoView({
                    ...{
                        behavior: 'smooth'
                        // block: 'start'
                        // inline: 'nearest'
                    },
                    ...scrollIntoViewOptions
                });
            }, wait);
        }
    }

    scrollToTop(location: string, wait: number): void {
        const element = document.querySelector('#' + location);
        if (element) {
            setTimeout(() => {
                element.scrollTo(0, 0);
            }, wait);
        }
    }

    activateAccessibilityForPopup(classes) {
        classes.forEach((eleClass) => {
            const ele = document.getElementsByClassName(eleClass);
            if (ele.length > 0) {
                ele[0].setAttribute('tabindex', 0);
            }
        });
        const headEle = document.getElementsByClassName(classes[0]);
        if (headEle.length > 0) {
            headEle[0].focus();
        }
    }

    setTimeOutForAccessibilityPopUp() {
        setTimeout(() => {
            this.activateAccessibilityForPopup([
                'p-dialog-title',
                'p-dialog-header-icon',
                'p-confirm-dialog-message',
                'p-confirm-dialog-accept',
                'p-confirm-dialog-reject'
            ]);
        }, 100);
    }

    isEdgeBrowser() {
        const isEdge = /Edg/.test(navigator.userAgent) || window.navigator.userAgent.indexOf('EdgiOS') > -1;
        return isEdge;
    }

    isOlderSafari() {
        return Bowser.getParser(window.navigator.userAgent).satisfies({
            macos: {
                safari: '<=12'
            }
        });
    }

    diff(obj1, obj2) {
        const result = {};
        if (Object.is(obj1, obj2)) {
            return undefined;
        }
        if (!obj2 || typeof obj2 !== 'object') {
            return obj2;
        }
        Object.keys(obj1 || {})
            .concat(Object.keys(obj2 || {}))
            .forEach((key) => {
                if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
                    result[key] = obj2[key];
                }
                if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
                    const value = this.diff(obj1[key], obj2[key]);
                    if (value !== undefined) {
                        result[key] = value;
                    }
                }
            });
        return result;
    }

    encodeHtmlEntities(str) {
        return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    }

    htmlDecode(input) {
        const e = document.createElement('textarea');
        e.innerHTML = input;
        // handle case of empty input
        return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
    }

    getCookie(name: string) {
        const ca: Array<string> = document.cookie.split(';');
        const caLen: number = ca.length;
        const cookieName = `${name}=`;
        let c: string;

        for (let i = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) === 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    formatJiomeetId(meetingId) {
        let gap = 4;
        let count = 0;
        let result = '';
        for (let i = meetingId.length - 1; i >= 0; i--) {
            result = result + meetingId[i];
            count++;

            if (count === gap) {
                result += ' ';
                count = 0;

                if (gap === 4) {
                    gap = 3;
                }
            }
        }
        return result.split('').reverse().join('').trim();
    }

    mapTranslateData(dataObj = {}, translatedData) {
        Object.keys(dataObj).map((menu) => {
            // check if the key value is object then again recursively call the function
            if (dataObj[menu] instanceof Object) {
                dataObj[menu] = this.mapTranslateData(dataObj[menu], translatedData);
            } else if (dataObj[menu] instanceof Array) {
                // check if key value is an array of objects then map through the data
                dataObj[menu] = dataObj[menu].map((childDataObj) => {
                    childDataObj = this.mapTranslateData(childDataObj, translatedData);
                    return childDataObj;
                });
            } else {
                // check if translated key exist
                dataObj[menu] = translatedData[`website.t${menu}`] ?? dataObj[menu];
            }
        });

        return dataObj;
    }

    async handleDataTranslation(translatedDataKey, dataObj = {}) {
        return new Promise(async (resolve, reject) => {
            // import the language json file
            this.#ENJSON = this.#ENJSON ?? (await import('../../../assets/i18n/en.json'));
            if (this.#traslationData[translatedDataKey]) {
                const translatedData = this.mapTranslateData(dataObj, this.#traslationData[translatedDataKey]);
                resolve(translatedData);
            } else
                this.translateService
                    .get([...Object.keys(this.#ENJSON[translatedDataKey]).map((c) => `${translatedDataKey}.${c}`)])
                    .subscribe((translations) => {
                        this.#traslationData[translatedDataKey] = translations;
                        const translatedData = this.mapTranslateData(dataObj, translations);
                        resolve(translatedData);
                    });
        });
    }

    getLeftRightWidth(afterWidth = 1366) {
        return window.innerWidth - 1366 > 0 ? window.innerWidth - 1366 : 0;
    }

    formatAMPM(date) {
        date = new Date(date);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm =
            hours >= 12
                ? this.translateService.instant('meetingcard.pm')
                : this.translateService.instant('meetingcard.am');

        hours = hours % 12;
        hours = hours ? hours : 12;
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    formatTopicName(topicName) {
        let default_index = topicName.search('s JioMeet Meeting');
        if (default_index == -1) {
            return topicName;
        }
        return this.translateService.instant('meetingcard.topic_name', {
            value: topicName.slice(0, default_index - 1)
        });
    }

    getShortenerUrl() {
        return window.location.origin;
    }

    getMeetingShareInfo(meetingId: String, meetingPin: String) {
        const shortenerUrl = this.getShortenerUrl();
        return {
            url: `${shortenerUrl}/shortener?meetingId=${meetingId}&pwd=${meetingPin}`,
            pin: meetingPin
        };
    }

    async getUpdatedPermissions() {
        const videoStream = await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
        videoStream.getVideoTracks().forEach((track) => track.stop());
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
        audioStream.getAudioTracks().forEach((track) => track.stop());
    }

    async getDevicePermissions(requestInitFrom) {
        if (this.appService.isDesktopApp()) return this.getDesktopDevicePermissions(requestInitFrom);
        else return this.getWebDevicePermissions(requestInitFrom);
    }

    async getWebDevicePermissions(requestInitFrom) {
        const devicePermissions = {
            noVideoDevice: false,
            noAudioDevice: false
        };
        try {
            const videoStream = await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
            videoStream.getVideoTracks().forEach((track) => track.stop());
            if (videoStream?.getVideoTracks()?.length === 0) {
                devicePermissions.noVideoDevice = true;
            }
        } catch (e) {
            devicePermissions.noVideoDevice = true;
            console.error('Camera - Device error from ' + requestInitFrom + ':', e);
        }
        try {
            const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
            audioStream.getAudioTracks().forEach((track) => track.stop());
            if (audioStream?.getAudioTracks()?.length === 0) {
                devicePermissions.noAudioDevice = true;
            }
        } catch (e) {
            devicePermissions.noAudioDevice = true;
            console.error('Mic - Device error from ' + requestInitFrom + ':', e);
        }
        return devicePermissions;
    }

    async getDesktopDevicePermissions(requestInitFrom) {
        const devicePermissions = {
            noVideoDevice: false,
            noAudioDevice: false
        };
        devicePermissions.noAudioDevice =
            (await this.desktopAppService.isDevicePermissionAllowed('microphone')) === false;
        devicePermissions.noVideoDevice = (await this.desktopAppService.isDevicePermissionAllowed('camera')) === false;
        return devicePermissions;
    }

    async checkIfCameraDoesntExists() {
        let allDevices = await navigator.mediaDevices.enumerateDevices();
        let videoDevices = allDevices.filter((device) => device.kind === 'videoinput');
        if (videoDevices.length === 0) {
            return true;
        } else {
            return false;
        }
    }

    ngOnDestroy() {
        this.resizeSubscription$.unsubscribe();
    }

    fetchUserId(user) {
        return user?._id?.substring(0, 2) === 'c-' ? user?.userId : user?._id;
    }

    deviceType() {
        const ua = window.navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return 'Tablet';
        } else if (
            /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                ua
            )
        ) {
            return 'mobile';
        }
        return this.fetchPlatformType();
    }

    getReadableFileSizeString = (fileSizeInBytes: any) => {
        let i = -1;
        const byteUnits = [' kbps', ' Mbps', ' Gbps', ' Tbps', 'Pbps', 'Ebps', 'Zbps', 'Ybps'];
        do {
            fileSizeInBytes = fileSizeInBytes / 1024;
            i++;
        } while (fileSizeInBytes > 1024);

        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    };
    findPacketLossPercentage = (packetCount: number, packetsLost: number) => {
        let value = (packetsLost / packetCount) * 100;
        value = isFinite(value) ? value : 0;
        return value.toFixed(2);
    };

    downloadICSFile(jiomeetId, roomId, redirectToHome?: false) {
        return this.restService.get(`api/external/downloadICSFile?jiomeetId=${jiomeetId}&roomPIN=${roomId}`, {
            responseType: 'text',
            observe: 'response'
        });
    }

    downloadMeetingFile(filename = 'meeting.ics', text) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.setAttribute('target', '_blank');
        element.style.display = 'none';
        element.click();
    }

    handleUrlRedirection() {
        this.router.navigate(['/login'], {
            queryParams: {
                returnUrl: this.router.routerState.snapshot.url
            }
        });
    }

    onSuccessDownloadFile(_res, redirectToHome?: false) {
        const header = _res.headers.get('content-disposition');
        const filename = header.split('=')[1];
        this.downloadMeetingFile(filename, _res.body);
        if (redirectToHome) {
            this.router.navigate(['/home']);
        }
    }

    convertBase64ToString(base64string) {
        return atob(base64string);
    }

    canDownloadOrPreviewAttachments(senderDetails, currentUser) {
        if (senderDetails?._id === currentUser?._id) {
            return false;
        }
        if (senderDetails.isDataProtection) {
            return senderDetails?.tenantId === currentUser?.tenantId ? false : true;
        }
        return senderDetails.isDataProtection;
    }

    removeDuplicatesByProperty(arr: any[], prop: string): any[] {
        const uniqueKeys = new Set();
        return arr.filter((item) => {
            const key = item[prop];
            uniqueKeys.add(key);
            return true;
        });
    }
    insertTextFragment(fragment: DocumentFragment) {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            range.deleteContents();
            range.insertNode(fragment);
        }
    }
    isBase64(str: string): boolean {
        return (
            str.includes('data:image/jpeg;base64') ||
            str.includes('data:image/png;base64') ||
            str.includes('data:image/gif;base64') ||
            str.includes('data:image/bmp;base64')
        );
    }
    base64ToBlob(base64: string, contentType: string = ''): Blob | null {
        try {
            const prefixToRemove = `<img src="data:image/${contentType};base64,`;
            base64 = base64.substring(prefixToRemove.length);
            base64 = base64.split(' ')[0];
            base64 = base64.slice(0, -1);
            const arrayBuffer = new Uint8Array(
                atob(base64)
                    .split('')
                    .map((char) => char.charCodeAt(0))
            );
            return new Blob([arrayBuffer]);
        } catch (error) {
            console.error('Error converting base64 to Blob:', error);
            return null;
        }
    }
    emitAttachmentLoaded() {
        this.attachmentLoadedSubject.next();
    }
    createOrder() {
        var endPoint = 'api/jiochargeit/createorder';
        var user_token = this.localStorageService.getItem('userInfo').jwt;
        var HOST_URL = this.appService.getEnvVariable('HOST_URL');
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + user_token,
            'Content-Type': 'application/json'
        });
        const os = this.getOS();
        const os_version = this.getOsVersion();
        const createOrder = this.appService.getConfigVariable('CREATE_ORDER');
        const requestBody = {
            planId: createOrder?.planId,
            callbackUrl: `${HOST_URL}subscription?status=success,${HOST_URL}subscription?status=failure`,
            paymentGateway: createOrder?.paymentGateway,
            country: createOrder?.country,
            paymentMode: createOrder?.paymentMode,
            source: os,
            platform: {
                os: os,
                version: os_version
            },
            isRecurringSupported: false,
            paymentFlow: 'seamless',
            productName: createOrder?.productName,
            billingFrequency: createOrder?.billingFrequency
        };
        return this.restService.post(endPoint, requestBody, { headers });
    }
    getAddress(pincode: string) {
        var endPoint = `api/my_profile/address?pincode=${pincode}`;
        var HOST_URL = this.appService.getEnvVariable('HOST_URL');
        var user_token = this.localStorageService.getItem('userInfo').jwt;
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + user_token,
            'Content-Type': 'application/json'
        });
        return this.restService.get(endPoint, { headers });
    }

    getMaxTiles(currentUser) {
        if (this.isMobileBrowser()) return 6;
        return currentUser?.tenantId ? 24 : currentUser?.isOTT ? 15 : 8;
    }

    fetchPricingData() {
        return this.restService.get('api/jiochargeit/getplans?productName=jiomeet');
    }
}
