import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { interval } from 'rxjs';

@Component({
    selector: 'app-chat-toast',
    templateUrl: './chat-toast.component.html',
    styleUrls: ['./chat-toast.component.scss']
})
export class ChatToastComponent implements OnInit {
    @Input() toastTrigger;
    @Input() toastObject;
    @Input() messageType;
    @Input() unreadParticipantCount;
    @Input() isLargeMeeting;
    @Output() clickEmitter = new EventEmitter();
    @Output() closeEmitter = new EventEmitter();
    constructor() {}

    ngOnInit(): void {
        setTimeout(() => {
            this.closeEmitter.emit();
        }, 3000);
    }

    public onClick(type, param) {
        this.clickEmitter.emit({ type, param });
    }

    public close() {
        this.closeEmitter.emit();
    }
}
