<div class="container">
    <div class="box">
        <div class="text">Thank you for joining the meeting</div>
        <div class="image">
            <img src="assets/img/website/thankyou.png" alt="" />
        </div>

        <div class="rejoin-meeting">
            {{ 'thankyou.rejoinMeetingMessage' | translate }}
        </div>
        <div class="btn">
            <button (click)="rejoinMeeting()">
                {{ 'thankyou.rejoin_button' | translate }}
            </button>
        </div>
        <hr class="divider" />
        <div class="subText">
            This call was powered by JioMeet Platform API. <span (click)="goto()">Learn more.</span>
        </div>
    </div>
</div>
