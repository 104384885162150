<div class="website-conference" id="website-top">
    <section appForceFullWidth class="full-width">
        <div class="conference-top" [class.padding-h-0]="isMobile" [class.width-1366]="!isMobile">
            <div class="left-content" [class.padding-h-16]="isMobile">
                <div class="heading no-spacing" *ngIf="!isAuthenticated">
                    {{ 'website_events.jio' | translate }}
                    <span class="conference-highlight-0">{{ 'website_conference.conference' | translate }}</span>
                </div>
                <div
                    class="heading no-spacing welcome-text"
                    *ngIf="isAuthenticated"
                    [ngStyle]="{ 'max-width': isMobile ? '70%' : '40%' }"
                >
                    <div class="username">
                        {{ 'website_conference.hi' | translate }} {{ currentUser?.name + ' ' + currentUser?.lname }},
                    </div>
                </div>
                <div class="desc">
                    <span *ngIf="!isAuthenticated" class="conference-highlight-1">{{
                        'website_conference.top-desc-0' | translate
                    }}</span>
                    <span *ngIf="isAuthenticated">{{
                        'website_conference.connect_audiences_jio_events' | translate
                    }}</span>
                </div>
                <div class="action-btns">
                    <button class="custom-action-btn" *ngIf="!isAuthenticated" (click)="goToContactSales(1)">
                        {{ 'website_conference.contact_sales' | translate }}
                    </button>
                    <button class="custom-action-btn" *ngIf="isAuthenticated" (click)="navToCreateEvents()">
                        {{ 'website_conference.create_events' | translate }}
                    </button>
                    <button class="custom-action-btn secondary" *ngIf="!isAuthenticated" (click)="navToJioEvents()">
                        {{ 'website_conference.jioevents_website' | translate }}
                    </button>
                    <button
                        class="custom-action-btn secondary"
                        *ngIf="isAuthenticated"
                        (click)="navToEventsDashboard()"
                    >
                        {{ 'website_conference.goto_events_dashboard' | translate }}
                    </button>
                </div>
            </div>
            <div class="right-content">
                <img src="assets/img/conference/conference-top-mobile.png" *ngIf="isMobile" alt="" />
            </div>
        </div>
    </section>
    <section class="sec-2" [class.padding-h-16]="isMobile">
        <div *ngFor="let productFeature of websiteConferenceFeatures; let i = index">
            <app-website-product-features
                [productFeature]="productFeature"
                [reverse]="i % 2 != 0"
            ></app-website-product-features>
        </div>
    </section>

    <section class="usecase-sec" [class.padding-h-16]="isMobile">
        <h2 class="sec-head text-center">
            {{ 'website_conference.tailored_for_usecase' | translate }}
        </h2>
        <div class="container-block">
            <div class="usecase-container">
                <div class="usecase-block" [class.make-center]="isMobile" *ngFor="let usecase of tailored_usecase">
                    <div [class.img]="usecase?.feature || usecase?.desc" *ngIf="usecase?.icon">
                        <img [src]="usecase?.icon" [alt]="usecase?.feature" />
                    </div>
                    <div class="usecase-info">
                        <div class="title" *ngIf="usecase?.feature">
                            {{ usecase?.tfeature | translate }}
                            <div
                                class="coming-soon-container"
                                *ngIf="usecase?.hasFlagTag && usecase?.feature === 'Parallel Sessions'"
                            >
                                <span *ngIf="usecase?.hasFlagTag" class="coming-soon">
                                    {{ usecase.thasFlagTag | translate }}</span
                                >
                            </div>
                            <span
                                *ngIf="usecase?.hasFlagTag && usecase?.feature !== 'Parallel Sessions'"
                                class="coming-soon"
                            >
                                {{ usecase.thasFlagTag | translate }}</span
                            >
                        </div>
                        <div class="desc" [innerHTML]="usecase?.tdesc | translate">
                            {{ usecase.tdesc | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section
        class="website-content partners"
        appForceFullWidth
        [class.padding-h-16]="isMobile"
        [style.display]="jioEventsBranding?.length === 0 ? 'none' : 'block'"
    >
        <h4 class="heading">{{ 'website_home.trusted_partners' | translate }}</h4>
        <div class="partners-list">
            <ng-container *ngFor="let partner of jioEventsBranding">
                <img src="{{ partner.path }}" alt="" />
            </ng-container>
        </div>
    </section>
    <section
        class="testimonials sec"
        [class.sec-margin-h-44]="isMobile"
        [style.display]="jioEventsTestimonials?.length === 0 ? 'none' : 'block'"
    >
        <h2 class="heading-0">{{ 'website_home.customers_comment' | translate }}</h2>
        <div class="testimonial-slider" #testimonialSlider>
            <div class="slide" *ngFor="let testimonial of jioEventsTestimonials">
                <app-website-testimonials [testimonial]="testimonial"></app-website-testimonials>
            </div>
        </div>
    </section>

    <section class="sec-3" [class.padding-h-0]="isMobile" [class.infinite-reach-bg]="!isMobile">
        <div class="heading-0" [class.padding-h-16]="isMobile" *ngIf="isMobile">
            <span>{{ 'website_conference.connect_to_world' | translate }},</span>
            <span>{{ 'website_conference.experience_infinite_reach' | translate }}</span>
        </div>
        <div class="img">
            <img src="assets/img/conference/infinite-reach.png" *ngIf="isMobile" alt="" />
            <img src="assets/img/conference/infinite-reach-desktop.png" *ngIf="!isMobile" alt="" />
        </div>

        <div [class.padding-h-16]="isMobile" class="info">
            <div class="heading-0" [class.padding-h-16]="isMobile" *ngIf="!isMobile">
                <span>{{ 'website_conference.connect_to_world' | translate }},</span>
                <span>{{ 'website_conference.experience_infinite_reach' | translate }}</span>
            </div>
            <div class="action-btns">
                <button class="custom-action-btn" *ngIf="!isAuthenticated" (click)="goToContactSales(2)">
                    {{ 'website_conference.contact_sales' | translate }}
                </button>
                <button class="custom-action-btn" *ngIf="isAuthenticated" (click)="navToCreateEvents()">
                    {{ 'website_conference.create_events' | translate }}
                </button>
                <button class="custom-action-btn secondary" *ngIf="isAuthenticated" (click)="navToEventsDashboard()">
                    {{ 'website_conference.goto_events_dashboard' | translate }}
                </button>
            </div>
        </div>
    </section>
</div>
