<div class="content-wrapper">
    <h3>{{ 'linkexpiredpage.linkexpired' | translate }}</h3>
    <div class="img-wrapper my-4">
        <img alt="" src="assets/img/link_expired_lg.svg" />
    </div>
    <div class="text text-center">
        {{ 'linkexpiredpage.no_longer_valid' | translate }}
    </div>
    <div class="btn">
        <button type="button" class="custom-button bg-primary lg" (click)="resendEmail()">
            {{ 'linkexpiredpage.sendnewlink' | translate }}
        </button>
    </div>
    <div class="sign-in">
        <a (click)="navigateToLogin()">{{ 'linkexpiredpage.signin' | translate }}</a>
    </div>
</div>
