import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { JioCloudUtilService } from 'src/app/core/services/jio-cloud-util.service';
import { DesktopAppService } from 'src/app/core';
import { IpcRenderer } from 'electron/renderer';
@Component({
    selector: 'app-viewer',
    templateUrl: './viewer.component.html',
    styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit {
    @Input() item;
    @Input() isDataProtection;
    @Input() jiomeetId;
    @Output() close = new EventEmitter();
    show = false;
    constructor(private jioCloudUtilService: JioCloudUtilService, public desktopAppService: DesktopAppService) {}
    supportedFormat: 'Image' | 'Doc' | 'Video' | 'Audio' | 'None' = 'None';

    ngOnInit() {
        this.desktopAppService.setContentProtection(this.isDataProtection);
        this.supportedFormat = this.jioCloudUtilService.getFileFormat(this.item.fileName);
        setTimeout(() => {
            this.show = true;
        }, 300);
    }
    ngOnDestroy() {
        this.desktopAppService.setContentProtection(false);
    }

    closePreview(e) {
        this.close.emit(true);
    }
}
