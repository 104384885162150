<div class="attachment rounded py-2" [ngClass]="desktopAppService?.isDesktopApp ? 'desktop-attachment' : ''">
    <div class="upload-txt">
        <div class="thumbnail pointer" (click)="openPreview()">
            <div
                *ngIf="fileType == 'Image'"
                title="{{ item.fileName }}"
                class="pt-2 pr-5"
                class="image-file"
                [class.showAttachment]="showAttachments"
            >
                <div *ngIf="isAuthenticated && isPreviewDownloadEnabled" class="image-container">
                    <img
                        alt=""
                        class="{{ applyClass }} pointer"
                        class="img"
                        class="img-size"
                        [ngStyle]="{ 'max-width': maxWidth }"
                        [class.chat-max-width]="maxWidth === '250px'"
                        [class.inside-call-max-width]="maxWidth === '190px'"
                        [attr.src]="
                            item?.imageTrancodedURL
                                ? (item?.imageTrancodedURL | jioCloudIageAuth : jiomeetId : 's' | async)
                                : thumbnailUrl
                        "
                        loading="lazy"
                    />
                </div>
                <div class="download-icon" (click)="downloadItem(item, $event)">
                    <img
                        alt=""
                        title="Download"
                        class="img downloadIcon mx-2 pointer"
                        src="assets/images/downloadIcon2.svg"
                    />
                </div>
                <div *ngIf="!isAuthenticated || !isPreviewDownloadEnabled">
                    <img alt="" class="defaultIcon pointer" src="assets/images/default-img.svg" />
                </div>
            </div>
            <div *ngIf="fileType == 'Video'" title="{{ item.fileName }}" class="image-file">
                <div *ngIf="isAuthenticated && isPreviewDownloadEnabled" class="video-container">
                    <img alt="" class="{{ playIcon }}" src="assets/images/videoPlayIcon.svg" />
                    <img
                        alt=""
                        class="{{ applyClass }} pointer"
                        class="video-img-size"
                        [ngStyle]="{ 'max-width': maxWidth }"
                        [class.chat-max-width]="maxWidth === '250px'"
                        [class.inside-call-max-width]="maxWidth === '190px'"
                        [attr.src]="
                            item?.imageTrancodedURL
                                ? (item?.imageTrancodedURL | jioCloudIageAuth : jiomeetId : 's' | async)
                                : thumbnailUrl
                        "
                    />
                </div>
                <div class="download-icon" (click)="downloadItem(item)">
                    <img
                        alt=""
                        title="Download"
                        class="img downloadIcon mx-2 pointer"
                        src="assets/images/downloadIcon2.svg"
                    />
                </div>
                <div *ngIf="!isAuthenticated || !isPreviewDownloadEnabled">
                    <img alt="" class="defaultIcon pointer" src="assets/images/defaultVideoIconForUploading.svg" />
                </div>
            </div>
            <div class="audio-container" *ngIf="fileType == 'Audio'">
                <div
                    title="{{ item.fileName }}"
                    class="p-3 bg-white"
                    class="audio-file"
                    [class.showAttachment]="showAttachments"
                >
                    <img alt="" class="{{ applyClass }} pointer" src="assets/images/defaultAudioIconlarge.svg" />
                </div>
                <div class="download-icon" (click)="downloadItem(item)">
                    <img
                        alt=""
                        title="Download"
                        class="img downloadIcon mr-2 pointer"
                        src="assets/images/downloadIcon2.svg"
                    />
                </div>
            </div>

            <div
                *ngIf="fileType == 'Doc'"
                title="{{ item.fileName }}"
                class="doc-file"
                [class.showAttachment]="showAttachments"
            >
                <div class="doc-logo">
                    <img
                        alt=""
                        class="{{ applyClass }} pointer"
                        *ngIf="docType == 'Word'"
                        src="assets/images/word_icon.png"
                    />
                    <img
                        alt=""
                        class="{{ applyClass }} pointer"
                        *ngIf="docType == 'Excel'"
                        src="assets/images/Excel_icon.png"
                    />
                    <img
                        alt=""
                        class="{{ applyClass }} pointer"
                        *ngIf="docType == 'PPT'"
                        src="assets/images/ppt_icon.png"
                    />
                    <img
                        alt=""
                        class="{{ applyClass }} pointer"
                        *ngIf="docType == 'PDF'"
                        src="assets/images/PDF_file_icon.png"
                    />
                    <img
                        alt=""
                        class="{{ applyClass }} pointer"
                        *ngIf="docType == 'TXT'"
                        src="assets/images/default_text_icon.svg"
                    />
                    <img
                        alt=""
                        class="{{ applyClass }} pointer"
                        *ngIf="docType == 'ZIP'"
                        src="assets/images/zip_icon.png"
                    />
                    <img
                        alt=""
                        class="{{ applyClass }} pointer"
                        *ngIf="docType == 'Other'"
                        src="assets/images/defaultDocsIcon.svg"
                    />
                </div>
                <div class="file-container mx-2">
                    <div class="filename">
                        <label class="fit-text">
                            {{ item.fileName | trimFileName }}
                        </label>
                    </div>
                    <label class="fit-label-text"> {{ item.fileSize | filesizeConverter }} . {{ fileType }} </label>
                </div>
                <div class="download-icon" (click)="downloadItem(item)">
                    <img
                        alt=""
                        title="Download"
                        class="img downloadIcon mr-2 pointer"
                        src="assets/images/downloadIcon2.svg"
                    />
                </div>
            </div>
            <div class="file-container mx-2" *ngIf="fileType == 'Video'">
                <div class="filename">
                    <label class="fit-text">
                        {{ item.fileName | trimFileName }}
                    </label>
                </div>
                <label class="fit-label-text"> {{ item.fileSize | filesizeConverter }} . {{ fileType }} </label>
            </div>
            <div class="file-container mx-2" *ngIf="fileType == 'Audio'">
                <div class="filename">
                    <label class="fit-text">
                        {{ item.fileName | trimFileName }}
                    </label>
                </div>
                <label class="fit-label-text"> {{ item.fileSize | filesizeConverter }} . {{ fileType }} </label>
            </div>
        </div>
    </div>
    <div class="popup-component" *ngIf="showFileInfo">
        <div class="bg"></div>
        <div class="popup">
            <div class="popup-header">
                <h3 tabindex="0" class="fileInfoHeading file-info-title">
                    {{ 'File Info' }}
                </h3>
                <div
                    tabindex="0"
                    (keyup.enter)="toggleFileInfo(item)"
                    class="close"
                    aria-label="Close file info dialog box"
                    (click)="toggleFileInfo(item)"
                >
                    <img alt="" src="assets/img/close_icon.svg" />
                </div>
            </div>
            <div class="popup-body">
                <label class="file-info-label">
                    {{ 'File Name: ' + item.fileName }}
                </label>
                <label class="file-info-label">
                    {{ 'File Type: ' + fileType }}
                </label>
                <label class="file-info-label"> {{ 'File Size: ' }} {{ item.fileSize | filesizeConverter }} </label>
                <label class="file-info-label">
                    Shared by {{ message?.creatorsName }} {{ message?.creatorsLName }} on
                    {{ message?.cOn | date : 'dd-MM-yyyy' }}
                </label>
            </div>
        </div>
    </div>
    <p-confirmDialog key="deleteItem_{{ item.fileID }}"></p-confirmDialog>
    <button class="hide" id="downloadAnchor" #downloadAnchor></button>
</div>
