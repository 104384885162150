<div class="general-input">
    <div class="checkbox-wrapper" *ngIf="currentUser?.tenantId != null">
        <div class="custom-control custom-checkbox">
            <input
                tabindex="0"
                type="checkbox"
                class="custom-control-input"
                id="enableOnBehalfSchedule"
                [(ngModel)]="isOnBehalfEnabled"
                (change)="onBehalfHostEnabled($event)"
            />
            <label class="custom-control-label pointer" for="enableOnBehalfSchedule">{{
                'planmeeting_popup.allow_other_to_schedule' | translate
            }}</label>
        </div>
        <div class="help">
            {{ 'planmeeting_popup.allow_other_to_schedule_desc' | translate }}
        </div>
    </div>
    <div *ngIf="showAllowedUserList" class="subelement">
        <label
            tabindex="0"
            [ngClass]="isOnBehalfEnabled == true ? '' : 'disable-text'"
            aria-label="Add their email addresses below (maximum of 20)"
            >{{ 'planmeeting_popup.add_email_addresses' | translate }}</label
        >
        <app-participants-input
            [isFromMeetingCreation]="false"
            [isParticipantsField]="false"
            tabindex="0"
            [isEnabledBehalf]="isOnBehalfEnabled"
            [ngClass]="isOnBehalfEnabled == true ? '' : 'disable-text'"
            aria-label="Search by Name, Email ID or Mobile No (+91)"
            [isOnBehalfSection]="true"
            [isOnBehalfSelectedUsers]="usersList"
            (groupParticipants)="selectedUserList($event)"
        >
        </app-participants-input>
    </div>

    <div class="checkbox-wrapper">
        <div class="custom-control custom-checkbox">
            <input
                type="checkbox"
                class="custom-control-input"
                id="enablePopupcheck"
                [(ngModel)]="isConfirmationEnabled"
                (keydown.Tab)="setFocusOnElement()"
                (keydown.enter)="onConfirmationCheckEntered()"
                (change)="onConfirmationPopUpEnabled($event)"
            />
            <label class="custom-control-label pointer pl-2" for="enablePopupcheck">
                {{ 'joinmeeting_page_or_joincall.leave_confirmation_popup' | translate }}
            </label>
        </div>
    </div>
</div>
