import { Injectable } from '@angular/core';
import {
    CUSTOM_LAYOUT,
    ICustomMediaType,
    MEDIA_LAYER_RANGE,
    IConnectionState,
    CallManager,
    EventManager,
    EVENT,
    JMMediaWeb,
    Preview,
    DeviceManager,
    RemoteUser,
    IResolution
} from '@jiomeet/jm-media-web';
import { BehaviorSubject, Subject } from 'rxjs';
import { ExternalInterfaceService } from './external-interface.service';
import { RtcService } from './rtc.service';
import { UserService } from './user.service';
import { VirtualBgService } from './virtual-bg.service';
import { UtilService } from './util.service';
import { APP_EVENTS, DEVICE_ERROR_CONSTANTS, VIDYO_EVENTS } from 'src/app/constants';
import { AuthService } from './auth.service';
import { AppLoggerService } from './app-logger.service';
import { EventEmitterService } from './event-emitter.service';
import { AppService } from './app.service';
import { ThirdPartyExternalIntegrationService } from './third-party-external-integration.service';
import { LocalStorageService } from './local-storage.service';

declare const window;
interface IGoLive {
    record: boolean;
    streaming?: {
        url: string;
        channel: 'YOUTUBE' | 'FACEBOOK' | 'CUSTOM';
    };
    meetingUrl: string;
}
enum SignalStatus {
    NONE = 'NONE',
    VERYBAD = 'VERYBAD',
    GOOD = 'GOOD',
    BAD = 'BAD'
}
@Injectable({
    providedIn: 'root'
})
export class JmMediaService {
    isStopRecordingElementAdded = false;
    isAuthenticated = false;
    isAudioPublished = false;
    isVideoPublished = false;
    recording = false;
    processingToggleMic: boolean = false;
    processingToggleCamera: boolean = false;
    callInProgress: boolean = false;
    jmRTC = null;
    currentSenderId = '';
    jmMediaClient;
    currentUser;
    conferenceInfo;
    joinData;
    reconnecting = false;
    localParticipant;
    noMediaPermission = false;
    noAudioPermission = false;
    noCameraPermission = false;
    isBreakoutRoom = false;
    leaveBreakoutRoom = false;
    users = {};
    vcs = {};
    cameras = [];
    microphones = [];
    remoteParticipants = [];
    speakers = [];
    selectedLocalCamera = '';
    selectedLocalMicrophone = '';
    selectedLocalSpeaker = '';

    oldSelectedLocalCamera = '';
    oldSelectedLocalMicrophone = '';
    oldSelectedLocalSpeaker = '';

    selectedLocalMicrophoneGroupId = '';
    selectedLocalSpeakerGroupId = '';

    microphonesData;
    speakersData;
    isPostionChecked = false;

    microphoneMutedAll = false;
    roomLocked = false;
    screenSharing = false;
    waitingUsers = {};
    remoteScreenSharingStream;
    isScreenShareAvaiable = false;
    roomData;
    previewInstance;
    // localCameraTrack: any = null;
    isAudioOnly = false;
    breakoutRoomInfo;
    canJoinAsSpeaker = false;
    isAutoplayFailed = false;
    MAX_VIDEO_TILES = 9;
    MAX_VIDEO_TILES_FOR_SQUARE_TILE_LAYOUT = 8;
    MAX_VIDEO_TILES_IN_SCREEN_SHARING = 3;
    MAX_VIDEO_TILES_IN_SCREEN_SHARING_FOR_SQUARE_TILE_LAYOUT = 4;
    MAX_SUPPORTED_VIDEO_TILES_IN_GRID_LAYOUT = 24;
    MAX_SUPPORTED_VIDEO_TILES_IN_GRID_LAYOUT_FOR_OTT = 15;
    MAX_VIDEO_TILES_IN_SPEAKER_LAYOUT = 4;
    SPEAKER_LIMIT = 25;
    AUDIENCE_LIMIT = 1000;
    activeSpeakerList = [];
    joiningRoom: boolean = false;
    joinedInRoom: boolean = false;
    isActiveSpeakerLayout: boolean = false;
    isFitToScreen = true;
    dominantSpeaker;
    signalStrengthData = {
        signal: SignalStatus.GOOD
    };
    counterForGoodSignal = 0;
    counterForBadSignal = 0;
    counterForVeryBadSignal = 0;
    networkQuality;
    hideNonVideoSelected = false;
    showActiveSpeaker = false;
    unPublishMicrophoneTrack: any;
    reconnectPopupTimer;
    jmCallDisconnected = false;
    localPeerId: string = '';
    oldPeerId: string = '';
    assignedRole: string = 'host';
    assignedVideoResolution: string = '360p';
    showedLimitReached = true;
    enabledAudienceModeFeature = false;
    enableFullHdStreaming = false;

    private whiteboardShared;

    private meeting$: BehaviorSubject<any> = new BehaviorSubject(null);
    private participantsStatus$: BehaviorSubject<any> = new BehaviorSubject(null);
    private chatMessages$: BehaviorSubject<any> = new BehaviorSubject(null);

    private disconneting = false;
    private alreadySharingScreen = false;

    numberOfVideoTilesSelected;
    numberOfVideoTilesSelectedSubject = new Subject<number>();
    audioLevel = new Subject();
    isConnected = new Subject<boolean>();
    participantStatusUpdated = new Subject<any>();
    isVideoOn = new BehaviorSubject<boolean>(false);
    participantAdded = new Subject<any>();
    participantRemoved = new Subject<any>();
    hideScreenShareNotification = new Subject<boolean>();
    $speakerLayoutSelected = new BehaviorSubject<boolean>(false);
    $hideNonVideoSelected = new BehaviorSubject<boolean>(false);

    videoDevicesUpdated = new Subject<boolean>();
    audioDevicesUpdated = new Subject<boolean>();
    errors = new Subject<any>();
    headlessUserToken: any;

    constructor(
        private userService: UserService,
        private rtcService: RtcService,
        private externalInterfaceService: ExternalInterfaceService,
        private vbService: VirtualBgService,
        private utilService: UtilService,
        private authService: AuthService,
        private appLoggerService: AppLoggerService,
        private eventEmitterService: EventEmitterService,
        private appService: AppService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        private localStorageService: LocalStorageService
    ) {
        this.previewInstance = new Preview();
    }

    get screenShareUser() {
        return this.jmMediaClient?.subscribedScreenShareUsers[0];
    }

    get localMicrophoneTrack() {
        return this.jmMediaClient?.localUser?.audioTrack;
    }

    setRecordingStatus(isRecording) {
        this.recording = isRecording;
    }

    get localCameraTrack() {
        return this.jmMediaClient?.localUser?.videoTrack;
    }

    get overallStats() {
        return this.jmMediaClient.overallStats;
    }

    getParticipantStatus() {
        return this.participantsStatus$;
    }

    getLocalParticipant() {
        return this.localParticipant;
    }

    getMeetingObs() {
        return this.meeting$;
    }
    getChatMessages() {
        return this.chatMessages$;
    }

    get localHasAudio() {
        return this.jmMediaClient?.localUser?.hasAudio;
    }

    get localHasVideo() {
        return this.jmMediaClient?.localUser?.hasVideo;
    }

    get userToken() {
        return this.jmMediaClient.userToken;
    }
    setHostDisconnectParticipant(id) {
        if (this.users[id]) {
            this.users[id].disconnectedByHost = true;
        }
    }
    toggleLockStatus(enable) {
        this.roomLocked = enable;
        this.sendControlStatus();
    }
    updateUserSelectedLayout(layoutType) {
        this.jmMediaClient.updateUserSelectedLayout(layoutType);
    }
    setUserSelectedLayout(layoutType) {
        this.jmMediaClient.setUserSelectedLayout(layoutType);
    }
    get userSelectedLayout() {
        return this.jmMediaClient.currentLayout.userSelectedLayout;
    }
    get currentLayout() {
        return this.jmMediaClient.currentLayout.currentLayout;
    }
    get isRemoteScreenShare() {
        return this.jmMediaClient.isRemoteScreenShare;
    }

    get isMobileDevice() {
        return (
            this.thirdPartyExternalIntegrationService.getThirdPartyExternalIntegration() ||
            this.utilService.isMobileBrowser()
        );
    }

    getDevices() {
        this.cameras = this.jmMediaClient.cameras;
        this.microphones = this.jmMediaClient.microphones;
        this.speakers = this.jmMediaClient.speakers;
    }

    async initializeVidyoConnector(ignoreJoinCall?) {
        if (this.joiningRoom || this.joinedInRoom) {
            let err = new Error('error while joining into the room');
            err.message = `jm-media:`;
            this.appLoggerService.debug('Duplicate call to JoinRoom', err);
            return;
        }
        this.joinedInRoom = true;
        this.currentUser = this.userService.getUserSync();
        const meetingDomain = this.joinData.host;
        if (!meetingDomain) {
            let err = new Error('error while joining into the room');
            err.message = `jm-media:`;
            this.appLoggerService.debug('Meeting Url not found', err);
            this.joiningRoom = false;
            this.joinedInRoom = false;
            return;
        }
        this.enableFullHdStreaming = this.localStorageService.getItem('enableFullHdStreaming');
        if (this.currentUser?.tenantId && this.enableFullHdStreaming) {
            this.assignedVideoResolution = '1080p';
        } else if (this.currentUser?.proStatus || this.currentUser?.tenantId !== undefined) {
            this.assignedVideoResolution = '720p';
        } else {
            this.assignedVideoResolution = '360p';
        }
        // const vbResolution = {
        //     width: 1280,
        //     height: 720
        // };
        this.jmMediaClient = this.createRoom(CUSTOM_LAYOUT.GRID, MEDIA_LAYER_RANGE.high, meetingDomain);
        const isJmLogEnabled = this.appService.getConfigVariable('JM_LOCAL_LOGS_ENABLED')?.enable;
        const isJmLogLevel =
            this.appService.getConfigVariable('JM_LOCAL_LOGS_ENABLED')?.level !== undefined
                ? this.appService.getConfigVariable('JM_LOCAL_LOGS_ENABLED')?.level
                : 1;
        if (this.appService.isJmStatsEnable() || isJmLogEnabled) {
            this.jmMediaClient.setLogLevel(isJmLogLevel);
        }
        (window as any).jiomedia = this.jmMediaClient;
        if (
            this.externalInterfaceService.askPermission &&
            !this.externalInterfaceService.isEmbibe &&
            !this.appService.getRecorderUser()
        ) {
            if (this.utilService.inIframe()) {
                await this.updateAllDevices(false);
            } else {
                await this.updateAllDevices();
            }

            this.onDeviceChangeListener();
            this.onMuteSpeakingListener();
        } else {
            this.noMediaPermission = true;
        }
        this.meeting$.next({ type: 'VC_CREATED', data: null });
        if (!!this.rtcService.getConferenceInfo() && !ignoreJoinCall) {
            this.joinRoom();
        }
        this.meeting$.next({ type: 'SHOW_PREVIEW_CNTROLS', data: null });
        EventManager.on(EVENT.AUDIO_PLAY_FAILED, () => {
            this.isAutoplayFailed = true;
        });
    }

    createRoom(
        layout: CUSTOM_LAYOUT,
        highestRecvResolution: MEDIA_LAYER_RANGE,
        meetingUrl: string,
    ) {
        return new JMMediaWeb({ highestRecvResolution, meetingUrl });
    }

    async joinRoom() {
        if (!this.jmMediaClient) {
            let err = new Error('error while joining into the room');
            err.message = `jm-media:`;
            this.appLoggerService.debug('room is not initialized', err);
            return;
        }
        const roomInfo = this.rtcService.getRoomInfo();
        this.canJoinAsSpeaker = true;
        this.conferenceInfo = this.rtcService.getConferenceInfo();
        const breakOutRoomInfo = this.rtcService.getBreakoutRoomInfo();
        let roomID;
        let roomPIN;
        // const { jiomeetId, roomPIN } = this.conferenceInfo.room;
        const isRILUser = this.currentUser.email ? this.currentUser.isRil : false;
        const isHostLimitIncreased = this.appService.getConfigVariable('isHostLimitIncreased');
        if (isRILUser && isHostLimitIncreased) {
            if (!isNaN(Number(this.conferenceInfo.jmMediaMaxParticipantsForRIL))) {
                this.SPEAKER_LIMIT = Number(this.conferenceInfo.jmMediaMaxParticipantsForRIL);
            }
        } else {
            if (!isNaN(Number(this.conferenceInfo.jmMediaMaxParticipants))) {
                this.SPEAKER_LIMIT = Number(this.conferenceInfo.jmMediaMaxParticipants);
            }
        }
        if (!isNaN(Number(this.conferenceInfo?.maxAudienceCount))) {
            this.AUDIENCE_LIMIT = Number(this.conferenceInfo?.maxAudienceCount);
        }
        this.enabledAudienceModeFeature =
            this.appService.getConfigVariable('JM_AUDIENCE_MODE')?.enabled !== undefined
                ? this.appService.getConfigVariable('JM_AUDIENCE_MODE')?.enabled
                : false;
        if (this.leaveBreakoutRoom) {
            roomID = roomInfo.jiomeetId;
            roomPIN = roomInfo.roomPIN;
            if (roomInfo.roomStatus === 'Locked') {
                this.meeting$.next({
                    type: 'FAILURE',
                    data: {
                        reason: 'Meeting room is locked.',
                        code: 'LOCKED'
                    }
                });
                return;
            }
            this.leaveBreakoutRoom = false;
            this.isBreakoutRoom = false;
        } else {
            if (breakOutRoomInfo) {
                //joing breakout room and joining breakout room from start
                this.isBreakoutRoom = true;
                roomID = breakOutRoomInfo?.roomInfo?.jiomeetId || this.conferenceInfo?.room?.jiomeetId;
                roomPIN = breakOutRoomInfo?.roomInfo?.roomPIN || this.conferenceInfo?.room?.roomPIN;
            } else {
                //joining main room
                this.isBreakoutRoom = false;
                roomID = this.conferenceInfo?.room?.jiomeetId;
                roomPIN = this.conferenceInfo?.room?.roomPIN;
            }
        }
        this.addEventListener();
        try {
            this.remoteParticipants = [];
            let joinInfo: {
                roomExtension: string;
                roomPin: string;
                userName: string;
                userType: 'human' | 'recorder' | 'livestream' | 'NSBot';
                userRole: string;
                historyId: string;
                oldPeerId?: string;
                metaData?: string;
                token?: string;
                jioApiBackendUrl?: string;
                config?: any;
                translationLanguage?: string;
                isP2PMeeting?: boolean;
            } = {
                userName: this.joinData.displayName,
                roomExtension: roomID,
                roomPin: roomPIN,
                isP2PMeeting: this.appService.getConfigVariable('enableP2P'),
                userType: this.appService.getRecorderUser() ? 'recorder' : 'human',
                userRole: this.appService.getRecorderUser() ? 'recorder' : this.assignedRole,
                historyId: this.appService.historyId
            };
            if (this.oldPeerId) {
                joinInfo = {
                    ...joinInfo,
                    oldPeerId: this.oldPeerId
                };
            }
            if (this.userService.translationLanguage) {
                joinInfo = {
                    ...joinInfo,
                    translationLanguage: this.userService.translationLanguage
                };
            }
            if (this.enabledAudienceModeFeature) {
                if (
                    this.joinData.isInitiater ||
                    this.rtcService.isHostOrCoHostOfMeeting ||
                    (this.conferenceInfo?.jmSpeakerCount < this.SPEAKER_LIMIT && !this.appService.getRecorderUser())
                ) {
                    joinInfo = {
                        ...joinInfo,
                        userRole: this.assignedRole
                    };
                } else {
                    joinInfo = {
                        ...joinInfo,
                        userRole: 'audience'
                    };
                }
            }
            if (this.assignedVideoResolution) {
                joinInfo = {
                    ...joinInfo,
                    config: {
                        maxVideoResolution: this.assignedVideoResolution
                    }
                };
            }

            if (!this.appService.getEnvVariable('BASE_URL', true).includes('localhost')) {
                joinInfo = {
                    ...joinInfo,
                    jioApiBackendUrl: this.appService.getEnvVariable('BASE_URL', true)
                };
            }
            this.jmMediaClient.enableNetworkQuality();
            await this.jmMediaClient.join(joinInfo);
            this.localPeerId = String(this.jmMediaClient.localUser.uid);
            this.oldPeerId = '';
            this.userService.localParticipantId = String(this.jmMediaClient.localUser.uid);
            let participant = {
                id: String(this.jmMediaClient.localUser.uid),
                userId: String(this.jmMediaClient.localUser.uid),
                name: this.joinData.displayName,
                isLocal: true,
                isHost: this.joinData.isInitiater ? true : false,
                microphoneMute: !this.joinData.micPrivacy,
                cameraMute: !this.joinData.cameraPrivacy,
                participantType: joinInfo.userRole === 'audience' ? 'audience' : 'speaker'
            };
            this.remoteParticipants = this.jmMediaClient.remoteUsers;
            if (participant.participantType === 'audience') {
                participant.cameraMute = true;
                participant.microphoneMute = true;
            }
            this.localParticipant = participant;
            if (this.rtcService.isGuestOfJMLargeMeeting) {
                this.appService.speakerLayoutOnBeforeScreenShare = true;
                this.updateSpeakerLayout(true);
            }
            this.meeting$.next({ type: 'ACTIVE', data: null });
            this.meeting$.next({
                type: 'LOCAL_PARTICIPANT_CONNECTED',
                data: null
            });
            setTimeout(() => {
                this.jmCallDisconnected = false;
            }, 2000);
            this.joiningRoom = false;
            this.joinedInRoom = true;
            this.callInProgress = true;
            this.setNumberOfVideoTiles();
            this.checkMicrophoneForDigiGov();
        } catch (error) {
            if (error.statusCode === 423) {
                this.eventEmitterService.emit({ type: APP_EVENTS.JM_MAX_LIMIT_REACHED, data: null });
            } else {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.JM_MEDIA_JOIN_FAILED,
                    data: {}
                });
            }
            this.disconneting = true;
            this.joinedInRoom = false;
            this.callInProgress = false;
            return;
        }
        if (!this.appService.getRecorderUser()) {
            this.publishTracks();
        }
        if (this.appService.getIsNoiseSuppression) {
            this.enableNoiseSuppression();
        }
    }

    async publishTracks() {
        if (!this.noMediaPermission) {
            try {
                if (this.selectedLocalMicrophone === '') {
                    //check added for to handle issue on safari
                    this.selectedLocalMicrophone = this.microphones[0]?.id;
                }
                let settings: any = {
                    trackSettings: {
                        isAudioEnabled: !this.localParticipant.microphoneMute,
                        isVideoEnabled: !this.localParticipant.cameraMute,
                        audioInputDeviceId: String(this.selectedLocalMicrophone)
                    },
                    virtualBackgroundSettings: {
                        isVirtualBackground: false,
                        sourceType: 'none',
                        sourceValue: '',
                        width: 1280,
                        height: 720
                    }
                };
                if (this.selectedLocalCamera) {
                    settings = {
                        trackSettings: { ...settings.trackSettings, videoDeviceId: String(this.selectedLocalCamera) }
                    };
                }
                if (this.vbService.backgroundConfig.type === 'blur') {
                    settings = {
                        trackSettings: { ...settings.trackSettings },
                        virtualBackgroundSettings: {
                            isVirtualBackground: true,
                            sourceType: 'blur',
                            sourceValue: '10'
                        }
                    };
                } else if (this.vbService.backgroundConfig.type === 'image') {
                    settings = {
                        trackSettings: { ...settings.trackSettings },
                        virtualBackgroundSettings: {
                            isVirtualBackground: true,
                            sourceType: 'image',
                            sourceValue: this.vbService.backgroundConfig.imageUrl,
                            width: 1280,
                            height: 720
                        }
                    };
                }
                this.jmMediaClient.noiseSuppression = this.appService.getIsNoiseSuppression;
                await this.jmMediaClient.startPublish(settings);
                if (this.jmMediaClient.localUser.videoTrack) {
                    this.isVideoOn.next(true);
                    this.isVideoPublished = true;
                    // this.localCameraTrack = this.jmMediaClient.localUser.videoTrack;
                }
                if (this.jmMediaClient.localUser.audioTrack) {
                    this.isAudioPublished = true;
                } else {
                    const track = await this.jmMediaClient.createMicrophoneTrack();
                    if (track) {
                        this.unPublishMicrophoneTrack = track;
                    }
                }
            } catch (e) {
                this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                    message: e?.message,
                    code: e?.code,
                    area: DEVICE_ERROR_CONSTANTS.creating_video_track
                });
            }
        }
    }

    setNumberOfVideoTiles() {
        const isRILUser = this.currentUser.email ? this.currentUser.isRil : false;
        const isJMLargeMeeting = this.rtcService.isJMLargeMeeting;
        this.numberOfVideoTilesSelected = isJMLargeMeeting
            ? this.utilService.isMobileBrowser()
                ? 6
                : 15
            : this.utilService.getMaxTiles(this.currentUser);
    }

    async makeAudience(participant) {
        try {
            await this.jmMediaClient.changeRole(participant.participantId, 'audience');
        } catch (err) {
            throw err;
        }
    }
    async makeSpeaker(participant) {
        try {
            await this.jmMediaClient.changeRole(participant.participantId, this.assignedRole);
        } catch (err) {
            throw err;
        }
    }

    showLimitReached(participants, numberOfSpeaker) {
        if (numberOfSpeaker >= this.SPEAKER_LIMIT) {
            if (
                (this.localParticipant.isHost || this.isLocalParticipantCoHost(participants)) &&
                this.showedLimitReached
            ) {
                this.meeting$.next({ type: 'SHOW_LARGE_MEETINGINFO', data: {} });
                this.showedLimitReached = false;
            }
        }
    }

    isLocalParticipantCoHost(participants) {
        let localListParticipant = participants.find(
            (p) => p.isCoHost === true && p.participantUri === this.localParticipant.userId
        );
        if (localListParticipant) {
            let numberofcohost = participants.filter((p) => p.userId === localListParticipant.userId);
            if (numberofcohost.length > 1) {
                return false;
            } else {
                if (numberofcohost.length === 1) {
                    return true;
                }
            }
        } else {
            return false;
        }
    }

    getParticipantsWithActiveSpeakerValue(list) {
        for (let index = 0; index < list.length; index++) {
            if (this.activeSpeakerList.findIndex((a) => a.uid === list[index].participantUri) > -1) {
                list[index]['isActiveSpeaker'] = true;
            } else {
                list[index]['isActiveSpeaker'] = false;
            }
        }
        return list;
    }

    getLocalParticipantFromParticipants(list) {
        return list.find((l) => l.participantUri === this.localParticipant.userId);
    }

    toFindDuplicates(arry) {
        const lookup = arry.reduce((a, e) => {
            a[e.userId] = ++a[e.userId] || 0;
            return a;
        }, {});
        return arry.filter((e) => lookup[e.userId]);
    }

    checkPostion(participants, isHardMute) {
        if (this.breakoutRoomInfo?.breakoutRoomId) {
            return;
        }
        const numberOfSpeaker = participants?.filter((p) => p.participantType === 'speaker').length;
        this.showLimitReached(participants, numberOfSpeaker);
        if (this.isPostionChecked === false) {
            this.externalInterfaceService.sendHardMuteAudiostatus(isHardMute);
        }
        if (!this.localParticipant.isHost) {
            if (this.isPostionChecked === false && !this.externalInterfaceService.isEmbibe) {
                if (numberOfSpeaker >= this.SPEAKER_LIMIT && this.isLocalParticipantCoHost(participants)) {
                    const index = participants.findIndex((p) => p.participantUri === this.localParticipant.userId);
                    if (participants[index]?.participantType === 'audience') {
                        this.sendMakeSpeaker();
                    }
                }
                if (this.localParticipant.participantType === 'audience') {
                    this.meeting$.next({ type: 'SHOW_DOWNGRADE_AUDIENCE_INFO', data: {} });
                }
                this.isPostionChecked = true;
                return;
            }

            if (this.localParticipant.participantType === 'speaker') {
                let speakerList = participants.filter((p) => p.participantType === 'speaker');
                speakerList = this.getParticipantsWithActiveSpeakerValue(speakerList);
                const speakerListLength = speakerList.length;
                if (speakerListLength > this.SPEAKER_LIMIT) {
                    const localPfromL = this.getLocalParticipantFromParticipants(speakerList);
                    if (!localPfromL.isHost) {
                        if (!localPfromL.isSharingScreen) {
                            //  if(!this.checkIfLocalParticipantActive(localPfromL)){
                            if (!localPfromL.isActiveSpeaker) {
                                if (!localPfromL.isCoHost) {
                                    const speakerList1 = speakerList.filter(
                                        (s) => !s.isHost && !s.isSharingScreen && !s.isActiveSpeaker && !s.isCoHost
                                    );

                                    let multidevicelist = speakerList1.filter((s) => s.userId === localPfromL.userId);
                                    if (multidevicelist.length > 1) {
                                        this.sendMakeAudience(multidevicelist);
                                    } else {
                                        const speakerlist2 = speakerList.filter(
                                            (s) => !s.isHost && !s.isSharingScreen && !s.isActiveSpeaker && s.isCoHost
                                        );
                                        if (this.toFindDuplicates(speakerlist2)?.length === 0) {
                                            this.sendMakeAudience(speakerList1);
                                        }
                                    }
                                } //else if is cohost
                                else {
                                    let cohostmultidevicelist = speakerList.filter(
                                        (s) => s.userId === localPfromL.userId
                                    );

                                    if (cohostmultidevicelist.length > 1) {
                                        this.sendMakeAudience(cohostmultidevicelist);
                                    } else {
                                        if (cohostmultidevicelist.length === 1) {
                                            let cohostlist = speakerList.filter(
                                                (s) =>
                                                    !s.isHost && !s.isSharingScreen && !s.isActiveSpeaker && !s.isCoHost
                                            );
                                            if (cohostlist.length === 0) {
                                                let cohostlist = speakerList.filter(
                                                    (s) =>
                                                        !s.isHost &&
                                                        !s.isSharingScreen &&
                                                        !s.isActiveSpeaker &&
                                                        s.isCoHost
                                                );
                                                this.sendMakeAudience(cohostlist);
                                            }
                                        }
                                    }
                                }
                            } //else if all activ
                        }
                    }
                }
            }
        }
    }

    sortFunction(a, b) {
        var dateA = new Date(a.participantJoinTime).getTime();
        var dateB = new Date(b.participantJoinTime).getTime();
        return dateA > dateB ? 1 : -1;
    }
    sendMakeSpeaker() {
        const chatObj = {
            agoraShareUid: 'abbx',
            type: 'PublicChat',
            message: VIDYO_EVENTS.HOST_UPGRADE_AUDIENCE,
            targetParticipantId: '',
            targetParticipantName: ''
        };
        this.sendChatMessage(chatObj);
    }

    sendMakeAudience(speakerList) {
        speakerList = speakerList.sort(this.sortFunction);
        const participantToDemote = speakerList[speakerList.length - 1];
        if (participantToDemote.participantUri === this.localParticipant.userId) {
            if (participantToDemote.isCoHost) {
                this.meeting$.next({
                    type: 'DOWNGRADE_CO_HOST',
                    data: { userId: participantToDemote.userId }
                });
            }
            const chatObj = {
                agoraShareUid: 'abbx',
                type: 'PublicChat',
                message: VIDYO_EVENTS.HOST_DOWNGRADE_AUDIENCE,
                targetParticipantId: '',
                targetParticipantName: ''
            };
            this.sendChatMessage(chatObj);
        }
    }

    addRecordingStopElement() {
        this.isStopRecordingElementAdded = true;
    }

    removeStopRecordingElement() {
        this.isStopRecordingElementAdded = false;
    }

    addEventListener() {
        EventManager.on(EVENT.USER_PUBLISHED, async (user, mediaType) => {
            if (mediaType === ICustomMediaType.SCREEN_VIDEO) {
                if (this.screenSharing) {
                    await this.toggleWindowShare(false);
                }
                setTimeout(() => {
                    this.remoteScreenSharingStream = user;
                    this.isScreenShareAvaiable = true;
                    this.participantStatusUpdated.next({ type: 'screenSharingStarted', data: user });
                }, 600);
            } else if (mediaType === ICustomMediaType.CAMERA_VIDEO) {
                this.participantStatusUpdated.next({ type: 'userStreamPublished', data: user });
            } else {
                await this.jmMediaClient.subscribe(user, mediaType).catch((err) => {});
                const audioTrack =
                    mediaType === ICustomMediaType.MICROPHONE_AUDIO ? user.audioTrack : user.screenShareAudioTrack;

                if (!audioTrack) return;

                audioTrack.play();
                await audioTrack?.setPlaybackDevice(String(this.selectedLocalSpeaker)).catch((e) => {
                    this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                        message: e?.message,
                        code: e?.code,
                        area: DEVICE_ERROR_CONSTANTS.audio_stream_playback
                    });
                });
            }
        });
        EventManager.on(EVENT.USER_JOINED, (user) => {
            if (user.role === 'audience') {
                return;
            }
            if (this.appService.getAutoStopRecordingInfo().enabled && this.isStopRecordingElementAdded) {
                this.removeStopRecordingElement();
            }
            this.participantAdded.next(user);
            this.updateRemoteParticipants(user, true);

            if (this.rtcService.isChimeAudioEnabledForHost()) {
                this.sendControlStatus({ participantJoined: true });
            }
        });
        EventManager.on(EVENT.USER_LEFT, (user) => {
            if (this.appService.getAutoStopRecordingInfo().enabled && this.jmMediaClient?.peers.length <= 1) {
                this.addRecordingStopElement();
            }
            this.participantRemoved.next(user);
            if (this.remoteScreenSharingStream && this.remoteScreenSharingStream.uid === user.uid) {
                this.meeting$.next({ type: 'CLEAR_SCREENSHARE_INFO', data: {} });
                this.isScreenShareStreamAvaiable();
                this.participantStatusUpdated.next({ type: 'screenSharingStopped', data: {} });
            }
            this.updateRemoteParticipants(user, false);
            if (this.rtcService.isChimeAudioEnabledForHost()) {
                this.sendControlStatus({ participantLeft: true });
            }
        });
        EventManager.on(EVENT.USER_UNPUBLISHED, (user, mediaType) => {
            if (mediaType === ICustomMediaType.SCREEN_VIDEO) {
                this.meeting$.next({ type: 'CLEAR_SCREENSHARE_INFO', data: {} });
                this.isScreenShareStreamAvaiable();
                this.participantStatusUpdated.next({ type: 'screenSharingStopped', data: user?.uid });
            } else if (mediaType === ICustomMediaType.CAMERA_VIDEO) {
                this.participantStatusUpdated.next({ type: 'userStreamUnpublished', data: user });
            }
        });
        EventManager.on(EVENT.CLOSE_TRACK_REQUEST, (mediaType) => {
            if (mediaType === ICustomMediaType.MICROPHONE_AUDIO) {
                this.participantStatusUpdated.next({ type: 'JM_MEDIA_MUTE_MIC', data: {} });
            }
            // if (mediaType === ICustomMediaType.SCREEN_VIDEO) {
            //     console.log('close-track-request');
            //     this.screenSharing = false;
            //     this.participantStatusUpdated.next({ type: 'screenSharingStopped', data: {} });
            //     this.sendControlStatus();
            //     this.externalInterfaceService.sendScreenShareStatus(false);
            // }
        });
        EventManager.on(EVENT.BROAD_CAST_MESSAGE, (msgData) => {
            this.currentSenderId = msgData.sender.participantId;
            this.chatMessages$.next(msgData);
        });
        EventManager.on(EVENT.BROAD_CAST_MESSAGE_TO_USER, (msgData) => {
            this.currentSenderId = msgData.sender.participantId;
            this.chatMessages$.next(msgData);
        });
        EventManager.on(EVENT.TOP_SPEAKER, (topSpeaker: Array<{ peerId: string; volume: number }>) => {
            this.showActiveSpeaker = false;
            if (topSpeaker[0].peerId != this.localParticipant?.id) {
                this.dominantSpeaker = topSpeaker[0].peerId;
                this.setDominantSpeaker(topSpeaker[0].peerId);
            }
            topSpeaker.forEach((user) => {
                if (user.peerId != this.localParticipant?.id) {
                    this.showActiveSpeaker = true;
                    this.setAudioLevelToRemoteParticipant(user.peerId, user.volume);
                }
            });
        });
        // EventManager.on(EVENT.DOMINANT_SPEAKER, (peerId) => {
        //     if (peerId != this.localParticipant?.id) {
        //         this.dominantSpeaker = peerId;
        //         this.setDominantSpeaker(peerId);
        //     }
        // });
        EventManager.on(
            EVENT.CONNECTION_STATE_CHANGE,
            (newConnectionState: IConnectionState, oldConnectionState: IConnectionState) => {
                this.appLoggerService.info('newConnectionState value from JM Service: ', newConnectionState);
                if (newConnectionState === 'RECONNECTING') {
                    this.reconnecting = true;
                    this.meeting$.next({
                        type: 'JM_RECONNECTING',
                        data: null
                    });
                }
                if (newConnectionState === 'CONNECTED' && this.reconnecting) {
                    this.reconnecting = false;
                    this.meeting$.next({
                        type: 'JM_RECONNECTED',
                        data: null
                    });
                }
                if (newConnectionState === 'DISCONNECTED') {
                    this.jmCallDisconnected = true;
                    this.reconnecting = true;
                    this.meeting$.next({
                        type: 'JM_RETRY_POPUP',
                        data: null
                    });
                }
            }
        );

        EventManager.on(EVENT.NETWORK_QUALITY, (networkQuality: any) => {
            console.log('networkQuality 0', networkQuality);
            this.listenNetworkQuality(networkQuality);
        });
        EventManager.on(EVENT.USER_ROLE_UPDATED, (user, oldRole, newRole) => {
            if (this.jmMediaClient.localUser === user) {
                if (newRole === 'audience') {
                    this.localParticipant.participantType = 'audience';
                    this.localParticipant.microphoneMute = true;
                    this.localParticipant.cameraMute = true;
                    this.isVideoOn.next(false);
                } else if (newRole === 'host') {
                    this.localParticipant.participantType = 'speaker';
                }
                this.eventEmitterService.emit({
                    type: APP_EVENTS.JM_USER_ROLE_UPDATED,
                    data: {
                        role: newRole
                    }
                });
                this.meeting$.next({ type: 'SEND_CONNECTION_STATUS', data: {} });
            } else {
                if (newRole === 'audience') {
                    this.participantRemoved.next(user);
                    this.updateRemoteParticipants(user as RemoteUser, false);
                } else if (newRole === 'host') {
                    this.participantAdded.next(user);
                    this.updateRemoteParticipants(user as RemoteUser, true);
                }
            }
        });
    }

    isScreenShareStreamAvaiable() {
        if (this.jmMediaClient.remoteUsers?.size === 0) {
            // if there is no remote participant then the below forEach will not iterate
            // and this.isScreenShareAvaiable won't reset to false
            // this condition used to reset it to false
            this.isScreenShareAvaiable = false;
            return;
        }
        if (this.jmMediaClient.publishedScreenShareUsers.length !== 0) {
            this.isScreenShareAvaiable = true;
        } else {
            this.isScreenShareAvaiable = false;
        }
    }

    setAudioLevelToRemoteParticipant(uid, audiolevel) {
        this.participantStatusUpdated.next({
            type: 'isVolumeChanged',
            data: {
                id: uid,
                volume: audiolevel
            }
        });
    }

    setDominantSpeaker(uid) {
        this.participantStatusUpdated.next({
            type: 'isDominantSpeakerChange',
            data: {
                id: uid
            }
        });
    }

    setJoinData({
        host,
        displayName,
        roomKey,
        roomPin,
        micPrivacy,
        cameraPrivacy,
        isInitiater = false,
        viewId = 'localVideo',
        isEmbedInMobile = false,
        unlockAndJoin = false,
        meetingObj = null,
        userId = ''
    }) {
        this.joinData = {
            host,
            displayName,
            roomKey,
            roomPin,
            micPrivacy: this.rtcService.isGuestOfJMLargeMeeting ? false : micPrivacy,
            cameraPrivacy: this.rtcService.isGuestOfJMLargeMeeting ? false : cameraPrivacy,
            isInitiater,
            viewId,
            isEmbedInMobile,
            unlockAndJoin,
            meetingObj,
            userId
        };
    }
    toggleRecordingStatus(enable) {
        this.recording = enable;
        this.sendControlStatus();
    }

    async toggleMicPrivacy(enable = false) {
        if (enable !== this.localHasAudio) {
            throw new Error('state mismatch');
        }
        if (this.processingToggleMic) {
            return;
        }
        this.processingToggleMic = true;
        try {
            if (this.localHasAudio) {
                if (this.localMicrophoneTrack) {
                    await this.jmMediaClient.pauseTrack(this.localMicrophoneTrack);
                } else {
                    this.processingToggleMic = false;
                    return;
                }
            } else {
                if (this.localMicrophoneTrack) {
                    await this.jmMediaClient.resumeTrack(this.localMicrophoneTrack);
                } else if (this.unPublishMicrophoneTrack) {
                    await this.jmMediaClient.publish(this.unPublishMicrophoneTrack);
                    this.unPublishMicrophoneTrack = null;
                } else {
                    await this.jmMediaClient.setLocalAudioEnabled(true);
                }
                this.eventEmitterService.emit({
                    type: APP_EVENTS.JM_SPEAKING_ON_MUTE,
                    data: {
                        showIndicator: false
                    }
                });
            }

            if (this.localParticipant) {
                this.localParticipant.microphoneMute = !this.jmMediaClient.localUser.hasAudio;
                this.externalInterfaceService.sendMicStatus(this.jmMediaClient.localUser.hasAudio);
            }

            this.sendControlStatus();
        } catch (error) {
            if (this.localParticipant) {
                this.localParticipant.microphoneMute = !this.jmMediaClient.localUser.hasAudio;
            }

            this.processingToggleCamera = false;
            throw error;
        } finally {
            this.processingToggleMic = false;
        }
    }

    async toggleCameraPrivacy(enable = false) {
        if (enable !== this.localHasVideo) {
            throw new Error('state mismatch');
            return;
        }
        if (this.processingToggleCamera) {
            return;
        }
        this.processingToggleCamera = true;
        try {
            if (!this.localHasVideo) {
                await this.jmMediaClient.setLocalVideoEnabled(true);
                this.localParticipant.cameraMute = false;
                this.externalInterfaceService.sendCameraStatus(true);
                this.isVideoOn.next(true);
            } else {
                await this.jmMediaClient.setLocalVideoEnabled(false);
                this.localParticipant.cameraMute = true;
                this.externalInterfaceService.sendCameraStatus(false);
                this.isVideoOn.next(false);
            }
            this.sendControlStatus();
        } catch (error) {
            this.processingToggleCamera = false;
            if (this.noCameraPermission || error?.message === 'Not allowed to publish video') {
                this.errors.next({ type: APP_EVENTS.ERRORS.NO_CAMERA_PERMISSION });
                this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: true });
                return;
            }
            throw error;
        } finally {
            this.processingToggleCamera = false;
        }
    }

    async leaveRoom() {
        try {
            this.isVideoOn.next(false);
            this.isBreakoutRoom = false;
            this.disconneting = true;
            this.joinedInRoom = false;
            this.isPostionChecked = false;
            this.remoteScreenSharingStream = null;
            this.isScreenShareAvaiable = false;
            this.hideNonVideoSelected = false;
            this.callInProgress = false;
            await this.jmMediaClient.leave();
            this.jmMediaClient.removeAttachListeners();
        } catch (exception) {
            this.externalInterfaceService.sendErrorOccuredOnJoined('exception', exception?.message);
            console.log(exception);
        }
    }

    async rejoinRoom() {
        try {
            this.isVideoOn.next(false);
            this.isBreakoutRoom = false;
            this.disconneting = true;
            this.joinedInRoom = false;
            this.remoteScreenSharingStream = null;
            this.isScreenShareAvaiable = false;
            this.hideNonVideoSelected = false;
            this.oldPeerId = this.localPeerId;
            await this.jmMediaClient.leave();
            this.jmMediaClient.removeAttachListeners();
            this.callInProgress = false;
        } catch (exception) {
            this.externalInterfaceService.sendErrorOccuredOnJoined('exception', exception?.message);
            console.log(exception);
        }
    }
    private sendControlStatus(updates: any = {}) {
        this.participantsStatus$.next({
            users: this.users,
            microphoneMutedAll: this.microphoneMutedAll,
            roomLocked: this.roomLocked,
            recording: this.recording,
            screenSharing: this.screenSharing,
            waitingUsers: this.waitingUsers,
            alreadySharingScreen: this.alreadySharingScreen,
            vcs: this.vcs,
            participantJoined: updates.participantJoined,
            participantLeft: updates.participantLeft
        });
    }
    functionHideScreenShareNotification(state) {
        this.hideScreenShareNotification.next(state);
    }
    userTalkListener(callback): void {}
    async updateAllDevices(skipPermission = false) {
        // this.cameras = await this.jmMediaClient.getCameras(false);
        // this.microphones = await this.mapDeviceCollection(this.jmMediaClient.getMicrophones(false));
        // this.speakers = await this.jmMediaClient.getPlaybackDevices(false);
        // this.noMediaPermission = false;
        // this.selectDevices();

        return new Promise<void>((resolve, reject) => {
            Promise.all([
                DeviceManager.getMediaPermissions(false, true).catch((errorInfo) => {
                    return {
                        hasError: true,
                        error: errorInfo
                    };
                }),
                DeviceManager.getMediaPermissions(true, false).catch((errorInfo) => {
                    return {
                        hasError: true,
                        error: errorInfo
                    };
                })
            ])
                .then((data) => {
                    if (data[0] && data[0]?.hasError) {
                        console.log('Unable to load Camera', data[0]?.error);
                        if (data[0]?.error?.code === 'PERMISSION_DENIED') {
                            this.noCameraPermission = true;
                        }
                    } else {
                        this.cameras = DeviceManager.getCameras();
                    }
                    this.videoDevicesUpdated.next(true);
                    if (data[1] && data[1]?.hasError) {
                        console.log('Unable to load Michrophone', data[1]?.error);
                        if (data[1]?.error?.code === 'PERMISSION_DENIED') {
                            this.noAudioPermission = true;
                        }
                    } else {
                        this.microphonesData = DeviceManager.getMicrophones();
                        this.microphones = [];
                        this.microphonesData.forEach((microphone) => {
                            if (this.isValidMediaDevice(microphone)) {
                                this.microphones.push(microphone);
                            }
                        });
                    }
                    this.speakersData = DeviceManager.getPlaybackDevices();
                    this.speakers = [];
                    this.speakersData.forEach((speaker) => {
                        if (this.isValidMediaDevice(speaker)) {
                            this.speakers.push(speaker);
                        }
                    });
                    this.audioDevicesUpdated.next(true);
                    this.cameras = this.mapDeviceCollection(this.cameras);
                    this.microphones = this.mapDeviceCollection(this.microphones);
                    this.speakers = this.mapDeviceCollection(this.speakers);
                    this.noMediaPermission = false;
                    this.sendControlStatus();
                    this.selectDevices();
                })
                .catch((e) => {
                    this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                        message: e?.message,
                        code: e?.code,
                        area: DEVICE_ERROR_CONSTANTS.asking_device_permission
                    });
                    this.noMediaPermission = true;
                    resolve();
                })

                .finally(() => {
                    resolve();
                });
        });
    }

    async createScreenShareTrack(mediaStream?: MediaStream) {
        let tracks = [];
        if (mediaStream) {
            tracks = await this.jmMediaClient.createCustomScreenTrack(mediaStream);
        }
        return tracks;
    }

    async toggleWindowShareCustom(tracks) {
        try {
            await this.jmMediaClient.setCustomScreenShareEnabled(tracks);
            this.jmMediaClient.localUser.screenShareVideoTrack?.on('track-ended', async () => {
                this.meeting$.next({ type: 'SHARE_STOP', data: { stopShare: true } });
            });
            this.screenSharing = true;
            this.sendControlStatus();
            const chatObj = {
                agoraShareUid: 'abbx',
                type: 'PublicChat',
                message: VIDYO_EVENTS.PARTRICIPANT_START_SHARE,
                targetParticipantId: '',
                targetParticipantName: ''
            };
            this.sendChatMessage(chatObj);
            this.externalInterfaceService.sendScreenShareStatus(true);
            return true;
        } catch (e) {
            throw e;
        }
    }

    async muteWindowShareLocal() {
        try {
            this.screenSharing = false;
            this.sendControlStatus();
            this.externalInterfaceService.sendScreenShareStatus(false);
        } catch (err) {
            throw err;
        }
    }
    async toggleWindowShare(enable: boolean) {
        if (enable) {
            try {
                await this.jmMediaClient.setScreenShareEnabled(true, true);
                this.jmMediaClient.localUser.screenShareVideoTrack?.on('track-ended', async () => {
                    this.meeting$.next({ type: 'SHARE_STOP', data: { stopShare: true } });
                });
                this.screenSharing = true;
                this.sendControlStatus();
                const chatObj = {
                    agoraShareUid: 'abbx',
                    type: 'PublicChat',
                    message: VIDYO_EVENTS.PARTRICIPANT_START_SHARE,
                    targetParticipantId: '',
                    targetParticipantName: ''
                };
                this.sendChatMessage(chatObj);
                this.externalInterfaceService.sendScreenShareStatus(true);
                return true;
            } catch (e) {
                throw e;
            }
        } else {
            try {
                await this.jmMediaClient.setScreenShareEnabled(false);
                this.screenSharing = false;
                this.sendControlStatus();
                this.externalInterfaceService.sendScreenShareStatus(false);
            } catch (e) {
                throw e;
            }
        }
    }
    releaseDevices() {}
    async selectDevices() {
        let speakerId = this.utilService.getCookie('cacheSpeaker');
        let micId = this.utilService.getCookie('cacheMic');
        let cameraId = this.utilService.getCookie('cacheCamera');

        //setting device from url
        if (this.externalInterfaceService.cameraLabel) {
            const camera = this.cameras.find((x) => x.name === this.externalInterfaceService.cameraLabel);
            cameraId = camera.id;
        }
        if (this.externalInterfaceService.micLabel) {
            const mic = this.microphones.find((x) => x.name === this.externalInterfaceService.micLabel);
            micId = mic.id;
        }
        if (this.externalInterfaceService.speakerLabel) {
            const speaker = this.speakers.find((x) => x.name === this.externalInterfaceService.speakerLabel);
            speakerId = speaker.id;
        }

        //setting mic
        if (micId) {
            this.microphones.map(async (mic) => {
                if (mic.id == micId) {
                    this.selectedLocalMicrophone = mic.id;
                }
            });
            if (!this.selectedLocalMicrophone) {
                if (this.microphones.length > 0) this.selectedLocalMicrophone = this.microphones[0].id;
            }
        } else {
            if (this.microphones.length > 0) this.selectedLocalMicrophone = this.microphones[0].id;
        }

        //setting camera
        if (cameraId) {
            this.cameras.map(async (camera) => {
                if (camera.id == cameraId) {
                    this.selectedLocalCamera = camera.id;
                }
            });
            if (!this.selectedLocalMicrophone) {
                if (this.microphones.length > 0) this.selectedLocalMicrophone = this.microphones[0].id;
            }
        } else {
            if (this.cameras.length > 0) this.selectedLocalCamera = this.cameras[0].id;
        }

        //setting speaker
        if (speakerId) {
            this.speakers.forEach((speaker) => {
                if (speaker.id === speakerId) {
                    this.selectedLocalSpeaker = speaker.id;
                }
            });
            if (!this.selectedLocalSpeaker) {
                if (this.speakers.length > 0) this.selectedLocalSpeaker = this.speakers[0]?.id;
            }
        } else {
            this.selectedLocalSpeaker = this.speakers[0]?.id;
        }
    }

    async requestPermission() {
        if (this.noMediaPermission && !this.appService.getRecorderUser()) {
            await this.updateAllDevices();
            this.onDeviceChangeListener();
            this.publishTracks();
        }
    }

    async manuallyChangeSpeaker(localSpeaker) {
        document.cookie = `cacheSpeaker=${localSpeaker.id}`;
        this.selectedLocalSpeaker = localSpeaker.id;

        try {
            await this.jmMediaClient.setPlaybackDevice({ deviceId: String(this.selectedLocalSpeaker) });
        } catch (e) {
            this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                message: e?.message,
                code: e?.code,
                area: DEVICE_ERROR_CONSTANTS.changing_audio_output
            });
        }
    }
    async manuallyChangeCamera(localCamera) {
        document.cookie = `cacheCamera=${localCamera.id}`;
        this.selectedLocalCamera = localCamera.id;
        try {
            await this.jmMediaClient.setVideoSettings({ deviceId: String(this.selectedLocalCamera) });
        } catch (e) {
            this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                message: e?.message,
                code: e?.code,
                area: DEVICE_ERROR_CONSTANTS.changing_video_input
            });
        }
    }
    async manuallyChangeMicrophone(localMicrophone) {
        document.cookie = `cacheMic=${localMicrophone.id}`;
        this.selectedLocalMicrophone = localMicrophone.id;
        try {
            await this.jmMediaClient.setAudioSettings({ deviceId: String(this.selectedLocalMicrophone) });
        } catch (e) {
            this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                message: e?.message,
                code: e?.code,
                area: DEVICE_ERROR_CONSTANTS.changing_audio_input
            });
        }
    }
    // for playing locally on selected divs
    async toggleCameraForSetting(divId, status) {
        await this.previewInstance.createPreview({
            trackSettings: {
                ...this.jmMediaClient.localUserSettings.trackSettings,
                isVideoEnabled: true
            },
            virtualBackgroundSettings: {
                ...this.jmMediaClient.localUserSettings.virtualBackgroundSettings
            }
        });
        await this.previewInstance.localUser.videoTrack.play('video-preview');
    }
    async disableBg() {
        try {
            await this.previewInstance.removeVirtualBackground();
        } catch (error) {}
    }
    async setBackgroundBlurring() {
        try {
            await this.previewInstance.setVirtualBackground({
                sourceType: 'blur',
                sourceValue: '10'
            });
        } catch (e) {
            console.log(e);
        }
    }
    async setBackgroundImage(url) {
        try {
            await this.previewInstance.setVirtualBackground({
                sourceType: 'image',
                sourceValue: url,
                width: 1280,
                height: 720
            });
        } catch (error) {
            console.log('error', error);
        }
    }
    async stopPreviewVb() {
        if (this.previewInstance.localUser.videoTrack) {
            this.previewInstance.setVideoEnabled(false);
        }
        this.vbService.backgroundConfig.type =
            this.jmMediaClient.localUserSettings.virtualBackgroundSettings.sourceType;
        this.vbService.backgroundConfig.imageUrl =
            this.jmMediaClient.localUserSettings.virtualBackgroundSettings.sourceValue;
    }

    async setWhiteboardStatus(state) {
        this.whiteboardShared = state;
        let streamType = this.whiteboardShared ? 1 : 0;
        if (state) {
            this.participantStatusUpdated.next({ type: 'whiteboardSharingStarted', data: {} });
        } else {
            this.participantStatusUpdated.next({ type: 'whiteboardSharingStopped', data: {} });
        }
        // this.isOptimizeOnScreenSharingEnabled && (await this.updateVideoConfig(streamType));
    }
    async confirmVbSetting() {
        await this.jmMediaClient.updateLocalUserTrack({
            trackSettings: { ...this.jmMediaClient.localUserSettings.trackSettings },
            virtualBackgroundSettings: {
                ...this.previewInstance.localUserSettings.virtualBackgroundSettings
            }
        });
    }

    updateRemoteParticipants(user: RemoteUser, add: boolean) {
        if (add) {
            this.remoteParticipants.push(user);
        } else {
            this.remoteParticipants = this.remoteParticipants.filter((loopUser: RemoteUser) => {
                return loopUser.uid !== user.uid;
            });
        }
        this.eventEmitterService.emit({
            type: APP_EVENTS.UPDATE_REMOTE_PARTICIPANTS,
            data: {}
        });
    }

    getRemoteParticipantsLength() {
        return this.remoteParticipants?.length;
    }

    notifyMicrophoneDeviceUpdate() {
        if (this.microphones.length > 0) {
            const currDevice = this.microphones.filter(
                (microphone) => microphone.groupId === this.selectedLocalMicrophoneGroupId
            );
            if (currDevice.length > 0) {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.JM_MICROPHONE_DEVICE_CHANGES,
                    data: {
                        device: currDevice[0].label
                    }
                });
            } else {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.JM_MICROPHONE_DEVICE_CHANGES,
                    data: {
                        device: this.microphones[0]?.label
                    }
                });
            }
        }
    }

    notifySpeakerDeviceUpdate() {
        if (this.speakers.length > 0) {
            const currDevice = this.speakers.filter((speaker) => speaker.groupId === this.selectedLocalSpeakerGroupId);
            if (currDevice.length > 0) {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.JM_MICROPHONE_DEVICE_CHANGES,
                    data: {
                        device: currDevice[0].label
                    }
                });
            } else {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.JM_MICROPHONE_DEVICE_CHANGES,
                    data: {
                        device: this.speakers[0]?.label
                    }
                });
            }
        }
    }

    async handleDeviceChange() {
        const currDevies = DeviceManager.getMicrophones();
        if (this.microphones?.length > currDevies.length) {
            // device removed
            let removedDevice = this.microphones.filter(
                (microphone) => !currDevies.some((device) => device.groupId === microphone.groupId)
            );
            if (removedDevice.length > 0) {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.JM_MICROPHONE_DEVICE_REMOVED,
                    data: {
                        device: removedDevice[0]?.label
                    }
                });
            }
        } else if (this.microphones?.length < currDevies.length) {
            //device added
            let newDevice = currDevies.filter(
                (microphone) => !this.microphones.some((device) => device.groupId === microphone.groupId)
            );
            if (newDevice.length > 0) {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.JM_MICROPHONE_DEVICE_ADDED,
                    data: {
                        device: newDevice[0]?.label
                    }
                });
            }
        }
        this.microphones = DeviceManager.getMicrophones();
    }

    checkUpdatedMediaDevice() {
        setInterval(async () => {
            const currDevies = DeviceManager.getMicrophones();
            if (currDevies.length !== this.microphones?.length) {
                this.handleDeviceChange();
            }
        }, 3000);
    }

    onDeviceChangeListener() {
        EventManager.on(EVENT.MICROPHONE_DEVICE_CHANGED, async (info) => {
            try {
                this.microphones = [];
                this.microphonesData = this.mapDeviceCollection(DeviceManager.getMicrophones());
                this.microphonesData.forEach((microhpone) => {
                    if (this.isValidMediaDevice(microhpone)) {
                        this.microphones.push(microhpone);
                    }
                });
                if (info.state === 'INACTIVE') {
                    if (this.microphones.length > 0) {
                        if (String(this.selectedLocalMicrophone) === info.device.deviceId) {
                            this.oldSelectedLocalMicrophone = this.selectedLocalMicrophone;
                            let selectedLocalMicrophoneObject = this.microphones.find(
                                (microphone) => microphone.groupId === this.selectedLocalSpeakerGroupId
                            );
                            if (selectedLocalMicrophoneObject) {
                                this.selectedLocalMicrophoneGroupId = selectedLocalMicrophoneObject?.groupId;
                                this.selectedLocalMicrophone = selectedLocalMicrophoneObject?.id;
                            } else {
                                this.selectedLocalMicrophone = this.microphones[0]?.id;
                                this.selectedLocalMicrophoneGroupId = this.microphones[0]?.groupId;
                            }
                        }
                        if (this.unPublishMicrophoneTrack) {
                            await this.unPublishMicrophoneTrack.setDevice(String(this.selectedLocalMicrophone));
                            await this.jmMediaClient
                                .setAudioSettings({ deviceId: String(this.selectedLocalMicrophone) })
                                .catch(() => {});
                        } else {
                            await this.jmMediaClient.setAudioSettings({
                                deviceId: String(this.selectedLocalMicrophone)
                            });
                        }
                    } else {
                        this.oldSelectedLocalMicrophone = this.selectedLocalMicrophone;
                        this.toggleMicPrivacy(true);
                    }
                }

                if (info.state === 'ACTIVE') {
                    if (!this.isVirtualDevice(info.device.label)) {
                        this.selectedLocalMicrophone = info.device.deviceId;
                        this.selectedLocalMicrophoneGroupId = info.device.groupId;
                        await this.jmMediaClient.setAudioSettings({ deviceId: String(this.selectedLocalMicrophone) });
                    }
                }
                this.sendControlStatus();
            } catch (err) {
                console.log('Microphone Device Changed Error', err);
            } finally {
                if (this.isMobileDevice) {
                    this.notifyMicrophoneDeviceUpdate();
                }
            }
        });

        EventManager.on(EVENT.PLAYBACK_DEVICE_CHANGED, async (info) => {
            try {
                this.speakers = [];
                this.speakersData = this.mapDeviceCollection(DeviceManager.getPlaybackDevices());
                this.speakersData.forEach((speaker) => {
                    if (this.isValidMediaDevice(speaker)) {
                        this.speakers.push(speaker);
                    }
                });

                if (info.state === 'INACTIVE') {
                    if (this.speakers.length > 0) {
                        if (String(this.selectedLocalSpeaker) === info.device.deviceId) {
                            this.oldSelectedLocalSpeaker = this.selectedLocalSpeaker;
                            let selectedLocalSpeakerObject = this.speakers.find(
                                (speaker) => speaker.groupId === this.selectedLocalMicrophoneGroupId
                            );
                            if (selectedLocalSpeakerObject) {
                                this.selectedLocalSpeakerGroupId = selectedLocalSpeakerObject?.groupId;
                                this.selectedLocalSpeaker = selectedLocalSpeakerObject?.id;
                            } else {
                                this.selectedLocalSpeaker = this.speakers[0]?.id;
                                this.selectedLocalSpeakerGroupId = this.speakers[0]?.groupId;
                            }
                        }
                        await this.updateJmClientSpeakerOut(String(this.selectedLocalSpeaker));
                    } else {
                        this.oldSelectedLocalSpeaker = this.selectedLocalSpeaker;
                    }
                }

                if (info.state === 'ACTIVE') {
                    if (!this.isVirtualDevice(info.device.label)) {
                        this.selectedLocalSpeaker = info.device.deviceId;
                        this.selectedLocalSpeakerGroupId = info.device.groupId;
                        await this.updateJmClientSpeakerOut(String(this.selectedLocalSpeaker));
                    }
                }
                this.sendControlStatus();
            } catch (err) {
                console.log('Speaker Device Changed Error', err);
            } finally {
                if (!this.isMobileDevice) {
                    this.notifySpeakerDeviceUpdate();
                }
            }
        });
        EventManager.on(EVENT.CAMERA_DEVICE_CHANGED, async (info) => {
            this.cameras = this.mapDeviceCollection(DeviceManager.getCameras());
            if (info.state === 'INACTIVE') {
                if (this.cameras.length > 0) {
                    if (String(this.selectedLocalCamera) === info.device.deviceId) {
                        this.oldSelectedLocalCamera = this.selectedLocalCamera;
                        this.selectedLocalCamera = this.cameras[0].id;
                        await this.jmMediaClient.setVideoSettings({ deviceId: String(this.selectedLocalCamera) });
                    }
                } else {
                    this.oldSelectedLocalCamera = this.selectedLocalCamera;
                    this.toggleCameraPrivacy(true);
                }
            }

            if (info.state === 'ACTIVE') {
                this.selectedLocalCamera = info.device.deviceId;
                await this.jmMediaClient.setVideoSettings({ deviceId: String(this.selectedLocalCamera) });
            }
            this.sendControlStatus();
        });
    }

    onMuteSpeakingListener() {
        EventManager.on(EVENT.SPEAKING_ON_MUTE, () => {
            this.eventEmitterService.emit({
                type: APP_EVENTS.JM_SPEAKING_ON_MUTE,
                data: {
                    showIndicator: true
                }
            });
        });
    }
    isVirtualDevice(deviceName) {
        return deviceName?.toLowerCase().endsWith('(virtual)');
    }
    isValidMediaDevice(device) {
        //disabling default communication check to go back to previous state
        return true;

        return !(
            device?.deviceId === 'default' ||
            device?.deviceId === 'communications' ||
            device?.id === 'default' ||
            device?.id === 'communications'
        );
    }
    mapDeviceCollection(arr) {
        return arr
            .map((device) => {
                return { ...device, id: device.deviceId, name: device.label, groupId: device.groupId };
            })
            .sort((device1, device2) => {
                let isDevice1Virtual = device1?.name?.toLowerCase().endsWith('(virtual)');
                let isDevice2Virtual = device2?.name?.toLowerCase().endsWith('(virtual)');
                if (isDevice1Virtual && !isDevice2Virtual) {
                    return 1;
                } else if (!isDevice1Virtual && isDevice2Virtual) {
                    return -1;
                } else return 0;
            });
    }
    async sendChatMessage({
        agoraShareUid,
        type,
        message,
        targetParticipantId = '',
        targetParticipantName = '',
        isOverride = false,
        reactionsType = ''
    }) {
        let payload;
        if (type === 'PublicChat') {
            payload = {
                agoraShareUid,
                type,
                message,
                sender: {
                    participantId: this.roomData?.localParticipant.participantId,
                    name: this.roomData?.localParticipant.participantName,
                    userId: this.roomData?.localParticipant.userId
                },
                targetParticipantId: '',
                reactionsType: reactionsType
            };

            if (
                message === VIDYO_EVENTS.PARTICIPANT_COHOST_GRANT ||
                message === VIDYO_EVENTS.PARTICIPANT_COHOST_REVOKE ||
                message === VIDYO_EVENTS.HOST_LOWER_HAND
            ) {
                payload.targetParticipantId = targetParticipantId ? targetParticipantId : this.currentSenderId;
                // if (targetParticipantId) {
                //   payload = { ...payload, ...{ targetParticipantId } };
                // }
            }
        } else {
            payload = {
                type,
                message,
                targetParticipantName,
                targetParticipantId,
                targetHostUserId: this.roomData?.localParticipant.userId,
                isOverride,
                sender: {
                    participantId: this.roomData?.localParticipant.participantId,
                    name: this.roomData?.localParticipant.participantName,
                    userId: this.roomData?.localParticipant.userId
                }
            };
        }
        if (type === 'PublicChat') {
            return await this.jmMediaClient.broadCastMessage(payload, this.roomData?.localParticipant.participantId);
        } else {
            return await this.jmMediaClient.broadCastMessageToUser(
                payload,
                this.roomData?.localParticipant.participantId,
                payload.targetParticipantId
            );
        }
    }

    updateGalleryVideoTiles(numberOfTiles) {
        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
        });
        const isJMLargeMeeting = this.rtcService.isJMLargeMeeting;
        const MAX_VIDEO_TILES = isJMLargeMeeting
            ? this.utilService.isMobileBrowser()
                ? 6
                : 15
            : this.utilService.getMaxTiles(this.currentUser);
        this.appLoggerService.error('currentUser data', this.currentUser);
        this.appLoggerService.error('MAX_VIDEO_TILES', MAX_VIDEO_TILES);
        this.appLoggerService.error('numberOfTiles selected', numberOfTiles);

        if (numberOfTiles < 2) {
            this.appLoggerService.error('numberOfTiles < 2:', numberOfTiles < 2);
            return;
        }

        if (numberOfTiles > MAX_VIDEO_TILES) {
            this.appLoggerService.error('numberOfTiles > MAX_VIDEO_TILES:', numberOfTiles > MAX_VIDEO_TILES);
            return;
        }
        this.numberOfVideoTilesSelected = numberOfTiles;
        this.numberOfVideoTilesSelectedSubject.next(numberOfTiles);
    }

    setFitToScreen(value) {
        this.isFitToScreen = value;
        this.participantStatusUpdated.next({ type: 'fitToScreen', data: { value } });
    }

    updateSpeakerLayout(status) {
        this.isActiveSpeakerLayout = status;
        this.$speakerLayoutSelected.next(status);
        if (this.isActiveSpeakerLayout && this.dominantSpeaker) {
            this.setDominantSpeaker(this.dominantSpeaker);
        }
    }

    hideNonVideo(status) {
        this.hideNonVideoSelected = status;
        this.$hideNonVideoSelected.next(status);
    }

    async updateJmClientSpeakerOut(id: string) {
        try {
            await this.jmMediaClient.setPlaybackDevice({ deviceId: id });
        } catch (e) {
            this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                message: e?.message,
                code: e?.code,
                area: DEVICE_ERROR_CONSTANTS.changing_audio_output
            });
        }
    }
    getSignalQuality(stats) {
        return this.localParticipant?.microphoneMute && this.localParticipant?.cameraMute
            ? stats?.downlinkQuality
            : stats?.uplinkQuality;
    }

    convertSignalToQuality(signal): SignalStatus {
        let outPutSignal = SignalStatus.NONE;
        switch (signal) {
            case 0:
                outPutSignal = SignalStatus.NONE;
                break;
            case 1:
            case 2:
                outPutSignal = SignalStatus.GOOD;
                break;
            case 3:
            case 4:
                outPutSignal = SignalStatus.BAD;
                break;
            case 5:
                outPutSignal = SignalStatus.VERYBAD;
                break;
        }
        return outPutSignal;
    }

    updateCounterInfo(networkQuality) {
        if (networkQuality === SignalStatus.GOOD) {
            this.counterForGoodSignal++;
            this.counterForBadSignal = 0;
            this.counterForVeryBadSignal = 0;
        } else if (networkQuality === SignalStatus.BAD) {
            this.counterForBadSignal++;
            this.counterForGoodSignal = 0;
            this.counterForVeryBadSignal = 0;
        } else if (networkQuality === SignalStatus.VERYBAD) {
            this.counterForVeryBadSignal++;
            this.counterForGoodSignal = 0;
            this.counterForBadSignal = 0;
        }
    }

    checkUpgradeDowngrade(networkQuality) {
        let checkValue = 5;
        if (this.signalStrengthData.signal === SignalStatus.GOOD) {
            if (networkQuality === SignalStatus.BAD || networkQuality === SignalStatus.VERYBAD) {
                checkValue = 5;
            }
        }
        if (this.signalStrengthData.signal === SignalStatus.BAD) {
            if (networkQuality === SignalStatus.GOOD) {
                checkValue = 10;
            } else if (networkQuality === SignalStatus.VERYBAD) {
                checkValue = 5;
            }
        }
        if (this.signalStrengthData.signal === SignalStatus.VERYBAD) {
            if (networkQuality === SignalStatus.GOOD || networkQuality === SignalStatus.BAD) {
                checkValue = 10;
            }
        }
        return checkValue;
    }

    async listenNetworkQuality(networkData) {
        this.networkQuality = networkData;
        const networkQuality = this.convertSignalToQuality(networkData);
        console.log(
            'networkQuality 1',
            networkQuality,
            this.counterForGoodSignal,
            this.counterForBadSignal,
            this.counterForVeryBadSignal
        );

        if (this.counterForGoodSignal === 0 && this.counterForBadSignal === 0 && this.counterForVeryBadSignal === 0) {
            this.signalStrengthData.signal = SignalStatus.GOOD;
        }
        this.updateCounterInfo(networkQuality);
        let checkValue = this.checkUpgradeDowngrade(networkQuality);
        console.log(
            'networkQuality 2',
            checkValue,
            this.counterForGoodSignal,
            this.counterForBadSignal,
            this.counterForVeryBadSignal
        );
        if (this.counterForGoodSignal === checkValue) {
            if (this.appService.audioOnlyModeEnabledFromAlerts) {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.DISABLE_AUDIO_ONLY_MODE,
                    data: {}
                });
            }
            if (
                this.appService.getConfigVariable('isNetworkQualityInCallAlertsEnabled') &&
                (this.signalStrengthData.signal === SignalStatus.BAD ||
                    (this.signalStrengthData.signal === SignalStatus.VERYBAD &&
                        !this.appService.cameraOnBeforeAudioOnlyMode))
            ) {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.SHOW_GOOD_NETWORK_TOAST,
                    data: {}
                });
            }
            this.signalStrengthData.signal = SignalStatus.GOOD;
            this.appService.inCallBannerType = SignalStatus.GOOD;
            if (this.appService.audioOnlyModeEnabledFromAlerts && this.appService.cameraOnBeforeAudioOnlyMode) {
                this.appService.showInCallBanner = true;
            } else {
                this.appService.showInCallBanner = false;
            }
            this.appLoggerService.info('jm-media network quality :', networkQuality);
            this.appLoggerService.info('is Audio-only mode :', this.appService.audioOnlyMode);
        } else if (this.counterForBadSignal === checkValue) {
            this.signalStrengthData.signal = SignalStatus.BAD;
            this.appService.inCallBannerType = SignalStatus.BAD;
            this.appService.showInCallBanner = true;
            this.appLoggerService.info('jm-media network quality :', networkQuality);
            this.appLoggerService.info('is Audio-only mode :', this.appService.audioOnlyMode);
        } else if (this.counterForVeryBadSignal === checkValue) {
            this.signalStrengthData.signal = SignalStatus.VERYBAD;
            if (!this.appService.audioOnlyMode) {
                this.appService.inCallBannerType = SignalStatus.VERYBAD;
                this.appService.showInCallBanner = true;
            }
            this.appLoggerService.info('jm-media network quality :', networkQuality);
            this.appLoggerService.info('is Audio-only mode :', this.appService.audioOnlyMode);
        }
    }

    getSignalStrength() {
        return this.signalStrengthData;
    }
    clear() {
        this.localParticipant = {};
        this.joinData = {};
        this.users = {};
        this.microphoneMutedAll = false;
        this.roomLocked = false;
        this.recording = false;
        this.screenSharing = false;
        this.reconnecting = false;
        this.whiteboardShared = false;
    }

    async changeFacingMode() {
        if (this.localCameraTrack) {
            const currentFacingMode = this.localCameraTrack.getSettings().facingMode;
            this.externalInterfaceService.sendSwitchCameraLog(`current facing mode is ${currentFacingMode}`);
            if (currentFacingMode == 'environment') {
                try {
                    await this.jmMediaClient.setVideoSettings({ facingMode: 'user' });
                    this.externalInterfaceService.sendSwitchCameraLog('FRONT_SWITCH_SUCCESS');
                } catch (e) {
                    this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                        message: e?.message,
                        code: e?.code,
                        area: DEVICE_ERROR_CONSTANTS.changing_video_input
                    });
                }
            } else {
                try {
                    await this.jmMediaClient.setVideoSettings({ facingMode: 'environment' });
                    this.externalInterfaceService.sendSwitchCameraLog('BACK_SWITCH_SUCCESS');
                } catch (e) {
                    this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                        message: e?.message,
                        code: e?.code,
                        area: DEVICE_ERROR_CONSTANTS.changing_video_input
                    });
                }
            }
        }
    }

    async flipCamera() {
        const selectedCamera = this.selectedLocalCamera;
        let newCameraId;

        if (this.cameras.length > 1) {
            const currentCameraIndex = this.cameras.findIndex((device) => device.deviceId === selectedCamera);

            if (currentCameraIndex === -1) {
                // Current camera ID not found in the list
                return null;
            }

            const frontCameras = this.cameras.filter((device) => this.isFrontCamera(device.name));
            const backCameras = this.cameras.filter((device) => this.isBackCamera(device.name));

            if (frontCameras.length > 0 && backCameras.length > 0) {
                // User has both front and back cameras
                if (currentCameraIndex < frontCameras.length) {
                    // Current camera is a front camera
                    const nextBackCameraIndex = currentCameraIndex % backCameras.length;
                    newCameraId = backCameras[nextBackCameraIndex];
                } else {
                    // Current camera is a back camera
                    const nextFrontCameraIndex = (currentCameraIndex - frontCameras.length + 1) % frontCameras.length;
                    newCameraId = frontCameras[nextFrontCameraIndex];
                }
            }
            if (newCameraId) {
                await this.manuallyChangeCamera(newCameraId);
            } else {
                await this.changeFacingMode();
            }
        }
    }

    checkMicrophoneForDigiGov() {
        if (!this.thirdPartyExternalIntegrationService.isDigiGovCustomer()) {
            return;
        }
        let selectedMicrophoneDetails = [];
        let restMicrophones = [];
        this.microphones.forEach((microphone) => {
            if (microphone.id === this.selectedLocalMicrophone) {
                selectedMicrophoneDetails.push(microphone);
            } else {
                restMicrophones.push(microphone);
            }
        });
        if (this.checkIfMicrophoneIsEarpiece(selectedMicrophoneDetails)) {
            let restUseableMicrophones = restMicrophones.filter((mic) => mic?.id !== 'default');
            this.manuallyChangeMicrophone(restUseableMicrophones[0]);
        }
    }

    checkIfMicrophoneIsEarpiece(selectedMicrophoneDetails) {
        return selectedMicrophoneDetails?.length > 0 && selectedMicrophoneDetails[0]?.name?.includes('earpiece');
    }

    async switchToCamera(newFacingMode: 'user' | 'environment', logSuccess, logFailed) {
        try {
            await this.jmMediaClient.setVideoSettings({ facingMode: newFacingMode });
            this.externalInterfaceService.sendSwitchCameraLog(logSuccess);
        } catch (e) {
            this.externalInterfaceService.sendSwitchCameraLog(logFailed);
            this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                message: e?.message,
                code: e?.code,
                area: DEVICE_ERROR_CONSTANTS.changing_video_input
            });
        }
    }

    async switchCamera() {
        if (!this.localCameraTrack) {
            this.externalInterfaceService.sendSwitchCameraLog(`no local video track`);
            return;
        }
        const currentFacingMode = this.localCameraTrack.getMediaStreamTrack().getConstraints().facingMode;
        this.externalInterfaceService.sendSwitchCameraLog(`Current facing mode is ${currentFacingMode}`);
        if (currentFacingMode == 'environment') {
            this.externalInterfaceService.sendSwitchCameraLog('No front camera by label');
            await this.switchToCamera('user', 'FRONT_SWITCH_SUCCESS', 'FRONT_SWITCH_FAILED');
        } else {
            this.externalInterfaceService.sendSwitchCameraLog('No back camera by label');
            await this.switchToCamera('environment', 'BACK_SWITCH_SUCCESS', 'BACK_SWITCH_FAILED');
        }
    }

    isFrontCamera(name) {
        const frontCameraPatterns = ['front', 'selfie', 'user']; // Add more patterns if necessary
        const lowercaseLabel = name.toLowerCase();
        return frontCameraPatterns.some((pattern) => lowercaseLabel.includes(pattern));
    }

    isBackCamera(name) {
        const backCameraPatterns = ['back', 'rear', 'environment']; // Add more patterns if necessary
        const lowercaseLabel = name.toLowerCase();
        return backCameraPatterns.some((pattern) => lowercaseLabel.includes(pattern));
    }

    stopPreviewTrack() {}

    async toggleNoiseSuppression() {
        const enable = this.appService.getIsNoiseSuppression;
        try {
            if (enable) {
                await this.jmMediaClient.stopNoiseSuppression();
            } else {
                await this.jmMediaClient.startNoiseSuppression();
            }
        } catch (error) {
            throw error;
        }
    }

    async enableNoiseSuppression() {
        try {
            await this.jmMediaClient.startNoiseSuppression();
            this.appService.setIsNoiseSuppression = true;
        } catch (error) {
            this.appService.setIsNoiseSuppression = false;
        }
    }
}
