import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { UserService } from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-search-dropdown',
    templateUrl: './search-dropdown.component.html',
    styleUrls: ['./search-dropdown.component.scss']
})
export class SearchDropdownComponent implements ControlValueAccessor {
    list = [];
    temp_list = [];
    keyword = '';
    _img: any;
    _label: any;
    _email: any;
    _uid: any;
    @Output() afterChange = new EventEmitter();
    @ViewChild('input', { static: false }) input: ElementRef;
    @Input('isOnBehalfEdit') isOnBehalfEdit;
    @Input('size') size;
    @Input('items') set items(value) {
        this.list = value;
        this.temp_list = value;
    }
    @Input('img') img;
    @Input('label') label;
    @Input('uid') _id;
    @Input('email') email;
    onChange: any = () => {};
    onTouch: any = () => {};
    value: any = 'Search host by name, email id';
    shown = false;
    constructor(
        private ele: ElementRef,
        private userService: UserService,
        private translateService: TranslateService
    ) {}
    ngOnInit() {
        this.getOnBehalfParticipants();
        if (this.list.length > 0) this.select(this.list?.[0]);
    }
    getOnBehalfParticipants() {
        this.userService.getScheduleForUsersList().subscribe(
            (onBehalfUsersList) => {
                this.list = onBehalfUsersList.scheduleOnBehalf;
                this.temp_list = onBehalfUsersList.scheduleOnBehalf;
            },
            (err) => {
                this.list = [];
                this.temp_list = [];
            }
        );
    }
    removeParticipant(selectedUser) {
        this.value = this.translateService.instant('planmeeting_popup.add_host_placeholder');
    }
    ngOnChanges() {
        this._label = typeof this.label !== 'undefined' && this.label !== '' ? this.label : 'name';
        this._img = typeof this.img !== 'undefined' && this.img !== '' ? this.img : 'img';
        this._id = typeof this._id !== 'undefined' && this._id !== '' ? this._id : 'id';
        this._email = typeof this.email !== 'undefined' && this.email !== '' ? this.email : 'id';
        this.value = this.translateService.instant('planmeeting_popup.add_host_placeholder');
    }
    writeValue(value) {
        if (value) {
            this.temp_list.map((user) => {
                if (user[this._id] == value) {
                    this.value = user[this._label];
                }
            });
        }
    }
    registerOnChange(fn: any) {
        this.onChange = fn;
    }
    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }
    search(e) {
        const val = e.toLowerCase();
        const temp = this.temp_list.filter((user) => {
            if (
                user?.name?.toLowerCase().indexOf(val) !== -1 ||
                !val ||
                user?.email?.toLowerCase().indexOf(val) !== -1
            ) {
                return user;
            }
        });
        this.list = temp;
    }
    select(item) {
        this.onChange(item[this._label] + ' ' + item.lname);
        this.value = item[this._label] + ' ' + item.lname;
        this.shown = false;
        this.afterChange.emit(item);
    }
    show() {
        if (this.isOnBehalfEdit) return;
        this.shown = this.shown ? false : true;
        setTimeout(() => {
            this.input.nativeElement.focus();
        }, 200);
    }
    @HostListener('document:click', ['$event']) onClick(e) {
        if (!this.ele.nativeElement.contains(e.target)) {
            this.shown = false;
        }
    }
}
