<div class="page container-fluid" tabindex="0" aria-label="Start meeting page">
    <div class="compatibility-info" *ngIf="showCompatibilityMessage && !isThirdPartyExternalIntegration">
        <div class="img-wrap mr-3">
            <img alt="" src="assets/img/warning_icn.svg" alt="warning" />
        </div>
        <div>{{ 'downloadspage.compatibility_info' | translate }}</div>
    </div>
    <div class="mobile-back-btn" (click)="navigateToDashboard()" *ngIf="!checkMultiDeviceLogin">
        <img alt="" src="assets/images/mobile_back_btn.svg" /> {{ 'host_meeting.back' | translate }}
    </div>
    <div class="back pointer pt-3 px-3" (click)="clickAppBackButton()" *ngIf="!checkMultiDeviceLogin">
        <div
            class="img-wrap mr-2"
            tabindex="0"
            aria-label="Go back to home page"
            title="{{ 'host_meeting.back' | translate }}"
            (keyup.enter)="clickAppBackButton()"
        >
            <div class="img"></div>
        </div>
        {{ 'host_meeting.back' | translate }}
    </div>
    <div class="row h-100" *ngIf="!checkMultiDeviceLogin">
        <div class="left-content col-xl-5 col-lg-6 col-md-12 col-sm-12 py-5">
            <div class="call-preview">
                <app-call-preview
                    (showsettings)="openSettingsPopup($event)"
                    [hostMeeting]="true"
                    [joinWithoutVideo]="joinWithoutVideo"
                    [joinWithoutAudio]="joinWithoutAudio"
                ></app-call-preview>
            </div>
        </div>
        <div class="right-content col-xl-7 col-lg-6 col-md-12 col-sm-12 py-5">
            <div class="component-wrap">
                <div class="form-wrap">
                    <h3 tabindex="0" class="headingfocus" aria-label="{{ 'host_meeting.youre_about' | translate }}">
                        {{ 'host_meeting.youre_about' | translate }}
                    </h3>
                    <div class="meeting-options">
                        <label
                            for="dynamic"
                            role="radio"
                            tabindex="0"
                            aria-label="one time meeting"
                            [attr.aria-checked]="!personalMeeting"
                            (keyup.enter)="selectRadio('dynamic')"
                            [class.selected]="roomType === 'dynamic'"
                        >
                            <input
                                tabindex="-1"
                                aria-hidden="true"
                                type="radio"
                                class="mr-3"
                                name="option"
                                id="dynamic"
                                value="dynamic"
                                [(ngModel)]="roomType"
                            />
                            <span>{{ 'host_meeting.onetime' | translate }}</span>
                        </label>
                        <label
                            for="personal"
                            role="radio"
                            tabindex="0"
                            [attr.aria-checked]="personalMeeting"
                            aria-label="Personal meeting"
                            (keyup.enter)="selectRadio('personal')"
                            [class.selected]="roomType === 'personal'"
                        >
                            <input
                                tabindex="-1"
                                aria-hidden="true"
                                type="radio"
                                class="mr-3"
                                name="option"
                                id="personal"
                                value="personal"
                                [(ngModel)]="roomType"
                            />
                            <span
                                >{{ 'host_meeting.pmi' | translate }} {{ currentUser?.jiomeetId | meetingidFormat }}
                                <img
                                    alt=""
                                    src="assets/img/icon_info.svg"
                                    class="icon_info"
                                    pTooltip="{{ 'host_meeting.pmitooltip' | translate }}"
                                    tooltipPosition="right"
                                    tooltipStyleClass="white-bg max-tooltip-width"
                                    tabindex="0"
                                    role="tooltip"
                                    aria-label="Your Personal Meeting ID(PMI) is the ID to your personal meeting room. This meeting ID and password does not change with every meeting. You can change the PMI password through settings section."
                            /></span>
                            <label class="visually-hidden">
                                {{ 'host_meeting.pmitooltip' | translate }}
                            </label>
                            <span class="mobile-sublabel">{{
                                'user_dashboard_settings.pmi.your_personal_id_that_never_changes' | translate
                            }}</span>
                        </label>
                    </div>
                    <div class="action-btns">
                        <button
                            type="button"
                            class="custom-button bg-secondary"
                            (click)="shareInvite()"
                            *ngIf="roomType === 'personal'"
                            tabindex="0"
                            aria-label="{{ 'host_meeting.share' | translate }}"
                            (keydown.enter)="shareInviteonEnter($event)"
                            [style.width]="actionBtnWidth"
                        >
                            {{ 'host_meeting.share' | translate }}
                        </button>
                        <button
                            type="button"
                            class="custom-button bg-primary main-focus"
                            (click)="hostMeeting()"
                            [class.disabled]="isOffline || joiningInProgress"
                            tabindex="0"
                            aria-label="{{ getStartBtnTitle() | translate }}"
                            (keyup.enter)="hostMeeting()"
                            [style.width]="actionBtnWidth"
                        >
                            {{ getStartBtnTitle() | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="join-device-handler h-100" *ngIf="checkMultiDeviceLogin">
        <section class="join-device-action">
            <div class="back left-content" (click)="checkMultiDeviceLogin = false">
                <div class="img-wrap mr-2">
                    <div class="img"></div>
                </div>
            </div>
            <div class="right-content">
                <h3 class="mb-4">{{ 'joinmeeting_page_or_joincall.multi_device_join_title' | translate }}</h3>
                <section class="action-list">
                    <div class="device-action" (click)="handleMultiDeviceLogin('add')">
                        <div class="img add-img mb-2"></div>
                        <h5>
                            {{ 'joinmeeting_page_or_joincall.add_device' | translate }}
                        </h5>
                        <p>{{ 'joinmeeting_page_or_joincall.add_device_desc' | translate }}</p>
                    </div>
                    <div class="device-action" (click)="handleMultiDeviceLogin('remove')">
                        <div class="img transfer-img mb-2"></div>
                        <h5>
                            {{ 'joinmeeting_page_or_joincall.transfer_call' | translate }}
                        </h5>
                        <p>{{ 'joinmeeting_page_or_joincall.transfer_call_desc' | translate }}</p>
                    </div>
                </section>
            </div>
        </section>
        <!-- <app-pre-loader *ngIf="isJoining"></app-pre-loader> -->
    </div>
</div>
<ng-template viewHost></ng-template>

<div class="invitation-area" id="invite-email" *ngIf="shareInfo">
    <app-meeting-invitation [host]="currentUser" [meeting]="shareInfo" id="meeting-invitation"></app-meeting-invitation>
</div>

<div class="popup-component invitation-popup" *ngIf="showMeetingDetailsPopup">
    <div class="bg" (click)="toggleMeetingDetailsPopup()"></div>
    <div class="popup">
        <div class="popup-header">
            <h3>{{ 'host_meeting.share_meeting_details' | translate }}</h3>
            <button
                class="close simplemodal-close"
                aria-label="close"
                tabindex="0"
                (click)="toggleMeetingDetailsPopup()"
                (keyup.enter)="toggleMeetingDetailsPopup()"
            >
                ×
            </button>
        </div>
        <div class="popup-body">
            <div class="block">
                <label>{{ 'host_meeting.topic' | translate }}</label>
                <div class="value">{{ shareInfo.topic }}</div>
            </div>
            <div class="block d-flex">
                <div class="inline">
                    <label>{{ 'host_meeting.meetingid' | translate }}</label>
                    <div class="value">{{ shareInfo.jiomeetId | meetingidFormat }}</div>
                </div>
                <div>
                    <label>{{ 'host_meeting.password' | translate }}</label>
                    <div class="value">{{ shareInfo.pin }}</div>
                </div>
            </div>
            <div class="block">
                <label>{{ 'host_meeting.meetinglink' | translate }}</label>
                <div class="value">
                    <a
                        tabindex="0"
                        target="_blank"
                        [href]="shareInfo.url"
                        (keyup.enter)="handleEnterKey($event)"
                        aria-label="Meeting Link"
                    >
                        {{ shareInfo.url }}</a
                    >
                </div>
            </div>
            <div class="block">
                <label>{{ 'host_meeting.sharevia' | translate }}</label>
                <div class="value d-flex">
                    <button
                        class="custom-button bg-secondary copy-option"
                        tabindex="0"
                        aria-label="Share Meeting link via Outlook"
                        (click)="sendOutlookInvite()"
                        (keyup.enter)="sendOutlookInvite()"
                    >
                        <div class="img-wrap mr-2">
                            <img alt="" src="assets/img/outlook_icn_small.png" />
                        </div>
                        {{ 'host_meeting.outlook' | translate }}
                    </button>
                    <button
                        class="custom-button bg-secondary copy-option gmail"
                        tabindex="0"
                        aria-label="Share Meeting link via Gmail"
                        (click)="sendGmailInvite()"
                        (keyup.enter)="sendGmailInvite()"
                    >
                        <div class="img-wrap mr-2">
                            <img alt="" src="assets/img/gmail.svg" />
                        </div>
                        {{ 'host_meeting.gmail' | translate }}
                    </button>
                    <button
                        class="custom-button bg-secondary copy-option copy"
                        tabindex="0"
                        [attr.aria-label]="getCopyArialLabel()"
                        (keyup.enter)="copyInvitation()"
                        (click)="copyInvitation()"
                    >
                        <div class="img-wrap mr-2">
                            <!-- <img alt="" src="assets/img/revamp/copy-invitation-icon.svg"> -->
                        </div>
                        {{
                            (copiedInviteToClipboard ? 'host_meeting.copied' : 'host_meeting.copy_to_clipboard')
                                | translate
                        }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div [class.mask]="loader"></div>
<app-pre-loader
    id="loadingPage"
    tabindex="0"
    [attr.aria-label]="'inside_call.call_component.starting' | translate"
    *ngIf="loader"
    [title]="'inside_call.call_component.starting' | translate"
></app-pre-loader>

<app-permission-denied *ngIf="showPermissionUI && !isMobileDevice"></app-permission-denied>
<app-mic-video-disabled *ngIf="showPermissionUI && isMobileDevice"></app-mic-video-disabled>

<div *ngIf="iosMacDeeplink" [ngStyle]="{ visibility: 'hidden' }">
    <iframe title="" ref="iframe" [src]="iosMacDeeplink | safe"></iframe>
</div>

<app-online-offline-banner
    *ngIf="showBanner"
    [bannerInput]="bannerData"
    (hideBanner)="closeBanner($event)"
></app-online-offline-banner>
