import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'timestampToDuration'
})
export class TimestampToDurationPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(timestamp: string, ...args: any[]): unknown {
        let duration = parseInt(timestamp);
        const portions: string[] = [];

        const msInHour = 1000 * 60 * 60;
        const hours = Math.trunc(duration / msInHour);
        if (hours > 0) {
            portions.push(hours + this.translateService.instant('tostrs.hr'));
            duration = duration - hours * msInHour;
        }

        const msInMinute = 1000 * 60;
        const minutes = Math.trunc(duration / msInMinute);
        if (minutes > 0) {
            portions.push(minutes + this.translateService.instant('tostrs.min'));
            duration = duration - minutes * msInMinute;
        }

        const seconds = Math.trunc(duration / 1000);
        if (seconds > 0) {
            portions.push(seconds + this.translateService.instant('tostrs.sec'));
        }

        return portions.join(' ');
    }
}
