<div
    class="modal fade"
    id="preview-modal"
    [ngStyle]="{ display: this.showPreview ? 'block' : 'none', opacity: 1 }"
    tabindex="-1"
    role="dialog"
>
    <div class="modal-header">
        <div class="row headerRow">
            <div class="col-md-4 imageName"><img alt="" src="assets/images/default-img.svg" />{{ fileName }}</div>
            <div class="col-md-8 preview-opt-cont">
                <div class="row">
                    <div class="col-md-4 mr-3 pointer" data-toggle="tooltip" title="Download" (click)="downloadFile()">
                        <img alt="" class="downloadButton" src="assets/images/downloadIcon.svg" />
                        <!-- <span class="fa fa-download" style="font-size:22px;cursor: pointer;"></span> -->
                    </div>
                    <div
                        class="col-md-4 mr-5 pointer"
                        (click)="this.closePreview(false)"
                        data-toggle="tooltip"
                        title="Close"
                    >
                        <button class="closeButton">Close</button>
                        <!-- <span class="fa fa-window-close" style="font-size:22px;cursor: pointer;"></span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button class="hide" id="downloadAnchor" #downloadAnchor></button>

    <div class="modal-body" id="myObject">
        <div id="imageContainer" class="display-content">
            <img
                alt=""
                class="preview_img"
                [attr.src]="this.webUrl | jioCloudIageAuth: jiomeetId:size | async"
                alt=""
                title="{{ fileName }}"
            />
        </div>
    </div>
</div>
