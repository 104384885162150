<div class="search-dropdown" [ngClass]="{ open: shown, small: size == 'small' }">
    <div class="search-dropdown__btn dropdownbg" (click)="show()">
        <span [ngClass]="isOnBehalfEdit ? 'disable-host-section' : ''">{{ value | titlecase }}</span>
    </div>
    <div class="search-dropdown__dropdown">
        <input
            class="search-dropdown__dropdown__search"
            type="text"
            placeholder="Search host by name, email id "
            [(ngModel)]="keyword"
            (keyup)="search(keyword)"
            #input
        />
        <div class="search-dropdown__dropdown__scroller user">
            <div class="search-dropdown__dropdown__item" *ngFor="let item of list" (click)="select(item)">
                <div class="avatar" appAvatarWithInitials name="{{ item.name + ' ' + item.lname }} "></div>
                <div class="info">
                    <div class="name" tabindex="0">
                        {{ item.name + ' ' + item.lname | titlecase }}
                    </div>
                    <div class="email" tabindex="0" aria-label="{{ item.email }}">
                        {{ item.email }}
                    </div>
                </div>
            </div>
            <div class="search-dropdown__dropdown__msg" *ngIf="list?.length == 0 || temp_list?.length == 0">
                {{ 'planmeeting_popup.errors.no_host_available' | translate }}
                <br />
                {{ 'planmeeting_popup.errors.no_host_available_sbutitle' | translate }}
            </div>
        </div>
    </div>
</div>
