import { ErrorHandler, Injectable } from '@angular/core';
import { ExternalInterfaceService, LoggerService } from '../services';
import { AppLoggerService } from '../services/app-logger.service';
@Injectable({
    providedIn: 'root'
})
export class errorHandler implements ErrorHandler {
    constructor(private externalInterfaceService: ExternalInterfaceService, private logService: AppLoggerService) {}
    handleError(error) {
        // do something with the exception
        //throwing exception to iframe users
        // console.log(error);
        this.logService.handleError(error);
        this.externalInterfaceService.sendErrorOccuredOnJoined('exception', error?.message);
    }
}
