import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
    AppService,
    UtilService,
    AuthService,
    EventEmitterService,
    EventData,
    GaDiagnosticService,
    GoogleTagManagerService,
    DesktopAppService
} from 'src/app/core';

import { APP_EVENTS } from 'src/app/constants';
import { DiagnosticService } from 'src/app/core/services/diagnostic.service';
// import { VERSION } from 'src/environments/version';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { GA_EVENTS } from '../ga-events';
import { MEGA_MENUES } from '../websiteConstant';
import { timeStamp } from 'console';
import { GA4_EVENTS } from '../ga4-events';

@Component({
    selector: 'app-website-footer',
    templateUrl: './website-footer.component.html',
    styleUrls: ['./website-footer.component.scss']
})
export class WebsiteFooterComponent implements OnInit {
    isAuthenticated = false;
    // buildHash = VERSION.hash;
    showHash = false;
    currentLang = this.appService.getAndSaveUserPreferredLang();
    enableMultiLanguage = this.appService.getConfigVariable('ENABLE_MULTI_LANG');
    footerMenues: any = [];
    isMobile = false;
    blogUrl = null;
    currentYear = new Date().getFullYear();
    constructor(
        private router: Router,
        private appService: AppService,
        private utilService: UtilService,
        private authService: AuthService,
        private eventEmitterService: EventEmitterService,
        private diagnosticService: DiagnosticService,
        private translate: TranslateService,
        private config: PrimeNGConfig,
        private gaService: GaDiagnosticService,
        private gTagService: GoogleTagManagerService,
        private desktopAppService: DesktopAppService
    ) {}

    ngOnInit() {
        this.blogUrl = this.appService.getConfigVariable('BLOG_URL');
        this.showHash = !!this.appService.getAppQueryParams().get('v');
        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
        });

        // prepare footer menues
        this.footerMenues = [
            { label: 'Products', tlabel: 'website.products', data: this.getMegaMenues('products') },
            { label: 'Use Cases', tlabel: 'website.use_cases', data: this.getMegaMenues('useCases') },
            { label: 'Resources', tlabel: 'website.resources', data: this.getMegaMenues('resources') },
            { label: 'Support', tlabel: 'website.support', data: this.getMegaMenues('support') },
            { label: 'About', tlabel: 'website.about', data: this.getMegaMenues('about') }
        ];
        this.isMobile = this.utilService.isMobileBrowser(1080);
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 1080 ? false : true;
            }
        });
    }

    getMegaMenues(type = '') {
        let menues = [];
        if (type === 'resources' || type === 'support' || type === 'about') {
            if (this.desktopAppService.isDesktopApp) {
                let index = MEGA_MENUES.more.menues[0].data.findIndex((record) => record.title === 'Download JioMeet');
                if (index !== -1) {
                    MEGA_MENUES.more.menues[0].data.splice(index, 1);
                }
            }

            return (
                (type === 'resources' && MEGA_MENUES.more.menues[0]) ||
                (type === 'support' && MEGA_MENUES.more.menues[1]) ||
                (type === 'about' && MEGA_MENUES.more.menues[2])
            ).data;
        }
        for (let i = 0; i < MEGA_MENUES[type].menues.length; i++) {
            menues = [...menues, ...MEGA_MENUES[type].menues[i].data];
        }

        // reorder menus for useCase scenario
        if (type === 'useCases') {
            menues.sort((a, b) => a.index - b.index);
        }
        return menues;
    }

    gotopp() {
        this.gTagService.sendEvent(GA_EVENTS.FOOTER_PRIVACY);
        this.gTagService.sendEvent(GA4_EVENTS.FOOTER_PRIVACY);
        if (this.appService.getAndSaveUserPreferredLang() == 'hi') {
            window.open(this.appService.getEnvVariable('HOST_URL') + 'privacy-policy-business?lan=hi');
        } else {
            window.open(this.appService.getEnvVariable('HOST_URL') + 'privacy-policy-business');
        }
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Website Home Options',
                eventType: 'app_event',
                eventAction: 'Privacy Policy',
                status: 'success'
            })
            .subscribe();
    }

    gototnc() {
        this.gTagService.sendEvent(GA_EVENTS.FOOTER_TNC);
        this.gTagService.sendEvent(GA4_EVENTS.FOOTER_TNC);
        if (this.appService.getAndSaveUserPreferredLang() == 'hi') {
            window.open(this.appService.getEnvVariable('HOST_URL') + 'terms-condition-business?lan=hi');
        } else {
            window.open(this.appService.getEnvVariable('HOST_URL') + 'terms-condition-business');
        }
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Website Home Options',
                eventType: 'app_event',
                eventAction: 'Terms and Conditions',
                status: 'success'
            })
            .subscribe();
    }

    joinMeeting() {
        this.router.navigate(['shortener']);
    }

    goToMain() {
        this.gTagService.sendEvent(GA_EVENTS.FOOTER_JIOMEET);
        this.gTagService.sendEvent(GA4_EVENTS.FOOTER_JIOMEET_LOGO);
        this.router.navigate(['/main']);
    }

    goToHome() {
        this.gTagService.sendEvent(GA_EVENTS.FOOTER_JIOMEET);
        this.gTagService.sendEvent(GA4_EVENTS.FOOTER_JIOMEET_LOGO);
        this.router.navigate(['/home']);
    }

    navigateToAppStore(os) {
        let url;
        if (os === 'android') {
            url = this.appService.getConfigVariable('ANDROID_INSTALLER_URL');
            this.diagnosticService
                .sendEvent({
                    eventAction: 'Download on Google Play',
                    eventCategory: 'Download App',
                    eventType: 'app_event',
                    status: 'success'
                })
                .subscribe();
            this.gaService.sendEvent(GA_EVENTS.DOWNLOAD_ON_GOOGLEPLAY);
        } else if (os === 'ios') {
            url = this.appService.getConfigVariable('IOS_INSTALLER_URL');
            this.diagnosticService
                .sendEvent({
                    eventAction: 'Download on iOS',
                    eventCategory: 'Download App',
                    eventType: 'app_event',
                    status: 'success'
                })
                .subscribe();
            this.gaService.sendEvent(GA_EVENTS.DOWNLOAD_ON_APPSTORE);
        }

        if (url) {
            const appQueryParams = this.appService.getAppQueryParams();
            const utmSource = appQueryParams.get('utm_source');
            const utmMedium = appQueryParams.get('utm_medium');
            const utmCampaign = appQueryParams.get('utm_campaign');
            if (utmSource) {
                url += `&utm_source=${utmSource}`;
            }
            if (utmMedium) {
                url += `&utm_medium=${utmMedium}`;
            }
            if (utmCampaign) {
                url += `&utm_campaign=${utmCampaign}`;
            }
            window.open(url, '_blank');
        }
    }

    navigateToMacAppStore() {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Download App',
                eventType: 'app_event',
                eventAction: 'Download on App Store',
                status: 'success'
            })
            .subscribe();
        this.gaService.sendEvent(GA_EVENTS.DOWNLOAD_ON_IOS);
        window.open(this.appService.getConfigVariable('MAC_INSTALLER_URL'), '_blank');
    }

    navigateToDownload() {
        // const array = window.location.href.split('/');
        // array.pop();
        // window.open(array.join('/') + '/download', '_blank');
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Download App',
                eventType: 'app_event',
                eventAction: 'Download on Windows',
                status: 'success'
            })
            .subscribe();
        this.gaService.sendEvent(GA_EVENTS.DOWNLOAD_ON_WINDOWS);
        window.open(this.utilService.getWindowsClient());
    }

    navigateToSignIn() {
        const array = window.location.href.split('/');
        array.pop();
        window.open(array.join('/') + '/login', '_blank');
    }

    navToSignUp() {
        // this.router.navigate(['signup']);
        this.eventEmitterService.emit({
            type: APP_EVENTS.SHOW_INVITE_CODE_FORM_POPUP,
            data: null
        });
    }

    navToSignIn() {
        this.router.navigate(['login']);
    }

    navToWebApp() {
        if (this.isAuthenticated) {
            this.router.navigate(['meetings']);
        } else {
            this.navToSignIn();
        }
    }

    downloadPlugin() {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Download App',
                eventType: 'app_event',
                eventAction: 'Download Outlook Plugin',
                status: 'success'
            })
            .subscribe();
        window.open(this.appService.getConfigVariable('OUTLOOK_PLUGIN_URL'));
    }

    scrollToAnchor(location: string, wait: number): void {
        const element = document.querySelector('#' + location);
        if (element) {
            setTimeout(() => {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest'
                });
            }, wait);
        }
    }

    selectLang(lang) {
        this.currentLang = lang;
        this.appService.getAndSaveUserPreferredLang(lang);
        this.translate.use(lang);
        this.translate.get('primeng').subscribe((res) => this.config.setTranslation(res));
    }

    handleGoTo(menuInfo: any) {
        this.gTagService.sendEvent({
            event: 'jiomeet',
            event_category: 'JioMeet_Footer',
            event_action: `${menuInfo.title}`,
            event_label: 'Success'
        });
        switch (menuInfo.title) {
            case 'JioMeet':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_JIOMEET);
                break;
            case 'Enterprise':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_ENTERPRISE);
                break;
            case 'Platform':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_PLATFORM);
                break;
            case 'JioEvents':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_JIOEVENTS);
                break;
            case 'Discover':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_DISCOVER);
                break;
            case 'Education':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_EDUCATION);
                break;
            case 'Healthcare':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_HEALTHCARE);
                break;
            case 'Hybrid Workplaces':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_HYBRID);
                break;
            case 'Platform Services':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_PLATFORM_SERVICES);
                break;
            case 'Government Sectors':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_GOVERNMENT);
                break;
            case 'Download JioMeet':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_DOWNLOAD_JIOMEET);
                break;
            case 'JioMeet Dev Center':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_JIOMEET_DEV_CENTER);
                break;
            case 'Developer Community':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_JIOMEET_DEV_COMMUNITY);
                break;
            case 'Blog':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_BLOG);
                break;
            case 'Need Help?':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_NEED_HELP);
                break;
            case 'Developer Support?':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_DEV_SUPPORT);
                break;
            case 'Provide Feedback':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_PROVIDE_FEEDBACK);
                break;
            case 'Contact Us':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_CONTACT_US);
                break;
            case 'Press Release':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_PRESS_RELEASE);
                break;
            case 'Customers':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_CUSTOMERS);
                break;
            case 'Careers':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_CAREERS);
                break;
            default:
                break;
        }
        if (!menuInfo.path) return;
        if (!menuInfo.isExternal) this.router.navigate([menuInfo.path]);
        else
            window.open(
                menuInfo.ttitle !== 'blog' ? menuInfo.path : this.blogUrl,
                '_blank' // <- This is what makes it open in a new window.
            );
    }

    goToSocialPlatform(platform) {
        this.gTagService.sendEvent({
            event: 'jiomeet',
            event_category: 'JioMeet_Footer',
            event_action: `${platform}`,
            event_label: 'Success'
        });

        switch (platform) {
            case 'Facebook':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_FACEBOOK);
                break;
            case 'LinkedIn':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_LINKEDIN);
                break;
            case 'Twitter':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_TWITTER);
                break;
            case 'Youtube':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_YOUTUBE);
                break;
            case 'Instagram':
                this.gTagService.sendEvent(GA4_EVENTS.FOOTER_INSTAGRAM);
                break;
        }
    }
}
