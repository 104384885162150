<div *ngIf="step == 0">
    <div class="container-popup">
        <div class="card-wrapper">
            <div class="card">
                <div class="row" style="display: flex; justify-content: space-between">
                    <div class="pop-nav-left">
                        <img class="card-icon" src="assets/images/ps_jio_meet.svg" alt="Jio Meet Logo" />
                        <h2>JioMeet</h2>
                    </div>

                    <img
                        class="close-icon"
                        src="assets/images/ic_close.svg"
                        alt="close-icon"
                        (click)="closePermissiondialog()"
                    />
                </div>

                <div class="head">
                    <p>You have denied the Camera and Microphone access</p>
                </div>

                <div class="middle-screen">
                    <img class="video-disable" src="assets/images/Group 206081.svg" alt="disabled video icon" />
                    <p class="plus">+</p>
                    <img class="mic" src="assets/images/disabled mic.svg" alt="disabled mic icon" />
                </div>

                <div class="btn-container">
                    <button class="read-btn" (click)="incrementStep(step)">
                        <div class="btn-txt">Read more on how to enable it</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isMobileOS == 'iOS' && browser == 'Safari' && step == 1">
    <div class="container">
        <div class="card-wrapper">
            <div class="card">
                <div class="row">
                    <div style="display: flex; align-items: center">
                        <img
                            class="back-icon"
                            src="assets/images/ic_chevron_left.svg"
                            alt="back-icon"
                            (click)="decrementStep(step)"
                        />
                        <h3>For Safari</h3>
                    </div>
                    <img
                        class="close-icon"
                        src="assets/images/ic_close.svg"
                        alt="close-icon"
                        (click)="closePermissiondialog()"
                    />
                </div>
                <div class="head">
                    <p>The following steps will guide you in enabling your camera and microphone</p>

                    <div></div>

                    <div class="content">
                        <p>1. Tap on <b>AA </b>to the left of the address bar.</p>
                        <div>
                            <img class="image-ins" src="assets/images/pop-images/iOS Safari/1-iOS Safari.png" alt="" />
                        </div>
                    </div>

                    <div class="content">
                        <p>2. Tap on Website Settings.</p>
                        <div>
                            <img class="image-ins" src="assets/images/pop-images/iOS Safari/2-iOS Safari.png" alt="" />
                        </div>
                    </div>

                    <div class="content">
                        <p>3. Ensure your camera and microphone are set to Allow:</p>
                        <div>
                            <img class="image-ins" src="assets/images/pop-images/iOS Safari/3-iOS Safari.png" alt="" />
                        </div>
                    </div>

                    <div class="content">
                        <p>4. Select Done and refresh Safari.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isMobileOS == 'Android' && step == 1">
    <div class="container">
        <div class="card-wrapper">
            <div class="card">
                <div class="row">
                    <div class="pop-nav">
                        <img
                            class="back-icon"
                            src="assets/images/ic_chevron_left.svg"
                            alt="back-icon"
                            (click)="decrementStep(step)"
                        />
                        <h3>For Chrome</h3>
                    </div>
                    <img
                        class="close-icon"
                        src="assets/images/ic_close.svg"
                        alt="closeicon"
                        (click)="closePermissiondialog()"
                    />
                </div>
                <div class="head">
                    <p>The following steps will guide you in enabling your camera and microphone</p>

                    <p class="changes">Phone Settings</p>
                    <div class="content">
                        <p>1. On your device, open the Settings app.</p>
                    </div>
                    <div class="content">
                        <p>2. Tap Apps.</p>
                    </div>
                    <div class="content">
                        <p>3. Tap on Chrome.</p>
                        <div>
                            <img class="image-ins" src="assets/images/pop-images/Android/Android-1.png" alt="" />
                        </div>
                    </div>
                    <div class="content">
                        <p>4. Tap on Permissions.</p>
                        <div>
                            <img class="image-ins" src="assets/images/pop-images/Android/Android-2.png" alt="" />
                        </div>
                    </div>
                    <div class="content">
                        <p>
                            5. Toggle on the switch next to "Camera" and "Microphone" to allow the app to access them.
                        </p>
                        <div>
                            <img class="image-ins" src="assets/images/pop-images/Android/Android-3.png" alt="" />
                        </div>
                    </div>

                    <p class="changes">Change a site's camera & microphone permissions</p>
                    <div class="content">
                        <p>1. On your Android device, open the Chrome app.</p>
                    </div>

                    <div class="content">
                        <p>
                            2. To the right of the address bar, tap on the three dots in the upper right-hand corner of
                            your browser URL bar.
                        </p>
                        <div>
                            <img class="image-ins" src="assets/images/pop-images/Android/Android-4.png" alt="" />
                        </div>
                    </div>
                    <div class="content">
                        <div class="content">
                            <p>3. Tap on "Settings"</p>
                            <div>
                                <img class="image-ins" src="assets/images/pop-images/Android/Android-5.png" alt="" />
                            </div>
                        </div>

                        <div class="content">
                            <p>4. Select "Site Settings"</p>
                            <div>
                                <img class="image-ins" src="assets/images/pop-images/Android/Android-6.png" alt="" />
                            </div>
                        </div>
                        <div class="content">
                            <p>5. Tap on Microphone or Camera.</p>
                            <div>
                                <img class="image-ins" src="assets/images/pop-images/Android/Android-7.png" alt="" />
                            </div>
                        </div>
                        <div class="content">
                            <p>6. Tap to turn the microphone or camera on or off.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isMobileOS == 'iOS' && browser != 'Safari' && step == 1">
    <div class="container">
        <div class="card-wrapper">
            <div class="card">
                <div class="row">
                    <div style="display: flex; align-items: center">
                        <img
                            class="back-icon"
                            src="assets/images/ic_chevron_left.svg"
                            alt="back-icon"
                            (click)="decrementStep(step)"
                        />
                        <h3>For Chrome</h3>
                    </div>
                    <img
                        class="close-icon"
                        src="assets/images/ic_close.svg"
                        alt="close-icon"
                        (click)="closePermissiondialog()"
                    />
                </div>
                <div class="head">
                    <p>The following steps will guide you in enabling your camera and microphone</p>

                    <div></div>

                    <div class="content">
                        <p>1. Open Settings on your phone.</p>
                    </div>

                    <div class="content">
                        <p>2. Select your favourite browser, for example Chrome.</p>
                        <div>
                            <img
                                class="image-ins"
                                src="assets/images/pop-images/iOS Chrome/iOS Chrome - 1.png"
                                alt=""
                            />
                        </div>
                    </div>

                    <div class="content">
                        <p>3. Look for "Camera" and “Microphone” and make sure it's toggled "On".</p>
                        <div>
                            <img
                                class="image-ins"
                                src="assets/images/pop-images/iOS Chrome/iOS Chrome - 2.png"
                                alt=""
                            />
                        </div>
                    </div>

                    <div class="content">
                        <p>4. Refresh the page.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
