<div class="call-loader" *ngIf="showCallLoader">
    <audio id="testAudio">
        <source src="./assets/audio/call_receive.mp3" type="audio/mpeg" />
    </audio>
    <div class="initials-block" *ngIf="userContact?.length === 1">
        <span appAvatarWithInitials name="{{ userContact[0]?.name }} {{ userContact[0]?.lname }}"></span>
    </div>
    <div class="calling-text" [class.calling-text-top]="userContact?.length > 1">
        <span>{{ 'inside_call.participants_controls.calling' | translate }}</span>
        <span *ngIf="userContact?.length > 1"
            >"{{ (userContact[0]?.name + ' ' + userContact[0]?.lname).slice(0, 15) }} +
            {{ userContact.length - 1 }} others</span
        >
        <span>{{ callTitle }}</span>
    </div>
    <div class="controls">
        <div
            class="control pointer text-center mic-section"
            id="toggleMicButton"
            (click)="toggleAudio()"
            [class.disabled]="!meetingInfo?.historyId || joinWithoutAudio"
            tabindex="0"
        >
            <div class="mic-dropdown control-dropdown" #micDropdown [hidden]="!micDropdownOpened">
                <div (click)="openSettings($event, 'audio')" (outside)="onOutsideClick($event)">
                    {{ 'inside_call.call_controls.audiosettings' | translate }}
                </div>
            </div>
            <div class="img-holder">
                <img alt="" src="assets/img/revamp/mic_unmuted_2.svg" *ngIf="micPrivacy" />
                <img alt="" src="assets/img/revamp/mic_muted_2.svg" *ngIf="!micPrivacy" />
            </div>
            <div class="text-center title" *ngIf="micPrivacy">
                {{ 'inside_call.call_controls.mute' | translate }}
            </div>
            <div class="text-center title" *ngIf="!micPrivacy">
                {{ 'inside_call.call_controls.unmute' | translate }}
            </div>
            <img
                alt=""
                class="room-setting-arws"
                [src]="micDropdownOpened ? 'assets/img/arrow_down.svg' : 'assets/img/arrow_up.svg'"
                alt=""
                (click)="toggleDropdown($event, micDropdown)"
            />
        </div>
        <div
            class="control pointer text-center camera-section"
            id="toggleCameraButton"
            (click)="toggleVideo()"
            [class.disabled]="!meetingInfo?.historyId || joinWithoutVideo"
        >
            <div class="camera-dropdown control-dropdown" #cameraDropdown [hidden]="!cameraDropdownOpened">
                <div (click)="openSettings($event, 'background')" (outside)="onOutsideClick($event)">
                    {{ 'inside_call.call_controls.change_background' | translate }}
                    <span class="beta-tag"><img alt="" src="assets/images/Group 119888.svg" /></span>
                </div>
                <div (click)="openSettings($event, 'video')" (outside)="onOutsideClick($event)">
                    {{ 'inside_call.call_controls.videosettings' | translate }}
                </div>
            </div>
            <div class="img-holder align-items-center">
                <img alt="" src="assets/img/revamp/camera_unmuted.png" *ngIf="cameraPrivacy" width="21" />
                <img alt="" src="assets/img/revamp/camera_muted.png" *ngIf="!cameraPrivacy" width="21" />
            </div>
            <div class="text-center title" *ngIf="cameraPrivacy">
                {{ 'inside_call.call_controls.stopvideo' | translate }}
            </div>
            <div class="text-center title" *ngIf="!cameraPrivacy">
                {{ 'inside_call.call_controls.startvideo' | translate }}
            </div>
            <img
                alt=""
                class="room-setting-arws"
                [src]="cameraDropdownOpened ? 'assets/img/arrow_down.svg' : 'assets/img/arrow_up.svg'"
                alt=""
                (click)="toggleDropdown($event, cameraDropdown)"
            />
        </div>
        <div class="control cancel-btn">
            <div
                class="btn close-btn text-white"
                id="endCallButton"
                (click)="endCall()"
                [class.disabled]="!meetingInfo?.historyId"
            >
                {{ 'inside_call.call_component.cancel' | translate }}
            </div>
        </div>
    </div>
    <div class="video-container">
        <div class="video" id="contacts-call-preview"></div>
    </div>
    <ng-template viewHost></ng-template>
</div>
