<div class="website-enterprise" id="website-top">
    <section class="enterprise-top" [class.padding-h-0]="isMobile">
        <div class="left-content" [class.padding-h-16]="isMobile" *ngIf="!isAuthenticated || currentUser.isOTT">
            <div class="heading" tabindex="0" aria-label="JioMeet Enterprise">
                <span>{{ 'website_enterprise.jiomeet' | translate }}</span>
                <span> {{ 'website_enterprise.enterprise' | translate }}</span>
            </div>
            <div class="desc" tabindex="0" aria-label="Collaborate effectively with hybrid workforce.">
                <span>{{ 'website_enterprise.top-desc-0' | translate }}</span>
                <span>{{ 'website_enterprise.top-desc-1' | translate }}</span>
            </div>
            <div class="action-btns">
                <button class="custom-action-btn" (click)="goToContactSales()">
                    {{ 'website_enterprise.contact_sales' | translate }}
                </button>
            </div>
        </div>
        <div
            class="left-content"
            [class.padding-h-16]="isMobile"
            *ngIf="isAuthenticated && !currentUser.isOTT"
            [class.authenticated]="isAuthenticated"
        >
            <div class="heading" tabindex="0">
                {{ 'aftersignin_or_introduction.hello' | translate }} {{ currentUser?.name }} {{ currentUser?.lname }},
            </div>
            <div class="desc" tabindex="0" aria-label="Collaborate effectively with hybrid workforce.">
                <span>{{ 'website_enterprise.top-desc-0' | translate }}</span>
                <span>{{ 'website_enterprise.top-desc-1' | translate }}</span>
            </div>
            <div class="action-btns">
                <button class="custom-action-btn" (click)="navigateToAdminPortal()">
                    {{ 'website_enterprise.go_to_admin_portal' | translate }}
                </button>
            </div>
        </div>

        <div class="right-content" *ngIf="isMobile">
            <img src="assets/img/enterprise/enterprise-mobile.png" alt="" />
        </div>
    </section>

    <section class="sec-1" [class.padding-h-16]="isMobile">
        <div class="workspace-container">
            <h2 class="heading" tabindex="0">
                {{ 'website_enterprise.work_from_anywhere' | translate }}<br />
                <span>{{ 'website_enterprise.transit_hybrid_workspace' | translate }}</span>
            </h2>
            <div class="text-center">
                <img
                    src="assets/img/enterprise/hybrid_workspace_desktop.png"
                    alt="Transition to hybrid workspace"
                    *ngIf="!isMobile"
                />
                <img src="assets/img/enterprise/hybrid_workspace_mobile.png" alt="" *ngIf="isMobile" />
            </div>
        </div>
    </section>
    <section class="enterprise-count feature-bottom-margin" [class.padding-h-16]="isMobile">
        <div class="subheading" tabindex="0">
            {{ 'website_enterprise.increase_productivity_jiomeet' | translate }}
        </div>
    </section>
    <section class="sec-2 feature-bottom-margin" [class.padding-h-16]="isMobile">
        <div *ngFor="let productFeature of websiteEnterpriseFeatures; let i = index">
            <app-website-product-features
                [productFeature]="productFeature"
                [reverse]="i % 2 != 0"
            ></app-website-product-features>
        </div>
    </section>
    <section
        class="website-content partners"
        appForceFullWidth
        [class.padding-h-16]="isMobile"
        [style.display]="enterpriseBranding?.length == 0 ? 'none' : 'block'"
    >
        <h4 class="heading" tabindex="0">{{ 'website_home.trusted_partners' | translate }}</h4>
        <div class="partners-list">
            <ng-container *ngFor="let partner of enterpriseBranding">
                <img src="{{ partner.path }}" alt="" />
            </ng-container>
        </div>
    </section>
    <section
        class="sec-3 testimonials sec"
        [class.sec-margin-h-44]="isMobile"
        [style.display]="enterpriseTestimonials?.length == 0 ? 'none' : 'block'"
    >
        <h2 class="heading-0" tabindex="0">{{ 'website_home.customers_comment' | translate }}</h2>
        <div class="testimonial-slider" #testimonialSlider>
            <div
                class="slide"
                *ngFor="let testimonial of enterpriseTestimonials; let i = index"
                [attr.aria-selected]="currentIndex === i ? 'true' : 'false'"
                [attr.aria-label]="'Testimonial ' + (i + 1)"
                tabindex="0"
            >
                <app-website-testimonials [testimonial]="testimonial"></app-website-testimonials>
            </div>
        </div>
    </section>
    <section class="got-questions feature-bottom-margin" [class.sec-padding-h-44]="isMobile">
        <app-explore-meet
            title="{{ 'website_enterprise.explore_title' | translate }}"
            desc=""
            contactSalesEnterprise="ContactSalesEnterprise"
            btnText1="{{ 'website_enterprise.contact_sales' | translate }}"
            btnText1Link="/contactsales"
        >
            <span>{{ 'website_home.jiomeet_for_business' | translate }} </span>
            <span class="margin-bottom-0">{{ 'website_home.integrate_jiomeet' | translate }} </span>
            <span class="margin-bottom-0">{{ 'website_home.plan_a_conference' | translate }}</span>
        </app-explore-meet>
    </section>

    <section class="website-content tips-updates" [class.sec-padding-h-44]="isMobile">
        <app-jiomeet-subscribe SubcribePage="productEnterprise"></app-jiomeet-subscribe>
    </section>
</div>
