import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    AppService,
    EventEmitterService,
    RoomConnectionService,
    VideoWrapperService,
    UtilService,
    ThirdPartyExternalIntegrationService,
    RtcService,
    RdpSocketService
} from 'src/app/core';
import { APP_EVENTS } from 'src/app/constants';
@Component({
    selector: 'app-in-call-banner',
    templateUrl: './in-call-banner.component.html',
    styleUrls: ['./in-call-banner.component.scss']
})
export class InCallBannerComponent implements OnInit {
    @Output() hideInCallBanner = new EventEmitter();
    @Output() makeAudioOnlyMode = new EventEmitter();
    @Output() sendDenyRDPAccess = new EventEmitter();
    @Output() sendAllowRDPAccess = new EventEmitter();
    @Input() type;
    @Input() isRequestingAccess;
    showUnmuteCameraPopup = false;
    videoService;
    roomStatus;
    isMobileDevice;
    isThirdPartyExternalIntegration;
    isJMMeeting = false;

    constructor(
        private appService: AppService,
        private eventEmitterService: EventEmitterService,
        private videoWrapperService: VideoWrapperService,
        public rtcService: RtcService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        public utilService: UtilService,
        private roomConnectionService: RoomConnectionService,
        private rdpService: RdpSocketService
    ) {
        this.videoService = this.videoWrapperService.getVideoService();
    }

    ngOnInit(): void {
        this.showUnmuteCameraPopup = this.appService.cameraOnBeforeAudioOnlyMode;

        this.roomConnectionService.getRoomConnectionStatus$().subscribe((roomStatus) => {
            if (!roomStatus || roomStatus?.success === false) {
                return;
            }
            this.roomStatus = roomStatus;
        });
        this.isThirdPartyExternalIntegration =
            this.thirdPartyExternalIntegrationService.getThirdPartyExternalIntegration();
        this.isMobileDevice = this.isThirdPartyExternalIntegration || this.utilService.isMobileBrowser();
        this.isJMMeeting = this.rtcService.getIsJMMeeting();
    }

    closeBanner() {
        this.hideInCallBanner.emit();
        this.appService.cameraOnBeforeAudioOnlyMode = false;
    }

    turnOnCamera() {
        if (this.videoService?.hideNonVideoSelected) {
            this.eventEmitterService.emit({
                type: APP_EVENTS.DISABLE_HIDE_NON_VIDEO_MESSAGE,
                data: {}
            });
            return;
        }
        this.eventEmitterService.emit({
            type: APP_EVENTS.TOGGLE_CAMERA,
            data: {}
        });
        this.appService.cameraOnBeforeAudioOnlyMode = false;
        this.hideInCallBanner.emit();
    }

    enableAudioOnlyMode() {
        if (this.videoService?.hideNonVideoSelected) {
            this.eventEmitterService.emit({
                type: APP_EVENTS.DISABLE_HIDE_NON_VIDEO_MESSAGE,
                data: {}
            });
            this.hideInCallBanner.emit();
            return;
        }
        this.hideInCallBanner.emit();
        this.makeAudioOnlyMode.emit();
    }

    requestingUser() {
        const user = this.roomStatus?.participants?.filter(
            (participant) => participant.participantId === this.rdpService.userId
        );
        return { value: user[0]?.participantName || 'Anonymous' };
    }

    denyRDPAccess() {
        this.sendDenyRDPAccess.emit();
    }

    allowRDPAccess() {
        this.sendAllowRDPAccess.emit();
    }
}
