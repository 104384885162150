<app-loader *ngIf="loading"></app-loader>
<ng-container *ngIf="!loading">
    <div class="page container-fluid" *ngIf="browser === 'iphone' || browser === 'android'">
        <div class="row h-100">
            <div class="left-content left-content col-xl-7 col-lg-6 col-md-12 col-sm-12">
                <div class="img-wrap">
                    <app-lottie-renderer path="assets/lottie/Page Animation.json"></app-lottie-renderer>
                </div>
            </div>
            <div class="right-content col-xl-5 col-lg-6 col-md-12 col-sm-12 p-5">
                <div class="component-wrap text-center">
                    <div class="form-wrap">
                        <h3 *ngIf="currentUser?.name">
                            {{
                                'downloadspage.experience'
                                    | translate: { value1: currentUser?.name, value2: currentUser?.lname || '' }
                            }}
                        </h3>
                        <h3 *ngIf="!currentUser?.name">
                            {{ 'downloadspage.experience_guest' | translate }}
                        </h3>
                        <div class="mt-5">
                            <div class="link pointer" *ngIf="browser === 'android'" (click)="downloadJioMeet()">
                                <img alt="" src="assets/img/website/google-play-store.png" />
                            </div>
                            <div class="link pointer" *ngIf="browser === 'iphone'" (click)="downloadJioMeet()">
                                <img alt="" src="assets/img/website/app-store.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="page container-fluid" *ngIf="browser !== 'iphone' && browser !== 'android'">
        <div class="compatibility-info" *ngIf="showCompatibilityMessage">
            <div class="img-wrap mr-3">
                <img alt="" src="assets/img/warning_icn.svg" alt="warning" />
            </div>
            <div>{{ 'downloadspage.compatibility_info' | translate }}</div>
        </div>
        <div class="row h-100">
            <div class="left-content left-content col-xl-7 col-lg-6 col-md-12 col-sm-12">
                <div class="img-wrap">
                    <app-lottie-renderer path="assets/lottie/Page Animation.json"></app-lottie-renderer>
                </div>
            </div>
            <div class="right-content col-xl-5 col-lg-6 col-md-12 col-sm-12 p-5">
                <div class="component-wrap text-center">
                    <div class="form-wrap">
                        <h3 *ngIf="currentUser?.name">
                            <span>{{
                                'downloadspage.hi'
                                    | translate: { value1: currentUser?.name, value2: currentUser?.lname || '' }
                            }}</span
                            >{{
                                'downloadspage.title'
                                    | translate
                                        : {
                                              value:
                                                  (type === 'shortener' ? 'downloadspage.join' : 'downloadspage.add')
                                                  | translate
                                          }
                            }}
                        </h3>
                        <h3 *ngIf="!currentUser?.name">
                            <span>{{ 'downloadspage.hi_guest' | translate }}</span
                            >{{
                                'downloadspage.title'
                                    | translate
                                        : {
                                              value:
                                                  (type === 'shortener' ? 'downloadspage.join' : 'downloadspage.add')
                                                  | translate
                                          }
                            }}
                        </h3>
                        <div class="join-options my-4">
                            <div>
                                <button
                                    type="button"
                                    id="downloadJioMeetButton"
                                    class="download custom-button bg-primary my-4"
                                    *ngIf="currentOS === 'Windows' || currentOS === 'Mac OS'"
                                    (click)="downloadJioMeet()"
                                >
                                    <span class="icon d-inline-block">
                                        <img alt="" src="assets/img/download_app_white.svg" />
                                    </span>
                                    <span>{{ 'downloadspage.download' | translate }}</span>
                                </button>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    id="continueOnBrowserButton"
                                    class="mb-4 custom-button bg-secondary"
                                    *ngIf="
                                        (enableJoinWithBrowser || currentUser?.nativeWebrtcEnabled) &&
                                        type === 'shortener' &&
                                        ((isWebinar && redirectToJioConf) || !isWebinar)
                                    "
                                    (click)="join()"
                                >
                                    <span class="icon d-inline-block">
                                        <img alt="" src="assets/img/browser.svg" />
                                    </span>
                                    <span>{{ 'downloadspage.continuebutton' | translate }}</span>
                                </button>
                                <button
                                    type="button"
                                    id="addToJioMeetButton"
                                    class="mb-4"
                                    *ngIf="type === 'addtojiomeet' && currentUser"
                                    (click)="addToJiomeet()"
                                >
                                    <span class="icon d-inline-block">
                                        <img alt="" src="assets/img/browser.svg" />
                                    </span>
                                    <span>{{ 'downloadspage.add_button' | translate }}</span>
                                </button>
                            </div>
                        </div>
                        <div class="launch-text" id="launchClientButton">
                            <span
                                >{{ 'downloadspage.already' | translate }}
                                <a class="pointer blue" (click)="launch()">{{
                                    'downloadspage.launch' | translate
                                }}</a></span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="iosMacDeeplink" [ngStyle]="{ visibility: 'hidden' }">
    <iframe title="" ref="iframe" [src]="iosMacDeeplink | safe"></iframe>
</div>
