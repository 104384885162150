import { browser } from 'protractor';
import { Component, OnInit } from '@angular/core';

import { APP_EVENTS } from 'src/app/constants';
import { EventEmitterService } from 'src/app/core';

import { SystemService } from 'src/app/core';
import { UtilService } from 'src/app/core';

@Component({
    selector: 'app-mic-video-disabled',
    templateUrl: './mic-video-disabled.component.html',
    styleUrls: ['./mic-video-disabled.component.scss']
})
export class MicVideoDisabledComponent implements OnInit {
    public browser: string;
    public isMobileOS: string;
    public step: number;
    constructor(
        private systemService: SystemService,
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService
    ) {}

    ngOnInit(): void {
        const { browser, version } = this.systemService.getSysytemInfo();
        this.browser = browser;
        this.isMobileOS = this.utilService.isMobileAndroidOrIphone();
        this.step = 0;
    }
    closePermissiondialog() {
        this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: false });
    }
    incrementStep(step) {
        this.step = 1;
    }
    decrementStep(step) {
        this.step = 0;
    }
}
