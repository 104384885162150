import { Component, Input, OnInit, OnChanges, ViewChild, ElementRef } from '@angular/core';

import { differenceInMinutes } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import {
    ThirdPartyExternalIntegrationService,
    UserService,
    UtilService,
    AppService,
    GoogleTagManagerService
} from 'src/app/core';
import { GA_NEW } from '../../ga-new';
import { ToastrService } from 'ngx-toastr';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { TimeZoneMappingPipe } from '../../pipes';

@Component({
    selector: 'app-meeting-invitation',
    templateUrl: './meeting-invitation.component.html',
    styleUrls: ['./meeting-invitation.component.scss'],
    providers: [TimeZoneMappingPipe]
})
export class MeetingInvitationComponent implements OnInit, OnChanges {
    @Input() id;
    @Input() host;
    @Input() meeting;
    @Input() ischildofpmicomponent;
    @Input() insideMeeting = false;
    @ViewChild('copyLinkIcon') copyLinkIcon: ElementRef;

    isAgoraMeeting = false;
    duration;
    hours = '';
    minutes = '';
    hostName;
    endDate: Date;
    myjioIntegration: boolean = false;
    copiedUrlToClipboard = false;
    options;
    addToIcsCalenderUrl: string;
    hostUrl: string;
    showIcsInvi: boolean;
    GoogleTagManagerService: any;
    isInternetIpHidden: boolean = false;
    isIntranetIpHidden: boolean = false;
    isDnsAddressHidden: boolean = false;
    isIntranetIpValuePresent: boolean = false;
    isScheduledMeeting: boolean = false;
    webhelpUrl: string;
    allParticipants = null;

    constructor(
        private translateService: TranslateService,
        private userService: UserService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        private utilService: UtilService,
        private appService: AppService,
        private gTagService: GoogleTagManagerService,
        private timeZoneMappingPipe: TimeZoneMappingPipe,
        private toastrService: ToastrService
    ) {}

    ngOnInit() {
        this.myjioIntegration = this.thirdPartyExternalIntegrationService.getMyjioIntegration();
        this.setHostName();
        this.showIcsInvi = this.meeting?.startTime != null;
        this.isAgoraMeeting = this.userService.getUserSync()['mediaEnginePreference'] === 'agora';
        if (this.meeting.startTime && this.meeting.endTime) {
            this.duration = differenceInMinutes(new Date(this.meeting.endTime), new Date(this.meeting.startTime));
        }
        if (this.meeting?.repeatOptions && this.meeting?.repeatOptions?.repeat != 'none') {
            this.endDate = this.meeting?.repeatOptions?.endDate.substring(0, 10);
        }
        if (this.meeting?.isScheduledMeeting === false) {
            delete this.meeting?.addToJmtUrl;
        }
        this.hostUrl = this.appService.getEnvVariable('HOST_URL');
        this.addToIcsCalenderUrl = `${this.hostUrl}downloadICSFile?jiomeetId=${this.meeting?.jiomeetId}&roomPIN=${this.meeting?.pin}`;
        this.webhelpUrl = `${this.hostUrl}webhelp`;
        this.loadParticipantCount();
        this.isScheduledMeeting =
            this.meeting?.isScheduledMeeting || this.meeting?._id?.substring(0, 3) === 'sm-' ? true : false;
        this.initializeMeetingInfoVariables();
    }

    ngOnChanges() {
        this.setHostName();
        this.setValue();
        this.initializeMeetingInfoVariables();
        if (this.meeting.startTime && this.meeting.endTime) {
            this.duration = differenceInMinutes(new Date(this.meeting.endTime), new Date(this.meeting.startTime));
        }
        this.isScheduledMeeting =
            this.meeting?.isScheduledMeeting || this.meeting?._id?.substring(0, 3) === 'sm-' ? true : false;
    }

    setHostName() {
        this.hostName = this.meeting?.owner_name
            ? `${this.meeting.owner_name + (this.meeting.owner_lname ? ' ' + this.meeting.owner_lname : '')}`
            : `${this.host.name + (this.host.lname ? ' ' + this.host.lname : '')}`;
    }

    setValue() {
        this.options = this.meeting?.legacyConnect || this.meeting?.legacyConnectInfo ? '3' : '2';
        this.addToIcsCalenderUrl = `${this.hostUrl}downloadICSFile?jiomeetId=${this.meeting?.jiomeetId}&roomPIN=${this.meeting?.pin}`;
    }

    loadParticipantCount() {
        if (this.meeting?.participants?.length) {
            let participantsExceptGuest = this.meeting?.participants?.filter(
                (x) => x?.userId?.substring(0, 3) != 'gu-'
            );
            this.allParticipants = participantsExceptGuest?.length;
        }
    }

    downloadICSMeeting() {
        this.utilService.downloadICSFile(this.meeting?.jiomeetId, this.meeting?.pin).subscribe(
            (_res) => {
                this.utilService.onSuccessDownloadFile(_res, false);
            },
            (err) => {
                if (err === 'no jwt') {
                    this.toastrService.info(this.translateService.instant('tostrs.not_logged_in'));
                    this.utilService.handleUrlRedirection();
                    return err;
                }
                console.log(err?.error);
                err = JSON.parse(err?.error);
                this.toastrService.error(err?.errors);
                return err;
            }
        );
    }

    formatAMPM(date) {
        date = new Date(date);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm1 =
            hours >= 12
                ? this.translateService.instant('meeting_invitation.pm1')
                : this.translateService.instant('meeting_invitation.am1');
        let ampm2 =
            hours >= 12
                ? this.translateService.instant('meeting_invitation.pm2')
                : this.translateService.instant('meeting_invitation.am2');

        hours = hours % 12;
        hours = hours ? hours : 12;
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;

        let userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let shortTimeZone = format(new Date(), 'zzz', { timeZone: userTimezone });

        if (shortTimeZone.includes('GMT+') || shortTimeZone.includes('GMT-')) {
            shortTimeZone = this.timeZoneMappingPipe.transform(shortTimeZone) || shortTimeZone;
        }

        let strTime = `${ampm1} ${hours}:${minutes}${ampm2 ? ` ${ampm2}` : ''} (${shortTimeZone})`;
        return strTime;
    }

    JointhroughLink() {
        this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_JOIN_THROUGH_LINK);
    }
    joinThroughSip() {
        this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_JOIN_THROUGH_SIP_DEVICE);
    }
    addToJiomeetCalender() {
        this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_ADD_TO_CALENDAR);
    }
    downloadIcs() {
        this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_DOWNLOAD_ICS);
    }

    handleAddtoCalendarevent(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            const addtocalendarevent = event.target as HTMLElement;
            addtocalendarevent.click();
        }
    }
    handleDownloadFileevent(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            const downloadfileevent = event.target as HTMLElement;
            downloadfileevent.click();
        }
    }

    handleGoToHelpEvent(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            const helpEvent = event.target as HTMLElement;
            helpEvent.click();
        }
    }

    performCopy(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            const performCopyfunctionality = event.target as HTMLElement;
            performCopyfunctionality.click();
            this.copyLinkIcon.nativeElement.focus();
        }
    }

    copyLink() {
        this.utilService.copy(this.meeting.meetingUrl);
        setTimeout(() => {
            this.copiedUrlToClipboard = false;
        }, 1000);
        this.copiedUrlToClipboard = true;
    }

    initializeMeetingInfoVariables() {
        this.isInternetIpHidden =
            this.meeting?.legacyConnectInfo?.settings?.internetIp?.hide ||
            this.meeting?.legacyConnect?.settings?.internetIp?.hide;
        this.isIntranetIpHidden =
            this.meeting?.legacyConnectInfo?.settings?.intranetIp?.hide ||
            this.meeting?.legacyConnect?.settings?.intranetIp?.hide;
        this.isIntranetIpValuePresent =
            this.meeting?.legacyConnectInfo?.settings?.intranetIp || this.meeting?.legacyConnect?.settings?.intranetIp;
        this.isDnsAddressHidden =
            this.meeting?.legacyConnectInfo?.settings?.dnsAddress?.hide ||
            this.meeting?.legacyConnect?.settings?.dnsAddress?.hide;
    }

    formatDuration(minutes: number): string {
        if (minutes >= 60) {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            if (remainingMinutes === 0) {
                return `${hours} ${hours > 1 ? 'hrs' : 'hr'}`;
            } else {
                return `${hours} ${hours > 1 ? 'hrs' : 'hr'} ${remainingMinutes} mins`;
            }
        } else {
            return `${minutes} ${minutes > 1 ? 'mins' : 'min'}`;
        }
    }
}
