<div class="explore-meet">
    <div class="left-container">
        <h3 tabindex="0" class="header heading-0">{{ title }}</h3>
        <p tabindex="0" class="desc">
            {{ desc }}
            <ng-content></ng-content>
        </p>
        <div class="action-btns">
            <button class="custom-action-btn" *ngIf="btnText1" (click)="navigateTo(btnText1Link)">
                {{ btnText1 }}
            </button>
            <button class="custom-action-btn secondary" *ngIf="btnText2" (click)="navigateTo(btnText2Link)">
                {{ btnText2 }}
            </button>
        </div>
    </div>
    <div *ngIf="!isMobile">
        <img src="assets/img/website/got-question.svg" alt="" />
    </div>
</div>
