<div
    tabindex="0"
    role="button"
    class="solution-container"
    [class.is-discover]="solutionInfo?.title === 'Discover'"
    (click)="onFeatureClick(); handleGoTo(solutionInfo)"
    [class.active]="!!active"
    [class.illustration]="!solutionInfo?.title && !solutionInfo?.desc"
    [class.pointer]="!!solutionInfo.path"
>
    <div [class.img]="solutionInfo?.title || solutionInfo?.desc" *ngIf="solutionInfo?.icon">
        <img
            [src]="featureItemIndex === featureItemActiveIndex ? solutionInfo?.iconActive : solutionInfo?.icon"
            alt="JioMeet"
        />
    </div>
    <div class="solution-info">
        <div class="title" *ngIf="solutionInfo?.title">
            {{ solutionInfo?.ttitle | translate }}
            <span *ngIf="solutionInfo?.title === 'Discover'" class="coming-soon">
                {{ 'website.coming_soon' | translate }}</span
            >
        </div>
        <div class="desc" #solutionDesc [innerHTML]="solutionInfo?.tdesc | translate"></div>
    </div>
</div>
