import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core';

@Component({
    selector: 'app-server-down',
    templateUrl: './server-down.component.html',
    styleUrls: ['./server-down.component.scss']
})
export class ServerDownComponent implements OnInit {
    ngOnInit() {}
}
