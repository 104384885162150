export const GA_EVENTS = {
    INFORMATION_ICON_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'meeting_information_icon',
        event_label: 'success'
    },
    INFORMATION_ICON_INSIDE_CALL_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'meeting_information_icon',
        event_label: 'success_breakoutroom'
    },
    COPY_LINK_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'copy_link',
        event_label: 'success'
    },
    COPY_LINK_INSIDE_CALL_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'copy_link',
        event_label: 'success_breakoutroom'
    },
    COPY_INVITATION_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'copy_invitation',
        event_label: 'success'
    },
    COPY_INVITATION_INSIDE_CALL_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'copy_invitation',
        event_label: 'success_breakoutroom'
    },
    FULL_SCREEN_INSIDE_CALL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'full_screen_mode',
        event_label: 'success'
    },
    FULL_SCREEN_INSIDE_CALL_SUCCESS_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'full_screen_mode',
        event_label: 'success_breakoutroom'
    },
    EXIT_FULL_SCREEN_INSIDE_CALL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'exit_full_screen',
        event_label: 'success'
    },
    EXIT_FULL_SCREEN_INSIDE_CALL_SUCCESS_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'exit_full_screen',
        event_label: 'success_breakoutroom'
    },
    HELP_CLICK_INSIDE_CALL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'Need help',
        event_label: 'success'
    },
    MIC_MUTE_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'mute',
        event_label: 'success'
    },
    MIC_MUTE_INSIDE_CALL_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'mute',
        event_label: 'fail'
    },
    MIC_UNMUTE_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'unmute',
        event_label: 'success'
    },
    MIC_UNMUTE_INSIDE_CALL_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'unmute',
        event_label: 'fail'
    },
    AUDIO_SETTING_INSIDE_CALL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'audio settings',
        event_label: 'success'
    },
    VIDEO_MUTE_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'stop_video',
        event_label: 'success'
    },
    VIDEO_MUTE_INSIDE_CALL_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'stop_video',
        event_label: 'failure'
    },
    VIDEO_UNMUTE_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'start_video',
        event_label: 'success'
    },
    VIDEO_UNMUTE_INSIDE_CALL_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'start_video',
        event_label: 'failure'
    },
    BACKGROUND_SETTING_INSIDE_CALL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'Change Background',
        event_label: 'success'
    },
    VIDEO_SETTING_INSIDE_CALL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'Video Settings',
        event_label: 'success'
    },
    AUDIO_ONLY_MODE_INSIDE_CALL_ENABLED: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'layout',
        event_label: 'enable_audio_only_mode'
    },
    AUDIO_ONLY_MODE_INSIDE_CALL_ENABLED_BREAKOUT: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'audio_only_mode',
        event_label: 'enable_breakoutroom'
    },
    AUDIO_ONLY_MODE_INSIDE_CALL_DISABLED_BREAKOUT: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'audio_only_mode',
        event_label: 'disable_breakoutroom'
    },
    AUDIO_ONLY_MODE_INSIDE_CALL_DISABLED: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'layout',
        event_label: 'disable_audio_only_mode'
    },
    PARTICIPANT_PANEL_TOGGLE_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel',
        event_label: 'success'
    },
    CHAT_TOGGLE_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'chat',
        event_label: 'success'
    },
    START_WHITEBOARD_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'start_whiteboard_share',
        event_label: 'success'
    },
    STOP_WHITEBOARD_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'stop_whiteboard_share',
        event_label: 'success'
    },
    START_SCREENSHARE_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'start_screen_share',
        event_label: 'success'
    },
    STOP_SCREENSHARE_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'stop_screen_share',
        event_label: 'success'
    },
    STOP_SCREENSHARE_INSIDE_CALL_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'stop_screen_share',
        event_label: 'failure'
    },
    RECORDING_START_INSIDE_CALL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'recording_start',
        event_label: 'success'
    },
    RECORDING_START_INSIDE_CALL_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'recording_start',
        event_label: 'failure'
    },
    RECORDING_STOP_INSIDE_CALL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'recording_stop',
        event_label: 'success'
    },
    RECORDING_STOP_INSIDE_CALL_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'recording_stop',
        event_label: 'failure'
    },
    RAISE_HAND_INSIDE_CALL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'raise_hand',
        event_label: 'success'
    },
    RAISE_HAND_INSIDE_CALL_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'raise_hand',
        event_label: 'failure'
    },
    LOWER_HAND_INSIDE_CALL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'lower_hand',
        event_label: 'success'
    },
    LOWER_HAND_INSIDE_CALL_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'lower_hand',
        event_label: 'failure'
    },
    LIVESTREAM_START_INSIDE_CALL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'live_stream',
        event_label: 'success'
    },
    LIVESTREAM_STOP_INSIDE_CALL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'Live stream stop',
        event_label: 'success'
    },
    LEAVE_MEETING_INSIDE_CALL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'leave_meeting',
        event_label: 'success'
    },
    END_MEETING_INSIDE_CALL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'end_meeting',
        event_label: 'success'
    },
    PARTICIPANT_PANEL_CLICK_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel',
        event_label: 'success'
    },
    PARTICIPANT_PANEL_CLICK_INSIDE_CALL_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel',
        event_label: 'success_breakoutroom'
    },
    BREAKOUT_ROOM_CLICK_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'breakout_room',
        event_label: 'success'
    },
    BREAKOUT_ROOM_CLICK_INSIDE_CALL_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'breakout_room',
        event_label: 'success_breakoutroom'
    },
    ADD_PARTICIPANTS_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'add_participants',
        event_label: 'success'
    },
    FORCE_MUTE_ALL_INSIDE_CALL_APPLY: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'force_mute_all',
        event_label: 'success'
    },
    FORCE_MUTE_ALL_INSIDE_CALL_APPLY_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'force_mute_all',
        event_label: 'success_breakoutroom'
    },
    FORCE_MUTE_ALL_INSIDE_CALL_REMOVE: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'undo_force_mute',
        event_label: 'success'
    },
    FORCE_MUTE_ALL_INSIDE_CALL_REMOVE_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'undo_force_mute',
        event_label: 'success_breakoutroom'
    },
    FORCE_MUTE_ALL_INSIDE_CALL_POPUP_CANCEL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'Force mute all ',
        event_label: 'Cancel'
    },
    FORCE_MUTE_ALL_INSIDE_CALL_REMOVE_POPUP_CANCEL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'Undo Force mute all ',
        event_label: 'Cancel'
    },
    SOFT_MUTE_ALL_INSIDE_CALL_APPLY: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'mute_all',
        event_label: 'success'
    },
    SOFT_MUTE_ALL_INSIDE_CALL_APPLY_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'mute_all',
        event_label: 'success_breakoutroom'
    },
    SOFT_MUTE_ALL_INSIDE_CALL_REMOVE: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'mute all ',
        event_label: 'Cancel'
    },
    LOCK_MEETING_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'lock_meeting',
        event_label: 'success'
    },
    UNLOCK_MEETING_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'unlock_meeting',
        event_label: 'success'
    },
    ENTRY_EXIT_CHIME_ON_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'play_entry-exit_chime',
        event_label: 'enabled'
    },
    ENTRY_EXIT_CHIME_OFF_INSIDE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'play_entry-exit_chime',
        event_label: 'disabled'
    },
    LOWER_ALL_HANDS_PARTICIPANT_PANEL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'lower_all_hands',
        event_label: 'success'
    },
    LOWER_ALL_HANDS_PARTICIPANT_PANEL_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'lower_all_hands',
        event_label: 'success_breakoutroom'
    },
    SHARE_MEETING_PARTICIPANT_PANEL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'share_meeting',
        event_label: 'success'
    },
    SHARE_MEETING_PARTICIPANT_PANEL_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'share_meeting',
        event_label: 'success_breakoutroom'
    },
    FILTER_BY_NAME_PARTICIPANT_PANEL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'filter_by_name',
        event_label: 'success'
    },
    FILTER_BY_NAME_PARTICIPANT_PANEL__BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'filter_by_name',
        event_label: 'success_breakoutroom'
    },
    SORT_BY_NAME_A_Z_PARTICIPANT_PANEL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'sort_by_name(a-z)',
        event_label: 'success'
    },
    SORT_BY_NAME_A_Z_PARTICIPANT_PANEL_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'sort_by_name(a-z)',
        event_label: 'success_breakoutroom'
    },
    SORT_BY_NAME_Z_A_PARTICIPANT_PANEL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'sort_by_name(z-a)',
        event_label: 'success'
    },
    SORT_BY_NAME_Z_A_PARTICIPANT_PANEL__BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'sort_by_name(z-a)',
        event_label: 'success_breakoutroom'
    },
    SORT_BY_CAMERA_STATUS_PARTICIPANT_PANEL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'sort_by_camera_status',
        event_label: 'success'
    },
    SORT_BY_CAMERA_STATUS_PARTICIPANT_PANEL_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'sort_by_camera_status',
        event_label: 'success_breakoutroom'
    },
    SORT_BY_MIC_STATUS_PARTICIPANT_PANEL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'sort_by_mic_status',
        event_label: 'success'
    },
    SORT_BY_MIC_STATUS_PARTICIPANT_PANEL_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'sort_by_mic_status',
        event_label: 'success_breakoutroom'
    },
    PARTICIPANT_PANEL_START_VIDEO: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_ask_for_video',
        event_label: 'success'
    },
    PARTICIPANT_PANEL_START_VIDEO_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_ask_for_video',
        event_label: 'success_breakoutroom'
    },
    PARTICIPANT_PANEL_START_VIDEO_SELF: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_start_video',
        event_label: 'success'
    },
    PARTICIPANT_PANEL_START_VIDEO_SELF_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_start_video',
        event_label: 'success_breakoutroom'
    },
    PARTICIPANT_PANEL_STOP_VIDEO: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_stop_video',
        event_label: 'success'
    },
    PARTICIPANT_PANEL_STOP_VIDEO_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_stop_video',
        event_label: 'success_breakoutroom'
    },
    PARTICIPANT_PANEL_MUTE_MIC: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_mute',
        event_label: 'success'
    },
    PARTICIPANT_PANEL_MUTE_MIC_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_mute',
        event_label: 'success_breakoutroom'
    },
    PARTICIPANT_PANEL_UNMUTE_MIC: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_ask_for_unmute',
        event_label: 'success'
    },
    PARTICIPANT_PANEL_UNMUTE_MIC_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_ask_for_unmute',
        event_label: 'success_breakoutroom'
    },
    PARTICIPANT_PANEL_UNMUTE_SELF: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_unmute',
        event_label: 'success'
    },
    PARTICIPANT_PANEL_UNMUTE_SELF_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_unmute',
        event_label: 'success_breakoutroom'
    },
    PARTICIPANT_PANEL_RAISE_HAND: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_raise_hand',
        event_label: 'success'
    },
    PARTICIPANT_PANEL_RAISE_HAND_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_raise_hand',
        event_label: 'success_breakoutroom'
    },
    PARTICIPANT_PANEL_LOWER_HAND: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_lower_hand',
        event_label: 'success'
    },
    PARTICIPANT_PANEL_LOWER_HAND_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_lower_hand',
        event_label: 'success_breakoutroom'
    },
    ADD_ROOM_BREAKOUT_ROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'add_room',
        event_label: 'success'
    },
    ADD_ROOM_BREAKOUT_ROOM_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'add_room',
        event_label: 'failure'
    },
    RENAME_BREAKOUT_ROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'breakout_room_rename',
        event_label: 'success'
    },
    RENAME_BREAKOUT_ROOM_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'breakout_room_rename',
        event_label: 'failure'
    },
    EDIT_BREAKOUT_ROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'breakout_room_edit',
        event_label: 'success'
    },
    EDIT_BREAKOUT_ROOM_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'breakout_room_edit',
        event_label: 'failure'
    },
    DELETE_BREAKOUT_ROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'breakout_room_delete',
        event_label: 'success'
    },
    DELETE_BREAKOUT_ROOM_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'breakout_room_delete',
        event_label: 'failure'
    },
    ADD_PARTICIPANTS_BREAKOUT_ROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'breakout_room_add_participants',
        event_label: 'success'
    },
    ADD_PARTICIPANTS_BREAKOUT_ROOM_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'breakout_room_add_participants',
        event_label: 'failure'
    },
    MOVE_TO_AUDIENCE: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_move_to_audience',
        event_label: 'success'
    },
    MOVE_TO_AUDIENCE_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_move_to_audience',
        event_label: 'failure'
    },
    MOVE_TO_PARTICIPANT: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_audience_move_to_participant',
        event_label: 'success'
    },
    MOVE_TO_PARTICIPANT_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_audience_move_to_participant',
        event_label: 'failure'
    },
    PARTICIPANT_PANEL_MAKE_COHOST_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_make_co-host',
        event_label: 'success'
    },
    PARTICIPANT_PANEL_MAKE_COHOST_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_make_co-host',
        event_label: 'failure'
    },
    PARTICIPANT_PANEL_REMOVE_AS_COHOST_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_remove_as_co-host',
        event_label: 'success'
    },
    PARTICIPANT_PANEL_REMOVE_AS_COHOST_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_remove_as_co-host',
        event_label: 'failure'
    },
    PARTICIPANT_PANEL_REMOVE_PARTICIPANT_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_remove_participant',
        event_label: 'success'
    },
    PARTICIPANT_PANEL_REMOVE_PARTICIPANT_SUCCESS_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_remove_participant',
        event_label: 'success_breakoutroom'
    },
    PARTICIPANT_PANEL_REMOVE_PARTICIPANT_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_remove_participant',
        event_label: 'failure'
    },
    PARTICIPANT_PANEL_REMOVE_PARTICIPANT_FAIL_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_remove_participant',
        event_label: 'failure_breakout'
    },
    PARTICIPANT_PANEL_REMOVE_AUDIENCE_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_audience_remove',
        event_label: 'success'
    },
    PARTICIPANT_PANEL_REMOVE_AUDIENCE_SUCCESS_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_audience_remove',
        event_label: 'success_breakoutroom'
    },
    PARTICIPANT_PANEL_REMOVE_AUDIENCE_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_audience_remove',
        event_label: 'failure'
    },
    PARTICIPANT_PANEL_REMOVE_AUDIENCE_FAIL_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel_audience_remove',
        event_label: 'failure_breakoutroom'
    },
    START_BREAKOUT_SESSION_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'start_breakout_session',
        event_label: 'success'
    },
    START_BREAKOUT_SESSION_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'start_breakout_session',
        event_label: 'failure'
    },
    END_BREAKOUT_SESSION_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'end_breakout_session',
        event_label: 'success'
    },
    END_BREAKOUT_SESSION_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'end_breakout_session',
        event_label: 'failure'
    },
    HOST_ENTER_BREAKOUT_ROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'enter_breakout_room',
        event_label: 'success'
    },
    LEAVE_MEETING_BREAKOUT_ROOM_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'leave_breakout_room',
        event_label: 'success'
    },
    MIC_MUTE_BREAKOUT_ROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'mute',
        event_label: 'success_breakoutroom'
    },
    MIC_MUTE_BREAKOUT_ROOM_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'mute',
        event_label: 'failure_Breakoutroom '
    },
    MIC_UNMUTE_BREAKOUT_ROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'unmute',
        event_label: 'success_breakoutroom'
    },
    MIC_UNMUTE_BREAKOUT_ROOM_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'unmute',
        event_label: 'failure_breakoutroom'
    },
    VIDEO_MUTE_BREAKOUT_ROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'stop_video',
        event_label: 'success_breakoutroom'
    },
    VIDEO_MUTE_BREAKOUT_ROOM_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'stop_video',
        event_label: 'failure_breakoutroom '
    },
    VIDEO_UNMUTE_BREAKOUT_ROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'start_video',
        event_label: 'success_breakoutroom'
    },
    VIDEO_UNMUTE_BREAKOUT_ROOM_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'start_video',
        event_label: 'failure_breakoutroom '
    },
    PARTICIPANT_PANEL_TOGGLE_BREAKOUT_ROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'participant_panel',
        event_label: 'success_breakoutroom'
    },
    CHAT_TOGGLE_BREAKOUT_ROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'chat',
        event_label: 'success_breakoutroom'
    },
    START_WHITEBOARD_BREAKOUT_ROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'start_whiteboard_share',
        event_label: 'success_breakoutroom'
    },
    STOP_WHITEBOARD_BREAKOUT_ROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'stop_whiteboard_share',
        event_label: 'success_breakoutroom'
    },
    START_SCREENSHARE_BREAKOUT_ROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'start_screen_share',
        event_label: 'success_breakoutroom'
    },
    STOP_SCREENSHARE_BREAKOUT_ROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'stop_screen_share',
        event_label: 'success_breakoutroom'
    },
    STOP_SCREENSHARE_BREAKOUT_ROOM_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'stop_screen_share',
        event_label: 'failure_breakoutroom'
    },
    RAISE_HAND_BREAKOUT_ROOM_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'raise_hand',
        event_label: 'success_breakoutroom'
    },
    RAISE_HAND_BREAKOUT_ROOM_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'raise_hand',
        event_label: 'failure_breakoutroom'
    },
    LOWER_HAND_BREAKOUT_ROOM_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'lower_hand',
        event_label: 'success_breakoutroom'
    },
    LOWER_HAND_BREAKOUT_ROOM_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'lower_hand',
        event_label: 'failure_breakoutroom'
    },
    END_MEETING_BREAKOUT_ROOM_SUCCESS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'End meeting',
        event_label: 'success_breakoutroom'
    },
    CLOSE_CHAT: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'close_chat',
        event_label: 'success'
    },
    CLOSE_CHAT_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'close_chat',
        event_label: 'success_breakoutroom'
    },
    POP_OUT_CHAT: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'pop_out_chat',
        event_label: 'success'
    },
    CALL_FEEDBACK_CLOSE: {
        event: 'jiomeet',
        event_category: 'feedback',
        event_action: 'close',
        event_label: 'success'
    },
    CALL_FEEDBACK_SKIP: {
        event: 'jiomeet',
        event_category: 'feedback',
        event_action: 'skip',
        event_label: 'success'
    },
    CALL_FEEDBACK_POOR_AUDIO_SELECT: {
        event: 'jiomeet',
        event_category: 'feedback',
        event_action: 'poor_audio_quality',
        event_label: 'select'
    },
    CALL_FEEDBACK_POOR_AUDIO_UNSELECT: {
        event: 'jiomeet',
        event_category: 'feedback',
        event_action: 'poor_audio_quality',
        event_label: 'unselect'
    },
    CALL_FEEDBACK_POOR_VIDEO_SELECT: {
        event: 'jiomeet',
        event_category: 'feedback',
        event_action: 'poor_video_quality',
        event_label: 'select'
    },
    CALL_FEEDBACK_POOR_VIDEO_UNSELECT: {
        event: 'jiomeet',
        event_category: 'feedback',
        event_action: 'poor_video_quality',
        event_label: 'unselect'
    },
    CALL_FEEDBACK_SCREEN_SHARE_PROBLEMS_SELECT: {
        event: 'jiomeet',
        event_category: 'feedback',
        event_action: 'screen_share_problems',
        event_label: 'select'
    },
    CALL_FEEDBACK_SCREEN_SHARE_PROBLEMS_UNSELECT: {
        event: 'jiomeet',
        event_category: 'feedback',
        event_action: 'screen_share_problems',
        event_label: 'unselect'
    },
    CALL_FEEDBACK_POOR_NETWORK_CONNECTION_SELECT: {
        event: 'jiomeet',
        event_category: 'feedback',
        event_action: 'poor_network_connection',
        event_label: 'select'
    },
    CALL_FEEDBACK_POOR_NETWORK_CONNECTION_UNSELECT: {
        event: 'jiomeet',
        event_category: 'feedback',
        event_action: 'poor_network_connection',
        event_label: 'unselect'
    },
    CALL_FEEDBACK_SUBMIT_ERROR: {
        event: 'jiomeet',
        event_category: 'feedback',
        event_action: 'submit',
        event_label: 'error'
    },
    CALL_FEEDBACK_SUBMIT_FAILURE: {
        event: 'jiomeet',
        event_category: 'feedback',
        event_action: 'close',
        event_label: 'failure'
    },
    INSIDE_CALL_INTERNET_ERROR_LEAVE: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'internet_error_leave',
        event_label: 'leave'
    },
    INSIDE_CALL_INTERNET_ERROR_CANCEL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'internet_error_leave',
        event_label: 'cancel'
    },
    SETTINGS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'settings',
        event_label: 'success'
    },
    SETTINGS_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'settings',
        event_label: 'success_breakoutroom'
    },
    CHANGE_BACKGROUND: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'change_background',
        event_label: 'success'
    },
    CHANGE_BACKGROUND_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'change_background',
        event_label: 'success_breakoutroom'
    },
    SEND_MESSAGE_IN_CHAT: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'send_message_in_chat',
        event_label: 'success'
    },
    SEND_MESSAGE_IN_CHAT_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'send_message_in_chat',
        event_label: 'success_breakoutroom'
    },
    SEND_MESSAGE_IN_CHAT_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'send_message_in_chat',
        event_label: 'failure'
    },
    SEND_MESSAGE_IN_CHAT_FAIL_breakoutroom: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'send_message_in_chat',
        event_label: 'failure_breakoutroom'
    },
    ADD_NEW_CHANNEL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'live_stream_add_new_channel',
        event_label: 'success'
    },
    CANCEL_LIVE_STREAM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'live_stream_cancel',
        event_label: 'success'
    },
    GO_LIVE: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'live_stream_go_live',
        event_label: 'success'
    },
    GO_LIVE_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'live_stream_go_live',
        event_label: 'failure'
    },
    NETWORK_ICON: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'network_icon',
        event_label: 'success'
    },
    NETWORK_ICON_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'network_icon',
        event_label: 'success_breakoutroom'
    },
    INSIDE_CALL_JOINED_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Inside call',
        event_action: 'Call joined',
        event_label: 'Success'
    },
    SHARE_MEETING_ON_GMAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'share_through_gmail',
        event_label: 'success'
    },
    SHARE_MEETING_ON_OUTLOOK: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'share_through_outlook',
        event_label: 'success'
    },
    SHARE_MEETING_ON_YAHOO: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'share_through_yahoo',
        event_label: 'success'
    },
    CLICK_MORE: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'more',
        event_label: 'success'
    },
    CLICK_MORE_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'more',
        event_label: 'success_breakoutroom'
    },
    CALL_AGAIN_PARTICIPANT_PANEL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'call_again',
        event_label: 'success'
    },
    ENABLE_HIDE_NONVIDEO_PARTICIPANTS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'layout',
        event_label: 'enable_hide_non_video_participants'
    },
    DISABLE_HIDE_NONVIDEO_PARTICIPANTS: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'layout',
        event_label: 'disable_hide_non_video_participants'
    }
};
