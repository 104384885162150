import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { UserService, AppService, AuthService, GaDiagnosticService, GoogleTagManagerService } from 'src/app/core';
import { REG_EX } from 'src/app/constants';
import { TranslateService } from '@ngx-translate/core';
import { GA_EVENTS } from '../ga-events';
import { GA_NEW } from 'src/app/shared/ga-new';

@Component({
    selector: 'app-activate',
    templateUrl: './activate.component.html',
    styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {
    hash;
    email;
    firstName;
    lastName;

    accountInfoForm: any;
    errors: any = {};
    validPassword = false;
    showPassword = false;
    txParams = {
        characters: { value: 8 },
        alphabets: { value: 1 },
        numbers: { value: 1 }
    };

    showDialog = false;
    enableProceedBtn = true;
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private authService: AuthService,
        private appService: AppService,
        private userService: UserService,
        private gaService: GaDiagnosticService,
        private translateService: TranslateService,
        private gTagService: GoogleTagManagerService
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((queryParams) => {
            this.hash = queryParams.hash;
            this.email = queryParams.email;
            this.firstName = queryParams.name;
            this.lastName = queryParams.lname;
        });
        this.accountInfoForm = this.fb.group({
            firstName: [this.firstName, [Validators.required]],
            lastName: [this.lastName, [Validators.required]],
            password: [null, [Validators.required]],
            confirmPassword: [null, [Validators.required]]
        });
        this.accountInfoForm.get('password').valueChanges.subscribe(() => {
            this.validPassword = this.validatePassword(this.accountInfoForm.get('password').value);
        });
        this.gaService.sendPageView({
            page_title: 'activate_page',
            page_path: '/activate'
        });
        this.gTagService.sendEvent(GA_EVENTS.EMAIL_VERIFY_SUCCESS);
        this.gTagService.sendEvent(GA_NEW.EMAIL_VERIFY_SUCCESS);
    }

    validateAccountInfoForm() {
        this.errors = {};
        if (this.accountInfoForm.value.password !== this.accountInfoForm.value.confirmPassword) {
            this.errors.confirmPassword = this.translateService.instant('tostrs.passwords_do_not_match_please_reenter');
            return false;
        }
        if (!REG_EX.NAME.test(this.accountInfoForm.value.firstName)) {
            this.errors.firstName = this.translateService.instant('tostrs.please_enter_a_valid_name');
            return false;
        }
        if (!REG_EX.NAME.test(this.accountInfoForm.value.lastName)) {
            this.errors.lastName = 'Please enter a valid last name';
            return false;
        }
        return true;
    }

    submit() {
        if (this.accountInfoForm.valid && this.validPassword) {
            if (this.validateAccountInfoForm()) {
                if ((this.accountInfoForm.value.password || '').indexOf(' ') > -1) {
                    this.errors.global = this.translateService.instant(
                        'tostrs.password_should_be_of_minimum_length_8_and_should_contains_atleast_1_uppercase_1_lowercase_1_number_and_1_special_character'
                    );
                    return;
                }
                this.enableProceedBtn = false;
                this.userService
                    .setOTTPassword(
                        this.accountInfoForm.get('password').value,
                        this.hash,
                        this.accountInfoForm.get('firstName').value,
                        this.accountInfoForm.get('lastName').value
                    )
                    .subscribe(
                        () => this.login(),
                        (err) => {
                            this.errors.global = err.error.message;
                            this.enableProceedBtn = true;
                        }
                    );
            }
        }
    }

    login() {
        this.authService
            .login({
                email: this.email,
                password: this.accountInfoForm.get('password').value
            })
            .subscribe(
                () =>
                    this.userService.getUser().subscribe(() => {
                        this.appService.getConfigVariable('SHOW_SIGNUP_BANNER')
                            ? (this.showDialog = true)
                            : this.goToOnboarding();
                    }),
                (err) => {
                    this.errors.global = err.error.message;
                    this.enableProceedBtn = true;
                }
            );
    }

    goToOnboarding() {
        this.router.navigate(['/onboarding']);
    }

    launchTnc() {
        window.open(this.appService.getEnvVariable('HOST_URL') + 'terms-condition-business');
    }

    launchPrivacy() {
        window.open(this.appService.getEnvVariable('HOST_URL') + 'privacy-policy-business');
    }

    validatePassword(password) {
        password = (password || '').trim();
        this.errors.password = '';
        const lengthRegex = new RegExp('(?=.{8,})');
        this.errors.password += lengthRegex.exec(password) ? '' : 'lengthError';

        const numberRegex = new RegExp('(?=.*?[0-9])');

        this.errors.password += numberRegex.exec(password) ? '' : 'numberError';

        const alphabetRegex = new RegExp('(?=.*[a-zA-Z])');
        this.errors.password += alphabetRegex.exec(password) ? '' : 'alphabetError';

        const dualRegex = new RegExp('(?=.*?[A-Z])(?=.*?[a-z])');
        this.errors.password += dualRegex.exec(password) ? '' : 'dualError';

        const specialCharsRegex = new RegExp('[!@#$%^&*]');
        this.errors.password += specialCharsRegex.exec(password) ? '' : 'specialCharsError';

        // for non repeating case
        const unique = new Set(password.split(''));
        this.errors.password += unique.size === 1 ? 'repeatingError' : '';

        // for consecutive case
        const alphabet = 'abcdefghijklmnopqrstuvwxyz';
        const number = '0123456789';
        this.errors.password += alphabet.includes(password) || number.includes(password) ? 'consecutiveError' : '';
        return !!this.errors.password ? false : true;
    }
    togglePassword() {
        this.showPassword = !this.showPassword;
    }
}
