import { Pipe, PipeTransform } from '@angular/core';
import { AppService, UserService, UtilService, RestService, SystemService } from 'src/app/core';
import { HttpHeaders } from '@angular/common/http';
import { JiocloudAuthService } from 'src/app/core/services/jiocloud-auth.service';

@Pipe({
    name: 'jioCloudIageAuth'
})
export class JioCloudIageAuthPipe implements PipeTransform {
    constructor(
        private appService: AppService,
        private userService: UserService,
        private utilService: UtilService,
        private jiocloudAuthService: JiocloudAuthService,
        private restService: RestService,
        private systemService: SystemService
    ) {}

    async transform(src: string, jiomeetId: string, size: string): Promise<string> {
        if (!src) {
            return null;
        }
        const meetingId = jiomeetId;
        const currentTimeInMills = +new Date() - +new Date('1970-01-01');
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.set('X-User-Id', this.userService.getUserSync().jcData.jcId);
        headers = headers.set('X-Device-Key', this.jiocloudAuthService?.deviceID);
        headers = headers.set(
            'X-Request-Hash',
            this.utilService.getSHA256(
                `${this.userService.getUserSync().jcData.jcId}#${meetingId}#${currentTimeInMills}`,
                this.jiocloudAuthService.getAuthInfo().authToken.sessionToken
            )
        );
        headers = headers.set('X-Meeting-Id', meetingId);
        if (!this.appService.getEnvVariable('local')) {
            headers = headers.set('origin', `${this.appService.getEnvVariable('HOST_URL')?.slice(0, -1)}`);
        }
        headers = headers.set('X-Current-Timestamp', `${currentTimeInMills}`);
        headers = headers.set(
            'Authorization',
            `Basic ${window.btoa(this.jiocloudAuthService.getAuthInfo().authToken.accessToken)}`
        );
        headers = headers.set('X-API-Key', `${this.appService.getConfigVariable('JIO_CLOUD_API_KEY')}`);
        headers = headers.set('X-App-Secret', `${this.appService.getConfigVariable('JIO_CLOUD_APP_SECRET')}`);

        if (this.appService.getEnvVariable('local')) {
            const url = new URL(src);
            src = url.pathname;
        }

        const imageBlob = await this.restService
            .get(`${src}${'?size='}${size}`, { headers, responseType: 'blob' }, false)
            .toPromise()
            .catch((err) => {
                console.error('error:', err);
                throw err;
            });
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(imageBlob);
        });
    }
}
