import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DesktopAppService } from 'src/app/core';
import { JioCloudUtilService } from 'src/app/core/services/jio-cloud-util.service';

@Component({
    selector: 'app-upload-attachment',
    templateUrl: './upload-attachment.component.html',
    styleUrls: ['./upload-attachment.component.scss']
})
export class UploadAttachmentComponent implements OnInit {
    @Input() item;
    @Output() remove = new EventEmitter();
    @Input() isOutsideCall?: Boolean;
    thumbnailUrl;
    docType;
    playIcon;
    fileType: 'Image' | 'Doc' | 'Video' | 'Audio' | 'None' = 'None';
    constructor(private jioCloudUtilService: JioCloudUtilService, public desktopAppService: DesktopAppService) {}

    ngOnInit() {
        if (!this.item) {
            this.fileType = this.jioCloudUtilService.getFileFormat(this.item.name);
            if (this.fileType === 'Doc') {
                this.docType = this.jioCloudUtilService.getDocType(this.item.name);
            }
            this.thumbnailUrl = this.jioCloudUtilService.getDefaultThumbnailForFile(this.fileType);
        }
    }

    removeAttchment() {
        this.remove.emit(this.item);
    }
}
