import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppService } from 'src/app/core';
import { Router } from '@angular/router';
import { GaDiagnosticService } from 'src/app/core/services/ga-diagnostic.service';

@Component({
    selector: 'app-verification',
    templateUrl: './verification.component.html',
    styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {
    changePasswordForm: any;
    errors: any = {};

    constructor(
        private fb: FormBuilder,
        private appService: AppService,
        private router: Router,
        private gaService: GaDiagnosticService
    ) {}

    ngOnInit() {
        this.changePasswordForm = this.fb.group({
            newPassword: [null, [Validators.required]],
            confirmPassword: [null, [Validators.required]]
        });
        this.gaService.sendPageView({ page_title: 'verification_page', page_path: '/verification' });
    }

    launchTnc() {
        window.open(this.appService.getEnvVariable('HOST_URL') + 'terms-condition-business');
    }

    launchPrivacy() {
        window.open(this.appService.getEnvVariable('HOST_URL') + 'privacy-policy-business');
    }
}
