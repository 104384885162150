import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ExternalIntegrationService {
    fromAuth = false;

    constructor() {}

    loadScript(url): HTMLScriptElement {
        const node = document.createElement('script');
        node.src = url;
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
        return node;
    }

    isScriptLoaded(url) {
        if (document.querySelector(`script[src="${url}"]`)) {
            return true;
        }
        return false;
    }
}
