import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AppService, ValidationService, UserService } from 'src/app/core';

@Component({
    selector: 'app-website-config',
    templateUrl: './website-config.component.html',
    styleUrls: ['./website-config.component.scss']
})
export class WebsiteConfigComponent implements OnInit {
    toggleView: boolean = false;
    isMobile = false;

    websiteMetaForm: FormGroup;

    activeMetaForm: FormGroup;
    submitted = false;
    activeTab = 1;
    error;
    calenderEndDate = new Date();
    constructor(private fb: FormBuilder, private toastrService: ToastrService, private userService: UserService) {}

    ngOnInit() {
        this.websiteMetaForm = this.fb.group({
            partners: new FormArray([]),
            milestones: this.fb.group({
                downloads: ['', Validators.required],
                meetingMinutes: ['', [Validators.required]],
                webinarMinutes: ['', [Validators.required]]
            }),
            storeComments: new FormArray([])
        });

        this.activeMetaForm = this.websiteMetaForm;

        //fetch existing meta info
        this.getMetaInfo();
    }

    getMetaInfo() {
        this.userService.getWebsiteMetaInfo().subscribe(
            (res) => {
                if (res) {
                    // prefil the form
                    //1. prefill partners info
                    res.partners.map((partnerInfo) => {
                        this.onAddFormGroup('partners', partnerInfo);
                    });

                    //2. set milestones info
                    if (res.milestones) {
                        this.activeMetaForm.get('milestones').setValue(res.milestones);
                    }

                    //3. set storeComments
                    res.storeComments.map((commentInfo) => {
                        this.onAddFormGroup('storeComments', commentInfo);
                    });
                } else {
                    this.onAddFormGroup('partners');
                    this.onAddFormGroup('storeComments');
                }
            },
            (err) => {
                this.error = err?.error?.message;
            }
        );
    }

    get f() {
        return this.activeMetaForm.controls;
    }

    get partners() {
        return this.f.partners as FormArray;
    }

    get milestones() {
        return this.f.milestones;
    }

    get storeComments() {
        return this.f.storeComments as FormArray;
    }

    onFileChange(event, index) {
        if (
            event.target.files[0] &&
            !(
                event.target.files[0].type === 'image/jpeg' ||
                event.target.files[0].type === 'image/jpg' ||
                event.target.files[0].type === 'image/png'
            )
        ) {
            this.toastrService.error('Please select a jpg/jpeg or png image file ');
            return;
        }
        if (event.target.files[0] && event.target.files[0].size / 1024 / 1024 >= 2) {
            this.toastrService.error('File size cannot exceed 2MB');
            return;
        }

        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const partnersControls = this.f.partners as FormArray;
            partnersControls.controls[index].patchValue({
                fileSource: file
            });
        }
    }

    onAddFormGroup(type: string, typeInfo: any = {}) {
        switch (type) {
            case 'partners': {
                const partnerControl = this.f.partners as FormArray;
                partnerControl.push(
                    this.fb.group({
                        name: [typeInfo.name ?? '', Validators.required],
                        logo: ['', Validators.required],
                        fileSource: ['', Validators.required]
                    })
                );
                break;
            }
            case 'storeComments': {
                const storeComments = this.f.storeComments as FormArray;
                storeComments.push(
                    this.fb.group({
                        comment: [typeInfo.comment ?? '', Validators.required],
                        name: [typeInfo.name ?? '', Validators.required],
                        rating: [{ name: typeInfo.rating, value: typeInfo.rating } ?? '', Validators.required],
                        dateSource: [typeInfo.date ? new Date(typeInfo.date) : '', Validators.required],
                        date: ['', Validators.required]
                    })
                );
                break;
            }
            default:
                break;
        }
    }

    onRemoveFormGroup(type: string, index: number) {
        switch (type) {
            case 'partners': {
                const partnerControl = this.f.partners as FormArray;

                partnerControl.removeAt(index);
            }
            case 'storeComments': {
                const storeComments = this.f.storeComments as FormArray;
                storeComments.removeAt(index);
            }
        }
    }

    uploadImageFile(files) {
        return new Promise((resolve, rejet) => {
            const formData = new FormData();
            formData.append('files', files);

            // this.progress.emit(true);

            this.userService.uploadPartnersLogo(formData).subscribe(
                (res: any) => {
                    if (res.success) {
                        // this.progress.emit(false);
                        resolve(res);
                    } else {
                        this.toastrService.error('Failed to update profile picture.');
                        rejet(res);
                    }
                    // this.progress.emit(false);
                },
                (err) => {
                    // this.progress.emit(false);
                    rejet(err);
                    console.log(err);
                }
            );
        });
    }

    async checkAndUploadFile() {
        // check if partner logo has to be uploaded
        const partnersLogo = this.f.partners.value;
        const files = [];
        for (let i = 0; i < partnersLogo.length; i++) {
            if (partnersLogo[i].fileSource) {
                files.push(partnersLogo[i].fileSource);
            }
        }

        // const res = await this.uploadImageFile(files);
        // return res;
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.activeMetaForm.invalid) {
            return;
        }

        // check if any partner logo has to be uploaded
        this.checkAndUploadFile();
        // prepare the request payload
        const { ...payload } = this.activeMetaForm.value;
        // payload.milestones = this.activeMetaForm.value.milestones[0];
        payload.storeComments = payload.storeComments.map((commentInfo) => {
            commentInfo.rating = commentInfo.rating.value;
            return commentInfo;
        });

        this.userService.updateWebsiteMetaInfo(payload).subscribe(
            (res) => {
                this.submitted = true;
            },
            (err) => {
                this.error = err?.error?.message;
            }
        );
    }

    selectDate(date, index) {
        const storeComments = this.f.storeComments as FormArray;
        storeComments.controls[index].patchValue({
            date: new Date(date).toISOString()
        });
    }
}
