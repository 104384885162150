<div class="website-download website-usecases" id="website-top">
    <section class="section-top" appForceFullWidth [class.hindi]="currentLang == 'hi'"></section>

    <ng-container *ngIf="isMobile">
        <section *ngFor="let feature of downloadableApps; let i = index" class="sec-features">
            <app-usecase-features [useCaseFeature]="feature" [featureIndex]="i">
                <div class="action-btns">
                    <ng-container *ngFor="let platform of feature.appTypes">
                        <button class="custom-action-btn" (click)="handlePlatformClick(platform.os)">
                            {{ platform.ttitle | translate }}
                        </button>
                    </ng-container>
                </div>
            </app-usecase-features>
        </section>
        <section class="add-ins-container">
            <h2 class="heading">{{ 'website_download.add_ins' | translate }}</h2>
            <section *ngFor="let feature of addIns; let i = index" class="sec-add-ins">
                <img [src]="feature.imgMobile" class="padding-h-16" alt="" />
                <app-usecase-features [useCaseFeature]="feature" [featureIndex]="i">
                    <div class="action-btns">
                        <button
                            class="custom-action-btn"
                            *ngFor="let platform of feature.appTypes"
                            (click)="handlePlatformClick(platform.os)"
                        >
                            {{ platform.ttitle | translate }}
                        </button>
                    </div>
                </app-usecase-features>
            </section>
        </section>
    </ng-container>

    <ng-container *ngIf="!isMobile">
        <ng-container *ngFor="let feature of downloadableApps">
            <section class="sec padding-h" [id]="feature.id">
                <app-usecase-features [useCaseFeature]="feature" [featureIndex]="2">
                    <div class="action-btns">
                        <ng-container *ngFor="let platform of feature.appTypes">
                            <button class="custom-action-btn" (click)="handlePlatformClick(platform.os)">
                                {{ platform.ttitle | translate }}
                            </button>
                        </ng-container>
                    </div>
                </app-usecase-features>
            </section>
        </ng-container>
        <section class="add-ins-container padding-h">
            <h2 class="heading">{{ 'website_download.add_ins' | translate }}</h2>
            <div class="sec-add-ins">
                <section *ngFor="let feature of addIns; let i = index">
                    <img [src]="feature.img" alt="" />
                    <app-usecase-features [useCaseFeature]="feature" [featureIndex]="i">
                        <div class="action-btns">
                            <button
                                class="custom-action-btn"
                                *ngFor="let platform of feature.appTypes"
                                (click)="handlePlatformClick(platform.os)"
                            >
                                {{ platform.ttitle | translate }}
                            </button>
                        </div>
                    </app-usecase-features>
                </section>
            </div>
        </section>
    </ng-container>

    <section class="tips-updates" [class.padding-h-44]="isMobile" [class.sec-padding-h]="!isMobile">
        <app-jiomeet-subscribe [subscribeInfo]="JioMeetDownload"></app-jiomeet-subscribe>
    </section>
</div>
