<div *ngIf="!showRDPPermissionPopup && !showScreenSharePermissionPopup" class="permissionDeniedDialog">
    <img class="closeIcon" src="assets/img/icon_search_close.svg" alt="Close icon" (click)="closePermissiondialog()" />
    <div class="permissionInfo" [class.permissionInfoMobile]="isMobileDevice">
        <h4 *ngIf="!isMobileDevice" class="permissionHeading">Camera and Microphone access blocked</h4>
        <div *ngIf="isMobileDevice" class="permissionHeadingMobile">Camera and Microphone access blocked</div>
        <div *ngIf="!isMobileDevice" class="permissionSubHeading">
            You need to allow access to your camera and mic on browser
        </div>
        <div *ngIf="isMobileDevice" class="permissionSubHeadingMobile">
            You need to allow access to your camera and mic on browser
        </div>
        <h5 *ngIf="!isMobileDevice">Try one of these steps to resolve this:</h5>
        <div *ngIf="!isMobileDevice" class="pageNumber">{{ pageNumber }}</div>
        <div class="permissionSteps" *ngIf="pageNumber === 1 && !isMobileDevice">
            Click on the lock icon <img src="assets/img/lock_icon.png" alt="lock icon" /> and toggle ON camera and
            microphone <br />
            <img class="stepsImage" src="assets/img/permission_steps.png" alt="permission steps" />
        </div>
        <div class="permissionSteps permissionStepsMobile" *ngIf="pageNumber === 1 && isMobileDevice">
            <img class="stepsImage" src="assets/img/mobile_permission_step_first.png" alt="permission steps" />
            Click on the lock icon <img src="assets/img/lock_icon.png" alt="lock icon" /><br />and toggle ON camera and
            microphone <br />
        </div>
        <div class="permissionSteps permissionStepsMobile" *ngIf="pageNumber === 2 && isMobileDevice">
            <img class="stepsImage" src="assets/img/mobile_permission_step_second.png" alt="permission steps" />
            Click on the lock icon <img src="assets/img/lock_icon.png" alt="lock icon" /> <br />and toggle ON camera and
            microphone <br />
        </div>
        <div class="permissionSteps" *ngIf="pageNumber === 2 && !isMobileDevice">
            Allow camera and mic permission for your browser in system preferences.
            <div class="osTabs">
                <span [class.tab]="tabNumber === 1" (click)="toggleTab(1)" [ngStyle]="{ cursor: 'pointer' }">Mac</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span [class.tab]="tabNumber === 2" (click)="toggleTab(2)" [ngStyle]="{ cursor: 'pointer' }"
                    >Windows</span
                >
            </div>
            <div class="imageWrapper">
                <img
                    class="changeImageButton"
                    src="assets/img/arrow_left_active.svg"
                    alt=""
                    *ngIf="pageInsideTab === 2"
                    (click)="changePageInsideTab(-1)"
                />
                <img
                    class="changeImageButton"
                    src="assets/img/arrow_left_disabled.svg"
                    alt=""
                    *ngIf="pageInsideTab === 1"
                />
                <img
                    src="assets/img/mac_permission_2.png"
                    alt="os permission steps"
                    *ngIf="tabNumber === 1 && pageInsideTab === 1"
                />
                <img
                    src="assets/img/apple_mic.png"
                    alt="os permission steps"
                    *ngIf="tabNumber === 1 && pageInsideTab === 2"
                />
                <img
                    src="assets/img/windows_permission_2.png"
                    alt="os permission steps"
                    *ngIf="tabNumber === 2 && pageInsideTab === 1"
                />
                <img
                    src="assets/img/windows_mic.png"
                    alt="os permission steps"
                    *ngIf="tabNumber === 2 && pageInsideTab === 2"
                />
                <img
                    class="changeImageButton"
                    src="assets/img/arrow_right_active.svg"
                    alt=""
                    *ngIf="pageInsideTab === 1"
                    (click)="changePageInsideTab(1)"
                />
                <img
                    class="changeImageButton"
                    src="assets/img/arrow_right_disabled.svg"
                    alt=""
                    *ngIf="pageInsideTab === 2"
                />
            </div>
            <div class="bottomBarsWrapper">
                <span class="bottomBars" [class.active]="pageInsideTab === 1"></span>
                <span class="bottomBars" [class.active]="pageInsideTab === 2"></span>
            </div>
        </div>
        <div class="permissionSteps" *ngIf="pageNumber === 3 && !isMobileDevice">
            Close other apps those may be using camera and mic already
            <img src="assets/img/other_apps.png" alt="os permission steps" />
        </div>
        <div class="permissionSteps" *ngIf="pageNumber === 4 && !isMobileDevice">
            Clear site cache and refresh this tab
            <img src="assets/img/refresh_tabs.png" alt="os permission steps" />
        </div>
    </div>
    <div *ngIf="isMobileDevice" class="bottomNumberMobile">1/{{ pageNumber }}</div>
    <div *ngIf="!isMobileDevice" class="bottomNumber">{{ pageNumber }}/4</div>
    <div *ngIf="!isMobileDevice" class="mt-3 mb-3">
        <button
            class="custom-button bg-secondary pageTogglebuttons"
            (click)="decreasePageNumber()"
            [class.disabled]="pageNumber === 1"
        >
            Previous</button
        >&nbsp;&nbsp;<button
            class="custom-button bg-primary pageTogglebuttons"
            (click)="increasePageNumber()"
            [class.disabled]="(isMobileDevice && pageNumber === 2) || (!isMobileDevice && pageNumber === 4)"
        >
            Next
        </button>
    </div>
    <div *ngIf="isMobileDevice" class="mt-3 mb-3 bottomButtomSectionMobile">
        <button
            class="custom-button bg-primary pageTogglebuttonsMobile"
            (click)="increasePageNumber()"
            [class.disabled]="(isMobileDevice && pageNumber === 2) || (!isMobileDevice && pageNumber === 4)"
        >
            Next
        </button>

        <button
            class="custom-button bg-secondary pageTogglebuttonsMobile"
            (click)="decreasePageNumber()"
            [class.disabled]="pageNumber === 1"
        >
            Previous
        </button>
    </div>
</div>
<div class="permissionMask"></div>

<div *ngIf="showRDPPermissionPopup" class="permissionDeniedDialog rdp-permissions">
    <img
        class="closeIcon"
        src="assets/img/icon_search_close.svg"
        alt="Close icon"
        (click)="closeRDPPermissiondialog()"
    />
    <div class="permission-header">Access to accessibility is not given</div>
    <div class="permission-info">
        Please add Jiomeet to Accessibility Settings in your Mac System to give remote user access to operate your
        screen.
    </div>
    <div class="permission-steps">
        Steps to give an APP access to accessibility in settings:
        <div class="steps">
            System Preferences -> Security & Privacy -> Unlock Settings -> Select Accessibility -> Enable the App ->
            Grant Access -> Lock Settings
        </div>
    </div>
</div>

<div *ngIf="showScreenSharePermissionPopup" class="permissionDeniedDialog rdp-permissions">
    <img
        class="closeIcon"
        src="assets/img/icon_search_close.svg"
        alt="Close icon"
        (click)="closeRDPPermissiondialog()"
    />
    <div class="permission-header">Access to Screen share is not given</div>
    <div class="permission-info">
        Please add Jiomeet to Screen recording settings in your Mac System to get screen sharing access.
        <div class="permission-steps">
            Steps to give an APP access to screen sharing in settings:
            <div class="steps">
                System Preferences -> Security & Privacy -> Screen Recording -> Click + button to add JioMeet-lite app
                -> Enable the App -> Grant Access
            </div>
        </div>
    </div>
</div>
