import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DeepLinkService } from 'src/app/core/services/deep-link.service';
import {
    UtilService,
    UserService,
    MeetingService,
    SystemService,
    ThirdPartyExternalIntegrationService,
    AuthService,
    DiagnosticService,
    DownloadAppService
} from 'src/app/core';

@Component({
    selector: 'app-downloads-page',
    templateUrl: './downloads-page.component.html',
    styleUrls: ['./downloads-page.component.scss']
})
export class DownloadsPageComponent implements OnInit, OnDestroy {
    supported = false;
    currentOS: 'Windows' | 'Mac OS' | 'Other';
    params = '';
    meetingId;
    hash;
    pwd;
    isMobileOS;
    isIOS;
    iosMacDeeplink;
    isHosting = false;
    currentUser;
    enableJoinWithBrowser = false;
    subscriptions: Subscription[] = [];
    type: 'shortener' | 'addtojiomeet' | 'recents';
    browser;
    showCompatibilityMessage = true;
    isWebinar: boolean = false;
    loading: boolean = true;
    redirectToJioConf: boolean = false;
    isThirdPartyExternalIntegration;
    isAuthenticated: any;

    constructor(
        private utilService: UtilService,
        private activatedRoute: ActivatedRoute,
        private deepLinkService: DeepLinkService,
        private downloadAppService: DownloadAppService
    ) {}

    ngOnInit() {
        // this.subscriptions.push(
        //   this.activatedRoute.data.subscribe((route) => {
        //     this.type = route.type === 'join' ? 'shortener' : 'addtojiomeet';
        //     this.deepLinkService.handleDeepLink(this.activatedRoute);
        //   })
        // );

        this.initialize();
    }

    initialize() {
        this.browser = this.deepLinkService.browser;
        this.enableJoinWithBrowser = this.deepLinkService.enableJoinWithBrowser;
        this.redirectToJioConf = this.deepLinkService.redirectToJioConf;
        this.currentUser = this.deepLinkService.currentUser;
        this.supported = this.deepLinkService.supported;
        this.currentOS = this.deepLinkService.currentOS;
        this.showCompatibilityMessage = this.deepLinkService.showCompatibilityMessage;
        this.isWebinar = this.deepLinkService.isWebinar;
    }

    downloadJioMeet() {
        this.downloadAppService.downloadJioMeet();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
