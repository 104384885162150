import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { APP_EVENTS } from 'src/app/constants';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import {
    AppService,
    RtcService,
    DesktopAppService,
    DesktopAppUpdaterService,
    EventEmitterService,
    EventData
} from 'src/app/core';

@Component({
    selector: 'app-electron-sticky-updater',
    templateUrl: './electron-sticky-updater.component.html',
    styleUrls: ['./electron-sticky-updater.component.scss']
})
export class ElectronStickyUpdaterComponent implements OnInit {
    public isUpdateAvailable: any = false;
    public hideStickyBar = false;
    @Output() close: EventEmitter<any> = new EventEmitter();
    public CHECK_UPDATE_INTERVAL; // 30 MINS
    private forceUpgrade = false;
    private forceUpdateFromConfig = false;
    constructor(
        public desktopAppUpdaterService: DesktopAppUpdaterService,
        private desktopAppService: DesktopAppService,
        private appService: AppService,
        public rtcService: RtcService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private eventEmitterService: EventEmitterService,
        private confirmationService: ConfirmationService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.desktopAppUpdaterService.startCheckForUpdates();
        this.CHECK_UPDATE_INTERVAL = this.appService.getConfigVariable('checkForUpdaterInterval') || 1800000; // timer to trigger check for electron app update
        this.checkForUpdates();
        var _this = this;

        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.UPDATE_CHECK_EVENT && !this.rtcService.getIsConferenceActive()) {
                console.log('update check event triggred');
                this.desktopAppService.appStoreDistributionBuild
                    ? this.checkMacStoreUpdates()
                    : _this.desktopAppUpdaterService.startCheckForUpdates();
            }
        });

        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                if (event.url.substring(0, 5) !== '/chat') {
                    this.hideStickyBar = false;
                } else {
                    this.hideStickyBar = true;
                }
            });
    }

    checkForUpdates() {
        let supportedVersion =
            this.appService
                .getConfigVariable('SupportedVersions')
                ?.includes(this.desktopAppService.electronAppVersion) || false;
        this.forceUpdateFromConfig = this.appService.getConfigVariable('forceUpgrade') || false;
        this.forceUpgrade = this.forceUpdateFromConfig || !supportedVersion;
        if (this.desktopAppService.appStoreDistributionBuild) {
            this.checkMacStoreUpdates();
        } else {
            this.desktopAppUpdaterService.updateAvailable((event, obj) => {
                console.log('update status' + JSON.stringify(obj));
                this.isUpdateAvailable = obj.updateAvailable;
                if (this.forceUpgrade && this.isUpdateAvailable && !this.rtcService.getIsConferenceActive()) {
                    this.showAppUpdatePopup();
                }
            });
        }
    }

    checkMacStoreUpdates() {
        let macStoreVersion = this.appService.getConfigVariable('MacStoreVersion') || '0.0.0';
        this.isUpdateAvailable = macStoreVersion > this.desktopAppService.electronAppVersion;
        if (this.isUpdateAvailable && this.forceUpdateFromConfig) {
            this.showMacStoreForceUpdatePopUp(true);
        }
    }

    showMacStoreForceUpdatePopUp(forceUpdate = false) {
        this.desktopAppService.showElectronMacStoreUpdatePopup = true;
        this.translate.get(['mac_store_update_popup']).subscribe((translations) => {
            this.confirmationService.confirm({
                message: translations.mac_store_update_popup.text,
                header: translations.mac_store_update_popup.title,
                acceptLabel: translations.mac_store_update_popup.rejectLabel,
                rejectLabel: forceUpdate
                    ? translations.mac_store_update_popup.forceAcceptLabel
                    : translations.mac_store_update_popup.acceptLabel,
                acceptButtonStyleClass: 'custom-button bg-secondary',
                rejectButtonStyleClass: 'custom-button bg-primary',
                acceptVisible: !forceUpdate,
                dismissableMask: false,
                closeOnEscape: !forceUpdate,
                accept: () => {
                    this.desktopAppService.showElectronMacStoreUpdatePopup = false;
                },
                reject: () => {
                    this.desktopAppService.showElectronMacStoreUpdatePopup = false;
                    let macStoreUpdateLink = this.appService.getConfigVariable('MacStoreUpdateLink') || '';
                    window.open(macStoreUpdateLink, '_blank');
                }
            });
        });
    }

    showAppUpdatePopup() {
        if (this.desktopAppService.appStoreDistributionBuild) {
            this.showMacStoreForceUpdatePopUp();
        } else {
            this.desktopAppUpdaterService.showElectronUpdatePopup = !!this.desktopAppService.isDesktopApp;
        }
    }

    cancel() {
        this.desktopAppUpdaterService.showElectronUpdatePopup = !this.desktopAppService.isDesktopApp;
    }
}
