import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { APP_EVENTS } from 'src/app/constants';
import { EventEmitterService, UtilService, ThirdPartyExternalIntegrationService } from 'src/app/core';

@Component({
    selector: 'app-permission-denied',
    templateUrl: './permission-denied.component.html',
    styleUrls: ['./permission-denied.component.scss']
})
export class PermissionDeniedComponent implements OnInit {
    @Input() showRDPPermissionPopup;
    @Input() showScreenSharePermissionPopup;
    @Output() closeRDPPermissionPopup = new EventEmitter();

    pageNumber = 1;
    tabNumber = 1;
    pageInsideTab = 1;
    isThirdPartyExternalIntegration;
    isMobileDevice = false;
    constructor(
        private eventEmitterService: EventEmitterService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        public utilService: UtilService
    ) {}

    ngOnInit(): void {
        this.isThirdPartyExternalIntegration =
            this.thirdPartyExternalIntegrationService.getThirdPartyExternalIntegration();
        this.isMobileDevice = this.isThirdPartyExternalIntegration || this.utilService.isMobileBrowser();
    }

    closePermissiondialog() {
        this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: false });
    }

    closeRDPPermissiondialog() {
        this.closeRDPPermissionPopup.emit(false);
    }

    increasePageNumber() {
        this.pageNumber = this.pageNumber + 1;
        if (this.pageNumber === 2) {
            this.tabNumber = 1;
            this.pageInsideTab = 1;
        }
    }

    decreasePageNumber() {
        this.pageNumber = this.pageNumber - 1;
        if (this.pageNumber === 2) {
            this.tabNumber = 1;
            this.pageInsideTab = 1;
        }
    }

    toggleTab(tabNumber) {
        this.tabNumber = tabNumber;
        this.pageInsideTab = 1;
    }

    changePageInsideTab(value) {
        this.pageInsideTab = this.pageInsideTab + value;
    }
}
