import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'systemChatMessageLabel'
})
export class SystemChatMessageLabelPipe implements PipeTransform {
    currentLanguage;
    constructor(private translateService: TranslateService) {}

    transform(value: any, args?: any): any {
        if (!value) {
            return null;
        } else if (value === 'Meeting recording has started.') {
            return this.translateService.instant('inside_call.chat.system_messages.recording_started');
        } else {
            return this.translateService.instant('inside_call.chat.system_messages.recording_stopped');
        }
    }
}
