import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from 'src/app/core';

@Component({
    selector: 'app-compare-edition',
    templateUrl: './compare-edition.component.html',
    styleUrls: ['./compare-edition.component.scss']
})
export class CompareEditionComponent implements OnInit {
    isMobile = false;
    @Input() featureDataInput: any = {};
    constructor(private utilService: UtilService) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser(1080);
    }
}
