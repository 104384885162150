<div class="customer-feedback-component" [class.mb-0]="feedbackSubmited">
    <div class="container">
        <div class="layout" *ngIf="!feedbackSubmited">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-space">
                    <div class="page-header">
                        <h1>{{ 'customer-feedback.head' | translate }}</h1>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 col-space" *ngIf="!isMobile">
                    <div class="feedback-img">
                        <img src="assets/img/feedback.svg" alt="Customer Feedback" />
                    </div>
                </div>
            </div>
            <div class="bottom-container">
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-space">
                        <form [formGroup]="customerFeedbackForm" class="form">
                            <div class="form-group">
                                <label for="inputName"
                                    >{{ 'customer-feedback.full' | translate }} {{ 'customer-feedback.name' | translate
                                    }}<em>*</em></label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    id="inputName"
                                    formControlName="userName"
                                    (keypress)="checkCharLimit(200, $event)"
                                    [class.err]="errors.name"
                                />
                                <div class="error" *ngIf="errors.name">
                                    {{ errors.name }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="inputNumber"
                                    >{{ 'customer-feedback.phone_number' | translate }}<em>*</em></label
                                >
                                <div class="phone-number-container">
                                    <div class="phone-prefix">
                                        <input type="text" value="+91" readonly [class.err]="errors.phone" />
                                    </div>
                                    <div class="phone-number">
                                        <input
                                            type="text"
                                            id="inputNumber"
                                            formControlName="phoneNo"
                                            (input)="checkNumber($event)"
                                            maxlength="10"
                                            [class.err]="errors.phone"
                                        />
                                    </div>
                                </div>
                                <div class="error" *ngIf="errors.phone">
                                    {{ errors.phone }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="inputEmail"
                                    >{{ 'customer-feedback.email_address' | translate }}<em>*</em></label
                                >
                                <input
                                    type="email"
                                    class="form-control"
                                    id="inputEmail"
                                    formControlName="emailId"
                                    [class.err]="errors.email"
                                />
                                <div class="error" *ngIf="errors.email">
                                    {{ errors.email }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="inputCompany"
                                    >{{ 'customer-feedback.company' | translate }}
                                    {{ 'customer-feedback.name' | translate }}<em>*</em></label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    id="inputCompany"
                                    formControlName="companyName"
                                    (keypress)="checkCharLimit(1000, $event)"
                                    [class.err]="errors.company"
                                />
                                <div class="error" *ngIf="errors.company">
                                    {{ errors.company }}
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="inputFeedback"
                                    >{{ 'customer-feedback.your_feedback' | translate }}<em>*</em></label
                                >
                                <textarea
                                    class="form-control"
                                    id="inputFeedback"
                                    formControlName="yourFeedback"
                                    rows="3"
                                    maxlength="1000"
                                ></textarea>
                                <div class="error" *ngIf="errors.yourFeedback">
                                    {{ errors.yourFeedback }}
                                </div>
                            </div>

                            <div class="d-flex mt-3 captcha-area justify-content-around flex-column">
                                <div class="form-group">
                                    <label for="captchaText"
                                        >{{ 'customer-feedback.security_check' | translate }}<em>*</em></label
                                    >
                                    <div
                                        class="captcha-container"
                                        [class.form-control]="!isMobile"
                                        [class.err]="errors.captchaText"
                                    >
                                        <input
                                            type="text"
                                            id="captchaText"
                                            placeholder="{{ 'customer-feedback.enter_displayed_text' | translate }}"
                                            formControlName="captchaText"
                                            [class.form-control]="isMobile"
                                            maxlength="6"
                                        />
                                        <div class="captcha-box d-flex" [class.form-control]="isMobile">
                                            <img
                                                [src]="captchaDetails?.captcha"
                                                class="captcha-img"
                                                alt=""
                                                draggable="false"
                                            />
                                            <div class="reload-btn-section pointer">
                                                <img
                                                    src="assets/img/reload_icn.svg"
                                                    alt=""
                                                    (click)="refreshCaptcha()"
                                                    draggable="false"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="error" *ngIf="errors.captchaText">
                                        {{ errors.captchaText }}
                                    </div>
                                </div>
                            </div>

                            <div class="err" *ngIf="error">{{ error }}</div>
                            <div class="d-flex justify-content">
                                <button
                                    type="submit"
                                    class="custom-button custom-action-btn bg-primary mr-2 submit-btn"
                                    [class.disabled]="!customerFeedbackForm.valid || submitted"
                                    (click)="register()"
                                >
                                    {{ 'customer-feedback.submit' | translate }}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-6 col-sm-12 col-space" *ngIf="!isMobile">
                        <div class="mask">
                            <img src="assets/img/mask.svg" alt="Mask" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout thankyou" *ngIf="feedbackSubmited">
            <div class="row">
                <div class="col" [class.padding-h-44]="isMobile">
                    <div class="page-header" [class.col-10]="!isMobile">
                        <h1>{{ 'customer-feedback.head_thanks' | translate }}</h1>
                    </div>
                    <div class="header-info" [class.col-11]="!isMobile">
                        <p>
                            {{ 'customer-feedback.body_thanks' | translate }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="row img-container">
                <div class="col" [class.padding-h-44]="isMobile">
                    <img src="assets/img/website/thanks-feedback-mob.svg" alt="" *ngIf="isMobile" />
                    <img src="assets/img/website/thanks-feedback.svg" alt="" *ngIf="!isMobile" />
                </div>
            </div>
        </div>
    </div>
</div>
