<div class="section">
    <div class="content m-auto">
        <div class="password-form p-3">
            <div class="logo-wrap mb-5">
                <img alt="" src="assets/img/logo/jm-logo-blue.svg" />
            </div>
            <form [formGroup]="changePasswordForm" (submit)="submit()">
                <div class="title mb-4">
                    <div class="text-1">Change Password</div>
                    <div class="text-2">Please change your password at first login.</div>
                </div>
                <div class="form-wrap">
                    <div class="form-group">
                        <input
                            type="password"
                            class="form-control text-left"
                            id="oldPassword"
                            formControlName="oldPassword"
                            aria-describedby="oldPasswordHelp"
                            [class.is-invalid]="errors.oldPassword"
                            placeholder="Enter Old Password"
                        />
                        <span class="invalid-feedback">{{ errors.oldPassword }}</span>
                    </div>
                    <div class="form-group">
                        <input
                            type="password"
                            class="form-control text-left"
                            id="newPassword"
                            formControlName="newPassword"
                            [class.is-invalid]="errors.newPassword"
                            placeholder="Enter New Password"
                        />
                        <span class="invalid-feedback">{{ errors.newPassword }}</span>
                    </div>
                    <div class="form-group">
                        <input
                            type="password"
                            class="form-control text-left"
                            id="confirmPassword"
                            formControlName="confirmPassword"
                            [class.is-invalid]="errors.confirmPassword"
                            placeholder="Re-enter New Password"
                        />
                        <span class="invalid-feedback">{{ errors.confirmPassword }}</span>
                    </div>
                    <div class="password-hint">
                        <p>
                            * Password should contain: Minimum of 8 characters, one lowercase letter, one uppercase
                            letter, one number, one special character.
                        </p>
                    </div>
                </div>
                <p class="text-error" *ngIf="errors.global">{{ errors.global }}</p>
                <button type="submit" class="btn primary mt-5">Change Password</button>
            </form>
        </div>
    </div>
</div>
