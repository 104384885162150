import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { UtilService, RtcService, UserService } from 'src/app/core';

@Component({
    selector: 'app-upgrade-popup',
    templateUrl: './upgrade-popup.component.html',
    styleUrls: ['./upgrade-popup.component.scss']
})
export class UpgradePopupComponent implements OnInit {
    @Output() close: EventEmitter<any> = new EventEmitter();
    public index = 0;
    public userData: any;
    public buyLink: any;
    public email: any;
    public phoneNo: any;
    public resellerUrl: any;
    dataList: any;
    featuresListInterval: any;

    constructor(private rtcService: RtcService, private utilService: UtilService, private userService: UserService) {}

    ngOnInit() {
        this.userService.getUser().subscribe((value) => {
            this.userData = value.defaultUpgradeSubscription.meta;
            this.email = value.email;
            this.phoneNo = value.phoneNo;
            this.resellerUrl = value.defaultUpgradeSubscription.resellerUrl;
            this.buyLink = this.resellerUrl + `?userKey=${this.email ? this.email : this.phoneNo}`;
        });
        this.startFeatureList();
    }

    startFeatureList() {
        this.featuresListInterval = setInterval(() => {
            this.index++;
            if (this.userData?.length && this.userData && this.index > this.userData.length - 1) {
                this.index = 0;
            }
        }, 2000);
    }

    changeListIndex(indexOfelement) {
        this.index = indexOfelement;
        clearInterval(this.featuresListInterval);
        this.startFeatureList();
    }

    buyNow() {
        const buyURL = this.buyLink;
        this.openURLInNewTab(buyURL);
        this.cancel();
    }

    openURLInNewTab(url) {
        const win = window.open(url, '_blank');
        win.focus();
    }

    cancel() {
        this.close.emit();
    }

    ngOnDestroy() {
        clearInterval(this.featuresListInterval);
    }
}
