import { Component, OnInit, OnDestroy, ViewEncapsulation, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import {
    ValidationService,
    AppService,
    AuthService,
    UtilService,
    GaDiagnosticService,
    DiagnosticService,
    EventEmitterService,
    EventData,
    GoogleTagManagerService,
    DesktopAppService
} from 'src/app/core';
import { REG_EX, APP_EVENTS } from 'src/app/constants';
import { GA_EVENTS } from '../ga-events';
import { GA_NEW } from 'src/app/shared/ga-new';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SignUpComponent implements OnInit, OnDestroy {
    signupForm: FormGroup;
    ottForm: FormGroup;
    errors: any = {};
    ottLogin = false;
    invitecode = '';
    verifyingOTP = false;
    signingUp = false;
    mailSent = false;
    newOTPSent = false;
    unVerifiedAccount = false;
    loading = true;
    alreadyRegistered = false;
    isMobile = false;
    disableResend = false;
    isGuest = false;
    rating = 0;
    thankyou = false;
    redirectToLogin = false;
    sessionId;
    token;
    success;
    samlLoginInProgress;
    loggingIn;
    bannersConfigData;
    returnUrl = '';
    fbVisibility = false;
    googleVisibility = true;
    private subscriptions: Subscription[] = [];

    constructor(
        private fb: FormBuilder,
        private toastrService: ToastrService,
        private validationService: ValidationService,
        private appService: AppService,
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private gaService: GaDiagnosticService,
        private diagnosticService: DiagnosticService,
        private utilService: UtilService,
        private translateService: TranslateService,
        private eventEmitterService: EventEmitterService,
        private gTagService: GoogleTagManagerService,
        private elementRef: ElementRef,
        private desktopAppService: DesktopAppService
    ) {}

    ngOnInit() {
        // this.router.routeReuseStrategy.shouldReuseRoute = () => {
        //   return false;
        // };
        this.configureSocialLoginVisibility();
        this.bannersConfigData = this.appService.getConfigVariable('BANNERS_CONFIG');
        this.isMobile = this.utilService.isMobileBrowser();
        if (this.authService.getIsAuthenticated()) {
            this.router.navigate(['/home']);
            this.gTagService.sendEvent(GA_EVENTS.ONBOARDING_PAGE_GO_TO_DASHBOARD);
            return;
        }

        this.signupForm = this.fb.group({
            emailId: [null, [Validators.required]],
            name: [
                '',
                [
                    Validators.required,
                    Validators.pattern(/^[a-zA-Z0-9\s]{2,50}$/)
                    // this.validationService.noWhitespaceValidator,
                ],
                ,
            ],
            lname: [
                '',
                [
                    Validators.required,
                    Validators.pattern(/^[a-zA-Z0-9\s]{2,50}$/)
                    // this.validationService.noWhitespaceValidator,
                ],
                ,
            ]
        });
        this.activatedRoute.queryParams.subscribe((params) => {
            this.isGuest = params.rewardsOnSignup && params.rewardsOnSignup;
            this.signupForm.patchValue({ emailId: params.email });
            this.invitecode = params.invitecode;
            this.returnUrl = params.returnUrl;
        });
        this.ottForm = this.fb.group({
            phoneNo: [null, [Validators.required, Validators.pattern(/^\d{10}$/)]],
            otp: [null, [Validators.required]]
        });
        this.gaService.sendPageView({
            page_title: 'signup_page',
            page_path: '/signup'
        });

        this.loading = false;

        this.subscriptions.push(
            this.eventEmitterService.subscribe((event: EventData) => {
                if (event.type === APP_EVENTS.SIGNUP_NAVIGATION) {
                    this.resetSignup();
                }
            }),
            this.translateService.onLangChange.subscribe(() => {
                if (this.errors.emailId) {
                    this.validate();
                }
            })
        );
        setTimeout(() => {
            document.getElementById('name').focus();
        });
    }

    configureSocialLoginVisibility() {
        this.fbVisibility = this.appService.getConfigVariable('show_facebook_login') || false;
        this.googleVisibility = this.appService.getConfigVariable('show_google_login') || true;
    }

    validate() {
        this.errors = {};
        if (
            !(
                this.validationService.isEmail(this.signupForm.value.emailId) ||
                this.signupForm.value.emailId.match(/^\d{10}$/)
            )
        ) {
            this.errors.emailId = this.translateService.instant('tostrs.invalid_email_address_or_phone_number');
            setTimeout(() => {
                const popUpElement: any = this.elementRef.nativeElement.getElementsByClassName('id-error-focus')[0];
                if (popUpElement) {
                    popUpElement?.focus();
                }
            }, 100);
            return false;
        }

        if (
            this.appService
                .getConfigVariable('SAML_DOMAINS')
                .includes(this.utilService.getDomainFromEmail(this.signupForm.value.emailId))
        ) {
            this.errors.emailId = this.translateService.instant(
                'tostrs.your_company_is_already_onboarded_with_jiomeet_please_sign_in_using_your_company_id'
            );
            setTimeout(() => {
                const popUpElement: any = this.elementRef.nativeElement.getElementsByClassName('id-error-focus')[0];
                if (popUpElement) {
                    popUpElement?.focus();
                }
            }, 100);
            return false;
        }

        if (!/^[a-zA-Z0-9]{2,50}$/.test(this.signupForm.value?.name?.trim())) {
            this.errors.name = this.translateService.instant(
                'tostrs.please_enter_a_valid_name_greater_than_1_character'
            );
            return false;
        }
        if (!/^[a-zA-Z0-9]{2,50}$/.test(this.signupForm.value?.lname?.trim())) {
            this.errors.lname = this.translateService.instant('tostrs.please_enter_a_valid_last_name');
            return false;
        }
        return true;
    }

    submit() {
        setTimeout(() => {
            const popUpElement: any = document.getElementsByClassName('email-verification-header')[0];
            if (popUpElement) {
                popUpElement?.focus();
            }
        }, 100);

        this.gTagService.sendEvent(GA_NEW.SIGNUP_PAGE_SUBMIT);
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translateService.instant('tostrs.something_went_right'));
            return;
        }
        this.errors = {};
        if (!this.validate()) {
            return;
        }

        if (this.signupForm.value.emailId.match(/^\d{10}$/)) {
            this.authService.isOTTUser({ phoneNo: this.signupForm.value.emailId }).subscribe(
                (res: any) => {
                    if (res.isOTT) {
                        this.alreadyRegistered = true;
                        // this.errors.global = 'You are already registered. Please login with your credentials.';
                    }
                    // } else {
                    this.sendOTP(this.signupForm.value.emailId);
                    // }
                },
                (err) => {
                    if (err.error.message === 'USERNOTFOUND') {
                        this.sendOTP(this.signupForm.value.emailId);
                    } else {
                        this.errors.global = err.error.errors;
                        if (!this.isGuest) {
                            this.gTagService.sendEvent(GA_EVENTS.SIGNUP_MOBILE_FAIL);
                            this.gTagService.sendEvent(GA_NEW.SIGNUP_MOBILE_FAIL);
                        } else {
                            this.gTagService.sendEvent(GA_EVENTS.SIGNUP_GUEST_MOBILE_FAIL);
                        }
                    }
                }
            );
            return;
        }

        this.authService.isOTTUser({ email: this.signupForm.value.emailId }).subscribe(
            (res: any) => {
                // if (res.isOTT) {
                // this.handleUnverifiedAccount();
                this.signup();
                // }
            },
            (error) => {
                if (error.error.message === 'USERNOTFOUND') {
                    // Needs to be handled
                    this.signup();
                } else {
                    this.errors.global = error.error.errors;
                    if (!this.isGuest) {
                        this.gTagService.sendEvent(GA_EVENTS.SIGNUP_EMAIL_FAIL);
                        this.gTagService.sendEvent(GA_NEW.SIGNUP_EMAIL_FAIL);
                    } else {
                        this.gTagService.sendEvent(GA_EVENTS.SIGNUP_GUEST_EMAIL_FAIL);
                    }
                }
            }
        );
    }

    handleRating(event) {
        this.rating = event.newValue;
    }

    submitRating() {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Feedback',
                eventType: 'app_event',
                eventAction: 'Post Call Feedback',
                status: 'success',
                data: { rating: this.rating }
            })
            .subscribe();
        setTimeout(() => {
            this.thankyou = true;
        }, 500);
    }

    signup() {
        this.signingUp = true;
        this.authService
            .signup({
                emailId: this.signupForm.value.emailId,
                name: this.signupForm.value.name?.trim(),
                lname: this.signupForm.value.lname?.trim(),
                code: this.invitecode
            })
            .subscribe(
                (res: any) => {
                    this.mailSent = true;
                    this.signingUp = false;
                    if (!this.isGuest) {
                        this.diagnosticService
                            .sendEvent({
                                eventCategory: 'Sign Up'
                            })
                            .subscribe();
                        this.gTagService.sendEvent(GA_EVENTS.SIGNUP_EMAIL_SUCCESS);
                        this.gTagService.sendEvent(GA_NEW.SIGNUP_EMAIL_SUCCESS);
                        this.gTagService.sendEvent(GA_EVENTS.CONFIRMATION_EMAIL_SENT);
                    } else {
                        this.diagnosticService
                            .sendEvent({
                                eventCategory: 'Sign Up',
                                eventAction: 'Sign Up with Email',
                                eventType: 'app_event',
                                endpoint: '/api/sign/signup',
                                status: 'success',
                                data: { email: res.email }
                            })
                            .subscribe();
                        this.gTagService.sendEvent(GA_EVENTS.SIGNUP_GUEST_EMAIL_SUCCESS);
                        this.gTagService.sendEvent(GA_EVENTS.CONFIRMATION_GUEST_EMAIL_SENT);
                    }
                },
                (err) => {
                    if (!this.isGuest) {
                        this.diagnosticService
                            .sendEvent({
                                eventCategory: 'Sign Up'
                            })
                            .subscribe();
                        this.gTagService.sendEvent(GA_EVENTS.SIGNUP_EMAIL_FAIL);
                        this.gTagService.sendEvent(GA_EVENTS.CONFIRMATION_EMAIL_SENT_FAIL);
                    } else {
                        this.diagnosticService
                            .sendEvent({
                                eventCategory: 'Sign Up',
                                eventAction: 'Sign Up with Email',
                                eventType: 'api_failure',
                                endpoint: '/api/sign/signup',
                                status: 'failed',
                                data: err.error
                            })
                            .subscribe();
                        this.gTagService.sendEvent(GA_EVENTS.SIGNUP_GUEST_EMAIL_FAIL);
                        this.gTagService.sendEvent(GA_EVENTS.CONFIRMATION_GUEST_EMAIL_SENT_FAIL);
                    }

                    this.signingUp = false;
                    if (err.error?.customCode === 421) {
                        this.handleUnverifiedAccount();
                    } else if (err.error?.customCode === 400) {
                        this.errors.global = err.error.errors;
                        setTimeout(() => {
                            const popUpElement: any =
                                this.elementRef.nativeElement.getElementsByClassName('global-error-focus')[0];
                            if (popUpElement) {
                                popUpElement?.focus();
                            }
                        }, 100);
                        // this.router.navigate(['login'], {
                        //   queryParams: { email: this.signupForm.value.emailId }
                        // });
                    } else {
                        if (err.error && err.error.errors) {
                            this.errors.global = err.error.errors;
                        } else {
                            this.errors.global = this.translateService.instant(
                                'tostrs.something_went_wrong_please_try_again'
                            );
                        }
                    }
                }
            );
    }

    handleUnverifiedAccount() {
        this.unVerifiedAccount = true;
        // this.resendEmail();
        this.toastrService.info(
            this.translateService.instant('tostrs.account_verification_link_has_been_sent_to_your_email')
        );
        this.mailSent = true;
    }

    getOTP(newOTP = false) {
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translateService.instant('tostrs.something_went_right'));
            return;
        }
        this.errors = {};
        if (!this.ottForm.value.phoneNo.match(/^\d{10}$/)) {
            this.errors.phoneNo = this.translateService.instant('tostrs.please_enter_valid_10_digit_mobile_no');
            return;
        }
        let resend = true;
        this.sendOTP(this.ottForm.value.phoneNo, resend);
        this.newOTPSent = true;
        if (!this.isGuest) {
            this.gTagService.sendEvent(GA_EVENTS.RESEND_OTP_SUCCESS);
            this.gTagService.sendEvent(GA_NEW.RESEND_OTP_SUCCESS);
        } else {
            this.gTagService.sendEvent(GA_EVENTS.RESEND_GUEST_OTP_SUCCESS);
        }
    }

    private sendOTP(phoneNo, isResend?) {
        this.authService
            .getOtp({
                phoneNo,
                code: this.invitecode
            })
            .subscribe(
                (res: any) => {
                    if (res.status === 'success') {
                        if (this.alreadyRegistered) {
                            this.newOTPSent = true;
                        }
                        this.ottLogin = true;
                        this.ottForm.patchValue({
                            phoneNo
                        });
                        this.setFocusOnElement('OtpHeaderFocus');
                        if (!this.isGuest) {
                            this.diagnosticService
                                .sendEvent({
                                    eventCategory: 'Sign Up',
                                    eventAction: 'Sign Up with Mobile',
                                    eventType: 'app_event',
                                    endpoint: '/api/getotp',
                                    status: 'success',
                                    data: { phoneNo: res.phoneNo }
                                })
                                .subscribe();
                            this.gTagService.sendEvent(GA_EVENTS.SIGNUP_MOBILE_SUCCESS);
                            this.gTagService.sendEvent(GA_NEW.SIGNUP_MOBILE_SUCCESS);
                        } else {
                            this.diagnosticService
                                .sendEvent({
                                    eventCategory: 'Sign Up',
                                    eventAction: 'Sign Up with Mobile',
                                    eventType: 'app_event',
                                    endpoint: '/api/getotp',
                                    status: 'success',
                                    data: { phoneNo: res.phoneNo }
                                })
                                .subscribe();
                            this.gTagService.sendEvent(GA_EVENTS.SIGNUP_GUEST_MOBILE_SUCCESS);
                        }
                    } else {
                        !this.isGuest
                            ? this.gTagService.sendEvent(GA_EVENTS.RESEND_OTP_FAIL)
                            : this.gTagService.sendEvent(GA_EVENTS.RESEND_GUEST_OTP_FAIL);
                    }
                },
                (err) => {
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign Up',
                            eventAction: 'Sign Up with Mobile',
                            eventType: 'api_failure',
                            endpoint: '/api/getotp',
                            status: 'failed',
                            data: err.error
                        })
                        .subscribe();
                    if (!this.isGuest) {
                        if (!isResend) {
                            this.gTagService.sendEvent(GA_EVENTS.SIGNUP_MOBILE_FAIL);
                            this.gTagService.sendEvent(GA_NEW.SIGNUP_MOBILE_FAIL);
                        }
                    } else {
                        this.gTagService.sendEvent(GA_EVENTS.SIGNUP_GUEST_MOBILE_FAIL);
                    }
                    if (err.error.customCode === 429) {
                        this.errors.maxOTPLimitReached = this.errors.phoneNo = this.translateService.instant(
                            'tostrs.max_otp_limit',
                            { value: '15:00' }
                        );
                        this.disableResend = true;
                        setTimeout(() => {
                            this.disableResend = false;
                        }, 900000);
                        return;
                    }
                    if (err.error && err.error.errors) {
                        this.errors.global = err.error.errors;
                    } else {
                        this.errors.global = err.error;
                    }
                }
            );
    }

    disableOttLoginForm() {
        if (!(this.ottForm.value.otp && (this.ottForm.value.otp || '').match(/^\d{6}$/))) {
            return true;
        }
        return false;
    }

    verifyOTP() {
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translateService.instant('tostrs.something_went_right'));
            return;
        }
        if (this.verifyingOTP) {
            return;
        }
        this.verifyingOTP = true;
        this.authService
            .verifyOTP({
                phoneNo: this.ottForm.value.phoneNo,
                otp: this.ottForm.value.otp,
                code: this.invitecode,
                username: this.signupForm.value.name?.trim(),
                lname: this.signupForm.value.lname?.trim()
            })
            .subscribe(
                (res: any) => {
                    this.verifyingOTP = false;
                    if (res.success === false) {
                        res.message === 'OTPAUTHERROR'
                            ? (this.errors.otp = this.translateService.instant('signup_page.incorrect_otp'))
                            : (this.errors.global = res.errors);
                        this.verifyingOTP = false;
                        this.ottForm.markAsPristine();
                        this.diagnosticService
                            .sendEvent({
                                eventCategory: 'Sign In',
                                eventAction: 'Verify OTP',
                                eventType: 'api_failure',
                                endpoint: '/api/verifyotp',
                                status: 'failed',
                                data: res
                            })
                            .subscribe();
                        if (!this.isGuest) {
                            this.gTagService.sendEvent(GA_EVENTS.VERIFY_OTP_FAIL);
                            this.gTagService.sendEvent(GA_NEW.VERIFY_OTP_FAIL);
                        } else {
                            this.gTagService.sendEvent(GA_EVENTS.VERIFY_GUEST_OTP_FAIL);
                        }
                        return;
                    }
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign Up',
                            eventAction: 'Verify OTP',
                            eventType: 'app_event',
                            endpoint: '/api/verifyotp',
                            status: 'success',
                            data: { phoneNo: res.phoneNo }
                        })
                        .subscribe();
                    if (!this.isGuest) {
                        this.gTagService.sendEvent(GA_EVENTS.VERIFY_OTP_SUCCESS);
                        this.gTagService.sendEvent(GA_NEW.VERIFY_OTP_SUCCESS);
                    } else {
                        this.gTagService.sendEvent(GA_EVENTS.VERIFY_GUEST_OTP_SUCCESS);
                    }

                    if (res.token) {
                        this.authService
                            .verifyOtpDualLogin({
                                phoneNo: this.ottForm.value.phoneNo,
                                otp: this.ottForm.value.otp,
                                token: res.token
                            })
                            .subscribe(() => {
                                if (this.alreadyRegistered) {
                                    this.router.navigate(['/home']);
                                } else {
                                    this.router.navigate(['/onboarding']);
                                }
                            });
                    } else {
                        if (this.alreadyRegistered) {
                            this.router.navigate(['/home']);
                        } else {
                            this.router.navigate(['/onboarding']);
                        }
                    }
                    if (!this.isGuest) {
                        this.gTagService.sendEvent(GA_EVENTS.ONBOARDING_PAGE_GO_TO_DASHBOARD);
                    } else {
                        this.gTagService.sendEvent(GA_EVENTS.GUEST_ONBOARDING_PAGE_GO_TO_DASHBOARD);
                    }
                },
                (err) => {
                    this.errors.global = this.translateService.instant('signup_page.incorrect_otp');
                    this.ottLogin = true;
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign Up',
                            eventAction: 'Verify OTP',
                            eventType: 'api_failure',
                            endpoint: '/api/verifyotp',
                            status: 'failed'
                        })
                        .subscribe();
                    if (!this.isGuest) {
                        this.gTagService.sendEvent(GA_EVENTS.VERIFY_OTP_FAIL);
                        this.gTagService.sendEvent(GA_NEW.VERIFY_OTP_FAIL);
                    } else {
                        this.gTagService.sendEvent(GA_EVENTS.VERIFY_GUEST_OTP_FAIL);
                    }
                }
            );
    }

    changePhoneNo() {
        this.ottLogin = false;
    }

    navigateToLogin(redirectedFromVerificationPage = false) {
        redirectedFromVerificationPage ? (this.redirectToLogin = true) : (this.redirectToLogin = false);
        this.router.navigate(['login'], { queryParams: { returnUrl: this.returnUrl } });
        if (redirectedFromVerificationPage === false) {
            if (!this.isGuest) {
                this.gTagService.sendEvent(GA_EVENTS.SIGNIN_FROM_SIGNUP_SUCCESS);
                this.gTagService.sendEvent(GA_NEW.SIGNIN_FROM_SIGNUP_SUCCESS);
            } else {
                this.gTagService.sendEvent(GA_EVENTS.SIGNIN_FROM_SIGNUP_GUEST_SUCCESS);
            }
        }
        if (redirectedFromVerificationPage === true) {
            if (!this.isGuest) {
                this.gTagService.sendEvent(GA_EVENTS.SIGNIN_AFTER_SIGNUP_SUCCESS);
            } else {
                this.gTagService.sendEvent(GA_EVENTS.SIGNIN_GUEST_AFTER_SIGNUP_SUCCESS);
            }
        }
    }

    launchTnc() {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Website Home Options',
                eventType: 'app_event',
                eventAction: 'Terms and Conditions',
                status: 'success'
            })
            .subscribe();
        this.gTagService.sendEvent(GA_NEW.TERMS_OF_SERVICE);
        window.open(this.appService.getEnvVariable('HOST_URL') + 'terms-condition-business');
    }

    launchPrivacy() {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Website Home Options',
                eventType: 'app_event',
                eventAction: 'Privacy Policy',
                status: 'success'
            })
            .subscribe();
        this.gTagService.sendEvent(GA_NEW.PRIVACY_POLICY);
        window.open(this.appService.getEnvVariable('HOST_URL') + 'privacy-policy-business');
    }

    loginWithSSO(providerName) {
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translateService.instant('tostrs.something_went_right'));
            return;
        }

        this.diagnosticService
            .sendEvent({
                eventCategory: 'Sign In',
                eventType: 'app_event',
                eventAction: `Sign In with ${providerName}`,
                endpoint: `/api/authorize/${providerName}`,
                status: 'success'
            })
            .subscribe();
        if (providerName === 'google') {
            if (!this.isGuest) {
                this.gTagService.sendEvent(GA_EVENTS.SIGNUP_GOOGLE_SUCCESS);
                this.gTagService.sendEvent(GA_NEW.SIGNUP_GOOGLE_SUCCESS);
            } else {
                this.gTagService.sendEvent(GA_EVENTS.SIGNUP_GUEST_GOOGLE_SUCCESS);
            }
        }
        if (providerName === 'facebook') {
            if (!this.isGuest) {
                this.gTagService.sendEvent(GA_EVENTS.SIGNUP_FACEBOOK_SUCCESS);
                this.gTagService.sendEvent(GA_NEW.SIGNUP_FACEBOOK_SUCCESS);
            } else {
                this.gTagService.sendEvent(GA_EVENTS.SIGNUP_GUEST_FACEBOOK_SUCCESS);
            }
        }
        this.authService.doSSOLogin({ providerName }).subscribe((res) => {
            this.launchAuthUrl(res);
        });
    }

    launchAuthUrl(res) {
        if (this.desktopAppService.isDesktopApp) {
            this.desktopAppService.launchAuthWindow(res.authUrl, (url) => {
                console.log(`auth callback url is ${url}`);
                this.validateAndGoToLanding(url);
            });
        } else {
            const url = new URL(res.authUrl);
            url.searchParams.append('returnUrl', this.returnUrl);
            //window.location.href = res.authUrl;
            window.location.href = url.toString();
        }
    }

    validateAndGoToLanding(url: string) {
        var parsedUrl = new URL(url);
        this.sessionId = parsedUrl.searchParams.get('sessionId');
        this.token = parsedUrl.searchParams.get('token');
        this.success = parsedUrl.searchParams.get('success');
        if (!this.success || this.success === 'false') {
            this.errors.global = 'Login failed. Please try again later.';
            this.samlLoginInProgress = false;
            this.loading = false;
            return;
        }
        this.samlLoginInProgress = true;
        this.authService.getSamlAccessToken(this.sessionId, this.token).subscribe(
            () => {
                this.samlLoginInProgress = false;
                this.router.navigate(['/home']);
            },
            (err) => {
                if (err.error && err.error.errors) {
                    this.errors.global = err.error.errors;
                } else {
                    this.errors.global = 'Something went wrong. Please try again.';
                }
                this.samlLoginInProgress = false;
                this.loggingIn = false;
            }
        );
        return;
    }

    checkNameLength(event) {
        if (this.signupForm.value.name?.toString().length > 50) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    checkLastNameLength(event) {
        if (this.signupForm.value.lname?.toString().length > 50) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    checkEmailIdLength(event) {
        if (this.signupForm.value.emailId?.toString().length > 74) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    validateErrors(control: string, formGroup: FormGroup = this.signupForm): string {
        const formControl = formGroup.get(control);
        if (!formControl || !formControl.errors) {
            return;
        }
        return Object.keys(formControl.errors)[0];
    }

    resendEmail() {
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translateService.instant('tostrs.something_went_right'));
            return;
        }
        this.authService.resendEmail(this.signupForm.value.emailId).subscribe(
            () => {
                this.toastrService.info(
                    this.translateService.instant(
                        'tostrs.we_have_sent_a_verification_link_to_your_registered_email_please_verify_to_login'
                    )
                );
                if (!this.isGuest) {
                    this.gTagService.sendEvent(GA_EVENTS.RESEND_EMAIL_SUCCESS);
                    this.gTagService.sendEvent(GA_NEW.RESEND_EMAIL_SUCCESS);
                } else {
                    this.gTagService.sendEvent(GA_EVENTS.RESEND_GUEST_EMAIL_SUCCESS);
                }
            },
            () => {
                this.toastrService.error(this.translateService.instant('tostrs.resend_failed_please_try_again_later'));
                if (!this.isGuest) {
                    this.gTagService.sendEvent(GA_EVENTS.RESEND_EMAIL_FAIL);
                    this.gTagService.sendEvent(GA_NEW.RESEND_EMAIL_FAIL);
                } else {
                    this.gTagService.sendEvent(GA_EVENTS.RESEND_GUEST_EMAIL_FAIL);
                }
            }
        );
    }

    userAnotherEmail() {
        this.mailSent = false;
        this.ottLogin = false;
        this.signupForm.reset();
        this.ottForm.reset();
    }

    resetSignup() {
        this.signupForm && this.signupForm.reset();
        this.ottForm && this.ottForm.reset();
        this.errors = {};
        this.ottLogin = false;
        this.invitecode = '';
        this.verifyingOTP = false;
        this.signingUp = false;
        this.mailSent = false;
        this.newOTPSent = false;
        this.unVerifiedAccount = false;
        this.loading = false;
        this.alreadyRegistered = false;
        this.disableResend = false;
    }

    setFocusOnElement(focusElement) {
        setTimeout(() => {
            const element: any = document.getElementsByClassName(focusElement)[0];
            if (element) {
                element?.focus();
            }
        }, 100);
    }

    ngOnDestroy() {
        if (this.signupForm.dirty && !this.redirectToLogin) {
            this.gTagService.sendEvent(GA_EVENTS.USER_NOT_SIGNED_UP);
            this.gTagService.sendEvent(GA_NEW.USER_NOT_SIGNED_UP);
        }
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
