import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'meetingidFormat'
})
export class MeetingidFormatPipe implements PipeTransform {
    transform(value: string, ...args: any[]): any {
        if (!value) return '';
        let result;
        if (value?.includes('-')) {
            result = value.replace(/-/g, ' ');
        } else if (!/\s/.test(value)) {
            result = `${value.substring(0, 3)} ${value.substring(3, 6)} ${value.substring(6)}`;
        }

        return result || value;
    }
}
