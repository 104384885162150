<div class="permissionDeniedDialog">
    <img class="closeIcon" src="assets/img/icon_search_close.svg" alt="Close icon" (click)="closePermissiondialog()" />
    <div class="permissionInfo">
        <h4 class="permissionHeading">Screen Sharing access blocked</h4>
        <div class="permissionSubHeading">You need to allow access to screen sharing for your browser</div>
        <h5>Try the following steps to resolve this:</h5>
        <div class="permissionSteps">
            <div class="mac_view">
                <div class="instruction">Allow Screen Recording for your browser in system preferences.</div>
                <img class="stepsImage" src="assets/images/mac_screen_permission.png" alt="permission steps" />
            </div>
        </div>
    </div>
</div>
<div class="permissionMask"></div>
