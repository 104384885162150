import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    UserService,
    UtilService,
    AppService,
    GaDiagnosticService,
    EventEmitterService,
    EventData,
    GoogleTagManagerService,
    LocalStorageService
} from 'src/app/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { INCOMING_CALL_AUDIOS } from 'src/app/components/website/websiteConstant';
import { APP_EVENTS } from 'src/app/constants';
import { GA_EVENTS } from '../ga-events';
import { GA_NEW } from 'src/app/shared/ga-new';
@Component({
    selector: 'app-notifications-settings',
    templateUrl: './notifications-settings.component.html',
    styleUrls: ['./notifications-settings.component.scss']
})
export class NotificationsSettingsComponent implements OnInit {
    form: FormGroup;
    emailSMSForm: FormGroup;
    showPhoneInput: boolean;
    enableSubscribe: boolean;
    phoneAdded: boolean;
    showOtpInput: boolean;
    enableOTPButton: boolean;
    phoneValid: boolean;
    whatsAppBotURL = '';
    phoneNo = '';
    user: any = {};
    incomingCallAudios;
    selectedAudio;
    selectedIndex;
    isPlaying = false;
    audio: any;
    constructor(
        private fb: FormBuilder,
        private userService: UserService,
        private utilService: UtilService,
        private appService: AppService,
        private gaService: GaDiagnosticService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private eventEmitterService: EventEmitterService,
        private gTagService: GoogleTagManagerService,
        private localStorageService: LocalStorageService
    ) {}

    ngOnInit(): void {
        document.documentElement.scrollTop = 0;
        this.emailSMSForm = this.fb.group({
            enableEmailNotification: false,
            enableSMSNotification: false
        });

        this.form = this.fb.group({
            enableNotification: false,
            phone: '',
            otp: ''
        });

        this.user = this.utilService.clone(this.userService.getUserSync());
        this.phoneValid = true;
        this.whatsAppBotURL = this.appService.getConfigVariable('whatsapp_config')?.whatsapp_url;
        if (this.user.whatsappNotification?.enabled) {
            this.form.get('enableNotification').setValue(true);
            this.showPhoneInput = true;
            this.phoneAdded = true;
            this.form.get('phone').setValue(this.user.whatsappNotification.number.substring(3));
            this.enableSubscribe = true;
        } else if (this.user.phoneNo) {
            if (this.user.phoneNo.includes('+91')) {
                this.user.phoneNo = this.user.phoneNo.substring(3);
            }
            this.form.get('phone').setValue(this.user.phoneNo);
        }

        this.emailSMSForm.get('enableEmailNotification').setValue(this.user.notifications.enabledOnEmail);
        this.emailSMSForm.get('enableSMSNotification').setValue(this.user.notifications.enabledOnSMS);

        this.listenToFormValueChanges();

        this.incomingCallAudios = INCOMING_CALL_AUDIOS;
        this.selectedAudio = this.utilService.selectedIncomingaudio;
        this.audio = document.getElementById('testAudio') as HTMLAudioElement;
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.GET_UPDATED_USER_DATA) {
                this.user = this.userService.getUserSync();
            }
            if (event.type === APP_EVENTS.INCOMING_CALL) {
                this.stopAudio();
            }
        });
        const selectedAudioItem = this.localStorageService.getItem('selectedAudioItem');
        if (selectedAudioItem != null) {
            this.selectedAudio = selectedAudioItem;
            this.selectAudio();
        }
    }

    listenToFormValueChanges(): void {
        this.form.get('phone').valueChanges.subscribe((phone) => {
            if (phone.trim().split(' ').join('').length != 10) {
                this.enableSubscribe = false;
            } else {
                this.enableSubscribe = true;
            }
        });

        this.form.get('otp').valueChanges.subscribe((otp) => {
            if (otp.trim().length != 6) {
                this.enableOTPButton = false;
            } else {
                this.enableOTPButton = true;
            }
        });

        this.form.get('enableNotification').valueChanges.subscribe((res) => {
            if (res === false) {
                this.gTagService.sendEvent(GA_NEW.WHATSAPP_NOTIFICATIONS_DISABLED);
                this.gTagService.sendEvent(GA_EVENTS.WHATSAPP_NOTIFICATIONS_DISABLED);
                if (this.user.whatsappNotification.enabled) {
                    this.userService.subscribeToWhatsapp({ enabled: false }).subscribe(
                        (res) => {
                            this.gTagService.sendEvent(GA_NEW.WHATSAPP_NOTIFICATIONS_DISABLED);
                            this.gTagService.sendEvent(GA_EVENTS.WHATSAPP_NOTIFICATIONS_DISABLED);
                        },
                        (err) => {}
                    );
                }
                this.phoneAdded = false;
            } else {
                this.gTagService.sendEvent(GA_EVENTS.WHATSAPP_NOTIFICATIONS_ENABLED);
                this.gTagService.sendEvent(GA_NEW.WHATSAPP_NOTIFICATIONS_ENABLED);
                this.form.get('phone').setValue(this.user.displayPhoneNo ? this.user.displayPhoneNo : '');
            }
            this.showPhoneInput = res;
            this.showOtpInput = false;
        });

        this.emailSMSForm.get('enableEmailNotification').valueChanges.subscribe((enable) => {
            this.userService.toggleNotifications({ notificationType: 'email', enable: enable }).subscribe(
                (res) => {
                    this.gTagService.sendEvent(
                        enable === true ? GA_EVENTS.EMAIL_NOTIFICATIONS_ENABLED : GA_EVENTS.EMAIL_NOTIFICATIONS_DISABLED
                    );
                    this.gTagService.sendEvent(
                        enable === true ? GA_NEW.EMAIL_NOTIFICATIONS_ENABLED : GA_NEW.EMAIL_NOTIFICATIONS_DISABLED
                    );
                },
                (err) => {}
            );
        });

        this.emailSMSForm.get('enableSMSNotification').valueChanges.subscribe((enable) => {
            this.userService.toggleNotifications({ notificationType: 'sms', enable: enable }).subscribe(
                (res) => {
                    this.gTagService.sendEvent(
                        enable ? GA_EVENTS.SMS_NOTIFICATIONS_ENABLED : GA_EVENTS.SMS_NOTIFICATIONS_DISABLED
                    );
                    this.gTagService.sendEvent(
                        enable ? GA_NEW.SMS_NOTIFICATIONS_ENABLED : GA_NEW.SMS_NOTIFICATIONS_DISABLED
                    );
                },
                (err) => {}
            );
        });
    }

    handleBackspace(event: ClipboardEvent) {
        event.stopPropagation();
        setTimeout(() => {
            const value = (event.target as HTMLInputElement).value;
            if (value) {
                if (value[value.length - 1] === ' ') {
                    (event.target as HTMLInputElement).value = value.substr(0, value.length - 1);
                } else {
                    // (event.target as HTMLInputElement).value = value.substr(0, value.length - 1);
                }
            }
            this.phoneValid = true;
        }, 0);
    }

    onSubscribe() {
        this.gTagService.sendEvent(GA_NEW.WHATSAPP_SUBSCRIBE);
        this.phoneNo = '+91' + this.form.get('phone').value;
        this.phoneNo = this.phoneNo.trim().split(' ').join('');
        if (
            (this.user.source === 'mobile' || this.user.source === 'saml') &&
            this.phoneNo === '+91' + this.user.displayPhoneNo
        ) {
            this.userService.addPhoneNumber({ phoneNo: this.phoneNo }).subscribe(
                (res) => {
                    this.showOtpInput = false;
                    this.phoneAdded = true;
                },
                (err) => {
                    if (err.error.message === 'LIMIT_EXCEEDED') {
                        this.toastrService.error(err.error.errors);
                    }
                }
            );
        } else {
            this.userService.addPhoneNumber({ phoneNo: this.phoneNo }).subscribe(
                (res) => {
                    this.toastrService.info(this.translateService.instant('tostrs.otp_successful'));
                    this.form.get('otp').setValue('');
                    this.showOtpInput = true;
                    this.showPhoneInput = false;
                    this.phoneAdded = false;
                },
                (err) => {
                    if (err.error.customCode === 412) {
                        this.phoneValid = false;
                    }
                    if (err.error.message === 'LIMIT_EXCEEDED') {
                        this.toastrService.error(err.error.errors);
                    }
                }
            );
        }
    }

    handlemessageusevent(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            const whatsappredirection = event.target as HTMLElement;
            whatsappredirection.click();
        }
    }

    onConfirm() {
        let phoneNo = '+91' + this.form.get('phone').value;
        phoneNo = phoneNo.trim().split(' ').join('');
        this.userService
            .verifyPhone({
                phoneNo: phoneNo,
                otp: this.form.get('otp').value
            })
            .subscribe(
                (res: any) => {
                    if (res?.success !== false) {
                        this.userService.subscribeToWhatsapp({ enabled: true }).subscribe(
                            (res) => {
                                this.showOtpInput = false;
                                this.showPhoneInput = true;
                                this.phoneAdded = true;
                            },
                            (err) => {}
                        );
                        return;
                    }
                    this.toastrService.error(
                        this.translateService.instant('user_dashboard_settings.notification.incorrect_OTP')
                    );
                },
                (err) => {
                    this.toastrService.error(err.error.errors);
                }
            );
    }

    selectAudio() {
        this.stopAudio();
        this.utilService.selectedIncomingaudio = this.selectedAudio;
        this.localStorageService.addItem('selectedAudioItem', this.selectedAudio);
        if (this.selectedAudio?.name === 'Silent') {
            this.gTagService.sendEvent(GA_EVENTS.INCOMING_CALL_SILENT);
            this.gTagService.sendEvent(GA_NEW.INCOMING_CALL_SILENT);
        }
        if (this.selectedAudio?.name === 'JioMeet Ringtone') {
            this.gTagService.sendEvent(GA_EVENTS.INCOMING_CALL_JIOMEET_TUNE);
            this.gTagService.sendEvent(GA_NEW.INCOMING_CALL_JIOMEET_TUNE);
        }
        if (this.selectedAudio?.name === 'JioMeet Ringtone1') {
            this.gTagService.sendEvent(GA_EVENTS.INCOMING_CALL_JIOMEET_TUNE);
            this.gTagService.sendEvent(GA_NEW.INCOMING_CALL_JIOMEET_TUNE);
        }
        if (this.selectedAudio?.name === 'JioMeet Ringtone2') {
            this.gTagService.sendEvent(GA_EVENTS.INCOMING_CALL_JIOMEET_TUNE);
            this.gTagService.sendEvent(GA_NEW.INCOMING_CALL_JIOMEET_TUNE);
        }
    }

    playAudio(track) {
        this.stopAudio();
        this.audio.src = track.audioPath;
        this.audio.loop = false;
        this.audio.play();
        track.isPlaying = true;
        this.audio.addEventListener('ended', function () {
            this.audio.currentTime = 0;
            track.isPlaying = false;
        });
    }

    stopAudio() {
        if (this.audio?.paused === false) {
            this.audio.pause();
        }
        this.incomingCallAudios.forEach((audio) => {
            audio.isPlaying = false;
        });
    }

    playorpause(event, selectedAudio) {
        event.stopPropagation();
        selectedAudio.isPlaying ? this.stopAudio() : this.playAudio(selectedAudio);
    }

    getPhoneNo() {
        return this.phoneNo.substring(0, 3) + ' ' + this.phoneNo.substring(3);
    }

    onEdit() {
        this.phoneAdded = false;
        this.gTagService.sendEvent(GA_EVENTS.EDIT_WHATSAPP_NUMBER);
    }

    clearPhoneInput() {
        this.form.get('phone').setValue('');
    }

    isPhoneEmpty() {
        return this.form.get('phone').value !== '';
    }

    isOTPEmpty() {
        return this.form.get('otp').value !== '';
    }
    //Accessability changes
    setFocusOnElement() {
        setTimeout(() => {
            const element: any = document.getElementById('language');
            element.focus();
        }, 100);
    }
}
