<div class="website-press-release" id="website-top" [class.padding-h-141]="!isMobile">
    <section class="section-top" [class.padding-h-16]="isMobile">
        <div>
            <div class="heading-0">Press Releases</div>
            <div class="desc subheading-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
            </div>
        </div>
        <div *ngIf="!isMobile" class="illustration">
            <img src="assets/img/press-release-top.svg" alt="" />
        </div>
    </section>
    <section class="sec-notes" [class.padding-h-16]="isMobile">
        <div class="note-card pointer" *ngFor="let note of pressReleaseNotes">
            <div class="subheading-1">{{ note.date }}</div>
            <div class="subheading-2">{{ note.note }}</div>
            <div class="read-more">Read More<img src="assets/img/right-active-arrow.svg" alt="" /></div>
            <div class="read-more-hidden">Read More<img src="assets/img/right-active-arrow.svg" alt="" /></div>
        </div>
    </section>
</div>
