import {
    Component,
    OnInit,
    AfterViewInit,
    Input,
    Output,
    ViewChild,
    ElementRef,
    OnDestroy,
    EventEmitter,
    NgZone,
    ComponentFactoryResolver,
    ComponentRef,
    ViewEncapsulation,
    Renderer2,
    HostListener
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { HttpEventType, HttpEvent } from '@angular/common/http';

import { combineLatest, Subscription, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { ConfirmationService } from 'primeng/api';

import { ToastrService } from 'ngx-toastr';

import * as FileSaver from 'file-saver';

import { ResizedEvent } from 'angular-resize-event';
import { find } from 'lodash-es';
import {
    UserService,
    EventEmitterService,
    SocketService,
    SocketEvent,
    AuthService,
    ChatService,
    AppService,
    GuestAuthService,
    UtilService,
    JiocloudService,
    JioCloudUtilService,
    JiocloudAuthService,
    RoomConnectionService,
    ComponentFactoryService,
    RtcService,
    ThirdPartyExternalIntegrationService,
    GoogleTagManagerService,
    DesktopAppService
} from 'src/app/core';

import { HostDirective } from 'src/app/shared';

import { APP_CONSTANT, APP_EVENTS, JIO_CLOUD } from 'src/app/constants';

import { PrivateChatV2Component } from '../private-chat-v2/private-chat-v2.component';
import { AttachmentListComponent } from '../attachment-list/attachment-list.component';
import { ChatToastComponent } from '../chat-toast/chat-toast.component';
import { ChatMarkdownComponent } from '../chat-markdown/chat-markdown.component';
import { TranslateService } from '@ngx-translate/core';
import * as sanitizeHtml from 'sanitize-html';
import { GA_EVENTS } from '../../ga-events';
import { GA4_EVENTS } from 'src/app/components/website/ga4-events';
import { GA_NEW } from 'src/app/shared/ga-new';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { SelectControlValueAccessor, SelectMultipleControlValueAccessor } from '@angular/forms';

declare var $: any;
declare const TurndownService: any;
@Component({
    selector: 'app-inside-call-chat-v2',
    templateUrl: './inside-call-chat-v2.component.html',
    styleUrls: ['./inside-call-chat-v2.component.scss']
})
export class InsideCallChatV2Component implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('messages', { static: true }) messagesEle: ElementRef;
    @ViewChild('virtualChatList', { static: false }) virtualChatList: ElementRef;
    @ViewChild('textArea') textArea: ElementRef;
    @ViewChild('content') content: ElementRef;
    @Input()
    enabled;
    @Input() meetingObj;
    @Input() popout = false;
    @Input() shouldOpenPrivate;
    @Output() close: EventEmitter<any> = new EventEmitter();
    @ViewChild('downloadAnchor') downloadAnchor: ElementRef;
    @ViewChild('attachmentIcon') attachmentIcon: ElementRef;
    @ViewChild('uploadFiles') openFileMenu: ElementRef;
    @ViewChild('box') input: ElementRef;
    @ViewChild('dropdownToggle') dropdownToggle: ElementRef;
    @ViewChild('uploadedDocs') uploadedDocs: ElementRef;

    @ViewChild(HostDirective, { static: true }) viewHost: HostDirective;
    @ViewChild(ChatMarkdownComponent) private chatMarkdownComponent: ChatMarkdownComponent;

    messages = [];
    currentUser: any = {};
    guestUser: any = {};
    isAuthenticated;
    messageToSend;
    roomStatus: any = {};
    roomParticipants: any[] = [];
    unreadChatParticipantIds: any[] = [];
    pastedImageFile: File = null;
    // isScheduledMeeting: any = {};

    chatGroupsByDate: any[];
    attachmentList: any[] = [];
    showAttachments;
    uploadedItems: any = {};
    subscriptions: Subscription[] = [];
    public dropdown = false;
    public privateChatToggle = false;
    privateChatRecipient: any;
    loggerTrigger = true;
    loggerObject: { name: string; _id: string };
    showErrorPopUp: boolean = true;
    errorMessage: string = 'test error message';
    uploadPermitted: boolean = true;
    selectedEmoji = false;
    working = false;
    groupThreadId;
    breakoutRoomInfo;
    getMessagesPool;
    showLoadingMessages = false;
    fetchAllchatMsgsSub: Subscription;
    isImagePastedFirst: boolean = false;
    filesUploaded: number = 0;
    filesUploading: number = 0;
    fileWithInvalidFileType: boolean = false;
    disableSendButton: boolean = true;
    showWhiteDots: boolean = false;

    tagsToReplace = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;'
    };

    showPrivateChat = this.appService.getConfigVariable('SHOW_PRIVATE_CHAT');
    showEmojiBar = this.appService.getConfigVariable('SHOW_EMOJI_BAR');
    enableMarkdown = this.appService.getConfigVariable('SHOW_MARKDOWN');
    enableJioCloud = false;
    enableDownloadPreview = false;
    showMarkdown = false;
    showMoreOptions = false;
    chatTitle;
    turnDownService = new TurndownService();

    private previousScrollHeight;
    private timeouts = [];
    room;
    isThirdPartyExternalIntegration;
    isMobileDevice;
    thirdPartyEventSubscriptions = [];
    isLargeMeeting = false;
    isJMMeeting = false;
    chatContentHeight = 500;
    chatMessages = [];
    noOfMessagesLoaded = 0;
    oldScrollHeight = 0;
    areMoreMessagesAvailable = true;
    selectedChatOption = 'NONE';
    showDisabledChatToast: boolean = false;
    isUserHostOrCohost: boolean = false;
    show_options: boolean = false;
    mouse_hover_options: boolean = false;
    show_delete: boolean = false;
    messageId: any;
    isMsgDeleted: boolean = false;
    currentTime: any;
    justDeleted: boolean = false;
    deletedMessages: any[] = [];
    imageWrapper: HTMLElement;
    attachmentsCount = 0;
    toastCounter = 0;

    constructor(
        public userService: UserService,
        private guestUserService: GuestAuthService,
        public chatService: ChatService,
        private eventEmitterService: EventEmitterService,
        private jioCloudService: JiocloudService,
        private jioCloudUtilService: JioCloudUtilService,
        private socketService: SocketService,
        private zone: NgZone,
        private authService: AuthService,
        private toastrService: ToastrService,
        private jiocloudAuthService: JiocloudAuthService,
        private confirmationService: ConfirmationService,
        private roomConnectionService: RoomConnectionService,
        private compFactory: ComponentFactoryResolver,
        private appService: AppService,
        private factoryService: ComponentFactoryService,
        private utilService: UtilService,
        private rtcService: RtcService,
        private translateService: TranslateService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        private gTagService: GoogleTagManagerService,
        private appLoggerService: AppLoggerService,
        public desktopAppService: DesktopAppService,
        private renderer: Renderer2
    ) {
        this.isThirdPartyExternalIntegration =
            this.thirdPartyExternalIntegrationService.getThirdPartyExternalIntegration();
        this.isMobileDevice = this.utilService.isMobileBrowser() || this.isThirdPartyExternalIntegration;
        this.thirdPartyEventSubscriptions.push(
            this.eventEmitterService.subscribe((event: any) => {
                if (event.type === APP_EVENTS.NATIVE_BACK_BUTTON) {
                    this.closeView();
                }
            })
        );
        this.enableJioCloud = this.userService.isJioCloudEnabled();
        this.enableDownloadPreview = this.appService.isDesktopApp();
        this.addTurndownRules();
    }
    public get userID() {
        return this.currentUser?._id || this.guestUser?.guestUserId;
    }

    getCurrentUserId() {
        return this.currentUser?._id || this.guestUser?.guestUserId;
    }

    ngOnInit() {
        this.room = this.rtcService.getRoomInfo();
        this.guestUser = this.guestUserService.getAuthInfo();
        this.currentUser = this.userService.getUserSync();
        this.isAuthenticated = this.authService.getIsAuthenticated();
        this.isJMMeeting = this.rtcService.getIsJMMeeting();
        this.isLargeMeeting = this.rtcService.isJMLargeMeeting;
        // this.isScheduledMeeting = !!this.rtcService.getRoomInfo().meetingId;
        if (this.shouldOpenPrivate && !(this.isJMMeeting && this.isLargeMeeting)) {
            this.switchToPrivateChat(this.shouldOpenPrivate);
        }
        this.breakoutRoomInfo = this.rtcService.getBreakoutRoomInfo();
        this.eventEmitterService.emit({
            type: APP_EVENTS.SHOW_UNSEEN_COUNT,
            data: false
        });
        this.getMessages(true);
        this.subscriptions.push(
            this.chatService.getNotifiedParticipants().subscribe((value) => {
                this.unreadChatParticipantIds = value;
            }),
            this.socketService.dataEvents$.subscribe(this.handleMeetingEvents.bind(this)),
            this.roomConnectionService.getRoomConnectionStatus$().subscribe((res) => {
                this.zone.run(() => {
                    if (res) {
                        this.handleRoomConnectionStatusResponse(res);
                    }
                });
            })
        );
        if (!this.popout) {
            this.eventEmitterService.emit({ type: 'POPOUT_CHAT', data: false });
        }
        this.deletedMessages = [];
        const broadcastChannel = new BroadcastChannel('chat-deletion-channel');
        broadcastChannel.addEventListener('message', (event) => {
            const { type, messageId } = event.data;
            if (type === 'deletion' && messageId) {
                this.deletedMessages.push(messageId);
                this.resetSettings(messageId);
                this.isMsgDeleted = true;
                this.currentTime = new Date();
                this.justDeleted = true;
            }
        });
    }

    handleMaxTableSizeError(error: boolean) {
        this.disableSendButton = error;
    }

    handleRoomConnectionStatusResponse(res) {
        this.roomStatus = res;
        this.setRoomParticipats(this.roomStatus.participants);
        this.selectedChatOption = this.roomStatus?.restrictChat;
        this.showDisabledChatToast = !this.roomStatus?.localParticipant?.chatStatus;
        var divElement = document.querySelector('.chatAttachments') as HTMLElement;
        if (this.showDisabledChatToast && divElement) {
            if (divElement === document.activeElement || divElement.contains(document.activeElement)) {
                var tempElement = document.createElement('button');
                document.body.appendChild(tempElement);
                tempElement.focus();
                document.body.removeChild(tempElement);
            }
        }
        this.isUserHostOrCohost =
            this.roomStatus?.localParticipant?.isHost || this.roomStatus?.localParticipant?.isCoHost;
        this.handleChatDisableScrollerChange();
    }

    browseFileWindow() {
        document.getElementById('upload').click();
        const messagesDiv = this.messagesEle.nativeElement;
        this.renderer.setStyle(messagesDiv, 'height', 'calc(100vh - 270px)');
        setTimeout(() => {
            this.scrollToBottomAfterAdjustment();
        }, 2000);
    }

    toggleDeleteImage() {
        this.showWhiteDots = true;
    }

    toggleDeleteImageAgain() {
        this.showWhiteDots = false;
    }

    resetHeightForDynamicScroller(force = false) {
        if (this.isJMMeeting && this.isLargeMeeting) {
            this.timeouts.push(
                setTimeout(() => {
                    this.chatContentHeight = this.messagesEle?.nativeElement?.clientHeight - 10 || 500;
                }, 200)
            );
            if (force) {
                this.scrollToBottom(true);
            }
        }
    }

    ngAfterViewInit() {
        this.previousScrollHeight = this.messagesEle.nativeElement.scrollTop;
        this.resetHeightForDynamicScroller(true);
        this.dropdownToggle.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                this.dropdownToggle.nativeElement.click();
            }
        });
        this.handleChatDisableScrollerChange();
    }

    handleChatDisableScrollerChange() {
        if (this.showDisabledChatToast) {
            setTimeout(() => {
                let disbaledChatToast = document.getElementsByClassName('disabled-chat-toast')[0] as HTMLElement;
                if (disbaledChatToast) {
                    disbaledChatToast.style.bottom = `${$('.attachments').height() - 2}px`;
                }
                let addExtraHeight = $('.disabled-chat-toast').height() + 25 || 0;
                $('.messages').css('bottom', `${$('.attachments').height() + addExtraHeight}px`);
            }, 50);
        }
    }

    showChatDisableRelatedChanges() {
        return (
            this.isUserHostOrCohost &&
            this.currentUser?.tenantId != null &&
            this.currentUser?.featuresEnabled?.forceChatMute &&
            !this.room?.advancedOptions?.isClassroomMode
        );
        if (this.uploadedDocs) {
            this.uploadedDocs.nativeElement.style.display = 'none';
        }
    }

    toggleDropdown() {
        this.dropdown = !this.dropdown;
        setTimeout(() => {
            if (this.dropdown) {
                this.setDropdownHeight();
            }
            return;
        }, 50);
    }

    setDropdownHeight() {
        let heightDiff =
            (document.getElementsByClassName('group-chat-conponent')[0] as HTMLElement).offsetHeight -
            (document.getElementsByClassName('attachments')[0] as HTMLElement).offsetHeight;

        let elem = document
            .getElementsByClassName('participants-dropdown')[0]
            .getElementsByClassName('dropdown')[0] as HTMLElement;

        if (elem) {
            elem.style.maxHeight = `${heightDiff}px`;
        }
    }

    addTurndownRules() {
        this.turnDownService.addRule('strikethrough', {
            filter: ['del', 's', 'strike'],
            replacement: (content) => {
                return '~~' + content + '~~';
            }
        });

        this.turnDownService.addRule('bold', {
            filter: ['b', 'strong'],
            replacement: (content) => {
                return '**' + content + '**';
            }
        });

        this.turnDownService.addRule('italic', {
            filter: ['i', 'em'],
            replacement: (content) => {
                return '*' + content + '*';
            }
        });

        this.turnDownService.addRule('underline', {
            filter: ['u', 'ins'],
            replacement: (content) => {
                return '__' + content + '__';
            }
        });

        this.turnDownService.addRule('table', {
            filter: 'table',
            replacement: (content) => {
                return content + '\n\n';
            }
        });

        this.turnDownService.addRule('tableRow', {
            filter: 'tr',
            replacement: (content) => {
                return content + '\n';
            }
        });

        this.turnDownService.addRule('tableCell', {
            filter: ['td', 'th'],
            replacement: (content, node) => {
                if (!content.trim() || content.trim() === '<br>') {
                    if (node.nodeName === 'TD') {
                        return '<center>&nbsp;</center>JM_cell';
                    } else {
                        return 'JM_cell';
                    }
                } else {
                    return content.trim() + 'JM_cell';
                }
            }
        });
    }

    setRoomParticipats(participants) {
        let obj = {};
        for (let i = 0; i < participants.length; i++) {
            obj[participants[i].userId] = participants[i];
        }
        const usersArray = [];
        for (var key in obj) {
            usersArray.push(obj[key]);
        }
        this.roomParticipants = this.utilService.clone(usersArray);
        this.roomParticipants.sort((p1, p2) => {
            const p1UnreadSender = this.unreadChatParticipantIds.includes(p1.userId);
            const p2UnreadSender = this.unreadChatParticipantIds.includes(p2.userId);
            return p1UnreadSender === p2UnreadSender ? 0 : p1UnreadSender ? -1 : 1;
        });
        // const firstNonSenderIndex = this.roomParticipants.findIndex(
        //   (p) => !this.unreadChatParticipantIds.includes(p.userId)
        // );
        // this.roomParticipants.splice(firstNonSenderIndex, 0, { participantName: 'All', userId: null });
        this.roomParticipants.unshift({ participantName: 'All', userId: null });
    }

    getMessages(getThread = false) {
        // this.chatService.fetchAllMessages(this.meetingObj.jiomeetId).subscribe((data: any) => {
        //   const chats = (data.messages || []).filter((msg) => msg.type === 'message');
        //   this.chatGroupsByDate = this.chatService.groupChatMessagesByDate(chats);
        //   this.scrollToBottom();
        // });
        // this.chatService
        //   .getChatThreadId(this.meetingObj.jiomeetId)
        //   .pipe(
        //     tap((thread) => (this.groupThreadId = thread._id)),
        //     switchMap((thread) => {
        //       return combineLatest([
        //         this.getAttchmentsInGroupChat(),
        //         this.chatService.getSortedMembers(thread._id)
        //       ]);
        //     })
        //   )
        //   .subscribe(([attachments, sortedMembers]) => {});

        this.fetchAllchatMsgsSub = this.chatService.fetchAllMessages(this.meetingObj.jiomeetId, 20, 0).subscribe(
            (data: any) => {
                clearInterval(this.getMessagesPool);
                this.showLoadingMessages = false;
                this.areMoreMessagesAvailable = data?.messages?.length > 0;
                this.scrollToBottom(!getThread);
                this.noOfMessagesLoaded = data.messages?.length;
                const chats = this.filterChats(data);
                if (this.isJMMeeting && this.isLargeMeeting) {
                    this.chatMessages = this.chatService.groupChatMessagesWithDate(chats);
                    this.chatService.inCallChatMessages = this.chatMessages;
                } else {
                    this.chatGroupsByDate = this.chatService.groupChatMessagesByDate(chats);
                    this.chatService.inCallChatMessages = this.chatGroupsByDate;
                }
                this.getAttchmentsInGroupChat().subscribe();
                if (getThread) {
                    if (chats.length > 0) {
                        this.groupThreadId = chats[0]?.threadId;
                        this.getAttchmentsInGroupChat().subscribe();
                        this.chatService
                            .markAllMessagesAsRead(this.groupThreadId, chats[0]?._id)
                            .subscribe((data: any) => {});
                        this.chatService.getChatAttachments(this.groupThreadId).subscribe((attachments) => {
                            this.attachmentList = (attachments.messages || []).filter((msg) => msg.type === 'message');
                            for (let i = 0; i < this.attachmentList.length; i++) {
                                for (let j = 0; j < this.attachmentList[i].messageComponent.attachments.length; j++) {
                                    if (
                                        this.attachmentList[i].messageComponent.attachments[j].imageTrancodedURL !==
                                            '' ||
                                        this.attachmentList[i].messageComponent.attachments[j].downloadURL !== ''
                                    )
                                        this.attachmentsCount += 1;
                                }
                            }
                        });
                    } else {
                        this.chatService.getChatThreadId(this.meetingObj.jiomeetId).subscribe(
                            (thread) => {
                                this.groupThreadId = thread._id;
                                this.getAttchmentsInGroupChat().subscribe();
                            },
                            (err) => {
                                this.appLoggerService.error(
                                    'Get chat thread id in call error:',
                                    new Error(JSON.stringify(err?.error?.errors)),
                                    err
                                );
                            }
                        );
                    }
                }
            },
            (err) => {
                if (err?.error?.message === 'LIMIT_EXCEEDED') {
                    this.showLoadingMessages = true;
                    if (this.isJMMeeting && this.isLargeMeeting) {
                        this.chatMessages = this.chatService.inCallChatMessages;
                    } else {
                        this.chatGroupsByDate = this.chatService.inCallChatMessages;
                    }
                }
                if (!this.getMessagesPool) {
                    this.getMessagesPool = setInterval(() => {
                        this.getMessages(true);
                    }, 2000);
                }

                this.appLoggerService.error(
                    'Get chat messages in call error: ',
                    new Error(JSON.stringify(err?.error?.errors)),
                    err
                );
            }
        );
    }

    getAttchmentsInGroupChat() {
        if (!this.groupThreadId || !this.enableJioCloud) {
            return of([]);
        }
        return this.chatService.getChatAttachments(this.groupThreadId).pipe(
            tap((attachments) => {
                this.attachmentList = (attachments.messages || []).filter((msg) => msg.type === 'message');
            })
        );
    }

    handleMeetingEvents(event: SocketEvent) {
        this.zone.run(() => {
            switch (event.event) {
                case 'chatMessage':
                    if (
                        event?.data?.type === 'message' &&
                        (event?.data?.jiomeetId === this.meetingObj?.jiomeetId ||
                            event?.data?.threadId === this.groupThreadId)
                    ) {
                        if (event.data.threadId === this.groupThreadId) {
                            if (this.isJMMeeting && this.isLargeMeeting) {
                                let currChatMessages = this.chatService.appendRecievedMessageToList(
                                    this.chatMessages,
                                    event.data
                                );
                                this.chatMessages = [...currChatMessages];
                            } else {
                                this.chatGroupsByDate = this.chatService.appendRecievedMessage(
                                    this.chatGroupsByDate,
                                    event.data
                                );
                                this.chatService
                                    .markAllMessagesAsRead(event?.data?.threadId, event?.data?._id)
                                    .subscribe((data: any) => {});
                            }
                            try {
                                this.scrollToBottom(this.userID === event.data.createdBy);
                                if (event?.data?.messageComponent?.attachments?.length > 0) {
                                    this.getAttchmentsInGroupChat().subscribe();
                                    this.getAttachmentsAndUpdateChatCount(this.groupThreadId);
                                }
                            } catch (error) {
                                console.log('chatMessage error', error);
                            }
                        } else {
                            if (this.loggerTrigger && this.userID !== event.data.createdBy) {
                                setTimeout(() => {
                                    this.generateToast(
                                        {
                                            _id: event.data.createdBy,
                                            name: `${event.data.creatorsName} ${event.data?.creatorsLName || ''}`
                                        },
                                        'PRIVATE_CHAT'
                                    );
                                }, 300);
                            }
                            this.chatService.updateNotifiedParticipants(event.data.createdBy);
                        }
                    }
                    break;
                case 'chatMessageUpdate':
                    if (this.groupThreadId === event.data.threadId) {
                        switch (event.data.action) {
                            case 'deleted':
                                this.getAttachmentsAndUpdateChatCount(this.groupThreadId);
                                event.data.messageIdsToBeUpdated.forEach((deletedId) => {
                                    this.chatGroupsByDate.forEach((value) => {
                                        value?.value?.forEach((msg) => {
                                            if (msg._id === deletedId) {
                                                msg.msgDeleted = true;
                                            }
                                            return msg;
                                        });
                                    });
                                });
                                this.getAttchmentsInGroupChat().subscribe();
                                break;
                        }
                    }
                    break;
                case 'chatThreadAddOrEdit':
                    if (
                        event?.data?.jiomeetId === this.meetingObj.jiomeetId &&
                        this.groupThreadId !== event?.data?._id
                    ) {
                        this.groupThreadId = event?.data?._id;
                        this.chatTitle = event?.data?.name;
                        this.getMessages();
                    }
                    break;
            }
        });
    }

    getAttachmentsAndUpdateChatCount(threadId: string) {
        this.chatService.getChatAttachments(threadId).subscribe((attachments) => {
            this.attachmentList = (attachments.messages || []).filter((msg) => msg.type === 'message');
            this.updateChatCount();
        });
    }

    updateChatCount() {
        this.attachmentsCount = this.attachmentList.reduce((count, attachment) => {
            return count + (attachment.messageComponent.attachments.length > 0 ? 1 : 0);
        }, 0);
    }

    messageSanitization() {
        let message = '';
        if (this.chatMarkdownComponent?.quill?.container?.innerHTML) {
            let content = this.chatMarkdownComponent.quill.root.innerHTML;
            content = content.replace(/<p>   <\/p>|<p><br><\/p>/g, '');
            const isTableData = this.utilService.isTableData(content);

            if (isTableData) {
                const MessagesComponents = this.splitHTMLString(content);
                MessagesComponents.forEach((element) => {
                    let sanitizedData;
                    if (element.includes('JM_cell')) sanitizedData = this.sanitizeTableData(element);
                    else sanitizedData = this.sanitizeTextData(element);
                    message = message + '\n\n' + sanitizedData;
                });
            } else {
                message = this.sanitizeTextData(content);
            }
        }
        return message.trim();
    }

    splitHTMLString(htmlString) {
        const splitHTML = htmlString.split(/(<p[^>]*>.*?<\/p>|<table[^>]*>.*?<\/table>)/g);
        const cleanedHTML = splitHTML.filter((part) => part.trim() !== '').map((part) => part.trim());
        return cleanedHTML;
    }

    sanitizeTableData(content) {
        let sanitizedMessage = this.turnDownService.turndown(content);
        sanitizedMessage = sanitizeHtml(sanitizedMessage, {
            allowedTags: ['b', 'i', 'em', 'strong', 'a', 's', 'ul', 'ol', 'li', 'table', 'tbody', 'tr', 'td', 'th']
        });
        sanitizedMessage = sanitizedMessage.replace(/<td>\s*<\/td>/g, '<td>&nbsp;</td>');
        sanitizedMessage = sanitizedMessage.replace(/<th>\s*<\/th>/g, '<th>&nbsp;</th>');
        sanitizedMessage = sanitizedMessage.replace(/<td\b[^>]*>(\s|<br\/?>)*<\/td>/g, '<td>&nbsp;</td>');
        sanitizedMessage = sanitizedMessage.replace(/<th\b[^>]*>(\s|<br\/?>)*<\/th>/g, '<th>&nbsp;</th>');

        sanitizedMessage = sanitizedMessage.replace('[](about:blank)', '');
        sanitizedMessage = sanitizedMessage.replace(/\\_/g, '_');
        sanitizedMessage = sanitizedMessage.replace('\\>', '>');
        sanitizedMessage = sanitizedMessage.replace(/\n\*$/g, '');
        return sanitizedMessage;
    }

    sanitizeTextData(content) {
        let sanitizedMessage = this.turnDownService.turndown(content);
        sanitizedMessage = sanitizeHtml(sanitizedMessage, {
            allowedTags: ['b', 'i', 'em', 'strong', 'a', 's']
        });

        sanitizedMessage = sanitizedMessage.trim();

        sanitizedMessage = sanitizedMessage.replace('[](about:blank)', '');
        sanitizedMessage = sanitizedMessage.replace(/\\_/g, '_');
        sanitizedMessage = sanitizedMessage.replace('\\>', '>');
        sanitizedMessage = sanitizedMessage.replace(/\n\*$/g, '');

        return sanitizedMessage;
    }

    async sendMessage(event: KeyboardEvent) {
        if (this.working) {
            return;
        }
        if (this.showDisabledChatToast) {
            this.toastrService.clear();
            this.toastrService.error(this.translateService.instant('inside_call.chat.host-cohost-disabled-chat'));
            return;
        }
        const message = this.messageSanitization();
        if (this.isMessageSizeExceeded(message)) {
            this.showSizeLimitExceededError();
            this.toastCounter = 0;
            return;
        }
        if (message === '' && this.filesUploaded === 0 && this.uploadedItems.length === 0) return;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        let prefix = this.chatMarkdownComponent.isImagePastedFirst ? '1' : '0';
        this.isImagePastedFirst = prefix === '1' ? true : false;
        await this.waitUntilUploadComplete();
        if (this.shouldSendMessage(message)) {
            const attachments = this.getAttachments();
            const encodedMessage = this.utilService.encodeHtmlEntities(message);
            if (encodedMessage === '' && attachments[0].fileName === undefined) return;
            this.sendChatMessage(encodedMessage, attachments);
            this.disableSendButton = true;
        } else {
            if (message == '') {
                this.toastrService.error(this.translateService.instant('tostrs.please_type_a_message_to_continue'));
                this.chatMarkdownComponent.clearMeessage();
            }
        }
    }

    sendChatMessage(encodedMessage, attachments) {
        this.working = true;
        this.chatService
            .sendInCallChatMessage({
                jiomeetId: this.meetingObj.jiomeetId,
                messageComponent: {
                    text: encodedMessage,
                    attachments
                }
            })
            .subscribe(
                (res: any) => {
                    this.enableAttachmentIcon();
                    if (res.success === false) {
                        this.toastrService.error(res.error.errors);
                        if (this.breakoutRoomInfo.breakoutRoomId) {
                            this.gTagService.sendEvent(GA_EVENTS.SEND_MESSAGE_IN_CHAT_FAIL_breakoutroom);
                        } else {
                            this.gTagService.sendEvent(GA_EVENTS.SEND_MESSAGE_IN_CHAT_FAIL);
                            this.gTagService.sendEvent(GA_NEW.SEND_MESSAGE_IN_CHAT_FAIL);
                        }
                        return;
                    }
                    if (res && res.messageComponent && res.messageComponent.attachments.length) {
                        this.attachmentList.unshift(res);
                    }
                    if (this.breakoutRoomInfo?.breakoutRoomId) {
                        this.gTagService.sendEvent(GA_EVENTS.SEND_MESSAGE_IN_CHAT_BREAKOUTROOM);
                        this.gTagService.sendEvent(GA4_EVENTS.BREAKOUT_ROOM_CHAT_SEND_MESSAGE);
                    } else {
                        this.gTagService.sendEvent(GA_EVENTS.SEND_MESSAGE_IN_CHAT);
                        this.gTagService.sendEvent(GA_NEW.SEND_MESSAGE_IN_CHAT);
                    }
                    Object.keys(this.uploadedItems).forEach((key) => {
                        delete this.uploadedItems[key];
                    });
                    if (this.filesUploaded > 0 || this.pastedImageFile) this.attachmentsCount += 1;
                    this.filesUploaded = 0;
                    this.filesUploading = 0;
                    this.fileWithInvalidFileType = false;
                    this.uploadedDocs.nativeElement.style.display = 'none';
                    this.chatMarkdownComponent.isImagePastedFirst = false;
                    this.chatMarkdownComponent.isTextEnteredFirst = false;
                    if (this.pastedImageFile) {
                        const imageWrappers = this.content?.nativeElement?.querySelectorAll('.image-wrapper');
                        imageWrappers.forEach((imageWrapper) => {
                            imageWrapper.remove();
                        });
                        const messagesDiv = this.messagesEle.nativeElement;
                        // this.renderer.setStyle(messagesDiv, 'height', 'calc(100vh - 310px)');
                        setTimeout(() => {
                            this.scrollToBottomAfterAdjustment();
                        }, 1000);
                    } else {
                        const messagesDiv = this.messagesEle.nativeElement;
                        // this.renderer.setStyle(messagesDiv, 'height', 'calc(100vh - 310px)');
                        setTimeout(() => {
                            this.scrollToBottomAfterAdjustment();
                        }, 1000);
                    }
                    this.showMarkdown = false;
                    this.messageToSend = '';
                    this.chatMarkdownComponent.clearMeessage();
                    this.uploadedItems = {};
                    this.working = false;
                    this.chatService.uploadedItems = {};
                },
                (err) => {
                    this.working = false;
                    if (this.breakoutRoomInfo.breakoutRoomId) {
                        this.gTagService.sendEvent(GA_EVENTS.SEND_MESSAGE_IN_CHAT_FAIL_breakoutroom);
                        this.gTagService.sendEvent(GA4_EVENTS.BREAKOUT_ROOM_CHAT_SEND_MESSAGE_FAILED);
                    } else {
                        this.gTagService.sendEvent(GA_EVENTS.SEND_MESSAGE_IN_CHAT_FAIL);
                        this.gTagService.sendEvent(GA_NEW.SEND_MESSAGE_IN_CHAT_FAIL);
                    }
                }
            );
        if (this.input) {
            this.input.nativeElement.value = null;
        }
    }

    getAttachments() {
        var attachments = [];
        const prefix = this.getAttachmentPrefix();
        if (Object.keys(this.uploadedItems).length) {
            attachments = Object.keys(this.uploadedItems).map((attachmentObj: any) => {
                return {
                    imageTrancodedURL: this.uploadedItems[attachmentObj].meetingImageTranscodeUrl || '',
                    streamingURL: this.uploadedItems[attachmentObj].meetingPlaybackUrl || '',
                    documentURL: this.uploadedItems[attachmentObj].meetingDocumentUrl || '',
                    downloadURL: this.uploadedItems[attachmentObj].meetingFileUrl || '',
                    fileName: prefix + '_' + this.uploadedItems[attachmentObj].objectName,
                    fileID: this.uploadedItems[attachmentObj].objectKey,
                    fileSize: '' + this.uploadedItems[attachmentObj].size
                };
            });
        } else if (Object.keys(this.chatService.uploadedItems).length) {
            let uploadedItems = this.chatService.uploadedItems;
            attachments = Object.keys(this.chatService.uploadedItems).map((attachmentObj: any) => {
                let attachedObj = uploadedItems[attachmentObj];
                return {
                    imageTrancodedURL: attachedObj.meetingImageTranscodeUrl || '',
                    streamingURL: attachedObj.meetingPlaybackUrl || '',
                    documentURL: attachedObj.meetingDocumentUrl || '',
                    downloadURL: attachedObj.meetingFileUrl || '',
                    fileName: attachedObj.objectName,
                    fileID: attachedObj.objectKey,
                    fileSize: '' + attachedObj.size
                };
            });
        }
        return this.removeDuplicatesByProperty(attachments, 'fileID');
    }

    getAttachmentPrefix() {
        let prefix = this.chatMarkdownComponent.isImagePastedFirst ? '1' : '0';
        this.isImagePastedFirst = prefix === '1' ? true : false;
        return prefix;
    }

    shouldSendMessage(message) {
        this.disableSendButton = message.length ? false : Object.keys(this.uploadedItems).length ? false : true;
        return (
            (message.trim().length ||
                Object.keys(this.uploadedItems).length ||
                Object.keys(this.chatService.uploadedItems).length) &&
            !this.disableSendButton
        );
    }

    isMessageSizeExceeded(message) {
        const isTable = message.includes('\t');
        const messageLength = isTable ? 0 : message.length;
        if (messageLength >= APP_CONSTANT.CHAT_MESSAGE_LIMIT) {
            this.toastrService.clear();
            this.showSizeLimitExceededError();
            return true;
        }
        return false;
    }

    showSizeLimitExceededError() {
        if (this.toastCounter == 0) {
            this.toastrService.error(
                this.translateService.instant('chat.message_size_limit_exceeded', {
                    value: APP_CONSTANT.CHAT_MESSAGE_LIMIT
                })
            );
            this.toastCounter++;
        }
    }

    waitUntilUploadComplete(): Promise<void> {
        return new Promise((resolve) => {
            const checkUploadComplete = () => {
                if (this.isUploadInProgress()) {
                    setTimeout(checkUploadComplete, 100);
                } else {
                    resolve();
                }
            };
            checkUploadComplete();
        });
    }

    removeDuplicatesByProperty(arr: any[], prop: string): any[] {
        const uniqueKeys = new Set();
        return arr.filter((item) => {
            const key = item[prop];
            uniqueKeys.add(key);
            return true;
        });
    }

    checkBase64String(input) {
        return input.includes('![](data:image/') && input.includes('base64') ? true : false;
    }

    onResized(event: ResizedEvent) {
        this.bottomStyleReducer();
    }

    toggleAttachmentSection() {
        this.showAttachments = !this.showAttachments;
    }

    isUploadInProgress() {
        return Object.keys(this.uploadedItems).filter(
            (attachmentObj: any) => !this.uploadedItems[attachmentObj].uploadSuccess
        ).length;
    }

    uploadFiles(files) {
        if (files.length > 0) this.pastedImageFile = null;
        const file = this.pastedImageFile ? files : files[0];
        if (file.size <= JIO_CLOUD.MAX_FILE_SIZE_ALLOWED_FOR_UPLOAD) {
            file.unique = new Date().valueOf();
            file.totalSize = this.jioCloudUtilService.calculateSize(file.size);
            this.uploadedItems[file.unique] = file;
            if (file.size > JIO_CLOUD.SMALL_FILE_SIZE_LIMIT) {
                return this.chunkUpload(file);
            } else {
                return this.singleFileUpload(file);
            }
        } else {
            this.enableAttachmentIcon();
            this.showMaxFileSizeAlert();
        }
    }

    private async singleFileUpload(file) {
        let success = true;
        try {
            file.hash = await this.jioCloudUtilService.getFileHash(file);
            this.jioCloudService
                .singleFileUpload({
                    file,
                    fileMetadata: this.getMetaData(file),
                    meetingId: this.groupThreadId
                })
                .subscribe(
                    (event: HttpEvent<any>) => {
                        switch (event.type) {
                            case HttpEventType.UploadProgress:
                                this.uploadedItems[file.unique].progress = Math.round(
                                    (event.loaded / event.total) * 100
                                );
                                this.uploadedItems[file.unique].uploadedBytes = this.jioCloudUtilService.calculateSize(
                                    event.loaded
                                );
                                break;
                            case HttpEventType.Response:
                                const metaData = this.jioCloudUtilService.setMeataDataForUploadedFile(event.body);
                                Object.assign(this.uploadedItems[file.unique], {
                                    meetingImageTranscodeUrl: metaData.meetingImageTranscodeUrl,
                                    meetingPlaybackUrl: metaData.meetingPlaybackUrl,
                                    meetingDocumentUrl: metaData.meetingDocumentUrl,
                                    meetingFileUrl: metaData.meetingFileUrl,
                                    objectName: metaData.objectName,
                                    objectKey: metaData.objectKey
                                });
                                this.uploadedItems[file.unique].uploadSuccess = true;
                                this.disableSendButton = false;
                                this.chatMarkdownComponent.overlay.style.display = 'none';
                                success = true;
                                break;
                        }
                    },
                    (err) => {
                        this.uploadFailedNotification(err?.error);
                        success = false;
                    }
                );
        } catch (err) {
            console.error('fileUploadError:', err?.error);
            this.uploadFailedAlert();
            success = false;
        }
        return success;
    }

    uploadFailedNotification(error) {
        switch (error?.code) {
            case 'TEJUM409':
                this.toastrService.warning(
                    'You have exhausted the 5GB storage limit. Please delete the existing files to upload new one.'
                );
                break;
            default:
                this.toastrService.error(error?.error || 'Upload failed');
                break;
        }
    }

    private async chunkUpload(file) {
        let success = true;
        try {
            file.hash = await this.jioCloudUtilService.getFileHash(file);
            this.jioCloudService
                .initiateUpload({
                    fileMetadata: this.getMetaData(file),
                    meetingId: this.groupThreadId
                })
                .subscribe(
                    (event: any) => {
                        switch (event.type) {
                            case HttpEventType.Response:
                                if (event.status === 200) {
                                    let response = event.body;
                                    this.uploadedItems[file.unique].uploadedBytes =
                                        this.jioCloudUtilService.calculateSize(response.offset);
                                    this.startChunkUpload(
                                        file,
                                        response.offset,
                                        JIO_CLOUD.FIRST_CHUNK_SIZE,
                                        response.transactionId,
                                        null,
                                        true
                                    );
                                }
                                if (event.status === 201) {
                                    // upload done, process next file
                                    // this.uploadedItems[
                                    //   file.unique
                                    // ].info = this.jioCloudUtilService.setMeataDataForUploadedFile(event.body);
                                    const metaData = this.jioCloudUtilService.setMeataDataForUploadedFile(event.body);
                                    Object.assign(this.uploadedItems[file.unique], {
                                        meetingImageTranscodeUrl: metaData.meetingImageTranscodeUrl,
                                        meetingPlaybackUrl: metaData.meetingPlaybackUrl,
                                        meetingDocumentUrl: metaData.meetingDocumentUrl,
                                        meetingFileUrl: metaData.meetingFileUrl,
                                        objectName: metaData.objectName,
                                        objectKey: metaData.objectKey
                                    });
                                }
                                this.uploadedItems[file.unique].uploadSuccess = true;
                                this.disableSendButton = false;
                                this.chatMarkdownComponent.overlay.style.display = 'none';
                                success = true;
                                break;
                        }
                    },
                    (err) => {
                        this.uploadFailedNotification(err?.error);
                        success = false;
                    }
                );
        } catch (err) {
            console.error('fileChunkUploadError:', err?.error);
            this.uploadFailedAlert();
            success = false;
        }
        return success;
    }

    private startChunkUpload(file, offset, chunkSize, transactionId, chunkUploadTime, isStart = false) {
        if (!isStart) {
            chunkSize = this.jioCloudUtilService.getChunkSize(chunkUploadTime, chunkSize, file.size, offset);
        }

        try {
            const chunkUploadStartTime = new Date().getTime();
            const chunk1 = File.prototype.slice.call(file, offset, offset + chunkSize);
            const reader = new FileReader();
            reader.onload = async (data: any) => {
                const chunk = data.target.result;
                const chunkChecksum = await this.jioCloudUtilService.getChunkCheckSum(chunk);
                this.jioCloudService
                    .uploadChunk(chunk, offset, chunkChecksum, transactionId, this.groupThreadId)
                    .subscribe(
                        (event: any) => {
                            switch (event.type) {
                                case HttpEventType.Response:
                                    if (event.status === 200) {
                                        const response = event.body;
                                        this.uploadedItems[file.unique].progress = Math.round(
                                            (response.offset / file.size) * 100
                                        );
                                        this.uploadedItems[file.unique].uploadedBytes =
                                            this.jioCloudUtilService.calculateSize(response.offset);
                                        this.startChunkUpload(
                                            file,
                                            response.offset,
                                            chunkSize,
                                            response.transactionId,
                                            new Date().getTime() - chunkUploadStartTime
                                        );
                                    }
                                    if (event.status === 201) {
                                        this.uploadedItems[file.unique].progress = 100;
                                        const metaData = this.jioCloudUtilService.setMeataDataForUploadedFile(
                                            event.body
                                        );
                                        Object.assign(this.uploadedItems[file.unique], {
                                            meetingImageTranscodeUrl: metaData.meetingImageTranscodeUrl,
                                            meetingPlaybackUrl: metaData.meetingPlaybackUrl,
                                            meetingDocumentUrl: metaData.meetingDocumentUrl,
                                            meetingFileUrl: metaData.meetingFileUrl,
                                            objectName: metaData.objectName,
                                            objectKey: metaData.objectKey
                                        });
                                    }
                                    this.uploadedItems[file.unique].uploadSuccess = true;
                                    break;
                            }
                        },
                        (err) => {
                            this.uploadFailedNotification(err?.error);
                        }
                    );
            };
            reader.onerror = (event) => {
                reader.abort();
            };
            reader.readAsArrayBuffer(chunk1);
        } catch (err) {
            console.error('startChunkUploadError:', err?.error);
            throw err;
        }
    }

    getMetaData(file) {
        return {
            name: file.name,
            size: file.size,
            hash: file.hash,
            folderKey: this.jiocloudAuthService.getAuthInfo().rootFolderKey
        };
    }

    async fileChangeEvent(event) {
        if (!this.isFileSupported(event.target.files[0])) {
            this.confirmationService.confirm({
                message: this.translateService.instant('tostrs.file_type_not_supported'),
                header: this.translateService.instant('tostrs.alert'),
                acceptLabel: this.translateService.instant('tostrs.ok'),
                rejectVisible: false,
                accept: () => {},
                reject: () => {}
            });
            this.utilService.setTimeOutForAccessibilityPopUp();
            this.filesUploading += 1;
            return;
        }
        // this.disableAttachmentIcon();
        this.textArea?.nativeElement?.focus();
        if (event.target.files.length) {
            if (await this.uploadFiles(event.target.files)) {
                this.filesUploaded += 1;
                this.filesUploading += 1;
            }
        }
        if (this.filesUploaded !== 0) {
            this.uploadedDocs.nativeElement.style.display = 'block';
            this.disableSendButton = false;
            this.attachmentIcon.nativeElement.blur();
            this.chatMarkdownComponent.quill.focus();
        }
    }

    handleChatMartkdownOutput(event) {
        this.messageToSend = event;
    }

    isFileSupported(file) {
        return this.jioCloudUtilService.getFileFormat(file.name) !== 'None';
    }

    showMaxFileSizeAlert() {
        this.confirmationService.confirm({
            message: this.translateService.instant('tostrs.maximum_file_size_allowed_is_30mb', {
                value: 30
            }),
            header: this.translateService.instant('tostrs.alert'),
            acceptLabel: this.translateService.instant('tostrs.ok'),
            rejectVisible: false,
            accept: () => {},
            reject: () => {}
        });
        this.utilService.setTimeOutForAccessibilityPopUp();
        if (this.filesUploaded === 0) this.uploadedDocs.nativeElement.style.display = 'none';
    }

    uploadFailedAlert() {
        this.confirmationService.confirm({
            message: this.translateService.instant('tostrs.file_uploading_failed'),
            header: this.translateService.instant('tostrs.alert'),
            acceptLabel: this.translateService.instant('tostrs.ok'),
            rejectVisible: false,
            accept: () => {},
            reject: () => {}
        });
        this.utilService.setTimeOutForAccessibilityPopUp();
    }

    getAttachedFilesCount() {
        var count = [].concat.apply(
            [],
            this.attachmentList.map((msg) => msg.messageComponent.attachments)
        ).length;
        return count;
    }

    resetEnablingAttachments(files) {
        const file = files[0];
        if (file.size > JIO_CLOUD.MAX_FILE_SIZE_ALLOWED_FOR_UPLOAD) {
            this.enableAttachmentIcon();
            this.chatService.showMaxFileSizeAlert();
        }
    }

    // removeAttchment(event, file) {
    //     delete this.uploadedItems[file.unique];
    //     this.enableAttachmentIcon();
    // }

    removeAttchment(event, file) {
        if (this.uploadedItems[file.unique].uploadSuccess) this.filesUploaded -= 1;
        delete this.uploadedItems[file.unique];
        //this.enableAttachmentIcon();
        this.filesUploading -= 1;
        if (this.filesUploading === 0) {
            this.uploadedDocs.nativeElement.style.display = 'none';
            this.filesUploaded = 0;
        }
    }

    trackByFuntion(index, item) {
        return index;
    }

    trackMessages(index, item) {
        return item._id;
    }

    getProfilePic(userId) {
        return this.utilService.getProfilePic(userId);
    }

    closeView() {
        if (this.isThirdPartyExternalIntegration && this.meetingObj) {
            this.thirdPartyExternalIntegrationService.enableBackNavigationOnHomePage();
            this.meetingObj.hideAllCallControls = false;
        }
        this.close.emit();
        if (this.breakoutRoomInfo?.breakoutRoomId) {
            this.gTagService.sendEvent(GA_EVENTS.CLOSE_CHAT_BREAKOUTROOM);
            this.gTagService.sendEvent(GA4_EVENTS.BREAKOUT_ROOM_CHAT_CLOSE);
        } else {
            this.gTagService.sendEvent(GA_EVENTS.CLOSE_CHAT);
            this.gTagService.sendEvent(GA_NEW.CLOSE_CHAT);
        }
    }

    popoutChat() {
        this.closeView();
        this.eventEmitterService.emit({ type: 'POPOUT_CHAT', data: true });
        if (!this.breakoutRoomInfo?.breakoutRoomId) {
            this.gTagService.sendEvent(GA_NEW.POP_OUT_CHAT);
        }
        this.gTagService.sendEvent(GA_EVENTS.POP_OUT_CHAT);
        if (this.breakoutRoomInfo?.breakoutRoomId) {
            this.gTagService.sendEvent(GA4_EVENTS.BREAKOUT_ROOM_CHAT_POP_OUT);
        }
    }

    deleteItem(message) {
        this.confirmationService.confirm({
            message: this.translateService.instant('tostrs.are_you_sure_you_want_to_delete_this_file'),
            header: this.translateService.instant('tostrs.delete_file'),
            acceptLabel: this.translateService.instant('tostrs.delete'),
            rejectLabel: this.translateService.instant('tostrs.cancel'),
            acceptButtonStyleClass: 'custom-button bg-primary',
            rejectButtonStyleClass: 'custom-button bg-secondary',
            accept: () => {
                this.chatService
                    .deleteFromJioMeetAndJioCloud(
                        message.threadId,
                        message._id,
                        message.messageComponent.attachments[0].fileID,
                        this.groupThreadId
                    )
                    .subscribe((resp) => {});
            },
            reject: () => {}
        });
        this.utilService.setTimeOutForAccessibilityPopUp();
    }

    fileInfo(message) {
        this.eventEmitterService.emit({
            type: APP_EVENTS.OPEN_FILE_INFO,
            data: {
                name: message.messageComponent.attachments[0].fileName,
                size: message.messageComponent.attachments[0].fileSize,
                sharedBy: `${message.creatorsName} ${message.creatorsLName}`,
                createdOn: message.cOn
            }
        });
    }

    downloadFile(message) {
        if (this.chatService.activeChatMembers?.length > 0) {
            let creator = find(this.chatService.activeChatMembers, ['_id', message?.createdBy]);
            if (this.utilService.canDownloadOrPreviewAttachments(creator, this.currentUser)) {
                this.toastrService.error(this.translateService.instant('tostrs.downloading_a_file_is_not_allowed'));
                return;
            }
        }
        this.toastrService.success(this.translateService.instant('tostrs.downloading_in_progress'));
        const files = message.messageComponent.attachments;
        (files || []).map((file) => {
            this.jioCloudService.downloadFile(file.fileName, file.downloadURL, this.groupThreadId).subscribe((resp) => {
                FileSaver.saveAs(resp, file.fileName);
                this.toastrService.success(this.translateService.instant('tostrs.download_completed'));
            });
        });
    }

    disableAttachmentIcon() {
        if (this.attachmentIcon) {
            this.attachmentIcon?.nativeElement.setAttribute('class', 'disabled');
        }
        if (this.openFileMenu) {
            this.openFileMenu?.nativeElement.setAttribute('disabled', true);
        }
    }

    enableAttachmentIcon() {
        if (this.attachmentIcon) {
            this.attachmentIcon?.nativeElement.setAttribute('class', '');
        }
        if (this.openFileMenu) {
            this.openFileMenu?.nativeElement.removeAttribute('disabled');
        }
    }

    resetLastSelectedFileValue(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        inputElement.value = '';
    }

    showInCallChatPopUp(message, type) {
        this.eventEmitterService.emit({ type: type, data: message });
    }
    onOutsideClick(event) {
        if (this.dropdown) {
            this.dropdown = false;
        }
    }
    generateToast(toastObject, type) {
        if (this.isJMMeeting && this.isLargeMeeting && type === 'PRIVATE_CHAT') {
            return;
        }
        if (document.getElementsByTagName('app-chat-toast')?.length && type !== 'PRIVATE_CHAT') {
            return;
        }

        const component: ComponentRef<ChatToastComponent> = this.factoryService.generateComponent(
            this.viewHost,
            ChatToastComponent
        );
        component.instance.toastObject = toastObject;
        component.instance.messageType = type;
        component.instance.unreadParticipantCount = this.unreadChatParticipantIds.filter(
            (uId) => uId !== this.userID
        ).length;
        component.instance.isLargeMeeting = this.isJMMeeting && this.isLargeMeeting;
        component.instance.closeEmitter.subscribe(() => component.destroy());
        component.instance.clickEmitter.subscribe((res) => {
            if (res.type === 'PRIVATE_CHAT') {
                this.switchToPrivateChat(toastObject);
            } else if (res.type === 'NEW_MESSAGE') {
                this.scrollToBottom(true);
            }
            component.destroy();
        });
    }
    switchToPrivateChat(participant) {
        this.loggerTrigger = false;
        this.chatService.clearNotifiedParticipants(participant._id);
        const component: ComponentRef<PrivateChatV2Component> = this.factoryService.generateComponent(
            this.viewHost,
            PrivateChatV2Component
        );
        this.factoryService.updateInstanceCount(component);
        component.instance.jiomeetId = this.meetingObj.jiomeetId;
        component.instance.recipient = participant;
        component.instance.senderId = this.currentUser?._id || this.guestUser?.guestUserId;
        component.instance.isAuthenticated = this.isAuthenticated;
        component.instance.popout = this.popout;
        const { nativeElement } = component.location;
        component.instance.afterClose.subscribe((res) => {
            this.loggerTrigger = true;
            this.factoryService.destroyAllPrivateChatInstances();
            if (res?._id) {
                this.timeouts.push(
                    setTimeout(() => {
                        this.switchToPrivateChat(res);
                    }, 0)
                );
            }
        });
    }

    switchToAttachmentSection() {
        const component = this.compFactory.resolveComponentFactory(AttachmentListComponent);
        const componentRef = this.viewHost.viewContainerRef.createComponent(component);
        componentRef.instance.threadId = this.groupThreadId;
        componentRef.instance.afterClose.subscribe((res) => {
            componentRef.destroy();
        });
    }

    toggleEmojis(event) {
        setTimeout(() => {
            const popUpElement: any = document.getElementsByClassName('emojis')[0];
            if (popUpElement) {
                popUpElement?.focus();
            }
        }, 100);

        this.selectedEmoji = !this.selectedEmoji;
    }

    toggleMarkdown() {
        setTimeout(() => {
            const popUpElement: any = document.getElementsByClassName('chat-window')[0];
            if (popUpElement) {
                popUpElement.focus();
            }
        }, 100);
        this.showMarkdown = !this.showMarkdown;
    }

    bottomStyleReducer() {
        $('.messages').css('bottom', `${$('.attachments').height()}px`);
        this.handleChatDisableScrollerChange();
        this.resetHeightForDynamicScroller();
    }

    toggleMoreOptions(message) {
        // this.showMoreOptions = !this.showMoreOptions;
        message.showMoreOptions = !message.showMoreOptions;
    }

    clickOutside(message) {
        message.showMoreOptions = false;
    }

    showMoreMessages(event) {
        let loadMoreMessages = this.noOfMessagesLoaded % 20 === 0;
        const scrollTopThreshold = 10;
        const scrollContainer = event.target;
        const shouldLoadMore = scrollContainer.scrollTop < scrollTopThreshold && this.areMoreMessagesAvailable;
        if (this.showLoadingMessages) {
            return;
        }
        if (shouldLoadMore && loadMoreMessages) {
            this.showLoadingMessages = true;
            this.loadMessages(event);
        }
    }

    loadMessages(event) {
        this.fetchAllchatMsgsSub = this.chatService
            .fetchAllMessages(this.meetingObj.jiomeetId, 20, this.noOfMessagesLoaded)
            .subscribe(
                (data: any) => {
                    clearInterval(this.getMessagesPool);
                    this.areMoreMessagesAvailable = data?.messages?.length > 0;
                    this.showLoadingMessages = false;
                    this.noOfMessagesLoaded += data.messages?.length;
                    const chats = this.filterChats(data);
                    // this.handleLoadedMessages(chats, event);
                    this.isJMMeeting && this.isLargeMeeting
                        ? this.handleLargeMeetingMessages(chats, event)
                        : this.handleLoadedMessages(chats, event);
                    this.getAttchmentsInGroupChat().subscribe();
                },
                (err) => {
                    if (err?.error?.message === 'LIMIT_EXCEEDED') {
                        this.showLoadingMessages = false;
                        if (this.isJMMeeting && this.isLargeMeeting) {
                            this.chatMessages = this.chatService.inCallChatMessages;
                        } else {
                            this.chatGroupsByDate = this.chatService.inCallChatMessages;
                        }
                    }
                    if (!this.getMessagesPool) {
                        this.getMessagesPool = setInterval(() => {
                            this.getMessages(true);
                        }, 2000);
                    }

                    this.appLoggerService.error(
                        'Get chat messages in call error: ',
                        new Error(JSON.stringify(err?.error?.errors)),
                        err
                    );
                }
            );
    }

    filterChats(data) {
        return (data.messages || []).filter((msg) => msg.type === 'message');
    }

    handleLargeMeetingMessages(chats, event) {
        this.oldScrollHeight = event?.target?.scrollHeight;
        let newChatMessages = this.chatService.groupChatMessagesWithDate(chats);
        let currChatMessages = [];
        if (newChatMessages[0]?.key === this.chatMessages[0]?.key) {
            newChatMessages = [this.chatMessages[0], ...newChatMessages.slice(1, newChatMessages?.length)];
            currChatMessages = this.chatMessages.slice(1, this.chatMessages?.length);
        }
        this.chatMessages = [...newChatMessages, ...currChatMessages];
        setTimeout(() => {
            document.getElementsByClassName('chat-group-large-meeting')[0].scrollTop =
                event?.target?.scrollHeight - this.oldScrollHeight;
        }, 100);
        this.chatService.inCallChatMessages = this.chatMessages;
    }

    handleLoadedMessages(chats, event) {
        this.oldScrollHeight = event?.target?.scrollHeight;
        let newChatsLoaded = this.chatService.groupChatMessagesByDate(chats);
        const matchingKey = newChatsLoaded[newChatsLoaded?.length > 0 ? newChatsLoaded?.length - 1 : 0]?.key;
        const matchingIndex = this.chatGroupsByDate?.findIndex((item) => item?.key === matchingKey);

        if (matchingIndex === 0) {
            this.addChatToExistingAndOtherArrays(newChatsLoaded);
        } else if (matchingIndex === -1) {
            this.chatGroupsByDate = [...newChatsLoaded, ...this.chatGroupsByDate];
        }
        setTimeout(() => {
            document.getElementsByClassName('chat-group-container')[0].scrollTop =
                event?.target?.scrollHeight - this.oldScrollHeight;
        }, 100);
        this.chatService.inCallChatMessages = this.chatGroupsByDate;
    }

    addChatToExistingAndOtherArrays(newChats) {
        this.chatGroupsByDate[0].value = [
            ...this.chatGroupsByDate[0].value,
            ...newChats[newChats.length > 0 ? newChats.length - 1 : 0].value
        ];
        this.chatGroupsByDate =
            newChats.length > 1
                ? [...newChats.slice(0, newChats.length - 1), ...this.chatGroupsByDate]
                : this.chatGroupsByDate;
    }

    private scrollToBottom(force = false) {
        this.timeouts.push(
            setTimeout(() => {
                let ele;
                if (this.isJMMeeting && this.isLargeMeeting) {
                    if (this.virtualChatList['element']) {
                        ele = this.virtualChatList['element']?.nativeElement;
                    }
                } else {
                    ele = this.messagesEle?.nativeElement;
                }

                if (force && ele) {
                    ele.scrollTop = ele.scrollHeight;
                } else {
                    if (this.previousScrollHeight - ele.scrollTop - ele.clientHeight < 10 && ele) {
                        ele.scrollTop = ele.scrollHeight;
                        this.previousScrollHeight = ele.scrollHeight;
                    } else {
                        // show new message toast
                        this.generateToast({}, 'NEW_MESSAGE');
                    }
                }
            }, 300)
        );
    }

    getParticipantName(participant) {
        let name = participant.participantName;
        return participant.signIn === 'Guest'
            ? name.substring(0, name.length - 8) +
                  ' (' +
                  this.translateService.instant('inside_call.participants_controls.guest') +
                  ')'
            : participant.participantName;
    }

    getPastedImageFile(eventData: { file: File; imageWrapper: HTMLElement }) {
        this.pastedImageFile = eventData.file;
        this.imageWrapper = eventData.imageWrapper;
        this.uploadFiles(this.pastedImageFile);
    }
    disablingSendButton(eventData: { status: boolean }) {
        this.disableSendButton = eventData.status;
    }
    deletedImage() {
        this.uploadedItems = {};
        this.chatService.uploadedItems = {};
    }
    isDesktopApp() {
        return this.appService.isDesktopApp();
    }
    private scrollToBottomAfterAdjustment(force = false) {
        this.timeouts.push(
            setTimeout(() => {
                const ele = this.messagesEle.nativeElement;
                ele.scrollTop = ele.scrollHeight;
            }, 1000)
        );
    }

    onChatDropdownChange(event) {
        this.selectedChatOption = event?.target?.value;
        this.roomConnectionService
            .setChatStatusForAllUsers({ jiomeetId: this.meetingObj?.jiomeetId, restrictChat: this.selectedChatOption })
            .subscribe(
                (res) => {
                    switch (this.selectedChatOption) {
                        case 'ALL':
                            this.toastrService.success(
                                this.translateService.instant('tostrs.chat_disabled_for_everyone')
                            );
                            break;

                        case 'PARTICIPANTS':
                            this.toastrService.success(
                                this.translateService.instant('tostrs.chat_disabled_for_participants')
                            );
                            break;

                        case 'NONE':
                            this.toastrService.success(
                                this.translateService.instant('tostrs.chat_enabled_for_everyone')
                            );
                            break;

                        default:
                            break;
                    }
                },
                (err) => {
                    this.toastrService.error(this.translateService.instant('tostrs.failed_to_change_chat_status'));
                }
            );
    }

    captureMessage(message) {
        this.messageId = message?._id;
    }
    showOptions(mode) {
        this.show_options = true;
        if (mode === 1) this.mouse_hover_options = true;
    }

    hideOptions(mode) {
        if (mode === 1) this.mouse_hover_options = false;
        if (!this.show_delete && !this.mouse_hover_options) {
            this.show_options = false;
            this.show_delete = false;
            this.messageId = '';
        }
    }
    show_Options(message) {
        if (!this.deletedMessages.includes(message._id) && message?._id !== this.messageId) {
            this.show_options = true;
            this.messageId = message._id;
            this.show_delete = false;
        }
    }

    resetSettings(messageId) {
        if (!this.mouse_hover_options && messageId === this.messageId) {
            this.show_options = false;
            this.show_delete = false;
            this.messageId = '';
        }
    }

    showDelete() {
        this.show_delete = true;
    }

    deleteMessagesFromChat(msg) {
        const threadId = msg?.threadId;
        const messageId = msg?._id;
        this.chatService.deleteMessagesFromChat(threadId, messageId).subscribe(
            (data) => {
                console.log('Message deletion success', data);
                this.deletedMessages.push(messageId);
                this.resetSettings(messageId);
                this.isMsgDeleted = true;
                this.currentTime = new Date();
                this.justDeleted = true;
                this.chatService.notifyDeletion(msg._id);
            },
            (err) => {
                console.error('Message deletion failure', err);
            }
        );
    }

    adjustMessagesHeight(eventData: { functionName: string; imageHeight: number }) {
        if (
            eventData.functionName.includes('adjustImageDimensions') ||
            eventData.functionName.includes('deleteImage')
        ) {
            const messagesDiv = this.messagesEle.nativeElement;
            if (eventData.imageHeight > 0) {
                const currentHeight = messagesDiv.offsetHeight;
                const newHeight = currentHeight - eventData.imageHeight;
                messagesDiv.style.height = `${newHeight}px`;
            } else {
                this.renderer.setStyle(messagesDiv, 'height', 'calc(100vh - 270px)');
            }
            this.scrollToBottomAfterAdjustment();
        }
    }

    @HostListener('click', ['$event'])
    messageClicked(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (target.tagName === 'A' && target.getAttribute('href')?.startsWith('mailto:')) {
            event.preventDefault();
        }
    }

    ngOnDestroy() {
        if (this.isThirdPartyExternalIntegration) {
            this.thirdPartyEventSubscriptions.forEach((subscription) => subscription.unsubscribe());
        }
        this.eventEmitterService.emit({
            type: APP_EVENTS.SHOW_UNSEEN_COUNT,
            data: true
        });
        if (this.fetchAllchatMsgsSub) {
            this.fetchAllchatMsgsSub.unsubscribe();
        }
        if (this.getMessagesPool) {
            clearInterval(this.getMessagesPool);
        }
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.timeouts.forEach((id) => clearTimeout(id));
    }
}
