import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_EVENTS } from 'src/app/constants';
import { EventData, EventEmitterService, UtilService, GoogleTagManagerService, AuthService } from 'src/app/core';
import { GA_EVENTS } from '../ga-events';
import { GA_PAGEVIEW } from '../ga-pageview';
import { GA4_EVENTS } from '../ga4-events';

@Component({
    selector: 'app-website-healthcare',
    templateUrl: './website-healthcare.component.html',
    styleUrls: ['../website.usecases.scss']
})
export class WebsiteHealthcareComponent implements OnInit {
    isAuthenticated: any;
    isMobile = false;
    healthcareFeatures = [
        {
            title: 'Online OPDs in hospitals',
            ttitle: 'website_healthcare.online_opds',
            imgMob: 'assets/img/healthcare/online-opd-mobile.png',
            imgDesktop: 'assets/img/healthcare/online-opd.png',
            altText: 'Online OPDs in hospitals',
            padding0: true,
            desc: [
                {
                    text: 'Multiple hospitals across India are using JioMeet for both online OPD bookings and as well as online consultations.',
                    ttext: 'website_healthcare.online_consultation_across_india'
                },
                {
                    text: 'All doctor patient meetings and prescriptions are auto-recorded for future reference of doctors.',
                    ttext: 'website_healthcare.auto_record_doc_patient_meet'
                }
            ]
        },
        {
            title: 'Enhancing the reach of super specialists',
            ttitle: 'website_healthcare.enhance_reach_doc',
            imgDesktop: 'assets/img/healthcare/reach-specialists.png',
            imgMob: 'assets/img/healthcare/reach-specialists-mobile.png',
            altText: 'Jiomeet Enhancing the reach of super specialists',
            desc: [
                {
                    text: 'For complicated cases, doctors can add remote super specialist doctors during their discussion with patient to ensure the best treatment.',
                    ttext: 'website_healthcare.add_remote_docs'
                }
            ]
        },
        {
            title: 'Online counselling sessions for mental health',
            ttitle: 'website_healthcare.online_counselling',
            imgDesktop: 'assets/img/healthcare/online-counselling.png',
            imgMob: 'assets/img/healthcare/online-counselling-mobile.png',
            altText: 'Doctor handling online counselling sessions for mental health',
            desc: [
                {
                    text: 'A number of Psychiatrists/Psychologists use JioMeet for their online sessions.',
                    ttext: 'website_healthcare.jiomeet_used_by_many_docs'
                },
                {
                    text: 'High-quality video, Whiteboard, and Share screen allows them to understand patient condition and demonstrate the physical exercises.',
                    ttext: 'website_healthcare.hd_video_screenshare'
                }
            ]
        },
        {
            title: 'Patient Privacy',
            ttitle: 'website_healthcare.patient_privacy',
            imgMob: 'assets/img/healthcare/patient-privacy-mobile.png',
            imgDesktop: 'assets/img/healthcare/patient-privacy.png',
            altText: 'Doctor and patient having online consultation',
            padding0: true,
            desc: [
                {
                    text: 'A number of patients are more willing to get the medical help they need, if it is done in privacy of their home.',
                    ttext: 'website_healthcare.privacy_facility_at_home'
                },
                {
                    text: 'Improving access to health care by facilitating in-home remote consultations.',
                    ttext: 'website_healthcare.in_home_remote_consultation'
                }
            ]
        },
        {
            title: 'Patient Records',
            ttitle: 'website_healthcare.patient_records',
            imgDesktop: 'assets/img/healthcare/patient-records.png',
            imgMob: 'assets/img/healthcare/patient-records-mobile.png',
            altText: 'Patients medical record',
            desc: [
                {
                    text: 'Doctors can access the complete patient record during the online session.',
                    ttext: 'website_healthcare.access_patient_record_online'
                },
                {
                    text: 'Doctors can prescribe medicines online.',
                    ttext: 'website_healthcare.prescribe_medicines_online'
                }
            ]
        },
        {
            title: 'Online yoga, meditation, aerobics',
            ttitle: 'website_healthcare.online_yoga',
            imgDesktop: 'assets/img/healthcare/online-meditation.png',
            imgMob: 'assets/img/healthcare/online-meditation-mobile.png',
            altText: 'Online yoga, meditation, aerobics classes',
            desc: [
                {
                    text: 'Online classes are preferred as compared to offline classes as the willpower required for an online class is lower than physical classes. You can find several online classes on JioMeet Events.',
                    ttext: 'website_healthcare.online_classes'
                }
            ]
        },
        {
            title: 'Powering Top Health-Tech Platforms',
            ttitle: 'website_healthcare.top_health_tech_platforms',
            imgDesktop: 'assets/img/healthcare/top-healthtech-platforms.png',
            imgMob: 'assets/img/healthcare/top-healthtech-platforms-mobile.png',
            altText: 'JiOHealtHub a Health-Tech Platform',
            desc: [
                {
                    text: 'Enabling healthcare platforms to build live consultations amongst stakeholders across doctors, patients, and medical representatives.',
                    ttext: 'website_healthcare.live_consultation_among_doc_patient'
                }
            ]
        }
    ];

    constructor(
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private gTagService: GoogleTagManagerService,
        private router: Router,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser(1080);
        this.translateHealthcareSolutions();
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 1080 ? false : true;
            }
        });
        this.authService.getIsAuthenticated$().subscribe((authStatus) => {
            this.isAuthenticated = authStatus;
        });
        this.gTagService.pushOnDataLayer(GA_PAGEVIEW.USECASE_HEALTHCARE);
    }

    async translateHealthcareSolutions() {
        const HealthcareSolutions = await this.utilService.handleDataTranslation('website_healthcare', {
            HealthcareSolutions: this.healthcareFeatures
        });
        this.healthcareFeatures = HealthcareSolutions['HealthcareSolutions'];
    }

    navigateToSignUp() {
        this.gTagService.sendEvent(GA_EVENTS.USECASE_HEALTHCARE_SIGNUP);
        this.gTagService.sendEvent(GA4_EVENTS.USECASE_HEALTHCARE_SIGNUP);
        if (this.isAuthenticated) {
            this.router.navigate(['dashboard']);
        } else {
            this.router.navigate(['signup']);
        }
    }

    goToDownloadApp() {
        this.gTagService.sendEvent(GA_EVENTS.USECASE_HEALTHCARE_DOWNLOAD);
        this.gTagService.sendEvent(GA4_EVENTS.USECASE_HEALTHCARE_DOWNLOAD);
        this.router.navigate(['jiomeetDownload']);
    }
}
