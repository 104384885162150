import { Component, Input, OnInit } from '@angular/core';
import { APP_EVENTS } from 'src/app/constants';
import { EventData, EventEmitterService, UtilService } from 'src/app/core';

@Component({
    selector: 'app-usecase-features',
    templateUrl: './usecase-features.component.html',
    styleUrls: ['./usecase-features.component.scss']
})
export class UsecaseFeaturesComponent implements OnInit {
    @Input() useCaseFeature: any = {};
    @Input() featureIndex: number;
    isMobile = false;

    constructor(private utilService: UtilService, private eventEmitterService: EventEmitterService) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser(1080);
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 1080 ? false : true;
            }
        });
    }
}
