export const GA_EVENTS = {
    CONTACT_SALES_SUBMIT_ERROR: {
        event: 'jiomeet',
        event_category: 'Sales_JioMeet',
        event_action: 'Submit',
        event_label: 'Error'
    },
    CONTACT_SALES_SUBMIT_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sales_JioMeet',
        event_action: 'Submit',
        event_label: 'Success'
    },
    CONTACT_SALES_SUBMIT_FAILURE: {
        event: 'jiomeet',
        event_category: 'Sales_JioMeet',
        event_action: 'Submit',
        event_label: 'Failure'
    },
    CONTACT_SALES_FORM_NOT_SUBMITTED: {
        event: 'jiomeet',
        event_category: 'Sales_JioMeet',
        event_action: 'User_not_submitted_sales_form',
        event_label: 'Success'
    },
    JOIN_MEETING_VIDEO_ON: {
        event: 'jiomeet',
        event_category: 'Join a Meeting ',
        event_action: 'Join a meeting video on',
        event_label: 'Success'
    },
    JOIN_MEETING_VIDEO_OFF: {
        event: 'jiomeet',
        event_category: 'Join a Meeting ',
        event_action: 'Join a meeting video off',
        event_label: 'Success'
    },
    JOIN_MEETING_AUDIO_ON: {
        event: 'jiomeet',
        event_category: 'Join a Meeting ',
        event_action: 'Join a meeting audio on',
        event_label: 'Success'
    },
    JOIN_MEETING_AUDIO_OFF: {
        event: 'jiomeet',
        event_category: 'Join a Meeting ',
        event_action: 'Join a meeting audio off',
        event_label: 'Success'
    },
    JOIN_MEETING_WITH_URL: {
        event: 'jiomeet',
        event_category: 'Join a Meeting ',
        event_action: 'Join a meeting with meeting url',
        event_label: 'Success'
    },
    JOIN_MEETING_WITH_ID_AND_PWD: {
        event: 'jiomeet',
        event_category: 'Join a Meeting ',
        event_action: 'Join a meeting with meeting id and password',
        event_label: 'Success'
    },
    JOIN_MEETING_WITH_DEEPLINKING: {
        event: 'jiomeet',
        event_category: 'Join a Meeting ',
        event_action: 'Join a meeting through deeplinking',
        event_label: 'Success'
    },
    JOIN_MEETING_ERROR: {
        event: 'jiomeet',
        event_category: 'Join a Meeting ',
        event_action: 'Join a meeting error',
        event_label: 'Failure'
    },
    JOIN_MEETING_VIDEO_ON_GUEST: {
        event: 'jiomeet',
        event_category: 'Join a Meeting ',
        event_action: 'Join a meeting video on',
        event_label: 'Success_Guest'
    },
    JOIN_MEETING_VIDEO_OFF_GUEST: {
        event: 'jiomeet',
        event_category: 'Join a Meeting ',
        event_action: 'Join a meeting video off',
        event_label: 'Success_Guest'
    },
    JOIN_MEETING_AUDIO_ON_GUEST: {
        event: 'jiomeet',
        event_category: 'Join a Meeting ',
        event_action: 'Join a meeting audio on',
        event_label: 'Success_Guest'
    },
    JOIN_MEETING_AUDIO_OFF_GUEST: {
        event: 'jiomeet',
        event_category: 'Join a Meeting ',
        event_action: 'Join a meeting audio off',
        event_label: 'Success_Guest'
    },
    JOIN_MEETING_WITH_URL_GUEST: {
        event: 'jiomeet',
        event_category: 'Join a Meeting ',
        event_action: 'Join a meeting with meeting url',
        event_label: 'Success_Guest'
    },
    JOIN_MEETING_WITH_ID_AND_PWD_GUEST: {
        event: 'jiomeet',
        event_category: 'Join a Meeting ',
        event_action: 'Join a meeting with meeting id and password',
        event_label: 'Success_Guest'
    },
    JOIN_MEETING_WITH_DEEPLINKING_GUEST: {
        event: 'jiomeet',
        event_category: 'Join a Meeting ',
        event_action: 'Join a meeting through deeplinking',
        event_label: 'Success_Guest'
    },
    SIGNIN_WITH_EMAIL: {
        event: 'jiomeet',
        event_category: 'Sign In',
        event_action: 'Sign in with Email',
        event_label: 'Success'
    },
    SIGNIN_WITH_EMAIL_FAIL: {
        event: 'jiomeet',
        event_category: 'Sign In',
        event_action: 'Sign in with Email',
        event_label: 'Failure'
    },
    SIGNIN_WITH_MOBILE: {
        event: 'jiomeet',
        event_category: 'Sign In',
        event_action: 'Sign in with Mobile',
        event_label: 'Success'
    },
    SIGNIN_WITH_MOBILE_FAIL: {
        event: 'jiomeet',
        event_category: 'Sign In',
        event_action: 'Sign in with Mobile',
        event_label: 'Failure'
    },
    VERIFY_PASSWORD: {
        event: 'jiomeet',
        event_category: 'Sign In',
        event_action: 'Verify password',
        event_label: 'Success'
    },
    VERIFY_PASSWORD_FAIL: {
        event: 'jiomeet',
        event_category: 'Sign In',
        event_action: 'Verify password',
        event_label: 'Failure'
    },
    FORGOT_PASSWORD: {
        event: 'jiomeet',
        event_category: 'Sign In',
        event_action: 'Forgot password',
        event_label: 'Success'
    },
    VERIFY_OTP: {
        event: 'jiomeet',
        event_category: 'Sign In',
        event_action: 'Verify OTP',
        event_label: 'Success'
    },
    RESEND_OTP: {
        event: 'jiomeet',
        event_category: 'Sign In',
        event_action: 'Resend OTP',
        event_label: 'Success'
    },
    SIGNIN_WITH_COMPANY_DOMAIN: {
        event: 'jiomeet',
        event_category: 'Sign In',
        event_action: 'Sign in with Company Domain',
        event_label: 'Success'
    },
    SIGNIN_WITH_FACEBOOK: {
        event: 'jiomeet',
        event_category: 'Sign In',
        event_action: 'Sign in with facebook',
        event_label: 'Success'
    },
    SIGNIN_WITH_GOOGLE: {
        event: 'jiomeet',
        event_category: 'Sign In',
        event_action: 'Sign in with google',
        event_label: 'Success'
    },
    SIGNUP_FROM_SIGNIN: {
        event: 'jiomeet',
        event_category: 'Sign In',
        event_action: 'Sign Up from Sign In',
        event_label: 'Success'
    },
    SIGNUP_EMAIL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Signup with Email',
        event_label: 'Success'
    },
    SIGNUP_GUEST_EMAIL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Signup with Email',
        event_label: 'Success_Rewards'
    },
    SIGNUP_EMAIL_FAIL: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Signup with Email',
        event_label: 'Failure'
    },
    SIGNUP_GUEST_EMAIL_FAIL: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Signup with Email',
        event_label: 'Failure_Rewards'
    },
    SIGNUP_MOBILE_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Signup with Mobile',
        event_label: 'Success'
    },
    SIGNUP_GUEST_MOBILE_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Signup with Mobile',
        event_label: 'Success_Rewards'
    },
    SIGNUP_MOBILE_FAIL: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Signup with Mobile',
        event_label: 'Failure'
    },
    SIGNUP_GUEST_MOBILE_FAIL: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Signup with Mobile',
        event_label: 'Failure_Rewards'
    },
    SIGNIN_FROM_SIGNUP_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Sign In from Sign Up',
        event_label: 'Success'
    },
    SIGNIN_FROM_SIGNUP_GUEST_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Sign In from Sign Up',
        event_label: 'Success_Rewards'
    },
    VERIFY_OTP_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Verify OTP',
        event_label: 'Success'
    },
    VERIFY_GUEST_OTP_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Verify OTP',
        event_label: 'Success_Rewards'
    },
    VERIFY_OTP_FAIL: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Verify OTP',
        event_label: 'Failure'
    },
    VERIFY_GUEST_OTP_FAIL: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Verify OTP',
        event_label: 'Failure_Rewards'
    },
    RESEND_OTP_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Resend OTP',
        event_label: 'Success'
    },
    RESEND_OTP_FAIL: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Resend OTP',
        event_label: 'Failure'
    },
    RESEND_GUEST_OTP_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Resend OTP',
        event_label: 'Success_Rewards'
    },
    RESEND_GUEST_OTP_FAIL: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Resend OTP',
        event_label: 'Failure_Rewards'
    },
    CONFIRMATION_EMAIL_SENT: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Confirmation email sent',
        event_label: 'Success'
    },
    EMAIL_VERIFY_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Verify_email',
        event_label: 'Success'
    },
    EMAIL_VERIFY_FAIL: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Verify_email',
        event_label: 'Failure'
    },
    GUEST_EMAIL_VERIFY_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Verify_email',
        event_label: 'Success_Rewards'
    },
    GUEST_EMAIL_VERIFY_FAIL: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Verify_email',
        event_label: 'Failure_Rewards'
    },
    CONFIRMATION_EMAIL_SENT_FAIL: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Confirmation email sent',
        event_label: 'Failure'
    },
    CONFIRMATION_GUEST_EMAIL_SENT: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Confirmation email sent',
        event_label: 'Success_Rewards'
    },
    CONFIRMATION_GUEST_EMAIL_SENT_FAIL: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Confirmation email sent',
        event_label: 'Failure_Rewards'
    },
    SIGNIN_AFTER_SIGNUP_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Sign In after Sign Up',
        event_label: 'Success'
    },
    SIGNIN_GUEST_AFTER_SIGNUP_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Sign In after Sign Up',
        event_label: 'Success_Rewards'
    },
    RESEND_EMAIL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Resend confirmation email',
        event_label: 'Success'
    },
    RESEND_GUEST_EMAIL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Resend confirmation email',
        event_label: 'Success_Rewards'
    },
    RESEND_EMAIL_FAIL: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Resend confirmation email',
        event_label: 'Failure'
    },
    RESEND_GUEST_EMAIL_FAIL: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Resend confirmation email',
        event_label: 'Failure_Rewards'
    },
    SIGNUP_GOOGLE_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Signup with google',
        event_label: 'Success'
    },
    SIGNUP_GUEST_GOOGLE_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Signup with google',
        event_label: 'Success_Rewards'
    },
    SIGNUP_FACEBOOK_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Signup with facebook',
        event_label: 'Success'
    },
    SIGNUP_GUEST_FACEBOOK_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'Signup with facebook',
        event_label: 'Success_Rewards'
    },
    ONBOARDING_PAGE_GO_TO_DASHBOARD: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'My dashboard',
        event_label: 'Success'
    },
    ONBOARDING_PAGE_GO_TO_DASHBOARD_FAIL: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'My dashboard',
        event_label: 'Failure'
    },
    GUEST_ONBOARDING_PAGE_GO_TO_DASHBOARD: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'My dashboard',
        event_label: 'Success'
    },
    GUEST_ONBOARDING_PAGE_GO_TO_DASHBOARD_FAIL: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'My dashboard',
        event_label: 'Failure'
    },
    USER_NOT_SIGNED_UP: {
        event: 'jiomeet',
        event_category: 'Sign Up',
        event_action: 'User_not_signed_up',
        event_label: 'Success'
    },
    JOIN_CALL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Join a Meeting',
        event_action: 'Join',
        event_label: 'Success'
    },
    JOIN_CALL_FAILURE: {
        event: 'jiomeet',
        event_category: 'Join a Meeting',
        event_action: 'Join',
        event_label: 'Failure'
    },
    CALL_JOIN_WAITING_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Join a Meeting',
        event_action: 'call join waiting',
        event_label: 'Success'
    },
    CALL_JOIN_WAITING_FAILURE: {
        event: 'jiomeet',
        event_category: 'Join a Meeting',
        event_action: 'call join waiting',
        event_label: 'Failure'
    },
    CALL_JOIN_APPROVED: {
        event: 'jiomeet',
        event_category: 'Join a Meeting',
        event_action: 'call join approved',
        event_label: 'Success'
    }
};
