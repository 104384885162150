import { Injectable } from '@angular/core';

import { AppService } from './app.service';
import { RestService } from './rest.service';
import { SocketService } from './socket.service';

import { SOCKET_EVENTS } from 'src/app/constants';

@Injectable({
    providedIn: 'root'
})
export class GroupsService {
    private currentGroupForEdit;

    constructor(
        private appService: AppService,
        private restService: RestService,
        private socketService: SocketService
    ) {}

    getGroups({ offset = 0, limit = 500, query = '' }) {
        return this.restService.get(`api/groups?offset=${offset}&keyword=${query}`);
    }

    createGroup({ title, members, avatar }) {
        return this.restService.post(`api/groups`, {
            title,
            members,
            avatar
        });
    }

    updateGroup(group) {
        return this.restService.put(`api/groups`, group);
    }

    deleteGroup(groupId) {
        return this.restService.post(`api/groups/delete`, {
            group_id: groupId
        });
    }

    leaveGroup(groupId) {
        return this.restService.put(`api/groups/leave`, {
            group_id: groupId
        });
    }

    setCurrentGroupForEdit(group) {
        this.currentGroupForEdit = group;
    }

    getCurrentGroupForEdit() {
        return this.currentGroupForEdit;
    }

    sendGroupUpdateMessageOnSocket(group, eventType) {
        group.members.forEach((member) => {
            const message = {
                event: SOCKET_EVENTS.GENERIC,
                data: {
                    eventType,
                    userId: member.userId,
                    details: group
                }
            };
            this.socketService.send(message);
        });
    }
}
