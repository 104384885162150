import { Component, ComponentFactoryResolver, ComponentRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { of, Subscription } from 'rxjs';
import { tap, mergeMap, filter, pairwise } from 'rxjs/operators';

import {
    RtcService,
    DiagnosticService,
    UserService,
    UtilService,
    AppService,
    VideoWrapperService,
    ThirdPartyExternalIntegrationService,
    EventEmitterService,
    GaDiagnosticService,
    SystemService,
    CallService,
    ExternalInterfaceService,
    GoogleTagManagerService,
    LocalStorageService
} from 'src/app/core';
import { CallSettingsComponent, HostDirective, MeetingidFormatPipe } from 'src/app/shared';

import { APP_EVENTS, KEYBOARD_CONSTANT } from 'src/app/constants';
import { GA_EVENTS } from './ga-events';
import { DeepLinkService } from 'src/app/core/services/deep-link.service';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { ConfirmationService } from 'primeng/api';
import { GA_NEW } from 'src/app/shared/ga-new';
declare const window: any;

@Component({
    selector: 'app-host-meeting',
    templateUrl: './host-meeting.component.html',
    styleUrls: ['./host-meeting.component.scss'],
    providers: [MeetingidFormatPipe]
})
export class HostMeetingComponent implements OnInit, OnDestroy {
    @ViewChild(HostDirective) viewHost: HostDirective;
    settingsComponentRef: ComponentRef<CallSettingsComponent>;

    meetingId;
    roomType = 'dynamic';
    currentUser;
    room: any = {
        personal: null,
        dynamic: null
    };
    shareInfo;
    showMeetingDetailsPopup = false;
    copiedInviteToClipboard = false;

    private timeoutIds = [];
    private subject = 'Please join JioMeet meeting';
    private mailBody;
    private roomInfo;
    isThirdPartyExternalIntegration;
    private thirdPartyEventSubscriptions = [];
    isMobileDevice;
    showCompatibilityMessage: boolean = true;
    subscriptions: Subscription[] = [];
    iosMacDeeplink: unknown;
    loader = false;
    isMyjioIntegration: boolean = false;
    showPermissionUI: boolean = false;
    isOffline: boolean = false;
    showBanner: boolean = false;
    joining: boolean = false;
    transferCall: boolean = false;
    checkMultiDeviceLogin: boolean;
    mettingInfo: any;
    videoService;
    noVideoDeviceAvailable: boolean = false;
    joiningInProgress = false;
    bannerData;
    joinWithoutVideo = false;
    joinWithoutAudio = false;
    showHoldAndJoin = false;
    actionBtnWidth = '170px';
    personalMeeting = false;
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private rtcService: RtcService,
        private utilService: UtilService,
        private appService: AppService,
        private diagnosticService: DiagnosticService,
        private compFactory: ComponentFactoryResolver,
        private meetingidFormatPipe: MeetingidFormatPipe,
        private videoWrapperService: VideoWrapperService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        private eventEmitterService: EventEmitterService,
        private zone: NgZone,
        private gaService: GaDiagnosticService,
        private systemService: SystemService,
        private deepLinkService: DeepLinkService,
        private callService: CallService,
        private externalInterfaceService: ExternalInterfaceService,
        private localStorageService: LocalStorageService,
        private gTagService: GoogleTagManagerService,
        private appLoggerService: AppLoggerService,
        private confirmationService: ConfirmationService
    ) {
        this.videoService = this.videoWrapperService.getVideoServiceForPreview();
    }

    async ngOnInit() {
        this.isThirdPartyExternalIntegration =
            this.thirdPartyExternalIntegrationService.getThirdPartyExternalIntegration();
        this.isMobileDevice = this.utilService.isMobileBrowser() || this.isThirdPartyExternalIntegration;
        this.currentUser = this.userService.getUserSync();
        if (
            this.currentUser?.mediaEnginePreference === 'agora' ||
            (this.appService.getConfigVariable('ENABLE_DEEPLINK_FOR_JMMEDIA') &&
                this.currentUser?.mediaEnginePreference === 'jm-media')
        ) {
            this.subscriptions.push(
                this.activatedRoute.data.subscribe((route) => {
                    // this.type = route.type === 'join' ? 'shortener' : 'addtojiomeet';
                    this.deepLinkService.handleDeepLink(this.activatedRoute);
                }),
                this.deepLinkService.iosMacDeeplinkSubject.subscribe((link) => {
                    this.iosMacDeeplink = link;
                })
            );
        }
        this.isMyjioIntegration = this.thirdPartyExternalIntegrationService.getMyjioIntegration();
        this.subscriptions.push(
            this.eventEmitterService.subscribe((event: any) => {
                if (event.type === APP_EVENTS.NATIVE_BACK_BUTTON) {
                    this.zone.run(() => {
                        //this.navigateToDashboard();
                    });
                } else if (event.type === APP_EVENTS.ERRORS.CALL_JOIN) {
                    this.zone.run(() => {
                        this.loader = false;
                        this.toastrService.error(
                            this.translateService.instant('tostrs.something_went_wrong_please_try_again')
                        );
                    });
                } else if (event?.type === APP_EVENTS.SHOW_PERMISSION_UI) {
                    this.showPermissionUI = event?.data;
                    if (event?.data === false) {
                        this.showHoldAndJoin = false;
                        this.hostMeeting();
                    }
                } else if (event?.type === APP_EVENTS.JM_MEDIA_JOIN_FAILED) {
                    this.loader = false;
                } else if (event?.type === APP_EVENTS.JM_MAX_LIMIT_REACHED) {
                    this.loader = false;
                    this.confirmationService.confirm({
                        message: this.translateService.instant('tostrs.reached_max_capacity_dialog'),
                        header: this.translateService.instant('tostrs.reached_max_capacity'),
                        // acceptLabel: this.translateService.instant('tostrs.ok'),
                        acceptButtonStyleClass: 'custom-button bg-primary',
                        rejectVisible: false,
                        accept: () => {},
                        reject: () => {}
                    });
                    this.utilService.setTimeOutForAccessibilityPopUp();
                } else if (
                    event?.type === KEYBOARD_CONSTANT.KEYBOARD_SHORTCUTS &&
                    event?.data === KEYBOARD_CONSTANT.JOIN_MEETING
                ) {
                    this.hostMeeting();
                } else if (
                    event?.type === KEYBOARD_CONSTANT.KEYBOARD_SHORTCUTS &&
                    event?.data === KEYBOARD_CONSTANT.CLOSE_WINDOW
                ) {
                    this.confirmationService.close();
                }
            })
        );
        if (!this.isThirdPartyExternalIntegration) {
            this.checkBrowserCompatibiity();
        }
        this.activatedRoute.queryParams.subscribe((queryParams) => (this.meetingId = queryParams.meetingId));
        this.handleWindowEvents();
        const devicePermissions = await this.utilService.getDevicePermissions('Host');
        this.joinWithoutVideo = devicePermissions?.noVideoDevice;
        this.joinWithoutAudio = devicePermissions?.noAudioDevice;
        if (this.joinWithoutVideo || this.joinWithoutAudio) {
            this.showHoldAndJoin = true;
            this.actionBtnWidth = 'auto';
        }
    }

    ngAfterViewInit() {
        this.setPageFocusOnLaunch();
    }

    setPageFocusOnLaunch() {
        setTimeout(() => {
            const focusElement: any = document.getElementsByClassName('page container-fluid')[0];
            if (focusElement) {
                focusElement?.focus();
            }
        }, 100);
    }

    getRoom() {
        return (
            this.room[this.roomType]
                ? of(this.room[this.roomType])
                : this.rtcService.getRoom(this.roomType === 'personal')
        ).pipe(tap((room) => (this.room[this.roomType] = room)));
    }

    checkBrowserCompatibiity() {
        const { browser, version } = this.systemService.getSysytemInfo();
        const majorVersion = parseInt((version || '').split('.')[0], 10);
        if (browser === 'Safari' && majorVersion >= 13) {
            this.showCompatibilityMessage = false;
        }
        if (browser === 'Chrome' && majorVersion >= 83) {
            this.showCompatibilityMessage = false;
        }
        if (this.utilService.isEdgeBrowser()) {
            this.showCompatibilityMessage = true;
        }
    }
    selectRadio(option: string) {
        this.roomType = option;
        this.personalMeeting = option === 'personal';
    }

    handleEnterKey(event: KeyboardEvent) {
        event.preventDefault();
        const link = event.target as HTMLAnchorElement;
        window.open(link.href, '_blank');
    }

    getCopyArialLabel(): string {
        return this.copiedInviteToClipboard ? 'copied' : 'copy to clipboard';
    }

    handleWindowEvents() {
        window.addEventListener('online', (event) => {
            this.setBannerData(
                this.translateService.instant('joinmeeting_page_or_joincall.stable_connection_now'),
                this.translateService.instant('joinmeeting_page_or_joincall.please_try_joining_now'),
                'success'
            );
        });
        window.addEventListener('offline', (e) => {
            this.setBannerData(
                this.translateService.instant('joinmeeting_page_or_joincall.unable_to_connect_server'),
                this.translateService.instant('joinmeeting_page_or_joincall.please_try_again'),
                'error'
            );
            this.loader = false;
        });
    }

    closeBanner() {
        this.showBanner = false;
    }

    setBannerData(title, content, type) {
        this.bannerData = { title, content, type };
        this.showBanner = true;
    }

    shareInvite() {
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translateService.instant('tostrs.something_went_right'));
            return;
        }
        this.shareInvitelogic();
    }
    shareInviteonEnter(event: Event) {
        event.preventDefault();
        this.shareInvitelogic();
    }

    shareInvitelogic() {
        this.getRoom().subscribe((room) => {
            this.roomInfo = room;
            this.rtcService.getCallShareUrl(room).subscribe((shareInfo: any) => {
                this.shareInfo = shareInfo;
                this.shareInfo.topic = `${this.currentUser.name} ${this.currentUser.lname}'s JioMeet Meeting`;
                this.shareInfo.jiomeetId = this.room[this.roomType].jiomeetId;
                let noOfParticipants = 1;
                const invitation = this.rtcService.getInvitation(
                    `${this.currentUser.name} ${this.currentUser.lname}`,
                    shareInfo.topic,
                    null,
                    null,
                    shareInfo.url,
                    this.meetingidFormatPipe.transform(shareInfo.jiomeetId),
                    shareInfo.pin || '',
                    null,
                    this.roomInfo.legacyConnect,
                    this.roomInfo.vpin || null,
                    noOfParticipants
                );
                this.mailBody = encodeURIComponent(invitation);
                this.toggleMeetingDetailsPopup();
            });
        });
        this.gTagService.sendEvent(GA_EVENTS.SHARE_PERSONAL_MEETING_SUCCESS);
        this.gTagService.sendEvent(GA_NEW.SHARE_PERSONAL_MEETING_SUCCESS);
    }

    async hostMeeting() {
        if (this.joiningInProgress) {
            return;
        }
        this.joiningInProgress = true;
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translateService.instant('tostrs.something_went_right'));
            this.joiningInProgress = false;
            return;
        }
        if (this.showHoldAndJoin) {
            this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: true });
            this.joiningInProgress = false;
            return;
        }
        // Ignoring Device permissions while Start/Host Call
        /*try {
            this.noVideoDeviceAvailable = await this.utilService.checkIfCameraDoesntExists();
            await this.utilService.getUpdatedPermissions();
            if (
                (this.videoService.cameras.length === 0 && !this.noVideoDeviceAvailable) ||
                this.videoService.microphones.length === 0
            ) {
                this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: true });
                this.joiningInProgress = false;
                return;
            }
        } catch (err) {
            this.appLoggerService.error('Host meeting - Mic or Camera access denied', err);
            if (!this.noVideoDeviceAvailable) {
                this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: true });
                this.joiningInProgress = false;
                return;
            }
        }*/
        this.localStorageService.removeItem('clientDeviceId');
        this.localStorageService.removeItem('clientDeviceType');
        this.getRoom().subscribe(
            (room) => {
                this.mettingInfo = room;
                const isAddTransferCallEnabled = this.appService.getConfigVariable('WEB_ADD_TRANSFER_CALL') ?? false;
                if (
                    room?.deviceIds?.length &&
                    room.deviceIds.indexOf(this.systemService.getDeviceUUID()) === -1 &&
                    isAddTransferCallEnabled
                ) {
                    this.joining = false;
                    this.checkMultiDeviceLogin = true;
                    this.gTagService.sendEvent(GA_EVENTS.CALL_JOIN_WAITING_SUCCESS);
                    this.joiningInProgress = false;
                    return;
                }

                this.gTagService.sendEvent(GA_EVENTS.START_CALL_SUCCESS);
                this.start(room);
            },
            (err) => {
                this.joiningInProgress = false;
                this.gTagService.sendEvent(GA_EVENTS.START_CALL_FAILURE);
                if (this.joinWithoutVideo || this.joinWithoutAudio) {
                    this.showHoldAndJoin = true;
                }
            }
        );
    }

    getStartBtnTitle() {
        let startBtnTxt = 'host_meeting.start';
        if (this.joinWithoutVideo && this.joinWithoutAudio) {
            startBtnTxt = 'joinmeeting_page_or_joincall.start_without_video_audio';
        } else if (this.joinWithoutVideo) {
            startBtnTxt = 'joinmeeting_page_or_joincall.start_without_video';
        } else if (this.joinWithoutAudio) {
            startBtnTxt = 'joinmeeting_page_or_joincall.start_without_audio';
        }
        return startBtnTxt;
    }

    async start(room) {
        this.rtcService.setBreakoutRoomInfo(null);
        const room1 = { ...room };
        if (room1.mediaEngine === 'jm-media' || room1.mediaEngine === 'pexip') {
            this.videoService?.stopPreviewTrack();
        }
        if (this.currentUser.mediaEnginePreference === 'agora') {
            this.rtcService.setPreparatorySetupDone(true);
            this.userService.localParticipantId = String(room?.agoraUid);
        }
        if (
            !this.appService.getConfigVariable('ENABLE_CALLS_MOBILE_DEVICES') &&
            this.utilService.isMobileBrowser() &&
            !this.isThirdPartyExternalIntegration &&
            this.currentUser?.mediaEnginePreference !== 'pexip' &&
            this.currentUser?.mediaEnginePreference !== 'jm-media'
        ) {
            this.router.navigate(['shortener'], {
                queryParams: {
                    meetingId: this.meetingId
                }
            });
            this.joiningInProgress = false;
            return;
        }

        if (this.isThirdPartyExternalIntegration && !this.isMyjioIntegration) {
            this.router.navigate(['call-preview-screen'], {
                queryParams: {
                    meetingId: room?.jiomeetId,
                    name: `${this.currentUser?.name} ${this.currentUser?.lname}`,
                    pin: room?.roomPIN,
                    roomType: this.roomType,
                    previousURL: 'host'
                }
            });
            this.joiningInProgress = false;
            return;
        }

        this.diagnosticService
            .sendEvent({
                eventCategory: 'Start a Meeting',
                eventAction: 'New Meeting Start',
                eventType: 'app_event',
                status: 'success'
            })
            .subscribe();

        if (this.roomType === 'personal') {
            this.gTagService.sendEvent(GA_EVENTS.PERSONAL_MEETING_SUCCESS);
            this.gTagService.sendEvent(GA_NEW.PERSONAL_MEETING_SUCCESS);
        }

        if (this.roomType === 'dynamic') {
            this.gTagService.sendEvent(GA_EVENTS.ONE_TIME_MEETING_SUCCESS);
            this.gTagService.sendEvent(GA_NEW.ONE_TIME_MEETING_SUCCESS);
        }

        if (this.isMyjioIntegration && !this.appService.getEnvVariable('ENABLE_MYJIO_CALLING')) {
            const myjiodeeplinkopen =
                `${this.appService.getEnvVariable('HOST_URL')}shortener?` +
                'meetingId=' +
                room?.jiomeetId +
                '&pwd=' +
                room?.roomPIN;
            this.externalInterfaceService.launchBrowser(myjiodeeplinkopen);
            this.navigateToDashboard();
            this.joiningInProgress = false;
            return;
        }
        const commonConferenceInfo = {
            isInitiater: true,
            joiningName: (this.currentUser.name + ' ' + this.currentUser.lname).trim(),
            room: {
                ...room,
                topic: `${this.currentUser.name} ${this.currentUser.lname}'s JioMeet Meeting`,
                title: `${this.currentUser.name} ${this.currentUser.lname}'s JioMeet Meeting`
            },
            ownerDetails: {
                isHostRight: true,
                lname: this.currentUser.lname,
                name: this.currentUser.name,
                userId: this.currentUser._id,
                _id: this.currentUser._id
            },
            hideMoreCallControls: false,
            hideAllCallControls: false,
            isFullScreenShare: false,
            forBetaTesting: room?.forBetaTesting,
            transferCall: this.transferCall
        };
        const additionalConferenceInfo = this.videoWrapperService.getAdditionalConferenceInfo(room, false);
        const conferenceInfo = { ...commonConferenceInfo, ...additionalConferenceInfo };
        this.rtcService.isHostOrCoHostOfMeeting = true;
        this.rtcService.setConferenceInfo(conferenceInfo);
        this.videoService = this.videoWrapperService.getVideoServiceForPreview();
        if (this.shoudInitiateMeetingLoaders()) {
            this.loader = true;
            this.callService.initiateCall();
            setTimeout(() => {
                document.getElementById('loadingPage').focus();
            });
        } else {
            this.router.navigate(['conference/call']);
        }
        this.joiningInProgress = false;
    }

    toggleMeetingDetailsPopup() {
        this.showMeetingDetailsPopup = !this.showMeetingDetailsPopup;
    }

    copyInvitation() {
        this.utilService.copyToClipboardFromHTMLElement('meeting-invitation');
        this.copiedInviteToClipboard = true;
        this.timeoutIds.push(
            setTimeout(() => {
                this.copiedInviteToClipboard = false;
            }, 2000)
        );
        this.gaService.sendEvent(GA_EVENTS.COPY_CLIPBOARD_SUCCESS);
    }

    sendOutlookInvite() {
        const inviteURL = `https://outlook.office.com/?path=/mail/action/compose&subject=${this.subject}&body=${this.mailBody}`;
        window.open(inviteURL, '_blank');
        this.gaService.sendEvent(GA_EVENTS.SHARE_OUTLOOK_SUCCESS);
    }

    sendGmailInvite() {
        const inviteURL = `https://mail.google.com/mail/?view=cm&fs=1&su=${this.subject}&body=${this.mailBody}`;
        window.open(inviteURL, '_blank');
        this.gaService.sendEvent(GA_EVENTS.SHARE_GMAIL_SUCCESS);
    }

    sendYahooInvite() {
        const inviteURL = `https://compose.mail.yahoo.com?subject=${this.subject}&body=${this.mailBody}`;
        window.open(inviteURL, '_blank');
    }

    shoudInitiateMeetingLoaders() {
        return this.rtcService.getIsAgoraOrJmMeeting() && !this.isThirdPartyExternalIntegration;
    }

    openSettingsPopup(e) {
        if (!this.settingsComponentRef?.instance) {
            const settings = this.compFactory.resolveComponentFactory(CallSettingsComponent);
            this.settingsComponentRef = this.viewHost.viewContainerRef.createComponent(settings);
            this.settingsComponentRef.instance.selectedLabel = e.setting;
            this.settingsComponentRef.instance.afterClose.subscribe((res) => {
                this.settingsComponentRef.destroy();
                this.settingsComponentRef = undefined;
            });
            this.gaService.sendEvent(GA_EVENTS.SETTINGS_SUCCESS);
        }
    }

    clickAppBackButton() {
        this.gTagService.sendEvent(GA_NEW.START_A_MEETING_BACK_BUTTON);
        this,
            this.eventEmitterService.emit({
                type: APP_EVENTS.NATIVE_BACK_BUTTON,
                data: {}
            });
    }

    navigateToDashboard() {
        this.eventEmitterService.emit({
            type: APP_EVENTS.TOGGLE_CAMERA_OFF,
            data: {}
        });
        this.router.navigate(['/meetings']);
    }

    closePermissiondialog() {
        this.showPermissionUI = false;
    }

    /**
     * This function handle User action performed when User take action
     * either to add the device or remove all other active devices
     * @param action action which is either add | remove.
     */
    handleMultiDeviceLogin(action: string) {
        const aa = this.mettingInfo;
        this.joining = true;
        this.transferCall = false;
        if (action === 'remove') {
            this.transferCall = true;
            this.rtcService
                .getRoomDetailsGuest({
                    extension: this.mettingInfo?.extension,
                    pin: this.mettingInfo?.roomPIN,
                    hash: this.mettingInfo?.hash,
                    userId: this.currentUser._id,
                    isAuthenticated: true,
                    transferCall: this.transferCall
                })
                .subscribe(
                    (res: any) => {
                        this.appService.setMeetingId(res?.roomDetails?.meetingId);
                        this.gTagService.sendEvent(GA_EVENTS.CALL_JOIN_APPROVED);
                        this.start(this.mettingInfo);
                    },
                    (err) => {
                        this.gTagService.sendEvent(GA_EVENTS.CALL_JOIN_WAITING_FAILURE);
                    }
                );
        } else {
            this.start(this.mettingInfo);
        }
    }

    ngOnDestroy() {
        if (this.isThirdPartyExternalIntegration) {
            this.thirdPartyEventSubscriptions.forEach((subscription) => subscription.unsubscribe());
        }
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.timeoutIds.forEach((id) => clearTimeout(id));
    }
}
