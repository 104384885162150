<div class="login-comp">
    <div class="login-component">
        <div class="m-auto" *ngIf="!loading && !samlLoginInProgress">
            <div class="main-container" *ngIf="!showUserForm && !showErrorForm && !showConsentForm && clientDetails">
                <div class="d-flex justify-content-center mb-3">
                    <img alt="" src="assets/img/logo/jm-logo-blue.svg" />
                </div>
                <div class="client-section">
                    You are being authorized from
                    {{ state === 'jiorooms' ? clientDetails?.appName : clientDetails?.name }}
                </div>
                <div class="login-form">
                    <section>
                        <h2>Sign In</h2>

                        <form *ngIf="!emailLogin && !ottLogin" [formGroup]="usernameForm">
                            <div class="form-group mb-4">
                                <label *ngIf="emailOrMobileNumberInput.value">Enter email / Mobile no.</label>
                                <input
                                    #emailOrMobileNumberInput
                                    type="text"
                                    class="form-control"
                                    id="username"
                                    formControlName="username"
                                    aria-describedby="usernameHelp"
                                    [class.is-invalid]="errors.username"
                                    placeholder="Enter email / Mobile no."
                                    (keyup.enter)="submitUsername()"
                                />
                                <span class="invalid-feedback">{{ errors.username }}</span>
                            </div>
                            <div class="col p-0 w-100 proceed text-center">
                                <button
                                    type="button"
                                    class="btn primary w-50"
                                    [class.disabled]="!enableProceedBtn"
                                    (click)="submitUsername()"
                                >
                                    {{ loggingIn ? 'Please Wait..' : 'Proceed' }}
                                </button>
                            </div>
                            <div class="col p-0 w-100 mt-3">
                                <span class="text-error" *ngIf="errors.global">{{ errors.global }}</span>
                            </div>
                        </form>

                        <div
                            class="sign-up-text d-flex justify-content-center mt-4"
                            [ngClass]="(ottLogin && !requestingOTP) || emailLogin ? 'otp' : ''"
                        >
                            <span class="link"
                                >Don’t have an account?
                                <a class="text-primary" href="/signup" target="_blank">Sign Up</a></span
                            >
                        </div>

                        <form *ngIf="emailLogin" [formGroup]="loginForm" (submit)="submitEmailPassword()">
                            <div class="form-group">
                                <label for="email" [class.invalid]="errors.global">Email Address</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="email"
                                    formControlName="email"
                                    aria-describedby="emailHelp"
                                    [class.is-invalid]="errors.email"
                                    [class.invalid]="errors.global"
                                    placeholder="Enter email"
                                />
                                <span class="invalid-feedback">{{ errors.email }}</span>
                            </div>
                            <div class="form-group" *ngIf="!isDomainEmail">
                                <label for="password" [class.invalid]="errors.global">Password</label>
                                <div class="row">
                                    <div class="col-md-12" id="passwordfield">
                                        <input
                                            type="password"
                                            class="form-control"
                                            id="password"
                                            #password
                                            formControlName="password"
                                            [class.is-invalid]="errors.password"
                                            [class.invalid]="errors.global"
                                            placeholder="Password"
                                        />
                                        <div class="text pointer" (click)="navToForgotPassword()">Forgot?</div>
                                        <span class="invalid-feedback">{{ errors.password }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 w-100 mt-3">
                                <span class="text-error" *ngIf="errors.global"> {{ errors.global }}</span>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mt-3">
                                <div class="remember-me">
                                    <label for="rememberMe" class="bottom-zero">
                                        <input
                                            type="checkbox"
                                            id="rememberMe"
                                            name="rememberMe"
                                            class="mr-2"
                                            formControlName="rememberMe"
                                        />
                                        Remember me
                                    </label>
                                </div>
                                <div class="forgot-password">
                                    <!-- <a routerLink="../forgot-password">Forgot Password?</a> -->
                                    <button
                                        type="submit"
                                        class="btn primary"
                                        [class.disabled]="
                                            (!isDomainEmail && !loginForm.valid) ||
                                            (isDomainEmail && loginForm.controls.email.errors) ||
                                            loggingIn
                                        "
                                    >
                                        {{ loggingIn ? 'Please Wait..' : 'Sign In' }}
                                    </button>
                                </div>
                            </div>
                        </form>

                        <form *ngIf="ottLogin && !requestingOTP" [formGroup]="ottForm" (submit)="verifyOTP()">
                            <div class="form-group position-relative">
                                <input
                                    type="text"
                                    class="form-control"
                                    id="email"
                                    formControlName="phoneNo"
                                    aria-describedby="phoneNoHelp"
                                    [class.is-invalid]="errors.phoneNo"
                                    placeholder="Mobile no."
                                    disabled
                                />
                                <div class="text pointer text-primary" (click)="changePhoneNo()">Change</div>
                                <span class="invalid-feedback">{{ errors.phoneNo }}</span>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-12" id="passwordfield">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="password"
                                            #password
                                            formControlName="otp"
                                            [class.is-invalid]="errors.otp"
                                            placeholder="Enter OTP"
                                            maxlength="6"
                                        />
                                        <div class="text pointer text-primary" (click)="resendOTP()">Resend OTP</div>
                                        <span class="invalid-feedback">{{ errors.otp }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 w-100 mt-3">
                                <span class="text-error" *ngIf="errors.global">{{ errors.global }}</span>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mt-3">
                                <div class="pl-3 remember-me custom-checkbox">
                                    <input
                                        type="checkbox"
                                        id="rememberMe"
                                        name="rememberMe"
                                        class="mr-2 custom-control-input"
                                        formControlName="rememberMe"
                                    />
                                    <label class="custom-control-label" for="rememberMe">Remember me</label>
                                </div>
                                <div class="forgot-password">
                                    <!-- <a routerLink="../forgot-password">Forgot Password?</a> -->
                                    <button type="submit" class="btn primary" [class.disabled]="disableOttLoginForm()">
                                        {{ loggingIn ? 'Please Wait..' : 'Verify OTP' }}
                                    </button>
                                </div>
                            </div>
                        </form>

                        <form *ngIf="ottLogin && requestingOTP" [formGroup]="ottForm" (submit)="getOTP()">
                            <div class="form-group position-relative">
                                <input
                                    type="text"
                                    class="form-control"
                                    id="email"
                                    formControlName="phoneNo"
                                    aria-describedby="phoneNoHelp"
                                    [class.is-invalid]="errors.phoneNo"
                                    placeholder="Mobile no."
                                />
                                <span class="invalid-feedback">{{ errors.phoneNo }}</span>
                            </div>
                            <div class="col p-0 w-100 mt-5">
                                <button type="submit" class="btn primary" [class.disabled]="!ottForm.valid">
                                    {{ loggingIn ? 'Please Wait..' : 'Get OTP' }}
                                </button>
                                <div class="col p-0 w-100 mt-3">
                                    <span class="text-error" *ngIf="errors.global">{{ errors.global }}</span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mt-3">
                                <div class="forgot-password"></div>
                            </div>
                        </form>

                        <!-- <div class="sign-up-text d-flex justify-content-center mt-5">
              <span class="link"><a class="text-primary" (click)="showInviteCodePopup()">Sign Up</a></span>
            </div> -->
                        <div>
                            <div class="separator">
                                <hr />
                            </div>
                            <div class="social-login">
                                <div class="social-login-btn" routerLink="/sso" style="display: none">
                                    <div class="icon-wrap">
                                        <img alt="" src="assets/img/key_icn.png" />
                                    </div>
                                    <div class="text">Sign in with Company domain</div>
                                </div>
                                <div class="social-login-btn" (click)="loginWithSSO('google')">
                                    <img alt="" src="assets/img/google-login-btn.png" />

                                    <div class="text">Sign in with Google</div>
                                </div>
                                <div class="social-login-btn" (click)="loginWithSSO('facebook')">
                                    <div class="icon-wrap">
                                        <img alt="" src="assets/img/facebook-login-btn.png" />
                                    </div>
                                    <div class="text">Sign in with Facebook</div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <div
                class="main-container user-form"
                *ngIf="showUserForm && !showErrorForm && !showConsentForm && clientDetails"
            >
                <div class="user-content text-center">
                    <div class="d-flex justify-content-center mb-3">
                        <img alt="" src="assets/img/logo/jm-logo-blue.svg" />
                    </div>
                    <div class="header-text mb-2">You’re already logged in as</div>
                    <div class="user-details">
                        <img alt="" [src]="profilePicPath" alt="" *ngIf="profilePicPath" />

                        <div class="name mt-2" *ngIf="!currentUser?.lname">
                            {{ currentUser?.name }}
                        </div>
                        <div class="name mt-2" *ngIf="currentUser?.lname">
                            {{ currentUser?.name + ' ' + currentUser?.lname }}
                        </div>
                        <div class="email" *ngIf="!currentUser?.isOTT">
                            {{ currentUser?.email }}
                        </div>
                        <div class="email" *ngIf="currentUser?.isOTT">
                            {{ currentUser?.displayPhoneNo || currentUser?.email }}
                        </div>
                    </div>
                    <button class="continue-btn mt-3" (click)="checkAuthentication()">Continue</button>
                </div>
            </div>
            <div class="main-container consent-form" *ngIf="showConsentForm && !showErrorForm && clientDetails">
                <div class="img-container d-flex mb-4">
                    <div class="d-flex justify-content-center col-sm-5 p-0">
                        <img alt="" [src]="clientDetails?.iconUrl" *ngIf="clientDetails?.iconUrl" />
                        <div class="clientName" *ngIf="!clientDetails?.iconUrl">{{ clientDetails?.name }}</div>
                    </div>
                    <div class="d-flex justify-content-center col-sm-2 p-0">
                        <img alt="" src="assets/img/transfer.svg" width="20" />
                    </div>
                    <div class="d-flex justify-content-center col-sm-5 p-0">
                        <img alt="" src="assets/img/logo/jm-logo-blue.svg" />
                    </div>
                </div>
                <div class="info-text">
                    You may be sharing sensitive info with this site. By clicking Authorise, you authorise this app to
                    access your information in compliance to their
                    <span (click)="openPrivacyPolicy()" class="text-primary pointer">privacy policy</span>
                </div>
                <div class="info-text mb-3 mt-3" *ngIf="isCpass">
                    Below actions will performed by the client on the user behalf.
                </div>
                <div class="info-texxt mb-3 mt-3" *ngIf="isCpass">
                    <div class="part" *ngFor="let i of scopes | keyvalue">
                        <div class="heading mt-4">
                            {{ i.key }}
                        </div>
                        <div class="subheading" *ngFor="let y of i.value">
                            <div class="values mt-2">
                                <img alt="" src="assets/img/tick_green_icn.svg" />
                                {{ y }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-holder d-flex mt-4 justify-content-around">
                    <button class="btn decline-btn" (click)="handleManualRedirection()">Decline</button>
                    <button class="btn continue-btn" (click)="saveConsentAndRedirect()">Authorize</button>
                </div>
            </div>
            <div class="main-container error-form" *ngIf="showErrorForm">
                <div class="error-text">
                    {{ errMsg }}
                </div>
            </div>
        </div>
        <app-loader *ngIf="(loading || samlLoginInProgress) && !clientDetails"></app-loader>
    </div>
</div>
