import { Pipe, PipeTransform } from '@angular/core';

declare const showdown: any;

const customExtensions = [
    {
        type: 'lang',
        regex: /\*\*([^\*]+)\*\*/g,
        replace: '<strong>$1</strong>'
    },
    {
        type: 'lang',
        regex: /\*([^\*]+)\*/g,
        replace: '<em>$1</em>'
    },
    {
        type: 'lang',
        regex: /~~([^~]+)~~/g,
        replace: '<s>$1</s>'
    }
];

@Pipe({
    name: 'showdown'
})
export class ShowdownPipe implements PipeTransform {
    transform(value: string, ...args: any[]): any {
        let finalMsg = '';
        if (value.includes('JM_cell')) {
            const msgComponent = value.trim().split('\n\n');
            msgComponent.forEach((msg, indx) => {
                if (msg.includes('JM_cell')) msg = this.transformTabSeparatedText(msg);
                finalMsg = finalMsg + '\n' + this.transformMarkdown(msg);
            });
            return finalMsg;
        } else {
            return this.transformMarkdown(value);
        }
    }

    private transformTabSeparatedText(value: string): string {
        const rows = value.split('\n');
        let tableHtml = '<table>';
        rows.forEach((row, rowIndex) => {
            let cells = row.split('JM_cell');
            cells = cells.slice(0, -1);
            tableHtml += '<tr>';

            cells.forEach((cell, cellIndex) => {
                const cellContent = cell.trim() === '' ? '&nbsp;' : cell.trim();
                const cellType = rowIndex === 0 ? 'th' : 'td';

                tableHtml += `<${cellType}>${cellContent}</${cellType}>`;
            });

            tableHtml += '</tr>';
        });
        tableHtml += '</table>';
        return tableHtml;
    }

    private transformMarkdown(value: string): string {
        const converter = new showdown.Converter({
            extensions: [customExtensions],
            strikethrough: true,
            underline: true,
            emoji: true,
            tables: true
        });
        return converter.makeHtml(value);
    }
}
