<div class="d-flex justify-content-between attachment rounded p-2 mt-1 position-relative" *ngIf="!isOutsideCall">
    <div class="upload-txt">
        <div class="thumbnail">
            <div *ngIf="fileType == 'Image'">
                <img alt="" class="thumbnailIcon" src="assets/images/default-img.svg" />
            </div>
            <div *ngIf="fileType == 'Video'">
                <img alt="" class="thumbnailIcon" src="assets/images/defaultVideoIconForUploading.svg" />
            </div>
            <div *ngIf="fileType == 'Audio'">
                <img alt="" class="thumbnailIcon" src="assets/images/defaultAudioIconlarge.svg" />
            </div>
            <div *ngIf="fileType == 'Doc'">
                <img alt="" class="thumbnailIcon" *ngIf="docType == 'Word'" src="assets/images/defaultWordIcon.svg" />
                <img alt="" class="thumbnailIcon" *ngIf="docType == 'Excel'" src="assets/images/defaultExcelicon.svg" />
                <img alt="" class="thumbnailIcon" *ngIf="docType == 'PPT'" src="assets/images/defaultGraphIcon.svg" />
                <img alt="" class="thumbnailIcon" *ngIf="docType == 'PDF'" src="assets/images/defaultDocsIcon.svg" />
                <img alt="" class="thumbnailIcon" *ngIf="docType == 'TXT'" src="assets/images/default_text_icon.svg" />
                <img alt="" class="thumbnailIcon" *ngIf="docType == 'Other'" src="assets/images/defaultDocsIcon.svg" />
            </div>
        </div>
        <div>
            {{ item?.name | trimFileName }}
            <div class="uploadStatus" *ngIf="!item?.uploadSuccess">
                Uploading {{ item?.uploadedBytes }} of {{ item?.totalSize }}
            </div>
        </div>
    </div>

    <div class="dot pointer" (click)="removeAttchment()">
        <img alt="" src="assets/images/close_icn.svg" class="rotate" />
    </div>
</div>

<div class="d-flex justify-content-between attachment rounded position-relative" *ngIf="isOutsideCall">
    <div class="upload-txt desktop-upload-text">
        <div class="spinner-container">
            <div
                class="spinner"
                *ngIf="item"
                [ngClass]="{ spinner: !item?.uploadSuccess, 'circle-with-tick': item?.uploadSuccess }"
            ></div>
            <div class="circle-with-x" *ngIf="!item"></div>
        </div>
        <ng-container *ngIf="item">
            <div class="filename mx-2" *ngIf="item">
                {{ item?.name | trimFileName }}
            </div>
            <div class="dot pointer remove-icon mr-2" (click)="removeAttchment()" *ngIf="item">
                <img alt="" src="assets/images/close_icn.svg" class="rotate" />
            </div>
        </ng-container>
    </div>
</div>
