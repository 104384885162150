<div class="app-drag-bar" *ngIf="desktopAppUpdaterService.isDesktopApp"></div>
<div *ngIf="!loader">
    <div class="app-container" id="app-container" appScrollShowHide>
        <div class="top-nav-wrap" *ngIf="showHeader" #header id="website-header">
            <app-website-header></app-website-header>
        </div>
        <div class="top-plus-alert-banner" *ngIf="showProAlertBanner">
            <div class="banner-container">
                <div tabindex="0" class="title">{{ 'website_home.pro_plan_alert' | translate }}</div>
                <button class="plain-button" (click)="proAlertBannerReminderClicked()">
                    {{ 'website_home.remind_later_button' | translate }}
                </button>
                <button class="plain-button with-border" (click)="proAlertBannerOkClicked()">
                    {{ 'website_home.ok_button' | translate }}
                </button>
            </div>
        </div>
        <div
            class="scrollable"
            [class.without-header]="!showHeader"
            [class.scrollWithFooter]="showFooter"
            [class.without-top-header]="!enableWidth_1366 && !enableChatForAbove1080()"
            [class.without-top-short-header]="!this.isMattermostEnabled && enableChatForAbove1080()"
            [class.without-top-short-header-mattermost]="this.isMattermostEnabled"
            id="scrollable"
            appScrollShowHide
        >
            <div class="content-wrap" [class.width-1366]="enableWidth_1366">
                <router-outlet></router-outlet>
            </div>
            <div class="footer-wrap" *ngIf="showFooter">
                <div [class.width-1366]="enableWidth_1366">
                    <app-website-footer></app-website-footer>
                </div>
            </div>
        </div>
    </div>
    <ng-template viewHost></ng-template>
</div>
<app-loader *ngIf="loader"></app-loader>

<div class="no-internet-bar" *ngIf="isOffline">
    <img alt="" class="warning-image" />&nbsp;
    <div>
        {{ 'tostrs.unable_to_connect_to_network' | translate }}
        <u (click)="tryReconnect()" class="pointer"
            ><strong>{{ 'tostrs.try_reconnecting' | translate }}</strong></u
        >
    </div>
    <div class="d-flex justify-content-end flex-grow-1">
        <img alt="" class="close pointer" (click)="close()" />&nbsp;
    </div>
</div>
<app-incoming-call-popup
    *ngIf="showIncomingCallPopup"
    (close)="toggleIncomingCallPopup(true)"
></app-incoming-call-popup>

<p-confirmDialog [transitionOptions]="'300ms'" acceptIcon="null" rejectIcon="null"></p-confirmDialog>

<div *ngIf="desktopAppUpdaterService.isDesktopApp">
    <app-electron-update (close)="cancel()" *ngIf="desktopAppUpdaterService.showElectronUpdatePopup">
    </app-electron-update>
</div>

<div *ngIf="iosMacDeeplink" [ngStyle]="{ visibility: 'hidden' }">
    <iframe title="" ref="iframe" [src]="iosMacDeeplink | safe"></iframe>
</div>
