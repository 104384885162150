import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_EVENTS } from 'src/app/constants';
import { EventData, EventEmitterService, UtilService, GoogleTagManagerService, AuthService } from 'src/app/core';
import { GA_EVENTS } from '../ga-events';
import { GA_PAGEVIEW } from '../ga-pageview';
import { GA4_EVENTS } from '../ga4-events';

@Component({
    selector: 'app-website-education',
    templateUrl: './website-education.component.html',
    styleUrls: ['../website.usecases.scss']
})
export class WebsiteEducationComponent implements OnInit {
    isAuthenticated: any;
    isMobile = false;
    teachingSolutions = [
        {
            title: '',
            desc: 'Share presentation',
            tdesc: 'website_education.share_presentation',
            icon: 'assets/img/education/share-presentation.svg'
        },
        {
            title: '',
            desc: 'Whiteboard',
            tdesc: 'website_education.whiteboard',
            icon: 'assets/img/education/whiteboard.svg'
        },
        {
            title: '',
            desc: 'Attendance Report',
            tdesc: 'website_education.attendance_report',
            icon: 'assets/img/education/auto-attendance.svg'
        },
        {
            title: '',
            desc: 'Classroom meeting controls',
            tdesc: 'website_education.classroom_meeting_controls',
            icon: 'assets/img/education/meeting-controls.svg'
        },

        {
            title: '',
            desc: 'Low bandwidth Optimisations',
            tdesc: 'website_education.bandwidth_optimisation',
            icon: 'assets/img/education/audio-optimisation.svg'
        },
        {
            title: '',
            desc: 'Classroom Groups',
            tdesc: 'website_education.classroom_groups',
            icon: 'assets/img/education/classroom-groups.svg'
        },
        {
            title: '',
            desc: 'File Sharing',
            tdesc: 'website_education.file_sharing',
            icon: 'assets/img/education/file-sharing.svg'
        },
        {
            title: '',
            desc: 'Virtual Background',
            tdesc: 'website_education.virtual_background',
            icon: 'assets/img/education/virtual-background.svg'
        }
    ];
    educationFeatures = [
        {
            title: 'Enabling learning continuity',
            ttitle: 'website_education.enable_learning',
            imgMob: 'assets/img/education/enable-learning-mobile.png',
            imgDesktop: 'assets/img/education/enable-learning.png',
            altText: 'Daughter teaching her father on mobile',
            padding0: true,
            desc: [
                {
                    text: 'India has 130+ million students in schools and colleges, out of which 60% are in Tier 3 cities or below.',
                    ttext: 'website_education.education_feature_text0'
                },
                {
                    text: 'Less than 35% of students have access to basic technological infrastructure.',
                    ttext: 'website_education.education_feature_text1'
                },
                {
                    text: 'JioMeet is on a mission to ensure that Covid-19 doesn’t hamper learning for the vast majority of Indian youth.',
                    ttext: 'website_education.education_feature_text2',
                    highlight: true
                }
            ]
        },
        {
            title: 'Inclusive platform even for Non-English speakers',
            ttitle: 'website_education.inclusive_platform',
            imgDesktop: 'assets/img/education/non-english-speaker.png',
            imgMob: 'assets/img/education/non-english-speaker-mobile.png',
            altText: 'Jiomeet is inclusive platform even for Non-English speakers  ',
            desc: [
                {
                    text: '92% of education happens in non-english medium in India.',
                    ttext: 'website_education.education_feature_text3'
                },
                {
                    text: 'JioMeet is the only platform which supports 3 Indian languages— Hindi, Gujarati, and Marathi',
                    ttext: 'website_education.education_feature_text4'
                }
            ]
        },
        {
            title: 'Overcoming technology barriers',
            ttitle: 'website_education.overcome_tech_barrier',
            imgDesktop: 'assets/img/education/overcome-tech-barrier.png',
            imgMob: 'assets/img/education/overcome-tech-barrier-mobile.png',
            altText: 'Mother encouraging  her daughter to attend online classes and overcome technology barriers',
            desc: [
                {
                    text: 'Most of the students who do not have laptops or high-speed internet connection to attend online classes rely only on basic mobile network. JioMeet has been designed for mass consumption in India catering to needs of all the users.',
                    ttext: 'website_education.education_feature_text5'
                }
            ]
        },
        {
            title: 'Online Teaching Aids',
            ttitle: 'website_education.online_teaching_aid',
            imgMob: 'assets/img/education/trading-aids-mobile.png',
            altText: 'Online Teaching Aids  are highly ensured by jiomeet',
            desc: [
                {
                    text: 'JioMeet ensures enhanced teaching experience through teaching aids such as',
                    ttext: 'website_education.education_feature_text7'
                }
            ]
        },
        {
            title: 'Teacher Training',
            ttitle: 'website_education.teacher_training',
            imgDesktop: 'assets/img/education/teacher-training.png',
            imgMob: 'assets/img/education/teacher-training-mobile.png',
            altText: 'Teacher learning to use JioMeet with demos and presentations.',
            desc: [
                {
                    text: 'Teachers learn to use JioMeet with demos and presentations.',
                    ttext: 'website_education.education_feature_text8'
                }
                // {
                //   text: 'Using JioMeet has resulted in exponential increase in tech savviness of the teachers.',
                //   ttext: 'education_feature_text9'
                // }
            ]
        },
        {
            title: 'Parent Teacher Meeting (PTM)',
            ttitle: 'website_education.parent_teacher_meet',
            imgDesktop: 'assets/img/education/parent-teacher-meeting.png',
            imgMob: 'assets/img/education/parent-teacher-meeting-mobile.png',
            altText: 'Online Parent Teacher Meeting (PTM) on jiomeet',
            desc: [
                {
                    text: 'JioMeet analytics help teachers to track every student’s progress and discuss with parents.',
                    ttext: 'website_education.education_feature_text10'
                }
            ]
        },
        {
            title: 'Peer Learning',
            ttitle: 'website_education.peer_learning',
            imgDesktop: 'assets/img/education/peer-learning.png',
            imgMob: 'assets/img/education/peer-learning-mobile.png',
            altText: 'Students forming study groups on JioMeet',
            desc: [
                {
                    text: 'Students are forming study groups on JioMeet where they socialise, work on assignments, share study materials/notes, etc.',
                    ttext: 'website_education.education_feature_text11'
                }
            ]
        },
        {
            title: 'Tracking Student Learning',
            ttitle: 'website_education.tracking_student_learning',
            imgDesktop: 'assets/img/education/student-learning.png',
            imgMob: 'assets/img/education/student-learning-mobile.png',
            altText: 'Teacher tracking student learning through online quizzes, assignments and attendance',
            desc: [
                {
                    text: 'Teachers can evaluate student’s learning through online quizzes, assignments and attendance tracking.',
                    ttext: 'website_education.education_feature_text12'
                }
            ]
        },
        {
            title: 'Personalised Learning',
            ttitle: 'website_education.personalised_learning',
            imgDesktop: 'assets/img/education/pl-learning.png',
            imgMob: ['assets/img/education/pl-learning-mobile.png', 'assets/img/education/pl-learning-mobile-1.png'],
            altText: 'Empowerment of teachers to deliver personalised learning through one to one sessions on jiomeet',
            padding0: true,
            desc: [
                {
                    text: 'Empower teachers to deliver personalised learning through one to one sessions.',
                    ttext: 'website_education.education_feature_text13'
                }
            ]
        },
        {
            title: 'Powering Top Ed-Tech Platforms',
            ttitle: 'website_education.powering_tech_platform',
            imgDesktop: 'assets/img/education/tech-platforms.png',
            imgMob: 'assets/img/education/tech-platforms-mobile.png',
            altText: 'Powering Top Ed-Tech Platforms ',
            desc: [
                {
                    text: 'Helping build multiple independent learning platforms, college ERPs, and education AI platforms to connect teachers with students at scale.',
                    ttext: 'website_education.education_feature_text14'
                }
            ]
        }
    ];
    constructor(
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private gTagService: GoogleTagManagerService,
        private router: Router,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser(1080);
        this.translateTeachingSolutions();
        this.translateEducationFeatures();
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 1080 ? false : true;
            }
        });
        this.authService.getIsAuthenticated$().subscribe((authStatus) => {
            this.isAuthenticated = authStatus;
        });
        this.gTagService.pushOnDataLayer(GA_PAGEVIEW.USECASE_EDUCATION);
    }

    async translateTeachingSolutions() {
        const teachingSolutions = await this.utilService.handleDataTranslation('website_education', {
            teachingSolutions: this.teachingSolutions
        });
        this.teachingSolutions = teachingSolutions['teachingSolutions'];
    }

    async translateEducationFeatures() {
        const educationFeatures = await this.utilService.handleDataTranslation('website_education', {
            educationFeatures: this.educationFeatures
        });
        this.educationFeatures = educationFeatures['educationFeatures'];
    }

    navigateToSignUp() {
        this.gTagService.sendEvent(GA_EVENTS.USECASE_EDUCATION_SIGNUP);
        this.gTagService.sendEvent(GA4_EVENTS.USECASE_EDUCATION_SIGNUP);
        if (this.isAuthenticated) {
            this.router.navigate(['dashboard']);
        } else {
            this.router.navigate(['signup']);
        }
    }

    goToDownloadApp() {
        this.gTagService.sendEvent(GA_EVENTS.USECASE_EDUCATION_DOWNLOAD);
        this.gTagService.sendEvent(GA4_EVENTS.USECASE_EDUCATION_DOWNLOAD);
        this.router.navigate(['jiomeetDownload']);
    }
}
