import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';

import { ConfirmationService } from 'primeng/api';

import * as FileSaver from 'file-saver';

import { ToastrService } from 'ngx-toastr';

import { JioCloudUtilService } from 'src/app/core/services/jio-cloud-util.service';
import { EventEmitterService, AuthService, ChatService, UserService, AppService, UtilService } from 'src/app/core';
import { JiocloudService } from 'src/app/core/services/jiocloud.service';
import { TranslateService } from '@ngx-translate/core';
import { APP_EVENTS } from 'src/app/constants';
import { find } from 'lodash-es';
@Component({
    selector: 'app-chat-attachment',
    templateUrl: './chat-attachment.component.html',
    styleUrls: ['./chat-attachment.component.scss']
})
export class ChatAttachmentComponent implements OnInit {
    @Input() item;
    @Input() jiomeetId;
    @Input() message;
    @Input() showAttachments;
    @Output() refresh = new EventEmitter();
    thumbnailUrl;
    isAuthenticated;
    docType;
    applyClass = '';
    playIcon;
    currentUser;
    @ViewChild('downloadAnchor') downloadAnchor: ElementRef;
    fileType: 'Image' | 'Doc' | 'Video' | 'Audio' | 'None' = 'None';
    isPreviewDownloadEnabled = false;
    creatorDataProtection = false;
    constructor(
        private eventEmitterService: EventEmitterService,
        private authService: AuthService,
        public chatService: ChatService,
        public jioCloudService: JiocloudService,
        private confirmationService: ConfirmationService,
        private jioCloudUtilService: JioCloudUtilService,
        private toastrService: ToastrService,
        private userService: UserService,
        private appService: AppService,
        private translateService: TranslateService,
        private utilService: UtilService
    ) {
        this.currentUser = this.userService.getUserSync();
        this.isAuthenticated = this.authService.getIsAuthenticated();
        this.isPreviewDownloadEnabled = this.appService.isDesktopApp();
    }

    ngOnInit() {
        if (this.chatService.activeChatMembers?.length > 0) {
            let creator = find(this.chatService.activeChatMembers, ['_id', this.message?.createdBy]);
            if (creator) {
                this.creatorDataProtection = this.utilService.canDownloadOrPreviewAttachments(
                    creator,
                    this.currentUser
                );
            }
        }
        this.fileType = this.jioCloudUtilService.getFileFormat(this.item.fileName);
        if (this.fileType === 'Doc') {
            this.docType = this.jioCloudUtilService.getDocType(this.item.fileName);
        }
        this.thumbnailUrl = this.jioCloudUtilService.getDefaultThumbnailForFile(this.fileType);

        if (this.showAttachments) {
            this.applyClass = 'thumbnailIcon';
            this.playIcon = 'videoPlayIconSmall';
        } else if (!this.showAttachments && (this.fileType == 'Doc' || this.fileType == 'Audio')) {
            this.applyClass = 'docIcon';
        } else {
            this.applyClass = '';
            this.playIcon = 'videoPlayIcon';
        }
    }

    openPreview() {
        if (!this.isPreviewDownloadEnabled) {
            this.toastrService.error(
                this.translateService.instant('tostrs.previewing_and_downloading_a_file_is_not_allowed_on_browser')
            );
            return;
        }
        if (!this.isAuthenticated) {
            this.toastrService.error("Guest user(s) can't access this file");
            return;
        }
        this.eventEmitterService.emit({
            type: APP_EVENTS.SHOW_PREVIEW,
            data: {
                item: this.item,
                chatThreadId: this.jiomeetId,
                isDataProtection: this.creatorDataProtection
            }
        });
    }

    deleteItem() {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete this file?',
            header: 'Delete File',
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            acceptButtonStyleClass: 'custom-button bg-primary',
            rejectButtonStyleClass: 'custom-button bg-secondary',
            accept: () => {
                this.chatService
                    .deleteFromJioMeetAndJioCloud(
                        this.message.threadId,
                        this.message._id,
                        this.item.fileID,
                        this.jiomeetId
                    )
                    .subscribe((resp) => {
                        this.refresh.emit(true);
                    });
            },
            reject: () => {}
        });
        this.utilService.setTimeOutForAccessibilityPopUp();
    }

    downloadItem(item, event?) {
        if (event) {
            event.stopPropagation();
        }
        if (this.creatorDataProtection) {
            this.toastrService.error(this.translateService.instant('tostrs.downloading_a_file_is_not_allowed'));
            return;
        }
        this.toastrService.success('Downloading in progress');
        this.jioCloudService
            .downloadFile(this.item.fileName, this.item.downloadURL, this.jiomeetId)
            .subscribe((resp) => {
                FileSaver.saveAs(resp, this.item.fileName);
                this.toastrService.success('Download completed');
            });
    }
}
