import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { RtcService } from './rtc.service';

@Injectable({
    providedIn: 'root'
})
export class LiveStreamService {
    activeLiveStreams: any = [];
    isLiveStreamGoingOn = false;
    liveStreamType = '';
    constructor(private restService: RestService, private rtcService: RtcService) {}

    getAllLiveStreams() {
        return this.restService.get(`api/livestream`);
    }

    createLiveStream(payload) {
        return this.restService.post(`api/livestream/create`, payload);
    }

    updateLiveStream(id, payload) {
        return this.restService.put(`api/livestream/${id}`, payload);
    }

    deleteLiveStream(liveStreamId) {
        return this.restService.delete(`api/livestream/${liveStreamId}`);
    }

    startLiveStream(meetingId, payload) {
        return this.restService.post(`api/meeting/${meetingId}/livestream/multi/start`, payload);
    }

    stopLiveStream(meetingId, payload) {
        return this.restService.post(`api/meeting/${meetingId}/livestream/multi/stop`, payload);
    }

    updateSingleLiveStream(jiomeetId, livestreamType, payload) {
        return this.restService.put(`api/meeting/livestream/${jiomeetId}/${livestreamType}`, payload);
    }

    startSingleLivestreaming(jiomeetId, livestreamType) {
        return this.restService.post(`api/meeting/livestream/${jiomeetId}/${livestreamType}/start`, {});
    }

    stopSingleLivestreaming(jiomeetId, livestreamType) {
        return this.restService.post(`api/meeting/livestream/${jiomeetId}/${livestreamType}/stop`, {});
    }

    setStreamingStatus() {
        const room = this.rtcService.getRoomInfo();
        this.isLiveStreamGoingOn =
            room?.ytlivestreamByJiomeetId?.streaming ||
            room?.fblivestreamByJiomeetId?.streaming ||
            room?.customlivestreamByJiomeetId?.streaming;
        this.liveStreamType = room?.ytlivestreamByJiomeetId?.streaming
            ? 'yt'
            : room?.fblivestreamByJiomeetId?.streaming
            ? 'fb'
            : room?.customlivestreamByJiomeetId?.streaming
            ? 'custom'
            : '';
    }

    setLiveStreamType(type) {
        this.liveStreamType = type;
    }
}
