import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_EVENTS } from 'src/app/constants';
import { EventData, EventEmitterService, UtilService, GoogleTagManagerService, AuthService } from 'src/app/core';
import { GA_EVENTS } from '../ga-events';
import { GA_PAGEVIEW } from '../ga-pageview';
import { GA4_EVENTS } from '../ga4-events';

@Component({
    selector: 'app-website-hybrid-workplace',
    templateUrl: './website-hybrid-workplace.component.html',
    styleUrls: ['../website.usecases.scss']
})
export class WebsiteHybridWorkplaceComponent implements OnInit {
    isMobile = false;
    isAuthenticated: any;

    hybridWorkplaceFeatures = [
        {
            title: 'Scrums, in the Hybrid Way!',
            ttitle: 'website_hybrid_workspace.scrum_hybrid_way',
            imgMob: 'assets/img/hybridWorkplace/hybrid-scrums-mobile.png',
            imgDesktop: 'assets/img/hybridWorkplace/hybrid-scrums.png',
            altText: 'People adopting a more flexible and refined approach to the daily scrums.',
            padding0: true,
            desc: [
                {
                    text: 'The transition towards the Hybrid Workspace calls for phasing out the traditional in-person and online meeting setups.',
                    ttext: 'website_hybrid_workspace.transition_hybrid_workspace'
                },
                {
                    text: 'Instead, let’s step into the Hybrid WorkLife and adopt a more flexible and refined approach to the daily scrums.',
                    ttext: 'website_hybrid_workspace.step_into_hybrid_worklife'
                }
            ]
        },
        {
            title: 'Make way for happy meal hours!',
            ttitle: 'website_hybrid_workspace.happy_meal_hours',
            imgDesktop: 'assets/img/hybridWorkplace/happy-meal-hours.png',
            imgMob: 'assets/img/hybridWorkplace/happy-meal-hours-mobile.png',
            altText: 'Employee enjoying meal with hybrid work life',
            desc: [
                {
                    text: 'Working from home can at times really mess up your breakfasts and make you miss the dedicated in-office coffee breaks even more.',
                    ttext: 'website_hybrid_workspace.working_from_home'
                },
                {
                    text: 'With the Hybrid Work-life, you can now get the best of both worlds, and enjoy your meal hours with teammates worldwide.',
                    ttext: 'website_hybrid_workspace.hybrid_work_life'
                }
            ]
        },
        {
            title: 'The Smarter Work-Life Balance',
            ttitle: 'website_hybrid_workspace.work_life_balance',
            imgDesktop: 'assets/img/hybridWorkplace/smarter-worklife.png',
            imgMob: 'assets/img/hybridWorkplace/smarter-worklife-mobile.png',
            altText: 'Employee maitaining hybrid work life balance',
            desc: [
                {
                    text: 'Post Pandemic, managing work-life balance has become even more challenging. It’s time, that we bring in a more sustainable way of reliving with in-office life while working from home, or vice-versa. The Hybrid Work-Life maintains this balance at its best.',
                    ttext: 'website_hybrid_workspace.work_life_balance_chalanges'
                },
                {
                    text: 'Let’s try the smarter way.',
                    ttext: 'website_hybrid_workspace.hybrid_work_life_maintain'
                }
            ]
        },
        {
            title: 'Celebrating Digital Nomads!',
            ttitle: 'website_hybrid_workspace.celebrate_digital_nomads',
            imgMob: 'assets/img/hybridWorkplace/digital-nomads-mobile.png',
            imgDesktop: 'assets/img/hybridWorkplace/digital-nomads.png',
            altText: 'Collaboration of people Celebrating digital Nomads',
            desc: [
                {
                    text: 'Right from sunbathing on an exotic beach of Phuket to sipping your coffee at classic cafes in Paris, comfort, and flexibility of working from a myriad of locales is on the rise now.',
                    ttext: 'website_hybrid_workspace.flexibility_around'
                },
                {
                    text: 'Let’s give in more to this work-life of digital nomads through the Hybrid Work Style!',
                    ttext: 'website_hybrid_workspace.give_more_hybrid_work_style'
                }
            ]
        },
        {
            title: 'Nurturing the Culture',
            ttitle: 'website_hybrid_workspace.nurtur_the_culture',
            imgDesktop: 'assets/img/hybridWorkplace/nurturing-culture.png',
            imgMob: 'assets/img/hybridWorkplace/nurturing-culture-mobile.png',
            altText: 'Two employees having their meal and maitaining work-life balance',
            padding0: true,
            desc: [
                {
                    text: 'Business Leaders are certainly worried about the cultural imbalance that the remote jobs have triggered within the companies.',
                    ttext: 'website_hybrid_workspace.cultural_imbalance'
                },
                {
                    text: 'Evolving the work-life in a Hybrid Environment can certainly pull the ongoing culture from the physical office location and enable the remote ones to maintain good sync.',
                    ttext: 'website_hybrid_workspace.evolve_worklife_hybrid_env'
                }
            ]
        },
        {
            title: 'Let’s keep the cameras on!',
            ttitle: 'website_hybrid_workspace.keep_the_camera_on',
            imgDesktop: 'assets/img/hybridWorkplace/keep-camera-on-v2.png',
            imgMob: 'assets/img/hybridWorkplace/keep-camera-on-mobile-v2.png',
            altText: 'People attending a meeting on jiomeet keeping the cameras on',
            desc: [
                {
                    text: 'While businesses are exploring the meeting etiquettes, JioMeet encourages conferencing with cameras ON to see the expressions or the body language of remote teammates and prevent distraction or disengagement.',
                    ttext: 'website_hybrid_workspace.jiomeet_encourage_conf_camera'
                }
            ]
        },
        {
            title: 'The Relaxed Work-Life',
            ttitle: 'website_hybrid_workspace.relaxed_worklife',
            imgDesktop: 'assets/img/hybridWorkplace/relaxed-work-life.png',
            imgMob: 'assets/img/hybridWorkplace/relaxed-work-life-mobile.png',
            altText: 'An employee having comfort in work-life',
            desc: [
                {
                    text: 'The Hybrid Ecosystem brings a radical shift in management and administration. More Informal working styles can be adapted that would definitely outweigh the tight schedules of in-office life as comfort or flexibility of employees takes precedence.',
                    ttext: 'website_hybrid_workspace.hybrid_ecosystem'
                }
            ]
        }
    ];
    constructor(
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private gTagService: GoogleTagManagerService,
        private router: Router,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser(1080);
        this.translateHybridWorkplaceSolutions();
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 1080 ? false : true;
            }
        });
        this.authService.getIsAuthenticated$().subscribe((authStatus) => {
            this.isAuthenticated = authStatus;
        });
        this.gTagService.pushOnDataLayer(GA_PAGEVIEW.USECASE_HYBRID_WORKPLACE);
    }

    async translateHybridWorkplaceSolutions() {
        const HybridWorkplaceSolutions = await this.utilService.handleDataTranslation('website_hybrid_workspace', {
            HybridWorkplaceSolutions: this.hybridWorkplaceFeatures
        });
        this.hybridWorkplaceFeatures = HybridWorkplaceSolutions['HybridWorkplaceSolutions'];
    }

    navigateToSignUp() {
        this.gTagService.sendEvent(GA_EVENTS.USECASE_HYBRID_WORKPLACE_SIGNUP);
        this.gTagService.sendEvent(GA4_EVENTS.USECASE_HYBRID_WORKPLACE_SIGNUP);
        if (this.isAuthenticated) {
            this.router.navigate(['dashboard']);
        } else {
            this.router.navigate(['signup']);
        }
    }

    goToDownloadApp() {
        this.gTagService.sendEvent(GA_EVENTS.USECASE_HYBRID_WORKPLACE_DOWNLOAD);
        this.gTagService.sendEvent(GA4_EVENTS.USECASE_HYBRID_WORKPLACE_DOWNLOAD);
        this.router.navigate(['jiomeetDownload']);
    }
}
