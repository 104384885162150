<div
    class="modal fade"
    id="preview-modal"
    [ngStyle]="{ display: this.showPreview ? 'block' : 'none', opacity: 1 }"
    tabindex="-1"
    role="dialog"
>
    <div class="modal-header">
        <div class="row headerRow">
            <div class="col-md-4 videoName"><img alt="" src="assets/images/defaultVideoIcon.svg" />{{ fileName }}</div>
            <div class="col-md-8 preview-opt-cont">
                <div class="row">
                    <div class="col-md-4 mr-3" data-toggle="tooltip" title="Download" (click)="downloadFile()">
                        <img alt="" class="downloadButton pointer" src="assets/images/downloadIcon.svg" />
                    </div>
                    <div class="col-md-4 mr-5" (click)="this.closePreview(false)" data-toggle="tooltip" title="Close">
                        <button class="closeButton pointer">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button class="hide" id="downloadAnchor" #downloadAnchor></button>

    <div class="modal-body" id="myObject">
        <div class="media-wrapper">
            <div id="video-media-content" class="media-content"></div>
        </div>
    </div>
</div>
