<div class="website-healthcare website-usecases" id="website-top">
    <section class="healthcare-top section-top" [class.padding-h-16]="isMobile">
        <div
            class="left-content First-Element-Focus"
            tabindex="0"
            aria-label="The JioMeet for HealthCare Page has been opened!
             To navigate through the page, please utilize the tab key on your keyboard"
        >
            <div class="subheading-1" tabindex="0">
                {{ 'website_healthcare.top-title-0' | translate }}
            </div>
            <div class="heading" tabindex="0">
                {{ 'website_healthcare.top-title-1' | translate }}
            </div>
            <div class="desc subheading-0" tabindex="0">
                {{ 'website_healthcare.top-desc' | translate }}
            </div>
            <div class="action-btns">
                <button
                    class="custom-action-btn"
                    (click)="navigateToSignUp()"
                    (keydown.enter)="navigateToSignUp()"
                    tabindex="0"
                >
                    {{ 'website_healthcare.start_free' | translate }}
                </button>
                <button
                    class="custom-action-btn secondary"
                    (click)="goToDownloadApp()"
                    (keydown.enter)="goToDownloadApp()"
                    tabindex="0"
                >
                    {{ 'website_healthcare.download' | translate }}
                </button>
            </div>
        </div>
        <div class="right-content">
            <img
                src="assets/img/healthcare/healthcare-top.png"
                *ngIf="!isMobile"
                alt="Jiomeet helping patients, doctors and health institutions"
            />
            <img src="assets/img/healthcare/healthcare-top-mobile.png" *ngIf="isMobile" alt="" />
        </div>
    </section>

    <div *ngIf="!isMobile">
        <section class="sec-1 sec">
            <div class="img">
                <img [src]="healthcareFeatures[0].imgDesktop" [alt]="healthcareFeatures[0].altText" />
            </div>
            <div class="sec-info padding-h-140-150">
                <div class="heading-0" tabindex="0" [class.padding-h-44]="featureIndex === 0">
                    {{ healthcareFeatures[0].ttitle | translate }}
                </div>
            </div>

            <div class="sec-info padding-h-140-150 margin-top-0" [ngStyle]="{ 'align-items': 'start' }">
                <div>
                    <p class="subheading-2" tabindex="0">
                        {{ healthcareFeatures[0].desc[0].ttext | translate }}
                    </p>
                </div>
                <div>
                    <p class="subheading-2" tabindex="0">
                        {{ healthcareFeatures[0].desc[1].ttext | translate }}
                    </p>
                </div>
            </div>
        </section>

        <section class="sec-2 sec padding-h-140-150">
            <app-usecase-features [useCaseFeature]="healthcareFeatures[1]" [featureIndex]="1"></app-usecase-features>
        </section>

        <section class="sec padding-h-140-150">
            <app-usecase-features [useCaseFeature]="healthcareFeatures[2]" [featureIndex]="2"></app-usecase-features>
        </section>

        <section class="sec-1 sec">
            <div class="img">
                <img [src]="healthcareFeatures[3].imgDesktop" alt="" />
            </div>
            <div class="heading-0 padding-h-140-150" tabindex="0">
                {{ healthcareFeatures[3].ttitle | translate }}
            </div>
            <div class="sec-info padding-h-140-150" [ngStyle]="{ 'align-items': 'start' }">
                <div>
                    <p class="subheading-2" tabindex="0">
                        {{ healthcareFeatures[3].desc[0].ttext | translate }}
                    </p>
                </div>
                <div>
                    <p class="subheading-2" tabindex="0">
                        {{ healthcareFeatures[3].desc[1].ttext | translate }}
                    </p>
                </div>
            </div>
        </section>

        <section class="sec-5 sec padding-h-140-150">
            <app-usecase-features [useCaseFeature]="healthcareFeatures[4]" [featureIndex]="5"></app-usecase-features>
        </section>

        <section class="sec-6 sec padding-h-140-150">
            <app-usecase-features [useCaseFeature]="healthcareFeatures[5]" [featureIndex]="6"></app-usecase-features>
        </section>

        <section class="sec-7 sec padding-h-140-150">
            <app-usecase-features [useCaseFeature]="healthcareFeatures[6]" [featureIndex]="7"></app-usecase-features>
        </section>
    </div>

    <ng-container *ngIf="isMobile">
        <section *ngFor="let feature of healthcareFeatures; let i = index" class="sec">
            <app-usecase-features [useCaseFeature]="feature" [featureIndex]="i"></app-usecase-features>
        </section>
    </ng-container>

    <section class="got-questions" [class.padding-h-44]="isMobile">
        <app-explore-meet
            bottomButtons="healthcare"
            title="{{ 'website_healthcare.explore_title' | translate }}"
            desc="{{ 'website_healthcare.explore_desc' | translate }}"
            btnText1="{{ 'website_healthcare.start_free' | translate }}"
            btnText2="{{ 'website_healthcare.download_now' | translate }}"
            btnText1Link="/signup"
            btnText2Link="/jiomeetDownload"
        ></app-explore-meet>
    </section>
</div>
