import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { AuthService } from 'src/app/core';
import { GaDiagnosticService } from 'src/app/core/services/ga-diagnostic.service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    changePasswordForm: FormGroup;
    passwordToggle = { oldPassword: false, newPassword: false, confirmPassword: false };
    errors: any = {};
    previousPasswordValuess = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    };
    passwordMinLength = 8;
    passwordMaxLength = 20;
    canChangePassword = false;

    constructor(
        private fb: FormBuilder,
        private toastrService: ToastrService,
        private router: Router,
        private authService: AuthService,
        private gaService: GaDiagnosticService
    ) {}

    ngOnInit() {
        this.changePasswordForm = this.fb.group({
            oldPassword: [null, [Validators.required]],
            newPassword: [null, [Validators.required]],
            confirmPassword: [null, [Validators.required]]
        });

        this.changePasswordForm.get('oldPassword').valueChanges.subscribe((val) => {
            if (val.length > this.passwordMaxLength) {
                this.changePasswordForm.get('oldPassword').setValue(this.previousPasswordValuess.oldPassword);
            } else {
                this.previousPasswordValuess.oldPassword = val;
            }
        });

        this.changePasswordForm.get('newPassword').valueChanges.subscribe((val) => {
            if (val.length > this.passwordMaxLength) {
                this.changePasswordForm.get('newPassword').setValue(this.previousPasswordValuess.newPassword);
            } else {
                this.previousPasswordValuess.newPassword = val;
            }
        });

        this.changePasswordForm.get('confirmPassword').valueChanges.subscribe((val) => {
            if (val.length > this.passwordMaxLength) {
                this.changePasswordForm.get('confirmPassword').setValue(this.previousPasswordValuess.confirmPassword);
            } else {
                this.previousPasswordValuess.confirmPassword = val;
            }
        });
        this.gaService.sendPageView({
            page_title: 'change_password_page',
            page_path: '/change-password'
        });
    }

    validate() {
        this.errors = {};
        if (!this.changePasswordForm.value.oldPassword) {
            this.errors.oldPassword = 'Password cannot be blank.';
            return false;
        }
        if (!this.changePasswordForm.value.newPassword) {
            this.errors.newPassword = 'Password cannot be blank.';
            return false;
        }
        if (this.changePasswordForm.value.newPassword.length < this.passwordMinLength) {
            this.errors.newPassword = 'Password should at least be 8 characters long.';
            return false;
        }
        if (this.changePasswordForm.value.newPassword !== this.changePasswordForm.value.confirmPassword) {
            this.errors.confirmPassword = 'Passwords do not match, please re-enter';
            return false;
        }
        if (this.changePasswordForm.value.newPassword.length > this.passwordMaxLength) {
            this.errors.confirmPassword = `Password cannot be more than ${this.passwordMaxLength} characters long.`;
            return false;
        }
        return true;
    }

    submit() {
        if (!this.validate()) {
            return;
        }
        this.authService
            .changePassword({
                oldPassword: this.changePasswordForm.value.oldPassword,
                newPassword: this.changePasswordForm.value.newPassword
            })
            .subscribe(
                () => {
                    this.toastrService.success('Password changed successfully! Please login with your new password.');
                    this.authService.cleanup();
                    this.router.navigate(['/login']);
                },
                (err) => {
                    this.errors.global = err.error.error || err.error.errors;
                }
            );
    }
}
