import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-online-offline-banner',
    templateUrl: './online-offline-banner.component.html',
    styleUrls: ['./online-offline-banner.component.scss']
})
export class OnlineOfflineBannerComponent implements OnInit, OnChanges {
    @Output() hideBanner = new EventEmitter();
    @Input() bannerInput;

    bannerData;

    typeOnlineOffline = false;
    isOffline = false;

    constructor() {}
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.bannerInput.currentValue) {
            this.bannerData = changes.bannerInput.currentValue;
        }
    }

    ngOnInit(): void {}

    closeBanner() {
        this.hideBanner.next();
    }
}
