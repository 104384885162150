import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, RestService, UtilService, SessionStorageService } from 'src/app/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-payment-success-failure',
    templateUrl: './payment-success-failure.component.html',
    styleUrls: ['./payment-success-failure.component.scss']
})
export class PaymentSuccessFailureComponent implements OnInit {
    isSuccess: boolean = false;
    isFailure: boolean = false;
    isAuthenticated: boolean = false;
    userName = '';
    referenceNumber = '';
    transactionId = '';
    paymentMode = '';
    totalAmount;
    status = '';
    paymentResult: boolean = false;
    counter = 60;
    currentDate: any;
    expiredDate: any;
    intervalId: any;
    isLoading: boolean = false;
    newFlow: boolean = false;
    need_support: string = '';

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private restService: RestService,
        private datePipe: DatePipe,
        private translateService: TranslateService,
        private utilService: UtilService,
        private sessionStorageService: SessionStorageService
    ) {}

    ngOnInit(): void {
        this.status = this.activatedRoute.snapshot.queryParams?.status || '';
        this.status === 'success' ? (this.paymentResult = true) : (this.paymentResult = false);
        this.isAuthenticated = this.authService.getIsAuthenticated() || false;
        if (this.isAuthenticated) {
            this.restService
                .get(`api/my_profile`, {
                    header: { Authorization: this.activatedRoute.snapshot.queryParams?.userToken }
                })
                .subscribe((user) => {
                    const currentUser = user;
                    this.userName = currentUser.name + ' ' + (currentUser.lname ? currentUser.lname : '');
                    this.referenceNumber = currentUser.subscriptionInfo.referenceId;
                    this.transactionId = currentUser.subscriptionInfo.transactionId;
                    this.paymentMode = currentUser.subscriptionInfo.paymentMode;
                    this.totalAmount = currentUser.subscriptionInfo.totalAmount;
                    const date = new Date(currentUser.subscriptionInfo.cOn);
                    this.currentDate = this.datePipe.transform(date, 'dd MMM, yyyy hh:mm a');
                    this.currentDate = this.utilService.translateDateWithTime(this.currentDate);
                    const expiredDate = new Date(currentUser.subscriptionInfo.expiresOn);
                    this.expiredDate = this.datePipe.transform(expiredDate, 'dd MMMM yyyy');
                    this.expiredDate = this.utilService.translatedDate(this.expiredDate);
                });
            this.paymentResult ? (this.isSuccess = true) : (this.isFailure = true);
            if (this.paymentResult) {
                this.intervalId = setInterval(() => {
                    if (this.counter > 0) this.counter -= 1;
                    if (this.counter === 0) {
                        this.redirectToHome();
                        clearInterval(this.intervalId);
                    }
                }, 1000);
            }
            if (this.isFailure) {
                const date = new Date();
                this.currentDate = this.datePipe.transform(date, 'dd MMM, yyyy hh:mm a');
                this.currentDate = this.utilService.translateDateWithTime(this.currentDate);
                this.need_support = this.translateService.instant('payment-page.need_support', {
                    value: 'jiomeet.support@jio.com'
                });
            }
        }
    }

    redirectToHome() {
        this.router.navigate(['/home']);
    }

    close() {
        this.router.navigate(['/pricing'], { queryParams: { newFlow: true } });
    }

    redirectToTechJockey() {
        if (!this.isAuthenticated) {
            const returnUrl = '/pricing';
            this.router.navigate(['login'], { queryParams: { returnUrl } });
        } else {
            window.open(environment.PAYMENT_GATEWAY_URL, '_blank');
        }
    }

    redirectToChargeIt() {
        if (!this.isAuthenticated) {
            this.sessionStorageService.addItem('returnUrl', '/pricing');
            var returnUrl = '';
            returnUrl = this.newFlow ? '/pricing?newFlow=true' : '/pricing';
            this.router.navigate(['login'], { queryParams: { returnUrl } });
        } else {
            this.isLoading = true;
            this.utilService.createOrder().subscribe((res: any) => {
                if (res.success) {
                    window.location.href = res.paymentUrl;
                }
                setTimeout(() => {
                    this.isLoading = false;
                }, 500);
            });
        }
    }
}
