export const environment = {
    production: true,
    local: false,
    HOST_URL: 'https://prestage.jiomeet.com/',
    BASE_URL: 'https://prestage.jiomeet.com/api',
    CPASS_HOST_URL: 'https://prestage-platform.jiomeet.com/',
    EVENTS_URL: 'https://prestage.jioevents.com/',
    JIO_CLOUD: {
        HOST: 'https://pp-apis.tejdrive.com',
        HOST_UPLOAD: 'https://pp-upload.tejdrive.com'
    },
    PAYMENT_GATEWAY_URL: 'https://www.techjockey.com/ordernow/jiomeet'
};
