<div class="page container-fluid" *ngIf="!showUI">
    <app-loader></app-loader>
</div>
<div class="page container-fluid" *ngIf="showUI && !mailSent">
    <div class="row h-100">
        <div class="left-content col-xl-7 col-lg-7 col-md-12 col-sm-12">
            <div class="img-wrap">
                <app-lottie-renderer path="assets/lottie/Page Animation.json"></app-lottie-renderer>
            </div>
        </div>
        <div class="right-content col-xl-5 col-lg-5 col-md-12 col-sm-12 py-5">
            <div class="component-wrap pb-3">
                <div class="form-wrap">
                    <h3>{{ 'forgot-password.forgot_password' | translate }}</h3>
                    <div class="sub-title">
                        {{ 'forgot-password.please_enter' | translate }}
                    </div>
                    <form [formGroup]="resetpasswordForm" (submit)="submit()" class="custom-form">
                        <div class="form-group">
                            <label>{{ 'forgot-password.email' | translate }}</label>
                            <input
                                type="email"
                                class="form-control"
                                id="email"
                                formControlName="email"
                                aria-describedby="emailHelp"
                                [class.is-invalid]="errors.email"
                            />
                            <div class="invalid-feedback text-error text-left mt-2" *ngIf="errors.email">
                                {{ 'forgot-password.email_error' | translate }}
                            </div>
                            <div class="text-error text-left mt-2" *ngIf="errors.global">{{ errors.global }}</div>
                        </div>
                        <button
                            type="submit"
                            class="custom-button bg-primary"
                            [class.disabled]="!resetpasswordForm.valid || loading"
                        >
                            {{ (loading ? 'forgot-password.please_wait' : 'forgot-password.send') | translate }}
                        </button>
                    </form>
                    <div class="sign-up-text mt-4">
                        <span class="acc-info mr-1">{{ 'forgot-password.dont_have' | translate }} </span>
                        <span class="link">
                            <a class="text-primary" (click)="navigateToSignUp()"
                                ><u>{{ 'forgot-password.signup' | translate }}</u></a
                            >
                        </span>
                    </div>
                    <div class="success py-5" *ngIf="mailSent">
                        <div class="title">{{ 'forgot-password.reset_your' | translate }}</div>
                        <div class="sub-title">
                            {{ 'forgot-password.an_email_has' | translate }}
                            <strong>{{ resetpasswordForm.controls.email.value }}</strong>
                            {{ 'forgot-password.please_click_the_resend' | translate }}
                        </div>
                        <div class="footer-text">
                            <p>{{ 'forgot-password.didnt_receive' | translate }}</p>
                            <p>
                                {{ 'forgot-password.please_check_your_spam' | translate }}
                                <a (click)="submit()">{{
                                    (loading ? 'forgot-password.sending_email' : 'forgot-password.try_again')
                                        | translate
                                }}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="page" *ngIf="showUI && mailSent">
    <div class="forgot-component post-email">
        <div class="header">{{ 'forgot-password.check_your_inbox' | translate }}</div>
        <div class="img-wrap">
            <img alt="" src="assets/img/signup-email.svg" />
        </div>
        <div class="top-text py-4">
            <div>
                {{ 'forgot-password.weve_sent' | translate }}
                <a class="email"> {{ resetpasswordForm.get('email').value }},</a>
            </div>
            <div>{{ 'forgot-password.please_click_the_resend' | translate }}</div>
        </div>
        <div class="bottom-text">
            <div class="mail-bottom-text">
                {{ 'forgot-password.havent_received' | translate }}
                <a class="pointer blue underline d-inline" (click)="sendEmail()">{{
                    'forgot-password.resend' | translate
                }}</a>
                {{ 'forgot-password.or' | translate }}
                <a class="pointer blue underline d-inline" (click)="reset()">{{
                    'forgot-password.use_a_different' | translate
                }}</a>
            </div>
        </div>
        <div class="sign-in">
            <a class="pointer blue underline" (click)="navigateToSignIn()">{{
                'forgot-password.sign_in_now' | translate
            }}</a>
        </div>
    </div>
</div>
