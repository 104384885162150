<div class="info-banner-popup">
    <h1>sdfsfsd</h1>
    <div class="bubble">
        <div class="type">
            <img alt="" [src]="infoImage" />
        </div>
        <div class="content">
            <div class="header" *ngIf="!forDeclineAccessControl">
                <div>
                    {{ 'inside_call.call_component.screen_control_message' | translate }}
                </div>
                <div class="header">
                    {{ 'inside_call.call_component.screen_control_message1' | translate }}
                </div>
            </div>
            <div class="msg" *ngIf="forDeclineAccessControl">
                {{ message }}
            </div>
        </div>
        <div class="actions-btn" *ngIf="!forDeclineAccessControl">
            <button class="option" (click)="JioMeetDownload()">
                {{ 'inside_call.call_component.download_lite' | translate }}
            </button>
        </div>
        <div class="close">
            <img
                alt=""
                (click)="closeBanner()"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDQwIDQwIj4KICA8ZyBpZD0iR3JvdXBfMTE5MzAxIiBkYXRhLW5hbWU9Ikdyb3VwIDExOTMwMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTc5MiAtMjIyKSI+CiAgICA8Y2lyY2xlIGlkPSJFbGxpcHNlXzE3NDA4IiBkYXRhLW5hbWU9IkVsbGlwc2UgMTc0MDgiIGN4PSIyMCIgY3k9IjIwIiByPSIyMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNzkyIDIyMikiIGZpbGw9Im5vbmUiLz4KICAgIDxnIGlkPSJHcm91cF8xMTkyODkiIGRhdGEtbmFtZT0iR3JvdXAgMTE5Mjg5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxODI4OC41MDQgMTc0MzAuNTA0KSI+CiAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfMjQ4OTIiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDI0ODkyIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNzQ4OC41MDQgLTE3MjAwLjUwNCkiIGZpbGw9Im5vbmUiLz4KICAgICAgPHBhdGggaWQ9ImNsb3NlIiBkPSJNMTMuOCwxMi4zM2w2LjU2LTYuNTVBMS4wNDYsMS4wNDYsMCwxLDAsMTguODgsNC4zbC02LjU1LDYuNTZMNS43ODEsNC4zQTEuMDQ2LDEuMDQ2LDAsMCwwLDQuMyw1Ljc4MWw2LjU2LDYuNTVMNC4zLDE4Ljg4YTEuMDQ2LDEuMDQ2LDAsMSwwLDEuNDc5LDEuNDc5TDEyLjMzLDEzLjhsNi41NSw2LjU2YTEuMDQ2LDEuMDQ2LDAsMSwwLDEuNDc5LTEuNDc5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3NDg4LjgzNiAtMTcyMDAuODM2KSIgZmlsbD0iIzk3OWNhYSIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg=="
            />
        </div>
    </div>
</div>
