import { Injectable } from '@angular/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';

import {
    UtilService,
    AppService,
    UserService,
    MeetingService,
    SystemService,
    ThirdPartyExternalIntegrationService,
    AuthService,
    DiagnosticService,
  LocalStorageService,
  DesktopAppService
} from 'src/app/core';

@Injectable({
  providedIn: 'root'
})
export class DeepLinkService {
  supported = false;
  currentOS: 'Windows' | 'Mac OS' | 'Other';
  params = '';
  meetingId;
  hash;
  pwd;
  isMobileOS;
  isIOS;
  iosMacDeeplink;
  isHosting = false;
  currentUser;
  enableJoinWithBrowser = false;
  subscriptions: Subscription[] = [];
  type: 'shortener' | 'addtojiomeet' | 'openjiomeet';
  browser;
  showCompatibilityMessage = true;
  isWebinar: boolean = false;
  loading: boolean = true;
  redirectToJioConf: boolean = false;
  isThirdPartyExternalIntegration;
  isAuthenticated: any;
  iosMacDeeplinkSubject = new Subject();
  isMyjioIntegration: boolean = false;
  isMobileBrowser: boolean;

  constructor(
    private utilService: UtilService,
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private localStorageSession: LocalStorageService,
    private route: Router,
    private userService: UserService,
    private toastrService: ToastrService,
    private meetingService: MeetingService,
    private systemService: SystemService,
    private authService: AuthService,
    private translateService: TranslateService,
    private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
    private diagnosticService: DiagnosticService,
    private desktopAppService: DesktopAppService
  ) { }

  handleDeepLink(activatedRoute: ActivatedRoute) {
    if (this.desktopAppService.isDesktopApp) {
      return;
    }
    this.activatedRoute = activatedRoute;
    this.activatedRoute.data.subscribe((route) => {
      if (route?.type === 'openjiomeet') {
        this.type = 'openjiomeet';
      } else {
        this.type = 'shortener';
      }
    });
    this.isThirdPartyExternalIntegration =
      this.thirdPartyExternalIntegrationService.getThirdPartyExternalIntegration();
    this.isMyjioIntegration = this.thirdPartyExternalIntegrationService.getMyjioIntegration();
    if (!this.isThirdPartyExternalIntegration) {
      this.checkBrowserCompatibiity();
    }
    this.authService.getIsAuthenticated$().subscribe((bool) => {
      this.isAuthenticated = bool;
    });

    this.browser = this.utilService.detectBrowser();
    this.enableJoinWithBrowser = this.appService.getConfigVariable('ENABLE_WEBRTC');
    this.redirectToJioConf = this.appService.getConfigVariable('jioConfConfig')?.redirectToJioconfHost;
    this.currentUser = this.userService.getUserSync();
    this.supported = this.utilService.isRTCSupportedBrowser();
    this.setOS();
    this.fetchParams();
    if (this.isThirdPartyExternalIntegration && !this.isMyjioIntegration) {
      this.join();
    } else {
      this.handleLaunch();
    }
  }

  checkBrowserCompatibiity() {
    const { browser, version } = this.systemService.getSysytemInfo();
    const majorVersion = parseInt((version || '').split('.')[0], 10);
    if (browser === 'Safari' && majorVersion >= 13) {
      this.showCompatibilityMessage = false;
    }
    if (browser === 'Chrome' && majorVersion >= 83) {
      this.showCompatibilityMessage = false;
    }
  }

  setOS() {
    const os = this.utilService.getOS();
    switch (os) {
      case 'Windows':
        this.currentOS = 'Windows';
        break;
      case 'Mac OS':
        this.currentOS = 'Mac OS';
        break;
      default:
        this.currentOS = 'Other';
    }
  }

  launch() {
    const os = this.utilService.getOS();
    switch (os) {
      case 'Windows':
        let isPlatformRoom = this.localStorageSession.getItem('isPlatform');
        if (isPlatformRoom) {
          break;
        }

        location.href =
          'jiomeet:' + this.appService.getEnvVariable('HOST_URL') + this.type + this.fetchHostMeetingParams();
        break;
      case 'Mac OS':
        this.iosMacDeeplink = null;
        let isPlatformRoom_1 = this.localStorageSession.getItem('isPlatform');
        if (isPlatformRoom_1) {
          break;
        }
        setTimeout(() => {
          this.iosMacDeeplink =
            'jhb://' +
            this.appService.getEnvVariable('HOST_URL') +
            this.type +
            this.fetchHostMeetingParams();
          this.iosMacDeeplinkSubject.next(this.iosMacDeeplink);
        }, 100);
        break;
      case 'iOS':
        if (this.utilService.isEdgeBrowser()) {
          if (this.utilService.isIpad()) {
            this.openAppOrNavigateToStore();
          } else {
            // for iphone
            this.iosMacDeeplink = null;
            setTimeout(() => {
              this.iosMacDeeplink =
                'jhb://' +
                this.appService.getEnvVariable('HOST_URL') +
                this.type +
                this.fetchHostMeetingParams();
            }, 100);
          }
        } else {
          this.openAppOrNavigateToStore();
          // const a = document.createElement('a');
          // a.href = this.appService.getConfigVariable('IOS_INSTALLER_URL');
          // a.download = 'Download';
          // document.body.appendChild(a);
          // a.click();
          // document.body.removeChild(a);
        }
        this.isMobileOS = true;
        break;
      case 'Android':
        this.openJiomeetOrPlaystore();
        this.isMobileOS = true;
        break;
      default:
        if (this.utilService.detectBrowser() === 'android') {
          this.openJiomeetOrPlaystore();
          this.isMobileOS = true;
          return;
        }
        if (this.utilService.detectBrowser() === 'ipad' || this.utilService.detectBrowser() === 'iphone') {
          const a = document.createElement('a');
          a.href = this.appService.getConfigVariable('IOS_INSTALLER_URL');
          a.download = 'Download';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.isMobileOS = true;
          return;
        }
        break;
    }
  }

  fetchHostMeetingParams() {
    if (this.isHosting) {
      let param = '';
      if (this.currentUser.jiomeetId) {
        param = `?meetingId=${this.currentUser.jiomeetId}`;
      }
      param = `${param}${this.currentUser.roomPIN ? '&pwd=' + this.currentUser.roomPIN : ''}`;
      return param;
    }
    return this.fetchParams();
  }

  fetchParams() {
    this.isIOS = this.activatedRoute.snapshot.queryParams.ios;
    this.meetingId = this.activatedRoute.snapshot.queryParams.meetingId;
    this.hash = this.activatedRoute.snapshot.queryParams.hash;
    this.pwd = this.activatedRoute.snapshot.queryParams.pwd;
    this.isHosting = this.activatedRoute.snapshot.queryParams.host;
    let param = '';
    if (this.meetingId) {
      param = `?meetingId=${this.meetingId}`;
    } else if (this.hash) {
      param = `?hash=${this.hash}`;
    }
    param = `${param}${this.pwd ? '&pwd=' + this.pwd : ''}`;
    return param;
  }

  openAppOrNavigateToStore() {
    if (this.isIOS !== 'true') {
      window.location.href =
        'jhb://' + this.appService.getEnvVariable('HOST_URL') + this.type + this.fetchParams();
      setTimeout(() => {
        const params = this.fetchParams();
        let iosTrue = '';
        if (params) {
          iosTrue = '&ios=true';
        } else {
          iosTrue = '?ios=true';
        }
        window.location.replace(this.appService.getEnvVariable('HOST_URL') + this.type + params + iosTrue);
      }, 2000);
    } else {
      const a = document.createElement('a');
      a.href = this.appService.getConfigVariable('IOS_INSTALLER_URL');
      a.download = 'Download';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  openJiomeetOrPlaystore() {
    window.location.href = `intent://${this.appService.getEnvVariable('HOST_URL').slice('https://'.length)}${this.type
      }${this.fetchHostMeetingParams()}#Intent;package=com.jio.rilconferences;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;scheme=https;end`;

    setTimeout(() => {
      // window.location.href = this.appService.getConfigVariable('ANDROID_INSTALLER_URL');
      window.open(this.appService.getConfigVariable('ANDROID_INSTALLER_URL'), '_blank');
    }, 1000);
  }

  launchJioMeetAppOnMobile() {
    // const os = this.utilService.getOS();
    let os = 'Android';
    this.type = 'shortener';
    switch (os) {
      case 'iOS':
        if (this.utilService.isEdgeBrowser()) {
          if (this.utilService.isIpad()) {
            this.openIOSAppUtil();
          } else {
            // for iphone
            this.iosMacDeeplink = null;
            setTimeout(() => {
              this.iosMacDeeplink =
                'jhb://' +
                this.appService.getEnvVariable('HOST_URL') +
                this.type +
                this.fetchHostMeetingParams();
            }, 100);
          }
        } else {
          this.openIOSAppUtil();
        }
        this.isMobileOS = true;
        break;
      case 'Android':
        this.openJiomeetAppAndroid();
        this.isMobileOS = true;
        break;
    }
  }

  openJiomeetAppAndroid() {
    window.location.href = `${this.appService.getEnvVariable('HOST_URL')}${this.type
      }${this.fetchHostMeetingParams()}&isAndroid=true#Intent;package=com.jio.rilconferences;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;scheme=https;end`;
  }

  openIOSAppUtil() {
    if (this.isIOS !== 'true') {
      window.location.href = this.appService.getEnvVariable('HOST_URL') + this.type + this.fetchParams();
    }
  }

  join() {
    if (!this.supported && !this.isThirdPartyExternalIntegration && !this.isWebinar) {
      this.toastrService.warning('JioMeet is not supported on this browser. Please use Chrome or Safari. ');
      return;
    }
    if (!(this.enableJoinWithBrowser || this.currentUser?.nativeWebrtcEnabled)) {
      this.toastrService.warning("You don't have permission to join meeting in browser");
      return;
    }
    if (this.redirectToJioConf && this.isWebinar) {
      this.diagnosticService
        .sendEvent({
          eventCategory: 'Jioconf Join Event',
          eventAction: 'Continue on Browser',
          eventType: 'app_event',
          endpoint: '',
          status: 'success'
        })
        .subscribe();
      window.location.href = `${this.appService.getEnvVariable('HOST_URL')}jioconf/join?meetingId=${this.meetingId
        }&pwd=${this.pwd}`;
      return;
    }
    this.isHosting = this.activatedRoute.snapshot.queryParams.host;
    if (this.isHosting) {
      this.route.navigate(['host'], {
        queryParams: {
          meetingId: this.meetingId
        }
      });
    } else {
      this.route.navigate(['join'], {
        queryParams: {
          meetingId: this.meetingId,
          hash: this.hash,
          pwd: this.pwd
        }
      });
    }
  }

  handleLaunch() {
    const os = this.utilService.getOS();
    if (os !== 'iOS' && os !== 'Android' && this.type === 'shortener') {
      // this.join();
      if (!this.isWebinar) {
        this.launch();
      }
    } else {
      this.launch();
    }
  }
}
