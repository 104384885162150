import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppService } from './app.service';

@Injectable()
export class RestService {
    constructor(private httpClient: HttpClient, private appService: AppService) {}

    get(url, options: any = {}, includeHosturl = true): Observable<any> {
        return this.httpClient.get(
            includeHosturl ? this.appService.getEnvVariable('HOST_URL', true) + url : url,
            options
        );
    }

    post(url: string, body: any | null, options: any = {}, includeHosturl = true) {
        return this.httpClient.post(
            includeHosturl ? this.appService.getEnvVariable('HOST_URL', true) + url : url,
            body,
            options
        );
    }

    put(url: string, body: any | null, options: any = {}, includeHosturl = true) {
        return this.httpClient.put(
            includeHosturl ? this.appService.getEnvVariable('HOST_URL', true) + url : url,
            body,
            options
        );
    }

    delete(url: string, options: any = {}, includeHosturl = true) {
        return this.httpClient.delete(
            includeHosturl ? this.appService.getEnvVariable('HOST_URL', true) + url : url,
            options
        );
    }
}
