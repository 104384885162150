<div class="viewer" *ngIf="show">
    <app-audio-clappr
        *ngIf="supportedFormat === 'Audio'"
        [downloadUrl]="item?.downloadURL"
        [isDataProtection]="isDataProtection"
        [meetingImageTranscodeUrl]="item?.imageTrancodedURL"
        [meetingPlaybackUrl]="item?.streamingURL"
        [fileName]="item?.fileName"
        [jiomeetId]="jiomeetId"
        (close)="closePreview($event)"
    >
    </app-audio-clappr>

    <app-video-clappr
        *ngIf="supportedFormat === 'Video'"
        [downloadUrl]="item?.downloadURL"
        [isDataProtection]="isDataProtection"
        [meetingImageTranscodeUrl]="item?.imageTrancodedURL"
        [meetingPlaybackUrl]="item?.streamingURL"
        [fileName]="item?.fileName"
        [jiomeetId]="jiomeetId"
        (close)="closePreview($event)"
    ></app-video-clappr>

    <app-image-viewer
        *ngIf="supportedFormat === 'Image'"
        [downloadUrl]="item?.downloadURL"
        [isDataProtection]="isDataProtection"
        [meetingImageTranscodeUrl]="item?.imageTrancodedURL"
        [fileName]="item?.fileName"
        [jiomeetId]="jiomeetId"
        (close)="closePreview($event)"
    >
    </app-image-viewer>

    <app-doc-viewer
        *ngIf="supportedFormat === 'Doc'"
        [downloadUrl]="item?.downloadURL"
        [isDataProtection]="isDataProtection"
        [meetingDocumentUrl]="item?.documentURL"
        [fileName]="item?.fileName"
        [jiomeetId]="jiomeetId"
        (close)="closePreview($event)"
    >
    </app-doc-viewer>
</div>
