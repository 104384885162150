import { AuthGuard } from './auth.guard';
import { AuthGuardChat } from './auth.guard.chat';
import { AuthGuardContacts } from './auth.guard.contacts';
import { CanCallGuard } from './can-call.guard';
import { CheckForServerDownGuard } from './check-for-server-down.guard';
import { CheckForWebinarGuard } from './check-for-webinar.guard';
import { CheckWebrtcEnabledGuard } from './check-webrtc-enabled.guard';

export * from './auth.guard';
export * from './auth.guard.chat';
export * from './auth.guard.contacts';
export * from './check-webrtc-enabled.guard';
export * from './check-for-server-down.guard';
export * from './can-call.guard';
export * from './check-for-webinar.guard';

export const guards = [
    AuthGuard,
    CheckWebrtcEnabledGuard,
    CheckForServerDownGuard,
    CanCallGuard,
    CheckForWebinarGuard,
    AuthGuardChat,
    AuthGuardContacts
];
