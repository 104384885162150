import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EventData, EventEmitterService, UtilService, GoogleTagManagerService } from 'src/app/core';
import * as Flickity from 'flickity';

import { WEBSITE_EVENTS_FEATURES } from '../websiteConstant';
import { APP_EVENTS } from 'src/app/constants';
import { GA_PAGEVIEW } from '../ga-pageview';
import { GA_EVENTS } from '../ga-events';

@Component({
    selector: 'app-website-events',
    templateUrl: './website-events.component.html',
    styleUrls: ['./website-events.component.scss']
})
export class WebsiteEventsComponent implements OnInit, AfterViewInit {
    isMobile = false;
    websiteEventFeatures = WEBSITE_EVENTS_FEATURES;
    eventStats = [
        { label: 'Book an event', tlabel: 'website_events.book_event' },
        { label: 'Attend event', tlabel: 'website_events.attend_event' },
        { label: 'View Recordings', tlabel: 'website_events.view_recording' },
        { label: 'Portal for webinar', tlabel: 'website_events.webinar_portal' },
        { label: 'Basic statistics of webinar', tlabel: 'website_events.statistics_webinar' }
    ];
    activePlatformIndex = 0;

    @ViewChild('productSlider', { static: true })
    productSlider: ElementRef;

    constructor(
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private router: Router,
        private gTagService: GoogleTagManagerService
    ) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser(1080);
        this.translateEventSolutions();
        this.router.navigate(['/home']);
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 1080 ? false : true;
            }
        });
        this.gTagService.pushOnDataLayer(GA_PAGEVIEW.PRODUCT_PAGE_VIEW_DISCOVER);
    }

    ngAfterViewInit() {
        if (this.isMobile) {
            this.initProductSlider();
        }
    }

    initProductSlider() {
        this.productSlider = new Flickity(this.productSlider.nativeElement, {
            prevNextButtons: false,
            autoPlay: false,
            contain: false,
            cellAlign: 0,
            pageDots: true,
            selectedAttraction: 0.2,
            friction: 0.8,
            index: 0,
            resize: true
        });
    }

    async translateEventSolutions() {
        const websiteEventFeatures = await this.utilService.handleDataTranslation('website', {
            eventFeatures: this.websiteEventFeatures
        });
        this.websiteEventFeatures = websiteEventFeatures['eventFeatures'];
    }

    goToContactSales() {
        this.gTagService.sendEvent(GA_EVENTS.PRODUCT_DISCOVER_CONTACT_SALES);
        this.router.navigate(['contactsales']);
    }
}
