import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService, GuestAuthService } from 'src/app/core';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import videojs from 'video.js';
import 'videojs-seek-buttons';

@Component({
    selector: 'app-videojs-player',
    templateUrl: './videojs-player.component.html',
    styleUrls: ['./videojs-player.component.scss']
})
export class VideojsPlayerComponent implements OnInit, OnDestroy {
    @ViewChild('target', { static: true }) target: ElementRef;
    @Input() options: any;
    @Input() setupDone: Subject<string>;
    player: videojs.Player;
    isSourceAvailable: boolean = false;

    @Output() replayed = new EventEmitter();
    @Output() played = new EventEmitter();
    @Output() errorOccurred = new EventEmitter();

    constructor(
        private elementRef: ElementRef,
        private authService: AuthService,
        private guestAuthService: GuestAuthService,
        private loggerService: AppLoggerService
    ) {
        this.options = {
            autoplay: false,
            controls: true,
            response: true,
            muted: false,
            preload: 'auto',
            overrideNative: true,
            playbackRates: [1, 1.25, 1.5, 1.75, 2.0],
            controlBar: {
                liveDisplay: false,
                seekToLive: false,
                pictureInPictureToggle: false,
                currentTimeDisplay: true,
                progressControl: {
                    seekBar: {
                        loadProgressBar: true,
                        mouseTimeDisplay: true,
                        playProgressBar: true
                    }
                }
            }
        };
    }

    ngOnInit(): void {
        this.setupDone.subscribe((url) => {
            if (url) {
                this.isSourceAvailable = true;
                const authToken = this.getInputToken();
                if (authToken) {
                    url = `${url}&tempAuth=${authToken}`;
                }
                this.options.sources = [{ src: url, type: 'video/mp4' }];
                this.setupPlayer();
            }
        });
    }

    setupPlayer() {
        const videoPlayer = document.getElementById('my-player');

        // this does not work in case of type: 'video/mp4'
        // videojs.Vhs.xhr.beforeRequest = this.setupTokenForDecrypt.bind(this);
        this.player = videojs(videoPlayer, this.options, function onPlayerReady() {
            this.seekButtons({
                forward: 10,
                back: 10
            });
            this.play();
        });

        this.player.on('error', () => {
            this.loggerService.log(this.player.error(), 'video js player error');
        });
    }

    ngOnDestroy(): void {
        if (this.player) {
            this.player.dispose();
        }
    }

    setSource(url) {
        this.player.src({
            src: url,
            type: 'video/mp4' /*video type*/
        });
    }

    setupTokenForDecrypt(options) {
        options.headers = options.headers || {};
        options.headers.Authorization = 'Bearer=' + this.getInputToken();

        return options;
    }

    getInputToken() {
        if (this.authService.getAuthInfo()) {
            return this.authService.getAuthInfo().jwt;
        }

        if (this.guestAuthService.getAuthInfo()) {
            return this.guestAuthService.getAuthInfo().jwt;
        }

        return null;
    }
}
