<div class="join-meeting-component">
    <div id="content" class="container">
        <div class="layout">
            <div class="d-flex justify-content-center">
                <div class="w-100">
                    <section>
                        <h2 class="text-center">Join Meeting on TV</h2>
                        <h5 class="text-center my-3">To join meeting on TV, enter the details below.</h5>
                        <form [formGroup]="joinMeetingForm" (submit)="submit()">
                            <div class="form-group">
                                <input
                                    type="text"
                                    class="form-control text-center"
                                    id="tvcode"
                                    formControlName="meetingHash"
                                    placeholder="TV Code"
                                    maxlength="4"
                                />
                                <span class="invalid-error" *ngIf="errors?.meetingHash">{{ errors.meetingHash }}</span>
                            </div>

                            <div class="form-group">
                                <input
                                    type="text"
                                    class="form-control text-center"
                                    id="name"
                                    formControlName="memberName"
                                    placeholder="Name"
                                    maxlength="25"
                                />
                                <span class="invalid-error" *ngIf="errors?.memberName">{{ errors.memberName }}</span>
                            </div>

                            <div class="form-group">
                                <input
                                    type="text"
                                    class="form-control text-center"
                                    id="extension"
                                    formControlName="extension"
                                    placeholder="Meeting ID"
                                    maxlength="100"
                                />
                                <span class="invalid-error">{{ errors.extension }}</span>
                            </div>

                            <div class="form-group">
                                <input
                                    type="text"
                                    class="form-control text-center"
                                    id="pin"
                                    formControlName="pin"
                                    aria-describedby="emailHelp"
                                    placeholder="Meeting Password"
                                    maxlength="5"
                                />
                                <span class="invalid-error" *ngIf="errors?.pin">{{ errors.pin }}</span>
                            </div>
                            <span class="invalid-error" *ngIf="errors.global">
                                {{ errors.global }}
                            </span>
                            <div class="col p-0 w-100 mt-3">
                                <button
                                    type="submit"
                                    class="btn primary"
                                    [class.disabled]="!joinMeetingForm.valid || working"
                                >
                                    Join
                                </button>
                            </div>
                        </form>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>
