import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GaDiagnosticService } from 'src/app/core/services/ga-diagnostic.service';
import { AppService } from 'src/app/core';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
    showStbChanges = false;
    isLangHindi = false;

    constructor(
        private gaService: GaDiagnosticService,
        private route: ActivatedRoute,
        private appService: AppService
    ) {}

    ngOnInit() {
        // const ele = document.getElementsByClassName('app-container')[0];
        // if (ele) {
        //   ele.scrollTo(0, 0);
        // }

        this.route.queryParams.subscribe((params) => {
            if (params['isstb']) {
                this.showStbChanges = true;
            }
            this.isLangHindi = params['lan'] === 'hi' ? true : false;
        });

        if (this.appService.getAndSaveUserPreferredLang() == 'hi') {
            this.isLangHindi = true;
        }

        this.gaService.sendPageView({
            page_title: 'privacy_page',
            page_path: '/privacy-policy-business'
        });
    }
}
