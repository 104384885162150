import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {
    AppService,
    AuthService,
    EventData,
    EventEmitterService,
    MeetingService,
    UserService,
    UtilService
} from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { isToday, parseISO } from 'date-fns';
import { ConvertToLocaleDatePipe } from 'src/app/shared';
import { APP_EVENTS } from 'src/app/constants';

@Component({
    selector: 'app-recording-player',
    templateUrl: './recording-player.component.html',
    styleUrls: ['./recording-player.component.scss'],
    providers: [ConvertToLocaleDatePipe]
})
export class RecordingPlayerComponent implements OnInit {
    @ViewChild('url_text') url_text: ElementRef;
    updateUrl: BehaviorSubject<string> = new BehaviorSubject('');
    public urlUpdated = false;
    link: string;
    scriptsLoaded: boolean = false;
    isMobileOS: boolean = false;
    isRecordingExpired: boolean = false;
    currentUser: any;
    milliSecondsInMin = 60000;
    minsInHour = 60;
    showRecordingTimeBanner: boolean = false;

    scripts: any = [
        { src: 'https://vjs.zencdn.net/7.3.0/video-js.css', loaded: false },
        { src: 'https://cdn.jsdelivr.net/npm/videojs-seek-buttons/dist/videojs-seek-buttons.css', loaded: false }
    ];

    recordingDetails: any = {};
    recordingDetailsAvailable: boolean = false;
    currentDay = new Date().getDay();
    isCpass: boolean = false;
    constructor(
        private activatedRoute: ActivatedRoute,
        private appService: AppService,
        private meetingService: MeetingService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private loggerService: AppLoggerService,
        private router: Router,
        private utilService: UtilService,
        private convertToLocaleDatePipe: ConvertToLocaleDatePipe,
        private userService: UserService,
        private authService: AuthService,
        private eventEmitterService: EventEmitterService
    ) {}

    ngOnInit(): void {
        if (!this.authService.getIsAuthenticated()) {
            setTimeout(() => {
                this.toastrService.error(this.translateService.instant('tostrs.signin_to_continue'));
                this.router.navigate(['/login']);
            }, 100);
            return;
        }
        this.currentUser = this.userService.getUserSync();
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.GET_UPDATED_USER_DATA) {
                this.currentUser = this.userService.getUserSync();
            }
        });
        this.handleScriptLoading();
        this.isMobileOS = this.utilService.isMobileBrowser();
        this.activatedRoute.queryParams.subscribe((params) => {
            setTimeout(() => {
                this.meetingService.getRecordingDetailsByHash(params.hash).subscribe(
                    (details: any) => {
                        this.recordingDetails = details;
                        this.recordingDetailsAvailable = true;
                        this.showRecordingTimeBanner = !!this.recordingDetails?.isExpiryApplicable;
                        this.meetingService.getVideoStream(params.hash).subscribe(
                            (res) => {
                                this.link = res?.uri;
                                this.urlUpdated = true;
                                this.updateUrl.next(this.link);
                                if (res?.success) {
                                    this.isCpass = res?.isCpassRoom;
                                } else {
                                    // redirect
                                    const redirectUrl = `${this.appService.getEnvVariable(
                                        'HOST_URL'
                                    )}authenticateurl?redirectURL=${encodeURIComponent(window.location.href)}`;
                                    window.location.href = redirectUrl;
                                    return;
                                }
                            },
                            (err) => {
                                if (err?.error?.customCode == 400) {
                                    if (err?.error?.message !== 'REC_EXPIRED') {
                                        this.toastrService.info(err.error.errors);
                                        this.router.navigate(['/login'], { relativeTo: this.activatedRoute });
                                        return;
                                    } else if (err?.error?.message === 'REC_EXPIRED') {
                                        this.isRecordingExpired = true;
                                    }
                                } else if (err?.error?.customCode == 401 && err?.error?.message === 'UNAUTHORIZED') {
                                    return this.toastrService.error(
                                        this.translateService.instant(
                                            'tostrs.insufficient_permissions_to_access_recordings'
                                        )
                                    );
                                } else {
                                    return this.toastrService.error(
                                        this.translateService.instant('tostrs.something_went_wrong_please_try_again')
                                    );
                                }
                            }
                        );
                    },
                    (hashError) => {
                        console.error('Has URL failed:', hashError);
                        if (
                            hashError?.error?.customCode == 400 &&
                            (hashError?.error?.message === 'RECORDINGERROR' ||
                                hashError?.error?.message === 'BADREQUEST')
                        ) {
                            this.isRecordingExpired = true;
                            this.toastrService.info(hashError.error.errors);
                            this.router.navigate(['/login'], { relativeTo: this.activatedRoute });
                        }
                    }
                );
            }, 500);
        });
    }

    hoursRemainingInExpiry(time, currentUserId) {
        if (time) {
            const dateString = time;
            const expiryTime = new Date(dateString).getTime() / this.milliSecondsInMin;
            const currentTime = new Date().getTime() / this.milliSecondsInMin;
            const timeDifferenceMinutes = expiryTime - currentTime;
            const timeDifferenceHours = timeDifferenceMinutes / this.minsInHour;
            if (timeDifferenceMinutes < 0) {
                this.isRecordingExpired = true;
                return this.currentUser._id == currentUserId
                    ? `${this.translateService.instant(
                          'aftersignin_or_introduction.previousmeeting.recording_link_expired_update'
                      )} `
                    : `${this.translateService.instant(
                          'aftersignin_or_introduction.previousmeeting.recording_link_expired_video'
                      )} `;
            } else if (timeDifferenceHours > 1) {
                this.isRecordingExpired = false;
                return this.currentUser._id == currentUserId
                    ? `${this.translateService.instant(
                          'aftersignin_or_introduction.previousmeeting.recording_link_expires_in_hrs',
                          {
                              value: Math.floor(timeDifferenceHours)
                          }
                      )} `
                    : `${this.translateService.instant(
                          'aftersignin_or_introduction.previousmeeting.recording_link_expires_video_hrs',
                          {
                              value: Math.floor(timeDifferenceHours)
                          }
                      )} `;
            } else {
                this.isRecordingExpired = false;
                return this.currentUser._id == currentUserId
                    ? `${this.translateService.instant(
                          'aftersignin_or_introduction.previousmeeting.recording_link_expires_in_mins',
                          {
                              value:
                                  timeDifferenceMinutes > 59
                                      ? Math.floor(timeDifferenceMinutes)
                                      : Math.ceil(timeDifferenceMinutes)
                          }
                      )} `
                    : `${this.translateService.instant(
                          'aftersignin_or_introduction.previousmeeting.recording_link_expires_video_mins',
                          {
                              value:
                                  timeDifferenceMinutes > 59
                                      ? Math.floor(timeDifferenceMinutes)
                                      : Math.ceil(timeDifferenceMinutes)
                          }
                      )} `;
            }
        }
    }

    handleScriptLoading() {
        this.scripts.forEach((item: any) => {
            this.loadScripts(item);
        });
    }

    openPricingPage() {
        const buyURL = `${this.appService.getEnvVariable('HOST_URL')}/pricing`;
        this.openURLInNewTab(buyURL);
    }

    openURLInNewTab(url) {
        const win = window.open(url, '_blank');
        win.focus();
    }

    loadScripts(s) {
        if (this.scriptsLoaded) {
            return;
        }

        const script = document.createElement('link');
        script.rel = 'stylesheet';
        script.href = s?.src;
        script.crossOrigin = 'anonymous';
        script.onload = () => {
            this.scripts[this.scripts.indexOf(s)].loaded = true;
            if (this.scripts.every((s) => s.loaded)) {
                this.scriptsLoaded = true;
            }
        };
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    downloadRecording(url) {
        this.userService.getFinalTempAuthUrl({ finalURL: `${url}&ngsw-bypass=true` }).subscribe(
            (res: any) => {
                window.location.href = res.finalURL;
                this.toastrService.success(this.translateService.instant('tostrs.downloading_in_progress'));
            },
            (err) => {
                this.loggerService.log(err?.error, 'error while fetching recording stream url');
                if (err?.error?.message === 'URL_EXPIRED') {
                    this.toastrService.error(err.error.errors);
                } else {
                    this.toastrService.info(
                        this.translateService.instant('tostrs.something_went_wrong_please_try_again')
                    );
                }
            }
        );
    }

    copyRecordingLink(url) {
        navigator.clipboard.writeText(url);
        this.toastrService.success(this.translateService.instant('tostrs.recording_link_copied'));
        if (this.url_text && this.url_text.nativeElement) {
            this.url_text.nativeElement.textContent = this.translateService.instant('inside_call.call_info.copied');
        }

        setTimeout(() => {
            if (this.url_text && this.url_text.nativeElement) {
                this.url_text.nativeElement.textContent = this.translateService.instant('invitepopup.copy_url');
            }
        }, 3000);
    }

    formatAMPM(date) {
        return this.utilService.formatAMPM(date);
    }

    getFormattedDate(date, showToday) {
        let recordedDate = new Date(date);
        return showToday && isToday(parseISO(date))
            ? `${this.translateService.instant(
                  `aftersignin_or_introduction.upcomingmeeting.dayNames.${this.currentDay}`
              )}, ${this.convertToLocaleDatePipe.transform(date, 'd MMM y')}`
            : `${this.translateService.instant(
                  `aftersignin_or_introduction.upcomingmeeting.dayNames.${recordedDate.getDay()}`
              )}, ${this.convertToLocaleDatePipe.transform(date, 'd MMM y')}`;
    }
}
