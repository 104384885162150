export const MAIL_TEMPLATES = {
    INVITATION: ({
        userName,
        topic,
        startTime,
        duration,
        meetingURL,
        meetingId,
        password,
        addToJmtUrl,
        legacyConnect,
        hostUrl,
        vpin,
        participants
    }) => {
        let isIntranetIpHidden = legacyConnect?.settings?.intranetIp?.hide || null;
        let isInternetIpHidden = legacyConnect?.settings?.internetIp?.hide || null;
        let isIntranetIpValuePresent = legacyConnect?.settings?.intranetIp || null;
        let isDnsAddressHidden = legacyConnect?.settings?.dnsAddress?.hide || null;

        let invite = `
        ${userName} has invited you to the below meeting.

        Meeting Details:
        Topic : ${topic}`;
        if (startTime) {
            invite += `
        Date & Time : ${startTime}`;
        }

        if (duration) {
            invite += `
        ${duration ? 'Duration : ' + duration + ' mins' : ''}
            `;
        }

        if (!startTime && !duration) {
            invite += `
        `;
        }

        if (participants) {
            invite += `${'Number of participants invited: ' + participants}`;
        }

        invite += `

        More ways to join the meeting

        •Join using meeting link:
        ${meetingURL}

        • Join using MeetingID:
        MeetingID : ${meetingId}
        Password : ${password}
        Join using desktop, mobile application or through web and use above details`;

        if (legacyConnect && !(isIntranetIpHidden && isInternetIpHidden && isDnsAddressHidden)) {
            invite += `

            •Join from video system or SIP device:`;

            if (!isInternetIpHidden || !isIntranetIpHidden) {
                invite += `
                Dial  `;
                if (!isInternetIpHidden) {
                    invite += `${legacyConnect?.settings?.internetIp?.value} (Internet)  `;
                }
                if (!isInternetIpHidden && !isIntranetIpHidden) {
                    invite += `
                    `;
                }
                if (!isIntranetIpHidden && isIntranetIpValuePresent) {
                    invite += `${legacyConnect?.settings?.intranetIp?.value} (Intranet)`;
                }
            }

            if (!isInternetIpHidden || !isIntranetIpHidden) {
                invite += `
                and enter meeting details:
                Meeting ID: ${meetingId}
                Meeting PassCode: ${vpin || ''}
                `;
            }

            if (!isDnsAddressHidden) {
                invite += `or
                you can also dial ${legacyConnect?.SIP?.url}`;
            }
        }

        if (addToJmtUrl) {
            invite += `

            Add to JioMeet Calendar:
            ${addToJmtUrl}`;
        }

        if (startTime != null && meetingId && password) {
            invite += `

            Download .ics file:
            ${hostUrl}downloadICSFile?jiomeetId=${meetingId}&roomPin=${password}`;
        }

        invite += `

            Need help? Go to ${hostUrl}webhelp

            Regards,
            Team JioMeet`;

        return invite;
    }
};
