import {
    AfterViewInit,
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
    NgZone,
    ComponentRef,
    ComponentFactoryResolver
} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { debounceTime, distinctUntilChanged, map, tap, filter, mergeMap } from 'rxjs/operators';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { from, fromEvent, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash-es';

import {
    UserService,
    AuthService,
    EventEmitterService,
    EventData,
    AppService,
    UtilService,
    GaDiagnosticService,
    DiagnosticService,
    SearchService,
    ChatService,
    SocketService,
    SocketEvent,
    GoogleTagManagerService,
    LocalStorageService,
    RtcService,
    VideoWrapperService,
    SystemService,
    RoomConnectionService,
    CallService,
    DesktopAppService,
    DesktopAppUpdaterService,
    DownloadAppService
} from 'src/app/core';
import { APP_EVENTS } from '../../../constants/app-events';
import { MEGA_MENUES } from '../websiteConstant';
import { GA_EVENTS } from '../ga-events';
import { FormBuilder } from '@angular/forms';
import { APP_CONSTANT, SOCKET_EVENTS, VIDYO_EVENTS, KEYBOARD_CONSTANT } from 'src/app/constants';
import { ProfilePhotoService } from 'src/app/core/services/profile-photo.service';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { CallSettingsComponent, HostDirective } from 'src/app/shared';
import { LocalHelperServiceService } from 'src/app/contacts/local-helper-service.service';
import { GA_NEW } from 'src/app/shared/ga-new';
import { rejects } from 'assert';
import { GA4_EVENTS } from '../ga4-events';
import { Button } from 'primeng/button';

@Component({
    selector: 'app-website-header',
    templateUrl: './website-header.component.html',
    styleUrls: ['./website-header.component.scss'],
    providers: [ConfirmationService]
})
export class WebsiteHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
    currentUser;
    autoJoin = false;
    loading = false;
    profilePicPath;
    isAuthenticated = false;
    headerNavActiveStatuss = {
        help: false,
        signIn: true,
        joinAMeeting: false,
        HostAMeeting: false,
        scheduleMeeting: false,
        signUp: false
    };
    showMenu;
    highlightSignUp = false;
    isDefaultPic: boolean;
    isMobile;
    blogUrl = null;

    megaMenuData;
    mobileMegaMenuData: any = [];
    activeHover = false;
    activeHoverMenu = null;
    activeTheme: string = 'themeLight';
    showHomeMegaMenues = true;
    hoverTimeOutRef = null;
    leaveTimeOutRef = null;
    enableWidth_1366 = false;
    headerRef: any;
    currentScrollTop = 0;
    topHeaderVisibility = true;
    prevScrollTop = 0;
    activeLang: string;

    showMeetingForm = false;
    showPlanPopup: boolean = false;
    isChatEnabledForWeb = false;
    isGlobalSearchEnabled = false;
    @ViewChild('searchInput', { static: false }) input: ElementRef<HTMLInputElement>;
    createGroupForm: any;
    searchStr = '';
    listOfUsers = { contacts: [], contacts_ex: [], groups: [], users: [], users_un: [], vcs: [], allUsers: [] };
    noUsersFound = false;
    noLocalUsersFound = true;
    suggestionsCount = 0;
    selectedUsers = [];
    showUsersPanel = false;
    isGlobalSearchCalled = 0;
    mstIntegration = false;
    whatsAppBotURL = this.utilService.isMobileBrowser()
        ? this.appService.getConfigVariable('whatsappBotUrlMobile')
        : this.appService.getConfigVariable('whatsappBotUrlWeb');
    private subscriptions: Subscription[] = [];
    private subscription: Subscription;
    private recentThreadsSubscription: Subscription;
    participantInitials: any;
    colorChosen;
    isMattermostEnabled: any = false;
    dashboardFloater = false;
    offerBanner = false;
    bannersConfigData;
    isContactsEnabledForWeb = false;

    enableCallLoader = false;
    contactToCall;
    event;
    type;
    previousUrl: any;
    currentUrl: any;
    showProBadge: boolean = false;
    showProUserBadge: boolean = false;
    showSkipcontentbtn = false;
    hideSkipcontainer = true;
    nextFocusElement;

    mainTabAccessibilityLabel = 'Navigate to main page';
    homeTabAccessibilityLabel = 'Home page selected';
    chatTabAccessibilityLabel = 'Navigate to chat page';
    meetingTabAccessibilityLabel = 'Navigate to meeting page';
    contactTabAccessibilityLabel = 'Navigate to contact page';

    skipButtonText: string = 'main content';
    subscriptionHoursLeft = 0;
    daysInPercenatge = 0;
    statusImage;
    Math = Math;
    isMacElectronApp = false;
    showElectronUpdatePopup: Boolean = false;
    showProfileMenu = false;
    isDesktopApp = false;
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private appService: AppService,
        private authService: AuthService,
        public userService: UserService,
        private confirmationService: ConfirmationService,
        private eventEmitterService: EventEmitterService,
        private diagnosticService: DiagnosticService,
        private utilService: UtilService,
        private translate: TranslateService,
        private toastrService: ToastrService,
        private gaService: GaDiagnosticService,
        private config: PrimeNGConfig,
        private fb: FormBuilder,
        private searchService: SearchService,
        private chatService: ChatService,
        private changeDetector: ChangeDetectorRef,
        private socketService: SocketService,
        private zone: NgZone,
        public profilePhotoService: ProfilePhotoService,
        private localStorageService: LocalStorageService,
        private appLoggerService: AppLoggerService,
        private gTagService: GoogleTagManagerService,
        private videoWrapperService: VideoWrapperService,
        private localHelperService: LocalHelperServiceService,
        private elementRef: ElementRef,
        private desktopAppService: DesktopAppService,
        public desktopAppUpdaterService: DesktopAppUpdaterService,
        private downloadAppService: DownloadAppService,
        private rtcService: RtcService
    ) {}

    async ngOnInit() {
        this.isDesktopApp = this.desktopAppService.isDesktopApp;
        window.addEventListener('keydown', this.showSkipContainer.bind(this));
        let participants = new Map();
        this.createGroupForm = this.fb.group({
            participants: [participants]
        });
        this.currentUrl = this.router.url;
        this.isMattermostEnabled = this.utilService.isMattermost();
        this.isChatEnabledForWeb = this.appService.getConfigVariable('ENABLE_WEBSITE_CHAT');

        this.isContactsEnabledForWeb = this.appService.getConfigVariable('ENABLE_CONTACTS_SECTION');
        this.isGlobalSearchEnabled = this.appService.getConfigVariable('ENABLE_GLOBAL_SEARCH');
        if (this.isGlobalSearchEnabled) {
            this.userService.localSearches = this.localStorageService.getItem('globalSearches');
        }
        this.bannersConfigData = this.appService.getConfigVariable('BANNERS_CONFIG');
        this.blogUrl = this.appService.getConfigVariable('BLOG_URL');
        this.isMobile = this.utilService.isMobileBrowser(1080);
        this.activeLang = this.appService.getAndSaveUserPreferredLang();
        this.translate.onLangChange.subscribe(() => (this.activeLang = this.appService.getAndSaveUserPreferredLang()));
        this.highlightSignUp = this.router.url === '/home';
        this.headerNavActiveStatuss.signIn = this.router.url !== '/login';
        this.headerNavActiveStatuss.signUp = this.router.url === '/login';
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.clearInput();
            this.showUsersPanel = false;
            this.selectedUsers = [];
            if (!this.localStorageService.getItem('globalSearches')) {
                this.userService.localSearches = [];
            }
            this.dashboardFloater = false;
            this.offerBanner = false;
            this.highlightSignUp = event.url === '/home';
            this.emitUpdateCheck();
            if (event.url != '/home') {
                this.userService.setProBannerVisibility(false);
            }
            if (
                event.url.substring(0, 9) !== '/meetings' &&
                event.url.substring(0, 13) !== '/addtojiomeet' &&
                event.url.substring(0, 5) !== '/chat' &&
                event.url.substring(0, 9) !== '/contacts'
            ) {
                this.isGlobalSearchCalled = 0;
                if (!Object.keys(this.currentUser).length && this.isAuthenticated) {
                    this.userService.getUser().subscribe((res) => {
                        this.currentUser = this.userService.getUserSync();
                        if (this.currentUser._id) {
                            this.participantInitials = this.profilePhotoService?.getAvatarInitials(
                                this.currentUser?.name + ' ' + this.currentUser?.lname
                            );
                            this.setOwnImageProfilePath();
                            this.colorChosen = this.profilePhotoService.getColorForInitials(
                                this.currentUser?.name + ' ' + this.currentUser?.lname
                            );
                        }
                    });
                }
            }

            this.showBanner(event.url);
            if (this.input) {
                this.input.nativeElement.value = '';
            }
        });

        this.showBanner(this.router.url);

        this.currentUser = this.userService.getUserSync();
        if (this.currentUser._id) {
            this.participantInitials = this.profilePhotoService?.getAvatarInitials(
                this.currentUser?.name + ' ' + this.currentUser?.lname
            );
            this.setOwnImageProfilePath();
            this.colorChosen = this.profilePhotoService.getColorForInitials(
                this.currentUser?.name + ' ' + this.currentUser?.lname
            );
        }
        this.renderplanBadgeUI();
        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
            this.showProBadge = this.currentUser?.proStatus || !this.currentUser?.isOTT;
        });

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.headerNavActiveStatuss.help = event.url === '/webhelp';
            this.headerNavActiveStatuss.joinAMeeting = event.url === '/join';
            this.headerNavActiveStatuss.signUp = event.url === '/login';
            this.headerNavActiveStatuss.signIn = event.url !== '/login';
            this.headerNavActiveStatuss.scheduleMeeting = event.url === '/schedule';
            this.handleMaxScreenWidth();
            this.handleShowMegaMenues(event.url);
            this.showMenu = false;
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
            if (this.previousUrl != this.currentUrl) {
                this.hideSkipcontainer = true;
            }
        });

        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.NATIVE_BACK_BUTTON) {
                if (this.previousUrl) {
                    this.router.navigate([this.previousUrl]);
                } else {
                    this.router.navigate(['/home']);
                }
            }
            if (event.type === APP_EVENTS.UPDATE_PROFILE || event.type === APP_EVENTS.NAME_CHANGED) {
                this.currentUser = this.userService.getUserSync();
                this.setOwnImageProfilePath();
                this.currentUser = this.userService.getUserSync();
                this.isDefaultPic = !this.currentUser?.imageMeta && !this.currentUser?.imageMeta?.path;
                this.renderplanBadgeUI();
            } else if (event.type === APP_EVENTS.NAV_ITEM_CLICKED) {
                if (!this.isMobile) this.handleMouseLeave();
                else this.toggleMenu();
            } else if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 1080 ? false : true;
                if (this.isMobile) {
                    this.isGlobalSearchCalled = 0;
                }
                this.resetNavBarHeight();
            } else if (
                event?.type === KEYBOARD_CONSTANT.KEYBOARD_SHORTCUTS &&
                event?.data === KEYBOARD_CONSTANT.CLOSE_WINDOW
            ) {
                this.confirmationService.close();
            } else if (event.type === APP_EVENTS.PROFILE_HIDE_EVENT && event?.data === 'profile') {
                this.clickOutsideProfileIcon();
            }
        });

        this.mstIntegration = this.activatedRoute.snapshot.queryParams.bot === 'true' ? true : false;

        // subscribe to window resize
        if (this.utilService.inIframe()) {
            this.autoJoin = this.activatedRoute.snapshot.queryParams.autoJoin === 'true' ? true : false;
        }

        this.handleMaxScreenWidth();
        this.handleShowMegaMenues();
        this.subscriptions.push(this.socketService.dataEvents$.subscribe(this.handleChatEvents.bind(this)));
        this.selfStatusListener();
        this.demandSelfUserStatus();
        this.settingsViewSubcribtionForMobile();
        this.setSelectedLocalRingTone();
        this.isMacElectronApp = this.desktopAppService.isDesktopApp && this.desktopAppService.ismacOSDesktop();
    }

    emitUpdateCheck() {
        this.eventEmitterService.emit({
            type: APP_EVENTS.UPDATE_CHECK_EVENT,
            data: null
        });
    }

    setSelectedLocalRingTone() {
        const selectedAudioItem = this.localStorageService.getItem('selectedAudioItem');
        if (selectedAudioItem != null) {
            this.utilService.selectedIncomingaudio = selectedAudioItem;
        }
    }

    setOwnImageProfilePath() {
        this.profilePicPath = this.userService.getSelfProfilePicPath(this.currentUser);
    }

    async renderplanBadgeUI() {
        if (this.currentUser.isOTT && this.currentUser.proStatus) {
            let currentDate: any = new Date();
            await this.userService.getServerCurrentTime().subscribe((currTime) => {
                currentDate = new Date(currTime[0]);
            });
            const expiresOnDate: any = new Date(this.currentUser.subscriptionInfo.expiresOn);

            // Calculate the remaining time in milliseconds
            const remainingTime = expiresOnDate - currentDate;

            // Calculate the remaining hours
            const hoursRemaining = Math.floor(remainingTime / 3600000);

            if (hoursRemaining <= 0) {
                this.subscriptionHoursLeft = 0;
                this.daysInPercenatge = 0;
            } else {
                this.subscriptionHoursLeft = hoursRemaining;
                const totalHours = 24 * 365;
                const percentageRemaining = (hoursRemaining / totalHours) * 100;
                this.daysInPercenatge = percentageRemaining;
            }
        }
    }

    showBanner(url) {
        if (url === '/main') {
            this.offerBanner = true;
        }
    }

    enableChatForAbove1080() {
        return (
            !!this.isChatEnabledForWeb &&
            (this.router.url.substring(0, 5) === '/chat' ||
                this.router.url.substring(0, 9) === '/meetings' ||
                this.router.url.substring(0, 13) === '/addtojiomeet' ||
                this.router.url.substring(0, 9) === '/contacts' ||
                this.router.url.substring(0, 5) === '/home' ||
                this.router.url.substring(0, 5) === '/join' ||
                this.router.url.substring(0, 5) === '/host' ||
                this.router.url.substring(0, 15) === '/precalltesting') &&
            this.isAuthenticated &&
            window.innerWidth >= 1080 &&
            !this.enableWidth_1366
        );
    }

    enableContactsForAbove1080() {
        return (
            !!this.isContactsEnabledForWeb &&
            (this.router.url.substring(0, 9) === '/contacts' ||
                this.router.url.substring(0, 9) === '/meetings' ||
                this.router.url.substring(0, 13) === '/addtojiomeet' ||
                this.router.url.substring(0, 5) === '/chat' ||
                this.router.url.substring(0, 5) === '/home' ||
                this.router.url.substring(0, 5) === '/join' ||
                this.router.url.substring(0, 5) === '/host' ||
                this.router.url.substring(0, 15) === '/precalltesting') &&
            this.isAuthenticated &&
            window.innerWidth >= 1080 &&
            !this.enableWidth_1366
        );
    }

    showGlobalChatAbove1080() {
        const result =
            !!this.isChatEnabledForWeb &&
            (this.router.url.substring(0, 5) === '/chat' ||
                this.router.url.substring(0, 9) === '/meetings' ||
                this.router.url.substring(0, 13) === '/addtojiomeet' ||
                this.router.url.substring(0, 9) === '/contacts' ||
                this.router.url.substring(0, 5) === '/home' ||
                this.router.url.substring(0, 5) === '/join' ||
                this.router.url.substring(0, 5) === '/host' ||
                this.router.url.substring(0, 15) === '/precalltesting') &&
            this.isAuthenticated &&
            window.innerWidth >= 1080 &&
            !this.enableWidth_1366;
        if (result) {
            this.isGlobalSearchCalled++;
        }
        if (this.isGlobalSearchCalled === 1 && result) {
            this.changeDetector.detectChanges();
            this.initGlobalSearch();
            this.fetchUnreadCount();
        }
        return result;
    }

    showSkipContainer(event) {
        if (this.router.url === '/main' || this.router.url === '/home') {
            if (!this.hideSkipcontainer) {
                this.showSkipcontentbtn = false;
                return;
            }
            if (event.key === 'Tab') {
                this.handleMainAndHomeFocus(event);
            }
        }

        if (this.router.url === '/login') {
            if (!this.hideSkipcontainer) {
                this.showSkipcontentbtn = false;
                return;
            }
            if (event.key === 'Tab') {
                this.handleLoginFocus(event);
            }
        }

        if (this.router.url === '/signup') {
            if (!this.hideSkipcontainer) {
                this.showSkipcontentbtn = false;
                return;
            }
            if (event.key === 'Tab') {
                this.handleSignupFocus(event);
            }
        }

        if (this.router.url === '/shortener' || this.router.url === '/join') {
            if (!this.hideSkipcontainer) {
                this.showSkipcontentbtn = false;
                return;
            }
            if (event.key === 'Tab') {
                this.handleShortnerFocus(event);
            }
        }

        if (this.router.url === '/host') {
            if (!this.hideSkipcontainer) {
                this.showSkipcontentbtn = false;
                return;
            }
            if (event.key === 'Tab') {
                this.handleStartMeetingFocus(event);
            }
        }
    }

    setFocusOnMainContent() {
        this.showSkipcontentbtn = true;
        this.setFocusOnElement('skip-main-content-btn');
        this.hideSkipcontainer = false;
    }

    handleMainAndHomeFocus(event) {
        this.skipButtonText = 'main content';
        this.setFocusOnMainContent();
    }

    handleStartMeetingFocus(event) {
        this.skipButtonText = 'start';
        this.setFocusOnMainContent();
    }

    handleLoginFocus(event) {
        let focusElement = document.getElementsByClassName('main-focus')[0] as HTMLInputElement;
        if (focusElement && focusElement?.value?.trim() !== '') {
            event.stopPropagation();
            return;
        }
        this.skipButtonText = 'main content';
        this.setFocusOnMainContent();
    }

    handleSignupFocus(event) {
        const form = document.getElementById('signup-form');
        const inputElements = form.querySelectorAll('input');
        for (let i = 0; i < inputElements.length; i++) {
            if (inputElements[i].value.trim() !== '') {
                return; // Exit the loop as soon as a filled value is found
            }
        }
        this.skipButtonText = 'main content';
        this.setFocusOnMainContent();
    }

    handleShortnerFocus(event) {
        const form = document.getElementById('join-meeting-form');
        const inputElements = form.querySelectorAll('input');
        for (let i = 0; i < inputElements.length; i++) {
            if (inputElements[i].value.trim() !== '') {
                if (this.isAuthenticated && inputElements[i]?.id === 'name') {
                    this.skipButtonText = 'join';
                    this.setFocusOnMainContent();
                    return;
                }
                return;
            }
        }
        this.skipButtonText = 'join';
        this.setFocusOnMainContent();
    }

    handleSkipButtonClick() {
        this.showSkipcontentbtn = false;
        this.setFocusOnElement('main-focus');
    }

    isActiveTab(tab) {
        switch (tab) {
            case 'chat':
                return this.router.url.substring(0, 5) === '/chat';
            case 'dashboard':
                return (
                    this.router.url.substring(0, 10) === '/meetings' ||
                    this.router.url.substring(0, 13) === '/addtojiomeet'
                );
            case 'contactsales':
                return this.router.url === '/contactsales';
            case 'pricing':
                return this.router.url === '/pricing';
            case 'contacts':
                return this.router.url.substring(0, 9) === '/contacts';
            case 'home':
                return this.router.url.substring(0, 5) === '/home';
            default:
                break;
        }
    }

    ngAfterViewInit() {
        this.headerRef = document.getElementById('website-header');
        //  this.pageScrollRef = document.getElementById('app-container');
        this.handleTopHeader();
        this.currentUser = this.userService.getUserSync();
        if (this.enableChatForAbove1080() && !this.subscription) {
            this.initGlobalSearch();
        }
        if (this.enableChatForAbove1080() && !this.recentThreadsSubscription) {
            this.fetchUnreadCount();
        }
    }

    initGlobalSearch() {
        if (this.currentUser) {
            this.subscription = from(['keyup', 'paste'])
                .pipe(mergeMap((event) => fromEvent(this.input?.nativeElement, event)))
                .pipe(
                    debounceTime(700),
                    distinctUntilChanged(),
                    map((event: KeyboardEvent) => (event.target as HTMLInputElement).value),
                    tap((term) => !(term || '').length && this.resetUsers()),
                    filter((term) => (term || '').trim().length > 0),
                    tap(() => {
                        this.loading = true;
                        this.noUsersFound = false;
                    })
                )
                .subscribe(
                    (term) => {
                        this.searchService
                            .globalSearch(term)
                            .pipe(
                                map((res: any) => {
                                    const { users, users_un, contacts, contacts_ex, groups, vcs, ...rest } = res;
                                    let allUsers = [
                                        ...(users?.map((user) => ({ ...user, type: 'user' })) || []),

                                        ...(contacts?.map((user) => ({ ...user, type: 'contact' })) || []),
                                        ...(contacts_ex?.map((user) => ({ ...user, type: 'contact_ex' })) || [])
                                    ]
                                        ?.filter((u) => {
                                            return (
                                                (u?.userId || u?._id) !== this.currentUser?._id &&
                                                (this.userService.localSearches
                                                    ? this.userService?.localSearches.findIndex(
                                                          (lu) => (lu?.userId || lu?._id) === (u?.userId || u?._id)
                                                      ) === -1
                                                    : true)
                                            );
                                        })
                                        ?.map((user) => ({ ...user, fullname: user.name + user.lname }))
                                        ?.sort((a, b) =>
                                            (a.name + ' ' + a.lname).localeCompare(b.name + ' ' + b.lname)
                                        );

                                    let filteredGroups = groups
                                        ?.filter((u) => {
                                            return this.userService.localSearches
                                                ? this.userService?.localSearches.findIndex(
                                                      (lu) => lu?._id === u?._id
                                                  ) === -1
                                                : true;
                                        })
                                        ?.sort((a, b) => a.title.localeCompare(b.title));

                                    let filteredVcs = vcs
                                        ?.filter((u) => {
                                            return this.userService.localSearches
                                                ? this.userService?.localSearches.findIndex(
                                                      (lu) => (lu?.userId || lu?._id) === (u?.userId || u?._id)
                                                  ) === -1
                                                : true;
                                        })
                                        ?.sort((a, b) => a.name.localeCompare(b.name));

                                    return {
                                        ...rest,
                                        allUsers: allUsers,
                                        groups: filteredGroups,
                                        vcs: filteredVcs
                                    };
                                }),
                                tap((res) => {
                                    this.suggestionsCount =
                                        (res?.groups ? res?.groups?.length : 0) +
                                        (res?.allUsers ? res?.allUsers?.length : 0) +
                                        (res?.vcs ? res?.vcs?.length : 0);

                                    this.noLocalUsersFound =
                                        this.userService.getLocalSearchResults(term, [
                                            'fullname',
                                            'lname',
                                            'phoneNo',
                                            'title',
                                            'name',
                                            'email'
                                        ])?.length == 0;
                                })
                            )
                            .subscribe(
                                (res) => {
                                    this.listOfUsers = res;

                                    if (!this.suggestionsCount && this.noLocalUsersFound) {
                                        this.noUsersFound = true;
                                    }
                                    this.showUsersPanel = true;
                                    this.loading = false;
                                },
                                (err) => {
                                    this.noUsersFound = true;
                                    this.listOfUsers.contacts = [];
                                    this.listOfUsers.contacts_ex = [];
                                    this.listOfUsers.groups = [];
                                    this.listOfUsers.users = [];
                                    this.listOfUsers.users_un = [];
                                    this.listOfUsers.allUsers = [];
                                    this.listOfUsers.vcs = [];
                                    this.suggestionsCount = 0;
                                    this.appLoggerService.error(
                                        'Global search items not found: ',
                                        new Error(JSON.stringify(err?.error?.errors)),
                                        err
                                    );
                                }
                            );
                    },
                    (error) => {
                        this.appLoggerService.error('Global search error: ', error);
                    }
                );
        }
    }

    filterLocalUsers(items) {
        const localUserIds = _.map(this.userService.localSearches, '_id');
        items = items?.map((item: any) => {
            if (item?.title) {
                item.fullname = item?.title;
            } else {
                item.fullname = item?.name + ' ' + item?.lname;
            }
            return item;
        });
        let users = _.filter(items, function (user) {
            return !(localUserIds.includes(user?.userId) || localUserIds.includes(user?._id));
        });
        return users;
    }

    fetchUnreadCount() {
        this.recentThreadsSubscription = this.chatService.getAllRecentChats().subscribe((data) => {
            let count = 0;
            count = _.sumBy(data?.threads, function (thread) {
                return thread.unreadCount;
            });
            this.appService.setUnreadCount(count);
        });
    }

    handleChatEvents(event: SocketEvent) {
        this.zone.run(() => {
            switch (event.event) {
                case SOCKET_EVENTS.CHAT_MESSAGE:
                case SOCKET_EVENTS.CHAT_MESSAGE_READ:
                    this.fetchUnreadCount();
                    break;
                case SOCKET_EVENTS.IS_ONLINE:
                    this.handleSelfUserStatus(event);
                    break;
            }
        });
    }

    clearInput() {
        if (this.input) {
            this.input.nativeElement.value = '';
        }
        this.searchStr = '';
        this.resetUsers();
    }

    resetUsers() {
        this.listOfUsers.contacts = [];
        this.listOfUsers.contacts_ex = [];
        this.listOfUsers.groups = [];
        this.listOfUsers.users = [];
        this.listOfUsers.users_un = [];
        this.listOfUsers.vcs = [];
        this.listOfUsers.allUsers = [];
        this.loading = false;
        this.noUsersFound = false;
        this.noLocalUsersFound = true;
        this.suggestionsCount = 0;
    }

    addOrRemoveUser(user, event?) {
        const matchedIndex = _.findIndex(this.selectedUsers, ['_id', user._id]);
        if (matchedIndex === -1) {
            this.selectedUsers.push(user);
        } else {
            this.selectedUsers.splice(matchedIndex, 1);
            if (this.selectedUsers.length === 0 && this.input.nativeElement.value === '') {
                this.showUsersPanel = false;
            }
        }
        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    initGroupChat(group) {
        if (this.isGlobalSearchEnabled) {
            this.userService.addGlobalSearchItem(group);
        }
        this.appService.resetChatInfo();
        const members = _.map(group?.members, 'userId');
        if (!members.includes(group?.userId)) {
            members.unshift(group?.userId);
        }
        this.subscriptions.push(
            this.chatService
                .getPrivateGroupThread(members, group?._id, [group?.userId], group?.title)
                .subscribe((res) => {
                    const newGroup = {
                        name: group?.title ? group?.title : '',
                        lname: '',
                        unreadCount: 0,
                        isGroupThread: true,
                        _id: res?._id,
                        isFavourite: false,
                        lastUnReadMessageId: '',
                        members: members,
                        context: res?.context ? res.context : ''
                    };
                    this.appService.setInitChatInfo(newGroup);
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.NOTIFY_NEW_CHAT_INIT,
                        data: newGroup
                    });
                    this.showUsersPanel = false;
                    this.clearInput();
                    this.router.navigate(['/chat/', newGroup._id]);
                })
        );
    }

    initAdhocChat(chat) {
        this.appService.resetChatInfo();
        this.subscriptions.push(
            this.chatService.getPrivateAdhocThread(chat?.members, [this.currentUser?._id]).subscribe(
                (res) => {
                    const newChat = {
                        name: chat?.name ? chat?.name : '',
                        lname: chat?.lname ? chat?.lname : '',
                        unreadCount: 0,
                        isGroupThread: true,
                        _id: res?._id,
                        isFavourite: false,
                        lastUnReadMessageId: '',
                        members: res?.members ? res.members : [],
                        context: res?.context ? res.context : '',
                        admin: res?.admins[0]
                    };
                    this.showUsersPanel = false;
                    this.clearInput();
                    this.appService.setInitChatInfo(newChat);
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.NOTIFY_NEW_CHAT_INIT,
                        data: newChat
                    });
                    this.router.navigate(['/chat/', newChat._id]);
                },
                (err) => {
                    this.toastrService.error(this.translate.instant('tostrs.something_went_wrong_please_try_again'));
                }
            )
        );
    }

    checkSelection(id) {
        const matchedIndex = _.findIndex(this.selectedUsers, ['_id', id]);
        return matchedIndex !== -1;
    }

    initChat() {
        this.appService.resetChatInfo();
        let threadId = '';
        if (this.isGlobalSearchEnabled) {
            this.userService.addGlobalSearchItem(
                this.selectedUsers?.length > 3 ? this.selectedUsers?.slice(0, 3) : this.selectedUsers
            );
        }
        if (this.selectedUsers.length === 1) {
            const user = this.selectedUsers[0];
            this.chatService
                .getPrivateChatThread(
                    [this.currentUser?._id, this.utilService.fetchUserId(user)],
                    this.currentUser?._id,
                    [this.currentUser?._id]
                )
                .subscribe((data) => {
                    this.showUsersPanel = false;
                    if (this.isGlobalSearchEnabled) {
                        this.clearInput();
                    }
                    if (this.input) {
                        this.input.nativeElement.value = '';
                    }
                    const newUser = {
                        name: user?.name ? user.name : '',
                        lname: user?.lname ? user.lname : '',
                        unreadCount: data.unreadCount ? data.unreadCount : 0,
                        isGroupThread: false,
                        _id: data?._id,
                        isFavourite: false,
                        lastUnReadMessageId: '',
                        members: data?.members,
                        context: data?.context ? data.context : ''
                    };
                    threadId = data?._id;
                    this.appService.setInitChatInfo(newUser);
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.NOTIFY_NEW_CHAT_INIT,
                        data: newUser
                    });
                    this.router.navigate(['/chat/', threadId]);
                });
        } else if (this.selectedUsers.length > 1) {
            this.showUsersPanel = false;
            if (this.input) {
                this.input.nativeElement.value = '';
            }
            const users = _.orderBy(this.selectedUsers, [(user) => user.name.toLowerCase()], ['asc']);
            const chat = {
                members: [this.currentUser?._id, ..._.map(this.selectedUsers, this.utilService.fetchUserId)],
                name: users[0]?.name,
                lname: users[0]?.lname + ' +' + users.length + ' ' + this.translate.instant('chat.more')
            };
            this.initAdhocChat(chat);
        }
        this.selectedUsers = [];
    }

    handleMaxScreenWidth(routeUrl = this.router.url) {
        if (!this.isAuthenticated) {
            this.enableWidth_1366 =
                routeUrl.indexOf('/meetings') === -1 &&
                routeUrl.indexOf('/chat') === -1 &&
                routeUrl.indexOf('/conference/call') === -1 &&
                routeUrl.indexOf('/addtojiomeet') === -1 &&
                routeUrl != '/contacts';
        } else {
            this.enableWidth_1366 =
                routeUrl.indexOf('/meetings') === -1 &&
                routeUrl.indexOf('/chat') === -1 &&
                routeUrl.indexOf('/conference/call') === -1 &&
                routeUrl.indexOf('/addtojiomeet') === -1 &&
                routeUrl != '/contacts' &&
                routeUrl.indexOf('/home') === -1 &&
                routeUrl.indexOf('/host') === -1 &&
                routeUrl.indexOf('/join') === -1 &&
                routeUrl.indexOf('/precalltesting') === -1;
        }
        window.scroll(0, 0);
        document.getElementById('mainapp').classList.remove('overflow-hidden');
        if (!this.enableWidth_1366) {
            this.toggleDarkTheme('remove');
        }
    }

    handleShowMegaMenues(routeUrl = this.router.url) {
        // check if user is logged in and on dashboard screen then don't show home mega menues
        this.showHomeMegaMenues = this.enableWidth_1366;
        if (this.showHomeMegaMenues) {
            //  this.setTheme();
            if (this.isMobile) {
                //this.toggleMenu();
            }
            this.activeHover = false;
            // generate dummy data
            this.megaMenuData = MEGA_MENUES;

            // if user is logged in then add sales menu in more mega menu
            if (this.isAuthenticated && this.megaMenuData.more.menues[1].data.length === 4) {
                this.megaMenuData.more.menues[1].data.unshift({
                    title: 'Sales',
                    ttitle: 'website_header.sales',
                    desc: 'Register your interest for various JioMeet products',
                    tdesc: 'website.sales_desc',
                    type: '2',
                    icon: 'assets/img/website/menuIcons/contact_sales.svg',
                    path: '/contactsales',
                    isExternal: false,
                    isPublic: false
                });
            }

            // if mode is mobile mode then prepare mobile menues
            this.mobileMegaMenuData = [
                {
                    label: 'Products',
                    data: this.headerMenuesMobile('products'),
                    tlabel: 'website_header.products'
                },
                {
                    label: 'Use Cases',
                    data: this.headerMenuesMobile('useCases'),
                    tlabel: 'website_header.use_cases'
                },
                { label: 'Sales', data: [], tlabel: 'website_header.sales' },
                { label: 'Pricing', data: [], tlabel: 'website_header.pricing' },
                { label: 'More', data: this.headerMenuesMobile('more'), tlabel: 'website_header.more' },
                { label: 'Download App', data: [], tlabel: 'website.download_app' },
                {
                    label: 'English',
                    tlabel: 'English',
                    icon: 'assets/img/glob-lang-dark.svg',
                    data: [
                        {
                            title: 'English',
                            ttitle: 'English',
                            desc: '',
                            icon: 'assets/img/done-tickmark.svg',
                            path: 'en'
                        },
                        {
                            title: 'हिंदी',
                            ttitle: 'हिंदी',
                            icon: 'assets/img/done-tickmark.svg',
                            path: 'hi'
                        }
                    ]
                }
            ];

            // if user is logged in then don't show sales as main menu
            // if (this.isAuthenticated) this.mobileMegaMenuData.splice(6, 1);
            this.setLanguageMenu(this.activeLang);
            this.setTheme();
        }
    }

    async translateMegaMenues() {
        this.megaMenuData = await this.utilService.handleDataTranslation('website', this.megaMenuData);
    }

    headerMenuesMobile(type) {
        let menues = [];
        for (let i = 0; i < this.megaMenuData[type].menues.length; i++) {
            menues = [...menues, ...this.megaMenuData[type].menues[i].data];
        }

        // if type is usecase then sort the menu category
        if (type === 'useCases') {
            menues.sort((a, b) => a.index - b.index);
        }
        return menues;
    }

    getProfilePicPath() {
        return this.userService.getProfilePicPath(this.currentUser._id);
    }

    hostMeeting(show) {
        this.toggleMenu();
        this.router.navigate(['shortener'], { queryParams: { host: true } });
    }

    navigateToPlanMeeting() {
        this.router.navigate(['meetings'], { queryParams: { planMeeting: true } });
    }

    navigateToJoinMeeting() {
        this.gTagService.sendEvent(GA_EVENTS.HEADER_JOIN_MEETING);
        this.gTagService.sendEvent(GA4_EVENTS.HEADER_JOIN_A_MEETING);
        this.router.navigate(['shortener']);
        this.showMenu = false;
    }
    navigateToJiomeet() {
        this.appService.openSettings(false); // Closing settings view if opens on sign out option clicked
        this.gTagService.sendEvent(GA_NEW.HEADER_JIOMEET);
        this.gTagService.sendEvent(GA4_EVENTS.HEADER_JIOMEET_LOGO);
        this.router.navigate(['/main']);
        this.setTabBtnAccessibityName('main');
        this.setFocusOnElement('website');
    }

    navigateToHome() {
        this.gTagService.sendEvent(GA_NEW.HEADER_HOME);
        // this.toggleMenu();
        this.gTagService.sendEvent(GA_EVENTS.HEADER_JIOMEET);
        if (this.showMenu) {
            this.showMenu = false;
        }
        this.router.navigate(['/home'], { relativeTo: this.activatedRoute });
        this.setTabBtnAccessibityName('home');
        this.setFocusOnElement('website');
        // setTimeout(() => {
        //   this.utilService.scrollToAnchor('website-top', 50);
        // }, 100);
    }

    navigateToSignUp() {
        this.gTagService.sendEvent(GA_EVENTS.HEADER_SIGNUP);
        this.gTagService.sendEvent(GA4_EVENTS.HEADER_SIGNUP);
        if (!this.router.url.includes('/signup')) {
            this.router.navigate(['/signup'], { relativeTo: this.activatedRoute });
        } else {
            this.eventEmitterService.emit({ type: APP_EVENTS.SIGNUP_NAVIGATION, data: {} });
        }
        this.showMenu = false;
        this.setFocusOnElement('signupheader');
    }

    navigateToSignIn() {
        this.gTagService.sendEvent(GA_EVENTS.HEADER_SIGNIN);
        this.gTagService.sendEvent(GA4_EVENTS.HEADER_SIGNIN);
        setTimeout(() => {
            const headingFocusEle: any = document.querySelector('.headingfocus');
            if (headingFocusEle) {
                headingFocusEle?.focus();
            }
        }, 100);
        if (!this.router.url.includes('/login')) {
            this.router.navigate(['/login'], { relativeTo: this.activatedRoute });
        } else {
            this.eventEmitterService.emit({ type: APP_EVENTS.LOGIN_NAVIGATION, data: {} });
        }
        this.showMenu = false;
    }

    navigateToProfile() {
        this.toggleMenu();
        this.router.navigate(['/profile'], { relativeTo: this.activatedRoute });
    }

    navToScheduleMeeting() {
        // this.toggleMenu();
        this.router.navigate(['meetings'], { queryParams: { planMeeting: true } });
    }

    navigateToDashboard() {
        this.showMenu = false;
        this.gTagService.sendEvent(GA_EVENTS.HEADER_MEETINGS);
        this.gTagService.sendEvent(GA_NEW.HEADER_MEETINGS);
        this.setTabBtnAccessibityName('meeting');
        if (this.isMobile || !this.enableWidth_1366) {
            this.router.navigate(['meetings']);
            this.setFocusOnElement('page');
        } else window.open('meetings', '_blank');
    }

    navigateToChat() {
        this.setFocusOnElement('chat');
        this.setTabBtnAccessibityName('chat');
        this.gTagService.sendEvent(GA_EVENTS.HEADER_CHATS);
        this.gTagService.sendEvent(GA_NEW.HEADER_CHATS);
        if (this.router.url.substring(0, 5) === '/chat') {
            return;
        } else {
            this.router.navigate(['chat'], { relativeTo: this.activatedRoute });
        }
    }

    navigateToContacts() {
        this.setFocusOnElement('contacts-main');
        this.setTabBtnAccessibityName('contact');
        if (this.router.url.substring(0, 9) === '/contacts') {
            return;
        } else {
            this.router.navigate(['contacts'], { relativeTo: this.activatedRoute });
        }
    }

    navigateToHelp() {
        this.toggleMenu();
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Website Home Options',
                eventType: 'app_event',
                eventAction: 'Help',
                status: 'success'
            })
            .subscribe();
        this.gaService.sendEvent(GA_EVENTS.HELP_SECTION);
        const helpURL = `${this.appService.getEnvVariable('HOST_URL')}/webhelp`;
        window.open(helpURL, '_blank');
    }

    navigateToAppStore() {
        const a = document.createElement('a');
        a.href = this.appService.getConfigVariable('IOS_INSTALLER_URL');
        a.download = 'Download';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    navigateToPlayStore() {
        const a = document.createElement('a');
        a.href = this.appService.getConfigVariable('ANDROID_INSTALLER_URL');
        a.download = 'Download';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    navigateTo(route) {
        this.setFocusOnElement('contact_sales_page');
        const event = route === 'contactsales' ? GA_EVENTS.HEADER_CONTACTSALES_MENU : GA_EVENTS.HEADER_PRICING_MENU;
        const GA4event =
            route === 'contactsales' ? GA4_EVENTS.HEADER_CONTACTSALES_MENU : GA4_EVENTS.HEADER_PRICING_MENU;
        this.gTagService.sendEvent(event);
        this.gTagService.sendEvent(GA4event);
        this.router.navigate([route]);
    }

    navigateToPricing() {
        this.setFocusOnElement('pricing_page');
        this.router.navigate(['pricing']);
    }

    logout() {
        this.appService.openSettings(false); // Closing settings view if opens on sign out option clicked
        this.gTagService.sendEvent(GA_EVENTS.HEADER_SIGNOUT);
        this.showMenu = false;
        this.translate.get(['sign_out_popup']).subscribe((translations) => {
            this.confirmationService.confirm({
                message: translations.sign_out_popup.text,
                header: translations.sign_out_popup.title,
                acceptLabel: translations.sign_out_popup.acceptLabel,
                rejectLabel: translations.sign_out_popup.rejectLabel,
                acceptButtonStyleClass: 'custom-button bg-secondary',
                rejectButtonStyleClass: 'custom-button bg-primary',
                accept: () => {
                    this.userService.setProBannerVisibility(false);
                    if (!this.appService.isOnLine) {
                        this.toastrService.info(this.translate.instant('tostrs.something_went_right'));
                        return;
                    }
                    this.gTagService.sendEvent(GA4_EVENTS.HEADER_SIGNOUT_YES);
                    this.diagnosticService.sendEvent({
                        eventCategory: 'Web Profile',
                        eventType: 'app_event',
                        eventAction: 'Sign Out',
                        endpoint: '/api/logout',
                        status: 'success'
                    });
                    //.subscribe();
                    this.authService.logout(this.desktopAppService.isDesktopApp).subscribe(() => {
                        if (this.desktopAppService.isDesktopApp) {
                            this.desktopAppService.reload(null);
                        }
                    });
                },
                reject: () => {
                    this.gTagService.sendEvent(GA4_EVENTS.HEADER_SIGNOUT_NO);
                }
            });
            this.utilService.setTimeOutForAccessibilityPopUp();
        });
    }

    downloadJioMeet(a = null) {
        this.gaService.sendEvent(GA_EVENTS.DOWNLOAD_JIOMEET);
        if (a) {
            this.toggleMenu();
            if (!this.utilService.downloadJioMeet()) {
                this.utilService.scrollToAnchor('download', 50);
            }
        } else {
            if (!this.utilService.downloadJioMeet()) {
                this.utilService.scrollToAnchor('download', 50);
            }
        }
    }

    toggleMenu() {
        this.showMenu = !this.showMenu;
        document.getElementById('mainapp').classList.remove('overflow-hidden');
        if (this.showMenu && this.isMobile) {
            this.appService.openSettings(false); // Closing settings view if opens on sign out option clicked
            document.getElementById('mainapp').classList.add('overflow-hidden');
            document.getElementById('navbarToggler').style.height = `${window.innerHeight - 86}px`;
            this.appService.closeProfileSettingsMenu();
        }
    }

    onOutsideClick(event) {
        this.showUsersPanel = false;
        this.appService.closeProfileSettingsMenu();
    }

    clickOutsideProfileIcon() {
        this.showProUserBadge = false;
        this.showProfileMenu = false;
    }

    onOutsideClickFloater(event) {
        var sideBarExp = document.getElementById('sideBarExpanded');
        var sideBarCol = document.getElementById('sideBarCollapsed');
        if (sideBarExp.style.display === 'block') {
            sideBarExp.style.display = 'none';
            sideBarCol.style.display = 'block';
        }
    }

    sidebarToggle() {
        var sideBarCol = document.getElementById('sideBarCollapsed');
        var sideBarExp = document.getElementById('sideBarExpanded');
        if (sideBarCol.style.display === 'none') {
            sideBarCol.style.display = 'block';
            sideBarExp.style.display = 'none';
        } else {
            sideBarCol.style.display = 'none';
            sideBarExp.style.display = 'block';
        }
    }

    goToContactSales() {
        this.gTagService.sendEvent(GA_NEW.DASHBOARD_SIDE_BAR_CHAT);
        this.router.navigate(['contactsales']);
    }

    openLetsChat() {
        this.gTagService.sendEvent(GA_NEW.DASHBOARD_SIDE_BAR_CONTACT);
        const iframes = document.getElementsByClassName('xdk-iframe');
        const iframeElemRef: any = document.getElementsByClassName('xdk-iframe')[iframes.length - 1];
        if (iframeElemRef) {
            const chatElemRef = iframeElemRef.contentWindow.document.getElementsByClassName('minimized-view')[0];
            chatElemRef.click();
        }
    }
    whatsapp_us() {
        this.gTagService.sendEvent(GA_NEW.DASHBOARD_SIDE_BAR_WHATSAPP);
    }

    navigateToBlog() {
        this.gaService.sendEvent(GA_EVENTS.BLOG);
        window.open(this.blogUrl, '_blank');
    }

    handleMouseEnter(event, menu) {
        if (this.leaveTimeOutRef) {
            clearTimeout(this.leaveTimeOutRef);
        }
        clearTimeout(this.hoverTimeOutRef);
        this.hoverTimeOutRef = setTimeout(() => {
            this.activeHover = true;
            this.activeHoverMenu = menu;

            this.headerRef.classList.add('border-bottom');
        }, 200);
    }

    handleMouseLeave() {
        this.leaveTimeOutRef = setTimeout(() => {
            this.activeHover = false;
            clearTimeout(this.hoverTimeOutRef);
            this.headerRef.classList.remove('border-bottom');
        }, 200);
    }

    setTheme() {
        this.headerRef = document.getElementById('website-header');
        const route = this.router.url;
        this.activeTheme = 'themeLight';
        switch (route) {
            //case '/register/conference':
            case '/events':
            case '/cpaas':
                this.activeTheme = 'themeDark';
            default:
                break;
        }

        // if active theme is dark then start scrollable content from top
        if (this.activeTheme === 'themeDark' && this.enableWidth_1366) {
            this.toggleDarkTheme();
        } else {
            this.toggleDarkTheme('remove');
        }

        // this is we are reseting if we are navigating from /webhelp page
        document.getElementById('scrollable').classList.remove('pos-abs');

        // if the url is webhelp then handle it
        if (route === '/webhelp') {
            this.handleNavigateToHelp();
        }
        if (route.indexOf('/join') !== -1 || route.indexOf('/host') !== -1) {
            this.addPosAbsolute();
        }
    }

    toggleDarkTheme(action = 'add') {
        document.getElementById('scrollable')?.classList[action]('dark');
        this.headerRef?.classList[action]('dark');
        document.getElementById('navbar')?.classList[action]('dark');
    }

    addPosAbsolute() {
        document.getElementById('scrollable').classList.add('pos-abs');
    }

    handleNavigateToHelp() {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Website Home Options',
                eventType: 'app_event',
                eventAction: 'Help',
                status: 'success'
            })
            .subscribe();
        this.addPosAbsolute();
    }

    handleTopHeader() {
        let prevScrollTop = 0;
        window.addEventListener('scroll', this.onScrollCallback.bind(this));
    }

    onScrollCallback(e) {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        this.currentScrollTop = scrollTop;
        // handle top header show /hide
        if (scrollTop > this.prevScrollTop + 37) {
            this.topHeaderVisibility = false;
            this.prevScrollTop = scrollTop;
        } else if (this.prevScrollTop > scrollTop + 37) {
            this.topHeaderVisibility = true;
            this.prevScrollTop = scrollTop;
        }
        if (scrollTop === 0) {
            this.topHeaderVisibility = true;
        }

        // handle main header transition from dark to light or viceversa
        if (this.activeTheme === 'themeDark') {
            if (scrollTop > 690) {
                // remove dart theme
                if (scrollTop > 690 && this.headerRef.classList.contains('dark')) {
                    this.toggleDarkTheme('remove');
                }
            } else {
                // add dark theme
                if (!this.headerRef.classList.contains('dark') && this.enableWidth_1366) {
                    this.toggleDarkTheme();
                }
            }
        }

        this.eventEmitterService.emit({
            type: APP_EVENTS.PAGE_SCROLLED,
            data: { target: { scrollTop } }
        });
    }

    handleDownloadApp() {
        this.gTagService.sendEvent(GA_EVENTS.HEADER_DOWNLOAD_APP);
        this.gTagService.sendEvent(GA4_EVENTS.HEADER_DOWNLOAD_APP);
        this.downloadAppService.downloadJioMeet();
    }

    whatsAppClick() {
        this.gTagService.sendEvent(GA_EVENTS.HEADER_WHATSAPPS);
        this.gTagService.sendEvent(GA4_EVENTS.HEADER_WHATSAPP);
    }

    helpClick() {
        this.gTagService.sendEvent(GA_EVENTS.HEADER_HELP);
        this.gTagService.sendEvent(GA4_EVENTS.HEADER_HELP);
        const url = this.appService.getEnvVariable('HOST_URL') + 'webhelp';
        window.open(url.toString(), '_blank');
    }

    navigateToAdminPortal() {
        this.gTagService.sendEvent(GA_EVENTS.HEADER_ADMIN_PORTAL);
        this.gTagService.sendEvent(GA_NEW.HEADER_ADMIN_PORTAL);
        const url = this.appService.getEnvVariable('HOST_URL') + 'adminportal/login';
        window.open(url.toString(), '_blank');
    }

    navigateToEventsPortal() {
        this.gTagService.sendEvent(GA_EVENTS.HEADER_JIO_EVENTS);
        this.gTagService.sendEvent(GA4_EVENTS.HEADER_JIO_EVENTS);
        window.open(`${this.appService.getConfigVariable('JIOEVENTS_BASE_URL')}dashboard`, '_blank');
    }

    setLang(lang) {
        let oldGAEvent = lang == 'en' ? GA_EVENTS.HEADER_SET_LANG_EN : GA_EVENTS.HEADER_SET_LANG_HI;
        this.gTagService.sendEvent(oldGAEvent);
        let ga4Event = lang == 'en' ? GA4_EVENTS.HEADER_SET_LANG_EN : GA4_EVENTS.HEADER_SET_LANG_HI;
        this.gTagService.sendEvent(ga4Event);
        this.appService.getAndSaveUserPreferredLang(lang);
        this.translate.use(lang);
        this.translate.get('primeng').subscribe((res) => this.config.setTranslation(res));
        this.activeLang = lang;
        this.setLanguageMenu(lang);
        this.eventEmitterService.emit({
            type: APP_EVENTS.LANGUAGE_CHANGE,
            data: { lang }
        });
    }

    // set the language menu of mega menu based on active lang
    setLanguageMenu(lang) {
        const languageObjPos = 6;
        // update the lang data
        this.mobileMegaMenuData[languageObjPos].label = lang === 'en' ? 'English' : 'हिंदी';
        this.mobileMegaMenuData[languageObjPos].tlabel = lang === 'en' ? 'English' : 'हिंदी';
        this.mobileMegaMenuData[languageObjPos].data[0].icon = lang === 'en' && 'assets/img/done-tickmark.svg';
        this.mobileMegaMenuData[languageObjPos].data[1].icon = lang === 'hi' && 'assets/img/done-tickmark.svg';
    }

    closePlanSelection() {
        this.showPlanPopup = false;
        this.router.navigate(['/home']);
    }

    navigateToWebinars() {
        this.router.navigate(['dashboard/webinar']);
    }

    onFocus() {
        this.gTagService.sendEvent(GA_EVENTS.HEADER_SEARCH);
        this.gTagService.sendEvent(GA_NEW.HEADER_SEARCH);
        if (this.isGlobalSearchEnabled) {
            this.showUsersPanel = true;
        }
    }

    clickProfileIcon() {
        this.showProfileMenu = !this.showProfileMenu;
        this.showProUserBadge = !this.showProUserBadge;
        this.emitLetChatsHideEvent();
        this.gTagService.sendEvent(GA_EVENTS.HEADER_PROFILE_ICON);
        this.gTagService.sendEvent(GA_NEW.HEADER_PROFILE_ICON);
        this.appService.setAvailabilityStatusFromServer(this.appService.getUserAvailability());
    }

    goToDashboard() {
        this.gTagService.sendEvent(GA_EVENTS.HEADER_MY_DASHBOARD);
        this.router.navigate(['meetings']);
    }

    resetNavBarHeight() {
        if (this.showMenu && this.isMobile) return;
        document.getElementById('mainapp').classList.remove('overflow-hidden');
        if (document.getElementById('navbarToggler')) {
            document.getElementById('navbarToggler').style.height = `unset`;
        }
    }

    openVCRoom(vcroom) {
        this.userService.addGlobalSearchItem(vcroom);
        this.localHelperService.setVCroomActivatedTrue(true, vcroom);
        this.router.navigate(['/contacts']);
    }

    async makeCall(userContact, type, event) {
        this.userService.addGlobalSearchItem(userContact);
        this.contactToCall = userContact;
        this.type = type;
        this.event = event;
        this.enableCallLoader = true;
    }

    disableCallLoader(event) {
        this.enableCallLoader = false;
    }

    ngOnDestroy() {
        window.removeEventListener('scroll', this.onScrollCallback);
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.subscription?.unsubscribe();
        this.recentThreadsSubscription?.unsubscribe();
    }

    showAppUpdatePopup() {
        this.desktopAppUpdaterService.showElectronUpdatePopup = !!this.desktopAppService.isDesktopApp;
    }

    cancel() {
        this.desktopAppUpdaterService.showElectronUpdatePopup = !this.desktopAppService.isDesktopApp;
    }

    //Accessibility Methods
    setDefaultTabBtnAccessibityName() {
        this.mainTabAccessibilityLabel = 'Navigate to main page';
        this.homeTabAccessibilityLabel = 'Navigate to home page';
        this.chatTabAccessibilityLabel = 'Navigate to chat page';
        this.meetingTabAccessibilityLabel = 'Navigate to meeting page';
        this.contactTabAccessibilityLabel = 'Navigate to contact page';
    }

    setTabBtnAccessibityName(index) {
        this.setDefaultTabBtnAccessibityName();
        switch (index) {
            case 'home':
                this.homeTabAccessibilityLabel = 'Home page selected';
                break;
            case 'chat':
                this.chatTabAccessibilityLabel = 'Chat page selected';
                break;
            case 'meeting':
                this.meetingTabAccessibilityLabel = 'Meeting page selected';
                break;
            case 'contact':
                this.contactTabAccessibilityLabel = 'Contact page selected';
                break;
            case 'main':
                this.mainTabAccessibilityLabel = 'Main page selected';
                break;
        }
    }

    setFocusOnElement(focusElement) {
        setTimeout(() => {
            const element: any = document.getElementsByClassName(focusElement)[0];
            if (element) {
                element?.focus();
            }
        }, 100);
    }

    onKeyDown(event: KeyboardEvent) {
        if (event.shiftKey && event.key === 'Tab') {
            // Handle the Shift+Tab key event
            this.handleMouseLeave();
        }
    }

    setUserAvailabilityStatus() {
        if (this.isAuthenticated) {
            this.appService.setUserAvailability(this.appService.getUserAvailability());
            this.sendStatusToServer(this.appService.getUserAvailability());
        }
    }

    settingsViewSubcribtionForMobile() {
        this.appService.openSettingsView.subscribe((state) => {
            if (state && this.isMobile && this.showMenu) {
                this.showMenu = false;
            }
        });
    }
    selfStatusListener() {
        this.appService.statusSelf.subscribe((status) => {
            this.setSelfStatusImage(status);
        });
    }

    setSelfStatusImage(status) {
        switch (status) {
            case APP_CONSTANT.AVAILABLE:
                this.statusImage = 'assets/img/contacts/online.svg';
                break;
            case APP_CONSTANT.AWAY:
                this.statusImage = 'assets/img/contacts/profile-away.svg';
                break;
            case APP_CONSTANT.BUSY:
                this.statusImage = 'assets/img/contacts/busy.svg';
                break;
            default:
                this.statusImage = 'assets/img/contacts/offline.svg';
        }
    }

    sendStatusToServer(status) {
        this.userService.setUserStatusService(status).subscribe((res: any) => {
            if (res.success === false) {
                return;
            }
        });
    }

    demandSelfUserStatus() {
        if (this.appService.isPageRefreshed) {
            this.appService.isPageRefreshed = false;
            this.setUserAvailabilityStatus();
            return;
        }
        setTimeout(() => {
            this.rtcService.sendEvent({
                event: 'isonline',
                data: {
                    userIds: [this.currentUser._id]
                }
            });
        }, 2000);
    }

    handleSelfUserStatus(event: SocketEvent) {
        if (this.isAuthenticated && event.data?.userId === this.currentUser._id) {
            if (event.data?.status !== APP_CONSTANT.OFFLINE) {
                this.appService.setUserAvailability(event.data?.status);
                this.appService.setAvailabilityStatusFromServer(event.data?.status);
            } else {
                this.setUserAvailabilityStatus();
            }
        }
    }
    emitLetChatsHideEvent() {
        this.eventEmitterService.emit({
            type: APP_EVENTS.PROFILE_HIDE_EVENT,
            data: 'letsChat'
        });
    }
}
function navigateToContacts(): number {
    throw new Error('Function not implemented.');
}
