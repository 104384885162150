<div class="pre-call-testing-cta">
    <div class="cta-container">
        <div class="cta-left">
            <div class="cta-img">
                <img src="assets/images/pre-call-testing/cta.svg" alt="" />
            </div>
            <div class="cta-text">Run Pre-Call Test</div>
        </div>
        <div class="cta-right">
            <div class="cta-button" (click)="navigateToTestSection()">Start Test</div>
        </div>
    </div>
</div>
