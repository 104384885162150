import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { GaDiagnosticService } from 'src/app/core/services/ga-diagnostic.service';
import { AppService } from 'src/app/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-tnc',
    templateUrl: './tnc.component.html',
    styleUrls: ['./tnc.component.scss']
})
export class TncComponent implements OnInit {
    websiteLink = location.origin;
    showStbChanges = false;
    isLangHindi = false;
    monthlyComplianceReportUrl;

    constructor(
        private gaService: GaDiagnosticService,
        private appService: AppService,
        private router: Router,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        // const ele = document.getElementsByClassName('app-container')[0];
        // if (ele) {
        //   ele.scrollTo(0, 0);
        // }

        this.monthlyComplianceReportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.appService.getConfigVariable('MONTHLY_COMPLIANCE_REPORT_URL')
        );

        this.route.queryParams.subscribe((params) => {
            if (params['isstb']) {
                this.showStbChanges = true;
            }
            this.isLangHindi = params['lan'] === 'hi' ? true : false;
        });

        if (this.appService.getAndSaveUserPreferredLang() == 'hi') {
            this.isLangHindi = true;
        }

        this.gaService.sendPageView({
            page_title: 'tnc_page',
            page_path: '/terms-condition-business'
        });
    }

    gotopp() {
        this.router.navigate(['privacy-policy-business']);
        // window.open(this.appService.getEnvVariable('HOST_URL') + 'privacy-policy-business');
    }

    gototnc() {
        //this.router.navigate(['terms-condition-business']);
        // window.open(this.appService.getEnvVariable('HOST_URL') + 'terms-condition-business');
    }
}
