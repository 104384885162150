import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { APP_EVENTS } from 'src/app/constants';
import {
    AuthService,
    EventData,
    EventEmitterService,
    UserService,
    UtilService,
    AppService,
    DownloadAppService
} from 'src/app/core';
import { WEBSITE_RETAIL_SOLUTIONS } from '../websiteConstant';
import { GoogleTagManagerService } from 'src/app/core';
import { GA_EVENTS } from '../ga-events';

import * as Flickity from 'flickity';
import { GA_PAGEVIEW } from '../ga-pageview';
import { GA_NEW } from 'src/app/shared/ga-new';

@Component({
    selector: 'app-website-retails',
    templateUrl: './website-retails.component.html',
    styleUrls: ['./website-retails.component.scss']
})
export class WebsiteRetailsComponent implements OnInit {
    retailSolutions = [];
    isMobile = false;
    screenInnerWidth = window.innerWidth;
    testimonialCarousel;
    websiteMetaInfo;
    isAuthenticated = false;
    @ViewChild('testimonialSlider', { static: true })
    testimonialSlider: ElementRef;
    activeLang = 'en';
    retailTestimonials: string[] = [];

    constructor(
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private userService: UserService,
        private authService: AuthService,
        private appService: AppService,
        private router: Router,
        private gTagService: GoogleTagManagerService,
        private downloadAppService: DownloadAppService
    ) {}

    ngOnInit(): void {
        this.retailSolutions = WEBSITE_RETAIL_SOLUTIONS;
        this.isMobile = this.utilService.isMobileBrowser(1080);
        this.translateRetailSolutions();
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 1080 ? false : true;
                this.screenInnerWidth = event.data.innerWidth;
            }
        });
        this.activeLang = this.appService.getAndSaveUserPreferredLang();
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.LANGUAGE_CHANGE) {
                this.activeLang = this.appService.getAndSaveUserPreferredLang();
            }
        });
        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
        });
        this.getMetaInfo();
        this.gTagService.pushOnDataLayer(GA_PAGEVIEW.PRODUCT_PAGE_JIOMEET);
    }

    getMetaInfo() {
        this.userService.getWebsiteMetaInfo().subscribe(
            (res) => {
                if (res) {
                    this.websiteMetaInfo = res;

                    setTimeout(() => {
                        this.initTestimonialSlider();
                    }, 0);
                    this.testimonialCheck(this.websiteMetaInfo.testimonial);
                }
            },
            (err) => {}
        );
    }

    async translateRetailSolutions() {
        const retailSolutions = await this.utilService.handleDataTranslation('website', {
            retailSolutions: this.retailSolutions
        });
        this.retailSolutions = retailSolutions['retailSolutions'];
    }

    testimonialCheck(testimonials) {
        if (testimonials?.length > 0) {
            testimonials.forEach((element) => {
                if (element.publishOn.includes('JioMeet')) {
                    this.retailTestimonials.push(element);
                }
            });
        }
    }

    initTestimonialSlider() {
        this.testimonialCarousel = new Flickity(this.testimonialSlider.nativeElement, {
            prevNextButtons: !this.isMobile,
            autoPlay: 4000,
            contain: false,
            cellAlign: 0,
            pageDots: true,
            selectedAttraction: 0.2,
            friction: 0.8,
            index: 0,
            resize: true,
            accessibility: false
        });
    }

    goToDownloadApp() {
        this.gTagService.sendEvent(GA_EVENTS.RETAIL_DOWNLOADAPP_CLICKED_BANNER);
        this.gTagService.sendEvent(GA_NEW.RETAIL_DOWNLOADAPP_CLICKED_BANNER);
        this.downloadAppService.downloadJioMeet();
    }

    navigateToSignUp() {
        this.gTagService.sendEvent(GA_EVENTS.RETAIL_SIGNUP_CLICKED_BANNER);
        this.gTagService.sendEvent(GA_NEW.RETAIL_SIGNUP_CLICKED_BANNER);
        this.router.navigate(['signup']);
        setTimeout(() => {
            const signupElement: any = document.getElementsByClassName('signupheader')[0];
            if (signupElement) {
                signupElement?.focus();
            }
        }, 0);
    }
}
