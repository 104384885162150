import { Injectable } from '@angular/core';

import { AppService } from './app.service';
import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root'
})
export class AppInfoService {
    constructor(private appService: AppService, private restService: RestService) {}

    getReleaseNotesForOS(os) {
        return this.restService.get(`api/config?os_type=${os}`);
    }

    getFeedbackReasons() {
        return this.restService.get(`api/feedback/reasons`);
    }

    getCaptchaDetail(payload) {
        return this.restService.post(`api/captcha/refresh`, payload);
    }

    verifyCaptchaDetail(payload) {
        return this.restService.post(`api/captcha/verify`, payload);
    }

    submitFeedback(payload) {
        return this.restService.post(`api/feedback/capture`, payload);
    }

    submitCustomerFeedback(payload) {
        return this.restService.post(`api/feedback/storefeedback`, payload);
    }
}
