import { RtcService } from './../../../../core/services/rtc.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import {
    AppService,
    UserService,
    AuthService,
    AgoraService,
    GoogleTagManagerService,
    UtilService,
    LocalStorageService,
    EventEmitterService
} from 'src/app/core';
import { VideoWrapperService } from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';
import { GA_EVENTS } from '../ga-events';
import { GA_NEW } from 'src/app/shared/ga-new';
import { APP_EVENTS } from 'src/app/constants';
import { JMVideoInputTest } from '@jiomeet/jm-test-rtc';

@Component({
    selector: 'app-video-settings',
    templateUrl: './video-settings.component.html',
    styleUrls: ['./video-settings.component.scss']
})
export class VideoSettingsComponent implements OnInit, OnDestroy {
    private videoService;
    public selectedCameraId;
    public selectedCamera;
    public cameras;

    isGuestUser;
    turnOffVideo;
    fitToScreen = true;
    isAgoraMeeting = false;
    showHDScreenShareOption = false;
    enableHDScreenShare = false;
    screenSharing = false;
    isMobileDevice = false;
    mirroView: boolean;
    currentUser;
    isFullHdStreamingEnabled = false;
    showImagePanel = false;
    testVideoInputDevice;
    countDown = 6;
    countDownId?: NodeJS.Timeout;
    cameraResult;
    supportsFullHdStreaming = false;
    camTestInProgress = false;

    subsciption: Subscription[] = [];
    constructor(
        private videoServiceWrapper: VideoWrapperService,
        private userService: UserService,
        private appService: AppService,
        private toastrService: ToastrService,
        private rtcService: RtcService,
        private authService: AuthService,
        private translateService: TranslateService,
        public agoraService: AgoraService,
        private utilService: UtilService,
        private gTagService: GoogleTagManagerService,
        private localStorageService: LocalStorageService,
        private eventEmitterService: EventEmitterService
    ) {
        this.videoService = this.videoServiceWrapper.getVideoServiceForPreview();
    }

    ngOnInit(): void {
        this.mirroView = !this.localStorageService.getItem('isMirrorViewEnabled');
        this.isAgoraMeeting = this.rtcService.getIsAgoraMeeting();
        this.turnOffVideo = this.userService.getUserSync()?.videoOff;
        this.fitToScreen = this.videoService.isFitToScreen;
        this.enableHDScreenShare = this.videoService.isEnableHDshare;
        this.showHDScreenShareOption = this.videoService.showHDScreenShareOption;
        this.isGuestUser = !this.authService.getIsAuthenticated();
        this.isMobileDevice = this.utilService.isMobileBrowser();
        this.currentUser = this.userService.getUserSync();
        this.isFullHdStreamingEnabled = this.localStorageService.getItem('enableFullHdStreaming');

        this.subsciption.push(
            this.videoService.getParticipantStatus().subscribe(async (status) => {
                if (!this.videoService?.AgoraRTC && this.videoService.cameras.length === 0) {
                    await this.agoraService.initializeAgoraRTC();
                    await this.videoService.updateAllDevices();
                }
                this.selectedCameraId = this.videoService.selectedLocalCamera;
                this.cameras = this.videoService.cameras.map((camera, idx) => {
                    if (!camera.name) camera.name = `Camera ${idx + 1}`;
                    return camera;
                });
                this.selectedCamera = this.cameras.find((camera) => camera.id === this.selectedCameraId);
            }),
            this.eventEmitterService.subscribe((data) => {
                if (data.type === 'SELF_VIEW_TOGGLED') {
                    this.mirroView = !this.localStorageService.getItem('isMirrorViewEnabled');
                }
            })
        );
        this.screenSharing = this.videoService.screenSharing;
    }

    async ngAfterViewInit() {
        this.startVideo();
    }

    checkFullHDSupport() {
        this.camTestInProgress = true;

        let track = this.videoService.localCameraTrack.getMediaStreamTrack();
        const capabilities = track.getCapabilities();
        let supportedWidth = '';
        let supportedHeight = '';
        if (capabilities) {
            supportedWidth = capabilities.width.max;
            supportedHeight = capabilities.height.max;
            const supportedResolutions = supportedWidth + 'x' + supportedHeight;
            console.log(
                '🚀 ~ VideoSettingsComponent ~ checkFullHDSupport ~ supportedResolutions:',
                supportedResolutions
            );
        } else {
            const settings = track.getSettings();
            supportedWidth = settings.width;
            supportedHeight = settings.height;
            const supportedResolutions = supportedWidth + 'x' + supportedHeight;
            console.log(
                '🚀 ~ VideoSettingsComponent ~ checkFullHDSupport ~ supportedResolutions:',
                supportedResolutions
            );
        }
        if (parseInt(supportedHeight) >= 1080) {
            this.supportsFullHdStreaming = true;
        } else {
            this.supportsFullHdStreaming = false;
            this.isFullHdStreamingEnabled = false;
            this.localStorageService.addItem('enableFullHdStreaming', this.isFullHdStreamingEnabled);
        }
        this.camTestInProgress = false;
    }

    async selectCamera() {
        await this.videoService.manuallyChangeCamera(this.selectedCamera);
        this.checkFullHDSupport();
    }

    onTurnOffVideo(event) {
        const el = event.target;
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translateService.instant('tostrs.something_went_right'));
            el.checked = !el.checked;
            return;
        }

        const isTurnOffVideo = el.checked;
        const videoCheckBox = isTurnOffVideo ? GA_EVENTS.VIDEO_ON_WHILE_CALL : GA_EVENTS.VIDEO_OFF_WHILE_CALL;
        const videoCheckBoxNew = isTurnOffVideo ? GA_NEW.VIDEO_ON_WHILE_CALL : GA_NEW.VIDEO_OFF_WHILE_CALL;
        this.gTagService.sendEvent(videoCheckBox);
        this.gTagService.sendEvent(videoCheckBoxNew);
        this.userService
            .updateProfile({
                videoOff: isTurnOffVideo
            })
            .subscribe(
                () => {},
                (err) => {
                    this.turnOffVideo = !this.turnOffVideo;
                    this.toastrService.error(
                        this.translateService.instant('tostrs.something_went_wrong_please_try_again')
                    );
                }
            );
    }
    mirrorViewToggle() {
        if ((this.mirroView = this.localStorageService.getItem('isMirrorViewEnabled'))) {
            this.gTagService.sendEvent(GA_NEW.SETTINGS_SELF_VIEW_ENABLE);
        } else {
            this.gTagService.sendEvent(GA_NEW.SETTINGS_SELF_VIEW_DISABLE);
        }
        this.mirroView = !this.utilService.toggleMirrorView();
        if (this.isMobileDevice) {
            this.eventEmitterService.emit({
                type: APP_EVENTS.SELF_VIEW_TOGGLE,
                data: null
            });
        }
    }

    onFitToScreen(value) {
        this.fitToScreen = value.checked;
        this.videoService.setFitToScreen(this.fitToScreen);
    }

    onScreenShareHD(value) {
        this.enableHDScreenShare = value.checked;
        this.videoService.enabledHDScreenShare(this.enableHDScreenShare);
    }

    onFullHdStreamingEnabled(event) {
        const el = event.target;
        if (event.type === 'keydown' && event.key === 'Enter') {
            this.isFullHdStreamingEnabled = !this.isFullHdStreamingEnabled;
        } else {
            this.isFullHdStreamingEnabled = el.checked;
        }
        this.localStorageService.addItem('enableFullHdStreaming', this.isFullHdStreamingEnabled);
    }

    async startVideo() {
        try {
            await this.videoService.toggleCameraForSetting('video-preview', true);
            this.showImagePanel = true;
            this.checkFullHDSupport();
        } catch (e) {
            this.showImagePanel = true;
            // console.error('Video Settings page - create video track');
        }
        setTimeout(() => {
            const BGFocusEle: any = document.getElementById('bgfocus');
            if (BGFocusEle) {
                BGFocusEle?.focus();
            }
        }, 100);
    }

    async ngOnDestroy() {
        this.subsciption.forEach((s) => s.unsubscribe());
        await this.videoService.toggleCameraForSetting('video-preview', false);
    }
    //Accessibility change
    setFocusOnElement() {
        setTimeout(() => {
            const element: any = document.getElementById('background');
            element.focus();
        }, 100);
    }
}
