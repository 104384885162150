import { Router } from '@angular/router';
import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    OnDestroy,
    NgZone,
    ViewChild,
    ElementRef,
    AfterViewInit
} from '@angular/core';

import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import {
    RtcService,
    SocketService,
    UserService,
    SocketEvent,
    EventEmitterService,
    EventData,
    VideoWrapperService,
    AppService,
    UtilService,
    AuthService,
    AgoraService,
    DesktopAppService
} from 'src/app/core';
import { SOCKET_EVENTS, APP_EVENTS, KEYBOARD_CONSTANT } from 'src/app/constants';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-incoming-call-popup',
    templateUrl: './incoming-call-popup.component.html',
    styleUrls: ['./incoming-call-popup.component.scss']
})
export class IncomingCallPopupComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('incomingCallPopup') popup: ElementRef<HTMLDivElement>;
    @ViewChild('speakerIcon') speaker: ElementRef<HTMLDivElement>;
    @Output() close: EventEmitter<any> = new EventEmitter();

    meeting: any = {};

    private currentUser;
    private audio: HTMLAudioElement;
    private incomingCallTimeoutId;
    private subscriptions: Subscription[] = [];
    incomingCallAudio: { name: string; audioPath: string };
    videoService;
    isCallEnabledFromChat = false;
    callerMeetingInfo = '';
    isCallIgnored = true;
    noVideoDeviceAvailable: boolean = false;
    isRinging = true;
    joinWithoutVideo = false;
    joinWithoutAudio = false;

    constructor(
        private zone: NgZone,
        private router: Router,
        private toastrService: ToastrService,
        private rtcService: RtcService,
        private userService: UserService,
        private socketService: SocketService,
        private eventEmitterService: EventEmitterService,
        private videoWrapperService: VideoWrapperService,
        private appService: AppService,
        private utilService: UtilService,
        private authService: AuthService,
        private appLoggerService: AppLoggerService,
        private translateService: TranslateService,
        private desktopAppService: DesktopAppService,
        public agoraService: AgoraService
    ) {
        this.videoService = this.videoWrapperService.getVideoServiceForPreview();
    }

    async ngOnInit() {
        this.isCallEnabledFromChat = this.appService.getConfigVariable('ENABLE_CHAT_CALL');
        this.incomingCallAudio = this.utilService.selectedIncomingaudio;
        if (this.incomingCallAudio.name !== 'Silent') {
            this.audio = new Audio(this.incomingCallAudio.audioPath);
            this.audio.loop = true;
            this.audio.play();
        }
        this.meeting = this.rtcService.getIncomingCallMeeting();
        if (this.isCallEnabledFromChat) {
            this.isCallIgnored = true;
            this.callerMeetingInfo = this.getCallName(this.meeting);
        }
        this.currentUser = this.userService.getUserSync();
        const devicePermissions = await this.utilService.getDevicePermissions('Call Loader');
        this.joinWithoutVideo = devicePermissions?.noVideoDevice;
        this.joinWithoutAudio = devicePermissions?.noAudioDevice;

        this.incomingCallTimeoutId = setTimeout(() => {
            this.rtcService.sendCallNotAnsweredNotification().subscribe();
            if (this.meeting?.users?.length > 1) {
                this.eventEmitterService.emit({ type: APP_EVENTS.REFRESH_MEETINGS, data: null });
            }
            this.cancel();
        }, 40 * 1000);

        this.subscriptions.push(
            this.socketService.dataEvents$.subscribe(this.handleSocketDataEvents.bind(this)),
            this.eventEmitterService.subscribe((event: EventData) => {
                if (event.type === APP_EVENTS.SESSION_EXPIRED || event.type === APP_EVENTS.LOGOUT) {
                    clearTimeout(this.incomingCallTimeoutId);
                    this.cancel();
                }
                if (event.type === KEYBOARD_CONSTANT.KEYBOARD_SHORTCUTS) {
                    this.handleKeyboardShortcuts(event);
                }
            })
        );
        this.desktopAppService.restore(1000);
    }

    ngAfterViewInit() {
        this.positionItself();
    }

    getCallName(meetingInfo) {
        let name = this.translateService.instant('incoming_call.is_calling');
        if (meetingInfo) {
            if (meetingInfo?.group_id === '0' && meetingInfo?.users?.length > 1) {
                name = this.translateService.instant('incoming_call.want_to_add_adhoc_call');
            } else if (meetingInfo?.group_id !== '0') {
                name = this.translateService.instant('incoming_call.want_to_add_group_call', {
                    value: meetingInfo?.title
                });
            }
        }
        return name;
    }

    minimizeMe() {
        const popup = this.popup.nativeElement;
        popup.style.position = 'fixed';
        popup.style.right = 10 + 'px';
        popup.style.bottom = 10 + 'px';
        popup.style.top = 'auto';
    }

    toggleRinger() {
        if (this.isRinging) {
            this.speaker.nativeElement.setAttribute(
                'src',
                'assets/img/loudspeaker-mute-basicaction-functional-outline.svg'
            );
            this.audio.pause();
        } else {
            this.speaker.nativeElement.setAttribute('src', 'assets/img/icon-Speaker.svg');
            this.audio.play();
        }
        this.isRinging = !this.isRinging;
    }

    handleSocketDataEvents(event: SocketEvent) {
        this.zone.run(() => {
            switch (event.event) {
                case SOCKET_EVENTS.CALL_ACCEPT:
                    if (event.data.historyId === this.meeting.historyId && event.data.userId === this.currentUser._id) {
                        this.cancel();
                    }
                    break;
                case SOCKET_EVENTS.GENERIC:
                    if (event.data.historyId === this.meeting.historyId && event.data.ownId === this.currentUser._id) {
                        if (
                            [
                                SOCKET_EVENTS.CALL_CUT_NOTIFICATION,
                                SOCKET_EVENTS.CALL_NOT_ANSWERED_NOTIFICATION
                                // SOCKET_EVENTS.IN_ANOTHER_CALL_NOTIFICATION
                            ].includes(event.data.eventType)
                        ) {
                            this.cancel();
                        }
                    }
                    break;
                case SOCKET_EVENTS.CALL_FINISH:
                    // {
                    //   data: {
                    //     dropExisting: false
                    //     host: "u-bff7ab2e-e6fb-418c-ba3b-23bd3680d2e0"
                    //     isFinished: true
                    //     journalId: "jrnl-7f2e96dc-7040-4330-8388-a5577d9bb14c"
                    //     meetingId: "1706742173"
                    //     roomKey: "ajQuxMFPCd"
                    //   }
                    //   event: "callFinish"
                    // }
                    if (this.meeting.jiomeetId === event.data.meetingId && this.meeting.room === event.data.roomKey) {
                        this.toastrService.info(
                            this.translateService.instant('joinmeeting_page_or_joincall.ended_by_host')
                        );
                        this.cancel();
                    }
            }
        });
    }

    declineCall() {
        if (!navigator.onLine) {
            this.toastrService.error('Oops! Please check your internet connection');
            this.cancel();
            return;
        }
        this.rtcService.sendCallRejectNotification().subscribe();
        if (this.isCallEnabledFromChat) {
            this.isCallIgnored = false;
        }
        if (this.meeting?.users?.length > 1) {
            this.eventEmitterService.emit({ type: APP_EVENTS.REFRESH_MEETINGS, data: null });
        }
        this.cancel();
    }

    async acceptCall(type?) {
        if (!navigator.onLine) {
            this.toastrService.error('Oops! Please check your internet connection');
            this.cancel();
            return;
        }
        // Ignoring Device permissions while join call
        /*try {
            if (!this.videoService?.AgoraRTC && this.videoService.cameras.length === 0) {
                await this.agoraService.initializeAgoraRTC();
                await this.videoService.updateAllDevices();
            }
            this.noVideoDeviceAvailable = await this.utilService.checkIfCameraDoesntExists();
            await this.utilService.getUpdatedPermissions();
            if (
                (!this.noVideoDeviceAvailable && this.videoService.cameras.length === 0) ||
                this.videoService.microphones.length === 0
            ) {
                this.cancel();
                this.toastrService.error(
                    this.translateService.instant('tostrs.please_provide_camera_microphone_permission_and_try_again')
                );
                return;
            }
        } catch (err) {
            this.appLoggerService.error('Incoming call - Mic or Camera access denied', err);
            if (!this.noVideoDeviceAvailable) {
                this.cancel();
                this.toastrService.error(
                    this.translateService.instant('tostrs.please_provide_camera_microphone_permission_and_try_again')
                );
                return;
            }
        }*/
        if (this.isCallEnabledFromChat) {
            if (type === 'video' && !this.joinWithoutVideo) {
                this.meeting.isHardMute || this.joinWithoutAudio
                    ? this.userService.setMicrophoneOff(true)
                    : this.userService.setMicrophoneOff(false);
                this.meeting.isHardVideoMute ? this.userService.setVideoOff(true) : this.userService.setVideoOff(false);
            }
            if (type === 'audio' && !this.joinWithoutAudio) {
                this.meeting.isHardMute
                    ? this.userService.setMicrophoneOff(true)
                    : this.userService.setMicrophoneOff(false);
                this.userService.setVideoOff(true);
            }
            this.isCallIgnored = false;
        }
        this.rtcService.sendCallAcceptNotification().subscribe();
        let commonConferenceInfo = {};
        if (this.meeting?.mediaEngine === 'pexip') {
            commonConferenceInfo = {
                isInitiater: false,
                joiningName: this.currentUser.name + ' ' + this.currentUser.lname,
                room: {
                    roomUrl: this.meeting.roomUrl,
                    roomKey: this.meeting.roomKey,
                    roomID: this.meeting.roomID,
                    vpin: this.meeting.vpin,
                    roomPIN: this.meeting.roomPIN,
                    gateway_ip: '',
                    jiomeetId: this.meeting.jiomeetId,
                    topic: this.meeting.topic || this.meeting.title,
                    advancedOptions: {
                        isClassroomMode: this.meeting.isClassroomMode,
                        isRestrictedMeeting: this.meeting.isRestrictedMeeting,
                        isLargeParticipants: this.meeting?.isLargeParticipants
                    },
                    legacyConnect: this.meeting.legacyConnectInfo
                },
                host: this.meeting.host,
                ownerDetails: {
                    name: this.meeting.owner,
                    lname: '',
                    _id: this.meeting.owner_id,
                    email: this.meeting.owner_emailId,
                    tenantId: this.meeting.owner_tenantId
                },
                hideMoreCallControls: false,
                hideAllCallControls: false,
                isFullScreenShare: false,
                incomingCall: true,
                isOneToOneCall: this.meeting?.isOneToOneCall
            };
        } else {
            commonConferenceInfo = {
                isInitiater: false,
                joiningName: this.currentUser.name + ' ' + this.currentUser.lname,
                room: {
                    room_url: this.meeting.callurl,
                    room_key: this.meeting.room,
                    roomID: this.meeting.room_id,
                    vpin: this.meeting.vpin,
                    roomPIN: this.meeting.roomPIN,
                    mediaEngine: this.meeting.mediaEngine,
                    gateway_ip: '',
                    jiomeetId: this.meeting.jiomeetId,
                    topic: this.meeting.topic || this.meeting.title,
                    advancedOptions: {
                        isClassroomMode: this.meeting.isClassroomMode,
                        isRestrictedMeeting: this.meeting.isRestrictedMeeting,
                        isLargeParticipants: this.meeting?.isLargeParticipants
                    },
                    legacyConnect: this.meeting.legacyConnectInfo
                },
                host: this.meeting.host,
                ownerDetails: {
                    name: this.meeting.owner,
                    lname: '',
                    _id: this.meeting.owner_id,
                    email: this.meeting.owner_emailId,
                    tenantId: this.meeting.owner_tenantId
                },
                hideMoreCallControls: false,
                hideAllCallControls: false,
                isFullScreenShare: false,
                incomingCall: true,
                isOneToOneCall: this.meeting?.isOneToOneCall
            };
        }
        this.cancel();

        if (this.meeting?.isWebinar && this.appService.getConfigVariable('redirectIncomingToEvents')) {
            const mConferenceInfo = {
                commonConferenceInfo: commonConferenceInfo,
                roomInfo: this.meeting,
                jwt: this.authService.getAuthInfo()?.jwt
            };
            const hash = btoa(JSON.stringify(mConferenceInfo));
            window.open(
                `${this.appService.getConfigVariable('JIOEVENTS_BASE_URL')}conference/call?incoming=true&hash=${hash}`
            );
            return;
        }
        const additionalConferenceInfo = this.videoWrapperService.getAdditionalConferenceInfo(this.meeting, false);
        const conferenceInfo = { ...commonConferenceInfo, ...additionalConferenceInfo };
        this.rtcService.isHostOrCoHostOfMeeting = this.meeting.hostId === this.currentUser._id;
        this.rtcService.setConferenceInfo(conferenceInfo);
        this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => this.router.navigate(['conference/call'], { queryParams: { incomingCall: true } }));
    }

    cancel() {
        if (this.isCallEnabledFromChat && this.isCallIgnored && this.meeting?.users?.length > 1) {
            this.eventEmitterService.emit({ type: APP_EVENTS.REFRESH_MEETINGS, data: null });
        }
        document.body.style.cursor = 'default';
        this.rtcService.setIncomingCallMeeting(null);
        if (this.audio !== undefined) this.audio.pause();
        this.close.emit();
    }

    positionItself() {
        const popup = this.popup.nativeElement;
        const body = document.body;
        const rect = popup.getBoundingClientRect();
        // popup.style.left = body.clientWidth / 2 - rect.width / 2 + 'px';
        // popup.style.top = body.clientHeight / 2 - rect.height / 2 + 'px';
        popup.style.position = 'fixed';
        popup.style.left = body.clientWidth - rect.width - 40 + 'px';
        popup.style.top = 5 + 'rem';
    }

    handleKeyboardShortcuts(event: EventData) {
        switch (event.data) {
            case KEYBOARD_CONSTANT.CALL_ACCEPT:
                this.acceptCall('audio');
                break;
            case KEYBOARD_CONSTANT.CALL_DECLINE:
                this.declineCall();
                break;
        }
    }

    ngOnDestroy() {
        clearTimeout(this.incomingCallTimeoutId);
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
