import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-megamenu-submenu',
    templateUrl: './megamenu-submenu.component.html',
    styleUrls: ['./megamenu-submenu.component.scss']
})
export class MegamenuSubmenuComponent implements OnInit {
    @Input() subMenuData = { menues: [], whatsNew: [] };
    @Input() parentMenu: string;

    constructor() {}

    ngOnInit(): void {}
}
