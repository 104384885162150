import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { RatingModule } from 'primeng/rating';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { ListboxModule } from 'primeng/listbox';
import { ConfirmationService } from 'primeng/api';

import { ImageCropperModule } from 'ngx-image-cropper';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxImageCompressService } from 'ngx-image-compress';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { ColorPickerModule } from 'primeng/colorpicker';
import { CarouselModule } from 'primeng/carousel';
import { AngularResizedEventModule } from 'angular-resize-event';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { MsalModule } from '@azure/msal-angular';

const primengModules = [
    DialogModule,
    ConfirmDialogModule,
    DynamicDialogModule,
    CalendarModule,
    DropdownModule,
    RatingModule,
    CheckboxModule,
    TableModule,
    TooltipModule,
    VirtualScrollerModule,
    ColorPickerModule,
    OverlayPanelModule,
    ListboxModule,
    CarouselModule
];

import * as fromPipes from './pipes';
import * as fromComponents from './components';
import * as fromDirectives from './directives';
import { CallPreviewMobileComponent } from './components/call-preview-mobile/call-preview-mobile.component';
import { NotificationsSettingsComponent } from './components/call-settings/notifications-settings/notifications-settings.component';
import { VirtualBackgroundComponent } from './components/call-settings/virtual-background/virtual-background.component';
import { EXTERNAL_INTEGRATION } from '../constants';
import { LiveStreamConfigComponent } from './components/call-settings/live-stream-settings/configuration/live-stream-config/live-stream-config.component';
import { TimestampToDurationPipe } from './pipes/timestamp-to-duration.pipe';
import { FilesizeConverterPipe } from './pipes/filesize-converter.pipe';
import { ScreenSharePermissionComponent } from './components/screen-share-permission/screen-share-permission.component';
import { MeetingFormComponent } from './components/meeting-form/meeting-form.component';
import { FilterNonVideoPipe } from './pipes/filter-non-video.pipe';
import { FilterVideoPipe } from './pipes/filterVideo.pipe';
import { UpgradePopupComponent } from './components/upgrade-popup/upgrade-popup.component';
import { ImageSrcTransformerPipe } from './pipes/image-src-transformer.pipe';
import { RecordingPlayerPopUpComponent } from '../dashboard/components/recording-player-popup/recording-player-popup.component';
import { EnvironmentSelectionComponent } from './components/environment-selection/environment-selection.component';
import { ChatAttachmentComponent } from './../conference/components/chat-related/chat-attachment/chat-attachment.component';
import { UploadAttachmentComponent } from '../conference/components/chat-related/upload-attachment/upload-attachment.component';
import { ChatAttachmentsComponent } from './../conference/components/chat-related/chat-attachments/chat-attachments.component';
import {
    AudioClapprComponent,
    DocViewerComponent,
    ImageViewerComponent,
    VideoClapprComponent,
    ViewerComponent
} from '../conference/components/chat-related';
import { GeneralSettingsComponent } from './components/call-settings/general-settings/general-settings.component';
import { SearchDropdownComponent } from './components/search-dropdown/search-dropdown.component';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule.forRoot(),
        ToastContainerModule,
        ...primengModules,
        ImageCropperModule,
        ClipboardModule,
        AngularResizedEventModule,
        TranslateModule,
        MsalModule.forRoot(
            {
                auth: {
                    clientId: EXTERNAL_INTEGRATION.EC_OUTLOOK_CLIENT_ID,
                    authority: EXTERNAL_INTEGRATION.EC_OUTLOOK_AUTHORITY_URL,
                    redirectUri: EXTERNAL_INTEGRATION.EC_OUTLOOK_REDIRECT_URI
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: isIE // set to true for IE 11
                }
            },
            {
                popUp: !isIE,
                consentScopes: ['Calendars.ReadWrite'],
                protectedResourceMap: [[EXTERNAL_INTEGRATION.GRAPH_API_ENDPOINT, ['Calendars.ReadWrite']]],
                extraQueryParameters: {}
            }
        )
    ],
    declarations: [
        ...fromPipes.pipes,
        ...fromComponents.components,
        ...fromDirectives.directives,
        CallPreviewMobileComponent,
        NotificationsSettingsComponent,
        VirtualBackgroundComponent,
        LiveStreamConfigComponent,
        TimestampToDurationPipe,
        FilesizeConverterPipe,
        ScreenSharePermissionComponent,
        MeetingFormComponent,
        FilterNonVideoPipe,
        UpgradePopupComponent,
        FilterVideoPipe,
        ImageSrcTransformerPipe,
        RecordingPlayerPopUpComponent,
        EnvironmentSelectionComponent,
        ChatAttachmentComponent,
        ChatAttachmentsComponent,
        ViewerComponent,
        AudioClapprComponent,
        VideoClapprComponent,
        DocViewerComponent,
        ImageViewerComponent,
        UploadAttachmentComponent,
        GeneralSettingsComponent,
        SearchDropdownComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule,
        ToastContainerModule,
        ...fromPipes.pipes,
        ...fromComponents.components,
        VirtualBackgroundComponent,
        ...fromDirectives.directives,
        ...primengModules,
        ImageCropperModule,
        ClipboardModule,
        AngularResizedEventModule,
        TranslateModule,
        DragDropModule,
        MeetingFormComponent,
        RecordingPlayerPopUpComponent,
        EnvironmentSelectionComponent,
        ChatAttachmentComponent,
        ChatAttachmentsComponent,
        ViewerComponent,
        AudioClapprComponent,
        VideoClapprComponent,
        DocViewerComponent,
        ImageViewerComponent,
        UploadAttachmentComponent
    ],
    providers: [NgxImageCompressService, DatePipe, ConfirmationService]
})
export class SharedModule {}
