import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_EVENTS } from 'src/app/constants';
import { AppService, EventEmitterService, GoogleTagManagerService } from 'src/app/core';
import { GA4_EVENTS } from '../../ga4-events';

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {
    @Input() menuInfo: any;
    @Input() lastItem: boolean;
    @Input() parentMenu: string;
    blogUrl = null;
    constructor(
        private router: Router,
        private eventEmitterService: EventEmitterService,
        private appService: AppService,
        private gTagService: GoogleTagManagerService
    ) {}

    ngOnInit(): void {
        this.blogUrl = this.appService.getConfigVariable('BLOG_URL');
    }

    handleGoTo(menuInfo: any) {
        const popUpElement: any = document.getElementsByClassName('First-Element-Focus')[0];
        setTimeout(() => {
            if (popUpElement) {
                popUpElement?.focus();
            }
        }, 100);

        this.gTagService.sendEvent({
            event: 'jiomeet',
            event_category: 'JioMeet_Header',
            event_action: `${menuInfo?.title}`,
            event_label: 'Success'
        });

        switch (menuInfo?.title) {
            case 'JioMeet':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_PRODUCTS_JIOMEET);
                break;
            case 'Enterprise':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_PRODUCTS_ENTERPRISE);
                break;
            case 'Platform':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_PRODUCTS_PLATFORM);
                break;
            case 'JioEvents':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_PRODUCTS_JIOEVENTS);
                break;
            case 'Discover':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_PRODUCTS_DISCOVER);
                break;
            case 'Education':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_PRODUCTS_EDUCATION);
                break;
            case 'Healthcare':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_PRODUCTS_HEALTHCARE);
                break;
            case 'Hybrid Workplaces':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_PRODUCTS_HYBRID_WORKPLACE);
                break;
            case 'Platform Services':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_PRODUCTS_PLATFORM_SERVICES);
                break;
            case 'Government Sectors':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_PRODUCTS_GOVERNMENT_SECTORS);
                break;
            case 'Download JioMeet':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_DOWNLOAD_JIOMEET);
                break;
            case 'JioMeet Dev Center':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_JIOMEET_DEV_CENTER);
                break;
            case 'Developer Community':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_JIOMEET_DEV_COMMUNITY);
                break;
            case 'Blog':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_BLOG);
                break;
            case 'Need Help?':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_NEED_HELP);
                break;
            case 'Developer Support?':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_DEV_SUPPORT);
                break;
            case 'Provide Feedback':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_PROVIDE_FEEDBACK);
                break;
            case 'Contact Us':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_CONTACT_US);
                break;
            case 'Press Release':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_PRESS_RELEASE);
                break;
            case 'Customers':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_CUSTOMERS);
                break;
            case 'Careers':
                this.gTagService.sendEvent(GA4_EVENTS.HEADER_CAREERS);
                break;
            default:
                break;
        }
        this.eventEmitterService.emit({ type: APP_EVENTS.NAV_ITEM_CLICKED, data: {} });
        if (!menuInfo.path) return;
        if (!menuInfo.isExternal) this.router.navigate([menuInfo.path]);
        else {
            if (this.appService.isDesktopApp() && menuInfo.path.indexOf('http') < 0) {
                const hostname =
                    this.appService
                        .getEnvVariable('HOST_URL')
                        .slice(0, this.appService.getEnvVariable('HOST_URL').length - 1) + menuInfo.path;
                window.open(
                    menuInfo.ttitle !== 'blog' ? hostname : this.blogUrl,
                    '_blank' // <- This is what makes it open in a new window.
                );
            } else {
                window.open(
                    menuInfo.ttitle !== 'blog' ? menuInfo.path : this.blogUrl,
                    '_blank' // <- This is what makes it open in a new window.
                );
            }
        }
    }

    handleTabPress(event) {
        if (this.lastItem) {
            this.setFocusToParentItem();
        }
    }

    setFocusToParentItem() {
        setTimeout(() => {
            const element: any = document.getElementById(this.parentMenu);
            if (element) {
                element?.focus();
            }
        }, 100);
    }
}
