import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnChanges,
    ViewChild,
    ElementRef,
    AfterViewInit,
    ChangeDetectorRef,
    Renderer2
} from '@angular/core';
import { GoogleTagManagerService } from 'src/app/core';
import { Router } from '@angular/router';
import { GA_EVENTS } from '../ga-events';
import { GA4_EVENTS } from '../ga4-events';
import { GA_NEW } from 'src/app/shared/ga-new';

@Component({
    selector: 'app-website-solution',
    templateUrl: './website-solution.component.html',
    styleUrls: ['./website-solution.component.scss']
})
export class WebsiteSolutionComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('solutionDesc', { static: true }) solutionDescRef: ElementRef;
    @Input() solutionType: string;
    @Input() solutionInfo: any;
    @Input() featureItemIndex: number = 0; // index of solution in array of solutions data
    @Input() featureItemActiveIndex: number = null; // index of solution in array of solutions data
    @Output() clickHandler = new EventEmitter<any>();

    active = false;
    constructor(private renderer: Renderer2, private router: Router, private gTagService: GoogleTagManagerService) {}

    ngOnInit(): void {
        if (!this.solutionInfo.iconActive) {
            this.solutionInfo.iconActive = this.solutionInfo.icon;
        }
    }

    ngOnChanges() {
        this.handleActivation();
    }

    handleActivation() {
        this.active = false;
        this.solutionDescRef?.nativeElement.classList.remove(this.solutionType);
        if (this.featureItemIndex === this.featureItemActiveIndex) {
            this.active = true;
            this.renderer.addClass(this.solutionDescRef?.nativeElement, this.solutionType);
        }
    }

    onFeatureClick() {
        this.clickHandler?.emit(this.featureItemIndex);
    }

    ngAfterViewInit() {
        this.handleActivation();
    }

    handleGoTo(solutionInfo: any) {
        switch (this.solutionInfo.desc) {
            case 'Outlook Add-in':
                this.gTagService.sendEvent(GA_EVENTS.OUTLOOK_ADD_IN);
                this.gTagService.sendEvent(GA_NEW.OUTLOOK_ADD_IN);
                break;
            case 'Personalised Virtual Background':
                this.gTagService.sendEvent(GA_EVENTS.PERSONALISED_VIRTUAL_BACKGROUND);
                this.gTagService.sendEvent(GA_NEW.PERSONALISED_VIRTUAL_BACKGROUND);
                break;
            case 'View your meeting recording':
                this.gTagService.sendEvent(GA_EVENTS.VIEW_YOUR_MEETING_RECORDING);
                this.gTagService.sendEvent(GA_NEW.VIEW_YOUR_MEETING_RECORDING);
                break;
            case 'Create groups with all user types':
                this.gTagService.sendEvent(GA_EVENTS.CREATE_GROUPS_WITH_ALL_USER_TYPES);
                this.gTagService.sendEvent(GA_NEW.CREATE_GROUPS_WITH_ALL_USER_TYPES);
                break;
            case 'Call/chat by Non- Enterprise users':
                this.gTagService.sendEvent(GA_EVENTS.CALL_CHAT_BY_NON_ENTERPRISE_USER);
                this.gTagService.sendEvent(GA_NEW.CALL_CHAT_BY_NON_ENTERPRISE_USER);
                break;
            case 'Set custom rules for file sharing':
                this.gTagService.sendEvent(GA_EVENTS.SET_CUSTOM_RULES_FOR_FILE_SHARING);
                this.gTagService.sendEvent(GA_NEW.SET_CUSTOM_RULES_FOR_FILE_SHARING);
                break;
            case 'Legacy VC Room through VPN':
                this.gTagService.sendEvent(GA_EVENTS.LEGACY_VC_ROOM_THROUGH_VPN);
                this.gTagService.sendEvent(GA_NEW.LEGACY_VC_ROOM_THROUGH_VPN);
                break;
            case 'More Meeting Participants':
                this.gTagService.sendEvent(GA_EVENTS.MORE_MEETING_PARTICIPANTS);
                this.gTagService.sendEvent(GA_NEW.MORE_MEETING_PARTICIPANTS);
                break;
            case 'JioMeet Bot for Microsoft Teams':
                this.gTagService.sendEvent(GA_EVENTS.JIOMEET_BOT_FOR_MICROSOFT_TEAMS);
                this.gTagService.sendEvent(GA_NEW.JIOMEET_BOT_FOR_MICROSOFT_TEAMS);
                break;
            case 'White<br />Labelling':
                this.gTagService.sendEvent(GA_EVENTS.WHITE_LABELLING);
                this.gTagService.sendEvent(GA_NEW.WHITE_LABELLING);
                break;
            case 'Events only for your organisation':
                this.gTagService.sendEvent(GA_EVENTS.EVENTS_ONLY_FOR_YOUR_ORGANISATION);
                this.gTagService.sendEvent(GA_NEW.EVENTS_ONLY_FOR_YOUR_ORGANISATION);
                break;
            case 'CPaaS<br />Offering':
                this.gTagService.sendEvent(GA_EVENTS.CPASS_OFFERING);
                this.gTagService.sendEvent(GA_NEW.CPASS_OFFERING);
                break;
            case 'MeghRaj certified Data Centers':
                this.gTagService.sendEvent(GA_EVENTS.MEGHRAJ_CERTIFIED_DATACENTERS);
                this.gTagService.sendEvent(GA_NEW.MEGHRAJ_CERTIFIED_DATACENTERS);
                break;
            case 'STQC Certified':
                this.gTagService.sendEvent(GA_EVENTS.STQC_CERTIFIED);
                this.gTagService.sendEvent(GA_NEW.STQC_CERTIFIED);
                break;
            case 'Host upto 10k+ attendees':
                this.gTagService.sendEvent(GA_EVENTS.HOST_UPTO_10K_ATTENDEES);
                break;
            case 'Q&A’s, comments and reactions':
                this.gTagService.sendEvent(GA_EVENTS.QNA_COMMENTS_REACTIONS);
                break;
            case 'Stream on Youtube and Facebook':
                this.gTagService.sendEvent(GA_EVENTS.STREAM_ON_YOUTUBE_FACEBOOK);
                break;
            case 'Customise and brand events':
                this.gTagService.sendEvent(GA_EVENTS.CUSTOMISE_AND_BRAND_EVENTS);
                break;
            case 'Upload and play cloud video':
                this.gTagService.sendEvent(GA_EVENTS.UPLOAD_AND_PLAY_CLOUD_VIDEO);
                break;
            case 'Download event report':
                this.gTagService.sendEvent(GA_EVENTS.DOWNLOAD_EVENT_REPORT);
                break;
            case 'HealthCare Sector':
                this.gTagService.sendEvent(GA_EVENTS.HEALTHCARE_SECTOR);
                break;
            case 'Education Sector':
                this.gTagService.sendEvent(GA_EVENTS.EDUCATION_SECTOR);
                break;
            case 'We’d love to talk about how we can work together.':
                this.gTagService.sendEvent(GA4_EVENTS.CONTACT_US_CONTACT_SALES);
        }
        if (!solutionInfo.path) return;
        if (!solutionInfo.isExternal) this.router.navigate([solutionInfo.path]);
        else
            window.open(
                solutionInfo.path,
                '_blank' // <- This is what makes it open in a new window.
            );
    }
}
