import { Injectable } from '@angular/core';

import { interval, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, retryWhen, throttleTime } from 'rxjs/operators';
import { genericRetryStrategy } from '../helpers/generic-retry-strategy';
import { LoggerService } from './logger.service';
import { dict } from './../../shared';
import io from 'socket.io-client';
import * as _ from 'lodash-es';
import { DesktopAppService } from './desktop-app.service';
import { SocketEvent, SocketConfig, SocketService } from './socket.service';
import { RoomConnectionService } from './room-connection.service';
import { LocalStorageService } from './local-storage.service';
const url = '';

@Injectable({
    providedIn: 'root'
})
export class RdpSocketService {
    public x;
    public y;
    public videoWidth;
    public videoHeight;
    // public userId;
    // public remoteId;
    private socket;
    public userId: any = '123';
    public remoteId: any = '123';
    private mouseMoveEvent;
    private dblClickEvent;
    private keyDownEvent;
    private keyUpEvent;
    private mouseClickEvent;
    private contextMenuEvent;
    private mouseWheelEvent;
    public isRdpActive;
    private listeners;
    public shareParticipantId;
    public controlParticipantId;
    private mouseMoveSubject = new Subject<any>();

    constructor(
        private loggerService: LoggerService,
        private desktopAppService: DesktopAppService,
        private roomConnectionService: RoomConnectionService,
        private socketService: SocketService,
        private localStorageService: LocalStorageService
    ) {
        this.mouseMoveSubject.pipe(throttleTime(100)).subscribe((data) => {
            this.socketService.send({
                event: 'remoteControl',
                data
            });
        });
        //        if (this.desktopAppService.isDesktopApp) this.socket = io.connect(url || '');
    }

    send(data: SocketEvent) {
        try {
            if (this.socket) {
                this.socket.send(JSON.stringify(data));
            }
        } catch (e) {
            if (this.socket) {
                //                this.reconnect();
            }
        }
    }

    startAccessControl({
        jiomeetId,
        shareParticipantId,
        controlParticipantId,
        shareAccepted,
        height,
        width,
        scaleFactor
    }) {
        // this.socket.emit('join', 'User' + uid);
        return this.roomConnectionService.sendShareControl({
            jiomeetId,
            shareParticipantId,
            controlParticipantId,
            shareAccepted,
            height,
            width,
            scaleFactor
        });
        // this.registerWindowSocketEvents(uid, remoteId);
    }

    denyAccessControl({ jiomeetId, shareParticipantId, controlParticipantId, shareAccepted }) {
        // this.socket.emit('join', 'User' + uid);
        return this.roomConnectionService.sendShareControl({
            jiomeetId,
            shareParticipantId,
            controlParticipantId,
            shareAccepted,
            height: 0,
            width: 0,
            scaleFactor: 0
        });
        // this.registerWindowSocketEvents(uid, remoteId);
    }

    stopAccessControl({ jiomeetId, participantId, shareParticipantId }) {
        this.deregisterWindowSocketEvents();
        return this.roomConnectionService.stopShareControl({ jiomeetId, participantId, shareParticipantId });
    }

    deregisterWindowSocketEvents() {
        var screenShareVideo = this.getRemoteVideo();
        if ((this.desktopAppService.isDesktopApp || !this.desktopAppService.isDesktopApp) && screenShareVideo) {
            screenShareVideo.removeEventListener('mousemove', this.mouseMoveEvent, true);
            screenShareVideo.removeEventListener('dblclick', this.dblClickEvent, true);
            window.removeEventListener('keydown', this.keyDownEvent, true);
            window.removeEventListener('keyup', this.keyUpEvent, true);
            screenShareVideo.removeEventListener('click', this.mouseClickEvent, true);
            screenShareVideo.removeEventListener('contextmenu', this.contextMenuEvent, true);
            screenShareVideo.removeEventListener('wheel', this.mouseWheelEvent, true);
        }
    }

    registerWindowSocketEvents(userId, remoteId, jiomeetId, jmUserId) {
        var _this = this;
        var screenSharingSize = this.desktopAppService.screenSize;
        this.mouseMoveEvent = function (e) {
            var x_offset =
                (screenSharingSize?.scaleFactor > 1
                    ? screenSharingSize.width * screenSharingSize.scaleFactor
                    : screenSharingSize.width) || 1920;
            var y_offset =
                (screenSharingSize?.scaleFactor > 1
                    ? screenSharingSize.height * screenSharingSize.scaleFactor
                    : screenSharingSize.height) || 1080;
            var ssVideo = this.getRemoteVideo();
            var positionInfo = ssVideo.getBoundingClientRect();
            var calculateX = x_offset / positionInfo.width;
            var calculateY = y_offset / positionInfo.height;
            var xOffset = calculateX * e.offsetX;
            var yOffset = calculateY * e.offsetY;
            if (screenSharingSize?.scaleFactor >= 2) {
                xOffset = xOffset / screenSharingSize?.scaleFactor;
                yOffset = yOffset / screenSharingSize?.scaleFactor;
            }
            yOffset =
                yOffset > screenSharingSize?.height * screenSharingSize?.scaleFactor - 20
                    ? screenSharingSize?.height * screenSharingSize?.scaleFactor
                    : yOffset < 0
                    ? 0
                    : yOffset;
            var data = {
                controlParticipantId: userId,
                shareParticipantId: remoteId,
                userId: jmUserId,
                jiomeetId,
                event: { x: xOffset, y: yOffset },
                type: 'mousemove',
                screenSharingSize,
                timestamp: new Date()
            };
            if (e) {
                _this.mouseMoveSubject.next(data);
            }
        };

        this.dblClickEvent = function (e) {
            console.log(`AppScreen dblclick : ${e}`);

            e.preventDefault();
            var data = {
                controlParticipantId: userId,
                shareParticipantId: remoteId,
                userId: jmUserId,
                jiomeetId,
                event: { button: e.button },
                timestamp: new Date(),
                type: 'dblclick'
            };
            this.socketService.send({
                event: 'remoteControl',
                data
            });
        };

        this.keyDownEvent = function (e) {
            console.log(userId);
            console.log(`AppScreen keydown : ${e.key}`);
            e.preventDefault();

            let key = e.key;
            if (key.length !== 1 && key !== ' ') {
                key = dict[key];
            }

            console.log(`AppScreen keydown from dict: ${key}`);
            var data = {
                controlParticipantId: userId,
                shareParticipantId: remoteId,
                userId: jmUserId,
                jiomeetId,
                event: { keyCode: key },
                timestamp: new Date(),
                type: 'keydown'
            };
            this.socketService.send({
                event: 'remoteControl',
                data
            });
        };

        this.keyUpEvent = function (e) {
            console.log(userId);
            console.log(`AppScreen keyup : ${e.key}`);
            e.preventDefault();

            let key = e.key;
            if (key.length !== 1 && key !== ' ') {
                key = dict[key];
            }

            console.log(`AppScreen keyup from dict: ${key}`);
            var data = {
                controlParticipantId: userId,
                shareParticipantId: remoteId,
                userId: jmUserId,
                jiomeetId,
                event: { keyCode: key },
                timestamp: new Date(),
                type: 'keyup'
            };
            this.socketService.send({
                event: 'remoteControl',
                data
            });
        };

        this.mouseClickEvent = function (e) {
            console.log(`AppScreen leftClick : ${e}`);
            e.preventDefault();
            let remoteVideo = this.getRemoteVideo();

            var data = {
                controlParticipantId: userId,
                shareParticipantId: remoteId,
                userId: jmUserId,
                jiomeetId,
                event: this.coordsAndSize(e, remoteVideo),
                timestamp: new Date(),
                type: 'leftClick'
            };

            this.socketService.send({
                event: 'remoteControl',
                data
            });
        };

        this.contextMenuEvent = function (e) {
            console.log(`AppScreen rightClick : ${e}`);
            e.preventDefault();

            let remoteVideo = this.getRemoteVideo();
            var data = {
                controlParticipantId: userId,
                shareParticipantId: remoteId,
                userId: jmUserId,
                jiomeetId,
                event: this.coordsAndSize(e, remoteVideo),
                timestamp: new Date(),
                type: 'rightClick'
            };
            this.socketService.send({
                event: 'remoteControl',
                data
            });
        };

        this.mouseWheelEvent = function (e) {
            console.log(`AppScreen entered wheel coordinates x & y: ${e.deltaX} & ${e.deltaY}`);
            var data = {
                controlParticipantId: userId,
                shareParticipantId: remoteId,
                userId: jmUserId,
                jiomeetId,
                event: { x: e.deltaX, y: e.deltaY },
                type: 'wheel',
                timestamp: new Date(),
                screenSharingSize
            };
            this.socketService.send({
                event: 'remoteControl',
                data
            });
            console.log(`AppScreen exited wheel coordinates x & y: ${e.deltaX} & ${e.deltaY}`);
        };

        var screenShareVideo = this.getRemoteVideo();

        screenShareVideo.addEventListener('mousemove', this.mouseMoveEvent.bind(this));

        screenShareVideo.addEventListener('dblclick', this.dblClickEvent.bind(this));

        window.addEventListener('keydown', this.keyDownEvent.bind(this)); // since mouse movement is controlled only inside video container and key board events are controlled based on focus of the cursor

        window.addEventListener('keyup', this.keyUpEvent.bind(this));

        screenShareVideo.addEventListener('click', this.mouseClickEvent.bind(this));

        screenShareVideo.addEventListener('contextmenu', this.contextMenuEvent.bind(this));

        screenShareVideo.addEventListener('wheel', this.mouseWheelEvent.bind(this));
    }

    coordsAndSize(event, video) {
        return {
            x: event.clientX - video.getBoundingClientRect().left,
            y: event.clientY - video.getBoundingClientRect().top,
            videoWidth: video.getBoundingClientRect().width,
            videoHeight: video.getBoundingClientRect().height
        };
    }

    getRemoteVideo() {
        let remoteVideo;
        const screenSharingStreamElement = document.getElementById('screenSharingStream');
        remoteVideo =
            screenSharingStreamElement?.querySelector('.agora_video_player') ||
            screenSharingStreamElement?.querySelector('.jm-media-video-element');
        return remoteVideo;
    }

    requestAccessControl({ jiomeetId, controlParticipantId, shareParticipantId }) {
        return this.roomConnectionService.requestShareControl({ jiomeetId, controlParticipantId, shareParticipantId });
    }
}
