import { IncomingCallPopupComponent } from './incoming-call-popup/incoming-call-popup.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { CustomerFeedbackComponent } from './customer-feedback/customer-feedback.component';
import { JoinMeetingComponent } from './join-meeting/join-meeting.component';
import { JoinCallComponent } from './join-call/join-call.component';
import { HostMeetingComponent } from './host-meeting/host-meeting.component';
import { VerificationComponent } from './verification/verification.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { GuestFormComponent } from './guest-form/guest-form.component';
import { MattermostComponent } from './mattermost/mattermost.component';
import { ActivateComponent } from './activate/activate.component';
import { TncComponent } from './tnc/tnc.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AlreadyVerifiedComponent } from './already-verified/already-verified.component';
import { DownloadsPageComponent } from './downloads-page/downloads-page.component';
import { AgmEventComponent } from './agm-event/agm-event.component';
import { AddToJiomeetComponent } from './add-to-jiomeet/add-to-jiomeet.component';
import { ExternalloginComponent } from './externallogin/externallogin.component';
import { ActivationLinkExpiryComponent } from './activation-link-expiry/activation-link-expiry.component';
import { JoinMeetingTvComponent } from './join-meeting-tv/join-meeting-tv.component';
import { WebsiteComponent } from './website/website.component';
import { DownloadComponent } from './download/download.component';
import { DownloadRecordingsComponent } from './download-recordings/download-recordings.component';
import { OauthComponent } from './oauth/oauth.component';
import { ClusterLoadTestComponent } from './cluster-load-test/cluster-load-test.component';
import { ServerDownComponent } from './server-down/server-down.component';
import { RestrictedMeetingJoinComponent } from './restricted-meeting-join/restricted-meeting-join.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { GetClientComponent } from './get-client/get-client.component';
import { ContactSalesComponent } from './contact-sales/contact-sales.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { RecordingPlayerComponent } from './recording-player/recording-player.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { PricingComponent } from './pricing/pricing.component';
import { RecorderBotComponent } from './recorder-bot/recorder-bot.component';

import * as fromWebsiteComponents from './website';
import { PreLoaderComponent } from './pre-loader/pre-loader.component';
import { DownloadIcsfileComponent } from './download-icsfile/download-icsfile.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { PaymentSuccessFailureComponent } from './payment-success-failure/payment-success-failure.component';
export * from './forgot-password/forgot-password.component';
export * from './login/login.component';
export * from './change-password/change-password.component';
export * from './sign-up/sign-up.component';
export * from './customer-feedback/customer-feedback.component';
export * from './join-meeting/join-meeting.component';
export * from './join-call/join-call.component';
export * from './thankyou/thankyou.component';
export * from './host-meeting/host-meeting.component';
export * from './verification/verification.component';
export * from './reset-password/reset-password.component';
export * from './guest-form/guest-form.component';
export * from './mattermost/mattermost.component';
export * from './activate/activate.component';
export * from './tnc/tnc.component';
export * from './privacy-policy/privacy-policy.component';
export * from './already-verified/already-verified.component';
export * from './downloads-page/downloads-page.component';
export * from './agm-event/agm-event.component';
export * from './add-to-jiomeet/add-to-jiomeet.component';
export * from './externallogin/externallogin.component';
export * from './activation-link-expiry/activation-link-expiry.component';
export * from './join-meeting-tv/join-meeting-tv.component';
export * from './website/website.component';
export * from './website';
export * from './download/download.component';
export * from './download-recordings/download-recordings.component';
export * from './oauth/oauth.component';
export * from './cluster-load-test/cluster-load-test.component';
export * from './server-down/server-down.component';
export * from './restricted-meeting-join/restricted-meeting-join.component';
export * from './onboarding/onboarding.component';
export * from './incoming-call-popup/incoming-call-popup.component';
export * from './get-client/get-client.component';
export * from './pre-loader/pre-loader.component';
export * from './contact-sales/contact-sales.component';
export * from './contact-us/contact-us.component';
export * from './recording-player/recording-player.component';
export * from './delete-account/delete-account.component';
export * from './pricing/pricing.component';
export * from './download-icsfile/download-icsfile.component';
export * from './payment-success-failure/payment-success-failure.component';
export * from './recorder-bot/recorder-bot.component';

export const components = [
    ForgotPasswordComponent,
    LoginComponent,
    ChangePasswordComponent,
    SignUpComponent,
    CustomerFeedbackComponent,
    JoinMeetingComponent,
    JoinCallComponent,
    HostMeetingComponent,
    VerificationComponent,
    ResetPasswordComponent,
    GuestFormComponent,
    MattermostComponent,
    ActivateComponent,
    TncComponent,
    ThankyouComponent,
    PrivacyPolicyComponent,
    AlreadyVerifiedComponent,
    DownloadsPageComponent,
    AgmEventComponent,
    AddToJiomeetComponent,
    ExternalloginComponent,
    ActivationLinkExpiryComponent,
    JoinMeetingTvComponent,
    WebsiteComponent,
    DownloadComponent,
    DownloadRecordingsComponent,
    OauthComponent,
    ClusterLoadTestComponent,
    ServerDownComponent,
    RestrictedMeetingJoinComponent,
    OnboardingComponent,
    IncomingCallPopupComponent,
    GetClientComponent,
    PreLoaderComponent,
    ContactSalesComponent,
    ContactUsComponent,
    RecordingPlayerComponent,
    DeleteAccountComponent,
    PricingComponent,
    DownloadIcsfileComponent,
    PaymentSuccessFailureComponent,
    RecorderBotComponent,
    ...fromWebsiteComponents.components
];
