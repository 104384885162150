import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    Output,
    EventEmitter,
    ComponentRef,
    ComponentFactoryResolver,
    OnDestroy
} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import * as FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { JioCloudUtilService } from 'src/app/core/services/jio-cloud-util.service';
import {
    EventEmitterService,
    AuthService,
    ChatService,
    UserService,
    AppService,
    EventData,
    DesktopAppService,
    UtilService
} from 'src/app/core';
import { JiocloudService } from 'src/app/core/services/jiocloud.service';
import { TranslateService } from '@ngx-translate/core';
import { APP_EVENTS } from 'src/app/constants';
import { FileInfoComponent } from './../file-info/file-info.component';
import { HostDirective } from 'src/app/shared';
import { find } from 'lodash-es';

@Component({
    selector: 'app-chat-attachments',
    templateUrl: './chat-attachments.component.html',
    styleUrls: ['./chat-attachments.component.scss']
})
export class ChatAttachmentsComponent implements OnInit, OnDestroy {
    @Input() item;
    @Input() jiomeetId;
    @Input() message;
    @Input() showAttachments;
    @Input() maxWidth;
    @Output() refresh = new EventEmitter();
    @Output() openPreviewEmit = new EventEmitter();
    thumbnailUrl;
    showPreview = false;
    showFileInfo = false;
    isAuthenticated;
    docType;
    applyClass = '';
    playIcon;
    currentUser;
    @ViewChild('downloadAnchor') downloadAnchor: ElementRef;
    fileInfoComponentRef: ComponentRef<FileInfoComponent>;
    @ViewChild(HostDirective) viewHost: HostDirective;
    subscriptions: Array<Subscription> = [];
    fileType: 'Image' | 'Doc' | 'Video' | 'Audio' | 'None' = 'None';
    isPreviewDownloadEnabled = false;
    previewData = null;
    isJPGFile: boolean = false;
    creatorDataProtection = false;
    constructor(
        private eventEmitterService: EventEmitterService,
        private authService: AuthService,
        public chatService: ChatService,
        public jioCloudService: JiocloudService,
        private confirmationService: ConfirmationService,
        private jioCloudUtilService: JioCloudUtilService,
        private toastrService: ToastrService,
        private userService: UserService,
        private appService: AppService,
        private translateService: TranslateService,
        private compFactory: ComponentFactoryResolver,
        public desktopAppService: DesktopAppService,
        private utilService: UtilService
    ) {
        this.currentUser = this.userService.getUserSync();
        this.isAuthenticated = this.authService.getIsAuthenticated();
        this.isPreviewDownloadEnabled = this.appService.isDesktopApp();
    }
    ngOnInit() {
        if (this.chatService.activeChatMembers?.length > 0) {
            let creator = find(this.chatService.activeChatMembers, ['_id', this.message?.createdBy]);
            if (creator) {
                this.creatorDataProtection = this.utilService.canDownloadOrPreviewAttachments(
                    creator,
                    this.currentUser
                );
            }
        }
        this.isJPGFile = false;
        this.fileType = this.jioCloudUtilService.getFileFormat(this.item.fileName);
        if (this.fileType === 'Doc') {
            this.docType = this.jioCloudUtilService.getDocType(this.item.fileName);
        }
        this.thumbnailUrl = this.jioCloudUtilService.getDefaultThumbnailForFile(this.fileType);
        if (this.showAttachments) {
            this.applyClass = this.userService.isJioCloudEnabled()
                ? 'thumbnailIcon desktop-preview-image'
                : 'thumbnailIcon';
            this.playIcon = 'videoPlayIconSmall';
        } else if (!this.showAttachments && (this.fileType == 'Doc' || this.fileType == 'Audio')) {
            this.applyClass = 'docIcon';
        } else {
            this.applyClass = '';
            this.playIcon = 'videoPlayIcon';
        }
        const extension = this.getFileExtension(this.item.fileName);
        if (extension === 'jpg' || extension === 'jpeg') this.isJPGFile = true;
        this.utilService.emitAttachmentLoaded();
    }
    ngOnDestroy(): void {}
    openPreview() {
        this.eventEmitterService.emit({
            type: APP_EVENTS.SHOW_PREVIEW,
            data: {
                item: this.item,
                chatThreadId: this.jiomeetId,
                isDataProtection: this.creatorDataProtection
            }
        });
    }
    getFileExtension(filename: string): string {
        const parts = filename.split('.');
        if (parts.length > 1) {
            return parts.pop()!.toLowerCase();
        }
        return '';
    }
    deleteItem(item) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete this file?',
            header: 'Delete File',
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            key: 'deleteItem_' + this.item.fileID,
            acceptButtonStyleClass: 'custom-button bg-primary',
            rejectButtonStyleClass: 'custom-button bg-secondary',
            accept: () => {
                this.chatService
                    .deleteFromJioMeetAndJioCloud(
                        this.message.threadId,
                        this.message._id,
                        this.item.fileID,
                        this.jiomeetId
                    )
                    .subscribe((resp) => {
                        this.eventEmitterService.emit({
                            type: APP_EVENTS.REFRESH_CHAT_MESSAGES,
                            data: {}
                        });
                    });
            },
            reject: () => {}
        });
        this.utilService.setTimeOutForAccessibilityPopUp();
    }

    toggleFileInfo(item) {
        this.showFileInfo = !this.showFileInfo;
    }

    downloadItem(item, event?) {
        if (event) {
            event.stopPropagation();
        }
        if (this.creatorDataProtection) {
            this.toastrService.error(this.translateService.instant('tostrs.downloading_a_file_is_not_allowed'));
            return;
        }
        this.toastrService.success(this.translateService.instant('tostrs.downloading_in_progress'));
        this.jioCloudService
            .downloadFile(this.item.fileName, this.item.downloadURL, this.jiomeetId)
            .subscribe((resp) => {
                FileSaver.saveAs(resp, this.item.fileName);
                this.toastrService.success(this.translateService.instant('tostrs.download_completed'));
            });
    }
}
