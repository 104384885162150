<div class="info-banner-popup" [style.top]="yPos">
    <div class="bubble">
        <div class="type">
            <img alt="" src="assets/images/call-info-icon.svg" />
        </div>
        <div class="content">
            <div class="header">
                <div>
                    {{ 'inside_call.call_component.are_you_trying_to_speak' | translate }}
                </div>
            </div>
            <div class="body-bad">
                {{ 'inside_call.call_component.unmute_yourself_if_you_are_speaking' | translate }}
            </div>
        </div>
        <div class="actions-btn">
            <button class="option" [ngClass]="{ mobileDevice: isMobileDevice && isJMMeeting }" (click)="unmuteUser()">
                {{ 'inside_call.call_controls.unmute' | translate }}
            </button>
        </div>
        <div class="close">
            <img
                alt=""
                (click)="closeBanner()"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDQwIDQwIj4KICA8ZyBpZD0iR3JvdXBfMTE5MzAxIiBkYXRhLW5hbWU9Ikdyb3VwIDExOTMwMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTc5MiAtMjIyKSI+CiAgICA8Y2lyY2xlIGlkPSJFbGxpcHNlXzE3NDA4IiBkYXRhLW5hbWU9IkVsbGlwc2UgMTc0MDgiIGN4PSIyMCIgY3k9IjIwIiByPSIyMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNzkyIDIyMikiIGZpbGw9Im5vbmUiLz4KICAgIDxnIGlkPSJHcm91cF8xMTkyODkiIGRhdGEtbmFtZT0iR3JvdXAgMTE5Mjg5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxODI4OC41MDQgMTc0MzAuNTA0KSI+CiAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfMjQ4OTIiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDI0ODkyIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNzQ4OC41MDQgLTE3MjAwLjUwNCkiIGZpbGw9Im5vbmUiLz4KICAgICAgPHBhdGggaWQ9ImNsb3NlIiBkPSJNMTMuOCwxMi4zM2w2LjU2LTYuNTVBMS4wNDYsMS4wNDYsMCwxLDAsMTguODgsNC4zbC02LjU1LDYuNTZMNS43ODEsNC4zQTEuMDQ2LDEuMDQ2LDAsMCwwLDQuMyw1Ljc4MWw2LjU2LDYuNTVMNC4zLDE4Ljg4YTEuMDQ2LDEuMDQ2LDAsMSwwLDEuNDc5LDEuNDc5TDEyLjMzLDEzLjhsNi41NSw2LjU2YTEuMDQ2LDEuMDQ2LDAsMSwwLDEuNDc5LTEuNDc5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3NDg4LjgzNiAtMTcyMDAuODM2KSIgZmlsbD0iIzk3OWNhYSIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg=="
            />
        </div>
    </div>
</div>
