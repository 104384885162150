import { Injectable } from '@angular/core';
import {
    AppService,
    AuthService,
    CallService,
    ChatService,
    ContactsService,
    EventEmitterService,
    GoogleTagManagerService,
    RoomConnectionService,
    RtcService,
    SystemService,
    UserService,
    UtilService,
    VideoWrapperService
} from '../core';
import { map } from 'rxjs/operators';

import { APP_EVENTS, SOCKET_EVENTS, VIDYO_EVENTS } from 'src/app/constants';
import { AppLoggerService } from '../core/services/app-logger.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash-es';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { GA4_EVENTS } from '../components/website/ga4-events';

@Injectable({
    providedIn: 'root'
})
export class LocalHelperServiceService {
    contactsIdForCall = '';
    noVideoDeviceAvailable: boolean = false;
    micPrivacy = false;
    cameraPrivacy = false;
    meetingInfo: any = {};
    callTitle = '';
    transferCall: boolean = false;
    callLoader: boolean = false;
    currentUser;
    videoService;
    noOfUsersRejected = 0;
    roomType = 'dynamic';
    room: any = {
        personal: null,
        dynamic: null
    };
    showCallLoader: BehaviorSubject<any>;
    participantsAddedInGroup: BehaviorSubject<any>;
    participantsRemovedFromGroup: BehaviorSubject<any>;
    callUser;
    callType;
    callEvent;
    localFavouriteContacts = [];

    groupLeft = false;
    contactsToAddInGroup = [];
    contactsToRemoveFromGroup = [];
    vcRoomInfo;
    openVCroom: BehaviorSubject<boolean> = new BehaviorSubject(false);
    setopenVCRoomValue;
    isSearchContactAddedToArray = false;
    calledFromComponent;

    constructor(
        private utilService: UtilService,
        private rtcService: RtcService,
        private videoWrapperService: VideoWrapperService,
        private appService: AppService,
        private systemService: SystemService,
        private authService: AuthService,
        private callService: CallService,
        private roomConnectionService: RoomConnectionService,
        private eventEmitterService: EventEmitterService,
        private appLoggerService: AppLoggerService,
        private toasterService: ToastrService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private chatService: ChatService,
        private router: Router,
        private userService: UserService,
        private contactsService: ContactsService,
        private confirmationService: ConfirmationService,
        private gTagService: GoogleTagManagerService
    ) {
        this.videoService = this.videoWrapperService.getVideoServiceForPreview();
        this.showCallLoader = new BehaviorSubject(this.callLoader);
        this.participantsAddedInGroup = new BehaviorSubject([]);
        this.participantsRemovedFromGroup = new BehaviorSubject([]);
    }

    setCurrentUser() {
        this.currentUser = this.userService.getUserSync();
    }

    setVCroomActivatedTrue(val, info) {
        this.vcRoomInfo = info;
        this.setopenVCRoomValue = val;
        this.openVCroom.next(true);
    }

    exceededCharacterLimitToast(event, max_limit) {
        const inputValue: string = event.target.value;
        const keyPressed: string = event.key;
        const excludedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight'];
        if (!excludedKeys.includes(keyPressed) && inputValue.length === max_limit) {
            this.toastrService.clear();
            this.toastrService.error(this.translateService.instant('tostrs.character_limit_exceeded'));
        }
    }

    setVCroomActivatedFalse(val) {
        this.vcRoomInfo = null;
        this.setopenVCRoomValue = val;
        this.openVCroom.next(false);
    }

    makeGroupCall(contact, type, event, calledFromComponent) {
        this.userService
            .getCallHistory({ offset: null, limit: null, keyword: '' })
            .pipe(map((res) => res.callHistory))
            .subscribe((calls) => {
                let activeCalls = calls.filter(
                    (call) =>
                        call.isActive &&
                        !call?.meetingDetails?.isWebinar &&
                        call?.groupId === (contact?.groupId || contact._id)
                );

                if (activeCalls?.length != 0) {
                    this.router.navigate(['join'], {
                        queryParams: {
                            meetingId: activeCalls[0].jiomeetId,
                            pwd: activeCalls[0].roomPIN
                        }
                    });
                } else {
                    this.setCallInfo(contact, type, event, calledFromComponent);
                }
            });
    }

    setCallInfo(userContact, type, event, calledFromComponent?) {
        this.showCallLoader.next(true);
        this.callUser = userContact;
        this.callType = type;
        this.callEvent = event;
        this.calledFromComponent = calledFromComponent;
    }

    getCallInfo() {
        return {
            callUser: this.callUser,
            callType: this.callType,
            callEvent: this.callEvent,
            calledFromComponent: this.calledFromComponent
        };
    }

    setCallLoaderAsFalse() {
        this.showCallLoader.next(false);
    }

    setFavouriteParticipants(contacts) {
        this.localFavouriteContacts = contacts;
    }

    getFavouriteParticipants() {
        return this.localFavouriteContacts;
    }

    addToLocalFavList(updatedContact) {
        this.localFavouriteContacts.push(updatedContact);
    }

    removeFromLocalFavList(updatedContact) {
        let index = this.localFavouriteContacts.findIndex((contact) => contact._id === updatedContact?._id);
        this.localFavouriteContacts.splice(index, 1);
    }

    openChat(contact, chatOpenedFrom?) {
        this.currentUser = this.userService.getUserSync();
        if (contact?.members?.length > 2) {
            contact['isOneToOne'] = false;
        }
        this.appService.resetContactsInfo();
        let threadId = '';
        if (!contact?.members || contact?.isOneToOne || contact?.groupId === '0') {
            const user = contact;
            if (user?._id === this.currentUser?._id) {
                user._id = user?.userId;
            }
            if (user?._id.substring(0, 4) === 'chis' && user?.members) {
                user.members.forEach((element) => {
                    if (element.userId && element?.userId != this.currentUser?._id) {
                        contact._id = element?.userId;
                        if (!user?.name || !user?.lname) {
                            user['name'] = element.name;
                            user['lname'] = element.lname;
                        }
                    }
                });
            }
            if (user._id === this.currentUser?._id) {
                user._id = user.userId;
            }
            if (user._id.substring(0, 2) != 'u-') {
                user._id = user?.userId;
            }
            this.chatService
                .getPrivateChatThread(
                    [this.currentUser?._id, this.utilService.fetchUserId(user)],
                    this.currentUser?._id,
                    [this.currentUser?._id]
                )
                .subscribe(
                    (data) => {
                        const newUser = {
                            name: user?.name ? user.name : '',
                            lname: user?.lname ? user.lname : '',
                            unreadCount: data.unreadCount ? data.unreadCount : 0,
                            isGroupThread: false,
                            _id: data?._id,
                            isFavourite: false,
                            lastUnReadMessageId: '',
                            members: data?.members,
                            context: data?.context ? data.context : ''
                        };
                        threadId = data?._id;
                        this.appService.setInitChatInfo(newUser);

                        switch (chatOpenedFrom) {
                            case 'recents':
                                this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_RECENT_CHAT_BUTTON);
                                break;
                            case 'groupContact':
                                this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_GROUP_INDIVIDUAL_FROM_LIST_OPEN_CHAT);
                                break;
                            case 'groupDetailed':
                                this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_GROUP_INDIVIDUAL_DETAILED_OPEN_CHAT);
                                break;
                            case 'individualContact':
                                this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_INDIVIDUAL_FROM_LIST_CHAT_CLICK);
                                break;
                            case 'individualContactDetailed':
                                this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_INDIVIDUAL_DETAILED_CHAT_CLICK);
                                break;
                            case 'individualContactOfGroup':
                                this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_INDIVIDUAL_CONTACTS_OF_GROUP_CHAT);
                                break;
                            default:
                                break;
                        }

                        this.eventEmitterService.emit({
                            type: APP_EVENTS.NOTIFY_NEW_CHAT_INIT,
                            data: newUser
                        });
                        this.router.navigate(['/chat/', threadId]);
                    },
                    (err) => {
                        switch (chatOpenedFrom) {
                            case 'recents':
                                this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_RECENT_CHAT_BUTTON_FAILED);
                                break;
                            case 'groupContact':
                                this.gTagService.sendEvent(
                                    GA4_EVENTS.CONTACTS_GROUP_INDIVIDUAL_FROM_LIST_OPEN_CHAT_FAILED
                                );
                                break;
                            case 'groupDetailed':
                                this.gTagService.sendEvent(
                                    GA4_EVENTS.CONTACTS_GROUP_INDIVIDUAL_DETAILED_OPEN_CHAT_FAILED
                                );
                                break;
                            case 'individualContact':
                                this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_INDIVIDUAL_FROM_LIST_CHAT_CLICK_FAILED);
                                break;
                            case 'individualContactDetailed':
                                this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_INDIVIDUAL_DETAILED_CHAT_CLICK_FAILED);
                                break;
                            case 'individualContactOfGroup':
                                this.gTagService.sendEvent(
                                    GA4_EVENTS.CONTACTS_INDIVIDUAL_CONTACTS_OF_GROUP_CHAT_FAILED
                                );
                                break;
                            default:
                                break;
                        }
                        this.appLoggerService.error(
                            'Failed to open individual chat: ',
                            new Error(JSON.stringify(err?.error?.errors)),
                            err
                        );
                    }
                );
        } else if (contact?.members) {
            const members = _.map(contact?.members, 'userId');
            var containsOwnerId;
            members.forEach((element) => {
                if (element === this.currentUser._id) {
                    containsOwnerId = true;
                }
            });
            if (!containsOwnerId) {
                members.unshift(contact?.userId);
            }

            if (contact?._id.substring(0, 4) === 'chis') {
                contact._id = contact?.groupId;
            }
            this.chatService.getPrivateGroupThread(members, contact?._id, [contact?.userId], contact?.title).subscribe(
                (res) => {
                    const newGroup = {
                        name: contact?.title ? contact?.title : '',
                        lname: '',
                        unreadCount: 0,
                        isGroupThread: true,
                        _id: res?._id,
                        isFavourite: false,
                        lastUnReadMessageId: '',
                        members: res?.members ? res.members : [],
                        context: res?.context ? res.context : '',
                        admin: res?.admins[0]
                    };

                    switch (chatOpenedFrom) {
                        case 'recents':
                            this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_RECENT_CHAT_BUTTON);
                            break;
                        case 'groupContact':
                            this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_GROUP_INDIVIDUAL_FROM_LIST_OPEN_CHAT);
                            break;
                        case 'groupDetailed':
                            this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_GROUP_INDIVIDUAL_DETAILED_OPEN_CHAT);
                            break;
                        default:
                            break;
                    }

                    this.appService.setInitChatInfo(newGroup);
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.NOTIFY_NEW_CHAT_INIT,
                        data: newGroup
                    });
                    this.router.navigate(['/chat/', newGroup._id]);
                },
                (err) => {
                    switch (chatOpenedFrom) {
                        case 'recents':
                            this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_RECENT_CHAT_BUTTON_FAILED);
                            break;
                        case 'groupContact':
                            this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_GROUP_INDIVIDUAL_FROM_LIST_OPEN_CHAT_FAILED);
                            break;
                        case 'groupDetailed':
                            this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_GROUP_INDIVIDUAL_DETAILED_OPEN_CHAT_FAILED);
                            break;
                        default:
                            break;
                    }

                    this.appLoggerService.error(
                        'Failed to open group chat: ',
                        new Error(JSON.stringify(err?.error?.errors)),
                        err
                    );
                }
            );
        }
    }

    editGroup(group, title) {
        let memberId = [];
        let previousTitle = '';
        var i = 0;
        group.members.forEach((member) => {
            memberId[i] = member.userId;
            i++;
        });
        previousTitle = group.title;
        if (title.length < 1) {
            this.toasterService.info(
                this.translateService.instant('tostrs.please-enter-atleast-1-character-in-groupName')
            );
        } else {
            group['title'] = title;
            this.contactsService.editGroup(group, memberId).subscribe(
                (res) => {
                    this.toasterService.info(this.translateService.instant('tostrs.group-update'));
                    this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_EDIT_GROUP_NAME);
                },
                (err) => {
                    group['title'] = previousTitle;
                    this.appLoggerService.error(
                        'Failed to edit group title: ',
                        new Error(JSON.stringify(err?.error?.errors)),
                        err
                    );
                    this.toasterService.info(this.translateService.instant('tostrs.group-update-fail'));
                }
            );
        }
    }
    deleteGroup(group) {
        this.contactsService.deleteGroup(group?._id).subscribe(
            (res: any) => {
                this.toasterService.info(this.translateService.instant('tostrs.group-deleted'));
                this.eventEmitterService.emit({ type: APP_EVENTS.CONTACT_DELETE_GROUP, data: res._id });
                this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_DELETE_GROUP);
            },
            (err) => {
                this.appLoggerService.error(
                    'Failed to delete group: ',
                    new Error(JSON.stringify(err?.error?.errors)),
                    err
                );
                this.toasterService.info(this.translateService.instant('tostrs.group-deleted-fail'));
            }
        );
    }

    leaveGroup(group) {
        this.contactsService.leaveGroup(group).subscribe(
            (res) => {
                this.toasterService.info(this.translateService.instant('tostrs.group_left_successfully'));
                this.groupLeft = true;
                this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_LEAVE_GROUP);
                this.eventEmitterService.emit({ type: APP_EVENTS.CONTACT_LEAVE_GROUP, data: null });
            },
            (err) => {
                this.appLoggerService.error(
                    'Failed to leave group: ',
                    new Error(JSON.stringify(err?.error?.errors)),
                    err
                );
                this.toasterService.info(this.translateService.instant('tostrs.failed-to-leave-group'));
            }
        );
    }

    createGroup(groupName, groupParticipants) {
        var participantIds = [];
        var i = 0;
        groupParticipants.forEach((key, value) => {
            participantIds[i] = key.userId || key._id;
            i++;
        });

        participantIds.push(this.currentUser?._id);
        if (groupName.length < 1) {
            this.toasterService.info(
                this.translateService.instant('tostrs.please-enter-atleast-1-character-in-groupName')
            );
        } else if (participantIds.length < 3) {
            this.toasterService.info(this.translateService.instant('tostrs.please-enter-atleast-3-members'));
        } else {
            this.contactsService.createGroup(groupName, participantIds).subscribe(
                (res) => {
                    this.toasterService.info(this.translateService.instant('tostrs.group-created'));
                },
                (err) => {
                    this.appLoggerService.error(
                        'Failed to create group: ',
                        new Error(JSON.stringify(err?.error?.errors)),
                        err
                    );
                    this.toasterService.info(this.translateService.instant('tostrs.group-created-fail'));
                }
            );
        }
    }

    editGroupParticipants(group, memberId) {
        this.contactsService.editGroup(group, memberId).subscribe(
            (res: any) => {
                if (memberId.length > group.members.length) {
                    this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_ADD_MEMBERS_TO_GROUP);
                    this.toasterService.info(this.translateService.instant('tostrs.added-to-group'));
                    this.contactsToAddInGroup = [];
                    let tempIdsList = [];
                    memberId.forEach((memberIds) => {
                        let isPresent = false;
                        let i = 0;
                        group?.members?.forEach((member) => {
                            if (memberIds === member?.userId) {
                                isPresent = true;
                                i = i + 1;
                            }
                        });
                        if (!isPresent) {
                            tempIdsList.push(memberIds);
                        }
                    });

                    let contactDetails;
                    tempIdsList.forEach((member) => {
                        contactDetails = res?.members.filter((x) => x?.userId === member);
                        this.contactsToAddInGroup.push(contactDetails[0]);
                    });

                    this.participantsAddedInGroup.next(this.contactsToAddInGroup);
                } else {
                    this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_REMOVE_MEMBER_FROM_GROUP);
                    this.toasterService.info(this.translateService.instant('tostrs.updated-the-group'));
                    this.contactsToRemoveFromGroup = [];
                    let tempMembersList = [];
                    group?.members?.forEach((member) => {
                        let isPresent = false;
                        let i = 0;
                        memberId.forEach((memberIds) => {
                            if (memberIds === member?.userId) {
                                isPresent = true;
                                i = i + 1;
                            }
                        });
                        if (!isPresent) {
                            tempMembersList.push(member?.userId);
                        }
                    });

                    let updatedGroups = group?.members.filter((member) => {
                        let flag = false;
                        tempMembersList.forEach((element) => {
                            if (element === member?.userId) {
                                flag = true;
                            }
                        });
                        if (!flag) {
                            return member;
                        }
                    });

                    this.participantsRemovedFromGroup.next(updatedGroups);
                }
            },
            (err) => {
                this.appLoggerService.error(
                    'Failed to edit group participants: ',
                    new Error(JSON.stringify(err?.error?.errors)),
                    err
                );
                this.toasterService.info(this.translateService.instant('tostrs.added-to-group-fail'));
            }
        );
    }

    addFavourite(contact, favouriteFromComponent?) {
        var resourceType;
        var updatedContact = contact;
        if (
            !(
                contact?._id.substring(0, 2) === 'vc' ||
                contact?._id.substring(0, 2) === 'u-' ||
                contact?._id.substring(0, 2) === 'g-'
            )
        ) {
            contact._id = contact?.userId;
        }
        if (contact?._id.substring(0, 2) === 'vc') {
            resourceType = 'vc';
        } else if (contact?._id.substring(0, 2) === 'u-') {
            resourceType = 'contact';
        } else if (contact?._id.substring(0, 2) === 'g-') {
            resourceType = 'group';
        }
        this.contactsService.addResourceToFavourites(contact, resourceType).subscribe(
            (res: any) => {
                this.toasterService.info(this.translateService.instant('tostrs.added-to-favourites'));
                switch (favouriteFromComponent) {
                    case 'vcRoomDetailed':
                        this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_ADD_VC_ROOM_FAVOURITE);
                        break;
                    case 'individualContactDetailed':
                        this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_INDIVIDUAL_ADD_TO_FAVOURITE);
                        break;
                    case 'groupContactDetailed':
                        this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_GROUP_ADD_FAVOURITE);
                        break;
                    default:
                        break;
                }
                this.addToLocalFavList(contact);
                updatedContact = res;
            },

            (err) => {
                this.appLoggerService.error(
                    'Failed to add favourite: ',
                    new Error(JSON.stringify(err?.error?.errors)),
                    err
                );
                switch (favouriteFromComponent) {
                    case 'vcRoomDetailed':
                        this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_ADD_VC_ROOM_FAVOURITE_FAILED);
                        break;
                    case 'individualContactDetailed':
                        this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_INDIVIDUAL_ADD_TO_FAVOURITE_FAILED);
                        break;
                    case 'groupContactDetailed':
                        this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_GROUP_ADD_FAVOURITE_FAILED);
                        break;
                    default:
                        break;
                }
                this.toasterService.info(this.translateService.instant('tostrs.added-to-favourites-fail'));
            },
            () => {
                this.eventEmitterService.emit({ type: APP_EVENTS.CONTACT_ADD_FAVOURITE, data: updatedContact });
            }
        );
    }

    removeFavourite(resource, favouriteFromComponent?) {
        var resourceType;
        if (resource?._id.substring(0, 2) === 'vc') {
            resourceType = 'vc';
        } else if (resource?._id.substring(0, 2) === 'u-' || resource?._id.substring(0, 2) === 'c-') {
            resourceType = 'contact';
        } else if (resource?._id.substring(0, 2) === 'g-') {
            resourceType = 'group';
        }
        this.confirmationService.confirm({
            message: this.translateService.instant('tostrs.remove_contact-from_favourites'),
            header: this.translateService.instant('tostrs.remove_from_favourites'),
            acceptLabel: this.translateService.instant('tostrs.yes'),
            rejectLabel: this.translateService.instant('tostrs.no'),
            acceptButtonStyleClass: 'custom-button bg-primary',
            rejectButtonStyleClass: 'custom-button bg-secondary',
            accept: () => {
                this.confirmationService.close();
                this.contactsService.deleteResourceFromFavourites(resource, resourceType).subscribe(
                    (res: any) => {
                        this.toasterService.info(this.translateService.instant('tostrs.removed-from-favourites'));
                        switch (favouriteFromComponent) {
                            case 'vcRoomDetailed':
                                this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_REMOVE_VC_ROOM_FAVOURITE);
                                break;
                            case 'individualContactDetailed':
                                this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_INDIVIDUAL_REMOVE_FROM_FAVOURITE);
                                break;
                            case 'groupContactDetailed':
                                this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_GROUP_REMOVE_FAVOURITE);
                                break;
                            default:
                                break;
                        }
                        this.removeFromLocalFavList(resource);
                        this.eventEmitterService.emit({ type: APP_EVENTS.CONTACT_REMOVE_FAVOURITE, data: res });
                    },
                    (err) => {
                        this.appLoggerService.error(
                            'Failed to remove favourite: ',
                            new Error(JSON.stringify(err?.error?.errors)),
                            err
                        );
                        switch (favouriteFromComponent) {
                            case 'vcRoomDetailed':
                                this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_REMOVE_VC_ROOM_FAVOURITE_FAILED);
                                break;
                            case 'individualContactDetailed':
                                this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_INDIVIDUAL_REMOVE_FROM_FAVOURITE_FAILED);
                                break;
                            case 'groupContactDetailed':
                                this.gTagService.sendEvent(GA4_EVENTS.CONTACTS_GROUP_REMOVE_FAVOURITE_FAILED);
                                break;
                            default:
                                break;
                        }
                        this.toasterService.info(this.translateService.instant('tostrs.removed-from-favourites-fail'));
                    }
                );
            }
        });
        this.utilService.setTimeOutForAccessibilityPopUp();
    }
}
