import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProfilePhotoService } from 'src/app/core';

@Directive({
    selector: '[appVcroomAvatar]'
})
export class VcroomAvatarDirective implements AfterViewInit, OnChanges {
    @Input() name = '';

    constructor(private elRef: ElementRef, private profilePhotoService: ProfilePhotoService) {}
    ngAfterViewInit(): void {
        this.createAvatar();
    }

    createAvatar() {
        if (!this.name) {
            return;
        }

        let result = "<img src='assets/img/contacts/vcroom-icon.svg'>";
        this.elRef.nativeElement.innerHTML = result;
        this.elRef.nativeElement.style.backgroundColor = this.profilePhotoService.getColorForInitials(this.name.trim());
        this.elRef.nativeElement.style.borderRadius = '3px';
        this.elRef.nativeElement.style.height = '100%';
        this.elRef.nativeElement.style.width = '100%';
        this.elRef.nativeElement.style.textAlign = 'center';
        this.elRef.nativeElement.style.display = 'flex';
        this.elRef.nativeElement.style.alignItems = 'center';
        this.elRef.nativeElement.style.justifyContent = 'center';
    }

    ngOnChanges(changes: SimpleChanges) {
        this.name = changes.name.currentValue;
        this.createAvatar();
    }
}
