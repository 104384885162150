import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timeZoneMapping'
})
export class TimeZoneMappingPipe implements PipeTransform {
    transform(value: string): string {
        const timeZoneMapping = {
            'GMT-12': 'AoE',
            'GMT-11': 'SST',
            'GMT-10': 'HST',
            'GMT-9:30': 'MART',
            '-GMT-9': 'AKDT',
            'GMT-8': 'PST',
            'GMT-7': 'MST',
            'GMT-6': 'CST',
            'GMT-5': 'EST',
            'GMT-4': 'AST',
            'GMT-3:30': 'NST',
            'GMT-3': 'BRT',
            'GMT-2': 'FNT',
            'GMT-1': 'CVT',
            'GMT+0': 'UTC',
            'GMT+1': 'CET',
            'GMT+2': 'EET',
            'GMT+3': 'MSK',
            'GMT+3:30': 'IRST',
            'GMT+4': 'GST',
            'GMT+4:30': 'AFT',
            'GMT+5': 'IST',
            'GMT+5:30': 'IST',
            'GMT+5:45': 'NPT',
            'GMT+6': 'BST',
            'GMT+6:30': 'MMT',
            'GMT+7': 'ICT',
            'GMT+8': 'CST',
            'GMT+8:45': 'ACST',
            'GMT+9': 'JST',
            'GMT+9:30': 'ACDT',
            'GMT+10': 'AEST',
            'GMT+10:30': 'ACDT',
            'GMT+11': 'VLAT',
            'GMT+11:30': 'ACDT',
            'GMT+12': 'NZST',
            'GMT+12:45': 'CHADT',
            'GMT+13': 'NZDT',
            'GMT+14': 'LINT'
        };

        return timeZoneMapping[value];
    }
}
