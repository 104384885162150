<div class="audio-settings-container" *ngIf="!isMobileDevice">
    <section class="speaker-section">
        <div class="unit">
            <label aria-label="Speaker setting" tabindex="0" class="speakerfocus">{{
                'user_dashboard_settings.audio.speaker' | translate
            }}</label>
        </div>
        <div class="unit">
            <!-- <span></span> -->
            <button class="custom-button bg-secondary" (click)="testSpeaker()">
                {{
                    (testingControl.speaker
                        ? 'user_dashboard_settings.audio.stop'
                        : 'user_dashboard_settings.audio.test_speaker'
                    ) | translate
                }}
            </button>
            <audio id="testAudio">
                <source src="./assets/audio/call.mp3" type="audio/mpeg" />
            </audio>
            <select #audioOutput (change)="selectSpeaker()" [(ngModel)]="selectedSpeaker">
                <option *ngFor="let speaker of availableAudio?.speakers" [ngValue]="speaker">{{ speaker.name }}</option>
            </select>
        </div>
    </section>
    <section class="mic-section" *ngIf="!(rtcService.isACSMeeting || rtcService.isAgoraMeeting)">
        <div class="unit">
            <span>{{ 'user_dashboard_settings.audio.microphone' | translate }}</span>
        </div>
        <div class="unit">
            <!-- <span></span> -->
            <button class="custom-button bg-secondary" (click)="testMic()">
                {{
                    (testingControl.mic
                        ? 'user_dashboard_settings.audio.stop'
                        : 'user_dashboard_settings.audio.test_mic'
                    ) | translate
                }}
            </button>
            <select [(ngModel)]="selectedMic" (change)="selectMicrophone()">
                <option *ngFor="let mic of availableAudio?.mics" [ngValue]="mic">{{ mic.name }}</option>
            </select>
        </div>
        <div class="unit">
            <span class="input-level">{{ 'user_dashboard_settings.audio.input_level' | translate }}</span>
            <span class="pids-wrapper" [class.disabled]="!testingControl.mic">
                <div #level class="pid"></div>
                <div #level class="pid"></div>
                <div #level class="pid"></div>
                <div #level class="pid"></div>
                <div #level class="pid"></div>
                <div #level class="pid"></div>
                <div #level class="pid"></div>
                <div #level class="pid"></div>
                <div #level class="pid"></div>
                <div #level class="pid"></div>
            </span>
        </div>
        <div class="mic-input mt-5" *ngIf="!this.isGuestUser">
            <div class="checkbox-wrapper">
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="audiosettingscheck"
                        [(ngModel)]="turnOffAudio"
                        (change)="onTurnOffAudio($event)"
                    />
                    <label class="custom-control-label pointer pl-2" for="audiosettingscheck">
                        {{ 'user_dashboard_settings.audio.turn_off_audio' | translate }}
                    </label>
                </div>
            </div>
        </div>
        <div
            class="noise"
            *ngIf="!this.isGuestUser && !!noiseSuppressionEnabled && this.currentUser?.isNoiseCancellationEnabled"
        >
            <div class="checkbox-wrapper">
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="noisesupressioncheck"
                        (keydown.Tab)="setFocusOnElement()"
                        [checked]="enableNoiseSuppression"
                        (change)="onChangeNoiseSuppression($event)"
                    />
                    <label class="custom-control-label pointer pl-2" for="noisesupressioncheck">
                        {{ 'user_dashboard_settings.audio.noise_suppression' | translate }}
                    </label>
                </div>
            </div>
        </div>
    </section>
    <section class="mic-section" *ngIf="rtcService.isACSMeeting || rtcService.isAgoraMeeting">
        <div class="unit d-flex">
            <span>{{ 'user_dashboard_settings.audio.microphone' | translate }}:</span>
            <select [(ngModel)]="selectedMic" (change)="selectMicrophone()">
                <option *ngFor="let mic of availableAudio?.mics" [ngValue]="mic">{{ mic.name }}</option>
            </select>
        </div>
    </section>
    <app-permission-denied *ngIf="showPermissionUI"></app-permission-denied>
</div>

<div class="audio-settings-container" *ngIf="isMobileDevice">
    <section class="speaker-section">
        <div class="unit">
            <span>{{ 'user_dashboard_settings.audio.speaker' | translate }}</span>
            <select #audioOutput (change)="selectSpeaker()" [(ngModel)]="selectedSpeaker">
                <option *ngFor="let speaker of availableAudio?.speakers" [ngValue]="speaker">{{ speaker.name }}</option>
            </select>
        </div>
        <div class="unit">
            <!-- <span></span> -->
            <button class="custom-button bg-secondary" (click)="testSpeaker()">
                {{
                    (testingControl.speaker
                        ? 'user_dashboard_settings.audio.stop'
                        : 'user_dashboard_settings.audio.test_speaker'
                    ) | translate
                }}
            </button>
            <audio id="testAudio">
                <source src="./assets/audio/call.mp3" type="audio/mpeg" />
            </audio>
        </div>
    </section>
    <section class="mic-section" *ngIf="!(rtcService.isACSMeeting || rtcService.isAgoraMeeting)">
        <div *ngIf="isJMMeeting" class="unit">
            <span>{{ 'user_dashboard_settings.audio.microphone' | translate }}</span>
            <button class="custom-button bg-secondary" *ngIf="!isJMMeeting" (click)="testMic()">
                {{
                    (testingControl.mic
                        ? 'user_dashboard_settings.audio.stop'
                        : 'user_dashboard_settings.audio.test_mic'
                    ) | translate
                }}
            </button>
            <select [(ngModel)]="selectedMic" (change)="selectMicrophone()">
                <option *ngFor="let mic of availableAudio?.mics" [ngValue]="mic">{{ mic.name }}</option>
            </select>
        </div>
        <div *ngIf="!isJMMeeting" class="unit">
            <span>{{ 'user_dashboard_settings.audio.microphone' | translate }}</span>
        </div>
        <div *ngIf="!isJMMeeting" class="unit">
            <!-- <span></span> -->
            <button class="custom-button bg-secondary" *ngIf="!isJMMeeting" (click)="testMic()">
                {{
                    (testingControl.mic
                        ? 'user_dashboard_settings.audio.stop'
                        : 'user_dashboard_settings.audio.test_mic'
                    ) | translate
                }}
            </button>
            <select [(ngModel)]="selectedMic" (change)="selectMicrophone()">
                <option *ngFor="let mic of availableAudio?.mics" [ngValue]="mic">{{ mic.name }}</option>
            </select>
        </div>
        <div *ngIf="!isJMMeeting" class="unit">
            <span class="input-level">{{ 'user_dashboard_settings.audio.input_level' | translate }}</span>
            <span class="pids-wrapper" [class.disabled]="!testingControl.mic">
                <div #level class="pid"></div>
                <div #level class="pid"></div>
                <div #level class="pid"></div>
                <div #level class="pid"></div>
                <div #level class="pid"></div>
                <div #level class="pid"></div>
                <div #level class="pid"></div>
                <div #level class="pid"></div>
                <div #level class="pid"></div>
                <div #level class="pid"></div>
            </span>
        </div>
        <div class="mic-input mt-5" *ngIf="!this.isGuestUser">
            <div class="checkbox-wrapper">
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="audiosettingscheck"
                        [(ngModel)]="turnOffAudio"
                        (change)="onTurnOffAudio($event)"
                    />
                    <label class="custom-control-label pointer pl-2" for="audiosettingscheck">
                        {{ 'user_dashboard_settings.audio.turn_off_audio' | translate }}
                    </label>
                </div>
            </div>
        </div>
        <div
            class="noise mt-5"
            *ngIf="
                !this.isGuestUser && !!noiseSuppressionEnabled && this.currentUser?.mediaEnginePreference === 'agora'
            "
        >
            <div class="checkbox-wrapper">
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="noisesupressioncheck"
                        (keydown.Tab)="setFocusOnElement()"
                        [checked]="enableNoiseSuppression"
                        (change)="onChangeNoiseSuppression($event)"
                    />
                    <label class="custom-control-label pointer pl-2" for="noisesupressioncheck">
                        {{ 'user_dashboard_settings.audio.noise_suppression' | translate }}
                    </label>
                </div>
            </div>
        </div>
        <div
            class="ns-container mt-5"
            tabindex="0"
            (keydown.enter)="onChangeNoiseSuppressionAdv($event)"
            aria-label="Enable Noise Suppression"
            *ngIf="rtcService.isNoiseCancellationEnabled"
        >
            <div class="ns-button-container">
                <div class="ns-button-left">
                    {{ 'user_dashboard_settings.audio.noise_suppression' | translate }}
                </div>
                <label class="ns-button-right">
                    <input
                        [class.checked]="appService.getIsNoiseSuppression"
                        type="checkbox"
                        [checked]="appService.getIsNoiseSuppression"
                        (change)="onChangeNoiseSuppressionAdv($event)"
                    />
                    <span class="slider round"></span>
                </label>
            </div>
            <div class="ns-text-container">Choose this option for optimal noise suppression</div>
        </div>
    </section>
    <section class="mic-section" *ngIf="rtcService.isACSMeeting || rtcService.isAgoraMeeting">
        <div class="unit d-flex">
            <span>{{ 'user_dashboard_settings.audio.microphone' | translate }}:</span>
            <select [(ngModel)]="selectedMic" (change)="selectMicrophone()">
                <option *ngFor="let mic of availableAudio?.mics" [ngValue]="mic">{{ mic.name }}</option>
            </select>
        </div>
    </section>
    <app-permission-denied *ngIf="showPermissionUI"></app-permission-denied>
</div>
