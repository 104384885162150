<div class="website-cpaas" id="website-top">
    <section [class.padding-h-0]="isMobile" appForceFullWidth class="full-width">
        <div class="cpaas-top" [class.padding-h-0]="isMobile" [class.width-1366]="!isMobile">
            <div class="left-content" [class.padding-h-16]="isMobile">
                <div class="heading">
                    {{ 'website_platforms.jiomeet' | translate }}
                    <span class="cpaas-highlight-0">{{ 'website_platforms.platform' | translate }}</span>
                </div>
                <div class="desc cpaas-highlight-1">
                    {{ 'website_platforms.top-desc' | translate }}
                </div>
                <div class="action-btns">
                    <button class="custom-action-btn" (click)="goToContactSales(1)">
                        {{ 'website_platforms.contact_sales' | translate }}
                    </button>
                </div>
            </div>
            <div class="right-content">
                <img src="assets/img/cpaas/cpaas-top-mobile.png" *ngIf="isMobile" alt="" />
                <!-- <img src="assets/img/cpaas/cpaas-top.png" *ngIf="!isMobile" alt="" /> -->
            </div>
        </div>
    </section>

    <section class="padding-v-80 website-cpaas-integration">
        <h4 class="heading text-center">{{ 'website_platforms.platform_head' | translate }}</h4>
        <section class="integration-content">
            <div *ngFor="let integration of integrations; let i = index" [class.m-auto]="isMobile">
                <div class="integration-container" (click)="showIntegrationPopUp(integration)">
                    <div [class.img]="integration?.title || integration?.desc" *ngIf="integration?.icon">
                        <img [src]="integration?.icon" alt="integration?.title" />
                    </div>
                    <div class="integration-info">
                        <div class="title" *ngIf="integration?.title">
                            {{ integration?.ttitle | translate }}
                        </div>
                        <div class="desc" [innerHTML]="integration?.tdesc | translate"></div>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="sec-2 sec-150">
        <div class="separator" [class.padding-h-28]="isMobile"></div>
        <div *ngFor="let productFeature of websiteCpaasFeatures; let i = index" class="feature-wrap">
            <app-website-product-features [productFeature]="productFeature" [reverse]="i % 2 == 0">
                <div class="check-list" *ngIf="productFeature?.featureList?.length" [class.padding-h-28]="isMobile">
                    <div *ngFor="let feature of productFeature.featureList">
                        <span class="checkmark"><img src="assets/img/done-tickmark.svg" alt="" /> </span
                        ><span class="text">{{ feature.tlabel | translate }}</span>
                    </div>
                </div>
                <div *ngIf="i === 1" class="platform-logos" [class.padding-h-28]="isMobile">
                    <div class="heading-1">{{ 'website_platforms.build_complete_app' | translate }}</div>
                    <div class="platforms">
                        <img src="assets/img/cpaas/apple-logo.svg" alt="" />
                        <img src="assets/img/cpaas/android-logo.svg" alt="" />
                        <img src="assets/img/cpaas/window-logo.svg" alt="" />
                        <img src="assets/img/cpaas/other-platform-logo.svg" alt="" />
                    </div>
                </div>
                <div
                    class="explore-jiomeet"
                    *ngIf="i === 0"
                    [class.padding-h-28]="isMobile"
                    (click)="goToContactSales(2)"
                >
                    <span class="pointer">{{ 'website_platforms.learn_more' | translate }}</span>
                    <img src="assets/img/cpaas/right-active-arrow.svg" alt="" />
                </div>
            </app-website-product-features>
        </div>
    </section>

    <section class="sec-5 sec-150 website-cpaas-solutions">
        <h4 class="heading-0">{{ 'website_platforms.why_choose_us' | translate }}</h4>
        <section class="solution-content">
            <div *ngFor="let solutionInfo of reasonsToChoose; let i = index">
                <app-website-solution [solutionInfo]="solutionInfo" [class.highlight]="i === 0"></app-website-solution>
            </div>
        </section>
    </section>

    <section
        class="sec-1 padding-v-80"
        [class.padding-h-16]="isMobile"
        appForceFullWidth
        [style.display]="platformBranding?.length == 0 ? 'none' : 'block'"
    >
        <div class="heading-0">{{ 'website_platforms.trusted_by_business' | translate }}</div>
        <div class="partners-list partners">
            <ng-container *ngFor="let partner of platformBranding">
                <img src="{{ partner.path }}" alt="" />
            </ng-container>
        </div>
    </section>

    <section
        class="sec-3 testimonials sec"
        [class.sec-padding-h-44]="isMobile"
        [style.display]="platFormTestimonials?.length == 0 ? 'none' : 'block'"
    >
        <h2 class="heading-0">{{ 'website_platforms.words_from_partners' | translate }}</h2>
        <div class="testimonial-slider" #testimonialSlider>
            <div class="slide" *ngFor="let testimonial of platFormTestimonials">
                <app-website-testimonials [testimonial]="testimonial"></app-website-testimonials>
            </div>
        </div>
    </section>

    <!-- <section class="sec-4 sec" [class.sec-padding-h-44]="isMobile">
    <h3 class="heading-0">{{ 'website_platforms.explore_case_studies' | translate }}</h3>
    <app-website-upcoming-events [upcomingEvents]="cPaasEvents"></app-website-upcoming-events>
  </section> -->

    <section class="got-questions sec-150" [class.sec-padding-h-44]="isMobile">
        <app-explore-meet
            title="{{ 'website_platforms.explore_title' | translate }}"
            desc=""
            contactSalesCpaas="ContactSalesCpaas"
            btnText1="{{ 'website_platforms.contact_sales' | translate }}"
            btnText1Link="/contactsales"
        >
            <span>{{ 'website_home.jiomeet_for_business' | translate }} </span>
            <span class="margin-bottom-0">{{ 'website_home.integrate_jiomeet' | translate }} </span>
            <span class="margin-bottom-0">{{ 'website_home.plan_a_conference' | translate }}</span>
        </app-explore-meet>
    </section>

    <section class="website-content tips-updates" [class.sec-padding-h-44]="isMobile">
        <app-jiomeet-subscribe SubcribePage="productCpass"></app-jiomeet-subscribe>
    </section>

    <div class="popup-component integration-popup" *ngIf="integrationPopupState">
        <div class="bg" (click)="hideIntegrationPopup()"></div>
        <div class="popup">
            <div class="popup-header">
                <h4>{{ integration?.popup_heading | translate }}</h4>
            </div>
            <div class="popup-body">
                <div class="block">
                    <form class="custom-form">
                        <div class="form-group">
                            <ol>
                                <li *ngFor="let process of integration?.processSteps; let i = index">
                                    <span [innerHTML]="process | translate"></span>
                                    <a [routerLink]="['/contactsales']" *ngIf="i === 0">{{
                                        'contact-sales.contact_sales' | translate
                                    }}</a>
                                    <span *ngIf="i === 0"> {{ 'website_platforms.integration.page' | translate }}</span>
                                </li>
                            </ol>
                        </div>
                        <div [class.text-right]="!isMobile" [class.text-center]="isMobile">
                            <button
                                type="button"
                                [class.width-full]="isMobile"
                                class="custom-button bg-secondary"
                                (click)="hideIntegrationPopup()"
                            >
                                {{ 'tostrs.close' | translate }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
