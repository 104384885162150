export const APP_EVENTS = {
    TOGGLE_LOCK: 'TOGGLE_LOCK',
    SELF_VIEW_TOGGLE: 'SELF_VIEW_TOGGLED',
    RESETTIMER: 'RESET',
    AUTHORIZED: 'AUTHORIZED',
    LOGOUT: 'LOGOUT',
    SESSION_EXPIRED: 'SESSION_EXPIRED',
    GUEST_JOIN: 'GUEST_JOIN',
    TOP_NAV_SEARCH_QUERY_CHANGE: 'TOP_NAV_SEARCH_QUERY_CHANGE',
    ACTIVATE_CONFERENCE: 'ACTIVATE_CONFERENCE',
    CONFERENCE_ENDED: 'CONFERENCE_ENDED',
    PARTICIPANT_REFRESH: 'PARTICIPANT_REFRESH',
    ACTIVE_CALL_ON_ANOTHER_DEVICE: 'ACTIVE_CALL_ON_ANOTHER_DEVICE',
    SEARCH_TARGET_CHANGE: 'SEARCH_TARGET_CHANGE',
    EXTENSION_REQUIRED: 'EXTENSION_REQUIRED',
    MEETING_SCHEDULED: 'MEETING_SCHEDULED',
    VIEW_PARTICIPANTS: 'VIEW_PARTICIPANTS',
    HIDE_PARTICIPANTS: 'HIDE_PARTICIPANTS',
    VIEW_EVALUATION: 'VIEW_EVALUATION',
    HIDE_EVALUATION: 'HIDE_EVALUATION',
    SHOW_CHAT: 'SHOW_CHAT',
    HIDE_CHAT: 'HIDE_CHAT',
    SHOW_HOST_CONTROLS: 'SHOW_HOST_CONTROLS',
    HIDE_HOST_CONTROLS: 'HIDE_HOST_CONTROLS',
    SHOW_MORE_OPTIONS: 'SHOW_MORE_OPTIONS',
    HIDE_MORE_OPTIONS: 'HIDE_MORE_OPTIONS',
    SHOW_UNSEEN_COUNT: 'SHOW_UNSEEN_COUNT',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    USER_REQUESTING: 'USER_REQUESTING',
    SHOW_INVITE_POPUP: 'SHOW_INVITE_POPUP',
    SHOW_INVITE_CODE_FORM_POPUP: 'SHOW_INVITE_CODE_FORM_POPUP',
    SHOW_PREVIEW: 'SHOW_PREVIEW',
    SHOW_WAITING_ROOM_POPUP: 'SHOW_WAITING_ROOM_POPUP',
    MEETING_DATA: 'MEETING_DETAILS',
    ACTIVATE_ROOM_SETTINGS: 'ACTIVATE_ROOM_SETTINGS',
    TOGGLE_SELF_VIEW: 'TOGGLE SELF VIEW',
    OPEN_FILE_INFO: 'OPEN_FILE_INFO',
    REFRESH_CHAT_MESSAGES: 'REFRESH_CHAT_MESSAGES',
    REFRESH_BREAKOUT_INFO: 'REFRESH_BREAKOUT_INFO',
    REFRESH_MEETINGS: 'REFRESH_MEETINGS',
    DATE_CHANGE: 'DATE_CHANGE',
    EDIT_MEETING: 'EDIT_MEETING',
    NAME_CHANGED: 'NAME_CHANGED',
    JWT_TOKEN: 'JWT_TOKEN',
    NATIVE_BACK_BUTTON: 'NATIVE_BACK_BUTTON',
    NATIVE_FORWARD_BUTTON: 'NATIVE_FORWARD_BUTTON',
    APP_IN_BACKGROUND: 'APP_IN_BACKGROUND',
    NOTIFY_DECLINE_TO_ALL: 'notifyDeclineToAll',
    NAV_ITEM_CLICKED: 'NAV_ITEM_CLICKED',
    PAGE_SCROLLED: 'PAGE_SCROLLED',
    PAGE_RESIZED: 'PAGE_RESIZED',
    SIGNUP_NAVIGATION: 'SIGNUP_NAVIGATION',
    LOGIN_NAVIGATION: 'LOGIN_NAVIGATION',
    TOGGLE_CAMERA: 'TOGGLE_CAMERA',
    LANGUAGE_CHANGE: 'LANGUAGE_CHANGE',
    UPDATE_QUILL: 'UPDATE_QUILL',
    NOTIFY_UNREAD_AS_READ: 'NOTIFY_UNREAD_AS_READ',
    NOTIFY_NEW_CHAT_INIT: 'NOTIFY_NEW_CHAT_INIT',
    UPDATE_CHAT_THREADS: 'UPDATE_CHAT_THREADS',
    SET_REMOTE_AUDIO: 'SET_REMOTE_AUDIO',
    OPEN_VC_DIALOG: 'OPEN_VC_DIALOG',
    ACTIVE_PIP_MODE: 'ACTIVE_PIP_MODE',
    PLAN_MEETING: 'PLAN_MEETING',
    TAKE_SCREENSHOT: 'TAKE_SCREENSHOT',
    CAPTURE_LOCAL_VIDEO_FEED: 'CAPTURE_LOCAL_VIDEO_FEED',
    ERRORS: {
        CALL_JOIN: 'CALL_JOIN_ERROR',
        NO_CAMERA_PERMISSION: 'no_camera_permission',
        GENERIC_CAMERA_ERROR: 'generic_camera_error',
        NO_AUDIO_PERMISSION: 'no_audio_permission',
        GENERIC_AUDIO_ERROR: 'generic_audio_error'
    },
    GET_UPDATED_USER_DATA: 'GET_UPDATED_USER_DATA',
    INIT_START_LIVE_STREAM: 'INIT_START_LIVE_STREAM',
    INIT_STOP_LIVE_STREAM: 'INIT_STOP_LIVE_STREAM',
    LIVE_STREAM_STARTED: 'LIVE_STREAM_STARTED',
    LIVE_STREAM_STOPPED: 'LIVE_STREAM_STOPPED',
    REFRESH_LIVE_STREAMS: 'REFRESH_LIVE_STREAMS',
    SHOW_PERMISSION_UI: 'SHOW_PERMISSION_UI',
    UPDATE_STREAM_FAILURE: 'UPDATE_STREAM_FAILURE',
    TOGGLE_CAMERA_OFF: 'TOGGLE_CAMERA_OFF',
    SHOW_SCREEN_PERMISSION_UI: 'SHOW_SCREEN_PERMISSION_UI',
    ENABLE_AUDIO_ONLY_MODE: 'ENABLE_AUDIO_ONLY_MODE',
    DISABLE_AUDIO_ONLY_MODE: 'DISABLE_AUDIO_ONLY_MODE',
    SHOW_GOOD_NETWORK_TOAST: 'SHOW_GOOD_NETWORK_TOAST',
    CLIENT_MEETING_DETAILS: 'CLIENT_MEETING_DETAILS',
    CONFIRM_VIRTUAL_BACKGROUND: 'CONFIRM_VIRTUAL_BACKGROUND',
    CLOSE_SETTINGS_MODAL: 'CLOSE_SETTINGS_MODAL',
    INITCHATCALL: 'INITCHATCALL',
    FLIP_CAMERA: 'FLIP_CAMERA',
    SWITCH_CAMERA: 'SWITCH_CAMERA',
    SWITCH_FRONT_CAMERA: 'SWITCH_FRONT_CAMERA',
    SWITCH_BACK_CAMERA: 'SWITCH_BACK_CAMERA',
    JM_MEDIA_JOIN_FAILED: 'JM_MEDIA_JOIN_FAILED',
    JM_MAX_LIMIT_REACHED: 'JM_MAX_LIMIT_REACHED',
    AGORA_MAX_LIMIT_REACHED: 'AGORA_MAX_LIMIT_REACHED',
    DISABLE_HIDE_NON_VIDEO_MESSAGE: 'DISABLE_HIDE_NON_VIDEO_MESSAGE',
    SUB_UNSUB_SCREENSHARE: 'SUB_UNSUB_SCREENSHARE',
    SHOW_ACTIVE_SPEAKER_TOP: 'SHOW_ACTIVE_SPEAKER_TOP',
    UPDATE_REMOTE_PARTICIPANTS: 'UPDATE_REMOTE_PARTICIPANTS',
    CONTACT_LEAVE_GROUP: 'CONTACT_LEAVE_GROUP',
    CONTACT_REMOVE_FAVOURITE: 'CONTACT_REMOVE_FAVOURITE',
    CONTACT_DELETE_GROUP: 'CONTACT_DELETE_GROUP',
    CONTACT_ADD_FAVOURITE: 'CONTACT_ADD_FAVOURITE',
    JM_VIDEO_SUBSCRIBE: 'JM_VIDEO_SUBSCRIBE',
    JM_LARGE_CO_HOST: 'JM_LARGE_CO_HOST',
    SHOW_HIDE_HRHOT_POPUP: 'SHOW_HIDE_HRHOT_POPUP',
    SUBMIT_HRHOT_FEEDBACK: 'SUBMIT_HRHOT_FEEDBACK',
    SHOW_HRHOT_FEEDBACK_CKECK: 'SHOW_HRHOT_FEEDBACK_CKECK',
    JM_PARTICIPANT_BACKGROUND_ACTIVATED: 'JM_PARTICIPANT_BACKGROUND_ACTIVATED',
    JM_PARTICIPANT_BACKGROUND_INACTIVATED: 'JM_PARTICIPANT_BACKGROUND_INACTIVATED',
    JM_SPEAKING_ON_MUTE: 'JM_SPEAKING_ON_MUTE',
    JM_UNMUTE: 'JM_UNMUTE',
    JM_USER_ROLE_UPDATED: 'JM_USER_ROLE_UPDATED',
    JM_MICROPHONE_DEVICE_CHANGES: 'JM_MICROPHONE_DEVICE_CHANGES',
    JM_MICROPHONE_DEVICE_ADDED: 'JM_MICROPHONE_DEVICE_ADDED',
    JM_MICROPHONE_DEVICE_REMOVED: 'JM_MICROPHONE_DEVICE_REMOVED',
    REQUESTING_ACCESS_CONTROL: 'REQUESTING_ACCESS_CONTROL',
    REQUEST_SHARE_CONTROL: 'REQUEST_SHARE_CONTROL',
    REQUEST_SHARE_CONTROL_ERROR: 'REQUEST_SHARE_CONTROL_ERROR',
    START_SHARE_CONTROL: 'START_SHARE_CONTROL',
    STOP_SHARE_CONTROL: 'STOP_SHARE_CONTROL',
    GRANT_SHARE_CONTROL: 'GRANT_SHARE_CONTROL',
    DENY_SHARE_CONTROL: 'DENY_SHARE_CONTROL',
    DENY_SHARE_CONTROL_POPUP: 'DENY_SHARE_CONTROL_POPUP',
    SHARE_PERMISSION_POPUP: 'SHARE_PERMISSION_POPUP',
    SET_DEVICE_PERMISSION: 'SET_DEVICE_PERMISSION',
    STOP_REQUEST_CONTROL_HANDLER: 'STOP_REQUEST_CONTROL_HANDLER',
    INCOMING_CALL: 'INCOMING_CALL',
    LEAVECALLFROMBR: 'LEAVECALLFROMBR',
    FULL_SCREEN_EVENT: 'FULL_SCREEN_EVENT',
    ENTER_BREAKOUTROOM: 'ENTER_BREAKOUTROOM',
    EXIT_BREAKOUTROOM: 'EXIT_BREAKOUTROOM',
    UPDATE_CHECK_EVENT: 'UPDATE_CHECK_EVENT',
    PROFILE_HIDE_EVENT: 'PROFILE_HIDE_EVENT'
};
