import { Injectable } from '@angular/core';

import { AppService } from './app.service';
import { RestService } from './rest.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ParticipantsControlsService {
    private headers: HttpHeaders = new HttpHeaders();

    constructor(private appService: AppService, private restService: RestService) {
        this.headers = this.headers.set('isPortal', 'true');
    }

    getVCParticipants(jiomeetId) {
        return this.restService.post(`api/portal/getparticipants?vc=true`, { jiomeetId }, { headers: this.headers });
    }

    getMeetingParticipantsFromPortal(jiomeetId, { keyword = '', start = 0, limit = 10 }) {
        return this.restService.post(
            `api/portal/getparticipants?limit=${limit}&start=${start}&keyword=${keyword}`,
            { jiomeetId },
            { headers: this.headers }
        );
    }

    getMeetingParticipants(jiomeetId, roomID, roomPin) {
        return this.restService.post(
            `api/roomconnectionstatus/get`,
            { jiomeetId, roomID, roomPin },
            { headers: this.headers }
        );
    }

    softMuteAudioForParticipant(jiomeetId, roomID, participantId, isLegacy) {
        if (isLegacy) {
            return this.restService.post(`api/portal/muteaudio`, {
                jiomeetId,
                participantID: participantId
            });
        }
        return this.restService.post(`api/roomconnectionstatus/control/audio`, {
            jiomeetId,
            roomID,
            participantId,
            audio: false
        });
    }

    hardMuteAudioForParticipant(jiomeetId, roomID, participantId, isLegacy) {
        if (isLegacy) {
            return this.restService.post(
                `api/portal/muteaudio`,
                {
                    jiomeetId,
                    participantID: participantId
                },
                { headers: this.headers }
            );
        }
        return this.restService.post(
            `api/roomconnectionstatus/control/audio`,
            {
                jiomeetId,
                roomID,
                participantId,
                audio: false
            },
            { headers: this.headers }
        );
    }

    hardUnmuteAudioForParticipant(jiomeetId, roomID, participantId, isLegacy) {
        if (isLegacy) {
            return this.restService.post(
                `api/portal/unmuteaudio`,
                {
                    jiomeetId,
                    participantID: participantId
                },
                { headers: this.headers }
            );
        }
        return this.restService.post(
            `api/roomconnectionstatus/control/audio`,
            { jiomeetId, roomID, participantId, audio: true },
            { headers: this.headers }
        );
    }

    softMuteVideoForParticipant(jiomeetId, roomID, participantId, isLegacy) {
        if (isLegacy) {
            return this.restService.post(`api/portal/mutevideo`, {
                jiomeetId,
                participantID: participantId
            });
        }
        return this.restService.post(`api/roomconnectionstatus/control/video`, {
            jiomeetId,
            roomID,
            participantId,
            video: false
        });
    }

    hardMuteVideoForParticipant(jiomeetId, roomID, participantId, isLegacy) {
        if (isLegacy) {
            return this.restService.post(
                `api/portal/mutevideo`,
                {
                    jiomeetId,
                    participantID: participantId
                },
                { headers: this.headers }
            );
        }
        return this.restService.post(
            `api/roomconnectionstatus/control/video`,
            {
                jiomeetId,
                roomID,
                participantId,
                video: false
            },
            { headers: this.headers }
        );
    }

    hardUnmuteVideoForParticipant(jiomeetId, roomID, participantId, isLegacy) {
        if (isLegacy) {
            return this.restService.post(
                `api/portal/unmutevideo`,
                {
                    jiomeetId,
                    participantID: participantId
                },
                { headers: this.headers }
            );
        }
        return this.restService.post(
            `api/roomconnectionstatus/control/video`,
            { jiomeetId, roomID, participantId, video: true },
            { headers: this.headers }
        );
    }

    hardMuteAudioForAll(jiomeetId, roomID) {
        return this.restService.post(
            `api/roomconnectionstatus/control/audioall`,
            {
                jiomeetId,
                roomID,
                hard: true,
                audio: false
            },
            { headers: this.headers }
        );
    }

    hardUnmuteAudioForAll(jiomeetId, roomID) {
        return this.restService.post(
            `api/roomconnectionstatus/control/audioall`,
            { jiomeetId, roomID, hard: true, audio: true },
            { headers: this.headers }
        );
    }

    hardMuteVideoForAll(jiomeetId, roomID) {
        return this.restService.post(
            `api/roomconnectionstatus/control/videoall`,
            {
                jiomeetId,
                roomID,
                hard: true,
                video: false
            },
            { headers: this.headers }
        );
    }

    hardUnmuteVideoForAll(jiomeetId, roomID) {
        return this.restService.post(
            `api/roomconnectionstatus/control/videoall`,
            { jiomeetId, roomID, hard: true, video: true },
            { headers: this.headers }
        );
    }

    softMuteVideoForAll(jiomeetId, roomID) {
        return this.restService.post(
            `api/roomconnectionstatus/control/videoall`,
            {
                jiomeetId,
                roomID,
                hard: false,
                video: false
            },
            { headers: this.headers }
        );
    }

    softUnmuteVideoForAll(jiomeetId, roomID) {
        return this.restService.post(
            `api/roomconnectionstatus/control/videoall`,
            {
                jiomeetId,
                roomID,
                hard: false,
                video: true
            },
            { headers: this.headers }
        );
    }

    softMuteAudioForAll(jiomeetId, roomID) {
        return this.restService.post(
            `api/roomconnectionstatus/control/audioall`,
            {
                jiomeetId,
                roomID,
                hard: false,
                audio: false
            },
            { headers: this.headers }
        );
    }

    softUnmuteAudioForAll(jiomeetId, roomID) {
        return this.restService.post(
            `api/roomconnectionstatus/control/audioall`,
            {
                jiomeetId,
                roomID,
                hard: false,
                audio: true
            },
            { headers: this.headers }
        );
    }

    lockRoom(jiomeetId) {
        return this.restService.post(
            `api/portal/lockroom`,
            {
                jiomeetId
            },
            { headers: this.headers }
        );
    }

    unlockRoom(jiomeetId) {
        return this.restService.post(
            `api/portal/unlockroom`,
            {
                jiomeetId
            },
            { headers: this.headers }
        );
    }

    getLockStatus(jiomeetId) {
        return this.restService.post(
            `api/portal/roomstatus`,
            {
                jiomeetId
            },
            { headers: this.headers }
        );
    }

    removeParticipant(jiomeetId, participantID) {
        return this.restService.post(
            `api/portal/removeparticipant`,
            {
                jiomeetId,
                participantID
            },
            { headers: this.headers }
        );
    }

    removeAllParticipants(jiomeetId) {
        return this.restService.post(
            `api/portal/endall`,
            {
                jiomeetId
            },
            { headers: this.headers }
        );
    }

    startRecording(jiomeetId) {
        return this.restService.post(
            `api/portal/startrec`,
            {
                jiomeetId
            },
            { headers: this.headers }
        );
    }

    stopRecording(jiomeetId) {
        return this.restService.post(
            `api/portal/stoprec`,
            {
                jiomeetId
            },
            { headers: this.headers }
        );
    }

    pauseRecording(jiomeetId) {
        return this.restService.post(
            `api/portal/pauserec`,
            {
                jiomeetId
            },
            { headers: this.headers }
        );
    }

    resumeRecording(jiomeetId) {
        return this.restService.post(
            `api/portal/resumerec`,
            {
                jiomeetId
            },
            { headers: this.headers }
        );
    }

    lowerParticipantHands(jiomeetId, roomID, roomPin, handsDownParticipantIds) {
        return this.restService.post(
            `api/roomConnectionStatus/hosts`,
            {
                jiomeetId,
                roomID,
                roomPin,
                handsDownParticipantIds
            },
            { headers: this.headers }
        );
    }

    moveParticipantsToWaitingRoom(jiomeetId, participants) {
        return this.restService.post(`api/moveToWaitingRoom`, {
            participants,
            jiomeetId
        });
    }

    hostActions({
        jiomeetId,
        roomID,
        roomPin,
        allHandsDown = false,
        coHostsRemoved = [],
        coHostsAdded = [],
        handsDownParticipantIds = []
    }) {
        return this.restService.post(
            `api/roomconnectionstatus/hosts`,
            {
                jiomeetId,
                roomID,
                roomPin,
                allHandsDown,
                coHostsRemoved,
                coHostsAdded,
                handsDownParticipantIds
            },
            { headers: this.headers }
        );
    }
}
