import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_EVENTS } from 'src/app/constants';
import { EventData, EventEmitterService, UtilService, GoogleTagManagerService, AuthService } from 'src/app/core';
import { GA_EVENTS } from '../ga-events';
import { GA_PAGEVIEW } from '../ga-pageview';
import { GA4_EVENTS } from '../ga4-events';

@Component({
    selector: 'app-website-platform-services',
    templateUrl: './website-platform-services.component.html',
    styleUrls: ['../website.usecases.scss']
})
export class WebsitePlatformServicesComponent implements OnInit {
    isMobile = false;
    isAuthenticated: any;
    platformServiceFeatures = [
        {
            title: 'Hiring',
            ttitle: 'website_platform_service.hiring',
            imgMob: 'assets/img/platformService/hiring-mobile.png',
            imgDesktop: 'assets/img/platformService/hiring.png',
            altText: 'Online hiring on jiomeet',
            padding0: true,
            desc: [
                {
                    text: 'Expedite your hiring with JioMeet’s enhanced online recruitment.',
                    ttext: 'website_platform_service.expedite_hiring'
                },
                {
                    text: 'Just turn on the ‘Auto Interviews’ and finalize the initial rounds instantly.',
                    ttext: 'website_platform_service.auto_interviews'
                }
            ]
        },
        {
            title: 'Product Demos',
            ttitle: 'website_platform_service.product_demo',
            imgDesktop: 'assets/img/platformService/product-demo.png',
            imgMob: 'assets/img/platformService/product-demo-mobile.png',
            altText: 'Jiomeet enabling Virtual Product Demos',
            desc: [
                {
                    text: 'JioMeet enables Virtual Product Demos, that promise enhanced reach with a better insight to the customer base. Now, you can add or flaunt creative brochures and customize your video backgrounds in your style. This empowers consumers to access abundant information and make confident purchase decisions.',
                    ttext: 'website_platform_service.virtual_product_demo'
                }
            ]
        },
        {
            title: 'Video KYC',
            ttitle: 'website_platform_service.video_kyc',
            imgDesktop: 'assets/img/platformService/video-kyc.png',
            imgMob: 'assets/img/platformService/video-kyc-mobile.png',
            altText: 'Virtual video KYC',
            desc: [
                {
                    text: 'Designed for customer convenience. We offer Video KYC that onboards customers in real time, with accuracy, security and lower cost.',
                    ttext: 'website_platform_service.video_kyc_realtime_security'
                }
            ]
        },
        {
            title: 'Easy Video Attendance',
            ttitle: 'website_platform_service.easy_video_attendance',
            imgMob: 'assets/img/platformService/video-attendance-mobile.png',
            imgDesktop: 'assets/img/platformService/video-attendance.png',
            altText: 'Simplified Video-Based Online Attendance system ',
            desc: [
                {
                    text: 'The remote work ecosystems have developed smarter ways for marking employee attendance.',
                    ttext: 'website_platform_service.remote_employee_marking'
                },
                {
                    text: 'We bring you simplified Video-Based Online Attendance systems to save time and supervise the payrolls from any time anywhere.',
                    ttext: 'website_platform_service.video_based_online_attendance_system'
                }
            ]
        },
        {
            title: 'Watchparty',
            ttitle: 'website_platform_service.watchparty',
            imgDesktop: 'assets/img/platformService/watchparty.png',
            imgMob: 'assets/img/platformService/watchparty-mobile.png',
            altText: 'Integration of watch party',
            padding0: true,
            desc: [
                {
                    text: 'Integrate watch party in your application to achieve user stickiness to your product.',
                    ttext: 'website_platform_service.user_stickness_to_product'
                },
                {
                    text: 'Enjoy more with your friends',
                    ttext: 'website_platform_service.enjoy_with_friends'
                }
            ]
        },
        {
            title: 'Video Customer Contact Center',
            ttitle: 'website_platform_service.video_customer_contact_center',
            imgDesktop: 'assets/img/platformService/video-customer-center.png',
            imgMob: 'assets/img/platformService/video-customer-center-mobile.png',
            altText: 'Video Customer Contact Center ',
            desc: [
                {
                    text: 'Redefine your contact centre from audio calls to video calls.',
                    ttext: 'website_platform_service.redefine_contact_audio_video'
                },
                {
                    text: 'Customers can explain their problems to (video) physical person than audio.',
                    ttext: 'website_platform_service.easy_explain_video'
                }
            ]
        }
    ];

    constructor(
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private gTagService: GoogleTagManagerService,
        private router: Router,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser(1080);
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 1080 ? false : true;
            }
        });
        this.authService.getIsAuthenticated$().subscribe((authStatus) => {
            this.isAuthenticated = authStatus;
        });
        this.gTagService.pushOnDataLayer(GA_PAGEVIEW.USECASE_PLATFORM_SERVICES);
    }

    async translatePlatformServiceSolutions() {
        const PlatformServiceSolutions = await this.utilService.handleDataTranslation('website_platform_service', {
            PlatformServiceSolutions: this.platformServiceFeatures
        });
        this.platformServiceFeatures = PlatformServiceSolutions['PlatformServiceSolutions'];
    }

    navigateToSignUp() {
        this.gTagService.sendEvent(GA_EVENTS.USECASE_PLATFORM_SERVICES_SIGNUP);
        this.gTagService.sendEvent(GA4_EVENTS.USECASE_PLATFORM_SERVICES_SIGNUP);
        if (this.isAuthenticated) {
            this.router.navigate(['dashboard']);
        } else {
            this.router.navigate(['signup']);
        }
    }

    goToDownloadApp() {
        this.gTagService.sendEvent(GA_EVENTS.USECASE_PLATFORM_SERVICES_DOWNLOAD);
        this.gTagService.sendEvent(GA4_EVENTS.USECASE_PLATFORM_SERVICES_DOWNLOAD);
        this.router.navigate(['jiomeetDownload']);
    }
}
