import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AgoraService } from './agora.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { TelemetryHandlerOptions } from '@microsoft/microsoft-graph-client';
import { LocalStorageService } from './local-storage.service';
import { AppService } from './app.service';

declare let SelfieSegmentation;
declare let Camera;
@Injectable({
    providedIn: 'root'
})
export class VirtualBgService {
    agoraRTC: any;
    videoElement: any;
    canvasElement: any;
    imageElement: any;
    canvasCtx: any;
    camera: any;
    selfieSegmentation: any;
    isCameraRunning: boolean = false;
    isSegmentationLoaded: boolean = false;
    backgroundConfig = {
        type: 'none',
        imageUrl: undefined
    };

    public customImages: any[] = [];
    customImagesSuject = new Subject<any>();

    constructor(
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private localStorageService: LocalStorageService,
        private appService: AppService
    ) {
        this.canvasElement = document.createElement('canvas');
        this.canvasElement.width = 1600;
        this.canvasElement.height = 900;
        this.canvasElement.style.display = 'none';
        this.canvasElement.id = 'video-canvas';
        this.canvasCtx = this.canvasElement.getContext('2d');

        const videoEle = document.createElement('video');
        videoEle.style.display = 'none';
        const imageEle = document.createElement('img');
        this.imageElement = imageEle;
        this.imageElement.style.display = 'none';
        if (this.backgroundConfig.type == 'image' && this.imageElement) {
            this.imageElement.src = this.backgroundConfig.imageUrl;
        }
        this.videoElement = videoEle;
    }

    setBgConfig(config) {
        if (config.type == 'image') {
            if (this.imageElement) {
                this.imageElement.src = config.imageUrl;
                this.backgroundConfig = config;
            }
        } else {
            this.backgroundConfig = config;
        }
    }

    async runCamera(config) {
        if (!this.isCameraRunning) {
            this.camera = new Camera(this.videoElement, {
                onFrame: async () => {
                    if (!this.isSegmentPossible()) {
                        return;
                    }

                    if (this.backgroundConfig.type == 'none') {
                        this.drawVideoOnCanvas();
                    } else {
                        await this.selfieSegmentation.send({ image: this.videoElement });
                    }
                },
                width: config.width,
                height: config.height
            });

            await this.camera.start();
            this.startSegmentation();
            this.isCameraRunning = true;
        }
    }

    drawVideoOnCanvas() {
        this.canvasCtx.drawImage(this.videoElement, 0, 0, this.canvasElement.width, this.canvasElement.height);
    }

    onResults(results) {
        this.canvasCtx.save();
        this.canvasCtx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
        this.canvasCtx.drawImage(results.segmentationMask, 0, 0, this.canvasElement.width, this.canvasElement.height);
        if (this.backgroundConfig.type === 'image') {
            this.drawImageBackground(results);
        } else if (this.backgroundConfig.type === 'blur') {
            this.drawBlurBackground(results);
        } else {
            // simply draw this video
            this.canvasCtx.drawImage(results.image, 0, 0, this.canvasElement.width, this.canvasElement.height);
        }

        this.canvasCtx.restore();
    }

    startSegmentation() {
        if (!this.isSegmentationLoaded) {
            this.selfieSegmentation = new SelfieSegmentation({
                locateFile: (file) => {
                    return `${this.appService.getConfigVariable('VB_LIBRARY_FILE')}${file}`;
                }
            });

            this.selfieSegmentation.setOptions({ modelSelection: 0 });
            this.selfieSegmentation.onResults(this.onResults.bind(this));

            this.isSegmentationLoaded = true;
        }
    }

    drawBlurBackground(results: any) {
        this.canvasCtx.globalCompositeOperation = 'source-out';
        this.canvasCtx.filter = `blur(10px)`;
        this.canvasCtx.drawImage(results.image, 0, 0, this.canvasElement.width, this.canvasElement.height);

        this.canvasCtx.globalCompositeOperation = 'destination-atop';
        this.canvasCtx.filter = 'none';
        this.canvasCtx.drawImage(results.image, 0, 0, this.canvasElement.width, this.canvasElement.height);
    }

    drawImageBackground(results: any) {
        this.canvasCtx.globalCompositeOperation = 'source-out';
        this.canvasCtx.drawImage(this.imageElement, 0, 0, this.canvasElement.width, this.canvasElement.height);

        this.canvasCtx.globalCompositeOperation = 'destination-atop';
        this.canvasCtx.drawImage(results.image, 0, 0, this.canvasElement.width, this.canvasElement.height);
    }

    isSegmentPossible() {
        if (this.videoElement.videoWidth <= 0 || this.videoElement.videoHeight <= 0) {
            return false;
        }
        return true;
    }

    addCustomImages(image) {
        this.customImages.push({ src: image });
        this.loadCustomImages();
    }

    loadCustomImages() {
        this.customImagesSuject.next(this.customImages);
    }

    removeCustomImage(img) {
        this.customImages = this.customImages.filter((image) => image.src !== img.src);
        this.loadCustomImages();
    }

    checkIfImageExixts(img) {
        if (this.customImages.filter((image) => image.src === img).length !== 0) return true;

        return false;
    }

    getCustomImages() {
        return this.customImagesSuject;
    }

    closeSession() {
        this.camera.stop();
        this.isCameraRunning = false;
    }
}
