import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import {
    VideoWrapperService,
    UserService,
    RtcService,
    SystemService,
    AppService,
    DiagnosticService,
    AuthService,
    ExternalInterfaceService,
    GuestAuthService,
    ThirdPartyExternalIntegrationService,
    RoomConnectionService,
    JmMediaService,
    UtilService
} from '.';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { retryBackoff } from 'backoff-rxjs';
import { switchMap } from 'rxjs/operators';
import { EventEmitterService } from './event-emitter.service';
import { APP_EVENTS } from 'src/app/constants';
import { ChatService } from './chat.service';
import { LiveStreamService } from './live-stream.service';
import { LocalStorageService } from './local-storage.service';
@Injectable({
    providedIn: 'root'
})
export class CallService {
    videoService;
    subscriptions: Subscription[];
    currentUser: any;
    meetingObj: any;
    meetingObj$ = new Subject();
    isAgoraMeeting: boolean;
    isJMMeeting: boolean;
    breakoutRoomInfo = this.rtcService.getBreakoutRoomInfo();
    loading = new Subject();
    callPlaced: false;
    isRestrictedMeeting: false;
    isThirdPartyExternalIntegration: any;
    roomStatus: any = {};
    fetchParticipantInterval: NodeJS.Timeout;
    isRecording: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    roomConnectionOnJoinCall = true;
    isRoomSwitched = false;
    isHRHotFeedbackSubmitted: boolean = false;

    constructor(
        private zone: NgZone,
        private videoWrapperService: VideoWrapperService,
        private guestAuthService: GuestAuthService,
        private userService: UserService,
        private authService: AuthService,
        private rtcService: RtcService,
        private systemService: SystemService,
        private localStorageSession: LocalStorageService,
        private appService: AppService,
        private eventEmitterService: EventEmitterService,
        private diagnosticService: DiagnosticService,
        private externalInterfaceService: ExternalInterfaceService,
        private guestUserService: GuestAuthService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        private roomConnectionService: RoomConnectionService,
        private router: Router,
        private chatService: ChatService,
        private jmMediaService: JmMediaService,
        private liveStreamService: LiveStreamService,
        private utilService: UtilService
    ) {}

    initiateCall() {
        this.isThirdPartyExternalIntegration =
            this.thirdPartyExternalIntegrationService.getThirdPartyExternalIntegration();
        this.currentUser = this.userService.getUserSync();
        this.videoService = this.videoWrapperService.getVideoService();
        this.createMeetingObjectAndJoinCall(this.rtcService.getConferenceInfo());
        this.fetchParticipantInterval = setInterval(() => {
            // console.log('hello from the other side');
            // console.log(window.location.href);
            this.roomConnectionService.getMeetingParticipants(
                this.meetingObj.jiomeetId,
                this.meetingObj.room_id,
                this.meetingObj.userPIN
            );
        }, 500);
        this.roomConnectionService.getRoomConnectionStatus$().subscribe((roomStatus) => {
            this.roomStatus = roomStatus;
            if (roomStatus !== null && roomStatus?.localParticipant !== {}) {
                if (
                    roomStatus?.isHardVideoMute &&
                    !this.videoService?.localParticipant.cameraMute &&
                    !(this.roomStatus?.localParticipant?.isHost || this.roomStatus?.localParticipant?.isCohost) &&
                    this.roomConnectionOnJoinCall
                ) {
                    this.muteVideo();
                    this.sendChatJoined();
                    this.roomConnectionOnJoinCall = false;
                }
                this.isRecording.next(roomStatus?.isRecording);
                clearInterval(this.fetchParticipantInterval);
            }
        });
        this.isAgoraMeeting = this.rtcService.getIsAgoraMeeting();
        this.isJMMeeting = this.rtcService.getIsJMMeeting();
        this.videoService.getMeetingObs().subscribe((event) => {
            this.zone.run(() => {
                if (!event) {
                    return;
                }
                this.handleConferenceEvents(event);
            });
        });
        //  this.subscriptions.push(
        //    this.roomConnectionService.getRoomConnectionStatus$().subscribe((roomStatus) => {
        //      if (!roomStatus || roomStatus?.success === false) {
        //        return;
        //      }
        //      this.roomStatus = roomStatus;
        //    })
        //  );
    }

    sendChatJoined() {
        if (this.roomStatus?.participants?.length >= 2) {
            this.chatService.getChatThreadId(this.meetingObj.jiomeetId).subscribe();
        }
    }

    handleConferenceEvents(event: any) {
        switch (event.type) {
            case 'VC_CREATED':
                if (this.currentUser && Object.keys(this.currentUser).length !== 0) {
                    // Update user call status to true
                    this.sendUserEngagedInCall();
                    // Notify other devices where user logged in.
                    this.sendCallJoined();
                }

                break;

            case 'ACTIVE':
                this.rtcService.setIsConferenceActive(true);
                this.appService.setCurrentCallJiomeetID(this.meetingObj.jiomeetId);
                // this.meetingObj.active = true;
                // this.showCallControls = true;
                if (
                    this.meetingObj.isInitiater &&
                    this.currentUser.jiomeetId === this.meetingObj.jiomeetId // host a meeting case
                ) {
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Start a Meeting',
                            eventAction: 'New Meeting Started',
                            eventType: 'app_event',
                            status: 'success'
                        })
                        .subscribe();
                }

                if (this.authService.getIsAuthenticated()) {
                    this.userService.getConferenceDetails(this.meetingObj.historyId).subscribe((res) => {
                        this.meetingObj.isRecordingAllowedForRoom = res?.isRecordingAllowedForRoom;
                        this.videoService.toggleRecordingStatus(res.currentlyRecording);
                    });
                }
                if (this.isAgoraMeeting) {
                    this.externalInterfaceService.sendCallStarted({
                        userId: this.userID(),
                        participantId: this.videoService.localParticipant?.userId
                    });
                } else {
                    this.externalInterfaceService.sendCallStarted(this.userID());
                }
                if (!this.isRoomSwitched) {
                    this.sendConnectionStatus().subscribe(
                        (res) => {
                            this.router.navigate(['conference/call']);
                        },
                        (err) => {
                            // TODO show error message on joining screen?
                            // this.router.navigate(['conference/call']);
                            this.eventEmitterService.emit({
                                type: APP_EVENTS.ERRORS.CALL_JOIN,
                                data: null
                            });
                        }
                    );
                }
                break;
        }
    }

    sendCallJoined(isDisconnect = false) {
        // The below commented lines to be removed once transfer call story is implemented completely.
        const confInfo = this.rtcService.getConferenceInfo();
        const isCallTransferEnabled = this.appService.getConfigVariable('WEB_ADD_TRANSFER_CALL') ?? false;
        if (confInfo?.transferCall || !isCallTransferEnabled) {
            this.rtcService.sendEvent({
                event: 'disconnectActiveCall',
                data: {
                    userId: this.currentUser.userId || this.currentUser._id,
                    sessionId: this.systemService.getSessionID(),
                    uniqueBrowserId: this.systemService.getUniqueBrowserID(),
                    isDisconnect
                }
            });
        }
    }

    sendUserEngagedInCall() {
        this.rtcService
            .userEngagedInCall({
                event: 'userEngagedInCall',
                data: {
                    userId: this.currentUser.userId || this.currentUser._id,
                    historyId: this.meetingObj?.historyId || '',
                    participantEndPointType: this.getParticipantEndPoint(),
                    participantEndPointName: 'web',
                    machineIp: this.systemService.getDeviceUUID()
                }
            })
            .subscribe((res: any) => {
                this.appService.setCallMemberId(res?._id);
            });
    }

    sendConnectionStatus() {
        const localVidyoUser = this.videoService.getLocalParticipant();
        const localParticipant = this.roomConnectionService.getLocalParticipant();

        let payLoad = {
            jiomeetId: this.meetingObj.jiomeetId,
            roomID: this.meetingObj.room_id,
            roomPin: this.meetingObj.userPIN,
            historyId: this.meetingObj.historyId,
            status: 'connected',
            participantUri: localVidyoUser?.id,
            participantId: (localVidyoUser?.userId || '').replace('Guest_', ''),
            participantName: this.rtcService.getIsAgoraMeeting()
                ? this.rtcService.getConferenceInfo().joiningName
                : localVidyoUser?.name,
            micStatus: !localVidyoUser?.microphoneMute,
            cameraStatus: !localVidyoUser?.cameraMute,
            isHandRaise: localParticipant ? localParticipant.isHandRaise : false
        };
        if (this.isAgoraMeeting) {
            payLoad['participantType'] = localVidyoUser?.participantType;
        }
        if (localVidyoUser?.userId) {
            return this.roomConnectionService.updateConnectionStatus(payLoad).pipe(
                switchMap((res: any) => {
                    if (res.success) {
                        return of(res);
                    } else {
                        return throwError(new Error(res));
                    }
                }),
                retryBackoff({
                    initialInterval: 1000,
                    maxRetries: 4,
                    maxInterval: 5000,
                    resetOnSuccess: true
                })
            );
        }
    }

    createMeetingObjectAndJoinCall(conference) {
        const {
            isInitiater,
            joiningName,
            room,
            ownerDetails,
            hideMoreCallControls,
            hideAllCallControls,
            isFullScreenShare,
            incomingCall,
            transferCall,
            host,
            recorderToken
        } = conference;
        this.rtcService.setRoomInfo(room);
        this.rtcService.setIsInitiater(isInitiater);
        this.breakoutRoomInfo = this.rtcService.getBreakoutRoomInfo();
        const callPayload: any = {
            callurl: room.room_url || room.roomurl || room.roomUrl,
            room: room.room_key || room.roomkey || room.roomKey,
            room_id: room.roomID.toString(),
            roomPIN: room.mainRoomPIN || room.vpin || room.roomPIN || room.room_pin,
            userPIN: room.roomPIN || room.room_pin,
            gateway_ip: room.gateway_ip,
            jiomeetId: room?.mainRoomJiomeetId || room.jiomeetId,
            guest_name: joiningName,
            owner_name: ownerDetails.name,
            owner_lname: ownerDetails.lname,
            owner_id: ownerDetails._id,
            owner_emailId: ownerDetails.email,
            owner_tenantId: ownerDetails.tenantId,
            isInitiater,
            participantCount: 0,
            isJoinMeeting: true,
            title: room.topic || '',
            topic: room.topic || '',
            group_id: '0',
            users: this.rtcService.getIsAgoraMeeting() ? [{ userId: this.userID() }] : [],
            is_disconnect: 'false',
            hideMoreCallControls,
            hideAllCallControls,
            isFullScreenShare,
            participantEndPointType: this.getParticipantEndPoint(),
            participantEndPointName: 'web',
            machineIp: this.systemService.getDeviceUUID(),
            historyId: this.getHistoryID(),
            incomingCall,
            transferCall,
            host,
            customlivestream: room?.customlivestream,
            meetingId: room?.meetingId || ''
        };

        if (recorderToken) callPayload['recorderToken'] = recorderToken;
        if (conference?.isLiveStreaming) {
            callPayload['isLiveStreaming'] = true;
        }

        this.liveStreamService.isLiveStreamGoingOn =
            room?.customlivestreamByJiomeetId?.streaming ||
            room?.fblivestreamByJiomeetId?.streaming ||
            room?.ytlivestreamByJiomeetId?.streaming;
        const authInfo = this.authService.getAuthInfo() || this.guestAuthService.getAuthInfo();
        callPayload.token = authInfo.jwt;
        let hostURL = this.appService.getEnvVariable('HOST_URL');

        let cpass = this.localStorageSession.getItem('isPlatform');
        if (cpass) {
            hostURL = `https://${location.hostname}/`;
        }
        setTimeout(() => {
            this.meetingObj = {
                ...callPayload,
                startTime: room.startTime ? new Date(room.startTime) : null,
                endTime: room.endTime ? new Date(room.endTime) : null,
                url: `${hostURL}shortener?meetingId=${
                    room?.mainRoomJiomeetId ? room?.mainRoomJiomeetId : room.jiomeetId
                }&pwd=${room?.mainRoomPIN ? room?.mainRoomPIN : room.roomPIN}`,
                addToJmtUrl: `${hostURL}addtojiomeet?meetingId=${room.jiomeetId}&pwd=${room.roomPIN}&isWebinar=${
                    room.isWebinar || false
                }`,
                legacyConnect: room.legacyConnect,
                jiomeetIdDisplayLabel: this.meetingIdFormat(room.jiomeetId),
                pin: room.roomPIN || ''
            };
            this.meetingObj.jiomeetId = room.jiomeetId;
            this.meetingObj.mainRoomPIN = room.mainRoomPIN || callPayload.userPIN;
            this.meetingObj.mainRoomJiomeetId = room.mainRoomJiomeetId || room.jiomeetId;
            this.meetingObj$.next(this.meetingObj);
            this.roomConnectionService.setMeetingInfo(this.meetingObj);
            this.joinCall(callPayload, !!authInfo.guestUserId);
        }, 50);
    }

    meetingIdFormat(value) {
        if (!value) return '';
        let result;
        if (value?.includes('-')) {
            result = value.replace(/-/g, ' ');
        } else if (!/\s/.test(value)) {
            result = `${value.substring(0, 3)} ${value.substring(3, 6)} ${value.substring(6)}`;
        }

        return result || value;
    }

    joinCall(callPayload: any, isGuestUser) {
        /*if (this.breakoutRoomInfo) {
            return;
        }*/
        this.rtcService.joinCall({ data: callPayload }, isGuestUser).subscribe(
            (res: any) => {
                this.localStorageSession.addItem('cpass', res?.isCpassRoom ? true : false);
                const { historyId, topic } = res;
                this.meetingObj.isScheduledMeeting = res.isScheduledMeeting;
                var meetingHistoryID = this.breakoutRoomInfo?.historyId || historyId;
                this.meetingObj.historyId = meetingHistoryID;
                this.meetingObj.title =
                    topic ||
                    callPayload?.title ||
                    `${this.meetingObj?.owner_name}${
                        this.meetingObj?.owner_lname ? ` ${this.meetingObj?.owner_lname}` : ''
                    }'s JioMeet Meeting`;
                this.appService.setHistoryId(meetingHistoryID);
                this.placeCall();
            },
            (err) => {
                if (
                    err?.error &&
                    err?.error?.customCode === 400 &&
                    (err?.error?.message === 'MEETING_REACHED_MAX_CAPACITY_CONTACT_HOST' ||
                        err?.error?.message === 'MEETING_REACHED_MAX_CAPACITY')
                ) {
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.AGORA_MAX_LIMIT_REACHED,
                        data: err?.error?.errors
                    });
                    return;
                }
                this.placeCall();
            }
        );
    }

    placeCall() {
        if (this.callPlaced) {
            return;
        }

        setTimeout(() => {
            const conference = this.rtcService.getConferenceInfo();
            if (conference.ownerDetails.isHostRight && conference.room.roomStatus === 'Locked') {
                this.joinRoom({ unlockAndJoin: true });
            } else {
                this.joinRoom();
            }
        }, 50);
    }

    joinRoom({ joinRoom = false, unlockAndJoin = false } = {}) {
        const { micPrivacy, cameraPrivacy } = this.getPreferences();
        this.videoService.setJoinData({
            host: this.meetingObj.callurl,
            displayName: this.meetingObj.guest_name,
            roomKey: this.meetingObj.room,
            roomPin: this.meetingObj.roomPIN,
            micPrivacy,
            cameraPrivacy,
            isInitiater: this.meetingObj.isInitiater,
            unlockAndJoin,
            meetingObj: {
                room_id: this.meetingObj?.room_id?.toString(),
                meetingId: this.meetingObj?.meetingId,
                historyId: this.meetingObj?.historyId
            },
            userId: this.userID()
        });
        this.videoService.initializeVidyoConnector();
    }

    getParticipantEndPoint() {
        return this.utilService.fetchPlatformType();
    }

    getPreferences() {
        // Switch Room Case
        const localParticipant = this.videoService.getLocalParticipant();
        const conferenceInfo = this.rtcService.getConferenceInfo();
        this.isRestrictedMeeting = this.rtcService.getRoomInfo()?.advancedOptions?.isRestrictedMeeting;
        if (this.isThirdPartyExternalIntegration) {
            return {
                micPrivacy: this.rtcService.getCameraMicPreference().micPrivacy,
                cameraPrivacy: this.rtcService.getCameraMicPreference().cameraPrivacy
            };
        }

        if (this.roomStatus?.localParticipant) {
            return {
                micPrivacy: this.roomStatus?.localParticipant?.micStatus,
                cameraPrivacy: this.roomStatus?.localParticipant?.cameraStatus
            };
        }
        if (localParticipant) {
            return {
                micPrivacy: !localParticipant.microphoneMute,
                cameraPrivacy: !localParticipant.cameraMute
            };
        }

        if (this.meetingObj?.isAudioMode) {
            return { micPrivacy: true, cameraPrivacy: false };
        }
        const userPreference = this.rtcService.getCameraMicPreference();
        if (this.meetingObj.incomingCall) {
            return {
                micPrivacy: !this.userService.getUserSync()?.microphoneOff,
                cameraPrivacy: !this.userService.getUserSync()?.videoOff
            };
        }
        const room = this.rtcService.getRoomInfo();
        if (this.meetingObj.isInitiater) {
            //mute host if room is hard muted
            if (room?.advancedOptions?.participantHardAudioMute && userPreference?.micPrivacy) {
                userPreference.micPrivacy = false;
            }
            //for host
            return userPreference;
        }
        //for participants
        if (room.advancedOptions) {
            if (room.advancedOptions.participantHardAudioMute === true) {
                userPreference.micPrivacy = false;
            } else if (
                room.advancedOptions.participantAudio === false &&
                !this.rtcService.isHostOrCoHostOfJMLargeMeeting
            ) {
                userPreference.micPrivacy = false;
            }
            if (room.advancedOptions.participantHardVideoMute === true) {
                userPreference.cameraPrivacy = false;
            } else if (room.advancedOptions.participantVideo === false) {
                userPreference.cameraPrivacy = false;
            }
        }
        return userPreference;
    }

    getHistoryID() {
        return this.meetingObj?.historyId || this.breakoutRoomInfo?.historyId;
    }

    userID() {
        return this.currentUser?._id || this.guestUserService.getAuthInfo()?.guestUserId;
    }

    async muteVideo() {
        const participant = this.roomStatus.localParticipant;

        if (this.videoService.processingToggleCamera) return;
        try {
            await this.videoService.toggleCameraPrivacy(true);
        } catch (err) {
            console.log(err);
            return;
        }

        this.roomConnectionService
            .setVideo(this.meetingObj.jiomeetId, this.meetingObj.room_id, participant.participantId, false)
            .subscribe();
    }
}
