<div
    class="modal fade"
    id="preview-modal"
    [ngStyle]="{ display: this.showPreview ? 'block' : 'none', opacity: 1 }"
    tabindex="-1"
    role="dialog"
>
    <div class="modal-header">
        <div class="row headerRow">
            <div class="col-md-6 audioObjectName">
                <img alt="" src="assets/images/defaultAudioIcon.svg" />{{ fileName }}
            </div>
            <div class="col-md-6 preview-opt-cont">
                <div class="row">
                    <div class="col-md-4 mr-3" data-toggle="tooltip" title="Download" (click)="downloadFile()">
                        <img alt="" class="downloadButton pointer" src="assets/images/downloadIcon.svg" />
                    </div>
                    <div class="col-md-4 mr-5" (click)="this.closePreview(false)" data-toggle="tooltip" title="Close">
                        <button class="closeButton pointer">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button class="hide" id="downloadAnchor" #downloadAnchor></button>

    <div class="modal-body" id="myObject">
        <div id="shareView" class="prvwCntnr">
            <div id="audioplayer" class="mediaElement">
                <div id="audioplayerCntnr">
                    <div class="audioCntnr hide">
                        <div class="audioPoster audioScreen d-none d-md-block">
                            <div class="PlaybtnOvrLy hidden-xs"></div>
                            <div class="defaultAudioPoster">
                                <div class="defaultAudioPosterIconCont">
                                    <span class="NeueAudio"></span>
                                </div>
                            </div>

                            <div class="audioScreen">
                                <div id="screenId" class="icon-audioScreenPlay">
                                    <i class="NeuePlayFilled" (click)="playCurrentAudio()"></i>
                                </div>
                            </div>
                        </div>
                        <div id="audio_player" class="shareAudio"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
