<div class="agm-component" *ngIf="showBanner">
    <div class="desktop">
        <div class="banner">
            <img alt="" src="assets/img/AGM_Banner.png" *ngIf="!showJoin" />
            <img alt="" class="pointer" src="assets/img/AGM_Banner_Join.jpg" *ngIf="showJoin" (click)="navToEvent()" />
            <div class="close-icon pointer" (click)="close()">
                <img alt="" src="assets/img/close_banner.svg" />
            </div>
        </div>
    </div>
    <div class="mobile">
        <div class="banner">
            <img alt="" src="assets/img/AGM_Banner_mobile.png" *ngIf="!showJoin" />
            <img
                alt=""
                class="pointer"
                src="assets/img/AGM_Banner_mobile_join.png"
                *ngIf="showJoin"
                (click)="navToEvent()"
            />
            <div class="close-icon pointer" (click)="close()">
                <img alt="" src="assets/img/close_banner.svg" />
            </div>
        </div>
    </div>
</div>
