<div class="attachment rounded py-2">
    <div class="upload-txt">
        <div class="thumbnail pointer">
            <div
                *ngIf="fileType == 'Image'"
                title="{{ item.fileName }}"
                class="pt-2 pr-5"
                class="image-file"
                [class.showAttachment]="showAttachments"
            >
                <div *ngIf="isAuthenticated && isPreviewDownloadEnabled">
                    <img
                        alt=""
                        class="{{ applyClass }} pointer"
                        [attr.src]="
                            item?.imageTrancodedURL
                                ? (item?.imageTrancodedURL | jioCloudIageAuth : jiomeetId : 's' | async)
                                : thumbnailUrl
                        "
                    />
                </div>
                <div *ngIf="!isAuthenticated || !isPreviewDownloadEnabled">
                    <img alt="" class="defaultIcon pointer" src="assets/images/default-img.svg" />
                </div>
            </div>
            <div *ngIf="fileType == 'Video'" title="{{ item.fileName }}">
                <div *ngIf="isAuthenticated && isPreviewDownloadEnabled">
                    <img alt="" class="{{ playIcon }}" src="assets/images/videoPlayIcon.svg" />
                    <img
                        alt=""
                        class="{{ applyClass }} pointer"
                        [attr.src]="
                            item?.imageTrancodedURL
                                ? (item?.imageTrancodedURL | jioCloudIageAuth : jiomeetId : 's' | async)
                                : thumbnailUrl
                        "
                    />
                </div>
                <div *ngIf="!isAuthenticated || !isPreviewDownloadEnabled">
                    <img alt="" class="defaultIcon pointer" src="assets/images/defaultVideoIconForUploading.svg" />
                </div>
            </div>
            <div
                *ngIf="fileType == 'Audio'"
                title="{{ item.fileName }}"
                class="p-3 bg-white"
                class="audio-file"
                [class.showAttachment]="showAttachments"
            >
                <img alt="" class="{{ applyClass }} pointer" src="assets/images/defaultAudioIconlarge.svg" />
            </div>
            <div
                *ngIf="fileType == 'Doc'"
                title="{{ item.fileName }}"
                class="p-3 bg-white"
                class="doc-file"
                [class.showAttachment]="showAttachments"
            >
                <img
                    alt=""
                    class="{{ applyClass }} pointer"
                    *ngIf="docType == 'Word'"
                    src="assets/images/defaultWordIcon.svg"
                />
                <img
                    alt=""
                    class="{{ applyClass }} pointer"
                    *ngIf="docType == 'Excel'"
                    src="assets/images/defaultExcelicon.svg"
                />
                <img
                    alt=""
                    class="{{ applyClass }} pointer"
                    *ngIf="docType == 'PPT'"
                    src="assets/images/defaultGraphIcon.svg"
                />
                <img
                    alt=""
                    class="{{ applyClass }} pointer"
                    *ngIf="docType == 'PDF'"
                    src="assets/images/defaultDocsIcon.svg"
                />
                <img
                    alt=""
                    class="{{ applyClass }} pointer"
                    *ngIf="docType == 'TXT'"
                    src="assets/images/default_text_icon.svg"
                />
                <img
                    alt=""
                    class="{{ applyClass }} pointer"
                    *ngIf="docType == 'Other'"
                    src="assets/images/defaultDocsIcon.svg"
                />
            </div>
        </div>
        <div class="objectName d-flex ml-3" *ngIf="showAttachments">
            <div class="w-75">
                {{ item?.fileName | trimFileName }}
                <div class="detailsOfSharedPerson">
                    Shared by {{ message?.creatorsName }} {{ message?.creatorsLName }} on
                    {{ message?.cOn | date : 'dd-MM-yyyy' }}
                </div>
            </div>
            <div class="fileAttachmentsOptions w-25" *ngIf="showAttachments">
                <div class="action-icons">
                    <img
                        alt=""
                        title="Delete"
                        class="deleteIcon mr-2 pointer"
                        src="assets/images/deletIcon.svg"
                        (click)="deleteItem(item)"
                        *ngIf="currentUser?._id === message?.createdBy"
                    />
                    <img
                        alt=""
                        title="Download"
                        class="downloadIcon pointer"
                        src="assets/images/downloadIcon.svg"
                        (click)="downloadItem(item, $event)"
                    />
                </div>
            </div>
        </div>
    </div>

    <button class="hide" id="downloadAnchor" #downloadAnchor></button>
</div>
