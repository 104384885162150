import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { map, tap } from 'rxjs/operators';
import { differenceInMinutes } from 'date-fns';

import { RestService } from './rest.service';
import { UserService } from './user.service';
import { SocketService } from './socket.service';

import { MAIL_TEMPLATES } from 'src/app/constants/mail-templates';
import { SOCKET_EVENTS } from 'src/app/constants';
import { SystemService } from './system.service';
import { AppService } from './app.service';
import { LocalStorageService } from './local-storage.service';
@Injectable({
    providedIn: 'root'
})
export class RtcService {
    private room;
    private breakoutRoomInfo;
    private isConferenceActive;
    private isInitiater = false;
    private conferenceInfo;
    private chimeAudioEnabledForHost = false;
    private cameraMicUserPreference = {
        cameraPrivacy: false,
        micPrivacy: false
    };
    isJMMeeting = false;
    isACSMeeting = false;
    isJanusMeeting = false;
    isCreateACSMeeting = true;
    isAgoraMeeting = false;
    isPreparatorySetupDone = false;
    isPexipMeeting = false;
    hostOrCoHostOfMeeting = false;
    isCurrentUserCohost = false;
    participantCount = 0;
    participantCountOnJoin = 0;
    isNoiseCancellationInJoinPage = false;
    private incomingCallObject: any = null;

    constructor(
        private datePipe: DatePipe,
        private restService: RestService,
        private socketService: SocketService,
        private userService: UserService,
        private systemService: SystemService,
        private appService: AppService,
        private localStorageService: LocalStorageService,
        private http: HttpClient
    ) {}

    getConferenceInfo() {
        return this.conferenceInfo;
    }

    setConferenceInfo(info) {
        this.conferenceInfo = info;
        this.isPreparatorySetupDone = true;
        this.setIsAgoraMeeting(false);
        this.setIsJMMeeting(false);
        if (info?.room?.mediaEngine === 'agora') {
            this.setIsAgoraMeeting(true);
        }
        if (info?.room?.mediaEngine === 'jm-media') {
            this.setIsJMMeeting(true);
        }
    }

    getCameraMicPreference() {
        return this.cameraMicUserPreference;
    }

    setCameraMicPreference(cameraPrivacy, micPrivacy) {
        this.cameraMicUserPreference = {
            cameraPrivacy,
            micPrivacy
        };
    }

    setIsConferenceActive(bool) {
        this.isConferenceActive = bool;
    }

    getIsConferenceActive() {
        return this.isConferenceActive;
    }

    setIsInitiater(bool) {
        this.isInitiater = bool;
    }

    getIsInitiater() {
        return this.isInitiater;
    }

    sendEvent(event) {
        this.socketService.send(event);
    }

    makeCall(payload) {
        return this.restService.post(`api/call`, payload);
    }

    joinCall(payload, isGuestUser = false) {
        return this.restService.post(`api/joinCall${isGuestUser ? '/guest' : ''}`, payload);
    }

    userEngagedInCall(payload) {
        return this.restService.post(`api/userEngagedInCall`, payload);
    }

    setRoomInfo(room) {
        this.room = room;
    }

    getRoomInfo() {
        return this.room;
    }

    setIsCurrentUserCohost(bool: boolean) {
        this.isCurrentUserCohost = bool;
    }

    getIsCurrentUserCohost() {
        return this.isCurrentUserCohost;
    }

    getIsEvalutationEnabledRoom() {
        return (
            this.room?.advancedOptions?.isEnableFeedBackForm &&
            this.room?.advancedOptions?.feedbackFormIntegration == 'hrhot'
        );
    }

    getIsCurrentUserCanEvaluate() {
        return this.getIsCurrentUserCohost() && this.getIsEvalutationEnabledRoom();
    }

    get largeMeetingThreshold() {
        return this.appService.getConfigVariable('LARGE_MEETING_THRESHOLD');
    }

    get isJMLargeMeetingSupported() {
        return this.room?.advancedOptions?.isLargeParticipants && this.isJMMeeting;
    }

    get isJMLargeMeeting() {
        return (
            this.isJMLargeMeetingSupported &&
            this.participantCountOnJoin > this.largeMeetingThreshold &&
            this.participantCount > this.largeMeetingThreshold
        );
    }

    set isHostOrCoHostOfMeeting(hostOrCoHostOfMeeting: boolean) {
        this.hostOrCoHostOfMeeting = hostOrCoHostOfMeeting;
    }

    get isHostOrCoHostOfMeeting() {
        return this.hostOrCoHostOfMeeting;
    }

    get isHostOrCoHostOfJMLargeMeeting() {
        return this.isJMLargeMeeting && this.hostOrCoHostOfMeeting;
    }

    get isGuestOfJMLargeMeeting() {
        return this.isJMLargeMeeting && !this.hostOrCoHostOfMeeting;
    }

    get isNoiseCancellationEnabled() {
        return (
            this.isNoiseCancellationInJoinPage ||
            (this.room?.advancedOptions?.isNoiseCancellationEnabled && this.isJMMeeting)
        );
    }

    setBreakoutRoomInfo(info) {
        this.breakoutRoomInfo = info;
    }

    setPreparatorySetupDone(state: boolean) {
        this.isPreparatorySetupDone = state;
    }

    getBreakoutRoomInfo() {
        return this.breakoutRoomInfo;
    }

    getPreparatorySetupDone() {
        return this.isPreparatorySetupDone;
    }

    getCallShareUrl(room) {
        let payload = {
            roomID: (room.roomid || room.roomID).toString(),

            room_url: room.roomurl || room.room_url,
            extension: (room.extension || '').toString(),
            gateway_ip: room.gatewayip || room.gateway_ip || room.gatewayIp,
            jiomeetId: room.jiomeetId
        };
        if (room.roomkey || room.room_key) {
            payload['room_key'] = room.roomkey || room.room_key;
        }
        return this.restService.post(`api/shorturl/getshareurlhash`, payload);
    }

    isValidRoomAndPinFlag({ extension = '', hash = '', userId = '' }) {
        const payload: any = {
            ...(extension && { extension }),
            ...(hash && { hash }),
            ...(userId && { userId })
        };
        return this.restService.post(`api/shorturl/roomdetails/ispin`, payload);
    }

    getRoom(usePersonal: boolean) {
        return this.restService.post(`api/getroom`, { usePersonal });
    }

    getMeetingType({ jiomeetId = '', pin = '' }) {
        const payload: any = {
            ...(jiomeetId && { jiomeetId }),
            ...(pin && { pin })
        };
        return this.restService.post(`api/meeting/isWebinar`, payload);
    }

    getRoomDetailsGuest({
        extension = '',
        pin = '',
        hash = '',
        memberName = '',
        userId = '',
        isAuthenticated = false,
        captchaText = '',
        captchaId = '',
        recorderToken = '',
        breakoutpoll = false,
        roomID = null,
        transferCall = false,
        deviceType = this.systemService.getClientDeviceType(),
        noBlock = true,
        jmmediaWebpageStreamInfo = null,
        isLiveStreaming = false
    }) {
        const payload: any = {
            ...(extension && { extension }),
            ...(pin && { pin }),
            ...(hash && { hash }),
            ...(memberName && { memberName }),
            ...(userId && { userId }),
            ...(roomID && { roomID }), // breakoutpoll case,
            deviceType,
            transferCall,
            ...(jmmediaWebpageStreamInfo && { jmmediaWebpageStreamInfo }),
            ...(recorderToken && { recorderToken }),
            ...(isLiveStreaming && { isLiveStreaming })
        };

        let headers: HttpHeaders = new HttpHeaders();
        if (captchaId && captchaText) {
            headers = headers.set('captcha-id', captchaId);
            headers = headers.set('captcha-text', captchaText);
        }

        const queryParams =
            breakoutpoll && noBlock
                ? '?breakoutpoll=true&blockWaitingRoomSocket=true'
                : breakoutpoll
                ? '?breakoutpoll=true'
                : noBlock
                ? '?blockWaitingRoomSocket=true'
                : '';

        return this.restService.post(
            `api/shorturl/roomdetails${isAuthenticated ? '/host' : ''}${queryParams}`,
            payload,
            { headers }
        );
    }

    joinWithTV({ meetingHash, memberName, extension, pin }) {
        return this.restService.post(`api/shorturl/joinwithtv`, {
            meetingHash,
            memberName,
            extension,
            pin
        });
    }

    decodeUrl(payload) {
        return this.restService.post(`api/shorturl/decode`, payload);
    }

    getInvitation(
        userName,
        topic,
        startTime,
        endTime,
        meetingURL,
        meetingId,
        password,
        addToJmtUrl,
        legacyConnect,
        vpin,
        participants
    ) {
        let hostUrl = this.appService.getEnvVariable('HOST_URL');
        return MAIL_TEMPLATES.INVITATION({
            userName,
            topic,
            startTime: startTime ? this.datePipe.transform(new Date(startTime), 'EEEE, MMMM d HH:mm a') : null,
            duration: startTime && endTime ? differenceInMinutes(endTime, startTime) : null,
            meetingURL,
            meetingId,
            password,
            addToJmtUrl,
            legacyConnect,
            hostUrl,
            vpin,
            participants
        });
    }

    inviteVC(payload) {
        return this.restService.post(`api/vc/invite`, payload);
    }

    leaveRoom(payload, guest = false) {
        return this.restService.post(`api/leaveall${guest ? '/guest' : ''}`, payload);
    }

    endParticipant(participantID) {
        return this.restService.post(`api/endparticipant`, {
            roomID: '' + (this.room.roomid || this.room.roomID || this.room.room_id),
            participantID
            // meetingId
        });
    }

    toggleLegacyVideo(participantID, isMute) {
        return this.restService.post(`api/stopVideo`, {
            roomID: '' + (this.room.roomid || this.room.roomID || this.room.room_id),
            participantID,
            mute: isMute
            // meetingId
        });
    }

    toggleLegacyAudio(participantID, isMute) {
        return this.restService.post(`api/muteAudio`, {
            roomID: '' + (this.room.roomid || this.room.roomID || this.room.room_id),
            participantID,
            mute: isMute
            // meetingId
        });
    }

    getParticipantsDetails(roomID) {
        return this.restService
            .post(`api/getparticipantsdetails`, {
                roomID: '' + roomID
            })
            .pipe(
                map((res: any) => res.Entity),
                map((entities) => (Array.isArray(entities) ? entities : [entities]))
            );
    }

    lockRoom(payload) {
        return this.restService.post(`api/lock-room`, payload);
    }

    unlockRoom(payload) {
        return this.restService.post(`api/unlock-room`, payload);
    }

    toggleChimeAudio() {
        this.chimeAudioEnabledForHost = !this.chimeAudioEnabledForHost;
    }

    isChimeAudioEnabledForHost() {
        return this.chimeAudioEnabledForHost;
    }

    getActiveCallDetails() {
        return this.restService.get(`api/calls/inAnActiveCall`);
    }

    toggletRecording(bool, historyId) {
        return this.restService.post(`api/recording/${bool ? 'start' : 'stop'}`, {
            historyId
        });
    }

    startRecording(historyId, jmMediaRecordingData?: any) {
        return this.restService.post(`api/recording/start`, {
            historyId,
            jmMediaRecordingData
        });
    }

    stopRecording(historyId, jmMediaRecordingData?: any) {
        return this.restService.post(`api/recording/stop`, {
            historyId,
            jmMediaRecordingData
        });
    }

    dispose() {
        this.chimeAudioEnabledForHost = false;
        this.isConferenceActive = false;
        this.breakoutRoomInfo = null;
        this.cameraMicUserPreference = {
            cameraPrivacy: false,
            micPrivacy: false
        };
    }

    getWhiteboardUrl(jiomeetId) {
        return this.restService.get(`api/rooms/${jiomeetId}/whiteboard`);
    }

    startWhiteboard(jiomeetId, guest = false) {
        return this.restService.post(`api/rooms/${jiomeetId}/whiteboard/startsharing${guest ? '/guest' : ''}`, {});
    }
    setIsJMMeeting(isJMMeeting: boolean) {
        this.isJMMeeting = isJMMeeting;
    }

    getIsJMMeeting() {
        return this.isJMMeeting;
    }

    setIsACSMeeting(isACSMeeting: boolean) {
        this.isACSMeeting = isACSMeeting;
    }

    getIsACSMeeting() {
        return this.isACSMeeting;
    }

    setIsJanusMeeting(val: boolean) {
        this.isJanusMeeting = val;
    }

    getIsJanusMeeting() {
        return this.isJanusMeeting;
    }

    setCreateACSMeeting(isCreateACSMeeting: boolean) {
        this.isCreateACSMeeting = isCreateACSMeeting;
    }

    setIsAgoraMeeting(isAgoraMeeting: boolean) {
        this.isAgoraMeeting = isAgoraMeeting;
    }

    setIsPexipMeeting(isPexipMeeting: boolean) {
        this.isPexipMeeting = isPexipMeeting;
    }

    getIsPexipMeeting() {
        return this.isPexipMeeting;
    }

    getIsAgoraMeeting() {
        return this.isAgoraMeeting;
    }

    getCreateACSMeeting() {
        return this.isCreateACSMeeting;
    }

    getIsAgoraOrJmMeeting() {
        return this.isAgoraMeeting || this.isJMMeeting;
    }

    sendCallAcceptNotification() {
        const meeting = this.incomingCallObject;
        const user = this.userService.getUserSync();
        this.setIncomingCallMeeting(null);
        return this.restService.post('api/callAccept', {
            event: SOCKET_EVENTS.CALL_ACCEPT,
            data: {
                userIds: [meeting.owner_id, user._id],
                userId: user._id,
                status: 'true',
                historyId: meeting.historyId
            }
        });
    }

    sendCallRejectNotification() {
        const meeting = this.incomingCallObject;
        const user = this.userService.getUserSync();
        this.setIncomingCallMeeting(null);
        return this.restService.post('api/generic', {
            event: SOCKET_EVENTS.GENERIC,
            data: {
                eventType: SOCKET_EVENTS.CALL_CUT_NOTIFICATION,
                userIds: [meeting.owner_id, user._id],
                userId: user._id,
                ownId: user._id,
                roomKey: meeting.room,
                name: `${user.name} ${user.lname}`,
                phoneNo: user.phoneNo || '0123456789',
                historyId: meeting.historyId
            }
        });
    }

    sendCallNotAnsweredNotification() {
        const meeting = this.incomingCallObject;
        const user = this.userService.getUserSync();
        this.setIncomingCallMeeting(null);
        return this.restService.post('api/generic', {
            event: SOCKET_EVENTS.GENERIC,
            data: {
                eventType: SOCKET_EVENTS.CALL_NOT_ANSWERED_NOTIFICATION,
                userIds: [meeting.owner_id, user._id],
                userId: user._id,
                ownId: user._id,
                roomKey: meeting.room,
                name: `${user.name} ${user.lname}`,
                phoneNo: user.phoneNo || '0123456789',
                historyId: meeting.historyId
            }
        });
    }

    sendInAnotherCallNotification(callObj?) {
        const meeting = callObj || this.incomingCallObject;
        const user = this.userService.getUserSync();
        if (!callObj) {
            this.setIncomingCallMeeting(null);
        }
        return this.restService.post('api/generic', {
            event: SOCKET_EVENTS.GENERIC,
            data: {
                eventType: SOCKET_EVENTS.IN_ANOTHER_CALL_NOTIFICATION,
                userIds: [meeting.owner_id, user._id],
                userId: user._id,
                ownId: user._id,
                roomKey: meeting.room,
                name: `${user.name} ${user.lname}`,
                phoneNo: user.phoneNo || '0123456789',
                historyId: meeting.historyId
            }
        });
    }

    sendCallDropNotification(data) {
        return this.restService.post('api/generic', {
            event: SOCKET_EVENTS.GENERIC,
            data: data
        });
    }

    setIncomingCallMeeting(meeting) {
        this.incomingCallObject = meeting;
    }

    getIncomingCallMeeting() {
        return this.incomingCallObject;
    }

    getHrHotFeedbackForm(meetId, userId) {
        return this.restService.post('api/hrHotPlatform/fetchFeedbackForm', { jiomeetId: meetId, userId: userId });
    }

    saveHrHotFeedbackForm(data) {
        return this.restService.post('api/hrHotPlatform/saveFeedbackForm', data);
    }
}
