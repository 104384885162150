import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AppService, SocketService, UtilService, UserService } from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONSTANT, USER_STATUS, HELP_MENU } from 'src/app/constants';

@Component({
    selector: 'app-profile-menu-options',
    templateUrl: './profile-menu-options.component.html',
    styleUrls: ['./profile-menu-options.component.scss']
})
export class ProfileMenuOptionsComponent implements OnInit, AfterViewInit {
    currentStatus;
    userStatus = this.appService.getUserAvailability();
    showStatusMenu = false;
    showHelpMenu = false;
    userStatusMenu = USER_STATUS;
    helpMenu = HELP_MENU;
    constructor(
        private appService: AppService,
        private elementRef: ElementRef,
        private translate: TranslateService,
        private utilService: UtilService,
        private userService: UserService
    ) {}
    helpMenuXPos: string = '-190px';
    isMobile = false;
    statusAccordianImage = 'assets/img/arrow_down_icn.svg';
    helpAccordianImage = 'assets/img/arrow_down_icn.svg';

    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser();
    }

    ngAfterViewInit(): void {
        this.toggleStatus(this.userStatus, false);
        this.showStatusMenu = false;
        this.showHelpMenu = false;
        this.checkMyStatusListener();
        this.closeProfileSettingsMenuListener();
    }

    openSettingsPopup() {
        this.showStatusMenu = false;
        this.showHelpMenu = false;
        this.appService.openSettings(true);
        this.setFocusOnElement('settingheader');
        this.toggleAccordianImg();
    }

    toggleStatusMenu(event) {
        event.stopPropagation();
        this.showStatusMenu = !this.showStatusMenu;
        this.showHelpMenu = false;
        this.toggleAccordianImg();
    }

    toggleAccordianImg() {
        this.statusAccordianImage = this.showStatusMenu ? 'assets/img/arrow_up.svg' : 'assets/img/arrow_down_icn.svg';
        this.helpAccordianImage = this.showHelpMenu ? 'assets/img/arrow_up.svg' : 'assets/img/arrow_down_icn.svg';
    }

    toggleHelpMenu(event) {
        event.stopPropagation();
        this.showHelpMenu = !this.showHelpMenu;
        this.showStatusMenu = false;
        this.toggleAccordianImg();
    }

    avoidEventHandling(event) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
    }

    onclickHelp(event, menu) {
        this.avoidEventHandling(event);
        switch (menu) {
            case APP_CONSTANT.FAQ:
                this.navigateToFAQ();
                break;
            case APP_CONSTANT.PRIVACY:
                this.navigateToPrivacyPolicy();
                break;
            case APP_CONSTANT.TERMSANDCONDITION:
                this.navigateToTermsAndCondition();
                break;
        }
        this.showHelpMenu = false;
        this.toggleAccordianImg();
    }

    navigateToFAQ() {
        const url = this.appService.getEnvVariable('HOST_URL') + 'webhelp';
        window.open(url.toString(), '_blank');
    }

    navigateToPrivacyPolicy() {
        const url = this.appService.getEnvVariable('HOST_URL') + 'privacy-policy-business';
        window.open(url.toString(), '_blank');
    }

    navigateToTermsAndCondition() {
        const url = this.appService.getEnvVariable('HOST_URL') + 'terms-condition-business';
        window.open(url.toString(), '_blank');
    }

    onclickStatus(event, status) {
        this.avoidEventHandling(event);
        this.toggleStatus(status, true);
        this.toggleAccordianImg();
    }

    toggleStatus(status, toServer) {
        switch (status) {
            case APP_CONSTANT.AVAILABLE:
                this.currentStatus = this.translate.instant('landing_or_home_page.header.available');
                break;
            case APP_CONSTANT.AWAY:
                this.currentStatus = this.translate.instant('landing_or_home_page.header.away');
                break;
            case APP_CONSTANT.BUSY:
                this.currentStatus = this.translate.instant('landing_or_home_page.header.busy');
                break;
        }
        this.appService.setUserAvailability(status);
        if (toServer) {
            this.sendStatusToServer(status);
        }
        this.userStatus = status;
        this.showStatusMenu = false;
    }

    sendStatusToServer(status) {
        this.userService.setUserStatusService(status).subscribe((res: any) => {
            if (res.success === false) {
                return;
            }
        });
    }

    checkMyStatusListener() {
        this.appService.changeMyStatus.subscribe((status) => {
            this.toggleStatus(status, false);
            var lang = this.appService.getAndSaveUserPreferredLang();
            this.helpMenuXPos = lang === 'en' ? '-190px' : '-150px';
        });
    }

    closeProfileSettingsMenuListener() {
        this.appService.closeProfileMenu.subscribe((status) => {
            this.showStatusMenu = false;
            this.showHelpMenu = false;
            this.toggleAccordianImg();
        });
    }

    setFocusOnElement(focusElement) {
        setTimeout(() => {
            const element: any = document.getElementsByClassName(focusElement)[0];
            if (element) {
                element?.focus();
            }
        }, 100);
    }
}
