import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { th } from 'date-fns/locale';
import {
    EventData,
    EventEmitterService,
    UtilService,
    GoogleTagManagerService,
    AuthService,
    DesktopAppService,
    DesktopAppUpdaterService,
    AppService,
    RtcService
} from 'src/app/core';

@Component({
    selector: 'app-electron-update',
    templateUrl: './electron-update.component.html',
    styleUrls: ['./electron-update.component.scss']
})
export class ElectronUpdateComponent implements OnInit {
    public showLoader: Boolean = false;
    public showProgressLoader: Boolean = false;
    public downloadAvailable: any;
    public isDownloadInProgress: Boolean = false;
    public checkingForUpdates: Boolean = false;
    public isDownloadComplete: Boolean = true;
    public downloadingProgress: any = false;
    public isDownloadAvailable: any = false;
    public downloadProgressComplete;
    public downloadProgress = 0;
    public updateCheckStatus: Boolean = true;
    private forceUpgrade = false;
    @Output() close: EventEmitter<any> = new EventEmitter();
    constructor(
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private router: Router,
        private gTagService: GoogleTagManagerService,
        private authService: AuthService,
        public desktopAppService: DesktopAppService,
        public desktopAppUpdaterService: DesktopAppUpdaterService,
        private appService: AppService,
        public rtcService: RtcService
    ) {}

    ngOnInit(): void {
        this.showLoader = true;
        this.isDownloadComplete = false;
        this.desktopAppService.startCheckForUpdates();
        let supportedVersion =
            this.appService
                .getConfigVariable('SupportedVersions')
                ?.includes(this.desktopAppService.electronAppVersion) || false;
        let forceUpdateFromConfig = this.appService.getConfigVariable('forceUpgrade') || false;
        this.forceUpgrade = forceUpdateFromConfig || !supportedVersion;
        this.checkingForUpdates = this.desktopAppService.updateCheckStatus((event, obj) => {
            console.log('Checking for updates' + JSON.stringify(obj));
            this.updateCheckStatus = obj.checkingUpdate;
            if (!this.updateCheckStatus) {
                this.isDownloadInProgress = false;
            }
            return obj;
        });
        this.desktopAppService.updateAvailable((event, obj) => {
            this.showLoader = false;
            console.log('update status' + JSON.stringify(obj));
            this.isDownloadAvailable = obj.updateAvailable;
            if (this.forceUpgrade && this.isDownloadAvailable && !this.rtcService.getIsConferenceActive()) {
                this.download();
            }
            return obj.updateAvailable;
        });
    }

    download() {
        this.desktopAppService.downloadUpdate();
        this.downloadingProgress = this.desktopAppService.fetchDownloadProgress((event, obj) => {
            console.log('downloading in progress' + JSON.stringify(obj));
            this.showProgressLoader = true;
            this.isDownloadInProgress = true;
            if (obj.progress == 100) {
                this.isDownloadInProgress = false;
                this.showProgressLoader = false;
                this.isDownloadComplete = true;
            }
            this.downloadProgress = Math.round(obj.progress);
            return obj;
        });
        this.downloadProgressComplete = this.desktopAppService.downloadProgressComplete((event, obj) => {
            this.isDownloadInProgress = false;
            this.showProgressLoader = false;
            this.isDownloadComplete = true;
            console.log('download complete' + JSON.stringify(obj));
            return obj;
        });
    }

    cancel() {
        this.desktopAppUpdaterService.showElectronUpdatePopup = !this.desktopAppService.isDesktopApp;
        this.close.emit();
    }

    installAppUpdate() {
        this.desktopAppService.installAppUpdate({});
    }
}
