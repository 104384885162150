<div class="incoming-call-popup">
    <!-- <div id="incoming-call-popup-bg" class="bg"></div> -->
    <div #incomingCallPopup class="popup p-3" [class.gradient-bg]="isCallEnabledFromChat" appDraggable>
        <div class="popup-header">
            <div class="logo" [class.adjust-size]="isCallEnabledFromChat">
                <img alt="" draggable="false" *ngIf="!isCallEnabledFromChat" src="assets/img/logo/jm-logo-blue.svg" />
                <img
                    alt=""
                    draggable="false"
                    *ngIf="isCallEnabledFromChat"
                    src="assets/img/website/website_logo_header_light.svg"
                />
            </div>
            <div class="minimize-call" *ngIf="isCallEnabledFromChat" (click)="toggleRinger()">
                <img alt="" #speakerIcon draggable="false" src="assets/img/icon-Speaker.svg" />
            </div>
        </div>
        <div class="popup-body">
            <div class="avatar" [class.no-animation]="isCallEnabledFromChat">
                <div class="lottie-container" *ngIf="isCallEnabledFromChat">
                    <app-lottie-renderer path="assets/lottie/call_ripple.json"></app-lottie-renderer>
                </div>
                <div
                    class="circle"
                    [class.avtar-circle]="isCallEnabledFromChat"
                    appAvatarWithInitials
                    [name]="meeting?.owner"
                    [style]="'circle'"
                ></div>
            </div>
            <div *ngIf="!isCallEnabledFromChat" class="name text-truncate">{{ meeting?.owner }}</div>
            <div *ngIf="!isCallEnabledFromChat" class="text">{{ 'incoming_call.is_calling' | translate }}</div>
            <div *ngIf="isCallEnabledFromChat" class="calling-text">
                <div>{{ meeting?.owner }}</div>
                <div>{{ callerMeetingInfo }}</div>
            </div>
        </div>
        <div class="popup-footer text-center">
            <button
                type="button"
                *ngIf="!isCallEnabledFromChat"
                class="custom-button bg-primary mr-3 decline"
                (click)="declineCall()"
            >
                <span class="mr-2">
                    <img alt="" draggable="false" src="assets/img/call_decline_icn.svg" />
                </span>
                <span>{{ 'incoming_call.decline' | translate }}</span>
            </button>
            <button
                type="button"
                *ngIf="!isCallEnabledFromChat"
                class="custom-button bg-primary accept"
                (click)="acceptCall()"
            >
                <span class="mr-2">
                    <img alt="" draggable="false" src="assets/img/call_accept_icn.svg" />
                </span>
                <span>{{ 'incoming_call.accept' | translate }}</span>
            </button>

            <button
                type="button"
                *ngIf="isCallEnabledFromChat"
                class="small-btn accept-call"
                (click)="acceptCall('video')"
            >
                <img alt="" draggable="false" src="assets/img/call_accept_video.svg" />
            </button>
            <button
                type="button"
                *ngIf="isCallEnabledFromChat"
                class="small-btn accept-call"
                (click)="acceptCall('audio')"
            >
                <img alt="" draggable="false" src="assets/img/call_accept_audio.svg" />
            </button>
            <button type="button" *ngIf="isCallEnabledFromChat" class="small-btn decline" (click)="declineCall()">
                <img alt="" draggable="false" src="assets/img/call_decline.svg" />
            </button>
        </div>
    </div>
</div>
