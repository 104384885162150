<div class="page container-fluid">
    <div class="row h-100">
        <div class="left-content col-xl-7 col-lg-7 col-md-12 col-sm-12">
            <div class="img-wrap">
                <app-lottie-renderer path="assets/lottie/Page Animation.json"></app-lottie-renderer>
            </div>
        </div>
        <div class="right-content col-xl-5 col-lg-5 col-md-12 col-sm-12 py-5">
            <div class="component-wrap pb-3">
                <div class="form-wrap">
                    <h3>{{ 'reset-password.reset_password' | translate }}</h3>
                    <div class="sub-title" *ngIf="toggleUI">
                        {{ 'reset-password.set_new' | translate }}
                    </div>
                    <form [formGroup]="changePasswordForm" (submit)="submit()" class="custom-form">
                        <div *ngIf="toggleUI">
                            <div class="form-group my-4">
                                <label>Password</label>
                                <input
                                    [type]="showPassword ? 'text' : 'password'"
                                    class="form-control text-left"
                                    id="password"
                                    formControlName="password"
                                    [class.is-invalid]="errors.password"
                                    maxlength="20"
                                />
                                <div class="eye position-relative">
                                    <img
                                        alt=""
                                        src="{{
                                            showPassword ? 'assets/img/icon-hide.svg' : 'assets/img/icon-show.svg'
                                        }}"
                                        (click)="togglePassword()"
                                        class="eye"
                                    />
                                </div>
                            </div>
                            <ng-container *ngIf="changePasswordForm.value.password">
                                <div class="password-hint">
                                    <p class="password-info">
                                        {{ 'password_criteria.must_have' | translate }}
                                    </p>
                                    <ul>
                                        <li
                                            [ngClass]="{
                                                'is-invalid': errors?.password?.includes('lengthError'),
                                                valid: !errors?.password?.includes('lengthError')
                                            }"
                                        >
                                            {{ 'password_criteria.characters' | translate: txParams?.characters }}
                                        </li>
                                        <li
                                            [ngClass]="{
                                                'is-invalid': errors?.password?.includes('alphabetError'),
                                                valid: !errors?.password?.includes('alphabetError')
                                            }"
                                        >
                                            {{ 'password_criteria.letter' | translate: txParams?.alphabets }}
                                        </li>
                                        <li
                                            [ngClass]="{
                                                'is-invalid': errors?.password?.includes('numberError'),
                                                valid: !errors?.password?.includes('numberError')
                                            }"
                                        >
                                            {{ 'password_criteria.number' | translate: txParams?.numbers }}
                                        </li>
                                        <li
                                            [ngClass]="{
                                                'is-invalid': errors?.password?.includes('dualError'),
                                                valid: !errors?.password?.includes('dualError')
                                            }"
                                        >
                                            {{ 'password_criteria.case' | translate }}
                                        </li>
                                        <li
                                            [ngClass]="{
                                                'is-invalid': errors?.password?.includes('specialCharsError'),
                                                valid: !errors?.password?.includes('specialCharsError')
                                            }"
                                        >
                                            {{ 'password_criteria.symbol' | translate }}
                                        </li>
                                    </ul>
                                    <!-- <p class="password-info">
                    Password must NOT:
                  </p>
                  <ul>
                    <li [ngClass]="{'is-invalid': errors?.password?.includes('repeatingError')}">
                      Contain only one character (111111111 or aaaaaaaa)
                    </li>
                    <li [ngClass]="{'is-invalid': errors?.password?.includes('consecutiveError')}">
                      Contain only consecutive characters (12345678 or abcdefgh)
                    </li>
                  </ul> -->
                                </div>
                                <div class="form-group mt-3">
                                    <label>Confirm Password</label>
                                    <input
                                        type="password"
                                        class="form-control text-left"
                                        id="confirmPassword"
                                        formControlName="confirmPassword"
                                        [class.is-invalid]="errors.confirmPassword"
                                        maxlength="20"
                                    />
                                    <div class="text-error" *ngIf="errors.confirmPassword">
                                        {{ errors.confirmPassword }}
                                    </div>
                                </div>
                            </ng-container>
                            <div class="text-error" *ngIf="errors.global">
                                {{ errors.global }}
                            </div>
                            <button
                                class="custom-button bg-primary mt-3"
                                type="submit"
                                [class.disabled]="!validPassword || !changePasswordForm.valid"
                            >
                                {{ 'reset-password.save' | translate }}
                            </button>
                        </div>
                        <div *ngIf="!toggleUI">
                            <div class="invite-msg mt-5 mb-5 text-center">
                                {{ 'reset-password.changed' | translate }}
                            </div>
                            <button class="custom-button bg-primary mt-3" (click)="navigateToMeetings()" type="button">
                                {{ 'reset-password.proceed' | translate }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
