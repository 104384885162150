import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

import * as Flickity from 'flickity';
import { GoogleTagManagerService, UtilService } from 'src/app/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GA4_EVENTS } from '../website/ga4-events';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, AfterViewInit {
    solutions: Array<object>;
    address: Array<object>;
    @ViewChild('addressSlider', { static: true })
    addressSlider: ElementRef;
    whatsAppBotURL = this.utilService.isMobileBrowser()
        ? 'https://api.whatsapp.com/send?phone=+918369100100'
        : 'https://web.whatsapp.com/send?phone=+918369100100';
    addressCarousel;
    isMobile = false;
    constructor(
        private utilService: UtilService,
        private router: Router,
        private translateService: TranslateService,
        private gTagService: GoogleTagManagerService
    ) {}

    ngOnInit(): void {
        this.solutions = [
            {
                title: 'Contact Sales',
                ttitle: 'contact-us.contact_sales',
                desc: 'We’d love to talk about how we can work together.',
                tdesc: 'contact-us.contact_sales_desc',
                icon: 'assets/img/sales.svg',
                path: '/contactsales'
            },
            {
                title: 'Need Help?',
                ttitle: 'contact-us.need_help',
                desc: 'We’re here to help with any questions, troubleshooting or code.',
                tdesc: 'contact-us.need_help_desc',
                icon: 'assets/img/help.svg',
                path: '/webhelp',
                isExternal: true
            }
            // {
            //   title: 'Press Releases',
            //   desc: 'Get JioMeet news, company info and media resources.',
            //   icon: 'assets/img/press.svg',
            //   path: ''
            // }
        ];

        this.address = [
            {
                head: 'RJ',
                office: this.translateService.instant('contact-us.head_office'),
                landmark: this.translateService.instant('contact-us.landmark'),
                number: this.translateService.instant('contact-us.number'),
                area: this.translateService.instant('contact-us.area'),
                city: this.translateService.instant('contact-us.city'),
                hours: this.translateService.instant('contact-us.hours')
            }
            // {
            //   head: 'JB',
            //   office: 'Reliance Jio Banglore',
            //   landmark: ' Reliance Corporate Park,',
            //   number: ' No.8 A Wing, 1st Floor,',
            //   area: ' 5 TTC Industrial Area, Thane Belapur Road,',
            //   city: ' Ghansoli, Navi Mumbai – 400701.',
            //   hours: '24x7 Hrs'
            // },
            // {
            //   head: 'JH',
            //   office: 'Reliance Jio Hyderabad',
            //   landmark: ' Reliance Corporate Park,',
            //   number: ' No.8 A Wing, 1st Floor,',
            //   area: ' 5 TTC Industrial Area, Thane Belapur Road,',
            //   city: ' Ghansoli, Navi Mumbai – 400701.',
            //   hours: '24x7 Hrs'
            // }
        ];

        this.isMobile = this.utilService.isMobileBrowser();
        this.translateService.onLangChange.subscribe(() => {
            this.reloadCurrentRoute();
        });
    }

    reloadCurrentRoute() {
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
        });
    }
    ngAfterViewInit() {
        if (this.isMobile) {
            this.initAddressSlider();
        }
    }

    initAddressSlider() {
        this.addressCarousel = new Flickity(this.addressSlider.nativeElement, {
            prevNextButtons: !this.isMobile,
            autoPlay: false,
            contain: false,
            cellAlign: 0,
            pageDots: false,
            selectedAttraction: 0.2,
            friction: 0.8,
            index: 0,
            resize: true
        });
    }
    handleGoTo(solutionInfo: any) {
        if (solutionInfo?.title === 'Need Help?') {
            this.gTagService.sendEvent(GA4_EVENTS.CONTACT_US_NEED_HELP);
        }
        if (!solutionInfo.path) return;
        if (!solutionInfo.isExternal) this.router.navigate([solutionInfo.path]);
        else window.open(solutionInfo.path, '_blank');
    }

    whatsappNumberClicked() {
        this.gTagService.sendEvent(GA4_EVENTS.CONTACT_US_WHATSAPP);
    }

    emailClicked() {
        this.gTagService.sendEvent(GA4_EVENTS.CONTACT_US_EMAIL_US);
    }

    openChat() {
        this.gTagService.sendEvent(GA4_EVENTS.CONTACT_US_CHAT_WITH_US);
        const iframes = document.getElementsByClassName('xdk-iframe');
        const iframeElemRef: any = document.getElementsByClassName('xdk-iframe')[iframes.length - 1];
        if (iframeElemRef) {
            const chatElemRef = iframeElemRef.contentWindow.document.getElementsByClassName('minimized-view')[0];
            chatElemRef.click();
        }
    }
}
