import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { AppService } from 'src/app/core';

@Component({
    selector: 'app-in-call-request-control',
    templateUrl: './in-call-request-control.component.html',
    styleUrls: ['./in-call-request-control.component.scss']
})
export class InCallRequestControlComponent implements OnInit {
    @Output() hideInCallRequestBanner = new EventEmitter();
    @Input() forDeclineAccessControl = false;
    @Input() message;

    infoImage = 'assets/images/request-info-icon.svg';
    constructor(private appService: AppService) {}

    ngOnInit(): void {
        if (this.forDeclineAccessControl) {
            this.infoImage = 'assets/images/AccessDenied.svg';
        }
    }

    closeBanner() {
        this.hideInCallRequestBanner.emit();
    }

    JioMeetDownload() {
        window.open(this.appService.getConfigVariable('jiomeetLiteConfig')?.jioMeetLiteURL, '_blank');
        this.hideInCallRequestBanner.emit();
    }
}
