<div class="container-fluid download-page" *ngIf="loading">
    <app-loader></app-loader>
</div>
<div class="container-fluid download-page" *ngIf="!loading">
    <div class="top-nav">
        <div class="logo">
            <img alt="" src="assets/img/logo/jm-logo-white.svg" />
        </div>
        <div class="home-link light-gray" (click)="back()">Home</div>
    </div>
    <div class="content container">
        <div class="header dark-gray">Downloads</div>
        <div class="row">
            <div
                class="col d-flex align-items-start justify-content-start mt-4 ostypes"
                *ngFor="let os of currentOSTypes"
            >
                <div class="d-flex mb-4">
                    <div class="left">
                        <div class="platform-icn">
                            <img alt="" [src]="downloads[os].icon" />
                        </div>
                    </div>
                    <div class="right">
                        <div class="title">{{ downloads[os].title }}</div>
                        <div class="details">
                            <div class="info">
                                <div class="key light-gray">Version:</div>
                                <div class="val dark-gray">{{ downloads[os].version }}</div>
                            </div>
                            <div class="info">
                                <div class="key light-gray">Publisher:</div>
                                <div class="val dark-gray">Reliance Jio</div>
                            </div>
                            <div class="info">
                                <div class="key light-gray">Date:</div>
                                <div class="val dark-gray">{{ downloads[os].releaseDate }}</div>
                            </div>
                        </div>
                        <div class="features">
                            <div class="title dark-gray">What's New</div>
                            <ul>
                                <li class="light-gray" *ngFor="let feature of downloads[os].features">
                                    {{ feature }}
                                </li>
                            </ul>
                        </div>
                        <a class="download-btn" (click)="download(downloads[os])">Download</a>
                    </div>
                </div>
                <div class="d-flex plugin" [class.hidden]="true">
                    <div class="left">
                        <div class="platform-icn">
                            <img alt="" src="assets/img/outlook-icon.png" />
                        </div>
                    </div>
                    <div class="right">
                        <div class="title">{{ pluginDetails.data.title }}</div>
                        <div class="details">
                            <div class="info">
                                <div class="key light-gray">Version:</div>
                                <div class="val dark-gray">
                                    {{ pluginDetails.data.version }}
                                </div>
                            </div>
                            <div class="info">
                                <div class="key light-gray">Publisher:</div>
                                <div class="val dark-gray">Reliance Jio</div>
                            </div>
                            <div class="info">
                                <div class="key light-gray">Date:</div>
                                <div class="val dark-gray">
                                    {{ pluginDetails.data.releaseDate }}
                                </div>
                            </div>
                        </div>
                        <div class="features">
                            <div class="title dark-gray">What's New</div>
                            <ul>
                                <li class="light-gray" *ngFor="let feature of pluginDetails.data.features">
                                    {{ feature }}
                                </li>
                            </ul>
                        </div>
                        <a class="download-btn" (click)="downloadPlugin()">Download</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="copyright">&copy; JioMeet 2019. All Rights Reserved.</div>
        <ul>
            <li class="pointer" (click)="launchPrivacy()">Privacy</li>
            <li class="pointer" (click)="launchTnC()">Terms</li>
            <li class="pointer" (click)="launchFAQ()">FAQ</li>
        </ul>
    </div>
</div>
