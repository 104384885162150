export const GA4_EVENTS = {
    USECASE_EDUCATION_SIGNUP: {
        event: 'banner_sign_up',
        current_page: 'usecase_jiomeeteducation',
        clicked_text: '',
        outcome: 'succeeded'
    },
    USECASE_EDUCATION_DOWNLOAD: {
        event: 'banner_download_app',
        current_page: 'usecase_jiomeeteducation',
        clicked_text: '',
        outcome: 'succeeded'
    },
    USECASE_EDUCATION_START_FOR_FREE_BOTTOM: {
        event: 'bottom_sign_up',
        current_page: 'usecase_jiomeeteducation',
        clicked_text: '',
        outcome: 'succeeded'
    },
    USECASE_EDUCATION_DOWNLOAD_BOTTOM: {
        event: 'bottom_download_app',
        current_page: 'usecase_jiomeeteducation',
        clicked_text: '',
        outcome: 'succeeded'
    },
    USECASE_HEALTHCARE_SIGNUP: {
        event: 'banner_sign_up',
        current_page: 'usecase_jiomeethealthcare',
        clicked_text: '',
        outcome: 'succeeded'
    },
    USECASE_HEALTHCARE_DOWNLOAD: {
        event: 'banner_download_app',
        current_page: 'usecase_jiomeethealthcare',
        clicked_text: '',
        outcome: 'succeeded'
    },
    USECASE_HEALTHCARE_START_FOR_FREE_BOTTOM: {
        event: 'bottom_sign_up',
        current_page: 'usecase_jiomeethealthcare',
        clicked_text: '',
        outcome: 'succeeded'
    },
    USECASE_HEALTHCARE_DOWNLOAD_BOTTOM: {
        event: 'bottom_download_app',
        current_page: 'usecase_jiomeethealthcare',
        clicked_text: '',
        outcome: 'succeeded'
    },
    USECASE_HYBRID_WORKPLACE_SIGNUP: {
        event: 'banner_sign_up',
        current_page: 'usecase_jiomeethybridworkplace',
        clicked_text: '',
        outcome: 'succeeded'
    },
    USECASE_HYBRID_WORKPLACE_DOWNLOAD: {
        event: 'banner_download_app',
        current_page: 'usecase_jiomeethybridworkplace',
        clicked_text: '',
        outcome: 'succeeded'
    },
    USECASE_HYBRID_WORKPLACE_START_FOR_FREE_BOTTOM: {
        event: 'bottom_sign_up',
        current_page: 'usecase_jiomeethybridworkplace',
        clicked_text: '',
        outcome: 'succeeded'
    },
    USECASE_HYBRID_WORKPLACE_DOWNLOAD_BOTTOM: {
        event: 'bottom_download_app',
        current_page: 'usecase_jiomeethybridworkplace',
        clicked_text: '',
        outcome: 'succeeded'
    },
    USECASE_PLATFORM_SERVICES_SIGNUP: {
        event: 'banner_sign_up',
        current_page: 'usecase_jiomeetplatformservices',
        clicked_text: '',
        outcome: 'succeeded'
    },
    USECASE_PLATFORM_SERVICES_DOWNLOAD: {
        event: 'banner_download_app',
        current_page: 'usecase_jiomeetplatformservices',
        clicked_text: '',
        outcome: 'succeeded'
    },
    USECASE_PLATFORM_SERVICES_START_FOR_FREE_BOTTOM: {
        event: 'bottom_sign_up',
        current_page: 'usecase_jiomeetplatformservices',
        clicked_text: '',
        outcome: 'succeeded'
    },
    USECASE_PLATFORM_SERVICES_DOWNLOAD_BOTTOM: {
        event: 'bottom_download_app',
        current_page: 'usecase_jiomeetplatformservices',
        clicked_text: '',
        outcome: 'succeeded'
    },
    USECASE_GOVERMENT_SECTOR_SIGNUP: {
        event: 'banner_sign_up',
        current_page: 'usecase_jiomeetgovernmentsector',
        clicked_text: '',
        outcome: 'succeeded'
    },
    USECASE_GOVERMENT_SECTOR_DOWNLOAD: {
        event: 'banner_download_app',
        current_page: 'usecase_jiomeetgovernmentsector',
        clicked_text: '',
        outcome: 'succeeded'
    },
    USECASE_GOVERMENT_SECTOR_START_FOR_FREE_BOTTOM: {
        event: 'bottom_sign_up',
        current_page: 'usecase_jiomeetgovernmentsector',
        clicked_text: '',
        outcome: 'succeeded'
    },
    USECASE_GOVERMENT_SECTOR_DOWNLOAD_BOTTOM: {
        event: 'bottom_download_app',
        current_page: 'usecase_jiomeetgovernmentsector',
        clicked_text: '',
        outcome: 'succeeded'
    },
    HOME_PLAN_MEETING_BEFORE_LOGIN: {
        event: 'plan_a_meeting',
        current_page: 'jiomeet_home',
        clicked_text: '',
        outcome: 'succeeded'
    },
    HOME_START_MEETING_BEFORE_LOGIN: {
        event: 'start_a_meeting',
        current_page: 'jiomeet_home',
        clicked_text: '',
        outcome: 'succeeded'
    },
    HOME_JOIN_MEETING_BEFORE_LOGIN: {
        event: 'join_meeting',
        current_page: 'jiomeet_home',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACT_SALES_HOME_PAGE: {
        event: 'banner_contact_sales',
        current_page: 'jiomeet_home',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACT_US_HOME_PAGE: {
        event: 'button_click',
        current_page: 'jiomeet_home',
        clicked_text: 'contact_us',
        outcome: 'succeeded'
    },
    PRODUCT_SUBSCRIBE_COMMON: {
        event: 'subscribe',
        current_page: 'jiomeet_home',
        clicked_text: '',
        outcome: 'succeeded'
    },
    GETTING_STARTED: {
        event: 'button_click',
        clicked_text: 'getting_started',
        current_page: 'webhelp',
        outcome: 'succeeded'
    },
    HOW_TO_USE_JIOMEET: {
        event: 'button_click',
        clicked_text: 'how_to_use_jiomeet',
        current_page: 'webhelp',
        outcome: 'succeeded'
    },
    IN_CALL_HOST_CONTROLS: {
        event: 'button_click',
        clicked_text: 'in_call_host_controls',
        current_page: 'webhelp',
        outcome: 'succeeded'
    },
    IN_CALL_MEETING_FEATURES: {
        event: 'button_click',
        clicked_text: 'in_call_meeting_features',
        current_page: 'webhelp',
        outcome: 'succeeded'
    },
    JIOMEET_FOR_OUTLOOK_ADDIN: {
        event: 'button_click',
        clicked_text: 'jiomeet_for_outlook_addin',
        current_page: 'webhelp',
        outcome: 'succeeded'
    },
    TROUBLESHOOT: {
        event: 'button_click',
        clicked_text: 'troubleshoot',
        current_page: 'webhelp',
        outcome: 'succeeded'
    },
    JIOMEET_ENTERPRISE: {
        event: 'button_click',
        clicked_text: 'jiomeet_enterprise',
        current_page: 'webhelp',
        outcome: 'succeeded'
    },
    CONTACT_US: {
        event: 'button_click',
        clicked_text: 'contact_us',
        current_page: 'webhelp',
        outcome: 'succeeded'
    },
    DOWNLOAD_WINDOWS: {
        event: 'download_app',
        clicked_text: 'download_windows',
        current_page: 'download_jiomeet',
        outcome: 'succeeded'
    },
    DOWNLOAD_MAC: {
        event: 'download_app',
        clicked_text: 'download_mac',
        current_page: 'download_jiomeet',
        outcome: 'succeeded'
    },
    DOWNLOAD_ANDROID: {
        event: 'download_app',
        clicked_text: 'download_android',
        current_page: 'download_jiomeet',
        outcome: 'succeeded'
    },
    DOWNLOAD_IOS: {
        event: 'download_app',
        clicked_text: 'download_ios',
        current_page: 'download_jiomeet',
        outcome: 'succeeded'
    },
    OPEN_IN_BROWSER: {
        event: 'download_app',
        clicked_text: 'open_in_browser',
        current_page: 'download_jiomeet',
        outcome: 'succeeded'
    },
    DOWNLOAD_JIOMEET_TEAMS: {
        event: 'download_app',
        clicked_text: 'download_jiomeet_teams',
        current_page: 'download_jiomeet',
        outcome: 'succeeded'
    },
    DOWNLOAD_JIOMEET_OUTLOOK: {
        event: 'download_app',
        clicked_text: 'download_jiomeet_outlook',
        current_page: 'download_jiomeet',
        outcome: 'succeeded'
    },
    SUBSCRIBE_JIOMEET_DOWNLOAD: {
        event: 'subscribe',
        clicked_text: '',
        current_page: 'download_jiomeet',
        outcome: 'succeeded'
    },
    FOOTER_JIOMEET_LOGO: {
        event: 'button_click',
        clicked_text: 'jiomeet',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_PRIVACY: {
        event: 'button_click',
        clicked_text: 'privacy_policy',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_TNC: {
        event: 'button_click',
        clicked_text: 'tnc',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_JIOMEET: {
        event: 'button_click',
        clicked_text: 'products_jiomeet',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_ENTERPRISE: {
        event: 'button_click',
        clicked_text: 'products_enterprise',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_PLATFORM: {
        event: 'button_click',
        clicked_text: 'products_platform',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_JIOEVENTS: {
        event: 'button_click',
        clicked_text: 'products_jioevents',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_DISCOVER: {
        event: 'button_click',
        clicked_text: 'products_discover',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_EDUCATION: {
        event: 'button_click',
        clicked_text: 'usecases_education',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_HEALTHCARE: {
        event: 'button_click',
        clicked_text: 'usecases_healthcare',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_HYBRID: {
        event: 'button_click',
        clicked_text: 'usecases_hybridworkplaces',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_PLATFORM_SERVICES: {
        event: 'button_click',
        clicked_text: 'usecases_platformservices',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_GOVERNMENT: {
        event: 'button_click',
        clicked_text: 'usecases_governmentsectors',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_DOWNLOAD_JIOMEET: {
        event: 'button_click',
        clicked_text: 'download_jiomeet',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_JIOMEET_DEV_CENTER: {
        event: 'button_click',
        clicked_text: 'jiomeet_devcenter',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_JIOMEET_DEV_COMMUNITY: {
        event: 'button_click',
        clicked_text: 'developer_community',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_BLOG: {
        event: 'button_click',
        clicked_text: 'blog',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_NEED_HELP: {
        event: 'button_click',
        clicked_text: 'need_help',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_DEV_SUPPORT: {
        event: 'button_click',
        clicked_text: 'developer_support',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_PROVIDE_FEEDBACK: {
        event: 'button_click',
        clicked_text: 'provide_feedback',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_CONTACT_US: {
        event: 'button_click',
        clicked_text: 'contact_us',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_PRESS_RELEASE: {
        event: 'button_click',
        clicked_text: 'press_release',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_CUSTOMERS: {
        event: 'button_click',
        clicked_text: 'customers',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_CAREERS: {
        event: 'button_click',
        clicked_text: 'careers',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_FACEBOOK: {
        event: 'button_click',
        clicked_text: 'facebook',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_LINKEDIN: {
        event: 'button_click',
        clicked_text: 'linkedIn',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_TWITTER: {
        event: 'button_click',
        clicked_text: 'twitter',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_YOUTUBE: {
        event: 'button_click',
        clicked_text: 'youtube',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    FOOTER_INSTAGRAM: {
        event: 'button_click',
        clicked_text: 'instagram',
        current_page: 'jiomeet_footer',
        outcome: 'succeeded'
    },
    HEADER_WHATSAPP: {
        event: 'button_click',
        clicked_text: 'whatsapp',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_HELP: {
        event: 'button_click',
        clicked_text: 'help',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_SIGNIN: {
        event: 'button_click',
        clicked_text: 'sign_in',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_SIGNUP: {
        event: 'button_click',
        clicked_text: 'sign_up',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_DOWNLOAD_APP: {
        event: 'download_app',
        clicked_text: '',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_JOIN_A_MEETING: {
        event: 'join_meeting',
        clicked_text: '',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_SET_LANG_EN: {
        event: 'language',
        clicked_text: 'english',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_SET_LANG_HI: {
        event: 'language',
        clicked_text: 'hindi',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_PRODUCTS_JIOMEET: {
        event: 'button_click',
        clicked_text: 'products_jiomeet',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_PRODUCTS_ENTERPRISE: {
        event: 'button_click',
        clicked_text: 'products_enterprise',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_PRODUCTS_PLATFORM: {
        event: 'button_click',
        clicked_text: 'products_platform',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_PRODUCTS_JIOEVENTS: {
        event: 'button_click',
        clicked_text: 'products_jioevents',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_PRODUCTS_DISCOVER: {
        event: 'button_click',
        clicked_text: 'products_discover',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_PRODUCTS_EDUCATION: {
        event: 'button_click',
        clicked_text: 'usecases_education',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_PRODUCTS_HEALTHCARE: {
        event: 'button_click',
        clicked_text: 'usecases_healthcare',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_PRODUCTS_HYBRID_WORKPLACE: {
        event: 'button_click',
        clicked_text: 'usecases_hybridworkplaces',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_PRODUCTS_PLATFORM_SERVICES: {
        event: 'button_click',
        clicked_text: 'usecases_platformservices',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_PRODUCTS_GOVERNMENT_SECTORS: {
        event: 'button_click',
        clicked_text: 'usecases_governmentsectors',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_SALES: {
        event: 'button_click',
        clicked_text: 'sales',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_PRICING: {
        event: 'button_click',
        clicked_text: 'pricing',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_PRICING_MENU: {
        event: 'button_click',
        clicked_text: 'pricing',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_CONTACTSALES_MENU: {
        event: 'button_click',
        clicked_text: 'sales',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_DOWNLOAD_JIOMEET: {
        event: 'button_click',
        clicked_text: 'download_jiomeet',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_JIOMEET_DEV_CENTER: {
        event: 'button_click',
        clicked_text: 'jiomeet_devcenter',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_JIOMEET_DEV_COMMUNITY: {
        event: 'button_click',
        clicked_text: 'developer_community',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_BLOG: {
        event: 'button_click',
        clicked_text: 'blog',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_NEED_HELP: {
        event: 'button_click',
        clicked_text: 'need_help',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_DEV_SUPPORT: {
        event: 'button_click',
        clicked_text: 'developer_support',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_PROVIDE_FEEDBACK: {
        event: 'button_click',
        clicked_text: 'provide_feedback',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_CONTACT_US: {
        event: 'button_click',
        clicked_text: 'contact_us',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_PRESS_RELEASE: {
        event: 'button_click',
        clicked_text: 'press_release',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_CUSTOMERS: {
        event: 'button_click',
        clicked_text: 'customers',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_CAREERS: {
        event: 'button_click',
        clicked_text: 'careers',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_JIO_EVENTS: {
        event: 'button_click',
        clicked_text: 'jioevents_dashboard',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_ADMIN_PORTAL: {
        event: 'button_click',
        clicked_text: 'admin_portal',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    HEADER_SIGNOUT_YES: {
        event: 'sign_out',
        clicked_text: '',
        current_page: 'jiomeet_header',
        outcome: 'yes'
    },
    HEADER_SIGNOUT_NO: {
        event: 'sign_out',
        clicked_text: '',
        current_page: 'jiomeet_header',
        outcome: 'no'
    },
    HEADER_JIOMEET_LOGO: {
        event: 'button_click',
        clicked_text: 'jiomeet',
        current_page: 'jiomeet_header',
        outcome: 'succeeded'
    },
    PRICING_SIGNUP_FOR_FREE_MONTHLY: {
        event: 'monthly',
        current_page: 'pricing',
        clicked_text: 'standard_sign_up_for_free',
        outcome: 'succeeded'
    },
    PRICING_SIGNUP_FOR_FREE_ANNUALLY: {
        event: 'annually',
        current_page: 'pricing',
        clicked_text: 'standard_sign_up_for_free',
        outcome: 'succeeded'
    },
    PRICING_SIGNUP_FOR_FREE_MONTHLY_FAILURE: {
        event: 'monthly',
        current_page: 'pricing',
        clicked_text: 'standard_sign_up_for_free',
        outcome: 'failed'
    },
    PRICING_SIGNUP_FOR_FREE_ANNUALLY_FAILURE: {
        event: 'annually',
        current_page: 'pricing',
        clicked_text: 'standard_sign_up_for_free',
        outcome: 'failed'
    },
    PRICING_SMALL_BUSINESS_REQUEST_QUOTE_MONTHLY: {
        event: 'monthly',
        current_page: 'pricing',
        clicked_text: 'small_business_request_a_quote',
        outcome: 'succeeded'
    },
    PRICING_SMALL_BUSINESS_REQUEST_QUOTE_ANNUALLY: {
        event: 'annually',
        current_page: 'pricing',
        clicked_text: 'small_business_request_a_quote',
        outcome: 'succeeded'
    },
    PRICING_ENTERPRISE_REQUEST_QUOTE_MONTHLY: {
        event: 'monthly',
        current_page: 'pricing',
        clicked_text: 'enterprise_request_a_quote',
        outcome: 'succeeded'
    },
    PRICING_ENTERPRISE_REQUEST_QUOTE_ANNUALLY: {
        event: 'annually',
        current_page: 'pricing',
        clicked_text: 'enterprise_request_a_quote',
        outcome: 'succeeded'
    },
    PRICING_CUSTOM_REQUEST_QUOTE_MONTHLY: {
        event: 'monthly',
        current_page: 'pricing',
        clicked_text: 'custom_request_a_quote',
        outcome: 'succeeded'
    },
    PRICING_CUSTOM_REQUEST_QUOTE_ANNUALLY: {
        event: 'annually',
        current_page: 'pricing',
        clicked_text: 'custom_request_a_quote',
        outcome: 'succeeded'
    },
    PRICING_FULL_PLAN_SEE_COMPARISON_SUCCESS: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: 'see_full_plan_comparison',
        outcome: 'succeeded'
    },
    PRICING_FULL_PLAN_SEE_COMPARISON_FAILURE: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: 'see_full_plan_comparison',
        outcome: 'failed'
    },
    PRICING_SIGNUP_FOR_FREE_PLAN_COMPARISON: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: 'standard_sign_up_for_free',
        outcome: 'succeeded'
    },
    PRICING_SMALL_BUSINESS_REQUEST_QUOTE_SUCCESS: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: 'small_business_request_a_quote_bottom',
        outcome: 'succeeded'
    },
    PRICING_SMALL_BUSINESS_REQUEST_QUOTE_FAILURE: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: 'small_business_request_a_quote_bottom',
        outcome: 'failed'
    },
    PRICING_ENTERPRISE_REQUEST_QUOTE_SUCCESS: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: 'enterprise_request_a_quote_bottom',
        outcome: 'succeeded'
    },
    PRICING_ENTERPRISE_REQUEST_QUOTE_FAILURE: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: 'enterprise_request_a_quote_bottom',
        outcome: 'failed'
    },
    PRICING_CUSTOM_REQUEST_QUOTE_SUCCESS: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: 'custom_request_a_quote_bottom',
        outcome: 'succeeded'
    },
    PRICING_CUSTOM_REQUEST_QUOTE_FAILURE: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: 'custom_request_a_quote_bottom',
        outcome: 'failed'
    },
    PRICING_CLOUD_STORAGE_REQUEST_SUCCESS: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: 'cloud_storage_request_a_quote',
        outcome: 'succeeded'
    },
    PRICING_CLOUD_STORAGE_REQUEST_FAILURE: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: 'cloud_storage_request_a_quote',
        outcome: 'failed'
    },
    PRICING_CONFERENCE_ROOMS_REQUEST_SUCCESS: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: 'conference_rooms_request_a_quote',
        outcome: 'succeeded'
    },
    PRICING_CONFERENCE_ROOMS_REQUEST_FAILURE: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: 'conference_rooms_request_a_quote',
        outcome: 'failed'
    },
    PRICING_100_PARTICIPANTS_REQUEST_SUCCESS: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: '100_participants_request_a_quote',
        outcome: 'succeeded'
    },
    PRICING_100_PARTICIPANTS_REQUEST_FAILURE: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: '100_participants_request_a_quote',
        outcome: 'failed'
    },
    PRICING_LIVE_STREAMING_REQUEST_SUCCESS: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: 'live_streaming_request_a_quote',
        outcome: 'succeeded'
    },
    PRICING_LIVE_STREAMING_REQUEST_FAILURE: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: 'live_streaming_request_a_quote',
        outcome: 'failed'
    },
    VIDEO_ON_WHILE_CALL: {
        event: 'button_click',
        current_page: 'inside_call',
        clicked_text: 'turn_off_my_video_when_accepting_incoming_call',
        outcome: 'enabled'
    },
    VIDEO_OFF_WHILE_CALL: {
        event: 'button_click',
        current_page: 'inside_call',
        clicked_text: 'turn_off_my_video_when_accepting_incoming_call',
        outcome: 'disabled'
    },
    ADD_PARTICIPANTS_INSIDE_CALL: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'add_participants',
        outcome: 'succeeded'
    },
    FILTER_BY_NAME_PARTICIPANT_PANEL: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'filter_by_name',
        outcome: 'succeeded'
    },
    SORT_BY_NAME_A_Z_PARTICIPANT_PANEL: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'sort_by_name(a-z)',
        outcome: 'succeeded'
    },
    SORT_BY_NAME_Z_A_PARTICIPANT_PANEL: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'sort_by_name(z-a)',
        outcome: 'succeeded'
    },
    SORT_BY_CAMERA_STATUS_PARTICIPANT_PANEL: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'sort_by_camera_status',
        outcome: 'succeeded'
    },
    SORT_BY_MIC_STATUS_PARTICIPANT_PANEL: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'sort_by_mic_status',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_START_VIDEO: {
        event: 'camera',
        current_page: 'participant_panel',
        clicked_text: 'start_video',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_START_VIDEO_FAILED: {
        event: 'camera',
        current_page: 'participant_panel',
        clicked_text: 'start_video',
        outcome: 'failed'
    },
    PARTICIPANT_PANEL_STOP_VIDEO: {
        event: 'camera',
        current_page: 'participant_panel',
        clicked_text: 'stop_video',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_STOP_VIDEO_FAILED: {
        event: 'camera',
        current_page: 'participant_panel',
        clicked_text: 'stop_video',
        outcome: 'failed'
    },
    PARTICIPANT_PANEL_MUTE_MIC: {
        event: 'mic',
        current_page: 'participant_panel',
        clicked_text: 'mute',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_MUTE_MIC_FAILED: {
        event: 'mic',
        current_page: 'participant_panel',
        clicked_text: 'mute',
        outcome: 'failed'
    },
    PARTICIPANT_PANEL_UNMUTE_MIC: {
        event: 'mic',
        current_page: 'participant_panel',
        clicked_text: 'unmute',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_UNMUTE_MIC_FAILED: {
        event: 'mic',
        current_page: 'participant_panel',
        clicked_text: 'unmute',
        outcome: 'failed'
    },
    PARTICIPANT_PANEL_REMOTE_START_VIDEO: {
        event: 'camera',
        current_page: 'participant_panel',
        clicked_text: 'ask_for_video',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_REMOTE_START_VIDEO_FAILED: {
        event: 'camera',
        current_page: 'participant_panel',
        clicked_text: 'ask_for_video',
        outcome: 'failed'
    },
    PARTICIPANT_PANEL_REMOTE_STOP_VIDEO: {
        event: 'camera',
        current_page: 'participant_panel',
        clicked_text: 'stop_video',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_REMOTE_STOP_VIDEO_FAILED: {
        event: 'camera',
        current_page: 'participant_panel',
        clicked_text: 'stop_video',
        outcome: 'failed'
    },
    PARTICIPANT_PANEL_REMOTE_MUTE_MIC: {
        event: 'mic',
        current_page: 'participant_panel',
        clicked_text: 'mute',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_REMOTE_MUTE_MIC_FAILED: {
        event: 'mic',
        current_page: 'participant_panel',
        clicked_text: 'mute',
        outcome: 'failed'
    },
    PARTICIPANT_PANEL_REMOTE_UNMUTE_MIC: {
        event: 'mic',
        current_page: 'participant_panel',
        clicked_text: 'ask_for_unmute',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_REMOTE_UNMUTE_MIC_FAILED: {
        event: 'mic',
        current_page: 'participant_panel',
        clicked_text: 'ask_for_unmute',
        outcome: 'failed'
    },
    PARTICIPANT_PANEL_RAISE_HAND: {
        event: 'handraise',
        current_page: 'participant_panel',
        clicked_text: 'raise_hand',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_RAISE_HAND_FAILED: {
        event: 'handraise',
        current_page: 'participant_panel',
        clicked_text: 'raise_hand',
        outcome: 'failed'
    },
    PARTICIPANT_PANEL_LOWER_HAND: {
        event: 'handraise',
        current_page: 'participant_panel',
        clicked_text: 'lower_hand',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_LOWER_HAND_FAILED: {
        event: 'handraise',
        current_page: 'participant_panel',
        clicked_text: 'lower_hand',
        outcome: 'failed'
    },
    PARTICIPANT_PANEL_AUDIENCE_EXPAND: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'view_all',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_AUDIENCE_COLLAPSE: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'collapse',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_SEARCH_AUDIENCE: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'search_audience',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_MAKE_COHOST: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'make_co-host',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_MAKE_COHOST_FAILED: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'make_co-host',
        outcome: 'failed'
    },
    PARTICIPANT_PANEL_REMOVE_COHOST: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'remove_co_host',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_REMOVE_COHOST_FAILED: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'remove_co_host',
        outcome: 'failed'
    },
    PARTICIPANT_PANEL_MOVE_TO_AUDIENCE: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'move_to_audience',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_MOVE_TO_AUDIENCE_FAILED: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'move_to_audience',
        outcome: 'failed'
    },
    PARTICIPANT_PANEL_REMOVE_PARTICIPANT: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'remove_participant',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_REMOVE_PARTICIPANT_FAILED: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'remove_participant',
        outcome: 'failed'
    },
    PARTICIPANT_PANEL_AUDIENCE_MOVE_TO_PARTICIPANT: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'audience_move_to_participant',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_AUDIENCE_MOVE_TO_PARTICIPANT_FAILED: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'audience_move_to_participant',
        outcome: 'failed'
    },
    PARTICIPANT_PANEL_AUDIENCE_REMOVE: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'audience_remove',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_AUDIENCE_REMOVE_FAILED: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'audience_remove',
        outcome: 'failed'
    },
    PARTICIPANT_PANEL_CALL_AGAIN: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'call_again',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_CALL_AGAIN_FAILED: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'call_again',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_ADD_ROOM: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'add_room',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_ADD_ROOM_FAILED: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'add_room',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_RENAME: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'rename',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_DELETE: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'delete',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_DELETE_FAILED: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'delete',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_ADD_PARTICIPANTS: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'add_participants',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_ENTER_ROOM: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'enter_room',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_LEAVE_ROOM: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'leave_room',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_LEAVE_ROOM_FAILED: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'leave_room',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_START_BREAKOUT_SESSION: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'start_breakout_session',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_START_BREAKOUT_SESSION_FAILED: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'start_breakout_session',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_END_BREAKOUT_SESSION: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'end_breakout_session',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_END_BREAKOUT_SESSION_FAILED: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'end_breakout_session',
        outcome: 'failed'
    },
    NETWORK_ERROR_LEAVE: {
        event: 'button_click',
        current_page: 'inside_call',
        clicked_text: 'internet_error_leave',
        outcome: 'leave'
    },
    NETWORK_ERROR_CANCEL: {
        event: 'button_click',
        current_page: 'inside_call',
        clicked_text: 'internet_error_leave',
        outcome: 'cancel'
    },
    BREAKOUT_ROOM_MEETING_INFO_CLICK: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'meeting_information_icon',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_MEETING_INFO_COPY_LINK: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'copy_link',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_MEETING_INFO_COPY_INVITATION: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'copy_invitation',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_MIC_MUTE: {
        event: 'mic',
        current_page: 'breakout_room',
        clicked_text: 'mute',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_MIC_UNMUTE: {
        event: 'mic',
        current_page: 'breakout_room',
        clicked_text: 'unmute',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_MIC_MUTE_FAILED: {
        event: 'mic',
        current_page: 'breakout_room',
        clicked_text: 'mute',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_MIC_UNMUTE_FAILED: {
        event: 'mic',
        current_page: 'breakout_room',
        clicked_text: 'unmute',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_VIDEO_MUTE: {
        event: 'camera',
        current_page: 'breakout_room',
        clicked_text: 'start_video',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_VIDEO_UNMUTE: {
        event: 'camera',
        current_page: 'breakout_room',
        clicked_text: 'stop_video',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_VIDEO_MUTE_FAILED: {
        event: 'camera',
        current_page: 'breakout_room',
        clicked_text: 'start_video',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_VIDEO_UNMUTE_FAILED: {
        event: 'camera',
        current_page: 'breakout_room',
        clicked_text: 'stop_video',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_START_SHARE: {
        event: 'screenshare',
        current_page: 'breakout_room',
        clicked_text: 'start_screen_share',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_START_SHARE_FAILED: {
        event: 'screenshare',
        current_page: 'breakout_room',
        clicked_text: 'start_screen_share',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_STOP_SHARE: {
        event: 'screenshare',
        current_page: 'breakout_room',
        clicked_text: 'stop_screen_share',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_STOP_SHARE_FAILED: {
        event: 'screenshare',
        current_page: 'breakout_room',
        clicked_text: 'stop_screen_share',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_START_WHITEBOARD: {
        event: 'screenshare',
        current_page: 'breakout_room',
        clicked_text: 'start_whiteboard_share',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_STOP_WHITEBOARD: {
        event: 'screenshare',
        current_page: 'breakout_room',
        clicked_text: 'stop_whiteboard_share',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_RECORDING_START: {
        event: 'recording',
        current_page: 'breakout_room',
        clicked_text: 'recording_start',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_RECORDING_START_FAILED: {
        event: 'recording',
        current_page: 'breakout_room',
        clicked_text: 'recording_start',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_RECORDING_STOP: {
        event: 'recording',
        current_page: 'breakout_room',
        clicked_text: 'recording_stop',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_RECORDING_STOP_FAILED: {
        event: 'recording',
        current_page: 'breakout_room',
        clicked_text: 'recording_stop',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_RAISE_HAND: {
        event: 'handraise',
        current_page: 'breakout_room',
        clicked_text: 'raise_hand',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_LOWER_HAND: {
        event: 'handraise',
        current_page: 'breakout_room',
        clicked_text: 'lower_hand',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_RAISE_HAND_FAILED: {
        event: 'handraise',
        current_page: 'breakout_room',
        clicked_text: 'raise_hand',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_LOWER_HAND_FAILED: {
        event: 'handraise',
        current_page: 'breakout_room',
        clicked_text: 'lower_hand',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_REACTIONS_LIKE: {
        event: 'reactions',
        current_page: 'breakout_room',
        clicked_text: 'like',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_REACTIONS_APPLAUSE: {
        event: 'reactions',
        current_page: 'breakout_room',
        clicked_text: 'applause',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_REACTIONS_HEART: {
        event: 'reactions',
        current_page: 'breakout_room',
        clicked_text: 'heart',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_REACTIONS_CELEBRATIONS: {
        event: 'reactions',
        current_page: 'breakout_room',
        clicked_text: 'celebrations',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_REACTIONS_SMILE: {
        event: 'reactions',
        current_page: 'breakout_room',
        clicked_text: 'smile',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_REACTIONS_AWESOME: {
        event: 'reactions',
        current_page: 'breakout_room',
        clicked_text: 'awesome',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_REACTIONS_HIGHFIVE: {
        event: 'reactions',
        current_page: 'breakout_room',
        clicked_text: 'highfive',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CALL_CONTROLS_REACTIONS_SAD: {
        event: 'reactions',
        current_page: 'breakout_room',
        clicked_text: 'sad',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CHAT_CLICK: {
        event: 'chat',
        current_page: 'breakout_room',
        clicked_text: 'chat',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CHAT_POP_OUT: {
        event: 'chat',
        current_page: 'breakout_room',
        clicked_text: 'pop_out_chat',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CHAT_CLOSE: {
        event: 'chat',
        current_page: 'breakout_room',
        clicked_text: 'close_chat',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CHAT_SEND_MESSAGE: {
        event: 'chat',
        current_page: 'breakout_room',
        clicked_text: 'send_message',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CHAT_SEND_MESSAGE_FAILED: {
        event: 'chat',
        current_page: 'breakout_room',
        clicked_text: 'send_message',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_FILTER_BY_NAME_PARTICIPANT_PANEL: {
        event: 'button_click',
        current_page: 'breakout_room_participant_panel',
        clicked_text: 'filter_by_name',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_SORT_BY_NAME_A_Z_PARTICIPANT_PANEL: {
        event: 'button_click',
        current_page: 'breakout_room_participant_panel',
        clicked_text: 'sort_by_name(a-z)',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_SORT_BY_NAME_Z_A_PARTICIPANT_PANEL: {
        event: 'button_click',
        current_page: 'breakout_room_participant_panel',
        clicked_text: 'sort_by_name(z-a)',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_SORT_BY_CAMERA_STATUS_PARTICIPANT_PANEL: {
        event: 'button_click',
        current_page: 'breakout_room_participant_panel',
        clicked_text: 'sort_by_camera_status',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_SORT_BY_MIC_STATUS_PARTICIPANT_PANEL: {
        event: 'button_click',
        current_page: 'breakout_room_participant_panel',
        clicked_text: 'sort_by_mic_status',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_PARTICIPANT_PANEL_MUTE_MIC: {
        event: 'mic',
        current_page: 'breakout_room_participant_panel',
        clicked_text: 'mute',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_PARTICIPANT_PANEL_MUTE_MIC_FAILED: {
        event: 'mic',
        current_page: 'breakout_room_participant_panel',
        clicked_text: 'mute',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_PARTICIPANT_PANEL_UNMUTE_MIC: {
        event: 'mic',
        current_page: 'breakout_room_participant_panel',
        clicked_text: 'unmute',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_PARTICIPANT_PANEL_UNMUTE_MIC_FAILED: {
        event: 'mic',
        current_page: 'breakout_room_participant_panel',
        clicked_text: 'unmute',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_PARTICIPANT_PANEL_START_VIDEO: {
        event: 'camera',
        current_page: 'breakout_room_participant_panel',
        clicked_text: 'start_video',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_PARTICIPANT_PANEL_START_VIDEO_FAILED: {
        event: 'camera',
        current_page: 'breakout_room_participant_panel',
        clicked_text: 'start_video',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_PARTICIPANT_PANEL_STOP_VIDEO: {
        event: 'camera',
        current_page: 'breakout_room_participant_panel',
        clicked_text: 'stop_video',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_PARTICIPANT_PANEL_STOP_VIDEO_FAILED: {
        event: 'camera',
        current_page: 'breakout_room_participant_panel',
        clicked_text: 'stop_video',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_PARTICIPANT_PANEL_RAISE_HAND: {
        event: 'handraise',
        current_page: 'breakout_room_participant_panel',
        clicked_text: 'raise_hand',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_PARTICIPANT_PANEL_RAISE_HAND_FAILED: {
        event: 'handraise',
        current_page: 'breakout_room_participant_panel',
        clicked_text: 'raise_hand',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_PARTICIPANT_PANEL_LOWER_HAND: {
        event: 'handraise',
        current_page: 'breakout_room_participant_panel',
        clicked_text: 'lower_hand',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_PARTICIPANT_PANEL_LOWER_HAND_FAILED: {
        event: 'handraise',
        current_page: 'breakout_room_participant_panel',
        clicked_text: 'lower_hand',
        outcome: 'failed'
    },
    BREAKOUT_ROOM_MORE_CLICKED: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'more',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_MORE_CHANGE_LAYOUT: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'change_layout',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CHANGE_LAYOUT_GRID: {
        event: 'layout',
        current_page: 'breakout_room',
        clicked_text: 'switch_to_gallery_mode',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CHANGE_LAYOUT_SPEAKER: {
        event: 'layout',
        current_page: 'breakout_room',
        clicked_text: 'switch_to_speaker_view',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CHANGE_LAYOUT_HIDE_NONVIDEO_ENABLED: {
        event: 'layout',
        current_page: 'breakout_room',
        clicked_text: 'hide_non-video_participants',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CHANGE_LAYOUT_HIDE_NONVIDEO_DISABLED: {
        event: 'layout',
        current_page: 'breakout_room',
        clicked_text: 'hide_non-video_participants',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CHANGE_LAYOUT_AUDIO_MODE_ENABLED: {
        event: 'audio_only_mode',
        current_page: 'breakout_room',
        clicked_text: 'enable_audio_only_mode',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_CHANGE_LAYOUT_AUDIO_MODE_DISABLED: {
        event: 'audio_only_mode',
        current_page: 'breakout_room',
        clicked_text: 'disable_audio_only_mode',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_MORE_FULL_SCREEN: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'full_screen_mode',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_MORE_EXIT_FULL_SCREEN: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'exit_full_screen',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_MORE_LANGUAGE_ENGLISH: {
        event: 'language',
        current_page: 'breakout_room',
        clicked_text: 'english',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_MORE_LANGUAGE_HINDI: {
        event: 'language',
        current_page: 'breakout_room',
        clicked_text: 'hindi',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_MORE_SETTING_CLICKED: {
        event: 'settings',
        current_page: 'breakout_room',
        clicked_text: '',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_SETTING_TEST_SPEAKER: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'test_speaker',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_SETTING_STOP_SPEAKER: {
        event: 'button_click',
        current_page: 'breakout_room',
        clicked_text: 'stop_speaker',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_VB_NONE_CLICKED: {
        event: 'virtual_background',
        current_page: 'breakout_room',
        clicked_text: 'none',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_VB_BLUR_CLICKED: {
        event: 'virtual_background',
        current_page: 'breakout_room',
        clicked_text: 'blur',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_VB_CUSTOM_CLICKED: {
        event: 'virtual_background',
        current_page: 'breakout_room',
        clicked_text: 'custom',
        outcome: 'succeeded'
    },

    INSIDE_CALL_MORE_FULL_SCREEN: {
        event: 'button_click',
        current_page: 'inside_call',
        clicked_text: 'full_screen_mode',
        outcome: 'succeeded'
    },
    INSIDE_CALL_MORE_EXIT_FULL_SCREEN: {
        event: 'button_click',
        current_page: 'inside_call',
        clicked_text: 'exit_full_screen',
        outcome: 'succeeded'
    },
    INSIDE_CALL_MORE_LANGUAGE_ENGLISH: {
        event: 'language',
        current_page: 'inside_call',
        clicked_text: 'english',
        outcome: 'succeeded'
    },
    INSIDE_CALL__MORE_LANGUAGE_HINDI: {
        event: 'language',
        current_page: 'inside_call',
        clicked_text: 'hindi',
        outcome: 'succeeded'
    },
    INSIDE_CALL_MORE_SETTING_CLICKED: {
        event: 'settings',
        current_page: 'inside_call',
        clicked_text: '',
        outcome: 'succeeded'
    },
    INSIDE_CALL_SETTING_TEST_SPEAKER: {
        event: 'button_click',
        current_page: 'inside_call',
        clicked_text: 'test_speaker',
        outcome: 'succeeded'
    },
    INSIDE_CALL_SETTING_STOP_SPEAKER: {
        event: 'button_click',
        current_page: 'inside_call',
        clicked_text: 'stop_speaker',
        outcome: 'succeeded'
    },
    INSIDE_CALL_VB_NONE_CLICKED: {
        event: 'virtual_background',
        current_page: 'inside_call',
        clicked_text: 'none',
        outcome: 'succeeded'
    },
    INSIDE_CALL_VB_BLUR_CLICKED: {
        event: 'virtual_background',
        current_page: 'inside_call',
        clicked_text: 'blur',
        outcome: 'succeeded'
    },
    INSIDE_CALL_VB_CUSTOM_CLICKED: {
        event: 'virtual_background',
        current_page: 'inside_call',
        clicked_text: 'custom',
        outcome: 'succeeded'
    },
    CONTACT_US_WHATSAPP: {
        event: 'button_click',
        current_page: 'contact_us',
        clicked_text: 'whatsapp',
        outcome: 'succeeded'
    },
    CONTACT_US_CHAT_WITH_US: {
        event: 'button_click',
        current_page: 'contact_us',
        clicked_text: 'chat_with_us',
        outcome: 'succeeded'
    },
    CONTACT_US_EMAIL_US: {
        event: 'button_click',
        current_page: 'contact_us',
        clicked_text: 'email_us',
        outcome: 'succeeded'
    },
    CONTACT_US_CONTACT_SALES: {
        event: 'contact_sales',
        current_page: 'contact_us',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACT_US_NEED_HELP: {
        event: 'button_click',
        current_page: 'contact_us',
        clicked_text: 'help',
        outcome: 'succeeded'
    },
    CONTACT_US_SUBSCRIBE: {
        event: 'subscribe',
        current_page: 'contact_us',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACT_US_SUBSCRIBE_FAILED: {
        event: 'subscribe',
        current_page: 'contact_us',
        clicked_text: '',
        outcome: 'failed'
    },
    FEEDBACK_SUBMIT: {
        event: 'submit',
        current_page: 'provide_feedback',
        clicked_text: '',
        outcome: 'succeeded'
    },
    FEEDBACK_SUBMIT_FAILED: {
        event: 'submit',
        current_page: 'provide_feedback',
        clicked_text: '',
        outcome: 'failed'
    },
    FEEDBACK_NOT_SUBMIT: {
        event: 'user_not_submitted_feedback_form',
        current_page: 'provide_feedback',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACTS_SEARCH_CONTACTS_CLICKED: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'search_contact',
        outcome: 'succeeded'
    },
    CONTACTS_RECENTS_CLICKED: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'recents',
        outcome: 'succeeded'
    },
    CONTACTS_FAVOURITES_CLICKED: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'favorites',
        outcome: 'succeeded'
    },
    CONTACTS_GROUP_CLICKED: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'groups',
        outcome: 'succeeded'
    },
    CONTACTS_ALL_CONTACTS_CLICKED: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'all_contacts',
        outcome: 'succeeded'
    },
    CONTACTS_PEOPLE_CLICKED: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'people',
        outcome: 'succeeded'
    },
    CONTACTS_VC_ROOMS_CLICKED: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'vc_rooms',
        outcome: 'succeeded'
    },
    CONTACTS_RECENT_CHAT_BUTTON: {
        event: 'button_click',
        current_page: 'contacts_recents',
        clicked_text: 'chat',
        outcome: 'succeeded'
    },
    CONTACTS_RECENT_CHAT_BUTTON_FAILED: {
        event: 'button_click',
        current_page: 'contacts_recents',
        clicked_text: 'chat',
        outcome: 'failed'
    },
    CONTACTS_RECENT_AUDIO_CALL_BUTTON: {
        event: 'audio_call',
        current_page: 'contacts_recents',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACTS_RECENT_AUDIO_CALL_BUTTON_FAILED: {
        event: 'audio_call',
        current_page: 'contacts_recents',
        clicked_text: '',
        outcome: 'failed'
    },
    CONTACTS_RECENT_VIDEO_CALL_BUTTON: {
        event: 'video_call',
        current_page: 'contacts_recents',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACTS_RECENT_VIDEO_CALL_BUTTON_FAILED: {
        event: 'video_call',
        current_page: 'contacts_recents',
        clicked_text: '',
        outcome: 'failed'
    },
    CONTACTS_CREATE_GROUP: {
        event: 'create_group',
        current_page: 'contacts',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACTS_CREATE_GROUP_FAILED: {
        event: 'create_group',
        current_page: 'contacts',
        clicked_text: '',
        outcome: 'failed'
    },
    CONTACTS_DEFAULT_VC_ROOM_AUDIO_CALL: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'vc_rooms_start_call',
        outcome: 'succeeded'
    },
    CONTACTS_DEFAULT_VC_ROOM_AUDIO_CALL_FAILED: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'vc_rooms_start_call',
        outcome: 'failed'
    },
    CONTACTS_ADD_VC_ROOM_FAVOURITE: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'add_vc_room_to_favorites',
        outcome: 'succeeded'
    },
    CONTACTS_ADD_VC_ROOM_FAVOURITE_FAILED: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'add_vc_room_to_favorites',
        outcome: 'failed'
    },
    CONTACTS_REMOVE_VC_ROOM_FAVOURITE: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'remove_vc_room_from_favorites',
        outcome: 'succeeded'
    },
    CONTACTS_REMOVE_VC_ROOM_FAVOURITE_FAILED: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'remove_vc_room_from_favorites',
        outcome: 'failed'
    },
    CONTACTS_INDIVIDUAL_FROM_LIST_CHAT_CLICK: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'chat_from_menu',
        outcome: 'succeeded'
    },
    CONTACTS_INDIVIDUAL_FROM_LIST_CHAT_CLICK_FAILED: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'chat_from_menu',
        outcome: 'failed'
    },
    CONTACTS_INDIVIDUAL_FROM_LIST_AUDIO_CLICK: {
        event: 'audio_call',
        current_page: 'contact_options',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACTS_INDIVIDUAL_FROM_LIST_AUDIO_CLICK_FAILED: {
        event: 'audio_call',
        current_page: 'contact_options',
        clicked_text: '',
        outcome: 'failed'
    },
    CONTACTS_INDIVIDUAL_FROM_LIST_VIDEO_CLICK: {
        event: 'video_call',
        current_page: 'contact_options',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACTS_INDIVIDUAL_FROM_LIST_VIDEO_CLICK_FAILED: {
        event: 'video_call',
        current_page: 'contact_options',
        clicked_text: '',
        outcome: 'failed'
    },
    CONTACTS_INDIVIDUAL_DETAILED_CHAT_CLICK: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'chat',
        outcome: 'succeeded'
    },
    CONTACTS_INDIVIDUAL_DETAILED_CHAT_CLICK_FAILED: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'chat',
        outcome: 'failed'
    },
    CONTACTS_INDIVIDUAL_DETAILED_AUDIO_CLICK: {
        event: 'audio_call',
        current_page: 'contacts',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACTS_INDIVIDUAL_DETAILED_AUDIO_CLICK_FAILED: {
        event: 'audio_call',
        current_page: 'contacts',
        clicked_text: '',
        outcome: 'failed'
    },
    CONTACTS_INDIVIDUAL_DETAILED_VIDEO_CLICK: {
        event: 'video_call',
        current_page: 'contacts',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACTS_INDIVIDUAL_DETAILED_VIDEO_CLICK_FAILED: {
        event: 'video_call',
        current_page: 'contacts',
        clicked_text: '',
        outcome: 'failed'
    },
    CONTACTS_INDIVIDUAL_ADD_TO_FAVOURITE: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'add_to_favorites',
        outcome: 'succeeded'
    },
    CONTACTS_INDIVIDUAL_ADD_TO_FAVOURITE_FAILED: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'add_to_favorites',
        outcome: 'failed'
    },
    CONTACTS_INDIVIDUAL_REMOVE_FROM_FAVOURITE: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'remove_from_favorites',
        outcome: 'succeeded'
    },
    CONTACTS_INDIVIDUAL_REMOVE_FROM_FAVOURITE_FAILED: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'remove_from_favorites',
        outcome: 'failed'
    },
    CONTACTS_GROUP_INDIVIDUAL_FROM_LIST_AUDIO_CALL: {
        event: 'group_audio_call',
        current_page: 'contact_options',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACTS_GROUP_INDIVIDUAL_FROM_LIST_AUDIO_CALL_FAILED: {
        event: 'group_audio_call',
        current_page: 'contact_options',
        clicked_text: '',
        outcome: 'failed'
    },
    CONTACTS_GROUP_INDIVIDUAL_FROM_LIST_VIDEO_CALL: {
        event: 'group_video_call',
        current_page: 'contact_options',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACTS_GROUP_INDIVIDUAL_FROM_LIST_VIDEO_CALL_FAILED: {
        event: 'group_video_call',
        current_page: 'contact_options',
        clicked_text: '',
        outcome: 'failed'
    },
    CONTACTS_GROUP_INDIVIDUAL_DETAILED_AUDIO_CALL: {
        event: 'group_audio_call',
        current_page: 'contacts',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACTS_GROUP_INDIVIDUAL_DETAILED_AUDIO_CALL_FAILED: {
        event: 'group_audio_call',
        current_page: 'contacts',
        clicked_text: '',
        outcome: 'failed'
    },
    CONTACTS_GROUP_INDIVIDUAL_DETAILED_VIDEO_CALL: {
        event: 'group_video_call',
        current_page: 'contacts',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACTS_GROUP_INDIVIDUAL_DETAILED_VIDEO_CALL_FAILED: {
        event: 'group_video_call',
        current_page: 'contacts',
        clicked_text: '',
        outcome: 'failed'
    },
    CONTACTS_GROUP_INDIVIDUAL_FROM_LIST_OPEN_CHAT: {
        event: 'button_click',
        current_page: 'contact_options',
        clicked_text: 'group_chat_from_menu',
        outcome: 'succeeded'
    },
    CONTACTS_GROUP_INDIVIDUAL_FROM_LIST_OPEN_CHAT_FAILED: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'group_chat_from_menu',
        outcome: 'failed'
    },
    CONTACTS_GROUP_INDIVIDUAL_DETAILED_OPEN_CHAT: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'group_chat',
        outcome: 'succeeded'
    },
    CONTACTS_GROUP_INDIVIDUAL_DETAILED_OPEN_CHAT_FAILED: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'group_chat',
        outcome: 'failed'
    },
    CONTACTS_GROUP_ADD_FAVOURITE: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'add_group_to_favourites',
        outcome: 'succeeded'
    },
    CONTACTS_GROUP_ADD_FAVOURITE_FAILED: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'add_group_to_favourites',
        outcome: 'failed'
    },
    CONTACTS_GROUP_REMOVE_FAVOURITE: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'remove_group_from_favourites',
        outcome: 'succeeded'
    },
    CONTACTS_GROUP_REMOVE_FAVOURITE_FAILED: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'remove_group_from_favourites',
        outcome: 'failed'
    },
    CONTACTS_INDIVIDUAL_CONTACTS_OF_GROUP_CHAT: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'chat_with_member_through_group',
        outcome: 'succeeded'
    },
    CONTACTS_INDIVIDUAL_CONTACTS_OF_GROUP_CHAT_FAILED: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'chat_with_member_through_group',
        outcome: 'failed'
    },
    CONTACTS_OPEN_INDIVIDUAL_CONTACT_OF_GROUP: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'view_group_member_info',
        outcome: 'succeeded'
    },
    CONTACTS_INDIVIDUAL_CONTACT_OF_GROUP_AUDIO_CALL: {
        event: 'audio_call',
        current_page: 'contacts',
        clicked_text: 'member_in_group',
        outcome: 'succeeded'
    },
    CONTACTS_INDIVIDUAL_CONTACT_OF_GROUP_AUDIO_CALL_FAILED: {
        event: 'audio_call',
        current_page: 'contacts',
        clicked_text: 'member_in_group',
        outcome: 'failed'
    },
    CONTACTS_INDIVIDUAL_CONTACT_OF_GROUP_VIDEO_CALL: {
        event: 'video_call',
        current_page: 'contacts',
        clicked_text: 'member_in_group',
        outcome: 'succeeded'
    },
    CONTACTS_INDIVIDUAL_CONTACT_OF_GROUP_VIDEO_CALL_FAILED: {
        event: 'video_call',
        current_page: 'contacts',
        clicked_text: 'member_in_group',
        outcome: 'failed'
    },
    CONTACTS_LEAVE_GROUP: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'leave_group',
        outcome: 'yes'
    },
    CONTACTS_LEAVE_GROUP_CANCEL: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'leave_group',
        outcome: 'no'
    },
    CONTACTS_EDIT_GROUP_NAME: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'edit_group',
        outcome: 'save_changes'
    },
    CONTACTS_EDIT_GROUP_NAME_CANCEL: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'edit_group',
        outcome: 'cancel'
    },
    CONTACTS_DELETE_GROUP: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'delete_group',
        outcome: 'delete'
    },
    CONTACTS_DELETE_GROUP_CANCEL: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'delete_group',
        outcome: 'don’t_delete'
    },
    CONTACTS_ADD_MEMBERS_TO_GROUP: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'add_members',
        outcome: 'add'
    },
    CONTACTS_ADD_MEMBERS_TO_GROUP_CANCEL: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'add_members',
        outcome: 'cancel'
    },
    CONTACTS_REMOVE_MEMBER_FROM_GROUP: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'remove_member',
        outcome: 'remove'
    },
    CONTACTS_REMOVE_MEMBER_FROM_GROUP_CANCEL: {
        event: 'button_click',
        current_page: 'contacts',
        clicked_text: 'remove_member',
        outcome: 'don’t_remove'
    },
    CONTACTS_VCROOM_INDIVIDUAL_AUDIO_CALL: {
        event: 'audio_call',
        current_page: 'contacts_vc_rooms_options',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACTS_VCROOM_INDIVIDUAL_AUDIO_CALL_FAILED: {
        event: 'audio_call',
        current_page: 'contacts_vc_rooms_options',
        clicked_text: '',
        outcome: 'failed'
    },
    CONTACTS_VCROOM_INDIVIDUAL_VIDEO_CALL: {
        event: 'video_call',
        current_page: 'contacts_vc_rooms_options',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACTS_VCROOM_INDIVIDUAL_VIDEO_CALL_FAILED: {
        event: 'video_call',
        current_page: 'contacts_vc_rooms_options',
        clicked_text: '',
        outcome: 'failed'
    },
    CONTACTS_VCROOM_DETAILED_AUDIO_CALL: {
        event: 'audio_call',
        current_page: 'contacts_vc_rooms',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACTS_VCROOM_DETAILED_AUDIO_CALL_FAILED: {
        event: 'audio_call',
        current_page: 'contacts_vc_rooms',
        clicked_text: '',
        outcome: 'failed'
    },
    CONTACTS_VCROOM_DETAILED_VIDEO_CALL: {
        event: 'video_call',
        current_page: 'contacts_vc_rooms',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACTS_VCROOM_DETAILED_VIDEO_CALL_FAILED: {
        event: 'video_call',
        current_page: 'contacts_vc_rooms',
        clicked_text: '',
        outcome: 'failed'
    },
    BUY_NOW_COUNT_SUCCESS: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: 'buy_now',
        outcome: 'succeeded'
    },
    BUY_NOW_COUNT_FAILED: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: 'buy_now',
        outcome: 'failed'
    },

    PROCEED_TO_PAYMENT_FAILED: {
        event: 'button_click',
        current_page: 'buy_subscription_page',
        clicked_text: 'cancel',
        outcome: 'failed'
    },

    PROCEED_TO_PAYMENT_SUCCESS: {
        event: 'button_click',
        current_page: 'buy_subscription_page',
        clicked_text: 'proceed',
        outcome: 'succeeded'
    },

    REQUEST_ACCESS_CONTROL_SUCCESS: {
        event: 'button_click',
        current_page: 'inside_call',
        clicked_text: 'request_control',
        outcome: 'succeeded'
    },

    REQUEST_ACCESS_CONTROL_FAILED: {
        event: 'button_click',
        current_page: 'inside_call',
        clicked_text: 'request_control',
        outcome: 'failed'
    },

    REQUEST_CONTROL_POPUP_REQUEST_SUCCESS: {
        event: 'button_click',
        current_page: 'request_control_page',
        clicked_text: 'request',
        outcome: 'succeeded'
    },

    REQUEST_CONTROL_POPUP_REQUEST_FAILED: {
        event: 'button_click',
        current_page: 'request_control_page',
        clicked_text: 'request',
        outcome: 'failed'
    },

    REQUEST_CONTROL_POPUP_CANCEL_SUCCESS: {
        event: 'button_click',
        current_page: 'request_control_page',
        clicked_text: 'cancel',
        outcome: 'succeeded'
    },

    STOP_CONTROL_SUCCESS: {
        event: 'button_click',
        current_page: 'inside_call',
        clicked_text: 'stop_control',
        outcome: 'succeeded'
    },

    STOP_CONTROL_FAILED: {
        event: 'button_click',
        current_page: 'inside_call',
        clicked_text: 'stop_control',
        outcome: 'failed'
    },

    REQUEST_CONTROL_DENIED_PAGE_SUCCESS: {
        event: 'pageload',
        current_page: 'control_request_denied_page',
        outcome: 'succeeded'
    },

    REQUEST_CONTROL_DENIED_PAGE_FAILED: {
        event: 'pageload',
        current_page: 'request_control_denied_page',
        outcome: 'failed'
    },

    REQUEST_CONTROL_ERROR_PAGE_SUCCESS: {
        event: 'pageload',
        current_page: 'request_control_error_page',
        outcome: 'succeeded'
    },

    REQUEST_CONTROL_ERROR_PAGE_FAILED: {
        event: 'pageload',
        current_page: 'request_control_error_page',
        outcome: 'failed'
    },

    REQUEST_CONTROL_INITIATION_ERROR_PAGE_SUCCESS: {
        event: 'pageload',
        current_page: 'request_control_initiation_error_page',
        outcome: 'succeeded'
    },

    JIOMEET_LITE_POPUP_PAGE_SUCCESS: {
        event: 'pageload',
        current_page: 'jiomeet_lite_popup_page',
        outcome: 'succeeded'
    },

    STOP_SHARING_SUCCESS: {
        event: 'button_click',
        current_page: 'inside_call',
        clicked_text: 'stop_sharing',
        outcome: 'succeeded'
    },

    STOP_SHARING_FAILED: {
        event: 'button_click',
        current_page: 'inside_call',
        clicked_text: 'stop_sharing',
        outcome: 'failed'
    },

    STOP_SCREEN_CONTROL_SUCCESS: {
        event: 'button_click',
        current_page: 'inside_call',
        clicked_text: 'stop_screen_control',
        outcome: 'succeeded'
    },

    STOP_SCREEN_CONTROL_FAILED: {
        event: 'button_click',
        current_page: 'inside_call',
        clicked_text: 'stop_screen_control',
        outcome: 'failed'
    }
};
