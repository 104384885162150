export const dict = {
    Alt: 'alt',
    ArrowUp: 'up',
    ArrowRight: 'right',
    ArrowDown: 'down',
    ArrowLeft: 'left',
    Backspace: 'backspace',
    Control: 'control',
    Delete: 'delete',
    End: 'end',
    Enter: 'enter',
    Escape: 'escape',
    F1: 'f1',
    F2: 'f2',
    F3: 'f3',
    F4: 'f4',
    F5: 'f5',
    F6: 'f6',
    F7: 'f7',
    F8: 'f8',
    F9: 'f9',
    F10: 'f10',
    F11: 'f11',
    F12: 'f12',
    Home: 'home',
    Insert: 'insert',
    PageUp: 'pageup',
    PageDown: 'pagedown',
    Shift: 'shift',
    ' ': 'space',
    Tab: 'tab'
};
