import { Injectable, ɵConsole } from '@angular/core';
import { AppService } from './app.service';
import { LoggerService } from './logger.service';
import { BehaviorSubject, of, Observable, Subject } from 'rxjs';
import { VIDYO_EVENTS } from 'src/app/constants';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Speaker } from 'src/app/shared/interfaces/audio-settings';
import { RtcService } from './rtc.service';
import { SocketService } from './socket.service';
import { ErrorInterceptor } from '../interceptors';

declare var VC: any;
declare const window;

@Injectable({
    providedIn: 'root'
})
export class VidyoService {
    vidyoConnector;
    libraryLoaded = false;

    localParticipant;
    joinData;
    reconnecting = false;
    isUserOnline = true;

    users = {};
    vcs = {};
    microphoneMutedAll = false;
    roomLocked = false;
    recording = false;
    screenSharing = false;
    waitingUsers = {};
    cameras = [];
    microphones = [];
    speakers = [];
    selectedLocalCamera = 0;
    selectedLocalMicrophone = 0;
    selectedLocalSpeaker = 0;
    userPreferredDevices = {
        micId: null,
        speakerId: null,
        cameraId: null
    };

    private disconneting = false;
    private alreadySharingScreen = false;
    private windowShares = {};
    private whiteboardShared;

    private screenShareInfo = {
        isShared: false,
        host: '',
        id: ''
    };

    private meeting$: BehaviorSubject<any> = new BehaviorSubject(null);
    private chatMessages$: BehaviorSubject<any> = new BehaviorSubject(null);
    private deviceChanged$: BehaviorSubject<any> = new BehaviorSubject(null);
    private deviceLoaded$: BehaviorSubject<any> = new BehaviorSubject(null);
    private participantsStatus$: BehaviorSubject<any> = new BehaviorSubject(null);
    public isVideoOn = new Subject<boolean>();

    waitUntilRoomMove: boolean = false;
    hideScreenShareNotification = new Subject<boolean>();

    constructor(
        private loggerService: LoggerService,
        private appService: AppService,
        private toastrService: ToastrService,
        private rtcService: RtcService,
        private socketService: SocketService,
        private translateService: TranslateService
    ) {
        this.handleWindowEvents();
    }

    getMeetingObs() {
        return this.meeting$;
    }

    getChatMessages() {
        return this.chatMessages$;
    }

    getDeviceCahnge() {
        return this.deviceChanged$;
    }

    getDeviceLoaded() {
        return this.deviceLoaded$;
    }

    getParticipantStatus() {
        return this.participantsStatus$;
    }

    intializeValue = () => {
        this.users = {};
        this.cameras = [];
        this.microphones = [];
        this.speakers = [];
        this.selectedLocalCamera = 0;
        this.selectedLocalMicrophone = 0;
        this.selectedLocalSpeaker = 0;
        this.disconneting = false;
        this.reconnecting = false;
    };

    loadVidyoSDK = () => {
        if (typeof VC !== 'undefined') {
            return;
        }
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.appService.getEnvVariable('local')
            ? this.appService
                  .getConfigVariable('VIDYO_SDK_PATH')
                  .replace('VidyoClient.min.js', 'VidyoClient.local.min.js')
            : this.appService.getConfigVariable('VIDYO_SDK_PATH');
        script.onload = () => {
            this.onLoad({ state: 'READY', description: 'Native SCIP + WebRTC' });
        };
        document.getElementsByTagName('head')[0].appendChild(script);
        var style = document.createElement('link');
        style.rel = 'stylesheet';
        style.type = 'text/css';
        style.href = this.appService.getConfigVariable('VIDYO_SDK_STYLESHEET_PATH');
        document.getElementsByTagName('head')[0].appendChild(style);
    };

    private onLoad(status) {
        switch (status.state) {
            case 'READY':
                this.loggerService.log('Library loaded sucessfully.');

                if (!this.libraryLoaded) {
                    this.libraryLoaded = true;
                    window.VC = new window.VidyoClientLib.VidyoClient('', () => {
                        // After the VidyoClient is successfully initialized a global VC object will become available
                        // All of the VidyoConnector gui and logic is implemented in VidyoConnector.js
                        // StartVidyoConnector(VC, window.VCUtils ? window.VCUtils.params.webrtc : 'true');
                        this.initializeVidyoConnector();
                    });
                }

                break;
            case 'RETRYING':
                this.loggerService.error('Temporarily unavilable. Retrying...');
                break;
            case 'FAILED':
                // retry here
                this.loggerService.error('Failed to load library');
                this.loggerService.error(status.description);
                alert('An error occurred, please reload');
                break;
            case 'FAILEDVERSION':
                this.loggerService.error(status.description);
                this.loggerService.error(status.downloadPathPlugIn);
                this.loggerService.error(status.downloadPathApp);
                break;
            case 'NOTAVAILABLE':
                this.loggerService.error(status.description);
                break;
            case 'TIMEDOUT':
                this.loggerService.error('Library loading timeout');
                break;
        }
    }

    setJoinData({
        host,
        displayName,
        roomKey,
        roomPin,
        micPrivacy,
        cameraPrivacy,
        isInitiater = false,
        viewId = 'renderer',
        isEmbedInMobile = false,
        unlockAndJoin = false,
        meetingObj = null,
        autoJoin = true,
        userId = ''
    }) {
        this.joinData = {
            host,
            displayName,
            roomKey,
            roomPin,
            micPrivacy,
            cameraPrivacy,
            isInitiater,
            viewId,
            isEmbedInMobile,
            unlockAndJoin,
            meetingObj,
            autoJoin,
            userId
        };
    }

    initializeVidyoConnector() {
        if (typeof VC === 'undefined') {
            this.loadVidyoSDK();
            return;
        }
        this.intializeValue();
        // if (this.vidyoConnector) {
        //   this.checkViyoConnectorStateAndJoin();
        //   return;
        // }
        VC.CreateVidyoConnector({
            viewId: this.joinData.viewId, //  'renderer',
            viewStyle: 'VIDYO_CONNECTORVIEWSTYLE_Default',
            remoteParticipants: 8,
            logFileFilter:
                'all@VidyoDevelopment debug@VidyoClient debug@VidyoSDP debug@VidyoResourceManager all@VidyoSignaling',
            logFileName: '',
            userData: 0
        }).then((vc) => {
            if (this.joinData.autoJoin) {
                this.meeting$.next({ type: 'VC_CREATED', data: null });
            }
            this.meeting$.next({ type: 'SHOW_PREVIEW_CNTROLS', data: null });
            this.vidyoConnector = vc;
            this.showRenderer();
            this.handleParticipantChange();
            this.registerDeviceListeners();
            this.registerMessageListener();
            this.registerRemoteShareListener();
            this.registerLocalShareListener();
            this.setPrivacy();
            if (this.joinData.autoJoin) {
                this.handleReconnect();
                this.setReconnectionConfig();
                this.checkViyoConnectorStateAndJoin();
            }
        });
    }

    flipCamera() {
        const selectedCamera = this.selectedLocalCamera;
        let newCameraId;
        if (this.cameras.length > 1) {
            for (let i = 0; i < this.cameras.length; i++) {
                if (this.cameras[i].id === selectedCamera) {
                    if (i + 1 >= this.cameras.length) newCameraId = this.cameras[0];
                    else newCameraId = this.cameras[i + 1];
                }
            }

            if (newCameraId) {
                this.manuallyChangeCamera(newCameraId);
            } else {
                this.manuallyChangeCamera(this.cameras[0]);
            }
        }
    }

    private setPrivacy() {
        this.vidyoConnector.SetMicrophonePrivacy({ privacy: !this.joinData.micPrivacy });
        this.vidyoConnector.SetCameraPrivacy({ privacy: !this.joinData.cameraPrivacy });
    }

    private setReconnectionConfig() {
        this.vidyoConnector.SetAdvancedConfiguration({
            enableAutoReconnect: this.appService.getConfigVariable('reconnectionConfig')?.enableAutoReconnect || false,
            maxReconnectAttempts: this.appService.getConfigVariable('reconnectionConfig')?.maxReconnectAttempts || 3,
            reconnectBackoff: this.appService.getConfigVariable('reconnectionConfig')?.reconnectBackoff || 5
        });
    }

    private showRenderer() {
        const rndr = document.getElementById(this.joinData.viewId);
        if (rndr) {
            this.vidyoConnector.ShowViewAt({
                viewId: this.joinData.viewId, // 'renderer',
                x: rndr.offsetLeft,
                y: rndr.offsetTop,
                width: rndr.offsetWidth,
                height: rndr.offsetHeight
            });
        }
    }

    private checkViyoConnectorStateAndJoin() {
        const timeout = setTimeout(() => {
            this.meeting$.next({ type: 'INACTIVE', data: null });
        }, 10000);
        this.vidyoConnector.GetState().then((state) => {
            clearInterval(timeout);
            if (state !== 'VIDYO_CONNECTORSTATE_Ready') {
                this.meeting$.next({ type: 'INACTIVE', data: null });
                return;
            }
            if (this.joinData.unlockAndJoin) {
                const payload = {
                    roomID: this.joinData?.meetingObj?.room_id?.toString(),
                    meetingId: this.joinData?.meetingObj?.meetingId,
                    historyId: this.joinData?.meetingObj?.historyId,
                    unlockedToJoin: true
                };
                this.rtcService.unlockRoom(payload).subscribe(() => {
                    this.joinRoom(this.joinData);
                });
            } else {
                this.joinRoom(this.joinData);
            }
        });
    }

    userTalkListener(callback): void {
        this.vidyoConnector.RegisterLocalMicrophoneEnergyListener({
            onEnergy: callback
        });
    }
    private registerDeviceListeners() {
        this.vidyoConnector
            .RegisterLocalCameraEventListener({
                onAdded: (localCamera) => {
                    const camera = {
                        ...localCamera,
                        name: localCamera?.name.replace('(Built-in)', '')
                    };
                    this.cameras.push(camera);
                    if (this.joinData.isEmbedInMobile) {
                        if (localCamera.name.includes('front')) {
                            this.vidyoConnector.SelectLocalCamera({ localCamera: localCamera });
                        }
                    }
                    if (!this.joinData.autoJoin) {
                        if (this.userPreferredDevices.cameraId) {
                            if (this.userPreferredDevices.cameraId === localCamera.id) {
                                this.vidyoConnector.SelectLocalCamera({ localCamera });
                            }
                        } else if (localCamera.name.toLowerCase().includes('default')) {
                            this.vidyoConnector.SelectLocalCamera({ localCamera });
                        }
                    } else {
                        if (localCamera.name.toLowerCase().includes('default')) {
                            this.vidyoConnector.SelectLocalCamera({ localCamera });
                        }
                    }
                    this.sendControlStatus();
                },
                onRemoved: (localCamera) => {
                    this.cameras = this.cameras.filter((camera) => camera.id !== localCamera.id);
                    if (this.cameras.length <= 1) {
                        this.selectedLocalCamera = 0;
                    }
                    this.sendControlStatus();
                },
                onSelected: (localCamera) => {
                    if (localCamera) {
                        this.selectedLocalCamera = localCamera.id;
                        document.cookie = `cacheCamera=${localCamera.id}`;
                        this.joinData.displayName = this.joinData.displayName || '';
                        localCamera.SetPreviewLabel({
                            previewLabel:
                                this.joinData.displayName.length <= 25
                                    ? this.joinData.displayName
                                    : this.joinData.displayName.substr(0, 25) + '...'
                        });
                    } else {
                        this.selectedLocalCamera = 0;
                    }
                    this.sendControlStatus();
                },
                onStateUpdated: (localCamera, state) => {
                    if (localCamera) {
                        this.joinData.displayName = this.joinData.displayName || '';
                        localCamera.SetPreviewLabel({
                            previewLabel:
                                this.joinData.displayName.length <= 25
                                    ? this.joinData.displayName
                                    : this.joinData.displayName.substr(0, 25) + '...'
                        });
                    }
                    Object.keys(this.users).forEach((id) => {
                        // if (this.users[id].isLocal) {
                        //   if (state === 'VIDYO_DEVICESTATE_Started') {
                        //     this.users[id].cameraMute = false;
                        //   } else if (state === 'VIDYO_DEVICESTATE_Stopped') {
                        //     this.users[id].cameraMute = true;
                        //   }
                        // }
                    });
                    this.sendControlStatus();
                }
            })
            .then(() => {
                this.loggerService.log('RegisterLocalCameraEventListener Success');
                this.deviceLoaded$.next({ type: 'VIDEO_LOADED' });
            })
            .catch(() => {
                this.loggerService.error('RegisterLocalCameraEventListener Failed');
            });

        this.vidyoConnector
            .RegisterLocalMicrophoneEventListener({
                onAdded: (localMicrophone) => {
                    const mic = {
                        ...localMicrophone,
                        name: localMicrophone?.name.replace('(Built-in)', '')
                    };
                    this.microphones.push(mic);
                    if (this.joinData.isEmbedInMobile) {
                        if (localMicrophone.name.includes('Speaker')) {
                            this.vidyoConnector.SelectLocalMicrophone(mic);
                        }
                    }
                    if (!this.joinData.autoJoin) {
                        if (this.userPreferredDevices.micId) {
                            if (this.userPreferredDevices.micId === localMicrophone.id) {
                                this.vidyoConnector.SelectLocalMicrophone({ localMicrophone });
                            }
                        } else if (localMicrophone.name.toLowerCase().includes('default')) {
                            this.vidyoConnector.SelectLocalMicrophone({ localMicrophone });
                        }
                    } else {
                        if (localMicrophone.name.toLowerCase().includes('default')) {
                            this.vidyoConnector.SelectLocalMicrophone({ localMicrophone });
                        }
                    }
                    this.sendControlStatus();
                },
                onRemoved: (localMicrophone) => {
                    this.microphones = this.microphones.filter((microphone) => microphone.id !== localMicrophone.id);
                    if (this.microphones.length <= 1) {
                        this.selectedLocalMicrophone = 0;
                    }
                    this.sendControlStatus();
                },
                onSelected: (localMicrophone) => {
                    if (localMicrophone) {
                        this.selectedLocalMicrophone = localMicrophone.id;
                        document.cookie = `cacheMic=${localMicrophone.id}`;
                    } else {
                        this.selectedLocalMicrophone = 0;
                    }
                    this.sendControlStatus();
                },
                onStateUpdated: (localMicrophone, state) => {
                    Object.keys(this.users).forEach((id) => {
                        // if (this.users[id].isLocal) {
                        //   if (state === 'VIDYO_DEVICESTATE_Started') {
                        //     this.users[id].microphoneMute = false;
                        //   } else if (state === 'VIDYO_DEVICESTATE_Stopped') {
                        //     this.users[id].microphoneMute = true;
                        //   }
                        // }
                    });
                    this.sendControlStatus();
                }
            })
            .then(() => {
                this.deviceLoaded$.next({ type: 'AUDIO_LOADED' });
                this.loggerService.log('RegisterLocalMicrophoneEventListener Success');
            })
            .catch(() => {
                this.loggerService.error('RegisterLocalMicrophoneEventListener Failed');
            });

        this.vidyoConnector
            .RegisterLocalSpeakerEventListener({
                onAdded: (localSpeaker) => {
                    const spk = {
                        ...localSpeaker,
                        name: localSpeaker?.name.replace('(Built-in)', '')
                    };
                    if (!this.speakers.find((speaker) => speaker.id === spk.id)) {
                        this.speakers.push(spk);
                    }
                    if (!this.joinData.autoJoin) {
                        if (this.userPreferredDevices.speakerId) {
                            if (this.userPreferredDevices.speakerId === localSpeaker.id) {
                                this.vidyoConnector.SelectLocalSpeaker({ localSpeaker });
                            }
                        } else if (localSpeaker.name.toLowerCase().includes('default')) {
                            this.vidyoConnector.SelectLocalSpeaker({ localSpeaker });
                        }
                    } else {
                        if (localSpeaker.name.toLowerCase().includes('default')) {
                            this.vidyoConnector.SelectLocalSpeaker({ localSpeaker });
                        }
                    }
                    this.sendControlStatus();
                },
                onRemoved: (localSpeaker) => {
                    this.speakers = this.speakers.filter((speaker) => speaker.id !== localSpeaker.id);
                    if (this.speakers.length <= 1) {
                        this.selectedLocalSpeaker = 0;
                    }
                    this.sendControlStatus();
                },
                onSelected: (localSpeaker) => {
                    console.log(localSpeaker);
                    if (localSpeaker) {
                        this.selectedLocalSpeaker = localSpeaker.id;
                        document.cookie = `cacheSpeaker=${localSpeaker.id}`;
                    } else {
                        this.selectedLocalSpeaker = 0;
                    }
                    this.sendControlStatus();
                },
                onStateUpdated: (localSpeaker, state) => {
                    console.log(localSpeaker);

                    Object.keys(this.users).forEach((id) => {
                        // if (this.users[id].isLocal) {
                        //   if (state === 'VIDYO_DEVICESTATE_Started') {
                        //     this.users[id].speakerMute = false;
                        //   } else if (state === 'VIDYO_DEVICESTATE_Stopped') {
                        //     this.users[id].speakerMute = true;
                        //   }
                        // }
                    });
                    this.sendControlStatus();
                }
            })
            .then(() => {
                this.loggerService.log('RegisterLocalSpeakerEventListener Success');
            })
            .catch(() => {
                this.loggerService.error('RegisterLocalSpeakerEventListener Failed');
            });

        this.vidyoConnector
            .RegisterRemoteMicrophoneEventListener({
                onAdded: (microphone, participant) => {
                    if (this.microphoneMutedAll) {
                        this.sendChatMessage({
                            type: 'PrivateChat',
                            message: VIDYO_EVENTS.PARTICIPANT_STOP_AUDIO,
                            targetParticipantId: participant.id,
                            targetParticipantName: participant.name
                        });
                    }
                },
                onRemoved: (microphone, participant) => {
                    this.addUser(participant); // if not added locally
                    this.users[participant.id].microphoneMute = true;
                    this.sendControlStatus();
                },
                onStateUpdated: (microphone, participant, state) => {
                    if (!this.users[participant.id]) {
                        this.users[participant.id] = participant;
                    }

                    if (state === 'VIDYO_DEVICESTATE_Resumed') {
                        this.users[participant.id].microphoneMute = false;
                    } else if (state === 'VIDYO_DEVICESTATE_Paused') {
                        this.users[participant.id].microphoneMute = true;
                    }

                    this.sendControlStatus();
                }
            })
            .then(() => {
                this.loggerService.log('RegisterRemoteMicrophoneEventListener Success');
            })
            .catch(() => {
                this.loggerService.error('RegisterRemoteMicrophoneEventListener Failed');
            });

        this.vidyoConnector
            .RegisterRemoteCameraEventListener({
                onAdded: (microphone, participant) => {
                    this.addUser(participant); // if not added locally
                    this.users[participant.id].cameraMute = false;
                    this.sendControlStatus();
                },
                onRemoved: (microphone, participant) => {
                    this.addUser(participant); // if not added locally
                    this.users[participant.id].cameraMute = true;
                    this.sendControlStatus();
                },
                onStateUpdated: (microphone, participant, state) => {
                    if (this.users[participant.id]) {
                        if (state === 'VIDYO_DEVICESTATE_Resumed') {
                            this.users[participant.id].cameraMute = false;
                        } else if (state === 'VIDYO_DEVICESTATE_Paused') {
                            this.users[participant.id].cameraMute = true;
                        }
                    }
                    this.sendControlStatus();
                }
            })
            .then(() => {
                this.loggerService.log('RegisterRemoteCameraEventListener Success');
            })
            .catch(() => {
                this.loggerService.error('RegisterRemoteCameraEventListener Failed');
            });
    }

    private handleParticipantChange() {
        this.vidyoConnector
            .ReportLocalParticipantOnJoined({
                reportLocalParticipant: true
            })
            .then(() => {
                this.loggerService.log('ReportLocalParticipantOnJoined Success');
            })
            .catch(() => {
                this.loggerService.error('ReportLocalParticipantOnJoined Failed');
            });

        this.vidyoConnector
            .RegisterParticipantEventListener({
                onJoined: (participant) => {
                    participant.IsLocal().then((isLocal) => {
                        this.loggerService.log('[vc] participant onJoined= ' + JSON.stringify(participant));
                        if (isLocal) {
                            participant.isLocal = true;
                            if (this.joinData.isInitiater) {
                                participant.isHost = true;
                            }
                            participant.microphoneMute = !this.joinData.micPrivacy;
                            participant.cameraMute = !this.joinData.cameraPrivacy;
                            this.localParticipant = participant;
                            this.users[participant.id] = participant;
                            this.meeting$.next({
                                type: 'LOCAL_PARTICIPANT_CONNECTED',
                                data: null
                            });
                            this.isUserOnline = true;
                            this.disconneting = false;
                        } else {
                            if (this.joinData.isInitiater) {
                                this.sendChatMessage({
                                    type: 'PublicChat',
                                    message: VIDYO_EVENTS.HOST_IDENTITY
                                });
                            }
                            this.addUser(participant);
                            if (typeof this.users[participant.id].cameraMute === 'undefined') {
                                participant.cameraMute = true;
                            }

                            // send recording status for newly joined guest user
                            if (this.recording && this.joinData?.isInitiater) {
                                setTimeout(() => {
                                    this.meeting$.next({
                                        type: 'SEND_RECORDING_NOTIFICATION_TO_NEWLY_JOINED',
                                        data: null
                                    });
                                }, 2000);
                            }
                        }
                        if (participant.AppType === 'gateway' && participant.name !== 'Recorder') {
                            this.addVC(participant);
                        }
                        if (participant.isLocal) {
                            this.sendControlStatus();
                        } else {
                            this.sendControlStatus({ participantJoined: true });
                        }
                    });
                },
                onLeft: (participant) => {
                    this.loggerService.log('[vc] participant onLeft= ' + JSON.stringify(participant));
                    if (
                        !this.disconneting &&
                        this.isUserOnline &&
                        window?.navigator?.onLine &&
                        this.localParticipant.id !== participant.id
                    ) {
                        if (this.users && this.users[participant.id] && this.users[participant.id].disconnectedByHost) {
                            this.toastrService.error(
                                this.translateService.instant('tostrs.been_removed', {
                                    value: participant.name
                                })
                            );
                        } else {
                            // this.toastrService.warning(`${participant.name} left the call.`);
                        }
                    }

                    delete this.vcs[participant.id];
                    delete this.users[participant.id];
                    if (this.localParticipant.id !== participant.id) {
                        this.sendControlStatus({ participantLeft: true });
                    } else {
                        this.sendControlStatus();
                    }
                },
                onDynamicChanged: (participants) => {
                    this.loggerService.log(participants);
                },
                onLoudestChanged: (participant, audioOnly) => {
                    this.loggerService.log('[vc] participant Speaking= ' + JSON.stringify(participant));
                    this.loggerService.log(`audioOnly ${audioOnly}`);
                }
            })
            .then(() => {
                this.loggerService.log('RegisterParticipantEventListener Success');
            })
            .catch(() => {
                this.loggerService.error('RegisterParticipantEventListener Failed');
            });

        this.vidyoConnector
            .RegisterRecorderInCallEventListener({
                onRecorderInCallChanged: (hasRecorder, isPaused, isWebcasting) => {
                    if (hasRecorder && !isPaused) {
                        // resume
                        this.meeting$.next({
                            type: 'RECORD_STATUS',
                            data: 'resume'
                        });
                    } else if (hasRecorder && isPaused) {
                        // paused
                        this.meeting$.next({
                            type: 'RECORD_STATUS',
                            data: 'paused'
                        });
                    } else if (!hasRecorder) {
                        // stopped
                        this.meeting$.next({
                            type: 'RECORD_STATUS',
                            data: 'stopped'
                        });
                    }
                }
            })
            .then(() => {
                console.log('RegisterRecorderInCallEventListener Success');
            })
            .catch(() => {
                console.error('RegisterRecorderInCallEventListener Failed');
            });
    }

    private handleReconnect() {
        this.vidyoConnector
            .RegisterReconnectEventListener({
                onReconnecting: (attempt, attemptTimeout, lastReason) => {
                    this.reconnecting = true;
                    // if (attempt === 1) {
                    //   this.toastrService.info('Reconnecting...', '', {
                    //     positionClass: 'toast-top-center',
                    //     timeOut: 60 * 60 * 1000,
                    //     extendedTimeOut: 60 * 60 * 1000
                    //   });
                    // }

                    this.meeting$.next({
                        type: 'RE_CONNECTION_START',
                        data: null
                    });

                    console.warn(
                        `Reconnecting: attempt=${attempt}, attemptTimeout=${attemptTimeout}s, lastReason=${lastReason}`
                    );
                },
                onReconnected: () => {
                    this.reconnecting = false;
                    this.toastrService.clear();
                    // this.toastrService.success('Connected', '', {
                    //   positionClass: 'toast-top-center'
                    // });
                    this.meeting$.next({
                        type: 'RE_CONNECTED',
                        data: null
                    });
                    // force reconnect socket to avoid socket failures
                    this.socketService.reconnect();
                },
                onConferenceLost: (lastReason) => {
                    this.meeting$.next({
                        type: 'CONNECTION_LOST',
                        data: null
                    });
                    console.warn(`ConferenceLost: lastReason=${lastReason}`);
                }
            })
            .then(() => {
                console.log('RegisterReconnectEventListener Success');
            })
            .catch(() => {
                console.error('RegisterReconnectEventListener Failed');
            });
    }

    reConnectOnConnectionOnline() {
        this.joinData.micPrivacy = !this.getLocalParticipant().microphoneMute;
        this.joinData.cameraPrivacy = !this.getLocalParticipant().cameraMute;
        this.joinRoom(this.joinData);
    }

    private addUser(participant) {
        if (!this.users[participant.id]) {
            this.users[participant.id] = participant;
        }
    }

    private addVC(participant) {
        if (!this.vcs[participant.id]) {
            participant.isLegacy = true;
            this.vcs[participant.id] = participant;
        }
    }

    private registerMessageListener = () => {
        this.vidyoConnector
            .RegisterMessageEventListener({
                onChatMessageReceived: (participant, chatMessage) => {
                    if (participant === null) {
                        return;
                    }
                    const jsonObject = JSON.parse(chatMessage.body);
                    console.log(jsonObject);
                    this.chatMessages$.next({
                        message: chatMessage.body,
                        sender: participant,
                        receiver: this.localParticipant
                    });
                }
            })
            .then(() => {
                this.loggerService.log('RegisterMessageEventListener Success');
            })
            .catch(() => {
                this.loggerService.error('RegisterMessageEventListener Failed');
            });
    };

    private registerRemoteShareListener = () => {
        this.vidyoConnector.RegisterRemoteWindowShareEventListener({
            onAdded: (VidyoRemoteWindowShareObj, VidyoParticipantObj) => {
                // this.toastrService.info(
                //   this.translateService.instant('tostrs.you_are_viewing_screen', {
                //     value: VidyoParticipantObj.name
                //   })
                // );
                this.screenShareInfo = {
                    host: VidyoParticipantObj.name,
                    isShared: true,
                    id: VidyoParticipantObj?.id
                };
                if (this.screenSharing) {
                    this.meeting$.next({ type: 'SHARE_STOP', data: null });
                }
                if (this.whiteboardShared) {
                    this.meeting$.next({ type: 'WHITEBOARD_STOP', data: null });
                }
                this.sendControlStatus();
            },
            onRemoved: (VidyoRemoteWindowShareObj, VidyoParticipantObj) => {
                if (!this.disconneting) {
                    // this.toastrService.info(
                    //   this.translateService.instant('tostrs.stopped_screen_share', {
                    //     value: VidyoParticipantObj.name
                    //   })
                    // );
                }
                // this.toastrService.info(`${VidyoParticipantObj.name} stopped screen share`);
                if (this.screenShareInfo?.id === VidyoParticipantObj?.id) {
                    this.screenShareInfo = {
                        host: '',
                        isShared: false,
                        id: ''
                    };
                }

                this.sendControlStatus();
            },
            onStateUpdated: (VidyoRemoteWindowShareObj, VidyoParticipantObj, stateObj) => {}
        });
    };

    private registerLocalShareListener = () => {
        this.vidyoConnector
            .RegisterLocalWindowShareEventListener({
                onAdded: (localWindowShare) => {
                    // New share is available so add it to the windowShares array and the drop-down list
                    if (localWindowShare.name !== '') {
                        if (!Object.keys(this.windowShares).length) {
                            localWindowShare.SetFrameInterval(16666666.66);
                            this.windowShares[localWindowShare.id] = localWindowShare;
                        }
                    }
                },
                onRemoved: (localWindowShare) => {
                    delete this.windowShares[localWindowShare.id];
                },
                onSelected: (localWindowShare) => {
                    if (!localWindowShare) {
                        this.meeting$.next({ type: 'SHARE_STOP', data: null });
                        // this.toggleWindowShare(false);
                    } else {
                        this.screenSharing = true;
                        this.sendControlStatus();
                    }
                },
                onStateUpdated: (localWindowShare, state) => {}
            })
            .then(() => {})
            .catch(() => {});
    };

    joinRoom({ host, displayName, roomKey, roomPin, micPrivacy, cameraPrivacy }) {
        host = host.replace('https://', '');

        displayName = displayName.length <= 25 ? displayName : displayName.substr(0, 25) + '...';
        // this.vidyoConnector.SelectDefaultCamera();
        // this.vidyoConnector.SelectDefaultMicrophone();
        // this.vidyoConnector.SelectDefaultSpeaker();
        this.vidyoConnector.SetMicrophonePrivacy({ privacy: !micPrivacy });
        this.vidyoConnector.SetCameraPrivacy({ privacy: !cameraPrivacy });
        this.vidyoConnector
            .ConnectToRoomAsGuest({
                host,
                displayName,
                roomKey,
                roomPin,
                onSuccess: () => {
                    // select last manually selected camera, microphones and speaker
                    this.selectDevices();
                    this.meeting$.next({ type: 'ACTIVE', data: null });
                    this.showRenderer();
                    this.waitUntilRoomMove = false;
                },
                onFailure: (reason) => {
                    if (reason === 'VIDYO_CONNECTORFAILREASON_RoomLocked') {
                        this.meeting$.next({
                            type: 'FAILURE',
                            data: {
                                reason: 'Meeting room is locked.',
                                code: 'LOCKED'
                            }
                        });
                        return;
                    }
                    this.meeting$.next({
                        type: 'FAILURE',
                        data: {
                            reason: "Oops We couldn't connect to the meeting. Please refresh page and try again."
                        }
                    });

                    // VIDYO_CONNECTORFAILREASON_ConnectionFailed,
                    //   VIDYO_CONNECTORFAILREASON_InvalidResourceId,
                    //   VIDYO_CONNECTORFAILREASON_InvalidToken;
                    this.loggerService.error(reason);
                },
                onDisconnected: (reason) => {
                    // this.showRenderer();
                    if (reason === 'VIDYO_CONNECTORDISCONNECTREASON_Booted') {
                        this.meeting$.next({ type: 'END_CALL', data: null });
                    }
                }
            })
            .then((status) => {
                if (status) {
                    this.loggerService.log('[vc] Connect Success');
                } else {
                    this.loggerService.error('[vc] Connect Failed');
                }
            })
            .catch(() => {
                this.loggerService.error('[vc] Connect Failed');
            });
    }

    registerCameraFramesListener() {
        this.vidyoConnector.OnLocalCameraFrame((obj1, obj2) => {
            console.log(obj1, obj2);
        });
    }

    selectDevices() {
        // const speakerId = this.getCookie('cacheSpeaker');
        // const micId = this.getCookie('cacheMic');
        // const cameraId = this.getCookie('cacheCamera');

        if (this.reconnecting) return;
        const speakerId = this.userPreferredDevices.speakerId;
        const micId = this.userPreferredDevices.micId;
        const cameraId = this.userPreferredDevices.cameraId;
        if (speakerId) {
            this.speakers.forEach((speaker) => {
                if (speaker.id === speakerId) {
                    this.manuallyChangeSpeaker(speaker);
                }
            });
        }
        if (micId) {
            this.microphones.forEach((mic) => {
                if (mic.id === micId) {
                    this.manuallyChangeMicrophone(mic);
                }
            });
        }
        if (cameraId) {
            this.cameras.forEach((camera) => {
                if (camera.id === cameraId) {
                    this.manuallyChangeCamera(camera);
                }
            });
        }
    }

    manuallyChangeSpeaker(localSpeaker: Speaker) {
        document.cookie = `cacheSpeaker=${localSpeaker.id}`;
        this.userPreferredDevices.speakerId = localSpeaker.id;
        this.vidyoConnector.SelectLocalSpeaker({ localSpeaker });
    }
    manuallyChangeMicrophone(localMicrophone: Speaker) {
        document.cookie = `cacheMic=${localMicrophone.id}`;
        this.userPreferredDevices.micId = localMicrophone.id;
        this.vidyoConnector.SelectLocalMicrophone({ localMicrophone });
    }
    manuallyChangeCamera(localCamera: Speaker) {
        document.cookie = `cacheCamera=${localCamera.id}`;
        this.userPreferredDevices.cameraId = localCamera.id;
        this.vidyoConnector.SelectLocalCamera({ localCamera });
    }

    releaseDevices() {
        try {
            if (this.vidyoConnector) {
                this.vidyoConnector.SelectLocalCamera({
                    localCamera: null
                });
                this.vidyoConnector.SelectLocalMicrophone({
                    localMicrophone: null
                });
                this.vidyoConnector.SelectLocalSpeaker({
                    localSpeaker: null
                });
            }
        } catch (e) {
            console.error('Error releasing devices');
        }
    }

    leaveRoom() {
        this.disconneting = true;
        // this.vidyoConnector.SelectLocalCamera({
        //   localCamera: null
        // });
        // this.vidyoConnector.SelectLocalMicrophone({
        //   localMicrophone: null
        // });
        // this.vidyoConnector.SelectLocalSpeaker({
        //   localSpeaker: null
        // });
        this.toggleWindowShare(false);
        this.vidyoConnector
            .Disconnect()
            .then(() => {})
            .catch(() => {
                this.loggerService.error('Disconnect Failure');
            });
    }

    toggleCameraPrivacy(enable) {
        this.vidyoConnector
            .SetCameraPrivacy({ privacy: enable })
            .then(() => {
                this.loggerService.log('Camera privaccy changed success');
            })
            .catch(() => {
                this.loggerService.error('Camera privaccy changed failure.');
            });
        if (this.localParticipant) {
            (this.users[this.localParticipant.id] || {}).cameraMute = enable;
        }
        this.sendControlStatus();
    }

    toggleMicPrivacy(enable) {
        this.vidyoConnector.SetMicrophonePrivacy({ privacy: enable });
        if (this.localParticipant) {
            (this.users[this.localParticipant.id] || {}).microphoneMute = enable;
        }
        this.sendControlStatus();
    }

    toggleRecordingStatus(enable) {
        this.recording = enable;
        this.sendControlStatus();
    }

    toggleMicAll(enable) {
        this.microphoneMutedAll = enable;
        this.sendControlStatus();
    }

    toggleLockStatus(enable) {
        this.roomLocked = enable;
        this.sendControlStatus();
    }

    setHostDisconnectParticipant(id) {
        if (this.users[id]) {
            this.users[id].disconnectedByHost = true;
        }
    }

    sendChatMessage({ type, message, targetParticipantId = '', targetParticipantName = '', targetHostUserId = '' }) {
        this.vidyoConnector.SendChatMessage({
            message: JSON.stringify({
                type,
                message,
                targetParticipantId,
                targetParticipantName,
                targetHostUserId
            })
        });
    }

    setHostIdentity(sender) {
        if (this.users[sender.id]) {
            this.users[sender.id].isHost = true;
        }
    }

    toggleWindowShare(enable) {
        // this.vidyoConnector.ShowWindowSharePreview({ preview: true }).then((status) => {
        //   console.log(status);
        // });
        if (!enable) {
            this.vidyoConnector.SelectLocalWindowShare({
                localWindowShare: null
            });
            this.screenSharing = false;
            this.sendControlStatus();
            return;
        }

        // if (Object.keys(this.remoteSharingUsers).length) {
        //   this.alreadySharingScreen = true;
        //   this.sendControlStatus();
        //   return;
        // }

        if (Object.keys(this.windowShares).length) {
            this.toastrService.info(this.translateService.instant('tostrs.share_screen_message'), '', {
                positionClass: 'toast-center-center',
                toastClass: 'inside-call-chat-toast'
            });

            return this.vidyoConnector
                .SelectLocalWindowShare({
                    localWindowShare: this.windowShares[Object.keys(this.windowShares)[0]]
                })
                .catch((error) => {
                    // This API will be rejected in case any error occurred including:
                    // - permission is not given on the OS level (macOS).
                    if (error.name == 'NotAllowedError') {
                        this.toastrService.error(this.translateService.instant('tostrs.not_allowed'));
                    } else {
                        this.toastrService.error(error);
                    }
                });
        }
    }

    addUserInWaitingRoom(user) {
        if (user.memberName) {
            this.waitingUsers[user.memberId] = user;
            this.sendControlStatus();
        }
    }

    removeUserFromWaitingRoom(user) {
        delete this.waitingUsers[user.memberId];
        this.sendControlStatus();
    }

    setSharingScreenStatus(enable) {
        this.alreadySharingScreen = enable;
    }

    getLocalParticipant() {
        return this.localParticipant;
    }

    private sendControlStatus(updates: any = {}) {
        this.participantsStatus$.next({
            users: this.users,
            microphoneMutedAll: this.microphoneMutedAll,
            roomLocked: this.roomLocked,
            recording: this.recording,
            screenSharing: this.screenSharing,
            waitingUsers: this.waitingUsers,
            alreadySharingScreen: this.alreadySharingScreen,
            vcs: this.vcs,
            participantJoined: updates.participantJoined,
            participantLeft: updates.participantLeft,
            screenShareInfo: this.screenShareInfo
        });
    }

    private handleWindowEvents() {
        window.onVidyoClientLoaded = (status) => {
            this.onLoad(status);
        };

        window.onresize = () => {
            if (this.vidyoConnector) {
                this.showRenderer();
            }
        };

        window.onbeforeunload = () => {
            if (this.vidyoConnector) {
                this.vidyoConnector.Destruct();
            }
        };

        window.addEventListener('online', (e) => {
            this.isUserOnline = true;
        });
        window.addEventListener('offline', (e) => {
            this.isUserOnline = false;
        });
    }

    clear() {
        this.localParticipant = {};
        this.joinData = {};
        this.users = {};
        this.microphoneMutedAll = false;
        this.roomLocked = false;
        this.recording = false;
        this.screenSharing = false;
        this.reconnecting = false;
        this.whiteboardShared = false;
        this.screenShareInfo = { host: '', isShared: false, id: '' };
    }

    changeCamera() {
        this.vidyoConnector.CycleCamera();
    }

    changeSpeaker() {
        this.vidyoConnector.CycleSpeaker();
    }

    changeMicrophone() {
        this.vidyoConnector.CycleMicrophone();
    }
    setWhiteboardStatus(state) {
        this.whiteboardShared = state;
        this.vidyoConnector
            .AssignViewToCompositeRenderer({
                viewId: this.joinData.viewId,
                viewStyle: 'VIDYO_CONNECTORVIEWSTYLE_Default',
                remoteParticipants: state ? 3 : 8
            })
            .then(() => {
                this.loggerService.log('AssignViewToCompositeRenderer Success');
            })
            .catch(() => {
                this.loggerService.error('AssignViewToCompositeRenderer Failed');
            });
    }

    private getCookie(name: string) {
        const ca: Array<string> = document.cookie.split(';');
        const caLen: number = ca.length;
        const cookieName = `${name}=`;
        let c: string;

        for (let i = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) === 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    setResolution(width, height, frameInterval = 0) {
        return this.vidyoConnector.SetMaxConstraint(width, height, frameInterval);
    }

    functionHideScreenShareNotification(state) {
        this.hideScreenShareNotification.next(state);
    }
}
