import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { APP_EVENTS } from 'src/app/constants';
import {
    EventData,
    EventEmitterService,
    GoogleTagManagerService,
    LiveStreamService,
    LoggerService
} from 'src/app/core';
import { GA_NEW } from 'src/app/shared/ga-new';
import { GA_EVENTS } from '../ga-events';

@Component({
    selector: 'app-live-stream',
    templateUrl: './live-stream-settings.component.html',
    styleUrls: ['./live-stream-settings.component.scss']
})
export class LiveStreamSettingsComponent implements OnInit {
    showHideoverflowLiveStream = false;
    showconfigurationpage = false;
    showdeletepopup = false;
    streams = [];
    streamingList;
    private subscriptions: Subscription[] = [];
    activeStream: any;
    errorId = '';
    streamType = '';

    constructor(
        private liveStreamService: LiveStreamService,
        private loggerService: LoggerService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private eventEmitterService: EventEmitterService,
        private gTagService: GoogleTagManagerService
    ) {}

    ngOnInit(): void {
        this.getAllLiveStreams();
        this.subscriptions.push(
            this.eventEmitterService.subscribe((event: EventData) => {
                if (event.type === APP_EVENTS.REFRESH_LIVE_STREAMS) {
                    this.errorId = '';
                    this.getAllLiveStreams();
                }
                if (event.type === APP_EVENTS.UPDATE_STREAM_FAILURE) {
                    this.errorId = event?.data?._id;
                }
            })
        );
    }

    hideAddPopup() {
        this.showconfigurationpage = false;
        this.activeStream = {};
    }

    showAddPopup() {
        if (this.streams.length >= 10) {
            this.showHideoverflowLiveStream = true;
            return false;
        }
        this.gTagService.sendEvent(GA_EVENTS.ADD_LIVE_STREAM);
        this.gTagService.sendEvent(GA_NEW.ADD_LIVE_STREAM);
        // this.streamType = streamType;
        this.showconfigurationpage = true;
    }

    toggleDeletePopup(stream) {
        this.activeStream = stream;
        this.showdeletepopup = !this.showdeletepopup;
    }

    getAllLiveStreams() {
        this.subscriptions.push(
            this.liveStreamService.getAllLiveStreams().subscribe(
                (data) => {
                    this.streams = data;
                },
                (err) => {
                    this.loggerService.log('Unable to fetch all live streams', err);
                    this.toastrService.error(
                        this.translateService.instant('tostrs.something_went_wrong_please_try_again')
                    );
                }
            )
        );
    }

    initUpdateLiveStream(stream) {
        this.activeStream = stream;
        this.showconfigurationpage = true;
    }

    deleteLiveStream() {
        if (this.activeStream?._id) {
            this.subscriptions.push(
                this.liveStreamService.deleteLiveStream(this.activeStream?._id).subscribe(
                    (data) => {
                        this.toastrService.info(
                            this.translateService.instant('user_dashboard_settings.live_stream.delete_success')
                        );
                        this.showdeletepopup = false;
                        this.activeStream = {};
                        this.eventEmitterService.emit({
                            type: APP_EVENTS.REFRESH_LIVE_STREAMS,
                            data: {}
                        });
                    },
                    (err) => {
                        this.errorId = this.activeStream?._id;
                        this.loggerService.log('Unable to delete live stream', err);
                        this.showdeletepopup = false;
                    }
                )
            );
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
    handleLastelementTab(index) {
        if (this.streams.length - 1 == index) {
            setTimeout(() => {
                const element: any = document.getElementById('general');
                element.focus();
            }, 100);
        }
    }
}
