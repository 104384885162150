import { Observable, throwError, timer } from 'rxjs';
import { mergeMap, finalize } from 'rxjs/operators';

export const genericRetryStrategy =
    ({
        maxRetryAttempts = 3,
        scalingDuration = 5000,
        excludedStatusCodes = [],
        useRange = { enable: false, low: 0, high: 0 }
    }: {
        maxRetryAttempts?: number;
        scalingDuration?: number;
        excludedStatusCodes?: number[];
        useRange?: { enable: boolean; low: number; high: number };
    } = {}) =>
    (attempts: Observable<any>) => {
        return attempts.pipe(
            mergeMap((error, i) => {
                const retryAttempt = i + 1;
                // if maximum number of retries have been met
                // or response is a status code we don't wish to retry, throw error
                if (
                    retryAttempt > maxRetryAttempts ||
                    (useRange.enable
                        ? error.status >= useRange.low && error.status <= useRange.high
                        : excludedStatusCodes.find((e) => e === error.status))
                ) {
                    return throwError(error);
                }

                console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`);
                // retry after 1s, 2s, etc...
                return timer(retryAttempt * scalingDuration);
            }),
            finalize(() => console.log('Generic Retry Strategy - Completed'))
        );
    };
