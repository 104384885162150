import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UserService, DiagnosticService, UtilService, AppService, GoogleTagManagerService } from 'src/app/core';
import { GA_EVENTS } from '../ga-events';
import { GA_NEW } from 'src/app/shared/ga-new';

@Component({
    selector: 'app-pmisettings',
    templateUrl: './pmisettings.component.html',
    styleUrls: ['./pmisettings.component.scss']
})
export class PmisettingsComponent implements OnInit {
    @Output() close: EventEmitter<any> = new EventEmitter();

    user;
    form: FormGroup;
    previousForm: any = {};
    advancedOptionsCollapsed = false;
    errors: any = {};
    loading;
    disableSave = true;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private toastrService: ToastrService,
        private userService: UserService,
        private diagnosticService: DiagnosticService,
        private utilService: UtilService,
        private translateService: TranslateService,
        private appService: AppService,
        private gTagService: GoogleTagManagerService
    ) {}

    ngOnInit() {
        this.user = this.utilService.clone(this.userService.getUserSync());
        document.documentElement.scrollTop = 0;
        this.createForm();
        this.getPreferences();
    }

    createForm() {
        this.form = this.fb.group({
            hostAudio: [false],
            hostVideo: [false],
            participantAudio: [false],
            participantVideo: [true],
            waitingRoom: [false],
            participantHardAudioMute: [false],
            colleagueOnly: [false],
            loggedInOnly: [false],
            joinBeforeHost: [false]
        });
        this.listenToFormValueChanges();
    }

    createPreviousForm() {
        this.previousForm = this.utilService.clone(this.form.value);
    }

    listenToFormValueChanges() {
        this.form.get('waitingRoom').valueChanges.subscribe((waitingRoom) => {
            if (waitingRoom) {
                this.form.patchValue({ joinBeforeHost: false });
            }
        });

        this.form.get('joinBeforeHost').valueChanges.subscribe((joinBeforeHost) => {
            if (joinBeforeHost) {
                this.form.patchValue({ waitingRoom: false });
            }
        });

        this.form.get('participantAudio').valueChanges.subscribe((participantAudio) => {
            if (participantAudio && this.form.value.participantHardAudioMute) {
                this.form.patchValue({ participantHardAudioMute: false });
            }
        });

        this.form.get('participantHardAudioMute').valueChanges.subscribe((participantHardAudioMute) => {
            if (participantHardAudioMute && this.form.value.participantAudio) {
                this.form.patchValue({ participantAudio: false });
            }
        });

        this.form.get('colleagueOnly').valueChanges.subscribe((colleagueOnly) => {
            if (colleagueOnly && this.form.value.loggedInOnly) {
                this.form.patchValue({ loggedInOnly: false });
            }
        });

        this.form.get('loggedInOnly').valueChanges.subscribe((loggedInOnly) => {
            if (loggedInOnly && this.form.value.colleagueOnly) {
                this.form.patchValue({ colleagueOnly: false });
            }
        });

        this.form.valueChanges.subscribe((val) => {
            this.compWithPreviousForm(val);
        });
    }

    compWithPreviousForm(val) {
        let clonedVal = this.utilService.clone(val);
        let previousCloned = this.utilService.clone(this.previousForm);
        if (JSON.stringify(previousCloned) === JSON.stringify(clonedVal)) {
            this.disableSave = true;
        } else {
            this.disableSave = false;
        }
    }

    gTagPMISettings(event) {
        if (event?.target?.id === 'defaultUnchecked2') {
            const guestEvent = event.target.checked
                ? GA_EVENTS.PMI_DONT_ALLOW_GUEST_USER_ENABLED
                : GA_EVENTS.PMI_DONT_ALLOW_GUEST_USER_DISABLED;
            this.gTagService.sendEvent(guestEvent);
        }
        if (event?.target?.id === 'defaultUnchecked3') {
            const waitingRoomEvent = event.target.checked
                ? GA_EVENTS.PMI_WAITING_ROOM_ENABLED
                : GA_EVENTS.PMI_WAITING_ROOM_DISABLED;
            this.gTagService.sendEvent(waitingRoomEvent);
        }
        if (event?.target?.id === 'defaultUnchecked4') {
            const colleagueOnlyEvent = event.target.checked
                ? GA_EVENTS.PMI_PARTICIPANT_MY_ORG_ENABLED
                : GA_EVENTS.PMI_PARTICIPANT_MY_ORG_DISABLED;
            this.gTagService.sendEvent(colleagueOnlyEvent);
        }
        if (event?.target?.id === 'defaultUnchecked5') {
            const joinBeforeHostEvent = event.target.checked
                ? GA_EVENTS.PMI_JOIN_BEFORE_HOST_ENABLED
                : GA_EVENTS.PMI_JOIN_BEFORE_HOST_DISABLED;
            this.gTagService.sendEvent(joinBeforeHostEvent);
        }
        if (event?.target?.id === 'defaultUnchecked6') {
            const participantHardAudioMuteEvent = event.target.checked
                ? GA_EVENTS.PMI_FORCE_MUTE_ENTRY_ENABLED
                : GA_EVENTS.PMI_FORCE_MUTE_ENTRY_DISABLED;
            this.gTagService.sendEvent(participantHardAudioMuteEvent);
        }
        if (event?.target?.id === 'defaultUnchecked7') {
            const participantAudioEvent = event.target.checked
                ? GA_EVENTS.PMI_SOFT_MUTE_ENTRY_ENABLED
                : GA_EVENTS.PMI_SOFT_MUTE_ENTRY_DISABLED;
            this.gTagService.sendEvent(participantAudioEvent);
        }
    }

    onAdvancedMeetingOptionsEnterClicked(event) {
        event.preventDefault();
        // Toggle the checkbox manually
        const checkbox = event.target as HTMLInputElement;
        checkbox.checked = !checkbox.checked;

        switch (event?.target?.id) {
            case 'defaultUnchecked2': {
                this.form.patchValue({ loggedInOnly: checkbox.checked });
                break;
            }
            case 'defaultUnchecked3': {
                this.form.patchValue({ waitingRoom: checkbox.checked });
                break;
            }
            case 'defaultUnchecked4': {
                this.form.patchValue({ colleagueOnly: checkbox.checked });
                break;
            }
            case 'defaultUnchecked5': {
                this.form.patchValue({ joinBeforeHost: checkbox.checked });
                break;
            }
            case 'defaultUnchecked6': {
                this.form.patchValue({ participantHardAudioMute: checkbox.checked });
                break;
            }
            case 'defaultUnchecked7': {
                this.form.patchValue({ participantAudio: checkbox.checked });
                break;
            }
        }
    }

    saveButtonClicked() {
        this.gTagService.sendEvent(GA_EVENTS.PMI_SAVE_BUTTON);
        this.gTagService.sendEvent(GA_NEW.PMI_SAVE_BUTTON);
    }

    getPreferences() {
        this.userService.getMeetingPrefernces().subscribe((preferences) => {
            this.form.patchValue({ ...preferences, participantAudio: !preferences.participantAudio });
            this.createPreviousForm();
        });
    }

    saveUserPreferences() {
        return this.userService
            .updatePersonalRoomPreferences({
                isPinEnabled: !!this.user.roomPIN,
                roomPIN: this.user.roomPIN
            })
            .pipe(
                tap(() => {
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Web Profile',
                            eventType: 'app_event',
                            eventAction: 'Change PMI Password',
                            status: 'success'
                        })
                        .subscribe();
                })
            );
    }

    saveMeetingPreferences() {
        const { ...payload } = this.utilService.clone(this.form.value);
        payload.participantAudio = !payload.participantAudio;

        return this.userService.updateMeetingPrefernces(payload).pipe(
            tap(() => {
                this.diagnosticService
                    .sendEvent({
                        eventAction: 'Web Settings Updated',
                        eventCategory: 'Web Settings',
                        eventType: 'app_event',
                        endpoint: 'api/user/meetingpreferences',
                        status: 'success',
                        data: payload
                    })
                    .subscribe();

                this.toastrService.clear();
                this.toastrService.success(
                    this.translateService.instant('tostrs.your_settings_have_been_updated'),
                    '',
                    {
                        positionClass: 'toast-top-center',
                        timeOut: 3000
                    }
                );
            })
        );
    }

    save() {
        if (!this.validatePin()) {
            return;
        }
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translateService.instant('tostrs.something_went_right'));
            return;
        }
        forkJoin([this.saveUserPreferences(), this.saveMeetingPreferences()]).subscribe(() => {
            this.previousForm = this.utilService.clone(this.form.value);
        });
        const colleagueOnlyEventNew = this.form.value.colleagueOnly
            ? GA_NEW.PMI_PARTICIPANT_MY_ORG_ENABLED
            : GA_NEW.PMI_PARTICIPANT_MY_ORG_DISABLED;
        this.gTagService.sendEvent(colleagueOnlyEventNew);
        const participantAudioEventNew = this.form.value.participantAudio
            ? GA_NEW.PMI_SOFT_MUTE_ENTRY_ENABLED
            : GA_NEW.PMI_SOFT_MUTE_ENTRY_DISABLED;
        this.gTagService.sendEvent(participantAudioEventNew);
        const participantHardAudioMuteEventNew = this.form.value.participantHardAudioMute
            ? GA_NEW.PMI_FORCE_MUTE_ENTRY_ENABLED
            : GA_NEW.PMI_FORCE_MUTE_ENTRY_DISABLED;
        this.gTagService.sendEvent(participantHardAudioMuteEventNew);
        const joinBeforeHostEventNew = this.form.value.joinBeforeHost
            ? GA_NEW.PMI_JOIN_BEFORE_HOST_ENABLED
            : GA_NEW.PMI_JOIN_BEFORE_HOST_DISABLED;
        this.gTagService.sendEvent(joinBeforeHostEventNew);
        const waitingRoomEventNew = this.form.value.waitingRoom
            ? GA_NEW.PMI_WAITING_ROOM_ENABLED
            : GA_NEW.PMI_WAITING_ROOM_DISABLED;
        this.gTagService.sendEvent(waitingRoomEventNew);
        const guestEventNew = this.form.value.loggedInOnly
            ? GA_NEW.PMI_DONT_ALLOW_GUEST_USER_ENABLED
            : GA_NEW.PMI_DONT_ALLOW_GUEST_USER_DISABLED;
        this.gTagService.sendEvent(guestEventNew);
        this.form.markAsPristine();
    }

    validatePin() {
        if (
            !(
                /[a-z]/.test(this.user.roomPIN) &&
                /[A-Z]/.test(this.user.roomPIN) &&
                /[0-9]/.test(this.user.roomPIN) &&
                !this.user.roomPIN.includes('I') &&
                !this.user.roomPIN.includes('l') &&
                !this.user.roomPIN.includes('o') &&
                !this.user.roomPIN.includes('O') &&
                !this.user.roomPIN.includes('0') &&
                /^[0-9a-zA-Z]+$/.test(this.user.roomPIN)
            )
        ) {
            this.toastrService.error(this.translateService.instant('tostrs.password_should_be'));
            return false;
        }
        return true;
    }

    refreshRoomPin() {
        this.user.roomPIN = this.utilService.generatePassword(5);
        this.form.markAsDirty();
        this.gTagService.sendEvent(GA_EVENTS.PMI_PASSWORD_RESET);
    }

    toggleAdvancedOptions() {
        this.advancedOptionsCollapsed = !this.advancedOptionsCollapsed;
    }

    navigateToDashboard() {
        this.router.navigate(['dashboard']);
    }
    //Accessability changes
    setFocusOnElement() {
        setTimeout(() => {
            const element: any = document.getElementById('notifications');
            element.focus();
        }, 100);
    }
}
