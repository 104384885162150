<div class="participants-input" tabindex="0" outside (outside)="resetUsers()">
    <div class="save_onbehalf">
        <div class="input-wrap" [ngClass]="showResolveParticipantError ? 'participant-input-has-error' : ''">
            <span class="participants">
                <span *ngFor="let participant of participants | keyvalue : originalOrder">
                    <span
                        [ngClass]="isEnabledBehalf == true ? '' : 'onbehalf-disabled'"
                        class="participant"
                        *ngIf="!participant?.value?.userId?.startsWith('gu-')"
                        [ngClass]="participant?.value?.participantWithError ? 'participant-has-error' : ''"
                    >
                        <span
                            [ngClass]="isEnabledBehalf == true ? '' : 'onbehalf-disabled'"
                            tabindex="0"
                            aria-label="{{
                                participant.value.email ||
                                    participant.value.mobileNo ||
                                    participant.value.title ||
                                    participant.value.name
                            }}"
                            >{{
                                participant.value.email ||
                                    participant.value.mobileNo ||
                                    participant.value.title ||
                                    participant.value.name
                            }}</span
                        >
                        <span
                            class="close"
                            tabindex="0"
                            *ngIf="isEnabledBehalf"
                            [ngClass]="isEnabledBehalf == true ? '' : 'onbehalf-disabled'"
                            aria-label="Remove Member"
                            (click)="removeParticipant(participant)"
                            (keydown.enter)="removeParticipant(participant)"
                        >
                            <img alt="" src="assets/img/close_icon.svg" />
                        </span>
                    </span>
                </span>
            </span>
            <span
                #input
                class="input"
                contenteditable="true"
                placeholder
                tabindex="0"
                role="textbox"
                aria-label="Enter names here to seach for them"
                (paste)="onPaste($event)"
                (focus)="onFocus()"
            ></span>
            <!-- {{'planmeeting_popup.invite_placeholder' | translate}} -->
        </div>
        <button
            *ngIf="isOnBehalfSection && isEnabledBehalf"
            type="submit"
            tabindex="0"
            [disabled]="!isListChanged"
            [ngClass]="{ disabled: !isListChanged || noUsersFound }"
            style="margin: 10px"
            (click)="saveButtonClicked()"
            class="custom-button bg-primary save-button"
        >
            {{ 'user_dashboard_settings.save' | translate }}
        </button>
    </div>
    <div class="users bordered p-4" tabindex="0" *ngIf="loading">
        <app-loader></app-loader>
    </div>
    <div
        class="users bordered pointer"
        *ngIf="!loading && (isExternalEmail || isExternalPhoneNo) && !isContactsSection && !isOnBehalfSection"
    >
        <div
            class="no-results"
            (click)="inviteExternal(input.innerText)"
            (keydown.enter)="inviteExternal(input.innerText)"
            tabindex="0"
            aria-label="Invite"
        >
            {{ 'planmeeting_popup.invite_text' | translate }} {{ input.innerText }}
        </div>
    </div>
    <div
        class="users bordered pointer"
        *ngIf="!loading && (isExternalEmail || isExternalPhoneNo) && isContactsSection && !isOnBehalfSection"
    >
        <div
            class="no-results"
            tabindex="0"
            aria-label="No results found. Please enter a valid Email ID or Mobile No. or search your contact list by name"
        >
            {{ 'planmeeting_popup.no_results' | translate }}
        </div>
    </div>

    <div
        class="users bordered"
        *ngIf="
            !loading &&
            noUsersFound &&
            !isOnBehalfSection &&
            (
                userService?.localSearches
                | filterContactBySearchKey : searchStr : ['fullname', 'lname', 'phoneNo', 'name', 'email', 'title']
            ).length == 0
        "
    >
        <div
            class="no-results"
            tabindex="0"
            aria-label="No results found. Please enter a valid Email ID or Mobile No. or search your contact list by name"
        >
            {{ 'planmeeting_popup.no_results' | translate }} {{ input.innerText }}
        </div>
    </div>

    <div class="users" *ngIf="!loading && (isExternalEmail || isExternalPhoneNo || noUsersFound) && isOnBehalfSection">
        <div
            class="no-results"
            tabindex="0"
            aria-label="No results found. Please enter a valid Email ID or Mobile No. or search your contact list by name"
        >
            {{ 'planmeeting_popup.no_results' | translate }} {{ input.innerText }}
        </div>
    </div>

    <div
        class="users"
        tabindex="0"
        aria-label="Please press up and down arrows to explore dropdown names one by one"
        *ngIf="suggestionsCount && !loading && isInputFocused"
    >
        <!-- Caching Technique for retaining users -->
        <div class="retainingUsers" tabindex="0" role="button">
            <ng-container *ngIf="isInputFocused">
                <div
                    class="user"
                    *ngFor="
                        let user of userService.localSearches
                            | filterContactBySearchKey
                                : searchStr
                                : ['fullname', 'lname', 'phoneNo', 'title', 'name', 'email']
                    "
                >
                    <ng-container
                        *ngIf="
                            !user?.title && (user?._id?.substring(0, 2) === 'u-' || user?._id?.substring(0, 2) === 'c-')
                        "
                    >
                        <div class="user" (click)="select(user)">
                            <div
                                class="avatar"
                                appAvatarWithInitials
                                name="{{ user?.name + ' ' + user?.lname }} "
                            ></div>
                            <span
                                class="user-status"
                                *ngIf="user?.status"
                                [class.online]="user?.status === 'online'"
                                [class.offline]="user?.status === 'offline'"
                                [class.busy]="user?.status === 'busy'"
                                [class.away]="user?.status === 'away'"
                            ></span>
                            <div class="info">
                                <div class="name" [class.pad-left-7]="!user?.status">
                                    {{ user?.name }} {{ user?.lname }}
                                </div>
                                <div class="email" [class.pad-left-7]="user?.phoneNo && !user?.email && !user?.status">
                                    {{ user?.email || user?.phoneNo }}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="user?.title || user?._id.substring(0, 2) === 'g-'">
                        <div class="user" (click)="select(user, user?.type ? user.type : 'g-')">
                            <label class="user" (click)="initGroupChat(user)">
                                <div class="avatar"><span class="fa fa-users group-icon"></span></div>
                                <div class="info">
                                    <div class="name pad-left-7">{{ user?.title }}</div>
                                    <div class="email pad-left-7">
                                        {{ user?.members?.length }}
                                        {{ 'chat.participants' | translate }}
                                    </div>
                                </div>
                            </label>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="user?._id?.substring(0, 2) === 'vc' && !user?.title">
                        <div class="user" (click)="select(user)">
                            <label class="user" (click)="openVCRoom(user)">
                                <div class="avatar">
                                    <span appVcroomAvatar name="{{ user?.name }}"></span>
                                </div>
                                <div class="info">
                                    <div class="name pad-left-7">
                                        {{ user?.name }}
                                    </div>
                                    <div class="email pad-left-7">
                                        {{ user?.ipaddress }}
                                    </div>
                                </div>
                            </label>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <div
            class="user"
            role="button"
            tabindex="0"
            aria-label="{{ user.name }} {{ user.lname }}, {{ user.email || user.phoneNo }}"
            *ngFor="let user of users.allUsers"
            (click)="select(user, user.type)"
            (keydown.enter)="select(user, user.type)"
            [class.selected]="
                participants.has(user._id ? user._id : user) ||
                participants.has(user.email ? user.email : user) ||
                participants.has(user.phoneNo ? user.phoneNo : user) ||
                participants.has(user.userId ? user._id : user)
            "
        >
            <div class="avatar" appAvatarWithInitials name="{{ user.name + ' ' + user.lname }} "></div>
            <span
                *ngIf="isContactsSection"
                class="user-status"
                [class.online]="user.status === 'online'"
                [class.offline]="user.status === 'offline'"
                [class.busy]="user.status === 'busy'"
                [class.away]="user.status === 'away'"
            ></span>
            <div class="info">
                <div class="name" tabindex="0" aria-label="{{ user.name }} {{ user.lname }}">
                    {{ user.name }} {{ user.lname }}
                </div>
                <div class="email" tabindex="0" aria-label="{{ user.email || user.phoneNo }}">
                    {{ user.email || user.phoneNo }}
                </div>
            </div>
        </div>
        <div
            class="user"
            tabindex="0"
            role="button"
            aria-label="{{ group.title }}, {{ group.members.length }} Members"
            *ngFor="let group of users.groups"
            (click)="select(group, 'group')"
            (keydown.enter)="select(group, 'group')"
        >
            <div class="avatar">
                <img alt="" src="assets/images/group_icn.svg" />
            </div>
            <div class="info">
                <div class="name" tabindex="0" aria-label="{{ group.title }}">{{ group.title }}</div>
                <div class="email" tabindex="0" aria-label="{{ group.members.length }} Members">
                    {{ group.members.length }} {{ 'planmeeting_popup.members' | translate }}
                </div>
            </div>
        </div>
        <div
            class="user"
            tabindex="0"
            role="button"
            aria-label="{{ vc.name }}, {{ vc.ipaddress }}"
            *ngFor="let vc of users.vcs"
            (click)="select(vc)"
            (keydown.enter)="select(vc)"
        >
            <div class="avatar">
                <img alt="" src="assets/images/vc_icn.svg" />
            </div>
            <div class="info">
                <div class="name" tabindex="0" aria-label="{{ vc.name }}">{{ vc.name }}</div>
                <div class="email" tabindex="0" aria-label="{{ vc.ipaddress }}">{{ vc.ipaddress }}</div>
            </div>
        </div>
    </div>
    <div class="resolve-participant-error">
        <div class="error">
            <img
                *ngIf="isOnBehalfSection && showResolveParticipantError"
                alt="errormsg icon"
                src="assets/img/download/ic_error.svg"
            />
            <span *ngIf="showResolveParticipantError">{{ resolveParticipantErrorMsg }}</span>
        </div>
        <div
            class="remove-all"
            *ngIf="participants.size > 0 && isFromMeetingCreation && isParticipantsField"
            (click)="removeAll()"
        >
            {{ 'planmeeting_popup.remove_all_participants' | translate }}
        </div>
    </div>
</div>
