import { Component, Input, OnInit } from '@angular/core';
import { ProfilePhotoService } from 'src/app/core/services/profile-photo.service';
import { AppService, RtcService } from 'src/app/core';

@Component({
    selector: 'app-user-initial',
    templateUrl: './user-initial.component.html',
    styleUrls: ['./user-initial.component.scss']
})
export class UserInitialsComponent implements OnInit {
    @Input() participant;
    @Input() remoteParticpants = [];
    @Input() screenSharingView = false;
    @Input() inActive = false;

    isSelfViewEnable: boolean;
    isJMMeeting = false;
    imagePath = '';

    constructor(
        public profilePhotoService: ProfilePhotoService,
        private appService: AppService,
        public rtcService: RtcService
    ) {}

    ngOnInit(): void {
        this.isSelfViewEnable = this.appService.getConfigVariable('ENABLE_SELF_VIEW') ?? false;
        this.isJMMeeting = this.rtcService.getIsJMMeeting();
        if (this.participant?.image) {
            this.getProfileImagePath(this.participant?.userId);
        }
    }

    get participantName() {
        return this.participant ? this.participant?.participantName : '';
    }

    get participantInitials() {
        return this.profilePhotoService?.getAvatarInitials(this.participantName);
    }

    get colorChosen() {
        return this.profilePhotoService.getColorForInitials(this.participantName);
    }

    get moreFeed() {
        return this.isSelfViewEnable && this.remoteParticpants.length > 1;
    }

    get singleFeed() {
        return this.isSelfViewEnable && this.remoteParticpants.length == 1;
    }

    async getProfileImagePath(id) {
        await this.profilePhotoService.getImageAsBase64(this.profilePhotoService.getProfilePic(id)).then(
            (url: any) => {
                this.imagePath = url;
            },
            (error) => {
                this.imagePath = '';
                console.error('Profile image fatch error: ', error);
            }
        );
    }
}
