import { MeetingService } from './meeting.service';
import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

import { UserService } from './user.service';
import { RtcService } from './rtc.service';
import { AuthService } from './auth.service';
import { EventEmitterService } from './event-emitter.service';
import { PermissionService } from './permission.service';

import { APP_EVENTS, CALL_TYPES, SOCKET_EVENTS } from '../../constants';
import { GuestAuthService } from './guest-auth.service';
import { AppService } from './app.service';

@Injectable({
    providedIn: 'root'
})
export class CallerService {
    constructor(
        private toastrService: ToastrService,
        private userService: UserService,
        private authService: AuthService,
        private rtcService: RtcService,
        private eventEmitterService: EventEmitterService,
        private permissionService: PermissionService,
        private meetingService: MeetingService,
        private guestAuthService: GuestAuthService,
        private appService: AppService
    ) {}

    makeCall(type, participants, isInitiater = true) {
        switch (type) {
            case CALL_TYPES.SELF:
                this.joinOwnPersonalRoom();
                break;
            case CALL_TYPES.DIRECT:
            case CALL_TYPES.VC:
            case CALL_TYPES.GROUP:
                this.permissionService.checkForUser(['normal']).subscribe((bool) => {
                    if (bool && participants.length > 1) {
                        this.toastrService.warning("You don't have permission to call multiple users.");
                        return;
                    }
                    this.makeAnyCall(participants, isInitiater);
                });
                break;
            case CALL_TYPES.DIAL_VC:
                this.dialVC(participants, isInitiater);
                break;
        }
    }

    joinAsGuest({ historyId = null, isInitiater = false, guestName, room, ownerDetails }) {
        this.rtcService.setRoomInfo(room);
        const meetingObj: any = {
            callurl: room.room_url,
            room: room.room_key,
            room_id: room.roomID.toString(),
            extension: (room.extension || '').toString(),
            gateway_ip: room.gateway_ip || room.gatewayip,
            roomPIN: room.roomPIN || room.room_pin,
            guest_name: guestName,
            isInitiater,
            participantCount: 0,
            is_disconnect: 'false',
            historyId,
            owner_id: ownerDetails.ownerUserId || ownerDetails.userId,
            owner_name: ownerDetails.name
        };
        if (this.authService.getAuthInfo()) {
            meetingObj.token = this.authService.getAuthInfo().jwt;
        }
        this.sendJoinCall(meetingObj);
        // this.activateConference(meetingObj);
    }

    joinAMeeting({ isInitiater = false, guestName, room, ownerDetails }, isWatchAlong = false) {
        this.rtcService.setRoomInfo(room);
        const meetingObj: any = {
            callurl: room.room_url,
            room: room.room_key,
            room_id: (room.roomId || room.roomID).toString(),
            roomPIN: room.vpin || room.roomPIN || room.room_pin,
            userPIN: room.roomPIN || room.room_pin,
            // extension: (room.extension || '').toString(),
            gateway_ip: room.gateway_ip,
            jiomeetId: room.jiomeetId,
            guest_name: guestName,
            owner_name: ownerDetails.name,
            owner_lname: ownerDetails.lname,
            owner_id: ownerDetails._id,
            owner_emailId: ownerDetails.email,
            owner_tenantId: ownerDetails.tenantId,
            isInitiater,
            participantCount: 0,
            isJoinMeeting: true,
            title: room.topic || '',
            topic: room.topic || '',
            group_id: '0',
            users: [],
            is_disconnect: 'false',
            roomID: room.roomID //sending it seperately as we need this in case of healthhub
            // participantEndPointType: 'Web',
            // participantEndPointName: 'HP probook',
            // machineIp: '192.168.0.139'
        };
        if (this.authService.getAuthInfo()) {
            meetingObj.token = this.authService.getAuthInfo().jwt;
            this.sendJoinCall(meetingObj);
        } else if (this.guestAuthService.getAuthInfo()) {
            meetingObj.token = this.guestAuthService.getAuthInfo().jwt;
            this.sendJoinCall(meetingObj);
        } else {
            this.activateConference(meetingObj, isWatchAlong);
        }
    }

    //HACK until we add proper code for integrations
    joinAHHMeeting({ isInitiater = false, guestName, room, ownerDetails }) {
        this.rtcService.setRoomInfo(room);
        const meetingObj: any = {
            callurl: room.room_url || room.roomUrl,
            room: room.room_key || room.room,
            room_id: (room.roomId || room.roomID).toString(),
            roomPIN: room.vpin || room.roomPIN || room.room_pin || '',
            userPIN: room.roomPIN || room.room_pin,
            // extension: (room.extension || '').toString(),
            gateway_ip: room.gateway_ip,
            jiomeetId: room.jiomeetId,
            guest_name: guestName,
            owner_name: ownerDetails.name,
            owner_lname: ownerDetails.lname,
            owner_id: ownerDetails._id,
            owner_emailId: ownerDetails.email,
            owner_tenantId: ownerDetails.tenantId,
            isInitiater,
            participantCount: 0,
            isJoinMeeting: true,
            title: room.topic || '',
            topic: room.topic || '',
            group_id: '0',
            users: [],
            is_disconnect: 'false',
            historyId: room._id || null
        };
        if (this.authService.getAuthInfo()) {
            meetingObj.token = this.authService.getAuthInfo().jwt;
        }
        if (!meetingObj.historyId) {
            this.sendJoinCall(meetingObj);
        } else {
            this.activateConference(meetingObj);
        }
    }

    joinScheduledMeeting(guestName, meetingInfo, hostInfo, isInitiater = false) {
        const room = {
            room_url: meetingInfo.roomUrl,
            room_key: meetingInfo.roomKey,
            roomID: meetingInfo.roomId,
            jiomeetId: meetingInfo.jiomeetId,
            extension: meetingInfo.extension,
            gateway_ip: meetingInfo.gatewayIp,
            advancedOptions: meetingInfo.advancedOptions
        };
        this.rtcService.setRoomInfo(room);
        const meetingObj = {
            callurl: room.room_url,
            room: room.room_key,
            room_id: room.roomID.toString(),
            roomPIN: meetingInfo.pin,
            extension: (room.extension || '').toString(),
            gateway_ip: room.gateway_ip,
            jiomeetId: room.jiomeetId,
            guest_name: guestName,
            owner_name: hostInfo.name,
            owner_id: hostInfo._id,
            owner_emailId: hostInfo.email,
            owner_tenantId: meetingInfo.tenantId,
            isInitiater,
            participantCount: 0,
            isJoinMeeting: true,
            token: this.authService.getAuthInfo().jwt,
            meetingId: meetingInfo._id,
            title: meetingInfo.topic,
            group_id: '0',
            users: [],
            is_disconnect: 'false'
            // participantEndPointType: 'Web',
            // participantEndPointName: 'HP probook',
            // machineIp: '192.168.0.139'
        };
        // this.activateConference(meetingObj);
        this.sendJoinCall(meetingObj);
    }

    addParticipantsInCall(participants, isInitiater?) {
        const vcs = participants.filter((participant) => !!participant.ipaddress);
        const groups = participants.filter((participant) => !!participant.members);
        const users = participants.filter((participant) => !!participant.phoneNo);

        const targetIds = [];
        const targetVcs = [];
        participants.forEach((participant) => {
            if (participant.email) {
                targetIds.push({ userId: participant.userId });
            } else if (participant.ipaddress) {
                targetVcs.push({ vcId: participant._id });
            } else if (participant.members) {
                participant.members.forEach((member) => targetIds.push({ userId: member.userId }));
            }
        });

        forkJoin([this.userService.getUser(), this.userService.getUserRoom()]).subscribe(([user, room]) => {
            const meetingInfo: any = this.meetingService.getScheduleMeetingDetails() || {};
            if (Object.keys(meetingInfo).length) {
                room = {
                    roomid: meetingInfo.roomId,
                    roomurl: meetingInfo.roomUrl,
                    roomkey: meetingInfo.roomKey,
                    extension: meetingInfo.extension,
                    gateway_ip: meetingInfo.gatewayIp
                };
            }
            this.rtcService.setRoomInfo(room);
            const meetingObj = {
                // participants
                users: targetIds,
                vcs: targetVcs,
                // owner details
                owner: user.name,
                owner_id: user._id,
                owner_name: user.name,
                owner_phoneNo: user.phoneNo,
                owner_emailId: user.email,
                owner_tenantId: user.roles[0].tenantId,
                // room details
                room_id: '' + room.roomid.toString(),
                callurl: room.roomurl,
                room: room.roomkey,
                extension: '' + room.extension.toString(),
                gateway_ip: room.gatewayip,
                roomPIN: room.roomPIN,
                // conference info
                title: this.getMeetingTitle(groups, users, vcs),
                is_disconnect: 'false',
                group_id: groups.length ? groups[0]._id : '0',
                token: this.authService.getAuthInfo().jwt,
                time: new Date().valueOf(),
                isInitiater,
                isSelfCall: participants.length ? false : true,
                participantCount: 0
            };
            if (vcs.length) {
                this.sendVCInivite(vcs, room).subscribe(() => {
                    this.rtcService.sendEvent({
                        event: SOCKET_EVENTS.CALL,
                        data: meetingObj
                    });
                    this.sendJoinCall(meetingObj, false);
                });
            } else {
                this.rtcService.sendEvent({
                    event: SOCKET_EVENTS.CALL,
                    data: meetingObj
                });
            }
        });
    }

    makeAnyCall(participants, isInitiater) {
        const groups = participants.filter((participant) => !!participant.members);
        const vcs = participants.filter((participant) => !!participant.ipaddress);
        const users = participants.filter((participant) => !!participant.phoneNo);

        const targetIds = [];
        const targetVcs = [];
        participants.forEach((participant) => {
            if (participant.email) {
                targetIds.push({ userId: participant.userId });
            } else if (participant.ipaddress) {
                targetVcs.push({ vcId: participant.vcId || participant._id });
            } else if (participant.members) {
                participant.members.forEach((member) => targetIds.push({ userId: member.userId }));
            }
        });

        forkJoin([this.userService.getUser(), this.userService.getUserRoom()]).subscribe(([user, room]) => {
            this.rtcService.setRoomInfo(room);
            if (targetVcs.length) {
                this.sendVCInivite(targetVcs, room).subscribe();
            }
            const meetingObj: any = {
                // participants
                users: targetIds,
                vcs: targetVcs,
                // owner details
                owner: user.name,
                owner_id: user._id,
                owner_name: user.name,
                owner_phoneNo: user.phoneNo,
                owner_emailId: user.email,
                owner_tenantId: user.roles[0].tenantId,
                // room details
                room_id: '' + room.roomid.toString(),
                callurl: room.roomurl,
                room: room.roomkey,
                extension: '' + room.extension.toString(),
                gateway_ip: room.gatewayip,
                roomPIN: room.roomPIN,
                // conference info
                title: this.getMeetingTitle(groups, users, vcs),
                is_disconnect: 'false',
                group_id: groups.length ? groups[0]._id : '0',
                token: this.authService.getAuthInfo().jwt,
                time: new Date().valueOf(),
                isInitiater,
                isSelfCall: !participants.length,
                participantCount: 0
            };
            this.activateConference(meetingObj);
        });
    }

    private dialVC(participants, isInitiater) {
        forkJoin([this.userService.getUser(), this.userService.getUserRoom()]).subscribe(([user, room]) => {
            const manualIps = [
                {
                    type: participants[0].type.toLowerCase(),
                    ipaddress: participants[0].ipaddress
                }
            ];
            const payload: any = {
                roomID: '' + room.roomid,
                vcIds: [],
                manualIps
            };
            this.rtcService.inviteVC(payload).subscribe((res) => {
                this.rtcService.setRoomInfo(room);
                const meetingObj: any = {
                    // participants
                    users: [],
                    vcs: [],
                    manualIps: participants,
                    // owner details
                    owner: user.name,
                    owner_id: user._id,
                    owner_name: user.name,
                    owner_phoneNo: user.phoneNo,
                    owner_emailId: user.email,
                    owner_tenantId: user.roles[0].tenantId,
                    // room details
                    room_id: '' + room.roomid.toString(),
                    callurl: room.roomurl,
                    room: room.roomkey,
                    extension: '' + room.extension.toString(),
                    gateway_ip: room.gatewayip,
                    roomPIN: room.roomPIN,
                    // conference info
                    title: this.getMeetingTitle([], [], [], manualIps),
                    is_disconnect: 'false',
                    group_id: '0',
                    token: this.authService.getAuthInfo().jwt,
                    time: new Date().valueOf(),
                    isInitiater,
                    participantCount: 0,
                    isSelfCall: false,
                    dialOutCall: true,
                    tenantId: user.roles[0].tenantId
                };

                // this.activateConference(meetingObj);
                this.sendJoinCall(meetingObj);
            });
        });
    }

    joinUserPersonalRoom(contact) {
        forkJoin([this.userService.getUser(), this.userService.getOtherUserRoom(contact.userId)]).subscribe(
            ([user, room]) => {
                this.rtcService.setRoomInfo(room);
                const meetingObj: any = {
                    owner_id: contact.userId,
                    owner_name: contact.name,
                    owner_emailId: contact.email,
                    callurl: room.room_url,
                    room: room.room_key,
                    room_id: room.roomID.toString(),
                    extension: room.extension.toString(),
                    gateway_ip: room.gateway_ip || room.gatewayip,
                    roomPIN: room.roomPIN,
                    guest_name: user.name,
                    isInitiater: false,
                    participantCount: 0,
                    token: this.authService.getAuthInfo().jwt,
                    is_disconnect: 'false'
                };
                // this.activateConference(meetingObj);
                Object.assign(meetingObj, {
                    users: [
                        {
                            name: user.name,
                            phoneNo: user.phoneNo,
                            userId: user.userId || user._id,
                            email: user.email,
                            tenantId: user.roles[0].tenantId
                        }
                    ],
                    group_id: '0',
                    is_disconnect: 'false',
                    owner: contact.name,
                    owner_phoneNo: contact.phoneNo,
                    tenantId: contact.tenantId,
                    title: user.name
                });
                this.sendJoinCall(meetingObj, true);
            }
        );
    }

    joinOwnPersonalRoom(showVideo = '1') {
        forkJoin([this.userService.getUser()]).subscribe(([user]) => {
            this.userService.getUserRoom().subscribe((room) => {
                this.rtcService.setRoomInfo(room);
                const meetingObj: any = {
                    owner_id: user._id,
                    owner_name: user.name,
                    owner_lname: user.lname,
                    owner_emailId: user.email,
                    callurl: room.roomurl,
                    room: room.roomkey,
                    room_id: (room.roomid || '').toString(),
                    extension: room.extension.toString(),
                    gateway_ip: room.gatewayip,
                    roomPIN: room.vpin || room.roomPIN,
                    userPIN: room.roomPIN,
                    jiomeetId: room.jiomeetId,
                    guest_name: user.name, // + user.lname ? ' ' + user.lname : '',
                    isInitiater: true,
                    participantCount: 0,
                    token: this.authService.getAuthInfo().jwt,
                    is_disconnect: 'false',
                    isSelfCall: false,
                    isJoinMeeting: true,
                    showVideo
                };
                // this.activateConference(meetingObj);
                Object.assign(meetingObj, {
                    users: [],
                    group_id: '0',
                    is_disconnect: 'false',
                    owner: user.name,
                    owner_phoneNo: user.phoneNo || '',
                    tenantId: user.tenantId,
                    title: user.name,
                    topic: '',
                    jiomeetId: room.jiomeetId
                });
                // this.rtcService
                //   .joinCall({
                //     data: meetingObj
                //   })
                //   .subscribe((res: any) => {
                //     meetingObj.historyId = res.historyId;
                //     this.activateConference(meetingObj);
                //   });
                this.sendJoinCall(meetingObj);
            });
        });
    }

    private sendJoinCall(meetingObj, activateConferece = true) {
        let isAuthenticated = this.authService.getIsAuthenticated();
        this.rtcService
            .joinCall(
                {
                    data: meetingObj
                },
                !isAuthenticated
            )
            .subscribe((res: any) => {
                meetingObj.historyId = res.historyId;
                this.appService.setHistoryId(res.historyId);
                if (activateConferece) {
                    this.activateConference(meetingObj);
                }
            });
    }

    private sendVCInivite(vcs, room) {
        const payload: any = {};
        payload.roomID = '' + room.roomid;
        payload.vcIds = vcs.map((vc) => vc.vcId || vc._id);
        payload.manualIps = [];
        vcs.map((vc) => {
            if (vc.manualIps && vc.manualIps.length) {
                payload.manualIps.push({
                    type: vc.type.toLowerCase(),
                    ipaddress: vc.ipaddress
                });
            }
        });
        return this.rtcService.inviteVC(payload);
    }

    private activateConference(meetingObj, isWatchAlong = false) {
        this.eventEmitterService.emit({
            type: !isWatchAlong ? APP_EVENTS.ACTIVATE_CONFERENCE : 'ACTIVATE_WA_CONFERENCE',
            data: meetingObj
        });
    }

    private getMeetingTitle(groups = [], users = [], vcs = [], manualIps = []) {
        return `${[
            ...groups.map((g) => g.title),
            ...users.map((u) => u.name),
            ...vcs.map((v) => v.name),
            ...manualIps.map((i) => i.ipaddress)
        ]
            .slice(0, 3)
            .join(' ,')}`;
    }
}
