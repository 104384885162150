import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    AppService,
    EventData,
    EventEmitterService,
    UserService,
    UtilService,
    GoogleTagManagerService
} from 'src/app/core';
import { WEBSITE_CPAAS_FEATURES, WEBSITE_CPAAS_TESTIMONIALS, WEBSITE_CPAAS_UPCOMING_EVENTS } from '../websiteConstant';
import { GA_PAGEVIEW } from '../ga-pageview';

import * as Flickity from 'flickity';
import { APP_EVENTS } from 'src/app/constants';
import { GA_EVENTS } from '../ga-events';

@Component({
    selector: 'app-website-cpaas',
    templateUrl: './website-cpaas.component.html',
    styleUrls: ['./website-cpaas.component.scss']
})
export class WebsiteCpaasComponent implements OnInit, AfterViewInit {
    @ViewChild('testimonialSlider', { static: true })
    testimonialSlider: ElementRef;
    isMobile = false;
    websiteCpaasFeatures: any = WEBSITE_CPAAS_FEATURES;
    websiteCpassTestimonials = WEBSITE_CPAAS_TESTIMONIALS;
    cPaasEvents = WEBSITE_CPAAS_UPCOMING_EVENTS;
    private testimonialCarousel;
    websiteMetaInfo;
    integrationPopupState = false;
    integration = {
        popup_heading: '',
        processSteps: []
    };
    platFormTestimonials: string[] = [];
    platformBranding: string[] = [];

    integrations = [
        {
            title: 'Simple API Integration',
            ttitle: 'website_platforms.simple_api_integration',
            desc: 'Use JioMeet APIs for scheduling meetings in your application, while the actual meeting happens on the JioMeet app.',
            tdesc: 'website_platforms.simple_api_integration_desc',
            icon: 'assets/img/cpaas/integration_api.svg',
            popup_heading: 'website_platforms.integration.API.process_simple_api',
            processSteps: [
                'website_platforms.integration.API.steps.submit_your_interest',
                'website_platforms.integration.API.steps.team_will_getin_touch',
                'website_platforms.integration.API.steps.get_jiomeet_enterprise',
                'website_platforms.integration.API.steps.get_oauth_token',
                'website_platforms.integration.API.steps.use_jiomeet_api',
                'website_platforms.integration.API.steps.use_admin_portal'
            ]
        },
        {
            title: 'SDK Integration',
            ttitle: 'website_platforms.sdk_integration',
            desc: 'Integrate JioMeet SDK in your application for all video conferencing needs.',
            tdesc: 'website_platforms.sdk_integration_desc',
            icon: 'assets/img/cpaas/integration_sdk.svg',
            popup_heading: 'website_platforms.integration.SDK.process_sdk',
            processSteps: [
                'website_platforms.integration.SDK.steps.submit_your_interest',
                'website_platforms.integration.SDK.steps.team_will_getin_touch',
                'website_platforms.integration.SDK.steps.get_jiomeet_enterprise',
                'website_platforms.integration.SDK.steps.get_oauth_token',
                'website_platforms.integration.SDK.steps.get_latest_jiomeet',
                'website_platforms.integration.SDK.steps.integrate_jiomeet',
                'website_platforms.integration.SDK.steps.use_admin_portal'
            ]
        }
    ];
    reasonsToChoose = [
        {
            title: 'Enhanced meeting management',
            ttitle: 'website_platforms.enhanced_meeting_management',
            desc: 'With our enhanced meeting management customer remain in complete control for scheduling, modifying meetings with high level of security and participant management.',
            tdesc: 'website_platforms.enhanced_meeting_management_desc',
            icon: 'assets/img/cpaas/meeting-management.svg'
        },
        {
            title: 'Natively hosting meetings',
            ttitle: 'website_platforms.native_meeting_host',
            desc: 'Meetings can be hosted natively on your application giving your customers a seamless experience of a video calling.',
            tdesc: 'website_platforms.native_meeting_host_desc',
            icon: 'assets/img/cpaas/native-host-meet.svg'
        },
        {
            title: 'Classroom mode',
            ttitle: 'website_platforms.anaylytics_student_participat',
            desc: 'Enable classroom mode to your meetings to restrict participants from sharing screen, editing whiteboard and chat.',
            tdesc: 'website_platforms.anaylytics_student_participat_desc',
            icon: 'assets/img/cpaas/classroom-mode.svg'
        },
        {
            title: 'Scalability Usage based',
            ttitle: 'website_platforms.scalability_usage',
            desc: 'Our architecture is designed is designed to handle large number of traffic and our systems are scalable to handle multiple concurrent users as per the demand.',
            tdesc: 'website_platforms.scalability_usage_desc',
            icon: 'assets/img/cpaas/scalability-usage.svg'
        },
        {
            title: 'Priority support',
            ttitle: 'website_platforms.priority_support',
            desc: 'Our specialized technical team to help you during integration and operational support.',
            tdesc: 'website_platforms.priority_support_desc',
            icon: 'assets/img/cpaas/priority-support.svg'
        },
        {
            title: 'Multi-site support',
            ttitle: 'website_platforms.multi_site_support',
            desc: 'We provide whitelisting of multiple domain as per your business needs for seamless connectivity.',
            tdesc: 'website_platforms.multi_site_support_desc',
            icon: 'assets/img/cpaas/multi-site-support.svg'
        }
    ];

    constructor(
        private appService: AppService,
        private utilService: UtilService,
        private router: Router,
        private userService: UserService,
        private eventEmitterService: EventEmitterService,
        private gTagService: GoogleTagManagerService
    ) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser(1080);
        this.translateCpaasSolutions();
        this.translateReasons();
        this.getMetaInfo();
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 1080 ? false : true;
            }
        });
        this.gTagService.pushOnDataLayer(GA_PAGEVIEW.PRODUCT_PAGE_VIEW_PLATFORM);
    }

    getMetaInfo() {
        this.userService.getWebsiteMetaInfo().subscribe(
            (res) => {
                if (res) {
                    this.websiteMetaInfo = res;
                    setTimeout(() => {
                        this.initTestimonialSlider();
                    }, 0);
                    this.testimonialCheck(this.websiteMetaInfo.testimonial);
                    this.brandingCheck(this.websiteMetaInfo.branding);
                }
            },
            (err) => {}
        );
    }

    brandingCheck(branding) {
        if (branding?.length > 0) {
            branding.forEach((element) => {
                if (element.publishOn.includes('Platform')) {
                    this.platformBranding.push(element);
                }
            });
        }
    }

    async translateCpaasSolutions() {
        const websiteCpaasFeatures = await this.utilService.handleDataTranslation('website', {
            cPaasFeatures: this.websiteCpaasFeatures
        });
        this.websiteCpaasFeatures = websiteCpaasFeatures['cPaasFeatures'];
    }

    async translateReasons() {
        const websitePlatformReasons = await this.utilService.handleDataTranslation('website_platforms', {
            reasonsToChoose: this.reasonsToChoose
        });
        this.reasonsToChoose = websitePlatformReasons['reasonsToChoose'];
    }

    ngAfterViewInit() {}

    testimonialCheck(testimonials) {
        if (testimonials?.length > 0) {
            testimonials.forEach((element) => {
                if (element.publishOn.includes('Platform')) {
                    this.platFormTestimonials.push(element);
                }
            });
        }
    }

    initTestimonialSlider() {
        this.testimonialCarousel = new Flickity(this.testimonialSlider.nativeElement, {
            prevNextButtons: !this.isMobile,
            autoPlay: 4000,
            contain: false,
            cellAlign: 0,
            pageDots: true,
            selectedAttraction: 0.2,
            friction: 0.8,
            index: 0,
            resize: true
        });
    }

    showIntegrationPopUp(integration) {
        if (integration.title == 'Simple API Integration') {
            this.gTagService.sendEvent(GA_EVENTS.SIMPLE_API_INTEGRATION);
        } else {
            this.gTagService.sendEvent(GA_EVENTS.SDK_INTEGRATION);
        }
        this.integrationPopupState = true;
        this.integration = integration;
    }

    hideIntegrationPopup() {
        this.integrationPopupState = false;
    }

    gotToDevCenter() {
        window.open('/jioconf', '_blank');
    }

    goToContactSales(type) {
        const event =
            type == 1 ? GA_EVENTS.PRODUCT_CONTACT_SALES_PLATFORM : GA_EVENTS.PRODUCT_CONTACT_SALES_PLATFORM_LEARNMORE;
        this.gTagService.sendEvent(event);
        this.router.navigate(['contactsales']);
    }
}
