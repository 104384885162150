<div class="recording-player" [ngClass]="{ 'mobile-device': isMobileOS, 'web-version': !isMobileOS }">
    <div class="recording-container">
        <div
            class="recording-info"
            [class.remove-margin]="
                !(currentUser.isOTT && !currentUser.proStatus && recordingDetails?.recordingDetails?.expiryTime) &&
                isMobileOS
            "
            *ngIf="recordingDetailsAvailable"
        >
            <div class="info-container">
                <div class="meeting-details item">
                    <div class="meeting-title">
                        <span>{{ recordingDetails?.title }}</span>
                    </div>
                    <div class="meeting-time">
                        <div class="date">{{ getFormattedDate(recordingDetails?.startTime, true) }}</div>
                        <div class="time">
                            {{ formatAMPM(recordingDetails?.startTime) }} - {{ formatAMPM(recordingDetails?.endTime) }}
                        </div>
                    </div>
                </div>
                <div class="host-details item" *ngIf="!isCpass">
                    <div class="title">{{ 'meeting_controls_page.host' | translate }}</div>
                    <div class="host-name details">
                        {{ recordingDetails?.hostInfo.name }} {{ recordingDetails?.hostInfo.lname }}
                    </div>
                </div>
                <div class="participants-details item" *ngIf="!isCpass">
                    <div class="title">{{ 'meetingcard.participants' | translate }}</div>
                    <div class="participants details">
                        <div class="participant-list">
                            <ng-container *ngFor="let participant of recordingDetails?.participants; let i = index">
                                <span>
                                    {{ participant.name }} {{ participant.lname
                                    }}{{
                                        i < recordingDetails?.participants.length - 1 &&
                                        recordingDetails?.participants[i + 1].name
                                            ? ', '
                                            : ''
                                    }}
                                </span>
                            </ng-container>
                        </div>
                        <span *ngIf="recordingDetails?.participants.length > 3">
                            +{{ recordingDetails?.participants.length - 3 }}</span
                        >
                    </div>
                </div>
                <div class="recording-duration item">
                    <div class="title">{{ 'website.recording_duration' | translate }}</div>
                    <div class="duration details">
                        {{ recordingDetails?.recordingDetails.duration | timestampToDuration }}
                    </div>
                </div>
                <div class="file-size-details item">
                    <div class="title">{{ 'aftersignin_or_introduction.previousmeeting.file_size' | translate }}</div>
                    <div class="file-size details">
                        {{ recordingDetails?.recordingDetails.fileSize | filesizeConverter }}
                    </div>
                </div>
                <ng-container *ngIf="!isMobileOS">
                    <div class="recording-control item">
                        <div
                            class="download-link control-item"
                            (click)="
                                isRecordingExpired
                                    ? $event.stopPropagation()
                                    : downloadRecording(recordingDetails?.recordingDetails.url);
                                (isRecordingExpired ? false : null)
                            "
                            [ngClass]="[isRecordingExpired ? 'isDisabled' : '']"
                        >
                            <div class="control-continer">
                                <span class="link-title">{{ 'website.download_recording' | translate }}</span>
                                <span class="icon">
                                    <img alt="" class="img" src="assets/images/download_icn.svg" />
                                </span>
                            </div>
                        </div>
                        <div
                            class="copy-link control-item"
                            (click)="
                                isRecordingExpired
                                    ? $event.stopPropagation()
                                    : copyRecordingLink(recordingDetails?.recordingDetails.streamingURL);
                                (isRecordingExpired ? false : null)
                            "
                            [ngClass]="[isRecordingExpired ? 'isDisabled' : '']"
                        >
                            <div class="control-continer">
                                <span #url_text class="link-title">{{ 'invitepopup.copy_url' | translate }}</span>
                                <span class="icon">
                                    <img alt="" class="img-back" src="assets/images/chat_reco_copy.svg" />
                                    <img class="img-top" alt="" src="assets/images/Group 194935.svg" />
                                </span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <ng-container *ngIf="isMobileOS">
                <div class="recording-control item">
                    <div
                        class="download-link control-item"
                        (click)="
                            isRecordingExpired
                                ? $event.stopPropagation()
                                : downloadRecording(recordingDetails?.recordingDetails.url);
                            (isRecordingExpired ? false : null)
                        "
                        [ngClass]="[isRecordingExpired ? 'isDisabled' : '']"
                    >
                        <div class="control-continer">
                            <span class="link-title">{{ 'website.download_recording' | translate }}</span>
                            <span class="icon">
                                <img alt="" class="img" src="assets/images/download_icn.svg" />
                            </span>
                        </div>
                    </div>
                    <div
                        class="copy-link control-item"
                        (click)="
                            isRecordingExpired
                                ? $event.stopPropagation()
                                : copyRecordingLink(recordingDetails?.recordingDetails.streamingURL);
                            (isRecordingExpired ? false : null)
                        "
                        [ngClass]="[isRecordingExpired ? 'isDisabled' : '']"
                    >
                        <div class="control-continer">
                            <span #url_text class="link-title">{{ 'invitepopup.copy_url' | translate }}</span>
                            <span class="icon">
                                <img alt="" class="img-back" src="assets/images/chat_reco_copy.svg" />
                                <img class="img-top" alt="" src="assets/images/Group 194935.svg" />
                            </span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="recording-video">
            <app-videojs-player
                *ngIf="!isRecordingExpired && updateUrl.getValue()"
                [setupDone]="updateUrl"
            ></app-videojs-player>
            <div class="expired-video">
                <img src="assets/images/recording_expired_video.svg" alt="" *ngIf="isRecordingExpired" />
            </div>
            <div
                class="recording-upgrade"
                *ngIf="showRecordingTimeBanner && (updateUrl.getValue() || isRecordingExpired)"
            >
                <div class="recording-upgrade-items">
                    <div class="recording-upgrade-content">
                        <img src="assets/img/clock_recording.svg" />
                        <ng-container *ngIf="recordingDetails?.recordingDetails?.expiryTime">
                            <div
                                class="recording-upgrade-text"
                                [innerHTML]="
                                    hoursRemainingInExpiry(
                                        recordingDetails?.recordingDetails?.expiryTime,
                                        recordingDetails?.hostInfo?._id
                                    )
                                "
                            ></div>
                        </ng-container>
                    </div>
                    <button
                        (click)="openPricingPage()"
                        *ngIf="!currentUser.proStatus && recordingDetails?.hostInfo?._id == currentUser?._id"
                    >
                        {{ 'website.upgrade_now' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
