<div class="group-chat-conponent" #content>
    <div class="header" [class.popout]="popout" [ngClass]="isMobileDevice ? 'header-mobile' : ''">
        <div class="dropdown" *ngIf="!popout">
            <div
                class="dropdown-toggle"
                id="dropdownHeaderButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                *ngIf="meetingObj && !meetingObj.hideAllCallControls && !isMobileDevice"
                tabindex="0"
                role="button"
                aria-label="To open the Chat dropdown menu, please press the Enter key."
                #dropdownToggle
            >
                <div class="icon-wrap">
                    <img alt="" class="arrow-gray" src="assets/img/arrow_down_icn.svg" />
                    <img alt="" class="arrow-white" src="assets/img/arrow_down_white_icn.svg" />
                </div>
            </div>
            <div class="mobile-back" *ngIf="isMobileDevice">
                <div class="icon-wrap">
                    <img alt="" src="assets/img/mobile_participants_back_btn.svg" (click)="closeView()" />
                </div>
            </div>
            <div class="dropdown-menu dropdown-menu-right">
                <div
                    class="dropdown-item"
                    (click)="closeView()"
                    (keydown.enter)="closeView()"
                    tabindex="0"
                    aria-label="{{ 'inside_call.chat.close' | translate }}"
                    role="button"
                >
                    <div class="text">{{ 'inside_call.chat.close' | translate }}</div>
                </div>
                <div
                    class="dropdown-item"
                    *ngIf="!popout"
                    (click)="popoutChat()"
                    (keydown.enter)="popoutChat()"
                    tabindex="0"
                    aria-label="{{ 'inside_call.chat.pop_out' | translate }}"
                    role="button"
                >
                    <div class="text">{{ 'inside_call.chat.pop_out' | translate }}</div>
                </div>
            </div>
        </div>
        <!-- <button *ngIf="popout" class="btn btn-primary" (click)="closeView()">
            {{ 'inside_call.chat.close' | translate }}
        </button> -->
        <label
            class="title chat-popup"
            tabindex="0"
            aria-label="The chat panel has been opened!
                Please use the Tab key to navigate through the chat interface and interact with the participants"
        >
            {{ 'inside_call.chat.chat' | translate }}
        </label>
        <div (click)="switchToAttachmentSection()" *ngIf="attachmentsCount > 0">
            <button class="fileCount btn btn-light" [class.disabled]="!(isAuthenticated && enableJioCloud)">
                {{ 'inside_call.chat.files' | translate }} ({{ attachmentsCount }})
            </button>
        </div>
        <img *ngIf="popout" class="close-icn" src="assets/images/cross-options.png" alt="" (click)="closeView()" />
    </div>
    <div class="restrict-chat-container" *ngIf="showChatDisableRelatedChanges()">
        <div class="restrict-users">
            <span class="disable-chat-text" tabindex="0">
                {{ 'inside_call.chat.disable_chat' | translate }}
            </span>
            <select [(ngModel)]="selectedChatOption" (change)="onChatDropdownChange($event)" class="restrict-dropdown">
                <option value="PARTICIPANTS">{{ 'inside_call.chat.participants_only' | translate }}</option>
                <option value="ALL">{{ 'inside_call.chat.everyone' | translate }}</option>
                <option value="NONE">{{ 'inside_call.chat.no_one' | translate }}</option>
            </select>
        </div>
    </div>
    <div
        class="messages full chat-group-container"
        #messages
        [ngClass]="{
            'messages-mobile': isMobileDevice,
            'hide-scroll': isJMMeeting && isLargeMeeting,
            'add-top': showChatDisableRelatedChanges()
        }"
        (scroll)="showMoreMessages($event)"
    >
        <!-- [style.bottom]="bottomStyleReducer()" -->
        <div class="chat">
            <virtual-scroller
                (scroll)="showMoreMessages($event)"
                class="chat-group-large-meeting"
                #virtualChatList
                [items]="chatMessages"
                style="width: 100%; overflow-x: hidden"
                *ngIf="isJMMeeting && isLargeMeeting"
                [enableUnequalChildrenSizes]="true"
                [style.height.px]="chatContentHeight"
            >
                <div
                    class="d-flex justify-content-between pb-1"
                    style="width: 100%"
                    *ngFor="let message of virtualChatList.viewPortItems"
                >
                    <div class="date-container" *ngIf="message?.key">
                        <div class="date p-3">
                            <span>{{ message.key | dateLabel }}</span>
                        </div>
                    </div>
                    <div
                        class="chat-bubble"
                        *ngIf="!message?.key && !message?.messageComponent?.attachments?.length"
                        [class.right-aligned]="message?.createdBy === getCurrentUserId()"
                    >
                        <div class="content">
                            <div class="avatar" *ngIf="message?.createdBy !== getCurrentUserId()">
                                <span *ngIf="message?.createdBy?.includes('gu')">
                                    <div appAvatarWithInitials [name]="message?.creatorsName"></div>
                                </span>
                                <img
                                    alt=""
                                    [src]="getProfilePic(message?.createdBy)"
                                    *ngIf="!message?.createdBy?.includes('gu')"
                                />
                            </div>
                            <div class="block position-relative w-10">
                                <div class="text p-3" [class.self]="getCurrentUserId() === message?.createdBy">
                                    <div class="name-wrap">
                                        <div class="name">
                                            <span class="text-in" *ngIf="getCurrentUserId() === message?.createdBy"
                                                >{{ 'inside_call.chat.you' | translate }}
                                            </span>
                                            <span class="text-in" *ngIf="getCurrentUserId() !== message?.createdBy">
                                                <div
                                                    class="text-truncate"
                                                    *ngIf="message?.createdBy?.includes('gu-')"
                                                    title="{{ message?.creatorsName }} {{ message?.creatorsLName }}"
                                                >
                                                    {{ message?.creatorsName }} {{ message?.creatorsLName }}
                                                </div>
                                                <div
                                                    class="text-truncate"
                                                    *ngIf="!message?.createdBy?.includes('gu-')"
                                                    title="{{ message?.creatorsName }} {{ message?.creatorsLName }}"
                                                >
                                                    {{ message?.creatorsName }} {{ message?.creatorsLName }}
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        [innerHTML]="message?.messageComponent?.text?.trim() | linkify | showdown"
                                        id="msg-area"
                                        class="msg-area"
                                    ></div>
                                </div>
                                <div class="time p-3" [ng-class]="desktopAppService.isDesktopApp ? 'desktop-time' : ''">
                                    {{ message?.cOn | date : 'HH:mm' | convert24to12format }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="attachment-bubble"
                        *ngIf="
                            !message?.key &&
                            message?.messageComponent?.attachments?.length &&
                            (message?.messageComponent?.attachments[0].imageTrancodedURL !== '' ||
                                message?.messageComponent?.attachments[0].downloadURL !== '')
                        "
                        [class.right-aligned]="message?.createdBy === getCurrentUserId()"
                    >
                        <button id="downloadAnchor" #downloadAnchor style="display: none"></button>
                        <div class="content">
                            <div class="avatar" *ngIf="message?.createdBy !== getCurrentUserId()">
                                <span *ngIf="message?.createdBy?.includes('gu')">
                                    <div appAvatarWithInitials [name]="message?.creatorsName"></div>
                                </span>
                                <img
                                    alt=""
                                    [src]="getProfilePic(message?.createdBy)"
                                    *ngIf="!message?.createdBy?.includes('gu')"
                                />
                            </div>
                            <div class="block position-relative">
                                <div class="text px-3 py-3" [class.self]="getCurrentUserId() === message?.createdBy">
                                    <div class="name-wrap">
                                        <div class="name">
                                            <span class="text-in" *ngIf="getCurrentUserId() === message?.createdBy"
                                                >You
                                            </span>
                                            <span class="text-in" *ngIf="getCurrentUserId() !== message?.createdBy">
                                                <div
                                                    class="text-truncate"
                                                    title="{{ message?.creatorsName }} {{ message?.creatorsLName }}"
                                                >
                                                    {{ message?.creatorsName }} {{ message?.creatorsLName }}
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <!-- <div
                                        class="moreOptions pointer"
                                        *ngIf="isAuthenticated && enableDownloadPreview && !message?.msgDeleted"
                                        (click)="toggleMoreOptions(message)"
                                        [ngClass]="desktopAppService?.isDesktopApp ? 'more-electron' : ''"
                                    >
                                        <div class="dropdown" outside (outside)="clickOutside(message)">
                                            <img alt="" src="assets/images/moreOptionsIcon.svg" />
                                            <div class="custom-dropdown-menu" *ngIf="message?.showMoreOptions">
                                                <div class="dropdown-item pointer" (click)="fileInfo(message)">
                                                    <img
                                                        alt=""
                                                        style="width: 20px; height: 20px; margin-right: 2px"
                                                        src="assets/images/file_info.svg"
                                                    />
                                                    {{ 'inside_call.chat.files_info' | translate }}
                                                </div>
                                                <div
                                                    class="dropdown-item pointer"
                                                    (click)="deleteItem(message)"
                                                    *ngIf="getCurrentUserId() === message?.createdBy"
                                                >
                                                    <img alt="" src="assets/images/deletIcon.svg" />
                                                    {{ 'inside_call.chat.delete' | translate }}
                                                </div>
                                                <div class="dropdown-item pointer" (click)="downloadFile(message)">
                                                    <img alt="" src="assets/images/downloadIcon.svg" />
                                                    {{ 'inside_call.chat.download' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="msg-area" [class.self]="getCurrentUserId() === message?.createdBy">
                                        <div *ngIf="message.status !== 'active' || isMsgDeleted">
                                            <div>
                                                <i id="msg-area" style="word-break: break-word">{{
                                                    'inside_call.chat.this_message_has_been_deleted' | translate
                                                }}</i>
                                            </div>
                                            <div class="foot text-right" tabindex="0" *ngIf="!justDeleted">
                                                <span class="date-time">{{
                                                    message?.mOn | date : 'HH:mm' | convert24to12format
                                                }}</span>
                                            </div>
                                            <div class="foot text-right" tabindex="0" *ngIf="justDeleted">
                                                <span class="date-time">{{
                                                    currentTime | date : 'HH:mm' | convert24to12format
                                                }}</span>
                                            </div>
                                        </div>
                                        <div *ngIf="!message?.msgDeleted && !isImagePastedFirst">
                                            <div
                                                [innerHTML]="
                                                    message?.messageComponent?.text?.trim() | linkify | showdown
                                                "
                                                id="msg-area"
                                                class="msg-area"
                                            ></div>
                                        </div>

                                        <div *ngIf="!message?.msgDeleted">
                                            <div
                                                *ngFor="
                                                    let attachmnt of message?.messageComponent?.attachments;
                                                    trackBy: trackByFuntion
                                                "
                                            >
                                                <app-chat-attachments
                                                    [message]="message"
                                                    [showAttachments]="isDesktopApp()"
                                                    [item]="attachmnt"
                                                    [jiomeetId]="message?.threadId"
                                                    [maxWidth]="'190px'"
                                                ></app-chat-attachments>
                                            </div>
                                        </div>
                                        <div *ngIf="!message?.msgDeleted && isImagePastedFirst">
                                            <div
                                                [innerHTML]="
                                                    message?.messageComponent?.text?.trim() | linkify | showdown
                                                "
                                                id="msg-area"
                                                class="msg-area"
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="time p-3"
                                    [class.attachments-upload-time]="message?.messageComponent?.attachments?.length > 0"
                                >
                                    {{ message?.cOn | date : 'HH:mm' | convert24to12format }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </virtual-scroller>
            <ng-container *ngIf="!(isJMMeeting && isLargeMeeting)">
                <div class="chat-group" *ngFor="let group of chatGroupsByDate">
                    <div class="date p-3">
                        <span>{{ group.key | dateLabel }}</span>
                    </div>
                    <div *ngFor="let message of group?.value?.slice()?.reverse(); trackBy: trackMessages">
                        <div
                            class="chat-bubble"
                            *ngIf="!message?.messageComponent?.attachments?.length"
                            [class.right-aligned]="message.createdBy === getCurrentUserId()"
                        >
                            <div class="content">
                                <div class="avatar" *ngIf="message.createdBy !== getCurrentUserId()">
                                    <span *ngIf="message?.createdBy.includes('gu')">
                                        <div appAvatarWithInitials [name]="message?.creatorsName"></div>
                                    </span>
                                    <img
                                        alt=""
                                        [src]="getProfilePic(message?.createdBy)"
                                        *ngIf="!message?.createdBy.includes('gu')"
                                    />
                                </div>

                                <div
                                    class="block position-relative"
                                    (mouseenter)="show_Options(message)"
                                    (mouseleave)="hideOptions(1)"
                                    (outside)="resetSettings(message)"
                                >
                                    <div class="text p-3" [class.self]="getCurrentUserId() === message?.createdBy">
                                        <div class="name-wrap">
                                            <div class="name">
                                                <span
                                                    class="text-in"
                                                    tabindex="0"
                                                    aria-label="You"
                                                    *ngIf="getCurrentUserId() === message?.createdBy"
                                                    >{{ 'inside_call.chat.you' | translate }}
                                                </span>
                                                <span
                                                    class="text-in"
                                                    tabindex="0"
                                                    aria-label="{{ message?.creatorsName }} {{
                                                        message?.creatorsLName
                                                    }}"
                                                    *ngIf="getCurrentUserId() !== message?.createdBy"
                                                >
                                                    <div
                                                        class="text-truncate"
                                                        *ngIf="message?.createdBy?.includes('gu-')"
                                                        title="{{ message?.creatorsName }} {{ message?.creatorsLName }}"
                                                    >
                                                        {{ message?.creatorsName }} {{ message?.creatorsLName }}
                                                    </div>
                                                    <div
                                                        class="text-truncate"
                                                        *ngIf="!message?.createdBy?.includes('gu-')"
                                                        title="{{ message?.creatorsName }} {{ message?.creatorsLName }}"
                                                    >
                                                        {{ message?.creatorsName }} {{ message?.creatorsLName }}
                                                    </div>
                                                </span>
                                            </div>
                                        </div>

                                        <div
                                            *ngIf="show_delete && message?._id === messageId"
                                            class="delete-container"
                                            (click)="deleteMessagesFromChat(message)"
                                        >
                                            <img
                                                alt=""
                                                src="assets/images/deletIcon.svg"
                                                class="img-size flex-item1 img"
                                            />
                                            <span class="flex-item2 delete_text">{{
                                                'inside_call.chat.delete' | translate
                                            }}</span>
                                        </div>
                                        <div
                                            *ngIf="
                                                getCurrentUserId() === message?.createdBy &&
                                                show_options &&
                                                message?._id === messageId &&
                                                message.status === 'active'
                                            "
                                            class="flex-item options"
                                            (click)="showDelete()"
                                            (mouseenter)="toggleDeleteImage()"
                                            (mouseleave)="toggleDeleteImageAgain()"
                                        >
                                            <img
                                                alt=""
                                                [src]="
                                                    showWhiteDots
                                                        ? 'assets/images/3_dots_white.svg'
                                                        : 'assets/images/3_dots.svg'
                                                "
                                            />
                                        </div>
                                        <div class="msg-container">
                                            <div
                                                *ngIf="
                                                    !deletedMessages.includes(message?._id) &&
                                                    message.status === 'active'
                                                "
                                                id="msg-area"
                                                class="msg-area"
                                                tabindex="0"
                                                [innerHTML]="
                                                    message?.messageComponent?.text?.trim() | linkify | showdown
                                                "
                                            ></div>
                                        </div>

                                        <div
                                            *ngIf="
                                                message.status !== 'active' ||
                                                (isMsgDeleted && deletedMessages.includes(message?._id))
                                            "
                                        >
                                            <div>
                                                <i id="msg-area" style="word-break: break-word">{{
                                                    'inside_call.chat.this_message_has_been_deleted' | translate
                                                }}</i>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="time p-3"
                                        tabindex="0"
                                        *ngIf="message.status === 'active' && !deletedMessages.includes(message?._id)"
                                    >
                                        {{ message?.cOn | date : 'HH:mm' | convert24to12format }}
                                    </div>
                                    <div class="time p-3" tabindex="0" *ngIf="message.status !== 'active'">
                                        {{ message?.mOn | date : 'HH:mm' | convert24to12format }}
                                    </div>
                                    <div
                                        class="time p-3"
                                        tabindex="0"
                                        *ngIf="
                                            justDeleted &&
                                            message.status === 'active' &&
                                            deletedMessages.includes(message?._id)
                                        "
                                    >
                                        {{ currentTime | date : 'HH:mm' | convert24to12format }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="attachment-bubble"
                            *ngIf="
                                message?.messageComponent?.attachments?.length &&
                                (message?.messageComponent?.attachments[0].imageTrancodedURL !== '' ||
                                    message?.messageComponent?.attachments[0].downloadURL !== '')
                            "
                            [class.right-aligned]="message.createdBy === getCurrentUserId()"
                        >
                            <button id="downloadAnchor" #downloadAnchor style="display: none"></button>
                            <div class="content">
                                <div class="avatar" *ngIf="message.createdBy !== getCurrentUserId()">
                                    <span *ngIf="message?.createdBy.includes('gu')">
                                        <div appAvatarWithInitials [name]="message?.creatorsName"></div>
                                    </span>
                                    <img
                                        alt=""
                                        [src]="getProfilePic(message?.createdBy)"
                                        *ngIf="!message?.createdBy.includes('gu')"
                                    />
                                </div>
                                <div class="block position-relative">
                                    <div
                                        class="text px-3 py-3"
                                        [class.self]="getCurrentUserId() === message?.createdBy"
                                        (mouseenter)="show_Options(message)"
                                        (mouseleave)="hideOptions()"
                                        (outside)="resetSettings(message)"
                                    >
                                        <div class="name-wrap">
                                            <div class="name">
                                                <span class="text-in" *ngIf="getCurrentUserId() === message?.createdBy"
                                                    >You
                                                </span>
                                                <span class="text-in" *ngIf="getCurrentUserId() !== message?.createdBy">
                                                    <div
                                                        class="text-truncate"
                                                        title="{{ message?.creatorsName }} {{ message?.creatorsLName }}"
                                                    >
                                                        {{ message?.creatorsName }} {{ message?.creatorsLName }}
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div *ngIf="message?.messageComponent?.attachments?.length > 0">
                                            <div
                                                class="container"
                                                (mouseenter)="show_Options(message)"
                                                (mouseleave)="hideOptions()"
                                                (outside)="resetSettings(message)"
                                            >
                                                <div *ngIf="show_options && message?._id === messageId" class="options">
                                                    <div
                                                        *ngIf="show_delete && message?._id === messageId"
                                                        class="delete-container"
                                                        (click)="deleteMessagesFromChat(message)"
                                                    >
                                                        <img
                                                            alt=""
                                                            src="assets/images/deletIcon.svg"
                                                            class="img-size flex-item1"
                                                        />
                                                        <span class="flex-item2">{{
                                                            'inside_call.chat.delete' | translate
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="time">{{message?.cOn | date: 'HH:mm' | convert24to12format }}</div> -->
                                        <!-- <div
                                            class="moreOptions pointer"
                                            *ngIf="isAuthenticated && enableDownloadPreview && !message?.msgDeleted"
                                            (click)="toggleMoreOptions(message)"
                                            [ngClass]="desktopAppService?.isDesktopApp ? 'more-electron' : ''"
                                        >
                                            <div class="dropdown" outside (outside)="clickOutside(message)">
                                                <img alt="" src="assets/images/moreOptionsIcon.svg" />
                                                <div class="custom-dropdown-menu" *ngIf="message?.showMoreOptions">
                                                    <div class="dropdown-item pointer" (click)="fileInfo(message)">
                                                        <img
                                                            alt=""
                                                            style="width: 20px; height: 20px; margin-right: 2px"
                                                            src="assets/images/file_info.svg"
                                                        />
                                                        {{ 'inside_call.chat.files_info' | translate }}
                                                    </div>
                                                    <div
                                                        class="dropdown-item pointer"
                                                        (click)="deleteItem(message)"
                                                        *ngIf="getCurrentUserId() === message?.createdBy"
                                                    >
                                                        <img alt="" src="assets/images/deletIcon.svg" />
                                                        {{ 'inside_call.chat.delete' | translate }}
                                                    </div>
                                                    <div class="dropdown-item pointer" (click)="downloadFile(message)">
                                                        <img alt="" src="assets/images/downloadIcon.svg" />
                                                        {{ 'inside_call.chat.download' | translate }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="msg-area" [class.self]="getCurrentUserId() === message?.createdBy">
                                            <div
                                                *ngIf="
                                                    message.status !== 'active' ||
                                                    (isMsgDeleted && deletedMessages.includes(message?._id))
                                                "
                                            >
                                                <div>
                                                    <i id="msg-area" style="word-break: break-word">{{
                                                        'inside_call.chat.this_message_has_been_deleted' | translate
                                                    }}</i>
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="
                                                    !message?.msgDeleted &&
                                                    message?.status === 'active' &&
                                                    !(message?.messageComponent?.attachments[0].fileName[0] === '1'
                                                        ? true
                                                        : false)
                                                "
                                                class="message-padding"
                                            >
                                                <div
                                                    [innerHTML]="
                                                        message?.messageComponent?.text?.trim() | linkify | showdown
                                                    "
                                                    id="msg-area"
                                                    class="msg-area"
                                                ></div>
                                            </div>

                                            <div
                                                *ngIf="
                                                    getCurrentUserId() === message?.createdBy &&
                                                    show_options &&
                                                    message?._id === messageId &&
                                                    message.status === 'active'
                                                "
                                                class="flex-item options"
                                                (click)="showDelete()"
                                            >
                                                <img alt="" src="assets/images/3 dots.svg" />
                                            </div>
                                            <div
                                                *ngIf="
                                                    !(
                                                        message.status !== 'active' ||
                                                        (isMsgDeleted && deletedMessages.includes(message?._id))
                                                    )
                                                "
                                            >
                                                <div
                                                    *ngFor="
                                                        let attachmnt of message?.messageComponent?.attachments;
                                                        trackBy: trackByFuntion
                                                    "
                                                >
                                                    <app-chat-attachments
                                                        *ngIf="!message?.msgDeleted"
                                                        [message]="message"
                                                        [showAttachments]="isDesktopApp()"
                                                        [item]="attachmnt"
                                                        [jiomeetId]="message?.threadId"
                                                        [maxWidth]="'190px'"
                                                    ></app-chat-attachments>
                                                </div>
                                            </div>

                                            <div
                                                *ngIf="
                                                    !message?.msgDeleted &&
                                                    message?.messageComponent?.attachments[0].fileName[0] === '1'
                                                        ? true
                                                        : false
                                                "
                                                class="message-padding"
                                            >
                                                <div
                                                    [innerHTML]="
                                                        message?.messageComponent?.text?.trim() | linkify | showdown
                                                    "
                                                    id="msg-area"
                                                    class="msg-area"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="time p-3"
                                        [class.attachments-upload-time]="
                                            message?.messageComponent?.attachments?.length > 0
                                        "
                                    >
                                        {{ message?.cOn | date : 'HH:mm' | convert24to12format }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div
            *ngIf="showLoadingMessages"
            class="load-messages text-center"
            style="position: fixed; left: calc(50% - 28px); bottom: calc(50% - 22px)"
        >
            <app-lottie-renderer path="assets/lottie/loader_white_bg.json"></app-lottie-renderer>
        </div>
    </div>

    <div *ngIf="showDisabledChatToast && !room?.advancedOptions?.isClassroomMode" class="disabled-chat-toast">
        {{ 'inside_call.chat.host-cohost-disabled-chat' | translate }}
    </div>
    <div
        class="attachments"
        [class.disabled]="showDisabledChatToast && !room?.advancedOptions?.isClassroomMode"
        [ngClass]="isMobileDevice ? 'attachments-mobile' : ''"
        (resized)="onResized($event)"
        *ngIf="
            !(
                room?.advancedOptions?.isClassroomMode &&
                !(roomStatus?.localParticipant?.isHost || roomStatus?.localParticipant?.isCoHost)
            )
        "
    >
        <ng-container *ngIf="showPrivateChat">
            <div class="to-panel" *ngIf="roomStatus?.participants?.length > 2 && !(isJMMeeting && isLargeMeeting)">
                <strong>{{ 'inside_call.chat.to' | translate }}</strong>
                <div class="participants-dropdown pointer" outside (outside)="onOutsideClick($event)">
                    <div
                        tabindex="0"
                        aria-label="Please press Enter to choose the recipient for your message."
                        role="button"
                        class="selected position-relative"
                        (click)="toggleDropdown()"
                        (keydown.enter)="toggleDropdown()"
                        (window:resize)="setDropdownHeight()"
                    >
                        <span>{{ 'inside_call.chat.all' | translate }}</span>
                        <img alt="" src="assets/images/arrow-down-icon.png" alt="" />
                    </div>
                    <div class="dropdown" *ngIf="dropdown">
                        <div class="search-box">
                            <input
                                tabindex="0"
                                aria-label="{{ 'inside_call.chat.search_participants' | translate }}"
                                role="searchbox"
                                type="text"
                                name=""
                                id=""
                                placeholder="{{ 'inside_call.chat.search_participants' | translate }}"
                                #participantSearchInput
                            />
                            <img alt="" src="assets/images/search_icn.svg" />
                        </div>
                        <ng-container
                            *ngIf="
                                roomParticipants
                                    | filterContactByKey
                                        : participantSearchInput.value
                                        : ['participantName'] as participants
                            "
                        >
                            <div class="position-relative" *ngFor="let user of participants; trackBy: trackByFuntion">
                                <div
                                    tabindex="0"
                                    aria-label="{{ 'inside_call.chat.all' | translate }}"
                                    class="participant-item selected"
                                    *ngIf="user.userId === null"
                                    (click)="dropdown = !dropdown"
                                    (keydown.enter)="dropdown = !dropdown"
                                >
                                    <span>{{ 'inside_call.chat.all' | translate }}</span>
                                    <img alt="" src="assets/img/revamp/tick_black.svg" alt="" style="opacity: 0.5" />
                                </div>
                                <div
                                    *ngIf="
                                        participantSearchInput.value === null
                                            ? true
                                            : user.participantName
                                                  .toUpperCase()
                                                  .startsWith(participantSearchInput.value.toUpperCase())
                                            ? true
                                            : false
                                    "
                                >
                                    <div
                                        class="participant-item"
                                        *ngIf="
                                            user.userId !== null &&
                                            (this.currentUser?._id || this.guestUser?.guestUserId) !== user.userId
                                        "
                                        (click)="
                                            switchToPrivateChat({ _id: user.userId, name: user.participantName });
                                            dropdown = !dropdown
                                        "
                                        (keydown.enter)="
                                            switchToPrivateChat({ _id: user.userId, name: user.participantName });
                                            dropdown = !dropdown
                                        "
                                        tabindex="0"
                                        aria-label="{{ user.participantName }}"
                                    >
                                        <span class="text-truncate" [title]="user.participantName">{{
                                            getParticipantName(user)
                                        }}</span>
                                        <div
                                            class="new-message"
                                            *ngIf="unreadChatParticipantIds.includes(user.userId)"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div class="no-data" *ngIf="!participants.length">
                                <em>{{ 'inside_call.chat.no_participants_found' | translate }}</em>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- <div class="uploaded-docs">
            <div
                class="attached-files-container"
                *ngFor="let attachmentObj of uploadedItems | keyvalue; trackBy: trackByFuntion"
            >
                <app-upload-attachment
                    *ngIf="!pastedImageFile"
                    [item]="attachmentObj?.value"
                    (remove)="removeAttchment($event, attachmentObj?.value)"
                >
                </app-upload-attachment>
                <div
                    class="progres"
                    *ngIf="!attachmentObj?.value?.uploadSuccess"
                    [style.width.%]="attachmentObj?.value?.progress || 0"
                ></div>
            </div>
        </div> -->
        <div class="uploaded-files" #uploadedDocs>
            <div class="uploadingfiles">Uploading files ({{ filesUploaded }}/{{ filesUploading }})</div>
            <div
                class="attached-files-container"
                *ngFor="let attachmentObj of uploadedItems | keyvalue; trackBy: chatService.trackByFunction"
            >
                <app-upload-attachment
                    [item]="attachmentObj?.value"
                    (remove)="removeAttchment($event, attachmentObj?.value)"
                    [isOutsideCall]="true"
                >
                </app-upload-attachment>
                <div
                    class="progres"
                    *ngIf="!attachmentObj?.value?.uploadSuccess"
                    [style.width.%]="attachmentObj?.value?.progress || 0"
                ></div>
            </div>
            <div *ngIf="fileWithInvalidFileType" class="attached-files-container">
                <app-upload-attachment
                    [item]="invalidFile"
                    (remove)="removeAttchment($event, null)"
                    [isOutsideCall]="true"
                >
                </app-upload-attachment>
            </div>
        </div>
        <div class="chatAttachments" [ngClass]="isMobileDevice ? 'chat-attachments-mobile' : ''">
            <app-chat-markdown
                [showMarkdown]="showMarkdown"
                [selectedEmoji]="selectedEmoji"
                [disableSendButton]="disableSendButton"
                (sendmessage)="sendMessage(null, null)"
                (pastedImageFile)="getPastedImageFile($event)"
                (disablingSendButton)="disablingSendButton($event)"
                (deletedImage)="deletedImage()"
                (maxTableSizeError)="handleMaxTableSizeError($event)"
            >
            </app-chat-markdown>
        </div>
        <div>
            <hr *ngIf="!isMobileDevice" />
        </div>
        <label for="emoji" class="pointer emoji-switch mt-2" *ngIf="showEmojiBar" tabindex="0" aria-label="">
            <img
                src="assets/images/emoji-icon.svg"
                alt=""
                *ngIf="!selectedEmoji"
                id="emoji-popup"
                tabindex="0"
                aria-label="please press enter key to open Emoji menu in the chat"
                (click)="toggleEmojis($event)"
                (keydown.enter)="toggleEmojis($event)"
            />
            <img
                src="assets/images/emoji-clicked.svg"
                alt=""
                *ngIf="selectedEmoji"
                (click)="toggleEmojis($event)"
                (keydown.enter)="toggleEmojis($event)"
            />
            <!-- <img alt="" src="assets/img/emoji_select.svg" alt="" (click)="toggleEmojis($event)"> -->
        </label>
        <label
            for="upload"
            *ngIf="isAuthenticated && enableJioCloud"
            class="ml-3 mt-2"
            tabindex="0"
            aria-label="please press enter key to Upload File in the chat"
        >
            <!-- <img alt="" title="Upload Files" src="assets/images/attachmentIcon.svg" id="attachmentIcon" #attachmentIcon aria-hidden="true" class="pointer"> -->
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.08"
                height="11.65"
                viewBox="0 0 13.08 11.65"
                id="attachmentIcon"
                tabindex="0"
                aria-label="please press enter key to Upload File in the chat"
                #attachmentIcon
                aria-hidden="true"
                class="pointer"
                (keydown.enter)="browseFileWindow()"
            >
                <defs>
                    <style>
                        .a {
                            fill: #666666;
                        }
                    </style>
                </defs>
                <path
                    class="a"
                    d="M1669.861,7164.041l3.859-3.858a3.916,3.916,0,0,0-.145-5.657,4.192,4.192,0,0,0-2.9-1.294h-.047a3.809,3.809,0,0,0-2.7,1.149l-5.229,5.229a2.946,2.946,0,0,0,.048,4.306,3.067,3.067,0,0,0,2.183.966h.034a3.012,3.012,0,0,0,2.089-.918l3.974-3.975a2.186,2.186,0,0,0,.686-1.416,2.175,2.175,0,0,0-2.175-2.176,2.2,2.2,0,0,0-1.417.686l-2.6,2.6a.349.349,0,0,0,.247.6h0a.349.349,0,0,0,.246-.1l2.6-2.6a1.536,1.536,0,0,1,.964-.483,1.28,1.28,0,0,1,1,.434,1.3,1.3,0,0,1,.434,1.005,1.527,1.527,0,0,1-.483.962l-3.974,3.976a2.317,2.317,0,0,1-1.6.712h-.025a2.385,2.385,0,0,1-1.69-.762,2.223,2.223,0,0,1-.048-3.319l5.229-5.229a3.134,3.134,0,0,1,2.221-.944h.031a3.458,3.458,0,0,1,2.419,1.089,3.2,3.2,0,0,1,.144,4.671l-3.859,3.859a.348.348,0,0,0,0,.493A.348.348,0,0,0,1669.861,7164.041Z"
                    transform="translate(-1661.778 -7153.231)"
                />
            </svg>
            <input
                #uploadFiles
                type="file"
                id="upload"
                style="display: none"
                (change)="fileChangeEvent($event)"
                (click)="resetLastSelectedFileValue($event)"
                (keydown.enter)="resetLastSelectedFileValue($event)"
            />
        </label>
        <label
            for="markdown"
            class="pointer ml-3 mt-2"
            (keydown.enter)="toggleMarkdown()"
            aria-label="Please press enter key to open alignment"
            tabindex="0"
            *ngIf="enableMarkdown"
        >
            <span *ngIf="!showMarkdown"
                ><img
                    tabindex="0"
                    aria-label="please press enter key to close markdown menu in the chat"
                    alt=""
                    src="assets/images/markdown-icon.svg"
                    (click)="toggleMarkdown()"
                    (keydown.enter)="toggleMarkdown()"
            /></span>
            <span *ngIf="showMarkdown">
                <img
                    tabindex="0"
                    aria-label="please press enter key to open markdown menu in the chat"
                    alt=""
                    src="assets/images/markdown-clicked.svg"
                    (click)="toggleMarkdown()"
                    (keydown.enter)="toggleMarkdown()"
            /></span>
        </label>

        <div
            class="sendBtn pointer mt-2"
            tabindex="0"
            (click)="sendMessage(null, messageToSend)"
            (keydown.enter)="sendMessage(null, messageToSend)"
            aria-label=" Please press enter key to send message"
            [style.pointer-events]="'auto'"
            [style.opacity]="disableSendButton ? '0.6' : '1'"
        >
            <img alt="" src="assets/images/send-message.svg" alt="" />
        </div>
    </div>
    <div
        class="attachments"
        (resized)="onResized($event)"
        *ngIf="
            room?.advancedOptions?.isClassroomMode &&
            !(roomStatus?.localParticipant?.isHost || roomStatus?.localParticipant?.isCoHost)
        "
    >
        <div class="restriction-msg text-center">{{ 'inside_call.chat.restricted_chatting' | translate }}</div>
    </div>
</div>
<!-- <div class="chat-toast-container"> -->
<ng-template viewHost></ng-template>
<!-- </div> -->
