<div class="download-page">
    <div class="install-desc">
        {{ 'joinmeeting_page_or_joincall.doesnt_have_app' | translate }} <br />
        {{ 'joinmeeting_page_or_joincall.install_now' | translate }}
    </div>
    <button class="download-btn btn-primary custom-button" (click)="downloadApp()">
        {{ 'joinmeeting_page_or_joincall.download_app' | translate }}
    </button>
    <div class="launch">
        {{ 'joinmeeting_page_or_joincall.or' | translate }}
        <div class="launch-link" (click)="launchInBrowser()">
            {{ 'joinmeeting_page_or_joincall.launch_in_browser' | translate }}
        </div>
    </div>
</div>
