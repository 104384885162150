<div class="background-settings-container" toastContainer>
    <div id="preview-section">
        <!-- <video id="video-preview" class="video-section" #videoPreview></video> -->
        <div tabindex="0" id="video-preview" class="video-section" [class.mirroredImage]="!mirroView"></div>

        <div class="preview-label" *ngIf="showImagePanel">
            <label id="bgfocus" tabindex="0" aria-label="Preview">{{
                'user_dashboard_settings.background.preview' | translate
            }}</label>
        </div>
        <app-loader *ngIf="!showImagePanel"></app-loader>
    </div>
    <br />
    <div class="image-container" *ngIf="showImagePanel">
        <h5 tabindex="0" aria-label="{{ 'user_dashboard_settings.background.change_background' | translate }}">
            {{ 'user_dashboard_settings.background.change_background' | translate }}
        </h5>
        <span
            tabindex="0"
            aria-label="Background none"
            (keyup.enter)="clearBackground(); onSelect($event)"
            class="none-image-wrapper wrapper-img"
            (click)="clearBackground(); onSelect($event)"
            [class.selected]="backgroundConfig === 'none'"
        >
            <img [src]="noneSrc" alt="" class="image-icon" />
            <span class="none-image-title img-title">{{ 'user_dashboard_settings.background.NONE' | translate }}</span>
        </span>
        <span *ngFor="let img of festiveImagesRow; let i = index">
            <img
                src="{{ img.src }}"
                tabindex="0"
                [attr.aria-label]="img.description"
                alt="bg-image"
                class="image-icon"
                (keydown.Tab)="setFocusOnElement(i)"
                (click)="onSelect($event, true); selectedImage(img.id)"
                (keyup.enter)="onSelect($event, true); selectedImage(img.id)"
                [class.selected]="checkIfImageSelected(img.src)"
            />
        </span>
        <span
            tabindex="0"
            aria-label="Background blur"
            (keyup.enter)="blurBackground(); onSelect($event)"
            class="blur-image-wrapper wrapper-img"
            (click)="blurBackground(); onSelect($event)"
            [class.selected]="backgroundConfig === 'blur'"
        >
            <img [src]="blurSrc" alt="" class="image-icon" />
            <span class="blur-image-title img-title">{{ 'user_dashboard_settings.background.BLUR' | translate }}</span>
        </span>
        <label
            for="file"
            pTooltip="{{ 'user_dashboard_settings.background.file_type' | translate }}"
            tooltipPosition="bottom"
            data-html="true"
        >
            <span
                tabindex="0"
                aria-label="Choose custom background"
                (keyup.enter)="handlecustomfun($event)"
                class="custom-image-wrapper wrapper-img"
            >
                <img [src]="customSrc" alt="" class="image-icon" />
                <span class="custom-image-title img-title">{{
                    'user_dashboard_settings.background.CUSTOM' | translate
                }}</span>
            </span>
        </label>
        <input type="file" id="file" (change)="fileChangeEvent($event)" #fileInput hidden />
        <span *ngFor="let img of customImages; let i = index" class="custom-image-wrapper">
            <img
                src="{{ img.src }}"
                tabindex="0"
                [attr.aria-label]="'Custom background option' + (i + 1)"
                alt="bg-image"
                class="image-icon"
                (click)="onSelect($event, true)"
                [class.selected]="checkIfImageSelected(img.src)"
            />
            <span
                class="cross-icon"
                tabindex="0"
                aria-label="remove custom added background"
                (keyup.enter)="onRemove(img)"
                (click)="onRemove(img)"
                >&#x2715;</span
            >
        </span>
        <span *ngFor="let img of imagesRowOne; let i = index">
            <img
                src="{{ img.src }}"
                tabindex="0"
                [attr.aria-label]="img.description"
                alt="bg-image"
                class="image-icon"
                (keydown.Tab)="setFocusOnElement(i)"
                (click)="onSelect($event, true); selectedImage(img.id)"
                (keyup.enter)="onSelect($event, true); selectedImage(img.id)"
                [class.selected]="checkIfImageSelected(img.src)"
            />
        </span>
    </div>
    <div>
        <img class="uploaded-image" #imageContainer src="{{ url }}" alt="" />
    </div>
    <div class="confirm-container" *ngIf="isJMMeeting">
        <button (click)="onConfirm($event)" class="btn primary">
            {{ 'user_dashboard_settings.background.Confirm' | translate }}
        </button>
    </div>
</div>
