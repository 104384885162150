import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { APP_EVENTS } from 'src/app/constants';
import { EventEmitterService, DownloadAppService } from 'src/app/core';

@Component({
    selector: 'app-menu-accordion',
    templateUrl: './menu-accordion.component.html',
    styleUrls: ['./menu-accordion.component.scss']
})
export class MenuAccordionComponent implements OnInit {
    @Input() accordionData = [];
    @Input() menuPos: string;
    @Output() languageChangeClick: EventEmitter<any> = new EventEmitter();
    activePanel = null;
    constructor(
        private router: Router,
        private eventEmitterService: EventEmitterService,
        private downloadAppService: DownloadAppService
    ) {}

    ngOnInit(): void {}

    toggleAccordion(event, index) {
        const target = event.target;
        var panel = target.nextElementSibling;
        if (!panel && this.accordionData[index].tlabel === 'website_header.sales') {
            this.handleGoTo({ path: 'contactsales' });
            return;
        }
        if (!panel && this.accordionData[index].tlabel === 'website_header.pricing') {
            this.handleGoTo({ path: 'pricing' });
            return;
        }
        if (!panel && this.accordionData[index].tlabel === 'website.download_app') {
            this.downloadAppService.downloadJioMeet();
            return;
        }
        if (panel.style.maxHeight && this.activePanel === index) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + 'px';
        }

        this.activePanel = this.activePanel !== index ? index : null;
    }

    handleGoTo(menuInfo: any) {
        // handling language option click
        if (menuInfo.path === 'en' || menuInfo.path === 'hi') {
            this.languageChangeClick.emit(menuInfo.path);
            return;
        }
        //ws this.eventEmitterService.emit({ type: APP_EVENTS.NAV_ITEM_CLICKED, data: {} });
        if (!menuInfo.path) return;
        if (!menuInfo.isExternal) this.router.navigate([menuInfo.path]);
        else
            window.open(
                menuInfo.path,
                '_blank' // <- This is what makes it open in a new window.
            );
    }
}
