import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppService, MeetingService, UtilService } from '..';
@Injectable({
    providedIn: 'root'
})
export class CheckForWebinarGuard implements CanActivate {
    constructor(
        private meetingService: MeetingService,
        private appService: AppService,
        private utilService: UtilService
    ) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const jiomeetId = route?.queryParams?.meetingId;
        const autoJoin = route?.queryParams?.autoJoin;
        const recorderToken = route?.queryParams?.recorderToken;

        var pin = route?.queryParams?.pwd;
        if (window?.location?.host?.includes('platform')) {
            return of(true);
        }
        if (!pin) {
            var urlDecoded = new DOMParser().parseFromString(window.location.href, 'text/html').documentElement
                .textContent;
            var url = new URL(urlDecoded);
            pin = url.searchParams.get('pwd');
        }
        const isMobileOS = this.utilService.isMobileUserAgent();
        if (!jiomeetId) {
            return of(true);
        }
        this.meetingService.getMeetingType({ jiomeetId, pin }).subscribe(
            (res: any) => {
                if (res?.isWebinar) {
                    let link = `${this.appService.getEnvVariable('EVENTS_URL')}join?meetingId=${jiomeetId}`;

                    if (pin !== undefined) {
                        link += `&pwd=${pin}`;
                    }
                    if (route?.queryParams?.ios) {
                        link += `&ios=${route.queryParams.ios}`;
                    }
                    if (route?.queryParams?.enableMic) {
                        link += `&enableMic=${route.queryParams.enableMic}`;
                    }
                    if (route?.queryParams?.enableVideo) {
                        link += `&enableVideo=${route.queryParams.enableVideo}`;
                    }
                    if (route?.queryParams?.isAndroid) {
                        link += `&isAndroid=true`;
                    }
                    window.location.href = link;
                    return of(false);
                } else {
                    let link = `${this.appService.getEnvVariable('HOST_URL')}join?meetingId=${jiomeetId}`;

                    if (pin !== undefined) {
                        link += `&pwd=${pin}`;
                    }
                    if (autoJoin !== undefined) {
                        link += `&autoJoin=${autoJoin}`;
                    }
                    if (recorderToken !== undefined) {
                        link += `&recorderToken=${recorderToken}`;
                    }
                    if (route?.queryParams?.ios) {
                        link += `&ios=${route.queryParams.ios}`;
                    }
                    if (route?.queryParams?.enableMic) {
                        link += `&enableMic=${route.queryParams.enableMic}`;
                    }
                    if (route?.queryParams?.enableVideo) {
                        link += `&enableVideo=${route.queryParams.enableVideo}`;
                    }
                    if (route?.queryParams?.isAndroid) {
                        link += `&isAndroid=true`;
                    }

                    if (route?.queryParams?.ifl === '1' && route?.queryParams?.e) {
                        link += `&e=${route.queryParams.e}`;
                    }

                    window.location.href = link;
                    return of(true);
                }
            },
            (err) => {
                if (err?.error?.isWebinar) {
                    let link = `${this.appService.getEnvVariable('EVENTS_URL')}join?meetingId=${jiomeetId}`;

                    if (pin !== undefined) {
                        link += `&pwd=${pin}`;
                    }
                    if (route?.queryParams?.ios) {
                        link += `&ios=${route.queryParams.ios}`;
                    }
                    if (route?.queryParams?.isAndroid) {
                        link += `&isAndroid=true`;
                    }
                    window.location.href = link;
                    return of(false);
                } else {
                    let link = `${this.appService.getEnvVariable('HOST_URL')}join?meetingId=${jiomeetId}`;

                    if (pin !== undefined) {
                        link += `&pwd=${pin}`;
                    }
                    if (autoJoin !== undefined) {
                        link += `&autoJoin=${autoJoin}`;
                    }
                    if (recorderToken !== undefined) {
                        link += `&recorderToken=${recorderToken}`;
                    }
                    if (route?.queryParams?.ios) {
                        link += `&ios=${route.queryParams.ios}`;
                    }
                    if (route?.queryParams?.isAndroid) {
                        link += `&isAndroid=true`;
                    }
                    window.location.href = link;
                    return of(true);
                }
            }
        );
    }
}
