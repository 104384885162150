export const GA_PAGEVIEW = {
    HOME_PAGE_VIEW: {
        event: 'virtualPageView',
        virtualPageTitle: 'JioMeet_Home',
        virtualPageURL: '/home'
    },
    PRODUCT_PAGE_JIOMEET: {
        event: 'virtualpageview',
        virtualPageTitle: 'products_jiomeet',
        virtualPageURL: '/retail'
    },
    PRODUCT_PAGE_VIEW_ENTERPRISE: {
        event: 'virtualpageview',
        virtualPageTitle: 'products_jiomeetenterprise',
        virtualPageURL: '/enterprise'
    },
    PRODUCT_PAGE_VIEW_PLATFORM: {
        event: 'virtualpageview',
        virtualPageTitle: 'products_jiomeetplatform',
        virtualPageURL: '/cpaas'
    },
    PRODUCT_PAGE_VIEW_JIOEVENT: {
        event: 'virtualpageview',
        virtualPageTitle: 'products_jioevents',
        virtualPageURL: '/register/conference'
    },
    PRODUCT_PAGE_VIEW_DISCOVER: {
        event: 'virtualPageView',
        virtualPageTitle: 'Products_JioMeetDiscover',
        virtualPageURL: '/events'
    },
    PRICING_PAGE_VIEW: {
        event: 'virtualPageView',
        virtualPageTitle: 'Pricing',
        virtualPageURL: '/pricing'
    },
    DASHBOARD_VIEW: {
        event: 'virtualPageView',
        virtualPageTitle: 'Dashboard',
        virtualPageURL: '/dashboard'
    },
    USECASE_EDUCATION: {
        event: 'virtualPageView',
        virtualPageTitle: 'UseCase_JioMeetEducation',
        virtualPageURL: '/education'
    },
    USECASE_HEALTHCARE: {
        event: 'virtualPageView',
        virtualPageTitle: 'UseCase_JioMeetHealthCare',
        virtualPageURL: '/healthcare'
    },
    USECASE_HYBRID_WORKPLACE: {
        event: 'virtualPageView',
        virtualPageTitle: 'UseCase_JioMeetHybridWorkplace',
        virtualPageURL: '/hybridWorkplace'
    },
    USECASE_PLATFORM_SERVICES: {
        event: 'virtualPageView',
        virtualPageTitle: 'UseCase_JioMeetPlatformServices',
        virtualPageURL: '/platformServices'
    },
    USECASE_GOVERMENT_SECTOR: {
        event: 'virtualPageView',
        virtualPageTitle: 'UseCase_JioMeetGovernmentSector',
        virtualPageURL: '/govtSectors'
    },
    DOWNLOAD_JIOMEET_PAGE_VIEW: {
        event: 'virtualPageView',
        virtualPageTitle: 'DownloadJioMeet',
        virtualPageURL: '/jiomeetDownload'
    },
    SALES_PAGE_VIEW: {
        event: 'virtualPageView',
        virtualPageTitle: 'Sales_JioMeet',
        virtualPageURL: '/contactsales'
    },
    GET_CLIENT_PAGE_VIEW: {
        event: 'virtualPageView',
        virtualPageTitle: 'Get Client',
        virtualPageURL: '/getClient'
    }
};
