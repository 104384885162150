import { Injectable } from '@angular/core';
import { EXTERNAL_INTEGRATION } from 'src/app/constants';

declare let gapi;

@Injectable({
    providedIn: 'root'
})
export class GoogleapiService {
    constructor() {}

    initializeGoogleAPI(clientId, discoveryDocs, scope) {
        return gapi.client.init({
            clientId,
            discoveryDocs,
            scope,
            fetch_basic_profile: false
        });
    }

    isGapiLibraryLoaded() {
        try {
            const isLoaded = gapi ? true : false;
            return isLoaded;
        } catch (err) {
            return false;
        }
    }

    isSignedIn() {
        const token = gapi.auth2?.getAuthInstance()?.currentUser?.get()?.isSignedIn();
        return token ? true : false;
    }

    setTokenNull() {
        return gapi?.client?.setToken(null);
    }

    // signIn() {
    //   return new Promise((resolve, reject) => {
    //     gapi.auth2.authorize({
    //       client_id: EXTERNAL_INTEGRATION.EC_GOOGLE_CLIENT_ID,
    //       scope: EXTERNAL_INTEGRATION.EC_GOOGLE_SCOPES,
    //       response_type: 'permission'
    //     }, (_response) => {
    //       gapi.client.init({
    //         discoveryDocs: EXTERNAL_INTEGRATION.EC_GOOGLE_DISCOVERY_DOCS,
    //       }).then(() => {
    //         resolve('OK');
    //       }).catch((err) => {
    //         reject(err);
    //       })
    //     });
    //   });
    // }

    signIn() {
        return gapi.auth2.getAuthInstance().signIn({
            scope: EXTERNAL_INTEGRATION.EC_GOOGLE_SCOPES,
            prompt: 'consent'
        });
    }

    loadGoogleAPI(callback) {
        gapi.load('client:auth2', callback);
    }

    createEvent(topic, location, description, startTime, endTime, recurrence, attendees) {
        let event: any = {
            summary: topic,
            location: location,
            description: description,
            start: {
                dateTime: startTime.toISOString(),
                timeZone: 'Asia/Kolkata'
            },
            end: {
                dateTime: endTime.toISOString(),
                timeZone: 'Asia/Kolkata'
            },
            attendees: attendees
        };

        if (recurrence) {
            event.recurrence = [recurrence];
        }

        let request = gapi.client.calendar.events.insert({
            calendarId: 'primary',
            resource: event
        });

        return request;
    }

    updateEvent(id, topic, location, description, startTime, endTime, recurrence, attendees) {
        let event: any = {
            summary: topic,
            location: location,
            description: description,
            start: {
                dateTime: startTime.toISOString(),
                timeZone: 'Asia/Kolkata'
            },
            end: {
                dateTime: endTime.toISOString(),
                timeZone: 'Asia/Kolkata'
            }
        };

        if (attendees && attendees.length) {
            event.attendees = attendees;
        }

        if (recurrence) {
            event.recurrence = [recurrence];
        } else {
            event.recurrence = [];
        }

        let request = gapi.client.calendar.events.patch({
            calendarId: 'primary',
            eventId: id,
            resource: event
        });

        return request;
    }

    updateStartTime(id, startTime, endTime, recurrence) {
        let event: any = {
            start: {
                dateTime: startTime.toISOString(),
                timeZone: 'Asia/Kolkata'
            },
            end: {
                dateTime: endTime.toISOString(),
                timeZone: 'Asia/Kolkata'
            }
        };

        if (recurrence) {
            event.recurrence = [recurrence];
        }

        let request = gapi.client.calendar.events.patch({
            calendarId: 'primary',
            eventId: id,
            resource: event
        });

        return request;
    }

    getEvent(id) {
        let request = gapi.client.calendar.events.get({
            calendarId: 'primary',
            eventId: id
        });

        return request;
    }

    deleteEvent(id) {
        let request = gapi.client.calendar.events.delete({
            calendarId: 'primary',
            eventId: id
        });

        return request;
    }

    getEventInstances(id) {
        let request = gapi.client.calendar.events.instances({
            calendarId: 'primary',
            eventId: id
        });
        return request;
    }

    cleanISOString(isoString) {
        isoString = isoString.split('.')[0] + 'Z';
        let dummyString = '';
        for (let i = 0; i < isoString.length; i++) {
            if (isoString[i] === 'T' || isoString[i] === 'Z' || !isNaN(Number(isoString[i]))) {
                dummyString += isoString[i];
            }
        }
        return dummyString;
    }

    convertRepeatOptionsToGoogleFormat(repeatOptions) {
        if (!repeatOptions || repeatOptions.repeat === 'none') return;
        let recurrString = '';
        if (repeatOptions.repeat === 'daily') {
            recurrString += 'RRULE:FREQ=DAILY;';
        } else if (repeatOptions.repeat === 'weekly') {
            recurrString += 'RRULE:FREQ=WEEKLY;BYDAY=';
            const daysOfWeek = repeatOptions.daysOfWeek;
            const weekList = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
            daysOfWeek.forEach((isDay, index) => {
                if (isDay) recurrString += weekList[index] + ',';
            });
            recurrString = recurrString.slice(0, -1) + ';';
        } else if (repeatOptions.repeat === 'monthly') {
            const dateInMonth = repeatOptions.dateInMonth;
            recurrString += 'RRULE:FREQ=MONTHLY;BYMONTHDAY=' + dateInMonth + ';';
        }

        recurrString +=
            'UNTIL=' + this.cleanISOString(new Date(new Date(repeatOptions.endDate).getTime()).toISOString()) + ';';
        return recurrString;
    }

    signOut() {
        return gapi?.auth2?.getAuthInstance()?.signOut();
    }
}
