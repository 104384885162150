<div class="profile-options-menu" *ngIf="!isMobile">
    <div class="settings-options" tabindex="0" aria-label="Change userstatus" (click)="toggleStatusMenu($event)">
        <div class="status-view available" *ngIf="userStatus === 'online'"></div>
        <div class="status-view away" *ngIf="userStatus === 'away'"></div>
        <div class="status-view busy" *ngIf="userStatus === 'busy'"></div>
        <div class="settings-options-title">
            {{ currentStatus }}
        </div>
        <img src="assets/img/Settings_arrow.svg" alt="" />
        <div class="custom-dropdown-menu" *ngIf="showStatusMenu">
            <div class="menu-bg" *ngFor="let menu of userStatusMenu; let last = last">
                <div class="settings-options">
                    <div class="status-view-dropdown" [ngClass]="menu?.styleClass"></div>
                    <div
                        class="dropdown-item pointer"
                        tabindex="0"
                        aria-label="{{ menu?.title | translate }}"
                        role="button"
                        (click)="onclickStatus($event, menu?.status)"
                        (keydown.enter)="onclickStatus($event, menu?.status)"
                    >
                        {{ menu.title | translate }}
                    </div>
                </div>
                <div class="divider" [ngClass]="{ hidden: last }"></div>
            </div>
        </div>
    </div>
    <div class="settings-options" tabindex="0" aria-label="Settings" (click)="openSettingsPopup()">
        <img src="assets/img/Profile_settings.svg" alt="" />
        <div class="settings-options-title">
            {{ 'landing_or_home_page.header.settings' | translate }}
        </div>
    </div>
    <div class="settings-options" tabindex="0" aria-label="Help" (click)="toggleHelpMenu($event)">
        <img src="assets/img/Profile_help.svg" alt="" />
        <div class="settings-options-title">
            {{ 'landing_or_home_page.header.help' | translate }}
        </div>
        <img src="assets/img/Settings_arrow.svg" alt="" />
        <div class="custom-dropdown-menu help-dropdown" *ngIf="showHelpMenu" [style.left]="helpMenuXPos">
            <div class="menu-bg" *ngFor="let menu of helpMenu; let last = last">
                <div
                    class="dropdown-item help-dropdown-item pointer"
                    tabindex="0"
                    aria-label="{{ menu?.title | translate }}"
                    role="button"
                    (click)="onclickHelp($event, menu?.event)"
                    (keydown.enter)="onclickHelp($event, menu?.event)"
                >
                    {{ menu?.title | translate }}
                </div>
                <div class="divider" [ngClass]="{ hidden: last }"></div>
            </div>
        </div>
    </div>
</div>
<div class="profile-options-menu-mobile" *ngIf="isMobile">
    <div class="settings-options" tabindex="0" aria-label="Change userstatus" (click)="toggleStatusMenu($event)">
        <div class="status-view available" *ngIf="userStatus === 'online'"></div>
        <div class="status-view away" *ngIf="userStatus === 'away'"></div>
        <div class="status-view busy" *ngIf="userStatus === 'busy'"></div>
        <div class="settings-options-title">
            {{ currentStatus }}
        </div>
        <img [src]="statusAccordianImage" alt="" />
    </div>
    <div class="mobile-menu-dropdown" *ngIf="showStatusMenu">
        <div class="menu-bg" *ngFor="let menu of userStatusMenu">
            <div
                class="settings-options"
                tabindex="0"
                aria-label="{{ menu?.title | translate }}"
                role="button"
                (click)="onclickStatus($event, menu?.status)"
                (keydown.enter)="onclickStatus($event, menu?.status)"
            >
                <div class="status-view" [ngClass]="menu?.styleClass"></div>
                <div class="settings-options-title">
                    {{ menu.title | translate }}
                </div>
            </div>
        </div>
    </div>
    <div class="settings-options" tabindex="0" aria-label="Settings" (click)="openSettingsPopup()">
        <img src="assets/img/Profile_settings.svg" alt="" />
        <div class="settings-options-title">
            {{ 'landing_or_home_page.header.settings' | translate }}
        </div>
    </div>
    <div class="settings-options" tabindex="0" aria-label="Help" (click)="toggleHelpMenu($event)">
        <img src="assets/img/Profile_help.svg" alt="" />
        <div class="settings-options-title">
            {{ 'landing_or_home_page.header.help' | translate }}
        </div>
        <img [src]="helpAccordianImage" alt="" />
    </div>
    <div class="mobile-menu-dropdown" *ngIf="showHelpMenu">
        <div class="menu-bg" *ngFor="let menu of helpMenu">
            <div
                class="settings-options"
                tabindex="0"
                aria-label="{{ menu?.title | translate }}"
                role="button"
                (click)="onclickHelp($event, menu?.event)"
                (keydown.enter)="onclickHelp($event, menu?.event)"
            >
                <div class="settings-options-title">
                    {{ menu.title | translate }}
                </div>
            </div>
        </div>
    </div>
</div>
