import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError, of } from 'rxjs';
import { catchError, mergeMap, retryWhen } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { AuthService } from './../services/auth.service';

import { TokenInterceptor } from './token.interceptor';
import { JiocloudService } from '../services/jiocloud.service';
import { JiocloudAuthService } from '../services/jiocloud-auth.service';
import { ExternalIntegrationService, ExternalInterfaceService } from '..';
import { genericRetryStrategy } from '../helpers/generic-retry-strategy';
import { AppService } from '../services/app.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toastrService: ToastrService,
        private authService: AuthService,
        private tokenInterceptor: TokenInterceptor,
        private jiocloudAuthService: JiocloudAuthService,
        private externalInterfaceService: ExternalInterfaceService,
        private appService: AppService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let queryParams = {};
        this.activatedRoute.queryParams.subscribe((params) => (queryParams = params));
        // based on expiry time initiate token refresh
        if (!this.authService.isLoginRequest(request) && this.authService.isAccessTokenExpired()) {
            return this.authService.refreshToken().pipe(
                catchError((e) => throwError(e)),
                mergeMap(() => this.tokenInterceptor.intercept(request, next))
            );
        }

        return next.handle(request).pipe(
            retryWhen(genericRetryStrategy({ maxRetryAttempts: 3, useRange: { enable: true, low: 400, high: 500 } })),
            catchError((response: any) => {
                //throwing errors to iframe users
                this.externalInterfaceService.sendErrorOccuredOnJoined('httpexception', response?.error);
                // handle jio cloud related token issues
                let returnValue = this.handleJioCloudTokenExpiry(request, response, next);
                if (!!returnValue) return returnValue;

                // jio clod
                if (
                    [
                        '/upload/files',
                        '/upload/files/chunked/initiate',
                        '/nms/meeting/trash',
                        '/share/meeting',
                        '/download/meeting/',
                        '/download/files/',
                        '/media/auth/meeting/',
                        '/media/hls/',
                        '/media/stream',
                        '/nms/meeting/trash/'
                    ].filter((path) => request.url.indexOf(path) > -1).length &&
                    response.status === 401 &&
                    !this.authService.isLoginRequest(request)
                ) {
                    // return this.jiocloudAuthService.refreshToken().pipe(
                    //   catchError((e) => throwError(e)),
                    //   mergeMap(() => this.tokenInterceptor.intercept(request, next))
                    // );
                    return this.jiocloudAuthService.login().pipe(
                        catchError((e) => throwError(e)),
                        mergeMap(() => this.tokenInterceptor.intercept(request, next))
                    );
                }

                if (
                    ['/download/meeting/'].filter((path) => request.url.indexOf(path) > -1).length &&
                    response.status === 504
                ) {
                    return this.jiocloudAuthService.login().pipe(
                        catchError((e) => throwError(e)),
                        mergeMap(() => this.tokenInterceptor.intercept(request, next))
                    );
                }

                if (request.url.includes('/account/token/refresh') && response.status === 401) {
                    return this.jiocloudAuthService.login().pipe(
                        catchError((e) => throwError(e)),
                        mergeMap(() => this.tokenInterceptor.intercept(request, next))
                    );
                }

                if (
                    !request.url.includes('login') &&
                    !request.url.includes('logout') &&
                    !request.url.includes('refreshtoken') &&
                    response.status === 401
                ) {
                    // extra condition ?
                    return this.authService.refreshToken().pipe(
                        catchError((e) => throwError(e)),
                        mergeMap(() => this.tokenInterceptor.intercept(request, next))
                    );
                }
                // handle refresh token reponse for JioMeet API calls
                returnValue = this.handleRefreshTokenReponse(request, response, queryParams);
                if (!!returnValue) return returnValue;
                if (response.status === 429 && response.error.message === 'LOGIN_LIMITS_REACHED') {
                    return this.authService.switch(response.error.token).pipe(
                        catchError((e) => throwError(e)),
                        mergeMap((res) => {
                            return of(new HttpResponse({ body: res }));
                        })
                    );
                }
                if (
                    response.status === 412 &&
                    response.error.message === 'TOKEN_EXPIRED' &&
                    ['/api/verifyotp', '/api/authorize/google', '/api/authorize/facebook', '/api/sign/signup'].filter(
                        (path) => request.url.indexOf(path) > -1
                    ).length
                ) {
                    this.authService.cleanup();
                    this.router.navigate(['/']);
                }

                if (
                    (response.status === 502 || response.status === 503) &&
                    !this.jiocloudAuthService.isJioCloudRequest(request)
                ) {
                    this.authService.isServerDown(true);
                }

                return throwError(response);
            })
        );
    }

    handleJioCloudTokenExpiry(request, response, next) {
        if (
            response.status === 401 &&
            (request.url.includes('/account/token/refresh') || request.url.includes('/download/meeting/'))
        ) {
            this.jiocloudAuthService.cleanup();
            return this.jiocloudAuthService.login().pipe(
                catchError((e) => throwError(e)),
                mergeMap(() => this.tokenInterceptor.intercept(request, next))
            );
        }
        if (
            (response.status === 401 || response.status === 0) &&
            !request.url.includes('/account/token/refresh') &&
            this.jiocloudAuthService.isJioCloudRequest(request)
        ) {
            return this.jiocloudAuthService.refreshToken().pipe(
                catchError((e) => throwError(e)),
                mergeMap(() => this.tokenInterceptor.intercept(request, next))
            );
        }
        return false;
    }

    handleRefreshTokenReponse(request, response, queryParams) {
        if (request.url.includes('refreshtoken') && response.status === 401) {
            // forbidden
            if (!this.authService.sessionTimeout) {
                this.authService.sessionTimeout = true;
                this.toastrService.error(typeof response.error === 'string' ? response.error : response.error.errors);
                this.authService.cleanup();
                if (
                    this.router.url.includes('mst') ||
                    this.router.url.includes('outlook') ||
                    this.router.url.includes('oauth')
                ) {
                    this.appService.reloadWebContent();
                } else {
                    this.router.navigate(['/login'], {
                        queryParams
                    });
                    // Handling error of session timeout because Token is deleted from backend
                    this.appService.reloadWebContent();
                }
            }
            return throwError(response);
        }
    }
}
