import { Pipe, PipeTransform } from '@angular/core';
import { VideoWrapperService } from 'src/app/core';

@Pipe({
    name: 'filterNonVideo',
    pure: false
})
export class FilterNonVideoPipe implements PipeTransform {
    videoService: any;

    constructor(private videoWrapperService: VideoWrapperService) {
        this.videoService = this.videoWrapperService.getVideoService();
    }

    transform(galleryParticipants: any, ...args: unknown[]): unknown {
        if (this.videoService?.hideNonVideoSelected) {
            return galleryParticipants.filter(
                (participant) => participant?.participantData?.hasVideo || participant?.isPin
            );
        }

        return galleryParticipants;
    }
}
