export const GA_EVENTS = {
    ONCLICK_VIDEO: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Video',
        event_label: 'Success'
    },
    ONCLICK_CHANGEBACKGROUND: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Virtual background',
        event_label: 'None'
    },
    ONCLICK_AUDIO: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Audio',
        event_label: 'Success'
    },
    ONCLICK_PROFILE: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Profile',
        event_label: 'Success'
    },
    ONCLICK_PERSONAL_MEETING_ID: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Person meeting ID',
        event_label: 'Success'
    },
    ONCLICK_NOTIFICATION: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Notifications',
        event_label: 'Success'
    },
    ONCLICK_LIVE_STREAM: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Live Stream',
        event_label: 'Success'
    },
    VIDEO_ON_WHILE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'turn_off_my_video_when_accepting_incoming_call',
        event_label: 'enabled'
    },
    VIDEO_ON_WHILE_CALL_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'turn_off_my_video_when_accepting_incoming_call',
        event_label: 'enabled_breakoutroom'
    },
    VIDEO_OFF_WHILE_CALL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'turn_off_my_video_when_accepting_incoming_call',
        event_label: 'disabled'
    },
    VIDEO_OFF_WHILE_CALL_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'turn_off_my_video_when_accepting_incoming_call',
        event_label: 'disabled_breakoutroom'
    },
    FIT_TO_SCREEN_ENABLE: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'fit_to_screen',
        event_label: 'enabled'
    },
    FIT_TO_SCREEN_ENABLE_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'fit_to_screen',
        event_label: 'enabled_breakoutroom'
    },
    FIT_TO_SCREEN_DISABLE: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'fit_to_screen',
        event_label: 'disabled'
    },
    FIT_TO_SCREEN_DISABLE_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'fit_to_screen',
        event_label: 'disabled_breakoutroom'
    },
    CUSTOM_IMAGE_CLICK: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Virtual background',
        event_label: 'Custom'
    },
    NONE_IMAGE_CLICK: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Virtual background',
        event_label: 'None'
    },
    BLUR_IMAGE_CLICK: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Virtual background',
        event_label: 'Blur'
    },
    DEFAULT_IMAGE1_CLICK: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Virtual background',
        event_label: 'Default Image 1'
    },
    DEFAULT_IMAGE2_CLICK: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Virtual background',
        event_label: 'Default Image 2'
    },
    DEFAULT_IMAGE3_CLICK: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Virtual background',
        event_label: 'Default Image 3'
    },
    DEFAULT_IMAGE4_CLICK: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Virtual background',
        event_label: 'Default Image 4'
    },
    DEFAULT_IMAGE5_CLICK: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Virtual background',
        event_label: 'Default Image 5'
    },
    PROFILE_PICTURE_CHANGE: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Change profile pic',
        event_label: 'Changed'
    },
    PROFILE_PICTURE_CHANGE_CANCEL: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Change profile pic',
        event_label: 'Not changed'
    },
    AUDIO_ON_WHILE_CALL: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Audio',
        event_label: 'Enabled'
    },
    AUDIO_OFF_WHILE_CALL: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Audio',
        event_label: 'Disabled'
    },
    TEST_MIC: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Test Mic',
        event_label: 'Success'
    },
    TEST_SPEAKER: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'test_speaker',
        event_label: 'success'
    },
    TEST_SPEAKER_BREAKOUT: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'test_speaker',
        event_label: 'success_breakoutroom'
    },
    STOP_SPEAKER: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'stop_speaker',
        event_label: 'success'
    },
    STOP_SPEAKER_BREAKOUT: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'stop_speaker',
        event_label: 'success_breakoutroom'
    },
    PMI_PASSWORD_RESET: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Person meeting ID password changed',
        event_label: 'Success'
    },
    PMI_PARTICIPANT_MY_ORG_ENABLED: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Allow participants only from my organisation',
        event_label: 'Enabled'
    },
    PMI_PARTICIPANT_MY_ORG_DISABLED: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Allow participants only from my organisation',
        event_label: 'Disabled'
    },
    PMI_DONT_ALLOW_GUEST_USER_ENABLED: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: "Don't allow guest users",
        event_label: 'Enabled'
    },
    PMI_DONT_ALLOW_GUEST_USER_DISABLED: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: "Don't allow guest users",
        event_label: 'Disabled'
    },
    PMI_WAITING_ROOM_ENABLED: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Enable waiting room',
        event_label: 'Enabled'
    },
    PMI_WAITING_ROOM_DISABLED: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Enable waiting room',
        event_label: 'Disabled'
    },
    PMI_JOIN_BEFORE_HOST_ENABLED: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Allow join before host',
        event_label: 'Enabled'
    },
    PMI_JOIN_BEFORE_HOST_DISABLED: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Allow join before host',
        event_label: 'Disabled'
    },
    PMI_FORCE_MUTE_ENTRY_ENABLED: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Force mute participants on entry',
        event_label: 'Enabled'
    },
    PMI_FORCE_MUTE_ENTRY_DISABLED: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Force mute participants on entry',
        event_label: 'Disabled'
    },
    PMI_SOFT_MUTE_ENTRY_ENABLED: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Soft mute participants on entry',
        event_label: 'Enabled'
    },
    PMI_SOFT_MUTE_ENTRY_DISABLED: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Soft mute participants on entry',
        event_label: 'Disabled'
    },
    PMI_SAVE_BUTTON: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Person meeting ID Save',
        event_label: 'Success'
    },
    EMAIL_NOTIFICATIONS_ENABLED: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Email notification',
        event_label: 'Enabled'
    },
    EMAIL_NOTIFICATIONS_DISABLED: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Email notification',
        event_label: 'Disabled'
    },
    SMS_NOTIFICATIONS_ENABLED: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Sms notification',
        event_label: 'Enabled'
    },
    SMS_NOTIFICATIONS_DISABLED: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Sms notification',
        event_label: 'Disabled'
    },
    WHATSAPP_NOTIFICATIONS_ENABLED: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Whatsapp notification',
        event_label: 'Enabled'
    },
    WHATSAPP_NOTIFICATIONS_DISABLED: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Whatsapp notification',
        event_label: 'Disabled'
    },
    EDIT_WHATSAPP_NUMBER: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'WhatsApp Number Edit',
        event_label: 'Success'
    },
    INCOMING_CALL_SILENT: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Incoming meeting calls',
        event_label: 'Silent'
    },
    INCOMING_CALL_JIOMEET_TUNE: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Incoming meeting calls',
        event_label: 'JioMeet tune'
    },
    LANGUAGE_HINDI: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'language',
        event_label: 'hindi'
    },
    LANGUAGE_HINDI_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'language',
        event_label: 'hindi_breakoutroom'
    },
    LANGUAGE_ENGLISH: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'language',
        event_label: 'english'
    },
    LANGUAGE_ENGLISH_BREAKOUTROOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'language',
        event_label: 'english_breakoutroom'
    },
    ADD_LIVE_STREAM: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Add Live Stream platform',
        event_label: 'Success'
    },
    LIVESTREAM_YOUTUBE: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'live_stream_platform',
        event_label: 'youtube'
    },
    LIVESTREAM_FACEBOOK: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'live_stream_platform',
        event_label: 'facebook'
    },
    LIVESTREAM_CUSTOM: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'live_stream_platform',
        event_label: 'custom'
    },
    LIVESTREAM_CANCEL: {
        event: 'jiomeet',
        event_category: 'Settings',
        event_action: 'Live stream cancel',
        event_label: 'Success'
    },
    LIVESTREAM_SAVE: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'live_stream_add',
        event_label: 'success'
    },
    LIVESTREAM_SAVE_FAIL: {
        event: 'jiomeet',
        event_category: 'inside_call',
        event_action: 'live_stream_add',
        event_label: 'failure'
    }
};
