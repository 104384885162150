import { VidyoService } from './vidyo.service';
import { WaitingRoomService } from './waiting-room.service';
import { AppInfoService } from './app-info.service';
import { PermissionService } from './permission.service';
import { RememberMeService } from './remember-me.service';
import { VcRoomService } from './vc-room.service';
import { GroupsService } from './groups.service';
import { ContactsService } from './contacts.service';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { AppService } from './app.service';
import { KeyboardeventsService } from './keyboardevents.service';
import { EventEmitterService } from './event-emitter.service';
import { LoggerService } from './logger.service';
import { UtilService } from './util.service';
import { RestService } from './rest.service';
import { LocalStorageService } from './local-storage.service';
import { ValidationService } from './validation.service';
import { RtcService } from './rtc.service';
import { SocketService } from './socket.service';
import { ChatService } from './chat.service';
import { TimerService } from './timer.service';
import { NotificationService } from './notification.service';
import { CallerService } from './caller.service';
import { MeetingService } from './meeting.service';
import { AudioService } from './audio.service';
import { HistoryCallerService } from './history-caller.service';
import { SearchService } from './search.service';
import { GaDiagnosticService } from './ga-diagnostic.service';
import { ParticipantsControlsService } from './participants-controls.service';
import { DiagnosticService } from './diagnostic.service';
import { GuestAuthService } from './guest-auth.service';
import { RouterService } from './router.service';
import { ExternalInterfaceService } from './external-interface.service';
import { SessionStorageService } from './session-storage.service';
import { OauthService } from './oauth.service';
import { VidyoCustomLayoutService } from './vidyo-custom-layout.service';
import { OutlookAddinService } from './outlook-addin.service';
import { ExternalIntegrationService } from './external-integration.service';
import { RoomConnectionService } from './room-connection.service';
import { JiocloudService } from './jiocloud.service';
import { JioCloudUtilService } from './jio-cloud-util.service';
import { JiocloudAuthService } from './jiocloud-auth.service';
import { ComponentFactoryService } from './component-factory.service';
import { WhiteboardService } from './whiteboard.service';
// import { ACSService } from './acs.service';
import { VideoWrapperService } from './video-wrapper.service';
import { JanusService } from './janus.service';
import { SystemService } from './system.service';
import { GoogleapiService } from './googleapi.service';
import { AgoraService } from './agora.service';
import { FullscreenService } from './fullscreen.service';
import { RCChatService } from './rc-chat.service';
import { LiveStreamService } from './live-stream.service';
import { ThirdPartyExternalIntegrationService } from './third-party-external-integration.service';
import { CallService } from './call.service';
import { GoogleTagManagerService } from './google-tag-manager.service';
import { JmMediaService } from './jm-media.service';
import { ProfilePhotoService } from './profile-photo.service';
import { DesktopAppService } from './desktop-app.service';
import { DesktopAppUpdaterService } from './desktop-app-updater.service';
import { RdpSocketService } from './rdp-socket.service';
import { DownloadAppService } from './download-app.service';

export * from './auth.service';
export * from './app.service';
export * from './keyboardevents.service';
export * from './event-emitter.service';
export * from './logger.service';
export * from './util.service';
export * from './rest.service';
export * from './local-storage.service';
export * from './user.service';
export * from './validation.service';
export * from './contacts.service';
export * from './rtc.service';
export * from './groups.service';
export * from './socket.service';
export * from './chat.service';
export * from './timer.service';
export * from './notification.service';
export * from './vc-room.service';
export * from './remember-me.service';
export * from './caller.service';
export * from './permission.service';
export * from './meeting.service';
export * from './audio.service';
export * from './app-info.service';
export * from './history-caller.service';
export * from './search.service';
export * from './ga-diagnostic.service';
export * from './participants-controls.service';
export * from './diagnostic.service';
export * from './guest-auth.service';
export * from './router.service';
export * from './external-interface.service';
export * from './session-storage.service';
export * from './oauth.service';
export * from './vidyo-custom-layout.service';
export * from './outlook-addin.service';
export * from './external-integration.service';
export * from './component-factory.service';
export * from './jiocloud-auth.service';
export * from './jiocloud.service';
export * from './jio-cloud-util.service';
export * from './room-connection.service';
export * from './waiting-room.service';
export * from './vidyo.service';
export * from './whiteboard.service';
// export * from './acs.service';
export * from './video-wrapper.service';
export * from './janus.service';
export * from './system.service';
export * from './googleapi.service';
export * from './agora.service';
export * from './fullscreen.service';
export * from './rc-chat.service';
export * from './live-stream.service';
export * from './third-party-external-integration.service';
export * from './call.service';
export * from './google-tag-manager.service';
export * from './jm-media.service';
export * from './profile-photo.service';
export * from './desktop-app.service';
export * from './desktop-app-updater.service';
export * from './rdp-socket.service';
export * from './download-app.service';

export const services = [
    AuthService,
    AppService,
    KeyboardeventsService,
    EventEmitterService,
    LoggerService,
    UtilService,
    RestService,
    LocalStorageService,
    UserService,
    ValidationService,
    ContactsService,
    RtcService,
    GroupsService,
    SocketService,
    ChatService,
    TimerService,
    NotificationService,
    VcRoomService,
    RememberMeService,
    CallerService,
    PermissionService,
    MeetingService,
    AudioService,
    AppInfoService,
    HistoryCallerService,
    SearchService,
    GaDiagnosticService,
    ParticipantsControlsService,
    DiagnosticService,
    GuestAuthService,
    RouterService,
    ExternalInterfaceService,
    SessionStorageService,
    OauthService,
    VidyoCustomLayoutService,
    OutlookAddinService,
    ExternalIntegrationService,
    ComponentFactoryService,
    JioCloudUtilService,
    JiocloudAuthService,
    JiocloudService,
    RoomConnectionService,
    WaitingRoomService,
    VidyoService,
    WhiteboardService,
    // ACSService,
    VideoWrapperService,
    JanusService,
    SystemService,
    GoogleapiService,
    AgoraService,
    FullscreenService,
    RCChatService,
    LiveStreamService,
    ThirdPartyExternalIntegrationService,
    CallService,
    GoogleTagManagerService,
    JmMediaService,
    ProfilePhotoService,
    DesktopAppUpdaterService,
    RdpSocketService,
    DownloadAppService
];
