import { AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { APP_EVENTS } from 'src/app/constants';
import { EventData, EventEmitterService, UtilService } from 'src/app/core';

@Directive({
    selector: '[appForceFullWidth]'
})
export class ForceFullWidthDirective implements OnInit, AfterViewInit {
    @Input()
    afterWidth: number = 1366; //default screen width
    isMobile = false;
    constructor(
        private element: ElementRef,
        private utilService: UtilService,
        private renderer: Renderer2,
        private eventEmitterService: EventEmitterService
    ) {}
    ngOnInit() {
        this.isMobile = this.utilService.isMobileBrowser(1080);
    }

    ngAfterViewInit() {
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.handleForceFullWidth();
            }
        });
        this.handleForceFullWidth();
    }

    handleForceFullWidth() {
        if (this.isMobile) return;
        //force to get full width even the screen max width is 1366;
        // in that case give margin left and right in negative whatever the remaining after 1366 width;
        const remainingWidth = this.utilService.getLeftRightWidth() - 8;
        if (remainingWidth > 0) {
            this.renderer.setStyle(this.element.nativeElement, 'marginLeft', `${-remainingWidth / 2}px`);
            this.renderer.setStyle(this.element.nativeElement, 'margin-right', `${-remainingWidth / 2}px`);
        }
    }
}
