import { Component, OnInit } from '@angular/core';
import { APP_EVENTS } from 'src/app/constants';
import { EventEmitterService, UtilService } from 'src/app/core';

@Component({
    selector: 'app-screen-share-permission',
    templateUrl: './screen-share-permission.component.html',
    styleUrls: ['./screen-share-permission.component.scss']
})
export class ScreenSharePermissionComponent implements OnInit {
    pageInsideTab = 1;
    isWindow = false;
    constructor(private eventEmitterService: EventEmitterService, private utilService: UtilService) {}

    ngOnInit(): void {
        this.setOS();
    }

    closePermissiondialog() {
        this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_SCREEN_PERMISSION_UI, data: false });
    }

    setTab(tab) {
        if (tab == 'mac') {
            this.isWindow = false;
        } else {
            this.isWindow = true;
        }
    }

    setOS() {
        const os = this.utilService.getOS();
        switch (os) {
            case 'Windows':
                this.isWindow = true;
                break;
            case 'Mac OS':
                this.isWindow = false;
                break;
            default:
                this.isWindow = false;
        }
    }
}
