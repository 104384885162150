export const EXTERNAL_INTEGRATION = {
    MSFT_URL: `${window.location.origin}/assets/lib/msteams/microsoftteams.min.js`,
    OUTLOOK_URL: 'https://appsforoffice.microsoft.com/lib/1.1/hosted/office.js',
    OUTLOOK_LOGIN_URL: `${window.location.origin}/outlook/login?bot=false&outlook=true&chatbot=false`,
    OUTLOOK_MSAL_URL: `https://alcdn.msauth.net/lib/1.1.3/js/msal.min.js`,
    OUTLOOK_MS_AJAX_URL: 'https://ajax.microsoft.com/ajax/4.0/MicrosoftAjax.js',
    OUTLOOK_CLIENT_ID: 'de1ae1c6-81b5-401d-9d1f-8365188c40cd',
    OUTLOOK_REDIRECT_URI: `${window.location.origin}/teams-bot/outlook`,
    OUTLOOK_SCOPE: 'https://graph.microsoft.com/Calendars.ReadWrite',
    GRAPH_API_LOGIN_ENDPOINT_URL: 'https://login.microsoftonline.com',
    OUTLOOK_AUTHORITY_URL: 'https://login.microsoftonline.com/common',
    TENANT_ID: '49fc6461-6270-4d70-a7ec-317b78891989',
    GRAPH_API_ENDPOINT: 'https://graph.microsoft.com/v1.0/me',
    TEAMS_REDIRECT_URI: `${window.location.origin}/teams-bot/home`,
    TEAMS_CLIENT_ID: `528f4503-e05c-4489-8553-ea5f5dc51b4c`,
    EC_GOOGLE_AUTH_LIBRARY_URL: 'https://apis.google.com/js/api.js',
    EC_GOOGLE_CLIENT_ID: '451387017518-82omd7gios00o83jp94n5c7p3acthcqu.apps.googleusercontent.com',
    EC_GOOGLE_DISCOVERY_DOCS: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
    EC_GOOGLE_SCOPES: 'https://www.googleapis.com/auth/calendar.events',
    CALENDAR_TYPE_GOOGLE: 'google',
    CALENDAR_TYPE_OUTLOOK: 'outlook',
    CALENDAR_TYPE_OTHER: 'other',
    EC_OUTLOOK_CLIENT_ID: '1069e4ec-1d01-4322-ade9-127536fbaa54',
    EC_OUTLOOK_TENANT_ID: '49fc6461-6270-4d70-a7ec-317b78891989',
    EC_OUTLOOK_OBJECT_ID: '28001b0d-2b61-4ce7-b048-1f96b1d40c8b',
    EC_OUTLOOK_REDIRECT_URI: `${window.location.origin}`,
    EC_OUTLOOK_SCOPES: ['calendars.readwrite'],
    EC_OUTLOOK_AUTHORITY_URL: 'https://login.microsoftonline.com/common'
};
