<div
    class="chat-markdown chat-window"
    id="chatOuterBorder"
    tabindex="0"
    aria-label="Chat Window: To compose and send a message, please press the Tab key and type your message in the input field here."
    aria-describedby="instructions"
    aria-live="polite"
>
    <div
        #content
        class="content article demo"
        [class.increase]="showMarkdown"
        (drop)="onDrop($event)"
        [ngClass]="isMobileDevice ? 'content-mobile' : ''"
        (keydown)="handleKeyPress($event)"
    ></div>
    <div class="emojis-block" *ngIf="selectedEmoji">
        <app-emoji-box (onEmojiSelect)="insertEmoji($event)"></app-emoji-box>
    </div>
</div>
