import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { APP_EVENTS, SOCKET_EVENTS, VIDYO_EVENTS } from 'src/app/constants';
import {
    AppService,
    AuthService,
    CallService,
    EventData,
    EventEmitterService,
    LocalStorageService,
    ProfilePhotoService,
    RoomConnectionService,
    RtcService,
    SocketEvent,
    SocketService,
    SystemService,
    UserService,
    UtilService,
    VideoWrapperService
} from 'src/app/core';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { CallSettingsComponent, HostDirective } from 'src/app/shared';
import { findIndex } from 'lodash-es';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-call-dialer',
    templateUrl: './call-dialer.component.html',
    styleUrls: ['./call-dialer.component.scss']
})
export class CallDialerComponent implements OnInit, OnDestroy {
    @Input() userContact;
    @Input() type;
    @Input() event;

    @Output() disableCallLoader = new EventEmitter();

    isGlobalSearchEnabled;
    localSearches;

    currentUser;
    noVideoDeviceAvailable: boolean = false;
    micPrivacy = false;
    cameraPrivacy = false;
    showCallLoader: boolean = false;
    noOfUsersRejected = 0;
    meetingInfo: any = {};
    callTitle = '';
    roomType = 'dynamic';
    room: any = {
        personal: null,
        dynamic: null
    };
    transferCall: boolean = false;
    cameraDropdownOpened = false;
    micDropdownOpened = false;
    settingsComponentRef: ComponentRef<CallSettingsComponent>;
    @ViewChild(HostDirective) viewHost: HostDirective;
    videoService;
    joinWithoutVideo = false;
    joinWithoutAudio = false;
    subscriptions: Subscription[] = [];

    constructor(
        private router: Router,
        private appService: AppService,
        private authService: AuthService,
        private userService: UserService,
        private eventEmitterService: EventEmitterService,
        private utilService: UtilService,
        private translate: TranslateService,
        private toastrService: ToastrService,
        private socketService: SocketService,
        private zone: NgZone,
        public profilePhotoService: ProfilePhotoService,
        private localStorageService: LocalStorageService,
        private appLoggerService: AppLoggerService,
        private rtcService: RtcService,
        private videoWrapperService: VideoWrapperService,
        private systemService: SystemService,
        private roomConnectionService: RoomConnectionService,
        private callService: CallService,
        private toasterService: ToastrService,
        private compFactory: ComponentFactoryResolver
    ) {
        this.videoService = this.videoWrapperService.getVideoServiceForPreview();
    }

    ngOnInit(): void {
        this.isGlobalSearchEnabled = this.appService.getConfigVariable('ENABLE_GLOBAL_SEARCH');
        if (this.isGlobalSearchEnabled) {
            this.localSearches = this.localStorageService.getItem('globalSearches');
        }
        this.subscriptions.push(
            this.socketService.dataEvents$.subscribe(this.handleMeetingEvents.bind(this)),
            this.eventEmitterService.subscribe((event: EventData) => {
                this.eventActionResolver(event).has(event.type) && this.eventActionResolver(event).get(event.type)();
            })
        );

        this.currentUser = this.userService.getUserSync();

        this.makeCall(this.userContact, this.type, this.event);
    }

    async makeCall(userContact, type, event) {
        this.resetGlobalSearchCallInfo();
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        this.noVideoDeviceAvailable = await this.utilService.checkIfCameraDoesntExists();
        const devicePermissions = await this.utilService.getDevicePermissions('Call Dialer');
        this.joinWithoutVideo = devicePermissions?.noVideoDevice;
        this.joinWithoutAudio = devicePermissions?.noAudioDevice;
        this.micPrivacy = (type === 'audio' || type === 'video') && !this.joinWithoutAudio ? true : false;
        this.cameraPrivacy = type === 'video' && !this.noVideoDeviceAvailable && !this.joinWithoutVideo ? true : false;
        let users = this.getUsers(userContact);
        this.getRoom().subscribe((room) => {
            this.meetingInfo = room;
            this.start(userContact, room, users, type);
        });
    }

    start(userContact, roomObj, users, type) {
        if (this.currentUser.mediaEnginePreference === 'agora') {
            this.rtcService.setPreparatorySetupDone(true);
        }

        if (userContact?.length === 1) {
            this.callTitle = userContact[0]?.fullname
                ? userContact[0]?.fullname
                : userContact[0]?.name + ' ' + userContact[0]?.lname;
        }

        const commonConferenceInfo = {
            isInitiater: true,
            joiningName: (this.currentUser.name + ' ' + this.currentUser.lname).trim(),
            room: {
                ...roomObj,
                topic: `${this.callTitle}`,
                title: `${this.callTitle}`
            },
            ownerDetails: {
                isHostRight: true,
                lname: this.currentUser.lname,
                name: this.currentUser.name,
                userId: this.currentUser._id,
                _id: this.currentUser._id
            },
            hideMoreCallControls: false,
            hideAllCallControls: false,
            isFullScreenShare: false,
            forBetaTesting: roomObj?.forBetaTesting,
            transferCall: this.transferCall,
            isOneToOneCall: userContact?._id?.substring(0, 2) !== 'g-' && userContact?._id?.substring(0, 2) !== 'vc'
        };
        const additionalConferenceInfo = this.videoWrapperService.getAdditionalConferenceInfo(roomObj, false);
        const conferenceInfo = { ...commonConferenceInfo, ...additionalConferenceInfo };
        this.rtcService.setConferenceInfo(conferenceInfo);
        this.videoService = this.videoWrapperService.getVideoServiceForPreview();
        this.showCallLoader = true;
        this.appService.globalSearchCallUsers = users;
        this.appService.isCallInitiatedByGlobalSearch = true;

        setTimeout(() => {
            var audio = document.getElementById('testAudio') as HTMLAudioElement;
            audio.src = this.utilService.selectedIncomingaudio.audioPath;
            audio.loop = true;
            audio.play();
        }, 100);

        this.appService.globalSearchGroupId = '';
        const {
            isInitiater,
            joiningName,
            room,
            ownerDetails,
            hideMoreCallControls,
            hideAllCallControls,
            isFullScreenShare,
            incomingCall,
            transferCall,
            host,
            isOneToOneCall
        } = this.rtcService.getConferenceInfo();

        this.rtcService.setRoomInfo(room);
        this.rtcService.setIsInitiater(isInitiater);

        const callPayload: any = {
            callurl: room.room_url || room.roomurl || room.roomUrl,
            room: room.room_key || room.roomkey || room.roomKey,
            room_id: room.roomID.toString(),
            roomPIN: room.vpin || room.roomPIN || room.room_pin,
            userPIN: room.roomPIN || room.room_pin,
            gateway_ip: room.gateway_ip,
            jiomeetId: room.jiomeetId,
            guest_name: joiningName,
            owner_name: ownerDetails.name,
            owner_lname: ownerDetails.lname,
            owner_id: ownerDetails._id,
            owner_emailId: ownerDetails.email,
            owner_tenantId: ownerDetails.tenantId,
            isInitiater,
            participantCount: 0,
            isJoinMeeting: true,
            title: room.topic || '',
            topic: room.topic || '',
            group_id: this.appService.globalSearchGroupId !== '' ? this.appService.globalSearchGroupId : '0',
            users: this.appService.globalSearchCallUsers,
            is_disconnect: 'false',
            hideMoreCallControls,
            hideAllCallControls,
            isFullScreenShare,
            participantEndPointType: this.systemService.getClientDeviceType(),
            participantEndPointName: 'web',
            machineIp: this.systemService.getDeviceUUID(),
            incomingCall,
            transferCall,
            isOneToOneCall,
            manualIps: userContact?.ipaddress
                ? [
                      {
                          type: 'SIP',
                          ipaddress: userContact?.ipaddress
                      }
                  ]
                : [],
            host,
            customlivestream: room?.customlivestream
        };

        const authInfo = this.authService.getAuthInfo();
        callPayload.token = authInfo.jwt;
        const hostURL = this.appService.getEnvVariable('HOST_URL');
        this.appService.globalSearchCallCameraState = this.cameraPrivacy;

        this.videoService.setJoinData({
            host: null,
            displayName: null,
            roomKey: null,
            roomPin: null,
            micPrivacy: this.micPrivacy,
            cameraPrivacy: this.cameraPrivacy,
            autoJoin: false,
            viewId: 'contacts-call-preview'
        });
        if (this.currentUser.mediaEnginePreference === 'agora') {
            this.videoService.initializeVidyoConnector(true);
        } else {
            this.videoService.initializeVidyoConnector();
        }

        const meetingObj = {
            ...callPayload,
            startTime: room.startTime ? new Date(room.startTime) : null,
            endTime: room.endTime ? new Date(room.endTime) : null,
            url: `${hostURL}shortener?meetingId=${
                room?.mainRoomJiomeetId ? room?.mainRoomJiomeetId : room.jiomeetId
            }&pwd=${room?.mainRoomPIN ? room?.mainRoomPIN : room.roomPIN}`,
            addToJmtUrl: `${hostURL}addtojiomeet?meetingId=${room.jiomeetId}&pwd=${room.roomPIN}&isWebinar=${
                room.isWebinar || false
            }`,
            legacyConnect: room.legacyConnect,
            jiomeetIdDisplayLabel: this.meetingIdFormat(room.jiomeetId),
            pin: room.roomPIN || ''
        };
        this.callService.meetingObj = meetingObj;
        this.roomConnectionService.setMeetingInfo(meetingObj);
        callPayload['time'] = new Date().getTime() - new Date('1970-01-01').getTime();

        this.rtcService.makeCall({ data: callPayload, event: 'call' }).subscribe(
            (res: any) => {
                const { historyId, topic } = res;
                this.meetingInfo.isScheduledMeeting = res.isScheduledMeeting;
                this.meetingInfo.historyId = historyId;
                this.eventEmitterService.emit({ type: APP_EVENTS.INITCHATCALL, data: this.meetingInfo });
                this.meetingInfo.title =
                    topic ||
                    callPayload?.title ||
                    `${this.meetingInfo?.owner_name}${
                        this.meetingInfo?.owner_lname ? ` ${this.meetingInfo?.owner_lname}` : ''
                    }'s JioMeet Meeting`;
                this.rtcService.setCameraMicPreference(this.cameraPrivacy, this.micPrivacy);
                if (type === 'video' && !this.noVideoDeviceAvailable) {
                    this.videoService.toggleCameraForSetting('contacts-call-preview', this.cameraPrivacy);
                }
                /* if (res?.userStatusArr?.length > 0) {
                    this.checkCallStatus(res?.userStatusArr);
                } */
            },
            (err) => {
                this.appLoggerService.error(
                    'Chat call API Failed: ',
                    new Error(JSON.stringify(err?.error?.errors)),
                    err
                );
            }
        );
    }

    endCall() {
        this.videoService.sendChatMessage({
            type: 'PublicChat',
            message: VIDYO_EVENTS.HOST_DISCONNECT_ALL
        });
        this.videoService.leaveRoom();
        this.stopCall();
    }

    stopCall() {
        if (!this.meetingInfo.historyId) {
            this.resetGlobalSearchCallInfo();
            this.showCallLoader = false;
            this.disableCallLoader.next(false);
            return;
        }
        this.rtcService
            .leaveRoom({
                flow: 'host',
                autoDisconnect: false,
                historyId: this.meetingInfo.historyId || '',
                roomID: this.meetingInfo.room_id || this.meetingInfo.roomID,
                roomKey: null
            })
            .subscribe(
                (res) => {
                    this.sendCallDropEventToParticipants();
                },
                (err) => {
                    this.appLoggerService.error(
                        'Error while call end: ',
                        new Error(JSON.stringify(err?.error?.errors)),
                        err
                    );
                }
            );
    }

    sendCallDropEventToParticipants() {
        (this.appService?.globalSearchCallUsers || []).forEach((user) => {
            const data = {
                userId: user.userId,
                name: this.currentUser.name,
                phoneNo: this.currentUser.phoneNo,
                ownId: this.currentUser._id,
                roomKey: this.meetingInfo?.room,
                eventType: SOCKET_EVENTS.CALL_DROP_TOAST_NOTIFICATION
            };
            this.rtcService.sendCallDropNotification(data).subscribe((data) => {
                this.resetGlobalSearchCallInfo();
                this.showCallLoader = false;
                this.disableCallLoader.next(false);
                this.rtcService.setConferenceInfo(null);
            });
        });
    }

    // checkCallStatus(users) {
    //     users.forEach((user) => {
    //         if (user?.status === 'busy' || user?.status === 'offline') {
    //             this.noOfUsersRejected++;
    //         }
    //     });
    //     // if (users.length === this.noOfUsersRejected) {
    //     //     this.toasterService.clear();
    //     //     if (users.length > 1) {
    //     //         this.toastrService.info(this.translate.instant('tostrs.busy_or_offline_users'));
    //     //     } else {
    //     //         this.toastrService.info(this.translate.instant('tostrs.busy_or_offline_user'));
    //     //     }
    //     //     this.endCall();
    //     // }
    // }

    resetGlobalSearchCallInfo() {
        this.appService.resetGlobalSearchCallInfo();
        var audio = document.getElementById('testAudio') as HTMLAudioElement;
        if (audio) {
            audio.pause();
            audio.currentTime = 0;
        }
        this.noOfUsersRejected = 0;
        this.meetingInfo = {};
        this.callTitle = '';
    }

    getUsers(users) {
        let testUsers = users.map((user: any) => {
            return { userId: user?.userId || user?._id, name: user?.name + ' ' + user?.lname };
        });
        return testUsers;
    }

    getRoom() {
        return this.rtcService
            .getRoom(this.roomType === 'personal')
            .pipe(tap((room) => (this.room[this.roomType] = room)));
    }

    meetingIdFormat(value) {
        if (!value) return '';
        let result;
        if (value?.includes('-')) {
            result = value.replace(/-/g, ' ');
        } else if (!/\s/.test(value)) {
            result = `${value.substring(0, 3)} ${value.substring(3, 6)} ${value.substring(6)}`;
        }

        return result || value;
    }

    handleMeetingEvents(event: SocketEvent) {
        if (this.isGlobalSearchEnabled) {
            this.zone.run(() => {
                switch (event.event) {
                    case SOCKET_EVENTS.GENERIC:
                        this.handleGenericMeetingEvents(event.data);
                        break;
                    case SOCKET_EVENTS.CALL_ACCEPT:
                        if (
                            event?.data?.historyId === this.meetingInfo?.historyId &&
                            event?.data?.userIds.includes(this.currentUser?._id)
                        ) {
                            this.resetGlobalSearchCallInfo();
                            this.showCallLoader = false;
                            this.disableCallLoader.next(false);
                            this.router
                                .navigateByUrl('/', { skipLocationChange: true })
                                .then(() =>
                                    this.router.navigate(['conference/call'], { queryParams: { incomingCall: true } })
                                );
                        }
                        break;
                    case SOCKET_EVENTS.MISSED_CALL:
                        this.toasterService.clear();
                        if (event?.data?.historyId === this.meetingInfo?.historyId) {
                            if (this.appService.globalSearchCallUsers?.length > 1) {
                                this.toastrService.warning(this.translate.instant('tostrs.busy_or_offline_users'));
                            } else {
                                this.toastrService.warning(this.translate.instant('tostrs.busy_or_offline_user'));
                            }
                            this.endCall();
                        }
                        break;
                    case SOCKET_EVENTS.OUTGOING_CALL_UPDATE:
                        const userFound = findIndex(this.appService.globalSearchCallUsers, [
                            'userId',
                            event?.data?.userId
                        ]);
                        if (
                            event?.data?.historyId === this.meetingInfo?.historyId &&
                            userFound !== -1 &&
                            event?.data?.event === SOCKET_EVENTS.CALL_ACCEPT
                        ) {
                            this.resetGlobalSearchCallInfo();
                            this.showCallLoader = false;
                            this.disableCallLoader.next(false);
                            this.router
                                .navigateByUrl('/', { skipLocationChange: true })
                                .then(() =>
                                    this.router.navigate(['conference/call'], { queryParams: { incomingCall: true } })
                                );
                        }
                        break;
                }
            });
        }
    }

    handleGenericMeetingEvents(eventData) {
        if (
            [
                SOCKET_EVENTS.CALL_CUT_NOTIFICATION,
                SOCKET_EVENTS.CALL_NOT_ANSWERED_NOTIFICATION
                // SOCKET_EVENTS.IN_ANOTHER_CALL_NOTIFICATION
            ].includes(eventData.eventType)
        ) {
            this.noOfUsersRejected++;
        }
        switch (eventData.eventType) {
            case SOCKET_EVENTS.CALL_NOT_ANSWERED_NOTIFICATION:
                this.toasterService.info(this.translate.instant('chat.call_not_answer', { value: eventData?.name }));
                break;
            case SOCKET_EVENTS.CALL_CUT_NOTIFICATION:
                const userFound = findIndex(this.appService.globalSearchCallUsers, ['userId', eventData?.userId]);
                if (eventData?.historyId === this.meetingInfo?.historyId && userFound !== -1) {
                    this.toasterService.info(this.translate.instant('chat.call_reject', { value: eventData?.name }));
                }
                break;
            case SOCKET_EVENTS.IN_ANOTHER_CALL_NOTIFICATION:
                this.toasterService.info(this.translate.instant('chat.in_another_call', { value: eventData?.name }));
                break;
        }
        if (
            this.noOfUsersRejected === this.appService.globalSearchCallUsers.length &&
            eventData?.historyId === this.meetingInfo?.historyId
        ) {
            this.endCall();
        }
    }

    async toggleVideo() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
            stream.getVideoTracks().forEach((track) => track.stop());
            if (this.videoService.cameras.length === 0) {
                this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: true });
                return;
            }
            this.changeCameraPrivacy();
        } catch (err) {
            this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: true });
        }
    }

    async changeCameraPrivacy() {
        if (this.videoService?.processingToggleCamera) {
            return;
        }
        this.appService.globalSearchCallCameraState = !this.cameraPrivacy;
        await this.videoService.toggleCameraForSetting('contacts-call-preview', !this.cameraPrivacy);
        this.cameraPrivacy = !this.cameraPrivacy;
        this.rtcService.setCameraMicPreference(this.cameraPrivacy, this.micPrivacy);
    }

    async toggleAudio() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
            stream.getAudioTracks().forEach((track) => track.stop());
            if (this.videoService.microphones.length === 0) {
                this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: true });
                return;
            }
            this.changeMicPrivacy();
        } catch (err) {
            this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: true });
        }
    }

    changeMicPrivacy() {
        this.videoService.toggleMicPrivacy(this.micPrivacy);
        this.micPrivacy = !this.micPrivacy;
        this.rtcService.setCameraMicPreference(this.cameraPrivacy, this.micPrivacy);
    }

    eventActionResolver(event): Map<any, any> {
        return new Map([
            [
                APP_EVENTS.ACTIVATE_ROOM_SETTINGS,
                () => {
                    if (!this.settingsComponentRef?.instance) {
                        const settings = this.compFactory.resolveComponentFactory(CallSettingsComponent);
                        this.settingsComponentRef = this.viewHost.viewContainerRef.createComponent(settings);
                        this.settingsComponentRef.instance.selectedLabel = event.data.setting;
                        this.settingsComponentRef.instance.afterClose.subscribe((res) => {
                            this.settingsComponentRef.destroy();
                            this.settingsComponentRef = undefined;
                        });
                    }
                }
            ]
        ]);
    }

    toggleDropdown(event: MouseEvent, dropdown: HTMLElement) {
        event.stopPropagation();
        if (dropdown?.classList.contains('mic-dropdown')) {
            this.micDropdownOpened = !this.micDropdownOpened;
            this.cameraDropdownOpened = false;
        }
        if (dropdown?.classList.contains('camera-dropdown')) {
            this.cameraDropdownOpened = !this.cameraDropdownOpened;
            this.micDropdownOpened = false;
            let topPosistion = 43;
            if (document.getElementsByClassName('camera-dropdown')[0]?.children?.length > 1) {
                topPosistion = 43 * document.getElementsByClassName('camera-dropdown')[0]?.children?.length;
            }
            (document.getElementsByClassName('camera-dropdown') as HTMLCollectionOf<HTMLElement>)[0].style.top =
                '-' + topPosistion + 'px';
        }
    }

    openSettings(event: MouseEvent, setting) {
        event.stopPropagation();
        if (setting === 'audio') {
            this.micDropdownOpened = false;
        }
        if (setting === 'video') {
            this.cameraDropdownOpened = false;
        }
        this.eventEmitterService.emit({
            type: APP_EVENTS.ACTIVATE_ROOM_SETTINGS,
            data: {
                setting
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
