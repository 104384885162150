<div class="popup-component">
    <div class="bg"></div>
    <div
        class="popup"
        [class.scrollable-content]="scheduled"
        (scroll)="hideDivPopupMenu()"
        (click)="hideDivPopupMenu()"
    >
        <div class="close-mobile" (click)="cancel()">
            <img src="assets/images/mobile_back_btn.svg" alt="back button" /> {{ 'host_meeting.back' | translate }}
        </div>
        <div class="popup-header">
            <h3
                role="form"
                tabindex="0"
                class="meetingheadingfocus"
                *ngIf="!scheduled"
                aria-label="{{
                    (!meeting ? 'planmeeting_popup.planmeeting' : 'planmeeting_popup.editmeeting') | translate
                }}"
            >
                {{ (!meeting ? 'planmeeting_popup.planmeeting' : 'planmeeting_popup.editmeeting') | translate }}
            </h3>
            <h3 tabindex="0" class="scheduled-text" *ngIf="scheduled" aria-label="Your Meeting has been scheduled">
                {{ 'planmeeting_popup.schedulesuccess' | translate }}
            </h3>
            <div
                class="close"
                (click)="cancel(true)"
                tabindex="0"
                aria-label="Close meeting dialogue box"
                (keyup.enter)="cancel(true)"
            >
                <img src="assets/img/close_icon.svg" alt="close icon" />
            </div>
        </div>
        <div class="popup-body" *ngIf="!scheduled">
            <form class="custom-form" [formGroup]="form">
                <div class="form-group" [class.disabled]="singleEdit">
                    <label>{{ 'planmeeting_popup.topic' | translate }}</label>
                    <input
                        type="text"
                        formControlName="topic"
                        maxlength="100"
                        placeholder="{{ defaultMeetingTopic }}"
                        (keydown)="exceededCharacterLimit($event, 100)"
                    />
                </div>
                <div class="form-group start-time-group d-flex">
                    <div class="start-time pr-3" [class.disabled]="singleEdit">
                        <label>{{ 'planmeeting_popup.date' | translate }}</label>
                        <div
                            class="date-input"
                            aria-label="Select Plan a Meeting date"
                            tabindex="0"
                            (keydown)="onCalendarKeyDown($event)"
                        >
                            <p-calendar
                                #calendar
                                tabindex="0"
                                formControlName="startTime"
                                dateFormat="dd-M-yy"
                                [minDate]="minDate"
                                [(ngModel)]="date"
                                [showOtherMonths]="false"
                                [showIcon]="true"
                                icon="pi pi-angle-down"
                                [appendTo]="'body'"
                                [readonlyInput]="true"
                                (keydown)="onCalendarKeyDown($event)"
                                (onFocus)="onCalendarOpen($event)"
                            >
                                <ng-template pTemplate="header"></ng-template>
                            </p-calendar>
                        </div>
                    </div>
                    <div class="d-flex duration-input" formGroupName="duration">
                        <div class="time-input pr-3">
                            <label>{{ 'planmeeting_popup.starttime' | translate }}</label>
                            <div
                                class="dropdown timestartTimeDropdown"
                                id="timeInputDropdown"
                                tabindex="0"
                                (keydown.enter)="changeFocusToDropdown()"
                            >
                                <input
                                    type="text"
                                    aria-label="Select start time"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                    placeholder="Select Time"
                                    tabindex="-1"
                                    [(ngModel)]="startTimeString"
                                    id="timeInputDropdownInput"
                                    [appendTo]="'body'"
                                    (click)="showDivPopupMenu($event)"
                                    (keydown)="onDropdownKeyDown($event)"
                                    [ngModelOptions]="{ standalone: true }"
                                    (focusout)="onTimeInputChange(startTimeString)"
                                />
                                <div class="parent-dropdown dropdown-menu" id="divStartTime">
                                    <div class="custom-dropdown">
                                        <div
                                            *ngFor="let time of times; let i = index"
                                            [attr.id]="'timeOption' + i"
                                            class="dropdown-option"
                                            (click)="selectTime(time)"
                                            (mouseenter)="onTimeOptionHovered(i)"
                                            (mouseleave)="onTimeOptionUnhovered()"
                                            [ngClass]="{
                                                'selected-time': time.label === startTimeString,
                                                'hovered-time': i === hoveredTimeIndex
                                            }"
                                        >
                                            {{ time.label }}
                                            <span
                                                *ngIf="time.label === startTimeString"
                                                class="tick-mark size-s color-primary background-true inverse-true label-true swap-icon-ic_confirm"
                                                ><img alt="" src="assets/images/tick-mark.svg" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="time-input">
                            <label>{{ 'planmeeting_popup.duration' | translate }}</label>
                            <div
                                class="dropdown startTimeDropdown"
                                id="durationDropdown"
                                tabindex="0"
                                (keydown.Enter)="showDivPopupMenu($event)"
                            >
                                <input
                                    type="text"
                                    [class.error]="minserror"
                                    readonly
                                    data-toggle="dropdown"
                                    tabindex="0"
                                    aria-label="Select duration of a meeting"
                                    aria-expanded="false"
                                    placeholder="Select Duration"
                                    [value]="durationString"
                                    id="durationDropdownInput"
                                    (click)="showDivPopupMenu($event)"
                                />
                                <div class="dropdown-menu" id="divDuration">
                                    <label>{{ 'planmeeting_popup.duration' | translate }}</label>
                                    <div class="d-flex justify-content-start">
                                        <div class="select-wrap">
                                            <select
                                                formControlName="durationInHours"
                                                tabindex="0"
                                                aria-label="Select the duration of the meeting in hour"
                                            >
                                                <option [value]="val" *ngFor="let val of durationHours">
                                                    {{ 'planmeeting_popup.hr' | translate : { value: val } }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="separator">:</div>
                                        <div class="select-wrap">
                                            <select
                                                formControlName="durationInMinutes"
                                                tabindex="0"
                                                aria-label="Select the duration of the meeting in minute"
                                            >
                                                <option [value]="val" *ngFor="let val of durationMinutes">
                                                    {{ 'planmeeting_popup.min' | translate : { value: val | pad } }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="actions text-center mt-4">
                                        <button
                                            type="button"
                                            class="custom-button bg-primary"
                                            (click)="setEndDate(this.form.value.startTime)"
                                            (keydown.Enter)="setEndDate(this.form.value.startTime)"
                                        >
                                            {{ 'planmeeting_popup.done' | translate }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-error my-3" *ngIf="minserror">{{ minserror }}</div>

                <div class="form-group repeat-options" formGroupName="repeatOptions" [class.disabled]="singleEdit">
                    <div class="checkbox-wrapper">
                        <div>
                            <input
                                type="checkbox"
                                id="defaultUnchecked7"
                                formControlName="repeat"
                                (change)="onRecurringToggle($event)"
                                (keydown.enter)="onRecurringEnter($event)"
                                tabindex="0"
                                class="checkbox-width"
                            />
                            <label class="pointer" for="defaultUnchecked7">{{
                                'planmeeting_popup.recurringmeeting' | translate
                            }}</label>
                        </div>
                    </div>
                    <div *ngIf="form?.value?.repeatOptions?.repeat">
                        <fieldset>
                            <div class="d-flex align-items-start">
                                <div class="text w-25">
                                    <legend class="ml-5" tabindex="0">
                                        {{ 'planmeeting_popup.repeat' | translate }}
                                    </legend>
                                </div>
                                <div class="d-flex justify-content-start w-90 frequency">
                                    <label
                                        for="daily"
                                        class="pointer pr-5"
                                        tabindex="0"
                                        aria-label="daily"
                                        (click)="onClickOfRecurOption($event)"
                                        (keydown.enter)="onClickOfRecurOption($event)"
                                    >
                                        <input
                                            type="radio"
                                            formControlName="repeat"
                                            name="repeat"
                                            id="daily"
                                            value="daily"
                                            (click)="gTagRecurEvent($event)"
                                        />
                                        <span>{{ 'planmeeting_popup.daily' | translate }}</span>
                                    </label>
                                    <label
                                        for="weekly"
                                        class="pointer pr-5"
                                        tabindex="0"
                                        aria-label="weekly"
                                        (click)="onClickOfRecurOption($event)"
                                        (keydown.enter)="onClickOfRecurOption($event)"
                                    >
                                        <input
                                            type="radio"
                                            formControlName="repeat"
                                            name="repeat"
                                            id="weekly"
                                            value="weekly"
                                            (click)="gTagRecurEvent($event)"
                                        />
                                        <span>{{ 'planmeeting_popup.weekly' | translate }}</span>
                                    </label>
                                    <label
                                        for="monthly"
                                        class="pointer pr-5"
                                        tabindex="0"
                                        aria-label="monthly"
                                        (click)="onClickOfRecurOption($event)"
                                        (keydown.enter)="onClickOfRecurOption($event)"
                                    >
                                        <input
                                            type="radio"
                                            formControlName="repeat"
                                            name="repeat"
                                            id="monthly"
                                            value="monthly"
                                            (click)="gTagRecurEvent($event)"
                                        />
                                        <span>{{ 'planmeeting_popup.monthly' | translate }}</span>
                                    </label>
                                </div>
                            </div>
                        </fieldset>
                        <div class="recur-option" *ngIf="form?.value?.repeatOptions?.repeat === 'daily'">
                            <div class="end-date d-flex align-items-center justify-content-start">
                                <label class="text pr-4 day-align">{{
                                    'planmeeting_popup.occurs_day' | translate
                                }}</label>
                                <div class="date-input">
                                    <p-calendar
                                        formControlName="endDate"
                                        dateFormat="dd-M-yy"
                                        [minDate]="meetingStartTime"
                                        [showOtherMonths]="false"
                                        [showIcon]="true"
                                        icon="pi pi-angle-down"
                                        [appendTo]="'body'"
                                        (onFocus)="onRecurUntillCalenderSelected($event)"
                                        [readonlyInput]="true"
                                    >
                                    </p-calendar>
                                </div>
                            </div>
                        </div>
                        <div class="recur-option" *ngIf="form?.value?.repeatOptions?.repeat === 'weekly'">
                            <!-- <div class="d-lg-inline-flex d-sm-flex-column  align-items-start py-3"> -->
                            <div class="d-flex align-items-start py-3">
                                <div class="text w-25">
                                    <label class="ml-5">{{ 'planmeeting_popup.select_day' | translate }}</label>
                                </div>
                                <div class="options d-flex justify-content-start w-90">
                                    <div
                                        class="d-flex justify-content-around align-items-center"
                                        formArrayName="daysOfWeek"
                                        *ngFor="
                                            let item of form.get('repeatOptions')?.get('daysOfWeek')?.controls;
                                            let j = index
                                        "
                                    >
                                        <div class="checkbox-wrapper mr-3 mb-0" [class.today]="todaysDayIndex === j">
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    id="{{ 'defaultUnchecked8' + j }}"
                                                    formControlName="{{ j }}"
                                                />
                                                <label class="pointer" for="{{ 'defaultUnchecked8' + j }}">
                                                    {{ getDayFromIndex(j) }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="end-date d-flex align-items-center justify-content-start">
                                <label class="text pr-4 day-align">{{
                                    'planmeeting_popup.occurs_week' | translate : { value: getSelectedWeekDays() }
                                }}</label>
                                <div class="date-input">
                                    <p-calendar
                                        formControlName="endDate"
                                        dateFormat="dd-M-yy"
                                        [minDate]="meetingStartTime"
                                        [showOtherMonths]="false"
                                        [showIcon]="true"
                                        icon="pi pi-angle-down"
                                        [appendTo]="'body'"
                                        (onFocus)="onRecurUntillCalenderSelected($event)"
                                        [readonlyInput]="true"
                                    >
                                    </p-calendar>
                                </div>
                            </div>
                        </div>
                        <div class="recur-option" *ngIf="form?.value?.repeatOptions?.repeat === 'monthly'">
                            <div class="end-date d-flex align-items-center justify-content-start">
                                <label class="text pr-4 day-align">{{
                                    'planmeeting_popup.occurs_month'
                                        | translate : { value: form?.value?.repeatOptions?.dateInMonth }
                                }}</label>
                                <div class="date-input">
                                    <p-calendar
                                        formControlName="endDate"
                                        dateFormat="dd-M-yy"
                                        [minDate]="meetingStartTime"
                                        [showOtherMonths]="false"
                                        [showIcon]="true"
                                        icon="pi pi-angle-down"
                                        [appendTo]="'body'"
                                        (onFocus)="onRecurUntillCalenderSelected($event)"
                                        [readonlyInput]="true"
                                    >
                                    </p-calendar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- On Behalf or Self view -->
                <div class="on_behalf_layout" *ngIf="currentUser?.tenantId != null">
                    <label class="on_behalf_label">{{ 'planmeeting_popup.meeting_is_for' | translate }}</label>
                    <div class="on_behalf_buttons_layout">
                        <button
                            [ngClass]="isMeetingForSelf == true ? 'on_behalf_buttons_selected' : 'on_behalf_buttons'"
                            (click)="mySelfClicked()"
                            formControlName="mySelfButton"
                        >
                            <img
                                [src]="
                                    isMeetingForSelf == true ? 'assets/img/ic_user_blue.svg' : 'assets/img/ic_user.svg'
                                "
                            />
                            {{ 'planmeeting_popup.myself' | translate }}
                            <img *ngIf="isMeetingForSelf" class="tick_icon" src="assets/img/ic_blue_tick.svg" />
                        </button>
                        <button
                            [ngClass]="isMeetingOnBehalf == true ? 'on_behalf_buttons_selected' : 'on_behalf_buttons'"
                            class="on_behalf_buttons"
                            (click)="onBehalfHostClicked()"
                            formControlName="onBehalfButton"
                        >
                            <img
                                [src]="
                                    isMeetingOnBehalf == true
                                        ? 'assets/img/ic_onbehalf_host.svg'
                                        : 'assets/img/ic_onbehalf_gray.svg'
                                "
                            />
                            {{ 'planmeeting_popup.onbehalf_host' | translate }}
                            <img *ngIf="isMeetingOnBehalf" class="tick_icon" src="assets/img/ic_blue_tick.svg" />
                        </button>
                    </div>
                </div>
                <div class="form-group" *ngIf="isMeetingOnBehalf">
                    <label tabindex="0" aria-label="Add host">{{ 'planmeeting_popup.add_hosts' | translate }}</label>
                    <app-search-dropdown
                        [items]="items"
                        [isOnBehalfEdit]="isEditandOnBehalfMeeting"
                        [(ngModel)]="item"
                        (afterChange)="onHostSelected($event)"
                        formControlName="onBehalfhosts"
                        ngDefaultControl
                    >
                    </app-search-dropdown>
                </div>
                <hr class="divider mt-4 mb-4" />
                <div class="form-group">
                    <label
                        tabindex="0"
                        aria-label="Add Co-Host + {{
                            !form?.value?.advancedOptions?.isRestrictedMeeting ? 'Optional' : ''
                        }})"
                        >{{ 'planmeeting_popup.add_cohosts' | translate }}
                        {{
                            (!form?.value?.advancedOptions?.isRestrictedMeeting ? 'planmeeting_popup.optional' : '')
                                | translate
                        }}</label
                    >
                    <app-participants-input
                        [isFromMeetingCreation]="true"
                        tabindex="0"
                        aria-label="Search co-host by name, email id or mobile number optional"
                        [isCohostSection]="true"
                        formControlName="cohosts"
                    ></app-participants-input>
                </div>

                <div class="form-group">
                    <label
                        tabindex="0"
                        aria-label="Invite Participants + {{
                            !form?.value?.advancedOptions?.isRestrictedMeeting ? 'Optional' : ''
                        }}"
                        >{{ 'planmeeting_popup.invite' | translate }}
                        {{
                            (!form?.value?.advancedOptions?.isRestrictedMeeting ? 'planmeeting_popup.optional' : '')
                                | translate
                        }}</label
                    >
                    <img
                        alt="info icon {{
                            'planmeeting_popup.participants_limit_tooltip'
                                | translate : { value: currUserMaxParticipants }
                        }}"
                        *ngIf="!isFreeUser"
                        style="padding-left: 5px"
                        src="assets/img/info-icon.svg"
                        #tooltipEle
                        tabindex="0"
                        role="button"
                        tooltipPosition="{{ isMobileDevice ? 'top' : 'right' }}"
                        (click)="$event.stopPropagation(); tooltipEle.focus()"
                        tooltipEvent="{{ isMobileDevice ? 'focus' : 'hover' }}"
                        pTooltip="{{
                            'planmeeting_popup.participants_limit_tooltip'
                                | translate : { value: currUserMaxParticipants }
                        }}"
                        [attr.aria-label]="getInviteParticpantTooltip()"
                        role="tooltip"
                    />
                    <app-participants-input
                        [isFromMeetingCreation]="true"
                        [isParticipantsField]="true"
                        tabindex="0"
                        [declinedUsers]="declinedUsers"
                        aria-label="Invite participants by searching name, email id or mobile number optional"
                        formControlName="participants"
                    ></app-participants-input>
                </div>

                <!-- <div class="form-group calendar-options" formGroupName="calendarOptions">
                    <label>{{ 'planmeeting_popup.calendar' | translate }}</label>
                    <div class="d-flex justify-content-start flex-wrap">
                        <label
                            for="outlook"
                            class="pointer pr-5"
                            (click)="onClickOfOutlookOption($event)"
                            *ngIf="false"
                        >
                            <input
                                type="radio"
                                name="calendar"
                                id="outlook"
                                value="outlook"
                                formControlName="calendar"
                            />
                            <span>{{ 'planmeeting_popup.outlook' | translate }}</span>
                        </label>
                        <label for="google" class="pointer pr-5" (click)="onClickOfGoogleOption($event)">
                            <input type="radio" name="calendar" id="google" value="google" formControlName="calendar" />
                            <span>{{ 'planmeeting_popup.google' | translate }}</span>
                        </label>
                        <label for="other" class="pointer pr-5" (click)="onClickOfOtherOption($event)">
                            <input type="radio" name="calendar" id="other" value="other" formControlName="calendar" />
                            <span>{{ 'planmeeting_popup.other' | translate }}</span>
                        </label>
                    </div>
                </div> -->

                <hr class="divider mt-4 mb-4" />

                <h4
                    tabindex="0"
                    aria-label="Meeting settings"
                    class="sub-heading mb-3"
                    [class.collapsed]="advancedOptionsCollapsed"
                    (click)="toggleAdvancedOptions()"
                >
                    {{ 'planmeeting_popup.meeting_settings' | translate }}
                </h4>
                <div formGroupName="advancedOptions" *ngIf="!advancedOptionsCollapsed" class="ml-3">
                    <fieldset>
                        <legend tabindex="0">{{ 'planmeeting_popup.meeting_security' | translate }}</legend>
                        <div
                            class="checkbox-wrapper"
                            [ngClass]="{ 'disable-checkbox': !currentUser?.featuresEnabled?.restrictedMeeting }"
                        >
                            <div>
                                <input
                                    [attr.disabled]="!currentUser?.featuresEnabled?.restrictedMeeting ? true : null"
                                    type="checkbox"
                                    id="defaultUnchecked1"
                                    (click)="gTagSettingsSelected($event)"
                                    (keydown.enter)="onAdvancedMeetingOptionsEnterClicked($event)"
                                    formControlName="isRestrictedMeeting"
                                    tabindex="0"
                                    class="checkbox-width"
                                />
                                <label
                                    class="pointer"
                                    for="defaultUnchecked1"
                                    tabindex="{{ currentUser?.featuresEnabled?.restrictedMeeting ? -1 : 0 }}"
                                    aria-label="{{ 'planmeeting_popup.restrictedmeeting' | translate }}"
                                    aria-disabled="!currentUser?.featuresEnabled?.restrictedMeeting ? true : null"
                                    [ngClass]="{ 'disable-cursor': !currentUser?.featuresEnabled?.restrictedMeeting }"
                                    role="button"
                                    >{{ 'planmeeting_popup.restrictedmeeting' | translate }}
                                    <img aria-hidden="true" src="assets/img/restricted-icn.svg" alt="restricted icon" />
                                    <img
                                        aria-hidden="true"
                                        id="restrictedPlusIcon"
                                        *ngIf="!currentUser?.featuresEnabled?.restrictedMeeting"
                                        src="assets/img/enterprise/ic_plus.svg"
                                        alt="plus icon"
                                        (click)="onClickPlusIcon('restrictedPlusIcon')"
                                        pTooltip="{{ 'planmeeting_popup.plus_plan_tooltip' | translate }}"
                                        tooltipEvent="{{ isMobileDevice ? 'focus' : 'hover' }}"
                                        tooltipPosition="right"
                                        placeholder="autoHide: true"
                                    />
                                </label>
                            </div>
                            <div
                                class="help"
                                [class.disabled]="!currentUser?.featuresEnabled?.restrictedMeeting"
                                tabindex="0"
                            >
                                {{ 'planmeeting_popup.restrictedmeetinghelp' | translate }}
                            </div>
                        </div>
                        <div
                            class="checkbox-wrapper"
                            *ngIf="!currentUser?.isOTT"
                            [class.disabled]="
                                form.value?.advancedOptions?.isRestrictedMeeting ||
                                !currentUser?.featuresEnabled?.organisationOnlyParticipants
                            "
                        >
                            <div>
                                <input
                                    type="checkbox"
                                    id="defaultUnchecked4"
                                    (click)="gTagSettingsSelected($event)"
                                    (keydown.enter)="onAdvancedMeetingOptionsEnterClicked($event)"
                                    formControlName="colleagueOnly"
                                    tabindex="0"
                                    class="checkbox-width"
                                />
                                <label class="pointer" for="defaultUnchecked4">{{
                                    'planmeeting_popup.myorganization' | translate
                                }}</label>
                            </div>
                            <div class="help" tabindex="0">
                                {{ 'planmeeting_popup.myorganizationhelp' | translate }}
                            </div>
                        </div>
                        <div
                            class="checkbox-wrapper"
                            [class.disabled]="form?.value?.advancedOptions?.isRestrictedMeeting"
                        >
                            <div>
                                <input
                                    type="checkbox"
                                    id="defaultUnchecked2"
                                    (click)="gTagSettingsSelected($event)"
                                    (keydown.enter)="onAdvancedMeetingOptionsEnterClicked($event)"
                                    formControlName="loggedInOnly"
                                    tabindex="0"
                                    class="checkbox-width"
                                />
                                <label class="pointer" for="defaultUnchecked2">{{
                                    'planmeeting_popup.noguest' | translate
                                }}</label>
                            </div>
                            <div class="help" tabindex="0">
                                {{ 'planmeeting_popup.noguesthelp' | translate }}
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend tabindex="0" aria-label="Advanced option">
                            {{ 'planmeeting_popup.advancedoptions' | translate }}
                        </legend>
                        <div class="checkbox-wrapper" *ngIf="currentUser?.isTranslationEnabled">
                            <div>
                                <input
                                    type="checkbox"
                                    id="defaultUnchecked12"
                                    (click)="gTagSettingsSelected($event)"
                                    (keydown.enter)="onAdvancedMeetingOptionsEnterClicked($event)"
                                    formControlName="isTranslationEnabled"
                                    tabindex="0"
                                    class="checkbox-width"
                                />
                                <label
                                    id="translationRoomLabel"
                                    class="pointer"
                                    for="defaultUnchecked12"
                                    aria-label="{{ 'planmeeting_popup.translation_room' | translate }}"
                                    role="button"
                                    >{{ 'planmeeting_popup.translation_room' | translate }}
                                </label>
                            </div>
                            <div class="help" tabindex="0">
                                {{ 'planmeeting_popup.translation_room_help' | translate }}
                            </div>
                        </div>
                        <div
                            class="checkbox-wrapper"
                            [ngClass]="{ 'disable-checkbox': !currentUser?.featuresEnabled?.classroomMode }"
                            *ngIf="classRoomModeEnabled"
                        >
                            <div>
                                <input
                                    [attr.disabled]="!currentUser?.featuresEnabled?.classroomMode ? true : null"
                                    type="checkbox"
                                    id="defaultUncheckedA"
                                    (click)="gTagSettingsSelected($event)"
                                    (keydown.enter)="onAdvancedMeetingOptionsEnterClicked($event)"
                                    formControlName="isClassroomMode"
                                    tabindex="0"
                                    class="checkbox-width"
                                />
                                <label
                                    id="classRoomModeLabel"
                                    class="pointer"
                                    for="defaultUncheckedA"
                                    tabindex="{{ currentUser?.featuresEnabled?.classroomMode ? -1 : 0 }}"
                                    aria-label="{{ 'planmeeting_popup.classroom_mode' | translate }}"
                                    aria-disabled="!currentUser?.featuresEnabled?.classroomMode ? true : null"
                                    [ngClass]="{ 'disable-cursor': !currentUser?.featuresEnabled?.classroomMode }"
                                    role="button"
                                    >{{ 'planmeeting_popup.classroom_mode' | translate }}
                                    <img
                                        id="classRoomPlus"
                                        aria-hidden="true"
                                        *ngIf="!currentUser?.featuresEnabled?.classroomMode"
                                        src="assets/img/enterprise/ic_plus.svg"
                                        alt="plus icon"
                                        (click)="onClickPlusIcon('classRoomPlus')"
                                        pTooltip="{{ 'planmeeting_popup.plus_plan_tooltip' | translate }}"
                                        tooltipEvent="{{ isMobileDevice ? 'focus' : 'hover' }}"
                                        tooltipPosition="right"
                                        placeholder="autoHide: true"
                                    />
                                </label>
                            </div>
                            <div
                                class="help"
                                [class.disabled]="!currentUser?.featuresEnabled?.classroomMode"
                                tabindex="0"
                            >
                                {{ 'planmeeting_popup.classroom_mode_help' | translate }}
                            </div>
                        </div>
                        <div
                            class="checkbox-wrapper"
                            [class.disabled]="!currentUser?.featuresEnabled?.forceChatMute"
                            *ngIf="currentUser?.tenantId != null"
                        >
                            <div>
                                <input
                                    type="checkbox"
                                    id="defaultUnchecked11"
                                    formControlName="restrictChat"
                                    tabindex="0"
                                    class="checkbox-width"
                                    (keydown.enter)="onAdvancedMeetingOptionsEnterClicked($event)"
                                />
                                <label class="pointer" for="defaultUnchecked11">
                                    {{ 'planmeeting_popup.disable_chat' | translate }}
                                </label>
                            </div>
                            <div class="help" tabindex="0">
                                {{ 'planmeeting_popup.disable_chat_help' | translate }}
                            </div>
                        </div>
                        <div class="checkbox-wrapper">
                            <div>
                                <input
                                    type="checkbox"
                                    id="defaultUnchecked3"
                                    (click)="gTagSettingsSelected($event)"
                                    (keydown.enter)="onAdvancedMeetingOptionsEnterClicked($event)"
                                    formControlName="waitingRoom"
                                    tabindex="0"
                                    class="checkbox-width"
                                />
                                <label class="pointer" for="defaultUnchecked3">{{
                                    'planmeeting_popup.enablewaitingroom' | translate
                                }}</label>
                            </div>
                            <div class="help" tabindex="0">
                                {{ 'planmeeting_popup.admit' | translate }}
                            </div>
                        </div>
                        <div class="checkbox-wrapper">
                            <div>
                                <input
                                    type="checkbox"
                                    id="defaultUnchecked5"
                                    (click)="gTagSettingsSelected($event)"
                                    (keydown.enter)="onAdvancedMeetingOptionsEnterClicked($event)"
                                    formControlName="joinBeforeHost"
                                    tabindex="0"
                                    class="checkbox-width"
                                />
                                <label class="pointer" for="defaultUnchecked5">{{
                                    'planmeeting_popup.allowbeforehost' | translate
                                }}</label>
                            </div>
                            <div class="help" tabindex="0">
                                {{ 'planmeeting_popup.allowbeforehosthelp' | translate }}
                            </div>
                        </div>
                        <div class="checkbox-wrapper">
                            <div>
                                <input
                                    type="checkbox"
                                    id="defaultUnchecked6"
                                    (click)="gTagSettingsSelected($event)"
                                    (keydown.enter)="onAdvancedMeetingOptionsEnterClicked($event)"
                                    formControlName="participantHardAudioMute"
                                    tabindex="0"
                                    class="checkbox-width"
                                />
                                <label class="pointer" for="defaultUnchecked6">{{
                                    'planmeeting_popup.forcemute' | translate
                                }}</label>
                            </div>
                            <div class="help" tabindex="0">
                                {{ 'planmeeting_popup.forcemutehelp' | translate }}
                            </div>
                        </div>
                        <div class="checkbox-wrapper">
                            <div>
                                <input
                                    type="checkbox"
                                    id="defaultUnchecked9"
                                    (click)="gTagSettingsSelected($event)"
                                    (keydown.enter)="onAdvancedMeetingOptionsEnterClicked($event)"
                                    formControlName="participantAudio"
                                    tabindex="0"
                                    class="checkbox-width"
                                />
                                <label class="pointer" for="defaultUnchecked9">{{
                                    'planmeeting_popup.softmute' | translate
                                }}</label>
                            </div>
                            <div class="help" tabindex="0">{{ 'planmeeting_popup.softmutehelp' | translate }}</div>
                        </div>
                    </fieldset>
                </div>
                <br />
                <div tabindex="0" class="text-error my-3 restricted-focus" *ngIf="error">
                    {{ error }}<br />
                    <br />
                </div>
                <div class="form-group d-flex justify-content-end popup-buttons">
                    <button type="button" class="custom-button bg-secondary mr-2" (click)="cancel()">
                        {{ 'planmeeting_popup.cancelbutton' | translate }}
                    </button>
                    <button
                        type="button"
                        class="custom-button bg-primary mr-2"
                        [class.disabled]="!form.valid || submitting"
                        *ngIf="!meeting"
                        (click)="submit()"
                        (keypress.enter)="submit()"
                    >
                        {{ 'planmeeting_popup.schedule' | translate }}
                        <!-- {{ (!meeting ? 'planmeeting_popup.schedule' : 'planmeeting_popup.save')|translate }} -->
                    </button>
                    <button
                        type="button"
                        class="custom-button bg-primary mr-2"
                        [class.disabled]="!form.valid || submitting || disableSave || !form.dirty"
                        *ngIf="meeting"
                        (click)="submit()"
                        (keypress.enter)="submit()"
                    >
                        {{ 'planmeeting_popup.save' | translate }}
                    </button>
                    <!-- <button type="submit" class="custom-button bg-primary mr-2" [class.disabled]="!form.valid || submitting || !form.dirty">
            {{ (!meeting ? 'planmeeting_popup.schedule' : 'planmeeting_popup.save')|translate }}
          </button> -->
                </div>
            </form>
        </div>
        <div class="popup-body" *ngIf="scheduled">
            <div class="invitation">
                <app-meeting-invitation
                    [id]="'new-meeting-invitation'"
                    [host]="currentUser"
                    [meeting]="meeting"
                ></app-meeting-invitation>
            </div>
        </div>
        <div class="popup-footer" *ngIf="scheduled">
            <div class="d-flex justify-content-end">
                <button type="button" class="custom-button bg-secondary mr-2" (click)="cancel()">
                    {{ 'planmeeting_popup.close' | translate }}
                </button>
                <button
                    type="button"
                    class="custom-button bg-primary mr-2"
                    [attr.aria-label]="getCopyArialLabel()"
                    (click)="copyInvitation()"
                    [class.disabled]="meeting?.advancedOptions?.isRestrictedMeeting"
                >
                    {{ (copiedInviteToClipboard ? 'planmeeting_popup.copied' : 'planmeeting_popup.copy') | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
