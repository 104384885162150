import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    AppService,
    GoogleTagManagerService,
    UserService,
    ThirdPartyExternalIntegrationService,
    UtilService
} from 'src/app/core';
import { PrimeNGConfig } from 'primeng/api';
import { GA_EVENTS } from '../ga-events';
import { GA_NEW } from 'src/app/shared/ga-new';

@Component({
    selector: 'app-languagesettings',
    templateUrl: './languagesettings.component.html',
    styleUrls: ['./languagesettings.component.scss']
})
export class LanguagesettingsComponent implements OnInit {
    lang;
    public isMobileDevice;
    public isThirdPartyExternalIntegration;
    currentUser: any = {};
    isMyjioIntegration: boolean = false;
    inIFrame: boolean = false;
    isLiveStreamEnabled: boolean = false;
    constructor(
        private appService: AppService,
        private translate: TranslateService,
        private config: PrimeNGConfig,
        private gTagService: GoogleTagManagerService,
        private userService: UserService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        private utilService: UtilService
    ) {
        this.isThirdPartyExternalIntegration =
            this.thirdPartyExternalIntegrationService.getThirdPartyExternalIntegration();
        this.isMobileDevice = this.utilService.isMobileBrowser() || this.isThirdPartyExternalIntegration;
        this.currentUser = this.userService.getUserSync();
        this.isMyjioIntegration = this.thirdPartyExternalIntegrationService.getMyjioIntegration();
        this.inIFrame = this.utilService.inIframe();
        // Feature Check For Live Stream
        this.isLiveStreamEnabled =
            this.appService.getConfigVariable('isLiveStreamingEnabled') &&
            (this.currentUser.mediaEnginePreference === 'agora' ||
                this.currentUser.mediaEnginePreference === 'jm-media') &&
            this.currentUser?.tenantId != null &&
            !this.isThirdPartyExternalIntegration &&
            !this.isMobileDevice &&
            !this.isMyjioIntegration &&
            !this.inIFrame;
    }

    ngOnInit(): void {
        this.lang = this.appService.getAndSaveUserPreferredLang();
        if (this.lang === 'hi') {
            this.gTagService.sendEvent(GA_EVENTS.LANGUAGE_HINDI);
        } else if (this.lang === 'en') {
            this.gTagService.sendEvent(GA_EVENTS.LANGUAGE_ENGLISH);
        }
    }

    setLan(lang) {
        this.appService.getAndSaveUserPreferredLang(lang);
        this.translate.use(lang);
        this.translate.get('primeng').subscribe((res) => {
            this.config.setTranslation(res);
            try {
                (document.getElementsByClassName('p-datepicker-prev')[0] as HTMLElement).click();
                (document.getElementsByClassName('p-datepicker-next')[0] as HTMLElement).click();
            } catch (e) {
                console.log(e);
            }
        });
    }

    gTagLanguage(language) {
        if (language === 'hi') {
            this.gTagService.sendEvent(GA_NEW.LANGUAGE_HINDI);
            this.gTagService.sendEvent(GA_EVENTS.LANGUAGE_HINDI);
        } else if (language === 'en') {
            this.gTagService.sendEvent(GA_NEW.LANGUAGE_ENGLISH);
            this.gTagService.sendEvent(GA_EVENTS.LANGUAGE_ENGLISH);
        }
    }
    //Accessability changes
    setFocusOnElement(lang) {
        setTimeout(() => {
            if (lang == 'en') {
                document.getElementById('hi').focus();
                return;
            }
            if (this.isLiveStreamEnabled) {
                const element: any = document.getElementById('liveStream');
                element.focus();
                return;
            }
            const element: any = document.getElementById('general');
            element.focus();
        }, 100);
    }
}
