<!-- <app-mobile-download-page
    *ngIf="!hideDownloadsSection && isMobileDevice"
    (continueToMeeting)="launchInBrowser()"
></app-mobile-download-page> -->
<div
    class="page container-fluid"
    [ngClass]="isCpass ? 'cpassHeight' : ''"
    [ngClass]="isNavbarDisabled ? 'navbar-disabled' : ''"
    *ngIf="!loading"
    tabindex="0"
    aria-label="Join meeting page"
>
    <div class="compatibility-info" *ngIf="showCompatibilityMessage && !isThirdPartyExternalIntegration">
        <div class="img-wrap mr-3">
            <img alt="" src="assets/img/warning_icn.svg" alt="warning" />
        </div>
        <div>{{ 'downloadspage.compatibility_info' | translate }}</div>
    </div>
    <div class="h-100" [hidden]="!hideDownloadsSection" *ngIf="!checkMultiDeviceLogin">
        <div class="back pt-3 px-3" *ngIf="!isCpass">
            <div
                class="img-wrap pointer mr-2"
                tabindex="0"
                aria-label="Go back to home page"
                (keyup.enter)="clickAppBackButton()"
                (click)="clickAppBackButton()"
            >
                <div class="img"></div>
            </div>
            {{ 'joinmeeting_page_or_joincall.back' | translate }}
        </div>
        <div class="back-btn-mobile" (click)="navigateToDashboard()">
            <button>
                <img alt="" src="assets/images/mobile_back_btn.svg" alt="back button" />
                {{ 'host_meeting.back' | translate }}
            </button>
        </div>
        <div class="row">
            <div class="left-content col-xl-5 col-lg-5 col-md-12 col-sm-12 py-5">
                <div class="call-preview" *ngIf="!hideCallPreview && !headlessMode">
                    <app-call-preview
                        (showsettings)="openSettingsPopup($event)"
                        [hostMeeting]="false"
                        [joinWithoutVideo]="joinWithoutVideo"
                        [joinWithoutAudio]="joinWithoutAudio"
                    ></app-call-preview>
                </div>
            </div>
            <div class="right-content col-xl-7 col-lg-7 col-md-12 col-sm-12 py-5">
                <div class="component-wrap">
                    <div class="form-wrap">
                        <section
                            *ngIf="
                                !collegueOnly && !loggedInOnly && !waitingRoomDetails?.waitingRoom && !joinBeforeHost
                            "
                        >
                            <h3
                                tabindex="0"
                                aria-label="{{ 'joinmeeting_page_or_joincall.title' | translate }}"
                                class="mb-5"
                                (click)="desktopAppService?.enableDevMode()"
                            >
                                {{
                                    (isMobileDevice
                                        ? 'joinmeeting_page_or_joincall.mobileTitle'
                                        : 'joinmeeting_page_or_joincall.title'
                                    ) | translate
                                }}
                            </h3>
                            <form [formGroup]="form" (submit)="join(true)" id="join-meeting-form">
                                <div class="form-group">
                                    <label for="meetingId" class="m-0 mb-2" aria-hidden="true">{{
                                        (isMobileDevice
                                            ? 'joinmeeting_page_or_joincall.meetingidLink'
                                            : 'joinmeeting_page_or_joincall.meetingid'
                                        ) | translate
                                    }}</label>
                                    <input
                                        type="text"
                                        class="form-control main-focus"
                                        id="meetingId"
                                        (keydown.enter)="$event.preventDefault()"
                                        (input)="validateNumberPattern($event)"
                                        (paste)="onPaste($event)"
                                        (keydown.backspace)="handleBackspace($event)"
                                        formControlName="meetingId"
                                        aria-describedby="emailHelp"
                                        placeholder="{{
                                            'joinmeeting_page_or_joincall.meetingidplaceholder' | translate
                                        }}"
                                        maxlength="200"
                                        [class.disabled]="disableFields"
                                        (input)="togglePassword($event.target.value)"
                                    />
                                    <span class="invalid-error">{{ errors.meetingId }}</span>
                                    <span class="invalid-error" *ngIf="errors.global == 'Invalid ID'">
                                        {{ 'joinmeeting_page_or_joincall.please_enter_valid_meetingid' | translate }}
                                    </span>
                                </div>

                                <div class="form-group" *ngIf="showPassword">
                                    <label for="pin" aria-hidden="true">{{
                                        'joinmeeting_page_or_joincall.password' | translate
                                    }}</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="pin"
                                        (keydown.enter)="$event.preventDefault()"
                                        formControlName="pin"
                                        maxlength="5"
                                        placeholder="{{
                                            'joinmeeting_page_or_joincall.passwordplaceholder' | translate
                                        }}"
                                        [class.disabled]="disableFields"
                                    />
                                    <span class="invalid-error" *ngIf="errors.pin">{{ errors.pin }}</span>
                                </div>

                                <div class="form-group">
                                    <label for="name" class="m-0 mb-2" aria-hidden="true">{{
                                        'joinmeeting_page_or_joincall.name' | translate
                                    }}</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="name"
                                        (keydown.enter)="$event.preventDefault()"
                                        formControlName="name"
                                        placeholder="{{ 'joinmeeting_page_or_joincall.nameplaceholder' | translate }}"
                                        [appInputMaxLength]="50"
                                        [class.disabled]="currentUser?.name"
                                    />
                                </div>

                                <div class="ns-enable-button" *ngIf="rtcService.isNoiseCancellationEnabled">
                                    <input
                                        [checked]="rtcService.isNoiseCancellationEnabled"
                                        type="checkbox"
                                        id="defaultUnchecked7"
                                        (change)="enableNoiseSuprression($event)"
                                        class="ns-enable-input"
                                    />
                                    <label class="" for="defaultUnchecked7">{{
                                        'user_dashboard_settings.audio.noise_suppression' | translate
                                    }}</label>
                                </div>
                                <div class="form-group" *ngIf="isTranslationEnabled">
                                    <label>{{ 'joinmeeting_page_or_joincall.select_language' | translate }}</label>
                                    <select
                                        formControlName="translationLanguage"
                                        tabindex="0"
                                        aria-label="Select language for speech translation"
                                        class="language-select form-control"
                                    >
                                        <option [value]="language.value" *ngFor="let language of languageOptions">
                                            {{ language.text }}
                                        </option>
                                    </select>
                                </div>
                                <ng-container *ngIf="showCaptcha">
                                    <div class="captcha-container">
                                        <div class="captcha-box">
                                            <img alt="" [src]="captchaDetails?.captcha" class="captcha-img" alt="" />
                                            <div class="reload-btn">
                                                <img alt="" src="assets/img/reload_icn.svg" alt="" (click)="join()" />
                                            </div>
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Enter CAPTCHA"
                                            formControlName="captchaText"
                                            maxlength="6"
                                        />
                                        <div class="captcha-text">
                                            {{ 'joinmeeting_page_or_joincall.captcha' | translate }}
                                        </div>
                                    </div>
                                </ng-container>
                                <span tabindex="0" id="invaliderrorfocus" class="text-error" *ngIf="errors.global">{{
                                    errors.global
                                }}</span>
                                <div
                                    class="col p-0 w-100 mt-4"
                                    *ngIf="
                                        desktopAppService?.isDesktopApp &&
                                        appService.config.ShowEnvironmentSelectionPopup
                                    "
                                >
                                    <app-environment-selection></app-environment-selection>
                                </div>
                                <div class="col p-0 w-100 mt-4">
                                    <button
                                        type="submit"
                                        class="btn primary"
                                        [class.disabled]="!form.valid || joining || isOffline || maxParticipantReach"
                                    >
                                        {{
                                            (joining
                                                ? 'joinmeeting_page_or_joincall.please_wait'
                                                : getJoinStartBtnTitle()
                                            ) | translate
                                        }}
                                    </button>
                                </div>
                                <div *ngIf="isMobileDevice" class="form-note">
                                    <span class="form-note-text">{{
                                        'joinmeeting_page_or_joincall.callExperienceNote' | translate
                                    }}</span>
                                </div>
                            </form>

                            <div class="bottom-box" *ngIf="isCpass">
                                <span class="small-text">{{ 'joinmeeting_page_or_joincall.boxText' | translate }}</span>
                            </div>
                        </section>
                        <section *ngIf="loggedInOnly" class="err-scenario">
                            <h3>
                                {{ 'joinmeeting_page_or_joincall.please_login' | translate }}
                            </h3>
                            <div class="info my-3">
                                {{ 'joinmeeting_page_or_joincall.host_has_restricted' | translate }}
                            </div>
                            <div class="col p-0 w-100 mt-5 d-flex justify-content-center">
                                <button class="btn primary w-50 button-color" (click)="navigateToLogin()">
                                    <a class="text-white">{{ 'joinmeeting_page_or_joincall.signin' | translate }}</a>
                                </button>
                            </div>
                        </section>
                        <section *ngIf="collegueOnly" class="err-scenario">
                            <h3>{{ 'joinmeeting_page_or_joincall.sorry' | translate }}</h3>
                            <div class="info my-3">
                                {{ 'joinmeeting_page_or_joincall.restricted_organization' | translate }}
                            </div>
                            <div class="col p-0 w-100 mt-5 d-flex justify-content-center">
                                <button class="btn primary w-50 button-color" routerLink="/home">
                                    <a class="text-white">{{ 'joinmeeting_page_or_joincall.homepage' | translate }}</a>
                                </button>
                            </div>
                        </section>
                        <section *ngIf="joinBeforeHost" class="err-scenario">
                            <h3>{{ 'joinmeeting_page_or_joincall.waiting_for_host_to_start' | translate }}</h3>

                            <div class="loader my-5">
                                <app-loader></app-loader>
                            </div>
                        </section>
                        <section *ngIf="waitingRoomDetails?.waitingRoom" class="err-scenario">
                            <h3>{{ 'joinmeeting_page_or_joincall.please_wait_while' | translate }}</h3>
                            <div class="loader my-5">
                                <app-loader></app-loader>
                            </div>
                            <button type="button" class="btn primary" (click)="leaveWaitingRoom()">
                                {{ 'joinmeeting_page_or_joincall.leave' | translate }}
                            </button>
                        </section>
                        <app-restricted-meeting-join
                            *ngIf="showRestrictedMeetingJoinWarning"
                            (close)="toggleRestrictedMeetingJoinWarning()"
                        ></app-restricted-meeting-join>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="join-device-handler h-100" [hidden]="!hideDownloadsSection" *ngIf="checkMultiDeviceLogin">
        <section class="join-device-action">
            <div class="back left-content" (click)="checkMultiDeviceLogin = false">
                <div class="img-wrap mr-2">
                    <div class="img"></div>
                </div>
            </div>
            <div class="right-content">
                <h3 class="mb-4">{{ 'joinmeeting_page_or_joincall.multi_device_join_title' | translate }}</h3>
                <section class="action-list">
                    <div class="device-action" (click)="handleMultiDeviceLogin('add')">
                        <div class="img add-img mb-2"></div>
                        <h5>
                            {{ 'joinmeeting_page_or_joincall.add_device' | translate }}
                        </h5>
                        <p>{{ 'joinmeeting_page_or_joincall.add_device_desc' | translate }}</p>
                    </div>
                    <div class="device-action" (click)="handleMultiDeviceLogin('remove')">
                        <div class="img transfer-img mb-2"></div>
                        <h5>
                            {{ 'joinmeeting_page_or_joincall.transfer_call' | translate }}
                        </h5>
                        <p>{{ 'joinmeeting_page_or_joincall.transfer_call_desc' | translate }}</p>
                    </div>
                </section>
            </div>
        </section>
        <app-pre-loader *ngIf="isJoining"></app-pre-loader>
    </div>
</div>

<app-loader *ngIf="loading"></app-loader>

<div [class.mask]="loader"></div>
<app-pre-loader *ngIf="loader" [title]="'inside_call.call_component.joining' | translate"></app-pre-loader>

<app-permission-denied *ngIf="showPermissionUI && !isMobileDevice"></app-permission-denied>
<app-mic-video-disabled *ngIf="showPermissionUI && isMobileDevice"></app-mic-video-disabled>

<ng-template viewHost></ng-template>

<div *ngIf="iosMacDeeplink" [ngStyle]="{ visibility: 'hidden' }">
    <iframe title="" ref="iframe" [src]="iosMacDeeplink | safe"></iframe>
</div>

<app-online-offline-banner
    *ngIf="showBanner"
    [bannerInput]="bannerData"
    (hideBanner)="closeBanner($event)"
></app-online-offline-banner>
