export const GA_EVENTS = {
    JOIN_AS_GUEST: {
        action: 'Join as a Guest',
        category: 'Introduction',
        label: 'success'
    },
    SIGNIN_CLICKED: {
        action: 'Sign In Clicked',
        category: 'Introduction',
        label: 'success'
    },
    SIGNUP_CLICKED: {
        action: 'Sign Up Clicked',
        category: 'Introduction',
        label: 'success'
    },
    BLOG: {
        action: 'Blog',
        category: 'Introduction',
        label: 'success'
    },
    HELP_SECTION: {
        action: 'Help Section',
        category: 'Introduction',
        label: 'success'
    },
    DOWNLOAD_JIOMEET: {
        action: 'Download JioMeet',
        category: 'Introduction',
        label: 'success'
    },
    ENTERPRISE_REGISTRATION_INTEREST: {
        action: 'Enterprise Registration Interest',
        category: 'Introduction',
        label: 'success'
    },
    ENTERPRISE_REGISTERED: {
        action: 'Enterprise Registered',
        category: 'Introduction',
        label: 'success'
    },
    DOWNLOAD_ON_WINDOWS: {
        action: 'Download on Windows',
        category: 'Introduction',
        label: 'success'
    },
    DOWNLOAD_ON_APPSTORE: {
        action: 'Download on App Store',
        category: 'Introduction',
        label: 'success'
    },
    DOWNLOAD_ON_GOOGLEPLAY: {
        action: 'Download on Google Play',
        category: 'Introduction',
        label: 'success'
    },
    DOWNLOAD_ON_IOS: {
        action: 'Download on iOS',
        category: 'Introduction',
        label: 'success'
    },
    PRIVACY_POLICY: {
        action: 'Privacy Policy',
        category: 'Introduction',
        label: 'success'
    },
    TERMS_AND_CONDITIONS: {
        action: 'Terms & Conditions',
        category: 'Introduction',
        label: 'success'
    },
    RETAIL_SIGNUP_CLICKED_BANNER: {
        event: 'jiomeet',
        event_category: 'products_jiomeet',
        event_action: 'banner_sign_up',
        event_label: 'success'
    },
    RETAIL_DOWNLOADAPP_CLICKED_BANNER: {
        event: 'jiomeet',
        event_category: 'products_jiomeet',
        event_action: 'banner_download_app',
        event_label: 'success'
    },
    SIGNUP_CLICKED_COMMON: {
        event: 'jiomeet',
        event_category: 'Bottom',
        event_action: 'Bottom sign up',
        event_label: 'Success'
    },
    DOWNLOADAPP_CLICKED_COMMON: {
        event: 'jiomeet',
        event_category: 'Bottom',
        event_action: 'Bottom download app',
        event_label: 'Success'
    },
    CONTACT_SALES_COMMON: {
        event: 'jiomeet',
        event_category: 'Bottom',
        event_action: 'Bottom contact sales',
        event_label: 'Success'
    },
    CONTACTUS_CLICKED_COMMON: {
        event: 'jiomeet',
        event_category: 'Bottom',
        event_action: 'Bottom contact Us'
    },
    PRODUCT_CONTACT_SALES_PLATFORM: {
        event: 'jiomeet',
        event_category: 'products_jiomeetplatform',
        event_action: 'banner_contact_sales',
        event_label: 'success'
    },
    PRODUCT_CONTACT_SALES_PLATFORM_LEARNMORE: {
        event: 'jiomeet',
        event_category: 'products_jiomeetplatform',
        event_action: 'learn_more',
        event_label: 'success'
    },
    PRODUCT_SUBSCRIBE_COMMON: {
        event: 'jiomeet',
        event_category: 'Subscribe',
        event_action: 'Subscribe',
        event_label: 'Success'
    },
    PRODUCT_JIOEVENT_CONTACT_SALES: {
        event: 'jiomeet',
        event_category: 'products_jioevents',
        event_action: 'banner_contact_sales',
        event_label: 'success'
    },
    PRODUCT_JIOEVENT_EVENT_WEBSITE: {
        event: 'jiomeet',
        event_category: 'products_jioevents',
        event_action: 'go_to_jioevents_website',
        event_label: 'success'
    },
    PRODUCT_JIOEVENT_CONTACT_SALES_BOTTOM: {
        event: 'jiomeet',
        event_category: 'products_jioevents',
        event_action: 'bottom_contact_sales',
        event_label: 'success'
    },
    PRODUCT_DISCOVER_CONTACT_SALES: {
        event: 'jiomeet',
        event_category: 'Products_JioMeetDiscover',
        event_action: 'Banner contact sales',
        event_label: 'Success'
    },
    // Header events
    HEADER_WHATSAPPS: {
        event: 'jiomeet',
        event_category: 'JioMeet_Header',
        event_action: 'Whatsapp',
        event_label: 'Success'
    },
    HEADER_DOWNLOAD_APP: {
        event: 'jiomeet',
        event_category: 'JioMeet_Header',
        event_action: 'Download app',
        event_label: 'Success'
    },
    HEADER_HELP: {
        event: 'jiomeet',
        event_category: 'JioMeet_Header',
        event_action: 'Help',
        event_label: 'Success'
    },
    HEADER_SIGNIN: {
        event: 'jiomeet',
        event_category: 'JioMeet_Header',
        event_action: 'Sign in',
        event_label: 'Success'
    },
    HEADER_SIGNUP: {
        event: 'jiomeet',
        event_category: 'JioMeet_Header',
        event_action: 'Sign up',
        event_label: 'Success'
    },
    HEADER_JOIN_MEETING: {
        event: 'jiomeet',
        event_category: 'JioMeet_Header',
        event_action: 'Join meeting',
        event_label: 'Success'
    },
    HEADER_SET_LANG_EN: {
        event: 'jiomeet',
        event_category: 'JioMeet_Header',
        event_action: 'Language english',
        event_label: 'Success'
    },
    HEADER_SET_LANG_HI: {
        event: 'jiomeet',
        event_category: 'JioMeet_Header',
        event_action: 'Language hindi',
        event_label: 'Success'
    },
    HEADER_JIOMEET: {
        event: 'jiomeet',
        event_category: 'JioMeet_Header',
        event_action: 'JioMeet',
        event_label: 'Success'
    },
    HEADER_PRICING_MENU: {
        event: 'jiomeet',
        event_category: 'JioMeet_Header',
        event_action: 'Pricing',
        event_label: 'Success'
    },
    HEADER_CONTACTSALES_MENU: {
        event: 'jiomeet',
        event_category: 'JioMeet_Header',
        event_action: 'Sales',
        event_label: 'Success'
    },
    FOOTER_JIOMEET: {
        event: 'jiomeet',
        event_category: 'JioMeet_Footer',
        event_action: 'JioMeet',
        event_label: 'Success'
    },
    FOOTER_PRIVACY: {
        event: 'jiomeet',
        event_category: 'JioMeet_Footer',
        event_action: 'Privacy',
        event_label: 'Success'
    },
    FOOTER_TNC: {
        event: 'jiomeet',
        event_category: 'JioMeet_Footer',
        event_action: 'Tnc',
        event_label: 'Success'
    },
    HOME_START_MEETING_BEFORE_LOGIN: {
        event: 'jiomeet',
        event_category: 'JioMeet_Home',
        event_action: 'Start a meeting',
        event_label: 'Success'
    },
    HOME_JOIN_MEETING_BEFORE_LOGIN: {
        event: 'jiomeet',
        event_category: 'JioMeet_Home',
        event_action: 'Join meeting',
        event_label: 'Success'
    },
    HOME_PLAN_MEETING_BEFORE_LOGIN: {
        event: 'jiomeet',
        event_category: 'JioMeet_Home',
        event_action: 'Plan a meeting',
        event_label: 'Success'
    },
    HOME_MY_DASHBOARD: {
        event: 'jiomeet',
        event_category: 'JioMeet_Home',
        event_action: 'My dashboard',
        event_label: 'Success'
    },
    PRICING_CHANGE_PLAN: {
        event: 'jiomeet',
        event_category: 'Pricing',
        event_action: 'Monthly / Anually',
        event_label: 'Success'
    },
    PRICING_SIGN_UP: {
        event: 'jiomeet',
        event_category: 'Pricing',
        event_action: 'Free plan sign up',
        event_label: 'Success'
    },
    PRICING_CONTACT_SALES_PRO: {
        event: 'jiomeet',
        event_category: 'Pricing',
        event_action: 'Pro plan contact sales',
        event_label: 'Success'
    },
    PRICING_CONTACT_SALES_ENT: {
        event: 'jiomeet',
        event_category: 'Pricing',
        event_action: 'Enterprise pack contact sales',
        event_label: 'Success'
    },
    DASHBOARD_PLAN_MEETING: {
        event: 'jiomeet',
        event_category: 'Dashboard',
        event_action: 'Plan a meeting',
        event_label: 'Success'
    },
    DASHBOARD_CALANDER: {
        event: 'jiomeet',
        event_category: 'Dashboard',
        event_action: 'Calender',
        event_label: 'Success'
    },
    DASHBOARD_COPY_LINK: {
        event: 'jiomeet',
        event_category: 'Dashboard',
        event_action: 'Copy link',
        event_label: 'Success'
    },
    DASHBOARD_COPY_INVITATION: {
        event: 'jiomeet',
        event_category: 'Dashboard',
        event_action: 'Copy invitation',
        event_label: 'Success'
    },
    DASHBOARD_UPCOMING_MEETINGS: {
        event: 'jiomeet',
        event_category: 'Dashboard',
        event_action: 'Upcoming meetings',
        event_label: 'Success'
    },
    DASHBOARD_PREVIOUS_MEETINGS: {
        event: 'jiomeet',
        event_category: 'Dashboard',
        event_action: 'Previous meetings',
        event_label: 'Success'
    },
    DASHBOARD_SEND_INVITE: {
        event: 'jiomeet',
        event_category: 'Dashboard',
        event_action: 'Send Invite',
        event_label: 'Success'
    },
    DASHBOARD_DOWNLOAD_APP: {
        event: 'jiomeet',
        event_category: 'Dashboard',
        event_action: 'Download app',
        event_label: 'Success'
    },
    DASHBOARD_SETTINGS: {
        event: 'jiomeet',
        event_category: 'Dashboard',
        event_action: 'Settings',
        event_label: 'Success'
    },
    HEADER_MEETINGS: {
        event: 'jiomeet',
        event_category: 'Dashboard',
        event_action: 'Meetings',
        event_label: 'Success'
    },
    HEADER_CHATS: {
        event: 'jiomeet',
        event_category: 'Dashboard',
        event_action: 'Chats',
        event_label: 'Success'
    },
    HEADER_SEARCH: {
        event: 'jiomeet',
        event_category: 'Dashboard',
        event_action: 'Search',
        event_label: 'Success'
    },
    HEADER_PROFILE_ICON: {
        event: 'jiomeet',
        event_category: 'Dashboard',
        event_action: 'Icon',
        event_label: 'Success'
    },
    HEADER_MY_DASHBOARD: {
        event: 'jiomeet',
        event_category: 'Dashboard',
        event_action: 'My dashboard',
        event_label: 'Success'
    },
    HEADER_JIO_EVENTS: {
        event: 'jiomeet',
        event_category: 'Dashboard',
        event_action: 'Jioevents dashboard',
        event_label: 'Success'
    },
    HEADER_ADMIN_PORTAL: {
        event: 'jiomeet',
        event_category: 'Dashboard',
        event_action: 'Admin portal',
        event_label: 'Success'
    },
    HEADER_SIGNOUT: {
        event: 'jiomeet',
        event_category: 'Dashboard',
        event_action: 'Sign out'
    },
    USECASE_EDUCATION_SIGNUP: {
        event: 'jiomeet',
        event_category: 'UseCase_JioMeetEducation',
        event_action: 'Banner sign up',
        event_label: 'Success'
    },
    USECASE_EDUCATION_DOWNLOAD: {
        event: 'jiomeet',
        event_category: 'UseCase_JioMeetEducation',
        event_action: 'Banner download app',
        event_label: 'Success'
    },
    USECASE_HEALTHCARE_SIGNUP: {
        event: 'jiomeet',
        event_category: 'UseCase_JioMeetHealthCare',
        event_action: 'Banner sign up',
        event_label: 'Success'
    },
    USECASE_HEALTHCARE_DOWNLOAD: {
        event: 'jiomeet',
        event_category: 'UseCase_JioMeetHealthCare',
        event_action: 'Banner download app',
        event_label: 'Success'
    },
    USECASE_HYBRID_WORKPLACE_SIGNUP: {
        event: 'jiomeet',
        event_category: 'UseCase_JioMeetHybridWorkplace',
        event_action: 'Banner sign up',
        event_label: 'Success'
    },
    USECASE_HYBRID_WORKPLACE_DOWNLOAD: {
        event: 'jiomeet',
        event_category: 'UseCase_JioMeetHybridWorkplace',
        event_action: 'Banner download app',
        event_label: 'Success'
    },
    USECASE_PLATFORM_SERVICES_SIGNUP: {
        event: 'jiomeet',
        event_category: 'UseCase_JioMeetPlatformServices',
        event_action: 'Banner sign up',
        event_label: 'Success'
    },
    USECASE_PLATFORM_SERVICES_DOWNLOAD: {
        event: 'jiomeet',
        event_category: 'UseCase_JioMeetPlatformServices',
        event_action: 'Banner download app',
        event_label: 'Success'
    },
    USECASE_GOVERMENT_SECTOR_SIGNUP: {
        event: 'jiomeet',
        event_category: 'UseCase_JioMeetGovernmentSector',
        event_action: 'Banner sign up',
        event_label: 'Success'
    },
    USECASE_GOVERMENT_SECTOR_DOWNLOAD: {
        event: 'jiomeet',
        event_category: 'UseCase_JioMeetGovernmentSector',
        event_action: 'Banner download app',
        event_label: 'Success'
    },
    DOWNLOAD_WINDOWS: {
        event: 'jiomeet',
        event_category: 'DownloadJioMeet',
        event_action: 'Download windows',
        event_label: 'Success'
    },
    DOWNLOAD_MAC: {
        event: 'jiomeet',
        event_category: 'DownloadJioMeet',
        event_action: 'Download mac',
        event_label: 'Success'
    },
    DOWNLOAD_ANDROID: {
        event: 'jiomeet',
        event_category: 'DownloadJioMeet',
        event_action: 'Download android',
        event_label: 'Success'
    },
    DOWNLOAD_IOS: {
        event: 'jiomeet',
        event_category: 'DownloadJioMeet',
        event_action: 'Download ios',
        event_label: 'Success'
    },
    OPEN_IN_BROWSER: {
        event: 'jiomeet',
        event_category: 'DownloadJioMeet',
        event_action: 'Open in browser',
        event_label: 'Success'
    },
    DOWNLOAD_JIOMEET_TEAMS: {
        event: 'jiomeet',
        event_category: 'DownloadJioMeet',
        event_action: 'Download jiomeet teams',
        event_label: 'Success'
    },
    DOWNLOAD_JIOMEET_OUTLOOK: {
        event: 'jiomeet',
        event_category: 'DownloadJioMeet',
        event_action: 'Download jiomeet outlook',
        event_label: 'Success'
    },
    SUBSCRIBE_JIOMEET_DOWNLOAD: {
        event: 'jiomeet',
        event_category: 'DownloadJioMeet',
        event_action: 'Subscribe',
        event_label: 'Success'
    },
    PRICING_SIGNUP_FOR_FREE_MONTHLY: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'standard_sign_up_for_free',
        event_label: 'monthly'
    },
    PRICING_SIGNUP_FOR_FREE_ANNUALLY: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'standard_sign_up_for_free',
        event_label: 'annually'
    },
    PRICING_SMALL_BUSINESS_REQUEST_QUOTE_MONTHLY: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'small_business_request_a_quote',
        event_label: 'monthly'
    },
    PRICING_SMALL_BUSINESS_REQUEST_QUOTE_ANNUALLY: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'small_business_request_a_quote',
        event_label: 'annually'
    },
    PRICING_ENTERPRISE_REQUEST_QUOTE_MONTHLY: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'enterprise_request_a_quote',
        event_label: 'monthly'
    },
    PRICING_ENTERPRISE_REQUEST_QUOTE_ANNUALLY: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'enterprise_request_a_quote',
        event_label: 'annually'
    },
    PRICING_CUSTOM_REQUEST_QUOTE_MONTHLY: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'custom_request_a_quote',
        event_label: 'monthly'
    },
    PRICING_CUSTOM_REQUEST_QUOTE_ANNUALLY: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'custom_request_a_quote',
        event_label: 'annually'
    },
    PRICING_FULL_PLAN_SEE_COMPARISON_SUCCESS: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'see_full_plan_comparison',
        event_label: 'success'
    },
    PRICING_FULL_PLAN_SEE_COMPARISON_FAILURE: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'see_full_plan_comparison',
        event_label: 'failure'
    },
    PRICING_SIGNUP_FOR_FREE_SUCCESS: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'standard_sign_up_for_free',
        event_label: 'success'
    },
    PRICING_SIGNUP_FOR_FREE_FAILURE: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'standard_sign_up_for_free',
        event_label: 'failure'
    },
    PRICING_SMALL_BUSINESS_REQUEST_QUOTE_SUCCESS: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'small_business_request_a_quote_bottom',
        event_label: 'success'
    },
    PRICING_SMALL_BUSINESS_REQUEST_QUOTE_FAILURE: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'small_business_request_a_quote_bottom',
        event_label: 'failure'
    },
    PRICING_ENTERPRISE_REQUEST_QUOTE_SUCCESS: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'enterprise_request_a_quote_bottom',
        event_label: 'success'
    },
    PRICING_ENTERPRISE_REQUEST_QUOTE_FAILURE: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'enterprise_request_a_quote_bottom',
        event_label: 'failure'
    },
    PRICING_CUSTOM_REQUEST_QUOTE_SUCCESS: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'custom_request_a_quote_bottom',
        event_label: 'success'
    },
    PRICING_CUSTOM_REQUEST_QUOTE_FAILURE: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'custom_request_a_quote_bottom',
        event_label: 'failure'
    },
    PRICING_CLOUD_STORAGE_REQUEST_SUCCESS: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'cloud_storage_request_a_quote',
        event_label: 'success'
    },
    PRICING_CLOUD_STORAGE_REQUEST_FAILURE: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'cloud_storage_request_a_quote',
        event_label: 'failure'
    },
    PRICING_CONFERENCE_ROOMS_REQUEST_SUCCESS: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'conference_rooms_request_a_quote',
        event_label: 'success'
    },
    PRICING_CONFERENCE_ROOMS_REQUEST_FAILURE: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'conference_rooms_request_a_quote',
        event_label: 'failure'
    },
    PRICING_100_PARTICIPANTS_REQUEST_SUCCESS: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: '100_participants_request_a_quote',
        event_label: 'success'
    },
    PRICING_100_PARTICIPANTS_REQUEST_FAILURE: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: '100_participants_request_a_quote',
        event_label: 'failure'
    },
    PRICING_LIVE_STREAMING_REQUEST_SUCCESS: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'live_streaming_request_a_quote',
        event_label: 'success'
    },
    PRICING_LIVE_STREAMING_REQUEST_FAILURE: {
        event: 'jiomeet',
        event_category: 'pricing',
        event_action: 'live_streaming_request_a_quote',
        event_label: 'failure'
    },
    //for enterprise before login
    PRODUCT_ENTERPRISE_SUBSCRIBE: {
        event: 'jiomeet',
        event_category: 'products_jiomeetenterprise',
        event_action: 'subscribe',
        event_label: 'success'
    },
    OUTLOOK_ADD_IN: {
        event: 'jiomeet',
        event_category: 'products_jiomeetenterprise',
        event_action: 'outlook_add_in',
        event_label: 'success'
    },
    PERSONALISED_VIRTUAL_BACKGROUND: {
        event: 'jiomeet',
        event_category: 'products_jiomeetenterprise',
        event_action: 'personalised_virtual_background',
        event_label: 'success'
    },
    VIEW_YOUR_MEETING_RECORDING: {
        event: 'jiomeet',
        event_category: 'products_jiomeetenterprise',
        event_action: 'view_your_meeting_recording',
        event_label: 'success'
    },
    CREATE_GROUPS_WITH_ALL_USER_TYPES: {
        event: 'jiomeet',
        event_category: 'products_jiomeetenterprise',
        event_action: 'create_groups_with_all_usertypes',
        event_label: 'success'
    },
    CALL_CHAT_BY_NON_ENTERPRISE_USER: {
        event: 'jiomeet',
        event_category: 'products_jiomeetenterprise',
        event_action: 'call_chat_by_nonenterprise_users',
        event_label: 'success'
    },
    SET_CUSTOM_RULES_FOR_FILE_SHARING: {
        event: 'jiomeet',
        event_category: 'products_jiomeetenterprise',
        event_action: 'set_custom_rules_for_file_sharing',
        event_label: 'success'
    },
    LEGACY_VC_ROOM_THROUGH_VPN: {
        event: 'jiomeet',
        event_category: 'products_jiomeetenterprise',
        event_action: 'legacy_vc_room_through_vpn',
        event_label: 'success'
    },
    MORE_MEETING_PARTICIPANTS: {
        event: 'jiomeet',
        event_category: 'products_jiomeetenterprise',
        event_action: 'more_meeting_participants',
        event_label: 'success'
    },
    JIOMEET_BOT_FOR_MICROSOFT_TEAMS: {
        event: 'jiomeet',
        event_category: 'products_jiomeetenterprise',
        event_action: 'jiomeet_bot_for_microsoft_teams',
        event_label: 'success'
    },
    WHITE_LABELLING: {
        event: 'jiomeet',
        event_category: 'products_jiomeetenterprise',
        event_action: 'white_labelling',
        event_label: 'success'
    },
    EVENTS_ONLY_FOR_YOUR_ORGANISATION: {
        event: 'jiomeet',
        event_category: 'products_jiomeetenterprise',
        event_action: 'events_only_for_your_organisation',
        event_label: 'success'
    },
    CPASS_OFFERING: {
        event: 'jiomeet',
        event_category: 'products_jiomeetenterprise',
        event_action: 'cpaas_offering',
        event_label: 'success'
    },
    MEGHRAJ_CERTIFIED_DATACENTERS: {
        event: 'jiomeet',
        event_category: 'products_jiomeetenterprise',
        event_action: 'meghraj_certified_datacenters',
        event_label: 'success'
    },
    STQC_CERTIFIED: {
        event: 'jiomeet',
        event_category: 'products_jiomeetenterprise',
        event_action: 'stqc_certified',
        event_label: 'success'
    },
    JIOEVENTS_DASHBOARD: {
        event: 'jiomeet',
        event_category: 'products_jioevents',
        event_action: 'jioevents_dashboard',
        event_label: 'success'
    },
    CREATE_EVENTS: {
        event: 'jiomeet',
        event_category: 'products_jioevents',
        event_action: 'create_events',
        event_label: 'success'
    },
    HOST_UPTO_10K_ATTENDEES: {
        event: 'jiomeet',
        event_category: 'products_jioevents',
        event_action: 'host_upto_10k_attendees',
        event_label: 'success'
    },
    QNA_COMMENTS_REACTIONS: {
        event: 'jiomeet',
        event_category: 'products_jioevents',
        event_action: 'qna_comments_reactions',
        event_label: 'success'
    },
    STREAM_ON_YOUTUBE_FACEBOOK: {
        event: 'jiomeet',
        event_category: 'products_jioevents',
        event_action: 'stream_on_youtube_facebook',
        event_label: 'success'
    },
    CUSTOMISE_AND_BRAND_EVENTS: {
        event: 'jiomeet',
        event_category: 'products_jioevents',
        event_action: 'customise_and_brand_events',
        event_label: 'success'
    },
    UPLOAD_AND_PLAY_CLOUD_VIDEO: {
        event: 'jiomeet',
        event_category: 'products_jioevents',
        event_action: 'upload_and_play_cloud_video',
        event_label: 'success'
    },
    DOWNLOAD_EVENT_REPORT: {
        event: 'jiomeet',
        event_category: 'products_jioevents',
        event_action: 'download_event_report',
        event_label: 'success'
    },
    PRODUCT_JIOMEET_SUBSCRIBE: {
        event: 'jiomeet',
        event_category: 'products_jiomeet',
        event_action: 'subscribe',
        event_label: 'success'
    },
    PRODUCT_JIOMEET_BOTTOM_SIGN_UP: {
        event: 'jiomeet',
        event_category: 'products_jiomeet',
        event_action: 'bottom_sign_up',
        event_label: 'success'
    },
    PRODUCT_JIOMEET_BOTTOM_DOWNLOAD_APP: {
        event: 'jiomeet',
        event_category: 'products_jiomeet',
        event_action: 'bottom_download_app',
        event_label: 'success'
    },
    PRODUCT_PLATFORM_SUBSCRIBE: {
        event: 'jiomeet',
        event_category: 'products_jiomeetplatform',
        event_action: 'subscribe',
        event_label: 'success'
    },
    SIMPLE_API_INTEGRATION: {
        event: 'jiomeet',
        event_category: 'products_jiomeetplatform',
        event_action: 'simple_api_integration',
        event_label: 'success'
    },
    SDK_INTEGRATION: {
        event: 'jiomeet',
        event_category: 'products_jiomeetplatform',
        event_action: 'sdk_integration',
        event_label: 'success'
    },
    HEALTHCARE_SECTOR: {
        event: 'jiomeet',
        event_category: 'products_jiomeetplatform',
        event_action: 'healthcare_sector',
        event_label: 'success'
    },
    EDUCATION_SECTOR: {
        event: 'jiomeet',
        event_category: 'products_jiomeetplatform',
        event_action: 'education_sector',
        event_label: 'success'
    },
    PRODUCT_ENTERPRISE_BOTTOM_CONTACT_SALES: {
        event: 'jiomeet',
        event_category: 'products_jiomeetenterprise',
        event_action: 'bottom_contact_sales',
        event_label: 'success'
    },
    PRODUCT_PLATFORM_BOTTOM_CONTACT_SALES: {
        event: 'jiomeet',
        event_category: 'products_jiomeetplatform',
        event_action: 'bottom_contact_sales',
        event_label: 'success'
    }
};
