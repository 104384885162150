<div class="pmisettings-container">
    <form class="custom-form" [formGroup]="form" (submit)="save()">
        <div class="form-group">
            <label tabindex="0" aria-label="Personal Meeting ID" class="PMIfocus">{{
                'user_dashboard_settings.pmi.personal_meeting_id' | translate
            }}</label>
            <input
                type="text"
                maxlength="100"
                placeholder="{{ 'user_dashboard_settings.pmi.personal_meeting_id' | translate }}"
                [value]="user.jiomeetId | meetingidFormat"
                readonly
            />
        </div>
        <div class="form-group">
            <label>{{ 'user_dashboard_settings.pmi.password' | translate }}</label>
            <div class="d-flex align-items-center">
                <input
                    placeholder="{{ 'user_dashboard_settings.pmi.password' | translate }}"
                    type="text"
                    [(ngModel)]="user.roomPIN"
                    [ngModelOptions]="{ standalone: true }"
                    maxlength="5"
                    (ngModelChange)="form.markAsDirty()"
                />
                <div
                    class="ml-3 pointer"
                    (click)="refreshRoomPin()"
                    tabindex="0"
                    aria-label="Refresh pin"
                    role="button"
                    (keydown.Enter)="refreshRoomPin()"
                >
                    <img class="pointer" src="assets/img/pin_refresh_icn.svg" alt="" />
                </div>
            </div>
        </div>
        <!-- <hr class="divider mt-4 mb-4" />
            <h3 class="sub-heading mb-3">Video</h3>
            <div class="form-group d-flex">
              <div class="w-50 pr-4">
                <label>Host</label>
                <div class="d-flex justify-content-start my-3">
                  <label for="hostVideoOn" class="pointer">
                    <input type="radio" formControlName="hostVideo" name="hostVideo" id="hostVideoOn" [value]="true" />
                    <span>On</span>
                  </label>
                  <label for="hostVideoOff" class="ml-3 pointer">
                    <input type="radio" formControlName="hostVideo" name="hostVideo" id="hostVideoOff" [value]="false" />
                    <span>Off</span>
                  </label>
                </div>
              </div>
              <div class="w-50 pr-4">
                <label>Participants</label>
                <div class="d-flex justify-content-start my-3">
                  <label for="participantsVideoOn" class="pointer">
                    <input type="radio" formControlName="participantVideo" name="participantVideo" id="participantsVideoOn" [value]="true" />
                    <span>On</span>
                  </label>
                  <label for="participantsVideoOff" class="ml-3 pointer">
                    <input type="radio" formControlName="participantVideo" name="participantVideo" id="participantsVideoOff" [value]="false" />
                    <span>Off</span>
                  </label>
                </div>
              </div>
            </div> -->
        <hr class="divider mt-4 mb-4" />
        <h4
            class="sub-heading mb-3 collapsible"
            [class.collapsed]="advancedOptionsCollapsed"
            (click)="toggleAdvancedOptions()"
            tabindex="0"
        >
            {{ 'planmeeting_popup.meeting_settings' | translate }}
        </h4>
        <div *ngIf="!advancedOptionsCollapsed" class="ml-3">
            <fieldset>
                <legend>{{ 'planmeeting_popup.meeting_security' | translate }}</legend>
                <div class="checkbox-wrapper" *ngIf="!user?.isOTT">
                    <div class="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            id="defaultUnchecked4"
                            formControlName="colleagueOnly"
                            (click)="gTagPMISettings($event)"
                            (keydown.enter)="onAdvancedMeetingOptionsEnterClicked($event)"
                            tabindex="0"
                            class="checkbox-width"
                        />
                        <label class="custom-control-label pointer" for="defaultUnchecked4">
                            {{ 'planmeeting_popup.myorganization' | translate }}
                        </label>
                    </div>
                    <div class="help" tabindex="0">
                        {{ 'planmeeting_popup.myorganizationhelp' | translate }}
                    </div>
                </div>
                <div class="checkbox-wrapper">
                    <div class="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            id="defaultUnchecked2"
                            formControlName="loggedInOnly"
                            (click)="gTagPMISettings($event)"
                            (keydown.enter)="onAdvancedMeetingOptionsEnterClicked($event)"
                            tabindex="0"
                            class="checkbox-width"
                        />
                        <label class="custom-control-label pointer" for="defaultUnchecked2">
                            {{ 'planmeeting_popup.noguest' | translate }}
                        </label>
                    </div>
                    <div class="help" tabindex="0">
                        {{ 'planmeeting_popup.noguesthelp' | translate }}
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <legend>{{ 'planmeeting_popup.advancedoptions' | translate }}</legend>
                <div class="checkbox-wrapper">
                    <div class="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            id="defaultUnchecked3"
                            formControlName="waitingRoom"
                            (click)="gTagPMISettings($event)"
                            (keydown.enter)="onAdvancedMeetingOptionsEnterClicked($event)"
                            tabindex="0"
                            class="checkbox-width"
                        />
                        <label class="custom-control-label pointer" for="defaultUnchecked3">{{
                            'planmeeting_popup.enablewaitingroom' | translate
                        }}</label>
                    </div>
                    <div class="help" tabindex="0">
                        {{ 'planmeeting_popup.admit' | translate }}
                    </div>
                </div>
                <div class="checkbox-wrapper">
                    <div class="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            id="defaultUnchecked5"
                            formControlName="joinBeforeHost"
                            (click)="gTagPMISettings($event)"
                            (keydown.enter)="onAdvancedMeetingOptionsEnterClicked($event)"
                            tabindex="0"
                            class="checkbox-width"
                        />
                        <label class="custom-control-label pointer" for="defaultUnchecked5">{{
                            'planmeeting_popup.allowbeforehost' | translate
                        }}</label>
                    </div>
                    <div class="help" tabindex="0">{{ 'planmeeting_popup.allowbeforehosthelp' | translate }}</div>
                </div>
                <div class="checkbox-wrapper">
                    <div class="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            id="defaultUnchecked6"
                            formControlName="participantHardAudioMute"
                            (click)="gTagPMISettings($event)"
                            (keydown.enter)="onAdvancedMeetingOptionsEnterClicked($event)"
                            tabindex="0"
                            class="checkbox-width"
                        />
                        <label class="custom-control-label pointer" for="defaultUnchecked6">{{
                            'planmeeting_popup.forcemute' | translate
                        }}</label>
                    </div>
                    <div class="help" tabindex="0">
                        {{ 'planmeeting_popup.forcemutehelp' | translate }}
                    </div>
                </div>
                <div class="checkbox-wrapper">
                    <div class="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            id="defaultUnchecked7"
                            formControlName="participantAudio"
                            (click)="gTagPMISettings($event)"
                            (keydown.enter)="onAdvancedMeetingOptionsEnterClicked($event)"
                            tabindex="0"
                            class="checkbox-width"
                        />
                        <label class="custom-control-label pointer" for="defaultUnchecked7">{{
                            'planmeeting_popup.softmute' | translate
                        }}</label>
                    </div>
                    <div class="help" tabindex="0">{{ 'planmeeting_popup.softmutehelp' | translate }}</div>
                </div>
            </fieldset>
        </div>

        <div class="text-error my-3" *ngIf="errors.global">{{ errors.global }}</div>

        <div class="form-group d-flex justify-content-end mt-5 mr-5">
            <!-- <button type="button" class="custom-button bg-secondary mr-3" (click)="navigateToDashboard()">Cancel</button> -->
            <button
                type="submit"
                tabindex="0"
                (keydown.Tab)="setFocusOnElement()"
                class="custom-button bg-primary"
                [class.disabled]="!form.valid || user?.roomPIN?.length !== 5 || !form.dirty || disableSave"
                (click)="saveButtonClicked()"
            >
                {{ 'user_dashboard_settings.save' | translate }}
            </button>
        </div>
    </form>
</div>
