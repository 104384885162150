<div class="server-error-page d-flex">
    <div class="error-message" style="width: 30%">
        <div class="error-conatiner w-100 text-center">
            <h2>Sorry!!!</h2>
            <p class="message">We are down for scheduled maintenance right now.</p>
        </div>
    </div>
    <div class="error-image" style="width: 70%">
        <img alt="" src="assets/images/server-error.png" style="width: 100%" />
    </div>
</div>
