<div class="website-footer" *ngIf="!isMobile">
    <div class="footer-wrap">
        <main class="megamenu-submenu mb-3">
            <div *ngFor="let menuGroup of footerMenues">
                <label *ngIf="menuGroup?.label" tabindex="0">
                    {{ menuGroup?.tlabel | translate }}
                </label>
                <ng-container *ngFor="let menuInfo of menuGroup?.data">
                    <a
                        *ngIf="menuInfo.path && !menuInfo.isExternal"
                        (keydown.enter)="handleGoTo(menuInfo)"
                        (click)="handleGoTo(menuInfo)"
                        ><div class="sub-title pointer" role="link" type="link" tabindex="0">
                            {{ menuInfo?.ttitle | translate }}
                        </div></a
                    >
                    <a *ngIf="menuInfo.path && menuInfo.isExternal" target="_blank" (click)="handleGoTo(menuInfo)"
                        ><div class="sub-title pointer" role="link" type="link" tabindex="0">
                            {{ menuInfo?.ttitle | translate }}
                        </div></a
                    >
                    <div class="sub-title not-active" *ngIf="!menuInfo.path">
                        {{ menuInfo?.ttitle | translate }}
                    </div>
                </ng-container>
            </div>
        </main>
        <div class="divider"></div>
        <div class="footer-bottom">
            <div class="left-content">
                <div class="pointer">
                    <img
                        src="assets/img/logo/meet-logo-footer.svg"
                        tabindex="0"
                        aria-label="Navigate to JioMeet page"
                        (keyup.enter)="goToMain()"
                        (click)="goToMain()"
                        alt=""
                    />
                </div>
                <div class="text" tabindex="0">{{ 'website_footer.smarter_way_collaborate' | translate }}</div>
            </div>
            <div class="right-content">
                <div class="follow">
                    <span class="follow-info">
                        <span class="follow-text">{{ 'website_footer.follow_us_on' | translate }}</span>
                        <span class="logos-container">
                            <span
                                ><a
                                    tabindex="0"
                                    role="link"
                                    aria-label="Follow us on Facebook"
                                    href="https://www.facebook.com/myjiomeet"
                                    target="_blank"
                                    (click)="goToSocialPlatform('Facebook')"
                                    ><img src="assets/img/website/fb-footer.svg" alt=""
                                /></a>
                            </span>
                            <span
                                ><a
                                    tabindex="0"
                                    role="link"
                                    aria-label="Follow us on LinkedIn"
                                    href="https://www.linkedin.com/company/myjiomeet/?viewAsMember=true"
                                    target="_blank"
                                    (click)="goToSocialPlatform('LinkedIn')"
                                    ><img src="assets/img/website/linkedin-footer.svg" alt="" /></a
                            ></span>
                            <span
                                ><a
                                    tabindex="0"
                                    role="link"
                                    aria-label="Follow us on Twitter"
                                    href="https://twitter.com/jiomeet"
                                    target="_blank"
                                    (click)="goToSocialPlatform('Twitter')"
                                    ><img src="assets/img/website/twitter-footer.svg" alt="" /></a
                            ></span>
                            <span
                                ><a
                                    tabindex="0"
                                    role="link"
                                    aria-label="Follow us on Youtube"
                                    href="https://www.youtube.com/channel/UCqbAsISN6KG8ZXJReqUZt1A"
                                    target="_blank"
                                    (click)="goToSocialPlatform('Youtube')"
                                    ><img src="assets/img/website/youtube.svg" alt="" /></a
                            ></span>
                            <span
                                ><a
                                    tabindex="0"
                                    role="link"
                                    aria-label="Follow us on Instagram"
                                    href="https://www.instagram.com/myjiomeet"
                                    target="_blank"
                                    (click)="goToSocialPlatform('Instagram')"
                                    ><img src="assets/img/website/instagram.svg" alt="" /></a
                            ></span>
                        </span>
                    </span>
                </div>
                <div class="privacy-copyright">
                    <div class="privacy-text">
                        <span
                            (click)="gotopp()"
                            aria-label="please press enter to navigate to privacy"
                            (keydown.enter)="gotopp()"
                            class="pointer"
                            tabindex="0"
                            role="link"
                            >{{ 'website_footer.privacy' | translate }}</span
                        ><span>|</span
                        ><span
                            (click)="gototnc()"
                            aria-label="please press enter to navigate to Terms and Conditions"
                            (keydown.enter)="gototnc()"
                            class="pointer"
                            tabindex="0"
                            role="link"
                            >{{ 'website_footer.tnc' | translate }}</span
                        >
                    </div>
                    <span class="copyright-text" tabindex="0"
                        >{{ 'website_footer.copyrights' | translate : { value: currentYear } }}
                        {{ 'website_footer.all_rights_reserved' | translate }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="website-footer website-footer-mob" *ngIf="isMobile">
    <div class="footer-wrap">
        <app-menu-accordion [accordionData]="footerMenues" menuPos="footer"></app-menu-accordion>
        <div class="footer-bottom d-block">
            <div class="follow">
                <span class="follow-text">{{ 'website_footer.follow_us_on' | translate }}</span>
                <span class="left-img-container">
                    <span
                        ><a href="https://www.facebook.com/myjiomeet" target="_blank"
                            ><img src="assets/img/website/fb-footer.svg" alt=""
                        /></a>
                    </span>
                    <span
                        ><a href="https://www.linkedin.com/company/myjiomeet/?viewAsMember=true" target="_blank"
                            ><img src="assets/img/website/linkedin-footer.svg" alt="" /></a
                    ></span>
                    <span
                        ><a href="https://twitter.com/jiomeet" target="_blank"
                            ><img src="assets/img/website/twitter-footer.svg" alt="" /></a
                    ></span>
                    <span
                        ><a href="https://www.youtube.com/channel/UCqbAsISN6KG8ZXJReqUZt1A" target="_blank"
                            ><img src="assets/img/website/youtube.svg" alt="" /></a
                    ></span>
                    <span
                        ><a href="https://www.instagram.com/myjiomeet" target="_blank"
                            ><img src="assets/img/website/instagram.svg" alt="" /></a
                    ></span>
                </span>
            </div>
            <div class="logo-info text-center">
                <div class="pointer">
                    <img
                        src="assets/img/logo/meet-logo-footer-mob.svg"
                        tabindex="0"
                        aria-label="Navigate to JioMeet main page"
                        (keyup.enter)="goToHome()"
                        (click)="goToHome()"
                        alt=""
                    />
                </div>
                <div class="text">{{ 'website_footer.smarter_way_collaborate' | translate }}</div>
            </div>
            <div class="privacy-copyright">
                <div class="privacy-text">
                    <span (click)="gotopp()" class="pointer">{{ 'website_footer.privacy' | translate }}</span
                    ><span>|</span
                    ><span (click)="gototnc()" class="pointer">{{ 'website_footer.tnc' | translate }}</span>
                </div>
                <div class="copyright-text">
                    <span>{{ 'website_footer.copyrights' | translate : { value: currentYear } }}</span>
                    <span>{{ 'website_footer.all_rights_reserved' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
