import { Injectable } from '@angular/core';
import { addDays } from 'date-fns/esm';
import { LocalStorageService , DesktopAppService} from './';

@Injectable({
    providedIn: 'root'
})
export class RememberMeService {
    constructor(
        private localStorageService: LocalStorageService,
        private desktopAppService: DesktopAppService
    ) {}
    set(cookieName, value) {
      if(this.desktopAppService.isDesktopApp)
       this.localStorageService.addItem(cookieName, value);
      else
       this.cookieSet(cookieName,value);
    }

    get(cookieName) {
       if(this.desktopAppService.isDesktopApp)
       return this.localStorageService.getItem(cookieName);
       else
        return this.cookieGet(cookieName);
    }

    remove(cookieName) {
       if(this.desktopAppService.isDesktopApp)
        this.localStorageService.removeItem(cookieName);
       else
        this.cookieRemove(cookieName);
    }

    private cookieSet(cookieName, value)
    {
        if (this.cookieGet(cookieName)) {
            this.remove(cookieName);
        }
        document.cookie = `${cookieName}=${JSON.stringify(value)}; expires=${addDays(
            new Date(),
            30
        )}; path=/; secure; domain=${location.hostname};`;
    }

    private cookieGet(cookieName)
    {
        const name = cookieName + '=';
        const cookies = document.cookie.split(';');
        for (let cookie of cookies) {
            cookie = cookie.replace(/^\s+/g, '');
            if (cookie.indexOf(cookieName) === 0) {
                return JSON.parse(cookie.substring(name.length, cookie.length));
            }
        }
        return '';
    }

    private cookieRemove(cookieName)
    {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure; domain=${location.hostname};`;
    }
}
