import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
    ValidationService,
    UserService,
    AuthService,
    RtcService,
    CallerService,
    AppService,
    UtilService
} from 'src/app/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GaDiagnosticService } from 'src/app/core/services/ga-diagnostic.service';
import { DiagnosticService } from 'src/app/core/services/diagnostic.service';
// import { ConferenceWebinarService } from 'src/app/webinar';
import { GuestAuthService } from 'src/app/core/services/guest-auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-cluster-load-test',
    templateUrl: './cluster-load-test.component.html',
    styleUrls: ['./cluster-load-test.component.scss']
})
export class ClusterLoadTestComponent implements OnInit {
    namePinForm: FormGroup;
    errors: any = {};
    meetingId;
    hash;
    pwd;
    isPinEnabled = false;
    isAuthenticated;
    currentUser: any = {};
    name: string;
    autoJoin: boolean;
    hideMoreCallControls: boolean;
    hideAllCallControls: boolean;
    isFullScreenShare: boolean;

    working = false;
    valid = true;
    loader = true;

    loggedInOnly = false;
    CollegueOnly = false;
    joinBeforeHost = false;
    waitingRoomDetails: any;
    captchaDetails: any = {};
    showCaptcha = false;
    interval;

    roomID;
    roomkey;
    vpin;
    room_url;

    constructor(
        private fb: FormBuilder,
        private validdatorService: ValidationService,
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private authService: AuthService,
        private rtcService: RtcService,
        private callerService: CallerService,
        private appService: AppService,
        private toastrService: ToastrService,
        private router: Router,
        private gaService: GaDiagnosticService,
        private utilService: UtilService,
        private diagnosticService: DiagnosticService,
        // private conferenceWebinarService: ConferenceWebinarService,
        private guestAuthService: GuestAuthService,
        private translateService: TranslateService
    ) {
        this.autoJoin = false;
        this.hideMoreCallControls = false;
        this.hideAllCallControls = false;
        this.isFullScreenShare = false;
    }

    ngOnInit() {
        this.isAuthenticated = this.authService.getIsAuthenticated() || false;
        this.namePinForm = this.fb.group({
            name: [
                null,
                [
                    Validators.required,
                    this.validdatorService.noWhitespaceValidator,
                    Validators.pattern('^[a-zA-Z0-9 ]*$')
                ]
            ],
            pin: [null, []],
            captchaText: [null, []]
        });
        this.fetchParams();
        if (this.name) {
            this.namePinForm.patchValue({ name: this.name });
        }
        if (this.pwd) {
            this.namePinForm.patchValue({ pin: this.pwd });
        }
        if (this.isAuthenticated) {
            this.userService.getUser().subscribe((user) => {
                this.currentUser = user;
                this.namePinForm.patchValue({ name: `${user.name} ${user.lname ? user.lname : ''}` });
                this.checkMeetingSecurity();
            });
        } else {
            this.checkMeetingSecurity();
        }

        if (!this.roomID) {
            this.joinMeeting();
        } else {
            this.joinCluster();
        }

        this.gaService.sendPageView({
            page_title: 'meeting_name_pin_page',
            page_path: '/guest'
        });
    }

    checkMeetingSecurity() {
        this.rtcService
            .isValidRoomAndPinFlag({
                extension: this.meetingId,
                hash: this.hash,
                userId: this.currentUser._id
            })
            .subscribe(
                (res: any) => {
                    this.isPinEnabled = res.isHost ? false : res.isPinEnabled;
                    if (this.isPinEnabled && this.pwd) {
                        this.namePinForm.patchValue({ pin: this.pwd });
                    }
                    this.loader = false;
                },
                (err) => {
                    this.errors.global = err.error.message;
                    this.valid = false;
                }
            );
    }

    fetchParams() {
        this.meetingId = this.activatedRoute.snapshot.queryParams.meetingId;
        this.hash = this.activatedRoute.snapshot.queryParams.hash;
        this.pwd = this.activatedRoute.snapshot.queryParams.pwd;
        this.name = this.activatedRoute.snapshot.queryParams.name;
        this.autoJoin = this.activatedRoute.snapshot.queryParams.autoJoin === 'true' ? true : false;
        this.hideMoreCallControls =
            this.activatedRoute.snapshot.queryParams.hideMoreCallControls === 'true' ? true : false;
        this.hideAllCallControls =
            this.activatedRoute.snapshot.queryParams.hideAllCallControls === 'true' ? true : false;
        this.isFullScreenShare = this.activatedRoute.snapshot.queryParams.isFullScreenShare === 'true' ? true : false;
        this.roomID = this.activatedRoute.snapshot.queryParams.roomID;
        this.roomkey = this.activatedRoute.snapshot.queryParams.roomkey;
        this.vpin = this.activatedRoute.snapshot.queryParams.vpin;
        this.room_url = this.activatedRoute.snapshot.queryParams.room_url;
    }

    validateMeetingPin() {
        if (!this.namePinForm.get('name').valid) {
            this.errors.global = 'Please enter a valid Guest Name';
            return false;
        }

        if (
            this.isPinEnabled &&
            !(
                /[a-z]/.test(this.namePinForm.value.pin) &&
                /[A-Z]/.test(this.namePinForm.value.pin) &&
                /[0-9]/.test(this.namePinForm.value.pin) &&
                !this.namePinForm.value.pin.includes('I') &&
                !this.namePinForm.value.pin.includes('l') &&
                !this.namePinForm.value.pin.includes('o') &&
                !this.namePinForm.value.pin.includes('O') &&
                !this.namePinForm.value.pin.includes('0') &&
                /^[0-9a-zA-Z]+$/.test(this.namePinForm.value.pin) &&
                this.namePinForm.value.pin.length === 5
            )
        ) {
            this.errors.global = 'Password verification failed.';
            return false;
        }
        return true;
    }

    joinCluster() {
        this.joinClusterAs();
    }

    joinClusterAs() {
        this.guestAuthService
            .login({
                jiomeetId: this.meetingId,
                roomID: this.roomID,
                roomPin: this.pwd,
                name:
                    this.namePinForm.value.name.length <= 25
                        ? this.namePinForm.value.name
                        : this.namePinForm.value.name.substr(0, 25) + '...',
                phoneNo: '',
                emailId: '',
                recorderToken: ''
            })
            .subscribe((res) => {
                this.userService.isGuestLogin(true);
                this.userService.setGuestUser(name);
                this.rtcService.setConferenceInfo({
                    isInitiater: false,
                    joiningName: this.namePinForm.value.name.trim() + ' (Guest)',
                    room: {
                        jiomeetId: this.meetingId,
                        room_key: this.roomkey,
                        roomID: this.roomID,
                        vpin: this.vpin,
                        roomPIN: this.pwd,
                        room_url: this.room_url
                    },
                    ownerDetails: {},
                    hideMoreCallControls: this.hideMoreCallControls,
                    hideAllCallControls: this.hideAllCallControls,
                    isFullScreenShare: this.isFullScreenShare
                });
                this.router.navigate(['conference/call']);
            });
    }

    joinMeeting() {
        // if (!this.utilService.isRTCSupportedBrowser()) {
        //   this.toastrService.warning(
        //     'JioMeet is not supported on this browser. Please use Chrome or Firefox'
        //   );
        //   return;
        // }

        this.errors = {};
        if (!this.validateMeetingPin()) {
            return;
        }
        this.working = true;
        this.rtcService
            .getRoomDetailsGuest({
                extension: this.meetingId,
                pin: this.namePinForm.value.pin,
                hash: this.hash,
                userId: this.currentUser._id,
                isAuthenticated: this.isAuthenticated,
                memberName: this.namePinForm.value.name.trim(),
                captchaText: this.namePinForm.value.captchaText,
                captchaId: this.captchaDetails.captchaId
            })
            .subscribe(
                (res: any) => {
                    if (!res.roomDetails && res.waitingRoom) {
                        this.waitingRoomDetails = res;
                        this.waitUntillHostAllows(res);
                    } else {
                        this.joinAs(res);
                    }
                },
                (err) => {
                    this.namePinForm.get('captchaText').setValue(null);
                    if (err.error.customCode === 429) {
                        this.errors.global = err.error.errors;
                        this.captchaDetails = err.error;
                        this.showCaptcha = true;
                        this.working = false;
                        return;
                    }

                    if (err.error.customCode === 464) {
                        this.joinBeforeHost = true;
                        this.handlePageRefresh();
                        return;
                    }
                    if (err.error.customCode === 465) {
                        this.loggedInOnly = true;
                        return;
                    }
                    if (err.error.customCode === 466) {
                        this.CollegueOnly = true;
                        return;
                    }

                    if (err.error.customCode === 468) {
                        this.errors.global = this.translateService.instant(
                            'joinmeeting_page_or_joincall.errors.cannot_join_room_locked'
                        );
                        this.working = false;
                        return;
                    }

                    this.errors.global = err.error.errors;
                    this.working = false;
                }
            );
    }

    waitUntillHostAllows(res) {
        this.interval = setInterval(() => {
            this.working = true;
            this.rtcService
                .getRoomDetailsGuest({
                    extension: this.meetingId,
                    pin: this.namePinForm.value.pin,
                    hash: this.hash,
                    userId: this.currentUser._id || res.userId,
                    memberName: this.namePinForm.value.name.trim(),
                    isAuthenticated: this.isAuthenticated
                })
                .subscribe(
                    (res: any) => {
                        if (res.roomDetails) {
                            clearInterval(this.interval);
                            this.joinAs(res);
                            // this.waitingRoomDetails = null;
                        }
                    },
                    (err) => {
                        if (err.error.customCode === 467) {
                            clearInterval(this.interval);
                            this.waitingRoomDetails = null;
                            this.toastrService.error('The host has removed you from this meeting');
                            this.errors.global = err.error.errors || 'Request rejected';
                            this.working = false;
                            return;
                        }
                        if (err.error.customCode === 464) {
                            clearInterval(this.interval);
                            this.waitingRoomDetails = null;
                            this.toastrService.error('This meeting has been ended by the host');
                            this.errors.global = 'This meeting has been ended by the host';
                            this.working = false;
                            return;
                        }
                    }
                );
        }, this.appService.getConfigVariable('REQUEST_POLLING_INTERVAL'));
    }

    joinAs(room) {
        this.setHostOrCoHost(room);
        if (this.isAuthenticated && this.currentUser) {
            if (room.ownerDetails.userId === this.currentUser._id && this.currentUser.jiomeetId === this.meetingId) {
                // host a meeting case
                this.diagnosticService
                    .sendEvent({
                        eventCategory: 'Start a Meeting',
                        eventAction: 'New Meeting Start',
                        eventType: 'app_event',
                        status: 'success'
                    })
                    .subscribe();
            }

            if (room.roomDetails.isWebinar) {
                // this.conferenceWebinarService.joinAWebinar({
                //   isInitiater: room.ownerDetails.userId === this.currentUser._id,
                //   guestName: this.namePinForm.value.name.trim(),
                //   room: room.roomDetails,
                //   ownerDetails: room.ownerDetails,
                //   meetingId: room.roomDetails.meetingId
                // });
            } else {
                this.rtcService.setConferenceInfo({
                    isInitiater: room.ownerDetails.userId === this.currentUser._id,
                    joiningName: this.namePinForm.value.name.trim(),
                    room: room.roomDetails,
                    ownerDetails: room.ownerDetails,
                    hideMoreCallControls: this.hideMoreCallControls,
                    hideAllCallControls: this.hideAllCallControls,
                    isFullScreenShare: this.isFullScreenShare
                });
                this.router.navigate(['conference/call']);
            }
        } else {
            if (room.roomDetails.isWebinar) {
                this.loggedInOnly = true;
                return;
            }
            this.guestAuthService
                .login({
                    jiomeetId: room.roomDetails.jiomeetId,
                    roomID: room.roomDetails.roomID,
                    roomPin: room.roomDetails.roomPIN,
                    name:
                        this.namePinForm.value.name.length <= 25
                            ? this.namePinForm.value.name
                            : this.namePinForm.value.name.substr(0, 25) + '...',
                    phoneNo: '',
                    emailId: '',
                    recorderToken: ''
                })
                .subscribe((res) => {
                    this.userService.isGuestLogin(true);
                    this.userService.setGuestUser(name);
                    this.rtcService.setConferenceInfo({
                        isInitiater: false,
                        joiningName: this.namePinForm.value.name.trim() + ' (Guest)',
                        room: room.roomDetails,
                        ownerDetails: room.ownerDetails,
                        hideMoreCallControls: this.hideMoreCallControls,
                        hideAllCallControls: this.hideAllCallControls,
                        isFullScreenShare: this.isFullScreenShare
                    });
                    this.router.navigate(['conference/call']);
                });
        }
    }
    setHostOrCoHost(room) {
        const { ownerDetails, roomDetails } = room;
        const { coHosts } = roomDetails;

        const isCurrentUserHost = ownerDetails?.userId === this.currentUser._id;
        const isCurrentUserCoHost = coHosts?.some((coHost) => coHost?.userId === this.currentUser._id);

        this.rtcService.isHostOrCoHostOfMeeting = isCurrentUserHost || isCurrentUserCoHost;
    }

    leaveWaitingRoom() {
        clearInterval(this.interval);
        this.userService
            .stopWaiting({
                memberId: this.currentUser._id || this.waitingRoomDetails.userId,
                jiomeetId: this.waitingRoomDetails.jiomeetId
            })
            .subscribe(
                (res) => {
                    clearInterval(this.interval);
                    this.waitingRoomDetails = null;
                    this.working = false;
                },
                (err) => {}
            );
    }

    handleManualPageRefresh() {
        this.appService.reloadWebContent();
    }
    handlePageRefresh() {
        setTimeout(() => {
            this.appService.reloadWebContent();
        }, 5000);
    }
    navigateToLogin() {
        this.router.navigate(['/login'], { relativeTo: this.activatedRoute });
    }
}
