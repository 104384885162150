import { id } from 'date-fns/locale';
import { Injectable, ɵConsole } from '@angular/core';
import adapter from 'webrtc-adapter';

import { AppService } from './app.service';
import { LoggerService } from './logger.service';
import { BehaviorSubject, of, Observable, Subject } from 'rxjs';
import { VIDYO_EVENTS } from 'src/app/constants';
import { ToastrService } from 'ngx-toastr';
import { Speaker } from 'src/app/shared/interfaces/audio-settings';
import { RtcService } from './rtc.service';
import { UtilService } from './util.service';

declare var VC: any;
declare const window;
declare const $: any;

@Injectable({
    providedIn: 'root'
})
export class JanusService {
    vidyoConnector;
    libraryLoaded = false;

    localParticipant;
    joinData;
    reconnecting = false;
    isUserOnline = true;

    users = {};
    vcs = {};
    microphoneMutedAll = false;
    roomLocked = false;
    recording = false;
    screenSharing = false;
    waitingUsers = {};
    cameras = [];
    microphones = [];
    speakers = [];
    selectedLocalCamera = 0;
    selectedLocalMicrophone = 0;
    selectedLocalSpeaker = 0;

    private disconneting = false;
    private alreadySharingScreen = false;
    private windowShares = {};
    private whiteboardShared;
    private sentLocalParticipantConnected: boolean;

    private meeting$: BehaviorSubject<any> = new BehaviorSubject(null);
    private chatMessages$: BehaviorSubject<any> = new BehaviorSubject(null);
    private deviceChanged$: BehaviorSubject<any> = new BehaviorSubject(null);
    private participantsStatus$: BehaviorSubject<any> = new BehaviorSubject(null);
    private participantsCount$: BehaviorSubject<any> = new BehaviorSubject(null);
    private screenSharingStatus$: BehaviorSubject<any> = new BehaviorSubject(null);

    reConnectInterval;

    //acs variables
    isConnected = new Subject<boolean>();
    isVideoOn = new Subject<boolean>();

    videoDevicesUpdated = new Subject<boolean>();
    audioDevicesUpdated = new Subject<boolean>();

    localParticipantUpdated = new Subject<boolean>();
    // participantAdded = new Subject<RemoteParticipant>();
    // participantRemoved = new Subject<RemoteParticipant>();

    // rendererView: VideoStreamRenderer;

    meetingObj;

    roomData;
    isHandRaise = false;

    // permisionConstraints: PermissionConstraints = { audio: true, video: true };

    acsUserId;
    groupId = '';
    callOptionsInternal = {
        audioOptions: {
            isMuted: true,
            audioOutputDeviceId: '',
            audioInputDeviceId: ''
        },
        videoOptions: {
            isVideoOn: false,
            videoDeviceId: ''
        }
    };
    displayName = '';
    // callClient: CallClient | undefined;
    // callAgent: CallAgent | undefined;
    // azureLogger: AzureLogger | undefined;
    // deviceManager: DeviceManager | undefined;
    // videoDevices: VideoDeviceInfo[] = []
    //  microphoneDevices: AudioDeviceInfo[] = []
    //  speakerDevices: AudioDeviceInfo[] = []
    // call: Call | undefined;
    streams: any = [];
    streamsCollection = [];
    // remoteParticipants:RemoteParticipant[] = [];
    // localVideoStream: LocalVideoStream | undefined;
    customCollection = [];

    visibleParticipants = [];
    inVisibleParticipants = [];

    janus: any;
    janusaudio: any;
    sfutest: any;
    mixertest: any;
    sfutestscreen: any;
    myid = null;
    mymixerid = null;
    myscreenshareid = null;
    mystream = null;
    mypvtid = null;
    feeds = [];
    bitrateTimer = [];
    opaqueId = '';
    noOfParticipants = 0;
    MAX_REMOTE_STREAMS = 4;
    audioenabled = true;
    visibleParticipantList = [];
    hiddenParticipantList = [];
    participantList = {};

    constructor(
        private loggerService: LoggerService,
        private appService: AppService,
        private toastrService: ToastrService,
        private rtcService: RtcService,
        private utilService: UtilService
    ) {}

    getMeetingObs() {
        return this.meeting$;
    }

    getChatMessages() {
        return this.chatMessages$;
    }

    getDeviceCahnge() {
        return this.deviceChanged$;
    }

    getParticipantStatus() {
        return this.participantsStatus$;
    }

    getParticipantCount() {
        return this.participantsCount$;
    }

    getScreenSharingStatus() {
        return this.screenSharingStatus$;
    }

    intializeValue = () => {
        this.users = {};
        this.cameras = [];
        this.microphones = [];
        this.speakers = [];
        this.selectedLocalCamera = 0;
        this.selectedLocalMicrophone = 0;
        this.selectedLocalSpeaker = 0;
        this.disconneting = false;
        this.reconnecting = false;
    };

    loadVidyoSDK = () => {};

    setJoinData({
        host,
        displayName,
        roomKey,
        roomPin,
        micPrivacy,
        cameraPrivacy,
        isInitiater = false,
        viewId = 'renderer',
        isEmbedInMobile = false,
        unlockAndJoin = false,
        meetingObj = null,
        userId = undefined
    }) {
        this.joinData = {
            host,
            displayName,
            roomKey,
            roomPin,
            micPrivacy,
            cameraPrivacy,
            isInitiater,
            viewId,
            isEmbedInMobile,
            unlockAndJoin,
            meetingObj,
            userId
        };
    }

    updateDevices(updateSelected) {
        window.Janus.listDevices((devices) => {
            this.cameras = devices
                .filter((device) => {
                    return device.kind === 'videoinput';
                })
                .map((device) => {
                    return { ...device, id: device.deviceId, name: device.label };
                });
            this.microphones = devices
                .filter((device) => {
                    return device.kind === 'audioinput';
                })
                .map((device) => {
                    return { ...device, id: device.deviceId, name: device.label };
                });
            this.speakers = devices
                .filter((device) => {
                    return device.kind === 'audiooutput';
                })
                .map((device) => {
                    return { ...device, id: device.deviceId, name: device.label };
                });
            if (updateSelected) {
                this.setSelectedAcsDevices();
            }
        });
    }

    isPresentInVisibleList(id) {
        return this.findIndexInVisibleList(id) !== -1;
    }

    sortVisibleList() {
        this.visibleParticipantList.sort((f, s) => {
            return s.lastSpeakingTime - f.lastSpeakingTime;
        });
    }

    findIndexInVisibleList(id) {
        return this.visibleParticipantList.findIndex((v) => {
            return v.id === id;
        });
    }

    removeFromHiddenList(id) {
        let index = this.hiddenParticipantList.findIndex((v) => {
            return v.id === id;
        });
        if (index !== -1) {
            this.hiddenParticipantList.splice(index, 1);
        }
    }

    updateLastSpeakingTimeInVisibleList(id) {
        let index = this.findIndexInVisibleList(id);
        if (index !== -1) {
            this.visibleParticipantList[index].lastSpeakingTime = new Date().getTime();
        }
    }

    getPlugin(id) {
        return this.feeds.find((p) => {
            return p?.rfid === id;
        });
    }

    switchStream(plugin, id) {
        plugin.rfid = id;
        plugin.rfdisplay = this.participantList[id].name;
        if (plugin.isVideoMuted) {
            let initials = this.getInitials(plugin.rfdisplay)?.length > 0 ? this.getInitials(plugin.rfdisplay) : 'G';
            $('#videoremote' + plugin.rfindex + ' .no-video-container').remove();
            $('#videoremote' + plugin.rfindex).append(
                `
        <div class="no-video-container">
          <div class="noVideo">
            <div class="initials">${initials}</div>
          </div>
          <div class="participantName">
            <div>
              ${plugin.rfdisplay}
            </div>
          </div>
        </div>
        `
            );
        }
        plugin.send({
            message: {
                request: 'switch',
                feed: id
            }
        });
    }

    initializeVidyoConnector() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'assets/lib/janus/janus.js';
        script.onload = () => {
            this.initializeJanus();
        };
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    flipCamera() {
        const selectedCamera = this.selectedLocalCamera;
        let newCameraId;
        if (this.cameras.length > 1) {
            for (let i = 0; i < this.cameras.length; i++) {
                if (this.cameras[i].id === selectedCamera) {
                    if (i + 1 >= this.cameras.length) newCameraId = this.cameras[0];
                    else newCameraId = this.cameras[i + 1];
                }
            }

            if (newCameraId) {
                this.manuallyChangeCamera(newCameraId);
            } else {
                this.manuallyChangeCamera(this.cameras[0]);
            }
        }
    }

    initializeJanus() {
        this.opaqueId = 'videoroomtest-' + window.Janus.randomString(12);
        window.Janus.init({
            debug: 'all',
            dependencies: window.Janus.useDefaultDependencies({ adapter: adapter }),
            callback: () => {
                // Use a button to start the demo
                this.janus = new window.Janus({
                    server: 'wss://janus.jiomeet.com:7443/janus',
                    success: () => {
                        // Attach to VideoRoom plugin

                        this.janus.attach({
                            plugin: 'janus.plugin.videoroom',
                            opaqueId: this.opaqueId,
                            success: (pluginHandle) => {
                                this.sfutest = pluginHandle;
                                this.updateDevices(true);
                                window.sfutest = this.sfutest;
                                window.Janus.log(
                                    'Plugin attached! (' +
                                        this.sfutest.getPlugin() +
                                        ', id=' +
                                        this.sfutest.getId() +
                                        ')'
                                );
                                window.Janus.log(' -- This is a publisher/manager');
                                this.meeting$.next({ type: 'VC_CREATED', data: null });
                                let register = {
                                    request: 'join',
                                    room: this.rtcService.getConferenceInfo().room.roomID,
                                    pin: this.rtcService.getConferenceInfo().room.vpin,
                                    ptype: 'publisher',
                                    display: this.joinData.displayName,
                                    id: this.joinData.userId
                                };
                                this.sfutest.send({ message: register });
                                this.meeting$.next({ type: 'ACTIVE', data: null });
                            },

                            error: (error) => {
                                window.Janus.error(' -- Error attaching plugin...', error);
                            },

                            consentDialog: (on) => {
                                window.Janus.debug('Consent dialog should be ' + (on ? 'on' : 'off') + ' now');
                            },
                            iceState: (state) => {
                                window.Janus.log('ICE state changed to ' + state);
                            },

                            mediaState: (medium, on) => {
                                window.Janus.log('Janus ' + (on ? 'started' : 'stopped') + ' receiving our ' + medium);
                            },

                            webrtcState: (on) => {
                                window.Janus.log(
                                    'Janus says our WebRTC PeerConnection is ' + (on ? 'up' : 'down') + ' now'
                                );
                            },

                            onmessage: (msg, jsep) => {
                                window.Janus.debug(' ::: Got a message (publisher) :::', msg);
                                var event = msg['videoroom'];
                                window.Janus.debug('Event: ' + event);
                                if (event) {
                                    if (event === 'joined') {
                                        // Publisher/manager created, negotiate WebRTC and attach to existing feeds, if any
                                        this.myid = msg['id'];
                                        this.mypvtid = msg['private_id'];
                                        window.Janus.log(
                                            'Successfully joined room ' + msg['room'] + ' with ID ' + this.myid
                                        );
                                        this.publishOwnFeed(true);
                                        // Any new feed to attach to?
                                        if (msg['publishers']) {
                                            var list = msg['publishers'];
                                            window.Janus.debug('Got a list of available publishers/feeds:', list);
                                            let index = 0;
                                            for (var f in list) {
                                                var id = list[f]['id'];
                                                var display = list[f]['display'];
                                                var audio = list[f]['audio_codec'];
                                                var video = list[f]['video_codec'];
                                                var isScreenShareFeed =
                                                    audio === undefined &&
                                                    display === 'screenShareFeed' &&
                                                    id.indexOf('-screenShareFeed') !== -1;
                                                window.Janus.debug(
                                                    '  >> [' +
                                                        id +
                                                        '] ' +
                                                        display +
                                                        ' (audio: ' +
                                                        audio +
                                                        ', video: ' +
                                                        video +
                                                        ')'
                                                );
                                                window.Janus.debug('This is screen sharing feed : ', isScreenShareFeed);
                                                if (this.myscreenshareid !== id && isScreenShareFeed) {
                                                    this.newRemoteFeed(id, display, audio, video, isScreenShareFeed);
                                                } else if (
                                                    this.myscreenshareid !== id &&
                                                    index < this.MAX_REMOTE_STREAMS
                                                ) {
                                                    this.newRemoteFeed(id, display, audio, video, isScreenShareFeed);
                                                } else {
                                                    this.hiddenParticipantList.push({
                                                        id: id,
                                                        lastSpeakingTime: new Date().getTime()
                                                    });
                                                }
                                                index++;
                                            }
                                        }
                                    } else if (event === 'destroyed') {
                                        // The room has been destroyed
                                        window.Janus.warn('The room has been destroyed!');
                                    } else if (event === 'event') {
                                        // Any new feed to attach to?
                                        if (msg['publishers']) {
                                            var list = msg['publishers'];
                                            window.Janus.debug('Got a list of available publishers/feeds:', list);
                                            for (var f in list) {
                                                var id = list[f]['id'];
                                                var display = list[f]['display'];
                                                var audio = list[f]['audio_codec'];
                                                var video = list[f]['video_codec'];
                                                window.Janus.debug(
                                                    '  >> [' +
                                                        id +
                                                        '] ' +
                                                        display +
                                                        ' (audio: ' +
                                                        audio +
                                                        ', video: ' +
                                                        video +
                                                        ')'
                                                );
                                                var isScreenShareFeed =
                                                    audio === undefined &&
                                                    display === 'screenShareFeed' &&
                                                    id.indexOf('-screenShareFeed') !== -1;
                                                window.Janus.debug('This is screen sharing feed : ', isScreenShareFeed);
                                                if (this.myscreenshareid !== id && isScreenShareFeed) {
                                                    this.newRemoteFeed(id, display, audio, video, isScreenShareFeed);
                                                } else if (
                                                    this.myscreenshareid !== id &&
                                                    this.feeds.length <= this.MAX_REMOTE_STREAMS
                                                ) {
                                                    this.newRemoteFeed(id, display, audio, video, isScreenShareFeed);
                                                } else {
                                                    this.hiddenParticipantList.push({
                                                        id: id,
                                                        lastSpeakingTime: new Date().getTime()
                                                    });
                                                }

                                                if (!isScreenShareFeed) {
                                                    let participant = this.participantList[id];
                                                    if (participant) {
                                                        this.participantList[id] = {
                                                            ...participant,
                                                            name: display,
                                                            audio: audio,
                                                            video: video
                                                        };
                                                    } else {
                                                        this.participantList[id] = {
                                                            name: display,
                                                            audio: audio,
                                                            video: video
                                                        };
                                                    }
                                                }
                                            }
                                        } else if (msg['leaving']) {
                                            // One of the publishers has gone away?
                                            var leaving = msg['leaving'];
                                            window.Janus.log('Publisher left: ' + leaving);
                                            var remoteFeed = null;
                                            for (var i = 1; i < this.MAX_REMOTE_STREAMS; i++) {
                                                if (this.feeds[i] && this.feeds[i].rfid === leaving) {
                                                    remoteFeed = this.feeds[i];
                                                    break;
                                                }
                                            }
                                            if (remoteFeed != null) {
                                                window.Janus.debug(
                                                    'Feed ' +
                                                        remoteFeed.rfid +
                                                        ' (' +
                                                        remoteFeed.rfdisplay +
                                                        ') has left the room, detaching'
                                                );
                                                // $('#remote'+remoteFeed.rfindex).empty().hide();
                                                $('#videoremote' + remoteFeed.rfindex).empty();
                                                this.feeds[remoteFeed.rfindex] = null;
                                                remoteFeed.detach();
                                            }
                                        } else if (msg['unpublished']) {
                                            // One of the publishers has unpublished?
                                            var unpublished = msg['unpublished'];
                                            window.Janus.log('Publisher left: ' + unpublished);
                                            if (unpublished === 'ok') {
                                                // That's us
                                                this.sfutest.hangup();
                                                return;
                                            }
                                            var remoteFeed = null;
                                            for (var i = 1; i < this.MAX_REMOTE_STREAMS; i++) {
                                                if (this.feeds[i] && this.feeds[i].rfid === unpublished) {
                                                    remoteFeed = this.feeds[i];
                                                    break;
                                                }
                                            }
                                            if (remoteFeed != null) {
                                                window.Janus.debug(
                                                    'Feed ' +
                                                        remoteFeed.rfid +
                                                        ' (' +
                                                        remoteFeed.rfdisplay +
                                                        ') has left the room, detaching'
                                                );
                                                // $('#remote'+remoteFeed.rfindex).empty().hide();
                                                $('#videoremote' + remoteFeed.rfindex).empty();
                                                this.feeds[remoteFeed.rfindex] = null;
                                                remoteFeed.detach();
                                            }
                                        } else if (msg['error']) {
                                            if (msg['error_code'] === 426) {
                                                // This is a "no such room" error: give a more meaningful description
                                                console.log('Error 426');
                                            } else {
                                                console.log('Error !426');
                                            }
                                        }
                                    }
                                }
                                if (jsep) {
                                    window.Janus.debug('Handling SDP as well...', jsep);
                                    this.sfutest.handleRemoteJsep({ jsep: jsep });
                                    // Check if any of the media we wanted to publish has
                                    // been rejected (e.g., wrong or unsupported codec)
                                    var audio = msg['audio_codec'];
                                    if (
                                        this.mystream &&
                                        this.mystream.getAudioTracks() &&
                                        this.mystream.getAudioTracks().length > 0 &&
                                        !audio
                                    ) {
                                        // Audio has been rejected
                                        console.log("Our audio stream has been rejected, viewers won't hear us");
                                    }
                                    var video = msg['video_codec'];
                                    if (
                                        this.mystream &&
                                        this.mystream.getVideoTracks() &&
                                        this.mystream.getVideoTracks().length > 0 &&
                                        !video
                                    ) {
                                        // Video has been rejected
                                        console.log("Our video stream has been rejected, viewers won't see us");
                                        // Hide the webcam video
                                        // $('#myvideo').hide();
                                        // $('#videolocal').append(
                                        // 	'<div class="no-video-container">' +
                                        // 		'<i class="fa fa-video-camera fa-5 no-video-icon" style="height: 100%;"></i>' +
                                        // 		'<span class="no-video-text" style="font-size: 16px;">Video rejected, no webcam</span>' +
                                        // 	'</div>');
                                    }
                                }
                            },

                            onlocalstream: (stream) => {
                                window.Janus.debug(' ::: Got a local stream :::', stream);
                                this.mystream = stream;
                                let elem: any = document.getElementById('myVideo');
                                window.Janus.attachMediaStream(elem, stream);
                                elem.muted = 'muted';

                                if (!this.sentLocalParticipantConnected) {
                                    //This event is required ony at first time not for subsequent video mute/unmute
                                    this.sentLocalParticipantConnected = true;
                                    this.localParticipant = {
                                        id: this.myid.toString(),
                                        userId: this.mypvtid.toString(),
                                        name: this.joinData.displayName,
                                        isLocal: true,
                                        isHost: this.joinData.isInitiater ? true : false,
                                        microphoneMute: !this.joinData.micPrivacy,
                                        cameraMute: !this.joinData.cameraPrivacy
                                    };
                                    this.meeting$.next({
                                        type: 'LOCAL_PARTICIPANT_CONNECTED',
                                        data: null
                                    });
                                }
                            },

                            onremotestream: (stream) => {
                                // The publisher stream is sendonly, we don't expect anything here
                            },

                            oncleanup: () => {
                                window.Janus.log(' ::: Got a cleanup notification: we are unpublished now :::');
                            }
                        });
                    },
                    error: (error) => {
                        window.Janus.error(error);
                    },
                    destroyed: () => {}
                });

                this.janusaudio = new window.Janus({
                    server: 'wss://janus.jiomeet.com:7443/janus',
                    success: () => {
                        this.janusaudio.attach({
                            plugin: 'janus.plugin.audiobridge',
                            opaqueId: this.opaqueId,
                            success: (pluginHandle) => {
                                this.mixertest = pluginHandle;
                                window.mixertest = pluginHandle;
                                let register = {
                                    request: 'join',
                                    room: this.rtcService.getConferenceInfo().room.roomID,
                                    pin: this.rtcService.getConferenceInfo().room.vpin,
                                    display: this.joinData.displayName,
                                    quality: 10,
                                    id: this.joinData.userId
                                };
                                this.mixertest.send({ message: register });
                                window.Janus.log(
                                    'Plugin attached! (' +
                                        this.mixertest.getPlugin() +
                                        ', id=' +
                                        this.mixertest.getId() +
                                        ')'
                                );
                            },
                            error: function (error) {
                                window.Janus.error('  -- Error attaching plugin...', error);
                            },
                            consentDialog: function (on) {
                                window.Janus.debug('Consent dialog should be ' + (on ? 'on' : 'off') + ' now');
                            },
                            iceState: function (state) {
                                window.Janus.log('ICE state changed to ' + state);
                            },
                            mediaState: function (medium, on) {
                                window.Janus.log('Janus ' + (on ? 'started' : 'stopped') + ' receiving our ' + medium);
                            },
                            webrtcState: function (on) {
                                window.Janus.log(
                                    'Janus says our WebRTC PeerConnection is ' + (on ? 'up' : 'down') + ' now'
                                );
                            },
                            onmessage: (msg, jsep) => {
                                window.Janus.debug(' ::: Got a message :::', msg);
                                var event = msg['audiobridge'];
                                window.Janus.debug('Event: ' + event);
                                if (event) {
                                    if (event === 'joined') {
                                        // Successfully joined, negotiate WebRTC now
                                        if (msg['id']) {
                                            this.mymixerid = msg['id'];
                                            window.Janus.log(
                                                'Successfully joined room ' + msg['room'] + ' with ID ' + this.mymixerid
                                            );
                                            this.mixertest.createOffer({
                                                media: {
                                                    video: false,
                                                    audio: {
                                                        deviceId: { exact: ['default'] },
                                                        advanced: [
                                                            { googEchoCancellation: { exact: true } },
                                                            { googExperimentalEchoCancellation: { exact: true } },
                                                            { autoGainControl: { exact: true } },
                                                            { noiseSuppression: { exact: true } },
                                                            { googHighpassFilter: { exact: true } },
                                                            { googAudioMirroring: { exact: true } }
                                                        ]
                                                    }
                                                }, // This is an audio only room
                                                success: (jsep) => {
                                                    window.Janus.debug('Got SDP!', jsep);
                                                    var publish = { request: 'configure', muted: false };
                                                    this.mixertest.send({ message: publish, jsep: jsep });
                                                },
                                                error: (error) => {
                                                    window.Janus.error('WebRTC error:', error);
                                                }
                                            });
                                        }
                                        // Any room participant?
                                        if (msg['participants']) {
                                            var list = msg['participants'];
                                            window.Janus.debug('Got a list of participants:', list);
                                        }
                                    } else if (event === 'event') {
                                        if (msg['participants']) {
                                            var list = msg['participants'];
                                            window.Janus.debug('Got a list of participants:', list);
                                            list.forEach((element) => {
                                                this.participantList[element.id] = {
                                                    name: element.display,
                                                    muted: element.muted
                                                };
                                            });
                                        }
                                    } else if (event === 'talking') {
                                        let newId = msg['id'];
                                        if (newId !== this.myid) {
                                            if (this.isPresentInVisibleList(newId)) {
                                                this.updateLastSpeakingTimeInVisibleList(newId);
                                                this.sortVisibleList();
                                            } else {
                                                let lastSpeakingParticipant = this.visibleParticipantList.pop();
                                                let plugin = this.getPlugin(lastSpeakingParticipant.id);
                                                this.switchStream(plugin, newId);
                                                this.visibleParticipantList.push({
                                                    id: newId,
                                                    lastSpeakingTime: new Date().getTime()
                                                });
                                                this.sortVisibleList();
                                                this.removeFromHiddenList(newId);
                                                this.hiddenParticipantList.push(lastSpeakingParticipant);
                                            }
                                        }
                                    }
                                    // Any new feed to attach to?
                                    if (msg['leaving']) {
                                        // One of the participants has gone away?
                                        var leaving = msg['leaving'];
                                        window.Janus.log(
                                            'Participant left: ' +
                                                leaving +
                                                ' (we have ' +
                                                $('#rp' + leaving).length +
                                                ' elements with ID #rp' +
                                                leaving +
                                                ')'
                                        );
                                    }
                                }
                                if (jsep) {
                                    window.Janus.debug('Handling SDP as well...', jsep);
                                    this.mixertest.handleRemoteJsep({ jsep: jsep });
                                }
                            },
                            onlocalstream: function (stream) {
                                window.Janus.debug(' ::: Got a local stream :::', stream);
                            },
                            onremotestream: (stream) => {
                                window.Janus.attachMediaStream($('#roomaudio').get(0), stream);
                            },
                            oncleanup: function () {}
                        });
                    },
                    error: (error) => {
                        window.Janus.error(error);
                    },
                    destroyed: () => {}
                });
            }
        });
    }

    publishOwnFeed(useAudio) {
        // Publish our stream
        this.sfutest.createOffer({
            // Add data:true here if you want to publish datachannels as well
            media: {
                audio: false,
                video: {
                    deviceId: this.selectedLocalCamera,
                    width: { min: 320, max: 1280 },
                    height: { min: 180, max: 720 }
                }
            },
            simulcast: true,
            simulcast2: true,
            success: (jsep) => {
                window.Janus.debug('Got publisher SDP!', jsep);
                var publish = { request: 'configure', audio: false, video: true };
                this.sfutest.send({ message: publish, jsep: jsep });
            },
            error: (error) => {
                window.Janus.error('WebRTC error:', error);
                if (useAudio) {
                    this.publishOwnFeed(false);
                }
            }
        });
    }

    isVideoMuted() {
        return this.sfutest ? this.sfutest.isVideoMuted() : true;
    }

    toggleMute() {}

    unpublishOwnFeed() {
        // Unpublish our stream
        var unpublish = { request: 'unpublish' };
        this.sfutest.send({ message: unpublish });
    }

    newRemoteFeed(id, display, audio, video, isScreenShareFeed) {
        // A new feed has been published, create a new plugin handle and attach to it as a subscriber
        var remoteFeed = null;
        this.janus.attach({
            plugin: 'janus.plugin.videoroom',
            opaqueId: this.opaqueId,
            success: (pluginHandle) => {
                remoteFeed = pluginHandle;
                remoteFeed.simulcastStarted = false;
                window.Janus.log('Plugin attached! (' + remoteFeed.getPlugin() + ', id=' + remoteFeed.getId() + ')');
                window.Janus.log('  -- This is a subscriber');
                // We wait for the plugin to send us an offer
                let subscribe = {
                    request: 'join',
                    room: this.rtcService.getConferenceInfo().room.roomID,
                    pin: this.rtcService.getConferenceInfo().room.vpin,
                    ptype: 'subscriber',
                    feed: id,
                    private_id: this.mypvtid
                };
                // In case you don't want to receive audio, video or data, even if the
                // publisher is sending them, set the 'offer_audio', 'offer_video' or
                // 'offer_data' properties to false (they're true by default), e.g.:
                // 		subscribe["offer_video"] = false;
                // For example, if the publisher is VP8 and this is Safari, let's avoid video
                if (
                    window.Janus.webRTCAdapter.browserDetails.browser === 'safari' &&
                    (video === 'vp9' || (video === 'vp8' && !window.Janus.safariVp8))
                ) {
                    if (video) video = video.toUpperCase();
                    subscribe['offer_video'] = false;
                }
                remoteFeed.videoCodec = video;
                remoteFeed.send({ message: subscribe });
            },
            error: (error) => {
                window.Janus.error('  -- Error attaching plugin...', error);
            },
            onmessage: (msg, jsep) => {
                window.Janus.debug(' ::: Got a message (subscriber) :::', msg);
                var event = msg['videoroom'];
                window.Janus.debug('Event: ' + event);
                if (msg['error']) {
                    // show some error
                } else if (event) {
                    if (event === 'attached' && !isScreenShareFeed) {
                        // Subscriber created and attached
                        for (var i = 1; i <= this.MAX_REMOTE_STREAMS; i++) {
                            if (!this.feeds[i]) {
                                this.feeds[i] = remoteFeed;
                                remoteFeed.rfindex = i;
                                break;
                            }
                        }
                        remoteFeed.rfid = msg['id'];
                        remoteFeed.rfdisplay = msg['display'];
                        if (!isScreenShareFeed) {
                            this.visibleParticipantList.push({
                                id: remoteFeed.rfid,
                                lastSpeakingTime: new Date().getTime()
                            });
                        }
                        // if(!remoteFeed.spinner) {
                        //   var target = document.getElementById('videoremote'+remoteFeed.rfindex);
                        //   remoteFeed.spinner = new Spinner({top:100}).spin(target);
                        // } else {
                        //   remoteFeed.spinner.spin();
                        // }
                        window.Janus.log(
                            'Successfully attached to feed ' +
                                remoteFeed.rfid +
                                ' (' +
                                remoteFeed.rfdisplay +
                                ') in room ' +
                                msg['room']
                        );
                        $('#remote' + remoteFeed.rfindex)
                            .removeClass('hide')
                            .html(remoteFeed.rfdisplay)
                            .show();
                    }
                    // else if(event === "event") {
                    //   // Check if we got a simulcast-related event from this publisher
                    //   var substream = msg["substream"];
                    //   var temporal = msg["temporal"];
                    //   if((substream !== null && substream !== undefined) || (temporal !== null && temporal !== undefined)) {
                    //     if(!remoteFeed.simulcastStarted) {
                    //       remoteFeed.simulcastStarted = true;
                    //       // Add some new buttons
                    //       addSimulcastButtons(remoteFeed.rfindex, remoteFeed.videoCodec === "vp8" || remoteFeed.videoCodec === "h264");
                    //     }
                    //     // We just received notice that there's been a switch, update the buttons
                    //     updateSimulcastButtons(remoteFeed.rfindex, substream, temporal);
                    //   }
                    // } else {
                    //   // What has just happened?
                    // }
                }
                if (jsep) {
                    window.Janus.debug('Handling SDP as well...', jsep);
                    // Answer and attach
                    remoteFeed.createAnswer({
                        jsep: jsep,
                        // Add data:true here if you want to subscribe to datachannels as well
                        // (obviously only works if the publisher offered them in the first place)
                        media: { audioSend: false, videoSend: false }, // We want recvonly audio/video
                        success: (jsep) => {
                            window.Janus.debug('Got SDP!', jsep);
                            var body = { request: 'start', room: this.joinData.roomKey };
                            remoteFeed.send({ message: body, jsep: jsep });
                        },
                        error: (error) => {
                            window.Janus.error('WebRTC error:', error);
                        }
                    });
                }
            },
            iceState: (state) => {
                window.Janus.log(
                    'ICE state of this WebRTC PeerConnection (feed #' + remoteFeed.rfindex + ') changed to ' + state
                );
            },
            webrtcState: (on) => {
                window.Janus.log(
                    'Janus says this WebRTC PeerConnection (feed #' +
                        remoteFeed.rfindex +
                        ') is ' +
                        (on ? 'up' : 'down') +
                        ' now'
                );
            },
            onlocalstream: (stream) => {
                // The subscriber stream is recvonly, we don't expect anything here
            },
            onremotestream: (stream) => {
                if (!isScreenShareFeed) {
                    window.Janus.debug('Remote feed #' + remoteFeed.rfindex + ', stream:', stream);
                    var addButtons = false;
                    if ($('#videoremote' + remoteFeed.rfindex).length === 0) {
                        $('#maingrid').append(
                            `<div class="video-${remoteFeed.rfindex} videoContainer" id="videoremote${remoteFeed.rfindex}"></div>`
                        );
                    }
                    if ($('#remotevideo' + remoteFeed.rfindex).length === 0) {
                        addButtons = true;
                        this.noOfParticipants++;
                        this.participantsCount$.next({
                            type: 'PARTICIPANT_COUNT_UPDATED',
                            data: this.noOfParticipants
                        });
                        // No remote video yet
                        $('#videoremote' + remoteFeed.rfindex).append(
                            '<video class="rounded centered relative hide" id="remotevideo' +
                                remoteFeed.rfindex +
                                '" width="100%" height="100%" muted = "muted" autoplay playsinline/>'
                        );
                        $('#remotevideo' + remoteFeed.rfindex).bind('playing', () => {});
                    }
                    window.Janus.attachMediaStream(document.getElementById('remotevideo' + remoteFeed.rfindex), stream);
                    var videoTracks = stream.getVideoTracks();
                    var initials =
                        this.getInitials(remoteFeed.rfdisplay)?.length > 0
                            ? this.getInitials(remoteFeed.rfdisplay)
                            : 'G';
                    if (!videoTracks || videoTracks.length === 0) {
                        // No remote video
                        remoteFeed.isVideoMuted = true;
                        $('#remotevideo' + remoteFeed.rfindex).hide();
                        if ($('#videoremote' + remoteFeed.rfindex + ' .no-video-container').length === 0) {
                            $('#videoremote' + remoteFeed.rfindex).append(
                                `
                  <div class="no-video-container">
                    <div class="noVideo">
                      <div class="initials">${initials}</div>
                    </div>
                    <div class="participantName">
                      <div>
                        ${remoteFeed.rfdisplay}
                      </div>
                    </div>
                  </div>
                  `
                            );
                        }
                    } else {
                        remoteFeed.isVideoMuted = false;
                        $('#videoremote' + remoteFeed.rfindex + ' .no-video-container').remove();
                        $('#remotevideo' + remoteFeed.rfindex)
                            .removeClass('hide')
                            .show();
                    }
                    if (!addButtons) return;
                    if (
                        window.Janus.webRTCAdapter.browserDetails.browser === 'chrome' ||
                        window.Janus.webRTCAdapter.browserDetails.browser === 'firefox' ||
                        window.Janus.webRTCAdapter.browserDetails.browser === 'safari'
                    ) {
                        $('#curbitrate' + remoteFeed.rfindex)
                            .removeClass('hide')
                            .show();
                        this.bitrateTimer[remoteFeed.rfindex] = setInterval(() => {}, 1000);
                    }
                } else {
                    if (this.screenSharing) {
                        this.toggleWindowShare(false);
                    }
                    this.screenSharingStatus$.next({ type: 'SCREEN_SHARING_STARTED', data: {} });
                    $('#screenShare').empty();
                    $('#screenShare').append('<div class="video" id="screenShareFeed"></div>');
                    $('#screenShareFeed').append(
                        '<video class="rounded centered relative hide" id="screenShareVideo" width="100%" height="100%" autoplay playsinline/>'
                    );
                    window.Janus.attachMediaStream(document.getElementById('screenShareVideo'), stream);
                }
            },
            oncleanup: () => {
                window.Janus.log(' ::: Got a cleanup notification (remote feed ' + id + ') :::');
                if (!isScreenShareFeed) {
                    if (this.hiddenParticipantList.length !== 0) {
                        let hiddenParticipant = this.hiddenParticipantList.shift();
                        let id = hiddenParticipant.id;
                        let participant = this.participantList[id];
                        this.newRemoteFeed(id, participant.name, participant.audio, participant.video, false);
                        this.visibleParticipantList.splice(this.findIndexInVisibleList(remoteFeed.rfid), 1);
                        // this.visibleParticipantList.push(hiddenParticipant);
                    }
                    if (remoteFeed.spinner) remoteFeed.spinner.stop();
                    remoteFeed.spinner = null;
                    $('#videoremote' + remoteFeed.rfindex).remove();
                    this.noOfParticipants--;
                    this.participantsCount$.next({
                        type: 'PARTICIPANT_COUNT_UPDATED',
                        data: this.noOfParticipants
                    });
                    if (this.bitrateTimer[remoteFeed.rfindex]) clearInterval(this.bitrateTimer[remoteFeed.rfindex]);
                    this.bitrateTimer[remoteFeed.rfindex] = null;
                    remoteFeed.simulcastStarted = false;
                } else {
                    this.screenSharingStatus$.next({ type: 'SCREEN_SHARING_STOPPED', data: {} });
                    $('#screenShareFeed').empty();
                }
            }
        });
    }

    sendVCcreatedEvent() {
        const timeout = setTimeout(() => {
            this.meeting$.next({ type: 'VC_CREATED', data: null });
        }, 50);

        const timeout2 = setTimeout(() => {
            // this.meeting$.next({ type: 'VC_CREATED', data: null });
            this.meeting$.next({ type: 'ACTIVE', data: null });
        }, 100);

        const timeout23 = setTimeout(() => {
            this.meeting$.next({
                type: 'LOCAL_PARTICIPANT_CONNECTED',
                data: null
            });
        }, 150);
    }

    userTalkListener(callback): void {
        // this.vidyoConnector.RegisterLocalMicrophoneEnergyListener({
        //   onEnergy: callback
        // });
    }

    registerDeviceAcsListeners() {
        // this.deviceManager.askDevicePermission(this.permisionConstraints).then((e: DeviceAccess) => {
        //   if (e.audio !== undefined) {
        //     if (e.audio) {
        //       this.updateAudioDevices(this.deviceManager);
        //       this.joinRoom(this.joinData)
        //     }
        //   }
        //   if (e.video !== undefined) {
        //     if (e.video) {
        //       this.updateVideoDevices(this.deviceManager);
        //     }
        //   }
        // });
        // this.deviceManager.on('videoDevicesUpdated', async () => {
        //   this.updateVideoDevices(this.deviceManager);
        // });
        // this.deviceManager.on('audioDevicesUpdated', async () => {
        //   this.updateAudioDevices(this.deviceManager);
        // });
    }

    getInitials(name) {
        return name
            .split(' ')
            .map((n) => n[0])
            .join('')
            .toUpperCase()
            .substring(0, 2);
    }

    reConnect() {
        clearInterval(this.reConnectInterval);
        this.joinData.micPrivacy = !this.getLocalParticipant().microphoneMute;
        this.joinData.cameraPrivacy = !this.getLocalParticipant().cameraMute;
        this.joinRoom(this.joinData);

        this.reConnectInterval = setInterval(() => {
            this.joinRoom(this.joinData);
        }, 5 * 1000);
    }

    reConnectOnConnectionOnline() {
        this.joinData.micPrivacy = !this.getLocalParticipant().microphoneMute;
        this.joinData.cameraPrivacy = !this.getLocalParticipant().cameraMute;
        this.joinRoom(this.joinData);
    }

    getLocalVideoStream() {}

    joinRoom({ host, displayName, roomKey, roomPin, micPrivacy, cameraPrivacy }) {}

    updateRemoteParticipants() {
        // this.remoteParticipants = []
        // this.call.remoteParticipants.forEach(p =>{
        //   this.remoteParticipants.push(p)
        // })
    }

    setSelectedAcsDevices() {
        this.selectedLocalCamera = this.cameras[0].id;
        this.selectedLocalMicrophone = this.microphones[0].id;
        this.selectedLocalSpeaker = this.speakers[0].id;
    }

    registerCameraFramesListener() {
        this.vidyoConnector.OnLocalCameraFrame((obj1, obj2) => {
            console.log(obj1, obj2);
        });
    }

    selectDevices() {
        const speakerId = this.utilService.getCookie('cacheSpeaker');
        const micId = this.utilService.getCookie('cacheMic');
        const cameraId = this.utilService.getCookie('cacheCamera');
        if (speakerId) {
            this.speakers.forEach((speaker) => {
                if (speaker.id === speakerId) {
                    this.manuallyChangeSpeaker(speaker);
                }
            });
        } else {
            this.manuallyChangeSpeaker(this.speakers[0]);
        }

        if (micId) {
            this.microphones.forEach((mic) => {
                if (mic.id === micId) {
                    this.manuallyChangeMicrophone(mic);
                }
            });
        } else {
            this.manuallyChangeMicrophone(this.microphones[0]);
        }
        if (cameraId) {
            this.cameras.forEach((camera) => {
                if (camera.id === cameraId) {
                    this.manuallyChangeCamera(camera);
                }
            });
        } else {
            this.manuallyChangeCamera(this.cameras[0]);
        }
    }

    manuallyChangeSpeaker(localSpeaker) {
        document.cookie = `cacheSpeaker=${localSpeaker.id}`;
        this.selectedLocalSpeaker = localSpeaker.id;
        let audio: any = document.getElementById('roomaudio');
        audio.setSinkId(this.selectedLocalSpeaker);
    }

    manuallyChangeMicrophone(localMicrophone) {
        document.cookie = `cacheMic=${localMicrophone.id}`;
        this.selectedLocalMicrophone = localMicrophone.id;
        this.sfutest.createOffer({
            media: {
                audio: {
                    deviceId: this.selectedLocalMicrophone,
                    advanced: [
                        { googEchoCancellation: { exact: true } },
                        { googExperimentalEchoCancellation: { exact: true } },
                        { autoGainControl: { exact: true } },
                        { noiseSuppression: { exact: true } },
                        { googHighpassFilter: { exact: true } },
                        { googAudioMirroring: { exact: true } }
                    ]
                },
                replaceAudio: true,
                simulcast2: true
            },
            success: (jsep) => {
                window.Janus.debug(jsep);
                this.sfutest.send({ message: { audio: true, video: true }, jsep: jsep });
            },
            error: (error) => {
                console.log('WebRTC error... ' + JSON.stringify(error));
            }
        });
    }
    manuallyChangeCamera(localCamera) {
        document.cookie = `cacheCamera=${localCamera.id}`;
        this.selectedLocalCamera = localCamera.id;
        this.sfutest.createOffer({
            media: {
                video: {
                    deviceId: this.selectedLocalCamera,
                    width: { min: 320, max: 1280 },
                    height: { min: 180, max: 720 }
                },
                replaceVideo: true,
                simulcast2: true
            },
            success: (jsep) => {
                window.Janus.debug(jsep);
                this.sfutest.send({ message: { audio: true, video: true }, jsep: jsep });
            },
            error: (error) => {
                console.log('WebRTC error... ' + JSON.stringify(error));
            }
        });
    }

    leaveRoom() {
        this.disconneting = true;
        this.toggleWindowShare(false);
        this.sfutest.hangup();
    }

    toggleCameraPrivacy(enable) {
        console.log('toggling camera privacy : ', enable);
        if (!enable) {
            this.sfutest.createOffer({
                media: {
                    addVideo: true,
                    video: {
                        deviceId: this.selectedLocalCamera,
                        width: { min: 320, max: 1280 },
                        height: { min: 180, max: 720 }
                    },
                    simulcast2: true
                },
                success: (jsep) => {
                    window.Janus.debug(jsep);
                    this.sfutest.send({ message: { audio: true, video: true }, jsep: jsep });
                },
                error: (error) => {
                    console.log('WebRTC error... ' + JSON.stringify(error));
                }
            });
        } else {
            this.sfutest.createOffer({
                media: { removeVideo: true },
                simulcast2: true,
                success: (jsep) => {
                    window.Janus.debug(jsep);
                    this.sfutest.send({ message: { audio: true, video: true }, jsep: jsep });
                },
                error: (error) => {
                    console.log('WebRTC error... ' + JSON.stringify(error));
                }
            });
        }
        this.localParticipant.cameraMute = enable;
        this.sendControlStatus();
    }

    toggleMicPrivacy(enable) {
        console.log('toggling mic privacy : ', enable);
        if (this.mixertest) {
            this.mixertest.send({ message: { request: 'configure', muted: enable } });
        }
        // if (enable) {
        //   this.sfutest.muteAudio();
        // }
        // else {
        //   this.sfutest.unmuteAudio();
        // }
        this.localParticipant.microphoneMute = enable;
        this.sendControlStatus();
    }

    toggleRecordingStatus(enable) {
        this.recording = enable;
        this.sendControlStatus();
    }

    toggleMicAll(enable) {
        this.microphoneMutedAll = enable;
        this.sendControlStatus();
    }

    toggleLockStatus(enable) {
        this.roomLocked = enable;
        this.sendControlStatus();
    }

    setHostDisconnectParticipant(id) {
        if (this.users[id]) {
            this.users[id].disconnectedByHost = true;
        }
    }

    sendChatMessage({ type, message, targetParticipantId = '', targetParticipantName = '' }) {
        let payload = {
            type,
            message,
            targetParticipantId,
            targetParticipantName,
            sender: {
                participantId: this.roomData?.localParticipant.participantId,
                name: this.roomData?.localParticipant.participantName,
                userId: this.roomData?.localParticipant.userId
            },
            reciver: {
                id: targetParticipantId,
                name: targetParticipantName
            }
        };

        let data;
        let userids = [];

        if (type === 'PublicChat') {
            userids = this.roomData?.participants.map((p) => p.userId);
            userids = userids.filter((e) => e !== this.roomData?.localParticipant.userId);
        } else {
            let targetedUser = this.roomData?.participants.find((o) => o.participantUri === targetParticipantId);
            userids.push(targetedUser?.userId);
        }

        data = {
            userIds: userids,
            payload
        };

        this.rtcService.sendEvent({
            event: 'posttomeetingparticipants',
            data
        });
    }

    setHostIdentity(sender) {
        if (this.users[sender.id]) {
            this.users[sender.id].isHost = true;
        }
    }

    toggleWindowShare(enable) {
        if (enable) {
            if (this.sfutestscreen) {
                this.sfutestscreen.createOffer({
                    media: { video: 'screen', audioSend: false, videoRecv: false }, // Screen sharing Publishers are sendonly
                    success: (jsep) => {
                        window.Janus.debug('Got publisher SDP!', jsep);
                        var publish = { request: 'configure', audio: false, video: true };
                        this.sfutestscreen.send({ message: publish, jsep: jsep });
                        this.screenSharing = true;
                        this.sendControlStatus();
                    },
                    error: (error) => {
                        window.Janus.error('WebRTC error:', error);
                    }
                });
            } else {
                this.janus.attach({
                    plugin: 'janus.plugin.videoroom',
                    opaqueId: this.opaqueId,
                    success: (pluginHandle) => {
                        this.sfutestscreen = pluginHandle;
                        this.updateDevices(true);
                        window.Janus.log(
                            'Plugin attached! (' + this.sfutestscreen.getPlugin() + ', id=' + this.sfutest.getId() + ')'
                        );
                        window.Janus.log(' -- This is a publisher/manager');
                        let register = {
                            request: 'join',
                            room: this.rtcService.getConferenceInfo().room.roomID,
                            pin: this.rtcService.getConferenceInfo().room.vpin,
                            ptype: 'publisher',
                            display: `screenShareFeed`,
                            isScreenSharing: true,
                            id: this.joinData.userId + '-screenShareFeed'
                        };
                        this.sfutestscreen.send({ message: register });
                    },

                    error: (error) => {
                        window.Janus.error(' -- Error attaching plugin...', error);
                    },

                    consentDialog: (on) => {
                        window.Janus.debug('Consent dialog should be ' + (on ? 'on' : 'off') + ' now');
                    },
                    iceState: (state) => {
                        window.Janus.log('ICE state changed to ' + state);
                    },

                    mediaState: (medium, on) => {
                        window.Janus.log('Janus ' + (on ? 'started' : 'stopped') + ' receiving our ' + medium);
                    },

                    webrtcState: (on) => {
                        window.Janus.log('Janus says our WebRTC PeerConnection is ' + (on ? 'up' : 'down') + ' now');
                    },

                    onmessage: (msg, jsep) => {
                        window.Janus.debug(' ::: Got a message (publisher) :::', msg);
                        var event = msg['videoroom'];
                        window.Janus.debug('Event: ' + event);
                        if (event) {
                            if (event === 'joined') {
                                // Publisher/manager created, negotiate WebRTC and attach to existing feeds, if any
                                this.myscreenshareid = msg['id'];
                                window.Janus.log('Successfully joined room ' + msg['room'] + ' with ID ' + msg['id']);
                                this.sfutestscreen.createOffer({
                                    media: { video: 'screen', audioSend: false, videoRecv: false }, // Screen sharing Publishers are sendonly
                                    success: (jsep) => {
                                        window.Janus.debug('Got publisher SDP!', jsep);
                                        var publish = { request: 'configure', audio: false, video: true };
                                        this.sfutestscreen.send({ message: publish, jsep: jsep });
                                        this.screenSharing = true;
                                        this.sendControlStatus();
                                    },
                                    error: (error) => {
                                        window.Janus.error('WebRTC error:', error);
                                    }
                                });
                            }
                        }
                        if (jsep) {
                            window.Janus.debug('Handling SDP as well...', jsep);
                            this.sfutestscreen.handleRemoteJsep({ jsep: jsep });
                        }
                    },

                    onlocalstream: (stream) => {
                        console.log('Received local stream ', stream);
                        stream.getVideoTracks()[0].onended = () => {
                            var unpublish = { request: 'unpublish' };
                            this.sfutestscreen.send({ message: unpublish });
                            this.screenSharing = false;
                            this.sendControlStatus();
                        };
                    },

                    onremotestream: (stream) => {
                        // The publisher stream is sendonly, we don't expect anything here
                    },

                    oncleanup: () => {
                        window.Janus.log(' ::: Got a cleanup notification: we are unpublished now :::');
                    }
                });
            }
        } else {
            if (this.sfutestscreen) {
                var unpublish = { request: 'unpublish' };
                this.sfutestscreen.send({ message: unpublish });
                this.screenSharing = false;
                this.sendControlStatus();
            }
        }

        return;
    }

    addUserInWaitingRoom(user) {
        if (user.memberName) {
            this.waitingUsers[user.memberId] = user;
            this.sendControlStatus();
        }
    }

    removeUserFromWaitingRoom(user) {
        delete this.waitingUsers[user.memberId];
        this.sendControlStatus();
    }

    setSharingScreenStatus(enable) {
        this.alreadySharingScreen = enable;
    }

    getLocalParticipant() {
        return this.localParticipant;
    }

    private sendControlStatus(updates: any = {}) {
        this.participantsStatus$.next({
            users: this.users,
            microphoneMutedAll: this.microphoneMutedAll,
            roomLocked: this.roomLocked,
            recording: this.recording,
            screenSharing: this.screenSharing,
            waitingUsers: this.waitingUsers,
            alreadySharingScreen: this.alreadySharingScreen,
            vcs: this.vcs,
            participantJoined: updates.participantJoined,
            participantLeft: updates.participantLeft
        });
    }

    private handleWindowEvents() {
        // window.onVidyoClientLoaded = (status) => {
        //   this.onLoad(status);
        // };

        window.onresize = () => {};

        window.onbeforeunload = () => {};

        window.addEventListener('online', (e) => {
            this.isUserOnline = true;
        });
        window.addEventListener('offline', (e) => {
            this.isUserOnline = false;
        });
    }

    clear() {
        this.localParticipant = {};
        this.joinData = {};
        this.users = {};
        this.microphoneMutedAll = false;
        this.roomLocked = false;
        this.recording = false;
        this.screenSharing = false;
        this.reconnecting = false;
        this.whiteboardShared = false;
        clearInterval(this.reConnectInterval);
    }

    changeCamera() {
        this.vidyoConnector.CycleCamera();
    }

    changeSpeaker() {
        this.vidyoConnector.CycleSpeaker();
    }

    changeMicrophone() {
        this.vidyoConnector.CycleMicrophone();
    }
    setWhiteboardStatus(state) {
        this.whiteboardShared = state;
        this.vidyoConnector
            .AssignViewToCompositeRenderer({
                viewId: this.joinData.viewId,
                viewStyle: 'VIDYO_CONNECTORVIEWSTYLE_Default',
                remoteParticipants: state ? 3 : 8
            })
            .then(() => {
                this.loggerService.log('AssignViewToCompositeRenderer Success');
            })
            .catch(() => {
                this.loggerService.error('AssignViewToCompositeRenderer Failed');
            });
    }

    releaseDevices() {}
    stopPreviewTrack() {}
}
