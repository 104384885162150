import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';

import { AppService } from './app.service';
import { AuthService } from './auth.service';
import { GuestAuthService } from './guest-auth.service';
import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root'
})
export class WhiteboardService {
    private wb$: BehaviorSubject<any> = new BehaviorSubject({});
    state = 'stop'; //start,get,active,stop
    restart = false;
    private isWhiteBoardHost = false;
    constructor(
        private restService: RestService,
        private appService: AppService,
        private authService: AuthService,
        private guestAuthService: GuestAuthService
    ) {}

    getWhiteboardState() {
        return this.wb$;
    }

    setWhiteboardState(state) {
        this.state = state;
        this.restart = false;
        this.updateWhiteboardStatus();
    }

    setRestartState(restart) {
        this.state = 'stop';
        this.restart = restart;
        this.updateWhiteboardStatus();
    }

    private updateWhiteboardStatus() {
        this.wb$.next({
            state: this.state,
            restart: this.restart,
            isWhiteBoardHost: this.isWhiteBoardHost
        });
    }

    getWhiteboardUrl(jiomeetId) {
        const isAuthenticated = this.authService.getIsAuthenticated();

        let headers: HttpHeaders = new HttpHeaders();
        if (!isAuthenticated) {
            const guestInfo = this.guestAuthService.getAuthInfo();
            headers = headers.set('Authorization', guestInfo.jwt);
        }
        return this.restService.get(`api/rooms/${jiomeetId}/whiteboard`, { headers });
    }

    startWhiteboard(jiomeetId, historyId, participantUri) {
        const isAuthenticated = this.authService.getIsAuthenticated();

        return this.restService.post(
            `api/rooms/${jiomeetId}/whiteboard/startsharing${!isAuthenticated ? '/guest' : ''}`,
            { participantUri, historyId }
        );
    }

    setWhiteboardHost(state) {
        this.isWhiteBoardHost = state;
    }

    getWhiteboardHost() {
        return this.isWhiteBoardHost;
    }

    clear() {
        this.state = 'stop';
        this.restart = false;
        this.isWhiteBoardHost = false;
        this.updateWhiteboardStatus();
    }
}
