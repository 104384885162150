import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/core';

@Component({
    selector: 'app-restricted-meeting-join',
    templateUrl: './restricted-meeting-join.component.html',
    styleUrls: ['./restricted-meeting-join.component.scss']
})
export class RestrictedMeetingJoinComponent implements OnInit {
    @Output() close: EventEmitter<any> = new EventEmitter();

    isAuthenticated;

    constructor(private router: Router, private authService: AuthService) {}

    ngOnInit() {
        this.isAuthenticated = this.authService.getIsAuthenticated() || false;
    }

    closeWarning() {
        this.close.emit();
    }

    navigateToSignIn() {
        this.router.navigate(['/login']);
    }
}
