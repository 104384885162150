<div class="info-banner-popup">
    <div class="bubble">
        <div class="type" *ngIf="bannerData?.type === 'success'">
            <img
                alt=""
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICA8ZyBpZD0iR3JvdXBfMTE5Mjc1IiBkYXRhLW5hbWU9Ikdyb3VwIDExOTI3NSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTc0ODkgMTcyMDEpIj4KICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfMjQ4OTIiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDI0ODkyIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNzQ4OSAtMTcyMDEpIiBmaWxsPSJub25lIi8+CiAgICA8ZyBpZD0iSWNvbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3NDg5IC0xNzIwMSkiPgogICAgICA8Y2lyY2xlIGlkPSJFbGxpcHNlXzE3NDM2IiBkYXRhLW5hbWU9IkVsbGlwc2UgMTc0MzYiIGN4PSIxMCIgY3k9IjEwIiByPSIxMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMS45OTkgMS45OTkpIiBmaWxsPSIjZmZmIi8+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzg4MTEzIiBkYXRhLW5hbWU9IlBhdGggODgxMTMiIGQ9Ik0xMiwyYTEwLDEwLDAsMSwwLDMuODI3Ljc2MUExMCwxMCwwLDAsMCwxMiwyWm01LjIxLDcuNzEtNiw2YTEsMSwwLDAsMS0xLjQyLDBsLTMtM2ExLDEsMCwxLDEsMS40Mi0xLjQybDIuMjksMi4zLDUuMjktNS4zYTEsMSwwLDEsMSwxLjQyLDEuNDJaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwKSIgZmlsbD0iIzI1YWIyMSIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg=="
            />
        </div>
        <div class="type" *ngIf="bannerData?.type === 'error'">
            <img
                alt=""
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICA8ZyBpZD0iR3JvdXBfMTE5Mjc1IiBkYXRhLW5hbWU9Ikdyb3VwIDExOTI3NSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTc0ODkgMTcyMDEpIj4KICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfMjQ4OTIiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDI0ODkyIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNzQ4OSAtMTcyMDEpIiBmaWxsPSJub25lIi8+CiAgICA8ZyBpZD0iXzFJY29uIiBkYXRhLW5hbWU9IjFJY29uIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTc0ODkgLTE3MjAxKSI+CiAgICAgIDxjaXJjbGUgaWQ9IkVsbGlwc2VfMTc0MDciIGRhdGEtbmFtZT0iRWxsaXBzZSAxNzQwNyIgY3g9IjEwIiBjeT0iMTAiIHI9IjEwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyIDIpIiBmaWxsPSIjZmZmIi8+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzg4MTEyIiBkYXRhLW5hbWU9IlBhdGggODgxMTIiIGQ9Ik0xMiwyYTEwLDEwLDAsMSwwLDMuODI3Ljc2MUExMCwxMCwwLDAsMCwxMiwyWk0xMSw2LjVhMSwxLDAsMCwxLDIsMHY2YTEsMSwwLDAsMS0yLDBabTEsMTJhMS41LDEuNSwwLDEsMSwxLjA2MS0uNDM5QTEuNSwxLjUsMCwwLDEsMTIsMTguNVoiIGZpbGw9IiNmMDZkMGYiLz4KICAgIDwvZz4KICA8L2c+Cjwvc3ZnPgo="
            />
        </div>
        <div class="content">
            <div class="header">
                {{ bannerData?.title }}
            </div>
            <div>
                {{ bannerData?.content }}
            </div>
        </div>
        <div class="close">
            <img
                alt=""
                (click)="closeBanner()"
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDQwIDQwIj4KICA8ZyBpZD0iR3JvdXBfMTE5MzAxIiBkYXRhLW5hbWU9Ikdyb3VwIDExOTMwMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTc5MiAtMjIyKSI+CiAgICA8Y2lyY2xlIGlkPSJFbGxpcHNlXzE3NDA4IiBkYXRhLW5hbWU9IkVsbGlwc2UgMTc0MDgiIGN4PSIyMCIgY3k9IjIwIiByPSIyMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNzkyIDIyMikiIGZpbGw9Im5vbmUiLz4KICAgIDxnIGlkPSJHcm91cF8xMTkyODkiIGRhdGEtbmFtZT0iR3JvdXAgMTE5Mjg5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxODI4OC41MDQgMTc0MzAuNTA0KSI+CiAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfMjQ4OTIiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDI0ODkyIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNzQ4OC41MDQgLTE3MjAwLjUwNCkiIGZpbGw9Im5vbmUiLz4KICAgICAgPHBhdGggaWQ9ImNsb3NlIiBkPSJNMTMuOCwxMi4zM2w2LjU2LTYuNTVBMS4wNDYsMS4wNDYsMCwxLDAsMTguODgsNC4zbC02LjU1LDYuNTZMNS43ODEsNC4zQTEuMDQ2LDEuMDQ2LDAsMCwwLDQuMyw1Ljc4MWw2LjU2LDYuNTVMNC4zLDE4Ljg4YTEuMDQ2LDEuMDQ2LDAsMSwwLDEuNDc5LDEuNDc5TDEyLjMzLDEzLjhsNi41NSw2LjU2YTEuMDQ2LDEuMDQ2LDAsMSwwLDEuNDc5LTEuNDc5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3NDg4LjgzNiAtMTcyMDAuODM2KSIgZmlsbD0iIzk3OWNhYSIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg=="
            />
        </div>
    </div>
</div>
