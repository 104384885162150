<div class="notifications-container">
    <form class="custom-form" [formGroup]="emailSMSForm">
        <fieldset>
            <legend class="Meetingnotifocus" tabindex="0" aria-label="Meeting notifications setting">
                {{ 'user_dashboard_settings.notification.whatsapp_notifications' | translate }}
            </legend>
            <br />
            <div class="checkbox-wrapper">
                <div
                    class="custom-control custom-checkbox"
                    *ngIf="user?.source === 'email' || user?.source === 'saml' || user?.source === 'google'"
                >
                    <input
                        tabindex="0"
                        aria-label="Email Notification"
                        type="checkbox"
                        class="custom-control-input"
                        id="emailCheckBox"
                        formControlName="enableEmailNotification"
                    />
                    <label class="custom-control-label pointer" for="emailCheckBox">
                        {{ 'user_dashboard_settings.notification.email_label' | translate }}
                    </label>
                    <div class="help">
                        {{ 'user_dashboard_settings.notification.email_desc' | translate }}
                    </div>
                    <br />
                </div>
                <div
                    class="custom-control custom-checkbox"
                    *ngIf="user?.source === 'mobile' || user?.source === 'saml'"
                >
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="SMSCheckBox"
                        formControlName="enableSMSNotification"
                    />
                    <label class="custom-control-label pointer" for="SMSCheckBox">
                        {{ 'user_dashboard_settings.notification.sms_label' | translate }}
                    </label>
                    <div class="help">
                        {{ 'user_dashboard_settings.notification.sms_desc' | translate }}
                    </div>
                    <br />
                </div>
            </div>
        </fieldset>
    </form>
    <form class="custom-form" [formGroup]="form">
        <div class="checkbox-wrapper">
            <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    id="defaultUnchecked4"
                    formControlName="enableNotification"
                />
                <label class="custom-control-label pointer" for="defaultUnchecked4">
                    {{ 'user_dashboard_settings.notification.enable' | translate }}
                </label>
                <div class="help">
                    {{ 'user_dashboard_settings.notification.enable_description' | translate }}
                </div>
            </div>
            <br />
            <div class="phone-wrapper" *ngIf="showPhoneInput">
                <label for="phone-input" class="phone-label">{{
                    'user_dashboard_settings.notification.your_number' | translate
                }}</label
                ><br />
                <span>
                    <input
                        type="text"
                        name="std-code"
                        id="std-code"
                        value="+91"
                        readonly
                        [class.invalid]="!phoneValid"
                        [class.field-disabled]="phoneAdded"
                    />
                    <input
                        type="text"
                        id="phone-input"
                        formControlName="phone"
                        maxlength="10"
                        (keydown.enter)="$event.preventDefault()"
                        [class.invalid]="!phoneValid"
                        (keydown.backspace)="handleBackspace($event)"
                        [readonly]="phoneAdded"
                        [class.field-disabled]="phoneAdded"
                        placeholder="{{ 'user_dashboard_settings.notification.enter' | translate }}"
                    />&nbsp;&nbsp;&nbsp;
                    <img
                        src="assets/img/clear-text.svg"
                        alt="Clear button"
                        *ngIf="!phoneAdded && isPhoneEmpty()"
                        (click)="clearPhoneInput()"
                    />
                    <button class="edit" *ngIf="phoneAdded" (click)="onEdit()"></button>
                </span>
                <button
                    type="submit"
                    class="custom-button bg-primary"
                    [class.disabled]="!enableSubscribe"
                    *ngIf="!phoneAdded"
                    (click)="onSubscribe()"
                >
                    {{ 'user_dashboard_settings.notification.subscribe' | translate }}
                </button>
                <br />
                <span class="text-error" *ngIf="!phoneValid">{{
                    'user_dashboard_settings.notification.invalid_mobile_number' | translate
                }}</span
                ><br /><br />
            </div>
            <div class="otp-wrapper" *ngIf="showOtpInput">
                <label for="otp-input" class="otp-label">
                    <span *ngIf="showOtpInput">
                        {{ getPhoneNo() }}
                    </span> </label
                ><br />
                <span>
                    <input
                        type="text"
                        id="otp-input"
                        formControlName="otp"
                        maxlength="6"
                        placeholder="{{ 'user_dashboard_settings.notification.otp' | translate }}"
                    />&nbsp;&nbsp;&nbsp;
                    <button type="button" class="resend" (click)="onSubscribe()">
                        {{ 'user_dashboard_settings.notification.resend_OTP' | translate }}
                    </button>
                </span>
                <button
                    type="submit"
                    class="custom-button bg-primary"
                    [class.disabled]="!enableOTPButton"
                    (click)="onConfirm()"
                >
                    {{ 'user_dashboard_settings.notification.confirm_OTP' | translate }}
                </button>
                <br /><br />
            </div>
        </div>
        <div class="checkbox-wrapper">
            <img src="assets/img/whatsapp.png" alt="Info icon" />
            <span class="text">
                <a
                    href="{{ whatsAppBotURL }}"
                    target="_blank"
                    tabindex="0"
                    aria-label="Message us on whatsApp"
                    (keyup.enter)="handlemessageusevent($event)"
                >
                    {{ 'user_dashboard_settings.notification.message_us' | translate }}
                </a>
            </span>
            <div class="help">
                {{ 'user_dashboard_settings.notification.message_description' | translate }}
            </div>
        </div>
    </form>
    <hr />
    <h5>{{ 'user_dashboard_settings.notification.incoming_calls' | translate }}</h5>
    <div tabindex="0" class="custom-form" (keydown.Tab)="setFocusOnElement()">
        <audio id="testAudio" #audioPlayer>
            <source src="./assets/audio/call.mp3" type="audio/mpeg" />
        </audio>
        <p-dropdown
            class="p-dropdown"
            [options]="incomingCallAudios"
            [(ngModel)]="selectedAudio"
            [placeholder]="'user_dashboard_settings.notification.selectRingtone' | translate"
            (ngModelChange)="selectAudio()"
        >
            <ng-template let-audio pTemplate="selectedItem">
                {{ audio.tname | translate }}
            </ng-template>
            <ng-template let-audio pTemplate="selectedAudio">
                <div class="ringtone">
                    <div class="audio-details">
                        {{ audio.tname | translate }}
                        <ng-container>
                            <img
                                *ngIf="audio?.name === selectedAudio?.name"
                                class="selected-icon"
                                src="assets/img/website/tick_Icon.svg"
                                alt="Selected Icon"
                            />
                            <div
                                class="ppAudio"
                                (click)="playorpause($event, audio)"
                                (keydown.enter)="playorpause($event, audio)"
                                tabindex="0"
                                [attr.aria-label]="audio.isPlaying ? 'Stop' : 'Play'"
                                *ngIf="audio.name !== 'Silent'; else silentTemplate"
                            >
                                <img
                                    [src]="
                                        audio.isPlaying
                                            ? 'assets/img/website/audio_PauseIcon.svg'
                                            : 'assets/img/website/audio_PlayIcon.svg'
                                    "
                                    alt="Play/Pause Icon"
                                />
                            </div>
                        </ng-container>
                    </div>
                </div>
                <ng-template #silentTemplate> </ng-template>
            </ng-template>
        </p-dropdown>
    </div>
</div>
