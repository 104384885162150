export const SOCKET_EVENTS = {
    CALL: 'call',
    CALL_ACCEPT: 'callAccept',
    MISSED_CALL: 'missedCall',
    JOIN_CALL: 'joinCall',
    OUTGOING_CALL_UPDATE: 'outgoingCallUpdate',
    JOIN_FROM_HISTORY: 'joinFromHistory',
    NOTIFY_HISTORY_TO_OWNER: 'notifyHistoryToOwner',
    GENERIC: 'generic',
    CALL_FINISH: 'callFinish',
    CALL_NOT_ANSWERED_NOTIFICATION: 'CallNotAnswerNotification',
    CALL_CUT_NOTIFICATION: 'CallCutNotification',
    IN_ANOTHER_CALL_NOTIFICATION: 'InAnotherCallNotification',
    CALL_DROP_TOAST_NOTIFICATION: 'CallDropToastNotification',
    NOTIFICATION_ADDED: 'notificationAdded',
    GROUP_ADD_OR_EDIT: 'GroupAddOrEdit',
    GROUP_DELETE: 'GroupDelete',
    DISCONNECT_ACTIVE_CALL: 'disconnectActiveCall',
    PARTICIPANT_ADDED: 'participantAdded',
    PARTICIPANT_LEFT: 'participantLeft',
    IS_ONLINE: 'isonline',
    MEETING_STARTED: 'MeetingStarted',
    JOINING_INVITATION: 'joiningInvitation',
    JOINING_INVITATION_STOPPED: 'joiningInvitationWaitingStopped',
    WAITING_ROOM_ALLOW_ACTION: 'waitingRoomJoiningInvitationAction',
    REFRESH_MEETINGS: 'refreshmeetings',
    REFRESH_MEETINGS_LIST: 'refreshMeetingList',
    ROOM_CONNECTION_STATUS_UPDATE: 'roomconnectionstatusupdate',
    CHAT_MESSAGE: 'chatMessage',
    CHAT_MESSAGE_READ: 'chatMessageRead',
    REQUEST_SHARE_CONTROL: 'requestShareControl',
    START_SHARE_CONTROL: 'startShareControl',
    STOP_SHARE_CONTROL: 'stopShareControl',
    REMOTE_CONTROL_EVENTS: 'remoteControl',
    BREAKOUT_ROOM_START: 'breakoutRoomStart',
    BREAKOUT_ROOM_MOVE: 'breakoutRoomMove',
    BREAKOUT_ROOM_UPDATE: 'breakoutRoomUpdate',
    BREAKOUT_ROOM_END: 'breakoutRoomEnd',
    BR_RO0M_END_CALL: 'breakoutRoomEndCall',
    NORECORDERATTACHED: 'NORECORDERATTACHED',
    POST_TO_MEETING_PARTICIPANTS: 'posttomeetingparticipants',
    END_ALL: 'endCall',
    MOVE_TO_WAITING_ROOM: 'moveToWaitingRoom',
    DROP_CALL_TRANSFER: 'dropExisingCall',
    ADD_EXISTING_CALL: 'addToExistingCall',
    NOTIFY_DECLINE_TO_ALL: 'notifyDeclineToAll',
    REFRESH_FAVOURITES: 'refreshfavourites',
    CHAT_ADD_EDIT: 'chatThreadAddOrEdit',
    PROFILE_UPDATE: 'profileUpdate',
    CUSTOM_LIVESTREAM: 'customlivestream',
    CUSTOM_LIVESTREAM_ERROR: 'customlivestreamerror',
    YT_LIVESTREAM_ERROR: 'ytlivestreamerror',
    FB_LIVESTREAM_ERROR: 'fblivestreamerror',
    MULTI_LIVE_STREAM: 'multilivestream',
    YT_LIVESTREAM: 'ytlivestream',
    FB_LIVESTREAM: 'fblivestream',
    RECORDING_STARTED: 'recordingStarted',
    RECORDING_STOPPPED: 'recordingStopped',
    CO_HOSTS_CHANGED: 'coHostsChanged',
    GROUP_ADD_OR_DELETE: 'GroupAddOrEdit',
    VC_EVENT: 'vcEvent',
    CHAT_MESSAGE_READ_BY_EVERYONE: 'chatMessageReadByEveryOne',
    CHAT_MESSAGE_UPDATE: 'chatMessageUpdate',
    NOTIFY_USERS_ABOUT_CONFIG_UPDATE: 'notifyUsersAboutConfigUpdate'
};
