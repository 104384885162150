<div
    class="emojis"
    tabindex="0"
    aria-label="emoji menu opened! please press tab key to navigate and explore emoji's menu"
>
    <span
        *ngFor="let emoji of emojis"
        title="{{ emoji.name }}"
        (click)="selectEmoji(emoji)"
        (keydown.enter)="selectEmoji(emoji)"
        tabindex="0"
        aria-label=" {{ emoji.char }}"
    >
        {{ emoji.char }}
    </span>
</div>
