<div class="private-chat-container" (animationend)="animationDone($event)">
    <div class="header">
        <div class="back-wrapper">
            <div
                class="back"
                (click)="close()"
                (keydown.enter)="close()"
                tabindex="0"
                role="button"
                aria-label="please press back button to close attachmentList"
            >
                <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 477.175 477.175"
                    style="enable-background: new 0 0 477.175 477.175"
                    xml:space="preserve"
                >
                    <g>
                        <path
                            d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225
              c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"
                        />
                    </g>
                </svg>
            </div>
            <span>Files ({{ getAttachedFilesCount() }})</span>
        </div>
    </div>
    <div class="message-list full" #messageslist>
        <div class="attachment" *ngIf="!loader">
            <div *ngIf="attachmentList.length > 0">
                <div *ngFor="let message of attachmentList.slice().reverse(); trackBy: trackByFuntion">
                    <div
                        class="attachment-bubble"
                        *ngIf="
                            message?.messageComponent?.attachments?.length &&
                            message?.messageComponent?.attachments[0]?.imageTrancodedURL !== ''
                        "
                    >
                        <div class="showAttachmentsContent">
                            <div class="showAttachmentsText">
                                <div *ngFor="let attachmnt of message?.messageComponent?.attachments">
                                    <app-chat-attachment
                                        [message]="message"
                                        [showAttachments]="isDesktopApp()"
                                        [item]="attachmnt"
                                        [jiomeetId]="threadId"
                                        (refresh)="handleRefreshListEvent($event)"
                                    >
                                    </app-chat-attachment>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="attachmentList.length == 0">
                <div class="noAttachments">
                    <img alt="" src="assets/images/allDocsIcons.svg" />
                    <div class="noAttachmentsText">
                        <h5>No shared files</h5>
                        <h6>Files that are added to the chat will show up here</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="attachment" *ngIf="loader">
            <app-loader></app-loader>
        </div>
    </div>
</div>
