<div class="profile-settings-container">
    <div *ngIf="showProfile" class="profile-container">
        <div class="profile-setting row">
            <div class="profile-setting-left col-md-4">
                <div
                    class="gradientBorder"
                    [ngClass]="[
                        user.isOTT && user.proStatus ? 'gradientBorder-pro' : '',
                        !user.isOTT && user.tenantId != null ? 'gradientBorder-custom' : ''
                    ]"
                >
                    <ng-container *ngIf="!user?.imageMeta?.path; else elseTemplate">
                        <span
                            class="textinitials"
                            [ngStyle]="{ 'background-color': colorChosen }"
                            (click)="toggleChangeProfilePicture()"
                            >{{ participantInitials }}</span
                        >
                    </ng-container>
                    <ng-template #elseTemplate>
                        <img
                            src="{{ originalImage }}"
                            class="profile-image"
                            alt=""
                            (click)="toggleChangeProfilePicture()"
                        />
                    </ng-template>
                </div>
                <!-- <img src="{{ originalImage }}" class="profile-image" alt="" (click)="toggleChangeProfilePicture()" /> -->
                <div class="dropdown">
                    <div
                        class="profilefocus pointer dropdown-toggle"
                        tabindex="0"
                        aria-label="change the profile"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        role="button"
                        (keyup.enter)="handleKeyUp($event)"
                    >
                        <a class="blue mr-2">{{ 'user_dashboard_settings.profile.change' | translate }}</a>
                        <span>
                            <img alt="" src="assets/img/arrow_down_icn_blue.svg" />
                        </span>
                    </div>
                    <ul class="dropdown-menu">
                        <li>
                            <a
                                (click)="toggleChangeProfilePicture()"
                                tabindex="0"
                                role="button"
                                aria-label="{{ 'user_dashboard_settings.profile.change_picture' | translate }}"
                                (keyup.enter)="toggleChangeProfilePicture()"
                                >{{ 'user_dashboard_settings.profile.change_picture' | translate }}</a
                            >
                        </li>
                        <li *ngIf="user.imageMeta?.path">
                            <a
                                (click)="removeProfilePicture()"
                                tabindex="0"
                                role="button"
                                aria-label="{{ 'user_dashboard_settings.profile.remove_picture' | translate }}"
                                (keyup.enter)="removeProfilePicture()"
                                >{{ 'user_dashboard_settings.profile.remove_picture' | translate }}</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div
                class="profile-setting-right name col-md-8"
                *ngIf="!isNameBeingEdited"
                [ngClass]="{ isMobile: isMobile }"
            >
                <h3 class="full-name" title="{{ fullName }}">{{ fullName }}</h3>
                <div
                    tabindex="0"
                    role="button"
                    aria-label="Edit Profile"
                    class="edit-icn-fullName"
                    alt="edit profile"
                    *ngIf="user?.isOTT"
                    (click)="toggleNameEdit()"
                    (keydown.enter)="toggleNameEdit()"
                    pTooltip="{{ 'user_dashboard_settings.profile.edit_tooltip' | translate }}"
                    tooltipPosition="bottom"
                ></div>
            </div>
            <div class="profile-setting-right col-md-8" *ngIf="isNameBeingEdited" [ngClass]="{ isMobile: isMobile }">
                <form action="" [formGroup]="form">
                    <div class="input-field mb-3" [ngClass]="{ isMobile: isMobile }">
                        <label class="first-name">{{ 'user_dashboard_settings.profile.first_name' | translate }}</label>
                        <i aria-label="Required information">*</i>
                        <input
                            type="text"
                            maxlength="50"
                            formControlName="firstName"
                            (keydown)="exceededCharacterLimit($event, 50)"
                        />
                    </div>
                    <div class="input-field mb-3" [ngClass]="{ isMobile: isMobile }">
                        <label class="first-name">{{ 'user_dashboard_settings.profile.last_name' | translate }}</label>
                        <i aria-label="Required information">*</i>
                        <input
                            type="text"
                            maxlength="50"
                            formControlName="lastName"
                            (keydown)="exceededCharacterLimit($event, 50)"
                        />
                    </div>
                    <div class="mt-2 text-error" *ngIf="errors.global">{{ errors.global }}</div>
                    <div class="button-group" [ngClass]="{ isMobile: isMobile }">
                        <button
                            tabindex="0"
                            class="custom-button bg-secondary cancel"
                            type="button"
                            (click)="toggleNameEdit()"
                        >
                            {{ 'user_dashboard_settings.cancel' | translate }}
                        </button>
                        <button
                            type="submit"
                            tabindex="0"
                            class="custom-button bg-primary save"
                            [disabled]="!form.valid || !form.dirty"
                            (click)="changeName()"
                        >
                            {{ 'user_dashboard_settings.save' | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="alert-notification">
            <p *ngIf="currentUser.isOTT && isNameUpdated">
                {{ 'user_dashboard_settings.profile.message_ott' | translate }}
            </p>
            <p *ngIf="!currentUser.isOTT && currentUser.tenantId != null && isNameUpdated">
                {{ 'user_dashboard_settings.profile.message_admin_tenant' | translate }}
            </p>
        </div>

        <hr class="divider mt-4 mb-4" />
        <div class="profile-setting row" *ngIf="user?.email">
            <div class="profile-setting-left col-md-4" tabindex="0">
                {{ 'user_dashboard_settings.profile.sign_in_email' | translate }} -
            </div>
            <div class="profile-setting-right col-md-8" [ngClass]="{ mobileDevice: isMobile }" tabindex="0">
                {{ user.email }}
            </div>
        </div>
        <div class="profile-setting row" *ngIf="user?.phoneNo">
            <div class="profile-setting-left col-md-4" tabindex="0">
                {{ 'user_dashboard_settings.profile.sign_in_mobile' | translate }} -
            </div>
            <div class="profile-setting-right col-md-8" tabindex="0">
                {{ user.phoneNo }}
            </div>
        </div>
        <div class="profile-setting row" *ngIf="user?.source === 'email' && !isMobile">
            <div class="profile-setting-left col-md-4 pb-4" tabindex="0">
                {{ 'user_dashboard_settings.profile.sign_in_password' | translate }} -
            </div>
            <div class="profile-setting-right col-md-8 pt-2" *ngIf="user?.source === 'email'">
                ****************
                <div
                    class="change-password"
                    *ngIf="user?.source === 'email'"
                    (click)="toggleChangePassword()"
                    tabindex="0"
                >
                    {{ 'user_dashboard_settings.profile.change_password' | translate }}
                </div>
            </div>
        </div>
        <div class="profile-setting row" *ngIf="user?.source === 'email' && isMobile">
            <div class="profile-setting-left col-md-4" tabindex="0">
                {{ 'user_dashboard_settings.profile.sign_in_password' | translate }} -
            </div>
            <div class="profile-setting-right col-md-8" *ngIf="user?.source === 'email'">
                ****************
                <div
                    class="change-password"
                    *ngIf="user?.source === 'email'"
                    (click)="toggleChangePassword()"
                    tabindex="0"
                >
                    {{ 'user_dashboard_settings.profile.change_password' | translate }}
                </div>
            </div>
        </div>
        <hr class="divider mt-4 mb-4" />
        <div class="profile-setting row">
            <ng-container *ngIf="user?.subscription?.toLowerCase() === 'jiochargeit'">
                <div class="profile-setting-left col-md-4" tabindex="0">{{ 'website_header.invoice' | translate }}</div>
                <div class="profile-setting-right col-md-8">
                    <label class="badge-image download" tabindex="0" (click)="downloadInvoice()"
                        >{{ 'website_download.download' | translate }}
                    </label>
                    <img
                        src="assets/images/download-invoice.svg"
                        class="download-btn"
                        alt=""
                        (click)="downloadInvoice()"
                    />
                </div>
            </ng-container>
            <div class="profile-setting-left col-md-4" tabindex="0">{{ 'website_header.licence' | translate }}</div>
            <div *ngIf="isOTT && proStatus" class="profile-setting-right col-md-8">
                <label class="badge-image" tabindex="0"
                    >{{ (user?.planName === 'pro' ? 'website_header.pro' : 'website_header.plus') | translate }}
                </label>
                <img src="assets/images/crown.svg" alt="" />
            </div>
            <div *ngIf="!isOTT && tenantId != null" class="profile-setting-right col-md-8">
                <label class="badge-image" tabindex="0">{{ 'website_header.custom' | translate }} </label>
                <img src="assets/images/custom-member.svg" alt="" />
            </div>
            <div *ngIf="isOTT && !proStatus" class="profile-setting-right col-md-8">
                <label class="badge-image" tabindex="0">{{ 'website_header.free' | translate }} </label>
                <p class="p-description" tabindex="0">
                    {{ 'website_header.plus_plan_support' | translate }}
                </p>
                <button type="Upgrade" (click)="navigateToPricing()" tabindex="0" class="custom-button bg-primary save">
                    {{ 'website_header.upgrade_to_plus' | translate }}
                    <span>
                        <img src="assets/images/recharge.svg" alt="" />
                    </span>
                </button>
            </div>
        </div>

        <div class="profile-setting row" *ngIf="isOTT && proStatus">
            <div class="profile-setting-left col-md-4" tabindex="0">{{ 'website_header.expires_on' | translate }}</div>
            <div
                class="profile-setting-right col-md-8"
                [ngStyle]="{ color: subscriptionDaysLeft <= 30 ? '#E30513' : '#29385A' }"
                tabindex="0"
            >
                {{ expiresOnDate }}
            </div>
        </div>
    </div>

    <app-change-profile-picture *ngIf="isChangeProfilePicture" (close)="toggleChangeProfilePicture()">
    </app-change-profile-picture>
    <app-change-password *ngIf="isChangePassword" (close)="toggleChangePassword()"></app-change-password>
</div>
