import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import {
    AgoraService,
    AppService,
    AuthService,
    GoogleTagManagerService,
    RtcService,
    UserService,
    EventEmitterService,
    VideoWrapperService,
    JmMediaService,
    SessionStorageService,
    LocalStorageService
} from 'src/app/core';
import { VirtualBgService } from 'src/app/core/services/virtual-bg.service';
import { style } from '@angular/animations';
import { Subscription } from 'rxjs';
import { GA_EVENTS } from '../ga-events';
import { APP_EVENTS } from 'src/app/constants';
import { Router } from '@angular/router';
import { GA_NEW } from 'src/app/shared/ga-new';
@Component({
    selector: 'app-virtual-background',
    templateUrl: './virtual-background.component.html',
    styleUrls: ['./virtual-background.component.scss']
})
export class VirtualBackgroundComponent implements OnInit {
    noneSrc = 'assets/images/none-icon.svg';
    blurSrc = 'assets/images/blur-small.svg';
    customSrc = 'assets/images/plus-circle.svg';
    @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;

    selectedImageElement: any;
    showImagePanel = false;

    public imagesRowOne: any[] = [];

    public imagesRowTwo: any[] = [];

    public customImages: any[] = [];

    public festiveImagesRow: any[] = [];
    subscriptions: Subscription[] = [];

    imageChangedEvent: any = '';
    imagePath: any;
    url: string | ArrayBuffer;
    agoraRTC: any;
    videoElement: any;
    canvasElement: any;
    imageElement: any;
    canvasCtx: any;
    camera: any;
    control = window;
    selfieSegmentation: any;
    videoTrack: any;
    backgroundConfig = 'none';
    videoService;
    isJMMeeting;
    isAgoraMeeting;
    @ViewChild('videoPreview') video: ElementRef;
    @ViewChild('fileInput') fileElement: ElementRef;
    breakoutRoomInfo;
    mirroView = false;

    constructor(
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private agoraUtility: AgoraService,
        private vbService: VirtualBgService,
        private appService: AppService,
        private rtcService: RtcService,
        private gTagService: GoogleTagManagerService,
        private agoraService: AgoraService,
        private videoWrapperService: VideoWrapperService,
        private eventEmitterService: EventEmitterService,
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
        public sessionStorageService: SessionStorageService,
        private localStorageService: LocalStorageService
    ) {
        this.videoService = this.videoWrapperService.getVideoService();
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.vbService.getCustomImages().subscribe((images) => {
                this.customImages = images;
            })
        );
        this.mirroView = !this.localStorageService.getItem('isMirrorViewEnabled');
        this.isJMMeeting = this.rtcService.getIsJMMeeting();
        this.isAgoraMeeting = this.rtcService.getIsAgoraMeeting();
        this.backgroundConfig = this.vbService.backgroundConfig.type;
        let allStaticImages = this.appService.getConfigVariable('defaultsVirutalBackgroundImages');
        let festiveImages = allStaticImages.filter((image) => image.isFestival);
        festiveImages.forEach((imageObj, i) => {
            this.festiveImagesRow.push({ src: imageObj.imageUrl, id: i + 1, description: imageObj.imageName });
        });
        if (!this.authService.getIsAuthenticated() || this.userService.getUserSync().isOTT) {
            allStaticImages = allStaticImages.filter((image) => image.isOTT);
        }
        allStaticImages.forEach((imageObj, i) => {
            if (!imageObj.isFestival)
                this.imagesRowOne.push({ src: imageObj.imageUrl, id: i + 1, description: imageObj.imageName });
        });

        this.vbService.loadCustomImages();
        this.toastrService.overlayContainer = this.toastContainer;
    }

    async ngAfterViewInit() {
        this.startVideo();
    }

    setBackgroundConfig(type, url = undefined) {
        this.vbService.setBgConfig({ type, imageUrl: url });
        if (type === 'none') {
            this.gTagService.sendEvent(GA_EVENTS.NONE_IMAGE_CLICK);
            this.gTagService.sendEvent(GA_NEW.NONE_IMAGE_CLICK);
        } else if (type === 'blur') {
            this.gTagService.sendEvent(GA_EVENTS.BLUR_IMAGE_CLICK);
            this.gTagService.sendEvent(GA_NEW.BLUR_IMAGE_CLICK);
        }
    }

    blurBackground() {
        this.backgroundConfig = 'blur';
        this.setBackgroundConfig('blur');
        this.videoService.setBackgroundBlurring();
    }

    clearBackground() {
        this.backgroundConfig = 'none';
        this.setBackgroundConfig('none');
        this.videoService.disableBg();
    }

    handlecustomfun(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            const custombg = event.target as HTMLElement;
            custombg.click();
        }
    }

    fileChangeEvent(event: any): void {
        if (
            event.target.files[0] &&
            !(
                event.target.files[0].type === 'image/jpeg' ||
                event.target.files[0].type === 'image/jpg' ||
                event.target.files[0].type === 'image/png'
            )
        ) {
            this.toastrService.error(this.translateService.instant('tostrs.file_not_support'));
            return;
        }

        if (event.target.files[0] && event.target.files[0].size / 1024 / 1024 > 5) {
            this.toastrService.error(this.translateService.instant('tostrs.file_size_cannot_exceed_5mb'));
            this.fileElement.nativeElement.value = [];
            return;
        }

        this.imageChangedEvent = event;

        const files = event.target.files;
        const reader = new FileReader();
        this.imagePath = files;
        reader.readAsDataURL(files[0]);

        reader.onload = (_event) => {
            const img = new Image();

            //Set the Base64 string return from FileReader as source.
            img.src = reader.result as string;
            img.onload = () => {
                const height = img.naturalHeight;
                const width = img.naturalWidth;
                if (width < 840 || height < 480) {
                    this.toastrService.error(this.translateService.instant('tostrs.minimum_resolution'));
                    event.target.value = '';
                    return;
                }
                if (this.customImages.length === 5) {
                    this.toastrService.error(this.translateService.instant('tostrs.maximum_5_images'));
                    event.target.value = '';
                    return;
                }
                if (this.vbService.checkIfImageExixts(reader.result)) {
                    this.toastrService.error(this.translateService.instant('tostrs.already_exists'));
                    event.target.value = '';
                    return;
                }
                this.url = reader.result;
                this.backgroundConfig = 'image';
                this.setBackgroundConfig('image', this.url);
                this.videoService.setBackgroundImage(this.url);

                this.vbService.addCustomImages(this.url);
                this.gTagService.sendEvent(GA_EVENTS.CUSTOM_IMAGE_CLICK);
                this.gTagService.sendEvent(GA_NEW.CUSTOM_IMAGE_CLICK);
                event.target.value = '';
            };
        };
    }

    onSelect(event: any, imageSelected = false): void {
        this.url = event.target.src;
        if (this.selectedImageElement !== undefined) {
            this.selectedImageElement.style.border = '0px';
        }
        // this.setSelectedImageBorder(event.target);
        try {
            if (imageSelected) {
                this.backgroundConfig = 'image';
                this.sessionStorageService.addItem('vbImage', this.url);
                this.setBackgroundConfig('image', this.url);
                this.videoService.setBackgroundImage(this.url);
            }
        } catch (error) {
            this.toastrService.error(this.translateService.instant('tostrs.failed_apply'));
        }
    }

    selectedImage(id) {
        switch (id) {
            case 1: {
                this.gTagService.sendEvent(GA_EVENTS.DEFAULT_IMAGE1_CLICK);
                this.gTagService.sendEvent(GA_NEW.DEFAULT_IMAGE1_CLICK);
                break;
            }
            case 2: {
                this.gTagService.sendEvent(GA_EVENTS.DEFAULT_IMAGE2_CLICK);
                this.gTagService.sendEvent(GA_NEW.DEFAULT_IMAGE2_CLICK);
                break;
            }
            case 3: {
                this.gTagService.sendEvent(GA_EVENTS.DEFAULT_IMAGE3_CLICK);
                this.gTagService.sendEvent(GA_NEW.DEFAULT_IMAGE3_CLICK);
                break;
            }
            case 4: {
                this.gTagService.sendEvent(GA_EVENTS.DEFAULT_IMAGE4_CLICK);
                this.gTagService.sendEvent(GA_NEW.DEFAULT_IMAGE4_CLICK);
                break;
            }
            case 5: {
                this.gTagService.sendEvent(GA_EVENTS.DEFAULT_IMAGE5_CLICK);
                this.gTagService.sendEvent(GA_NEW.DEFAULT_IMAGE5_CLICK);
                break;
            }
            case 6: {
                this.gTagService.sendEvent(GA_NEW.DEFAULT_IMAGE6_CLICK);
                break;
            }
            case 7: {
                this.gTagService.sendEvent(GA_NEW.DEFAULT_IMAGE7_CLICK);
                break;
            }
            case 8: {
                this.gTagService.sendEvent(GA_NEW.DEFAULT_IMAGE8_CLICK);
                break;
            }
            case 9: {
                this.gTagService.sendEvent(GA_NEW.DEFAULT_IMAGE9_CLICK);
                break;
            }
            case 10: {
                this.gTagService.sendEvent(GA_NEW.DEFAULT_IMAGE10_CLICK);
                break;
            }
            case 11: {
                this.gTagService.sendEvent(GA_NEW.DEFAULT_IMAGE11_CLICK);
                break;
            }
            case 12: {
                this.gTagService.sendEvent(GA_NEW.DEFAULT_IMAGE12_CLICK);
                break;
            }
            case 13: {
                this.gTagService.sendEvent(GA_NEW.DEFAULT_IMAGE13_CLICK);
                break;
            }
            case 14: {
                this.gTagService.sendEvent(GA_NEW.DEFAULT_IMAGE14_CLICK);
                break;
            }
            case 15: {
                this.gTagService.sendEvent(GA_NEW.DEFAULT_IMAGE15_CLICK);
                break;
            }
        }
    }

    setSelectedImageBorder(selectedImage) {
        this.selectedImageElement = selectedImage;
        this.selectedImageElement.style.border = '2px solid blue';
    }

    checkIfImageSelected(img) {
        if (this.vbService.backgroundConfig.type === 'image' && this.vbService.backgroundConfig.imageUrl.includes(img))
            return true;
        return false;
    }

    onRemove(img) {
        if (this.vbService.backgroundConfig.imageUrl === img.src) {
            this.clearBackground();
        }
        this.vbService.removeCustomImage(img);
    }

    async startVideo() {
        try {
            await this.videoService.toggleCameraForSetting('video-preview', true);
            this.showImagePanel = true;
        } catch (e) {
            this.showImagePanel = true;
            console.error('VB Settings page - create video track');
        }
        setTimeout(() => {
            const BGFocusEle: any = document.getElementById('bgfocus');
            if (BGFocusEle) {
                BGFocusEle?.focus();
            }
        }, 100);
    }
    async onConfirm(event: any) {
        await this.videoService.confirmVbSetting();
        this.eventEmitterService.emit({
            type: APP_EVENTS.CONFIRM_VIRTUAL_BACKGROUND,
            data: {}
        });
    }

    async ngOnDestroy() {
        // We need to close the VB session in two cases:
        // 1. If we are outside call and we close the settings popup
        // 2. If we are inside the call with camera off and we close the settings popup
        const closeVbSession =
            (!this.rtcService?.getConferenceInfo() && !this.rtcService?.getCameraMicPreference()?.cameraPrivacy) ||
            (!!this.rtcService?.getConferenceInfo() && this.videoService?.localParticipant?.cameraMute);
        if (closeVbSession && !this.isJMMeeting) {
            await this.videoService?.localCameraTrack?.setEnabled(false);
        }

        //handling inside call toggling scenarios
        if (
            this.videoService?.localParticipant &&
            !this.videoService?.localParticipant?.cameraMute &&
            !this.isJMMeeting
        ) {
            await this.videoService.toggleCameraForSetting('localVideo', true);
        }
        if (this.isJMMeeting) {
            this.videoService.stopPreviewVb();
        }

        //handling preview screen toggling scenarios
        //Doing it only for preview screens
        let previewState = false;
        if (this.router?.url?.indexOf('/join') !== -1 || this.router?.url?.indexOf('/host') !== -1) {
            previewState = true;
        }
        if (this.rtcService?.getCameraMicPreference()?.cameraPrivacy && !this.videoService?.localParticipant) {
            await this.videoService.toggleCameraForSetting('preview-renderer', previewState);
        }
        if (!this.videoService?.localParticipant?.cameraMute && this.appService.isCallInitiatedByChat) {
            await this.videoService.toggleCameraForSetting('chat-call-preview', this.appService.chatCallCameraState);
        }
        if (!this.videoService?.localParticipant?.cameraMute && this.appService.isCallInitiatedByContacts) {
            await this.videoService.toggleCameraForSetting('contacts-call-preview', true);
        }
    }
    //Accessability changes
    setFocusOnElement(index) {
        setTimeout(() => {
            if (this.imagesRowOne.length - 1 == index) {
                const element: any = document.getElementById('audio');
                element.focus();
            }
        }, 100);
    }
}
