import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import {
    ValidationService,
    UserService,
    AuthService,
    RtcService,
    MeetingService,
    AppService,
    UtilService,
    GaDiagnosticService,
    DiagnosticService,
    GuestAuthService,
    VideoWrapperService,
    ThirdPartyExternalIntegrationService,
    ExternalInterfaceService,
    EventEmitterService,
    RestService,
    CallService,
    LocalStorageService
} from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';
import { APP_EVENTS } from 'src/app/constants';
import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector: 'app-guest-form',
    templateUrl: './guest-form.component.html',
    styleUrls: ['./guest-form.component.scss']
})
export class GuestFormComponent implements OnInit {
    namePinForm: FormGroup;
    errors: any = {};
    meetingId;
    hash;
    pwd;
    isPinEnabled = false;
    isAuthenticated;
    currentUser: any = {};
    name: string;
    autoJoin: boolean;
    hideMoreCallControls: boolean;
    hideAllCallControls: boolean;
    isFullScreenShare: boolean;
    hostToken: string;
    chatDisabled: boolean;

    working = false;
    valid = true;
    loader = true;

    loggedInOnly = false;
    CollegueOnly = false;
    joinBeforeHost = false;
    waitingRoomDetails: any;
    captchaDetails: any = {};
    showCaptcha = false;
    interval;
    showRestrictedMeetingJoinWarning = false;
    enableMic;
    enableVideo;
    hidePreviewScreen;
    videoService;
    isThirdPartyExternalIntegration;
    isJioHealthHub;
    isEmbibe;
    cameraLabel;
    micLabel;
    speakerLabel;
    userToken;
    userTokenFlow = false;
    isMyjioJhhMiniApp: boolean = false;
    metingDetails: any;
    isMattermost;
    hideAudience = false;
    hideParticipantCount = false;
    guestWhiteboardDisabled = false;
    isEkycEnabled = false;
    isLeaveDisabled = false;

    constructor(
        private fb: FormBuilder,
        private validdatorService: ValidationService,
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private authService: AuthService,
        private rtcService: RtcService,
        private meetingService: MeetingService,
        private appService: AppService,
        private toastrService: ToastrService,
        private router: Router,
        private gaService: GaDiagnosticService,
        private utilService: UtilService,
        private diagnosticService: DiagnosticService,
        private guestAuthService: GuestAuthService,
        private videoWrapperService: VideoWrapperService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        private externalIntegrationService: ExternalInterfaceService,
        private translateService: TranslateService,
        private eventEmitterService: EventEmitterService,
        private restService: RestService,
        private zone: NgZone,
        private callService: CallService,
        private localStorageService: LocalStorageService,
        private config: PrimeNGConfig
    ) {
        this.autoJoin = false;
        this.hideMoreCallControls = false;
        this.isEmbibe = false;
        this.videoService = this.videoWrapperService.getVideoService();
    }

    ngOnInit() {
        this.localStorageService.removeItem('clientDeviceId');
        this.localStorageService.removeItem('clientDeviceType');
        //this.isAuthenticated = this.authService.getIsAuthenticated() || false;
        this.thirdPartyExternalIntegrationService.hasJoinedFromGuestURL = true;
        if (this.activatedRoute.snapshot.queryParams.userToken == 'true') {
            this.loader = true;
            this.userTokenFlow = true;
            if (!this.isAuthenticated) {
                this.externalIntegrationService.requestJWT();
            } else {
                this.callForRequestMeetingDetails();
            }
        } else {
            // for guest users
            this.callForRequestMeetingDetails();
        }
        this.eventEmitterService.subscribe((event: any) => {
            if (event.type === APP_EVENTS.JWT_TOKEN) {
                const params = event.data;
                this.userToken = params.jwt;
                this.onTokenReceived(this.userToken);
            } else if (event.type === APP_EVENTS.CLIENT_MEETING_DETAILS) {
                this.metingDetails = event.data;
                this.joinAs(event.data.roomDetailsResponse, event.data);
            }
        });

        this.namePinForm = this.fb.group({
            name: [
                null,
                [
                    Validators.required,
                    this.validdatorService.noWhitespaceValidator,
                    Validators.pattern("^[a-zA-Z0-9 '.]*$")
                ]
            ],
            pin: [null, []],
            captchaText: [null, []]
        });
        this.fetchParams();
        if (this.name) {
            this.namePinForm.patchValue({ name: this.name });
        }
        if (this.pwd) {
            this.namePinForm.patchValue({ pin: this.pwd });
        }
        if (this.isAuthenticated) {
            this.userService.getUser().subscribe((user) => {
                this.currentUser = user;
                this.namePinForm.patchValue({ name: `${user.name} ${user.lname ? user.lname : ''}` });
                this.checkMeetingSecurity();
            });
        } else {
            this.checkMeetingSecurity();
        }

        if (this.autoJoin) {
            if (this.hidePreviewScreen) {
                this.loader = true;
            }
            this.joinMeeting();
        }

        this.rtcService.setCameraMicPreference(this.enableVideo ? true : false, this.enableMic ? true : false);

        this.gaService.sendPageView({
            page_title: 'meeting_name_pin_page',
            page_path: '/guest'
        });
    }

    callForRequestMeetingDetails() {
        if (this.activatedRoute.snapshot.queryParams.deviceId) {
            this.localStorageService.addItem('clientDeviceId', this.activatedRoute.snapshot.queryParams.deviceId);
        }
        if (this.activatedRoute.snapshot.queryParams.deviceType) {
            this.localStorageService.addItem('clientDeviceType', this.activatedRoute.snapshot.queryParams.deviceType);
        }
        this.externalIntegrationService.requestMeetingDetails();
    }

    checkMeetingSecurity() {
        this.rtcService
            .isValidRoomAndPinFlag({
                extension: this.meetingId,
                hash: this.hash,
                userId: this.currentUser._id
            })
            .subscribe(
                (res: any) => {
                    this.isPinEnabled = res.isHost ? false : res.isPinEnabled;
                    if (this.isPinEnabled && this.pwd) {
                        this.namePinForm.patchValue({ pin: this.pwd });
                    }
                    if (!this.hidePreviewScreen) {
                        this.loader = false;
                    }
                },
                (err) => {
                    //this.externalIntegrationService.sendErrorOccuredOnJoined(err?.error);
                    this.errors.global = err.error.message;
                    this.valid = false;
                }
            );
    }

    fetchParams() {
        this.meetingId = this.activatedRoute.snapshot.queryParams.meetingId;
        this.hideAudience = this.activatedRoute.snapshot.queryParams.hideAudienceMode == 'true' ? true : false;
        this.hideParticipantCount =
            this.activatedRoute.snapshot.queryParams.hidePaticipantsCount == 'true' ? true : false;

        this.hash = this.activatedRoute.snapshot.queryParams.hash;
        this.pwd = this.activatedRoute.snapshot.queryParams.pwd;
        this.name = this.activatedRoute.snapshot.queryParams.name;
        this.autoJoin = this.activatedRoute.snapshot.queryParams.autoJoin === 'true' ? true : false;
        this.enableMic = this.activatedRoute.snapshot.queryParams.enableMic === 'true' ? true : false;
        this.enableVideo = this.activatedRoute.snapshot.queryParams.enableVideo === 'true' ? true : false;
        this.hidePreviewScreen = this.activatedRoute.snapshot.queryParams.hidePreviewScreen === 'true' ? true : false;
        this.isJioHealthHub = this.activatedRoute.snapshot.queryParams.isJioHealthHub === 'true' ? true : false;
        this.isThirdPartyExternalIntegration =
            this.activatedRoute.snapshot.queryParams.isThirdPartyExternalIntegration === 'true' ? true : false;
        this.cameraLabel = this.activatedRoute.snapshot.queryParams.cameraLabel;
        this.micLabel = this.activatedRoute.snapshot.queryParams.micLabel;
        this.speakerLabel = this.activatedRoute.snapshot.queryParams.speakerLabel;
        this.chatDisabled = this.activatedRoute.snapshot.queryParams.chatDisabled === 'true' ? true : false;
        this.isMyjioJhhMiniApp = this.activatedRoute.snapshot.queryParams.isMyjioJhhMiniApp === 'true' ? true : false;
        this.isMattermost = this.activatedRoute.snapshot.queryParams.isMattermost === 'true' ? true : false;
        this.isEkycEnabled = this.activatedRoute.snapshot.queryParams.isEkyc === 'true' ? true : false;
        this.isLeaveDisabled = this.activatedRoute.snapshot.queryParams.disableLeave === 'true' ? true : false;

        if (this.isEkycEnabled) {
            this.thirdPartyExternalIntegrationService.setIsEkycEnabled(this.isEkycEnabled);
            this.isLeaveDisabled = true;
        }
        if (this.activatedRoute.snapshot.queryParams.askPermission) {
            this.externalIntegrationService.askPermission =
                this.activatedRoute.snapshot.queryParams.askPermission === 'true' ? true : false;
        }

        if (this.hideAudience) {
            this.externalIntegrationService.setHideAudience(this.hideAudience);
        }

        if (this.hideParticipantCount) {
            this.externalIntegrationService.setHideParticipantsCount(this.hideParticipantCount);
        }

        if (this.isMyjioJhhMiniApp) {
            this.thirdPartyExternalIntegrationService.enableParticipantList();
            this.thirdPartyExternalIntegrationService.myjioJhhIntegration();
        }

        if (this.chatDisabled) {
            this.thirdPartyExternalIntegrationService.setIsChatDisabled();
        }

        if (this.cameraLabel?.length > 0) {
            this.externalIntegrationService.cameraLabel = this.cameraLabel;
        }

        if (this.micLabel?.length > 0) {
            this.externalIntegrationService.micLabel = this.micLabel;
        }

        if (this.speakerLabel?.length > 0) {
            this.externalIntegrationService.speakerLabel = this.speakerLabel;
        }

        if (this.activatedRoute.snapshot.queryParams.setProxyType) {
            this.thirdPartyExternalIntegrationService.setProxyServer = Number(
                this.activatedRoute.snapshot.queryParams.setProxyType
            );
        }

        if (this.activatedRoute.snapshot.queryParams.enableManualTurnServer === 'true') {
            this.thirdPartyExternalIntegrationService.enableManualTurnServer = true;
        }

        if (this.isJioHealthHub) {
            this.autoJoin = true;
            this.enableMic = true;
            this.enableVideo = true;
            this.hidePreviewScreen = true;
            this.isThirdPartyExternalIntegration = true;
            this.thirdPartyExternalIntegrationService.enableThirdPartyIntegration();
            this.thirdPartyExternalIntegrationService.disableFooterForExternalIntegration();
            this.thirdPartyExternalIntegrationService.disableNavbarForExternalIntegration();
            this.thirdPartyExternalIntegrationService.setIsChatDisabled();
            this.thirdPartyExternalIntegrationService.enableJioHealthHubIntegration();
        }

        if (this.isMattermost) {
            this.autoJoin = true;
            this.enableMic = false;
            this.enableVideo = false;
            this.hidePreviewScreen = true;
            this.isThirdPartyExternalIntegration = true;
            this.thirdPartyExternalIntegrationService.enableThirdPartyIntegration();
            this.thirdPartyExternalIntegrationService.disableFooterForExternalIntegration();
            this.thirdPartyExternalIntegrationService.disableNavbarForExternalIntegration();
            this.thirdPartyExternalIntegrationService.setIsChatDisabled();
            this.thirdPartyExternalIntegrationService.enableJioHealthHubIntegration();
        }
        this.hideMoreCallControls = this.activatedRoute.snapshot.queryParams.hideMoreCallControls === 'true';
        this.hostToken = this.activatedRoute.snapshot.queryParams.hostToken
            ? this.activatedRoute.snapshot.queryParams.hostToken
            : '';
        this.guestWhiteboardDisabled =
            !this.hostToken?.length && this.activatedRoute.snapshot.queryParams.guestWhiteboardDisabled == 'true'
                ? true
                : false;

        if (this.guestWhiteboardDisabled) {
            this.thirdPartyExternalIntegrationService.setGuestWhiteboardDisabled(this.guestWhiteboardDisabled);
        }

        this.hideAllCallControls = this.activatedRoute.snapshot.queryParams.hideAllCallControls === 'true';
        this.isFullScreenShare = this.activatedRoute.snapshot.queryParams.isFullScreenShare === 'true';
        this.isEmbibe = this.activatedRoute.snapshot.queryParams.isEmbibe === 'true';

        this.externalIntegrationService.isEmbibe = this.hostToken.length === 0 && this.isEmbibe;

        if (this.hostToken?.length > 0) {
            this.thirdPartyExternalIntegrationService.isHostTokenUser = true;
        }

        this.thirdPartyExternalIntegrationService.setIsLeaveDisabled(this.isLeaveDisabled);
    }

    validateMeetingPin() {
        if (!this.namePinForm.get('name').valid) {
            this.errors.global = 'Please enter a valid Guest Name';
            return false;
        }

        if (
            this.isPinEnabled &&
            !(
                /[a-z]/.test(this.namePinForm.value.pin) &&
                /[A-Z]/.test(this.namePinForm.value.pin) &&
                /[0-9]/.test(this.namePinForm.value.pin) &&
                !this.namePinForm.value.pin.includes('I') &&
                !this.namePinForm.value.pin.includes('l') &&
                !this.namePinForm.value.pin.includes('o') &&
                !this.namePinForm.value.pin.includes('O') &&
                !this.namePinForm.value.pin.includes('0') &&
                /^[0-9a-zA-Z]+$/.test(this.namePinForm.value.pin) &&
                this.namePinForm.value.pin.length === 5
            )
        ) {
            this.errors.global = 'Password verification failed.';
            return false;
        }
        return true;
    }

    joinMeeting() {
        // if (!this.utilService.isRTCSupportedBrowser()) {
        //   this.toastrService.warning(
        //     'JioMeet is not supported on this browser. Please use Chrome or Firefox'
        //   );
        //   return;
        // }

        this.errors = {};
        if (!this.validateMeetingPin()) {
            return;
        }
        if (!this.hidePreviewScreen) {
            this.working = true;
        }
        if (this.hostToken?.length > 0) {
            this.localStorageService?.clear();
            this.guestAuthService
                .loginWithHostToken({
                    jiomeetId: this.meetingId,
                    roomID: '',
                    roomPin: this.pwd,
                    name:
                        this.namePinForm.value.name.length <= 17
                            ? this.namePinForm.value.name
                            : this.namePinForm.value.name.substr(0, 17) + '...',
                    phoneNo: '',
                    emailId: '',
                    hostToken: this.hostToken
                })
                .subscribe((res: any) => {
                    this.guestAuthService.setAuthInfo(res);
                    this.getRoomDetails(res?.guestUserId);
                });
        } else {
            this.getRoomDetails(this.currentUser._id);
        }
    }

    getRoomDetails(userId) {
        this.rtcService
            .getRoomDetailsGuest({
                extension: this.meetingId,
                pin: this.namePinForm.value.pin,
                hash: this.hash,
                userId: userId,
                isAuthenticated: this.isAuthenticated,
                memberName: this.namePinForm.value.name.trim(),
                captchaText: this.namePinForm.value.captchaText,
                captchaId: this.captchaDetails.captchaId,
                noBlock: false
            })
            .subscribe(
                (res: any) => {
                    this.appService.setMeetingId(res?.roomDetails?.meetingId);
                    if (!res.roomDetails && res.waitingRoom) {
                        this.waitingRoomDetails = res;
                        this.waitUntillHostAllows(res);
                    } else {
                        this.joinAs(res);
                    }
                },
                (err) => {
                    //this.externalIntegrationService.sendErrorOccuredOnJoined(err?.error);
                    this.namePinForm.get('captchaText').setValue(null);
                    if (err?.error?.customCode === 469) {
                        this.showRestrictedMeetingJoinWarning = true;
                        this.working = false;
                        return;
                    }
                    if (err?.error?.customCode === 429) {
                        this.errors.global = err.error.errors;
                        this.captchaDetails = err.error;
                        this.showCaptcha = true;
                        this.working = false;
                        return;
                    }

                    if (err?.error?.customCode === 464) {
                        this.joinBeforeHost = true;
                        this.handlePageRefresh();
                        return;
                    }
                    if (err?.error?.customCode === 465) {
                        this.loggedInOnly = true;
                        return;
                    }
                    if (err?.error?.customCode === 466) {
                        this.CollegueOnly = true;
                        return;
                    }

                    if (err?.error?.customCode === 468) {
                        this.errors.global = this.translateService.instant(
                            'joinmeeting_page_or_joincall.errors.cannot_join_room_locked'
                        );
                        this.working = false;
                        return;
                    }

                    this.externalIntegrationService.sendErrorOccuredOnJoined('exception', err);
                    this.errors.global = err?.error?.errors;
                    this.working = false;
                }
            );
    }

    waitUntillHostAllows(res) {
        this.interval = setInterval(() => {
            if (!this.hidePreviewScreen) {
                this.working = true;
            }
            this.rtcService
                .getRoomDetailsGuest({
                    extension: this.meetingId,
                    pin: this.namePinForm.value.pin,
                    hash: this.hash,
                    userId: this.currentUser._id || res.userId,
                    memberName: this.namePinForm.value.name.trim(),
                    isAuthenticated: this.isAuthenticated
                })
                .subscribe(
                    (res: any) => {
                        this.appService.setMeetingId(res?.roomDetails?.meetingId);
                        if (res.roomDetails) {
                            clearInterval(this.interval);
                            this.joinAs(res);
                            // this.waitingRoomDetails = null;
                        }
                    },
                    (err) => {
                        //this.externalIntegrationService.sendErrorOccuredOnJoined(err?.error);
                        if (err.error.customCode === 467) {
                            clearInterval(this.interval);
                            this.waitingRoomDetails = null;
                            this.toastrService.error('The host has removed you from this meeting');
                            this.errors.global = err.error.errors || 'Request rejected';
                            this.working = false;
                            return;
                        }
                        if (err.error.customCode === 464) {
                            clearInterval(this.interval);
                            this.waitingRoomDetails = null;
                            this.toastrService.error('This meeting has been ended by the host');
                            this.errors.global = 'This meeting has been ended by the host';
                            this.working = false;
                            return;
                        }
                    }
                );
        }, this.appService.getConfigVariable('REQUEST_POLLING_INTERVAL'));
    }

    joinAs(room, data?: any) {
        this.setHostOrCoHost(room);
        this.rtcService.setBreakoutRoomInfo(null);
        if (room.breakoutDetail) {
            this.rtcService.setBreakoutRoomInfo({
                ...room.breakoutDetail,
                breakoutRoomId: room.breakoutDetail._id
            });
        }
        if (data?.lang == 'hi' || data?.lang == 'en') {
            this.appService.getAndSaveUserPreferredLang(data.lang);
            this.translateService.use(data.lang);
            this.translateService.get('primeng').subscribe((res) => {
                this.config.setTranslation(res);
            });
        }
        if (this.isAuthenticated && this.currentUser) {
            if (room.ownerDetails.userId === this.currentUser._id && this.currentUser.jiomeetId === this.meetingId) {
                // host a meeting case
                this.diagnosticService
                    .sendEvent({
                        eventCategory: 'Start a Meeting',
                        eventAction: 'New Meeting Start',
                        eventType: 'app_event',
                        status: 'success'
                    })
                    .subscribe();
            }

            if (room.roomDetails.isWebinar) {
                this.rtcService.setConferenceInfo({
                    isInitiater: room.ownerDetails.userId === this.currentUser._id,
                    joiningName: this.namePinForm.value.name.trim(),
                    room: room.roomDetails,
                    ownerDetails: room.ownerDetails,
                    hideMoreCallControls: this.hideMoreCallControls,
                    hideAllCallControls: this.hideAllCallControls,
                    isFullScreenShare: this.isFullScreenShare,
                    webinar: room.roomDetails.webinar
                });
                this.router.navigate(['webinar/conference']);
            } else {
                const commonConferenceInfo = {
                    isInitiater: false,
                    joiningName: data?.memberName ? data.memberName : this.namePinForm.value.name.trim(),
                    room: room.roomDetails,
                    ownerDetails: room.ownerDetails,
                    hideMoreCallControls: this.hideMoreCallControls,
                    hideAllCallControls: this.hideAllCallControls,
                    isFullScreenShare: this.isFullScreenShare
                };
                const additionalConferenceInfo = this.videoWrapperService.getAdditionalConferenceInfo(room, true);
                const conferenceInfo = { ...commonConferenceInfo, ...additionalConferenceInfo };
                this.rtcService.setConferenceInfo(conferenceInfo);
                this.zone.run(() => {
                    this.router.navigate(['conference/call']);
                });
            }
        } else {
            if (room.roomDetails.isWebinar) {
                this.loggedInOnly = true;
                return;
            }
            if (this.hostToken === '') {
                this.guestAuthService
                    .login({
                        jiomeetId: room.roomDetails.jiomeetId,
                        roomID: room.roomDetails.roomID,
                        roomPin: room.roomDetails.roomPin || room.roomDetails.roomPIN,
                        name: this.name
                            ? this.name
                            : (this.namePinForm.value.name.length <= 17
                                  ? this.namePinForm.value.name
                                  : this.namePinForm.value.name.substr(0, 17) + '...') + ' (Guest)',
                        phoneNo: '',
                        emailId: '',
                        recorderToken: ''
                    })
                    .subscribe((res) => {
                        this.userService.isGuestLogin(true);
                        this.userService.setGuestUser(name);
                        const commonConferenceInfo = {
                            isInitiater: false,
                            joiningName: this.namePinForm.value.name.trim() + ' (Guest)',
                            room: room.roomDetails,
                            ownerDetails: room.ownerDetails,
                            hideMoreCallControls: this.hideMoreCallControls,
                            hideAllCallControls: this.hideAllCallControls,
                            isFullScreenShare: this.isFullScreenShare
                        };
                        const additionalConferenceInfo = this.videoWrapperService.getAdditionalConferenceInfo(
                            room,
                            true
                        );
                        const conferenceInfo = { ...commonConferenceInfo, ...additionalConferenceInfo };
                        this.rtcService.setConferenceInfo(conferenceInfo);
                        this.zone.run(() => {
                            this.router.navigate(['conference/call']);
                        });
                    });
            } else {
                this.userService.isGuestLogin(true);
                this.userService.setGuestUser(name);
                const commonConferenceInfo = {
                    isInitiater: false,
                    joiningName: this.namePinForm.value.name.trim(),
                    room: room.roomDetails,
                    ownerDetails: room.ownerDetails,
                    hideMoreCallControls: this.hideMoreCallControls,
                    hideAllCallControls: this.hideAllCallControls,
                    isFullScreenShare: this.isFullScreenShare
                };
                const additionalConferenceInfo = this.videoWrapperService.getAdditionalConferenceInfo(room, true);
                const conferenceInfo = { ...commonConferenceInfo, ...additionalConferenceInfo };
                this.rtcService.setConferenceInfo(conferenceInfo);
                this.zone.run(() => {
                    this.router.navigate(['conference/call']);
                });
            }
        }
    }

    setHostOrCoHost(room) {
        const { ownerDetails, roomDetails } = room;
        const { coHosts } = roomDetails;

        const isCurrentUserHost = ownerDetails?.userId === this.currentUser._id;
        const isCurrentUserCoHost = coHosts?.some((coHost) => coHost?.userId === this.currentUser._id);

        this.rtcService.isHostOrCoHostOfMeeting = isCurrentUserHost || isCurrentUserCoHost;
    }

    leaveWaitingRoom() {
        clearInterval(this.interval);
        this.userService
            .stopWaiting({
                memberId: this.currentUser._id || this.waitingRoomDetails.userId,
                jiomeetId: this.waitingRoomDetails.jiomeetId
            })
            .subscribe(
                (res) => {
                    clearInterval(this.interval);
                    this.waitingRoomDetails = null;
                    this.working = false;
                },
                (err) => {
                    // this.externalIntegrationService.sendErrorOccuredOnJoined(err?.error);
                }
            );
    }

    handleManualPageRefresh() {
        this.appService.reloadWebContent();
    }
    handlePageRefresh() {
        setTimeout(() => {
            this.appService.reloadWebContent();
        }, 5000);
    }
    navigateToLogin() {
        this.router.navigate(['/login'], { relativeTo: this.activatedRoute });
    }

    toggleRestrictedMeetingJoinWarning() {
        this.showRestrictedMeetingJoinWarning = !this.showRestrictedMeetingJoinWarning;
    }

    //pexip other clients login

    onTokenReceived(token) {
        if (!token) {
            this.zone.run(() => {
                this.loader = false;
            });
        } else {
            this.externalIntegrationService.requestForCameraAndMicrophone();
            this.loginUsingUserToken(token)
                .then((result: any) => {
                    this.authService.setAuthInfo(result);
                    this.appService.reloadWebContent();
                })
                .catch((err) => {
                    console.error(err);
                    this.externalIntegrationService.sendEventToRefreshJWT();
                });
        }
    }

    loginUsingUserToken(token) {
        return this.restService
            .post('api/pexip/verify', {
                jwt: token
            })
            .toPromise();
    }
}
