<div class="subscribe-container">
    <div class="left-container" [class.sec-padding-h]="isMobile" *ngIf="!jiomeetSubscription">
        <h3 tabindex="0" class="header heading-0">
            {{ 'landing_or_home_page.section.new_tips_updates.title' | translate }}
        </h3>

        <p tabindex="0" class="desc subheading">
            {{ 'landing_or_home_page.section.new_tips_updates.sub_text' | translate }}
        </p>

        <div class="subscribeForm">
            <div class="form" [class.submitted]="submitted">
                <form [formGroup]="subscribeForm" (submit)="subscribe()">
                    <div class="form-field email">
                        <input
                            type="email"
                            placeholder="{{ 'landing_or_home_page.section.subscribe_form.email_id' | translate }}"
                            formControlName="email"
                            [class.error]="!subscribeForm.valid && subscribeForm.value.email"
                            [class.valid]="subscribeForm.valid"
                            required
                        />
                    </div>
                    <div class="action">
                        <button
                            type="submit"
                            tabindex="0"
                            [class.disabled]="!subscribeForm.valid"
                            class="custom-action-btn"
                            (keyup.enter)="subscribeForm.valid && subscribe()"
                            [attr.aria-label]="!subscribeForm.valid ? 'Subscribe disabled' : 'Subscribe enabled'"
                        >
                            {{ 'landing_or_home_page.section.subscribe_form.subscribe' | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="left-container" [class.sec-padding-h]="isMobile" *ngIf="jiomeetSubscription">
        <ng-container *ngIf="!alreadySubscribed">
            <h3 class="subscribeheader header heading-0" tabindex="0">
                {{ 'landing_or_home_page.section.jiomeet_subscribe.title' | translate }}
            </h3>

            <p class="desc subheading subscribed-text" tabindex="0">
                {{
                    'landing_or_home_page.section.jiomeet_subscribe.sub_text'
                        | translate : { value: this.subscribedEmail }
                }}
            </p>

            <!-- <div class="subscribe-email">{{ this.subscribedEmail }}</div> -->
        </ng-container>
        <ng-container *ngIf="alreadySubscribed">
            <h3 class="subscribeheaderfocus header heading-0" tabindex="0">
                {{ 'landing_or_home_page.section.jiomeet_already_subscribe.title' | translate }}
            </h3>
            <div class="subscribe-email mt-3 already-subscribed">{{ this.subscribedEmail }}</div>
            <p class="desc subheading subscribed-text" tabindex="0">
                {{ 'landing_or_home_page.section.jiomeet_already_subscribe.sub_text' | translate }}
            </p>
        </ng-container>
    </div>

    <div class="img" *ngIf="!isMobile">
        <img src="assets/img/website/icon-idea.svg" alt="" />
    </div>
</div>
