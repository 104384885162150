<div class="video-settings-container">
    <div id="preview-section">
        <!-- <video id="video-preview" class="video-section" #videoPreview></video> -->
        <div tabindex="0" id="video-preview" class="video-section" [class.mirroredImage]="!mirroView"></div>

        <div class="preview-label" *ngIf="showImagePanel">
            <label id="bgfocus" tabindex="0" aria-label="Preview">{{
                'user_dashboard_settings.background.preview' | translate
            }}</label>
        </div>
        <app-loader *ngIf="!showImagePanel"></app-loader>
    </div>

    <div *ngIf="camTestInProgress" class="loading-section">
        Kindly wait till your device is being checked
        <div class="custom-loader-component">
            <div class="lds-dual-ring"></div>
        </div>
    </div>

    <div *ngIf="showImagePanel && !camTestInProgress">
        <section>
            <div class="unit d-flex">
                <span tabindex="0" class="camerafocus">{{ 'user_dashboard_settings.video.camera' | translate }}:</span>
                <select (change)="selectCamera()" [(ngModel)]="selectedCamera">
                    <option *ngFor="let cam of cameras" [ngValue]="cam">{{ cam.name }}</option>
                </select>
            </div>
        </section>
        <div class="camera-input mt-5" *ngIf="!isGuestUser && !isMobileDevice">
            <span class="meeting">{{ 'user_dashboard_settings.video.meeting' | translate }}:</span>
            <div class="checkbox-wrapper">
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="videosettingscheck"
                        [(ngModel)]="turnOffVideo"
                        (change)="onTurnOffVideo($event)"
                    />
                    <label class="custom-control-label pointer pl-2" for="videosettingscheck">
                        {{ 'user_dashboard_settings.video.turn_off_video' | translate }}
                    </label>
                </div>
            </div>
        </div>
        <div class="checkbox-wrapper" *ngIf="!isMobileDevice">
            <div class="custom-control custom-checkbox" [ngClass]="{}">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    id="checkMirrorView"
                    (keydown.Tab)="setFocusOnElement()"
                    [(ngModel)]="mirroView"
                    (click)="mirrorViewToggle()"
                />
                <label class="custom-control-label pointer pl-2" for="checkMirrorView">
                    {{ 'user_dashboard_settings.video.mirror_video' | translate }}
                </label>
            </div>
        </div>
        <div class="option" *ngIf="isMobileDevice">
            <div class="option-left" tabindex="0" aria-label="Mirror your own video (self view)">
                {{ 'user_dashboard_settings.video.mirror_video' | translate }}
            </div>
            <label class="switch">
                <input
                    type="checkbox"
                    [checked]="mirroView"
                    (keydown.Tab)="setFocusOnElement()"
                    (change)="mirrorViewToggle()"
                />
                <span class="slider round"></span>
            </label>
        </div>

        <!-- Full HD streaming -->
        <div class="checkbox-wrapper" *ngIf="!isGuestUser && !isMobileDevice && currentUser?.tenantId">
            <div class="custom-control custom-checkbox" *ngIf="!camTestInProgress">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    id="enableFullHdStreamingCheck"
                    [(ngModel)]="isFullHdStreamingEnabled"
                    (keydown.Tab)="setFocusOnElement()"
                    (keydown.enter)="onFullHdStreamingEnabled($event)"
                    (change)="onFullHdStreamingEnabled($event)"
                    [disabled]="!supportsFullHdStreaming"
                />
                <label class="custom-control-label pointer pl-2" for="enableFullHdStreamingCheck">
                    {{ 'joinmeeting_page_or_joincall.enableFullHDStream' | translate }}
                </label>
                <p class="fullHDStream-note" *ngIf="!supportsFullHdStreaming">
                    <b>Note:</b> Your device doesn't support 1080p resolution.
                </p>
            </div>
        </div>

        <div class="option" *ngIf="!isGuestUser && isMobileDevice && currentUser?.tenantId">
            <div class="option-left" tabindex="0" aria-label="Enable Full HD streaming">
                {{ 'joinmeeting_page_or_joincall.enableFullHDStream' | translate }}
            </div>
            <label class="switch">
                <input
                    type="checkbox"
                    [checked]="isFullHdStreamingEnabled"
                    (keydown.Tab)="setFocusOnElement()"
                    (change)="onFullHdStreamingEnabled($event)"
                    [disabled]="!supportsFullHdStreaming"
                />
                <span class="slider round"></span>
            </label>
            <p class="fullHDStream-note" *ngIf="!supportsFullHdStreaming">
                <b>Note:</b> Your device doesn't support 1080p resolution.
            </p>
        </div>

        <div class="camera-input mt-5" *ngIf="isAgoraMeeting && showHDScreenShareOption">
            <span class="meeting">{{ 'user_dashboard_settings.video.screen_share' | translate }}:</span>
            <div class="checkbox-wrapper">
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="enableHDShareCheck"
                        (keydown.Tab)="setFocusOnElement()"
                        [(ngModel)]="enableHDScreenShare"
                        (change)="onScreenShareHD($event.target)"
                    />
                    <label class="custom-control-label pointer pl-2" for="enableHDShareCheck">
                        {{ 'user_dashboard_settings.video.optimize' | translate }}
                    </label>
                    <br />&nbsp;
                    <span class="optimize-note">{{ 'user_dashboard_settings.video.optimize_note' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
