<div class="language-settings-container">
    <div class="form-group">
        <div class="d-flex justify-content-start flex-column w-75 frequency">
            <label for="en" class="langfoc pointer pr-5" (click)="setLan('en')">
                <input
                    type="radio"
                    [(ngModel)]="lang"
                    name="repeat"
                    (click)="gTagLanguage('en')"
                    id="en"
                    value="en"
                    tabindex="0"
                    (keydown.Tab)="setFocusOnElement('en')"
                    aria-label="Language changed to English"
                />
                <span class="ml-3">English</span>
            </label>
            <label for="hi" class="pointer pr-5" (click)="setLan('hi')">
                <input
                    type="radio"
                    [(ngModel)]="lang"
                    name="repeat"
                    (click)="gTagLanguage('hi')"
                    id="hi"
                    value="hi"
                    (keydown.Tab)="setFocusOnElement('hi')"
                    tabindex="0"
                    aria-label="Language changed to Hindi"
                />
                <span class="ml-3">हिंदी</span>
            </label>
        </div>
    </div>
</div>
