import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_EVENTS } from 'src/app/constants';
import { EventData, EventEmitterService, UtilService, GoogleTagManagerService, AuthService } from 'src/app/core';
import { GA_EVENTS } from '../ga-events';
import { GA_PAGEVIEW } from '../ga-pageview';
import { GA4_EVENTS } from '../ga4-events';

@Component({
    selector: 'app-website-govt-sectors',
    templateUrl: './website-govt-sectors.component.html',
    styleUrls: ['../website.usecases.scss', './website-govt-sectors.component.scss']
})
export class WebsiteGovtSectorsComponent implements OnInit {
    isMobile = false;
    isAuthenticated: any;
    govtSectorsFeatures = [
        {
            title: 'Digital Judiciary',
            ttitle: 'website_govt_sector.digital_judiciary',
            // imgMob: 'assets/img/govtSector/digital-judiciary-mobile.png',
            imgDesktop: 'assets/img/govtSector/digital-judiciary.png',
            altText: 'Proceedings of courtroom virtually on jiomeet',
            padding0: true,
            desc: [
                {
                    text: 'Strengthening the Courtroom Dynamics by enabling equitable virtual proceedings and running courts on Hybrid Mode.',
                    ttext: 'website_govt_sector.court_on_hybrid_mode'
                }
            ]
        },
        {
            title: 'Video complains',
            ttitle: 'website_govt_sector.video_complains',
            imgDesktop: 'assets/img/govtSector/video-complains.png',
            imgMob: 'assets/img/govtSector/video-complains-mobile.png',
            altText: 'Old man raising video complain',
            desc: [
                {
                    text: 'With the advent of Digitized Administration, the poor, uneducated, or illiterate mass of the society can now easily voice their complaints verbally or send them video recorded. A step closer to reach fairness, reach justice!',
                    ttext: 'website_govt_sector.reach_fairness_justice'
                }
            ]
        },
        {
            title: 'Preventing Frauds in Government Welfare Schemes',
            ttitle: 'website_govt_sector.prevent_fraud_govt_schemes',
            imgDesktop: 'assets/img/govtSector/govt-welfare-schemes.png',
            imgMob: 'assets/img/govtSector/govt-welfare-schemes-mobile.png',
            altText: 'Family getting benefits of Pradhan Mantri Awas Yojana Housing for All',
            desc: [
                {
                    text: 'Expedites verification for Government Projects through Video-based KYCs and Geotagging for schemes like Pradhan Mantri Awas Yojana Housing for All Mission.',
                    ttext: 'website_govt_sector.expedite_verification'
                }
            ]
        },
        {
            title: 'No in-person interaction to bureaucracy',
            ttitle: 'website_govt_sector.no_inperson_interaction',
            imgMob: 'assets/img/govtSector/bureaucracy-interaction-mobile.png',
            imgDesktop: 'assets/img/govtSector/bureaucracy-interaction.png',
            altText: 'Picture of a women having No physical interaction between citizens and bureaucracy',
            desc: [
                {
                    text: 'No physical interaction between citizens and bereaucracy.',
                    ttext: 'website_govt_sector.no_physical_interaction'
                }
            ]
        },
        {
            title: 'Data privacy',
            ttitle: 'website_govt_sector.data_privacy',
            imgDesktop: 'assets/img/govtSector/data-privacy.png',
            imgMob: 'assets/img/govtSector/data-privacy-mobile.png',
            altText: 'Data privacy',

            desc: [
                {
                    text: 'Connect securely and avail enhanced e-governance services with JioMeet, available in NIC Data Centres now.',
                    ttext: 'website_govt_sector.connect_securely'
                }
            ]
        }
    ];

    constructor(
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private gTagService: GoogleTagManagerService,
        private router: Router,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser(1080);
        this.translateGovtSectorsSolutions();
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 1080 ? false : true;
            }
        });
        this.authService.getIsAuthenticated$().subscribe((authStatus) => {
            this.isAuthenticated = authStatus;
        });
        this.gTagService.pushOnDataLayer(GA_PAGEVIEW.USECASE_GOVERMENT_SECTOR);
    }

    async translateGovtSectorsSolutions() {
        const GovtSectorsSolutions = await this.utilService.handleDataTranslation('website_govt_sector', {
            GovtSectorsSolutions: this.govtSectorsFeatures
        });
        this.govtSectorsFeatures = GovtSectorsSolutions['GovtSectorsSolutions'];
    }

    navigateToSignUp() {
        this.gTagService.sendEvent(GA_EVENTS.USECASE_GOVERMENT_SECTOR_SIGNUP);
        this.gTagService.sendEvent(GA4_EVENTS.USECASE_GOVERMENT_SECTOR_SIGNUP);
        if (this.isAuthenticated) {
            this.router.navigate(['dashboard']);
        } else {
            this.router.navigate(['signup']);
        }
    }

    goToDownloadApp() {
        this.gTagService.sendEvent(GA_EVENTS.USECASE_GOVERMENT_SECTOR_DOWNLOAD);
        this.gTagService.sendEvent(GA4_EVENTS.USECASE_GOVERMENT_SECTOR_DOWNLOAD);
        this.router.navigate(['jiomeetDownload']);
    }
}
