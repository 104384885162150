<div class="change-profile-container">
    <div class="backbutton" (click)="closeModal()">
        <img alt="" src="assets/images/arrow_back_blue.svg" />{{ 'user_dashboard_settings.back' | translate }}
    </div>
    <div class="modal-body">
        <div class="crop-container">
            <div class="crop-text p-2 d-flex justify-content-center">
                {{ 'user_dashboard_settings.profile.change_picture_text2' | translate }}
            </div>
            <input
                #fileUpload
                id="fileUploadButton"
                type="file"
                (click)="fileUpload.value = null; gtagChangePicture()"
                (change)="fileChangeEvent($event)"
                accept="image/*"
                hidden
            />
            <label
                for="fileUploadButton"
                class="changeImageButton"
                aria-label="Change Image"
                tabindex="0"
                (keyup.enter)="handelkeyupchangeimage($event)"
                >{{ 'user_dashboard_settings.profile.change_picture' | translate }}</label
            >
            <div class="clearfix d-flex mt-2 justify-content-center">
                <div class="original-container">
                    <image-cropper
                        aria-label="Crop the image"
                        [imageURL]="originalImage"
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="true"
                        [aspectRatio]="4 / 4"
                        format="png"
                        (imageCropped)="imageCropped($event)"
                    >
                    </image-cropper>
                </div>
            </div>

            <!-- <button class="ml-5 custom-button bg-secondary upload" (click)="fileUpload.click()">Upload</button> -->
        </div>
    </div>

    <div class="popup-footer d-flex justify-content-center">
        <button
            class="custom-button bg-secondary upload mr-5"
            (click)="closeModal()"
            tabindex="0"
            aria-label="Cancel"
            (keyup.enter)="closeModal()"
        >
            {{ 'user_dashboard_settings.cancel' | translate }}
        </button>
        <button
            class="custom-button bg-primary upload"
            (click)="onSubmit()"
            tabindex="0"
            [attr.aria-label]="profilesettingstatus()"
            (keyup.enter)="onSubmit()"
            [class.disabled]="uploading"
        >
            {{ (!uploading ? 'user_dashboard_settings.save' : 'user_dashboard_settings.please_wait') | translate }}
        </button>
    </div>
</div>
