<div
    class="app-udpater-sticky-popup"
    (click)="showAppUpdatePopup()"
    *ngIf="
        desktopAppUpdaterService?.isDesktopApp &&
        !desktopAppUpdaterService?.showElectronUpdatePopup &&
        isUpdateAvailable &&
        !hideStickyBar
    "
>
    <div class="app-updater-sticky-wrapper">
        <span class="floater-option">{{ 'website.new_update_available' | translate }}</span>
        <img class="app-update-img" src="assets/img/enterprise/app-update.svg" />
    </div>
</div>
