import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { APP_EVENTS } from 'src/app/constants';
import {
    AppService,
    AuthService,
    EventData,
    EventEmitterService,
    UserService,
    UtilService,
    GoogleTagManagerService
} from 'src/app/core';
import { WEBSITE_ENTERPRISE_FEATURES, WEBSITE_ENTERPRISE_RETAIL_COMPARISION } from '../websiteConstant';
import * as Flickity from 'flickity';
import { GA_PAGEVIEW } from '../ga-pageview';
@Component({
    selector: 'app-website-enterprise',
    templateUrl: './website-enterprise.component.html',
    styleUrls: ['./website-enterprise.component.scss']
})
export class WebsiteEnterpriseComponent implements OnInit {
    @ViewChild('compareContainer') compareContainerElement: ElementRef;
    @ViewChild('scrollBodyContainer') scrollBodyContainerEle: ElementRef;
    @Input() testimonial: any;

    isMobile = false;
    websiteEnterpriseFeatures = WEBSITE_ENTERPRISE_FEATURES;
    websiteRetailEnterpriseCompare = WEBSITE_ENTERPRISE_RETAIL_COMPARISION;
    activateComparisonFixedHeader = false;
    currentScrollDirection = 'down';
    prevScrollPos = 0;
    isAuthenticated = false;
    currentUser;
    currentLang = '';
    websiteMetaInfo;
    testimonialCarousel;
    @ViewChild('testimonialSlider', { static: true })
    testimonialSlider: ElementRef;
    enterpriseTestimonials: string[] = [];
    enterpriseBranding: string[] = [];

    constructor(
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private authService: AuthService,
        private userService: UserService,
        private appService: AppService,
        private translateService: TranslateService,
        private router: Router,
        private gTagService: GoogleTagManagerService
    ) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser(1080);
        this.translateEnterpriseSolutions();
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_SCROLLED) {
                const scrollTop = event.data?.target.scrollTop;
                this.currentScrollDirection = scrollTop > this.prevScrollPos ? 'down' : 'up';
                this.prevScrollPos = scrollTop;
                // const hiddenElem = this.comparePriceHiddenElement.nativeElement as HTMLElement;
            } else if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 1080 ? false : true;
            }
        });

        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
            this.currentUser = this.userService.getUserSync();
        });
        this.currentLang = this.appService.getAndSaveUserPreferredLang();
        this.translateService.onLangChange.subscribe(() => {
            this.currentLang = this.appService.getAndSaveUserPreferredLang();
        });
        this.gTagService.pushOnDataLayer(GA_PAGEVIEW.PRODUCT_PAGE_VIEW_ENTERPRISE);
        this.getMetaInfo();
    }

    getMetaInfo() {
        this.userService.getWebsiteMetaInfo().subscribe(
            (res) => {
                if (res) {
                    this.websiteMetaInfo = res;

                    setTimeout(() => {
                        this.initTestimonialSlider();
                    }, 0);
                    this.testimonialCheck(this.websiteMetaInfo.testimonial);
                    this.brandingCheck(this.websiteMetaInfo.branding);
                }
            },
            (err) => {}
        );
    }

    testimonialCheck(testimonials) {
        if (testimonials?.length > 0) {
            testimonials.forEach((element) => {
                if (element.publishOn.includes('Enterprise')) {
                    this.enterpriseTestimonials.push(element);
                }
            });
        }
    }

    brandingCheck(branding) {
        if (branding?.length > 0) {
            branding.forEach((element) => {
                if (element.publishOn.includes('Enterprise')) {
                    this.enterpriseBranding.push(element);
                }
            });
        }
    }

    initTestimonialSlider() {
        this.testimonialCarousel = new Flickity(this.testimonialSlider.nativeElement, {
            prevNextButtons: !this.isMobile,
            autoPlay: 4000,
            contain: false,
            cellAlign: 0,
            pageDots: true,
            selectedAttraction: 0.2,
            friction: 0.8,
            index: 0,
            resize: true,
            accessibility: false
        });
    }

    async translateEnterpriseSolutions() {
        const websiteEnterpriseFeatures = await this.utilService.handleDataTranslation('website', {
            enterpriseFeatures: this.websiteEnterpriseFeatures
        });
        this.websiteEnterpriseFeatures = websiteEnterpriseFeatures['enterpriseFeatures'];
    }

    onScrollHeader(event) {
        this.scrollBodyContainerEle.nativeElement.scrollLeft = event?.srcElement.scrollLeft;
    }

    navigateToAdminPortal() {
        this.gTagService.sendEvent({
            event: 'jiomeet',
            event_category: 'products_jiomeetenterprise',
            event_action: 'admin_portal',
            event_label: 'success'
        });
        this.gTagService.sendEvent({
            event: 'button_click',
            clicked_text: 'admin_portal',
            current_page: 'products_jiomeetenterprise',
            outcome: 'succeeded'
        });
        const url = this.appService.getEnvVariable('HOST_URL') + 'adminportal/login';
        window.open(url.toString(), '_blank');
    }

    goToContactSales() {
        setTimeout(() => {
            const contactElement: any = document.getElementsByClassName('contactsalesheading')[0];
            if (contactElement) {
                contactElement?.focus();
            }
        }, 0);
        this.gTagService.sendEvent({
            event: 'jiomeet',
            event_category: 'products_jiomeetenterprise',
            event_action: 'banner_contact_sales',
            event_label: 'success'
        });
        this.gTagService.sendEvent({
            event: 'banner_contact_sales',
            clicked_text: '',
            current_page: 'products_jiomeetenterprise',
            outcome: 'succeeded'
        });
        this.router.navigate(['contactsales']);
    }

    goToSignup() {
        this.gTagService.sendEvent({
            event: 'jiomeet',
            event_category: 'Products_JioMeet_ Enterprise',
            event_action: 'Free Plan Sign up Clicked',
            event_label: 'Success'
        });
        this.router.navigate(['signup']);
    }

    changePlan() {
        this.gTagService.sendEvent({
            event: 'jiomeet',
            event_category: 'Products_JioMeet_ Enterprise',
            event_action: 'Monthly / Annually',
            event_label: 'Success'
        });
    }
}
