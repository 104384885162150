<div class="website-retails" id="website-top">
    <section class="freedom-top">
        <div class="left-content">
            <div tabindex="0" aria-label="Freedom from Limits only on JioMeet">
                <div class="heading">
                    <span class="headline-gradient">{{
                        (activeLang === 'en' ? 'website_retail.freedom' : 'website_retail.limits') | translate
                    }}</span>
                    <span>{{ 'website_retail.from' | translate }}</span>
                </div>
                <div class="heading">
                    <span class="headline-gradient">
                        {{ (activeLang === 'en' ? 'website_retail.limits' : 'website_retail.freedom') | translate }}
                    </span>
                </div>
                <div class="desc">
                    {{ 'website_retail.only_on' | translate }}
                    <span>{{ 'website_retail.jiomeet' | translate }}</span>
                    <ng-container *ngIf="activeLang === 'hi'">{{ 'website_retail.on' | translate }}</ng-container>
                </div>
            </div>
            <div class="action-btns">
                <button
                    class="custom-action-btn"
                    tabindex="0"
                    aria-label="Sign-up for JioMeet"
                    (keydown.enter)="navigateToSignUp()"
                    (click)="navigateToSignUp()"
                    *ngIf="!isAuthenticated"
                >
                    {{ 'landing_or_home_page.header.sign_up' | translate }}
                </button>
                <button
                    class="custom-action-btn"
                    tabindex="0"
                    aria-label="Download JioMeet App"
                    [class.secondary]="!isAuthenticated"
                    (keydown.enter)="goToDownloadApp()"
                    (click)="goToDownloadApp()"
                >
                    {{ 'website.download_app' | translate }}
                </button>
            </div>
        </div>
        <div class="right-content">
            <img src="assets/img/retail/retail-v2.png" alt="Happy faces of people using jiomeet" />
        </div>
    </section>

    <section class="sec-1" [class.sec]="isMobile" appForceFullWidth>
        <div class="img">
            <img src="assets/img/retail/make-in-india.png" alt="" *ngIf="!isMobile" />
            <img src="assets/img/retail/make-in-india-mob.png" alt="" *ngIf="isMobile" />
        </div>
        <p class="desc subheading" tabindex="0" aria-label="Made in India to help India connect and grow together">
            <span>{{ 'website_retail.made_in_india' | translate }}</span>
            <span>{{ 'website_retail.connect_grow' | translate }}</span>
        </p>
    </section>
    <section class="sec-img" *ngIf="!isMobile">
        <img src="assets/img/retail/rangoli-icon.svg" alt="" />
    </section>
    <section class="sec-2 sec">
        <div
            class="sec-padding-h"
            tabindex="0"
            aria-label="Celebrate, Enjoy, Party.. Together, with all your loved ones"
        >
            <div class="subheading">{{ 'website_retail.celebrate_party' | translate }}…</div>
            <div class="heading-0">{{ 'website_retail.with_all_loved_once' | translate }}</div>
        </div>
        <div class="img" [class.sec-padding-h]="!isMobile">
            <img
                src="assets/img/retail/celebration-v2.png"
                alt="People happily watching their family celebrating via jiomeet video call"
            />
        </div>
    </section>
    <section class="sec-3 sec">
        <div
            class="sec-padding-h"
            tabindex="0"
            aria-label="No mandatory sign ups or app download Simply click meeting link to join!"
        >
            <div class="subheading">{{ 'website_retail.no_mandatory_signup' | translate }}</div>
            <div class="heading-0">{{ 'website_retail.click_meeting_link' | translate }}</div>
        </div>
        <div class="img" [class.sec-padding-h]="!isMobile">
            <img src="assets/img/retail/join-meet-v2.png" *ngIf="isMobile" alt="" />
            <img
                src="assets/img/retail/join-meet-desktop-v2.png"
                *ngIf="!isMobile"
                alt="Steps to join a meeting on jiomeet without mandatory sign up or app download"
            />
        </div>
    </section>

    <section class="website-meet-everyone sec">
        <div
            class="info"
            [class.sec-margin-h-44]="screenInnerWidth < 720"
            tabindex="0"
            aria-label="Create account from any SIM, any Email ID JioMeet is for everyone!"
        >
            <div>{{ 'website_home.create_account' | translate }}</div>
            <div>{{ 'website_home.jiomeet_for_everyone' | translate }}</div>
        </div>
        <img src="assets/img/website/jiomeet-for-everyone.svg" *ngIf="screenInnerWidth >= 720" alt="" />
        <img src="assets/img/website/jiomeet-for-everyone-mobile.svg" *ngIf="screenInnerWidth < 720" alt="" />
    </section>

    <section class="sec-4 sec" [class.sec-padding-h]="!isMobile">
        <div>
            <div
                [class.sec-padding-h]="isMobile"
                tabindex="0"
                aria-label="JioMeet offers Multilingual capability to connect in your own language"
            >
                <div class="subheading">{{ 'website_retail.multilingual_capability' | translate }}</div>
                <div class="heading-0">{{ 'website_retail.connect_own_language' | translate }}</div>
            </div>
            <div class="img">
                <img src="assets/img/retail/multi-lang.png" *ngIf="isMobile" alt="" />
                <img
                    src="assets/img/retail/multi-lang-desktop.png"
                    *ngIf="!isMobile"
                    alt="Jiomeet supports different languages like hindi ,marathi ,gujarati"
                />
            </div>
        </div>
        <div class="check-list" [class.sec-padding-h]="isMobile">
            <div class="img" *ngIf="!isMobile">
                <img src="assets/img/retail/multi-lang-arrow.svg" alt="" />
            </div>
            <div
                class="subheading"
                tabindex="0"
                aria-label="JioMeet supports languages, including Hindi, Marathi, and Gujarati"
            >
                {{ 'website_retail.supporting_lang' | translate }}
            </div>
            <div class="lang-wrap">
                <img src="assets/img/done-tickmark.svg" alt="" />
                <span class="text">{{ 'website_retail.hindi' | translate }}</span>
            </div>
            <div class="lang-wrap">
                <img src="assets/img/done-tickmark.svg" alt="" />
                <span class="text">{{ 'website_retail.marathi' | translate }}</span>
            </div>
            <div class="lang-wrap">
                <img src="assets/img/done-tickmark.svg" alt="" />
                <span class="text">{{ 'website_retail.gujarati' | translate }}</span>
            </div>
        </div>
    </section>

    <section class="sec-5 sec">
        <div [class.sec-padding-h]="isMobile">
            <div
                class="heading-0"
                tabindex="0"
                aria-label="Does this happen to you? like, I can't hear you, Video freeze hogayi!, Can you hear me?, Internet slow hai kya?!"
            >
                {{ 'website_retail.happen_to_you' | translate }}
            </div>
        </div>
        <div class="img">
            <img
                src="assets/img/retail/remove-obstacles.png"
                *ngIf="!isMobile"
                alt="Obstacles faced on video conferencing apps"
            />
            <img src="assets/img/retail/remove-obstacles-mobile.png" *ngIf="isMobile" alt="" />
        </div>
    </section>

    <section class="sec-6 sec" [class.sec-padding-h]="!isMobile">
        <div
            class="sec"
            [class.sec-padding-h]="isMobile"
            tabindex="0"
            aria-label="Say No to such Annoyances! JioMeet auto optimises your meeting experience"
        >
            <div class="subheading">{{ 'website_retail.no_such_annoyances' | translate }}</div>
            <div class="heading-0">{{ 'website_retail.jiomeet_auto_optimise' | translate }}</div>
        </div>
        <div class="audio-info">
            <div class="heading-0" [class.sec-padding-h]="isMobile">
                <img src="assets/img/retail/audio-smiley.png" alt="" *ngIf="!isMobile" />
                <img src="assets/img/retail/audio-smiley-mob.png" alt="" *ngIf="isMobile" />
                <span tabindex="0" aria-label="Audio-Only Mode">{{ 'website_retail.audio_mode' | translate }}</span>
            </div>
            <div>
                <p class="desc" [class.sec-padding-h]="isMobile" tabindex="0">
                    {{ 'website_retail.switch_audio_mode' | translate }}
                </p>
                <div class="img">
                    <app-lottie-renderer
                        path="assets/lottie/audio-switch-desktop-v2.json"
                        alt="Audio only mode feature on jiomeet"
                        *ngIf="!isMobile"
                    ></app-lottie-renderer>
                    <app-lottie-renderer
                        path="assets/lottie/audio-switch-mobile-v2.json"
                        *ngIf="isMobile"
                    ></app-lottie-renderer>
                </div>
            </div>
        </div>
    </section>

    <section class="sec-7 sec">
        <div
            [class.sec-padding-h]="isMobile"
            tabindex="0"
            aria-label="Teleport yourself anywhere using Virtual Backgrounds"
        >
            <div class="subheading">{{ 'website_retail.teleport_anywhere' | translate }}</div>
            <div class="heading-0">{{ 'website_retail.virtual_bg' | translate }}</div>
        </div>
        <div class="img" *ngIf="isMobile"><img src="assets/img/retail/virtual_bg_mobile.png" alt="" /></div>
        <!-- <div class="img" *ngIf="isMobile"><img src="assets/img/retail/virtual-bg-sm-v2.png" alt="" /></div> -->
        <div class="img" *ngIf="!isMobile">
            <img src="assets/img/retail/virtual_bg_desktop.png" alt="Virtual background collection on jiomeet" />
        </div>
        <div
            class="check-list"
            [class.sec-padding-h]="isMobile"
            tabindex="0"
            aria-label="Virtual Backgrounds Collection includes hand-picked HD backgrounds, upload your own custom backgrounds, find the perfect fit with the Crop Tool"
        >
            <div class="subheading">{{ 'website_retail.virtual_bg_collection' | translate }}</div>
            <div class="virtual-bg-info">
                <div class="lang-wrap">
                    <img src="assets/img/done-tickmark.svg" alt="" />
                    <span class="text">{{ 'website_retail.hd_bg' | translate }}</span>
                </div>
                <div class="lang-wrap">
                    <img src="assets/img/done-tickmark.svg" alt="" />
                    <span class="text">{{ 'website_retail.upload_custom_bg' | translate }}</span>
                </div>
                <div class="lang-wrap">
                    <img src="assets/img/done-tickmark.svg" alt="" />
                    <span class="text">{{ 'website_retail.perfect_fit_crop_tool' | translate }}</span>
                </div>
            </div>
        </div>
    </section>

    <section class="sec-8 sec" [class.sec-padding-h]="!isMobile">
        <div
            [class.sec-padding-h]="isMobile"
            tabindex="0"
            aria-label="Let the focus be on you by turning on Background Blur!"
        >
            <div class="subheading">{{ 'website_retail.focus_on_you' | translate }}</div>
            <div class="heading-0">{{ 'website_retail.turn_on_bg' | translate }}</div>
        </div>
        <div class="img">
            <img src="assets/img/retail/bg-blur.png" *ngIf="isMobile" alt="" />
            <img
                src="assets/img/retail/bg-blur-desktop.png"
                *ngIf="!isMobile"
                alt="Turn on background blur feature on jiomeet"
            />
        </div>
    </section>

    <section class="sec-9 sec" [class.sec-padding-h]="!isMobile">
        <div
            [class.sec-padding-h]="isMobile"
            tabindex="0"
            aria-label="We’re not done yet, There’s more where that came from!"
        >
            <div class="subheading">{{ 'website_retail.not_done_yet' | translate }},</div>
            <div class="heading-0">{{ 'website_retail.there_is_more' | translate }}</div>
        </div>
        <div class="retail-solutions">
            <div *ngFor="let solutionInfo of retailSolutions">
                <app-website-solution [solutionInfo]="solutionInfo"></app-website-solution>
            </div>
        </div>
    </section>

    <section
        class="testimonials sec"
        [class.sec-margin-h-44]="isMobile"
        [style.display]="retailTestimonials?.length == 0 ? 'none' : 'block'"
    >
        <h2 class="heading" tabindex="0">{{ 'website_home.customers_comment' | translate }}</h2>
        <div class="testimonial-slider" #testimonialSlider>
            <div
                class="slide"
                *ngFor="let testimonial of retailTestimonials; let i = index"
                [attr.aria-selected]="currentIndex === i ? 'true' : 'false'"
                [attr.aria-label]="'Testimonial ' + (i + 1)"
                tabindex="0"
            >
                <app-website-testimonials [testimonial]="testimonial"></app-website-testimonials>
            </div>
        </div>
    </section>

    <section class="got-questions sec" [class.sec-padding-h-44]="isMobile">
        <app-explore-meet
            downloadBottom="downloadPlatform"
            signBottom="signPlatform"
            title="{{ 'website_retail.explore_title' | translate }}"
            desc="{{ 'website_retail.explore_desc' | translate }}"
            btnText1="{{ 'landing_or_home_page.header.sign_up' | translate }}"
            btnText2="{{ 'website.download_app' | translate }}"
            btnText1Link="/signup"
            btnText2Link="/downloadApp"
            *ngIf="!isAuthenticated"
        ></app-explore-meet>
        <app-explore-meet
            downloadBottom="downloadPlatform"
            title="{{ 'website_retail.explore_title' | translate }}"
            desc="{{ 'website_retail.explore_desc' | translate }}"
            btnText1="{{ 'website.download_app' | translate }}"
            btnText1Link="/downloadApp"
            *ngIf="isAuthenticated"
        ></app-explore-meet>
    </section>

    <section class="website-content tips-updates" [class.sec-padding-h-44]="isMobile">
        <app-jiomeet-subscribe SubcribePage="productJiomeet"></app-jiomeet-subscribe>
    </section>
</div>
