import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/core';

@Pipe({
    name: 'dateLabel'
})
export class DateLabelPipe implements PipeTransform {
    constructor(private utilService: UtilService, private translateService: TranslateService) {}

    transform(value: any, args?: any): any {
        if (!value) {
            return null;
        }
        return this.utilService.isToday(new Date(value))
            ? this.translateService.instant('primeng.today')
            : this.utilService.isYesterday(new Date(value))
            ? this.translateService.instant('primeng.yesterday')
            : this.utilService.translatedDate(value);
    }
}
