<div class="livestreamconfiguration-container">
    <section class="header" *ngIf="!isRenderFromCall">
        <span>{{ 'user_dashboard_settings.live_stream.live_stream_config' | translate }}</span>
    </section>
    <form class="custom-form" [formGroup]="form">
        <div class="form-group my-4">
            <label for="csn" class="text-dark">{{
                'user_dashboard_settings.live_stream.channel_stream_name' | translate
            }}</label>
            <input
                type="text"
                class="form-control"
                placeholder="{{ 'user_dashboard_settings.live_stream.enter_name' | translate }}"
                id="csn"
                formControlName="streamName"
                [class.err]="errors.streamName"
                maxlength="30"
            />
            <div class="error" *ngIf="errors.streamName">
                {{ errors.streamName }}
            </div>
        </div>
        <div class="form-group my-4">
            <label for="sp" class="text-dark" tabindex="0" aria-label="Select stream platform">{{
                'user_dashboard_settings.live_stream.stream_platform' | translate
            }}</label
            ><br />
            <p-dropdown
                [options]="streamTypes"
                formControlName="streamPlatform"
                optionLabel="name"
                tabindex="0"
                optionValue="value"
                placeholder="{{ 'user_dashboard_settings.live_stream.select' | translate }}"
                aria-label="List of stream platform "
            ></p-dropdown>
        </div>
        <div class="form-group my-4">
            <label for="su" class="text-dark">{{
                'user_dashboard_settings.live_stream.streaming_url' | translate
            }}</label>
            <input
                type="text"
                class="form-control"
                placeholder="{{ 'user_dashboard_settings.live_stream.enter_streaming_url' | translate }}"
                id="su"
                formControlName="streamingUrl"
                [class.err]="errors.streamingUrl"
                maxlength="200"
            />
            <div class="error" *ngIf="errors.streamingUrl">
                {{ errors.streamingUrl }}
            </div>
        </div>
        <div class="passwordicon form-group my-4">
            <label for="sk" class="text-dark">{{
                'user_dashboard_settings.live_stream.streaming_key' | translate
            }}</label>
            <input
                [type]="showKey ? 'text' : 'password'"
                class="form-control"
                placeholder="{{ 'user_dashboard_settings.live_stream.enter_key' | translate }}"
                id="sk"
                formControlName="streamKey"
                maxlength="200"
            />
            <img
                tabindex="0"
                alt=""
                src="{{ showKey ? 'assets/img/show_password.svg' : 'assets/img/hide_password.svg' }}"
                (click)="toggleKey()"
                (keyup.enter)="toggleKey()"
                class="eye"
                [attr.aria-label]="showKey ? 'Show Key' : 'Hide Key'"
            />
        </div>

        <div class="button-group mt-5 mb-5 text-right">
            <button type="button" class="custom-button bg-secondary cancel" (click)="closepage()">
                {{ 'user_dashboard_settings.cancel' | translate }}
            </button>
            <button
                type="submit"
                [class.disabled]="!form.valid || submitted"
                (click)="createLiveStream(form.value.streamPlatform)"
                *ngIf="!streamObj?._id"
                class="custom-button bg-primary save ml-4"
            >
                {{ (isRenderFromCall ? 'user_dashboard_settings.add' : 'user_dashboard_settings.save') | translate }}
            </button>
            <button
                type="submit"
                [class.disabled]="!form.valid || submitted"
                (click)="updateLiveStream()"
                *ngIf="streamObj?._id"
                class="custom-button bg-primary save ml-4"
            >
                {{ 'user_dashboard_settings.update' | translate }}
            </button>
        </div>
    </form>
</div>
