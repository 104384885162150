import { Injectable, ɵConsole } from '@angular/core';
import { AppService } from './app.service';
import { LoggerService } from './logger.service';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { VIDYO_EVENTS } from 'src/app/constants';
import { ToastrService } from 'ngx-toastr';
import { Speaker } from 'src/app/shared/interfaces/audio-settings';
import { TranslateService } from '@ngx-translate/core';

declare var VC: any;
declare const window;

@Injectable({
    providedIn: 'root'
})
export class VidyoCustomLayoutService {
    vidyoConnector;
    libraryLoaded = false;

    localParticipant;
    joinData;
    reconnecting = false;

    users = {};
    vcs = {};
    microphoneMutedAll = false;
    roomLocked = false;
    recording = false;
    screenSharing = false;
    waitingUsers = {};
    cameras = [];
    microphones = [];
    speakers = [];
    selectedLocalCamera = 0;
    selectedLocalMicrophone = 0;
    selectedLocalSpeaker = 0;
    selectedLocalCameraObj = {};

    OPEN_REMOTE_SLOT = '-1';
    remoteCameras = {};

    // Keep track of attributes of remote camera sources:
    // * max = maximum number of remote cameras to render; initialize to 8 but update as needed per resource manager recommendations.
    // * count = total number of remote cameras that are streaming in the conference.
    // * rendered = number of remote cameras that are locally rendered.
    remoteSources = { max: 7, count: 0, rendered: 0 };

    // rendererSlots[0] is used to render the local camera;
    // rendererSlots[1] through rendererSlots[8] are used to render up to 8 cameras from remote participants.
    rendererSlots = [
        '1',
        this.OPEN_REMOTE_SLOT,
        this.OPEN_REMOTE_SLOT,
        this.OPEN_REMOTE_SLOT,
        this.OPEN_REMOTE_SLOT,
        this.OPEN_REMOTE_SLOT,
        this.OPEN_REMOTE_SLOT,
        this.OPEN_REMOTE_SLOT
    ];

    private disconneting = false;
    private alreadySharingScreen = false;
    private windowShares = {};
    private whiteboardShared;

    private meeting$: BehaviorSubject<any> = new BehaviorSubject(null);
    private chatMessages$: BehaviorSubject<any> = new BehaviorSubject(null);
    private deviceChanged$: BehaviorSubject<any> = new BehaviorSubject(null);
    private participantsStatus$: BehaviorSubject<any> = new BehaviorSubject(null);

    reConnectInterval;

    constructor(
        private loggerService: LoggerService,
        private appService: AppService,
        private toastrService: ToastrService,
        private translateService: TranslateService
    ) {
        this.handleWindowEvents();
    }

    getMeetingObs() {
        return this.meeting$;
    }

    getChatMessages() {
        return this.chatMessages$;
    }

    getDeviceCahnge() {
        return this.deviceChanged$;
    }

    getParticipantStatus() {
        return this.participantsStatus$;
    }

    intializeValue = () => {
        this.users = {};
        this.cameras = [];
        this.microphones = [];
        this.speakers = [];
        this.selectedLocalCamera = 0;
        this.selectedLocalMicrophone = 0;
        this.selectedLocalSpeaker = 0;
        this.disconneting = false;
        this.reconnecting = false;
    };

    loadVidyoSDK = () => {
        if (typeof VC !== 'undefined') {
            return;
        }
        const script = document.createElement('script');
        script.type = 'text/javascript';
        // script.src = 'https://web-static.alpha.vidyo.com/VidyoConnector/latest_build/VidyoClient.js';
        // script.src = ' https://localhost:4200/assets/lib/native-webrtc/VidyoClient.local.min.js';
        script.src = this.appService.getConfigVariable('VIDYO_SDK_PATH');
        script.onload = () => {
            this.onLoad({ state: 'READY', description: 'Native SCIP + WebRTC' });
        };
        document.getElementsByTagName('head')[0].appendChild(script);
        var style = document.createElement('link');
        style.rel = 'stylesheet';
        style.type = 'text/css';
        style.href = 'assets/lib/native-webrtc/vidyoclient.css';
        document.getElementsByTagName('head')[0].appendChild(style);
    };

    private onLoad(status) {
        switch (status.state) {
            case 'READY':
                this.loggerService.log('Library loaded sucessfully.');

                if (!this.libraryLoaded) {
                    this.libraryLoaded = true;
                    window.VC = new window.VidyoClientLib.VidyoClient('', () => {
                        // After the VidyoClient is successfully initialized a global VC object will become available
                        // All of the VidyoConnector gui and logic is implemented in VidyoConnector.js
                        // StartVidyoConnector(VC, window.VCUtils ? window.VCUtils.params.webrtc : 'true');
                        this.initializeVidyoConnector();
                    });
                }

                break;
            case 'RETRYING':
                this.loggerService.error('Temporarily unavilable. Retrying...');
                break;
            case 'FAILED':
                // retry here
                this.loggerService.error('Failed to load library');
                this.loggerService.error(status.description);
                alert('An error occurred, please reload');
                break;
            case 'FAILEDVERSION':
                this.loggerService.error(status.description);
                this.loggerService.error(status.downloadPathPlugIn);
                this.loggerService.error(status.downloadPathApp);
                break;
            case 'NOTAVAILABLE':
                this.loggerService.error(status.description);
                break;
            case 'TIMEDOUT':
                this.loggerService.error('Library loading timeout');
                break;
        }
    }

    setJoinData({
        host,
        displayName,
        roomKey,
        roomPin,
        micPrivacy,
        cameraPrivacy,
        isInitiater = false,
        viewIds = [
            'renderer0',
            'renderer1',
            'renderer2',
            'renderer3',
            'renderer4',
            'renderer5',
            'renderer6',
            'renderer7'
        ]
    }) {
        this.joinData = {
            host,
            displayName,
            roomKey,
            roomPin,
            micPrivacy,
            cameraPrivacy,
            isInitiater,
            viewIds
        };
    }

    initializeVidyoConnector() {
        if (typeof VC === 'undefined') {
            this.loadVidyoSDK();
            return;
        }
        this.intializeValue();
        // if (this.vidyoConnector) {
        //   this.checkViyoConnectorStateAndJoin();
        //   return;
        // }
        VC.CreateVidyoConnector({
            viewId: null,
            //viewStyle: 'VIDYO_CONNECTORVIEWSTYLE_Default',
            remoteParticipants: 7,
            logFileFilter:
                'all@VidyoDevelopment debug@VidyoClient debug@VidyoSDP debug@VidyoResourceManager all@VidyoSignaling',
            logFileName: '',
            userData: 0
        }).then((vc) => {
            this.meeting$.next({ type: 'VC_CREATED', data: null });
            this.vidyoConnector = vc;
            this.showRenderers();
            this.handleParticipantChange();
            this.registerDeviceListeners();
            this.registerMessageListener();
            this.registerRemoteShareListener();
            this.registerLocalShareListener();
            this.checkViyoConnectorStateAndJoin();
        });
    }

    private showRenderers() {
        this.showRenderer(this.joinData.viewIds[0]);
        this.showRenderer(this.joinData.viewIds[1]);
        this.showRenderer(this.joinData.viewIds[2]);
        this.showRenderer(this.joinData.viewIds[3]);
        this.showRenderer(this.joinData.viewIds[4]);
        this.showRenderer(this.joinData.viewIds[5]);
        this.showRenderer(this.joinData.viewIds[6]);
        this.showRenderer(this.joinData.viewIds[7]);
    }

    // Find an open slot in the receive source slots (1 - 8)
    private findOpenSlot() {
        // Scan through the renderer slots and look for an open slot.
        for (var i = 1; i < this.rendererSlots.length; ++i) {
            if (this.rendererSlots[i] === this.OPEN_REMOTE_SLOT) return i;
        }
        return 0;
    }

    // Render a remote camera to a particular slot
    private renderToSlot(participantId, slot) {
        // Render the remote camera to the slot.
        this.rendererSlots[slot] = participantId;
        this.remoteCameras[participantId].isRendered = true;
        this.vidyoConnector
            .AssignViewToRemoteCamera({
                viewId: 'renderer' + slot,
                remoteCamera: this.remoteCameras[participantId].camera,
                displayCropped: true,
                allowZoom: false
            })
            .then((retValue) => {
                console.log('AssignViewToRemoteCamera ' + participantId + ' to slot ' + slot + ' = ' + retValue);
                this.showRenderer('renderer' + slot);
                ++this.remoteSources.rendered;
            })
            .catch(() => {
                console.log('AssignViewToRemoteCamera Failed');
                this.rendererSlots[slot] = this.OPEN_REMOTE_SLOT;
                this.remoteCameras[participantId].isRendered = false;
            });
        this.meeting$.next({ type: 'VIEW_RENDERED', data: { slotId: slot } });
    }

    private renderToAlreadyRendredSlot(participantId, slot) {
        // Render the remote camera to the slot.
        this.rendererSlots[slot] = participantId;
        this.remoteCameras[participantId].isRendered = true;
        this.vidyoConnector
            .AssignViewToRemoteCamera({
                viewId: 'renderer' + slot,
                remoteCamera: this.remoteCameras[participantId].camera,
                displayCropped: true,
                allowZoom: false
            })
            .then((retValue) => {
                console.log('AssignViewToRemoteCamera ' + participantId + ' to slot ' + slot + ' = ' + retValue);
                this.showRenderer('renderer' + slot);
                //++this.remoteSources.rendered;
            })
            .catch(() => {
                console.log('AssignViewToRemoteCamera Failed');
                this.rendererSlots[slot] = this.OPEN_REMOTE_SLOT;
                this.remoteCameras[participantId].isRendered = false;
            });
        this.meeting$.next({ type: 'VIEW_RENDERED', data: { slotId: slot } });
    }

    private showRenderer(divId) {
        const rndr = document.getElementById(divId);
        this.vidyoConnector.ShowViewAt({
            viewId: divId,
            x: rndr.offsetLeft,
            y: rndr.offsetTop,
            width: rndr.offsetWidth,
            height: rndr.offsetHeight
        });
    }

    private checkViyoConnectorStateAndJoin() {
        const timeout = setTimeout(() => {
            this.meeting$.next({ type: 'INACTIVE', data: null });
        }, 10000);
        this.vidyoConnector.GetState().then((state) => {
            clearInterval(timeout);
            if (state !== 'VIDYO_CONNECTORSTATE_Ready') {
                this.meeting$.next({ type: 'INACTIVE', data: null });
                return;
            }
            this.joinRoom(this.joinData);
        });
    }

    userTalkListener(callback): void {
        this.vidyoConnector.RegisterLocalMicrophoneEnergyListener({
            onEnergy: callback
        });
    }
    private registerDeviceListeners() {
        this.vidyoConnector
            .RegisterLocalCameraEventListener({
                onAdded: (localCamera) => {
                    this.cameras.push(localCamera);
                },
                onRemoved: (localCamera) => {
                    this.cameras = this.cameras.filter((camera) => camera.id !== localCamera.id);
                    if (this.cameras.length <= 1) {
                        this.selectedLocalCamera = 0;
                        this.selectedLocalCameraObj = {};
                    }
                    // If the removed camera was the selected camera, then hide it
                    if (this.selectedLocalCamera === localCamera.id) {
                        this.vidyoConnector
                            .HideView({ viewId: this.joinData.viewIds[0] })
                            .then(() => {
                                console.log('HideView Success');
                            })
                            .catch((e) => {
                                console.log('HideView Failed');
                            });
                    }
                },
                onSelected: (localCamera) => {
                    if (localCamera) {
                        this.selectedLocalCamera = localCamera.id;
                        this.joinData.displayName = this.joinData.displayName || '';
                        localCamera.SetPreviewLabel({
                            previewLabel:
                                this.joinData.displayName.length <= 25
                                    ? this.joinData.displayName
                                    : this.joinData.displayName.substr(0, 25) + '...'
                        });
                        this.selectedLocalCameraObj = localCamera;
                        // Assign view to selected camera
                        this.vidyoConnector
                            .AssignViewToLocalCamera({
                                viewId: this.joinData.viewIds[0],
                                localCamera: this.selectedLocalCameraObj,
                                displayCropped: true,
                                allowZoom: false
                            })
                            .then(() => {
                                console.log('AssignViewToLocalCamera Success');
                                this.showRenderer(this.joinData.viewIds[0]);
                            })
                            .catch((e) => {
                                console.log('AssignViewToLocalCamera Failed');
                            });
                    } else {
                        this.selectedLocalCamera = 0;
                        this.selectedLocalCameraObj = {};
                    }
                },
                onStateUpdated: (localCamera, state) => {
                    if (localCamera) {
                        this.joinData.displayName = this.joinData.displayName || '';
                        localCamera.SetPreviewLabel({
                            previewLabel:
                                this.joinData.displayName.length <= 25
                                    ? this.joinData.displayName
                                    : this.joinData.displayName.substr(0, 25) + '...'
                        });
                    }
                    Object.keys(this.users).forEach((id) => {
                        // if (this.users[id].isLocal) {
                        //   if (state === 'VIDYO_DEVICESTATE_Started') {
                        //     this.users[id].cameraMute = false;
                        //   } else if (state === 'VIDYO_DEVICESTATE_Stopped') {
                        //     this.users[id].cameraMute = true;
                        //   }
                        // }
                    });
                    this.sendControlStatus();
                }
            })
            .then(() => {
                this.loggerService.log('RegisterLocalCameraEventListener Success');
            })
            .catch(() => {
                this.loggerService.error('RegisterLocalCameraEventListener Failed');
            });

        this.vidyoConnector
            .RegisterLocalMicrophoneEventListener({
                onAdded: (localMicrophone) => {
                    this.microphones.push(localMicrophone);
                },
                onRemoved: (localMicrophone) => {
                    this.microphones = this.microphones.filter((microphone) => microphone.id !== localMicrophone.id);
                    if (this.microphones.length <= 1) {
                        this.selectedLocalMicrophone = 0;
                    }
                },
                onSelected: (localMicrophone) => {
                    if (localMicrophone) {
                        this.selectedLocalMicrophone = localMicrophone.id;
                    } else {
                        this.selectedLocalMicrophone = 0;
                    }
                },
                onStateUpdated: (localMicrophone, state) => {
                    Object.keys(this.users).forEach((id) => {
                        // if (this.users[id].isLocal) {
                        //   if (state === 'VIDYO_DEVICESTATE_Started') {
                        //     this.users[id].microphoneMute = false;
                        //   } else if (state === 'VIDYO_DEVICESTATE_Stopped') {
                        //     this.users[id].microphoneMute = true;
                        //   }
                        // }
                    });
                    this.sendControlStatus();
                }
            })
            .then(() => {
                this.loggerService.log('RegisterLocalMicrophoneEventListener Success');
            })
            .catch(() => {
                this.loggerService.error('RegisterLocalMicrophoneEventListener Failed');
            });

        this.vidyoConnector
            .RegisterLocalSpeakerEventListener({
                onAdded: (localSpeaker) => {
                    console.log(localSpeaker);
                    if (!this.speakers.find((speaker) => speaker.id === localSpeaker.id)) {
                        this.speakers.push(localSpeaker);
                    }
                },
                onRemoved: (localSpeaker) => {
                    this.speakers = this.speakers.filter((speaker) => speaker.id !== localSpeaker.id);
                    if (this.speakers.length <= 1) {
                        this.selectedLocalSpeaker = 0;
                    }
                },
                onSelected: (localSpeaker) => {
                    console.log(localSpeaker);
                    if (localSpeaker) {
                        this.selectedLocalSpeaker = localSpeaker.id;
                    } else {
                        this.selectedLocalSpeaker = 0;
                    }
                },
                onStateUpdated: (localSpeaker, state) => {
                    console.log(localSpeaker);

                    Object.keys(this.users).forEach((id) => {
                        // if (this.users[id].isLocal) {
                        //   if (state === 'VIDYO_DEVICESTATE_Started') {
                        //     this.users[id].speakerMute = false;
                        //   } else if (state === 'VIDYO_DEVICESTATE_Stopped') {
                        //     this.users[id].speakerMute = true;
                        //   }
                        // }
                    });
                    this.sendControlStatus();
                }
            })
            .then(() => {
                this.loggerService.log('RegisterLocalSpeakerEventListener Success');
            })
            .catch(() => {
                this.loggerService.error('RegisterLocalSpeakerEventListener Failed');
            });

        this.vidyoConnector
            .RegisterRemoteMicrophoneEventListener({
                onAdded: (microphone, participant) => {
                    if (this.microphoneMutedAll) {
                        this.sendChatMessage({
                            type: 'PrivateChat',
                            message: VIDYO_EVENTS.PARTICIPANT_STOP_AUDIO,
                            targetParticipantId: participant.id,
                            targetParticipantName: participant.name
                        });
                    }
                },
                onRemoved: (microphone, participant) => {},
                onStateUpdated: (microphone, participant, state) => {
                    if (!this.users[participant.id]) {
                        this.users[participant.id] = participant;
                    }

                    if (state === 'VIDYO_DEVICESTATE_Resumed') {
                        this.users[participant.id].microphoneMute = false;
                    } else if (state === 'VIDYO_DEVICESTATE_Paused') {
                        this.users[participant.id].microphoneMute = true;
                    }

                    for (var i = 1; i < this.rendererSlots.length; i++) {
                        if (this.rendererSlots[i] === participant.id) {
                            if (this.users[participant.id].microphoneMute) {
                                this.meeting$.next({ type: 'AUDIO_MUTE', data: { slotId: i } });
                            } else {
                                this.meeting$.next({ type: 'AUDIO_UNMUTE', data: { slotId: i } });
                            }
                            break;
                        }
                    }

                    this.sendControlStatus();
                }
            })
            .then(() => {
                this.loggerService.log('RegisterRemoteMicrophoneEventListener Success');
            })
            .catch(() => {
                this.loggerService.error('RegisterRemoteMicrophoneEventListener Failed');
            });

        this.vidyoConnector
            .RegisterRemoteCameraEventListener({
                onAdded: (camera, participant) => {
                    this.addUser(participant); // if not added locally
                    this.users[participant.id].cameraMute = false;
                    this.sendControlStatus();

                    let alreadyRendred = false;
                    for (var i = 1; i < this.rendererSlots.length; i++) {
                        if (this.rendererSlots[i] === participant.id) {
                            this.meeting$.next({ type: 'VIDEO_UNMUTE', data: { slotId: i } });
                            this.remoteCameras[participant.id] = { camera: camera, isRendered: false };
                            this.renderToAlreadyRendredSlot(participant.id, i);
                            alreadyRendred = true;
                            break;
                        }
                    }
                    if (!alreadyRendred) {
                        // Store the remote camera for this participant
                        this.remoteCameras[participant.id] = { camera: camera, isRendered: false };
                        ++this.remoteSources.count;

                        // Check if resource manager allows for an additional source to be rendered.
                        if (this.remoteSources.rendered < this.remoteSources.max) {
                            // If an open slot is found then assign it to the remote camera.
                            var openSlot = this.findOpenSlot();
                            if (openSlot > 0) {
                                this.renderToSlot(participant.id, openSlot);
                            }
                        }
                    }
                },
                onRemoved: (camera, participant) => {
                    this.addUser(participant); // if not added locally
                    this.users[participant.id].cameraMute = true;
                    this.sendControlStatus();
                    console.log('RegisterRemoteCameraEventListener onRemoved participant.id : ' + participant.id);
                    // delete this.remoteCameras[participant.id];
                    // --this.remoteSources.count;

                    // Scan through the renderer slots and if this participant's camera
                    // is being rendered in a slot, then clear the slot and hide the camera.
                    for (var i = 1; i < this.rendererSlots.length; i++) {
                        if (this.rendererSlots[i] === participant.id) {
                            this.meeting$.next({ type: 'VIDEO_MUTE', data: { slotId: i } });
                            // this.rendererSlots[i] = this.OPEN_REMOTE_SLOT;
                            // console.log("Slot found, calling HideView on renderer" + i);
                            this.vidyoConnector
                                .HideView({ viewId: 'renderer' + i })
                                .then(() => {
                                    // console.log("HideView Success");
                                    // --this.remoteSources.rendered;
                                    // // If a remote camera is not rendered in a slot, replace it in the slot that was just cleared
                                    // for (var id in this.remoteCameras) {
                                    //     if (!this.remoteCameras[id].isRendered) {
                                    //         this.renderToSlot(id, i);
                                    //         break;
                                    //     }
                                    // }
                                })
                                .catch((e) => {
                                    console.log('HideView Failed');
                                });
                            break;
                        }
                    }
                },
                onStateUpdated: (microphone, participant, state) => {
                    if (this.users[participant.id]) {
                        if (state === 'VIDYO_DEVICESTATE_Resumed') {
                            this.users[participant.id].cameraMute = false;
                        } else if (state === 'VIDYO_DEVICESTATE_Paused') {
                            this.users[participant.id].cameraMute = true;
                        }
                    }
                    this.sendControlStatus();
                }
            })
            .then(() => {
                this.loggerService.log('RegisterRemoteCameraEventListener Success');
            })
            .catch(() => {
                this.loggerService.error('RegisterRemoteCameraEventListener Failed');
            });
    }

    private handleParticipantChange() {
        this.vidyoConnector
            .ReportLocalParticipantOnJoined({
                reportLocalParticipant: true
            })
            .then(() => {
                this.loggerService.log('ReportLocalParticipantOnJoined Success');
            })
            .catch(() => {
                this.loggerService.error('ReportLocalParticipantOnJoined Failed');
            });

        this.vidyoConnector
            .RegisterParticipantEventListener({
                onJoined: (participant) => {
                    participant.IsLocal().then((isLocal) => {
                        this.loggerService.log('[vc] participant onJoined= ' + JSON.stringify(participant));
                        if (isLocal) {
                            clearInterval(this.reConnectInterval);
                            if (this.reconnecting) {
                                this.reconnecting = false;
                                this.toastrService.clear();
                                this.toastrService.success('Connected', '', {
                                    positionClass: 'toast-top-center'
                                });
                            }
                            participant.isLocal = true;
                            if (this.joinData.isInitiater) {
                                participant.isHost = true;
                            }
                            participant.microphoneMute = !this.joinData.micPrivacy;
                            participant.cameraMute = !this.joinData.cameraPrivacy;
                            this.localParticipant = participant;
                            this.users[participant.id] = participant;
                            this.meeting$.next({
                                type: 'LOCAL_PARTICIPANT_CONNECTED',
                                data: null
                            });
                        } else {
                            if (this.joinData.isInitiater) {
                                this.sendChatMessage({
                                    type: 'PublicChat',
                                    message: VIDYO_EVENTS.HOST_IDENTITY
                                });
                            }
                            this.addUser(participant);
                            if (typeof this.users[participant.id].cameraMute === 'undefined') {
                                participant.cameraMute = true;
                            }

                            // send recording status for newly joined guest user
                            if (this.recording) {
                                this.sendChatMessage({
                                    type: 'PublicChat',
                                    message: VIDYO_EVENTS.HOST_START_RECORDING
                                });
                            }
                        }
                        if (participant.AppType === 'gateway') {
                            this.addVC(participant);
                        }
                        this.sendControlStatus();
                    });
                },
                onLeft: (participant) => {
                    this.loggerService.log('[vc] participant onLeft= ' + JSON.stringify(participant));
                    // if (!this.disconneting && this.localParticipant.id !== participant.id) {
                    //   if (this.users[participant.id].disconnectedByHost) {
                    //     this.toastrService.info(`${participant.name} has been removed`);
                    //   } else {
                    //     this.toastrService.warning(`${participant.name} left the call.`);
                    //   }
                    // }

                    delete this.vcs[participant.id];
                    delete this.users[participant.id];
                    this.sendControlStatus();
                    delete this.remoteCameras[participant.id];
                    --this.remoteSources.count;

                    for (var i = 1; i < this.rendererSlots.length; i++) {
                        if (this.rendererSlots[i] === participant.id) {
                            this.meeting$.next({ type: 'CLEAR_SLOT', data: { slotId: i } });
                            this.rendererSlots[i] = this.OPEN_REMOTE_SLOT;
                            console.log('Slot found, calling HideView on renderer' + i);
                            --this.remoteSources.rendered;
                            // this.vidyoConnector.HideView({ viewId: "renderer" + (i) }).then(() => {
                            //     console.log("HideView Success");
                            //     --this.remoteSources.rendered;
                            // }).catch((e) => {
                            //     console.log("HideView Failed");
                            // });
                            break;
                        }
                    }
                },
                onDynamicChanged: (participants) => {
                    this.loggerService.log(participants);
                },
                onLoudestChanged: (participant, audioOnly) => {
                    this.loggerService.log('[vc] participant Speaking= ' + JSON.stringify(participant));
                    this.loggerService.log(`audioOnly ${audioOnly}`);
                    // Consider switching loudest speaker tile if resource manager allows
                    // for at least 1 remote source to be rendered.
                    if (this.remoteSources.max > 0) {
                        // Check if the loudest speaker is being rendered in one of the slots
                        var found = false;
                        for (var i = 1; i < this.rendererSlots.length; i++) {
                            if (this.rendererSlots[i] === participant.id) {
                                found = true;
                                this.meeting$.next({ type: 'LOUDEST_CHANGED', data: { slotId: i } });
                                break;
                            }
                        }
                        console.log('onLoudestChanged: loudest speaker in rendererSlots? ' + found);

                        // First check if the participant's camera has been added to the remoteCameras dictionary
                        if (!(participant.id in this.remoteCameras)) {
                            console.log('Warning: loudest speaker participant does not have a camera in remoteCameras');
                        }
                        // If the loudest speaker is not being rendered in one of the slots then
                        // hide the slot 1 remote camera and assign loudest speaker to slot 1.
                        else if (!found && this.remoteSources.rendered >= this.remoteSources.max) {
                            // Set the isRendered flag to false of the remote camera which is being hidden
                            this.remoteCameras[this.rendererSlots[1]].isRendered = false;

                            // Hiding the view first, before assigning to the loudes speaker's camera.
                            this.vidyoConnector
                                .HideView({ viewId: this.joinData.viewIds[1] })
                                .then(() => {
                                    console.log('HideView Success');
                                    --this.remoteSources.rendered;

                                    // Assign slot 1 to the the loudest speaker
                                    this.renderToSlot(participant.id, 1);
                                    this.meeting$.next({ type: 'LOUDEST_CHANGED', data: { slotId: 1 } });
                                })
                                .catch((e) => {
                                    console.log('HideView Failed, loudest speaker not assigned');
                                });
                        }
                    }
                }
            })
            .then(() => {
                this.loggerService.log('RegisterParticipantEventListener Success');
            })
            .catch(() => {
                this.loggerService.error('RegisterParticipantEventListener Failed');
            });
    }

    reConnect() {
        clearInterval(this.reConnectInterval);
        this.joinData.micPrivacy = !this.getLocalParticipant().microphoneMute;
        this.joinData.cameraPrivacy = !this.getLocalParticipant().cameraMute;
        this.joinRoom(this.joinData);

        this.reConnectInterval = setInterval(() => {
            this.joinRoom(this.joinData);
        }, 5 * 1000);
    }

    reConnectOnConnectionOnline() {
        this.joinData.micPrivacy = !this.getLocalParticipant().microphoneMute;
        this.joinData.cameraPrivacy = !this.getLocalParticipant().cameraMute;
        this.joinRoom(this.joinData);
    }

    private addUser(participant) {
        if (!this.users[participant.id]) {
            this.users[participant.id] = participant;
        }
    }

    private addVC(participant) {
        if (!this.vcs[participant.id]) {
            participant.isLegacy = true;
            this.vcs[participant.id] = participant;
        }
    }

    private registerMessageListener = () => {
        this.vidyoConnector
            .RegisterMessageEventListener({
                onChatMessageReceived: (participant, chatMessage) => {
                    if (participant === null) {
                        return;
                    }
                    const jsonObject = JSON.parse(chatMessage.body);
                    console.log(jsonObject);
                    this.chatMessages$.next({
                        message: chatMessage.body,
                        sender: participant,
                        receiver: this.localParticipant
                    });
                }
            })
            .then(() => {
                this.loggerService.log('RegisterMessageEventListener Success');
            })
            .catch(() => {
                this.loggerService.error('RegisterMessageEventListener Failed');
            });
    };

    private registerRemoteShareListener = () => {
        this.vidyoConnector.RegisterRemoteWindowShareEventListener({
            onAdded: (VidyoRemoteWindowShareObj, VidyoParticipantObj) => {
                // this.toastrService.info(
                //   this.translateService.instant('tostrs.you_are_viewing_screen', {
                //     value: VidyoParticipantObj.name
                //   })
                // );
                if (this.screenSharing) {
                    this.meeting$.next({ type: 'SHARE_STOP', data: null });
                }
                if (this.whiteboardShared) {
                    this.meeting$.next({ type: 'WHITEBOARD_STOP', data: null });
                }
            },
            onRemoved: (VidyoRemoteWindowShareObj, VidyoParticipantObj) => {
                // this.toastrService.info(`${VidyoParticipantObj.name} stopped screen share`);
            },
            onStateUpdated: (VidyoRemoteWindowShareObj, VidyoParticipantObj, stateObj) => {}
        });
    };

    private registerLocalShareListener = () => {
        this.vidyoConnector
            .RegisterLocalWindowShareEventListener({
                onAdded: (localWindowShare) => {
                    // New share is available so add it to the windowShares array and the drop-down list
                    if (localWindowShare.name !== '') {
                        if (!Object.keys(this.windowShares).length) {
                            this.windowShares[localWindowShare.id] = localWindowShare;
                        }
                    }
                },
                onRemoved: (localWindowShare) => {
                    delete this.windowShares[localWindowShare.id];
                },
                onSelected: (localWindowShare) => {
                    if (!localWindowShare) {
                        this.meeting$.next({ type: 'SHARE_STOP', data: null });
                        // this.toggleWindowShare(false);
                    } else {
                        this.screenSharing = true;
                        this.sendControlStatus();
                    }
                },
                onStateUpdated: (localWindowShare, state) => {}
            })
            .then(() => {})
            .catch(() => {});
    };

    joinRoom({ host, displayName, roomKey, roomPin, micPrivacy, cameraPrivacy }) {
        host = host.replace('https://', '');

        displayName = displayName.length <= 25 ? displayName : displayName.substr(0, 25) + '...';
        // this.vidyoConnector.SelectDefaultCamera();
        // this.vidyoConnector.SelectDefaultMicrophone();
        // this.vidyoConnector.SelectDefaultSpeaker();
        this.vidyoConnector.SetMicrophonePrivacy({ privacy: !micPrivacy });
        this.vidyoConnector.SetCameraPrivacy({ privacy: !cameraPrivacy });
        this.vidyoConnector
            .ConnectToRoomAsGuest({
                host,
                displayName,
                roomKey,
                roomPin,
                onSuccess: () => {
                    this.meeting$.next({ type: 'ACTIVE', data: null });
                    this.showRenderers();
                },
                onFailure: (reason) => {
                    if (reason === 'VIDYO_CONNECTORFAILREASON_ConnectionFailed') {
                        this.meeting$.next({
                            type: 'FAILURE',
                            data: {
                                reason: 'Connecting...'
                            }
                        });
                        this.reConnect();
                        this.reconnecting = true;
                    } else {
                        clearInterval(this.reConnectInterval);
                        this.reconnecting = false;
                        this.meeting$.next({
                            type: 'FAILURE',
                            data: {
                                reason: "Oops We couldn't connect to the meeting. Please refresh page and try again."
                            }
                        });
                    }

                    // VIDYO_CONNECTORFAILREASON_ConnectionFailed,
                    //   VIDYO_CONNECTORFAILREASON_InvalidResourceId,
                    //   VIDYO_CONNECTORFAILREASON_InvalidToken;
                    this.loggerService.error(reason);
                },
                onDisconnected: (reason) => {
                    // this.showRenderer();
                    if (reason === 'VIDYO_CONNECTORDISCONNECTREASON_Booted') {
                        this.meeting$.next({ type: 'END_CALL', data: null });
                    }
                }
            })
            .then((status) => {
                if (status) {
                    this.loggerService.log('[vc] Connect Success');
                } else {
                    this.loggerService.error('[vc] Connect Failed');
                }
            })
            .catch(() => {
                this.loggerService.error('[vc] Connect Failed');
            });
    }

    registerCameraFramesListener() {
        this.vidyoConnector.OnLocalCameraFrame((obj1, obj2) => {
            console.log(obj1, obj2);
        });
    }
    manuallyChangeSpeaker(localSpeaker: Speaker) {
        this.vidyoConnector.SelectLocalSpeaker({ localSpeaker });
    }
    manuallyChangeMicrophone(localMicrophone: Speaker) {
        this.vidyoConnector.SelectLocalMicrophone({ localMicrophone });
    }
    manuallyChangeCamera(localCamera: Speaker) {
        this.vidyoConnector.SelectLocalCamera({ localCamera });
    }
    leaveRoom() {
        this.disconneting = true;
        this.vidyoConnector
            .HideView({ viewId: this.joinData.viewIds[0] })
            .then(() => {
                console.log('HideView Success');
            })
            .catch((e) => {
                console.log('HideView Failed');
            });
        for (let i = 1; i < this.joinData.viewIds.length; i++) {
            this.vidyoConnector
                .HideView({ viewId: this.joinData.viewIds[i] })
                .then(() => {
                    console.log('HideView Success');
                    this.meeting$.next({ type: 'CLEAR_SLOT', data: { slotId: i } });
                })
                .catch((e) => {
                    console.log('HideView Failed');
                });
        }
        this.vidyoConnector.SelectLocalCamera({
            localCamera: null
        });
        this.vidyoConnector.SelectLocalMicrophone({
            localMicrophone: null
        });
        this.vidyoConnector.SelectLocalSpeaker({
            localSpeaker: null
        });
        this.toggleWindowShare(false);
        this.vidyoConnector
            .Disconnect()
            .then(() => {})
            .catch(() => {
                this.loggerService.error('Disconnect Failure');
            });
    }

    toggleCameraPrivacy(enable) {
        this.vidyoConnector
            .SetCameraPrivacy({ privacy: enable })
            .then(() => {
                this.loggerService.log('Camera privaccy changed success');
            })
            .catch(() => {
                this.loggerService.error('Camera privaccy changed failure.');
            });
        this.users[this.localParticipant.id].cameraMute = enable;
        this.sendControlStatus();

        if (enable) {
            this.meeting$.next({ type: 'VIDEO_MUTE', data: { slotId: 0 } });
            this.vidyoConnector
                .HideView({ viewId: this.joinData.viewIds[0] })
                .then(() => {
                    console.log('HideView Success');
                })
                .catch((e) => {
                    console.log('HideView Failed');
                });
        } else {
            this.meeting$.next({ type: 'VIDEO_UNMUTE', data: { slotId: 0 } });
            this.vidyoConnector
                .AssignViewToLocalCamera({
                    viewId: this.joinData.viewIds[0],
                    localCamera: this.selectedLocalCameraObj,
                    displayCropped: true,
                    allowZoom: false
                })
                .then(() => {
                    console.log('AssignViewToLocalCamera Success');
                    this.showRenderer(this.joinData.viewIds[0]);
                })
                .catch((e) => {
                    console.log('AssignViewToLocalCamera Failed');
                });
        }
    }

    toggleMicPrivacy(enable) {
        this.vidyoConnector.SetMicrophonePrivacy({ privacy: enable });
        this.users[this.localParticipant.id].microphoneMute = enable;
        this.sendControlStatus();

        if (enable) {
            this.meeting$.next({ type: 'AUDIO_MUTE', data: { slotId: 0 } });
        } else {
            this.meeting$.next({ type: 'AUDIO_UNMUTE', data: { slotId: 0 } });
        }
    }

    toggleRecordingStatus(enable) {
        this.recording = enable;
        this.sendControlStatus();
    }

    toggleMicAll(enable) {
        this.microphoneMutedAll = enable;
        this.sendControlStatus();
    }

    toggleLockStatus(enable) {
        this.roomLocked = enable;
        this.sendControlStatus();
    }

    setHostDisconnectParticipant(id) {
        if (this.users[id]) {
            this.users[id].disconnectedByHost = true;
        }
    }

    sendChatMessage({ type, message, targetParticipantId = '', targetParticipantName = '', wbUrl = '' }) {
        this.vidyoConnector.SendChatMessage({
            message: JSON.stringify({
                type,
                message,
                targetParticipantId,
                targetParticipantName,
                wbUrl
            })
        });
    }

    setHostIdentity(sender) {
        if (this.users[sender.id]) {
            this.users[sender.id].isHost = true;
        }
    }

    toggleWindowShare(enable) {
        // this.vidyoConnector.ShowWindowSharePreview({ preview: true }).then((status) => {
        //   console.log(status);
        // });
        if (!enable) {
            this.vidyoConnector.SelectLocalWindowShare({
                localWindowShare: 0
            });
            this.screenSharing = false;
            this.sendControlStatus();
            return;
        }

        // if (Object.keys(this.remoteSharingUsers).length) {
        //   this.alreadySharingScreen = true;
        //   this.sendControlStatus();
        //   return;
        // }

        if (Object.keys(this.windowShares).length) {
            this.vidyoConnector.SelectLocalWindowShare({
                localWindowShare: Object.keys(this.windowShares)[0]
            });
        }

        // this.vidyoConnector
        //   .RegisterLocalWindowShareEventListener({
        //     onAdded: (localWindowShare) => {
        //       this.vidyoConnector.SelectLocalWindowShare({
        //         localWindowShare
        //       });
        //       this.screenSharing = true;
        //       this.sendControlStatus();
        //     },
        //     onRemoved: (localWindowShare) => {},
        //     onSelected: (localWindowShare) => {
        //       if (!localWindowShare) {
        //         this.toggleWindowShare(false);
        //       }
        //     },
        //     onStateUpdated: (localWindowShare, state) => {}
        //   })
        //   .then((result) => {})
        //   .catch(() => {});
    }

    addUserInWaitingRoom(user) {
        if (user.memberName) {
            this.waitingUsers[user.memberId] = user;
            this.sendControlStatus();
        }
    }

    removeUserFromWaitingRoom(user) {
        delete this.waitingUsers[user.memberId];
        this.sendControlStatus();
    }

    setSharingScreenStatus(enable) {
        this.alreadySharingScreen = enable;
    }

    getLocalParticipant() {
        return this.localParticipant;
    }

    private sendControlStatus() {
        this.participantsStatus$.next({
            users: this.users,
            microphoneMutedAll: this.microphoneMutedAll,
            roomLocked: this.roomLocked,
            recording: this.recording,
            screenSharing: this.screenSharing,
            waitingUsers: this.waitingUsers,
            alreadySharingScreen: this.alreadySharingScreen,
            vcs: this.vcs
        });
    }

    private handleWindowEvents() {
        window.onVidyoClientLoaded = (status) => {
            this.onLoad(status);
        };

        window.onresize = () => {
            if (this.vidyoConnector) {
                this.showRenderers();
            }
        };

        window.onbeforeunload = () => {
            if (this.vidyoConnector) {
                this.vidyoConnector.Destruct();
            }
        };

        window.addEventListener('online', (e) => {
            if (this.vidyoConnector) {
                // this.reConnectOnConnectionOnline();
            }
        });
    }

    clear() {
        this.localParticipant = {};
        this.joinData = {};
        this.users = {};
        this.microphoneMutedAll = false;
        this.roomLocked = false;
        this.recording = false;
        this.screenSharing = false;
        this.cameras = [];
        this.microphones = [];
        this.speakers = [];
        this.selectedLocalCamera = 0;
        this.selectedLocalMicrophone = 0;
        this.selectedLocalSpeaker = 0;
        this.reconnecting = false;
        clearInterval(this.reConnectInterval);
    }

    changeCamera() {
        this.vidyoConnector.CycleCamera();
    }

    changeSpeaker() {
        this.vidyoConnector.CycleSpeaker();
    }

    changeMicrophone() {
        this.vidyoConnector.CycleMicrophone();
    }
    setWhiteboardStatus(state) {
        this.whiteboardShared = state;
    }
}
