import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-pre-loader',
    templateUrl: './pre-loader.component.html',
    styleUrls: ['./pre-loader.component.scss']
})
export class PreLoaderComponent implements OnInit {
    @Input() title;
    constructor(private translateService: TranslateService) {}

    ngOnInit() {
        this.setFocusOnElement('loader-focus');
    }

    getTitle() {
        return this.title || this.translateService.instant('inside_call.call_component.joining');
    }

    setFocusOnElement(focusElement) {
        setTimeout(() => {
            const element: any = document.getElementsByClassName(focusElement)[0];
            if (element) {
                element?.focus();
            }
        }, 100);
    }
}
