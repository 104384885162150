import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService, RtcService } from 'src/app/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-join-meeting',
    templateUrl: './join-meeting-tv.component.html',
    styleUrls: ['./join-meeting-tv.component.scss']
})
export class JoinMeetingTvComponent implements OnInit {
    constructor(
        private fb: FormBuilder,
        private rtcService: RtcService,
        private validdatorService: ValidationService,
        private toastrService: ToastrService
    ) {}

    joinMeetingForm: FormGroup;
    errors: any = {};
    meetingIdData;
    hashData;
    isMeetingIdFlow = false;
    isHashFlow = false;
    room;
    working = false;
    isAuthenticated = false;

    ngOnInit() {
        this.joinMeetingForm = this.fb.group({
            meetingHash: [null, [Validators.required, this.validdatorService.noWhitespaceValidator]],
            memberName: [null, [Validators.required, this.validdatorService.noWhitespaceValidator]],
            extension: [null, [Validators.required, this.validdatorService.noWhitespaceValidator]],
            pin: [null, [Validators.required, this.validdatorService.noWhitespaceValidator]]
        });
    }

    validate() {
        this.errors = {};
        if (!this.joinMeetingForm.value.extension) {
            this.errors.meetingId = 'Please enter a valid Meeting ID or Meeting Link';
            return false;
        }
        return true;
    }

    submit() {
        this.errors = {};
        // if (!this.validate()) {
        //   return;
        // }

        this.working = true;
        this.rtcService.joinWithTV(this.joinMeetingForm.value).subscribe(
            (res: any) => {
                if (res.success) {
                    this.working = false;
                    this.toastrService.success('Joined with TV successfully');
                } else {
                    this.working = false;
                    this.toastrService.error(res.errors);
                }
            },
            (err) => {
                this.working = false;
                this.errors.global = err.error.message;
            }
        );
    }
}
