import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppService, AppInfoService, UtilService } from 'src/app/core';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-download',
    templateUrl: './download.component.html',
    styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
    currentOSTypes = ['windows'];
    downloads: any = {
        windows: {
            icon: 'assets/img/win3_big.png',
            title: 'Get for Windows App',
            downloadLink: this.utilService.getWindowsClient()
        },
        mac: {
            icon: 'assets/img/osx3_big.png',
            title: 'Get for Mac',
            downloadLink: this.appService.getConfigVariable('MAC_INSTALLER_URL')
        }
    };
    loading = true;

    pluginDetails: any = {
        type: 'outlookplugin',
        data: {},
        downloadLink: this.appService.getConfigVariable('OUTLOOK_PLUGIN_URL')
    };

    constructor(
        private router: Router,
        private appService: AppService,
        private appInfoService: AppInfoService,
        private utilService: UtilService
    ) {}

    ngOnInit() {
        this.getReleaseNotes();
        this.getReleaseNotesForOutlookPlugin();
    }

    getReleaseNotes() {
        forkJoin(this.currentOSTypes.map((os) => this.appInfoService.getReleaseNotesForOS(os))).subscribe((res) => {
            res.forEach((details) => {
                Object.assign(this.downloads[details.os_type], details);
                this.downloads[details.os_type].features = details.whatsnew.split('  ');
            });
            this.loading = false;
        });
    }

    getReleaseNotesForOutlookPlugin() {
        this.appInfoService.getReleaseNotesForOS(this.pluginDetails.type).subscribe((res) => {
            this.pluginDetails.data = res;
            this.pluginDetails.data.title = 'Microsoft Outlook Plugin';
            this.pluginDetails.data.features = (res.whatsnew || '').split('  ');
        });
    }

    back() {
        this.router.navigate(['/home']);
    }

    launchPrivacy() {
        window.open(this.appService.getEnvVariable('HOST_URL') + 'privacy-policy-business');
    }

    launchTnC() {
        window.open(this.appService.getEnvVariable('HOST_URL') + 'terms-condition-business');
    }

    launchFAQ() {
        this.router.navigate(['/webhelp']);
    }

    download(os) {
        window.open(os.downloadLink);
    }

    downloadPlugin() {
        window.open(this.pluginDetails.downloadLink);
    }
}
