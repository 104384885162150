import { Injectable } from '@angular/core';

import { AppService } from './app.service';
import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root'
})
export class ContactsService {
    constructor(private appService: AppService, private restService: RestService) {}

    getDirectoryContacts({ offset = 0, query = '' }) {
        return this.restService.get(`api/contacts?keyword=${query}&offset=${offset}`);
    }

    getRecentContacts() {
        return this.restService.get(`api/calls`);
    }

    getRecentContactsTab(limit, offset) {
        // return this.restService.get(`api/contacts/recent`);
        return this.restService.get(
            `api/calls?callCategory=one_to_one_call%2Cgroup_call&offset=${offset}&limit=${limit}`
        );
    }

    getFavouriteContacts() {
        return this.restService.get(`api/favourites/getallfavourites`);
    }

    getAllGroups() {
        return this.restService.get(`api/groups?limit=100&offset=0`);
    }

    getExternalOttContacts() {
        return this.restService.get(`api/contacts/getOTTContacts?limit=100&offset=0`);
    }

    getExternalEmailContacts() {
        return this.restService.get(`api/contacts/emailcontacts?limit=100&offset=0`);
    }

    getExternalContacts() {
        return this.restService.get(`api/contacts?keyword=&offset=0`);
    }

    getAllContacts(limit = 0, offset = 0) {
        return this.restService.get(`api/contacts?limit=${limit}&offset=${offset}`);
    }

    getAllVCRooms(limit = 0, offset = 0) {
        return this.restService.get(`api/vc?limit=${limit}&offset=${offset}`);
    }

    addResourceToFavourites(resource, resourceType) {
        return this.restService.post(`api/favourites/${resourceType}`, { resourceId: resource._id });
    }

    deleteResourceFromFavourites(resource, resourceType) {
        return this.restService.delete(`api/favourites/${resourceType}`, {
            body: { resourceId: resource?.fabId }
        });
    }

    leaveGroup(group) {
        return this.restService.put(`api/groups/leave`, {
            group_id: group._id
        });
    }

    createGroup(title = '', members) {
        return this.restService.post(`api/groups`, {
            title: title,
            members: members,
            avatar: ''
        });
    }

    getGroup(groupId = '') {
        return this.restService.get(`api/groups/${groupId}`);
    }

    editGroup(group, memberId) {
        return this.restService.put(`api/groups`, {
            group_id: group._id,
            title: group.title,
            members: memberId,
            avatar: ''
        });
    }

    deleteGroup(groupId) {
        return this.restService.post(`api/groups/delete`, {
            group_id: groupId
        });
    }
}
