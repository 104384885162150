import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_EVENTS } from 'src/app/constants';
import {
    EventData,
    EventEmitterService,
    UtilService,
    GoogleTagManagerService,
    AuthService,
    DownloadAppService
} from 'src/app/core';
import { GA_NEW } from 'src/app/shared/ga-new';
import { GA_EVENTS } from '../ga-events';
import { GA4_EVENTS } from '../ga4-events';

@Component({
    selector: 'app-explore-meet',
    templateUrl: './explore-meet.component.html',
    styleUrls: ['./explore-meet.component.scss']
})
export class ExploreMeetComponent implements OnInit {
    @Input() title: string;
    @Input() desc: string;
    @Input() btnText1: string;
    @Input() btnText2: string;
    @Input() btnText1Link: string;
    @Input() btnText2Link: string;
    @Input() downloadBottom: string;
    @Input() signBottom: string;
    @Input() contactSalesEnterprise: string;
    @Input() contactSalesCpaas: string;
    @Input() bottomButtons: string;
    isMobile = false;
    isAuthenticated: any;
    constructor(
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private router: Router,
        private gTagService: GoogleTagManagerService,
        private authService: AuthService,
        private downloadAppService: DownloadAppService
    ) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser(1080);
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 1080 ? false : true;
            }
        });
        this.authService.getIsAuthenticated$().subscribe((authStatus) => {
            this.isAuthenticated = authStatus;
        });
    }

    navigateTo(route) {
        // handle external route
        if (route === '/downloadApp') {
            if (this.downloadBottom == 'downloadPlatform') {
                this.gTagService.sendEvent(GA_EVENTS.PRODUCT_JIOMEET_BOTTOM_DOWNLOAD_APP);
                this.gTagService.sendEvent(GA_NEW.PRODUCT_JIOMEET_BOTTOM_DOWNLOAD_APP);
            } else {
                this.gTagService.sendEvent(GA_EVENTS.DOWNLOADAPP_CLICKED_COMMON);
                this.gTagService.sendEvent(GA_NEW.DOWNLOADAPP_CLICKED_COMMON);
            }
            this.goToDownloadApp();
            return;
        }
        if (route === '/jiomeetDownload') {
            switch (this.bottomButtons) {
                case 'education':
                    this.gTagService.sendEvent(GA4_EVENTS.USECASE_EDUCATION_DOWNLOAD_BOTTOM);
                    break;
                case 'healthcare':
                    this.gTagService.sendEvent(GA4_EVENTS.USECASE_HEALTHCARE_DOWNLOAD_BOTTOM);
                    break;
                case 'hybrid-workplace':
                    this.gTagService.sendEvent(GA4_EVENTS.USECASE_HYBRID_WORKPLACE_DOWNLOAD_BOTTOM);
                    break;
                case 'platform-services':
                    this.gTagService.sendEvent(GA4_EVENTS.USECASE_PLATFORM_SERVICES_DOWNLOAD_BOTTOM);
                    break;
                case 'government':
                    this.gTagService.sendEvent(GA4_EVENTS.USECASE_GOVERMENT_SECTOR_DOWNLOAD_BOTTOM);
                    break;
            }
        }
        if (route === '/contactsales') {
            if (this.contactSalesCpaas == 'ContactSalesCpaas') {
                this.gTagService.sendEvent(GA_EVENTS.PRODUCT_PLATFORM_BOTTOM_CONTACT_SALES);
            } else if (this.contactSalesEnterprise == 'ContactSalesEnterprise') {
                this.gTagService.sendEvent(GA_EVENTS.PRODUCT_ENTERPRISE_BOTTOM_CONTACT_SALES);
                this.gTagService.sendEvent(GA_NEW.PRODUCT_ENTERPRISE_BOTTOM_CONTACT_SALES);
                setTimeout(() => {
                    const contactElement: any = document.getElementsByClassName('contactsalesheading')[0];
                    if (contactElement) {
                        contactElement?.focus();
                    }
                }, 0);
            } else {
                this.gTagService.sendEvent(GA_EVENTS.CONTACT_SALES_COMMON);
            }

            if (this.bottomButtons === 'homePage') {
                this.gTagService.sendEvent(GA4_EVENTS.CONTACT_SALES_HOME_PAGE);
            }
        }
        if (route === '/signup') {
            switch (this.bottomButtons) {
                case 'education':
                    this.gTagService.sendEvent(GA4_EVENTS.USECASE_EDUCATION_START_FOR_FREE_BOTTOM);
                    break;
                case 'healthcare':
                    this.gTagService.sendEvent(GA4_EVENTS.USECASE_HEALTHCARE_START_FOR_FREE_BOTTOM);
                    break;
                case 'hybrid-workplace':
                    this.gTagService.sendEvent(GA4_EVENTS.USECASE_HYBRID_WORKPLACE_START_FOR_FREE_BOTTOM);
                    break;
                case 'platform-services':
                    this.gTagService.sendEvent(GA4_EVENTS.USECASE_PLATFORM_SERVICES_START_FOR_FREE_BOTTOM);
                    break;
                case 'government':
                    this.gTagService.sendEvent(GA4_EVENTS.USECASE_GOVERMENT_SECTOR_START_FOR_FREE_BOTTOM);
                    break;
            }

            if (this.signBottom == 'signPlatform') {
                this.gTagService.sendEvent(GA_EVENTS.PRODUCT_JIOMEET_BOTTOM_SIGN_UP);
                this.gTagService.sendEvent(GA_NEW.PRODUCT_JIOMEET_BOTTOM_SIGN_UP);
                setTimeout(() => {
                    const signupElement: any = document.getElementsByClassName('signupheader')[0];
                    if (signupElement) {
                        signupElement?.focus();
                    }
                }, 0);
            } else {
                this.gTagService.sendEvent(GA_EVENTS.SIGNUP_CLICKED_COMMON);
                this.gTagService.sendEvent(GA_NEW.SIGNUP_CLICKED_COMMON);
            }
            if (this.isAuthenticated) {
                this.router.navigate(['/meetings']);
                return;
            }
        }
        if (route === '/contact-us') {
            if (this.bottomButtons === 'homePage') {
                this.gTagService.sendEvent(GA4_EVENTS.CONTACT_US_HOME_PAGE);
            }
            this.gTagService.sendEvent(GA_EVENTS.CONTACTUS_CLICKED_COMMON);
        }
        this.router.navigate([route]);
    }

    goToDownloadApp() {
        this.downloadAppService.downloadJioMeet();
    }
}
