import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

import { AppService, GaDiagnosticService, UserService, ValidationService } from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { GA_EVENTS } from '../ga-events';

@Component({
    selector: 'app-enterprise-registration',
    templateUrl: './enterprise-registration.component.html',
    styleUrls: ['./enterprise-registration.component.scss']
})
export class EnterpriseRegistrationComponent implements OnInit {
    registrationForm: FormGroup;
    error;
    errors: any = {};
    submitted = false;
    nameRegExp = /([a-zA-Z',.-]+( [a-zA-Z',.-]+)*){2,200}/;
    companyRegExp = /(?:\\s*[a-zA-Z0-9,_\\.\\077\\0100\\*\\+\\&\\#\\'\\~\\;\\-\\!\\@\\;]{2,}\\s*)*/;
    designationRegExp = /([a-zA-Z',.-]+( [a-zA-Z',.-]+)*){2,200}/;
    // phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;
    phoneRegExp = /^[6789]\d{9}$/;
    companySizes = [
        {
            name: 'Less than 10',
            value: '<10',
            tkey: 'landing_or_home_page.section.register_form.less_than_10',
            tvalue: ''
        },
        { name: '11 - 100', value: '<100', tkey: '11 - 100', tvalue: '' },
        { name: '101 - 1000', value: '<1000', tkey: '101 - 1000', tvalue: '' },
        { name: '1000+', value: '>1000', tkey: '1000+', tvalue: '' }
    ];
    sectors = [
        {
            value: 'education',
            name: 'Education',
            tkey: 'landing_or_home_page.section.register_form.education',
            tvalue: ''
        },
        {
            value: 'government',
            name: 'Government',
            tkey: 'landing_or_home_page.section.register_form.government',
            tvalue: ''
        },
        {
            value: 'healthcare',
            name: 'Healthcare',
            tkey: 'landing_or_home_page.section.register_form.healthcare',
            tvalue: ''
        },
        {
            value: 'erp',
            name: 'ERP',
            tkey: 'landing_or_home_page.section.register_form.erp',
            tvalue: ''
        },
        {
            value: 'collaboration',
            name: 'Collaboration',
            tkey: 'landing_or_home_page.section.register_form.collaboration',
            tvalue: ''
        },
        {
            value: 'financeAndBanking',
            name: 'Finance and Banking',
            tkey: 'landing_or_home_page.section.register_form.finance_and_banking',
            tvalue: ''
        },
        {
            value: 'productivity',
            name: 'Productivity',
            tkey: 'landing_or_home_page.section.register_form.productivity',
            tvalue: ''
        },
        {
            value: 'securityAndCompliance',
            name: 'Security and Compliance',
            tkey: 'landing_or_home_page.section.register_form.secure_and_comliance',
            tvalue: ''
        },
        {
            value: 'others',
            name: 'Others',
            tkey: 'landing_or_home_page.section.register_form.others',
            tvalue: ''
        }
    ];

    constructor(
        private fb: FormBuilder,
        private appService: AppService,
        private validationService: ValidationService,
        private userService: UserService,
        private translate: TranslateService,
        private toastrService: ToastrService,
        private gaService: GaDiagnosticService
    ) {}

    ngOnInit() {
        this.registrationForm = this.fb.group({
            company: ['', [Validators.required]],
            name: ['', [Validators.required]],
            designation: ['', [Validators.required]],
            phone: ['', [Validators.required, this.validationService.isFormControlValueAPhoneNumber]],
            email: ['', [Validators.required, this.validationService.isFormControlValueAnEmail]],
            companySize: ['', [Validators.required]],
            sector: ['', [Validators.required]]
        });

        this.translate
            .get([...this.companySizes.map((c) => c.tkey), ...this.sectors.map((c) => c.tkey)])
            .subscribe((translations) => {
                this.companySizes.forEach((c) => {
                    c.tvalue = translations[c.tkey];
                });
                this.sectors.forEach((s) => {
                    s.tvalue = translations[s.tkey];
                });
            });

        this.translate.onLangChange.subscribe((res) => {
            this.translate
                .get([...this.companySizes.map((c) => c.tkey), ...this.sectors.map((c) => c.tkey)])
                .subscribe((translations) => {
                    this.companySizes.forEach((c) => {
                        c.tvalue = translations[c.tkey];
                    });
                    this.sectors.forEach((s) => {
                        s.tvalue = translations[s.tkey];
                    });
                });
        });
    }

    validate() {
        this.errors = {};
        if ((this.registrationForm.value.company || '').trim().length < 1) {
            this.errors.company = this.translate.instant('tostrs.company_name_should_be_at_least_one_character_long');
            return false;
        }
        if ((this.registrationForm.value.company || '').trim().length > 1000) {
            this.errors.company = this.translate.instant('tostrs.company_name_should_not_be_more_than_1000_characters');
            return false;
        }
        if ((this.registrationForm.value.name || '').trim().length < 1) {
            this.errors.name = this.translate.instant('tostrs.name_should_be_at_least_one_character_long');
            return false;
        }
        if ((this.registrationForm.value.name || '').trim().length > 200) {
            this.errors.name = this.translate.instant('tostrs.name_should_not_be_more_than_200_characters');
            return false;
        }
        if ((this.registrationForm.value.designation || '').trim().length < 1) {
            this.errors.designation = this.translate.instant(
                'tostrs.designation_should_be_at_least_one_character_long'
            );
            return false;
        }
        if ((this.registrationForm.value.designation || '').trim().length > 200) {
            this.errors.designation = this.translate.instant(
                'tostrs.designation_should_not_be_more_than_200_characters'
            );
            return false;
        }
        if (!this.nameRegExp.test(this.registrationForm.value.name)) {
            this.errors.name = this.translate.instant('tostrs.please_enter_a_valid_name');
            return false;
        }
        if (!this.companyRegExp.test(this.registrationForm.value.company)) {
            this.errors.company = this.translate.instant('tostrs.please_enter_a_valid_company_name');
            return false;
        }
        if (!this.designationRegExp.test(this.registrationForm.value.designation)) {
            this.errors.designation = this.translate.instant('tostrs.please_enter_a_valid_designation');
            return false;
        }
        if (!this.phoneRegExp.test(this.registrationForm.value.phone)) {
            this.errors.phone = this.translate.instant('tostrs.please_enter_a_valid_phone_number');
            return false;
        }
        // if (!this.validationService.isPhoneNumber(this.registrationForm.value.phone)) {
        //   this.errors.phone = 'Please enter a valid phone number.';
        //   return false;
        // }
        if (!this.validationService.isEmail(this.registrationForm.value.email)) {
            this.errors.email = this.translate.instant('tostrs.please_enter_a_valid_email_id');
            return false;
        }
        return true;
    }

    register() {
        if (!this.registrationForm.valid) {
            return;
        }
        this.errors = {};
        this.error = false;
        if (!this.validate()) {
            return;
        }
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translate.instant('tostrs.something_went_right'));
            return;
        }
        this.gaService.sendEvent(GA_EVENTS.ENTERPRISE_REGISTERED);
        const { companySize, sector, ...payload } = this.registrationForm.value;
        payload.companySize = companySize.value;
        payload.sector = sector.value;

        this.userService.registerForEnterprise(payload).subscribe(
            (res) => {
                this.submitted = true;
            },
            (err) => {
                this.error =
                    err && err.error && err.error.message
                        ? err.error.message === 'REGISTRATION_LIMIT_EXCEEDED'
                            ? this.translate.instant(
                                  'landing_or_home_page.section.register_form.you_are_already_registered'
                              )
                            : err.error.message
                        : this.translate.instant('landing_or_home_page.section.register_form.something_went_wrong');
            }
        );
    }

    checkCharLimit(limit, event) {
        if (
            event.which !== 46 && // delete
            event.which !== 8 && // backspace
            event.target.value.length >= limit
        ) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    checkNumber(event) {
        // backspace, space, +, -, del, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0
        if (![8, 32, 43, 45, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57].includes(event.which)) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    navigateToTnC() {
        window.open(this.appService.getEnvVariable('HOST_URL') + 'terms-condition-business');
    }
}
