<div class="livestreamsettings-container">
    <div class="mainpage" *ngIf="!showconfigurationpage">
        <div class="title d-flex align-items-left">
            <div class="heading">
                <label tabindex="0" aria-label="Live Stream" class="livefocus">{{
                    'joinmeeting_page_or_joincall.live_stream' | translate
                }}</label>
                <img
                    alt=""
                    class="addicon pointer"
                    src="assets/images/live-stream-add.svg"
                    tabindex="0"
                    aria-label="Add Live Stream Configuration details"
                    (keydown.enter)="showAddPopup()"
                    (click)="showAddPopup()"
                />
            </div>
        </div>

        <ng-container *ngIf="streams.length === 0">
            <div class="content">
                <div class="no-data text-center">
                    <div class="text">
                        {{ 'user_dashboard_settings.live_stream.no_live_streams_placeholder' | translate }}
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="streams.length !== 0">
            <div class="w-60 mt-3">
                <table class="streamtable" aria-hidden="true">
                    <tr tabindex="0" aria-label="Stream and Actions for live streaming">
                        <th>{{ 'user_dashboard_settings.live_stream.stream' | translate }}</th>
                        <th class="text-right">{{ 'user_dashboard_settings.live_stream.actions' | translate }}</th>
                    </tr>
                    <ng-container *ngFor="let stream of streams; let i = index">
                        <tr>
                            <td
                                tabindex="0"
                                aria-hidden="true"
                                aria-label="{{ stream }}"
                                role="text"
                                class="d-flex align-items-center"
                            >
                                <span>
                                    <img
                                        alt=""
                                        src="assets/images/live_stream_youtube.svg"
                                        *ngIf="stream.streamPlatform === 'yt'"
                                    />
                                    <img
                                        alt=""
                                        src="assets/images/live_stream_facebook.svg"
                                        *ngIf="stream.streamPlatform === 'fb'"
                                    />
                                    <img
                                        alt=""
                                        src="assets/images/live_stream_linkedin.png"
                                        *ngIf="stream.streamPlatform === 'ld'"
                                        width="16"
                                    />
                                    <img
                                        alt=""
                                        src="assets/images/icon_custom_settings.svg"
                                        *ngIf="stream.streamPlatform === 'custom'"
                                    />
                                </span>
                                <span class="ml-2 {{ stream.streamPlatform }}">
                                    {{ stream.streamName | titlecase }}
                                </span>
                            </td>
                            <td class="text-right">
                                <img
                                    alt=""
                                    tabindex="0"
                                    role="button"
                                    aria-label="edit stream"
                                    class="pointer mr-2"
                                    src="assets/images/edit-icon-streaming.svg"
                                    (click)="initUpdateLiveStream(stream)"
                                    (keydown.enter)="initUpdateLiveStream(stream)"
                                />
                                <img
                                    alt=""
                                    tabindex="0"
                                    role="button"
                                    aria-label="delete stream"
                                    class="pointer"
                                    src="assets/images/delete-icon-streaming.svg"
                                    (click)="toggleDeletePopup(stream)"
                                    (keydown.enter)="toggleDeletePopup(stream)"
                                    (keydown.tab)="handleLastelementTab(i)"
                                />
                            </td>
                        </tr>
                        <tr *ngIf="errorId === stream._id">
                            <td colspan="2" class="error">
                                {{ 'user_dashboard_settings.live_stream.cannot_edit_delete' | translate }}
                            </td>
                        </tr>
                    </ng-container>
                </table>
            </div>
            <div class="popup-component delete-popup" *ngIf="showdeletepopup">
                <div class="bg"></div>
                <div class="delete-popup-container popup">
                    <div class="popup-header">
                        <h4>
                            {{
                                'user_dashboard_settings.live_stream.delete_live_stream'
                                    | translate : { value: activeStream?.streamName }
                            }}
                        </h4>
                        <div class="close" (click)="toggleDeletePopup()">
                            <img alt="" src="assets/img/close_icon.svg" />
                        </div>
                    </div>
                    <div class="popup-body">
                        <div class="message mt-4">
                            {{
                                'user_dashboard_settings.live_stream.want_to_delete'
                                    | translate : { value: activeStream?.streamName }
                            }}
                        </div>
                        <div class="actions mt-5 text-right">
                            <button type="button" class="custom-button bg-secondary mr-2" (click)="toggleDeletePopup()">
                                {{ 'user_dashboard_settings.cancel' | translate }}
                            </button>
                            <button type="button" class="custom-button bg-primary mr-2" (click)="deleteLiveStream()">
                                {{ 'user_dashboard_settings.delete' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div *ngIf="showconfigurationpage" class="config-form">
        <app-live-stream-config
            (close)="hideAddPopup()"
            [streamObj]="activeStream"
            [isRenderFromCall]="false"
        ></app-live-stream-config>
    </div>
</div>

<!-- Start Overflow Live Stream Modal -->
<div class="overflow-live-stream-popup" *ngIf="showHideoverflowLiveStream">
    <div class="bg"></div>
    <div class="content text-left">
        <div class="header">
            <div class="d-flex justify-content-between">
                <div class="title">
                    {{ 'inside_call.live_stream.live_stream' | translate }}
                </div>
                <button type="button" class="close" aria-label="Close" (click)="showHideoverflowLiveStream = false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="body">
            <div>
                {{ 'user_dashboard_settings.live_stream.settings_overflow_error' | translate }}
            </div>
        </div>
        <div class="footer" *ngIf="!initAddLiveStreamForm">
            <div class="options d-flex justify-content-end">
                <button class="custom-button bg-primary option" (click)="showHideoverflowLiveStream = false">
                    {{ 'user_dashboard_settings.okay' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
<!-- End Overflow Live Stream Modal  -->
