import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { RestService } from './rest.service';
import { BUILD } from 'src/environments/build_version';

import * as _ from 'lodash-es';
import { UtilService } from './util.service';
import { LocalStorageService } from './local-storage.service';
import { SystemService } from './system.service';

const NA = 'NA';
const ES_CONTENT_TYPE = 'application/x-ndjson';
const DEFAULT_ERROR_MESSAGE = 'Error without a message';
const API_KEY = 'ApiKey';
const STRING_TYPE = 'string';

export enum LogLevel {
    DEBUG = 1,
    INFO = 2,
    WARN = 3,
    ERROR = 4
}

@Injectable({
    providedIn: 'root'
})
export class AppLoggerService {
    public logsEnabled = false;
    private userInfo: any;
    private currentMeetingInfo: any;
    private guestUserInfo: any;
    private cloudLoggingConfig: any;
    private enableCloudLogging: boolean;
    private enableConsoleLogging: boolean = true;
    private logBuffer = [];
    private isUploading = false;
    private originalConsoleLog;
    private originalConsoleDebug;
    private originalConsoleAssert;
    private originalConsoleInfo;
    private originalConsoleError;
    private originalConsoleWarn;
    public build_version = BUILD.version;
    public localStorageLogging;
    public isInisideIframe;
    public localLogs = {
        errors: [],
        nonErrors: [],
        resetLogs: function () {
            this.errors = [];
            this.nonErrors = [];
        }
    };

    constructor(private appService: AppService, private restService: RestService, private utilService: UtilService) {
        this.isInisideIframe = window.self !== window.top;
    }

    initialize() {
        this.cloudLoggingConfig = this.appService.getConfigVariable('cloudLogging');
        this.enableCloudLogging = this.cloudLoggingConfig?.enable;
        this.localStorageLogging = this.appService.getConfigVariable('LOCAL_LOGS_ENABLED');
        this.enableConsoleLogging = !!this.appService.getConfigVariable('LOGS_ENABLED');
        if (this.enableCloudLogging) {
            setInterval(this.uploadLogs.bind(this), this.cloudLoggingConfig.timeout);
        }
        if (this.localStorageLogging?.enable) {
            setInterval(this.storeInLocal.bind(this), this.localStorageLogging?.timeout);
        }
        this.handleConsoleLogs();
    }

    handleConsoleLogs() {
        if (this.enableConsoleLogging) {
            this.originalConsoleAssert = console.assert;
            this.originalConsoleDebug = console.debug;
            this.originalConsoleInfo = console.info;
            this.originalConsoleLog = console.log;
            this.originalConsoleError = console.error;
            this.originalConsoleWarn = console.warn;
        }
        var selfRef = this;
        console.log = this.log.bind(this);
        console.debug = this.debug.bind(this);
        console.info = this.info.bind(this);
        console.warn = this.warn.bind(this);
        console.error = this.error.bind(this);
        console.assert = this.assert.bind(this);
    }

    setGuestUserInfo(guestUser: any) {
        this.guestUserInfo = this.guestUserInfo;
    }

    setUserInfo(userInfo) {
        this.userInfo = userInfo;
    }

    setMeetingInfo(meetingInfo) {
        this.currentMeetingInfo = meetingInfo;
    }

    isCloudLoggingUrl(url: string): boolean {
        return this.cloudLoggingConfig && url.indexOf(this.cloudLoggingConfig.url) > -1;
    }

    queueLogs(level: LogLevel, message, args?) {
        var data = {};
        var error;
        if (!_.isEmpty(args)) {
            var head = _.head(args);
            if (head instanceof Error) {
                // incase of error as JSON.stringify won't work build the object with stack
                error = {
                    stack: head.stack
                };
                if (args?.length > 1) {
                    data = args[1];
                }
            } else if (_.isObject(head) && !_.isArray(head)) {
                // consider only one argument and drop it if it is not an object
                data = head;
            } else {
                // Storing direct values aswell
                data = head;
            }
        }
        var logDocument = {
            '@timestamp': new Date().toISOString(),
            level: level,
            build_version: this.build_version,
            identity: this.userInfo?.email || this.userInfo?.phoneNo || this.guestUserInfo?.name || NA,
            userId: this.userInfo?._id || NA, // guest user id ?
            name: this.guestUserInfo?.name || this.userInfo?.name || NA,
            lname: this.guestUserInfo?.name || this.userInfo?.lname || NA,
            meetingId: this.currentMeetingInfo?.jiomeetId || NA,
            userAgent: navigator.userAgent || NA,
            message: typeof message === STRING_TYPE ? message.toString() : JSON.stringify(message),
            data,
            error
        };
        if (this.enableCloudLogging && level >= this.cloudLoggingConfig.level) {
            this.logBuffer.push(logDocument);
            if (this.logBuffer.length >= this.cloudLoggingConfig.batchSize) {
                this.uploadLogs();
            }
        }
        if (
            !this.isInisideIframe &&
            this.localStorageLogging?.enable &&
            level >= this.localStorageLogging?.level &&
            window.localStorage !== undefined
        ) {
            if (level === 4) this.localLogs?.errors.push(logDocument);
            this.localLogs?.nonErrors.push(logDocument);
            if (
                this.localLogs?.errors?.length + this.localLogs?.nonErrors?.length >=
                this.localStorageLogging?.batchSize
            ) {
                this.storeInLocal();
            }
        }
    }

    async uploadLogs() {
        try {
            if (!this.isUploading && !_.isEmpty(this.logBuffer)) {
                this.isUploading = true;
                var body = '';
                this.logBuffer.forEach((doc) => {
                    try {
                        body += `${JSON.stringify({ index: { _index: this.cloudLoggingConfig.index } })}\n`;
                        body += `${JSON.stringify(doc)}\n`;
                    } catch (err) {
                        this.logInternalError(err);
                    }
                });
                body += '\n';
                var tempBuffer = this.logBuffer;
                const bulkResponse: any = await this.restService
                    .post(
                        this.cloudLoggingConfig.url,
                        body,
                        {
                            headers: {
                                Authorization: `${API_KEY} ${this.cloudLoggingConfig.apiKey}`,
                                'Content-Type': ES_CONTENT_TYPE,
                                'x-platform': this.utilService.fetchPlatformType(),
                                'x-version': this.appService.fetchBuildVersion() || this.build_version
                            }
                        },
                        false
                    )
                    .toPromise();
                this.logBuffer = [];
                if (bulkResponse.errors) {
                    const erroredDocuments = [];
                    // The items array has the same order of the dataset we just indexed.
                    // The presence of the `error` key indicates that the operation
                    // that we did for the document has failed.
                    bulkResponse.items.forEach((action, i) => {
                        const operation = Object.keys(action)[0];
                        // If the status is 429 it means that you can retry the document,
                        // otherwise it's very likely a mapping error, and you should
                        // fix the document before to try it again.
                        if (action[operation].error && action[operation].status === 429) {
                            this.logBuffer.push(tempBuffer[i]);
                        }
                    });
                }
                this.isUploading = false;
            }
        } catch (error) {
            this.logInternalError(error);
            this.logBuffer = [];
        } finally {
            if (!this.logBuffer) {
                this.logBuffer = [];
            }
            this.isUploading = false;
        }
    }

    storeInLocal() {
        if (!this.isInisideIframe && (this.localLogs?.errors?.length > 0 || this.localLogs?.nonErrors?.length > 0)) {
            let appLocalErrorLogs: any = localStorage.getItem('appLocalErrorLogs');
            let appLocalNonErrorLogs: any = localStorage.getItem('appLocalNonErrorLogs');

            if (appLocalErrorLogs === null || appLocalErrorLogs === '') {
                appLocalErrorLogs = JSON.stringify([]);
                localStorage.setItem('appLocalErrorLogs', JSON.stringify([]));
            }
            if (appLocalNonErrorLogs === null || appLocalNonErrorLogs === '') {
                appLocalNonErrorLogs = JSON.stringify([]);
                localStorage.setItem('appLocalNonErrorLogs', JSON.stringify([]));
            }

            appLocalErrorLogs = typeof appLocalErrorLogs === 'string' ? JSON.parse(appLocalErrorLogs) : [];
            appLocalNonErrorLogs = typeof appLocalNonErrorLogs === 'string' ? JSON.parse(appLocalNonErrorLogs) : [];

            appLocalErrorLogs = appLocalErrorLogs?.concat(this.localLogs?.errors);
            if (appLocalErrorLogs?.length > this.localStorageLogging?.limit) {
                appLocalErrorLogs = appLocalErrorLogs?.slice(-this.localStorageLogging?.limit);
            }
            appLocalNonErrorLogs = appLocalNonErrorLogs?.concat(this.localLogs?.nonErrors);
            if (appLocalNonErrorLogs?.length > this.localStorageLogging?.limit) {
                appLocalNonErrorLogs = appLocalNonErrorLogs?.slice(-this.localStorageLogging?.limit);
            }

            try {
                const errorData = JSON.stringify(appLocalErrorLogs, this.removeCircularReferences());
                localStorage.setItem('appLocalErrorLogs', errorData);
            } catch (error) {
                console.error('Error storing error logs or CircularReferences error:', error);
            }
            try {
                const infoData = JSON.stringify(appLocalNonErrorLogs, this.removeCircularReferences());
                localStorage.setItem('appLocalErrorLogs', infoData);
            } catch (error) {
                console.error('Info storing info logs or CircularReferences error:', error);
            }

            this.localLogs.resetLogs();
        }
    }

    removeCircularReferences() {
        const seen = new WeakSet();
        return (key, value) => {
            if (typeof value === 'object' && value !== null) {
                if (seen.has(value)) {
                    return; // Skip circular references
                }
                seen.add(value);
            }
            return value; // Return value as is
        };
    }

    logInternalError(error) {
        if (this.originalConsoleError) {
            this.originalConsoleError(error);
        } else {
            console.error(error);
        }
    }

    log(...args) {
        // anything passed without a level goes to debug
        if (this.enableCloudLogging || this.localStorageLogging?.enable) {
            var logArg = args[0];
            if (logArg instanceof Error) {
                this.queueLogs.call(this, LogLevel.ERROR, args[0], _.tail(args));
            } else {
                this.queueLogs.call(this, LogLevel.DEBUG, args[0], _.tail(args));
            }
        }

        if (this.enableConsoleLogging) {
            if (args.length && args[0] instanceof Error) {
                this.originalConsoleError(...args);
            } else {
                this.originalConsoleLog(...args);
            }
        }
    }

    debug(...args) {
        // anything passed without a level goes to debug
        if (this.enableCloudLogging || this.localStorageLogging?.enable) {
            this.queueLogs.call(this, LogLevel.DEBUG, args[0], _.tail(args));
        }
        if (this.enableConsoleLogging) {
            this.originalConsoleDebug(...args);
        }
    }

    info(...args) {
        if (this.enableCloudLogging || this.localStorageLogging?.enable) {
            this.queueLogs.call(this, LogLevel.INFO, args[0], _.tail(args));
        }
        if (this.enableConsoleLogging) {
            this.originalConsoleInfo(...args);
        }
    }

    assert(...args) {
        if (this.enableCloudLogging || this.localStorageLogging?.enable) {
            this.queueLogs.call(this, LogLevel.DEBUG, args[0], _.tail(args));
        }
        if (this.enableConsoleLogging) {
            this.originalConsoleAssert(...args);
        }
    }

    error(...args) {
        if (this.enableCloudLogging || this.localStorageLogging?.enable) {
            this.queueLogs.call(this, LogLevel.ERROR, args[0], _.tail(args));
        }
        if (this.enableConsoleLogging) {
            this.originalConsoleError(...args);
        }
    }

    warn(...args) {
        if (this.enableCloudLogging || this.localStorageLogging?.enable) {
            this.queueLogs.call(this, LogLevel.WARN, args[0], _.tail(args));
        }
        if (this.enableConsoleLogging) {
            this.originalConsoleWarn(...args);
        }
    }

    group(groupName, ...rest) {
        if (this.enableConsoleLogging) {
        }
    }

    groupEnd() {
        if (this.enableConsoleLogging) {
        }
    }

    handleError(error) {
        if (this.enableCloudLogging || this.localStorageLogging?.enable) {
            // add meeting context ?
            this.queueLogs.call(this, LogLevel.ERROR, error.message || DEFAULT_ERROR_MESSAGE, [error]);
        }
        if (this.enableConsoleLogging) {
            this.originalConsoleError ? this.originalConsoleError(error) : console.error(error);
        }
    }
}
