import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-download-icsfile',
    templateUrl: './download-icsfile.component.html',
    styleUrls: ['./download-icsfile.component.scss']
})
export class DownloadIcsfileComponent implements OnInit {
    constructor(
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.userService.downloadIcsFile(params?.jiomeetId, params?.roomPIN).subscribe(
                (_res) => {
                    const header = _res.headers.get('content-disposition');
                    const filename = header.split('=')[1];
                    this.download(filename, _res.body);
                    this.router.navigate(['/home']);
                },
                (err) => {
                    if (err === 'no jwt') {
                        this.toastrService.info(this.translateService.instant('tostrs.not_logged_in'));
                        return this.handleUrlRedirection();
                    }
                    err = JSON.parse(err?.error);
                    this.toastrService.error(err?.errors);
                    this.router.navigate(['/home']);
                }
            );
        });
    }

    handleUrlRedirection() {
        this.router.navigate(['/login'], {
            queryParams: {
                returnUrl: this.router.routerState.snapshot.url
            }
        });
    }

    download(filename = 'meeting.ics', text) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.setAttribute('target', '_blank');
        element.style.display = 'none';
        element.click();
    }
}
