import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import hi from 'date-fns/locale/hi';
import enGB from 'date-fns/locale/en-GB';

@Pipe({ name: 'convertToLocaleDate' })
export class ConvertToLocaleDatePipe implements PipeTransform {
    constructor(public translateService: TranslateService) {}
    transform(value: string, dateFormat) {
        let localeLang = this.translateService.currentLang === 'en' ? enGB : hi;
        let resultDate = format(new Date(value), dateFormat, { locale: localeLang });
        return resultDate;
    }
}
