import { isDevMode, Pipe, PipeTransform } from '@angular/core';
import { AppService, DesktopAppService } from 'src/app/core';

@Pipe({
    name: 'imageSrcTransformer'
})
export class ImageSrcTransformerPipe implements PipeTransform {
    hostUrl: string;
    constructor(private desktopAppService: DesktopAppService, private appService: AppService) {
        this.hostUrl = this.appService.getEnvVariable('HOST_URL');
    }

    transform(url: string): string {
        console.log('checking env for image source transform');
        if (this.desktopAppService.isDesktopApp && !isDevMode()) {
            console.log('transforming using host url');
            return `${url}`;
        } else {
            return url;
        }
    }
}
