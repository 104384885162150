<div class="duration" title="{{ 'inside_call.call_component.call_duration' | translate }}" tabindex="0">
    <time class="unit" *ngIf="duration.days > 0" aria-label="{{ duration.days | pad }} days">{{
        duration.days | pad
    }}</time>
    <span class="separator" *ngIf="duration.days > 0">:</span>
    <time class="unit" [attr.aria-label]="isBR ? brduration.hours : duration.hours + 'Hours'">{{
        isBR ? (brduration.hours | pad) : (duration.hours | pad)
    }}</time>
    <span class="separator">:</span>
    <time class="unit" [attr.aria-label]="isBR ? brduration.minutes : duration.minutes + 'Minutes'">{{
        isBR ? (brduration.minutes | pad) : (duration.minutes | pad)
    }}</time>
    <span class="separator">:</span>
    <time class="unit" [attr.aria-label]="isBR ? brduration.seconds : duration.seconds + 'Seconds'">{{
        isBR ? (brduration.seconds | pad) : (duration.seconds | pad)
    }}</time>
</div>
