import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { APP_EVENTS } from 'src/app/constants';
import {
    AppService,
    AuthService,
    EventData,
    EventEmitterService,
    UserService,
    UtilService,
    GoogleTagManagerService
} from 'src/app/core';
import { GA_EVENTS } from '../ga-events';
import { GA_PAGEVIEW } from '../ga-pageview';
import { WEBSITE_CONFERENCE_FEATURES, TAILORED_USECASES } from '../websiteConstant';
import * as Flickity from 'flickity';
import { GA_NEW } from 'src/app/shared/ga-new';

@Component({
    selector: 'app-website-conference',
    templateUrl: './website-conference.component.html',
    styleUrls: ['./website-conference.component.scss']
})
export class WebsiteConferenceComponent implements OnInit {
    isMobile = false;
    isAuthenticated = false;
    currentUser: any = {};
    websiteConferenceFeatures = WEBSITE_CONFERENCE_FEATURES;
    tailored_usecase = TAILORED_USECASES;
    websiteMetaInfo;
    testimonialCarousel;
    @ViewChild('testimonialSlider', { static: true })
    testimonialSlider: ElementRef;
    jioEventsTestimonials: string[] = [];
    jioEventsBranding: string[] = [];

    constructor(
        private utilService: UtilService,
        private userService: UserService,
        private authService: AuthService,
        private eventEmitterService: EventEmitterService,
        private appService: AppService,
        private gTagService: GoogleTagManagerService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser(1080);
        this.translateConferenceSolutions();
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 1080 ? false : true;
            }
        });
        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
        });
        this.currentUser = this.userService.getUserSync();
        this.gTagService.pushOnDataLayer(GA_PAGEVIEW.PRODUCT_PAGE_VIEW_JIOEVENT);
        this.getMetaInfo();
    }

    async translateConferenceSolutions() {
        const websiteConferenceFeatures = await this.utilService.handleDataTranslation('website', {
            conferenceFeatures: this.websiteConferenceFeatures
        });
        this.websiteConferenceFeatures = websiteConferenceFeatures['conferenceFeatures'];
    }

    getMetaInfo() {
        this.userService.getWebsiteMetaInfo().subscribe(
            (res) => {
                if (res) {
                    this.websiteMetaInfo = res;

                    setTimeout(() => {
                        this.initTestimonialSlider();
                    }, 0);
                    this.testimonialCheck(this.websiteMetaInfo.testimonial);
                    this.brandingCheck(this.websiteMetaInfo.branding);
                }
            },
            (err) => {}
        );
    }

    testimonialCheck(testimonials) {
        if (testimonials?.length > 0) {
            testimonials.forEach((element) => {
                if (element.publishOn.includes('JioEvents')) {
                    this.jioEventsTestimonials.push(element);
                }
            });
        }
    }

    brandingCheck(branding) {
        if (branding?.length > 0) {
            branding.forEach((element) => {
                if (element.publishOn.includes('JioEvents')) {
                    this.jioEventsBranding.push(element);
                }
            });
        }
    }

    initTestimonialSlider() {
        this.testimonialCarousel = new Flickity(this.testimonialSlider.nativeElement, {
            prevNextButtons: !this.isMobile,
            autoPlay: 4000,
            contain: false,
            cellAlign: 0,
            pageDots: true,
            selectedAttraction: 0.2,
            friction: 0.8,
            index: 0,
            resize: true
        });
    }

    navToEventsDashboard() {
        this.gTagService.sendEvent(GA_EVENTS.JIOEVENTS_DASHBOARD);
        window.open(`${this.appService.getConfigVariable('JIOEVENTS_BASE_URL')}dashboard`, '_blank');
    }
    navToCreateEvents() {
        this.gTagService.sendEvent(GA_EVENTS.CREATE_EVENTS);
        window.open(`${this.appService.getConfigVariable('JIOEVENTS_BASE_URL')}dashboard?create=true`, '_blank');
    }
    navToJioEvents() {
        this.gTagService.sendEvent(GA_EVENTS.PRODUCT_JIOEVENT_EVENT_WEBSITE);
        window.open(`${this.appService.getConfigVariable('JIOEVENTS_BASE_URL')}home`, '_blank');
    }

    goToContactSales(type) {
        const event =
            type == 1 ? GA_EVENTS.PRODUCT_JIOEVENT_CONTACT_SALES : GA_EVENTS.PRODUCT_JIOEVENT_CONTACT_SALES_BOTTOM;
        this.gTagService.sendEvent(event);
        this.router.navigate(['contactsales']);
    }
}
