import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { interval, Subscription } from 'rxjs';
import { EventEmitterService, GoogleTagManagerService } from 'src/app/core';
import { APP_EVENTS } from '../../../constants';

@Component({
    selector: 'app-call-duration',
    templateUrl: './call-duration.component.html',
    styleUrls: ['./call-duration.component.scss']
})
export class CallDurationComponent implements OnInit, OnDestroy {
    intervalSubscription: Subscription;
    intervalBRSubscription: Subscription;
    isBR: boolean = false;
    duration = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    brduration = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    @Input() stopTimer = false;
    constructor(private gTagService: GoogleTagManagerService, private eventEmitterService: EventEmitterService) {}

    ngOnInit() {
        this.initiateTimer();
        this.eventEmitterService.subscribe((event) => {
            if (event.type == APP_EVENTS.RESETTIMER) {
                this.initiateTimer();
            } else if (event.type == APP_EVENTS.ENTER_BREAKOUTROOM) {
                this.isBR = true;
                this.initiateBRTimer();
            } else if (event.type == APP_EVENTS.EXIT_BREAKOUTROOM) {
                this.isBR = false;
                if (0 <= this.duration.seconds && this.duration.seconds <= 5) {
                    this.duration.seconds = this.duration.seconds + 55;
                } else {
                    this.duration.seconds = this.duration.seconds - 5;
                }
            }
        });
    }

    initiateBRTimer() {
        this.ngOnDestroy();
        let seconds = 0;
        this.intervalBRSubscription = interval(1000).subscribe(() => {
            seconds++;
            if (!this.stopTimer) {
                this.brduration.hours = this.pad(Math.floor(seconds / 60 / 60), 2);
                this.brduration.minutes = this.pad(Math.floor(seconds / 60) % 60, 2);
                this.brduration.seconds = this.pad(Math.floor(seconds - this.brduration.minutes * 60), 2);
            } else {
                this.gTagService.sendEvent({
                    event: 'jiomeet',
                    event_category: 'inside_call',
                    event_action: 'meeting_duration',
                    event_label: `${this.brduration.hours}:${this.brduration.minutes}:${this.brduration.seconds}`
                });
                this.intervalBRSubscription.unsubscribe();
            }
        });
    }

    initiateTimer() {
        this.ngOnDestroy();
        let seconds = 0;
        this.intervalSubscription = interval(1000).subscribe(() => {
            seconds++;
            // this.duration.days = Math.floor(seconds / (24 * 60 * 60));
            // seconds = seconds % (24 * 60 * 60);

            // this.duration.hours = Math.floor(seconds / (60 * 60));
            // seconds = seconds % (60 * 60);

            // this.duration.minutes = Math.floor(seconds / 60);
            // this.duration.seconds = seconds % 60;
            if (!this.stopTimer) {
                this.duration.hours = this.pad(Math.floor(seconds / 60 / 60), 2);
                this.duration.minutes = this.pad(Math.floor(seconds / 60) % 60, 2);
                this.duration.seconds = this.pad(Math.floor(seconds - this.duration.minutes * 60), 2);
            } else {
                this.gTagService.sendEvent({
                    event: 'jiomeet',
                    event_category: 'inside_call',
                    event_action: 'meeting_duration',
                    event_label: `${this.duration.hours}:${this.duration.minutes}:${this.duration.seconds}`
                });
                this.intervalSubscription.unsubscribe();
            }
        });
    }

    pad(num, size) {
        return parseInt(('000' + num).slice(size * -1));
    }

    ngOnDestroy() {
        if (!this.isBR) {
            if (this.intervalSubscription) {
                this.intervalSubscription.unsubscribe();
                this.intervalSubscription = null;
                this.duration = { days: 0, hours: 0, minutes: 0, seconds: 0 };
            }
        } else {
            if (this.intervalBRSubscription) {
                this.intervalBRSubscription.unsubscribe();
                this.intervalBRSubscription = null;
                this.brduration = { days: 0, hours: 0, minutes: 0, seconds: 0 };
            }
        }
    }
}
