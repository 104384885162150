import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import {
    RtcService,
    UserService,
    ThirdPartyExternalIntegrationService,
    VideoWrapperService,
    GaDiagnosticService,
    AuthService,
    AppService,
    EventEmitterService,
    SystemService,
    LocalStorageService,
    GuestAuthService,
    SessionStorageService
} from 'src/app/core';
import { forkJoin, from, Subscription } from 'rxjs';
declare const navigator: any;
import { GA_EVENTS } from './ga-events';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { APP_EVENTS } from 'src/app/constants';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GoogleTagManagerService } from 'src/app/core';
import { GA_NEW } from '../../ga-new';
import { VirtualBgService } from 'src/app/core/services/virtual-bg.service';

@Component({
    selector: 'app-call-preview',
    templateUrl: './call-preview.component.html',
    styleUrls: ['./call-preview.component.scss']
})
export class CallPreviewComponent implements OnInit, AfterViewInit, OnDestroy {
    currentUser: any;
    @Input() meetingObj;
    @Input() hostMeeting;
    @Input() joinWithoutVideo;
    @Input() joinWithoutAudio;
    @Output() showsettings = new EventEmitter();
    micPrivacy = false;
    cameraPrivacy = false;
    showSettingsPopup;
    videoService;
    isThirdPartyExternalIntegration;
    showControls = false;
    subscriptions: Subscription[] = [];
    isAuthenticated: boolean;
    vbEnabled: any;
    meetingParams: any = {
        enableVideo: false,
        enableMic: false
    };
    bannersConfigData;
    recorderUser: boolean = false;

    constructor(
        private vbService: VirtualBgService,
        public rtcService: RtcService,
        private userService: UserService,
        private guestUserService: GuestAuthService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        private zone: NgZone,
        private authService: AuthService,
        private videoWrapperService: VideoWrapperService,
        private appService: AppService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private eventEmitterService: EventEmitterService,
        private router: Router,
        private systemService: SystemService,
        private gTagService: GoogleTagManagerService,
        private activatedRoute: ActivatedRoute,
        private localStorageSession: LocalStorageService,
        public sessionStorageService: SessionStorageService
    ) {
        this.videoService = this.videoWrapperService.getVideoServiceForPreview();
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params) => (this.meetingParams = { ...params }));
        this.isThirdPartyExternalIntegration =
            this.thirdPartyExternalIntegrationService.getThirdPartyExternalIntegration();
        this.vbEnabled = this.appService.getConfigVariable('IS_VB_ENABLED') && !this.systemService.isSafariBrowser();
        this.bannersConfigData = this.appService.getConfigVariable('BANNERS_CONFIG');
        this.subscriptions.push(
            this.videoService.getMeetingObs().subscribe((event) => {
                this.zone.run(() => {
                    if (!event) {
                        return;
                    }
                    this.handleConferenceEvents(event);
                });
            }),
            this.eventEmitterService.subscribe((event: any) => {
                if (event?.type === APP_EVENTS.TOGGLE_CAMERA_OFF) {
                    if (this.videoService?.localCameraTrack?._enabled) {
                        this.toggleVideo();
                    }
                }
            })
        );
        this.currentUser = this.userService.getUserSync();
        this.isAuthenticated = this.authService.getIsAuthenticated();
        this.recorderUser = this.appService.getRecorderUser();
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            if (!event.url.includes('conference')) {
                this.videoService.checkAndToggleOffCamera();
            }
        });
    }

    ngAfterViewInit() {
        let cpass = this.localStorageSession.getItem('isPlatform');
        if (cpass) {
            const confInfo = this.rtcService.getConferenceInfo();
            this.subscriptions.push(
                this.rtcService
                    .getRoomDetailsGuest({
                        extension: this.meetingParams?.meetingId,
                        pin: this.meetingParams?.pwd,
                        userId: this.userID(),
                        memberName: this.meetingObj?.guest_name,
                        roomID: confInfo?.room?.roomID,
                        breakoutpoll: true,
                        isAuthenticated: this.authService.getIsAuthenticated() || false
                    })
                    .subscribe(
                        (room: any) => {
                            if (room.roomDetails) {
                                this.appService.setMeetingId(room?.roomDetails?.meetingId);
                                const advancedSettings = room.roomDetails.advancedOptions;
                                if (advancedSettings.participantVideo) {
                                    let checkedVideo = false;

                                    const checkVideo = setInterval(() => {
                                        if (this.videoService.cameras.length !== 0) {
                                            clearInterval(checkVideo);
                                            this.toggleVideo();
                                        } else if (!checkedVideo) {
                                            checkedVideo = true;
                                            this.eventEmitterService.emit({
                                                type: APP_EVENTS.SHOW_PERMISSION_UI,
                                                data: true
                                            });
                                        }
                                    }, 1000);
                                }
                                if (advancedSettings.participantAudio) {
                                    let checkedAudio = false;

                                    const checkAudio = setInterval(() => {
                                        if (this.videoService.microphones.length !== 0) {
                                            clearInterval(checkAudio);
                                            this.toggleAudio();
                                        } else if (!checkedAudio) {
                                            checkedAudio = true;
                                            this.eventEmitterService.emit({
                                                type: APP_EVENTS.SHOW_PERMISSION_UI,
                                                data: true
                                            });
                                        }
                                    }, 1000);
                                }
                            }
                        },
                        (err) => {}
                    )
            );
        }
        this.rtcService.setCameraMicPreference(false, false);
        if (!this.recorderUser) {
            setTimeout(() => {
                let audioStatus = this.sessionStorageService.getItem('audioStatus');
                if (audioStatus === 'true' || audioStatus === true) {
                    this.toggleAudio();
                }
                let videoStatus = this.sessionStorageService.getItem('videoStatus');
                if (videoStatus === true || videoStatus === 'true') {
                    this.toggleVideo();
                }
                let vbUrl = this.sessionStorageService.getItem('vbImage');
                if (vbUrl) {
                    this.vbService.setBgConfig({ type: 'image', imageUrl: vbUrl });
                    this.videoService.setBackgroundImage(vbUrl);
                }
            }, 300);
        }

        setTimeout(() => {
            if (this.meetingParams?.enableMic === 'true') {
                this.changeMicPrivacy();
            }
            if (this.meetingParams?.enableVideo === 'true') {
                this.toggleVideo();
            }
            this.setupConnector();
            // this.checkPreferencesAndInitiate();
        }, 30);
    }

    userID() {
        return this.currentUser?._id || this.guestUserService.getAuthInfo()?.guestUserId;
    }

    handleConferenceEvents(event) {
        switch (event.type) {
            case 'SHOW_PREVIEW_CNTROLS':
                this.showControls = true;
                break;
        }
    }

    setupConnector() {
        this.videoService.setJoinData({
            host: null,
            displayName: null,
            roomKey: null,
            roomPin: null,
            micPrivacy: this.micPrivacy,
            cameraPrivacy: this.cameraPrivacy,
            autoJoin: false,
            viewId: 'preview-renderer'
        });
        this.videoService.initializeVidyoConnector();
    }

    toggleSettingsPopup(setting) {
        setTimeout(() => {
            const popUpElement: any = document.getElementsByClassName('setting-container')[0];
            if (popUpElement) {
                popUpElement?.focus();
            }
        }, 100);
        this.videoService.selectDevices();
        this.showsettings.emit({ setting });
        if (setting === 'video') {
            const settingVideo = !this.hostMeeting
                ? GA_EVENTS.PREPARATORY_SCREEN_JOIN_MEETING_SETTINGS
                : GA_EVENTS.PREPARATORY_SCREEN_START_MEETING_SETTINGS;
            this.gTagService.sendEvent(settingVideo);
            const settingVideoNew = !this.hostMeeting
                ? GA_NEW.PREPARATORY_SCREEN_JOIN_MEETING_SETTINGS
                : GA_NEW.PREPARATORY_SCREEN_START_MEETING_SETTINGS;
            this.gTagService.sendEvent(settingVideoNew);
        } else if (setting === 'background') {
            const settingBackground = !this.hostMeeting
                ? GA_EVENTS.PREPARATORY_SCREEN_JOIN_VIRTUAL_BACKGROUND
                : GA_EVENTS.PREPARATORY_SCREEN_START_VIRTUAL_BACKGROUND;
            const settingBackgroundNew = !this.hostMeeting
                ? GA_NEW.PREPARATORY_SCREEN_JOIN_VIRTUAL_BACKGROUND
                : GA_NEW.PREPARATORY_SCREEN_START_VIRTUAL_BACKGROUND;
            this.gTagService.sendEvent(settingBackground);
            this.gTagService.sendEvent(settingBackgroundNew);
        }
    }

    async changeCameraPrivacy() {
        if (this.videoService?.processingToggleCamera) {
            return;
        }
        await this.videoService.toggleCameraForSetting('preview-renderer', !this.cameraPrivacy);
        this.cameraPrivacy = !this.cameraPrivacy;
        if (!document.getElementById('preview-renderer')) {
            this.videoService.localCameraTrack.setEnabled(false);
        }
        if (!this.hostMeeting) {
            const camJoinState = this.cameraPrivacy
                ? GA_EVENTS.PREPARATORY_SCREEN_JOIN_START_VIDEO
                : GA_EVENTS.PREPARATORY_SCREEN_JOIN_STOP_VIDEO;
            const camJoinStateNew = this.cameraPrivacy
                ? GA_NEW.PREPARATORY_SCREEN_JOIN_START_VIDEO
                : GA_NEW.PREPARATORY_SCREEN_JOIN_STOP_VIDEO;
            this.gTagService.sendEvent(camJoinState);
            this.gTagService.sendEvent(camJoinStateNew);
        } else {
            const camStartState = this.cameraPrivacy
                ? GA_EVENTS.PREPARATORY_SCREEN_START_START_VIDEO
                : GA_EVENTS.PREPARATORY_SCREEN_START_STOP_VIDEO;
            const camStartStateNew = this.cameraPrivacy
                ? GA_NEW.PREPARATORY_SCREEN_START_START_VIDEO
                : GA_NEW.PREPARATORY_SCREEN_START_STOP_VIDEO;
            this.gTagService.sendEvent(camStartState);
            this.gTagService.sendEvent(camStartStateNew);
        }
        this.rtcService.setCameraMicPreference(this.cameraPrivacy, this.micPrivacy);
    }

    async toggleVideo() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
            stream.getVideoTracks().forEach((track) => track.stop());
            if (this.videoService.cameras.length === 0) {
                this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: true });
                return;
            }
            if (this.isThirdPartyExternalIntegration) {
                this.changeCameraPrivacy();
                return;
            }
            this.changeCameraPrivacy();
        } catch (err) {
            this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: true });
            this.gTagService.sendEvent(GA_EVENTS.PREPARATORY_SCREEN_START_VIDEO_FAILURE);
        }
    }

    changeMicPrivacy() {
        this.videoService.toggleMicPrivacy(this.micPrivacy);
        this.micPrivacy = !this.micPrivacy;
        if (!this.hostMeeting) {
            const micJoinState = this.micPrivacy
                ? GA_EVENTS.PREPARATORY_SCREEN_JOIN_UNMUTE
                : GA_EVENTS.PREPARATORY_SCREEN_JOIN_MUTE;
            const micJoinStateNew = this.micPrivacy
                ? GA_NEW.PREPARATORY_SCREEN_JOIN_UNMUTE
                : GA_NEW.PREPARATORY_SCREEN_JOIN_MUTE;
            this.gTagService.sendEvent(micJoinState);
            this.gTagService.sendEvent(micJoinStateNew);
        } else {
            const micStartState = this.micPrivacy
                ? GA_EVENTS.PREPARATORY_SCREEN_START_UNMUTE
                : GA_EVENTS.PREPARATORY_SCREEN_START_MUTE;
            const micStartStateNew = this.micPrivacy
                ? GA_NEW.PREPARATORY_SCREEN_START_UNMUTE
                : GA_NEW.PREPARATORY_SCREEN_START_MUTE;
            this.gTagService.sendEvent(micStartState);
            this.gTagService.sendEvent(micStartStateNew);
        }
        this.rtcService.setCameraMicPreference(this.cameraPrivacy, this.micPrivacy);
    }

    async toggleAudio() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
            stream.getAudioTracks().forEach((track) => track.stop());
            if (this.videoService.microphones.length === 0) {
                this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: true });
                return;
            }
            if (this.isThirdPartyExternalIntegration) {
                this.changeMicPrivacy();
                return;
            }
            this.changeMicPrivacy();
        } catch (err) {
            this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: true });
            this.gTagService.sendEvent(GA_EVENTS.PREPARATORY_SCREEN_UNMUTE_FAILURE);
        }
    }

    checkPreferencesAndInitiate() {
        if (this.hostMeeting) {
            this.userService.getMeetingPrefernces().subscribe((preferences) => {
                this.micPrivacy = preferences.hostAudio;
                this.cameraPrivacy = preferences.hostVideo;
                this.setupConnector();
            });
        } else {
            this.micPrivacy = !this.userService.getUserSync()?.microphoneOff;
            this.cameraPrivacy = !this.userService.getUserSync()?.videoOff;
            this.setupConnector();
        }
    }

    ngOnDestroy() {
        this.sessionStorageService.addItem('videoStatus', 'false');
        this.sessionStorageService.addItem('audioStatus', 'false');
        this.videoService.releaseDevices();
        this.subscriptions.map((s) => s.unsubscribe());
    }
}
