import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

import * as FileSaver from 'file-saver';

import { JiocloudService } from 'src/app/core/services/jiocloud.service';

import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
    selector: 'app-image-viewer',
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit, OnDestroy {
    @Input() meetingImageTranscodeUrl;
    @Input() jiomeetId;
    @Input() fileName;
    @Input() downloadUrl;
    @Input() isDataProtection;
    @Output() close = new EventEmitter();
    @ViewChild('downloadAnchor', { static: true }) downloadAnchor: ElementRef;
    public webUrl: string = '';
    public size: string = 'm';
    public showPreview: boolean = false;
    toastIds: any[] = [];
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;

    constructor(
        public jcService: JiocloudService,
        private toastrService: ToastrService,
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        this.toastrService.overlayContainer = this.toastContainer;
        this.showPreview = true;
        console.log('meeting image transcode url', this.meetingImageTranscodeUrl, this.jiomeetId, this.fileName);
        this.getImageUrl();
    }

    closePreview(value: boolean) {
        this.close.emit(value);
    }

    fullScreen() {
        var elem = document.getElementById('myObject');
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem['mozRequestFullScreen']) {
            /* Firefox */
            elem['mozRequestFullScreen']();
        } else if (elem['webkitRequestFullscreen']) {
            /* Chrome, Safari and Opera */
            elem['webkitRequestFullscreen']();
        } else if (elem['msRequestFullscreen']) {
            /* IE/Edge */
            elem['msRequestFullscreen']();
        }
        document.addEventListener('fullscreenchange', (event) => {
            if (document.fullscreenElement) {
                this.size = 'l';
            } else {
                this.size = 'm';
            }
        });
        this.getImageUrl();
    }

    getImageUrl() {
        this.webUrl = this.meetingImageTranscodeUrl;
    }

    downloadFile() {
        if (this.isDataProtection) {
            this.toastrService.error(this.translateService.instant('tostrs.downloading_a_file_is_not_allowed'));
            return;
        }
        this.toastIds.push(this.toastrService.success(this.translateService.instant('tostrs.downloading_in_progress')));
        this.jcService
            .downloadFile(this.fileName, this.downloadUrl, this.jiomeetId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((resp) => {
                FileSaver.saveAs(resp, this.fileName);
                this.toastIds.push(
                    this.toastrService.success(this.translateService.instant('tostrs.download_completed'))
                );
            });
    }

    ngOnDestroy() {
        this.toastIds.forEach((toastr) => {
            this.toastrService.clear(toastr.toastId);
        });
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }
}
