import { AfterViewInit, Directive, ElementRef, OnInit, HostListener } from '@angular/core';
import { UtilService } from 'src/app/core';

@Directive({
    selector: '[appScrollShowHide]'
})
export class ScrollShowHideDirective implements OnInit, AfterViewInit {
    isMobile = false;
    timeoutRef;
    lastScrollPos = 0;
    constructor(private element: ElementRef, private utilService: UtilService) {}

    ngOnInit() {
        this.isMobile = this.utilService.isMobileBrowser();
    }

    ngAfterViewInit() {
        if (!this.isMobile) return;
        this.element.nativeElement.classList.add('hide-scrollbar');
    }

    @HostListener('scroll', ['$event'])
    handleShowHideScroll(e) {
        if (!this.isMobile) return;
        const scrollTop = e.target.scrollTop;

        if (!this.timeoutRef && Math.abs(scrollTop - this.lastScrollPos) > 100) {
            if (!this.element.nativeElement.classList.contains('showScrollbar')) {
                this.element.nativeElement.classList.add('show-scrollbar');
            }
            this.timeoutRef = setTimeout(() => {
                this.timeoutRef = 0;
                this.lastScrollPos = this.element.nativeElement.scrollTop;
                this.element.nativeElement.classList.remove('show-scrollbar');
            }, 1000);
        }
    }
}
