import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    // define a prefix for local storage keys
    private prefix = 'jmc';
    // in-memory storage for use when inside an iframe
    private localInMemoryStorage = {};
    // flag to check if the current window is inside an iframe
    private isInisideIframe = false;

    constructor() {
        // check if the current window is inside an iframe
        this.isInisideIframe = window.self !== window.top;
    }

    /**
     * Add an item to local storage
     * @param key the key to use for storing the item
     * @param value the value to store
     */
    addItem(key: string, value: any) {
        try {
            // construct the storage key by adding the prefix to the provided key
            const storageKey = `${this.prefix}.${key}`;
            // check if the window is inside an iframe
            if (!this.isInisideIframe) {
                // use regular local storage if not inside an iframe
                localStorage.setItem(storageKey, JSON.stringify(value));
            } else {
                // store in-memory if inside an iframe
                this.localInMemoryStorage[storageKey] = JSON.stringify(value);
            }
        } catch (error) {
            console.error('Error adding item to local storage', error);
        }
    }

    /**
     * Remove an item from local storage
     * @param key the key of the item to remove
     */
    removeItem(key: string) {
        try {
            // construct the storage key by adding the prefix to the provided key
            const storageKey = `${this.prefix}.${key}`;
            if (!this.isInisideIframe) {
                // use regular local storage if not inside an iframe
                localStorage.removeItem(storageKey);
            } else {
                // remove from in-memory storage if inside an iframe
                delete this.localInMemoryStorage[storageKey];
            }
        } catch (error) {
            console.error('Error removing item from local storage', error);
        }
    }

    /**
     * Get an item from local storage
     * @param key the key of the item to retrieve
     * @returns the value of the item, or null if not found
     */
    getItem(key: string) {
        try {
            // construct the storage key by adding the prefix to the provided key
            const storageKey = `${this.prefix}.${key}`;
            if (!this.isInisideIframe) {
                // use regular local storage if not inside an iframe
                const item = localStorage.getItem(storageKey);
                return item ? JSON.parse(item) : null;
            } else {
                // get from in-memory storage if inside an iframe
                const item = this.localInMemoryStorage[storageKey];
                return item ? JSON.parse(item) : null;
            }
        } catch (error) {
            console.error('Error getting item from local storage', error);
            // return null if an error occurs to prevent the code from throwing an error
            return null;
        }
    }

    /**
     * Clear all items from local storage
     */
    clear() {
        try {
            if (this.isInisideIframe) {
                // clear in-memory storage if inside an iframe
                this.localInMemoryStorage = {};
            } else {
                // use regular local storage if not inside an iframe
                localStorage.clear();
            }
        } catch (error) {
            console.error('Error clearing local storage', error);
        }
    }
}
