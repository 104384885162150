import { Injectable } from '@angular/core';
import { APP_CONSTANT, KEYBOARD_CONSTANT } from 'src/app/constants';
import { EventEmitterService, UtilService } from 'src/app/core';

@Injectable({
    providedIn: 'root'
})
export class KeyboardeventsService {
    constructor(private eventEmitterService: EventEmitterService, private utilService: UtilService) {}

    macOSkeyBoardShortcuts = [
        KEYBOARD_CONSTANT.COMMAND_J,
        KEYBOARD_CONSTANT.COMMAND_SHIFT_J,
        KEYBOARD_CONSTANT.COMMAND_CTRL_V,
        KEYBOARD_CONSTANT.COMMAND_E,
        KEYBOARD_CONSTANT.COMMAND_L,
        KEYBOARD_CONSTANT.COMMAND_D,
        KEYBOARD_CONSTANT.COMMAND_SHIFT_A,
        KEYBOARD_CONSTANT.COMMAND_CTRL_M,
        KEYBOARD_CONSTANT.COMMAND_CTRL_U,
        KEYBOARD_CONSTANT.COMMAND_SHIFT_V,
        KEYBOARD_CONSTANT.COMMAND_SHIFT_N,
        KEYBOARD_CONSTANT.COMMAND_SHIFT_S,
        KEYBOARD_CONSTANT.COMMAND_SHIFT_T,
        KEYBOARD_CONSTANT.COMMAND_SHIFT_C,
        KEYBOARD_CONSTANT.COMMAND_SHIFT_P,
        KEYBOARD_CONSTANT.COMMAND_SHIFT_W,
        KEYBOARD_CONSTANT.COMMAND_P,
        KEYBOARD_CONSTANT.COMMAND_N,
        KEYBOARD_CONSTANT.COMMAND_U,
        KEYBOARD_CONSTANT.COMMAND_SHIFT_H,
        KEYBOARD_CONSTANT.COMMAND_I,
        KEYBOARD_CONSTANT.COMMAND_SHIFT_I,
        KEYBOARD_CONSTANT.OPTION_Y,
        KEYBOARD_CONSTANT.COMMAND_CTRL_F,
        KEYBOARD_CONSTANT.COMMAND_2,
        KEYBOARD_CONSTANT.COMMAND_PLUS_SIGN,
        KEYBOARD_CONSTANT.COMMAND_MINUS_SIGN,
        KEYBOARD_CONSTANT.COMMAND_LEFTBRACKET,
        KEYBOARD_CONSTANT.COMMAND_RIGHTBRACKET,
        KEYBOARD_CONSTANT.COMMAND_O,
        KEYBOARD_CONSTANT.COMMAND_F,
        KEYBOARD_CONSTANT.COMMAND_OPTION_I,
        KEYBOARD_CONSTANT.COMMAND_CTRL_N,
        KEYBOARD_CONSTANT.COMMAND_SHIFT_U,
        KEYBOARD_CONSTANT.COMMAND_A,
        KEYBOARD_CONSTANT.COMMAND_R,
        KEYBOARD_CONSTANT.COMMAND_OPTION_C
    ];

    otherOSkeyBoardShortcuts = [
        KEYBOARD_CONSTANT.CTRL_J,
        KEYBOARD_CONSTANT.CTRL_SHIFT_J,
        KEYBOARD_CONSTANT.CTRL_ALT_V,
        KEYBOARD_CONSTANT.CTRL_E,
        KEYBOARD_CONSTANT.CTRL_L,
        KEYBOARD_CONSTANT.CTRL_D,
        KEYBOARD_CONSTANT.CTRL_SHIFT_A,
        KEYBOARD_CONSTANT.CTRL_ALT_M,
        KEYBOARD_CONSTANT.CTRL_ALT_U,
        KEYBOARD_CONSTANT.CTRL_SHIFT_V,
        KEYBOARD_CONSTANT.CTRL_SHIFT_N,
        KEYBOARD_CONSTANT.CTRL_SHIFT_S,
        KEYBOARD_CONSTANT.CTRL_SHIFT_T,
        KEYBOARD_CONSTANT.CTRL_SHIFT_C,
        KEYBOARD_CONSTANT.CTRL_SHIFT_P,
        KEYBOARD_CONSTANT.CTRL_ALT_G,
        KEYBOARD_CONSTANT.CTRL_P,
        KEYBOARD_CONSTANT.CTRL_N,
        KEYBOARD_CONSTANT.CTRL_U,
        KEYBOARD_CONSTANT.CTRL_SHIFT_H,
        KEYBOARD_CONSTANT.CTRL_I,
        KEYBOARD_CONSTANT.CTRL_SHIFT_I,
        KEYBOARD_CONSTANT.WINDOWS_Y,
        KEYBOARD_CONSTANT.CTRL_ALT_F,
        KEYBOARD_CONSTANT.CTRL_2,
        KEYBOARD_CONSTANT.CTRL_PLUS_SIGN,
        KEYBOARD_CONSTANT.CTRL_MINUS_SIGN,
        KEYBOARD_CONSTANT.CTRL_LEFTBRACKET,
        KEYBOARD_CONSTANT.CTRL_RIGHTBRACKET,
        KEYBOARD_CONSTANT.CTRL_O,
        KEYBOARD_CONSTANT.CTRL_F,
        KEYBOARD_CONSTANT.CTRL_OPTION_I,
        KEYBOARD_CONSTANT.CTRL_ALT_N,
        KEYBOARD_CONSTANT.CTRL_SHIFT_U,
        KEYBOARD_CONSTANT.CTRL_ALT_A,
        KEYBOARD_CONSTANT.CTRL_ALT_D,
        KEYBOARD_CONSTANT.CTRL_ALT_C,
        KEYBOARD_CONSTANT.ALT_SPACE_N
    ];

    getAllKeyboardShorcuts(): string[] {
        if (this.utilService.getOS() === 'Mac OS') {
            return this.macOSkeyBoardShortcuts;
        }
        return this.otherOSkeyBoardShortcuts;
    }

    recieveKeboardShortcuts(shortcut) {
        switch (shortcut) {
            case KEYBOARD_CONSTANT.COMMAND_J:
            case KEYBOARD_CONSTANT.CTRL_J:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.OPEN_JOIN_MEETING);
                break;
            case KEYBOARD_CONSTANT.COMMAND_SHIFT_J:
            case KEYBOARD_CONSTANT.CTRL_SHIFT_J:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.JOIN_MEETING);
                break;
            case KEYBOARD_CONSTANT.COMMAND_CTRL_V:
            case KEYBOARD_CONSTANT.CTRL_ALT_V:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.OPEN_START_MEETING);
                break;
            case KEYBOARD_CONSTANT.COMMAND_E:
            case KEYBOARD_CONSTANT.CTRL_E:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.END_ALL_MEETING);
                break;
            case KEYBOARD_CONSTANT.COMMAND_L:
            case KEYBOARD_CONSTANT.CTRL_L:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.LEAVE_MEETING);
                break;
            case KEYBOARD_CONSTANT.COMMAND_D:
            case KEYBOARD_CONSTANT.CTRL_D:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.OPEN_SCHEDULE_MEETING);
                break;
            case KEYBOARD_CONSTANT.COMMAND_SHIFT_A:
            case KEYBOARD_CONSTANT.CTRL_SHIFT_A:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.MIC_TOGGLE);
                break;
            case KEYBOARD_CONSTANT.COMMAND_CTRL_M:
            case KEYBOARD_CONSTANT.CTRL_ALT_M:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.FORCE_MUTE);
                break;
            case KEYBOARD_CONSTANT.COMMAND_CTRL_U:
            case KEYBOARD_CONSTANT.CTRL_ALT_U:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.UNDO_FORCE_MUTE);
                break;
            case KEYBOARD_CONSTANT.COMMAND_SHIFT_V:
            case KEYBOARD_CONSTANT.CTRL_SHIFT_V:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.CAMERA_TOGGLE);
                break;
            case KEYBOARD_CONSTANT.COMMAND_SHIFT_N:
            case KEYBOARD_CONSTANT.CTRL_SHIFT_N: //NEED TO CHECK
                break;
            case KEYBOARD_CONSTANT.COMMAND_SHIFT_S:
            case KEYBOARD_CONSTANT.CTRL_SHIFT_S:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.SHARE_SCREEN_TOGGLE);
                break;
            case KEYBOARD_CONSTANT.COMMAND_SHIFT_T:
            case KEYBOARD_CONSTANT.CTRL_SHIFT_T: //NEED TO CHECK
                break;
            case KEYBOARD_CONSTANT.COMMAND_SHIFT_C:
            case KEYBOARD_CONSTANT.CTRL_SHIFT_C:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.RECORDING_TOGGLE);
                break;
            case KEYBOARD_CONSTANT.COMMAND_SHIFT_P:
            case KEYBOARD_CONSTANT.CTRL_SHIFT_P: //NEED TO CHECK
                break;
            case KEYBOARD_CONSTANT.COMMAND_SHIFT_W:
            case KEYBOARD_CONSTANT.CTRL_ALT_G:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.LAYOUT_CHANGE);
                break;
            case KEYBOARD_CONSTANT.COMMAND_P:
            case KEYBOARD_CONSTANT.CTRL_P:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.LAYOUT_PREV_PAGE);
                break;
            case KEYBOARD_CONSTANT.COMMAND_N:
            case KEYBOARD_CONSTANT.CTRL_N:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.LAYOUT_NEXT_PAGE);
                break;
            case KEYBOARD_CONSTANT.COMMAND_U:
            case KEYBOARD_CONSTANT.CTRL_U:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.OPEN_PARTICPANT_PANEL);
                break;
            case KEYBOARD_CONSTANT.COMMAND_SHIFT_H:
            case KEYBOARD_CONSTANT.CTRL_SHIFT_H:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.OPEN_CHAT_PANEL);
                break;
            case KEYBOARD_CONSTANT.COMMAND_I:
            case KEYBOARD_CONSTANT.CTRL_I:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.OPEN_INIVTE_POPUP);
                break;
            case KEYBOARD_CONSTANT.COMMAND_SHIFT_I:
            case KEYBOARD_CONSTANT.CTRL_SHIFT_I:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.COPY_INIVTE);
                break;
            case KEYBOARD_CONSTANT.OPTION_Y:
            case KEYBOARD_CONSTANT.WINDOWS_Y:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.HAND_RAISE_TOGGLE);
                break;
            case KEYBOARD_CONSTANT.COMMAND_CTRL_F:
            case KEYBOARD_CONSTANT.CTRL_ALT_F:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.FULL_SCREEN);
                break;
            case KEYBOARD_CONSTANT.COMMAND_2:
            case KEYBOARD_CONSTANT.CTRL_2: //NEED TO CHECK
                break;
            case KEYBOARD_CONSTANT.COMMAND_PLUS_SIGN:
            case KEYBOARD_CONSTANT.CTRL_PLUS_SIGN: //NEED TO CHECK
                break;
            case KEYBOARD_CONSTANT.COMMAND_MINUS_SIGN:
            case KEYBOARD_CONSTANT.CTRL_MINUS_SIGN: //NEED TO CHECK
                break;
            case KEYBOARD_CONSTANT.COMMAND_LEFTBRACKET:
            case KEYBOARD_CONSTANT.CTRL_LEFTBRACKET:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.BACKWARD_CHAT_HISTORY);
                break;
            case KEYBOARD_CONSTANT.COMMAND_RIGHTBRACKET:
            case KEYBOARD_CONSTANT.CTRL_RIGHTBRACKET:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.FORWARD_CHAT_HISTORY);
                break;
            case KEYBOARD_CONSTANT.COMMAND_O:
            case KEYBOARD_CONSTANT.CTRL_O:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.NEW_CHAT);
                break;
            case KEYBOARD_CONSTANT.COMMAND_F:
            case KEYBOARD_CONSTANT.CTRL_F:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.SEARCH_CHAT);
                break;
            case KEYBOARD_CONSTANT.COMMAND_OPTION_I:
            case KEYBOARD_CONSTANT.CTRL_OPTION_I:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.ADD_MEMBER_TO_GROUP);
                break;
            case KEYBOARD_CONSTANT.COMMAND_CTRL_N:
            case KEYBOARD_CONSTANT.CTRL_ALT_N:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.GOTO_FIRST_CHAT);
                break;
            case KEYBOARD_CONSTANT.COMMAND_SHIFT_U:
            case KEYBOARD_CONSTANT.CTRL_SHIFT_U: //NEED TO CHECK
                break;
            case KEYBOARD_CONSTANT.COMMAND_A:
            case KEYBOARD_CONSTANT.CTRL_ALT_A:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.CALL_ACCEPT);
                break;
            case KEYBOARD_CONSTANT.COMMAND_R:
            case KEYBOARD_CONSTANT.CTRL_ALT_D:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.CALL_DECLINE);
                break;
            case KEYBOARD_CONSTANT.COMMAND_OPTION_C:
            case KEYBOARD_CONSTANT.CTRL_ALT_C:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.CLOSE_WINDOW);
                break;
            case KEYBOARD_CONSTANT.ALT_SPACE_N:
                this.emitKeyBoardShortcutEvent(KEYBOARD_CONSTANT.MINIMAL_WINDOW);
                break;
        }
    }

    emitKeyBoardShortcutEvent(data: any) {
        this.eventEmitterService.emit({
            type: KEYBOARD_CONSTANT.KEYBOARD_SHORTCUTS,
            data: data
        });
    }
}
