import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root'
})
export class RCChatService {
    token;

    constructor(private restService: RestService) {}

    login() {
        return this.restService.get(`api/rc/login`).pipe(tap((res) => (this.token = res.loginToken)));
    }

    getRCThreadId(jiomeetId) {
        return this.restService.post(`api/rc/getrcthread`, { jiomeetId });
    }

    getToken() {
        return this.token;
    }
}
