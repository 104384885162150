import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class ThirdPartyExternalIntegrationService {
    isBackNavigationDisabled = false;
    userInfo: any;
    isThirdPartyExternalIntegration: boolean;
    isNavBarDisabledForExternalIntegration: boolean;
    isFooterDisabledForExternalIntegration: boolean;
    isHaptikDisabled: boolean;
    isBreakoutRoomDisabled: boolean;
    isCallEndSent: boolean;
    meetingShareTemplate = `You are invited to a JioMeet meeting. Please click this link to join: `;
    isChatDisabled: boolean;
    isMyjioIntegration: boolean = false;
    isHostTokenUser = false;
    hasJoinedFromGuestURL = false;
    isJioHealthHubIntegration: boolean = false;
    hideParticipantList: boolean = true;
    isMyjioJhhIntegration: boolean = false;
    setProxyServer = 0;
    enableManualTurnServer = false;
    isMattermost: boolean;
    guestWhiteboardDisabled = false;
    isEkycEnabled = false;
    isLeaveDisabled = false;

    constructor() {}
    setGuestWhiteboardDisabled(bool: boolean) {
        this.guestWhiteboardDisabled = bool;
    }

    getGuestWhiteboardDisabled() {
        return this.guestWhiteboardDisabled;
    }

    isDigiGovCustomer() {
        return this.isEkycEnabled && !this.isHostTokenUser;
    }

    setIsEkycEnabled(bool: boolean) {
        this.isEkycEnabled = bool;
    }

    getIsEkycEnabled() {
        return this.isEkycEnabled;
    }

    setIsCallEndSent() {
        this.isCallEndSent = true;
    }
    getIsCallEndSent() {
        return this.isCallEndSent ? true : false;
    }

    setIsChatDisabled() {
        this.isChatDisabled = true;
    }

    setIsLeaveDisabled(bool: boolean) {
        this.isLeaveDisabled = bool;
    }

    getIsLeaveDisabled() {
        return this.isLeaveDisabled;
    }

    getIsChatDisabled() {
        return this.isChatDisabled ? true : false;
    }

    enableThirdPartyIntegration() {
        this.isThirdPartyExternalIntegration = true;
    }

    enableMattermostIntegration() {
        this.isMattermost = true;
    }

    disableBreakoutRoomForExternalIntegration() {
        this.isBreakoutRoomDisabled = true;
    }

    disableNavbarForExternalIntegration() {
        this.isNavBarDisabledForExternalIntegration = true;
    }

    disableHaptikForExternalIntegration() {
        this.isHaptikDisabled = true;
    }

    disableFooterForExternalIntegration() {
        this.isFooterDisabledForExternalIntegration = true;
    }

    getIsBreakoutRoomDisabled() {
        return this.isBreakoutRoomDisabled ? true : false;
    }

    getIsNavbarDisabledForExternalIntegration() {
        return this.isNavBarDisabledForExternalIntegration ? true : false;
    }

    getThirdPartyExternalIntegration() {
        return this.isThirdPartyExternalIntegration ? true : false;
    }

    getMattermostIntegration() {
        return this.isMattermost ? true : false;
    }

    getIsFooterDisabledForExternalIntegration() {
        return this.isFooterDisabledForExternalIntegration ? true : false;
    }

    getIsHaptikDisabledForExternalIntegration() {
        return this.isFooterDisabledForExternalIntegration ? true : false;
    }

    setUserInfo(userInfo) {
        this.userInfo = userInfo;
    }

    getUserInfo() {
        return this.userInfo;
    }

    enableBackNavigationOnHomePage() {
        this.isBackNavigationDisabled = false;
    }

    disableBackNavigationOnHomePage() {
        this.isBackNavigationDisabled = true;
    }

    getIsNavigationDisabledOnHomePage() {
        return this.isBackNavigationDisabled;
    }

    generateMeetingShareTemplate(url) {
        return `${this.meetingShareTemplate} ${url}`;
    }

    enableMyjioIntegration() {
        this.isMyjioIntegration = true;
        sessionStorage.setItem('myjioIntegration', 'true');
    }

    getMyjioIntegration() {
        return this.isMyjioIntegration;
    }

    enableJioHealthHubIntegration() {
        this.isJioHealthHubIntegration = true;
    }

    getJioHealthHubIntegration() {
        return this.isJioHealthHubIntegration;
    }

    disableParticipantList() {
        this.hideParticipantList = true;
    }

    enableParticipantList() {
        this.hideParticipantList = false;
    }

    getParticipantListStatus() {
        return this.hideParticipantList;
    }

    myjioJhhIntegration() {
        this.isMyjioJhhIntegration = true;
    }

    getMyjioJhhIntegration() {
        return this.isMyjioJhhIntegration;
    }
}
