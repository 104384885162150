import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FullscreenService {
    fullscreenMode$ = new Subject();

    toggleFullScreenMode(element): boolean {
        if (
            !(
                document.fullscreenElement ||
                document['webkitFullscreenElement'] ||
                document['msFullscreenElement'] ||
                document['mozFullscreenElement'] ||
                document['webkitCurrentFullScreenElement']
            )
        ) {
            const fullscreenElement: any = document.getElementById(element);
            if (fullscreenElement.requestFullscreen) {
                fullscreenElement.requestFullscreen();
            } else if (fullscreenElement.mozRequestFullScreen) {
                fullscreenElement.mozRequestFullScreen();
            } else if (fullscreenElement.webkitRequestFullscreen) {
                fullscreenElement.webkitRequestFullscreen();
            } else if (fullscreenElement.msRequestFullscreen) {
                fullscreenElement.msRequestFullscreen();
            }
            return true;
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document['mozCancelFullScreen']) {
                document['mozCancelFullScreen']();
            } else if (document['webkitExitFullscreen']) {
                document['webkitExitFullscreen']();
            } else if (document['msExitFullscreen']) {
                document['msExitFullscreen']();
            }
            return false;
        }
    }

    handleFullScreenChange() {
        if (
            document.fullscreenElement ||
            document['webkitFullscreenElement'] ||
            document['msFullscreenElement'] ||
            document['mozFullscreenElement'] ||
            document['webkitCurrentFullScreenElement']
        ) {
            this.fullscreenMode$.next(true);
        } else {
            this.fullscreenMode$.next(false);
        }
    }

    getFullScreenStatus() {
        return this.fullscreenMode$;
    }

    registerWindowEvents() {
        document.addEventListener('fullscreenchange', this.handleFullScreenChange.bind(this));
        document.addEventListener('webkitfullscreenchange', this.handleFullScreenChange.bind(this));
    }

    unregisterDocumentListener() {
        document.removeEventListener('fullscreenchange', this.handleFullScreenChange.bind(this));
        document.removeEventListener('webkitfullscreenchange', this.handleFullScreenChange.bind(this));
    }
}
