import { Injectable } from '@angular/core';
import { ToastrComponentlessModule } from 'ngx-toastr';
import { AgoraService } from './agora.service';

import { AppService } from './app.service';
import { JanusService } from './janus.service';
import { PexipService } from './pexip.service';
import { RtcService } from './rtc.service';
import { UserService } from '.';
import { AuthService } from './auth.service';
import { JmMediaService } from './jm-media.service';

@Injectable({
    providedIn: 'root'
})
export class VideoWrapperService {
    constructor(
        private rtcService: RtcService,
        private appService: AppService,
        private janusService: JanusService,
        private agoraService: AgoraService,
        private userService: UserService,
        private pexipService: PexipService,
        private jmMediaService: JmMediaService,
        private authService: AuthService
    ) {}

    getVideoService(): JanusService | AgoraService | PexipService | JmMediaService {
        if (this.rtcService.getIsJanusMeeting()) {
            return this.janusService;
        } else if (this.rtcService.getIsPexipMeeting()) {
            return this.pexipService;
        } else if (this.rtcService.getIsJMMeeting()) {
            return this.jmMediaService;
        }
        return this.agoraService;
    }

    getVideoServiceForPreview(): JanusService | AgoraService | PexipService | JmMediaService {
        if (!!this.rtcService.getConferenceInfo()) {
            return this.getVideoService();
        }
        if (
            !this.authService.getIsAuthenticated() ||
            this.userService.getUserSync().mediaEnginePreference === 'agora'
        ) {
            return this.agoraService;
        } else if (this.rtcService.getIsPexipMeeting()) {
            return this.pexipService;
        } else if (this.rtcService.getIsJMMeeting()) {
            return this.jmMediaService;
        }
        return this.agoraService;
    }

    getAdditionalConferenceInfo(room, fromJoinCall: boolean) {
        switch (fromJoinCall ? room.roomDetails.mediaEngine : room.mediaEngine) {
            case 'acs':
                this.rtcService.isACSMeeting = true;
                this.rtcService.isAgoraMeeting = false;
                this.rtcService.isJanusMeeting = false;
                this.rtcService.isJMMeeting = false;
                return {
                    acsToken: room.acsToken,
                    acsUserId: room.acsUserId
                };

            case 'agora':
                this.rtcService.isACSMeeting = false;
                this.rtcService.isAgoraMeeting = true;
                this.rtcService.isJanusMeeting = false;
                this.rtcService.isJMMeeting = false;
                this.userService.localParticipantId = String(room?.agoraUid);

                return {
                    agoraToken: room.agoraToken,
                    agoraUid: room.agoraUid,
                    agoraChannel: fromJoinCall ? room.roomDetails.roomID : room.roomID,
                    agoraAppId: this.appService.getConfigVariable('agoraAppID'),
                    agoraVideoEncoderConfig: this.appService.getConfigVariable('agoraVideoEncoderConfig'),
                    agoraScreenShareEncoderConfig: this.appService.getConfigVariable('agoraScreenShareEncoderConfig'),
                    agoraMaxParticipants: room.agoraMaxParticipants,
                    agoraMaxParticipantsForRIL: room.agoraMaxParticipants,
                    maxAudienceCount: room?.maxAudienceCount,
                    agoraBandwidthConfig: this.appService.getConfigVariable('agoraBandwidthConfig'),
                    agoraScreenShareInfo: room.agoraScreenShareInfo,
                    agoraRtmToken: room.agoraRtmToken,
                    agoraSpeakerCount: room?.speakerCount
                };

            case 'janus':
                this.rtcService.isACSMeeting = false;
                this.rtcService.isAgoraMeeting = false;
                this.rtcService.isJanusMeeting = true;
                this.rtcService.isJMMeeting = false;

                return {};

            case 'pexip':
                this.rtcService.isACSMeeting = false;
                this.rtcService.isAgoraMeeting = false;
                this.rtcService.isJanusMeeting = false;
                this.rtcService.isPexipMeeting = true;
                this.rtcService.isJMMeeting = false;

                return {};
            case 'jm-media':
                this.rtcService.isACSMeeting = false;
                this.rtcService.isAgoraMeeting = false;
                this.rtcService.isJanusMeeting = false;
                this.rtcService.isPexipMeeting = false;
                this.rtcService.isJMMeeting = true;
                return {
                    jmMediaMaxParticipants: room.agoraMaxParticipants,
                    jmMediaMaxParticipantsForRIL: room.agoraMaxParticipants,
                    jmSpeakerCount: room?.speakerCount,
                    maxAudienceCount: room?.maxAudienceCount
                };

            default:
                return {};
        }
    }
}
