import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService, AuthService, AppService } from '../services';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CheckWebrtcEnabledGuard implements CanActivate {
    constructor(
        private userService: UserService,
        private authService: AuthService,
        private appService: AppService,
        private router: Router
    ) {}
    canActivate(route: ActivatedRouteSnapshot, snapshot: RouterStateSnapshot): Observable<boolean> {
        if (this.appService.getConfigVariable('ENABLE_WEBRTC')) {
            return of(true);
        }
        const currentUser = this.userService.getUserSync();
        if (this.authService.getIsAuthenticated()) {
            return this.checkForWebRTCFlag();
        }
        this.router.navigateByUrl('/shortener' + location.search);
        return of(false);
    }

    checkForWebRTCFlag() {
        return this.userService.getUser().pipe(
            map((res) => {
                return res.nativeWebrtcEnabled;
            })
        );
    }
}
