import {
    Component,
    OnInit,
    AfterViewInit,
    Input,
    Output,
    ViewChild,
    ElementRef,
    OnDestroy,
    EventEmitter,
    NgZone,
    ComponentFactoryResolver,
    ComponentRef,
    ViewEncapsulation
} from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';

import { combineLatest, Subscription, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { ConfirmationService } from 'primeng/api';

import { ToastrService } from 'ngx-toastr';

import * as FileSaver from 'file-saver';
import { ResizedEvent } from 'angular-resize-event';
import { find } from 'lodash-es';
import {
    UserService,
    EventEmitterService,
    SocketService,
    SocketEvent,
    AuthService,
    ChatService,
    AppService,
    GuestAuthService,
    UtilService,
    JiocloudService,
    JioCloudUtilService,
    JiocloudAuthService,
    RoomConnectionService,
    ComponentFactoryService,
    RtcService,
    ThirdPartyExternalIntegrationService,
    GoogleTagManagerService
} from 'src/app/core';

import { HostDirective } from 'src/app/shared';

import { APP_CONSTANT, APP_EVENTS, JIO_CLOUD, SOCKET_EVENTS } from 'src/app/constants';

import { PrivateChatComponent } from '../private-chat/private-chat.component';
import { AttachmentListComponent } from '../attachment-list/attachment-list.component';
import { ChatToastComponent } from '../chat-toast/chat-toast.component';
import { ChatMarkdownComponent } from '../chat-markdown/chat-markdown.component';
import { TranslateService } from '@ngx-translate/core';
import * as sanitizeHtml from 'sanitize-html';
import { GA_EVENTS } from '../../ga-events';

declare var $: any;
declare const TurndownService: any;
declare const window: any;

@Component({
    selector: 'app-inside-call-chat',
    templateUrl: './inside-call-chat.component.html',
    styleUrls: ['./inside-call-chat.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class InsideCallChatComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('messages', { static: true }) messagesEle: ElementRef;
    @ViewChild('textArea') textArea: ElementRef;
    @Input()
    enabled;
    @Input() meetingObj;
    @Input() popout = false;
    @Input() shouldOpenPrivate;
    @Output() close: EventEmitter<any> = new EventEmitter();
    @ViewChild('downloadAnchor') downloadAnchor: ElementRef;
    @ViewChild('attachmentIcon') attachmentIcon: ElementRef;
    @ViewChild('uploadFiles') openFileMenu: ElementRef;
    @ViewChild('box') input: ElementRef;

    @ViewChild(HostDirective, { static: true }) viewHost: HostDirective;
    @ViewChild(ChatMarkdownComponent) private chatMarkdownComponent: ChatMarkdownComponent;

    messages = [];
    currentUser: any = {};
    guestUser: any = {};
    isAuthenticated;
    messageToSend;
    roomStatus: any = {};
    roomParticipants: any[] = [];
    unreadChatParticipantIds: any[] = [];
    // isScheduledMeeting: any = {};

    chatGroupsByDate: any[];
    attachmentList: any[] = [];
    showAttachments;
    uploadedItems: any = {};
    subscriptions: Subscription[] = [];
    public dropdown = false;
    public privateChatToggle = false;
    privateChatRecipient: any;
    loggerTrigger = true;
    loggerObject: { name: string; _id: string };
    showErrorPopUp: boolean = true;
    errorMessage: string = 'test error message';
    uploadPermitted: boolean = true;
    isLandscapeMode: boolean = false;
    selectedEmoji = false;
    working = false;
    groupThreadId;

    tagsToReplace = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;'
    };

    showPrivateChat = this.appService.getConfigVariable('SHOW_PRIVATE_CHAT');
    showEmojiBar = this.appService.getConfigVariable('SHOW_EMOJI_BAR');
    enableMarkdown = this.appService.getConfigVariable('SHOW_MARKDOWN');
    enableJioCloud = false;
    enableDownloadPreview = false;
    showMarkdown = false;
    showMoreOptions = false;
    chatTitle;
    turnDownService = new TurndownService();

    private previousScrollHeight;
    private timeouts = [];
    room;
    isThirdPartyExternalIntegration;
    isMobileDevice;
    thirdPartyEventSubscriptions = [];
    isJMMeeting = false;
    isLargeMeeting = false;

    selectedChatOption = 'No one';
    showDisabledChatToast: boolean = false;
    showEnabledChatToast: boolean = false;
    isUserHostOrCohost: boolean = false;
    isDropdownOpen: boolean = false;
    currentLang;

    options = [
        { value: 'NONE', label: 'No one' },
        { value: 'ALL', label: 'Everyone' },
        { value: 'PARTICIPANTS', label: 'Participants Only' }
    ];

    constructor(
        private userService: UserService,
        private guestUserService: GuestAuthService,
        private chatService: ChatService,
        private eventEmitterService: EventEmitterService,
        private jioCloudService: JiocloudService,
        private jioCloudUtilService: JioCloudUtilService,
        private socketService: SocketService,
        private zone: NgZone,
        private authService: AuthService,
        private toastrService: ToastrService,
        private jiocloudAuthService: JiocloudAuthService,
        private confirmationService: ConfirmationService,
        private roomConnectionService: RoomConnectionService,
        private compFactory: ComponentFactoryResolver,
        private appService: AppService,
        private factoryService: ComponentFactoryService,
        private utilService: UtilService,
        private rtcService: RtcService,
        private translateService: TranslateService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        private gTagService: GoogleTagManagerService
    ) {
        this.isThirdPartyExternalIntegration =
            this.thirdPartyExternalIntegrationService.getThirdPartyExternalIntegration();
        this.isMobileDevice = this.utilService.isMobileBrowser() || this.isThirdPartyExternalIntegration;
        this.thirdPartyEventSubscriptions.push(
            this.eventEmitterService.subscribe((event: any) => {
                if (event.type === APP_EVENTS.NATIVE_BACK_BUTTON) {
                    this.closeView();
                }
            })
        );
        this.enableJioCloud = this.userService.isJioCloudEnabled();
        this.enableDownloadPreview = this.appService.isDesktopApp();
        this.addTurndownRules();
    }
    public get userID() {
        return this.currentUser?._id || this.guestUser?.guestUserId;
    }

    getCurrentUserId() {
        return this.currentUser?._id || this.guestUser?.guestUserId;
    }

    ngOnInit() {
        this.room = this.rtcService.getRoomInfo();
        this.guestUser = this.guestUserService.getAuthInfo();
        this.currentUser = this.userService.getUserSync();
        this.isAuthenticated = this.authService.getIsAuthenticated();
        this.isJMMeeting = this.rtcService.getIsJMMeeting();
        this.isLargeMeeting = this.rtcService.isJMLargeMeeting;
        this.isLandscapeMode = window.innerWidth > window.innerHeight;
        // this.isScheduledMeeting = !!this.rtcService.getRoomInfo().meetingId;
        if (this.shouldOpenPrivate) {
            this.switchToPrivateChat(this.shouldOpenPrivate);
        }
        this.eventEmitterService.emit({
            type: APP_EVENTS.SHOW_UNSEEN_COUNT,
            data: false
        });
        this.getMessages(true);
        this.subscriptions.push(
            this.chatService.getNotifiedParticipants().subscribe((value) => {
                this.unreadChatParticipantIds = value;
            }),
            this.socketService.dataEvents$.subscribe(this.handleMeetingEvents.bind(this)),
            this.roomConnectionService.getRoomConnectionStatus$().subscribe((res) => {
                this.zone.run(() => {
                    if (res) {
                        this.handleRoomConnectionStatusResponse(res);
                    }
                });
            }),
            this.translateService.onLangChange.subscribe(() => {
                this.currentLang = this.appService.getAndSaveUserPreferredLang();
                this.changeChatRestrictContentBasedOnLanguage();
            })
        );
        this.currentLang = this.appService.getAndSaveUserPreferredLang();
        this.changeChatRestrictContentBasedOnLanguage();
        if (!this.popout) {
            this.eventEmitterService.emit({ type: 'POPOUT_CHAT', data: false });
        }

        window.addEventListener(
            'resize',
            () => {
                this.isLandscapeMode = window.innerWidth > window.innerHeight;
            },
            false
        );
    }

    changeChatRestrictContentBasedOnLanguage() {
        if (this.currentLang === 'en') {
            this.options = [
                { value: 'NONE', label: 'No one' },
                { value: 'ALL', label: 'Everyone' },
                { value: 'PARTICIPANTS', label: 'Participants Only' }
            ];
        } else if (this.currentLang === 'hi') {
            this.options = [
                { value: 'NONE', label: 'किसी को भी नहीं' },
                { value: 'ALL', label: 'हर कोई' },
                { value: 'PARTICIPANTS', label: 'केवल प्रतिभागी' }
            ];
        }
    }

    handleRoomConnectionStatusResponse(res) {
        this.roomStatus = res;
        const chatOption = this.options.find((option) => option.value === this.roomStatus?.restrictChat);
        this.selectedChatOption = chatOption?.label;
        this.showDisabledChatToast = !this.roomStatus?.localParticipant?.chatStatus;
        this.isUserHostOrCohost =
            this.roomStatus?.localParticipant?.isHost || this.roomStatus?.localParticipant?.isCoHost;
        this.setRoomParticipats(this.roomStatus.participants);
    }

    ngAfterViewInit() {
        this.previousScrollHeight = this.messagesEle.nativeElement.scrollTop;
    }

    toggleDropdown() {
        this.dropdown = !this.dropdown;
        setTimeout(() => {
            if (this.dropdown) {
                this.setDropdownHeight();
            }
            return;
        }, 50);
    }

    setDropdownHeight() {
        let heightDiff =
            (document.getElementsByClassName('group-chat-conponent')[0] as HTMLElement).offsetHeight -
            (document.getElementsByClassName('attachments')[0] as HTMLElement).offsetHeight;

        let elem = document
            .getElementsByClassName('participants-dropdown')[0]
            .getElementsByClassName('dropdown')[0] as HTMLElement;

        if (elem) {
            elem.style.maxHeight = `${heightDiff}px`;
        }
    }

    addTurndownRules() {
        this.turnDownService.addRule('strikethrough', {
            filter: ['del', 's', 'strike'],
            replacement: (content) => {
                return '~~' + content + '~~';
            }
        });
        this.turnDownService.addRule('bold', {
            filter: ['b', 'strong'],
            replacement: (content) => {
                return '**' + content + '**';
            }
        });
        this.turnDownService.addRule('italic', {
            filter: ['i', 'em'],
            replacement: (content) => {
                return '*' + content + '*';
            }
        });
        this.turnDownService.addRule('underline', {
            filter: ['u', 'ins'],
            replacement: (content) => {
                return '__' + content + '__';
            }
        });
    }

    setRoomParticipats(participants) {
        let obj = {};
        for (let i = 0; i < participants.length; i++) {
            obj[participants[i].userId] = participants[i];
        }
        const usersArray = [];
        for (var key in obj) {
            usersArray.push(obj[key]);
        }
        this.roomParticipants = this.utilService.clone(usersArray);
        this.roomParticipants.sort((p1, p2) => {
            const p1UnreadSender = this.unreadChatParticipantIds.includes(p1.userId);
            const p2UnreadSender = this.unreadChatParticipantIds.includes(p2.userId);
            return p1UnreadSender === p2UnreadSender ? 0 : p1UnreadSender ? -1 : 1;
        });
        // const firstNonSenderIndex = this.roomParticipants.findIndex(
        //   (p) => !this.unreadChatParticipantIds.includes(p.userId)
        // );
        // this.roomParticipants.splice(firstNonSenderIndex, 0, { participantName: 'All', userId: null });
        this.roomParticipants.unshift({ participantName: 'All', userId: null });
    }

    getMessages(getThread = false) {
        // this.chatService.fetchAllMessages(this.meetingObj.jiomeetId).subscribe((data: any) => {
        //   const chats = (data.messages || []).filter((msg) => msg.type === 'message');
        //   this.chatGroupsByDate = this.chatService.groupChatMessagesByDate(chats);
        //   this.scrollToBottom();
        // });
        // this.chatService
        //   .getChatThreadId(this.meetingObj.jiomeetId)
        //   .pipe(
        //     tap((thread) => (this.groupThreadId = thread._id)),
        //     switchMap((thread) => {
        //       return combineLatest([
        //         this.getAttchmentsInGroupChat(),
        //         this.chatService.getSortedMembers(thread._id)
        //       ]);
        //     })
        //   )
        //   .subscribe(([attachments, sortedMembers]) => {});

        this.chatService.fetchAllMessages(this.meetingObj.jiomeetId).subscribe((data: any) => {
            const chats = (data.messages || []).filter((msg) => msg.type === 'message');
            this.chatGroupsByDate = this.chatService.groupChatMessagesByDate(chats);
            this.scrollToBottom(!getThread);
            this.getAttchmentsInGroupChat().subscribe();
            if (getThread) {
                this.chatService.getChatThreadId(this.meetingObj.jiomeetId).subscribe((thread) => {
                    this.groupThreadId = thread._id;
                });
            }
        });
    }

    getAttchmentsInGroupChat() {
        if (!this.groupThreadId) {
            return of([]);
        }
        return this.chatService.getChatAttachments(this.groupThreadId).pipe(
            tap((attachments) => {
                this.attachmentList = (attachments.messages || []).filter((msg) => msg.type === 'message');
            })
        );
    }

    handleMeetingEvents(event: SocketEvent) {
        this.zone.run(() => {
            switch (event.event) {
                case 'chatMessage':
                    console.log('chatMessage 1', event.event);
                    if (
                        event?.data?.type === 'message' &&
                        (event?.data?.jiomeetId === this.meetingObj?.jiomeetId ||
                            event?.data?.threadId === this.groupThreadId)
                    ) {
                        console.log('chatMessage 2');
                        if (event.data.threadId === this.groupThreadId) {
                            this.chatGroupsByDate = this.chatService.appendRecievedMessage(
                                this.chatGroupsByDate,
                                event.data
                            );
                            console.log('chatMessage 3');
                            try {
                                this.scrollToBottom(this.userID === event.data.createdBy);
                                this.getAttchmentsInGroupChat().subscribe();
                            } catch (error) {
                                console.log('chatMessage error', error);
                            }

                            console.log('chatMessage 4');
                        } else {
                            if (this.loggerTrigger && this.userID !== event.data.createdBy) {
                                setTimeout(() => {
                                    this.generateToast(
                                        {
                                            _id: event.data.createdBy,
                                            name: `${event.data.creatorsName} ${event.data?.creatorsLName || ''}`
                                        },
                                        'PRIVATE_CHAT'
                                    );
                                }, 300);
                            }
                            this.chatService.updateNotifiedParticipants(event.data.createdBy);
                        }
                    }
                    break;
                case 'chatMessageUpdate':
                    if (this.groupThreadId === event.data.threadId) {
                        switch (event.data.action) {
                            case 'deleted':
                                event.data.messageIdsToBeUpdated.forEach((deletedId) => {
                                    this.chatGroupsByDate.forEach((value) => {
                                        value?.value?.forEach((msg) => {
                                            if (msg._id === deletedId) {
                                                msg.msgDeleted = true;
                                            }
                                            return msg;
                                        });
                                    });
                                });
                                this.getAttchmentsInGroupChat().subscribe();
                                break;
                        }
                    }
                    break;
                case 'chatThreadAddOrEdit':
                    if (event?.data?.jiomeetId === this.meetingObj.jiomeetId) {
                        this.groupThreadId = event?.data?._id;
                        this.chatTitle = event?.data?.name;
                        this.getMessages();
                    }
                    break;
                case SOCKET_EVENTS.ROOM_CONNECTION_STATUS_UPDATE:
                    this.handleRoomConnectionStatusUpdate(event);
                    break;
            }
        });
    }

    handleRoomConnectionStatusUpdate(event) {
        if (event?.data?.actionType && event?.data?.jiomeetId === this.meetingObj.jiomeetId) {
            switch (event?.data?.actionType) {
                case 'restrictChatChange':
                    this.handleRestrictChatChangeToasts(event);
                    break;
                default:
                    break;
            }
        }
    }

    handleRestrictChatChangeToasts(event) {
        if (!(event.data?.sourceId === this.getCurrentUserId()) && this.isMobileDevice) {
            if (event?.data?.restrictChat === 'NONE') {
                const toastEnable = document.getElementById('mobile-chat-enable-toast');
                this.toastrService.clear();
                if (toastEnable) {
                    this.showEnabledChatToast = true;
                    toastEnable.innerHTML =
                        this.currentLang === 'hi'
                            ? `होस्ट/सह-होस्ट ने चैट सक्षम कर दी है`
                            : `Host/Co-host has enabled chat`;
                    toastEnable.style.display = 'block';
                    setTimeout(() => {
                        this.showEnabledChatToast = false;
                        toastEnable.style.display = 'none';
                    }, 3000);
                }
            }
        }
    }

    sendMessage(event: KeyboardEvent, message1) {
        if (this.working) {
            return;
        }
        let message = '';
        if (this.chatMarkdownComponent?.quill?.container?.innerText?.trim()) {
            message = this.turnDownService.turndown(this.chatMarkdownComponent?.quill?.container.innerHTML);
            message = sanitizeHtml(message, {
                allowedTags: ['b', 'i', 'em', 'strong', 'a', 's', 'ul', 'ol', 'li']
            });
            message = message.replace('[](about:blank)', '').trim();
            message = message.replace(/\\_/g, '_');
            message = message.replace('\\>', '>');
            message = message.replace(/\n\*$/g, '');
        }
        if (message?.length >= APP_CONSTANT.CHAT_MESSAGE_LIMIT) {
            this.toastrService.error(this.translateService.instant('tostrs.character_limit_exceeded'));
            return;
        }
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }

        if (this.isUploadInProgress()) {
            this.toastrService.error(this.translateService.instant('tostrs.please_wait_uploading_is_in_progress'));
            return;
        }

        // if (this.roomStatus?.participants?.length <= 1) {
        //   this.toastrService.clear();
        //   this.toastrService.error(
        //     this.translateService.instant(
        //       'tostrs.you_are_the_only_one_in_meeting_add_a_participant_to_start_chat'
        //     ),
        //     '',
        //     {
        //       positionClass: 'toast-center-center',
        //       toastClass: 'inside-call-chat-toast'
        //     }
        //   );
        //   return;
        // }

        if (message?.trim().length || Object.keys(this.uploadedItems).length) {
            const attachments = Object.keys(this.uploadedItems).map((attachmentObj: any) => {
                return {
                    imageTrancodedURL: this.uploadedItems[attachmentObj].meetingImageTranscodeUrl || '',
                    streamingURL: this.uploadedItems[attachmentObj].meetingPlaybackUrl || '',
                    documentURL: this.uploadedItems[attachmentObj].meetingDocumentUrl || '',
                    downloadURL: this.uploadedItems[attachmentObj].meetingFileUrl || '',
                    fileName: this.uploadedItems[attachmentObj].objectName,
                    fileID: this.uploadedItems[attachmentObj].objectKey,
                    fileSize: '' + this.uploadedItems[attachmentObj].size
                };
            });
            this.working = true;
            this.chatService
                .sendInCallChatMessage({
                    jiomeetId: this.meetingObj.jiomeetId,
                    messageComponent: {
                        text: message?.trim(),
                        attachments
                    }
                })
                .subscribe(
                    (res: any) => {
                        this.enableAttachmentIcon();
                        if (res.success === false) {
                            this.toastrService.error(res.error.errors);
                            return;
                        }
                        if (res && res.messageComponent && res.messageComponent.attachments.length) {
                            this.attachmentList.unshift(res);
                        }
                        this.showMarkdown = false;
                        this.messageToSend = '';
                        this.chatMarkdownComponent.clearMeessage();
                        this.uploadedItems = {};
                        this.working = false;
                    },
                    (err) => {
                        this.working = false;
                    }
                );
            if (this.input) {
                this.input.nativeElement.value = null;
            }
        } else {
            this.toastrService.error(this.translateService.instant('tostrs.please_type_a_message_to_continue'));
            this.chatMarkdownComponent.clearMeessage();
        }
    }

    onResized(event: ResizedEvent) {
        this.bottomStyleReducer();
    }

    toggleAttachmentSection() {
        this.showAttachments = !this.showAttachments;
    }

    isUploadInProgress() {
        return Object.keys(this.uploadedItems).filter(
            (attachmentObj: any) => !this.uploadedItems[attachmentObj].uploadSuccess
        ).length;
    }

    uploadFiles(files) {
        const file = files[0];
        if (file.size <= JIO_CLOUD.MAX_FILE_SIZE_ALLOWED_FOR_UPLOAD) {
            file.unique = new Date().valueOf();
            file.totalSize = this.jioCloudUtilService.calculateSize(file.size);
            this.uploadedItems[file.unique] = file;
            if (file.size > JIO_CLOUD.SMALL_FILE_SIZE_LIMIT) {
                this.chunkUpload(file);
            } else {
                this.singleFileUpload(file);
            }
        } else {
            this.enableAttachmentIcon();
            this.showMaxFileSizeAlert();
        }
    }

    private async singleFileUpload(file) {
        try {
            file.hash = await this.jioCloudUtilService.getFileHash(file);
            this.jioCloudService
                .singleFileUpload({
                    file,
                    fileMetadata: this.getMetaData(file),
                    meetingId: this.groupThreadId
                })
                .subscribe(
                    (event: HttpEvent<any>) => {
                        switch (event.type) {
                            case HttpEventType.UploadProgress:
                                this.uploadedItems[file.unique].progress = Math.round(
                                    (event.loaded / event.total) * 100
                                );
                                this.uploadedItems[file.unique].uploadedBytes = this.jioCloudUtilService.calculateSize(
                                    event.loaded
                                );
                                break;
                            case HttpEventType.Response:
                                const metaData = this.jioCloudUtilService.setMeataDataForUploadedFile(event.body);
                                Object.assign(this.uploadedItems[file.unique], {
                                    meetingImageTranscodeUrl: metaData.meetingImageTranscodeUrl,
                                    meetingPlaybackUrl: metaData.meetingPlaybackUrl,
                                    meetingDocumentUrl: metaData.meetingDocumentUrl,
                                    meetingFileUrl: metaData.meetingFileUrl,
                                    objectName: metaData.objectName,
                                    objectKey: metaData.objectKey
                                });
                                this.uploadedItems[file.unique].uploadSuccess = true;
                                break;
                        }
                    },
                    (err) => {
                        this.uploadFailedNotification(err?.error);
                    }
                );
        } catch (err) {
            this.uploadFailedAlert();
        }
    }

    uploadFailedNotification(error) {
        switch (error?.code) {
            case 'TEJUM409':
                this.toastrService.warning(
                    'You have exhausted the 5GB storage limit. Please delete the existing files to upload new one.'
                );
                break;
            default:
                this.toastrService.error(error?.error || 'Upload failed');
                break;
        }
    }

    private async chunkUpload(file) {
        try {
            file.hash = await this.jioCloudUtilService.getFileHash(file);
            this.jioCloudService
                .initiateUpload({
                    fileMetadata: this.getMetaData(file),
                    meetingId: this.groupThreadId
                })
                .subscribe(
                    (event: any) => {
                        switch (event.type) {
                            case HttpEventType.Response:
                                if (event.status === 200) {
                                    let response = event.body;
                                    this.uploadedItems[file.unique].uploadedBytes =
                                        this.jioCloudUtilService.calculateSize(response.offset);
                                    this.startChunkUpload(
                                        file,
                                        response.offset,
                                        JIO_CLOUD.FIRST_CHUNK_SIZE,
                                        response.transactionId,
                                        null,
                                        true
                                    );
                                }
                                if (event.status === 201) {
                                    // upload done, process next file
                                    // this.uploadedItems[
                                    //   file.unique
                                    // ].info = this.jioCloudUtilService.setMeataDataForUploadedFile(event.body);
                                    const metaData = this.jioCloudUtilService.setMeataDataForUploadedFile(event.body);
                                    Object.assign(this.uploadedItems[file.unique], {
                                        meetingImageTranscodeUrl: metaData.meetingImageTranscodeUrl,
                                        meetingPlaybackUrl: metaData.meetingPlaybackUrl,
                                        meetingDocumentUrl: metaData.meetingDocumentUrl,
                                        meetingFileUrl: metaData.meetingFileUrl,
                                        objectName: metaData.objectName,
                                        objectKey: metaData.objectKey
                                    });
                                }
                                this.uploadedItems[file.unique].uploadSuccess = true;
                                break;
                        }
                    },
                    (err) => {
                        this.uploadFailedNotification(err?.error);
                    }
                );
        } catch (err) {
            this.uploadFailedAlert();
        }
    }

    private startChunkUpload(file, offset, chunkSize, transactionId, chunkUploadTime, isStart = false) {
        if (!isStart) {
            chunkSize = this.jioCloudUtilService.getChunkSize(chunkUploadTime, chunkSize, file.size, offset);
        }

        try {
            const chunkUploadStartTime = new Date().getTime();
            const chunk1 = File.prototype.slice.call(file, offset, offset + chunkSize);
            const reader = new FileReader();
            reader.onload = async (data: any) => {
                const chunk = data.target.result;
                const chunkChecksum = await this.jioCloudUtilService.getChunkCheckSum(chunk);
                this.jioCloudService
                    .uploadChunk(chunk, offset, chunkChecksum, transactionId, this.groupThreadId)
                    .subscribe(
                        (event: any) => {
                            switch (event.type) {
                                case HttpEventType.Response:
                                    if (event.status === 200) {
                                        const response = event.body;
                                        this.uploadedItems[file.unique].progress = Math.round(
                                            (response.offset / file.size) * 100
                                        );
                                        this.uploadedItems[file.unique].uploadedBytes =
                                            this.jioCloudUtilService.calculateSize(response.offset);
                                        this.startChunkUpload(
                                            file,
                                            response.offset,
                                            chunkSize,
                                            response.transactionId,
                                            new Date().getTime() - chunkUploadStartTime
                                        );
                                    }
                                    if (event.status === 201) {
                                        this.uploadedItems[file.unique].progress = 100;
                                        const metaData = this.jioCloudUtilService.setMeataDataForUploadedFile(
                                            event.body
                                        );
                                        Object.assign(this.uploadedItems[file.unique], {
                                            meetingImageTranscodeUrl: metaData.meetingImageTranscodeUrl,
                                            meetingPlaybackUrl: metaData.meetingPlaybackUrl,
                                            meetingDocumentUrl: metaData.meetingDocumentUrl,
                                            meetingFileUrl: metaData.meetingFileUrl,
                                            objectName: metaData.objectName,
                                            objectKey: metaData.objectKey
                                        });
                                    }
                                    this.uploadedItems[file.unique].uploadSuccess = true;
                                    break;
                            }
                        },
                        (err) => {
                            this.uploadFailedNotification(err?.error);
                        }
                    );
            };
            reader.onerror = (event) => {
                reader.abort();
            };
            reader.readAsArrayBuffer(chunk1);
        } catch (err) {
            throw err;
        }
    }

    getMetaData(file) {
        return {
            name: file.name,
            size: file.size,
            hash: file.hash,
            folderKey: this.jiocloudAuthService.getAuthInfo().rootFolderKey
        };
    }

    fileChangeEvent(event) {
        if (!this.isFileSupported(event.target.files[0])) {
            this.confirmationService.confirm({
                message: this.translateService.instant('tostrs.file_type_not_supported'),
                header: this.translateService.instant('tostrs.alert'),
                acceptLabel: this.translateService.instant('tostrs.ok'),
                rejectVisible: false,
                accept: () => {},
                reject: () => {}
            });
            this.utilService.setTimeOutForAccessibilityPopUp();
            return;
        }
        this.disableAttachmentIcon();
        this.textArea?.nativeElement?.focus();
        if (event.target.files.length) {
            this.uploadFiles(event.target.files);
        }
    }

    handleChatMartkdownOutput(event) {
        this.messageToSend = event;
    }

    isFileSupported(file) {
        return this.jioCloudUtilService.getFileFormat(file.name) !== 'None';
    }

    showMaxFileSizeAlert() {
        this.confirmationService.confirm({
            message: this.translateService.instant('tostrs.maximum_file_size_allowed_is_30mb', {
                value: 30
            }),
            header: this.translateService.instant('tostrs.alert'),
            acceptLabel: this.translateService.instant('tostrs.ok'),
            rejectVisible: false,
            accept: () => {},
            reject: () => {}
        });
        this.utilService.setTimeOutForAccessibilityPopUp();
    }

    uploadFailedAlert() {
        this.confirmationService.confirm({
            message: this.translateService.instant('tostrs.file_uploading_failed'),
            header: this.translateService.instant('tostrs.alert'),
            acceptLabel: this.translateService.instant('tostrs.ok'),
            rejectVisible: false,
            accept: () => {},
            reject: () => {}
        });
        this.utilService.setTimeOutForAccessibilityPopUp();
    }

    getAttachedFilesCount() {
        return [].concat.apply(
            [],
            this.attachmentList.map((msg) => msg.messageComponent.attachments)
        ).length;
    }

    removeAttchment(event, file) {
        delete this.uploadedItems[file.unique];
        this.enableAttachmentIcon();
    }

    trackByFuntion(index, item) {
        return index;
    }

    trackMessages(index, item) {
        return item._id;
    }

    getProfilePic(userId) {
        return this.utilService.getProfilePic(userId);
    }

    closeView() {
        if (this.isThirdPartyExternalIntegration && this.meetingObj) {
            this.thirdPartyExternalIntegrationService.enableBackNavigationOnHomePage();
            this.meetingObj.hideAllCallControls = false;
        }
        this.close.emit();
        this.gTagService.sendEvent(GA_EVENTS.CLOSE_CHAT);
    }

    popoutChat() {
        this.closeView();
        this.eventEmitterService.emit({ type: 'POPOUT_CHAT', data: true });
        this.gTagService.sendEvent(GA_EVENTS.POP_OUT_CHAT);
    }

    deleteItem(message) {
        this.confirmationService.confirm({
            message: this.translateService.instant('tostrs.are_you_sure_you_want_to_delete_this_file'),
            header: this.translateService.instant('tostrs.delete_file'),
            acceptLabel: this.translateService.instant('tostrs.delete'),
            rejectLabel: this.translateService.instant('tostrs.cancel'),
            acceptButtonStyleClass: 'custom-button bg-primary',
            rejectButtonStyleClass: 'custom-button bg-secondary',
            accept: () => {
                this.chatService
                    .deleteFromJioMeetAndJioCloud(
                        message.threadId,
                        message._id,
                        message.messageComponent.attachments[0].fileID,
                        this.groupThreadId
                    )
                    .subscribe((resp) => {});
            },
            reject: () => {}
        });
        this.utilService.setTimeOutForAccessibilityPopUp();
    }

    fileInfo(message) {
        this.eventEmitterService.emit({
            type: APP_EVENTS.OPEN_FILE_INFO,
            data: {
                name: message.messageComponent.attachments[0].fileName,
                size: message.messageComponent.attachments[0].fileSize,
                sharedBy: `${message.creatorsName} ${message.creatorsLName}`,
                createdOn: message.cOn
            }
        });
    }

    downloadFile(message) {
        if (this.chatService.activeChatMembers?.length > 0) {
            let creator = find(this.chatService.activeChatMembers, ['_id', message?.createdBy]);
            if (this.utilService.canDownloadOrPreviewAttachments(creator, this.currentUser)) {
                this.toastrService.error(this.translateService.instant('tostrs.downloading_a_file_is_not_allowed'));
                return;
            }
        }

        this.toastrService.success(this.translateService.instant('tostrs.downloading_in_progress'));
        const files = message.messageComponent.attachments;
        (files || []).map((file) => {
            this.jioCloudService.downloadFile(file.fileName, file.downloadURL, this.groupThreadId).subscribe((resp) => {
                FileSaver.saveAs(resp, file.fileName);
                this.toastrService.success(this.translateService.instant('tostrs.download_completed'));
            });
        });
    }

    disableAttachmentIcon() {
        if (this.attachmentIcon) {
            this.attachmentIcon?.nativeElement.setAttribute('class', 'disabled');
        }
        if (this.openFileMenu) {
            this.openFileMenu?.nativeElement.setAttribute('disabled', true);
        }
    }

    enableAttachmentIcon() {
        if (this.attachmentIcon) {
            this.attachmentIcon?.nativeElement.setAttribute('class', '');
        }
        if (this.openFileMenu) {
            this.openFileMenu?.nativeElement.removeAttribute('disabled');
        }
    }

    resetLastSelectedFileValue(event) {
        event.target.value = '';
    }

    showInCallChatPopUp(message, type) {
        this.eventEmitterService.emit({ type: type, data: message });
    }
    onOutsideClick(event) {
        if (this.dropdown) {
            this.dropdown = false;
        }
    }
    generateToast(toastObject, type) {
        const component: ComponentRef<ChatToastComponent> = this.factoryService.generateComponent(
            this.viewHost,
            ChatToastComponent
        );
        component.instance.toastObject = toastObject;
        component.instance.messageType = type;
        component.instance.unreadParticipantCount = this.unreadChatParticipantIds.filter(
            (uId) => uId !== this.userID
        ).length;
        component.instance.closeEmitter.subscribe(() => component.destroy());
        component.instance.clickEmitter.subscribe((res) => {
            if (res.type === 'PRIVATE_CHAT') {
                this.switchToPrivateChat(toastObject);
            } else if (res.type === 'NEW_MESSAGE') {
                this.scrollToBottom(true);
            }
            component.destroy();
        });
    }
    switchToPrivateChat(participant) {
        this.loggerTrigger = false;
        this.chatService.clearNotifiedParticipants(participant._id);
        const component: ComponentRef<PrivateChatComponent> = this.factoryService.generateComponent(
            this.viewHost,
            PrivateChatComponent
        );
        this.factoryService.updateInstanceCount(component);
        component.instance.jiomeetId = this.meetingObj.jiomeetId;
        component.instance.recipient = participant;
        component.instance.senderId = this.currentUser?._id || this.guestUser?.guestUserId;
        component.instance.isAuthenticated = this.isAuthenticated;
        component.instance.popout = this.popout;
        const { nativeElement } = component.location;
        component.instance.afterClose.subscribe((res) => {
            this.loggerTrigger = true;
            this.factoryService.destroyAllPrivateChatInstances();
            if (res?._id) {
                this.timeouts.push(
                    setTimeout(() => {
                        this.switchToPrivateChat(res);
                    }, 0)
                );
            }
        });
    }

    toggleChatDropdown(isDropdownOpen) {
        this.isDropdownOpen = !isDropdownOpen;
    }

    outsideChatDropdown() {
        this.isDropdownOpen = false;
    }

    selectOption(event) {
        this.roomConnectionService
            .setChatStatusForAllUsers({ jiomeetId: this.meetingObj?.jiomeetId, restrictChat: event })
            .subscribe(
                (res) => {
                    switch (event) {
                        case 'ALL':
                            this.toastrService.success(
                                this.translateService.instant('tostrs.chat_disabled_for_everyone')
                            );
                            break;

                        case 'PARTICIPANTS':
                            this.toastrService.success(
                                this.translateService.instant('tostrs.chat_disabled_for_participants')
                            );
                            break;

                        case 'NONE':
                            this.toastrService.success(
                                this.translateService.instant('tostrs.chat_enabled_for_everyone')
                            );
                            break;

                        default:
                            break;
                    }
                },
                (err) => {
                    this.toastrService.error(this.translateService.instant('tostrs.failed_to_change_chat_status'));
                }
            );
    }

    switchToAttachmentSection() {
        const component = this.compFactory.resolveComponentFactory(AttachmentListComponent);
        const componentRef = this.viewHost.viewContainerRef.createComponent(component);
        componentRef.instance.threadId = this.groupThreadId;
        componentRef.instance.afterClose.subscribe((res) => {
            componentRef.destroy();
        });
    }

    toggleEmojis(event) {
        if (this.isMobileDevice && this.isJMMeeting && this.showMarkdown) {
            this.showMarkdown = !this.showMarkdown;
        }
        this.selectedEmoji = !this.selectedEmoji;
        console.log(this.selectedEmoji);
    }

    toggleMarkdown() {
        if (this.isMobileDevice && this.isJMMeeting && this.selectedEmoji) {
            this.selectedEmoji = !this.selectedEmoji;
        }
        this.showMarkdown = !this.showMarkdown;
    }

    bottomStyleReducer() {
        $('.messages').css('bottom', `${$('.attachments').height()}px`);
    }

    toggleMoreOptions(message) {
        // this.showMoreOptions = !this.showMoreOptions;
        message.showMoreOptions = !message.showMoreOptions;
    }

    clickOutside(message) {
        message.showMoreOptions = false;
    }

    private scrollToBottom(force = false) {
        this.timeouts.push(
            setTimeout(() => {
                const ele = this.messagesEle.nativeElement;
                if (force) {
                    ele.scrollTop = ele.scrollHeight;
                } else {
                    if (this.previousScrollHeight - ele.scrollTop - ele.clientHeight < 10) {
                        ele.scrollTop = ele.scrollHeight;
                        this.previousScrollHeight = ele.scrollHeight;
                    } else {
                        // show new message toast
                        this.generateToast({}, 'NEW_MESSAGE');
                    }
                }
            }, 300)
        );
    }

    getParticipantName(participant) {
        let name = participant.participantName;
        return participant.signIn === 'Guest'
            ? name.substring(0, name.length - 8) +
                  ' (' +
                  this.translateService.instant('inside_call.participants_controls.guest') +
                  ')'
            : participant.participantName;
    }

    isDesktopApp() {
        return this.appService.isDesktopApp();
    }

    ngOnDestroy() {
        if (this.isThirdPartyExternalIntegration) {
            this.thirdPartyEventSubscriptions.forEach((subscription) => subscription.unsubscribe());
        }
        this.eventEmitterService.emit({
            type: APP_EVENTS.SHOW_UNSEEN_COUNT,
            data: true
        });
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.timeouts.forEach((id) => clearTimeout(id));
    }
}
