<section class="feature-container" [class.row-reverse]="reverse === true">
    <div class="feature-content">
        <h2
            tabindex="0"
            class="header"
            [innerHTML]="productFeature.tlabel | translate"
            [class.padding-h-28]="isMobile"
        ></h2>
        <img [src]="productFeature.imgMob || productFeature.img" *ngIf="isMobile && productFeature.img" alt="" />
        <p tabindex="0" class="desc" [class.padding-h-28]="isMobile" [innerHTML]="productFeature.tdesc | translate"></p>
        <div class="feature-list-wrap">
            <div
                class="feature-list"
                *ngIf="productFeature?.data?.length"
                [class.padding-h-44]="isMobile"
                [class.scroll-pos]="isMobile"
                [class.count-2]="!isMobile && productFeature?.data?.length === 2"
                appScrollShowHide
            >
                <ng-container *ngFor="let featureInfo of productFeature.data; let i = index">
                    <div class="feature-info pointer">
                        <app-website-solution
                            [solutionInfo]="featureInfo"
                            (clickHandler)="handleFeatureClick($event)"
                            [featureItemIndex]="i"
                            [featureItemActiveIndex]="isMobile ? i : featureItemActiveIndex"
                            [solutionType]="productFeature.type"
                        ></app-website-solution>
                        <div class="feature-img-preview" *ngIf="isMobile">
                            <ng-container
                                [ngTemplateOutlet]="imgTemplate"
                                [ngTemplateOutletContext]="{ featureInfo: featureInfo, index: 0 }"
                            >
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
            <ng-container
                [ngTemplateOutlet]="imgListTemplate"
                [ngTemplateOutletContext]="{ $implicit: productFeature }"
                *ngIf="screenInnerWidth >= 720 && screenInnerWidth < 1080"
            >
            </ng-container>
        </div>
        <ng-content></ng-content>
    </div>
    <ng-container
        [ngTemplateOutlet]="imgListTemplate"
        [ngTemplateOutletContext]="{ $implicit: productFeature }"
        *ngIf="screenInnerWidth >= 1080"
    >
    </ng-container>
    <div class="feature-img-preview" *ngIf="!isMobile && productFeature.img">
        <ng-container
            [ngTemplateOutlet]="imgTemplate"
            [ngTemplateOutletContext]="{ featureInfo: productFeature, index: 0 }"
        >
        </ng-container>
    </div>
</section>

<ng-template #imgTemplate let-featureInfo="featureInfo" let-i="index">
    <img
        [src]="featureInfo.img"
        [alt]="featureInfo.altText"
        [class.hidden]="this.featureItemActiveIndex !== i"
        *ngIf="featureInfo.img"
    />
    <app-lottie-renderer
        path="{{ isMobile ? featureInfo.lottieMobile : featureInfo.lottieDesktop }}"
        *ngIf="featureInfo.lottieDesktop || featureInfo.lottieMobile"
        [class.hidden]="this.featureItemActiveIndex !== i"
    ></app-lottie-renderer>
</ng-template>

<ng-template #imgListTemplate let-productFeature>
    <div class="feature-img-preview" *ngIf="!isMobile && !productFeature.img">
        <ng-container *ngFor="let featureInfo of productFeature.data; let i = index">
            <ng-container
                [ngTemplateOutlet]="imgTemplate"
                [ngTemplateOutletContext]="{ featureInfo: featureInfo, index: i }"
            >
            </ng-container>
        </ng-container>
    </div>
</ng-template>
