import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

import {
    UserService,
    DiagnosticService,
    UtilService,
    EventEmitterService,
    AppService,
    ThirdPartyExternalIntegrationService,
    RestService
} from 'src/app/core';
import { APP_EVENTS } from 'src/app/constants/app-events';
import { TranslateService } from '@ngx-translate/core';
import { ProfilePhotoService } from 'src/app/core/services/profile-photo.service';
import { LocalHelperServiceService } from 'src/app/contacts/local-helper-service.service';

@Component({
    selector: 'app-profilesettings',
    templateUrl: './profilesettings.component.html',
    styleUrls: ['./profilesettings.component.scss']
})
export class ProfilesettingsComponent implements OnInit {
    @Output() close: EventEmitter<any> = new EventEmitter();
    @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;

    user;
    form: FormGroup;
    advancedOptionsCollapsed = false;
    errors: any = {};
    loading;
    isDefaultPic;
    originalImage;
    userId;
    fullName;
    isNameBeingEdited = false;
    isChangePassword = false;
    isChangeProfilePicture = false;
    showProfile = true;
    isMobile = false;
    isThirdPartyExternalIntegration = false;
    participantInitials: any;
    colorChosen: any;
    proStatus = false;
    tenantId = null;
    isOTT = false;
    expiresOnDate: string;
    subscriptionDaysLeft: string;
    currentUser: any;
    isNameUpdated: boolean;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private toastrService: ToastrService,
        public localHelperServiceService: LocalHelperServiceService,
        private userService: UserService,
        private diagnosticService: DiagnosticService,
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private translateService: TranslateService,
        private appService: AppService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        private profilePhotoService: ProfilePhotoService,
        private restService: RestService
    ) {}

    ngOnInit() {
        this.currentUser = this.userService.getUserSync();
        document.documentElement.scrollTop = 0;
        this.userService.getUser().subscribe((user) => {
            this.isDefaultPic = !user?.imageMeta && !user?.imageMeta?.path;
            this.user = user;
            this.setOwnImageProfilePath();
            this.fullName = `${this.user.name} ${this.user.lname}`;
            this.proStatus = this.user.proStatus;
            this.tenantId = this.user.tenantId;
            this.isOTT = this.user.isOTT;
            this.isNameUpdated = this.user.isNameUpdated;
            const expiresOn: string = new Date(this.user.subscriptionInfo.expiresOn).toString();
            this.expiresOnDate = this.formatDate(expiresOn);
            this.subscriptionDaysLeft = (~~(
                (new Date(this.user.subscriptionInfo.expiresOn).getTime() - new Date().getTime()) /
                86400000
            )).toString();
        });
        this.form = this.fb.group({
            firstName: [this.user.name || '', [Validators.required]],
            lastName: [this.user.lname || '', [Validators.required]]
        });
        this.isThirdPartyExternalIntegration =
            this.thirdPartyExternalIntegrationService.getThirdPartyExternalIntegration();
        this.isMobile = this.utilService.isMobileBrowser() || this.isThirdPartyExternalIntegration;
        this.participantInitials = this.profilePhotoService?.getAvatarInitials(
            this.user?.name + ' ' + this.user?.lname
        );
        this.colorChosen = this.profilePhotoService.getColorForInitials(
            (this.user?.name + ' ' + this.user?.lname).trim()
        );
    }

    setOwnImageProfilePath() {
        this.originalImage = this.userService.getSelfProfilePicPath(this.user);
    }

    downloadInvoice() {
        this.restService.get('api/my_profile/invoice/download?productName=jiomeet').subscribe(
            (res: any) => {
                window.location.href = res?.data?.invoiceUrl;
                this.toastrService.success(this.translateService.instant('tostrs.downloading_in_progress'));
            },
            (err) => {
                console.error('Error while downloading Ivoice', err);
                this.toastrService.error(this.translateService.instant('tostrs.something_went_wrong_please_try_again'));
            }
        );
    }

    navigateToPricing() {
        this.router.navigate(['pricing']);
        this.eventEmitterService.emit({
            type: APP_EVENTS.CLOSE_SETTINGS_MODAL,
            data: {}
        });
    }

    formatDate(dateString: string): string {
        const months: string[] = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        const date: Date = new Date(dateString);
        const day: number = date.getDate();
        const month: string = months[date.getMonth()];
        const year: number = date.getFullYear();
        return `${day} ${month} ${year}`;
    }

    navigateToDashboard() {
        this.router.navigate(['dashboard']);
    }

    toggleNameEdit() {
        this.isNameBeingEdited = !this.isNameBeingEdited;
        this.fullName = `${this.user.name} ${this.user.lname}`;
        if (this.isNameBeingEdited) {
            this.form = this.fb.group({
                firstName: [this.user.name || '', [Validators.required]],
                lastName: [this.user.lname || '', [Validators.required]]
            });
        }
        // this.form.reset();
        this.errors.global = '';
    }

    getUpdatedUserName() {
        this.userService.getUser().subscribe((user) => {
            this.isDefaultPic = !user?.imageMeta && !user?.imageMeta?.path;
            this.user = user;
            this.setOwnImageProfilePath();
            this.fullName = `${this.user.name} ${this.user.lname}`;
        });
    }

    changeName() {
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translateService.instant('tostrs.something_went_right'));
            return;
        }
        this.errors = {};
        const formValue = this.form.value;
        let fname = formValue.firstName.trim();
        if (fname.length === 0) fname = this.user.name;
        let lname = formValue.lastName.trim();
        if (lname.length === 0) lname = this.user.lname;

        if (
            this.form.value.firstName.length < 2 ||
            this.form.value.firstName.length > 50 ||
            !/^[0-9a-zA-Z]+$/.test(this.form.value.firstName)
        ) {
            this.errors.global = this.translateService.instant(
                'tostrs.first_name_should_contain_only_lowercase_uppercase_or_numeric_digit_with_minimum_2_and_maximum_20_characters'
            );
            return;
        }

        if (
            this.form.value.lastName.length < 2 ||
            this.form.value.lastName.length > 50 ||
            (this.form.value.lastName && !/^[0-9a-zA-Z]+$/.test(this.form.value.lastName))
        ) {
            this.errors.global = this.translateService.instant(
                'tostrs.last_name_should_contain_only_lowercase_uppercase_or_numeric_digit_with_minimum_2_and_maximum_20_characters'
            );
            return;
        }

        const fullName = `${fname} ${lname}`;
        this.userService
            .updateProfile({
                name: fname,
                lname: lname
            })
            .subscribe(
                () => {
                    this.toastrService.success(
                        this.translateService.instant('tostrs.user_profile_updated_successfully')
                    );
                    // Instantly updating name in UI without waiting for API response
                    this.fullName = fullName;
                    this.toggleNameEdit();
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.NAME_CHANGED,
                        data: null
                    });
                },
                (err) => {
                    if (err?.error?.customCode) {
                        this.errors.global =
                            err.error?.errors ||
                            this.translateService.instant(
                                'tostrs.profile_update_failed_please_try_again_after_some_time'
                            );
                    }
                }
            );
        this.getUpdatedUserName();
    }

    exceededCharacterLimit(event, max_limit) {
        this.localHelperServiceService.exceededCharacterLimitToast(event, max_limit);
    }

    toggleChangePassword() {
        this.isChangePassword = !this.isChangePassword;
        this.showProfile = !this.showProfile;
    }

    toggleChangeProfilePicture() {
        this.isChangeProfilePicture = !this.isChangeProfilePicture;
        this.getUpdatedUserName();
        this.showProfile = !this.showProfile;
    }
    handleKeyUp(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            const dropdownclick = event.target as HTMLElement;
            dropdownclick.click();
        }
    }
    removeProfilePicture() {
        this.userService.deleteProfilePicture().subscribe(
            () => {
                this.toastrService.overlayContainer = this.toastContainer;
                this.toastrService.success(
                    this.translateService.instant('tostrs.profile_picture_deleted_successfully')
                );
                // Added delay to get current user info from api after profile settings modified
                setTimeout(() => {
                    this.getUpdatedUserName();
                }, 1000);
            },
            () => {
                this.toastrService.overlayContainer = this.toastContainer;
                this.toastrService.error(
                    this.translateService.instant('tostrs.error_deleting_profile_pic_please_try_again_later')
                );
            }
        );
    }
    //Accessability changes
    setFocusOnElement() {
        setTimeout(() => {
            const element: any = document.getElementById('personal meeting id');
            element.focus();
        }, 100);
    }
    setFocusOnEditElement() {
        if (!this.user?.isOTT) {
            setTimeout(() => {
                const element: any = document.getElementById('personal meeting id');
                element.focus();
            }, 100);
        }
    }
}
