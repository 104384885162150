export const DEVICE_ERROR_CONSTANTS = {
    enable_dual_stream: 'Enabling dual stream',
    asking_device_permission: 'Asking device permission',
    audio_stream_playback: 'Audio stream playback',
    creating_video_track: 'Creating video track',
    creating_audio_track: 'Creating audio track',
    creating_rtm_instance: 'Creating rtm instance',
    changing_audio_output: 'Changing audio output',
    changing_audio_input: 'Changing audio input',
    changing_video_input: 'Changing video input',
    toggle_camera: 'Toggle camera',
    toggle_microphone: 'Toggle microphone',
    creating_screenshare_track: 'Creating screenshare track',
    publishing_screenshare_track: 'Publishing screenshare track',
    init_screenshare_track: 'Initializing screenshare track',
    moving_to_speaker: 'Moving to speaker',
    moving_to_audience: 'Moving to audience',
    unpublishing_track: 'Unpublishing track'
};
