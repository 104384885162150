import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'trimFileName'
})
export class TrimFileNamePipe implements PipeTransform {
    transform(value: any): any {
        if (!value) {
            return;
        }
        if (value.length > 20) {
            var extnesion = value.split('.');

            return `${value.slice(0, 17)}...` + extnesion[extnesion.length - 1];
        }
        return value;
    }
}
