import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/core';

@Component({
    selector: 'app-website-press-release',
    templateUrl: './website-press-release.component.html',
    styleUrls: ['./website-press-release.component.scss']
})
export class WebsitePressReleaseComponent implements OnInit {
    isMobile = false;
    constructor(private utilService: UtilService) {}

    pressReleaseNotes = [
        {
            date: 'August 11, 2021',
            note: 'EY and JioMeet announce expansion of collaboration to drive a US$15b growth opportunity and technology innovation across industries'
        },
        {
            date: 'August 11, 2021',
            note: 'JioMeet earnings press release available on Investor Relations website'
        },
        {
            date: 'August 11, 2021',
            note: 'JioMeet announces redemption notice'
        },
        {
            date: 'August 11, 2021',
            note: 'JioMeet and NEC expand strategic partnership to boost business resiliency and growth'
        }
    ];
    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser();
    }
}
