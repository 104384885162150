import { Component, OnInit } from '@angular/core';
import { AppService, DesktopAppService, GoogleTagManagerService, LocalStorageService, RestService } from 'src/app/core';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-environment-selection',
    templateUrl: './environment-selection.component.html',
    styleUrls: ['./environment-selection.component.scss']
})
export class EnvironmentSelectionComponent implements OnInit {
    public selectedEnvironment: any;
    public environmentList: any = [
        {
            name: 'Default',
            value: ''
        },
        {
            name: 'RC',
            value: 'rc'
        },
        {
            name: 'Prestage',
            value: 'prestage'
        },
        {
            name: 'Prod',
            value: 'prod'
        }
    ];
    constructor(
        public desktopAppService: DesktopAppService,
        private restService: RestService,
        private appService: AppService,
        private gTagManagerService: GoogleTagManagerService,
        public localStorageService: LocalStorageService
    ) {}

    ngOnInit(): void {
        this.selectedEnvironment = !_.isEmpty(this.localStorageService.getItem('selectedEnvironment'))
            ? this.localStorageService.getItem('selectedEnvironment')
            : this.environmentList[0];
    }
    selectEnvironment() {
        this.desktopAppService.selectedEnvironment = this.selectedEnvironment;
        this.localStorageService.addItem('selectedEnvironment', this.selectedEnvironment);
        this.restService.get(`api/config/v1?os_type=webrtc`).subscribe((config) => {
            this.appService.setConfig(config);
            this.gTagManagerService.initialize(config);
        });
    }
}
