export const GA_EVENTS = {
    PERSONAL_MEETING_SUCCESS: {
        event: 'jiomeet',
        event_action: 'start_a_meeting_with_personal_meeting_id',
        event_category: 'start_a_meeting',
        event_label: 'success'
    },
    ONE_TIME_MEETING_SUCCESS: {
        event: 'jiomeet',
        event_action: 'start_a_meeting_with_one_time_meeting_id',
        event_category: 'start_a_meeting',
        event_label: 'success'
    },
    SHARE_PERSONAL_MEETING_SUCCESS: {
        event: 'jiomeet',
        event_action: 'share_personal_meeting_id',
        event_category: 'start_a_meeting',
        event_label: 'success'
    },
    SHARE_OUTLOOK_SUCCESS: {
        action: 'Share Link via Outlook',
        category: 'Start a Meeting',
        label: 'Success'
    },
    SHARE_GMAIL_SUCCESS: {
        action: 'Share Link via Gmail',
        category: 'Start a Meeting',
        label: 'Success'
    },
    COPY_CLIPBOARD_SUCCESS: {
        action: 'Copy Link to Clipboard',
        category: 'Start a Meeting',
        label: 'Success'
    },
    SETTINGS_SUCCESS: {
        action: 'Start a Meeting-Settings',
        category: 'Start a Meeting',
        label: 'Success'
    },
    START_CALL_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Start a Meeting',
        event_action: 'call start',
        event_label: 'Success'
    },
    START_CALL_FAILURE: {
        event: 'jiomeet',
        event_category: 'Start a Meeting',
        event_action: 'call start',
        event_label: 'Failure'
    },
    CALL_JOIN_WAITING_SUCCESS: {
        event: 'jiomeet',
        event_category: 'Join a Meeting',
        event_action: 'call join waiting',
        event_label: 'Success'
    },
    CALL_JOIN_WAITING_FAILURE: {
        event: 'jiomeet',
        event_category: 'Join a Meeting',
        event_action: 'call join waiting',
        event_label: 'Failure'
    },
    CALL_JOIN_APPROVED: {
        event: 'jiomeet',
        event_category: 'Join a Meeting',
        event_action: 'call join approved',
        event_label: 'Success'
    }
};
