<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
<div
    class="modal fade"
    id="preview-modal"
    [ngStyle]="{ display: this.showPreview ? 'block' : 'none', opacity: 1 }"
    tabindex="-1"
    role="dialog"
>
    <div class="modal-header">
        <div class="row headerRow">
            <div class="col-md-4 docName">
                <img alt="" *ngIf="docType == 'Word'" src="assets/images/defaultWordIcon.svg" />
                <img alt="" *ngIf="docType == 'Excel'" src="assets/images/defaultExcelicon.svg" />
                <img alt="" *ngIf="docType == 'PPT'" src="assets/images/defaultGraphIcon.svg" />
                <img alt="" *ngIf="docType == 'PDF'" src="assets/images/defaultDocsIcon.svg" />
                <img alt="" *ngIf="docType == 'None'" src="assets/images/defaultDocsIcon.svg" />
                <span class="fileName">{{ fileName }}</span>
            </div>
            <div class="col-md-8 preview-opt-cont">
                <div class="row">
                    <div class="col-md-4 mr-3" data-toggle="tooltip" title="Download" (click)="downloadFile()">
                        <img alt="" class="downloadButton pointer" src="assets/images/downloadIcon.svg" />
                    </div>
                    <div class="col-md-4 mr-5" (click)="this.closePreview(false)" data-toggle="tooltip" title="Close">
                        <button class="closeButton pointer">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button class="hide" id="downloadAnchor" #downloadAnchor></button>

    <div class="modal-body" id="myObject">
        <iframe
            title=""
            id="pdfview"
            width="1000px"
            height="1000px"
            #defaultPDFViewer
            *ngIf="renderDefaultPDFViewer"
        ></iframe>
        <!-- <pdf-viewer [src]="previewUrl" #ng2PDFViewer *ngIf="!renderDefaultPDFViewer"></pdf-viewer> -->
    </div>
</div>
