import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import {
    ComponentFactoryResolver,
    ComponentRef,
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ElementRef,
    Inject
} from '@angular/core';
import { interval, Subscription, timer } from 'rxjs';
import { RestService } from 'src/app/core';
import { ApmService } from '@elastic/apm-rum-angular';
import { PrimeNGConfig } from 'primeng/api';
import { CallSettingsComponent, HostDirective } from 'src/app/shared';
import { GA_EVENTS } from 'src/app/components/website/ga-events';
import { DeepLinkService } from 'src/app/core/services/deep-link.service';
declare const window: any;

import {
    EventEmitterService,
    EventData,
    AppService,
    KeyboardeventsService,
    LoggerService,
    SocketService,
    SocketEvent,
    AuthService,
    UserService,
    GuestAuthService,
    RouterService,
    RouteInfo,
    UtilService,
    RtcService,
    FullscreenService,
    ExternalInterfaceService,
    ThirdPartyExternalIntegrationService,
    SystemService,
    LocalStorageService,
    AgoraService,
    GoogleTagManagerService,
    DesktopAppService
} from './core';

import { APP_CONSTANT, APP_EVENTS, SOCKET_EVENTS } from './constants';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/api';
import { AppLoggerService } from './core/services/app-logger.service';
import { DesktopAppUpdaterService } from './core/services/desktop-app-updater.service';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';

declare const gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    @ViewChild('header') headerElement: ElementRef;
    @ViewChild(HostDirective) viewHost: HostDirective;
    settingsComponentRef: ComponentRef<CallSettingsComponent>;

    showSessionExpiryDialog = false;
    showHeader = false;
    showFooter = false;
    showInvite = false;
    queryParams: any = {};
    loader = true;
    isOffline = false;
    disableSocketConnection = false;
    showIncomingCallPopup = false;
    enableJoinWithBrowser = false;
    addParticipantsInCallEnabled;
    isThirdPartyExternalIntegration;
    enableWidth_1366 = false;
    isMobile = false;
    isAuthenticated = false;
    isMobileOS: boolean = false;
    timeoutRef;
    isOutlookAddInURL = false;
    isChatEnabledForWeb = false;
    isMattermostEnabled = false;
    localStorageLogging = false;
    isInisideIframe = false;
    showProAlertBanner = false;
    remindMeAlertbanner = false;
    iosMacDeeplink = null;
    letsChatEle;
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private appService: AppService,
        private keyboardeventsService: KeyboardeventsService,
        private authService: AuthService,
        private logger: LoggerService,
        private socketService: SocketService,
        private eventEmitterService: EventEmitterService,
        private userService: UserService,
        private guestAuthService: GuestAuthService,
        private routerService: RouterService,
        private utilService: UtilService,
        private translateService: TranslateService,
        private rtcService: RtcService,
        @Inject(ApmService) apmService: ApmService,
        private fullscreenService: FullscreenService,
        private externalInterfaceService: ExternalInterfaceService,
        private toastrService: ToastrService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        private systemService: SystemService,
        private confirmationService: ConfirmationService,
        private config: PrimeNGConfig,
        private localStorageSession: LocalStorageService,
        private agoraService: AgoraService,
        public appLoggerService: AppLoggerService,
        public desktopAppUpdaterService: DesktopAppUpdaterService,
        private compFactory: ComponentFactoryResolver,
        private gTagService: GoogleTagManagerService,
        private deepLinkService: DeepLinkService,
        private hotkeysService: HotkeysService,
        private desktopAppService: DesktopAppService,
        private restService: RestService,
        private gTagManagerService: GoogleTagManagerService
    ) {
        this.translateService.setDefaultLang('en');
        this.isOutlookAddInURL =
            window.location.href.includes('/outlook/') ||
            window.location.href.includes('/teams-bot/') ||
            this.utilService.isIE();

        if (this.appService.getConfigVariable('elasticApm')?.ENABLE) {
            const apm = apmService.init({
                serviceName: 'jiomeet-webrtc',
                serverUrl: this.appService.getConfigVariable('elasticApm')?.serverUrl,
                distributedTracingOrigins: this.appService.getConfigVariable('elasticApm')?.distributedTracingOrigins
            });

            this.appService.setApm(apm);
        }

        this.isMobileOS = this.utilService.isMobileBrowser();
        // if (!this.isOutlookAddInURL) {
        //     this.addGAScript();
        // }
        this.handleHotkeysEvents();
        this.router.events.pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd)).subscribe((event) => {
            if (event.id === 1 && event.url === event.urlAfterRedirects) {
                this.appService.isPageRefreshed = true;
                this.resetBreakOutInfo();
            }
        });
    }

    ngOnInit() {
        this.isInisideIframe = window.self !== window.top;
        this.isMattermostEnabled = this.utilService.isMattermost();
        this.isChatEnabledForWeb = this.appService.getConfigVariable('ENABLE_WEBSITE_CHAT');
        this.localStorageLogging = this.appService.getConfigVariable('LOCAL_LOGS_ENABLED')?.enable;
        this.isMobile = this.utilService.isMobileBrowser();
        this.isThirdPartyExternalIntegration =
            this.thirdPartyExternalIntegrationService.getThirdPartyExternalIntegration();
        this.addParticipantsInCallEnabled = this.appService.getConfigVariable('ENABLE_ADD_PARTICIPANTS_IN_CALL');
        this.enableJoinWithBrowser = this.appService.getConfigVariable('ENABLE_WEBRTC');
        this.activatedRoute.queryParams.subscribe((params) => (this.queryParams = params));
        this.appService.setAppQueryParams(new URLSearchParams(location.search));
        this.logger.setLogging(this.appService.getConfigVariable('LOGS_ENABLED'));
        this.externalInterfaceService.setupCallbacksFromNative();
        this.disableSocketConnection =
            new URLSearchParams(window.location.search).get('disableSocketConnection') === 'true' ? true : false;

        if (window.location.href.includes('/outlook/')) {
            this.disableSocketConnection = true;
        }

        const lang = new URLSearchParams(window.location.search).get('lan');
        this.translateService.use(this.appService.getAndSaveUserPreferredLang(lang));
        this.eventEmitterService.subscribe((event: EventData) => {
            if (this.disableSocketConnection) {
                return;
            }
            if (event.type === APP_EVENTS.SESSION_EXPIRED || event.type === APP_EVENTS.LOGOUT) {
                this.socketService.dispose();
                if (event.data.navigateToLogin) {
                    this.router.navigate(['/home'], { queryParams: this.queryParams });
                }
            } else if (event.type === APP_EVENTS.AUTHORIZED) {
                this.socketService.connect({
                    url: this.appService.getConfigVariable('WS_URL'),
                    jwt: this.authService.getAuthInfo()?.jwt || this.guestAuthService.getAuthInfo()?.jwt,
                    heartBeatRate: this.appService.getConfigVariable('PRESENCE_INTERVAL')
                });
            } else if (event.type === APP_EVENTS.PROFILE_HIDE_EVENT && event?.data === 'letsChat') {
                this.closeLetsChat();
            }
        });

        this.socketService.dataEvents$.subscribe((event: SocketEvent) => {
            this.handleSocketEvents(event);
        });

        const authInfo = this.authService.getAuthInfo();
        if (authInfo) {
            this.authService.setAuthInfo(authInfo);
            this.userService.getUser().subscribe(
                (res) => this.removeLoader(),
                (err) => this.removeLoader()
            );
        } else {
            this.removeLoader();
        }

        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.SHOW_INVITE_POPUP) {
                this.showInvite = true;
            }
        });

        this.routerService.listen();
        this.routerService.getRouterState().subscribe((state: RouteInfo) => {
            const ele = document.getElementsByClassName('app-container')[0];
            this.showHeader = typeof state.data.showHeader === 'undefined' ? true : state.data.showHeader;
            this.showFooter = typeof state.data.showFooter === 'undefined' ? false : state.data.showFooter;

            // special case for home page. If user is logged in then do not show footer only for home page.
            if (state.url === '/home' && this.isAuthenticated) {
                this.showFooter = false;
            }
            this.isThirdPartyExternalIntegration =
                this.thirdPartyExternalIntegrationService.getThirdPartyExternalIntegration();
            if (this.isThirdPartyExternalIntegration) {
                this.showHeader =
                    !this.thirdPartyExternalIntegrationService.getIsNavbarDisabledForExternalIntegration();
                this.showFooter =
                    !this.thirdPartyExternalIntegrationService.getIsFooterDisabledForExternalIntegration();
            }
            if (window.location.href.includes('/guest') && window.location.href.includes('isJioHealthHub')) {
                this.showFooter = false;
                this.showHeader = false;
            }
            if (ele && !this.isOutlookAddInURL) {
                ele.scrollTo(0, 0);
                const scrollable = ele.querySelector('#mainapp');
                if (scrollable) {
                    scrollable.scrollTo(0, 0);
                }
            }
            if (!this.isInisideIframe) {
                this.checkAndLoadHaptik(state.url);
                const haptikElement = document.getElementById('haptik-xdk-wrapper');
                if (haptikElement) {
                    haptikElement.style.display =
                        state.url.includes('/conference/call') ||
                        state.url.includes('/webinar/') ||
                        state.url.includes('/whiteboard') ||
                        state.url.includes('/integrations') ||
                        state.url.includes('/watchparty') ||
                        state.url.includes('/watch-party') ||
                        state.url.includes('/acs') ||
                        state.url.includes('/outlook') ||
                        state.url.includes('/teams-bot') ||
                        state.url.includes('/chat') ||
                        //  this.utilService.isMobileBrowser() || commenting this as we have to show in mobile as well
                        state.url.includes('/getClient')
                            ? 'none'
                            : 'block';
                }
            }

            this.handleMaxScreenWidth();
        });

        this.fullscreenService.registerWindowEvents();
        this.handleWindowEvents();

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (!this.appService.isOnLine) {
                    // this.toastrService.clear();
                    // this.toastrService.info(
                    //   this.translateService.instant('tostrs.unable_to_connect_to_network')
                    // );
                    this.isOffline = true;
                }
            }
            this.handleMaxScreenWidth();
        });

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.handleMaxScreenWidth(event.url);
        });

        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
        });

        this.checkSyncServerTime();

        if (!this.isInisideIframe && this.localStorageLogging && window.localStorage !== undefined) {
            window['downloadLocalLogs'] = function () {
                let localLogErrorObject = '';
                if (window.localStorage['appLocalErrorLogs'] !== null) {
                    localLogErrorObject = window.localStorage['appLocalErrorLogs'];
                } else {
                    localLogErrorObject = 'No error logs found!';
                }
                const eLink = document.createElement('a');
                const errorFile = new Blob([localLogErrorObject], { type: 'text/plain' });
                eLink.href = URL.createObjectURL(errorFile);
                const dd = new Date();
                const uniqueName =
                    dd.getDate() +
                    '-' +
                    dd.getMonth() +
                    '-' +
                    dd.getFullYear() +
                    '_' +
                    dd.getHours() +
                    '-' +
                    dd.getMinutes() +
                    '-' +
                    dd.getSeconds();
                eLink.download = `webRTC-Error-Log-${uniqueName}.txt`;
                eLink.click();
                URL.revokeObjectURL(eLink.href);

                let localLogNonErrorObject = '';
                if (window.localStorage['appLocalNonErrorLogs'] !== null) {
                    localLogNonErrorObject = window.localStorage['appLocalNonErrorLogs'];
                } else {
                    localLogNonErrorObject = 'No non error logs found!';
                }
                const neLink = document.createElement('a');
                const nonErrorFile = new Blob([localLogNonErrorObject], { type: 'text/plain' });
                neLink.href = URL.createObjectURL(nonErrorFile);
                neLink.download = `webRTC-Non-Error-Log-${uniqueName}.txt`;
                neLink.click();
                URL.revokeObjectURL(neLink.href);
            };
        }
        this.settingsViewLoadSubcribtion();
        this.proAlertBannerVisibilityListener();
        this.subscribeDownloadJioMeet();
    }

    enableChatForAbove1080() {
        return (
            !!this.isChatEnabledForWeb &&
            (this.router.url.substring(0, 5) === '/chat' ||
                this.router.url.substring(0, 10) === '/meetings' ||
                this.router.url.substring(0, 13) === '/addtojiomeet' ||
                this.router.url.substring(0, 9) === '/contacts') &&
            this.isAuthenticated &&
            window.innerWidth >= 1080 &&
            !this.enableWidth_1366
        );
    }

    removeLoader() {
        this.loader = false;
        setTimeout(() => this.handleMaxScreenWidth(), 0);
    }

    addGAScript() {
        let gtagScript: HTMLScriptElement = document.createElement('script');
        gtagScript.async = true;
        let trackingID = this.appService.getConfigVariable('GA_TRACKING_ID');
        gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + trackingID;
        document.head.prepend(gtagScript);
        /** Disable automatic page view hit to fix duplicate page view count  **/
        gtag('config', trackingID, {
            send_page_view: false,
            custom_map: {
                dimension1: 'client_id',
                dimension2: 'user_id',
                dimension3: 'meeting_id',
                dimension4: 'device_id'
            }
        });
    }

    handleMaxScreenWidth(routeUrl = this.router.url) {
        clearTimeout(this.timeoutRef);
        this.timeoutRef = setTimeout(() => {
            this.enableWidth_1366 =
                routeUrl.indexOf('/meetings') === -1 &&
                routeUrl.indexOf('/chat') === -1 &&
                routeUrl.indexOf('/conference/call') === -1 &&
                routeUrl.indexOf('/watch-party') === -1 &&
                routeUrl.indexOf('/addtojiomeet') === -1 &&
                routeUrl.indexOf('/contacts') === -1 &&
                routeUrl.indexOf('/help') === -1;
            routeUrl.indexOf('/webhelp') === -1;
            const body = document.getElementsByTagName('html')[0];
            //  document.getElementById('scrollable')?.classList.remove('pos-abs');
            if (this.enableWidth_1366) body.classList.add('width-1366');
            else {
                body.classList.remove('width-1366');
                document.getElementById('scrollable')?.classList.add('pos-abs');
            }

            // spacial edge case handling for ios devices
            document.getElementById('scrollable')?.classList.remove('pos-fixed');
            if (
                ((routeUrl.indexOf('/signup') !== -1 ||
                    routeUrl.indexOf('/forgot-password') !== -1 ||
                    routeUrl.indexOf('/login') !== -1) &&
                    this.isMobile) ||
                routeUrl.includes('/watch-party')
            ) {
                document.getElementById('scrollable')?.classList.add('pos-fixed');
            }
        }, 0);
    }

    checkSyncServerTime() {
        if (this.appService.getConfigVariable('DISABLE_SYNC_SERVER_TIME_CHECK')) {
            return;
        }
        if (window.location.href.includes('/whiteboard/')) {
            return;
        }
        this.systemService
            .isSyncWithServerTime(this.appService.getConfigVariable('SYNC_SERVER_OFFSET_LIMIT'))
            .subscribe((inSync) => {
                if (!inSync) {
                    this.confirmationService.confirm({
                        message: this.translateService.instant('tostrs.incorrect_time_zone'),
                        header: this.translateService.instant('tostrs.alert'),
                        acceptLabel: this.translateService.instant('tostrs.ok'),
                        acceptButtonStyleClass: 'custom-button bg-primary',
                        rejectVisible: false,
                        accept: () => {
                            this.confirmationService.close();
                        },
                        reject: () => {
                            this.confirmationService.close();
                        }
                    });
                    this.utilService.setTimeOutForAccessibilityPopUp();
                }
            });
    }

    handleWindowEvents() {
        window.addEventListener('online', (event) => {
            this.socketService.reconnect();
            this.isOffline = false;
        });
        window.addEventListener('storage', (event) => {
            if (event.storageArea != localStorage) return;
            if (event.key === 'jmc.userLang') {
                this.translateService.use(this.appService.getAndSaveUserPreferredLang());
                this.translateService.get('primeng').subscribe((res) => this.config.setTranslation(res));
                (document.getElementsByClassName('p-datepicker-today')[0]?.firstChild as HTMLElement)?.click();
            }
            if (event.key === 'jmc.isMirrorViewEnabled') {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.SELF_VIEW_TOGGLE,
                    data: null
                });
            }
        });
        window.addEventListener('offline', (e) => {
            if (!window.location.href.includes('conference/call')) {
                // this.toastrService.info(
                //   this.translateService.instant('tostrs.unable_to_connect_to_network')
                // );
                this.isOffline = true;
            }
        });
    }

    checkAndLoadHaptik(url) {
        if (
            url.includes('/webinar/') ||
            url.includes('/whiteboard') ||
            url.includes('/conference/call') ||
            url.includes('/mst') ||
            url.includes('/outlook') ||
            url.includes('/integrations') ||
            this.queryParams.chatbot === 'false' ||
            (this.isThirdPartyExternalIntegration &&
                this.thirdPartyExternalIntegrationService.getIsHaptikDisabledForExternalIntegration()) ||
            url.includes('/watchparty') ||
            url.includes('/watch-party') ||
            url.includes('/teams-bot') ||
            url.includes('/chat') ||
            url.includes('/getClient') ||
            url.includes('/mediaplayer') ||
            this.appService.getEnvVariable('local') ||
            this.desktopAppService.isDesktopApp
        ) {
            //haptikElement is null when webinar_attendee page/whiteboard is loaded.
            return;
        }

        const haptikConfig = this.appService.getConfigVariable('HAPTIK_CONFIG');
        if (haptikConfig && haptikConfig?.ENABLE && !window.haptikInitSettings) {
            window.haptikInitSettings = {
                'business-id': haptikConfig.BUSINESS_ID,
                'client-id': haptikConfig.CLIENT_ID,
                'base-url': haptikConfig.BASE_URL
            };
            const _this = this;
            const script = document.createElement('script');
            script.onload = () => {
                // in case of mobile show small chat icon
                if (this.isMobile) {
                    setTimeout(() => {
                        const iframe: any = document.getElementsByClassName('xdk-iframe')[2];
                        if (iframe) {
                            iframe?.classList.add('haptik-chat-mobileview');
                            const elmnt =
                                iframe?.contentWindow.document.getElementsByClassName('minimized-view-text')[0];
                            if (elmnt) {
                                elmnt.style.display = 'none';
                                elmnt.setAttribute('aria-label', "Let's chat");
                            }
                        }
                    }, 2000);
                }

                // Ingnore timeouts for optimization, Its external tool, So made it work for accessibility
                setTimeout(() => {
                    const chatIFrame: any = document.getElementsByClassName('xdk-iframe');
                    if (chatIFrame?.length) {
                        var chatDocument = chatIFrame[0].contentDocument
                            ? chatIFrame[0].contentDocument
                            : chatIFrame[0].contentWindow.document;
                        _this.letsChatEle = chatDocument.getElementsByClassName('minimized-view');
                        if (_this.letsChatEle?.length) {
                            _this.letsChatEle[0].setAttribute('tabindex', '0');
                            _this.letsChatEle[0].setAttribute('aria-label', "Let's Chat");
                            _this.letsChatEle[0].addEventListener('click', function (event) {
                                _this.emitProfileHideEvent();
                            });
                            _this.letsChatEle[0].addEventListener('keyup', function (event) {
                                event.preventDefault();
                                if (event.keyCode === 13) {
                                    setTimeout(() => {
                                        const closeBtnState = chatDocument.getElementsByClassName(
                                            'minimized-view-cross-icon open'
                                        );
                                        _this.letsChatEle[0].setAttribute(
                                            'aria-label',
                                            closeBtnState?.length ? 'Close Chat' : "Let's Chat"
                                        );
                                        if (closeBtnState?.length) {
                                            setTimeout(() => {
                                                const mainChatWindow: any =
                                                    document.getElementsByClassName('xdk-iframe');
                                                if (mainChatWindow?.length) {
                                                    var chatMainDocument = mainChatWindow[0].contentDocument
                                                        ? mainChatWindow[0].contentDocument
                                                        : mainChatWindow[0].contentWindow.document;
                                                    const brandingBtn = chatMainDocument.getElementsByTagName('a');
                                                    if (brandingBtn?.length) {
                                                        brandingBtn[0].setAttribute('tabindex', '-1');
                                                    }
                                                }
                                            }, 1000);
                                        }
                                    }, 200);
                                    _this.letsChatEle[0].click();
                                }
                            });
                        }
                    }
                }, 2000);
            };
            script.src = haptikConfig.SRC;
            document.head.appendChild(script);
        }
    }

    closeLetsChat() {
        if (this.letsChatEle?.length) {
            const chatIFrame: any = document.getElementsByClassName('xdk-iframe');
            if (chatIFrame?.length > 1) {
                this.letsChatEle[0]?.click();
            }
        }
    }

    handleSocketEvents(event: SocketEvent) {
        if (
            event.event === SOCKET_EVENTS.CALL &&
            this.enableJoinWithBrowser &&
            this.addParticipantsInCallEnabled &&
            (!this.utilService.isMobileBrowser() || this.appService.getConfigVariable('ENABLE_CALLS_MOBILE_DEVICES'))
        ) {
            const alreadyHaveAnIncomingCall =
                !!this.rtcService.getIncomingCallMeeting() || !!this.localStorageSession.getItem('inActiveEvent');
            if (alreadyHaveAnIncomingCall || this.agoraService.callInProgress) {
                // this.rtcService.sendInAnotherCallNotification(event.data).subscribe();
                return;
            }
            this.rtcService.setIncomingCallMeeting(event.data);
            this.showIncomingCallPopup = true;
            this.emitIncomingCallEvent();
        } else if (event.event === SOCKET_EVENTS.PROFILE_UPDATE) {
            this.userService.updateLocalUserData().subscribe(() => {
                this.eventEmitterService.emit({
                    type: APP_EVENTS.GET_UPDATED_USER_DATA,
                    data: {}
                });
            });
        } else if (
            event.event === SOCKET_EVENTS.VC_EVENT &&
            this.rtcService.getRoomInfo()?.jiomeetId === event?.data?.jiomeetId
        ) {
            if (event?.data?.errorCode === 1) {
                this.toastrService.info(this.translateService.instant('tostrs.vc_room_busy'));
            } else if (event?.data?.errorCode === 2 || event?.data?.errorCode === 3) {
                this.toastrService.info(this.translateService.instant('tostrs.vc_room_error'));
            }
        } else if (event.event === SOCKET_EVENTS.NOTIFY_USERS_ABOUT_CONFIG_UPDATE) {
            const update_config_timer = 5 * 60 * 1000;
            if (event.data.platform === 'webrtc') {
                setTimeout(() => {
                    this.restService.get(`api/config/v1?os_type=webrtc`).subscribe((config) => {
                        this.appService.setConfig(config);
                    });
                }, update_config_timer);
            }
        }
    }

    emitProfileHideEvent() {
        setTimeout(() => {
            if (this.letsChatEle?.length) {
                const chatIFrame: any = document.getElementsByClassName('xdk-iframe');
                if (chatIFrame?.length > 1) {
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.PROFILE_HIDE_EVENT,
                        data: 'profile'
                    });
                }
            }
        }, 100);
    }

    emitIncomingCallEvent() {
        if (this.showIncomingCallPopup) {
            this.eventEmitterService.emit({
                type: APP_EVENTS.INCOMING_CALL,
                data: {}
            });
        }
    }

    handleCloseInviteModal() {
        this.showInvite = false;
    }

    navigateToLogin() {
        this.showSessionExpiryDialog = false;
        this.router.navigate(['login']);
    }

    toggleIncomingCallPopup(forceClose = false) {
        if (forceClose) {
            this.showIncomingCallPopup = false;
        } else {
            this.showIncomingCallPopup = !this.showIncomingCallPopup;
            this.emitIncomingCallEvent();
        }
    }

    tryReconnect() {
        this.isOffline = false;
        setTimeout(() => {
            if (!window.navigator.onLine) {
                this.isOffline = true;
            }
        }, 1000);
    }

    close() {
        this.isOffline = false;
    }

    settingsViewLoadSubcribtion() {
        this.appService.openSettingsView.subscribe((state) => {
            if (!this.settingsComponentRef?.instance && state) {
                const settings = this.compFactory.resolveComponentFactory(CallSettingsComponent);
                this.settingsComponentRef = this.viewHost.viewContainerRef.createComponent(settings);
                this.settingsComponentRef.instance.selectedLabel = 'general';
                this.settingsComponentRef.instance.afterClose.subscribe((res) => {
                    this.settingsComponentRef?.destroy();
                    this.settingsComponentRef = undefined;
                });
                this.gTagService.sendEvent(GA_EVENTS.DASHBOARD_SETTINGS);
            } else if (this.settingsComponentRef?.instance && !state) {
                this.settingsComponentRef?.destroy();
                this.settingsComponentRef = undefined;
            }
        });
    }

    proAlertBannerVisibilityListener() {
        this.userService.proAlertBanner.subscribe((visibility) => {
            this.setProAlertBannerVisibility(visibility);
        });
    }

    setProAlertBannerVisibility(visible) {
        this.showProAlertBanner = false;
        if (visible && !this.localStorageSession.getItem('proAlertSeen') && !this.remindMeAlertbanner) {
            let currentUser = this.userService.getUserSync();
            if (currentUser.isOTT && currentUser.proStatus && currentUser.planName === 'pro') {
                this.showProAlertBanner = true;
            }
        }
    }

    proAlertBannerOkClicked() {
        this.showProAlertBanner = false;
        this.localStorageSession.addItem('proAlertSeen', true);
    }

    proAlertBannerReminderClicked() {
        this.showProAlertBanner = false;
        this.remindMeAlertbanner = true;
        this.localStorageSession.addItem('proAlertSeen', false);
    }
    subscribeDownloadJioMeet() {
        this.deepLinkService.iosMacDeeplinkSubject.subscribe((link) => {
            this.iosMacDeeplink = link;
            setTimeout(() => {
                this.iosMacDeeplink = null;
            }, 100);
        });
    }

    handleHotkeysEvents() {
        var keboardShortcutsList = this.keyboardeventsService.getAllKeyboardShorcuts();
        this.hotkeysService.add(
            new Hotkey(keboardShortcutsList, (event: KeyboardEvent, combo: string): boolean => {
                this.keyboardeventsService.recieveKeboardShortcuts(combo);
                return false;
            })
        );
    }

    resetBreakOutInfo() {
        this.rtcService.setBreakoutRoomInfo(null);
    }

    ngOnDestroy() {
        this.socketService.dispose();
        this.showIncomingCallPopup = false;
        this.fullscreenService.unregisterDocumentListener();
    }
}
