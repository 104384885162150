import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root'
})
export class OauthService {
    constructor(private restService: RestService, private appService: AppService) {}

    getClient(oauthclientId) {
        return this.restService.get(`api/oauth/${oauthclientId}`);
    }

    checkAuthentication(oauthclientId) {
        return this.restService.post(`api/oauth/${oauthclientId}/hasauthorized`, {});
    }

    saveUserConsent2(data) {
        return this.restService.post(`api/oauth2/v2/authorize`, data);
    }

    saveUserConsent(oauthclientId, redirect_uri, state) {
        return this.restService.post(`api/oauth/${oauthclientId}/authorize`, { redirect_uri, state });
    }
}
