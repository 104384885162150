import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';

import {
    UtilService,
    AuthService,
    AppService,
    GaDiagnosticService,
    UserService,
    EventEmitterService,
    EventData,
    GoogleTagManagerService,
    DownloadAppService
} from 'src/app/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GA_EVENTS } from './ga-events';
import { GA_PAGEVIEW } from './ga-pageview';

import { WEBSITE_SOLUTIONS, WEBSITE_PRODUCT_FEATURES, WEBSITE_TESTIMONIALS } from './websiteConstant';
import { APP_EVENTS, KEYBOARD_CONSTANT } from 'src/app/constants';
import { DeepLinkService } from 'src/app/core/services/deep-link.service';
import { Subscription } from 'rxjs';
import { GA_NEW } from 'src/app/shared/ga-new';
import { GA4_EVENTS } from './ga4-events';
import { TranslateService } from '@ngx-translate/core';

declare const window: any;

@Component({
    selector: 'app-website',
    templateUrl: './website.component.html',
    styleUrls: ['./website.component.scss']
})
export class WebsiteComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('bannerSlider', { static: true }) bannerSlider: ElementRef;
    currentUser;
    currentSlideIndex = 0;
    isAuthenticated;
    showNotSupportedMessage = false;
    isMobile = false;
    isOutlookAddInEnabled = false;
    showGaneshChaturthiBanner = this.appService.getConfigVariable('SHOW_GANESH_CHATURTHI_BANNER');
    showSignupBanner = this.appService.getConfigVariable('SHOW_SIGNUP_BANNER');
    language = this.appService.getAndSaveUserPreferredLang();

    private bannerCarousel;
    testimonialCarousel;

    jiomeetSubscription = false;
    subscribedEmail = null;

    websiteSolutions: any = [];
    websiteProductFeatures: any = WEBSITE_PRODUCT_FEATURES;
    websiteTestimonials = WEBSITE_TESTIMONIALS;
    mobileHeaderMenues = [];
    websiteMetaInfo;

    milestone_downloads = 0;
    milestone_meeting_minutes = 0;
    milestone_webinar_minutes = 0;
    screenInnerWidth = window.innerWidth;
    HomeSubscribe = 'HomeSubscribe';

    private numberAnimationComplete = false;
    subscriptions: Subscription[] = [];
    iosMacDeeplink: unknown;

    routeData;
    homePageBranding: string[] = [];

    showMeetingForm = false;
    showPlanPopup = false;
    bannersConfigData;

    homeAccessibilityHeaderLabel = 'Home page';

    constructor(
        private appService: AppService,
        private authService: AuthService,
        private utilService: UtilService,
        private userService: UserService,
        private router: Router,
        private eventEmitterService: EventEmitterService,
        private gaService: GaDiagnosticService,
        private gTagService: GoogleTagManagerService,
        private activatedRoute: ActivatedRoute,
        private deepLinkService: DeepLinkService,
        private translate: TranslateService,
        private downloadAppService: DownloadAppService
    ) {}

    ngOnInit() {
        this.showNotSupportedMessage = !this.utilService.isRTCSupportedBrowser();
        this.isMobile = this.utilService.isMobileBrowser(1080);
        this.isOutlookAddInEnabled = this.appService.getConfigVariable('OUTLOOK_ADD_IN_ENABLED');
        this.bannersConfigData = this.appService.getConfigVariable('BANNERS_CONFIG');
        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
            this.currentUser = this.userService.getUserSync();
        });
        this.homeAccessibilityHeaderLabel = this.isAuthenticated ? 'Home page' : 'Main page';
        this.activatedRoute.data.subscribe((params) => (this.routeData = { ...params }));
        if (this.routeData?.type === 'openjiomeet') {
            this.activatedRoute.data.subscribe((route) => {
                this.deepLinkService.handleDeepLink(this.activatedRoute);
            });
        }
        // get user profile info
        this.getUserProfile();
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 1080 ? false : true;
                this.screenInnerWidth = event.data.innerWidth;
            }
            if (event.type === APP_EVENTS.LANGUAGE_CHANGE) {
                this.language = this.appService.getAndSaveUserPreferredLang();
            }
            if (event.type === KEYBOARD_CONSTANT.KEYBOARD_SHORTCUTS) {
                this.handleKeyboardShortcuts(event);
            }
        });

        this.subscriptions.push(
            this.deepLinkService.iosMacDeeplinkSubject.subscribe((link) => {
                this.iosMacDeeplink = link;
            })
        );
        const hash = location.hash;
        if (hash === '#registration') {
            this.scrollToAnchor('registration-form', 100);
        }
        this.websiteSolutions = WEBSITE_SOLUTIONS;
        this.getMetaInfo();
        this.gTagService.pushOnDataLayer(GA_PAGEVIEW.HOME_PAGE_VIEW);
    }

    ngAfterViewInit() {
        this.showProPlanAlertBanner();
    }

    showProPlanAlertBanner() {
        if (this.router.url === '/home') {
            this.userService.setProBannerVisibility(this.isAuthenticated);
        }
    }

    openMeetingPopup() {
        this.gTagService.sendEvent(GA_EVENTS.HOME_PLAN_MEETING_BEFORE_LOGIN);
        this.gTagService.sendEvent(GA4_EVENTS.HOME_PLAN_MEETING_BEFORE_LOGIN);
        this.eventEmitterService.emit({ type: APP_EVENTS.PLAN_MEETING, data: {} });
    }

    openPlanPopup() {
        if (this.router.url === '/home') {
            this.gTagService.sendEvent(GA_NEW.HOME_PLAN_A_MEETING_BEFORE_LOGIN);
        } else {
            this.gTagService.sendEvent(GA4_EVENTS.HOME_PLAN_MEETING_BEFORE_LOGIN);
        }
        if (
            this.appService.getConfigVariable('webinarConfig')?.enableScheduleFlow &&
            this.currentUser?.canCreateWebinar
        ) {
            this.showPlanPopup = true;
        } else this.toggleMeetingForm();
    }

    closePlanSelection() {
        this.showPlanPopup = false;
    }

    navigateToWebinars() {
        window.open(this.appService.getConfigVariable('webinarConfig')?.jioEventsURL, '_blank');
    }

    toggleMeetingForm() {
        this.showMeetingForm = !this.showMeetingForm;
        this.showPlanPopup = false;
        if (!this.showMeetingForm) {
            this.router.navigate([this.router.url]);
        } else {
            this.setFocusOnElement('meetingheadingfocus');
        }
    }

    getUserProfile() {
        const authInfo = this.authService.getAuthInfo();
        if (authInfo) {
            this.userService.getUser().subscribe(
                (res) => {
                    this.currentUser = this.userService.getUserSync();
                },
                (err) => {}
            );
        }
    }

    getMetaInfo() {
        this.userService.getWebsiteMetaInfo().subscribe(
            (res) => {
                if (res) {
                    this.websiteMetaInfo = res;

                    // if there are not any milestone data then add default
                    if (!this.websiteMetaInfo?.milestones) {
                        this.websiteMetaInfo.milestones = {
                            downloads: 16,
                            meetingMinutes: 5.3,
                            webinarMinutes: 4.3
                        };
                    }
                    this.milestone_downloads = res?.milestones?.downloads;
                    this.milestone_meeting_minutes = res?.milestones?.meetingMinutes;
                    this.milestone_webinar_minutes = res?.milestones?.webinarMinutes;
                    this.brandingCheck(this.websiteMetaInfo.branding);
                }
            },
            (err) => {}
        );
    }

    brandingCheck(branding) {
        if (branding?.length > 0) {
            branding.forEach((element) => {
                if (element.publishOn.includes('JioMeet')) {
                    this.homePageBranding.push(element);
                }
            });
        }
    }

    downloadJioMeet() {
        this.downloadAppService.downloadJioMeet();
    }

    scrollTo(elementId) {
        if (elementId === 'registration-form') {
            this.gaService.sendEvent(GA_EVENTS.ENTERPRISE_REGISTRATION_INTEREST);
        }
        this.utilService.scrollToAnchor(elementId, 50);
    }

    getStarted() {
        if (this.isAuthenticated) {
            this.router.navigate(['/']);
            return;
        }
        this.router.navigate(['signup']);
    }

    scrollToAnchor(location: string, wait: number): void {
        const element = document.querySelector('#' + location);
        if (element) {
            setTimeout(() => {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest'
                });
            }, wait);
        }
    }

    getOutlookAddIn() {
        window.open(this.appService.getConfigVariable('GET_OUTLOOK_ADD_IN_URL'), '_blank');
    }

    handleSubscription(data) {
        this.jiomeetSubscription = true;
        this.subscribedEmail = data.email;
    }

    navigateTo(route) {
        this.router.navigate([route]);
    }

    onVisibleSlider(args) {
        if (args.status) {
            this.testimonialCarousel.options.autoPlay = 4000;
        }
    }

    onVisibleCarnival(data) {
        const res = this.websiteMetaInfo;
        if (data.status) {
            if (!this.numberAnimationComplete) {
                this.milestone_downloads = 0;
                this.milestone_meeting_minutes = 0;
                this.milestone_webinar_minutes = 0;
            }
            if (res?.milestones?.downloads) this.animateNumber(res?.milestones?.downloads, 'milestone_downloads');
            if (res?.milestones?.meetingMinutes)
                this.animateNumber(res?.milestones?.meetingMinutes, 'milestone_meeting_minutes');
            if (res?.milestones?.downloads)
                this.animateNumber(res?.milestones?.webinarMinutes, 'milestone_webinar_minutes');

            this.numberAnimationComplete = true;
        }
    }

    animateNumber(number, elemId) {
        setTimeout(
            (number, elemId) => {
                if (this[elemId] < number) {
                    this[elemId] += 1;
                    this.animateNumber(number, elemId);
                } else {
                    this[elemId] = number;
                }
            },
            100,
            number,
            elemId
        );
    }
    onNavigateToExternal(event) {
        const externalUrl = event?.target?.getAttribute('data-src');
        if (externalUrl) window.open(externalUrl, '_blank');
    }

    goToStartMeeting() {
        this.gTagService.sendEvent(GA_EVENTS.HOME_START_MEETING_BEFORE_LOGIN);
        this.gTagService.sendEvent(GA4_EVENTS.HOME_START_MEETING_BEFORE_LOGIN);
        this.router.navigate(['login']);
    }

    goToJoinMeeting() {
        if (this.router.url === '/home') {
            this.gTagService.sendEvent(GA_NEW.HOME_JOIN_A_MEETING_BEFORE_LOGIN);
        } else {
            this.gTagService.sendEvent(GA4_EVENTS.HOME_JOIN_MEETING_BEFORE_LOGIN);
        }
        this.gTagService.sendEvent(GA_EVENTS.HOME_JOIN_MEETING_BEFORE_LOGIN);
        this.router.navigate(['join']);
    }

    goToPlanMeeting() {
        this.gTagService.sendEvent(GA_EVENTS.HOME_PLAN_MEETING_BEFORE_LOGIN);
        this.router.navigate(['login']);
    }

    goToMeetingHost() {
        if (this.router.url === '/home') {
            this.gTagService.sendEvent(GA_NEW.HOME_START_A_MEETING_BEFORE_LOGIN);
        } else {
            this.gTagService.sendEvent(GA4_EVENTS.HOME_START_MEETING_BEFORE_LOGIN);
        }
        this.gTagService.sendEvent(GA_EVENTS.HOME_START_MEETING_BEFORE_LOGIN);
        this.router.navigate(['host']);
    }

    goToDashboard() {
        this.gTagService.sendEvent(GA_EVENTS.HOME_MY_DASHBOARD);
        this.gTagService.sendEvent(GA_NEW.HOME_MY_DASHBOARD);
        this.router.navigate(['dashboard']);
    }

    goToContactSales() {
        this.router.navigate(['contactsales']);
    }

    // Accessibility methods
    setFocusOnElement(focusElement) {
        setTimeout(() => {
            const element: any = document.getElementsByClassName(focusElement)[0];
            if (element) {
                element?.focus();
            }
        }, 100);
    }

    handleKeyboardShortcuts(event: EventData) {
        switch (event.data) {
            case KEYBOARD_CONSTANT.OPEN_JOIN_MEETING:
                this.isAuthenticated ? this.goToJoinMeeting() : this.goToStartMeeting();
                break;
            case KEYBOARD_CONSTANT.OPEN_START_MEETING:
                this.isAuthenticated ? this.goToMeetingHost() : this.goToStartMeeting();
                break;
            case KEYBOARD_CONSTANT.OPEN_SCHEDULE_MEETING:
                this.isAuthenticated ? this.openPlanPopup() : this.goToPlanMeeting();
                break;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
