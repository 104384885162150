import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { AuthService, GoogleTagManagerService } from 'src/app/core';
import { GA_EVENTS } from '../ga-events';

@Component({
    selector: 'app-activation-link-expiry',
    templateUrl: './activation-link-expiry.component.html',
    styleUrls: ['./activation-link-expiry.component.scss']
})
export class ActivationLinkExpiryComponent implements OnInit {
    private email;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toastrService: ToastrService,
        private authService: AuthService,
        private gTagService: GoogleTagManagerService
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((queryParams) => {
            this.email = queryParams.email;
        });
        this.gTagService.sendEvent(GA_EVENTS.EMAIL_VERIFY_FAIL);
    }

    resendEmail() {
        this.authService.resendEmail(this.email).subscribe(
            () => {
                this.toastrService.info(
                    'We have sent a verification link to your registered Email. Please verify to login.'
                );
                this.gTagService.sendEvent(GA_EVENTS.RESEND_EMAIL_SUCCESS);
            },
            (err) => {
                this.toastrService.error(err.error?.errors || 'Resend failed. Please try again later.');
                this.gTagService.sendEvent(GA_EVENTS.RESEND_EMAIL_FAIL);
            }
        );
    }

    navigateToLogin() {
        this.router.navigate(['/login']);
    }
}
