import { trigger, state, style, transition, animate } from '@angular/animations';
import { Input } from '@angular/core';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as emjs from 'emoji.json';

@Component({
    selector: 'app-emoji-box',
    templateUrl: './emoji-box.component.html',
    styleUrls: ['./emoji-box.component.scss']
})
export class EmojiBoxComponent implements OnInit {
    @Output() onEmojiSelect: EventEmitter<any> = new EventEmitter();
    public emojis = (emjs as any).default;
    constructor() {}

    ngOnInit() {}

    selectEmoji(emoji) {
        this.onEmojiSelect.emit(emoji);
    }
}
