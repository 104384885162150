<section class="submenu-container">
    <main class="megamenu-submenu" [class.d-block]="parentMenu === 'useCases'">
        <div *ngFor="let menuGroup of subMenuData.menues" [class.dgrid]="parentMenu === 'useCases'">
            <label *ngIf="menuGroup?.label && menuGroup?.label !== ' '" tabindex="0">
                {{ menuGroup?.tlabel | translate }}
            </label>
            <label *ngIf="menuGroup?.label === ' '">&nbsp;</label>
            <ng-container *ngFor="let menuInfo of menuGroup?.data">
                <app-menu-item [menuInfo]="menuInfo" [lastItem]="false" [parentMenu]="parentMenu"></app-menu-item>
            </ng-container>
        </div>
    </main>
    <footer class="megamenu-submenu d-block">
        <label tabindex="0" aria-label="whats new">
            {{ 'website.whats_new' | translate }}
        </label>
        <div *ngFor="let menuGroup of subMenuData.whatsNew" class="dgrid">
            <ng-container *ngFor="let menuInfo of menuGroup?.data; let last = last">
                <app-menu-item [menuInfo]="menuInfo" [lastItem]="last" [parentMenu]="parentMenu"></app-menu-item>
            </ng-container>
        </div>
    </footer>
</section>
