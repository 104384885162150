import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService, CallService, GuestAuthService, RtcService, UserService, VideoWrapperService } from 'src/app/core';

@Component({
    selector: 'app-recorder-bot',
    templateUrl: './recorder-bot.component.html',
    styleUrls: ['./recorder-bot.component.scss']
})
export class RecorderBotComponent implements OnInit {
    meetingParams;
    currentUser;
    jmmediaWebpageStreamInfo: {
        streamUserToken: jmmediaWebpageStreamUserToken;
        streamType: jmmediaWebpageStreamType;
    };
    roomDetails: any;
    recorderId;
    videoService;
    headlessMode: boolean = false;
    constructor(
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private rtcService: RtcService,
        private appService: AppService,
        private videoWrapperService: VideoWrapperService,
        private guestAuthService: GuestAuthService,
        private callService: CallService
    ) {
        this.videoService = this.videoWrapperService.getVideoServiceForPreview();
    }

    ngOnInit(): void {
        this.currentUser = this.userService.getUserSync();
        this.activatedRoute.queryParams.subscribe((params) => (this.meetingParams = { ...params }));
        console.log('meetingParams', this.meetingParams);
        if (this.meetingParams.autoJoin && this.meetingParams.recorderToken) {
            this.headlessMode = true;
            this.currentUser.name = 'recorder';
            this.appService.setRecorderUser(true);
            this.recorderId = 9;
            this.userService.getRecorderId(this.recorderId);
        }
        if (this.meetingParams.autostop && this.meetingParams.autostopid) {
            this.appService.setAutoStopRecordingInfo({
                enabled: this.meetingParams.autostop === 'true',
                elementId: this.meetingParams.autostopid
            });
        }
        if (this.headlessMode) {
            this.join();
        }
    }

    async join(joinFromButton: boolean = false, isCallFromWaitingRoom?) {
        this.redirectToJioMeetMeeting(joinFromButton, isCallFromWaitingRoom);
    }

    async redirectToJioMeetMeeting(joinFromButton: boolean = false, isCallFromWaitingRoom?) {
        this.rtcService
            .getRoomDetailsGuest({
                extension: this.meetingParams.meetingId,
                pin: this.meetingParams.pwd,
                hash: this.meetingParams.hash,
                userId: this.currentUser._id,
                isAuthenticated: false,
                memberName: this.currentUser.name,
                recorderToken: this.meetingParams?.recorderToken,
                isLiveStreaming: this.meetingParams?.isLiveStreaming === 'true'
            })
            .subscribe(
                (res: any) => {
                    this.roomDetails = res;
                    this.appService.setMeetingId(res?.roomDetails?.meetingId);
                    this.rtcService.participantCount = res.speakerCount + 1;
                    this.rtcService.participantCountOnJoin = res.speakerCount + 1;
                    this.joinAs(res);
                },
                (err) => {
                    console.log('roomdetails error', err);
                }
            );
    }

    async joinAs(room) {
        if (room.roomDetails.mediaEngine === 'jm-media' || room.roomDetails.mediaEngine === 'pexip') {
            this.videoService?.stopPreviewTrack();
        }
        this.rtcService.setBreakoutRoomInfo(null);
        if (room.breakoutDetail) {
            this.rtcService.setBreakoutRoomInfo({
                ...room.breakoutDetail,
                breakoutRoomId: room.breakoutDetail._id
            });
        }
        this.guestAuthService
            .login({
                jiomeetId: room.roomDetails.jiomeetId,
                roomID: room.roomDetails.roomID,
                roomPin: room.roomDetails.roomPIN,
                name: this.currentUser.name + ' (Guest)',
                phoneNo: 'Guest',
                emailId: 'Guest',
                recorderToken: this.meetingParams?.recorderToken,
                isLiveStreaming: this.meetingParams?.isLiveStreaming === 'true'
            })
            .subscribe((res) => {
                this.userService.isGuestLogin(true);
                this.userService.setGuestUser(name);

                const commonConferenceInfo = {
                    isInitiater: false,
                    joiningName: this.currentUser.name + ' (Guest)',
                    room: room.roomDetails,
                    ownerDetails: room.ownerDetails,
                    hideMoreCallControls: this.meetingParams.hideMoreCallControls === 'true',
                    hideAllCallControls: this.meetingParams.hideAllCallControls === 'true',
                    isFullScreenShare: this.meetingParams.isFullScreenShare === 'true',
                    transferCall: false,
                    forBetaTesting: room?.forBetaTesting,
                    recorderToken: this.meetingParams?.recorderToken || null
                };
                const additionalConferenceInfo = this.videoWrapperService.getAdditionalConferenceInfo(room, true);
                const conferenceInfo = { ...commonConferenceInfo, ...additionalConferenceInfo };
                if (this.meetingParams?.isLiveStreaming === 'true') {
                    conferenceInfo['isLiveStreaming'] = true;
                }
                this.rtcService.setConferenceInfo(conferenceInfo);
                if (this.rtcService.getIsAgoraOrJmMeeting()) {
                    this.callService.initiateCall();
                }
            });
    }
}

interface jmmediaWebpageStreamUserToken {
    type: '';
    maxLength: 100;
    minLength: 1;
}
interface jmmediaWebpageStreamType {
    type: '';
    enum: ['recording', 'youtube', 'facebook', 'custom'];
}
