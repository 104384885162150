import { Injectable } from '@angular/core';

import { BehaviorSubject, from, Subject, throwError } from 'rxjs';
import { retryBackoff } from 'backoff-rxjs';
import AgoraRTM, { RtmChannel, RtmClient } from 'agora-rtm-sdk';

import { RtcService } from './rtc.service';
import { ExternalInterfaceService } from './external-interface.service';
import { UtilService } from './util.service';
import { UserService } from './user.service';
import { VIDYO_EVENTS, DEVICE_ERROR_CONSTANTS, APP_EVENTS } from 'src/app/constants';
import { VirtualBgService } from './virtual-bg.service';
import { AppService, AuthService, ThirdPartyExternalIntegrationService } from '.';
import { AppLoggerService } from './app-logger.service';
import { EventEmitterService } from './event-emitter.service';
import { SystemService } from './system.service';
import { DesktopAppService } from '.';

// import {
//   IAgoraRTCClient,
//   IMicrophoneAudioTrack,
//   ICameraVideoTrack
// } from 'agora-rtc-sdk-ng';

declare var VC: any;
declare const window;

enum ConnectionState {
    DISCONNECTED = 'DISCONNECTED',
    CONNECTING = 'CONNECTING',
    RECONNECTING = 'RECONNECTING',
    CONNECTED = 'CONNECTED',
    DISCONNECTING = 'DISCONNECTING',
    TOO_MANY_BROADCASTERS = 'CONNECTION_CHANGED_TOO_MANY_BROADCASTERS',
    ABORTED = 'ABORTED'
}

enum SignalStatus {
    NONE = 'NONE',
    GOOD = 'GOOD',
    BAD = 'BAD',
    VERYBAD = 'VERYBAD'
}

// declare var AgoraRTC: any;

@Injectable({
    providedIn: 'root'
})
export class AgoraService {
    vidyoConnector;
    AgoraRTC = null;
    libraryLoaded = false;
    isAudioPublished = false;
    isVideoPublished = false;

    localParticipant;
    joinData;
    reconnecting = false;
    isUserOnline = true;
    isAuthenticated = false;

    currentUser;
    users = {};
    vcs = {};
    microphoneMutedAll = false;
    roomLocked = false;
    recording = false;
    screenSharing = false;
    waitingUsers = {};
    cameras = [];
    microphones = [];
    speakers = [];
    selectedLocalCamera = 0;
    selectedLocalMicrophone = 0;
    selectedLocalSpeaker = 0;

    oldSelectedLocalCamera = 0;
    oldSelectedLocalMicrophone = 0;
    oldSelectedLocalSpeaker = 0;

    selectedLocalMicrophoneGroupId = 0;
    selectedLocalSpeakerGroupId = 0;

    private disconneting = false;
    private alreadySharingScreen = false;
    private windowShares = {};
    private whiteboardShared;
    private isOptimizeOnScreenSharingEnabled = false;

    private meeting$: BehaviorSubject<any> = new BehaviorSubject(null);
    private chatMessages$: BehaviorSubject<any> = new BehaviorSubject(null);
    private deviceChanged$: BehaviorSubject<any> = new BehaviorSubject(null);
    private participantsStatus$: BehaviorSubject<any> = new BehaviorSubject(null);

    private rtmMessages$: BehaviorSubject<any> = new BehaviorSubject(null);

    reConnectInterval;
    localVolumeIndicator;

    //acs variables

    MAX_VIDEO_TILES = 9;
    MAX_VIDEO_TILES_FOR_SQUARE_TILE_LAYOUT = 8;
    MAX_VIDEO_TILES_IN_SCREEN_SHARING = 3;
    MAX_VIDEO_TILES_IN_SCREEN_SHARING_FOR_SQUARE_TILE_LAYOUT = 4;

    INIT_INTERVAL_MS = 500;
    MAX_INTERVAL_MS = 36000 * 1000;

    SPEAKER_LIMIT = 25;
    AUDIENCE_LIMIT = 1000;

    $speakerLayoutSelected = new BehaviorSubject<boolean>(false);
    hideNonVideoSelected = false;
    $hideNonVideoSelected = new BehaviorSubject<boolean>(false);
    numberOfVideoTilesSelected;
    numberOfVideoTilesSelectedSubject = new Subject<number>();
    isConnected = new Subject<boolean>();
    isVideoOn = new BehaviorSubject<boolean>(false);

    videoDevicesUpdated = new Subject<boolean>();
    audioDevicesUpdated = new Subject<boolean>();

    localParticipantUpdated = new Subject<boolean>();
    participantAdded = new Subject<any>();
    participantRemoved = new Subject<any>();

    participantStatusUpdated = new Subject<any>();

    meetingObj;

    errors = new Subject<any>();

    roomData;
    isHandRaise = false;

    groupId = '';
    callOptionsInternal = {
        audioOptions: {
            isMuted: true,
            audioOutputDeviceId: '',
            audioInputDeviceId: ''
        },
        videoOptions: {
            isVideoOn: false,
            videoDeviceId: ''
        }
    };
    displayName = '';
    streams: any = [];
    streamsCollection = [];
    remoteParticipants = [];
    localVideoStream;
    customCollection = [];

    visibleParticipants = [];
    inVisibleParticipants = [];
    //agora variables
    agoraClient;
    agoraRtmClient: RtmClient;
    agoraRtmChannel: RtmChannel;
    localMicrophoneTrack: any;
    localCameraTrack: any = null;
    conferenceInfo;
    joiningRoom: boolean = false;
    joinedInRoom: boolean = false;
    showActiveSpeaker = false;

    agoraVideoProfiles = [
        '120p_1',
        '120p_3',
        '180p_1',
        '180p_3',
        '180p_4',
        '240p_1',
        '240p_3',
        '240p_4',
        '360p_1',
        '360p_3',
        '360p_4',
        '360p_6',
        '360p_7',
        '360p_8',
        '360p_9',
        '360p_10',
        '360p_11',
        '480p_1',
        '480p_2',
        '480p_3',
        '480p_4',
        '480p_6',
        '480p_8',
        '480p_9',
        '480p_10',
        '720p_1',
        '720p_2',
        '720p_3',
        '720p_5',
        '720p_6',
        '1080p_1',
        '1080p_2',
        '1080p_3',
        '1080p_5',
        '1440p',
        '1440p_1',
        '1440p_2',
        '4K_1',
        '4K_3'
    ];

    agoraBandwidthConfig = {
        video: {
            width: 840,
            height: 480,
            fps: 15,
            bitrate: 0,
            orientation: 0
        },
        screenShare: {
            width: 1920,
            height: 1080,
            fps: 5,
            bitrate: 0,
            orientation: 0,
            hdfps: 15,
            hdwidth: 1920,
            hdheight: 1080
        },
        lowQualityVideo: {
            width: 320,
            height: 180,
            fps: 10,
            bitrate: 140,
            orientation: 0
        }
    };

    screenClient: any;

    screenClientUid = 0;

    screenClientToken = '';

    roomInfo;

    currentSenderId = '';

    currentUid = 0;
    currentRoomID = '';
    currentRtmToken = '';

    localScreenTrack;
    localScreenAudioTrack;

    isBreakoutRoom = false;

    noMediaPermission = false;
    noAudioPermission = false;
    noCameraPermission = false;

    leaveBreakoutRoom = false;
    agoraToken = '';

    isPostionChecked = false;

    activeSpeakerList = [];

    breakoutRoomInfo;

    localCameraOn = true;
    localMicOn = true;
    isScreenShareAvaiable = false;
    microphonesData;
    speakersData;
    isFitToScreen = true;
    currentConnectionState;
    previousConnectionState;

    reconnectPopupTimer;

    canJoinAsSpeaker = false;

    currentSpeakerCount = 0;

    showedLimitReached = true;

    showHDScreenShareOption = false;
    isEnableHDshare = false;

    presentorLayout = false;
    remoteScreenSharingStream;
    waitUntilRoomMove: boolean = false;
    hideScreenShareNotification = new Subject<boolean>();
    isVirtualBackgroundApplied = false;
    audioLevel = new Subject();
    noAudioPermissionBrowser: boolean;
    noAudioPermissionDevice: boolean;
    signalStrengthData = {
        signal: SignalStatus.NONE
    };
    counterForGoodSignal = 0;
    counterForBadSignal = 0;
    counterForVeryBadSignal = 0;
    processingToggleMic: boolean = false;
    processingToggleCamera: boolean = false;
    callInProgress: boolean = false;
    virtualBackgroundExtension;
    processor = null;
    currentProcessorOption: any = null;
    denoiserExt;
    denoiser;
    processeDenoiser;
    denoiserEnabled: boolean;
    selectedCameraBeforeFlip = null;

    constructor(
        private rtcService: RtcService,
        private utilService: UtilService,
        private externalInterfaceService: ExternalInterfaceService,
        private vbService: VirtualBgService,
        private userService: UserService,
        private appService: AppService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        private appLoggerService: AppLoggerService,
        private eventEmitterService: EventEmitterService,
        private authService: AuthService,
        private systemService: SystemService
    ) {}

    getMeetingObs() {
        return this.meeting$;
    }

    getChatMessages() {
        return this.chatMessages$;
    }

    getDeviceCahnge() {
        return this.deviceChanged$;
    }

    getParticipantStatus() {
        return this.participantsStatus$;
    }

    getRtmMessages() {
        return this.rtmMessages$;
    }

    intializeValue = () => {
        this.users = {};
        this.cameras = [];
        this.microphones = [];
        this.speakers = [];
        this.selectedLocalCamera = 0;
        this.selectedLocalMicrophone = 0;
        this.selectedLocalSpeaker = 0;
        this.disconneting = false;
        this.reconnecting = false;
    };

    isBehindProxy() {
        var proxyHeader = 'via';
        var req = new XMLHttpRequest();
        // req.open('GET', '/api/roomstatus', false); // for local
        req.open('GET', this.appService.getEnvVariable('HOST_URL'), false); // for production
        req.send();
        var header = req.getResponseHeader(proxyHeader);
        if (header) {
            console.log('is behind proxy');
            // we are on a proxy
            return true;
        }
        return false;
    }

    async initializeAgoraRTC() {
        return import('agora-rtc-sdk-ng').then(async (m) => {
            this.AgoraRTC = m.default;
        });
    }

    setRecordingStatus(isRecording) {
        this.recording = isRecording;
        this.sendControlStatus();
    }

    async initializeVidyoConnector(ignoreJoinCall?) {
        if (this.AgoraRTC === null) {
            await this.initializeAgoraRTC();
        }

        this.currentUser = this.userService.getUserSync();
        (window as any).agoraRTC = this.AgoraRTC;

        const ENABLE_LOGS_UPLOAD = this.appService.getConfigVariable('ENABLE_LOGS_UPLOAD_AGORA');
        if (ENABLE_LOGS_UPLOAD) {
            this.AgoraRTC.enableLogUpload();
        }

        // Set the log output level as INFO
        this.AgoraRTC.setLogLevel(4);
        const EVENT_REPORT_SEND_INTERVAL = this.appService.getConfigVariable('AGORA_EVENT_REPORT_SEND_INTERVAL');
        if (EVENT_REPORT_SEND_INTERVAL) {
            this.AgoraRTC.setParameter('EVENT_REPORT_SEND_INTERVAL', EVENT_REPORT_SEND_INTERVAL);
        }
        const areaCode = this.rtcService?.getConferenceInfo()?.agoraAreaCode;
        if (areaCode) {
            this.AgoraRTC.setArea({
                areaCode: areaCode
            });
        } //removing geofencing
        this.agoraClient = this.AgoraRTC.createClient({ codec: 'vp8', mode: 'live' });
        (window as any).client = this.agoraClient;
        var enableCloudProxy = this.appService.getConfigVariable('ENABLE_AGORA_CLOUD_PROXY');
        var cloudProxyValue = this.appService.getConfigVariable('CLOUD_PROXY_VALUE')
            ? this.appService.getConfigVariable('CLOUD_PROXY_VALUE')
            : 5;

        if (enableCloudProxy && this.isBehindProxy()) {
            // 3 is for udp cloud proxy
            this.agoraClient.startProxyServer(
                this.thirdPartyExternalIntegrationService.setProxyServer !== 0
                    ? this.thirdPartyExternalIntegrationService.setProxyServer
                    : cloudProxyValue
            );
        }
        if (this.joinData.isInitiater) {
            await this.setParticipantRole('host');
            this.externalInterfaceService.sendUserStatus(false);
        } else {
            await this.setParticipantRole('audience');
        }

        try {
            this.showHDScreenShareOption = this.conferenceInfo.agoraBandwidthConfig.screenShare.ishdsharingenabled;
        } catch (e) {
            console.log('unable to get config');
            this.externalInterfaceService.sendErrorOccuredOnJoined('exception', e?.message);
        }
        this.agoraClient
            .enableDualStream()
            .then(() => {
                console.log('Enable Dual stream success!');
                const lowQualityVideoProfile = this.getLowQualityVideoProfile();
                this.agoraClient.setLowStreamParameter(lowQualityVideoProfile);
            })
            .catch((e) => {
                this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                    message: e?.message,
                    code: e?.code,
                    area: DEVICE_ERROR_CONSTANTS.enable_dual_stream
                });
            })
            .finally(async () => {
                if (
                    this.externalInterfaceService.askPermission &&
                    !this.externalInterfaceService.isEmbibe &&
                    !this.appService.getRecorderUser()
                ) {
                    await this.updateAllDevices();
                    this.onDeviceChangeListener();
                } else {
                    this.noMediaPermission = true;
                }
                this.meeting$.next({ type: 'VC_CREATED', data: null });

                if (!!this.rtcService.getConferenceInfo() && !ignoreJoinCall) {
                    this.joinRoom();
                }
                this.meeting$.next({ type: 'SHOW_PREVIEW_CNTROLS', data: null });
            });

        if (this.thirdPartyExternalIntegrationService.enableManualTurnServer) {
            this.agoraClient.setTurnServer({
                turnServerURL: 'turns:turn-34-100-205-199.jiomeetcloud.com:443?transport=tcp',
                password: 'stunpassword',
                username: 'webrtc'
            });
        }
        // });
    }

    async setParticipantRole(role) {
        if (role == 'audience') {
            await this.agoraClient.setClientRole(role);
            return;
        }

        const isHostLimitIncreased = this.appService.getConfigVariable('isHostLimitIncreased');
        const source = from(this.agoraClient.setClientRole(role));
        source
            .pipe(
                retryBackoff({
                    initialInterval: this.INIT_INTERVAL_MS,
                    maxInterval: this.MAX_INTERVAL_MS,
                    shouldRetry: (error) => {
                        if (!isHostLimitIncreased) {
                            return true;
                        }
                        return false;
                    }
                })
            )
            .subscribe((val) => {
                console.log('Agora client role changed:', role);
                return;
            });
    }

    async requestPermission() {
        if (this.noMediaPermission && !this.appService.getRecorderUser()) {
            await this.updateAllDevices();
            this.onDeviceChangeListener();
            if (this.localParticipant?.participantType === 'speaker') {
                this.streamCreator();
            }
        }
    }

    flipCamera(togglePreviousCamera = false) {
        if (this.cameras.length <= 1) {
            this.externalInterfaceService.sendOnlyOneCameraAvailableError();
            return;
        }
        const selectedCamera = this.selectedLocalCamera;
        let newCameraId;
        if (!this.selectedCameraBeforeFlip && togglePreviousCamera) {
            this.selectedCameraBeforeFlip = this.selectedLocalCamera;
        } else if (this.selectedCameraBeforeFlip && togglePreviousCamera) {
            if (this.cameras.length > 1) {
                for (let i = 0; i < this.cameras.length; i++) {
                    if (this.cameras[i].id === this.selectedCameraBeforeFlip) {
                        newCameraId = this.cameras[i];
                    }
                }
                this.selectedCameraBeforeFlip = this.selectedLocalCamera;
                if (newCameraId) {
                    this.manuallyChangeCamera(newCameraId);
                } else {
                    this.manuallyChangeCamera(this.cameras[0]);
                }
            }

            return;
        }
        if (this.cameras.length > 1) {
            for (let i = 0; i < this.cameras.length; i++) {
                if (this.cameras[i].id === selectedCamera) {
                    if (i + 1 >= this.cameras.length) newCameraId = this.cameras[0];
                    else newCameraId = this.cameras[i + 1];
                }
            }

            if (newCameraId) {
                this.manuallyChangeCamera(newCameraId);
            } else {
                this.manuallyChangeCamera(this.cameras[0]);
            }
        }
    }

    /**
     * Switches between front and rear camera .
     *
     * @param {boolean} switchToFront - A boolean indicating whether to switch to the front camera (true) or the rear camera (false).
     *
     * @returns {Promise<void>} - A promise that resolves to a void object if the camera switch is successful, or null if it fails.
     *
     * This function allows switching between the front and rear cameras . It first checks if the local camera track is available, and if so, attempts to switch the camera.
     * If the camera switch is successful, the function resolves the promise; otherwise, it logs an error message and attempts to revert to the previous camera.
     */

    async switchToFrontCamera(switchToFront = true) {
        if (this.localCameraTrack) {
            try {
                // Get the current facing mode of the camera
                const currentFacingMode = this.localCameraTrack.getMediaStreamTrackSettings().facingMode;
                this.externalInterfaceService.sendSwitchCameraLog(`current facing mode is ${currentFacingMode}`);

                // Check if the camera switch is unnecessary (already in the desired mode)
                if (
                    (currentFacingMode === 'user' && switchToFront) ||
                    (currentFacingMode == 'environment' && !switchToFront)
                ) {
                    this.externalInterfaceService.sendSwitchCameraLog(
                        `Already facing ${switchToFront ? 'front' : 'back'} camera`
                    );
                    this.externalInterfaceService.sendSwitchCameraLog(
                        switchToFront ? 'FRONT_SWITCH_FAILED' : 'BACK_SWITCH_FAILED'
                    );
                    return;
                }
                this.externalInterfaceService.sendSwitchCameraLog('unpublishing current local tracks');
                // Unpublish and disable the current camera track
                await this.localCameraTrack.setEnabled(false);
                await this.agoraClient.unpublish([this.localCameraTrack]);

                this.externalInterfaceService.sendSwitchCameraLog(
                    `creating new camera video track of ${switchToFront ? 'front camera' : 'back camera'} `
                );
                // Create a new camera video track based on the desired mode
                const videoTrack = await this.AgoraRTC.createCameraVideoTrack({
                    encoderConfig: this.getAgoraVideoProfile(true),
                    facingMode: { exact: switchToFront ? 'user' : 'environment' }
                });

                this.externalInterfaceService.sendSwitchCameraLog(`closing previous camera track`);
                // Close the previous camera track and set the new one
                await this.localCameraTrack.close();
                this.localCameraTrack = videoTrack;

                this.externalInterfaceService.sendSwitchCameraLog(`playing and publishing new track`);
                // Play the new camera track and publish it
                await this.localCameraTrack.play('localVideo', { fit: 'cover' });
                await this.agoraClient.publish([this.localCameraTrack]);
                this.externalInterfaceService.sendSwitchCameraLog(
                    switchToFront ? 'FRONT_SWITCH_SUCCESS' : 'BACK_SWITCH_SUCCESS'
                );
            } catch (e) {
                // revert to the previous camera state in case of errors
                this.externalInterfaceService.sendSwitchCameraLog(
                    switchToFront ? 'FRONT_SWITCH_FAILED' : 'BACK_SWITCH_FAILED'
                );
                this.externalInterfaceService.sendSwitchCameraLog(`Error:- ${e}`);
                try {
                    this.externalInterfaceService.sendSwitchCameraLog(`republishing local track`);
                    await this.localCameraTrack.setEnabled(true);
                    await this.agoraClient.publish([this.localCameraTrack]);
                } catch (e) {
                    this.externalInterfaceService.sendSwitchCameraLog(`Failed to republish track Error:- ${e}`);
                }
            }
        }
    }

    /**

     * This function is combine implementation of switchCameraByCameraLabel() and switchToFrontCamera()
     *
     * @returns {Promise<void>} - A promise that resolves to a void object if the camera switch is successful, or null if it fails.
     *
     */
    async switchCamera() {
        if (this.localCameraTrack) {
            const currentFacingMode = this.localCameraTrack.getMediaStreamTrackSettings().facingMode;
            this.externalInterfaceService.sendSwitchCameraLog(`current facing mode is ${currentFacingMode}`);
            if (currentFacingMode == 'environment') {
                const frontCameras = this.cameras.filter((device) => this.isFrontCamera(device.name));
                if (!frontCameras.length) {
                    this.externalInterfaceService.sendSwitchCameraLog(`No front camera by label`);
                    // If no front camera is available, fall back to switchToFrontCamera
                    await this.switchToFrontCamera(true);
                } else {
                    this.externalInterfaceService.sendSwitchCameraLog(`switching to front camera`);
                    await this.manuallyChangeCamera(frontCameras[0]);
                    this.externalInterfaceService.sendSwitchCameraLog(`FRONT_SWITCH_SUCCESS`);
                }
            } else {
                const backCameras = this.cameras.filter((device) => this.isBackCamera(device.name));
                if (!backCameras.length) {
                    this.externalInterfaceService.sendSwitchCameraLog(`No back camera by label`);
                    // If no rear camera is available, fall back to switchToFrontCamera
                    await this.switchToFrontCamera(false);
                } else {
                    this.externalInterfaceService.sendSwitchCameraLog(`switching to back camera`);
                    await this.manuallyChangeCamera(backCameras[0]);
                    this.externalInterfaceService.sendSwitchCameraLog(`BACK_SWITCH_SUCCESS`);
                }
            }
        }
    }

    isFrontCamera(name) {
        //null camera name case handled
        if (!name.length) {
            return false;
        }
        const frontCameraPatterns = ['front', 'selfie', 'user']; // Add more patterns if necessary
        const lowercaseLabel = name.toLowerCase();
        return frontCameraPatterns.some((pattern) => lowercaseLabel.includes(pattern));
    }

    isBackCamera(name) {
        //null camera name case handled
        if (!name.length) {
            return false;
        }
        const backCameraPatterns = ['back', 'rear', 'environment']; // Add more patterns if necessary
        const lowercaseLabel = name.toLowerCase();
        return backCameraPatterns.some((pattern) => lowercaseLabel.includes(pattern));
    }

    setJoinData({
        host,
        displayName,
        roomKey,
        roomPin,
        micPrivacy,
        cameraPrivacy,
        isInitiater = false,
        viewId = 'localVideo',
        isEmbedInMobile = false,
        unlockAndJoin = false,
        meetingObj = null,
        userId = ''
    }) {
        this.joinData = {
            host,
            displayName,
            roomKey,
            roomPin,
            micPrivacy,
            cameraPrivacy,
            isInitiater,
            viewId,
            isEmbedInMobile,
            unlockAndJoin,
            meetingObj,
            userId
        };
    }

    userTalkListener(callback): void {}

    updateAllDevices() {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.AgoraRTC.getCameras().catch((errorInfo) => {
                    return {
                        hasError: true,
                        error: errorInfo
                    };
                }),
                this.AgoraRTC.getMicrophones().catch((errorInfo) => {
                    return {
                        hasError: true,
                        error: errorInfo
                    };
                }),
                this.AgoraRTC.getPlaybackDevices().catch((errorInfo) => {
                    return {
                        hasError: true,
                        error: errorInfo
                    };
                })
            ])
                .then((data) => {
                    if (data[0]?.hasError) {
                        console.log('Unable to load Camera', data[0]?.error);
                        if (data[0]?.error?.code === 'PERMISSION_DENIED') {
                            this.noCameraPermission = true;
                        }
                    } else {
                        this.cameras = data[0];
                    }
                    this.videoDevicesUpdated.next(true);
                    if (data[1]?.hasError) {
                        console.log('Unable to load Michrophone', data[1]?.error);
                        if (data[1]?.error?.code === 'PERMISSION_DENIED') {
                            this.noAudioPermission = true;
                        }
                    } else {
                        this.microphonesData = data[1];
                        this.microphones = [];
                        this.microphonesData.forEach((microphone) => {
                            if (this.isValidMediaDevice(microphone)) {
                                this.microphones.push(microphone);
                            }
                        });
                    }
                    if (data[2]?.hasError) {
                        console.log('Unable to load Playback Devices', data[2]?.error);
                    } else {
                        this.speakersData = data[2];
                        this.speakers = [];
                        this.speakersData.forEach((speaker) => {
                            if (this.isValidMediaDevice(speaker)) {
                                this.speakers.push(speaker);
                            }
                        });
                    }
                    this.audioDevicesUpdated.next(true);

                    this.cameras = this.mapDeviceCollection(this.cameras);
                    this.microphones = this.mapDeviceCollection(this.microphones);
                    this.speakers = this.mapDeviceCollection(this.speakers);
                    this.noMediaPermission = false;
                    this.sendControlStatus();
                    this.selectDevices();
                })
                .catch((e) => {
                    this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                        message: e?.message,
                        code: e?.code,
                        area: DEVICE_ERROR_CONSTANTS.asking_device_permission
                    });
                    this.noMediaPermission = true;
                    resolve();
                })

                .finally(() => {
                    resolve();
                });
        });
    }

    onDeviceChangeListener() {
        this.AgoraRTC.onCameraChanged = async (info) => {
            this.cameras = this.mapDeviceCollection(await this.AgoraRTC.getCameras());

            if (info.state === 'INACTIVE') {
                if (this.cameras.length > 0) {
                    if (String(this.selectedLocalCamera) === info.device.deviceId) {
                        this.oldSelectedLocalCamera = this.selectedLocalCamera;
                        this.selectedLocalCamera = this.cameras[0].id;
                        await this.localCameraTrack.setDevice(String(this.selectedLocalCamera));
                    }
                } else {
                    this.oldSelectedLocalCamera = this.selectedLocalCamera;
                    this.toggleCameraPrivacy(true);
                }
            }

            if (info.state === 'ACTIVE') {
                this.selectedLocalCamera = info.device.deviceId;
                await this.localCameraTrack.setDevice(String(this.selectedLocalCamera));
            }
            this.sendControlStatus();
        };

        this.AgoraRTC.onMicrophoneChanged = async (info) => {
            this.microphones = [];
            this.microphonesData = this.mapDeviceCollection(await this.AgoraRTC.getMicrophones());
            this.microphonesData.forEach((microhpone) => {
                if (this.isValidMediaDevice(microhpone)) {
                    this.microphones.push(microhpone);
                }
            });

            if (info.state === 'INACTIVE') {
                if (this.microphones.length > 0) {
                    if (String(this.selectedLocalMicrophone) === info.device.deviceId) {
                        this.oldSelectedLocalMicrophone = this.selectedLocalMicrophone;
                        let selectedLocalMicrophoneObject = this.microphones.find(
                            (microphone) => microphone.groupId === this.selectedLocalSpeakerGroupId
                        );
                        if (selectedLocalMicrophoneObject) {
                            this.selectedLocalMicrophoneGroupId = selectedLocalMicrophoneObject?.groupId;
                            this.selectedLocalMicrophone = selectedLocalMicrophoneObject?.id;
                        } else {
                            this.selectedLocalMicrophone = this.microphones[0]?.id;
                            this.selectedLocalMicrophoneGroupId = this.microphones[0]?.groupId;
                        }
                    }
                    await this.localMicrophoneTrack?.setDevice(String(this.selectedLocalMicrophone));
                } else {
                    this.oldSelectedLocalMicrophone = this.selectedLocalMicrophone;
                    this.toggleMicPrivacy(true);
                }
            }

            if (info.state === 'ACTIVE') {
                if (!this.isVirtualDevice(info.device.label)) {
                    this.selectedLocalMicrophone = info.device.deviceId;
                    this.selectedLocalMicrophoneGroupId = info.device.groupId;
                    await this.localMicrophoneTrack?.setDevice(String(this.selectedLocalMicrophone));
                }
            }

            this.sendControlStatus();
        };

        this.AgoraRTC.onPlaybackDeviceChanged = async (info) => {
            this.speakers = [];
            this.speakersData = this.mapDeviceCollection(await this.AgoraRTC.getPlaybackDevices());
            this.speakersData.forEach((speaker) => {
                if (this.isValidMediaDevice(speaker)) {
                    this.speakers.push(speaker);
                }
            });

            if (info.state === 'INACTIVE') {
                if (this.speakers.length > 0) {
                    if (String(this.selectedLocalSpeaker) === info.device.deviceId) {
                        this.oldSelectedLocalSpeaker = this.selectedLocalSpeaker;
                        let selectedLocalSpeakerObject = this.speakers.find(
                            (speaker) => speaker.groupId === this.selectedLocalMicrophoneGroupId
                        );
                        if (selectedLocalSpeakerObject) {
                            this.selectedLocalSpeakerGroupId = selectedLocalSpeakerObject?.groupId;
                            this.selectedLocalSpeaker = selectedLocalSpeakerObject?.id;
                        } else {
                            this.selectedLocalSpeaker = this.speakers[0]?.id;
                            this.selectedLocalSpeakerGroupId = this.speakers[0]?.groupId;
                        }
                        // this.localMicrophoneTrack.setPlaybackDevice(String(this.selectedLocalSpeaker));
                    }
                    this.updateAgoraClientSpeakerOut(String(this.selectedLocalSpeaker));
                } else {
                    this.oldSelectedLocalSpeaker = this.selectedLocalSpeaker;
                    //this.toggleMicPrivacy(true);
                }
            }

            if (info.state === 'ACTIVE') {
                if (!this.isVirtualDevice(info.device.label)) {
                    this.selectedLocalSpeaker = info.device.deviceId;
                    this.selectedLocalSpeakerGroupId = info.device.groupId;
                    this.updateAgoraClientSpeakerOut(String(this.selectedLocalSpeaker));
                }
            }
            this.sendControlStatus();
        };
    }

    isValidMediaDevice(device) {
        //disabling default communication check to go back to previous state
        return true;

        return !(
            device?.deviceId === 'default' ||
            device?.deviceId === 'communications' ||
            device?.id === 'default' ||
            device?.id === 'communications'
        );
    }

    mapDeviceCollection(arr) {
        return arr
            .map((device) => {
                return { ...device, id: device.deviceId, name: device.label, groupId: device.groupId };
            })
            .sort((device1, device2) => {
                let isDevice1Virtual = device1?.name?.toLowerCase().endsWith('(virtual)');
                let isDevice2Virtual = device2?.name?.toLowerCase().endsWith('(virtual)');
                if (isDevice1Virtual && !isDevice2Virtual) {
                    return 1;
                } else if (!isDevice1Virtual && isDevice2Virtual) {
                    return -1;
                } else return 0;
            });
    }

    isVirtualDevice(deviceName) {
        return deviceName?.toLowerCase().endsWith('(virtual)');
    }

    reConnect() {
        clearInterval(this.reConnectInterval);
        this.joinData.micPrivacy = !this.getLocalParticipant().microphoneMute;
        this.joinData.cameraPrivacy = !this.getLocalParticipant().cameraMute;
        this.joinRoom();

        this.reConnectInterval = setInterval(() => {
            this.joinRoom();
        }, 5 * 1000);
    }

    reConnectOnConnectionOnline() {
        this.joinData.micPrivacy = !this.getLocalParticipant().microphoneMute;
        this.joinData.cameraPrivacy = !this.getLocalParticipant().cameraMute;
        this.joinRoom();
    }

    async joinRoom() {
        if (this.joiningRoom || this.joinedInRoom) {
            let err = new Error('error while joining into the room');
            err.message = `agoraAppId: ${this.conferenceInfo.agoraAppId},
            currentRoomId:${this.currentRoomID},
            agoraToken:${this.agoraToken},
            currentUid:${this.currentUid}`;
            this.appLoggerService.debug('Duplicate call to JoinRoom', err);
            return;
        }
        let roomID,
            agoraToken = '';
        let agoraUid = 0;
        if (!this.agoraClient) return;

        // reset publish flag
        this.isAudioPublished = false;
        this.isVideoPublished = false;

        const breakOutRoomInfo = this.rtcService.getBreakoutRoomInfo();
        this.conferenceInfo = this.rtcService.getConferenceInfo();
        if (this.conferenceInfo.agoraBandwidthConfig?.lowQualityVideo?.isOptimizeOnScreenSharingEnabled) {
            this.isOptimizeOnScreenSharingEnabled =
                this.conferenceInfo.agoraBandwidthConfig?.lowQualityVideo?.isOptimizeOnScreenSharingEnabled;
        }

        const roomInfo = this.rtcService.getRoomInfo();
        const isRILUser = this.currentUser.email ? this.currentUser.isRil : false;
        const isHostLimitIncreased = this.appService.getConfigVariable('isHostLimitIncreased');

        if (isRILUser && isHostLimitIncreased) {
            if (!isNaN(Number(this.conferenceInfo.agoraMaxParticipantsForRIL))) {
                this.SPEAKER_LIMIT = Number(this.conferenceInfo.agoraMaxParticipantsForRIL);
            }
        } else {
            if (!isNaN(Number(this.conferenceInfo.agoraMaxParticipants))) {
                this.SPEAKER_LIMIT = Number(this.conferenceInfo.agoraMaxParticipants);
            }
        }

        if (!isNaN(Number(this.conferenceInfo?.maxAudienceCount))) {
            this.AUDIENCE_LIMIT = Number(this.conferenceInfo?.maxAudienceCount);
        }

        if (this.leaveBreakoutRoom) {
            //when leaving breakoutroom
            agoraToken = this.agoraToken;
            this.currentRoomID = roomInfo.roomID;
            if (this.currentSpeakerCount < this.SPEAKER_LIMIT) {
                this.canJoinAsSpeaker = true;
            } else {
                this.canJoinAsSpeaker = false;
            }
            if (roomInfo.roomStatus === 'Locked') {
                this.meeting$.next({
                    type: 'FAILURE',
                    data: {
                        reason: 'Meeting room is locked.',
                        code: 'LOCKED'
                    }
                });
                return;
            }
            this.leaveBreakoutRoom = false;
            this.isBreakoutRoom = false;
        } else {
            if (breakOutRoomInfo) {
                //joing breakout room and joining breakout room from start
                this.isBreakoutRoom = true;
                if (breakOutRoomInfo.agoraUid) {
                    this.currentUid = breakOutRoomInfo?.agoraUid || this.conferenceInfo.agoraUid;
                    agoraToken = breakOutRoomInfo?.agoraToken || this.conferenceInfo.agoraToken;
                    this.currentRoomID = breakOutRoomInfo?.roomInfo.roomID || roomInfo.roomID;
                } else {
                    this.currentUid = this.conferenceInfo.agoraUid;
                    agoraToken = this.conferenceInfo.agoraToken;
                    this.currentRoomID = roomInfo.roomID;
                }
            } else {
                //joining main room
                this.isBreakoutRoom = false;
                this.currentUid = this.conferenceInfo.agoraUid;
                agoraToken = this.conferenceInfo.agoraToken;
                this.currentRoomID = roomInfo.roomID;
            }

            if (this.conferenceInfo?.agoraSpeakerCount < this.SPEAKER_LIMIT && !this.appService.getRecorderUser()) {
                this.canJoinAsSpeaker = true;
            } else {
                this.canJoinAsSpeaker = false;
            }
        }

        if (
            this.breakoutRoomInfo?.breakoutRoomId ||
            (this.canJoinAsSpeaker && !this.externalInterfaceService.isEmbibe && !this.appService.getRecorderUser())
        ) {
            await this.setParticipantRole('host');
        }

        this.joiningRoom = true;
        this.addEventListener();
        await this.agoraClient
            .join(this.conferenceInfo.agoraAppId, this.currentRoomID, agoraToken, this.currentUid)
            .then(async () => {
                let participant = {
                    id: String(this.agoraClient.uid),
                    userId: String(this.agoraClient.uid),
                    name: this.joinData.displayName,
                    isLocal: true,
                    isHost: this.joinData.isInitiater ? true : false,
                    microphoneMute: !this.joinData.micPrivacy,
                    cameraMute: !this.joinData.cameraPrivacy,
                    participantType: this.joinData.isInitiater ? 'speaker' : 'audience'
                };

                if (
                    this.breakoutRoomInfo?.breakoutRoomId ||
                    (this.canJoinAsSpeaker && !this.externalInterfaceService.isEmbibe)
                ) {
                    participant.participantType = 'speaker';
                    this.setParticipantRole('host');
                    this.externalInterfaceService.sendUserStatus(false);
                }

                if (participant.participantType === 'audience') {
                    participant.cameraMute = true;
                    participant.microphoneMute = true;
                    this.setParticipantRole('audience');
                    this.externalInterfaceService.sendUserStatus(true);
                }

                this.localParticipant = participant;

                // this.meeting$.next({ type: 'VC_CREATED', data: null });

                this.meeting$.next({ type: 'ACTIVE', data: null });

                setTimeout(
                    () =>
                        this.meeting$.next({
                            type: 'LOCAL_PARTICIPANT_CONNECTED',
                            data: null
                        }),
                    200
                );

                this.isUserOnline = true;
                this.joinedInRoom = true;
                this.joiningRoom = false;

                this.waitUntilRoomMove = false;
                this.callInProgress = true;
                this.setNumberOfVideoTiles();
            });

        this.remoteParticipants = this.agoraClient.remoteUsers;
        this.updateRemoteParticipants();
        this.agoraClient.enableAudioVolumeIndicator();
        this.initRTM();
        if (this.localParticipant.isHost || this.breakoutRoomInfo?.breakoutRoomId || this.canJoinAsSpeaker) {
            await this.streamCreator();
        }
    }

    setNumberOfVideoTiles() {
        const isRILUser = this.currentUser.email ? this.currentUser.isRil : false;
        this.numberOfVideoTilesSelected = this.utilService.getMaxTiles(this.currentUser);
    }

    addEventListener() {
        this.agoraClient.on('user-published', async (user, mediaType) => {
            const uid = user.uid;
            if (this.isScreenShareUid(user.uid) && this.screenClientUid === user.uid) {
                return;
            }
            // await this.agoraClient.subscribe(user, mediaType);
            // If the subscribed track is an audio track
            if (mediaType === 'audio') {
                await this.agoraClient.subscribe(user, mediaType);
                const audioTrack = user.audioTrack;
                // Play the audio
                audioTrack.play();

                await audioTrack.setPlaybackDevice(String(this.selectedLocalSpeaker)).catch((e) => {
                    this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                        message: e?.message,
                        code: e?.code,
                        area: DEVICE_ERROR_CONSTANTS.audio_stream_playback
                    });
                });
            } else {
                if (this.isScreenShareUid(user.uid)) {
                    if (this.screenSharing) {
                        await this.toggleWindowShare(false);
                    }
                    this.setRemoteStreamType(1);
                    //await this.agoraClient.subscribe(user, mediaType);
                    this.remoteScreenSharingStream = user;
                    this.participantStatusUpdated.next({ type: 'screenSharingStarted', data: user });
                    this.isScreenShareAvaiable = true;

                    this.isOptimizeOnScreenSharingEnabled && (await this.updateVideoConfig(1));
                } else {
                    this.participantStatusUpdated.next({ type: 'userStreamPublished', data: user });
                }
            }
        });

        this.agoraClient.on('user-joined', (user) => {
            if (!this.isScreenShareUid(user.uid)) {
                // Changed from 2 to 1, Automatically subscribe to the low-quality video stream under poor network conditions
                this.agoraClient.setStreamFallbackOption(
                    user.uid,
                    this.appService.getConfigVariable('streamFallBackEnabled') ? 1 : 2
                );
                this.participantAdded.next(user);
                this.updateRemoteParticipants();
                if (this.rtcService.isChimeAudioEnabledForHost()) {
                    this.sendControlStatus({ participantJoined: true });
                }
            }
        });

        this.agoraClient.on('user-left', (user) => {
            this.participantRemoved.next(user);
            if (this.isScreenShareUid(user.uid)) {
                // this.setRemoteStreamType(0);
                this.meeting$.next({ type: 'CLEAR_SCREENSHARE_INFO', data: {} });
                this.isScreenShareStreamAvaiable();
                this.participantStatusUpdated.next({ type: 'screenSharingStopped', data: {} });
            }
            this.updateRemoteParticipants();
            if (this.rtcService.isChimeAudioEnabledForHost()) {
                this.sendControlStatus({ participantLeft: true });
            }
        });
        this.agoraClient.on('user-unpublished', async (user, mediaType) => {
            const uid = user.uid;
            // if (!user.hasAudio) {
            //     this.setAudioLevelToRemoteParticipant(uid, 0);
            // }
            if (this.isScreenShareUid(user.uid)) {
                // set local participant to high quality again
                this.isOptimizeOnScreenSharingEnabled && (await this.updateVideoConfig(0));
            }
            this.participantStatusUpdated.next({ type: 'userStreamUnpublished', data: user });
        });

        this.agoraClient.on('volume-indicator', (volumes) => {
            // console.table(volumes);
            this.showActiveSpeaker = false;
            volumes.forEach((volume, index) => {
                if (volume.level > 4) {
                    this.activeSpeakerListCreation(volume.uid, volume.level);
                }

                if (volume.uid != this.localParticipant.id) {
                    if (!this.isScreenShareUid(volume.uid)) {
                        if (volume.level > 4) {
                            this.setAudioLevelToRemoteParticipant(volume.uid, volume.level);
                            this.showActiveSpeaker = true;
                        }
                    }
                } else {
                    this.audioLevel.next(volume);
                }
            });
        });

        // interval to share local user volume details
        this.localVolumeIndicator = setInterval(() => {
            const volumeLevel = this.localMicrophoneTrack?.getVolumeLevel() * 10;
            if (this.localMicrophoneTrack && this.agoraClient.uid && volumeLevel > 4) {
                const data = {
                    uid: this.localParticipant.uid,
                    volumeLevel
                };
                this.externalInterfaceService.sendVolumeDetails(data);
            }
        }, 2000);

        // this.agoraClient.on('stream-fallback', (uid, isFallbackOrRecover) => {
        //     console.log(uid, isFallbackOrRecover, 'is fallback');
        // });

        // this.agoraClient.on('stream-type-changed', (uid, streamType) => {
        //     console.log(uid, streamType, 'stream-type-changed');
        // });

        this.agoraClient.on('connection-state-change', this.onConnectionStateChange.bind(this));

        this.agoraClient.on('network-quality', (stats) => {
            const decideUpDownSignal = this.getSignalQuality(stats);
            let networkQuality = this.convertSignalToQuality(decideUpDownSignal);
            if (
                this.counterForGoodSignal === 0 &&
                this.counterForBadSignal === 0 &&
                this.counterForVeryBadSignal === 0
            ) {
                this.signalStrengthData.signal = SignalStatus.GOOD;
            }
            this.updateCounterInfo(networkQuality);
            let checkValue = this.checkUpgradeDowngrade(networkQuality);
            if (this.counterForGoodSignal === checkValue) {
                if (this.appService.audioOnlyModeEnabledFromAlerts) {
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.DISABLE_AUDIO_ONLY_MODE,
                        data: {}
                    });
                }
                if (
                    this.appService.getConfigVariable('isNetworkQualityInCallAlertsEnabled') &&
                    (this.signalStrengthData.signal === SignalStatus.BAD ||
                        (this.signalStrengthData.signal === SignalStatus.VERYBAD &&
                            !this.appService.cameraOnBeforeAudioOnlyMode))
                ) {
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.SHOW_GOOD_NETWORK_TOAST,
                        data: {}
                    });
                }
                this.signalStrengthData.signal = SignalStatus.GOOD;
                this.appService.inCallBannerType = SignalStatus.GOOD;
                if (this.appService.audioOnlyModeEnabledFromAlerts && this.appService.cameraOnBeforeAudioOnlyMode) {
                    this.appService.showInCallBanner = true;
                } else {
                    this.appService.showInCallBanner = false;
                }
                this.appLoggerService.info('Agora network quality :', networkQuality);
                this.appLoggerService.info('is Audio-only mode :', this.appService.audioOnlyMode);
            } else if (this.counterForBadSignal === checkValue) {
                this.signalStrengthData.signal = SignalStatus.BAD;
                this.appService.inCallBannerType = SignalStatus.BAD;
                this.appService.showInCallBanner = true;
                this.appLoggerService.info('Agora network quality :', networkQuality);
                this.appLoggerService.info('is Audio-only mode :', this.appService.audioOnlyMode);
            } else if (this.counterForVeryBadSignal === checkValue) {
                this.signalStrengthData.signal = SignalStatus.VERYBAD;
                if (!this.appService.audioOnlyMode) {
                    this.appService.inCallBannerType = SignalStatus.VERYBAD;
                    this.appService.showInCallBanner = true;
                }
                this.appLoggerService.info('Agora network quality :', networkQuality);
                this.appLoggerService.info('is Audio-only mode :', this.appService.audioOnlyMode);
            }
        });
    }

    getSignalQuality(stats) {
        return this.localParticipant?.microphoneMute && this.localParticipant?.cameraMute
            ? stats?.downlinkNetworkQuality
            : stats?.uplinkNetworkQuality;
    }

    checkUpgradeDowngrade(networkQuality) {
        let checkValue = 5;
        if (this.signalStrengthData.signal === SignalStatus.GOOD) {
            if (networkQuality === SignalStatus.BAD || networkQuality === SignalStatus.VERYBAD) {
                checkValue = 5;
            }
        }
        if (this.signalStrengthData.signal === SignalStatus.BAD) {
            if (networkQuality === SignalStatus.GOOD) {
                checkValue = 10;
            } else if (networkQuality === SignalStatus.VERYBAD) {
                checkValue = 5;
            }
        }
        if (this.signalStrengthData.signal === SignalStatus.VERYBAD) {
            if (networkQuality === SignalStatus.GOOD || networkQuality === SignalStatus.BAD) {
                checkValue = 10;
            }
        }
        return checkValue;
    }

    updateCounterInfo(networkQuality) {
        if (networkQuality === SignalStatus.GOOD) {
            this.counterForGoodSignal++;
            this.counterForBadSignal = 0;
            this.counterForVeryBadSignal = 0;
        } else if (networkQuality === SignalStatus.BAD) {
            this.counterForBadSignal++;
            this.counterForGoodSignal = 0;
            this.counterForVeryBadSignal = 0;
        } else if (networkQuality === SignalStatus.VERYBAD) {
            this.counterForVeryBadSignal++;
            this.counterForGoodSignal = 0;
            this.counterForBadSignal = 0;
        }
    }
    convertSignalToQuality(signal): SignalStatus {
        let outPutSignal = SignalStatus.NONE;
        switch (signal) {
            case 0:
                outPutSignal = SignalStatus.NONE;
                break;
            case 1:
            case 2:
                outPutSignal = SignalStatus.GOOD;
                break;
            case 3:
            case 4:
                outPutSignal = SignalStatus.BAD;
                break;
            case 5:
                outPutSignal = SignalStatus.VERYBAD;
                break;
            case 6:
                outPutSignal = SignalStatus.NONE;
                break;
        }
        return outPutSignal;
    }

    getSignalStrength() {
        return this.signalStrengthData;
    }

    async streamCreator() {
        if (!this.noMediaPermission) {
            try {
                if (this.cameras.length > 0) {
                    if (!this.localCameraTrack && !this.localParticipant.cameraMute) {
                        if (this.thirdPartyExternalIntegrationService.getIsEkycEnabled()) {
                            this.localCameraTrack = await this.createLocalVideoTrack(true);
                            const facingMode = this.localCameraTrack.getMediaStreamTrackSettings().facingMode;
                            this.externalInterfaceService.sendCameraFacingMode(facingMode);
                        } else {
                            this.localCameraTrack = await this.createCameraTrack();
                        }

                        if (this.vbService.backgroundConfig.type === 'blur') {
                            this.setBackgroundBlurring();
                        } else if (this.vbService.backgroundConfig.type === 'image') {
                            this.setBackgroundImage(this.vbService.backgroundConfig.imageUrl);
                        }
                    }
                }
            } catch (e) {
                this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                    message: e?.message,
                    code: e?.code,
                    area: DEVICE_ERROR_CONSTANTS.creating_video_track
                });
                console.log(e, 'mobile permission issue 2');
                console.log('mobile permission track', this.localCameraTrack);
            }

            try {
                if (this.microphones.length > 0) {
                    if (this.selectedLocalMicrophone === 0) {
                        //check added for to handle issue on safari
                        this.selectedLocalMicrophone = this.microphones[0].id;
                    }

                    if (!this.localMicrophoneTrack)
                        this.localMicrophoneTrack = await this.AgoraRTC.createMicrophoneAudioTrack({
                            microphoneId: String(this.selectedLocalMicrophone),
                            AEC: true,
                            ANS: true,
                            encoderConfig: 'speech_standard'
                        }).catch((err) => {
                            if (this.isPermissionError(err)) {
                                this.noAudioPermission = true;
                                this.noAudioPermissionBrowser = true;
                            } else {
                                this.noAudioPermissionDevice = true;
                            }
                            this.appLoggerService.error('error while creating local microphone track', err);
                            return Promise.reject(err);
                        });
                    if (
                        this.appService.getIsNoiseSuppression &&
                        !!this.appService.getConfigVariable('ENABLE_NOISE_DENOISER') &&
                        !this.processeDenoiser?._enabled
                    ) {
                        this.createAIDenoiserInstance();
                    }
                }
            } catch (e) {
                this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                    message: e?.message,
                    code: e?.code,
                    area: DEVICE_ERROR_CONSTANTS.creating_audio_track
                });
            }
            await this.publishTracks();
        }
    }

    async publishTracks() {
        let tracksToBePublished = [];
        if (!this.localParticipant.cameraMute && this.localCameraTrack) {
            this.isVideoOn.next(true);
            tracksToBePublished.push(this.localCameraTrack);
            this.localCameraTrack.play('localVideo', { fit: 'cover' });
            this.isVideoPublished = true;
        }

        if (!this.localParticipant.microphoneMute) {
            tracksToBePublished.push(this.localMicrophoneTrack);

            this.isAudioPublished = true;
        }

        if (this.localParticipant.participantType === 'speaker' && tracksToBePublished.length) {
            await this.agoraClient.publish(tracksToBePublished);
        }
    }

    async initRTM() {
        let options = {
            uid: String(this.currentUid),
            token: this.conferenceInfo.agoraRtmToken
        };

        try {
            const areaCode = this.rtcService?.getConferenceInfo()?.agoraAreaCode;
            if (areaCode) {
                AgoraRTM.setArea({
                    areaCodes: areaCode
                });
            }

            var enableCloudProxy = this.appService.getConfigVariable('ENABLE_AGORA_CLOUD_PROXY');
            if (enableCloudProxy && this.isBehindProxy()) {
                this.agoraRtmClient = AgoraRTM.createInstance(this.conferenceInfo.agoraAppId, {
                    enableCloudProxy: true
                });
            } else {
                this.agoraRtmClient = AgoraRTM.createInstance(this.conferenceInfo.agoraAppId);
            }

            await this.agoraRtmClient.login(options);
            this.agoraRtmChannel = this.agoraRtmClient.createChannel(this.currentRoomID);
            await this.agoraRtmChannel.join();
        } catch (e) {
            this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                message: e?.message,
                code: e?.code,
                area: DEVICE_ERROR_CONSTANTS.creating_rtm_instance
            });
        }

        this.agoraRtmClient.on('MessageFromPeer', (message, peerId) => {
            let parsedMessage = JSON.parse(message['text']);
            this.currentSenderId = parsedMessage.sender.participantId;
            this.chatMessages$.next(parsedMessage);
        });
        // Display connection state changes
        this.agoraRtmClient.on('ConnectionStateChanged', (state, reason) => {
            console.log('Connection state changed to:', state, 'Reason:', reason);
            if (state === ConnectionState.DISCONNECTED || state === ConnectionState.ABORTED) {
                this.agoraRtmClient
                    .login(options)
                    .then((data) => {
                        this.appLoggerService.info('Sucessfully reconnected to agora RTM :', data);
                    })
                    .catch((err) => {
                        this.appLoggerService.error('Error while reconnecting to agora RTM', err);
                    });
            }
        });

        this.agoraRtmChannel.on('ChannelMessage', (message, memberId) => {
            const data = JSON.parse(message['text']);

            // this.screenClientUid = data.sender.screenClientUid;
            this.chatMessages$.next(JSON.parse(message['text']));
        });

        // Display channel member stats
        this.agoraRtmChannel.on('MemberJoined', (memberId) => {
            // console.log(memberId + ' joined the channel');
        });

        // Display channel member stats
        this.agoraRtmChannel.on('MemberLeft', (memberId) => {
            //  console.log(memberId + ' left the channel');
        });
    }

    async createCameraTrack() {
        const videoTrack = await this.createLocalVideoTrack();
        return videoTrack;
    }

    createLocalVideoTrack(createForFrontCamera = false) {
        if (createForFrontCamera) {
            return this.AgoraRTC.createCameraVideoTrack({
                encoderConfig: this.getAgoraVideoProfile(true),
                facingMode: 'user'
            }).catch((err) => {
                if (this.isPermissionError(err)) {
                    this.noCameraPermission = true;
                    this.errors.next({ type: APP_EVENTS.ERRORS.NO_CAMERA_PERMISSION });
                    this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: true });
                } else {
                    this.errors.next({ type: APP_EVENTS.ERRORS.GENERIC_CAMERA_ERROR });
                    this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: true });
                }
                this.appLoggerService.error('error while creating local front camera track', err);
                return Promise.reject(err);
            });
        } else if (this.selectedLocalCamera) {
            return this.AgoraRTC.createCameraVideoTrack({
                cameraId: String(this.selectedLocalCamera),
                encoderConfig: this.getAgoraVideoProfile(true)
            }).catch((err) => {
                if (this.isPermissionError(err)) {
                    this.noCameraPermission = true;
                    this.errors.next({ type: APP_EVENTS.ERRORS.NO_CAMERA_PERMISSION });
                    this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: true });
                } else {
                    this.errors.next({ type: APP_EVENTS.ERRORS.GENERIC_CAMERA_ERROR });
                    this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: true });
                }
                this.appLoggerService.error('error while creating local camera track for selected', err);
                return Promise.reject(err);
            });
        } else {
            return this.AgoraRTC.createCameraVideoTrack({
                encoderConfig: this.getAgoraVideoProfile(true)
            }).catch((err) => {
                if (this.isPermissionError(err)) {
                    this.noCameraPermission = true;
                    this.errors.next({ type: APP_EVENTS.ERRORS.NO_CAMERA_PERMISSION });
                    // this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: true });
                } else {
                    this.errors.next({ type: APP_EVENTS.ERRORS.GENERIC_CAMERA_ERROR });
                    // this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: true });
                }
                this.appLoggerService.error('error while creating local camera track for unselected', err);
                return Promise.reject(err);
            });
        }
    }

    activeSpeakerListCreation(u, v) {
        let obj = {
            uid: String(u),
            volume: v,
            lastSpeaking: new Date().getTime()
        };

        let index = this.activeSpeakerList.findIndex((e) => e.uid === String(u));

        if (index === -1) {
            this.activeSpeakerList.push(obj);
        } else {
            this.activeSpeakerList[index].volume = v;
            this.activeSpeakerList[index].lastSpeaking = new Date().getTime();
        }
    }

    isScreenShareUid(uid) {
        return uid.toString().length > 9 && uid.toString().charAt(0) === '1' ? true : false;
    }

    setAudioLevelToRemoteParticipant(uid, audiolevel) {
        this.participantStatusUpdated.next({
            type: 'isVolumeChanged',
            data: {
                id: uid,
                volume: audiolevel
            }
        });
    }

    updateRemoteParticipants() {
        this.remoteParticipants = [];
        this.agoraClient.remoteUsers.forEach((p) => {
            if (!this.isScreenShareUid(p.uid)) {
                this.remoteParticipants.push(p);
            }
        });

        if (this.remoteParticipants.length < 5) {
            this.setRemoteStreamType(0);
        } else {
            this.setRemoteStreamType(1);
        }
        this.eventEmitterService.emit({
            type: APP_EVENTS.UPDATE_REMOTE_PARTICIPANTS,
            data: {}
        });
    }

    getRemoteParticipantsLength() {
        return this.remoteParticipants?.length;
    }

    isScreenShareStreamAvaiable() {
        if (!this.agoraClient.remoteUsers?.length) {
            // if there is no remote participant then the below forEach will not iterate
            // and this.isScreenShareAvaiable won't reset to false
            // this condition used to reset it to false
            this.isScreenShareAvaiable = false;
            return;
        }
        this.agoraClient.remoteUsers.forEach((p) => {
            if (this.isScreenShareUid(p.uid)) {
                this.isScreenShareAvaiable = true;
                return;
            } else {
                this.isScreenShareAvaiable = false;
            }
        });
    }

    setRemoteStreamType(bitrate) {
        this.agoraClient.remoteUsers.forEach(async (element) => {
            if (!this.isScreenShareUid(element.uid)) {
                await this.agoraClient.setRemoteVideoStreamType(element.uid, bitrate);
            }
        });
    }

    async setLocalStreamProfile(level) {
        if (level) {
            // set to lower
            let config = this.getLowQualityVideoProfile();
            if (config && this.localCameraTrack?.isPlaying && this.localCameraTrack) {
                await this.localCameraTrack?.setEncoderConfiguration?.({
                    width: config.width,
                    height: config.height,
                    frameRate: config.framerate,
                    bitrateMax: config.bitrate
                });
            }
        } else {
            // set to higher
            if (this.localCameraTrack?.isPlaying && this.localCameraTrack) {
                await this.localCameraTrack?.setEncoderConfiguration?.(this.getAgoraVideoProfile(true));
            }
        }
    }

    async updateVideoConfig(flag) {
        /* 0 => high
       1 => low
    */

        await this.setLocalStreamProfile(flag);
        if (flag) {
            // disable dual stream as we lowered the sender resolution
            await this.agoraClient.disableDualStream();
        } else {
            // check dual stream is already enable or not
            if (!this.agoraClient._isDualStreamEnabled) {
                // enable dual stream
                this.agoraClient
                    .enableDualStream()
                    .then(() => {
                        let config = this.getLowQualityVideoProfile();
                        if (config) {
                            this.agoraClient.setLowStreamParameter(config);
                        }
                    })
                    .catch((e) => {
                        this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                            message: e?.message,
                            code: e?.code,
                            area: DEVICE_ERROR_CONSTANTS.enable_dual_stream
                        });
                    });
            }
        }
    }

    getLowQualityVideoProfile() {
        let width = this.agoraBandwidthConfig.lowQualityVideo.width;
        let height = this.agoraBandwidthConfig.lowQualityVideo.height;
        let framerate = this.agoraBandwidthConfig.lowQualityVideo.fps;
        let bitrate = this.agoraBandwidthConfig.lowQualityVideo.bitrate;

        try {
            width = this.conferenceInfo.agoraBandwidthConfig.lowQualityVideo.width;
            height = this.conferenceInfo.agoraBandwidthConfig.lowQualityVideo.height;
            framerate = this.conferenceInfo.agoraBandwidthConfig.lowQualityVideo.fps;
            bitrate = this.conferenceInfo.agoraBandwidthConfig.lowQualityVideo.bitrate;
        } catch (e) {
            this.externalInterfaceService.sendErrorOccuredOnJoined('exception', e?.message);
        }

        if (this.thirdPartyExternalIntegrationService.isDigiGovCustomer()) {
            const digiGovVideoConfig = this.appService.getConfigVariable('agoraBandwidthForDigiGov');
            try {
                width = digiGovVideoConfig.lowQualityVideo.width;
                height = digiGovVideoConfig.lowQualityVideo.height;
                framerate = digiGovVideoConfig.lowQualityVideo.fps;
                bitrate = digiGovVideoConfig.lowQualityVideo.bitrate;
            } catch (e) {
                this.externalInterfaceService.sendErrorOccuredOnJoined('exception', e?.message);
                console.log('error fetching digigov video attributes from config');
            }
        }
        return { width, height, framerate, bitrate };
    }

    onConnectionStateChange(curState, previousState, reason?) {
        if (curState == ConnectionState.TOO_MANY_BROADCASTERS) {
            this.setParticipantRole('audience');
        }

        if (curState == ConnectionState.RECONNECTING) {
            this.reconnecting = true;
            this.invokeRetryPopup();
        }

        if (previousState === ConnectionState.RECONNECTING && curState === ConnectionState.CONNECTED) {
            clearTimeout(this.reconnectPopupTimer);
            this.reconnectPopupTimer = undefined;
            this.reconnecting = false;
            this.meeting$.next({
                type: 'RE_CONNECTED',
                data: null
            });
        }
    }

    resetPopupTimer() {
        clearTimeout(this.reconnectPopupTimer);
        this.reconnectPopupTimer = undefined;
        this.invokeRetryPopup();
    }

    invokeRetryPopup() {
        if (this.reconnectPopupTimer === undefined) {
            this.reconnectPopupTimer = setTimeout(() => {
                this.meeting$.next({
                    type: 'AGORA_RETRY_POPUP',
                    data: null
                });
            }, 60 * 1000);
        }
    }

    async selectDevices() {
        let speakerId = this.utilService.getCookie('cacheSpeaker');
        let micId = this.utilService.getCookie('cacheMic');
        let cameraId = this.utilService.getCookie('cacheCamera');

        //setting device from url
        if (this.externalInterfaceService.cameraLabel) {
            const camera = this.cameras.find((x) => x.name === this.externalInterfaceService.cameraLabel);
            cameraId = camera.id;
        }
        if (this.externalInterfaceService.micLabel) {
            const mic = this.microphones.find((x) => x.name === this.externalInterfaceService.micLabel);
            micId = mic.id;
        }
        if (this.externalInterfaceService.speakerLabel) {
            const speaker = this.speakers.find((x) => x.name === this.externalInterfaceService.speakerLabel);
            speakerId = speaker.id;
        }

        //setting mic
        if (micId) {
            this.microphones.map(async (mic) => {
                if (mic.id == micId) {
                    this.selectedLocalMicrophone = mic.id;
                }
            });
            if (!this.selectedLocalMicrophone) {
                if (this.microphones.length > 0) this.selectedLocalMicrophone = this.microphones[0].id;
            }
        } else {
            if (this.microphones.length > 0) this.selectedLocalMicrophone = this.microphones[0].id;
        }

        //setting camera
        if (cameraId) {
            this.cameras.map(async (camera) => {
                if (camera.id == cameraId) {
                    this.selectedLocalCamera = camera.id;
                }
            });
            if (!this.selectedLocalMicrophone) {
                if (this.microphones.length > 0) this.selectedLocalMicrophone = this.microphones[0].id;
            }
        } else {
            if (this.cameras.length > 0) this.selectedLocalCamera = this.cameras[0].id;
        }

        //setting speaker
        if (speakerId) {
            this.speakers.forEach((speaker) => {
                if (speaker.id === speakerId) {
                    this.selectedLocalSpeaker = speaker.id;
                }
            });
            if (!this.selectedLocalSpeaker) {
                if (this.speakers.length > 0) this.selectedLocalSpeaker = this.speakers[0]?.id;
            }
        } else {
            this.selectedLocalSpeaker = this.speakers[0]?.id;
        }
    }

    async manuallyChangeSpeaker(localSpeaker) {
        document.cookie = `cacheSpeaker=${localSpeaker.id}`;
        this.selectedLocalSpeaker = localSpeaker.id;
        this.updateAgoraClientSpeakerOut(String(this.selectedLocalSpeaker));
    }

    updateAgoraClientSpeakerOut(id: string) {
        try {
            this.agoraClient.remoteUsers.forEach(async (p) => {
                if (!this.isScreenShareUid(p.uid)) {
                    await p.audioTrack?.setPlaybackDevice(id);
                }
            });
        } catch (e) {
            this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                message: e?.message,
                code: e?.code,
                area: DEVICE_ERROR_CONSTANTS.changing_audio_output
            });
        }
    }

    async manuallyChangeMicrophone(localMicrophone) {
        document.cookie = `cacheMic=${localMicrophone.id}`;
        this.selectedLocalMicrophone = localMicrophone.id;
        try {
            await this.localMicrophoneTrack.setDevice(String(this.selectedLocalMicrophone));
        } catch (e) {
            this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                message: e?.message,
                code: e?.code,
                area: DEVICE_ERROR_CONSTANTS.changing_audio_input
            });
        }
    }

    async manuallyChangeCamera(localCamera) {
        document.cookie = `cacheCamera=${localCamera.id}`;
        this.selectedLocalCamera = localCamera.id;
        if (this.localCameraTrack) {
            try {
                await this.localCameraTrack.setDevice(String(this.selectedLocalCamera));
            } catch (e) {
                this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                    message: e?.message,
                    code: e?.code,
                    area: DEVICE_ERROR_CONSTANTS.changing_video_input
                });
            }
        }
    }

    async leaveRoom() {
        try {
            this.isVideoOn.next(false);
            this.isBreakoutRoom = false;
            this.disconneting = true;
            this.isPostionChecked = false;
            this.joinedInRoom = false;
            this.remoteScreenSharingStream = null;
            this.isScreenShareAvaiable = false;
            this.processor = null;
            this.callInProgress = false;
            await this.agoraRtmChannel?.leave();
            await this.agoraRtmChannel?.removeAllListeners();
            await this.agoraRtmClient?.logout();
            await this.agoraRtmClient?.removeAllListeners();
            await this.agoraClient?.removeAllListeners();
            await this.agoraClient?.leave();

            this.localCameraTrack?.close();
            this.localMicrophoneTrack?.close();

            this.localCameraTrack = null;
            this.localMicrophoneTrack = null;

            clearInterval(this.localVolumeIndicator);
            // this.localCameraTrack.close();
            // this.localMicrophoneTrack.close();
        } catch (exception) {
            this.externalInterfaceService.sendErrorOccuredOnJoined('exception', exception?.message);
            console.log(exception);
        }
        await this.toggleWindowShare(false);
    }

    async toggleCameraPrivacy(enable) {
        if (this.processingToggleCamera) return;
        this.processingToggleCamera = true;
        if (this.localParticipant) {
            this.localParticipant.cameraMute = enable;
        }
        if (!enable) {
            if (this.agoraClient != undefined) {
                try {
                    if (this.localCameraTrack === null) {
                        this.localCameraTrack = await this.createCameraTrack();
                    }
                    await this.localCameraTrack.setEnabled(true);
                    if (this.vbService.backgroundConfig.type === 'blur') {
                        this.setBackgroundBlurring();
                    } else if (this.vbService.backgroundConfig.type === 'image') {
                        this.setBackgroundImage(this.vbService.backgroundConfig.imageUrl);
                    }
                    this.isVideoOn.next(true);
                    this.localCameraTrack.play(this.joinData.viewId, { fit: 'cover' });
                    await this.publishCameraTrack();
                    if (this.screenSharing || this.whiteboardShared || this.isScreenShareAvaiable) {
                        // if screen or whiteboard shared set the resolution to lower quality
                        if (this.isOptimizeOnScreenSharingEnabled) {
                            await this.updateVideoConfig(1);
                        }
                    }
                } catch (err) {
                    // there is an error while enabling camera
                    if (this.localParticipant) {
                        this.localParticipant.cameraMute = true;
                    }
                    this.processingToggleCamera = false;
                    if (this.noCameraPermission) {
                        this.errors.next({ type: APP_EVENTS.ERRORS.NO_CAMERA_PERMISSION });
                        this.eventEmitterService.emit({ type: APP_EVENTS.SHOW_PERMISSION_UI, data: true });
                    }
                    this.appLoggerService.error('local camera setEnabled error: ', err);
                }
            }
        } else {
            if (this.localCameraTrack) {
                await this.stopLocalCameraTrack();
            }
        }
        if (this.localParticipant) {
            this.externalInterfaceService.sendCameraStatus(!enable);
        }
        this.sendControlStatus();
        this.processingToggleCamera = false;
    }

    async publishCameraTrack() {
        if (!!this.localCameraTrack && !this.isVideoPublished && this.rtcService.getPreparatorySetupDone()) {
            try {
                if (this.localParticipant.participantType === 'speaker') {
                    await this.agoraClient.publish([this.localCameraTrack]);
                    this.isVideoPublished = true;
                }
            } catch (e) {
                this.appLoggerService.error('Error while publishing video', e);
                this.stopLocalCameraTrack();
                this.processingToggleCamera = false;
                return Promise.reject(e);
            }
        }
    }

    async stopLocalCameraTrack() {
        try {
            this.isVideoOn.next(false);
            await this.localCameraTrack?.setEnabled(false);
            this.agoraClient?.unpublish([this.localCameraTrack]);
            this.isVideoPublished = false;
            if (this.isVirtualBackgroundApplied) {
                this.vbService.closeSession();
            }
        } catch (err) {
            this.appLoggerService.error('Error while stop local camera: ', err);
        }
    }

    checkAndToggleOffCamera() {
        if (this.localCameraTrack?._enabled) {
            this.toggleCameraPrivacy(true);
        }
    }

    async toggleMicPrivacy(enable) {
        if (this.processingToggleMic) return;
        this.processingToggleMic = true;
        if (this.localParticipant && this.localMicrophoneTrack) {
            this.localParticipant.microphoneMute = enable;
        }
        if (this.agoraClient != undefined && this.localMicrophoneTrack) {
            if (!enable) {
                try {
                    if (!this.localMicrophoneTrack?.enabled) {
                        await this.localMicrophoneTrack.setEnabled(true);
                        await this.localMicrophoneTrack.setMuted(false);
                    } else {
                        await this.localMicrophoneTrack.setMuted(false);
                    }
                    if (!this.isAudioPublished) {
                        try {
                            await this.agoraClient.publish([this.localMicrophoneTrack]);
                        } catch (e) {
                            if (this.localParticipant) {
                                this.localParticipant.microphoneMute = true;
                            }
                            this.appLoggerService.error('Error while publishing audio', e);
                            this.processingToggleMic = false;
                            return Promise.reject(e);
                        }

                        this.isAudioPublished = true;
                    }
                } catch (e) {
                    this.appLoggerService.error('Disable local Microphone setMuted error: ', e);
                    if (this.localParticipant) {
                        this.localParticipant.microphoneMute = true;
                    }
                }
            } else {
                try {
                    if (!this.localMicrophoneTrack?.enabled) {
                        await this.localMicrophoneTrack.setEnabled(true);
                        await this.localMicrophoneTrack.setMuted(true);
                    } else {
                        await this.localMicrophoneTrack.setMuted(true);
                    }
                } catch (e) {
                    this.appLoggerService.error('Enable local Microphone setMuted error: ', e);
                }
            }
        }

        if (this.localParticipant && this.localMicrophoneTrack) {
            this.localParticipant.microphoneMute = enable;

            this.externalInterfaceService.sendMicStatus(!enable);
        }

        this.sendControlStatus();
        this.processingToggleMic = false;
    }

    toggleRecordingStatus(enable) {
        this.recording = enable;
        this.sendControlStatus();
    }

    toggleMicAll(enable) {
        this.microphoneMutedAll = enable;
        this.sendControlStatus();
    }

    toggleLockStatus(enable) {
        this.roomLocked = enable;
        this.sendControlStatus();
    }

    setHostDisconnectParticipant(id) {
        if (this.users[id]) {
            this.users[id].disconnectedByHost = true;
        }
    }

    async sendChatMessage({
        agoraShareUid,
        type,
        message,
        targetParticipantId = '',
        targetParticipantName = '',
        isOverride = false,
        reactionsType = '',
        users = []
    }) {
        let payload;

        if (type === 'PublicChat') {
            payload = {
                agoraShareUid,
                type,
                message,
                users,
                sender: {
                    participantId: this.roomData?.localParticipant.participantId,
                    name: this.roomData?.localParticipant.participantName,
                    userId: this.roomData?.localParticipant.userId
                },
                targetParticipantId: '',
                reactionsType: reactionsType
            };

            if (
                message === VIDYO_EVENTS.PARTICIPANT_COHOST_GRANT ||
                message === VIDYO_EVENTS.PARTICIPANT_COHOST_REVOKE ||
                message === VIDYO_EVENTS.HOST_LOWER_HAND
            ) {
                payload.targetParticipantId = targetParticipantId ? targetParticipantId : this.currentSenderId;
                // if (targetParticipantId) {
                //   payload = { ...payload, ...{ targetParticipantId } };
                // }
            }
        } else {
            payload = {
                type,
                message,
                targetParticipantName,
                targetParticipantId,
                targetHostUserId: this.roomData?.localParticipant.userId,
                isOverride,
                sender: {
                    participantId: this.roomData?.localParticipant.participantId,
                    name: this.roomData?.localParticipant.participantName,
                    userId: this.roomData?.localParticipant.userId
                }
            };
        }

        if (type === 'PublicChat') {
            return await this.agoraRtmChannel?.sendMessage({ text: JSON.stringify(payload) });
        } else {
            return await this.agoraRtmClient.sendMessageToPeer(
                { text: JSON.stringify(payload) },
                payload.targetParticipantId
            );
        }
    }

    setHostIdentity(sender) {
        if (this.users[sender.id]) {
            this.users[sender.id].isHost = true;
        }
    }

    getAspectRatio() {
        const feedHeight = this.localCameraTrack.getCurrentFrameData().height;
        const feedWidth = this.localCameraTrack.getCurrentFrameData().width;
        return feedWidth / feedHeight;
    }

    async startScreenCall() {
        this.screenClient = this.AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
        var enableCloudProxy = this.appService.getConfigVariable('ENABLE_AGORA_CLOUD_PROXY');
        var cloudProxyValue = this.appService.getConfigVariable('CLOUD_PROXY_VALUE')
            ? this.appService.getConfigVariable('CLOUD_PROXY_VALUE')
            : 5;

        if (enableCloudProxy && this.isBehindProxy()) {
            // 3 is for udp cloud proxy
            this.screenClient.startProxyServer(
                this.thirdPartyExternalIntegrationService.setProxyServer !== 0
                    ? this.thirdPartyExternalIntegrationService.setProxyServer
                    : cloudProxyValue
            );
        }
        await this.screenClient.setClientRole('host');
        await this.screenClient
            .join(this.conferenceInfo.agoraAppId, this.currentRoomID, this.screenClientToken, this.screenClientUid)
            .catch((e) => {
                this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                    message: e?.message,
                    code: e?.code,
                    area: DEVICE_ERROR_CONSTANTS.init_screenshare_track
                });
                this.isScreenShareAvaiable = false;
                this.meeting$.next({ type: 'SHARE_STOP', data: null });
            });

        const publishTracks = !!this.localScreenAudioTrack
            ? [this.localScreenTrack, this.localScreenAudioTrack]
            : this.localScreenTrack;
        await this.screenClient
            .publish(publishTracks)
            .then(() => {
                this.screenSharing = true;
                this.sendControlStatus();
                const chatObj = {
                    agoraShareUid: this.screenClientUid,
                    type: 'PublicChat',
                    message: VIDYO_EVENTS.PARTRICIPANT_START_SHARE,
                    targetParticipantId: '',
                    targetParticipantName: ''
                };
                this.updateSpeakerLayout(false);
                this.sendChatMessage(chatObj);
            })
            .catch((e) => {
                this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                    message: e?.message,
                    code: e?.code,
                    area: DEVICE_ERROR_CONSTANTS.publishing_screenshare_track
                });
                this.meeting$.next({ type: 'SHARE_STOP', data: null });
            });
    }

    async stopScreenAudioTrack() {
        if (this.localScreenAudioTrack) {
            await this.localScreenAudioTrack.setEnabled(false);
        }
    }

    async resumeScreenAudioTrack() {
        await this.localScreenAudioTrack.setEnabled(true);
    }

    async createScreenShareTrack(mediaStream?: MediaStream) {
        var screenShareTracks;
        let audio_check = false;
        if (mediaStream) {
            screenShareTracks = mediaStream.getVideoTracks();
            this.localScreenTrack = await this.AgoraRTC.createCustomVideoTrack({
                mediaStreamTrack: screenShareTracks[0]
            });
        } else {
            screenShareTracks = await this.AgoraRTC.createScreenVideoTrack(
                {
                    encoderConfig: this.getAgoraVideoProfile(false)
                },
                this.appService.getConfigVariable('ENABLE_SCREEN_SHARE_AUDIO') ? 'auto' : 'disable'
            );

            if (screenShareTracks instanceof Array) {
                audio_check = true;
                this.localScreenTrack = screenShareTracks[0];
                this.localScreenAudioTrack = screenShareTracks[1];
            } else {
                this.localScreenTrack = screenShareTracks;
            }
        }

        try {
            this.externalInterfaceService.sendScreenShareStatus(true);
            this.localScreenTrack.getMediaStreamTrack().onended = () => {
                this.meeting$.next({ type: 'SHARE_STOP', data: { stopShare: true } });
            };
            return {
                screenShareCreated: true,
                audioSharing: audio_check
            };
        } catch (e) {
            this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                message: e?.message,
                code: e?.code,
                area: DEVICE_ERROR_CONSTANTS.creating_screenshare_track
            });
        }

        return screenShareTracks;
    }

    async toggleWindowShare(enable) {
        if (enable) {
            this.startScreenCall();
        } else {
            if (this.screenClient) {
                this.screenClient.localTracks.forEach((t) => {
                    t.close();
                });
                await this.screenClient.leave().then(() => {
                    this.localScreenTrack = undefined;
                    this.localScreenAudioTrack = undefined;
                    this.screenSharing = false;
                    this.externalInterfaceService.sendScreenShareStatus(false);
                    this.sendControlStatus();
                });
            }
        }
        let streamType = enable ? 1 : 0;
        this.isOptimizeOnScreenSharingEnabled && (await this.updateVideoConfig(streamType));

        this.externalInterfaceService.sendScreenShareStatus(enable);
    }

    addUserInWaitingRoom(user) {
        if (user.memberName) {
            this.waitingUsers[user.memberId] = user;
            this.sendControlStatus();
        }
    }

    removeUserFromWaitingRoom(user) {
        delete this.waitingUsers[user.memberId];
        this.sendControlStatus();
    }

    setSharingScreenStatus(enable) {
        this.alreadySharingScreen = enable;
    }

    getLocalParticipant() {
        return this.localParticipant;
    }

    private sendControlStatus(updates: any = {}) {
        this.participantsStatus$.next({
            users: this.users,
            microphoneMutedAll: this.microphoneMutedAll,
            roomLocked: this.roomLocked,
            recording: this.recording,
            screenSharing: this.screenSharing,
            waitingUsers: this.waitingUsers,
            alreadySharingScreen: this.alreadySharingScreen,
            vcs: this.vcs,
            participantJoined: updates.participantJoined,
            participantLeft: updates.participantLeft
        });
    }

    private handleWindowEvents() {
        window.onVidyoClientLoaded = (status) => {
            // this.onLoad(status);
        };

        window.onresize = () => {};

        window.onbeforeunload = () => {
            if (this.vidyoConnector) {
                this.vidyoConnector.Destruct();
            }
        };

        window.addEventListener('online', (e) => {
            this.isUserOnline = true;
        });
        window.addEventListener('offline', (e) => {
            this.isUserOnline = false;
        });
    }

    clear() {
        this.localParticipant = {};
        this.joinData = {};
        this.users = {};
        this.microphoneMutedAll = false;
        this.roomLocked = false;
        this.recording = false;
        this.screenSharing = false;
        this.reconnecting = false;
        this.whiteboardShared = false;
        clearInterval(this.reConnectInterval);
    }

    changeCamera() {
        this.vidyoConnector.CycleCamera();
    }

    changeSpeaker() {
        this.vidyoConnector.CycleSpeaker();
    }

    changeMicrophone() {
        this.vidyoConnector.CycleMicrophone();
    }

    async setWhiteboardStatus(state) {
        this.whiteboardShared = state;
        let streamType = this.whiteboardShared ? 1 : 0;
        if (!state) {
            this.participantStatusUpdated.next({ type: 'screenSharingStopped', data: {} });
        }

        this.setRemoteStreamType(streamType);
        this.isOptimizeOnScreenSharingEnabled && (await this.updateVideoConfig(streamType));
    }

    releaseDevices() {}

    getAgoraVideoProfile(isVideo) {
        let width, height, framerate, bitrate;
        if (isVideo) {
            width = this.agoraBandwidthConfig.video.width;
            height = this.agoraBandwidthConfig.video.height;
            framerate = this.agoraBandwidthConfig.video.fps;
            bitrate = this.agoraBandwidthConfig.video.bitrate;
            try {
                width = this.conferenceInfo.agoraBandwidthConfig.video.width;
                height = this.conferenceInfo.agoraBandwidthConfig.video.height;
                framerate = this.conferenceInfo.agoraBandwidthConfig.video.fps;
                bitrate = this.conferenceInfo.agoraBandwidthConfig.video.bitrate;
            } catch (e) {
                this.externalInterfaceService.sendErrorOccuredOnJoined('exception', e?.message);
                console.log('error fetching video attributes from config');
            }
            if (this.thirdPartyExternalIntegrationService.isDigiGovCustomer()) {
                const digiGovVideoConfig = this.appService.getConfigVariable('agoraBandwidthForDigiGov');
                try {
                    width = digiGovVideoConfig.video.width;
                    height = digiGovVideoConfig.video.height;
                    framerate = digiGovVideoConfig.video.fps;
                    bitrate = digiGovVideoConfig.video.bitrate;
                } catch (e) {
                    this.externalInterfaceService.sendErrorOccuredOnJoined('exception', e?.message);
                    console.log('error fetching digigov video attributes from config');
                }
            }
            return {
                width: width,
                height: height,
                frameRate: framerate,
                bitrateMax: bitrate
            };
        } else {
            width = this.isEnableHDshare
                ? this.agoraBandwidthConfig.screenShare.hdwidth
                : this.agoraBandwidthConfig.screenShare.width;
            height = this.isEnableHDshare
                ? this.agoraBandwidthConfig.screenShare.hdheight
                : this.agoraBandwidthConfig.screenShare.height;
            framerate = this.isEnableHDshare
                ? this.agoraBandwidthConfig.screenShare.hdfps
                : this.agoraBandwidthConfig.screenShare.fps;
            bitrate = this.isEnableHDshare
                ? this.agoraBandwidthConfig.screenShare.hdfps
                : this.agoraBandwidthConfig.screenShare.bitrate;
            try {
                width = this.isEnableHDshare
                    ? this.conferenceInfo.agoraBandwidthConfig.screenShare.hdwidth
                    : this.conferenceInfo.agoraBandwidthConfig.screenShare.width;
                height = this.isEnableHDshare
                    ? this.conferenceInfo.agoraBandwidthConfig.screenShare.hdheight
                    : this.conferenceInfo.agoraBandwidthConfig.screenShare.height;
                framerate = this.isEnableHDshare
                    ? this.conferenceInfo.agoraBandwidthConfig.screenShare.hdfps
                    : this.conferenceInfo.agoraBandwidthConfig.screenShare.fps;
                bitrate = this.isEnableHDshare
                    ? this.conferenceInfo.agoraBandwidthConfig.screenShare.hdfps
                    : this.conferenceInfo.agoraBandwidthConfig.screenShare.bitrate;
            } catch (e) {
                this.externalInterfaceService.sendErrorOccuredOnJoined('exception', e?.message);
                console.log('error fetching screenShare attributes from config');
            }
            return {
                width: width,
                height: height,
                frameRate: framerate,
                bitrateMax: bitrate
            };
        }
    }

    async makeSpeaker(showToast) {
        if (this.appService.getRecorderUser()) {
            return;
        }
        try {
            this.localParticipant.participantType = 'speaker';

            if (!showToast) {
                this.localParticipant.microphoneMute = !this.joinData.micPrivacy;
                this.localParticipant.cameraMute = !this.joinData.cameraPrivacy;
            }
            await this.setParticipantRole('host');
            await this.streamCreator();
            await this.localCameraTrack?.setEnabled(false);
            await this.localMicrophoneTrack?.setEnabled(false);
            this.meeting$.next({ type: 'SEND_CONNECTION_STATUS', data: {} });
            this.externalInterfaceService.sendUserStatus(false);
        } catch (e) {
            this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                message: e?.message,
                code: e?.code,
                area: DEVICE_ERROR_CONSTANTS.moving_to_speaker
            });
        }
        await this.setParticipantRole('host');
        await this.streamCreator();
        await this.localCameraTrack?.setEnabled(false);
        await this.localMicrophoneTrack?.setEnabled(false);
        this.meeting$.next({ type: 'SEND_CONNECTION_STATUS', data: {} });
        this.externalInterfaceService.sendUserStatus(false);
    }

    async makeAudience(showToast) {
        try {
            this.localParticipant.participantType = 'audience';
            if (showToast) {
                this.meeting$.next({ type: 'SHOW_DOWNGRADE_AUDIENCE_TOAST', data: {} });
            }

            this.localParticipant.microphoneMute = true;
            this.localParticipant.cameraMute = true;
            this.isVideoOn.next(false);
            if (this.localCameraTrack) {
                await this.stopLocalCameraTrack();
            }
            await this.unpublishTracks();
            await this.localCameraTrack?.close();
            await this.localMicrophoneTrack?.close();
            this.localCameraTrack = null;
            this.localMicrophoneTrack = null;
            this.processor = null;
            this.currentProcessorOption = null;
            if (showToast) this.meeting$.next({ type: 'WHITEBOARD_STOP', data: {} });
            this.toggleWindowShare(false);
            this.sendControlStatus();
            await this.setParticipantRole('audience');
            this.meeting$.next({ type: 'SEND_CONNECTION_STATUS', data: {} });
            this.externalInterfaceService.sendUserStatus(true);
        } catch (e) {
            this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                message: e?.message,
                code: e?.code,
                area: DEVICE_ERROR_CONSTANTS.moving_to_audience
            });
        }
    }

    async unpublishTracks() {
        try {
            await this.agoraClient.unpublish();
            this.isAudioPublished = false;
            this.isVideoPublished = false;
        } catch (e) {
            this.externalInterfaceService.sendErrorOccuredOnJoined('exception', {
                message: e?.message,
                code: e?.code,
                area: DEVICE_ERROR_CONSTANTS.unpublishing_track
            });
        }
    }

    checkPostion(participants, isHardMute) {
        if (this.breakoutRoomInfo?.breakoutRoomId) {
            return;
        }

        const numberOfSpeaker = participants?.filter((p) => p.participantType === 'speaker').length;
        this.showLimitReached(participants, numberOfSpeaker);
        if (this.isPostionChecked === false) {
            this.externalInterfaceService.sendHardMuteAudiostatus(isHardMute);
        }

        if (!this.localParticipant.isHost) {
            if (this.isPostionChecked === false && !this.externalInterfaceService.isEmbibe) {
                if (numberOfSpeaker < this.SPEAKER_LIMIT) {
                    const index = participants.findIndex((p) => p.participantUri === this.localParticipant.userId);
                    if (participants[index]?.participantType === 'audience') {
                        this.makeSpeaker(false);
                    }
                } else {
                    if (this.isLocalParticipantCoHost(participants)) {
                        const index = participants.findIndex((p) => p.participantUri === this.localParticipant.userId);
                        if (participants[index]?.participantType === 'audience') {
                            this.makeSpeaker(false);
                        }
                    }
                }

                if (this.localParticipant.participantType === 'audience') {
                    this.meeting$.next({ type: 'SHOW_DOWNGRADE_AUDIENCE_INFO', data: {} });
                }

                this.isPostionChecked = true;
                return;
            }

            if (this.localParticipant.participantType === 'speaker') {
                let speakerList = participants.filter((p) => p.participantType === 'speaker');

                speakerList = this.getParticipantsWithActiveSpeakerValue(speakerList);

                const speakerListLength = speakerList.length;

                if (speakerListLength > this.SPEAKER_LIMIT) {
                    const localPfromL = this.getLocalParticipantFromParticipants(speakerList);

                    if (!localPfromL.isHost) {
                        if (!localPfromL.isSharingScreen) {
                            //  if(!this.checkIfLocalParticipantActive(localPfromL)){
                            if (!localPfromL.isActiveSpeaker) {
                                if (!localPfromL.isCoHost) {
                                    const speakerList1 = speakerList.filter(
                                        (s) => !s.isHost && !s.isSharingScreen && !s.isActiveSpeaker && !s.isCoHost
                                    );

                                    let multidevicelist = speakerList1.filter((s) => s.userId === localPfromL.userId);
                                    if (multidevicelist.length > 1) {
                                        this.sendMakeAudience(multidevicelist);
                                    } else {
                                        const speakerlist2 = speakerList.filter(
                                            (s) => !s.isHost && !s.isSharingScreen && !s.isActiveSpeaker && s.isCoHost
                                        );
                                        if (this.toFindDuplicates(speakerlist2)?.length === 0) {
                                            this.sendMakeAudience(speakerList1);
                                        }
                                    }
                                } //else if is cohost
                                else {
                                    let cohostmultidevicelist = speakerList.filter(
                                        (s) => s.userId === localPfromL.userId
                                    );

                                    if (cohostmultidevicelist.length > 1) {
                                        this.sendMakeAudience(cohostmultidevicelist);
                                    } else {
                                        if (cohostmultidevicelist.length === 1) {
                                            let cohostlist = speakerList.filter(
                                                (s) =>
                                                    !s.isHost && !s.isSharingScreen && !s.isActiveSpeaker && !s.isCoHost
                                            );
                                            if (cohostlist.length === 0) {
                                                let cohostlist = speakerList.filter(
                                                    (s) =>
                                                        !s.isHost &&
                                                        !s.isSharingScreen &&
                                                        !s.isActiveSpeaker &&
                                                        s.isCoHost
                                                );
                                                this.sendMakeAudience(cohostlist);
                                            }
                                        }
                                    }
                                }
                            } //else if all activ
                        }
                    }
                }
            }
        }
        this.isPostionChecked = true;
    }

    showLimitReached(participants, numberOfSpeaker) {
        if (numberOfSpeaker >= this.SPEAKER_LIMIT) {
            if (
                (this.localParticipant.isHost || this.isLocalParticipantCoHost(participants)) &&
                this.showedLimitReached
            ) {
                this.meeting$.next({ type: 'SHOW_LARGE_MEETINGINFO', data: {} });
                this.showedLimitReached = false;
            }
        }
    }

    sendMakeAudience(speakerList) {
        speakerList = speakerList.sort(this.sortFunction);
        const participantToDemote = speakerList[speakerList.length - 1];
        if (participantToDemote.participantUri === this.localParticipant.userId) {
            if (participantToDemote.isCoHost) {
                this.meeting$.next({
                    type: 'DOWNGRADE_CO_HOST',
                    data: { userId: participantToDemote.userId }
                });
            }
            this.makeAudience(true);
        }
    }

    isLocalParticipantCoHost(participants) {
        let localListParticipant = participants.find(
            (p) => p.isCoHost === true && p.participantUri === this.localParticipant.userId
        );
        if (localListParticipant) {
            let numberofcohost = participants.filter((p) => p.userId === localListParticipant.userId);

            if (numberofcohost.length > 1) {
                return false;
            } else {
                if (numberofcohost.length === 1) {
                    return true;
                }
            }
        } else {
            return false;
        }
    }

    sortFunction(a, b) {
        var dateA = new Date(a.participantJoinTime).getTime();
        var dateB = new Date(b.participantJoinTime).getTime();
        return dateA > dateB ? 1 : -1;
    }

    checkIfLocalParticipantActive(localPfromL) {
        return this.activeSpeakerList.findIndex((elment) => elment.uid === localPfromL.participantUri) === -1
            ? false
            : true;
    }

    getParticipantsWithActiveSpeakerValue(list) {
        for (let index = 0; index < list.length; index++) {
            if (this.activeSpeakerList.findIndex((a) => a.uid === list[index].participantUri) > -1) {
                list[index]['isActiveSpeaker'] = true;
            } else {
                list[index]['isActiveSpeaker'] = false;
            }
        }

        return list;
    }

    getLocalParticipantFromParticipants(list) {
        return list.find((l) => l.participantUri === this.localParticipant.userId);
    }

    setFitToScreen(value) {
        this.isFitToScreen = value;
        this.participantStatusUpdated.next({ type: 'fitToScreen', data: { value } });
    }

    enabledHDScreenShare(value) {
        this.isEnableHDshare = value;
    }

    toFindDuplicates(arry) {
        const lookup = arry.reduce((a, e) => {
            a[e.userId] = ++a[e.userId] || 0;
            return a;
        }, {});

        return arry.filter((e) => lookup[e.userId]);
    }

    functionHideScreenShareNotification(state) {
        this.hideScreenShareNotification.next(state);
    }

    handleRemoteAudio(mute) {
        let volume = 100;
        if (mute) {
            volume = 0;
        }
        this.agoraClient.remoteUsers.forEach(async (p) => {
            p?.audioTrack?.setVolume(volume);
        });
    }

    isPermissionError(err) {
        return err.code === 'PERMISSION_DENIED' || (err instanceof DOMException && err.name === 'NotAllowedError');
    }

    isSystemPermissionError(err) {
        return this.isPermissionError(err) && err?.message?.indexOf('system') > -1;
    }

    updateGalleryVideoTiles(numberOfTiles) {
        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
        });

        const MAX_VIDEO_TILES = this.utilService.getMaxTiles(this.currentUser);

        if (numberOfTiles < 2) {
            return;
        }

        if (numberOfTiles > MAX_VIDEO_TILES) {
            return;
        }
        this.numberOfVideoTilesSelected = numberOfTiles;
        this.numberOfVideoTilesSelectedSubject.next(numberOfTiles);
    }

    updateSpeakerLayout(status) {
        this.$speakerLayoutSelected.next(status);
    }

    hideNonVideo(status) {
        this.hideNonVideoSelected = status;
        this.$hideNonVideoSelected.next(status);
    }

    // for playing locally on selected divs
    async toggleCameraForSetting(divId, status) {
        this.processingToggleCamera = true;
        if (this.localCameraTrack === null) {
            this.localCameraTrack = await this.createLocalVideoTrack();
        }
        await this.localCameraTrack.setEnabled(status);
        this.localCameraTrack.play(divId);
        this.processingToggleCamera = false;
        // this.localParticipant.cameraMute = !status;
    }

    //Set bg blur
    async setBackgroundBlurring() {
        await this.checkForProcessorInstance();
        if (this.localCameraTrack) {
            try {
                this.processor.setOptions({ type: 'blur', blurDegree: 2 });
                this.currentProcessorOption = { type: 'blur', blurDegree: 2 };
                if (!this.processor.enabled) {
                    await this.processor.enable();
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    async createAIDenoiserInstance() {
        await import('agora-extension-ai-denoiser').then(async (m) => {
            this.denoiserExt = m.AIDenoiserExtension;
            this.denoiser = new this.denoiserExt({ assetsPath: './assets/wasms' });
            if (!this.denoiser.checkCompatibility()) {
                // The extension might not be supported in the current browser. You can stop executing further code logic
                this.appLoggerService.info('Denoiser Error: - Does not support AI Denoiser!');
                return;
            }

            await this.AgoraRTC.registerExtensions([this.denoiser]);
            await this.registerDenoiserEvt();
            this.processeDenoiser = await this.denoiser.createProcessor();
            this.denoiser.onloaderror = (err) => {
                // If the Wasm and JS files fail to load, you can disable the plugin, for example:
                this.processeDenoiser = null;
                this.appLoggerService.error('Denoiser File Load Error: ', err);
                return throwError(err);
            };
            this.listenDenoiserProcesserEvt();
            this.pipeAIDenosier();
        });
    }

    async pipeAIDenosier() {
        if (this.localMicrophoneTrack && this.processeDenoiser) {
            await this.localMicrophoneTrack
                .pipe(this.processeDenoiser)
                .pipe(this.localMicrophoneTrack.processorDestination);
            this.enableDenoiser();
        }
    }

    listenDenoiserProcesserEvt() {
        this.processeDenoiser.onoverload = async () => {
            this.appLoggerService.info('Denoiser Error: Denoiser Overload!');
            // If noise reduction takes too long, turn off the extension
            await this.processeDenoiser.setMode('STATIONARY_NS');
            await this.processeDenoiser.disable();
        };
    }

    registerDenoiserEvt() {
        this.denoiser.onloaderror = (err) => {
            // If the Wasm and JS files fail to load, you can disable the plugin, for example:
            this.processeDenoiser = null;
            this.appLoggerService.error('Denoiser OnLoad Error: ', err);
            return throwError(err);
        };
    }

    async enableDenoiser() {
        if (!this.denoiserEnabled) {
            await this.processeDenoiser.enable();
            this.denoiserEnabled = false;
        }
    }

    // Set an image as the background
    async setBackgroundImage(url) {
        await this.checkForProcessorInstance();
        if (this.localCameraTrack) {
            const imgElement = document.createElement('img');
            imgElement.crossOrigin = 'Anonymous';
            imgElement.onload = async () => {
                try {
                    this.processor.setOptions({ type: 'img', source: imgElement });
                    this.currentProcessorOption = { type: 'image', source: imgElement.src };
                    if (!this.processor.enabled) {
                        await this.processor.enable();
                    }
                } catch (e) {
                    console.log(e);
                }
            };
            imgElement.src = url;
        }
    }

    async checkForProcessorInstance() {
        if (this.processor === null) {
            await this.createProcessorInstanceAndAttach();
            return false;
        }
        return true;
    }

    async createProcessorInstanceAndAttach() {
        await import('agora-extension-virtual-background').then(async (m) => {
            this.virtualBackgroundExtension = m.default;
            const extension = new this.virtualBackgroundExtension();
            await this.AgoraRTC.registerExtensions([extension]);
            this.processor = await extension.createProcessor();
            if (this.processor && this.localCameraTrack) {
                // Create a VirtualBackgroundProcessor instance;
                try {
                    // Initialize the extension and pass in the URL of the Wasm file
                    await this.processor.init('./assets/wasms');
                } catch (e) {
                    console.log('Fail to load WASM resource!');
                    return null;
                }
                // Inject the extension into the video processing pipeline in the SDK
                await this.localCameraTrack.pipe(this.processor).pipe(this.localCameraTrack.processorDestination);
            }
        });
    }

    async disableBg() {
        await this.processor.disable();
        this.currentProcessorOption = null;
    }

    stopPreviewTrack() {
        try {
            if (this.localCameraTrack) {
                this.localCameraTrack?.stop();
                this.localCameraTrack?.close();
                if (this.isVirtualBackgroundApplied) {
                    this.vbService.closeSession();
                }
            }
            if (this.localMicrophoneTrack) {
                this.localMicrophoneTrack?.stop();
                this.localMicrophoneTrack?.close();
            }
        } catch (err) {
            this.appLoggerService.error('Error while stop preivew track: ', err);
        }
    }
}
