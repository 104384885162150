<div class="delete-account-container">
    <div class="delete-account-content">
        <h1 class="heading">{{ 'delete-account.thankyou_jiomeet' | translate }}</h1>
        <h3 class="sub-head">{{ 'delete-account.we_are_sorry' | translate }}</h3>
        <div>
            <h3 class="para-head">{{ 'delete-account.expect_after_delete.title' | translate }}</h3>
            <ul class="steps">
                <li>{{ 'delete-account.expect_after_delete.step1' | translate }}</li>
                <li>{{ 'delete-account.expect_after_delete.step2' | translate }}</li>
                <li>{{ 'delete-account.expect_after_delete.step3' | translate }}</li>
                <li>{{ 'delete-account.expect_after_delete.step4' | translate }}</li>
            </ul>
        </div>
        <div>
            <h3 class="para-head">{{ 'delete-account.for_enterprise_users.title' | translate }}</h3>
            <div>
                <div>{{ 'delete-account.for_enterprise_users.if_enterprise_user' | translate }}</div>
                <div class="enterprise-warning">{{ 'delete-account.for_enterprise_users.warning' | translate }}</div>
            </div>
        </div>
        <hr />
        <form [formGroup]="form">
            <div class="form-group">
                <label for="reasons" class="reasons">
                    {{ 'delete-account.choose_reason.title' | translate }}<em>*</em>
                </label>
                <br />
                <div class="p-dropdown-container">
                    <p-dropdown
                        [options]="reasons"
                        formControlName="reason"
                        placeholder="{{ 'delete-account.choose_reason.placeholder' | translate }}"
                        optionLabel="name"
                        optionValue="value"
                        (onChange)="onChangeReason($event)"
                        autocomplete="disabled"
                    ></p-dropdown>
                    <div
                        class="others-container"
                        [class.has-error]="form?.value?.otherReason?.length > 200"
                        *ngIf="form?.value?.reason === 'other'"
                    >
                        <textarea class="other-reason" formControlName="otherReason" rows="3"></textarea>
                        <div class="count-reason">{{ form?.value?.otherReason?.length }}/200</div>
                    </div>
                </div>
            </div>
            <div class="acknowledge">{{ 'delete-account.acknowledge_before_delete' | translate }}</div>
            <div class="acknowledge-check">
                <div class="custom-checkbox">
                    <input type="checkbox" id="acknowledge_check" formControlName="acknowledge_check" />
                    <label class="" for="acknowledge_check">
                        <span class="checkbox-container"></span>
                        <span>{{ 'delete-account.yes_i_want_to_delete' | translate }}<em>*</em></span>
                    </label>
                </div>
            </div>
            <div class="form-actions">
                <button
                    type="button"
                    class="custom-button bg-primary mr-2"
                    (click)="showDelAccPopUp = true; captcha = ''"
                    [class.disabled]="
                        form?.value?.reason == '' ||
                        this.form?.value?.acknowledge_check === false ||
                        validateFormWithOthers()
                    "
                >
                    {{ 'delete-account.delete_account' | translate }}
                </button>
                <button type="button" class="custom-button bg-secondary mr-2" [routerLink]="['/home']">
                    {{ 'delete-account.cancel' | translate }}
                </button>
            </div>
        </form>
    </div>

    <!-- Start Delete Account Confirm PopUp -->
    <div class="popup-component delete-account-popup" *ngIf="showDelAccPopUp">
        <div class="bg"></div>
        <div class="popup">
            <div class="popup-header">
                <h4>{{ 'delete-account.delete_account_popup.title' | translate }}</h4>
            </div>
            <div class="popup-body" *ngIf="!accountDeleted">
                <div class="sub-head">{{ 'delete-account.delete_account_popup.confirm_delete' | translate }}</div>
                <div class="message">
                    {{ 'delete-account.delete_account_popup.description' | translate: { value: user } }}
                </div>
                <div class="confirm-message">
                    {{ 'delete-account.delete_account_popup.type_delete_my_account' | translate }}
                </div>
                <div class="captcha-input">
                    <input type="text" [(ngModel)]="captcha" [class.err]="errors.captcha" />
                    <div class="error" *ngIf="errors.captcha">
                        {{ errors.captcha }}
                    </div>
                </div>
                <div class="actions mt-5 text-right">
                    <button
                        type="button"
                        class="custom-button bg-secondary mr-2"
                        (click)="showDelAccPopUp = false; captcha = ''; errors = {}"
                    >
                        {{ 'delete-account.cancel' | translate }}
                    </button>
                    <button
                        type="button"
                        class="custom-button bg-danger mr-2"
                        [class.disabled]="submitted"
                        (click)="deleteAccount()"
                    >
                        {{ 'delete-account.delete_account' | translate }}
                    </button>
                </div>
            </div>
            <div class="popup-body" *ngIf="accountDeleted">
                <div class="sub-head">{{ 'delete-account.delete_account_popup.account_deleted' | translate }}</div>
                <div class="message">{{ 'delete-account.delete_account_popup.sign_in_back' | translate }}</div>
                <div class="actions mt-5 text-right">
                    <button type="button" class="custom-button bg-secondary mr-2" [routerLink]="['/home']">
                        {{ 'delete-account.close' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Stop Delete Account Confirm PopUp -->
</div>
