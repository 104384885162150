import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoggerService, AppService, RtcService, SocketService, ExternalInterfaceService, UtilService } from '.';

declare const window;

@Injectable({
    providedIn: 'root'
})
export class PexipService {
    [x: string]: any;

    vidyoConnector;
    pexRTC;
    libraryLoaded = false;
    isAudioPublished = false;
    isVideoPublished = false;

    localParticipant = null;
    joinData;
    reconnecting = false;
    isUserOnline = true;
    isCallConnected = false;

    users = {};
    vcs = {};
    microphoneMutedAll = false;
    roomLocked = false;
    recording = false;
    screenSharing = false;
    waitingUsers = {};
    cameras = [];
    microphones = [];
    speakers = [];
    selectedLocalCamera = 0;
    selectedLocalMicrophone = 0;
    selectedLocalSpeaker = 0;

    oldSelectedLocalCamera = 0;
    oldSelectedLocalMicrophone = 0;
    oldSelectedLocalSpeaker = 0;

    private disconneting = false;
    private alreadySharingScreen = false;
    private windowShares = {};
    private whiteboardShared;
    private isOptimizeOnScreenSharingEnabled = false;
    private isJoinRoomCalled: boolean = false;

    private meeting$: BehaviorSubject<any> = new BehaviorSubject(null);
    private chatMessages$: BehaviorSubject<any> = new BehaviorSubject(null);
    private deviceChanged$: BehaviorSubject<any> = new BehaviorSubject(null);
    private participantsStatus$: BehaviorSubject<any> = new BehaviorSubject(null);

    hideScreenShareNotification = new Subject<boolean>();

    reConnectInterval;
    localVolumeIndicator;

    vcsDevices = [];
    //acs variables

    MAX_VIDEO_TILES = 9;
    MAX_VIDEO_TILES_IN_SCREEN_SHARING = 3;

    SPEAKER_LIMIT = 25;

    isConnected = new Subject<boolean>();
    isVideoOn = new Subject<boolean>();

    videoDevicesUpdated = new Subject<boolean>();
    audioDevicesUpdated = new Subject<boolean>();

    localParticipantUpdated = new Subject<boolean>();
    participantAdded = new Subject<any>();
    participantRemoved = new Subject<any>();

    participantStatusUpdated = new Subject<any>();

    meetingObj;

    roomData;
    isHandRaise = false;

    groupId = '';
    callOptionsInternal = {
        audioOptions: {
            isMuted: true,
            audioOutputDeviceId: '',
            audioInputDeviceId: ''
        },
        videoOptions: {
            isVideoOn: false,
            videoDeviceId: ''
        }
    };
    displayName = '';
    streams: any = [];
    streamsCollection = [];
    remoteParticipants = [];
    localVideoStream;
    customCollection = [];

    visibleParticipants = [];
    inVisibleParticipants = [];
    //agora variables
    agoraClient;
    localMicrophoneTrack: any;
    localCameraTrack: any;
    conferenceInfo;

    screenClient: any;

    screenClientUid = 0;

    screenClientToken = '';

    roomInfo;

    currentSenderId = '';

    currentUid = 0;
    currentRoomID = '';
    currentRtmToken = '';

    localScreenTrack;

    isBreakoutRoom = false;

    noMediaPermission = false;

    leaveBreakoutRoom = false;
    agoraToken = '';

    isPostionChecked = false;

    activeSpeakerList = [];

    breakoutRoomInfo;

    localCameraOn = true;
    localMicOn = true;
    isScreenShareAvaiable = false;

    isFitToScreen = true;
    currentConnectionState;
    previousConnectionState;

    reconnectPopupTimer;

    canJoinAsSpeaker = false;

    currentSpeakerCount = 0;

    showedLimitReached = true;

    showHDScreenShareOption = false;
    isEnableHDshare = false;

    userPreferredDevices = {
        micId: null,
        speakerId: null,
        cameraId: null
    };

    private screenShareInfo = {
        isShared: false,
        host: '',
        id: ''
    };

    localFeedUrl;
    remoteFeedUrl;
    screenShareUrl;
    isLocalScreenShareOn = false;
    isRemoteScreenShareOn = false;

    layouts = ['1:7', '4:0', '9:0'];
    layoutIndex = 0;
    pexipLayoutTypeSubject = new Subject<string>();
    layout = '';

    constructor(
        private loggerService: LoggerService,
        private appService: AppService,
        private toastrService: ToastrService,
        private rtcService: RtcService,
        private socketService: SocketService,
        private translateService: TranslateService,
        private externalInterfaceService: ExternalInterfaceService,
        private utilService: UtilService
    ) {
        //this.handleWindowEvents();
        //this.loadPexipSDK();
        this.handleNavigatorEvents();
    }

    getMeetingObs() {
        return this.meeting$;
    }

    getChatMessages() {
        return this.chatMessages$;
    }

    getDeviceCahnge() {
        return this.deviceChanged$;
    }

    getDeviceLoaded() {
        return this.deviceLoaded$;
    }

    getParticipantStatus() {
        return this.participantsStatus$;
    }

    intializeValue = () => {
        this.users = {};
        this.cameras = [];
        this.microphones = [];
        this.speakers = [];
        this.selectedLocalCamera = 0;
        this.selectedLocalMicrophone = 0;
        this.selectedLocalSpeaker = 0;
        this.disconneting = false;
        this.reconnecting = false;
    };

    loadVidyoSDK = () => {
        const pexipjsUrl = this.appService.getConfigVariable('PEXIP_JS_LIB')
            ? this.appService.getConfigVariable('PEXIP_JS_LIB')
            : 'https://conference.jiomeet.com/static/webrtc/js/pexrtc.js';
        if (this.pexRTC) {
            return;
        }
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = pexipjsUrl;
        document.getElementsByTagName('head')[0].appendChild(script);
        script.onload = () => {
            this.onLoad({ state: 'READY', description: 'Native SCIP + WebRTC' });
        };
        script.onerror = (error) => {
            console.log(error);
        };
    };
    private onLoad(status) {
        switch (status.state) {
            case 'READY':
                this.loggerService.log('Library loaded sucessfully.');

                // if (!this.libraryLoaded) {
                this.libraryLoaded = true;
                this.pexRTC = new window.PexRTC();
                window.pexRTC = this.pexRTC;
                this.initializeVidyoConnector();
                // }

                break;
            case 'RETRYING':
                this.loggerService.error('Temporarily unavilable. Retrying...');
                break;
            case 'FAILED':
                // retry here
                this.loggerService.error('Failed to load library');
                this.loggerService.error(status.description);
                alert('An error occurred, please reload');
                break;
            case 'FAILEDVERSION':
                this.loggerService.error(status.description);
                this.loggerService.error(status.downloadPathPlugIn);
                this.loggerService.error(status.downloadPathApp);
                break;
            case 'NOTAVAILABLE':
                this.loggerService.error(status.description);
                break;
            case 'TIMEDOUT':
                this.loggerService.error('Library loading timeout');
                break;
        }
    }

    async initializeVidyoConnector() {
        if (!this.pexRTC) {
            this.loadVidyoSDK();
            return;
        }
        this.getMediaDevices({
            video: true,
            audio: true
        });
    }

    //TODO: Handle audio video permission separately
    async getMediaDevices(cons) {
        let devices = [];
        try {
            // await navigator.mediaDevices.getUserMedia(cons);
            devices = await navigator.mediaDevices.enumerateDevices();
        } catch (e) {
            console.log(e);
            this.noMediaPermission = true;
        }
        this.getDevices(devices);
    }

    getDevices(devices = []) {
        if (devices.length > 0) {
            this.cameras = devices
                .filter((device) => {
                    return device.kind === 'videoinput';
                })
                .map((device) => {
                    return { ...device, id: device.deviceId, name: device.label };
                });
            this.microphones = devices
                .filter((device) => {
                    return device.kind === 'audioinput';
                })
                .map((device) => {
                    return { ...device, id: device.deviceId, name: device.label };
                });
            this.speakers = devices
                .filter((device) => {
                    return device.kind === 'audiooutput';
                })
                .map((device) => {
                    return { ...device, id: device.deviceId, name: device.label };
                });

            this.selectDevices();
            this.pexRTC.audio_source = this.selectedLocalMicrophone;
        }

        this.pexRTC.audio_source = this.noMediaPermission ? null : this.selectedLocalMicrophone;
        this.initPexip();
    }

    initPexip() {
        this.localParticipant = null;
        this.pexRTC.onSetup = this.setupCall.bind(this);
        this.pexRTC.onConnect = this.callConnected.bind(this);
        this.pexRTC.onError = this.onError.bind(this);
        this.pexRTC.onDisconnect = this.endCall.bind(this);
        this.pexRTC.onScreenshareConnected = this.localScreenShareConnected.bind(this);
        this.pexRTC.onScreenshareStopped = this.localScreenShareStopped.bind(this);
        this.pexRTC.onPresentationConnected = this.remoteScreenShareConnected.bind(this);
        this.pexRTC.onPresentationDisconnected = this.remoteScreenShareDisconnected.bind(this);
        this.pexRTC.onPresentation = this.requestRemoteScreenShare.bind(this);
        this.pexRTC.onChatMessage = this.onChatRecieved.bind(this);
        this.pexRTC.onLayoutUpdate = this.onLayoutUpdate.bind(this);
        this.pexRTC.onParticipantCreate = this.onParticipantCreated.bind(this);
        this.pexRTC.onParticipantUpdate = this.onParticipantUpdated.bind(this);
        this.pexRTC.onParticipantDelete = this.onParticipantDeleted.bind(this);
        this.pexRTC.onStageUpdate = this.onStageUpdated.bind(this);
        this.pexRTC.onLog = this.onLogPexip.bind(this);
        this.joinRoom(this.joinData);
    }

    initPexipForBreakout() {
        this.localParticipant = null;
        this.pexRTC.onSetup = this.setupCall.bind(this);
        this.pexRTC.onConnect = this.callConnected.bind(this);
        this.pexRTC.onError = this.onError.bind(this);
        this.pexRTC.onDisconnect = this.endCall.bind(this);
        this.pexRTC.onScreenshareConnected = this.localScreenShareConnected.bind(this);
        this.pexRTC.onScreenshareStopped = this.localScreenShareStopped.bind(this);
        this.pexRTC.onPresentationConnected = this.remoteScreenShareConnected.bind(this);
        this.pexRTC.onPresentationDisconnected = this.remoteScreenShareDisconnected.bind(this);
        this.pexRTC.onPresentation = this.requestRemoteScreenShare.bind(this);
        this.pexRTC.onChatMessage = this.onChatRecieved.bind(this);
        this.pexRTC.onLayoutUpdate = this.onLayoutUpdate.bind(this);
        this.pexRTC.onParticipantCreate = this.onParticipantCreated(this);
        this.pexRTC.onStageUpdate = this.onStageUpdated.bind(this);
    }

    onStageUpdated(stage) {
        // stage". 0 is most recent speaker, 1 is the next most recent etc
        // 0 = not speaking, 100 = speaking
        let rosterList: any = Object.values(this.pexRTC.rosterList) || [];
        let activeSpeaker = '';
        const result = stage.filter((data) => data.stage_index == 0 && data.vad == 100);
        if (result.length != 0) {
            result.forEach((data) => {
                rosterList.filter((roster) => {
                    if (roster.uuid == data.participant_uuid) {
                        activeSpeaker = roster.display_name;
                    }
                });
                this.participantStatusUpdated.next({
                    type: 'isSpeaking',
                    data: {
                        participant_uuid: data.participant_uuid,
                        stage_index: data.stage_index,
                        vad: data.vad,
                        name: activeSpeaker
                    }
                });
            });
        } else {
            this.participantStatusUpdated.next({ type: 'isSpeaking', data: {} });
        }
    }

    getVideoStatus() {
        let videoEnabled = this.pexRTC?.getRosterList()?.filter((user: any) => {
            if (user.is_video_muted == false && user.uuid !== this.localParticipant.id) {
                return true;
            } else {
                return false;
            }
        });
        this.participantStatusUpdated.next({ type: 'videoStatus', data: videoEnabled });
    }

    onLayoutUpdate(view, participants) {
        this.pexipLayoutTypeSubject.next(view.view);
        console.log('Layout changed to', view);
    }

    onError(err) {
        console.log('ERROR:', this.pexRTC.uuid, err, this.pexRTC.error);
        this.isCallConnected = false;
    }

    onLogPexip(err) {
        console.info('Pexip onLog info: ', err, this.pexRTC.uuid);
    }

    endCall(err) {
        console.log(err);
        this.isCallConnected = false;
    }

    setJoinData({
        host,
        displayName,
        roomKey,
        roomPin,
        micPrivacy,
        cameraPrivacy,
        isInitiater = false,
        viewId = 'renderer',
        isEmbedInMobile = false,
        unlockAndJoin = false,
        meetingObj = null,
        userId = ''
    }) {
        this.joinData = {
            host,
            displayName,
            roomKey,
            roomPin,
            micPrivacy,
            cameraPrivacy,
            isInitiater,
            viewId,
            isEmbedInMobile,
            unlockAndJoin,
            meetingObj,
            userId
        };
    }

    userTalkListener(callback): void {}

    async joinRoom({ host, displayName, roomKey, roomPin, micPrivacy, cameraPrivacy }) {
        this.breakoutRoomInfo = this.rtcService.getBreakoutRoomInfo();
        this.conferenceInfo = this.rtcService.getConferenceInfo();
        let pin = this.breakoutRoomInfo ? this.breakoutRoomInfo?.roomInfo?.roomKey : this.conferenceInfo?.room.roomKey;
        if (!pin) {
            setTimeout(() => {
                this.joinRoom({ host, displayName, roomKey, roomPin, micPrivacy, cameraPrivacy });
            }, 100);
            return;
        }

        if (this.isJoinRoomCalled) {
            return;
        }
        this.isJoinRoomCalled = true;

        if (!this.pexRTC) {
            this.pexRTC = new window.PexRTC();
            this.initPexipForBreakout();
        }

        this.pexRTC.presentation_in_main = true;

        this.pexRTC.turn_server = this.appService.getConfigVariable('PEXIP_TURN_SERVER')
            ? this.appService.getConfigVariable('PEXIP_TURN_SERVER')
            : {
                  url: 'turns:turn-34-100-205-199.jiomeetcloud.com:443?transport=tcp',
                  username: 'webrtc',
                  credential: 'stunpassword'
              };
        this.breakoutRoomInfo = this.rtcService.getBreakoutRoomInfo();
        if (this.breakoutRoomInfo) {
            this.isBreakoutRoom = true;
            this.conferenceInfo = this.breakoutRoomInfo;
            this.pexRTC.makeCall(
                this.conferenceInfo?.roomInfo?.roomUrl,
                this.conferenceInfo?.roomInfo?.roomID,
                this.conferenceInfo?.participant?.participantName,
                '1024',
                this.noMediaPermission ? 'recvonly' : ''
            );
        } else {
            this.isBreakoutRoom = false;
            this.pexRTC.makeCall(
                this.conferenceInfo?.room?.roomUrl,
                this.conferenceInfo?.room?.roomID,
                this.conferenceInfo?.joiningName,
                '1024',
                this.noMediaPermission ? 'recvonly' : ''
            );
        }
    }

    setupCall(videoURL, pin_status) {
        this.localFeedUrl = videoURL;
        if (!this.isCallConnected) {
            let participant = {
                id: this.joinData.userId,
                userId: this.joinData.userId,
                name: this.joinData.displayName,
                isLocal: true,
                isHost: this.joinData.isInitiater ? true : false,
                microphoneMute: !this.joinData.micPrivacy,
                cameraMute: !this.joinData.cameraPrivacy,
                participantType: 'speaker'
            };
            this.localParticipant = participant;
            this.meeting$.next({ type: 'VC_CREATED', data: null });
            this.meeting$.next({ type: 'ACTIVE', data: null });
            this.pexRTC.video_source = this.noMediaPermission ? null : this.selectedLocalCamera;
        } else {
            if (videoURL) {
                this.attachVideoFeed('video-localvideo', this.localFeedUrl);
                this.localFeedUrl = videoURL;
            }
        }

        let pin;
        try {
            pin = this.isBreakoutRoom ? this.conferenceInfo?.roomInfo?.roomKey : this.conferenceInfo?.room.roomKey;
            this.pexRTC.connect(pin);
        } catch (err) {
            console.log(err);
        }
    }

    callConnected(videoURL) {
        if (!this.isCallConnected) {
            this.localParticipant.id = this.pexRTC?.uuid;
            this.localParticipant.userId = this.pexRTC?.uuid;
            if (Object.keys(this.pexRTC.rosterList).length > 500) {
                this.meeting$.next({ type: 'MAX_LIMIT_REACHED', data: null });
                return;
            }
            this.pexRTC.muteAudio(this.localParticipant?.microphoneMute);
            this.pexRTC.muteVideo(this.localParticipant?.cameraMute);
            this.meeting$.next({ type: 'LOCAL_PARTICIPANT_CONNECTED', data: null });
            this.isUserOnline = true;
            this.remoteFeedUrl = videoURL;
            this.attachFeedToVideo('video-pexipvideocontainer', videoURL);
            this.attachVideoFeed('video-localvideo', this.localFeedUrl);
            this.isVideoOn.next(!this.localParticipant?.cameraMute);
            this.isCallConnected = true;
            this.toggleLayout('1:7', true);
            if (this.joinData.cameraPrivacy === false) {
                this.stopCamera(document.getElementById('video-localvideo'));
            }
        }
    }

    onParticipantCreated(participant) {
        if (participant?.protocol == 'webrtc') return;
        this.addVC(participant);
        this.sendControlStatus({ participantJoined: true });
    }

    onParticipantUpdated(participant) {
        this.getVideoStatus();
        if (participant?.protocol == 'webrtc') return;
        const index = this.vcsDevices.indexOf((p) => p.uuid === participant.uuid);
        if (this.vcsDevices[index]) {
            this.vcsDevices[index] = participant;
            this.sendControlStatus();
        }
    }

    onParticipantDeleted(uuid) {
        this.vcsDevices = this.vcsDevices.filter((item) => item.uuid !== uuid.uuid);
        this.sendControlStatus({ participantLeft: true });
    }

    private addVC(participant) {
        this.vcsDevices.push(participant);
    }

    toggleVideoVC(participant) {
        participant.is_video_muted
            ? this.pexRTC.videoMuted(participant.uuid)
            : this.pexRTC.videoUnmuted(participant.uuid);
    }

    toggleMicVC(participant) {
        this.pexRTC.setParticipantMute(participant.uuid, participant.is_muted === 'YES' ? false : true);
    }

    disconnectVCparticipant(participant) {
        this.pexRTC.disconnectParticipant(participant.uuid);
    }

    stopCamera(videoElem) {
        const stream = videoElem.srcObject;
        const tracks = stream.getTracks();

        tracks.forEach((track) => {
            if (track.kind === 'video') {
                track.stop();
            }
        });
    }

    localScreenShareConnected(localscreenshareurl) {
        this.isLocalScreenShareOn = true;
        this.screenSharing = true;
        this.toggleLayout('1:0');
        this.participantStatusUpdated.next({ type: 'SHARE_START', data: { stopShare: true } });
        this.getVideoStatus();
        this.sendControlStatus();
    }

    localScreenShareStopped(reason) {
        this.screenSharing = false;
        this.isLocalScreenShareOn = false;
        this.toggleLayout(this.layout);
        this.participantStatusUpdated.next({ type: 'SHARE_STOP', data: { stopShare: true } });
        this.meeting$.next({ type: 'SHARE_STOP', data: { stopShare: true } });
        this.getVideoStatus();
        this.sendControlStatus();
    }

    remoteScreenShareConnected(screenshareurl) {
        this.screenShareUrl = screenshareurl;
        this.isRemoteScreenShareOn = true;
        if (this.whiteboardShared === true) {
            this.meeting$.next({ type: 'WHITEBOARD_STOP', data: null });
        }
        this.toggleLayout('1:0');
        this.getVideoStatus();
    }

    remoteScreenShareDisconnected(reason) {
        this.toggleLayout(this.layout);
        this.participantStatusUpdated.next({ type: 'screenSharingStopped', data: {} });
        if (this.isRemoteScreenShareOn && this.whiteboardShared) {
            // whiteboard override case
            this.participantStatusUpdated.next({ type: 'whiteboardSharingStarted', data: {} });
            this.toggleLayout('1:0');
        }
        if (this.isRemoteScreenShareOn && this.isLocalScreenShareOn) {
            // screenshare override case
            this.participantStatusUpdated.next({ type: 'screenSharingStarted', data: {} });
            this.toggleLayout('1:0');
        }
        this.isRemoteScreenShareOn = false;
        this.attachFeedToVideo('video-pexipvideocontainer', this.remoteFeedUrl);
        this.getVideoStatus();
    }

    requestRemoteScreenShare(setting, presenter, uuid) {
        if (setting) {
            this.toggleLayout('1:0');
            this.participantStatusUpdated.next({ type: 'screenSharingStarted', data: {} });
            this.pexRTC.getPresentation();
            this.participantStatusUpdated.next({ type: 'screenSharingStarted', data: {} });
            this.getVideoStatus();
        }
    }

    attachFeedToVideo(videoId, url) {
        const video = document.getElementById(videoId) as HTMLVideoElement;
        if (typeof MediaStream !== 'undefined' && url instanceof MediaStream) {
            video.srcObject = url;
            video.autoplay = true;
            video.setAttribute('playsInline', 'true');
            video.setAttribute('width', '100%');
            video.setAttribute('height', '100%');
        }
    }

    getUserMediaStatistics() {
        return this.pexRTC?.getMediaStatistics();
    }

    stopStreamedVideo(videoElem) {
        const stream = videoElem.srcObject;
        const tracks = stream.getTracks();

        tracks.forEach((track) => {
            if (track.enabled && track.kind === 'video') {
                track.stop();
            }
        });

        // videoElem.srcObject = null;
    }

    attachSinkId(element, sinkId) {
        if (typeof element?.sinkId !== 'undefined') {
            element
                .setSinkId(sinkId)
                .then(() => {
                    console.log(`Success, audio output device attached: ${sinkId}`);
                })
                .catch((error) => {
                    let errorMessage = error;
                    if (error.name === 'SecurityError') {
                        errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                    }
                    console.error(errorMessage);
                    // Jump back to first output device in the list as it's the default.
                    this.selectedLocalSpeaker = 0;
                });
        } else {
            console.warn('Browser does not support output device selection.');
        }
    }

    changeAudioDestination(videoid, audioDestination) {
        const videoEle = document.getElementById(videoid) as HTMLVideoElement;
        this.attachSinkId(videoEle, audioDestination);
    }

    attachVideoFeed(domid, url) {
        const videocontainer = document.getElementById('localVideo');
        videocontainer.textContent = '';

        if (typeof MediaStream !== 'undefined' && url instanceof MediaStream) {
            const video = document.createElement('video');
            video.setAttribute('id', domid);
            video.srcObject = url;
            //this.remoteFeedUrl = screenshareurl;
            video.autoplay = true;
            video.muted = true;
            video.setAttribute('playsInline', 'true');
            video.loop = true;
            video.setAttribute('width', '100%');
            videocontainer.append(video);

            const nameDiv = document.createElement('div');
            nameDiv.style.position = 'relative';
            nameDiv.style.top = '-34px';
            nameDiv.innerHTML =
                '<div class="text-center"><span style="background:rgba(0,0,0,0.4); font-size: 13px; padding: 3px 6px; border-radius: 3px; text-overflow: ellipsis; overflow: hidden; max-width: 94%; max-height: 25px; display: inline-block;">' +
                (this.localParticipant?.name).trim() +
                '</span></div>';
            videocontainer.append(nameDiv);
        }
    }

    async toggleCameraPrivacy(enable) {
        if (enable) {
            try {
                this.stopStreamedVideo(document.getElementById('video-localvideo'));
            } catch (e) {
                console.log(e);
            }
        } else {
            await this.pexRTC.renegotiate(false);
        }
        this.isVideoOn.next(!enable);

        this.pexRTC.muteVideo(enable);
        if (this.localParticipant) {
            this.localParticipant.cameraMute = enable;
            this.externalInterfaceService.sendCameraStatus(!enable);
        }
        this.sendControlStatus();
    }

    async toggleMicPrivacy(enable) {
        this.pexRTC.muteAudio(enable);
        if (this.localParticipant) {
            this.localParticipant.microphoneMute = enable;
            this.externalInterfaceService.sendMicStatus(!enable);
        }
        this.sendControlStatus();
    }

    toggleLayout(layoutType, layoutChanged?: boolean) {
        if (layoutChanged) this.layout = layoutType;
        this.pexRTC.transformLayout({ layout: layoutType });
    }

    toggleRecordingStatus(enable) {
        this.recording = enable;
        this.sendControlStatus();
    }

    toggleMicAll(enable) {
        this.microphoneMutedAll = enable;
        this.sendControlStatus();
    }

    toggleLockStatus(enable) {
        this.roomLocked = enable;
        this.sendControlStatus();
    }

    setHostDisconnectParticipant(id) {
        if (this.users[id]) {
            this.users[id].disconnectedByHost = true;
        }
    }

    setHostIdentity(sender) {
        if (this.users[sender.id]) {
            this.users[sender.id].isHost = true;
        }
    }

    getAspectRatio() {
        const feedHeight = this.localCameraTrack.getCurrentFrameData().height;
        const feedWidth = this.localCameraTrack.getCurrentFrameData().width;
        return feedWidth / feedHeight;
    }

    async toggleWindowShare(enable) {
        if (enable) {
            try {
                this.pexRTC.present('screen');
                return true;
            } catch (e) {
                console.log(e);

                return false;
            }
        } else {
            this.pexRTC.present(null);
        }

        //this.screenSharing = enable;
    }

    addUserInWaitingRoom(user) {
        if (user.memberName) {
            this.waitingUsers[user.memberId] = user;
            this.sendControlStatus();
        }
    }

    removeUserFromWaitingRoom(user) {
        delete this.waitingUsers[user.memberId];
        this.sendControlStatus();
    }

    setSharingScreenStatus(enable) {
        this.alreadySharingScreen = enable;
    }

    getLocalParticipant() {
        return this.localParticipant;
    }

    private sendControlStatus(updates: any = {}) {
        this.participantsStatus$.next({
            users: this.users,
            microphoneMutedAll: this.microphoneMutedAll,
            roomLocked: this.roomLocked,
            recording: this.recording,
            screenSharing: this.screenSharing,
            waitingUsers: this.waitingUsers,
            alreadySharingScreen: this.alreadySharingScreen,
            vcs: this.vcs,
            participantJoined: updates.participantJoined,
            participantLeft: updates.participantLeft,
            microphoneMute: this.localParticipant?.microphoneMute,
            cameraMute: this.localParticipant?.cameraMute,
            whiteboardSharing: this.whiteboardShared
        });
    }

    private handleNavigatorEvents() {
        navigator.mediaDevices.ondevicechange = async (event) => {
            if (!this.pexRTC) {
                return;
            }
            let devices = [];
            try {
                devices = await navigator.mediaDevices.enumerateDevices();
            } catch (e) {
                console.log('Error while fetching devices: ', e);
                this.noMediaPermission = true;
            }
            if (devices.length > 0) {
                this.microphones = devices
                    .filter((device) => {
                        return device.kind === 'audioinput';
                    })
                    .map((device) => {
                        return { ...device, id: device.deviceId, name: device.label };
                    });
                this.speakers = devices
                    .filter((device) => {
                        return device.kind === 'audiooutput';
                    })
                    .map((device) => {
                        return { ...device, id: device.deviceId, name: device.label };
                    });
                if (this.microphones.length > 1) {
                    this.manuallyChangeMicrophone(this.microphones[1]);
                }
                if (this.speakers.length > 1) {
                    this.manuallyChangeSpeaker(this.speakers[1]);
                }
            }
        };
    }

    private handleWindowEvents() {
        window.onVidyoClientLoaded = (status) => {
            // this.onLoad(status);
        };

        window.onresize = () => {};

        window.onbeforeunload = () => {
            if (this.vidyoConnector) {
                this.vidyoConnector.Destruct();
            }
        };

        window.addEventListener('online', (e) => {
            this.isUserOnline = true;
        });
        window.addEventListener('offline', (e) => {
            this.isUserOnline = false;
        });
    }

    clear() {
        this.localParticipant = {};
        this.joinData = {};
        this.users = {};
        this.microphoneMutedAll = false;
        this.roomLocked = false;
        this.recording = false;
        this.screenSharing = false;
        this.reconnecting = false;
        this.whiteboardShared = false;
        clearInterval(this.reConnectInterval);
    }

    changeCamera() {
        this.vidyoConnector.CycleCamera();
    }

    changeSpeaker() {
        this.vidyoConnector.CycleSpeaker();
    }

    changeMicrophone() {
        this.vidyoConnector.CycleMicrophone();
    }

    async setWhiteboardStatus(state) {
        this.whiteboardShared = state;
        let streamType = this.whiteboardShared ? 1 : 0;
        if (state) {
            this.toggleLayout('1:0');
            this.participantStatusUpdated.next({ type: 'whiteboardSharingStarted', data: {} });
            this.attachFeedToVideo('video-pexipvideocontainersmall', this.remoteFeedUrl);
            this.getVideoStatus();
        } else {
            this.toggleLayout(this.layout);
            this.participantStatusUpdated.next({ type: 'whiteboardSharingStopped', data: {} });
            this.attachFeedToVideo('video-pexipvideocontainer', this.remoteFeedUrl);
            this.getVideoStatus();
        }
        this.isOptimizeOnScreenSharingEnabled && (await this.updateVideoConfig(streamType));
    }

    releaseDevices() {}

    sendChatMessage({ type, message, targetParticipantId = '', targetParticipantName = '', targetHostUserId = '' }) {
        this.pexRTC.sendChatMessage(
            JSON.stringify({
                message: {
                    type,
                    message,
                    targetParticipantId,
                    targetParticipantName,
                    targetHostUserId,
                    sender: this.localParticipant
                }
            })
        );
    }

    onChatRecieved(message) {
        const jsonObject = JSON.parse(message?.payload);
        this.chatMessages$.next({
            message: JSON.stringify(jsonObject?.message),
            sender: jsonObject?.sender,
            receiver: this.localParticipant
        });
    }

    async selectDevices() {
        let speakerId = this.utilService.getCookie('cacheSpeaker');
        let micId = this.utilService.getCookie('cacheMic');
        let cameraId = this.utilService.getCookie('cacheCamera');

        //setting device from url
        if (this.externalInterfaceService.cameraLabel) {
            const camera = this.cameras.find((x) => x.name === this.externalInterfaceService.cameraLabel);
            cameraId = camera.id;
        }
        if (this.externalInterfaceService.micLabel) {
            const mic = this.microphones.find((x) => x.name === this.externalInterfaceService.micLabel);
            micId = mic.id;
        }
        if (this.externalInterfaceService.speakerLabel) {
            const speaker = this.speakers.find((x) => x.name === this.externalInterfaceService.speakerLabel);
            speakerId = speaker.id;
        }

        //setting mic
        if (micId) {
            this.microphones.map(async (mic) => {
                if (mic.id == micId) {
                    this.selectedLocalMicrophone = mic.id;
                }
            });
            if (!this.selectedLocalMicrophone) {
                if (this.microphones.length > 0) this.selectedLocalMicrophone = this.microphones[0].id;
            }
        } else {
            if (this.microphones.length > 0) this.selectedLocalMicrophone = this.microphones[0].id;
        }

        //setting camera
        if (cameraId) {
            this.cameras.forEach((camera) => {
                if (camera.id == cameraId) {
                    this.selectedLocalCamera = camera.id;
                }
            });
            if (!this.selectedLocalCamera) {
                if (this.cameras.length > 0) this.selectedLocalCamera = this.cameras[0].id;
            }
        } else {
            if (this.cameras.length > 0) this.selectedLocalCamera = this.cameras[0].id;
        }

        //setting speaker
        if (speakerId) {
            this.speakers.forEach((speaker) => {
                if (speaker.id === speakerId) {
                    this.selectedLocalSpeaker = speaker.id;
                }
            });
            if (!this.selectedLocalSpeaker) {
                if (this.speakers.length > 0) this.selectedLocalSpeaker = this.speakers[0].id;
            }
        } else {
            this.selectedLocalSpeaker = this.speakers[0].id;
        }

        console.log(this.selectedLocalCamera, this.selectedLocalMicrophone, this.selectedLocalSpeaker);
    }

    async manuallyChangeSpeaker(localSpeaker) {
        document.cookie = `cacheSpeaker=${localSpeaker.id}`;
        this.selectedLocalSpeaker = localSpeaker.id;
        if (this.isRemoteScreenShareOn) {
            this.changeAudioDestination('video-pexipvideocontainersmall', this.selectedLocalSpeaker);
        } else {
            this.changeAudioDestination('video-pexipvideocontainer', this.selectedLocalSpeaker);
        }
    }

    async manuallyChangeMicrophone(localMicrophone) {
        document.cookie = `cacheMic=${localMicrophone.id}`;
        this.selectedLocalMicrophone = localMicrophone.id;

        this.pexRTC.audio_source = this.selectedLocalMicrophone;
        //   if (!this.localParticipant.microphoneMute) {
        try {
            //this.stopStreamedVideo(document.getElementById("video-localvideo"))

            this.pexRTC.renegotiate(false);
        } catch (express) {
            console.log(express);
        }
        //   }
    }

    async manuallyChangeCamera(localCamera) {
        document.cookie = `cacheCamera=${localCamera.id}`;
        this.selectedLocalCamera = localCamera.id;
        this.pexRTC.video_source = this.selectedLocalCamera;
        //  if (!this.localParticipant.cameraMute) {
        try {
            this.stopStreamedVideo(document.getElementById('video-localvideo'));
        } catch (expe) {
            console.log(expe);
        }
        this.pexRTC.renegotiate(false);

        //    }
    }

    flipCamera() {
        const selectedCamera = this.selectedLocalCamera;
        let newCameraId;
        if (this.cameras.length > 1) {
            for (let i = 0; i < this.cameras.length; i++) {
                if (this.cameras[i].id === selectedCamera) {
                    if (i + 1 >= this.cameras.length) newCameraId = this.cameras[0];
                    else newCameraId = this.cameras[i + 1];
                }
            }

            if (newCameraId) {
                this.manuallyChangeCamera(newCameraId);
            } else {
                this.manuallyChangeCamera(this.cameras[0]);
            }
        }
    }

    sendDTMF(numberEntered, uuid) {
        this.pexRTC.sendDTMF(numberEntered, uuid);
    }

    leaveRoom() {
        try {
            this.isJoinRoomCalled = false;
            this.isVideoOn.next(false);
            this.isBreakoutRoom = false;
            this.breakoutRoomInfo = null;
            this.disconneting = true;
            this.isPostionChecked = false;
            this.isCallConnected = false;
            this.pexRTC.disconnect();
            this.pexRTC = null;

            clearInterval(this.localVolumeIndicator);
            // this.localCameraTrack.close();
            // this.localMicrophoneTrack.close();
        } catch (exception) {
            console.log(exception);
        }
        // this.toggleWindowShare(false);
    }

    functionHideScreenShareNotification(state) {
        this.hideScreenShareNotification.next(state);
    }
    stopPreviewTrack() {}
}
