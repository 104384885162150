import { Inject, Injectable, Optional } from '@angular/core';
import { platform } from 'os';
import { AppService, UtilService, SystemService, UserService } from 'src/app/core';

import * as shajs from 'sha.js';
import { Obj } from '@popperjs/core';

@Injectable({
    providedIn: 'root'
})
export class GoogleTagManagerService {
    private isLoaded = false;
    config;
    private browserGlobals = {
        windowRef(): any {
            return window;
        },
        documentRef(): any {
            return document;
        }
    };

    currentUser;
    clientId: String;
    device_id: String;
    env: String;
    deviceType: String;
    os: String;
    jio_platform: String;
    app_version: String = '';
    os_version;

    constructor(
        @Optional()
        @Optional()
        @Inject('googleTagManagerId')
        public googleTagManagerId: string,
        @Optional()
        @Inject('googleTagManagerAuth')
        public googleTagManagerAuth: string,
        @Optional()
        @Inject('googleTagManagerPreview')
        public googleTagManagerPreview: string,
        private appService: AppService,
        private utilService: UtilService,
        private systemService: SystemService,
        private userService: UserService
    ) {
        if (this.config == null) {
            this.config = { id: null };
        }

        this.config = {
            ...this.config,
            id: googleTagManagerId || this.config.id,
            gtm_auth: googleTagManagerAuth || this.config['gtm_auth'],
            gtm_preview: googleTagManagerPreview || this.config['gtm_preview']
        };
        this.clientId = this.getCookie('_ga').split('.').pop();
        this.device_id = this.systemService.getDeviceUUID();
        this.os = window.navigator.userAgent.indexOf('CrOS') != -1 ? 'chromebook' : this.utilService.getOsForGtag();

        this.deviceType = this.utilService.deviceType();
        this.jio_platform = 'jiomeet';
        this.os_version = this.utilService.getOS();
    }

    public initialize(config) {
        if (config?.GTAG_ENABLED) {
            this.config.id = config.GTAG_ID;
            this.env = this.appService.getConfigVariable('production') ? 'prod' : 'dev';
            this.addGtmToDom();
        }
    }

    public getDataLayer() {
        const window = this.browserGlobals.windowRef();
        window['dataLayer'] = window['dataLayer'] || [];
        return window['dataLayer'];
    }

    public pushOnDataLayer(obj: object) {
        const dataLayer = this.getDataLayer();
        dataLayer.push(obj);
    }

    public addGtmToDom() {
        if (this.isLoaded) {
            return;
        }
        const doc = this.browserGlobals.documentRef();
        this.pushOnDataLayer({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        });

        const gtmScript = doc.createElement('script');
        gtmScript.id = 'GTMscript';
        gtmScript.async = true;
        gtmScript.src = this.applyGtmQueryParams('https://www.googletagmanager.com/gtm.js');
        doc.head.insertBefore(gtmScript, doc.head.firstChild);
        this.isLoaded = true;
        this.pushGTMEnv();
    }

    public pushTag(item: object) {
        if (!this.isLoaded) {
            this.addGtmToDom();
        }
        this.pushOnDataLayer(item);
    }

    private applyGtmQueryParams(url: string) {
        if (url.indexOf('?') === -1) {
            url += '?';
        }

        return (
            url +
            Object.keys(this.config)
                .filter((k) => this.config[k])
                .map((k) => `${k}=${this.config[k]}`)
                .join('&')
        );
    }

    pushGTMEnv() {
        this.pushTag({
            env: this.env,
            platform: 'website',
            device: this.deviceType,
            os: this.os,
            app_version: this.app_version,
            os_version: this.os_version,
            jio_platform: this.jio_platform,
            client_id: this.clientId,
            device_id: this.device_id
        });
    }

    getCookie(name: string) {
        const ca: Array<string> = document.cookie.split(';');
        const caLen: number = ca.length;
        const cookieName = `${name}=`;
        let c: string;

        for (let i = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) === 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    encrypt(value) {
        return shajs('sha256').update(value).digest('hex');
    }

    sendEvent(event: Object) {
        this.currentUser = this.userService.getUserSync();
        const userId = this.currentUser._id ? this.encrypt(this.currentUser._id) : 'Guest';
        const isLoggedIn = this.currentUser._id ? true : false;

        event = Object.assign(event, {
            user_id: userId,
            meeting_id: this.appService.meetingId ? this.encrypt(this.appService.meetingId) : '',
            call_id: this.appService.historyId ? this.encrypt(this.appService.historyId) : '',
            guest_user_id: this.appService.guestUserId ? this.encrypt(this.appService.guestUserId) : '',
            call_member_id: this.appService.callMemberId ? this.encrypt(this.appService.callMemberId) : '',
            livestream_id: this.appService.liveStreamId ? this.encrypt(this.appService.liveStreamId) : '',
            loggedin_status: isLoggedIn
        });
        if (this.appService.getConfigVariable('isGAEventsCaptureEnabled') && 'event_label' in event) {
            this.pushTag(event);
        }
        if ('outcome' in event) {
            this.pushTag(event);
        }
    }
}
