import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService, UserService } from 'src/app/core';
import { PrimeNGConfig } from 'primeng/api';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';

@Component({
    selector: 'app-delete-account',
    templateUrl: './delete-account.component.html',
    styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit {
    showDelAccPopUp = false;
    reasons = [
        {
            name: this.translate.instant('delete-account.choose_reason.option1'),
            value: 'I did not like overall experience'
        },
        {
            name: this.translate.instant('delete-account.choose_reason.option2'),
            value: 'I am shifting to another video conferencing application'
        },
        {
            name: this.translate.instant('delete-account.choose_reason.option3'),
            value: "I don't see myself using JioMeet in future"
        },
        { name: this.translate.instant('delete-account.choose_reason.option4'), value: 'other' }
    ];
    form;
    captcha = '';
    submitted = false;
    pageReadyToRender = false;
    accountDeleted = false;
    jwt = '';
    user = '';
    errors: any = {};
    lang = '';

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private loggerService: AppLoggerService,
        private userService: UserService,
        private translate: TranslateService,
        private appService: AppService,
        private config: PrimeNGConfig
    ) {}

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (!params.id || !params.u) {
                this.router.navigate(['/home']);
            } else {
                this.jwt = params.id;
                this.user = params.u;
                if (params?.lang) {
                    this.lang = params?.lang;
                }
            }
        });
        this.translate.onLangChange.subscribe(() => {
            if (this.lang != '') {
                this.setLan(this.lang);
            }
            this.reasons = [
                {
                    name: this.translate.instant('delete-account.choose_reason.option1'),
                    value: 'I did not like overall experience'
                },
                {
                    name: this.translate.instant('delete-account.choose_reason.option2'),
                    value: 'I am shifting to another video conferencing application'
                },
                {
                    name: this.translate.instant('delete-account.choose_reason.option3'),
                    value: "I don't see myself using JioMeet in future"
                },
                { name: this.translate.instant('delete-account.choose_reason.option4'), value: 'other' }
            ];
        });
        this.form = this.fb.group({
            reason: ['', [Validators.required]],
            acknowledge_check: [false, [Validators.required]],
            otherReason: ['']
        });
    }

    onChangeReason(e) {
        if (e?.value !== 'other') {
            this.form.controls['otherReason'].setValue('');
        }
    }

    validateFormWithOthers() {
        return (
            this.form?.value?.reason === 'other' &&
            (this.form?.value?.otherReason === '' || this.form?.value?.otherReason?.length > 200)
        );
    }

    deleteAccount() {
        if (this.captcha.trim().length === 0 || this.captcha != 'DeLete My AccoUnt') {
            this.errors.captcha = this.translate.instant('delete-account.delete_account_popup.captcha_error');
            return false;
        }
        this.errors = {};
        this.submitted = true;
        const options = {
            headers: new HttpHeaders({
                Authorization: 'Bearer  ' + this.jwt,
                'Content-Type': 'application/json'
            }),
            body: {
                acknowledged: true,
                yourFeedback:
                    this.form?.value?.reason === 'other' ? this.form?.value?.otherReason : this.form?.value?.reason
            }
        };
        this.userService.deleteUser(options).subscribe(
            (data) => {
                this.accountDeleted = true;
                this.submitted = false;
            },
            (err) => {
                this.errors.captcha = this.translate.instant('tostrs.something_went_wrong_please_try_again');
                this.loggerService.log('Unable to delete user', err);
                this.submitted = false;
            }
        );
    }

    setLan(lang) {
        this.appService.getAndSaveUserPreferredLang(lang);
        this.translate.use(lang);
        this.translate.get('primeng').subscribe((res) => {
            this.config.setTranslation(res);
        });
    }
}
