<div class="event-container">
    <div class="img">
        <img [src]="eventDetail.img" alt="JioMeet Event" />
    </div>
    <div class="event-info">
        <div class="title">{{ eventDetail?.title }}</div>
        <div class="desc">{{ eventDetail?.desc }}</div>
        <div class="read">
            <span class="read-more">Read More</span>
            <img src="assets/img/chevron-left.png" />
        </div>
    </div>
</div>
