import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import {
    AuthService,
    ValidationService,
    AppService,
    RememberMeService,
    UtilService,
    GaDiagnosticService,
    DiagnosticService,
    LocalStorageService,
    EventEmitterService,
    EventData,
    GoogleTagManagerService,
    RestService,
    DesktopAppService,
    UserService
} from 'src/app/core';
import { REG_EX, APP_EVENTS } from 'src/app/constants';
import { GA_EVENTS } from '../ga-events';
import { GA4_EVENTS } from '../ga4-events';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    constructor(
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private router: Router,
        private toastrService: ToastrService,
        private appService: AppService,
        private authService: AuthService,
        private validationService: ValidationService,
        private rememberMeService: RememberMeService,
        private gaService: GaDiagnosticService,
        private diagnosticService: DiagnosticService,
        private utilService: UtilService,
        private localStorageService: LocalStorageService,
        private translateService: TranslateService,
        private eventEmitterService: EventEmitterService,
        private gTagService: GoogleTagManagerService,
        private restService: RestService,
        private desktopAppService: DesktopAppService,
        private userService: UserService
    ) {}

    email: any;
    usernameForm: FormGroup;
    loginForm: FormGroup;
    ottForm: FormGroup;
    errors: any = {};
    rememberMe = false;
    loggingIn = false;
    showPassword = false;
    childWindow = null;
    samlLoginInProgress = false;
    isDomainEmail = false;
    emailLogin = false;
    ottLogin = false;
    requestingOTP = false;
    sessionId;
    token;
    success;
    enableProceedBtn = false;
    bot;
    outlook;
    google;
    googleRedirectURI;
    googleCalendarId;
    returnUrl;
    userNotFound = false;
    loading = true;
    @Output() closePopup = new EventEmitter();
    bannersConfigData;
    isLoading: boolean = false;
    showPopup: boolean = false;
    userName: string = '';
    paymentButtonDisabled: boolean = true;
    invalidPincode: boolean = false;
    city: string = 'Your city';
    state: string = 'Your state';
    pincode: string = '';
    userEmail;
    showPincodeError: boolean = false;

    private subscriptions: Subscription[] = [];

    fbVisibility = false;
    googleVisibility = true;

    // for windows social login

    ngOnInit() {
        // this.router.routeReuseStrategy.shouldReuseRoute = () => {
        //   return false;
        // };
        this.configureSocialLoginVisibility();
        this.bannersConfigData = this.appService.getConfigVariable('BANNERS_CONFIG');
        this.activatedRoute.queryParams.subscribe((params) => {
            this.sessionId = params.sessionId;
            this.token = params.token;
            this.success = params.success;
            this.bot = params.bot;
            this.outlook = params.outlook;
            this.google = params.google;
            this.googleRedirectURI = params.googleRedirectURI;
            this.googleCalendarId = params.googleCalendarId;
            if (this.bot === 'true') {
                sessionStorage.setItem('bot', 'true');
            }
            if (this.outlook === 'true') {
                // Session storage does not work as expected in IE and Safari
                this.localStorageService.addItem('outlook', 'true');
            }
            this.returnUrl = params.returnUrl;
            if (this.returnUrl) {
                sessionStorage.setItem('returnUrl', this.returnUrl);
            }

            if (this.google === 'true') {
                sessionStorage.setItem('google', 'true');
                sessionStorage.setItem('googleRedirectURI', this.googleRedirectURI);
            }

            if (params?.applicationError === 'MEDIANOTFOUND') {
                this.toastrService.info(this.translateService.instant('previousmeeting.recording_not_found'));
            }
        });
        this.usernameForm = this.fb.group({
            username: ['', [Validators.required]]
        });
        this.loginForm = this.fb.group({
            email: [this.email ? this.email : '', [Validators.required]],
            password: [null, [Validators.required]],
            rememberMe: [false]
        });
        this.ottForm = this.fb.group({
            phoneNo: [null, [Validators.required, Validators.pattern(/^\d{10}$/)]],
            otp: [null],
            rememberMe: [false]
        });

        this.activatedRoute.queryParams.subscribe(async (params) => {
            if (params?.email) {
                if (this.checkForDomainEmail(params?.email)) {
                    this.usernameForm.get('username').setValue(params?.email);
                    this.enableProceedBtn = await this.validateUsername(params?.email, true);
                    this.rememberMeService.set('forcedlogindata', {
                        email: params?.email,
                        meetingId: params?.meetingId,
                        pwd: params?.pwd
                    });
                }
            }

            if (params?.email) {
                this.emailLogin = true;
                this.loginForm.get('email').setValue(params.email);
            }
        });

        this.usernameForm
            .get('username')
            .valueChanges // .pipe(pairwise())
            .subscribe((next) => {
                this.checkForDomainEmail(next);
                this.enableProceedBtn = this.validateUsername(next, false);
            });

        const rememberedUserData = this.rememberMeService.get('rememberMe');
        if (rememberedUserData) {
            // this.loginForm.get('email').setValue(rememberedUserData.email);
            this.usernameForm.get('username').setValue(rememberedUserData.username);
            this.loginForm.get('rememberMe').setValue(true);

            this.checkForDomainEmail(rememberedUserData.username);
            this.enableProceedBtn = this.validateUsername(rememberedUserData.username, true);
        }

        this.loginForm.get('email').valueChanges.subscribe((email) => {
            this.checkForDomainEmail(email);
        });

        if (this.authService.getIsAuthenticated()) {
            this.navigateToLandingPage();
            return;
        }

        if (this.sessionId) {
            if (!this.success || this.success === 'false') {
                this.errors.global = this.translateService.instant('signin_page.login_failed');
                this.samlLoginInProgress = false;
                this.loading = false;
                return;
            }
            this.samlLoginInProgress = true;
            this.authService.getSamlAccessToken(this.sessionId, this.token).subscribe(
                () => {
                    this.samlLoginInProgress = false;
                    const flData = this.rememberMeService.get('forcedlogindata');
                    if (flData?.email) {
                        this.rememberMeService.remove('forcedlogindata');
                        window.location.href = `${window.location.origin}/join?meetingId=${flData?.meetingId}&pwd=${flData?.pwd}&e=${flData?.email}`;
                    } else {
                        this.navigateToLandingPage();
                    }
                },
                (err) => {
                    if (err.error && err.error.errors) {
                        this.errors.global = err.error.errors;
                    } else {
                        this.errors.global = this.translateService.instant('signin_page.something_went_wrong');
                    }
                    this.samlLoginInProgress = false;
                    this.loggingIn = false;
                }
            );
            return;
        }

        this.gaService.sendPageView({
            page_title: 'login_page',
            page_path: '/login'
        });

        this.loading = false;

        this.subscriptions.push(
            this.eventEmitterService.subscribe((event: EventData) => {
                if (event.type === APP_EVENTS.LOGIN_NAVIGATION) {
                    this.resetLogin();
                }
            })
        );
        this.city = 'Your city';
        this.state = 'Your state';
    }

    async checkForDomainEmail(email) {
        this.isDomainEmail = false;
        if (this.validationService.isEmail(email)) {
            const providerName = this.appService
                .getConfigVariable('SAML_DOMAINS')
                .includes(this.utilService.getDomainFromEmail(email))
                ? 'saml'
                : this.utilService.getDomainFromEmail(email);

            // this.authService.isDomainUser({ providerName }).subscribe(() => {
            //   this.isDomainEmail = true;
            // });
            try {
                this.isDomainEmail = await this.authService.isDomainUser({ providerName }).toPromise();
            } catch (err) {
                console.log(err);
            }
        }
    }

    configureSocialLoginVisibility() {
        this.fbVisibility = this.appService.getConfigVariable('show_facebook_login') || false;
        this.googleVisibility = this.appService.getConfigVariable('show_google_login') || true;
    }

    validateUsername(value, reportError = true) {
        // const val = value || this.usernameForm.value.username;
        if (value && value?.match(/^([+]91)\d{10}$/)) {
            value = value.substring(3);
        }
        if (!value || !(this.validationService.isEmail(value) || value?.match(/^\d{10}$/))) {
            if (reportError) {
                this.errors.username = 'Please enter valid email or phoneNo.';
            }
            return false;
        }
        return true;
    }

    async submitUsername() {
        setTimeout(() => {
            const popUpElement: any = document.getElementsByClassName('input-control')[0];
            if (popUpElement) {
                popUpElement.focus();
            }
        }, 100);
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translateService.instant('tostrs.something_went_right'));
            return;
        }
        this.userNotFound = false;
        this.errors = {};
        if (!this.validateUsername(this.usernameForm.value.username)) {
            return;
        }
        if (this.validationService.isEmail(this.usernameForm.value.username)) {
            await this.checkForDomainEmail(this.usernameForm.value.username);
            if (this.isDomainEmail) {
                this.gTagService.sendEvent(GA_EVENTS.SIGNIN_WITH_COMPANY_DOMAIN);
                this.gTagService.sendEvent(GA4_EVENTS.SIGNIN_WITH_COMPANY_DOMAIN);
                if (
                    this.appService
                        .getConfigVariable('SAML_DOMAINS')
                        .includes(this.utilService.getDomainFromEmail(this.usernameForm.value.username))
                ) {
                    this.authService
                        .doSSOLogin({
                            queryParams: {
                                email: this.usernameForm.value.username,
                                bot: this.bot,
                                hostInfo: null,
                                outlook: false
                            },
                            externalIntegrationParams: {
                                google: this.google,
                                googleRedirectURI: this.googleRedirectURI
                            }
                        })
                        .subscribe((res) => {
                            if (this.desktopAppService.isDesktopApp) {
                                this.launchAuthUrl(res);
                            } else {
                                window.location.href = res.authUrl;
                            }
                        });
                } else {
                    this.authService
                        .doSSOLogin({
                            providerName: this.utilService.getDomainFromEmail(this.usernameForm.value.username),
                            queryParams: {
                                email: null,
                                bot: this.bot,
                                hostInfo: null,
                                outlook: false
                            },
                            externalIntegrationParams: {
                                google: this.google,
                                googleRedirectURI: this.googleRedirectURI
                            }
                        })
                        .subscribe((res) => {
                            if (this.desktopAppService.isDesktopApp) {
                                this.launchAuthUrl(res);
                            } else {
                                window.location.href = res.authUrl;
                            }
                        });
                }
                return;
            }
            this.authService.isOTTUser({ email: this.usernameForm.value.username }).subscribe(
                (res: any) => {
                    if (res.isOTT && res.source === 'google') {
                        this.loginWithSSO('google');
                    } else {
                        this.emailLogin = true;
                        this.loginForm.patchValue({
                            email: this.usernameForm.value.username
                        });
                        this.gTagService.sendEvent(GA_EVENTS.SIGNIN_WITH_EMAIL);
                        this.gTagService.sendEvent(GA4_EVENTS.SIGNIN_WITH_EMAIL);
                        // }
                        this.focusPasswordField();
                    }
                },
                (err) => {
                    this.gTagService.sendEvent(GA_EVENTS.SIGNIN_WITH_EMAIL_FAIL);
                    this.gTagService.sendEvent(GA4_EVENTS.SIGNIN_WITH_EMAIL_FAIL);
                    if (err.error.message === 'USERNOTFOUND') {
                        this.userNotFound = true;
                        setTimeout(() => {
                            const invaliemail: any = document.querySelector('.invalidemailerror');
                            if (invaliemail) {
                                invaliemail?.focus();
                            }
                        }, 100);
                        // this.errors.global = 'You are not registered. Please signup.';
                    } else {
                        this.errors.global = err.error.errors;
                    }
                }
            );

            return;
        }

        if (this.usernameForm.value.username.match(/^([+]91)?\d{10}$/)) {
            this.gTagService.sendEvent(GA_EVENTS.SIGNIN_WITH_MOBILE);
            this.gTagService.sendEvent(GA4_EVENTS.SIGNIN_WITH_MOBILE);

            let phoneNo = this.usernameForm.value.username;
            if (phoneNo.match(/^([+]91)\d{10}$/)) {
                phoneNo = phoneNo.substring(3);
            }

            this.authService.isOTTUser({ phoneNo: phoneNo }).subscribe(
                (res: any) => {
                    // if (res.isOTT) {
                    this.sendOTP(phoneNo);
                    // }
                },
                (err) => {
                    this.gTagService.sendEvent(GA_EVENTS.SIGNIN_WITH_MOBILE_FAIL);
                    this.gTagService.sendEvent(GA4_EVENTS.SIGNIN_WITH_MOBILE_FAIL);
                    if (err.error.message === 'USERNOTFOUND') {
                        this.userNotFound = true;
                        // this.errors.global = 'You are not registered. Please signup.';
                    } else {
                        this.errors.global = err.error.errors;
                    }
                }
            );
        }
    }

    focusPasswordField() {
        setTimeout(() => {
            document.getElementById('password').focus();
        });
    }

    validate() {
        this.errors = {};

        if (!this.validationService.isEmail(this.loginForm.value.email)) {
            this.errors.email = this.translateService.instant('signin_page.please_enter_a_valid_email_id');
            return false;
        }
        if (!this.isDomainEmail && !this.loginForm.value.password) {
            this.errors.password = this.translateService.instant('signin_page.password_blank');
            return false;
        }
        return true;
    }

    submitEmailPassword() {
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translateService.instant('tostrs.something_went_right'));
            return;
        }
        if (!this.validate()) {
            return;
        }

        if (this.loginForm.value.rememberMe) {
            this.rememberMeService.set('rememberMe', {
                username: this.loginForm.value.email
            });
        } else {
            this.rememberMeService.remove('rememberMe');
        }

        this.gTagService.sendEvent(GA4_EVENTS.SIGNIN_WITH_EMAIL_PASSWORD_PAGE);

        if (this.isDomainEmail) {
            this.gTagService.sendEvent(GA_EVENTS.VERIFY_PASSWORD);
            this.gTagService.sendEvent(GA4_EVENTS.SIGNIN_WITH_EMAIL_PASSWORD_PAGE);
            if (
                this.appService
                    .getConfigVariable('SAML_DOMAINS')
                    .includes(this.utilService.getDomainFromEmail(this.loginForm.value.email))
            ) {
                this.authService
                    .doSSOLogin({
                        queryParams: {
                            email: this.loginForm.value.email,
                            bot: this.bot,
                            hostInfo: null,
                            outlook: false
                        },
                        externalIntegrationParams: {
                            google: this.google,
                            googleRedirectURI: this.googleRedirectURI
                        }
                    })
                    .subscribe((res) => {
                        if (this.desktopAppService.isDesktopApp) {
                            this.launchAuthUrl(res);
                        } else {
                            window.location.href = res.authUrl;
                        }
                    });
            } else {
                this.authService
                    .doSSOLogin({
                        providerName: this.utilService.getDomainFromEmail(this.loginForm.value.email),
                        queryParams: {
                            email: null,
                            bot: this.bot,
                            hostInfo: null,
                            outlook: false
                        },
                        externalIntegrationParams: {
                            google: this.google,
                            googleRedirectURI: this.googleRedirectURI
                        }
                    })
                    .subscribe((res) => {
                        if (this.desktopAppService.isDesktopApp) {
                            this.launchAuthUrl(res);
                        } else {
                            window.location.href = res.authUrl;
                        }
                    });
            }
        } else {
            this.loggingIn = true;
            this.authService.login(this.loginForm.value).subscribe(
                (res) => {
                    this.loggingIn = false;
                    if (res.success === false) {
                        this.gTagService.sendEvent(GA_EVENTS.VERIFY_PASSWORD_FAIL);
                        this.gTagService.sendEvent(GA4_EVENTS.SIGNIN_WITH_EMAIL_PASSWORD_PAGE_FAILED);
                        if (res.message === 'INVALIDCREDENTIALS') {
                            setTimeout(() => {
                                const ErrorFocusEle: any = document.querySelector('.errorfocus');
                                if (ErrorFocusEle) {
                                    ErrorFocusEle?.focus();
                                }
                            }, 100);

                            this.errors.global = this.translateService.instant('signin_page.incorrect_password');
                        } else {
                            this.errors.global = res.errors;
                            if (res.message === 'ACTIVATIONERROR') {
                                this.toastrService.info(
                                    'We have sent a verification link to your registered Email. Please verify to login.'
                                );
                            }
                        }
                        this.diagnosticService
                            .sendEvent({
                                eventCategory: 'Sign In',
                                eventAction: 'Sign In with Email',
                                eventType: 'api_failure',
                                endpoint: '/api/user/login',
                                status: 'failed',
                                data: res
                            })
                            .subscribe();
                        return;
                    }
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign In',
                            eventAction: 'Sign In with Email',
                            eventType: 'app_event',
                            endpoint: '/api/user/login',
                            status: 'success',
                            data: { email: this.loginForm.value.email }
                        })
                        .subscribe();
                    this.gTagService.sendEvent(GA_EVENTS.VERIFY_PASSWORD);
                    this.gTagService.sendEvent(GA4_EVENTS.SIGNIN_WITH_EMAIL_PASSWORD_PAGE);
                    this.navigateToLandingPage();
                },
                (err) => {
                    this.gTagService.sendEvent(GA_EVENTS.VERIFY_PASSWORD_FAIL);
                    this.gTagService.sendEvent(GA4_EVENTS.SIGNIN_WITH_EMAIL_PASSWORD_PAGE_FAILED);
                    this.errors.global = this.translateService.instant('signin_page.incorrect_email');
                    // this.errors.global = err.error.errors;
                    this.loggingIn = false;
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign In',
                            eventAction: 'Sign In with Email',
                            eventType: 'api_failure',
                            endpoint: '/api/user/login',
                            status: 'failed'
                        })
                        .subscribe();
                }
            );
        }
    }

    launchAuthUrl(res) {
        if (this.desktopAppService.isDesktopApp) {
            this.desktopAppService.launchAuthWindow(res.authUrl, (url) => {
                console.log(`auth callback url is ${url}`);
                this.validateAndGoToLanding(url);
            });
        } else {
            window.location.href = res.authUrl;
        }
    }

    validateAndGoToLanding(url: string) {
        var parsedUrl = new URL(url);
        this.sessionId = parsedUrl.searchParams.get('sessionId');
        this.token = parsedUrl.searchParams.get('token');
        this.success = parsedUrl.searchParams.get('success');
        if (!this.success || this.success === 'false') {
            this.errors.global = 'Login failed. Please try again later.';
            this.samlLoginInProgress = false;
            this.loading = false;
            return;
        }
        this.samlLoginInProgress = true;
        this.authService.getSamlAccessToken(this.sessionId, this.token).subscribe(
            () => {
                this.samlLoginInProgress = false;
                this.navigateToLandingPage();
            },
            (err) => {
                if (err.error && err.error.errors) {
                    this.errors.global = err.error.errors;
                } else {
                    this.errors.global = 'Something went wrong. Please try again.';
                }
                this.samlLoginInProgress = false;
                this.loggingIn = false;
            }
        );
        return;
    }

    disableOttLoginForm() {
        if (!(this.ottForm.value.otp && (this.ottForm.value.otp || '').match(/^\d{6}$/))) {
            return true;
        }
        return false;
    }

    verifyOTP() {
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translateService.instant('tostrs.something_went_right'));
            return;
        }
        if (this.ottForm.value.rememberMe) {
            this.rememberMeService.set('rememberMe', {
                username: this.ottForm.value.phoneNo
            });
        } else {
            this.rememberMeService.remove('rememberMe');
        }
        this.gTagService.sendEvent(GA_EVENTS.VERIFY_OTP);
        this.errors = {};
        this.loggingIn = true;
        this.authService
            .verifyOTP({
                phoneNo: this.ottForm.value.phoneNo,
                otp: this.ottForm.value.otp
            })
            .subscribe(
                (res: any) => {
                    this.errors = {};
                    this.loggingIn = false;
                    if (res.success === false || res.errors || res.message === 'OTPAUTHERROR') {
                        this.gTagService.sendEvent(GA4_EVENTS.VERIFY_OTP_FAILED);
                        setTimeout(() => {
                            const OTPerrorFocusEle: any = document.querySelector('.otperror');
                            if (OTPerrorFocusEle) {
                                OTPerrorFocusEle?.focus();
                            }
                        }, 100);

                        res.message === 'OTPAUTHERROR'
                            ? (this.errors.otp = this.translateService.instant('signin_page.incorrect_otp'))
                            : (this.errors.global = res.errors);
                        this.diagnosticService
                            .sendEvent({
                                eventCategory: 'Sign In',
                                eventAction: 'Verify OTP',
                                eventType: 'api_failure',
                                endpoint: '/api/verifyotp',
                                status: 'failed',
                                data: res
                            })
                            .subscribe();
                        return;
                    }
                    this.gTagService.sendEvent(GA4_EVENTS.VERIFY_OTP);
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign In',
                            eventAction: 'Verify OTP',
                            eventType: 'app_event',
                            endpoint: '/api/verifyotp',
                            status: 'success',
                            data: { phoneNo: res.phoneNo }
                        })
                        .subscribe();
                    if (res.token) {
                        this.authService
                            .verifyOtpDualLogin({
                                phoneNo: this.ottForm.value.phoneNo,
                                otp: this.ottForm.value.otp,
                                token: res.token
                            })
                            .subscribe(() => {
                                this.navigateToLandingPage();
                            });
                    } else {
                        this.navigateToLandingPage();
                    }
                },
                (err) => {
                    this.gTagService.sendEvent(GA4_EVENTS.VERIFY_OTP_FAILED);
                    this.errors.global =
                        err.error?.message === 'LIMITEXCEEDED'
                            ? this.translateService.instant('signin_page.try_again', { value: 15 })
                            : this.translateService.instant('signin_page.incorrect_otp');

                    this.loggingIn = false;
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign In',
                            eventAction: 'Verify OTP',
                            eventType: 'api_failure',
                            endpoint: '/api/verifyotp',
                            status: 'failed'
                        })
                        .subscribe();
                }
            );
    }

    resendOTP() {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Sign In',
                eventAction: 'Resend OTP',
                eventType: 'app_event',
                endpoint: '/api/getotp',
                status: 'success'
            })
            .subscribe();
        this.getOTP();
    }

    changePhoneNo() {
        this.ottForm.reset();
        this.requestingOTP = true;
    }

    getOTP() {
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translateService.instant('tostrs.something_went_right'));
            return;
        }
        this.gTagService.sendEvent(GA_EVENTS.RESEND_OTP);
        this.gTagService.sendEvent(GA4_EVENTS.RESEND_OTP);
        this.errors = {};
        if (!this.ottForm.value.phoneNo.match(/^\d{10}$/)) {
            this.errors.phoneNo = 'Please enter valid 10 digit mibile no.';
            return;
        }

        this.sendOTP(this.ottForm.value.phoneNo);
    }

    private sendOTP(phoneNo) {
        this.loggingIn = true;
        this.authService
            .getOtp({
                phoneNo
            })
            .subscribe(
                (res: any) => {
                    this.loggingIn = false;
                    if (res.status === 'success') {
                        this.ottLogin = true;
                        this.requestingOTP = false;
                        this.ottForm.patchValue({
                            phoneNo
                        });
                    }
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign In',
                            eventAction: 'Sign In with Mobile',
                            eventType: 'app_event',
                            endpoint: '/api/getotp',
                            status: 'success',
                            data: { phoneNo: res.phoneNo }
                        })
                        .subscribe();
                },
                (err) => {
                    this.diagnosticService
                        .sendEvent({
                            eventCategory: 'Sign In',
                            eventAction: 'Sign In with Mobile',
                            eventType: 'api_failure',
                            endpoint: '/api/getotp',
                            status: 'failed',
                            data: err.error
                        })
                        .subscribe();
                    if (err.error.customCode === 400) {
                        this.errors.global = this.translateService.instant('signin_page.try_again', {
                            value: 15
                        });
                        this.loggingIn = false;
                        return;
                    }

                    if (err.error && err.error.errors) {
                        this.errors.global = err.error.errors;
                    } else {
                        this.errors.global = err.error;
                    }
                    this.loggingIn = false;
                }
            );
    }

    showInviteCodePopup() {
        this.router.navigate(['/signup'], { relativeTo: this.activatedRoute });
    }

    navToForgotPassword() {
        this.gTagService.sendEvent(GA_EVENTS.FORGOT_PASSWORD);
        this.gTagService.sendEvent(GA4_EVENTS.FORGOT_PASSWORD);
        this.router.navigate(['forgot-password']);
    }

    loginWithSSO(providerName) {
        if (providerName === 'facebook') {
            this.gTagService.sendEvent(GA_EVENTS.SIGNIN_WITH_FACEBOOK);
            this.gTagService.sendEvent(GA4_EVENTS.SIGNIN_WITH_FACEBOOK);
        } else if (providerName === 'google') {
            this.gTagService.sendEvent(GA_EVENTS.SIGNIN_WITH_GOOGLE);
            this.gTagService.sendEvent(GA4_EVENTS.SIGNIN_WITH_GOOGLE);
        }
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translateService.instant('tostrs.something_went_right'));
            return;
        }

        this.errors = {};
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Sign In',
                eventType: 'app_event',
                eventAction: `Sign In with ${providerName}`,
                endpoint: `/api/authorize/${providerName}`,
                status: 'success'
            })
            .subscribe();

        this.authService
            .doSSOLogin({
                providerName,
                queryParams: {
                    email: null,
                    bot: this.bot,
                    hostInfo: null,
                    outlook: false
                },
                externalIntegrationParams: {
                    google: this.google,
                    googleRedirectURI: this.googleRedirectURI
                }
            })
            .subscribe((res) => {
                if (this.desktopAppService.isDesktopApp) {
                    this.launchAuthUrl(res);
                } else {
                    window.location.href = res.authUrl;
                }
            });
    }

    launchTnc() {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Website Home Options',
                eventType: 'app_event',
                eventAction: 'Terms and Conditions',
                status: 'success'
            })
            .subscribe();
        window.open(this.appService.getEnvVariable('HOST_URL') + 'terms-condition-business');
    }

    launchPrivacy() {
        this.diagnosticService
            .sendEvent({
                eventCategory: 'Website Home Options',
                eventType: 'app_event',
                eventAction: 'Privacy Policy',
                status: 'success'
            })
            .subscribe();
        window.open(this.appService.getEnvVariable('HOST_URL') + 'privacy-policy-business');
    }

    navigateToLandingPage() {
        if (sessionStorage.getItem('bot') === 'true') {
            sessionStorage.removeItem('bot');
            this.router.navigate(['teams-bot']);
        } else if (this.localStorageService.getItem('outlook')) {
            this.localStorageService.removeItem('outlook');
            const hostInfo = this.localStorageService.getItem('outlookHostInfo');
            let queryParam = '?chatbot=false';

            if (hostInfo) {
                queryParam += '&__host_Info=' + hostInfo;
            }

            // this.router.navigate([`teams-bot/outlook${queryParam}`]);
            window.location.href = `${window.location.origin}/teams-bot/outlook?${queryParam}`;
        } else if (sessionStorage.getItem('google') === 'true') {
            sessionStorage.removeItem('google');
            const userInfo = this.localStorageService.getItem('userInfo');
            const googleRedirectURI = sessionStorage.getItem('googleRedirectURI');
            window.location.href = `${googleRedirectURI}?jwt=${userInfo.jwt}&refToken=${userInfo.refToken}&calendarId=${this.googleCalendarId}`;
        } else {
            const returnUrl = sessionStorage.getItem('returnUrl') || sessionStorage.getItem('jmc-s.returnUrl');
            if (returnUrl) {
                this.returnUrl = decodeURIComponent(returnUrl);
                sessionStorage.removeItem('returnUrl');
                sessionStorage.removeItem('jmc-s.returnUrl');
                this.returnUrl = returnUrl.replace(/^"|"$/g, '');
                if (this.returnUrl === '/pricing?newFlow=true') {
                    this.restService
                        .get(`api/my_profile`, {
                            header: { Authorization: this.activatedRoute.snapshot.queryParams?.userToken }
                        })
                        .subscribe((currentUser) => {
                            this.userName = currentUser.name + ' ' + (currentUser.lname ? currentUser.lname : '');
                            this.userEmail = currentUser.email === undefined ? currentUser.phoneNo : currentUser.email;
                            this.pincode = currentUser?.pincode;
                            this.city = currentUser?.city;
                            this.state = currentUser?.state;
                            this.paymentButtonDisabled = this.pincode !== undefined ? false : true;
                            this.showPincodeError = false;
                            this.invalidPincode = false;
                            this.showPopup = true;
                        });
                } else if (this.returnUrl === '/pricing') {
                    window.open(environment.PAYMENT_GATEWAY_URL, '_blank');
                } else this.router.navigateByUrl(this.returnUrl);
            } else {
                this.router.navigate(['/home']);
            }
        }
    }
    navigateToSignUp() {
        this.gTagService.sendEvent(GA_EVENTS.SIGNUP_FROM_SIGNIN);
        this.gTagService.sendEvent(GA4_EVENTS.SIGNUP_FROM_SIGNIN);
        this.router.navigate(['signup']);
    }

    userAnotherEmail() {
        this.errors = {};
        this.ottLogin = false;
        this.usernameForm.reset();
    }
    togglePassword() {
        this.showPassword = !this.showPassword;
    }

    close() {
        this.closePopup.emit();
    }

    onInputPincode(pincode: string, event?: KeyboardEvent) {
        this.invalidPincode = false;
        this.showPincodeError = false;
        if (pincode.length === 6) {
            const pincodeRegex = /^\d{6}$/;
            const result = pincodeRegex.test(pincode);
            if (result) {
                this.pincode = pincode;
                this.utilService.getAddress(pincode).subscribe(
                    (address: any) => {
                        this.city = address.city;
                        this.state = address.state;
                        this.showPincodeError = false;
                        this.invalidPincode = false;
                        if (this.city === undefined && this.state === undefined) {
                            this.invalidPincode = true;
                            this.showPincodeError = false;
                            this.paymentButtonDisabled = true;
                        } else this.paymentButtonDisabled = false;
                    },
                    (error) => {
                        console.error('invalid pincode error', error);
                        this.invalidPincode = false;
                        this.showPincodeError = true;
                    }
                );
            } else {
                this.invalidPincode = true;
                this.city = '';
                this.state = '';
            }
        } else if (event?.key === 'Enter') {
            this.invalidPincode = true;
        } else if (event?.key === 'Backspace' || event?.key === 'Delete') {
            this.invalidPincode = false;
            this.paymentButtonDisabled = true;
            this.pincode = '';
            this.city = '';
            this.state = '';
        }
    }

    onInputState(state: string) {
        this.paymentButtonDisabled =
            state.length === 0 || this.pincode.length === 0 || this.city.length === 0 ? true : false;
    }
    onInputCity(city: string) {
        this.paymentButtonDisabled =
            city.length === 0 || this.pincode.length === 0 || this.state.length === 0 ? true : false;
    }

    closePopupWindow() {
        this.showPopup = false;
        var newFlow = 'true';
        this.router.navigate(['pricing'], { queryParams: { newFlow } });
    }

    submitPayment() {
        if (!this.paymentButtonDisabled) {
            this.showPopup = false;
            this.isLoading = true;
            const data = {
                pincode: this.pincode,
                city: this.city,
                state: this.state
            };
            this.userService.updateUserProfileWithAdditionalProps(data).subscribe(
                (res) => {
                    this.utilService.createOrder().subscribe((res: any) => {
                        if (res.success) {
                            window.location.href = res.paymentUrl;
                        }
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 500);
                    });
                },
                (error) => {
                    console.error('update profile error', error);
                }
            );
        }
    }

    resetLogin() {
        this.email = null;
        this.usernameForm && this.usernameForm.reset();
        this.loginForm && this.loginForm.reset();
        this.ottForm && this.ottForm.reset();
        this.errors = {};
        this.rememberMe = false;
        this.loggingIn = false;
        this.showPassword = false;
        this.childWindow = null;
        this.samlLoginInProgress = false;
        this.isDomainEmail = false;
        this.emailLogin = false;
        this.ottLogin = false;
        this.requestingOTP = false;
        this.sessionId = null;
        this.token = null;
        this.success = false;
        this.enableProceedBtn = false;
        this.bot = null;
        this.outlook = null;
        this.google = false;
        this.googleRedirectURI = null;
        this.googleCalendarId = null;
        this.returnUrl = null;
        this.userNotFound = false;
        this.loading = false;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
    enableDeveloperMode() {
        this.desktopAppService.enableDevMode();
    }
}
