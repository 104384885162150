import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    AfterViewInit,
    forwardRef,
    EventEmitter,
    Output,
    Input,
    HostListener,
    OnDestroy,
    SimpleChanges,
    OnChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { KeyValue } from '@angular/common';

import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { OverlayContainer, ToastContainerDirective, ToastrService } from 'ngx-toastr';

import { LocalStorageService, SearchService, UserService, ValidationService } from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { findIndex } from 'lodash-es';

@Component({
    selector: 'app-participants-input',
    templateUrl: './participants-input.component.html',
    styleUrls: ['./participants-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ParticipantInputComponent),
            multi: true
        }
    ]
})
export class ParticipantInputComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy, OnChanges {
    @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;
    @ViewChild('input') input: ElementRef<HTMLSpanElement>;
    @Output() groupParticipants = new EventEmitter();
    @Input() isEnabledBehalf;
    @Input() isContactsSection;
    @Input() isCohostSection;
    @Input() isOnBehalfSection;
    @Input() isOnBehalfSelectedUsers;
    @Input() isFromMeetingCreation = false;
    @Input() isParticipantsField = false;
    @Input() declinedUsers;
    isListChanged = false;
    @Input() isHostSection = false;

    users = { contacts: [], contacts_ex: [], groups: [], users: [], users_un: [], vcs: [], allUsers: [] };
    participants: Map<string, any> = new Map();
    cohosts: Map<string, any> = new Map();
    onBehalfScheduleList = [];
    isInputEmailIncorrect = false;
    loading = false;
    isExternalEmail = false;
    isExternalPhoneNo = false;
    noUsersFound = false;
    noLocalUsersFound = true;
    suggestionsCount = 0;
    currentUser;
    isMobileDevice;
    containerFocused = false;
    currIndex = -1;
    showResolveParticipantError = false;
    resolveParticipantErrorMsg = '';
    duplicateUsers = [];
    isFreeUser = true;
    currUserMaxParticipants = 150;

    localSearches = [];
    isInputFocused = false;
    searchStr = '';
    isEnabledOnBehalf = false;
    MAX_ON_BEHALF_PARTICIPANTS: number = 20;
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        const items = document.getElementsByClassName('user');
        if (event.key === 'Tab' && items.length > 0) {
            this.currIndex = -1;
            this.containerFocused = true;
            event.preventDefault();
            const usersEle = document.getElementsByClassName('users');
            if (usersEle.length > 0) {
                (usersEle[0] as HTMLElement).focus();
            }
        } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            if (this.containerFocused) {
                if (items.length > 0) {
                    if (event.key === 'ArrowUp') {
                        this.currIndex = this.currIndex > 0 ? this.currIndex - 1 : items.length - 1;
                    } else if (event.key === 'ArrowDown') {
                        this.currIndex = this.currIndex < items.length - 1 ? this.currIndex + 1 : 0;
                    }
                    (items[this.currIndex] as HTMLElement).focus();
                    event.preventDefault();
                }
            }
        } else if (this.suggestionsCount && event.key === 'Escape') {
            this.resetUsers();
            this.input.nativeElement.focus();
        } else if (
            this.duplicateUsers.length === 0 &&
            this.isOnBehalfSection &&
            this.resolveParticipantErrorMsg ===
                this.translateService.instant('planmeeting_popup.selected_user_has_already_added')
        ) {
            this.showResolveParticipantError = false;
            this.resolveParticipantErrorMsg = '';
        }
    }

    originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    };

    private onChange: any = () => {};
    private onTouch: any = () => {};

    ngOnChanges(changes: SimpleChanges): void {
        console.log('isEnabled ', this.isEnabledBehalf);
    }
    constructor(
        private toastrService: ToastrService,
        private toasterService: ToastrService,
        private searchService: SearchService,
        private validationService: ValidationService,
        public userService: UserService,
        private translateService: TranslateService,
        private appLoggerService: AppLoggerService,
        private localStorageService: LocalStorageService
    ) {}

    ngOnInit() {
        this.currentUser = this.userService.getUserSync();
        if (this.isOnBehalfSection || this.isHostSection) {
            this.getOnBehalfParticipants();
            this.currUserMaxParticipants = this.MAX_ON_BEHALF_PARTICIPANTS;
        }
        document
            .getElementsByClassName('input')[0]
            ?.setAttribute('placeholder', this.translateService.instant('planmeeting_popup.invite_placeholder'));
        document
            .getElementsByClassName('input')[1]
            ?.setAttribute('placeholder', this.translateService.instant('planmeeting_popup.invite_placeholder'));
        this.translateService.onLangChange.subscribe(() =>
            document
                .getElementsByClassName('input')[0]
                ?.setAttribute('placeholder', this.translateService.instant('planmeeting_popup.invite_placeholder'))
        );
        this.translateService.onLangChange.subscribe(() =>
            document
                .getElementsByClassName('input')[1]
                ?.setAttribute('placeholder', this.translateService.instant('planmeeting_popup.invite_placeholder'))
        );
        this.toastrService.overlayContainer = this.toastContainer;
        this.setFocusOnInit();
        this.isFreeUser = this.currentUser?.isOTT && this.currentUser?.proStatus == false;
        if (this.currentUser?.copyPasteMailLimit) {
            this.currUserMaxParticipants = this.currentUser?.copyPasteMailLimit;
        }
        this.userService.localSearches = this.localStorageService.getItem('globalSearches');
        if (this.isCohostSection || this.isParticipantsField) {
            this.isEnabledBehalf = true;
        }
    }
    updateListChange() {
        let changedusers = this.onBehalfScheduleList.filter((user) => {
            return !this.participants.has(user.email);
        });
        if (this.participants.size <= this.MAX_ON_BEHALF_PARTICIPANTS) {
            this.resetErrorMessage();
        }
        this.isListChanged =
            (changedusers.length > 0 || this.participants.size != this.onBehalfScheduleList.length) &&
            this.participants.size <= this.MAX_ON_BEHALF_PARTICIPANTS;
    }
    resetErrorMessage() {
        this.showResolveParticipantError = false;
        this.resolveParticipantErrorMsg = '';
    }
    getOnBehalfParticipants() {
        this.userService.getOnBehalfUsersList().subscribe(
            (onBehalfUsersList) => {
                this.resetErrorMessage();
                this.onBehalfScheduleList = onBehalfUsersList.usersOnBehalf;
                if (onBehalfUsersList.usersOnBehalf) {
                    onBehalfUsersList.usersOnBehalf.forEach((user) => {
                        this.participants.set(user.email, user);
                    });
                }
                this.value = this.participants;
            },
            (err) => {
                this.showResolveParticipantError = true;
                this.resolveParticipantErrorMsg = err?.error?.errors;
            }
        );
    }
    saveButtonClicked() {
        let participantIds = [];
        let i = 0;
        if (this.participants) {
            this.participants.forEach((key, value) => {
                participantIds[i] = key.email || key._id;
                i++;
            });
        }
        this.userService.updateOnBehalfUsersList(participantIds).subscribe(
            (response) => {
                this.resetErrorMessage();
                this.isListChanged = false;
                this.getOnBehalfParticipants();
                this.toastrService.success(this.translateService.instant('onbehalf.success'));
            },
            (err) => {
                this.showResolveParticipantError = true;
                this.resolveParticipantErrorMsg = err?.error?.errors;
                if (err?.error?.message === 'ONBEHALF_TENANT_INCORRECT') {
                    this.resolveParticipantErrorMsg = this.translateService.instant(
                        'planmeeting_popup.errors.on_behalf_error'
                    );
                } else if (err?.error?.message === 'LIMIT_REACHED_ONBEHALF') {
                    this.resolveParticipantErrorMsg = this.translateService.instant(
                        'planmeeting_popup.errors.on_behalf_max_limit'
                    );
                }
                if (this.duplicateUsers.length > 0) {
                    this.showDuplicateUserMessgae();
                }
                this.duplicateUsers = [];
            }
        );
    }
    showDuplicateUserMessgae() {
        if (this.isOnBehalfSection) {
            this.showResolveParticipantError = true;
            this.resolveParticipantErrorMsg = this.translateService.instant(
                'planmeeting_popup.selected_user_has_already_added'
            );
        } else {
            this.toastrService.info(
                this.translateService.instant('planmeeting_popup.one_or_more_is_already_added', {
                    value1: this.duplicateUsers[0],
                    value2: this.duplicateUsers.length - 1
                })
            );
        }
        this.duplicateUsers = [];
    }
    onPaste(event) {
        let clipboardData = event.clipboardData || (<any>window)?.clipboardData;
        let participantsFromClipboard = clipboardData.getData('text').split(/[/,\t\r\n ;\\"]+/);
        this.duplicateUsers = [];
        if (
            participantsFromClipboard?.length > 1 &&
            this.isFromMeetingCreation &&
            this.isParticipantsField &&
            !this.isFreeUser
        ) {
            event.preventDefault();
            this.searchService.resolveParticipants(participantsFromClipboard.toString()).subscribe(
                (participants: any) => {
                    if (!this.showResolveParticipantError) {
                        this.resetErrorMessage();
                    }
                    this.updateParticipants(participants?.registeredUsers, participants?.unregisteredUsers);
                    this.value = this.participants;
                    if (this.duplicateUsers.length > 0) {
                        this.showDuplicateUserMessgae();
                    }
                    this.duplicateUsers = [];
                },
                (err) => {
                    if (err?.error?.customCode === 400) {
                        this.showResolveParticipantError = true;
                        this.resolveParticipantErrorMsg = err?.error?.errors;
                        err?.error?.invalid?.forEach((user) => {
                            this.participants.set(user, { name: user, participantWithError: true });
                        });
                        this.updateParticipants(err?.error?.registeredUsers, err?.error?.unregisteredUsers);
                        err?.error?.unprocessedUsers?.forEach((user) => {
                            this.participants.set(user, { name: user, participantWithError: true });
                        });
                        this.value = this.participants;
                        if (this.duplicateUsers.length > 0) {
                            this.showDuplicateUserMessgae();
                        }
                        this.duplicateUsers = [];
                    }
                    this.appLoggerService.error('Resolve participants Error: ', err);
                }
            );
        } else if (this.isOnBehalfSection && participantsFromClipboard?.length > 1) {
            this.userService.resolveUserList(participantsFromClipboard.toString().toLowerCase()).subscribe(
                (participants: any) => {
                    this.updateonBehalfParticipants(
                        participants?.registeredTenantUsers,
                        participants?.invalid,
                        participants?.nonTenantUsers
                    );
                    this.value = this.participants;
                    if (this.duplicateUsers?.length === 0) {
                        this.resetErrorMessage();
                    } else {
                        this.showDuplicateUserMessgae();
                    }
                },
                (err) => {
                    if (err?.error?.customCode === 400) {
                        this.showResolveParticipantError = true;
                        this.resolveParticipantErrorMsg = err?.error?.errors;
                        err?.error?.invalid?.forEach((user) => {
                            this.participants.set(user, { name: user, participantWithError: true });
                        });
                        this.updateonBehalfParticipants(
                            err?.error?.registeredTenantUsers,
                            err?.error?.invalid,
                            err?.error?.nonTenantUsers
                        );
                        this.value = this.participants;
                        if (this.duplicateUsers.length > 0) {
                            this.showDuplicateUserMessgae();
                        }
                        this.duplicateUsers = [];
                    }
                    this.appLoggerService.error('Resolve participants Error: ', err);
                }
            );
        }
    }

    updateonBehalfParticipants(registeredUsers, unregisteredUsers, nonTenantUsers) {
        if (registeredUsers?.length > 0) {
            registeredUsers.forEach((regUser) => {
                this.showParticipantLimitError();
                let id = regUser?._id || regUser?.userId;
                let userEmailString = '';
                if (regUser?.email) {
                    userEmailString = regUser?.email?.toLowerCase();
                }
                if (!this.participants.has(userEmailString ? userEmailString : regUser?.mobileNo || id)) {
                    if (this.participants.size >= this.MAX_ON_BEHALF_PARTICIPANTS) {
                        regUser['participantWithError'] = true;
                        this.showResolveParticipantError = true;
                        this.resolveParticipantErrorMsg = this.translateService.instant(
                            'planmeeting_popup.participants_limit_reached',
                            { value: this.MAX_ON_BEHALF_PARTICIPANTS }
                        );
                    }
                    this.participants.set(userEmailString || regUser.mobileNo || id, regUser);
                    this.isListChanged = true;
                } else {
                    this.duplicateUsers.push(regUser?.name + ' ' + regUser?.lname);
                }
            });
        }
        if (unregisteredUsers?.length > 0) {
            unregisteredUsers.forEach((unRegUser) => {
                this.showParticipantLimitError();
                if (!this.participants.has(unRegUser)) {
                    let unRegUserObj = this.validationService.isEmail(unRegUser)
                        ? { email: unRegUser }
                        : { mobileNo: unRegUser };
                    if (this.participants.size >= this.MAX_ON_BEHALF_PARTICIPANTS) {
                        unRegUserObj['participantWithError'] = true;
                        this.showResolveParticipantError = true;
                        this.resolveParticipantErrorMsg = this.translateService.instant(
                            'planmeeting_popup.errors.on_behalf_error',
                            { value: this.MAX_ON_BEHALF_PARTICIPANTS }
                        );
                    }
                    this.participants.set(unRegUser, unRegUserObj);
                } else {
                    this.duplicateUsers.push(unRegUser);
                }
            });
        }
        if (nonTenantUsers?.length > 0) {
            this.showParticipantLimitError();
            nonTenantUsers.forEach((nonTenant) => {
                this.showParticipantLimitError();
                let nonTenantUserObj = this.validationService.isEmail(nonTenant)
                    ? { email: nonTenant }
                    : { mobileNo: nonTenant };
                if (!this.participants.has(nonTenant)) {
                    nonTenantUserObj['participantWithError'] = true;
                    this.showResolveParticipantError = true;
                    this.resolveParticipantErrorMsg = this.translateService.instant(
                        'planmeeting_popup.errors.on_behalf_error',
                        { value: this.MAX_ON_BEHALF_PARTICIPANTS }
                    );
                    this.participants.set(nonTenant, nonTenantUserObj);
                }
                console.log('participant ' + this.participants.get(nonTenant).toString());
            });
        }
        this.input.nativeElement.innerText = '';
        if (
            this.duplicateUsers?.length === 0 &&
            this.isOnBehalfSection &&
            this.resolveParticipantErrorMsg ===
                this.translateService.instant('planmeeting_popup.selected_user_has_already_added')
        ) {
            this.resetErrorMessage();
        }
    }

    updateParticipants(registeredUsers, unregisteredUsers) {
        if (registeredUsers?.length > 0) {
            registeredUsers.forEach((regUser) => {
                let id = regUser?._id || regUser?.userId;
                let userEmailString = '';
                if (regUser?.email) {
                    userEmailString = regUser?.email?.toLowerCase();
                }
                if (!this.participants.has(userEmailString ? userEmailString : regUser?.mobileNo || id)) {
                    if (this.participants.size >= this.currUserMaxParticipants) {
                        regUser['participantWithError'] = true;
                        this.showResolveParticipantError = true;
                        this.resolveParticipantErrorMsg = this.translateService.instant(
                            'planmeeting_popup.participants_limit_reached',
                            { value: this.currUserMaxParticipants }
                        );
                    }
                    this.participants.set(userEmailString || regUser.mobileNo || id, regUser);
                } else {
                    this.duplicateUsers.push(regUser?.name + ' ' + regUser?.lname);
                }
            });
        }
        if (unregisteredUsers?.length > 0) {
            unregisteredUsers.forEach((unRegUser) => {
                if (!this.participants.has(unRegUser)) {
                    let unRegUserObj = this.validationService.isEmail(unRegUser)
                        ? { email: unRegUser }
                        : { mobileNo: unRegUser };
                    if (this.participants.size >= this.currUserMaxParticipants) {
                        unRegUserObj['participantWithError'] = true;
                        this.showResolveParticipantError = true;
                        this.resolveParticipantErrorMsg = this.translateService.instant(
                            'planmeeting_popup.participants_limit_reached',
                            { value: this.currUserMaxParticipants }
                        );
                    }
                    this.participants.set(unRegUser, unRegUserObj);
                } else {
                    this.duplicateUsers.push(unRegUser);
                }
            });
        }
    }

    removeAll() {
        this.participants.clear();
        this.value = this.participants;
        this.resetErrorMessage();
    }

    checkRemoveErrorBorders() {
        if (document.getElementsByClassName('participant-has-error')?.length === 1) {
            this.showResolveParticipantError = false;
            this.resolveParticipantErrorMsg = '';
        }
    }

    setFocusOnInit() {
        if (!this.isFromMeetingCreation) {
            setTimeout(() => {
                const headerElement: any = document.getElementsByClassName(' participants-input')[0];
                if (headerElement) {
                    headerElement?.focus();
                }
            }, 100);
        }
    }

    ngOnDestroy() {
        this.currIndex = -1;
    }

    ngAfterViewInit() {
        fromEvent(this.input.nativeElement, 'keyup')
            .pipe(
                debounceTime(300),
                map((event: KeyboardEvent) => (event.target as HTMLSpanElement).innerText),
                tap((term) => !(term || '').length && this.resetUsers()),
                distinctUntilChanged(),
                filter((term) => (term || '').trim().length > 2),
                tap((term) => {
                    this.loading = true;
                    this.noUsersFound = false;
                    this.searchStr = term;
                    this.isInputFocused = true;
                })
            )
            .subscribe((term) => {
                this.searchService
                    .globalSearch(term)
                    .pipe(
                        map((res: any) => {
                            const { users, users_un, contacts, contacts_ex, groups, vcs, ...rest } = res;
                            let allUsers = [
                                ...(users?.map((user) => ({ ...user, type: 'user' })) || []),
                                ...(users_un?.map((user) => ({ ...user, type: 'user_un' })) || []),
                                ...(contacts?.map((user) => ({ ...user, type: 'contact' })) || []),
                                ...(contacts_ex?.map((user) => ({ ...user, type: 'contact_ex' })) || [])
                            ]
                                ?.filter((u) => {
                                    return (
                                        (u?.userId || u?._id) !== this.currentUser?._id &&
                                        (this.userService.localSearches
                                            ? this.userService?.localSearches.findIndex(
                                                  (lu) => (lu?.userId || lu?._id) === (u?.userId || u?._id)
                                              ) === -1
                                            : true)
                                    );
                                })
                                .sort((a, b) => (a.name + ' ' + a.lname).localeCompare(b.name + ' ' + b.lname));

                            let filteredGroups = groups
                                ?.filter((u) => {
                                    return this.userService.localSearches
                                        ? this.userService?.localSearches.findIndex((lu) => lu?._id === u?._id) === -1
                                        : true;
                                })
                                ?.sort((a, b) => a.title.localeCompare(b.title));

                            let filteredVcs = vcs
                                ?.filter((u) => {
                                    return this.userService.localSearches
                                        ? this.userService?.localSearches.findIndex(
                                              (lu) => (lu?.userId || lu?._id) === (u?.userId || u?._id)
                                          ) === -1
                                        : true;
                                })
                                ?.sort((a, b) => a.name.localeCompare(b.name));
                            if (this.isCohostSection) {
                                return {
                                    ...rest,
                                    allUsers: allUsers,
                                    groups: [],
                                    vcs: filteredVcs
                                };
                            } else {
                                return {
                                    ...rest,
                                    allUsers: allUsers,
                                    groups: filteredGroups,
                                    vcs: filteredVcs
                                };
                            }
                        }),
                        tap((res: any) => {
                            this.suggestionsCount =
                                (res.groups?.length || 0) + (res.allUsers?.length || 0) + (res.vcs?.length || 0);

                            this.noLocalUsersFound =
                                this.userService.getLocalSearchResults(term, [
                                    'fullname',
                                    'lname',
                                    'phoneNo',
                                    'title',
                                    'name',
                                    'email'
                                ]).length == 0;
                        })
                    )
                    .subscribe(
                        (res) => {
                            this.users = res;
                            this.isExternalEmail = false;
                            this.isExternalPhoneNo = false;

                            if (!this.suggestionsCount && this.noLocalUsersFound) {
                                if (
                                    this.validationService.isEmail(term) &&
                                    this.currentUser.email != term.toLowerCase()
                                ) {
                                    this.isExternalEmail = true;
                                } else if (
                                    this.validationService.isPhoneNumber(term) &&
                                    this.currentUser.displayPhoneNo != term
                                ) {
                                    this.isExternalPhoneNo = true;
                                } else {
                                    this.noUsersFound = true;
                                }
                            }
                            this.loading = false;
                        },
                        (err) => {
                            if (!this.suggestionsCount && !this.isCohostSection && !this.isContactsSection) {
                                if (
                                    this.validationService.isEmail(term) &&
                                    this.currentUser.email != term.toLowerCase()
                                ) {
                                    this.isExternalEmail = true;
                                } else if (
                                    this.validationService.isPhoneNumber(term) &&
                                    this.currentUser.displayPhoneNo != term
                                ) {
                                    this.isExternalPhoneNo = true;
                                } else {
                                    this.noUsersFound = true;
                                }
                            } else if (!this.suggestionsCount && (this.isCohostSection || this.isContactsSection)) {
                                this.noUsersFound = true;
                            }
                            this.loading = false;
                            this.appLoggerService.error(
                                'Participant search item not found:',
                                new Error(JSON.stringify(err?.error?.errors)),
                                err
                            );
                        }
                    );
            });
    }

    onFocus() {
        this.isInputFocused = true;
    }

    set value(val) {
        this.participants = val;
        this.cohosts = val;
        this.onChange(val);
        this.onTouch(val);
    }

    writeValue(value) {
        this.participants = value;
        this.cohosts = value;
        this.groupParticipants.emit(this.participants);
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    select(user, type?) {
        if (this.declinedUsers && this.declinedUsers.some((user) => user.userId === user.userId)) {
            this.declinedUsers = this.declinedUsers.filter((user) => user.userId !== user.userId);
        }
        if (type && type === 'group' && this.isFromMeetingCreation && this.isParticipantsField) {
            this.setGroupToParticipants(user?.members);
            return false;
        }
        let id = user._id || user.userId;
        if (['contact', 'contact_ex'].includes(type)) {
            id = user.userId;
        }
        let userEmailString = '';
        if (user.email) {
            userEmailString = user.email.toLowerCase();
        }
        if (this.showParticipantLimitError()) {
            this.toasterService.error(this.translateService.instant('inside_call.call_component.max_limit_hosts'));
            this.resetErrorMessage();
            return;
        }
        if (this.cohosts.size > 4 && this.isCohostSection) {
            let toast_Class = ' ngx-toastr audiencemode-toast';
            if (this.isMobileDevice) toast_Class = ' ngx-toastr audiencemode-toast isMobileDevice';
            this.toasterService.clear();
            this.toasterService.error(
                this.translateService.instant('planmeeting_popup.cohost_limit'),
                this.translateService.instant('planmeeting_popup.cohost_limit1'),
                {
                    toastClass: toast_Class
                }
            );
            return false;
        }
        if (!this.participants.has(userEmailString ? userEmailString : user.mobileNo || id)) {
            if (
                this.participants.size >= this.currUserMaxParticipants &&
                this.isFromMeetingCreation &&
                this.isParticipantsField &&
                !this.isFreeUser
            ) {
                user['participantWithError'] = true;
                this.showResolveParticipantError = true;
                this.resolveParticipantErrorMsg = this.translateService.instant(
                    'planmeeting_popup.participants_limit_reached',
                    { value: this.currUserMaxParticipants }
                );
            }
            this.participants.set(userEmailString || user.mobileNo || id, user);
            this.value = this.participants;
            this.clearInput();
            this.updateListChange();
            this.input.nativeElement.focus();
            // if (this.suggestionsCount < 2) {
            this.resetUsers();
            this.groupParticipants.emit(this.participants);
            // }
        } else {
            if (user.title) {
                this.toastrService.info(
                    this.isOnBehalfSection
                        ? this.translateService.instant('planmeeting_popup.selected_user_has_already_added')
                        : `${user.title} ${this.translateService.instant('planmeeting_popup.is_already_added')}`
                );
            } else if (user.ipaddress) {
                this.toastrService.info(
                    this.isOnBehalfSection
                        ? this.translateService.instant('planmeeting_popup.selected_user_has_already_added')
                        : `${user.name} ${this.translateService.instant('planmeeting_popup.is_already_added')}`
                );
            } else if (user.name) {
                if (this.isOnBehalfSection) {
                    this.showResolveParticipantError = true;
                    this.resolveParticipantErrorMsg = this.translateService.instant(
                        'planmeeting_popup.selected_user_has_already_added'
                    );
                } else {
                    this.toastrService.info(
                        this.isOnBehalfSection
                            ? this.translateService.instant('planmeeting_popup.selected_user_has_already_added')
                            : `${user.name + (user.lname ? ' ' + user.lname : '')} ${this.translateService.instant(
                                  'planmeeting_popup.is_already_added'
                              )}`
                    );
                }
            } else if (user.email) {
                if (this.isOnBehalfSection) {
                    this.showResolveParticipantError = true;
                    this.resolveParticipantErrorMsg = this.translateService.instant(
                        'planmeeting_popup.selected_user_has_already_added'
                    );
                } else {
                    this.toastrService.info(
                        `${userEmailString} ${this.translateService.instant('planmeeting_popup.is_already_added')}`
                    );
                }
            } else if (user.mobileNo) {
                this.toastrService.info(
                    this.isOnBehalfSection
                        ? this.translateService.instant('planmeeting_popup.selected_user_has_already_added')
                        : `${user.mobileNo} ${this.translateService.instant('planmeeting_popup.is_already_added')}`
                );
            }
        }
    }

    setGroupToParticipants(members) {
        members.forEach((element) => {
            this.select(element);
        });
    }

    showParticipantLimitError() {
        if (this.isOnBehalfSection && this.participants.size >= this.MAX_ON_BEHALF_PARTICIPANTS) {
            this.showResolveParticipantError = true;
            this.resolveParticipantErrorMsg = this.translateService.instant(
                'planmeeting_popup.errors.on_behalf_max_limit'
            );
            return true;
        }
        return false;
    }

    inviteExternal(emailOrPhoneNo) {
        this.select(this.isExternalEmail ? { email: emailOrPhoneNo } : { mobileNo: emailOrPhoneNo });
        this.clearInput();
        this.input.nativeElement.focus();
    }

    removeParticipant(participant) {
        this.participants.delete(participant.key);
        this.value = this.participants;
        this.groupParticipants.emit(this.participants);
        if (participant?.value?.participantWithError) {
            this.checkRemoveErrorBorders();
        }

        this.updateListChange();
    }

    removeCohost(cohost) {
        this.cohosts.delete(cohost.key);
        this.value = this.cohosts;
    }

    resetUsers() {
        this.users.contacts = [];
        this.users.contacts_ex = [];
        this.users.groups = [];
        this.users.users = [];
        this.users.users_un = [];
        this.users.vcs = [];
        this.users.allUsers = [];
        this.loading = false;
        this.noUsersFound = false;
        this.noLocalUsersFound = true;
        this.clearInput();
        this.suggestionsCount = 0;
        this.currIndex = -1;
        this.isInputFocused = false;
        this.searchStr = '';
    }

    clearInput() {
        this.input.nativeElement.innerText = '';
        this.isExternalEmail = false;
        this.isExternalPhoneNo = false;
    }
}
