import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

import * as FileSaver from 'file-saver';

import { JiocloudService } from 'src/app/core/services/jiocloud.service';
import { JioCloudUtilService } from 'src/app/core/services/jio-cloud-util.service';

import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
    selector: 'app-doc-viewer',
    templateUrl: './doc-viewer.component.html',
    styleUrls: ['./doc-viewer.component.scss']
})
export class DocViewerComponent implements OnInit, OnDestroy {
    @Input() meetingDocumentUrl;
    @Input() jiomeetId;
    @Input() fileName;
    @Input() downloadUrl;
    @Input() isDataProtection;
    @Output() close = new EventEmitter();
    @ViewChild('downloadAnchor') downloadAnchor: ElementRef;
    @ViewChild('defaultPDFViewer') defaultViewer: ElementRef;
    public showPreview: boolean = false;
    public renderDefaultPDFViewer: boolean = true;
    previewUrl: string;
    docType;
    toastIds: any[] = [];
    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        public jcService: JiocloudService,
        private jioCloudUtilService: JioCloudUtilService,
        private toastrService: ToastrService,
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        this.docType = this.jioCloudUtilService.getDocType(this.fileName);
        this.showPreview = true;
        this.createUrlForPDF();
    }

    closePreview(value: boolean) {
        this.close.emit(value);
    }

    createUrlForPDF() {
        console.log('document url ==>>', this.meetingDocumentUrl);
        this.jcService.openPreviewForDocs(this.meetingDocumentUrl, this.jiomeetId, this.fileName).subscribe((resp) => {
            this.showPreviewForDocs(resp);
        });
    }

    showPreviewForDocs(resp) {
        console.log('resp', resp);
        var file = new Blob([resp], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        if (this.renderDefaultPDFViewer) {
            var defViewer = this.defaultViewer.nativeElement;
            defViewer.setAttribute('style', "display:'block'");
            defViewer.setAttribute('src', fileURL + '#toolbar=0');
            defViewer.setAttribute('height', '1000px');
            defViewer.setAttribute('width', '1000px');
        } else {
            this.previewUrl = fileURL;
        }
    }

    downloadFile() {
        if (this.isDataProtection) {
            this.toastrService.error(this.translateService.instant('tostrs.downloading_a_file_is_not_allowed'));
            return;
        }
        this.toastIds.push(this.toastrService.success('Downloading in progress'));
        this.jcService
            .downloadFile(this.fileName, this.downloadUrl, this.jiomeetId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((resp) => {
                FileSaver.saveAs(resp, this.fileName);
                this.toastIds.push(this.toastrService.success('Download completed'));
            });
    }

    ngOnDestroy() {
        this.toastIds.forEach((toastr) => {
            this.toastrService.clear(toastr.toastId);
        });
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }
}
