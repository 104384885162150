import {
    Component,
    ViewChild,
    ElementRef,
    Input,
    OnDestroy,
    AfterViewInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';

declare const lottie: any;

@Component({
    selector: 'app-lottie-renderer',
    templateUrl: './lottie-renderer.component.html',
    styleUrls: ['./lottie-renderer.component.scss']
})
export class LottieRendererComponent implements AfterViewInit, OnDestroy, OnChanges {
    @ViewChild('renderer') renderer: ElementRef;

    @Input() path;

    private animation;
    private isFirstLoad = true;

    ngAfterViewInit() {
        if (this.isFirstLoad) {
            this.loadAnimation();
            this.isFirstLoad = false;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.isFirstLoad && changes.path) {
            this.animation?.stop();
            this.animation?.destroy();
            this.loadAnimation();
        }
    }

    ngOnDestroy() {
        this.animation?.stop();
        this.animation?.destroy();
    }

    private loadAnimation() {
        this.animation = lottie.loadAnimation({
            container: this.renderer?.nativeElement,
            path: this.path,
            renderer: 'svg',
            autoplay: true,
            loop: true
        });
    }
}
