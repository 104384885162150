import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/core';

@Component({
    selector: 'app-thankyou',
    templateUrl: './thankyou.component.html',
    styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {
    meetingid = '';
    pwd = '';
    constructor(
        private appService: AppService,
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.meetingid = params['meetingid'];
            this.pwd = params['pin'];
        });
    }

    goto() {
        location.href = this.appService.getEnvVariable('CPASS_HOST_URL');
    }
    rejoinMeeting() {
        this.router.navigate(['platform/join'], {
            queryParams: {
                meetingId: this.meetingid,
                pwd: this.pwd
            }
        });
    }
}
