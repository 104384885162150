import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { of } from 'rxjs';

import { UserService } from './user.service';
import { AppService } from './app.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    constructor(private appService: AppService, private userService: UserService) {}

    checkForUser(permissions: any[]) {
        if (this.appService.getConfigVariable('BYPASS_PERMISSIONS_CHECK') || !permissions.length) {
            return of(true);
        }
        return this.userService.getUser().pipe(
            map((user) => {
                if (user && user.roles.length) {
                    const userRoles = user.roles.map((role) => role.name);
                    return permissions.some((grants) => this.existPermission(userRoles, grants));
                }
                return false;
            })
        );
    }

    private existPermission(userRoles, permissions): boolean {
        // assuming single role, change incase of multi role in future
        return userRoles.find((x) => x.toUpperCase() === permissions.toUpperCase());
    }
}
