import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ChatService, UtilService, UserService, AppService } from 'src/app/core';
import { combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-attachment-list',
    templateUrl: './attachment-list.component.html',
    styleUrls: ['./attachment-list.component.scss']
})
export class AttachmentListComponent implements OnInit {
    @ViewChild('messageslist', { static: true }) messagesEle: ElementRef;
    @Input() threadId;
    @Output() afterClose: EventEmitter<any> = new EventEmitter();
    attachmentList: any[] = [];
    loader = true;
    currentUser: any;
    attachmentsCount = 0;
    constructor(
        private host: ElementRef<HTMLElement>,
        private chatService: ChatService,
        private appService: AppService,
        private userService: UserService
    ) {}
    ngOnInit(): void {
        this.getAttachmentInCurrentThreads();
        this.currentUser = this.userService.getUserSync();
    }

    getAttachmentInCurrentThreads() {
        this.chatService.getChatAttachments(this.threadId).subscribe((attachments) => {
            this.attachmentList = (attachments.messages || []).filter((msg) => msg.type === 'message');
            for (let i = 0; i < this.attachmentList.length; i++) {
                for (let j = 0; j < this.attachmentList[i].messageComponent.attachments.length; j++) {
                    if (this.attachmentList[i].messageComponent.attachments[j].imageTrancodedURL !== '')
                        this.attachmentsCount += 1;
                }
            }
            this.loader = false;
            setTimeout(() => (this.messagesEle.nativeElement.scrollTop = 0), 300);
        });
    }

    handleRefreshListEvent(e) {
        this.getAttachmentInCurrentThreads();
    }

    isDesktopApp() {
        return this.appService.isDesktopApp();
    }

    getAttachedFilesCount() {
        return [].concat.apply(
            [],
            this.attachmentList.map((msg) => msg.messageComponent.attachments)
        ).length;
    }

    trackByFuntion(index, item) {
        return index;
    }

    get container(): HTMLElement {
        return this.host.nativeElement.querySelector('.private-chat-container') as HTMLElement;
    }

    close() {
        this.container.style.animation = 'chatOut .3s';
    }

    animationDone(event: AnimationEvent) {
        if (event.animationName === 'chatOut') {
            this.afterClose.emit(true);
        }
    }
}
