import { Component, OnInit, Input } from '@angular/core';
import { APP_EVENTS } from 'src/app/constants';
import { EventData, EventEmitterService, UtilService } from 'src/app/core';

@Component({
    selector: 'app-website-product-features',
    templateUrl: './website-product-features.component.html',
    styleUrls: ['./website-product-features.component.scss']
})
export class WebsiteProductFeaturesComponent implements OnInit {
    @Input() productFeature: any = {};
    @Input() reverse: boolean = false; // to reverse row
    productPreviewImg: string;
    featureItemActiveIndex = 0;
    isMobile;
    pageScrollRef = document.getElementById('app-container');
    timeoutRef;
    scrollCount = 0;
    scrollDirection = 'down';
    maxSolutions = 0;
    prevScrollPos = 0;
    screenInnerWidth = window.innerWidth;
    constructor(private utilService: UtilService, private eventEmitterService: EventEmitterService) {}

    ngOnInit(): void {
        this.productPreviewImg = this.productFeature?.data?.length
            ? this.productFeature?.data[0]?.img
            : this.productFeature.img;
        this.isMobile = this.utilService.isMobileBrowser(720);
        this.maxSolutions = this.productFeature?.data?.length - 1;
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 720 ? false : true;
                this.screenInnerWidth = event.data.innerWidth;
            }
        });
    }

    handleFeatureClick(index) {
        // dont do anything if view is mobile view
        if (this.isMobile) return;
        index = index < 0 ? 0 : index;
        this.featureItemActiveIndex = index > this.maxSolutions ? this.maxSolutions : index;
        this.productPreviewImg = this.productFeature?.data[this.featureItemActiveIndex]?.img;
    }

    onVisible(data) {
        if (data.status && this.productFeature?.data?.length && !this.isMobile) {
            this.scrollDirection = 'down';
            if (this.featureItemActiveIndex >= this.maxSolutions) {
                this.scrollDirection = 'up';
            }
            this.disableScroll();
        }
    }

    disableScroll() {
        // this.featureItemActiveIndex = this.featureItemActiveIndex === 2 ? -1 : 0;
        this.scrollCount = 0;
        //  document.body.classList.add('stop-scrolling');
        // Get the current page scroll position
        const scrollTop = this.pageScrollRef.scrollTop;
        const scrollLeft = this.pageScrollRef.scrollLeft;
        // if any scroll is attempted,
        // set this to the previous value

        this.pageScrollRef.onscroll = this.handleOnScroll.bind(this, [scrollTop, scrollLeft]);
    }

    handleOnScroll(args) {
        console.log(args[1], args[0]);
        this.pageScrollRef.scrollTo(args[1], args[0]);
        this.scrollCount++;
        if (this.scrollCount >= 5) {
            this.handleHorizontalMove();
            this.scrollCount = 0;
        }
        // clearTimeout(this.timeoutRef);
        // // if (!this.timeoutRef)
        // this.timeoutRef = setTimeout(
        //   (currentScrollCount) => {
        //     console.log('in settimeout1212');
        //     if (currentScrollCount !== this.scrollCount) return;
        //     console.log('in settimeout');
        //     // if (this.featureItemActiveIndex <= 1) {
        //     //   this.handleFeatureClick(this.featureItemActiveIndex + 1);
        //     // }
        //     this.handleHorizontalMove();
        //   },
        //   20,
        //   this.scrollCount
        // );
    }

    handleHorizontalMove() {
        const currentScrollPos = this.pageScrollRef.scrollTop;
        this.handleFeatureClick(this.featureItemActiveIndex + (this.scrollDirection === 'up' ? -1 : 1));
        if (this.featureItemActiveIndex === this.maxSolutions || this.featureItemActiveIndex === 0) {
            // this.featureItemActiveIndex = -1;
            this.enableScroll();
        }
        this.timeoutRef = null;
        this.prevScrollPos = currentScrollPos;
    }

    enableScroll() {
        this.pageScrollRef.onscroll = function () {};
        //  document.body.classList.remove('stop-scrolling');
    }
}
