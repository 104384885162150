import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as FileSaver from 'file-saver';

import { ToastrService } from 'ngx-toastr';

import { JiocloudService } from 'src/app/core/services/jiocloud.service';
import { TranslateService } from '@ngx-translate/core';

declare var require: any;
var clappr = require('clappr');
declare var $: any;

@Component({
    selector: 'app-audio-clappr',
    templateUrl: './audio-clappr.component.html',
    styleUrls: ['./audio-clappr.component.scss'] // '../../../../assets/styles/icon-styles.scss'
})
export class AudioClapprComponent implements OnInit, OnDestroy {
    @Input() meetingImageTranscodeUrl;
    @Input() meetingPlaybackUrl;
    @Input() jiomeetId;
    @Input() fileName;
    @Input() downloadUrl;
    @Input() isDataProtection;
    @Output() close = new EventEmitter();
    @ViewChild('downloadAnchor', { static: true }) downloadAnchor: ElementRef;
    private audioplayer: any;
    public showPreview: boolean = false;
    toastIds: any[] = [];
    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        public jcService: JiocloudService,
        private toastrService: ToastrService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.showPreview = true;
        this.openClappr();
    }

    closePreview(value: boolean) {
        this.close.emit(value);
    }

    openClappr() {
        const playbackUrl = this.meetingPlaybackUrl;
        this.jcService.getPlaybackSourceUrl(playbackUrl, this.jiomeetId).subscribe(
            (suc) => {
                this.audioplayer = this.playAudioMedia(suc);
            },
            (error) => {}
        );
    }

    playCurrentAudio() {
        if ($('.audioCntnr').find('i').hasClass('NeuePlayFilled')) {
            this.audioplayer.play();
            this.playAudio();
        } else if ($('.audioCntnr').find('i').hasClass('NeuePause')) {
            this.audioplayer.pause();
            this.pauseAudio();
        }
    }

    audioClapprEvents() {
        return {
            onReady: function () {
                $('.audioCntnr').removeClass('hide');
                $('.audioCntnr .player-poster[data-poster] .play-wrapper[data-poster] svg path').addClass('hide');
                $('#audio-media-content [data-player]').height(
                    $('#imgover').outerHeight() - $('.itemListContainer').height()
                );
            },
            onPlay: function () {
                // clevertap.event.push("User_Media_Played", {
                //     "FILE_TYPE": "Audio",
                //     "LOCATION":cleverTapSource
                // });
                $('#screenId').find('i').removeClass('NeuePlayFilled').addClass('NeuePause');
                $('.icon-audioScreenPlay').find('i').removeClass('NeuePlayFilled').addClass('NeuePause');
                $('.icon-mobilePlay').find('i').removeClass('NeuePlayFilled').addClass('NeuePause');
            },
            onPause: function () {
                $('#screenId').find('i').addClass('NeuePlayFilled').removeClass('NeuePause');
                $('.icon-audioScreenPlay').find('i').addClass('NeuePlayFilled').removeClass('NeuePause');
                $('.icon-mobilePlay').find('i').addClass('NeuePlayFilled').removeClass('NeuePause');
            },
            onStop: function () {}
        };
    }

    playAudioMedia(sourceUrl) {
        return new clappr.Player({
            source: sourceUrl,
            parentId: '#audio_player',
            persistConfig: true, // added
            allowUserInteraction: true,
            autoSeekFromUrl: false,
            maxBufferLength: 5,
            playbackNotSupportedMessage: 'Please try on a different browser',
            events: this.audioClapprEvents()
        });
    }

    playAudio() {
        $('#screenId').find('i').removeClass('NeuePlayFilled').addClass('NeuePause');
        $('.icon-audioScreenPlay').find('i').removeClass('NeuePlayFilled').addClass('NeuePause');
        $('.icon-mobilePlay').find('i').removeClass('NeuePlayFilled').addClass('NeuePause');
    }

    pauseAudio() {
        $('#screenId').find('i').addClass('NeuePlayFilled').removeClass('NeuePause');
        $('.icon-audioScreenPlay').find('i').addClass('NeuePlayFilled').removeClass('NeuePause');
        $('.icon-mobilePlay').find('i').addClass('NeuePlayFilled').removeClass('NeuePause');
    }

    dismiss() {
        this.close.emit();
    }

    downloadFile() {
        if (this.isDataProtection) {
            this.toastrService.error(this.translateService.instant('tostrs.downloading_a_file_is_not_allowed'));
            return;
        }
        this.toastIds.push(this.toastrService.success('Downloading in progress'));
        this.jcService
            .downloadFile(this.fileName, this.downloadUrl, this.jiomeetId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((resp) => {
                FileSaver.saveAs(resp, this.fileName);
                this.toastIds.push(this.toastrService.success('Download completed'));
            });
    }

    fullScreen() {}

    ngOnDestroy() {
        this.audioplayer = null;
        this.fileName = null;
        this.toastIds.forEach((toastr) => {
            this.toastrService.clear(toastr.toastId);
        });
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }
}
