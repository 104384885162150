<div class="tnc">
    <div class="gray__bg" [ngStyle]="{ 'background-color': showStbChanges ? '#152A53' : '#f6f6f6' }">
        <div class="container" *ngIf="!isLangHindi">
            <div class="row">
                <div class="col-12 col-lg-10 m-auto pt-4">
                    <h2
                        class="pb-2 text-center"
                        tabindex="0"
                        [ngStyle]="{ color: showStbChanges ? '#ffffff' : '#000000' }"
                    >
                        Terms &amp; Conditions for JioMeet
                    </h2>

                    <div class="card radius-card card-format">
                        <div class="card-body">
                            <ol>
                                <li tabindex="0">
                                    <strong>SCOPE:</strong>
                                    <ol>
                                        <li tabindex="0">
                                            This application known as "JioMeet" (“Application”) is a multiplatform video
                                            conferencing application, provided by Jio Platforms Limited (“Company”, “we”
                                            or “us”), located at Office- 101, Saffron, Near Centre Point, Panchwati 5
                                            Rasta, Ambawadi, Ahmedabad.
                                        </li>
                                        <li tabindex="0">
                                            The Application provides to its users, the following services: (i)
                                            multi-party HD quality video conferencing; (ii) instant and scheduled
                                            invites to video conferencing; (iii) invite by various means such as (a)
                                            email; (b) SMS; (c) internet-based messaging applications like WhatsApp (d)
                                            third party messaging services installed in the user’s Device (herein after
                                            defined); (iv) allows invitees to join the conference through the
                                            Application installed in any compatible Device or through any compatible web
                                            browser installed in a laptop/desktop; (v) rich communication services
                                            involving (a) messaging services, where a user can simultaneously send
                                            messages and also display images, contacts and location through screen
                                            sharing services; (b) file sharing services, where a user can share files
                                            with third parties; and (c) chat services, including group chatting
                                            (collectively “Rich Communication Services” or “RCS”); and (vi) any other
                                            service as may be provided by the Company from time to time (collectively
                                            known as “Services”) on any compatible smart phones powered by Android,
                                            Microsoft windows or iOS and JioFiber Set Top Box (“JioSTB”); (“Device”).
                                        </li>
                                        <li tabindex="0">
                                            Your (“you”, “your” or “user”) use of the Application is subject to these
                                            <a class="pointer" tabindex="0" role="link" (click)="gototnc()"
                                                >Terms and Conditions</a
                                            >
                                            (“Terms”).
                                        </li>
                                        <li tabindex="0">
                                            Your use of the Application on the Device requires that you agree to these
                                            Terms and the
                                            <a (click)="gotopp()" tabindex="0" role="link" class="pointer"
                                                >privacy policy</a
                                            >
                                            of the Application. By downloading the Application and by registering or
                                            signing up to the Application, or otherwise having access to, receiving,
                                            and/or using the Application, you acknowledge to have read, understood and
                                            you consent to be governed and bound by these Terms and the privacy policy
                                            of the Application. If you do not understand the Terms or the privacy policy
                                            of the Application, or do not accept any part of them, then you should not
                                            use the Application.
                                        </li>
                                        <li tabindex="0">
                                            The Terms are an electronic record in terms of the Information Technology
                                            Act, 2000 (as amended/re-enacted) (“IT Act") and rules thereunder, and is
                                            published in accordance with the provisions of Rule 3 (1) of Information
                                            Technology (Intermediaries Guidelines and Digital Media Ethics code) Rules,
                                            2021, which mandates for publishing of rules and regulations, privacy policy
                                            and terms of use for access or usage of Application. This electronic record
                                            is generated by a computer system and does not require any physical or
                                            digital signatures.
                                        </li>
                                    </ol>
                                </li>
                                <li tabindex="0">
                                    <strong>PRIVACY AND PROTECTION OF PERSONAL INFORMATION:</strong>
                                    <ol>
                                        <li tabindex="0">
                                            Please refer to the privacy policy of the Application available
                                            <a class="pointer" tabindex="0" role="link" (click)="gotopp()">here.</a>
                                        </li>
                                        <li tabindex="0">
                                            Please note that your use of the Application or Services on your Device and
                                            download thereof from Google play store/Apple app store may be subject to
                                            the respective privacy policies as may be published or made available by the
                                            Device or the JioSTB and Google play store/Apple app store.
                                        </li>
                                        <li tabindex="0">
                                            If the user wants JioMeet team to delete all personal data collected, then
                                            he/she needs to send an email to
                                            <a href="mailto:Jiomeet.Support@jio.com" tabindex="0" role="link"
                                                >Jiomeet.Support@jio.com</a
                                            >
                                        </li>
                                    </ol>
                                </li>
                                <li tabindex="0">
                                    <strong>MODIFICATIONS: </strong>
                                    <ol>
                                        <li tabindex="0">
                                            Modifications of the Terms: The Company reserves the right to change the
                                            Terms under which the Services are offered, including but not limited to the
                                            charges, if any, associated with the use of the Services. The Terms may be
                                            further modified based on changes in the business, legal and regulatory
                                            requirements and will be updated online. We will notify you whenever any
                                            change to the Terms are made. You are encouraged to periodically visit this
                                            page to review these Terms and any changes to it.
                                        </li>
                                        <li tabindex="0">
                                            Modification of Services: The Company reserves the right to add, modify or
                                            delete any content or features available in the Services at any time at its
                                            sole discretion.
                                        </li>
                                    </ol>
                                </li>
                                <li tabindex="0">
                                    <strong>LINKS TO THIRD PARTY SITES AND APPLICATIONS:</strong>
                                    <ol>
                                        <li tabindex="0">
                                            The Application may contain links to other web sites/applications ("Linked
                                            Sites"). The Linked Sites are not under the control of the Company and the
                                            Company is not responsible for the contents of any Linked Site, including
                                            without limitation any link contained in a Linked Site, or any changes or
                                            updates to a Linked Site or any information transmitted on a Linked Site.
                                            The inclusion of any link does not imply endorsement by the Company of the
                                            site or any association with its operators.
                                        </li>
                                        <li tabindex="0">
                                            Any dealings with third parties (including advertisers) included within or
                                            available via a link from the Application or participation in promotions,
                                            including the delivery of and the payment for goods and services, and any
                                            other terms, conditions, warranties or representations associated with such
                                            dealings or promotions, are solely between you and the advertiser or other
                                            third party. The Company shall not be responsible or liable for any part of
                                            any such dealings or promotions.
                                        </li>
                                        <li tabindex="0">
                                            You acknowledge and agree that the use of any Linked Sites is governed by
                                            such third party’s terms of use, license agreement, privacy policy, or other
                                            such agreement. THE COMPANY DISCLAIMS ANY AND ALL RESPONSIBILITY FOR ANY
                                            DISCLOSURE OF INFORMATION OR ANY OTHER PRACTICES OF ANY THIRD PARTY. THE
                                            COMPANY EXPRESSLY DISCLAIMS ANY WARRANTY WITH RESPECT TO YOUR PERSONAL OR
                                            OTHER INFORMATION THAT MAY BE COLLECTED, PROCESSED, SHARED OR RETAINED BY
                                            ANY THIRD PARTY.
                                        </li>
                                    </ol>
                                </li>
                                <li tabindex="0">
                                    <strong>INTELLECTUAL PROPERTY RIGHTS:</strong>
                                    <ol>
                                        <li tabindex="0">
                                            The Company shall own title, interest and rights, including all related
                                            intellectual property rights, in/of the Application and/or your Device
                                            including but not limited to object code of the Application, features of
                                            services, which is/are protected by the applicable laws except the content
                                            owned by the user. You acknowledge that such ownership shall include all
                                            intellectual property rights arising from any suggestions, enhancement
                                            requests, recommendations or other information provided by the user, whether
                                            in the course of use of the Application, your Device or otherwise.
                                        </li>
                                    </ol>
                                </li>
                                <li tabindex="0">
                                    <strong>GRANT OF RIGHTS:</strong>
                                    <ol>
                                        <li tabindex="0">
                                            The Company allows the user(s) to share their screen while conducting video
                                            or audio conferencing with other user(s). The privacy policy for such
                                            sharing is available at
                                            <a class="pointer" tabindex="0" role="link" (click)="gotopp()">here</a>.
                                        </li>
                                        <li tabindex="0">
                                            The Company allows the user who initiates the video or audio conferencing
                                            (“Host”) to record the video or audio conferencing. The privacy policy for
                                            such sharing is available at
                                            <a class="pointer" tabindex="0" role="link" (click)="gotopp()">here</a>.
                                            Once the Host starts recording a video you would be able to see the
                                            recording sign. Your continued use of the Application even after seeing the
                                            recording sign would be taken as your deemed consent. In case you do not
                                            wish for the audio or video conference to be recorded you may leave the
                                            conference at any time after you have noticed that the conference is being
                                            recorded.
                                        </li>
                                    </ol>
                                </li>

                                <li tabindex="0">
                                    <strong>USE LIMITATIONS:</strong>
                                    <ol>
                                        <li tabindex="0">
                                            The Application is merely intended as a means for video or audio
                                            conferencing on multiple platforms.
                                        </li>
                                        <li tabindex="0">
                                            You acknowledge and agree that the free minutes as offered by the Company is
                                            at its sole discretion <strong>(“User Free Minutes”)</strong> and the
                                            Company shall not be liable to offer the User Free Minutes to every User
                                            showing its interest in. Further the User continued use of the Services post
                                            expiry of the User Free Minutes shall be subject to such payment plan as
                                            maybe mutually agreed between the Company and the User. In case the Company
                                            and the User fail to agree on the payment plan to be paid by the User, then
                                            the User’s access to the Services shall be terminated.
                                        </li>
                                        <li tabindex="0">
                                            As a condition of your use of the Application, you warrant to the Company
                                            that you will not use the Application for any purpose that is unlawful or
                                            prohibited by these Terms. You may not use the Application in any manner
                                            which could damage, disable, overburden or impair the Services or interfere
                                            with any other party's use and enjoyment of the Application.
                                        </li>
                                        <li tabindex="0">
                                            You are not entitled to use any kind of measures, mechanisms or tools
                                            (software or hardware) that could interfere with the functioning of the
                                            Application or any of its Services.
                                        </li>
                                        <li tabindex="0">
                                            You may not use the Services and/or Application if (i) you have been
                                            convicted of any offence under applicable law; and/or (ii) if you are under
                                            the age of 18 years.
                                        </li>
                                    </ol>
                                </li>

                                <li tabindex="0">
                                    <strong>JIOMEET (ENTERPRISE VERSION):</strong>
                                    <ol>
                                        <li tabindex="0">
                                            The following conditions shall additionally apply to use of the Application
                                            by an enterprise, being any person, company, limited liability partnership
                                            firm, partnership firm, registered society, trust and/or association of
                                            persons (“Enterprise”) for the purposes of use by the Enterprise through its
                                            employees:
                                            <div>&nbsp;</div>
                                            <ol>
                                                <li tabindex="0">
                                                    Registration: In order to use the Services through the Application,
                                                    the Enterprise availing the Application for the purposes of use by
                                                    its employees, is required to sign up for an administrator account
                                                    (“Administrator Account”) subject to such commercial terms and
                                                    Employee Account limitations as may be specified by the Company, and
                                                    updated and/or modified by the Company from time to time.
                                                </li>
                                                <li tabindex="0">
                                                    You acknowledge and agree that the free minutes as offered by the
                                                    Company is at its sole discretion
                                                    <strong>(“Enterprise Free Minutes”)</strong> and the Company shall
                                                    not be liable to offer the Enterprise Free Minutes to every
                                                    Enterprise showing its interest in. Further the Enterprise continued
                                                    use of the Services post expiry of the Enterprise Free Minutes shall
                                                    be subject to such payment plan as maybe mutually agreed between the
                                                    Company and the Enterprise. In case the Company and the Enterprise
                                                    fail to agree on the payment plan to be paid by the Enterprise, then
                                                    the Enterprise’s access to the Services shall be terminated.
                                                </li>
                                                <li tabindex="0">
                                                    The Administrator Account, upon being successfully created, shall be
                                                    used by the enterprise to open or create such employee accounts as
                                                    may be availed by the Enterprise in accordance with Clause 7.1.1
                                                    (each an “Employee Account”)
                                                </li>
                                                <li tabindex="0">
                                                    The Employee Accounts created shall be non-transferable and meant
                                                    for such use as may be authorized by the Enterprise. Notwithstanding
                                                    the foregoing but without prejudice to the absolute obligation of
                                                    the Enterprise to ensure compliance, the use of the Application by
                                                    the Enterprise, including its employees, shall be strictly for the
                                                    purposes of internal business use of the Enterprise and shall, at
                                                    all times, be in accordance with such terms and conditions,
                                                    including limitations, as may be prescribed by the Company from time
                                                    to time.
                                                </li>
                                                <li tabindex="0">
                                                    The Enterprise assures to provide us with accurate, complete, and
                                                    updated registration information about yourself or an authorized
                                                    personnel of your company.
                                                </li>
                                                <li tabindex="0">
                                                    The Administrator Account and each Employee Account shall be treated
                                                    as a user for the purposes of these Terms and wherever the
                                                    expression user is employed, it shall mean and include the
                                                    Administrator Account and each Employee Account.
                                                </li>
                                                <li tabindex="0">
                                                    Upon activation of the Administrator Account by an Enterprise, the
                                                    Enterprise will be charged with the monthly subscription as has been
                                                    agreed by the Enterprise and the Company. For avoidance of doubt,
                                                    the Enterprise will be charged with the monthly subscription even if
                                                    the Administrator Account or the respective User Accounts are
                                                    activated or not.
                                                </li>
                                                <li tabindex="0">
                                                    The Administrator Account and respective User Accounts will be
                                                    active and such subscription will automatically renew every billing
                                                    month, until the Enterprise cancels the subscription in the manner
                                                    as stipulated by the Company from time to time.
                                                </li>
                                                <li tabindex="0">
                                                    The Company does not offer refunds or credits for partial periods of
                                                    service, or unused months. Please note that if the Enterprise
                                                    cancels the Administrator Account or any User Account, the
                                                    Enterprise will still be billed in the next payment cycle for any
                                                    charges incurred during the previous month.
                                                </li>
                                                <!-- </ul> -->
                                            </ol>
                                        </li>
                                    </ol>
                                </li>

                                <li tabindex="0">
                                    <strong>GENERAL:</strong>
                                    <ol>
                                        <li tabindex="0">
                                            By using our Services you hereby agree to ensure that the explicit
                                            prohibitions mentioned in section 9.3 are at all times adhered to.
                                        </li>
                                        <li tabindex="0">
                                            Use Restriction: You must not access the Application if you are a person who
                                            is either barred or otherwise legally prohibited from receiving or using the
                                            Service or any Services under the laws of India.
                                        </li>
                                        <li tabindex="0">
                                            You shall not host, display, upload, modify, publish, transmit, update or
                                            share any information which:
                                            <ol type="a">
                                                <li tabindex="0">
                                                    Belongs to another person and to which you do not have any right to;
                                                </li>
                                                <li tabindex="0">
                                                    Is grossly harmful, harassing, blasphemous, defamatory, obscene,
                                                    pornographic, paedophilic, libellous, invasive of another’s privacy,
                                                    hateful, or racially, ethnically objectionable, disparaging,
                                                    relating or encouraging money laundering or gambling or otherwise
                                                    unlawful in any manner whatsoever; or unlawfully threatening or
                                                    unlawfully harassing including but not limited to “indecent
                                                    representation of women” within the meaning of the Indecent
                                                    Representation of Women (Prohibition) Act 1986;
                                                </li>
                                                <li tabindex="0">Is misleading in any way;</li>
                                                <li tabindex="0">
                                                    Is patently offensive to the online community, such as sexually
                                                    explicit content, or content that promotes obscenity, paedophilia,
                                                    racism, bigotry, hatred or physical harm of any kind against any
                                                    group or individual;
                                                </li>
                                                <li tabindex="0">
                                                    Harassing or advocates harassment of another person;
                                                </li>
                                                <li tabindex="0">
                                                    Involves the transmission of “junk mail”, “chained letters”, or
                                                    unsolicited mass mailing or “spamming”;
                                                </li>
                                                <li tabindex="0">
                                                    Promotes illegal activities or conduct that is abusive, threatening,
                                                    obscene, defamatory or libellous;
                                                </li>
                                                <li tabindex="0">
                                                    Infringes upon or violates any third party’s rights (including, but
                                                    not limited to, intellectual property rights, rights of privacy or
                                                    rights of publicity);
                                                </li>
                                                <li tabindex="0">
                                                    Promotes an illegal or unauthorized copy of another person’s
                                                    copyrighted work;
                                                </li>
                                                <li tabindex="0">
                                                    Provides instructional information about illegal activities such as
                                                    making and/or buying illegal weapons, violating someone’s privacy,
                                                    or providing or creating computer viruses;
                                                </li>
                                                <li tabindex="0">
                                                    Solicits gambling or engages in any gambling activity which we in
                                                    our sole discretion believe is or could be construed to be as
                                                    illegal;
                                                </li>
                                                <li tabindex="0">Harms minors in any way;</li>
                                                <li tabindex="0">Violates any law for the time being in force;</li>
                                                <li tabindex="0">Impersonates another person;</li>
                                                <li tabindex="0">
                                                    Contains software viruses or any other computer code, files or
                                                    programs designed to interupt, destroy or limit the functionality of
                                                    any computer resource; or contains any trojan horses, worms, time
                                                    bombs, cancelbots, easter eggs or other computer programming
                                                    routines that may damage, detrimentally interfere with, diminish
                                                    value of, surreptiously intercept or expropriate any system, data or
                                                    personal information;
                                                </li>
                                                <li tabindex="0">
                                                    Threatens the unity, integrity, defence, security or sovereignty of
                                                    India, friendly relations with foreign states, or public order or
                                                    causes incitement to the commission of any cognizable offense or
                                                    prevents investigation of any offense or is insulting any other
                                                    nation.
                                                </li>
                                            </ol>
                                        </li>
                                        <li tabindex="0">
                                            Services may be made available for free of cost or for a specified cost as
                                            may be decided by the Company from time to time.
                                        </li>
                                        <li tabindex="0">
                                            Pricing and availability of the Services are subject to change any time
                                            prior to the purchase.
                                        </li>
                                        <li tabindex="0">
                                            Third-Party Fees: You may incur access or data fees from third parties (such
                                            as your internet provider or mobile carrier) in connection with your use of
                                            Services and of Application. You are solely responsible for all such fees.
                                        </li>
                                        <li tabindex="0">
                                            Updates: You may need to install updates to the Application or related
                                            software that we introduce from time to time to use of the Application.
                                            Services originating from the Application may communicate with the servers
                                            from time to time to check for available updates to the Services and to the
                                            functionality of the Application, such as bug fixes, patches, enhanced
                                            functions, missing plug-ins and new versions (collectively, "Updates"). Your
                                            use of the Services you have installed requires that you have agreed to
                                            receive such automatically requested Updates.
                                        </li>
                                        <li tabindex="0">
                                            Accuracy of Information: You agree that the information entered by you while
                                            profile creation/updation or using any of Services is true, current,
                                            complete and accurate in all respects. The Company is not responsible for
                                            any losses arising out of discrepancy in your data. You are solely
                                            responsible for maintaining confidentiality and security of your user
                                            account and for all activities that occur on or through your user account.
                                            The Company is not responsible for any losses arising out of the
                                            unauthorized use of your user account.
                                        </li>
                                    </ol>
                                </li>
                                <li tabindex="0">
                                    <strong>LIMITATION OF LIABILITY:</strong>
                                    <ol>
                                        <li tabindex="0">
                                            To the maximum extent permitted by applicable law, in no event shall the
                                            company be liable to you for any special, incidental, indirect, punitive or
                                            consequential damages whatsoever (including, without limitation, damages for
                                            loss of use, loss of business profits, business interruption, loss of
                                            information, or any other pecuniary loss) arising out of the use of, or
                                            inability to use or access, the application or for any security breach or
                                            any virus, bug, unauthorized intervention, defect, or technical
                                            malfunctioning of the application, whether or not foreseeable or whether or
                                            not the company has been advised of the possibility of such damages, or
                                            based on any theory of liability, including breach of contract or warranty,
                                            negligence or other tortious action, or any other claim arising out, of or
                                            in connection with, your use of, or access to, the application or the
                                            content. Further, the company shall not be liable to you for any temporary
                                            disablement, permanent discontinuance or modification of the application by
                                            the company or for any consequences resulting from such actions.
                                        </li>
                                        <li tabindex="0">
                                            You shall be solely responsible for obtaining and maintaining any equipment
                                            and ancillary services (including the payment of any additional fees
                                            therefore) required to connect to, access or otherwise use the services,
                                            including, without limitation, video-enabled devices, video communication
                                            services, modems, hardware, servers, software, operating systems,
                                            networking, web servers, internet and telephone service (collectively,
                                            “equipment”). You will also be responsible for maintaining the security of
                                            the equipment and for all uses of the equipment with or without your
                                            knowledge or consent.
                                        </li>
                                        <li tabindex="0">
                                            You are solely responsible for the content and other materials you transmit
                                            through the service or share with other users or recipients. You will not
                                            transmit any content that you did not create or that you do not own all
                                            right, title and interest in and to, including, without limitation, all
                                            copyright and rights of publicity contained therein.
                                        </li>
                                        <li tabindex="0">
                                            The company and/or its respective affiliates makes no representations about
                                            the suitability, reliability, availability, timeliness and accuracy of the
                                            information, software and services and related content contained on the
                                            application for any purpose. All such information, software and services and
                                            related content are provided "As is" without warranty of any kind. The
                                            company and/or its respective suppliers hereby disclaims all warranties and
                                            conditions with regard to this information, software, and services and
                                            related content, including all implied warranties and conditions of
                                            merchantability, fitness for a particular purpose, title and
                                            non-infringement.
                                        </li>
                                        <li tabindex="0">
                                            You specifically agree that the company shall not be responsible for
                                            unauthorised access to or alteration of your transmissions or data, any
                                            material or data sent or received or not sent or received, or any
                                            transactions entered into through the application. You specifically agree
                                            that the company is not responsible or liable for any threatening, tory,
                                            obscene, offensive or illegal services or conduct of any other party or any
                                            infringement. Of another's rights, including intellectual property rights.
                                            You specifically agree that the company is not responsible for any services
                                            sent using and/or included in the application by any third party.
                                        </li>
                                        <li tabindex="0">
                                            In no event shall the company and/or its affiliates be liable for any
                                            direct, indirect, punitive, incidental, special, consequential damages or
                                            any damages whatsoever including, without limitation, damages for or in
                                            relation to: (a) loss of use, data or profits arising out of or in any way
                                            connected with the use or performance of application; (b) any delay or
                                            inability to use the application or services; (c) the provision of or
                                            failure to provide services, or for any information or services obtained
                                            through the application; (d) or otherwise arising out of the use of the
                                            application or the services of the company; whether or not all of the above
                                            losses, claims or damages are based on contract, tort, negligence, strict
                                            liability, even if the company and/or its affiliates and/or its associates
                                            have been advised of the possibility of such losses, claims or damages.
                                        </li>
                                    </ol>
                                </li>

                                <li tabindex="0">
                                    <strong>TERMINATION (TEMPORARY AND PERMANENT DISABLEMENT):</strong>
                                    <ol>
                                        <li tabindex="0">
                                            Unless otherwise provided in these Terms, the Services offered to you may be
                                            terminated by the Company without assigning any reasons at any time at the
                                            sole discretion of the Company. Upon termination/suspension, you shall not
                                            have the right to use or access the Application/Services.
                                        </li>
                                        <li tabindex="0">
                                            The Company reserves the right to suspend and/or terminate your access to
                                            the Services in case of any non-adherence to these Terms and Conditions by
                                            you.
                                        </li>
                                    </ol>
                                </li>
                                <li tabindex="0">
                                    <strong>GOVERNING LAW AND DISPUTE RESOLUTION:</strong>
                                    <ol>
                                        <li tabindex="0">
                                            These terms and conditions are governed and construed in accordance with the
                                            laws of India. The courts in Mumbai shall have exclusive jurisdiction to
                                            hear disputes arising out of these terms.
                                        </li>
                                    </ol>
                                </li>
                                <li tabindex="0">
                                    <strong>FORCE MAJEURE:</strong>
                                    <ol>
                                        <li tabindex="0">
                                            The Company shall be under no liability whatsoever in the event of
                                            non-availability of any portion of the Application or subscribed content
                                            occasioned by act of God, war, disease, revolution, riot, civil commotion,
                                            strike, lockout, flood, fire, failure of any public utility, manmade
                                            disaster, infrastructure failure or any other cause whatsoever beyond the
                                            control of the Company.
                                        </li>
                                    </ol>
                                </li>
                                <li tabindex="0">
                                    <strong>SEVERABILITY:</strong>
                                    <ol>
                                        <li tabindex="0">
                                            If any of the provisions of these Terms are deemed invalid, void, or for any
                                            reason unenforceable, that part of these Terms will be deemed severable and
                                            will not affect the validity and enforceability of any remaining provisions
                                            of these Terms.
                                        </li>
                                    </ol>
                                </li>
                                <li tabindex="0">
                                    <strong>CONTACT DETAILS:</strong>
                                    <ol>
                                        <li tabindex="0">
                                            Address: Office- 101, Saffron, Near Centre Point, Panchwati 5 Rasta,
                                            Ambawadi, Ahmedabad
                                            <br />Email:
                                            <a href="mailto:enterpriseservices@jio.com" tabindex="0" role="link"
                                                >enterpriseservices@jio.com</a
                                            >
                                            <br />
                                            Website:
                                            <a target="_blank" [href]="websiteLink" tabindex="0" role="link">{{
                                                websiteLink
                                            }}</a>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                        <p tabindex="0">
                            <a
                                class="monthly-report"
                                tabindex="0"
                                role="link"
                                target="_blank"
                                [href]="monthlyComplianceReportUrl"
                                >Monthly compliance report</a
                            >
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" *ngIf="isLangHindi">
            <div class="row">
                <div class="col-12 col-lg-10 m-auto pt-4">
                    <h2 class="pb-2 text-center" style="color: rgb(0, 0, 0)">जियोमीट के लिए नियम एवं शर्तें</h2>
                    <div class="card radius-card card-format">
                        <div class="card-body">
                            <ol>
                                <li>
                                    <strong>स्कोप:</strong>
                                    <ol>
                                        <li>
                                            &quot;जियो प्लेटफ़ॉर्म लिमिटेड (“कंपनी”, “हम” या “हमें”) द्वारा प्रदान की गई
                                            &quot;&quot;जियोमीट&quot;&quot; (“ऐप्लिकेशन”) एक मल्टीप्लेटफ़ॉर्म वीडियो
                                            कॉन्फ्रेंसिंग ऐप्लिकेशन है, जिसका कार्यालय-101, सैफ़्रन, सेंटर पॉइंट के पास,
                                            पंचवटी 5 रास्ता, अम्बावाड़ी, अहमदाबाद में स्थित है।
                                        </li>
                                        <li>
                                            ऐप्लिकेशन अपने उपयोगकर्ताओं को, निम्नलिखित सेवाएं प्रदान करती है: (i)
                                            कई-लोगों के साथ एच.डी गुणवत्ता वीडियो कॉन्फ्रेंसिंग; (ii) वीडियो
                                            कॉन्फ्रेंसिंग के लिए त्वरित और अनुसूचित इन्वाइट; (iii) विभिन्न माध्यमों से
                                            इन्वाइट करना जैसे (क) ईमेल; (ख) एस.एम.एस; (ग) व्हाट्सऐप जैसी इंटरनेट आधारित
                                            मैसेजिंग ऐप्लिकेशन; (घ) उपयोगकर्ता के डिवाइस में स्थापित थर्ड पार्टी के लिए
                                            संदेश सेवा (यहां परिभाषित करने के बाद); (iv) इन्वाइट किए गए लोगों को किसी भी
                                            उपयुक्त डिवाइस में स्थापित एप्लिकेशन के माध्यम से या लैपटॉप/डेस्कटॉप में
                                            स्थापित किसी भी उपयुक्त वेब ब्राउज़र के माध्यम से कॉन्फ्रेंस में शामिल होने
                                            की अनुमति देता है; (v) समृद्ध संचार सेवाएं जिसमें शामिल हैं (क) संदेश
                                            सेवाऍं, जहां उपयोगकर्ता एक साथ संदेश भेज सकते हैं और स्क्रीन शेयर करने जैसी
                                            सेवाओं के माध्यम से चित्र, संपर्क और स्थान भी दिखा सकते हैं; (ख) फ़ाइल शेयर
                                            करने जैसी सेवाएँ, जहाँ कोई उपयोगकर्ता थर्ड पार्टी के साथ फ़ाइलें शेयर कर
                                            सकता है; और (ग) ग्रुप चैट, (सामूहिक रूप से “रिच कम्युनिकेशन सर्विसेज़” या
                                            “आर.सी.एस”) सहित चैट सेवाएं; और (vi) एंड्रॉइड, माइक्रोसॉफ्ट विंडोज़ या
                                            आई.ओ.एस और जियोफाइबर सेट टॉप बॉक्स ("जियोSTB"); (“डिवाइस”) द्वारा संचालित
                                            किसी भी उपुक्त स्मार्ट फोन पर कंपनी द्वारा समय-समय पर प्रदान की जाने वाली
                                            कोई भी अन्य सेवा (जिसे सामूहिक रूप से “सेवाओं” के रूप में जाना जाता है)।
                                        </li>
                                        <li>
                                            आपके (“आप”, “आपका” या “उपयोगकर्ता”) ऐप्लिकेशन का उपयोग इन
                                            <a class="pointer" tabindex="0" role="link" (click)="gototnc()"
                                                >नियमों और शर्तों</a
                                            >
                                            (“नियम”) के अधीन है।
                                        </li>
                                        <li>
                                            डिवाइस पर इस ऐप्लिकेशन का उपयोग करने के लिए आवश्यक है कि आप इन शर्तों और
                                            ऐप्लिकेशन की
                                            <a class="pointer" tabindex="0" role="link" (click)="gotopp()"
                                                >गोपनीयता नीति</a
                                            >
                                            से सहमत हों। ऐप्लिकेशन को डाउनलोड करने और ऐप्लिकेशन में पंजीकृत करने या
                                            हस्ताक्षर करने से, या अन्यथा इसका एक्सेस प्राप्त करने, और/या ऐप्लिकेशन का
                                            उपयोग करने से, आप इस बात को स्वीकार करते हैं कि आपने इसे पढ़ा है और समझा है
                                            और इन नियमों और आवेदन की गोपनीयता नीति द्वारा नियंत्रित और बाध्य होने की
                                            सहमति दी है। यदि आप ऐप्लिकेशन की शर्तों या गोपनीयता नीति को नहीं समझते हैं,
                                            या उनमें से किसी भी भाग को स्वीकार नहीं करते हैं, तो आपको ऐप्लिकेशन का उपयोग
                                            नहीं करना चाहिए।
                                        </li>
                                        <li>
                                            शर्तें, सूचना प्रौद्योगिकी अधिनियम, 2000 (संशोधित / पुन: अधिनियमित) ("IT
                                            अधिनियम") और उसके तहत नियमों के संदर्भ में एक इलेक्ट्रॉनिक रिकॉर्ड हैं, और
                                            सूचना प्रौद्योगिकी (मध्यस्थ दिशानिर्देश और डिजिटल मीडिया आचार संहिता) नियम,
                                            2021 के नियम 3 (1) के प्रावधान के अनुसार प्रकाशित की जाती हैं जो नियमों और
                                            विनियमों, गोपनीयता नीति और ऐप्लिकेशन के उपयोग या उपयोग के लिए उपयोग की
                                            शर्तों के ऐक्सेस के लिए अनिवार्य है। यह इलेक्ट्रॉनिक रिकॉर्ड एक कंप्यूटर
                                            सिस्टम द्वारा बनाया गया है जिसे किसी भी भौतिक या डिजिटल हस्ताक्षर की
                                            आवश्यकता नहीं है।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>व्यक्तिगत जानकारी की गोपनीयता और सुरक्षा:</strong>
                                    <ol>
                                        <li>कृपया यहां उपलब्ध ऐप्लिकेशन की गोपनीयता नीति देखें।</li>
                                        <li>
                                            कृपया नोट करें कि आपके डिवाइस पर ऐप्लिकेशन या सेवाओं का उपयोग और गूगल प्ले
                                            स्टोर/ऐप्पल ऐप स्टोर से डाउनलोड करना संबंधित गोपनीयता नीतियों के अधीन हो
                                            सकता है जैसा कि डिवाइस या जियोSTB और गूगल प्ले स्टोर/ऐप्पल ऐप स्टोर द्वारा
                                            प्रकाशित या उपलब्ध कराया जा सकता है।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>संशोधन:</strong>
                                    <ol>
                                        <li>
                                            शर्तों का संशोधन: कंपनी उन शर्तों को बदलने का अधिकार सुरक्षित रखती है जिनके
                                            तहत सेवाएं प्रदान की जाती हैं, ये शर्तें सेवाओं के उपयोग से जुड़े शुल्क, यदि
                                            कोई हो, तक ही सीमित नहीं हैं। व्यापार, कानूनी और नियामक आवश्यकताओं में
                                            परिवर्तन के आधार पर शर्तों को और संशोधित किया जा सकता है और इन्हें ऑनलाइन
                                            अपडेट कर दिया जाएगा। जब भी शर्तों में कोई परिवर्तनकिया जाता है तो हमआपको
                                            सूचित करेंगे। आपको इन शर्तों और इसमें किसी भी बदलाव की समीक्षा करने के लिए
                                            समय-समय पर इस पेज पर जाने के लिए प्रोत्साहित किया जाता है।
                                        </li>
                                        <li>
                                            सेवाओं का संशोधन: कंपनी अपने स्वविवेक के अनुसार किसी भी समय सेवाओं में
                                            उपलब्ध किसी भी कंटेंट या फ़ीचर को जोड़ने, संशोधित करने या डिलीट करने का
                                            अधिकार सुरक्षित रखती है।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>थर्ड पार्टी की साइट और ऐप्लिकेशन के लिंक :</strong>
                                    <ol>
                                        <li>
                                            ऐप्लिकेशन में अन्य वेब साइट/ऐप्लिकेशन (&quot;लिंक्ड साइट्स&quot;) के लिंक हो
                                            सकते हैं। लिंक की गई साइट कंपनी के नियंत्रण में नहीं होती हैं और कंपनी किसी
                                            भी लिंक की गई साइट के कंटेंट के लिए ज़िम्मेदार नहीं है, जिसमें लिंक की गई
                                            साइट में निहित किसी भी लिंक को, या लिंक की गई साइट पर किसी भी परिवर्तन या
                                            अपडेट या लिंक की गई साइट पर प्रेषित किसी भी जानकारी को सीमित नहीं किया जाता
                                            है। किसी भी लिंक को शामिल करने का आशय साइट की कंपनी या इसके प्रचालकों के साथ
                                            कोई संबंध नहीं है।
                                        </li>
                                        <li>
                                            ऐप्लिकेशन या विज्ञापन के प्रसार के लिंक के माध्यम से उपलब्ध थर्ड पार्टी
                                            (विज्ञापनदाताओं सहित) के साथ कोई भी डील, या इस तरह की डील या प्रचार-प्रसार
                                            से जुड़ी वस्तुओं और सेवाओं के लिए पेमेंट, और ऐसी डील से संबंधित कोई भी अन्य
                                            नियम, शर्तें, वॉरंटी, केवल आपके और विज्ञापनदाता या अन्य थर्ड पार्टी के बीच
                                            होती हैं। कंपनी ऐसी किसी भी डील या प्रचार के लिए जिम्मेदार या उत्तरदायी नहीं
                                            होगी।
                                        </li>
                                        <li>
                                            आप स्वीकार करते हैं और सहमत होते हैं कि किसी भी लिंक की गई साइट का उपयोग ऐसे
                                            थर्ड पार्टी के उपयोग की शर्तों, लाइसेंस एग्रीमेंट, गोपनीयता नीति, या इस तरह
                                            के अन्य एग्रीमेंट से नियंत्रित होते हैं। किसी भी जानकारी के सार्वजनिक होने
                                            या किसी थर्ड पार्टी द्वारा होने वाले अन्य कायों के लिए कंपनी किसी भी प्रकार
                                            की जिम्मेदारी नहीं लेती है। कंपनी आपकी ऐसी किसी भी व्यक्तिगत या अन्य
                                            जानकारी, जो किसी थर्ड पार्टी द्वारा एकत्रित, संसाधित, शेयर की या रखी जा सकती
                                            है, के संबंध में स्पष्ट रूप से किसी भी वारंटी को अस्वीकार करती है।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>बौद्धिक संपदा अधिकार:</strong>
                                    <ol>
                                        <li>
                                            ऐप्लिकेशन और/या आपके डिवाइस में/से संबंधित सभी बौद्धिक संपदा अधिकारों सहित,
                                            शीर्षक, हित और अधिकारों का स्वामित्व कंपनी के पास होगा, जिसमें उपयोगकर्ता के
                                            स्वामित्व वाले कंटेंट को छोड़कर कानून द्वारा संरक्षित ऐप्लिकेशन के ऑब्जेक्ट
                                            कोड, सेवाओं के फ़ीचर शामिल है/हैं, लेकिन सीमित नहीं हैं। आप स्वीकार करते हैं
                                            कि इस तरह के स्वामित्व में किसी भी सुझाव, वृद्धि अनुरोधों, सुझावों या
                                            उपयोगकर्ता द्वारा प्रदान की गई अन्य जानकारी से उत्पन्न सभी बौद्धिक संपदा
                                            अधिकार शामिल होंगे, चाहे वे ऐप्लिकेशन, आपके डिवाइस के उपयोग के दौरान या
                                            अन्यथा हों।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>अधिकारों का लाभ:</strong>
                                    <ol>
                                        <li>
                                            कंपनी उपयोगकर्ता(उपयोगकर्ताओं) को अन्य उपयोगकर्ता(उपयोगकर्ताओं) के साथ
                                            वीडियो या ऑडियो कॉन्फ्रेंसिंग करते हुए अपनी स्क्रीन शेयर करने की अनुमति देती
                                            है। इस प्रकार स्क्रीन शेयर करने के लिए गोपनीयता नीति
                                            <a class="pointer" tabindex="0" role="link" (click)="gotopp()"> यहां </a>
                                            उपलब्ध है।
                                        </li>
                                        <li>
                                            कंपनी उस उपयोगकर्ता को वीडियो या ऑडियो कॉन्फ्रेंसिंग रिकॉर्ड करने की अनुमति
                                            देती है जो वीडियो या ऑडियो कॉन्फ्रेंसिंग (“होस्ट”) शुरू करता है। इस प्रकार
                                            स्क्रीन शेयर करने के लिए गोपनीयता नीति
                                            <a class="pointer" tabindex="0" role="link" (click)="gotopp()"> यहां </a>
                                            उपलब्ध है। एक बार होस्ट वीडियो रिकॉर्ड करना शुरू कर देगा, तब आप रिकॉर्डिंग
                                            के साइन को देख पाएंगे। रिकॉर्डिंग का साइन देखने के बाद भी आपके द्वारा
                                            ऐप्लिकेशन का उपयोग जारी रखना, आपकी सहमति के रूप में लिया जाएगा। यदि आप ऑडियो
                                            या वीडियो कॉन्फ्रेंस रिकॉर्ड नहीं करना चाहते हैं, तो कॉन्फ्रेंस रिकॉर्ड हो
                                            रही है यह देखने के बाद आप किसी भी समय कॉन्फ्रेंस छोड़ सकते हैं।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>सीमाओं का उपयोग करेंः</strong>
                                    <ol>
                                        <li>
                                            ऐप्लिकेशन कई प्लेटफ़ॉर्म पर वीडियो या ऑडियो कॉन्फ्रेंस के लिए मात्र एक साधन
                                            के रूप में नियत है।
                                        </li>
                                        <li>
                                            आप स्वीकार करते हैं और सहमत होते हैं कि कंपनी द्वारा प्रदान की जाने वाली
                                            मुफ्त मिनट
                                            <strong>(कंपनी द्वारा "उपयोगकर्ता मुफ्त मिनट्स" कही जाती है)</strong> कंपनी
                                            के पूर्ण विवेक पर निर्धारित होती है और कंपनी किसी भी उपयोगकर्ता को
                                            उपयोगकर्ता मुफ्त मिनट्स प्रदान करने के लिए ज़िम्मेदार नहीं होगी, जो अपनी
                                            रुचि प्रदर्शित कर रहा है। इसके अलावा, उपयोगकर्ता के मुफ्त मिनट्स समाप्त होने
                                            के बाद उपयोगकर्ता की सेवाओं का आगे उपयोग उस भुगतान योजना के अधीन होगा जो
                                            कंपनी और उपयोगकर्ता के बीच साझा की जा सकती है। यदि कंपनी और उपयोगकर्ता
                                            उपयोगकर्ता द्वारा भुगतान योजना पर सहमत नहीं होते हैं, तो उपयोगकर्ता की
                                            सेवाओं तक पहुँच समाप्त हो जाएगी।
                                        </li>
                                        <li>
                                            ऐप्लिकेशन उपयोग करने की शर्त के रूप में, आप कंपनी को इस बात का विश्वास
                                            दिलाते हैं कि आप किसी भी ऐसे उद्देश्य के लिए ऐप्लिकेशन का उपयोग नहीं करेंगे
                                            जो गैरकानूनी या इन शर्तों के तहत निषिद्ध है। आप एप्लिकेशन का उपयोग इस प्रकार
                                            से नहीं कर सकते हैं, जिससे सेवाओं को नुकसान पहुंचे, वे अक्षम हो जायें, इन पर
                                            अतिभार आ जाए या वे कार्य करना बंद कर दें या जिससे किसी अन्य पार्टी द्वारा
                                            उपयोग और ऐप्लिकेशन के लाभ प्राप्त करने में बाधा उत्पन्न हो।
                                        </li>
                                        <li>
                                            आप किसी भी प्रकार के मापदंडों, तंत्रों या उपकरणों (सॉफ़्टवेयर या हार्डवेयर)
                                            का उपयोग करने के हकदार नहीं हैं जो ऐप्लिकेशन या इसकी किसी भी सेवा के कार्य
                                            में बाधा डाल सकते हैं।
                                        </li>
                                        <li>
                                            आप सेवाओं और/या ऐप्लिकेशन का उपयोग नहीं कर सकते हैं यदि (i) आपको लागू कानून
                                            के तहत किसी अपराध का दोषी ठहराया गया है; और/या (ii) यदि आप 18 वर्ष से कम आयु
                                            के हैं।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>जियोमीट (इंटरप्राइज़ वर्ज़न):</strong>
                                    <ol>
                                        <li>
                                            इंटरप्राइज़, बतौर व्यक्ति, कंपनी, सीमित देयता भागीदारी फर्म, साझेदारी फर्म,
                                            पंजीकृत सोसाइटी, ट्रस्ट और/या व्यक्तियों के संघठन (“एंटरप्राइज”) द्वारा अपने
                                            कर्मचारियों के माध्यम से इस ऐप्लिकेशन के उपयोग के लिए निम्नलिखित शर्तें
                                            अतिरिक्त रूप से लागू होंगी:
                                            <div></div>
                                            <ol>
                                                <li>
                                                    पंजीकरण: ऐप्लिकेशन के माध्यम से सेवाओं का उपयोग करने के लिए, अपने
                                                    कर्मचारियों द्वारा उपयोग के उद्देश्य से ऐप्लिकेशन के लाभ प्राप्त
                                                    करने के लिए इंटरप्राइज़ को एडमिनिस्ट्रेटर अकाउंट (“एडमिनिस्ट्रेटर
                                                    अकाउंट”) के लिए साइनअप करना होगा, जो वाणिज्यिक शर्तों और कर्मचारी
                                                    खाता सीमा के अधीन होगा, जैसा कि कंपनी द्वारा निर्दिष्ट किया जा सकता
                                                    है, और समय-समय पर कंपनी द्वारा अपडेट और/या संशोधित किया जा सकता है।
                                                </li>
                                                <li>
                                                    आप स्वीकार करते हैं और सहमत होते हैं कि कंपनी द्वारा प्रदान की जाने
                                                    वाली मुफ्त मिनट
                                                    <strong
                                                        >(कंपनी द्वारा "एंटरप्राइज मुफ्त मिनट्स" कही जाती है)</strong
                                                    >
                                                    कंपनी के पूर्ण विवेक पर निर्धारित होती है और कंपनी किसी भी
                                                    एंटरप्राइज को एंटरप्राइज मुफ्त मिनट्स प्रदान करने के लिए ज़िम्मेदार
                                                    नहीं होगी, जो अपनी रुचि प्रदर्शित कर रहा है। इसके अलावा, एंटरप्राइज
                                                    के मुफ्त मिनट्स समाप्त होने के बाद एंटरप्राइज की सेवाओं का आगे उपयोग
                                                    उस भुगतान योजना के अधीन होगा जो कंपनी और एंटरप्राइज के बीच साझा की
                                                    जा सकती है। यदि कंपनी और एंटरप्राइज एंटरप्राइज द्वारा भुगतान योजना
                                                    पर सहमत नहीं होते हैं, तो एंटरप्राइज की सेवाओं तक पहुँच समाप्त हो
                                                    जाएगी।
                                                </li>
                                                <li>
                                                    सफलतापूर्वक बनाए जाने के बाद एडमिनिस्ट्रेटर अकाउंट का उपयोग
                                                    इंटरप्राइज़ द्वारा ऐसे कर्मचारी खाते खोलने या बनाने के लिए किया जाएगा
                                                    जिसे धारा 7.1.1 (प्रत्येक “कर्मचारी खाता”) के अनुसार इंटरप्राइज़
                                                    द्वारा प्राप्त किया जा सकता है।
                                                </li>
                                                <li>
                                                    बनाए गए कर्मचारी अकाउंट स्थानांतरित नहीं किए जाएंगे और इंटरप्राइज़
                                                    द्वारा ऐसे उपयोग के लिए अधिकृत किए जा सकेंगे। पूर्वगामी के बावजूद
                                                    लेकिन अनुपालन सुनिश्चित करने के लिए एंटरप्राइज़ के पूर्ण दायित्व को
                                                    प्रभावित किये बिना, एंटरप्राइज़ के आंतरिक व्यावसायिक उद्देश्यों के
                                                    लिए कर्मचारियों सहित एंटरप्राइज़ द्वारा अपने ऐप्लिकेशन का उपयोग सख्त
                                                    रूप से किया जाएगा, और हर समय, कंपनी द्वारा समय समय पर निर्धारित
                                                    सीमाओं सहित ऐसे नियमों और शर्तों के अनुसार होगा।
                                                </li>
                                                <li>
                                                    एंटरप्राइज़ हमें अपने बारे में या आपकी कंपनी के अधिकृत कर्मियों के
                                                    बारे में सटीक, पूर्ण और अपडेटेड पंजीकरण जानकारी प्रदान करने का
                                                    आश्वासन देता है।
                                                </li>
                                                <li>
                                                    एडमिनिस्ट्रेटर अकाउंट और प्रत्येक कर्मचारी खाते को इन शर्तों के
                                                    प्रयोजनों के लिए एक उपयोगकर्ता के रूप में माना जाएगा और जहाँ भी
                                                    उपयोगकर्ता नियोजित होगा, वहां वो मान्य होगा और एडमिनिस्ट्रेटर अकाउंट
                                                    के साथ साथ प्रत्येक कर्मचारी अकाउंट इसमें शामिल होगा।
                                                </li>
                                                <li>
                                                    किसी इंटरप्राइज़ द्वारा एडमिनिस्ट्रेटर अकाउंट के एक्टिवेट होने पर,
                                                    इंटरप्राइज़ को मासिक सदस्यता के साथ इंटरप्राइज़ व कंपनी के बीच हुई
                                                    सहमति के आधार पर शुल्क देना होगा। संदेह से बचने के लिए, इंटरप्राइज़
                                                    से मासिक सदस्यता शुल्क लिया जाएगा भले ही एडमिनिस्ट्रेटर अकाउंट या
                                                    संबंधित उपयोगकर्ता अकाउंट सक्रिय हों या नहीं।
                                                </li>
                                                <li>
                                                    एडमिनिस्ट्रेटर अकाउंट या संबंधित उपयोगकर्ता अकाउंट एक्टिव हो जाएंगे
                                                    और इस तरह की सदस्यता हर बिलिंग महीने में स्वचालित रूप से नवीनीकृत हो
                                                    जाएगी, जब तक कि एंटरप्राइज़ समय-समय पर कंपनी द्वारा निर्धारित रूप से
                                                    सदस्यता को रद्द नहीं करता है।
                                                </li>
                                                <li>
                                                    कंपनी सेवा की आंशिक अवधि, या जिन महीनों में उपयोग नहीं किया गया है
                                                    उसके लिए रिफ़ंड या क्रेडिट नहीं देती है। कृपया ध्यान दें कि यदि
                                                    एंटरप्राइज़ एडमिनिस्ट्रेटर अकाउंट या किसी उपयोगकर्ता अकाउंट को रद्द
                                                    कर देता है, तब भी एंटरप्राइज़ को पिछले महीने के दौरान खर्च किए गए
                                                    किसी भी शुल्क के लिए अगले भुगतान चक्र में बिल भेजा जाएगा।
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>सामान्य:</strong>
                                    <ol>
                                        <li>
                                            हमारी सेवाओं का उपयोग करके आप यह सुनिश्चित करने के लिए सहमत हैं कि अनुभाग
                                            9.3 में उल्लिखित स्पष्ट निषेधों का हर समय पालन किया जाता है।
                                        </li>
                                        <li>
                                            प्रतिबंध का उपयोग करें: यदि आप ऐसे व्यक्ति हैं, जो भारत के कानूनों के तहत
                                            किसी भी सेवा को प्राप्त करने या उसका उपयोग करने से प्रतिबंधित हैं या कानूनी
                                            रूप से निषिद्ध हैं, तो आपको ऐप्लिकेशन का उपयोग नहीं करना चाहिए।
                                        </li>
                                        <li>
                                            आप किसी भी जानकारी को होस्ट, डिस्प्ले, अपलोड, संशोधित, प्रकाशित, प्रसारित,
                                            अपडेट या शेयर नहीं करेंगे जो:
                                            <ol>
                                                <li>किसी अन्य व्यक्ति का है और जिस पर आपका कोई अधिकार नहीं है;</li>
                                                <li>
                                                    घोर हानिकारक, परेशान करने वाला, ईशनिंदा करने वाला, मानहानिकारक,
                                                    गंदा, अश्लील है, पीडोफिलिक, अपमानजनक, दूसरे की गोपनीयता के लिए
                                                    आक्रामक, घृणित, या नस्लीय, जातीय रूप से आपत्तिजनक, अपमानजनक, मनी
                                                    लॉन्ड्रिंग या जुए से संबंधित या प्रोत्साहित करना या अन्यथा किसी भी
                                                    तरह से गैरकानूनी; या अवैध रूप से धमकी या गैरकानूनी रूप से परेशान
                                                    करने सहित, लेकिन "महिलाओं के अशोभनीय प्रतिनिधित्व तक सीमित नहीं"
                                                    महिलाओं के अशोभनीय प्रतिनिधित्व (निषेध) अधिनियम 1986 के अर्थ के
                                                    भीतर;
                                                </li>
                                                <li>किसी भी तरह से भ्रामक है;</li>
                                                <li>
                                                    ऑनलाइन समुदाय के लिए स्पष्ट रूप से आक्रामक है, जैसे कि स्पष्ट यौन
                                                    कंटेंट, या ऐसा कंटेंट जो बेहूदापन, पीडोफिलिया, जातिवाद, कट्टरता,
                                                    घृणा या किसी भी ग्रुप या व्यक्ति के खिलाफ किसी भी प्रकार की शारीरिक
                                                    क्षति को बढ़ावा देती है;
                                                </li>
                                                <li>
                                                    किसी अन्य व्यक्ति को परेशान करना या उसके उत्पीड़न की वकालत करना;
                                                </li>
                                                <li>
                                                    "जंक मेल", "चेन्ड लेटर्स", या "अवांछित मास मेलिंग या "स्पैमिंग" का
                                                    प्रसारण शामिल है;
                                                </li>
                                                <li>
                                                    अवैध गतिविधियों या आचरण को बढ़ावा देता है जो अपमानजनक, धमकी देने
                                                    वाला, गंदा, मानहानिकारक या अपमानजनक है;
                                                </li>
                                                <li>
                                                    किसी थर्ड पार्टी के अधिकारों का उल्लंघन करता है (जिसमें बौद्धिक
                                                    संपदा अधिकार, गोपनीयता के अधिकार, या प्रचार के अधिकार शामिल हैं,
                                                    लेकिन इन्हीं तक सीमित नहीं है);
                                                </li>
                                                <li>
                                                    किसी अन्य व्यक्ति के कॉपीराइट कार्य की अवैध या अनधिकृत कॉपी का
                                                    प्रचार करता है;
                                                </li>
                                                <li>अवैध गतिविधियों की जानकारी प्रदान करता है</li>
                                                <li>
                                                    जैसे अवैध हथियार बनाना और/या खरीदना, किसी की गोपनीयता का उल्लंघन
                                                    करना, या कंप्यूटर वायरस प्रदान करना या बनाना;
                                                </li>
                                                <li>
                                                    जुए का आग्रह करता है या किसी भी जुआ गतिविधि में संलग्न होता है जिसे
                                                    हम अपने विवेकाधिकार में मानते हैं या अवैध माना जा सकता है;
                                                </li>
                                                <li>नाबालिगों को किसी भी तरह से नुकसान पहुँचाता है;</li>
                                                <li>किसी अन्य व्यक्ति का प्रतिरूपण करता है;</li>
                                                <li>
                                                    किसी भी कंप्यूटर रिसोर्स की कार्यक्षमता को बाधित करने, नष्ट करने या
                                                    लिमिट करने के लिए डिज़ाइन किए गए सॉफ़्टवेयर वायरस या कोई अन्य
                                                    कंप्यूटर कोड, फ़ाइलें या प्रोग्राम शामिल हैं; या इसमें शामिल है
                                                </li>
                                                <li>
                                                    कोई भी ट्रोजन हॉर्स, वॉर्म्स, टाइम बम, कैंसिलबॉट, ईस्टर एग्स या अन्य
                                                    कंप्यूटर प्रोग्रामिंग रूटीन जो नुकसान पहुंचा सकते हैं, हानिकारक रूप
                                                    से हस्तक्षेप कर सकते हैं, मूल्य कम कर सकते हैं, गुप्त रूप से किसी भी
                                                    सिस्टम, डेटा या व्यक्तिगत जानकारी को रोक सकते हैं या जब्त कर सकते
                                                    हैं;
                                                </li>
                                                <li>
                                                    भारत की एकता, अखंडता, रक्षा, सुरक्षा या संप्रभुता, विदेशी राज्यों के
                                                    साथ मैत्रीपूर्ण संबंधों, या सार्वजनिक व्यवस्था या किसी भी संज्ञेय
                                                    अपराध के कमीशन के लिए उकसाने का कारण बनता है या किसी अपराध की जांच
                                                    को रोकता है या किसी अन्य राष्ट्र का अपमान करता है।
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            सेवाओं को मुफ्त में या कंपनी द्वारा समय-समय पर तय किए गए एक निर्दिष्ट लागत
                                            के लिए उपलब्ध कराया जा सकता है।
                                        </li>
                                        <li>
                                            सेवाओं का मूल्य निर्धारण और उपलब्धता खरीद से पहले किसी भी समय परिवर्तन के
                                            अधीन हैं।
                                        </li>
                                        <li>
                                            थर्ड-पार्टी शुल्क: सेवाओं और ऐप्लिकेशन के आपके उपयोग के कनेक्शन में आप थर्ड
                                            पार्टीज़ (जैसे आपका इंटरनेट प्रोवाइडर या मोबाइल कैरियर) से ऐक्सेस या डेटा
                                            शुल्क ले सकते हैं। आप इस तरह के सभी शुल्क के लिए स्वयं पूरी तरह जिम्मेदार
                                            हैं।
                                        </li>
                                        <li>
                                            अपडेट: आपको ऐप्लिकेशन या संबंधित सॉफ़्टवेयर में अपडेट इंस्टॉल करने की
                                            आवश्यकता हो सकती है जिसे हम समय-समय पर ऐप्लिकेशन के उपयोग के लिए पेश करते
                                            हैं। ऐप्लिकेशन से शुरू होने वाली सेवाएँ समय-समय पर सर्वर के साथ कम्युनिकेट
                                            कर सकता है ताकि सेवाओं के लिए उपलब्ध अपडेट और ऐप्लिकेशन की कार्यक्षमता, जैसे
                                            बग फिक्स, पैच, एन्हांस्ड फ़ंक्शन, मिसिंग प्लग-इन और नए वर्शंस (सामूहिक रूप
                                            से, "अपडेट") की जांच कर सकें। आपके द्वारा इंस्टॉल की गई सेवाओं के उपयोग के
                                            लिए आवश्यक है कि आप ऐसे स्वचालित रूप से अनुरोध किए गए अपडेट प्राप्त करने के
                                            लिए सहमत हैं।
                                        </li>
                                        <li>
                                            एक्यूरेसी ऑफ इंफॉर्मेशन: आप सहमत हैं कि प्रोफ़ाइल निर्माण/अपडेट या किसी भी
                                            सेवा का उपयोग करते समय आपके द्वारा दर्ज की गई जानकारी सत्य, लेटेस्ट, पूर्ण
                                            और हर तरह से सटीक है। आपके डेटा में अंतर से उत्पन्न होने वाले किसी भी नुकसान
                                            के लिए कंपनी जिम्मेदार नहीं है। आप अपने उपयोगकर्ता अकाउंट की गोपनियता और उसे
                                            सुरक्षित बनाए रखने और अपने उपयोगकर्ता अकाउंट पर या उसके माध्यम से होने वाली
                                            सभी गतिविधियों के लिए पूरी तरह से स्वयं जिम्मेदार हैं। कंपनी आपके उपयोगकर्ता
                                            अकाउंट के अनधिकृत उपयोग से उत्पन्न होने वाले किसी भी नुकसान के लिए जिम्मेदार
                                            नहीं है।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>दायित्व की सीमा :</strong>
                                    <ol>
                                        <li>
                                            लागू होने वाले कानून द्वारा निर्धारित अधिकतम सीमा तक किसी भी विशेष, आकस्मिक,
                                            अप्रत्यक्ष, दंडनीय, या निरंतर नुकसान के लिए, कंपनी आपके प्रति उत्तरदायी नहीं
                                            होगी (बिना किसी सीमा के, उपयोग न करने के नुकसान, व्यवसाय लाभ प्राप्त नहीं कर
                                            पाने से नुकसान, व्यवसाय में बाधा, जानकारी के नुकसान, या किसी भी अन्य गुप्त
                                            हानि सहित) जो इस ऐप्लिकेशन के उपयोग, या उसे एक्सेस करने में अक्षमता, या किसी
                                            भी प्रकार के सुरक्षा नियमों को तोड़े जाने, या किसी वायरस, या बग, अनाधिकृत
                                            हस्तक्षेप, खराबी, या ऐप्लिकेशन के तकनीकी विश्लेषण, एप्लीकेशन में गड़बड़ी, चाहे
                                            वो पहले से देखी गई हो या नहीं, या कंपनी ने किसी क्षति के बारे में पहले से
                                            बताया हो, या अनुबंध अथवा वारंटी को तोड़ने के उत्तरदायित्व, या लापरवाही या
                                            अनुचित कार्य या एप्लीकेशन अथवा इसके कंटेंट के उपयोग से सम्बंधित अन्य दावे के
                                            कारण हुआ हो। इसके इलावा, कंपनी आप के द्वारा किसी भी अस्थायी अयोग्यता, स्थायी
                                            रूप से बंद करने या कंपनी द्वारा ऐप्लिकेशन के संशोधन या इस तरह के कार्यों के
                                            परिणामस्वरूप होने वाले प्रभाव के लिए उत्तरदायी नहीं होगी।
                                        </li>
                                        <li>
                                            आप किसी भी उपकरण और कनेक्ट करने के लिए, सेवाओं के उपयोग या उसके एक्सेस के
                                            लिए आवश्यक सहायक सेवाओं (किसी भी अतिरिक्त शुल्क का भुगतान शामिल है) इसमें
                                            शामिल हैं, बिना किसी सीमा के, वीडियो-सक्षम डिवाइस, वीडियो संचार सेवाएं,
                                            मॉडेम, हार्डवेयर, सर्वर, सॉफ़्टवेयर, ऑपरेटिंग सिस्टम, नेटवर्क, वेब सर्वर,
                                            इंटरनेट और टेलीफ़ोन सेवा (सामूहिक, “उपकरण”) को प्राप्त करने और उसके रखरखाव
                                            के लिए पूरी तरह से स्वयं जिम्मेदार होंगे। आप आपनी जानकारी या सहमति के साथ या
                                            उसके बिना उपकरण की सुरक्षा बनाए रखने और उपकरण के सभी उपयोगों के लिए भी
                                            जिम्मेदार होंगे।
                                        </li>
                                        <li>
                                            सेवा के माध्यम से या अन्य उपयोगकर्ताओं या प्राप्तकर्ताओं के साथ आपके द्वारा
                                            शेयर किए गए या संचारित किए गए कंटेंट और अन्य चीज़ों के लिए आप पूरी तरह से
                                            स्वयं जिम्मेदार हैं। आप ऐसे किसी भी कंटेंट का संचार नहीं करेंगे जो आपने नहीं
                                            बनाया है या जो आपके सभी अधिकार, शीर्षक और हित में नहीं आते हों, जिसमें उसमें
                                            निहित प्रचार के सभी कॉपीराइट और अधिकार शामिल हैं।
                                        </li>
                                        <li>
                                            कंपनी और/या उससे संबन्धित संबद्ध किसी प्रयोजन के लिए ऐप्लिकेशन पर निहित
                                            सूचना, सॉफ़्टवेयर और सेवाओं और उससे संबंधित कंटेंट की उपयुक्तता, विश्वसनीयता,
                                            उपलब्धता, समयबद्धता के बारे में नहीं बताते हैं। ऐसी सभी जानकारी, सॉफ़्टवेयर
                                            और सेवाएँ और उनसे संबंधित कंटेंट किसी भी प्रकार की वारंटी के बिना &quot;जैसी
                                            हैं&quot; प्रदान की जाती हैं। कंपनी और/या इससे संबंधित आपूर्तिकर्ता इस
                                            सूचना, सॉफ्टवेयर और सेवाओं और इससे संबंधित कंटेंट से जुड़े सभी वारंट और
                                            शर्तों को अस्वीकार करते हैं, जिसमें व्यापारी योग्यता की सभी अंतर्निहित
                                            वारंटियां और शर्तें, किसी विशेष प्रयोजन के लिए उपयुक्तता, शीर्षक और उल्लंघन
                                            नहीं करना शामिल है।
                                        </li>
                                        <li>
                                            आप विशेष रूप से सहमत हैं कि कंपनी आपके संचारण या डेटा, किसी भी चीज़ या भेजे
                                            गए या प्राप्त किए गए या नहीं भेजे गए या नहीं प्राप्त किए गए डेटा या
                                            ऐप्लिकेशन के माध्यम से होने वाले किसी भी लेन-देन के अनधिक्रत एक्सेस या बदलाव
                                            के लिए जिम्मेदार नहीं होगी। आप विशेष रूप से स्वीकार करते हैं कि किसी भी
                                            धमकी, निंदा, बहिष्कार, अप्रिय या अवैध सेवाओं या किसी अन्य पक्ष या बौद्धिक
                                            संपदा अधिकारों सहित किसी अन्य के अधिकारों के उल्लंघन के लिए कंपनी किसी भी
                                            तरह से जिम्मेदार नहीं है। आप विशेष रूप से यह स्वीकार करते हैं कि कंपनी किसी
                                            भी थर्ड पार्टी द्वारा ऐप्लिकेशन का उपयोग करके भेजी गई सेवा और/या उसमें
                                            सम्मिलित सेवा के लिए जिम्मेदार नहीं है।
                                        </li>
                                        <li>
                                            किसी भी हालत में कंपनी और/या उससे संबद्ध इकाइयों में कोई भी प्रत्यक्ष,
                                            अप्रत्यक्ष, दंडनीय, आकस्मिक, विशेष, परिणामी नुकसान या ऐसा कोई भी नुक्सान
                                            जिसकी कोई सीमा निर्धारित नहीं है, निम्न से सम्बन्धी नुक्सान के लिए उत्तरदायी
                                            नहीं होंगी : (A) ऐप्लिकेशन के उपयोग या उसके प्रदर्शन के कारण नहीं कर पाने,
                                            डेटा इनमें से किसी के भी कारण होने वाले नुक्सान या उभरने वाला लाभ या
                                            ऐप्लिकेशन के उपयोग या उसके साथ जुड़े होने की संभावना; (B) ऐप्लिकेशन या
                                            सेवाओं का उपयोग करने के लिए कोई भी देरी या अक्षमता; (C) सेवाओं को प्रदान
                                            करने के लिए या किसी भी जानकारी या सेवाओं के लिए लागू की जाने वाली सुविधा जो
                                            ऐप्लिकेशन लागू की गई है; (D) या कंपनी की ऐप्लिकेशन या सेवाओं के उपयोग से
                                            उत्पन्न; भले ही उपरोक्त सभी नुकसान, दावे या क्षति अनुबंध पर आधारित हों या
                                            नहीं, अपकृत्य, लापरवाही, सख्त देयता, भले ही कंपनी और/या इससे संबद्ध और/या
                                            इसके सहयोगियों को इस तरह के नुकसान, दावे या क्षति की संभावना होने की सलाह दी
                                            गई हो।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>बंद करना (अस्थायी और स्थायी रूप से बंद करना):</strong>
                                    <ol>
                                        <li>
                                            इन शर्तों में नहीं दिए जाने के बावजूद, आपको दी जाने वाली सेवाएं कंपनी द्वारा
                                            किसी भी समय बिना किसी कारण को बताए कंपनी के पूर्ण अधिकार स्वरुप समाप्त की जा
                                            सकती हैं। बंद करने/समाप्त करने पर, आपको ऐप्लिकेशन/सेवाओं का उपयोग करने या
                                            उनके एक्सेस का अधिकार नहीं होगा।
                                        </li>
                                        <li>
                                            कंपनी आपके द्वारा इन नियमों और शर्तों का पालन न करने की स्थिति में सेवाओं तक
                                            आपके ऐक्सेस को निलंबित और/या समाप्त करने का अधिकार सुरक्षित रखती है।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>कानून और विवाद समाधान:</strong>
                                    <ol>
                                        <li>
                                            ये नियम और शर्तें भारत के कानून के अनुसार बनाई गई हैं और उसके अनुसार
                                            नियंत्रित हैं। इन शर्तों से उत्पन्न विवादों को सुनने के लिए मुंबई की अदालतों
                                            में विशेष क्षेत्राधिकार है।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>अप्रत्याशित घटना:</strong>
                                    <ol>
                                        <li>
                                            किसी दैवीय घटना, युद्ध, बीमारी, क्रांति, दंगा, शहर में हंगामा, हड़ताल,
                                            तालाबंदी, बाढ़, आग, किसी भी सार्वजनिक उपयोगिता की विफलता, मानव निर्मित आपदा,
                                            बुनियादी ढांचे की विफलता या किसी अन्य कारण से जो कंपनी के नियंत्रण में नहीं
                                            है, के कारण ऐप्लिकेशन या सब्सक्राइब किये हुए कंटेंट के किसी भाग की
                                            अनुपलब्ध्ता के लिए कंपनी उत्तरदायी नहीं होगी।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>गंभीरता:</strong>
                                    <ol>
                                        <li>
                                            यदि इन शर्तों के किसी भी प्रावधान को अमान्य, शून्य, या किसी भी कारण से
                                            अप्राप्य माना जाता है, तो शर्तों के उस भाग को गंभीर माना जाएगा और यह किसी भी
                                            शेष प्रावधान की मान्यता और प्रवर्तनीयता को प्रभावित नहीं करेगा।
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>संपर्क विवरण:</strong>
                                    <ol>
                                        <li>
                                            पता: कार्यालय- 101, सैफ़्रन, सेंटर पॉइंट के पास, पंचवटी 5 रास्ता, अंबावाड़ी,
                                            अहमदाबाद <br />
                                            ईमेल:
                                            <a href="mailto:enterpriseservices@jio.com" tabindex="0" role="link"
                                                >enterpriseservices@jio.com</a
                                            ><br />
                                            वेबसाइट:
                                            <!-- <a target="_blank" href="https://jiomeetpro.jio.com/">https://jiomeetpro.jio.com</a> -->
                                            <a target="_blank" tabindex="0" role="link" [href]="websiteLink">{{
                                                websiteLink
                                            }}</a>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                        <p>
                            <a
                                class="monthly-report"
                                target="_blank"
                                tabindex="0"
                                role="link"
                                [href]="monthlyComplianceReportUrl"
                                >{{ 'tnc.monthly_compliance_report' | translate }}</a
                            >
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
