<div class="overlay">
    <div class="call-settings-container">
        <div class="header">
            <span tabindex="0" class="settingheader setting-container" aria-label="settings">{{
                'joinmeeting_page_or_joincall.settings' | translate
            }}</span>

            <img
                class="close"
                src="assets/img/chat_close.svg"
                alt=""
                (click)="close()"
                aria-label="Close the Setting dialog box"
                tabindex="0"
                (keydown.enter)="handleClosefunction($event)"
            />
        </div>
        <div class="tab-wrapper" [class.mobile]="isMobile">
            <div class="tabs">
                <div
                    class="tab"
                    *ngFor="let tab of tabs; let i = index"
                    [class.selected]="selectedLabel === tab.label"
                    (click)="openSetting(tab)"
                    [id]="tab.label"
                    (keyup.enter)="openSetting(tab)"
                    [tabindex]="i"
                    [class.hide]="
                        tab.hideTab ||
                        (hideBackgroundOption && tab.label == 'background') ||
                        (isMobileDevice && tab.label !== 'video' && tab.label !== 'audio')
                    "
                    [attr.aria-label]="tab.label"
                >
                    <img src=" {{ selectedLabel === tab.label ? tab.altImg : tab.img }}" alt="" style="height: 24px" />
                    <span>{{ tab.tkey | translate }}</span>
                    <ng-container *ngIf="tab.label === 'profile' && isNameUpdated">
                        <img class="super-admin-notify" src="assets/images/notification-alert.svg" alt="" />
                    </ng-container>
                    <span *ngIf="tab.label == 'background'" style="margin-left: auto"
                        ><img
                            src="{{
                                selectedLabel === tab.label
                                    ? 'assets/images/beta-tag_whitebackground.svg'
                                    : 'assets/images/beta_tag.svg'
                            }}"
                            alt=""
                            class="beta-tag"
                    /></span>
                </div>
            </div>
            <div class="content" (scroll)="removeAutocomplete()">
                <ng-template viewHost></ng-template>
            </div>
        </div>
    </div>
</div>

<div class="video" id="preview-renderer" style="display: none"></div>
