import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpEventType, HttpEvent } from '@angular/common/http';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import * as _ from 'lodash-es';

import { RestService } from './rest.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { AuthService, JioCloudUtilService, JiocloudAuthService, JiocloudService } from 'src/app/core';
import { JIO_CLOUD } from 'src/app/constants';

@Injectable({
    providedIn: 'root'
})
export class ChatService {
    private participantDDownNotification$: BehaviorSubject<any[]> = new BehaviorSubject([]);
    private datePipe = new DatePipe('en');

    inCallChatMessages = [];
    uploadedItems: any = {};
    activeChatMembers = [];

    constructor(
        private restService: RestService,
        private authService: AuthService,
        private jioCloudService: JiocloudService,
        private translateService: TranslateService,
        private confirmationService: ConfirmationService,
        private jiocloudAuthService: JiocloudAuthService,
        private jioCloudUtilService: JioCloudUtilService,
        private toastrService: ToastrService
    ) {}

    getRCThreadId(jiomeetId) {
        return this.restService.post(`api/rc/getrcthread`, { jiomeetId });
    }

    fetchAllMessages(jiomeetId, limit = 0, offset = 0) {
        const isAuthenticated = this.authService.getIsAuthenticated();
        return this.restService.post(
            `api/chat/getThreadForChat/messages${!isAuthenticated ? '/guest' : ''}?limit=${limit}&offset=${offset}`,
            {
                jiomeetId
            }
        );
    }

    getLatestMembersInCall(jiomeetId) {
        return this.restService.post(`api/chat/getThreadForChat`, {
            jiomeetId
        });
    }

    appendRecievedMessage(chatMessagesByDate: any[], chat): any[] {
        chatMessagesByDate = chatMessagesByDate || [];
        const key = this.datePipe.transform(chat.mOn, 'd MMM y');
        const filtered = chatMessagesByDate.filter((group) => group.key === key)[0];
        if (filtered) {
            filtered.value.unshift(chat);
        } else {
            chatMessagesByDate.push({ key: this.datePipe.transform(chat.mOn, 'd MMM y'), value: [chat] });
        }
        return chatMessagesByDate;
    }

    appendRecievedMessageToList(chatMessagesByDate: any[], chat): any[] {
        chatMessagesByDate = chatMessagesByDate || [];
        const key = this.datePipe.transform(chat.mOn, 'd MMM y');
        const filtered = chatMessagesByDate.filter((group) => group.key === key)[0];
        if (filtered) {
            chatMessagesByDate.push(chat);
        } else {
            chatMessagesByDate.push({ key: this.datePipe.transform(chat.mOn, 'd MMM y') }, ...[chat]);
        }
        return chatMessagesByDate;
    }

    sendInCallChatMessage({ jiomeetId, messageComponent }) {
        const isAuthenticated = this.authService.getIsAuthenticated();
        return this.restService.post(`api/chat/getThreadForChat/postmessage${!isAuthenticated ? '/guest' : ''}`, {
            jiomeetId,
            messageComponent
        });
    }

    getChatThreadId(jiomeetId): Observable<any> {
        const isAuthenticated = this.authService.getIsAuthenticated();
        return this.restService.post(`api/chat/getThreadForChat${!isAuthenticated ? '/guest' : ''}`, {
            jiomeetId
        });
    }

    getChatAttachments(chatThreadId) {
        return this.restService.get(`api/chat/thread/${chatThreadId}/messages?hasAttachments=true`);
    }

    deleteFromJioMeetAndJioCloud(chatThreadId, messageId, fileId, jiomeetId) {
        return this.restService
            .post(`api/chat/thread/${chatThreadId}/messages/deletemessages`, {
                messageIds: [messageId]
            })
            .pipe(
                switchMap((res) => {
                    return this.jioCloudService.deleteAttachment(fileId, jiomeetId);
                })
            );
    }
    deleteMessagesFromChat(chatThreadId, messageId) {
        return this.restService.post(`api/chat/thread/${chatThreadId}/messages/deletemessages/v1`, {
            messageIds: [messageId]
        });
    }

    notifyDeletion(messageId: string) {
        const broadcastChannel = new BroadcastChannel('chat-deletion-channel');
        broadcastChannel.postMessage({ type: 'deletion', messageId });
    }

    public getPrivateChatThread(members: string[], context: string, admins: string[]): Observable<any> {
        return this.restService.post(`api/chat/thread`, {
            members,
            context,
            admins
        });
    }

    public getPrivateGroupThread(members: string[], context: string, admins: string[], name: string): Observable<any> {
        return this.restService.post(`api/chat/thread`, {
            members,
            context,
            admins,
            name
        });
    }

    public getPrivateAdhocThread(members: string[], admins: string[]): Observable<any> {
        return this.restService.post(`api/chat/thread`, {
            members,
            admins
        });
    }

    removeParticipantFromThread(chatThreadId, members?: string[], admins?: string[]) {
        return this.restService.put(`api/chat/thread/${chatThreadId}`, {
            members: members,
            admins: admins
        });
    }

    addParticipantsToThread(chatThreadId, members?: string[], admins?) {
        return this.restService.put(`api/chat/thread/${chatThreadId}`, {
            members: members,
            admins: admins
        });
    }

    public getSortedMembers(chatThreadId) {
        return this.restService.get(`api/chat/thread/${chatThreadId}/getsortedmembers`);
    }
    public getPrivateChatMessages(chatThreadId): Observable<any> {
        return this.restService.get(`api/chat/thread/${chatThreadId}/messages`);
    }

    public sendPrivateMessage(chatThreadId, jiomeetId, text, attachments) {
        return this.restService.post(`api/chat/thread/${chatThreadId}/messages`, {
            jiomeetId,
            messageComponent: {
                text,
                attachments
            }
        });
    }

    get notifiedParticipantsValue() {
        return this.participantDDownNotification$.value;
    }

    getNotifiedParticipants() {
        return this.participantDDownNotification$;
    }

    updateNotifiedParticipants(userId) {
        if (!this.notifiedParticipantsValue.includes(userId)) {
            this.participantDDownNotification$.next([...this.notifiedParticipantsValue, userId]);
        }
    }

    clearNotifiedParticipants(userId) {
        this.participantDDownNotification$.next(this.notifiedParticipantsValue.filter((id) => id !== userId));
    }

    groupChatMessagesByDate(chats) {
        if (!chats.length) {
            return [];
        }
        // group chats by date
        const chatGroups = _.groupBy(chats, (chat) => this.datePipe.transform(chat.cOn, 'd MMM y'));
        // sort date keys
        const ordered = _.orderBy(_.keys(chatGroups), [(key) => new Date(key)], ['asc']);
        // sorted map by dates
        const map: Map<string, any[]> = ordered.reduce((result, key) => {
            result.set(key, chatGroups[key]);
            return result;
        }, new Map());

        const array = [];
        map.forEach((value, key) => {
            array.push({ key, value });
        });
        return array;
    }

    groupChatMessagesWithDate(chats) {
        if (!chats.length) {
            return [];
        }
        // group chats by date
        const chatGroups = _.groupBy(chats, (chat) => this.datePipe.transform(chat.cOn, 'd MMM y'));
        // sort date keys
        const ordered = _.orderBy(_.keys(chatGroups), [(key) => new Date(key)], ['asc']);
        // sorted map by dates
        const map: Map<string, any[]> = ordered.reduce((result, key) => {
            result.set(key, chatGroups[key].reverse());
            return result;
        }, new Map());

        const array = [];
        map.forEach((value, key) => {
            array.push({ key }, ...value);
        });
        return array;
    }

    editGroup(group, memberId) {
        return this.restService.put(`api/groups`, {
            group_id: group?.context,
            title: group?.name + ' ' + group?.lname,
            members: memberId,
            avatar: ''
        });
    }

    sendChatMessage({ threadId, messageComponent }) {
        const isAuthenticated = this.authService.getIsAuthenticated();
        return this.restService.post(`api/chat/thread/${threadId}/messages`, {
            messageComponent
        });
    }
    markAllMessagesAsRead(chatThreadId, unReadMessagesIds) {
        return this.restService.post(`api/chat/thread/${chatThreadId}/messages/markread?previous=read`, {
            messageIds: [unReadMessagesIds]
        });
    }

    getAllRecentChats() {
        return this.restService.get(`api/chat/thread`);
    }
    getThreadsById(chatThreadId, offset, limit) {
        return this.restService.get(`api/chat/thread/${chatThreadId}/messages?limit=${limit}&offset=${offset}`);
    }

    markAsFavourite(id, type) {
        return this.restService.post(`api/favourites/${type}`, {
            resourceId: id
        });
    }
    markAsUnFavourite(id, type) {
        return this.restService.delete(`api/favourites/${type}`, {
            body: {
                resourceId: id
            }
        });
    }

    isFileSupported(file) {
        return this.jioCloudUtilService.getFileFormat(file.name) !== 'None';
    }

    isUploadInProgress() {
        return Object.keys(this.uploadedItems).filter(
            (attachmentObj: any) => !this.uploadedItems[attachmentObj].uploadSuccess
        ).length;
    }
    uploadFailedNotification(error) {
        switch (error?.code) {
            case 'TEJUM409':
                this.toastrService.warning(
                    'You have exhausted the 5GB storage limit. Please delete the existing files to upload new one.'
                );
                break;
            default:
                this.toastrService.error(error?.error || 'Upload failed');
                break;
        }
    }
    private async chunkUpload(file, threadId) {
        let success = true;
        try {
            file.hash = await this.jioCloudUtilService.getFileHash(file);
            this.jioCloudService
                .initiateUpload({
                    fileMetadata: this.getMetaData(file),
                    meetingId: threadId
                })
                .subscribe(
                    (event: any) => {
                        switch (event.type) {
                            case HttpEventType.Response:
                                if (event.status === 200) {
                                    let response = event.body;
                                    this.uploadedItems[file.unique].uploadedBytes =
                                        this.jioCloudUtilService.calculateSize(response.offset);
                                    this.startChunkUpload(
                                        file,
                                        response.offset,
                                        JIO_CLOUD.FIRST_CHUNK_SIZE,
                                        response.transactionId,
                                        null,
                                        true,
                                        threadId
                                    );
                                }
                                if (event.status === 201) {
                                    // upload done, process next file
                                    // this.uploadedItems[
                                    //   file.unique
                                    // ].info = this.jioCloudUtilService.setMeataDataForUploadedFile(event.body);
                                    const metaData = this.jioCloudUtilService.setMeataDataForUploadedFile(event.body);
                                    Object.assign(this.uploadedItems[file.unique], {
                                        meetingImageTranscodeUrl: metaData.meetingImageTranscodeUrl,
                                        meetingPlaybackUrl: metaData.meetingPlaybackUrl,
                                        meetingDocumentUrl: metaData.meetingDocumentUrl,
                                        meetingFileUrl: metaData.meetingFileUrl,
                                        objectName: metaData.objectName,
                                        objectKey: metaData.objectKey
                                    });
                                }
                                this.uploadedItems[file.unique].uploadSuccess = true;
                                success = true;
                                break;
                        }
                    },
                    (err) => {
                        this.uploadFailedNotification(err?.error);
                        success = false;
                    }
                );
        } catch (err) {
            this.uploadFailedAlert();
            success = false;
        }
        return success;
    }
    uploadFailedAlert() {
        this.confirmationService.confirm({
            message: this.translateService.instant('tostrs.file_uploading_failed'),
            header: this.translateService.instant('tostrs.alert'),
            acceptLabel: this.translateService.instant('tostrs.ok'),
            rejectVisible: false,
            accept: () => {},
            reject: () => {}
        });
    }
    private startChunkUpload(file, offset, chunkSize, transactionId, chunkUploadTime, isStart = false, threadId) {
        if (!isStart) {
            chunkSize = this.jioCloudUtilService.getChunkSize(chunkUploadTime, chunkSize, file.size, offset);
        }
        try {
            const chunkUploadStartTime = new Date().getTime();
            const chunk1 = File.prototype.slice.call(file, offset, offset + chunkSize);
            const reader = new FileReader();
            reader.onload = async (data: any) => {
                const chunk = data.target.result;
                const chunkChecksum = await this.jioCloudUtilService.getChunkCheckSum(chunk);
                this.jioCloudService.uploadChunk(chunk, offset, chunkChecksum, transactionId, threadId).subscribe(
                    (event: any) => {
                        switch (event.type) {
                            case HttpEventType.Response:
                                if (event.status === 200) {
                                    const response = event.body;
                                    this.uploadedItems[file.unique].progress = Math.round(
                                        (response.offset / file.size) * 100
                                    );
                                    this.uploadedItems[file.unique].uploadedBytes =
                                        this.jioCloudUtilService.calculateSize(response.offset);
                                    this.startChunkUpload(
                                        file,
                                        response.offset,
                                        chunkSize,
                                        response.transactionId,
                                        new Date().getTime() - chunkUploadStartTime,
                                        false,
                                        threadId
                                    );
                                }
                                if (event.status === 201) {
                                    this.uploadedItems[file.unique].progress = 100;
                                    const metaData = this.jioCloudUtilService.setMeataDataForUploadedFile(event.body);
                                    Object.assign(this.uploadedItems[file.unique], {
                                        meetingImageTranscodeUrl: metaData.meetingImageTranscodeUrl,
                                        meetingPlaybackUrl: metaData.meetingPlaybackUrl,
                                        meetingDocumentUrl: metaData.meetingDocumentUrl,
                                        meetingFileUrl: metaData.meetingFileUrl,
                                        objectName: metaData.objectName,
                                        objectKey: metaData.objectKey
                                    });
                                }
                                this.uploadedItems[file.unique].uploadSuccess = true;
                                break;
                        }
                    },
                    (err) => {
                        this.uploadFailedNotification(err?.error);
                    }
                );
            };
            reader.onerror = (event) => {
                reader.abort();
            };
            reader.readAsArrayBuffer(chunk1);
        } catch (err) {
            throw err;
        }
    }
    getMetaData(file) {
        return {
            name: file.name,
            size: file.size,
            hash: file.hash,
            folderKey: this.jiocloudAuthService.getAuthInfo().rootFolderKey
        };
    }
    private async singleFileUpload(file, threadId) {
        let success = true;
        try {
            file.hash = await this.jioCloudUtilService.getFileHash(file);
            this.jioCloudService
                .singleFileUpload({
                    file,
                    fileMetadata: this.getMetaData(file),
                    meetingId: threadId
                })
                .subscribe(
                    (event: HttpEvent<any>) => {
                        switch (event.type) {
                            case HttpEventType.UploadProgress:
                                this.uploadedItems[file.unique].progress = Math.round(
                                    (event.loaded / event.total) * 100
                                );
                                this.uploadedItems[file.unique].uploadedBytes = this.jioCloudUtilService.calculateSize(
                                    event.loaded
                                );
                                break;
                            case HttpEventType.Response:
                                const metaData = this.jioCloudUtilService.setMeataDataForUploadedFile(event.body);
                                Object.assign(this.uploadedItems[file.unique], {
                                    meetingImageTranscodeUrl: metaData.meetingImageTranscodeUrl,
                                    meetingPlaybackUrl: metaData.meetingPlaybackUrl,
                                    meetingDocumentUrl: metaData.meetingDocumentUrl,
                                    meetingFileUrl: metaData.meetingFileUrl,
                                    objectName: metaData.objectName,
                                    objectKey: metaData.objectKey
                                });
                                this.uploadedItems[file.unique].uploadSuccess = true;
                                success = true;
                                break;
                        }
                    },
                    (err) => {
                        this.uploadFailedNotification(err?.error);
                        success = false;
                    }
                );
        } catch (err) {
            this.uploadFailedAlert();
            success = false;
        }
        return success;
    }
    uploadFiles(files, threadId) {
        const file = files[0];
        if (file.size <= JIO_CLOUD.MAX_FILE_SIZE_ALLOWED_FOR_UPLOAD) {
            file.unique = new Date().valueOf();
            file.totalSize = this.jioCloudUtilService.calculateSize(file.size);
            this.uploadedItems[file.unique] = file;
            if (file.size > JIO_CLOUD.SMALL_FILE_SIZE_LIMIT) {
                return this.chunkUpload(file, threadId);
            } else {
                return this.singleFileUpload(file, threadId);
            }
        } else {
            this.showMaxFileSizeAlert();
        }
    }
    showMaxFileSizeAlert() {
        this.confirmationService.confirm({
            message: this.translateService.instant('tostrs.maximum_file_size_allowed_is_30mb', {
                value: 30
            }),
            header: this.translateService.instant('tostrs.alert'),
            acceptLabel: this.translateService.instant('tostrs.ok'),
            rejectVisible: false,
            accept: () => {},
            reject: () => {}
        });
    }
    trackByFunction(index, item) {
        return index;
    }
}
