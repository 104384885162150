import { Component, ComponentFactoryResolver, ComponentRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { of } from 'rxjs';

import { differenceInMinutes } from 'date-fns';

import {
    RtcService,
    DiagnosticService,
    UserService,
    UtilService,
    AppService,
    VideoWrapperService,
    MeetingService,
    GuestAuthService,
    ExternalInterfaceService,
    EventEmitterService,
    ThirdPartyExternalIntegrationService,
    AuthService
} from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { APP_EVENTS } from 'src/app/constants';
import { HostDirective } from '../../directives';
import { CallSettingsComponent } from '../call-settings/call-settings.component';

@Component({
    selector: 'app-call-preview-mobile',
    templateUrl: './call-preview-mobile.component.html',
    styleUrls: ['./call-preview-mobile.component.scss']
})
export class CallPreviewMobileComponent implements OnInit, OnDestroy {
    @ViewChild(HostDirective) viewHost: HostDirective;
    settingsComponentRef: ComponentRef<CallSettingsComponent>;
    host = true;
    loading = true;
    isThirdPartyExternalIntegration = true;
    meetingParams: any = {
        meetingId: null,
        hash: null,
        pwd: null,
        autoJoin: 'false',
        hideMoreCallControls: 'false',
        hideAllCallControls: 'false',
        isFullScreenShare: 'false',
        cameraPrivacy: true,
        micPrivacy: true
    };
    errors;
    joining;
    waitingRoomDetails;
    isAuthenticated;
    currentUser;
    loggedInOnly = false;
    showRestrictedMeetingJoinWarning = false;
    showCaptcha = false;
    captchaDetails: any;
    collegueOnly = false;
    joinBeforeHost = false;
    private interval;
    startText = 'start';
    thirdPartyEventSubscriptions = [];
    showWaitingRoomPopup: boolean = false;
    roomType: string = 'dynamic';

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private rtcService: RtcService,
        private utilService: UtilService,
        private appService: AppService,
        private diagnosticService: DiagnosticService,
        private compFactory: ComponentFactoryResolver,
        private videoWrapperService: VideoWrapperService,
        private meetingService: MeetingService,
        private guestAuthService: GuestAuthService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private externalInterfaceService: ExternalInterfaceService,
        private eventEmitterService: EventEmitterService,
        private ngZone: NgZone,
        private authService: AuthService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService
    ) {}

    ngOnInit(): void {
        this.externalInterfaceService.requestForCameraAndMicrophone();
        this.isAuthenticated = this.authService.getIsAuthenticated();
        this.thirdPartyEventSubscriptions.push(
            this.eventEmitterService.subscribe((event: any) => {
                if (event.type === APP_EVENTS.NATIVE_BACK_BUTTON) {
                    this.ngZone.run(() => {
                        this.navigateToDashboard();
                    });
                }
            })
        );
        this.activatedRoute.queryParams.subscribe((params) => {
            this.meetingParams = { ...params };
            if (this.meetingParams.previousURL === 'host') this.startText = 'Start';
            else this.startText = 'Join';
            this.roomType = this.meetingParams.roomType;
        });
        this.currentUser = this.userService.getUserSync();
        this.loading = false;
        this.handleWindowEvents();
    }

    openSettingsPopup(e) {
        if (!this.settingsComponentRef?.instance) {
            const settings = this.compFactory.resolveComponentFactory(CallSettingsComponent);
            this.settingsComponentRef = this.viewHost.viewContainerRef.createComponent(settings);
            this.settingsComponentRef.instance.selectedLabel = e.setting;
            this.settingsComponentRef.instance.afterClose.subscribe((res) => {
                this.settingsComponentRef?.destroy();
                this.settingsComponentRef = undefined;
            });
        }
    }

    joinMeeting() {
        this.join();
        // if (this.host) {
        //   this.router.navigate(['shortener'], { queryParams: { host: true, chatbot: false } });
        // } else {
        //   this.router.navigate(['shortener'], { queryParams: { chatbot: false } });
        // }
    }

    getMeetingId() {
        const meetingId = this.meetingParams?.meetingId;
        if (meetingId?.trim()?.startsWith(this.appService.getEnvVariable('HOST_URL'))) {
            return this.utilService.getParameterByName('meetingId', meetingId);
        } else {
            return meetingId.replace(/-|\s/g, '');
        }
    }

    join() {
        this.externalInterfaceService.hideHeader();
        if (
            !this.appService.getConfigVariable('ENABLE_CALLS_MOBILE_DEVICES') &&
            this.utilService.isMobileBrowser() &&
            !this.isThirdPartyExternalIntegration
        ) {
            this.router.navigate(['shortener'], {
                queryParams: {
                    meetingId: this.getMeetingId(),
                    hash: this.meetingParams.hash,
                    pwd: this.meetingParams?.pin
                }
            });
            return;
        }
        this.errors = {};
        this.joining = true;
        this.rtcService
            .getRoomDetailsGuest({
                extension: this.getMeetingId(),
                pin: this.meetingParams?.pin,
                hash: this.meetingParams.hash,
                userId: this.currentUser._id,
                isAuthenticated: this.isAuthenticated,
                memberName: this.meetingParams?.name.trim()
            })
            .subscribe((res: any) => {
                this.appService.setMeetingId(res?.roomDetails?.meetingId);
                if (!res.roomDetails && res.waitingRoom) {
                    this.waitingRoomDetails = res;
                    this.waitUntillHostAllows(res);
                } else {
                    this.joinAs(res);
                }
            }, this.handleJoinError.bind(this));
    }

    joinAs(room) {
        this.showWaitingRoomPopup = false;
        this.setHostOrCoHost(room);
        this.rtcService.setBreakoutRoomInfo(null);
        if (room.breakoutDetail) {
            this.rtcService.setBreakoutRoomInfo({
                ...room.breakoutDetail,
                breakoutRoomId: room.breakoutDetail._id
            });
        }
        if (this.isAuthenticated && this.currentUser) {
            if (
                room.ownerDetails.userId === this.currentUser._id &&
                this.currentUser.jiomeetId === this.getMeetingId()
            ) {
                // host a meeting case
                this.diagnosticService
                    .sendEvent({
                        eventCategory: 'Start a Meeting',
                        eventAction: 'New Meeting Start',
                        eventType: 'app_event',
                        status: 'success'
                    })
                    .subscribe();
            }

            if (room.roomDetails.isWebinar) {
                this.rtcService.setConferenceInfo({
                    isInitiater: room.ownerDetails.userId === this.currentUser._id,
                    joiningName: this.meetingParams?.name.trim(),
                    room: room.roomDetails,
                    ownerDetails: room.ownerDetails,
                    hideMoreCallControls: this.meetingParams.hideMoreCallControls === 'true',
                    hideAllCallControls: this.meetingParams.hideAllCallControls === 'true',
                    isFullScreenShare: this.meetingParams.isFullScreenShare === 'true',
                    webinar: room.roomDetails.webinar
                });
                this.router.navigate(['webinar/conference']);
            } else {
                const commonConferenceInfo = {
                    isInitiater: room.ownerDetails.userId === this.currentUser._id,
                    joiningName: this.meetingParams?.name.trim(),
                    room: room.roomDetails,
                    ownerDetails: room.ownerDetails,
                    hideMoreCallControls: this.meetingParams.hideMoreCallControls === 'true',
                    hideAllCallControls: this.meetingParams.hideAllCallControls === 'true',
                    isFullScreenShare: this.meetingParams.isFullScreenShare === 'true'
                };

                const additionalConferenceInfo = this.videoWrapperService.getAdditionalConferenceInfo(room, true);
                const conferenceInfo = { ...commonConferenceInfo, ...additionalConferenceInfo };
                this.rtcService.setConferenceInfo(conferenceInfo);
                this.router.navigate(['conference/call']);
                this.joining = false;
            }
        } else {
            if (room.roomDetails.isWebinar) {
                this.loggedInOnly = true;
                return;
            }
            this.guestAuthService
                .login({
                    jiomeetId: room.roomDetails.jiomeetId,
                    roomID: room.roomDetails.roomID,
                    roomPin: room.roomDetails.roomPIN,
                    name:
                        (this.meetingParams?.name.length <= 17
                            ? this.meetingParams?.name
                            : this.meetingParams?.name.substr(0, 17) + '...') + ' (Guest)',
                    phoneNo: '',
                    emailId: '',
                    recorderToken: ''
                })
                .subscribe((res) => {
                    this.userService.isGuestLogin(true);
                    this.userService.setGuestUser(name);

                    const commonConferenceInfo = {
                        isInitiater: false,
                        joiningName: this.meetingParams?.name.trim() + ' (Guest)',
                        room: room.roomDetails,
                        ownerDetails: room.ownerDetails,
                        hideMoreCallControls: this.meetingParams.hideMoreCallControls === 'true',
                        hideAllCallControls: this.meetingParams.hideAllCallControls === 'true',
                        isFullScreenShare: this.meetingParams.isFullScreenShare === 'true'
                    };
                    const additionalConferenceInfo = this.videoWrapperService.getAdditionalConferenceInfo(room, true);
                    const conferenceInfo = { ...commonConferenceInfo, ...additionalConferenceInfo };
                    this.rtcService.setConferenceInfo(conferenceInfo);
                    this.router.navigate(['conference/call']);
                    this.joining = false;
                });
        }
    }
    setHostOrCoHost(room) {
        const { ownerDetails, roomDetails } = room;
        const { coHosts } = roomDetails;

        const isCurrentUserHost = ownerDetails?.userId === this.currentUser._id;
        const isCurrentUserCoHost = coHosts?.some((coHost) => coHost?.userId === this.currentUser._id);

        this.rtcService.isHostOrCoHostOfMeeting = isCurrentUserHost || isCurrentUserCoHost;
    }

    handleJoinError(err) {
        if ([400, 460, 412].includes(err.error.customCode)) {
            // Invalid meeting id or pin
            this.errors.global =
                this.translateService.instant('joinmeeting_page_or_joincall.errors.invalid_meetingId_password') ||
                err.error.errors;
            this.joining = false;
        } else if (err.error.customCode === 469) {
            this.showRestrictedMeetingJoinWarning = true;
            this.joining = false;
        } else if (err.error.customCode === 429) {
            this.errors.global = err.error.errors;
            this.captchaDetails = err.error;
            this.showCaptcha = true;
            this.joining = false;
        } else if (err.error.customCode === 464) {
            this.joinBeforeHost = true;
            setTimeout(() => {
                this.join();
            }, 5000);
        } else if (err.error.customCode === 465) {
            this.loggedInOnly = true;
        } else if (err.error.customCode === 466) {
            this.collegueOnly = true;
        } else if (err.error.customCode === 468) {
            this.errors.global =
                this.translateService.instant('joinmeeting_page_or_joincall.errors.cannot_join_room_locked') ||
                err.error.errors;
            this.joining = false;
        } else if (err.error.customCode === 461) {
            this.errors.global =
                this.translateService.instant('joinmeeting_page_or_joincall.errors.meeting_ended') || err.error.errors;
            this.joining = false;
        } else {
            this.errors.global = err.error.errors;
            this.joining = false;
        }
    }

    waitUntillHostAllows(res) {
        this.showWaitingRoomPopup = true;
        this.interval = setInterval(() => {
            this.joining = true;
            this.rtcService
                .getRoomDetailsGuest({
                    extension: this.getMeetingId(),
                    pin: this.meetingParams?.pin,
                    hash: this.meetingParams?.hash,
                    userId: this.currentUser._id || res.userId,
                    memberName: this.meetingParams?.name?.trim(),
                    isAuthenticated: this.isAuthenticated
                })
                .subscribe(
                    (res: any) => {
                        this.appService.setMeetingId(res?.roomDetails?.meetingId);
                        if (res.waitingRoomId) {
                            this.waitingRoomDetails.waitingRoomId = res.waitingRoomId;
                        }
                        if (res.roomDetails) {
                            clearInterval(this.interval);
                            this.joinAs(res);
                        }
                    },
                    (err) => {
                        if (err.error.customCode === 467) {
                            clearInterval(this.interval);
                            this.waitingRoomDetails = null;
                            this.toastrService.error(
                                this.translateService.instant(
                                    'joinmeeting_page_or_joincall.errors.host_removed_from_waiting_room'
                                )
                            );
                            this.joining = false;
                            this.navigateToDashboard();
                            return;
                        }
                        if (err.error.customCode === 464) {
                            clearInterval(this.interval);
                            this.waitingRoomDetails = null;
                            this.toastrService.error(
                                this.translateService.instant('joinmeeting_page_or_joincall.errors.request_rejected')
                            );
                            this.errors.global = this.translateService.instant(
                                'joinmeeting_page_or_joincall.errors.request_rejected'
                            );
                            this.joining = false;
                            this.navigateToDashboard();
                            return;
                        }
                    }
                );
        }, this.appService.getConfigVariable('REQUEST_POLLING_INTERVAL'));
    }

    navigateToDashboard() {
        // if (this.meetingParams.previousURL) {
        //   if (this.meetingParams.previousURL === 'host') {
        //     this.router.navigate(['shortener'], { queryParams: { host: true } });
        //   } else {
        //     this.router.navigate(['shortener']);
        //   }
        //   return;
        // }
        if (this.showWaitingRoomPopup) {
            this.externalInterfaceService.showHeader();
        }
        this.router.navigate(['dashboard'], {
            queryParams: {
                myjio: true,
                chatbot: false
            }
        });
    }

    launch() {
        const meetingURL = `${this.appService.getEnvVariable('HOST_URL')}shortener?meetingId=${
            this.meetingParams?.meetingId
        }&pwd=${this.meetingParams?.pin}`;
        const meetingShareTemplate = this.thirdPartyExternalIntegrationService.generateMeetingShareTemplate(meetingURL);
        this.externalInterfaceService.share(meetingShareTemplate);
    }

    leaveWaitingRoom() {
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translateService.instant('tostrs.something_went_right'));
            return;
        }
        clearInterval(this.interval);
        this.userService
            .stopWaitingWithWaitingRoomID({
                waitingRoomId: this.waitingRoomDetails.waitingRoomId,
                jiomeetId: this.waitingRoomDetails.jiomeetId
            })
            .subscribe(
                (res) => {
                    clearInterval(this.interval);
                    this.waitingRoomDetails = null;
                    this.joining = false;
                },
                (err) => {}
            );
        this.externalInterfaceService.showHeader();
        this.navigateToDashboard();
    }

    handleWindowEvents() {
        window.onbeforeunload = () => {
            if (this.waitingRoomDetails?.waitingRoom) {
                this.leaveWaitingRoom();
            }
        };
    }

    ngOnDestroy() {
        this.thirdPartyEventSubscriptions.forEach((subscribe) => subscribe.unsubscribe());
    }
}
