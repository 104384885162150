import { Injectable } from '@angular/core';

import { RestService } from './rest.service';
import { ValidationService } from './validation.service';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    constructor(private restService: RestService, private validationService: ValidationService) {}

    search(keyword) {
        return this.restService.get(`api/search/v1?keyword=${keyword}`);
    }

    globalSearch(keyword) {
        if (this.validationService.isEmail(keyword) || this.validationService.isPhoneNumber(keyword)) {
            return this.restService.get(`api/contacts/search?keyword=${keyword}`);
        } else {
            return this.restService.get(`api/search/v1?keyword=${keyword}`);
        }
    }

    resolveParticipants(participants) {
        return this.restService.post(`api/meeting/resolveParticipantList`, { participants });
    }
}
