export const GA_NEW = {
    PREPARATORY_SCREEN_JOIN_MUTE: {
        event: 'mic',
        current_page: 'join_meeting_preparatory_screen',
        clicked_text: 'mute',
        outcome: 'succeeded'
    },
    PREPARATORY_SCREEN_JOIN_UNMUTE: {
        event: 'mic',
        current_page: 'join_meeting_preparatory_screen',
        clicked_text: 'unmute',
        outcome: 'succeeded'
    },
    PREPARATORY_SCREEN_START_MUTE: {
        event: 'mic',
        current_page: 'start_meeting_preparatory_screen',
        clicked_text: 'mute',
        outcome: 'succeeded'
    },
    PREPARATORY_SCREEN_START_UNMUTE: {
        event: 'mic',
        current_page: 'start_meeting_preparatory_screen',
        clicked_text: 'unmute',
        outcome: 'succeeded'
    },
    PREPARATORY_SCREEN_START_STOP_VIDEO: {
        event: 'camera',
        current_page: 'start_meeting_preparatory_screen',
        clicked_text: 'stop_video',
        outcome: 'succeeded'
    },
    PREPARATORY_SCREEN_START_START_VIDEO: {
        event: 'camera',
        current_page: 'start_meeting_preparatory_screen',
        clicked_text: 'start_video',
        outcome: 'succeeded'
    },
    PREPARATORY_SCREEN_JOIN_STOP_VIDEO: {
        event: 'camera',
        current_page: 'join_meeting_preparatory_screen',
        clicked_text: 'stop_video',
        outcome: 'succeeded'
    },
    PREPARATORY_SCREEN_JOIN_START_VIDEO: {
        event: 'camera',
        current_page: 'join_meeting_preparatory_screen',
        clicked_text: 'start_video',
        outcome: 'succeeded'
    },
    PREPARATORY_SCREEN_JOIN_VIRTUAL_BACKGROUND: {
        event: 'virtual_background',
        current_page: 'join_meeting_preparatory_screen',
        clicked_text: '',
        outcome: 'succeeded'
    },
    PREPARATORY_SCREEN_START_VIRTUAL_BACKGROUND: {
        event: 'virtual_background',
        current_page: 'start_meeting_preparatory_screen',
        clicked_text: '',
        outcome: 'succeeded'
    },
    PREPARATORY_SCREEN_START_MEETING_SETTINGS: {
        event: 'settings',
        current_page: 'start_meeting_preparatory_screen',
        clicked_text: '',
        outcome: 'succeeded'
    },
    PREPARATORY_SCREEN_JOIN_MEETING_SETTINGS: {
        event: 'settings',
        current_page: 'join_meeting_preparatory_screen',
        clicked_text: '',
        outcome: 'succeeded'
    },
    PERSONAL_MEETING_SUCCESS: {
        event: 'start_a_meeting_with_personal_meeting_id',
        current_page: 'start_a_meeting',
        clicked_text: '',
        outcome: 'succeeded'
    },
    ONE_TIME_MEETING_SUCCESS: {
        event: 'start_a_meeting_with_one_time_meeting_id',
        current_page: 'start_a_meeting',
        clicked_text: '',
        outcome: 'succeeded'
    },
    SHARE_PERSONAL_MEETING_SUCCESS: {
        event: 'share_personal_meeting_id',
        current_page: 'start_a_meeting',
        clicked_text: '',
        outcome: 'succeeded'
    },
    JOIN_MEETING_WITH_URL: {
        event: 'category_input',
        current_page: 'join_a_meeting',
        clicked_text: 'join_with_url',
        outcome: 'succeeded'
    },
    JOIN_MEETING_WITH_DEEPLINKING: {
        event: 'category_input',
        current_page: 'join_a_meeting',
        clicked_text: 'join_through_deeplinking',
        outcome: 'succeeded'
    },
    JOIN_MEETING_WITH_ID_AND_PWD: {
        event: 'category_input',
        current_page: 'join_a_meeting',
        clicked_text: 'join_with_id_and_password',
        outcome: 'succeeded'
    },
    JOIN_MEETING_ERROR: {
        event: 'join_meeting_error',
        current_page: 'join_a_meeting',
        clicked_text: 'join',
        outcome: 'failed'
    },
    JOIN_CALL_FAILURE: {
        event: 'join_meeting_failure',
        current_page: 'join_a_meeting',
        clicked_text: 'join',
        outcome: 'failed'
    },
    START_A_MEETING_BACK_BUTTON: {
        event: 'button_click',
        current_page: 'start_a_meeting',
        clicked_text: 'back',
        outcome: 'succeeded'
    },
    JOIN_A_MEETING_BACK_BUTTON: {
        event: 'button_click',
        current_page: 'join_a_meeting',
        clicked_text: 'back',
        outcome: 'succeeded'
    },
    CALL_FEEDBACK_POOR_AUDIO_SELECT: {
        event: 'button_click',
        clicked_text: 'poor_audio_quality',
        current_page: 'feedback',
        outcome: 'select'
    },
    CALL_FEEDBACK_POOR_AUDIO_UNSELECT: {
        event: 'button_click',
        clicked_text: 'poor_audio_quality',
        current_page: 'feedback',
        outcome: 'unselect'
    },
    CALL_FEEDBACK_POOR_VIDEO_SELECT: {
        event: 'button_click',
        clicked_text: 'poor_video_quality',
        current_page: 'feedback',
        outcome: 'select'
    },
    CALL_FEEDBACK_POOR_VIDEO_UNSELECT: {
        event: 'button_click',
        clicked_text: 'poor_video_quality',
        current_page: 'feedback',
        outcome: 'unselect'
    },
    CALL_FEEDBACK_SCREEN_SHARE_PROBLEMS_SELECT: {
        event: 'button_click',
        clicked_text: 'screen_share_problems',
        current_page: 'feedback',
        outcome: 'select'
    },
    CALL_FEEDBACK_SCREEN_SHARE_PROBLEMS_UNSELECT: {
        event: 'button_click',
        clicked_text: 'screen_share_problems',
        current_page: 'feedback',
        outcome: 'unselect'
    },
    CALL_FEEDBACK_POOR_NETWORK_CONNECTION_SELECT: {
        event: 'button_click',
        clicked_text: 'poor_network_connection',
        current_page: 'feedback',
        outcome: 'select'
    },
    CALL_FEEDBACK_POOR_NETWORK_CONNECTION_UNSELECT: {
        event: 'button_click',
        clicked_text: 'poor_network_connection',
        current_page: 'feedback',
        outcome: 'unselect'
    },
    CALL_FEEDBACK_CLOSE: {
        event: 'button_click',
        clicked_text: 'close',
        current_page: 'feedback',
        outcome: 'succeeded'
    },
    CALL_FEEDBACK_SKIP: {
        event: 'button_click',
        clicked_text: 'skip',
        current_page: 'feedback',
        outcome: 'succeeded'
    },
    PLAN_A_MEETING_DAILY_RECURRING_CLICK: {
        event: 'button_click',
        clicked_text: 'recurring_meeting',
        current_page: 'plan_a_meeting',
        outcome: 'daily'
    },
    PLAN_A_MEETING_WEEKLY_RECURRING_CLICK: {
        event: 'button_click',
        clicked_text: 'recurring_meeting',
        current_page: 'plan_a_meeting',
        outcome: 'weekly'
    },
    PLAN_A_MEETING_MONTHLY_RECURRING_CLICK: {
        event: 'button_click',
        clicked_text: 'recurring_meeting',
        current_page: 'plan_a_meeting',
        outcome: 'monthly'
    },
    PLAN_A_MEETING_POPUP_CANCEL: {
        event: 'button_click',
        clicked_text: 'cancel',
        current_page: 'plan_a_meeting',
        outcome: 'succeeded'
    },
    PLAN_A_MEETING_POPUP_SUBMIT: {
        event: 'plan_a_meeting',
        clicked_text: '',
        current_page: 'plan_a_meeting',
        outcome: 'succeeded'
    },
    PLAN_A_MEETING_RESTRICTED_ENABLED: {
        event: 'button_click',
        clicked_text: 'restricted_meeting',
        current_page: 'plan_a_meeting',
        outcome: 'enabled'
    },
    PLAN_A_MEETING_RESTRICTED_DISABLED: {
        event: 'button_click',
        clicked_text: 'restricted_meeting',
        current_page: 'plan_a_meeting',
        outcome: 'disabled'
    },
    PLAN_A_MEETING_PARTICIPANT_MY_ORG_ENABLED: {
        event: 'button_click',
        clicked_text: 'allow_participants_only_from_my_organisation',
        current_page: 'plan_a_meeting',
        outcome: 'enabled'
    },
    PLAN_A_MEETING_PARTICIPANT_MY_ORG_DISABLED: {
        event: 'button_click',
        clicked_text: 'allow_participants_only_from_my_organisation',
        current_page: 'plan_a_meeting',
        outcome: 'disabled'
    },
    PLAN_A_MEETING_DONT_ALLOW_GUEST_USER_ENABLED: {
        event: 'button_click',
        clicked_text: 'dont_allow_guest_users',
        current_page: 'plan_a_meeting',
        outcome: 'enabled'
    },
    PLAN_A_MEETING_DONT_ALLOW_GUEST_USER_DISABLED: {
        event: 'button_click',
        clicked_text: 'dont_allow_guest_users',
        current_page: 'plan_a_meeting',
        outcome: 'disabled'
    },
    PLAN_A_MEETING_CLASSROOM_MODE_ENABLED: {
        event: 'button_click',
        clicked_text: 'classroom_mode',
        current_page: 'plan_a_meeting',
        outcome: 'enabled'
    },
    PLAN_A_MEETING_CLASSROOM_MODE_DISABLED: {
        event: 'button_click',
        clicked_text: 'classroom_mode',
        current_page: 'plan_a_meeting',
        outcome: 'disabled'
    },
    PLAN_A_MEETING_LARGE_MEETING_ENABLED: {
        event: 'button_click',
        clicked_text: 'large_meeting',
        current_page: 'plan_a_meeting',
        outcome: 'enabled'
    },
    PLAN_A_MEETING_LARGE_MEETING_DISABLED: {
        event: 'button_click',
        clicked_text: 'large_meeting',
        current_page: 'plan_a_meeting',
        outcome: 'disabled'
    },
    PLAN_A_MEETING_WAITING_ROOM_ENABLED: {
        event: 'button_click',
        clicked_text: 'enable_waiting_room',
        current_page: 'plan_a_meeting',
        outcome: 'enabled'
    },
    PLAN_A_MEETING_WAITING_ROOM_DISABLED: {
        event: 'button_click',
        clicked_text: 'enable_waiting_room',
        current_page: 'plan_a_meeting',
        outcome: 'disabled'
    },
    PLAN_A_MEETING_JOIN_BEFORE_HOST_ENABLED: {
        event: 'button_click',
        clicked_text: 'allow_join_before_host',
        current_page: 'plan_a_meeting',
        outcome: 'enabled'
    },
    PLAN_A_MEETING_JOIN_BEFORE_HOST_DISABLED: {
        event: 'button_click',
        clicked_text: 'allow_join_before_host',
        current_page: 'plan_a_meeting',
        outcome: 'disabled'
    },
    PLAN_A_MEETING_FORCE_MUTE_ENTRY_ENABLED: {
        event: 'button_click',
        clicked_text: 'force_mute_participants_on_entry',
        current_page: 'plan_a_meeting',
        outcome: 'enabled'
    },
    PLAN_A_MEETING_FORCE_MUTE_ENTRY_DISABLED: {
        event: 'button_click',
        clicked_text: 'force_mute_participants_on_entry',
        current_page: 'plan_a_meeting',
        outcome: 'disabled'
    },
    PLAN_A_MEETING_SOFT_MUTE_ENTRY_ENABLED: {
        event: 'button_click',
        clicked_text: 'soft_mute_participants_on_entry',
        current_page: 'plan_a_meeting',
        outcome: 'enabled'
    },
    PLAN_A_MEETING_SOFT_MUTE_ENTRY_DISABLED: {
        event: 'button_click',
        clicked_text: 'soft_mute_participants_on_entry',
        current_page: 'plan_a_meeting',
        outcome: 'disabled'
    },
    PLAN_A_MEETING_ADD_PARTICIPANTS: {
        event: 'button_click',
        clicked_text: 'invite_participants',
        current_page: 'plan_a_meeting',
        outcome: 'succeeded'
    },
    PLAN_A_MEETING_ADD_COHOST: {
        event: 'button_click',
        clicked_text: 'add_co-host',
        current_page: 'plan_a_meeting',
        outcome: 'succeeded'
    },
    HEADER_JIOMEET: {
        event: 'button_click',
        clicked_text: 'jiomeet',
        current_page: 'dashboard',
        outcome: 'succeeded'
    },
    HEADER_MEETINGS: {
        event: 'button_click',
        clicked_text: 'meetings',
        current_page: 'dashboard',
        outcome: 'succeeded'
    },
    HEADER_CHATS: {
        event: 'button_click',
        clicked_text: 'chat',
        current_page: 'dashboard',
        outcome: 'succeeded'
    },
    HEADER_SEARCH: {
        event: 'button_click',
        clicked_text: 'search',
        current_page: 'dashboard',
        outcome: 'succeeded'
    },
    HEADER_PROFILE_ICON: {
        event: 'button_click',
        clicked_text: 'icon',
        current_page: 'dashboard',
        outcome: 'succeeded'
    },
    DASHBOARD_CALENDAR: {
        event: 'button_click',
        clicked_text: 'calendar',
        current_page: 'dashboard',
        outcome: 'succeeded'
    },
    DASHBOARD_START_A_MEETING: {
        event: 'start_a_meeting',
        clicked_text: '',
        current_page: 'meetings_page',
        outcome: 'succeeded'
    },
    DASHBOARD_JOIN_A_MEETING: {
        event: 'join_a_meeting',
        clicked_text: '',
        current_page: 'meetings_page',
        outcome: 'succeeded'
    },
    DASHBOARD_PLAN_A_MEETING: {
        event: 'plan_a_meeting',
        clicked_text: 'share_invitation',
        current_page: 'meetings_page',
        outcome: 'succeeded'
    },
    HOME_MY_DASHBOARD: {
        event: 'menu_click',
        clicked_text: 'my_dashboard',
        current_page: 'dashboard',
        outcome: 'succeeded'
    },
    HEADER_ADMIN_PORTAL: {
        event: 'menu_click',
        clicked_text: 'admin_portal',
        current_page: 'dashboard',
        outcome: 'succeeded'
    },
    DASHBOARD_UPCOMING_MEETINGS: {
        event: 'button_click',
        clicked_text: 'upcoming_meetings',
        current_page: 'meetings_page',
        outcome: 'succeeded'
    },
    DASHBOARD_PREVIOUS_MEETINGS: {
        event: 'button_click',
        clicked_text: 'previous_meetings',
        current_page: 'meetings_page',
        outcome: 'succeeded'
    },
    DASHBOARD_SEND_INVITE: {
        event: 'button_click',
        clicked_text: 'send_invite',
        current_page: 'meetings_page',
        outcome: 'succeeded'
    },
    DASHBOARD_DOWNLOAD_APP: {
        event: 'button_click',
        clicked_text: 'download_app',
        current_page: 'meetings_page',
        outcome: 'succeeded'
    },
    DASHBOARD_COPY_INVITATION: {
        event: 'button_click',
        clicked_text: 'copy_invitation',
        current_page: 'meetings_page',
        outcome: 'succeeded'
    },
    DASHBOARD_COPY_INVITATION_FORM: {
        event: 'button_click',
        clicked_text: 'copy_invitation_meeting_card',
        current_page: 'meetings_page',
        outcome: 'succeeded'
    },
    WHATSAPP_BANNER_CLICKED: {
        event: 'button_click',
        clicked_text: 'whatsapp_banner_clicked',
        current_page: 'meetings_page',
        outcome: 'succeeded'
    },
    DASHBOARD_SETTINGS: {
        event: 'settings',
        clicked_text: '',
        current_page: 'dashboard',
        outcome: 'succeeded'
    },
    DASHBOARD_DECLINE_MEETING: {
        event: 'button_click',
        clicked_text: 'decline_meeting',
        current_page: 'meetings_page',
        outcome: 'decline'
    },
    DASHBOARD_DONT_DECLINE_MEETING: {
        event: 'button_click',
        clicked_text: 'decline_meeting',
        current_page: 'meetings_page',
        outcome: 'dont_decline'
    },
    DOWNLOAD_ICS: {
        event: 'button_click',
        clicked_text: 'download_ics',
        current_page: 'meetings_page',
        outcome: 'succeeded'
    },
    DASHBOARD_SHARE_MEETING: {
        event: 'button_click',
        clicked_text: 'share_invitation',
        current_page: 'meetings_page',
        outcome: 'succeeded'
    },
    DASHBOARD_JOIN_MEETING: {
        event: 'join',
        clicked_text: '',
        current_page: 'meetings_page',
        outcome: 'succeeded'
    },
    DASHBOARD_START_MEETING: {
        event: 'start',
        clicked_text: '',
        current_page: 'meetings_page',
        outcome: 'succeeded'
    },
    DASHBOARD_COPY_LINK: {
        event: 'button_click',
        clicked_text: 'copy_link_meeting_card',
        current_page: 'meetings_page',
        outcome: 'succeeded'
    },
    DASHBOARD_EDIT_MEETING_CANCEL: {
        event: 'button_click',
        clicked_text: 'edit_meeting',
        current_page: 'meetings_page',
        outcome: 'cancel'
    },
    DASHBOARD_EDIT_MEETING_SAVE: {
        event: 'button_click',
        clicked_text: 'edit_meeting',
        current_page: 'meetings_page',
        outcome: 'save'
    },
    DASHBOARD_DELETE_MEETING: {
        event: 'button_click',
        clicked_text: 'delete_meeting',
        current_page: 'meetings_page',
        outcome: 'delete'
    },
    DASHBOARD_DONT_DELETE_MEETING: {
        event: 'button_click',
        clicked_text: 'delete_meeting',
        current_page: 'meetings_page',
        outcome: 'dont_delete'
    },
    DASHBOARD_SIDE_BAR_CHAT: {
        event: 'button_click',
        clicked_text: 'chat_with_us',
        current_page: 'dashboard',
        outcome: 'succeeded'
    },
    DASHBOARD_SIDE_BAR_CONTACT: {
        event: 'button_click',
        clicked_text: 'contact_us',
        current_page: 'dashboard',
        outcome: 'succeeded'
    },
    DASHBOARD_SIDE_BAR_WHATSAPP: {
        event: 'button_click',
        clicked_text: 'whatsapp_us',
        current_page: 'dashboard',
        outcome: 'succeeded'
    },
    NEW_CHAT: {
        event: 'new_chat',
        clicked_text: 'new_chat',
        current_page: 'chat',
        outcome: 'succeeded'
    },
    ADD_MEMBER_BY_NAME: {
        event: 'new_chat',
        clicked_text: 'add_member_by_name',
        current_page: 'chat',
        outcome: 'succeeded'
    },
    ADD_MEMBER_BY_PHONE_NUMBER: {
        event: 'new_chat',
        clicked_text: 'add_member_by_phone_number',
        current_page: 'chat',
        outcome: 'succeeded'
    },
    ADD_MEMBER_BY_EMAIL: {
        event: 'new_chat',
        clicked_text: 'add_member_by_email',
        current_page: 'chat',
        outcome: 'succeeded'
    },
    FORMAT_TEXT: {
        event: 'button_click',
        clicked_text: 'format_text',
        current_page: 'chat',
        outcome: 'succeeded'
    },
    SEND_MESSAGE: {
        event: 'button_click',
        clicked_text: 'send_message',
        current_page: 'chat',
        outcome: 'succeeded'
    },
    EMOJI: {
        event: 'button_click',
        clicked_text: 'emoji',
        current_page: 'chat',
        outcome: 'succeeded'
    },
    SEARCH_CLICK_INPUT: {
        event: 'button_click',
        clicked_text: 'search_chat',
        current_page: 'chat',
        outcome: 'succeeded'
    },
    FAVOURITES_PANEL: {
        event: 'button_click',
        clicked_text: 'favourites',
        current_page: 'chat',
        outcome: 'succeeded'
    },
    CHATS: {
        event: 'button_click',
        clicked_text: 'chats',
        current_page: 'chat',
        outcome: 'succeeded'
    },
    ADD_FAVOURITE: {
        event: 'button_click',
        clicked_text: 'add_to_favourites',
        current_page: 'chat',
        outcome: 'succeeded'
    },
    REMOVE_FAVOURITE: {
        event: 'button_click',
        clicked_text: 'remove_from_favourites',
        current_page: 'chat',
        outcome: 'succeeded'
    },
    DOWNLOAD_RECORDING_LINK: {
        event: 'button_click',
        clicked_text: 'download_recording_link',
        current_page: 'chat',
        outcome: 'succeeded'
    },
    DOWNLOAD_RECORDING_LINK_FAILURE: {
        event: 'button_click',
        clicked_text: 'download_recording_link',
        current_page: 'chat',
        outcome: 'failed'
    },
    COPY_RECORDING_LINK: {
        event: 'button_click',
        clicked_text: 'copy_recording_link',
        current_page: 'chat',
        outcome: 'succeeded'
    },
    CHAT_CALL: {
        event: 'audio_call',
        clicked_text: '',
        current_page: 'chat',
        outcome: 'succeeded'
    },
    CHAT_CALL_FAILURE: {
        event: 'audio_call',
        clicked_text: '',
        current_page: 'chat',
        outcome: 'failed'
    },
    CHAT_VIDEO_MEET: {
        event: 'video_call',
        clicked_text: '',
        current_page: 'chat',
        outcome: 'succeeded'
    },
    CHAT_VIDEO_MEET_FAILURE: {
        event: 'video_call',
        clicked_text: '',
        current_page: 'chat',
        outcome: 'failed'
    },
    CHAT_AUDIO_CALL: {
        event: 'audio_call',
        clicked_text: '',
        current_page: 'chat_options',
        outcome: 'succeeded'
    },
    CHAT_AUDIO_CALL_FAILURE: {
        event: 'audio_call',
        clicked_text: '',
        current_page: 'chat_options',
        outcome: 'failed'
    },
    CHAT_VIDEO_CALL: {
        event: 'video_call',
        clicked_text: '',
        current_page: 'chat_options',
        outcome: 'succeeded'
    },
    CHAT_VIDEO_CALL_FAILURE: {
        event: 'video_call',
        clicked_text: '',
        current_page: 'chat_options',
        outcome: 'failed'
    },
    CONTACT_SALES_SUBMIT_ERROR: {
        event: 'submit',
        clicked_text: '',
        current_page: 'sales_jiomeet',
        outcome: 'error'
    },
    CONTACT_SALES_SUBMIT_SUCCESS: {
        event: 'submit',
        clicked_text: '',
        current_page: 'sales_jiomeet',
        outcome: 'succeeded'
    },
    CONTACT_SALES_SUBMIT_FAILURE: {
        event: 'submit',
        clicked_text: '',
        current_page: 'sales_jiomeet',
        outcome: 'failed'
    },
    CONTACT_SALES_PRIVACY_POLICY: {
        event: 'button_click',
        clicked_text: 'privacy_policy',
        current_page: 'sales_jiomeet',
        outcome: 'succeeded'
    },
    CONTACT_SALES_FORM_NOT_SUBMITTED: {
        event: 'user_not_submitted_sales_form',
        clicked_text: '',
        current_page: 'sales_jiomeet',
        outcome: 'succeeded'
    },
    SIGNIN_FROM_SIGNUP_SUCCESS: {
        event: 'button_click',
        clicked_text: 'sign_in_from_sign_up',
        current_page: 'sign_up',
        outcome: 'succeeded'
    },
    SIGNUP_PAGE_SUBMIT: {
        event: 'button_click',
        clicked_text: 'submit',
        current_page: 'sign_up',
        outcome: 'succeeded'
    },
    PRIVACY_POLICY: {
        event: 'button_click',
        clicked_text: 'privacy_policy',
        current_page: 'sign_up',
        outcome: 'succeeded'
    },
    TERMS_OF_SERVICE: {
        event: 'button_click',
        clicked_text: 'tnc',
        current_page: 'sign_up',
        outcome: 'succeeded'
    },
    SIGNUP_EMAIL_SUCCESS: {
        event: 'category_input',
        clicked_text: 'email',
        current_page: 'sign_up',
        outcome: 'succeeded'
    },
    SIGNUP_EMAIL_FAIL: {
        event: 'category_input',
        clicked_text: 'email',
        current_page: 'sign_up',
        outcome: 'failed'
    },
    SIGNUP_MOBILE_SUCCESS: {
        event: 'category_input',
        clicked_text: 'mobile',
        current_page: 'sign_up',
        outcome: 'succeeded'
    },
    SIGNUP_MOBILE_FAIL: {
        event: 'category_input',
        clicked_text: 'mobile',
        current_page: 'sign_up',
        outcome: 'failed'
    },
    SIGNUP_GOOGLE_SUCCESS: {
        event: 'sign_in_with_google',
        clicked_text: '',
        current_page: 'sign_up',
        outcome: 'succeeded'
    },
    SIGNUP_GOOGLE_FAIL: {
        event: 'sign_in_with_google',
        clicked_text: '',
        current_page: 'sign_up',
        outcome: 'failed'
    },
    SIGNUP_FACEBOOK_SUCCESS: {
        event: 'sign_in_with_facebook',
        clicked_text: '',
        current_page: 'sign_up',
        outcome: 'succeeded'
    },
    SIGNUP_FACEBOOK_FAIL: {
        event: 'sign_in_with_facebook',
        clicked_text: '',
        current_page: 'sign_up',
        outcome: 'failed'
    },
    RESEND_OTP_SUCCESS: {
        event: 'sign_up_with_mobile',
        clicked_text: 'resend_otp',
        current_page: 'sign_up',
        outcome: 'succeeded'
    },
    RESEND_OTP_FAIL: {
        event: 'sign_up_with_mobile',
        clicked_text: 'resend_otp',
        current_page: 'sign_up',
        outcome: 'failed'
    },
    VERIFY_OTP_SUCCESS: {
        event: 'sign_up_with_mobile',
        clicked_text: 'verify_otp',
        current_page: 'sign_up',
        outcome: 'succeeded'
    },
    VERIFY_OTP_FAIL: {
        event: 'sign_up_with_mobile',
        clicked_text: 'verify_otp',
        current_page: 'sign_up',
        outcome: 'failed'
    },
    RESEND_EMAIL_SUCCESS: {
        event: 'sign_up_with_email',
        clicked_text: 'resend_confirmation_email',
        current_page: 'sign_up',
        outcome: 'succeeded'
    },
    EMAIL_VERIFY_SUCCESS: {
        event: 'sign_up_with_email',
        clicked_text: 'verify_email',
        current_page: 'sign_up',
        outcome: 'succeeded'
    },
    RESEND_EMAIL_FAIL: {
        event: 'sign_up_with_email',
        clicked_text: 'resend_confirmation_email',
        current_page: 'sign_up',
        outcome: 'failed'
    },
    USER_NOT_SIGNED_UP: {
        event: 'user_not_signed_up',
        clicked_text: '',
        current_page: 'sign_up',
        outcome: 'succeeded'
    },
    HEADER_HOME: {
        event: 'button_click',
        clicked_text: 'home',
        current_page: 'dashboard',
        outcome: 'succeeded'
    },
    SETTINGS_SELF_VIEW_ENABLE: {
        event: 'button_click',
        clicked_text: 'mirror_your_own_video',
        current_page: 'settings',
        outcome: 'enabled'
    },
    SETTINGS_SELF_VIEW_DISABLE: {
        event: 'button_click',
        clicked_text: 'mirror_your_own_video',
        current_page: 'settings',
        outcome: 'disabled'
    },
    SETTINGS_NOISE_SUPPRESSION_ENABLED: {
        event: 'button_click',
        clicked_text: 'noise_suppression',
        current_page: 'settings',
        outcome: 'enabled'
    },
    SETTINGS_NOISE_SUPPRESSION_DISABLED: {
        event: 'button_click',
        clicked_text: 'noise_suppression',
        current_page: 'settings',
        outcome: 'disabled'
    },
    HOME_START_A_MEETING_BEFORE_LOGIN: {
        event: 'start_a_meeting',
        current_page: 'home_page',
        clicked_text: '',
        outcome: 'succeeded'
    },
    HOME_JOIN_A_MEETING_BEFORE_LOGIN: {
        event: 'join_a_meeting',
        current_page: 'home_page',
        clicked_text: '',
        outcome: 'succeeded'
    },
    HOME_PLAN_A_MEETING_BEFORE_LOGIN: {
        event: 'plan_a_meeting',
        current_page: 'home_page',
        clicked_text: '',
        outcome: 'succeeded'
    },
    DEFAULT_IMAGE6_CLICK: {
        event: 'virtual_background',
        clicked_text: 'default_image_6',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    DEFAULT_IMAGE7_CLICK: {
        event: 'virtual_background',
        clicked_text: 'default_image_7',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    DEFAULT_IMAGE8_CLICK: {
        event: 'virtual_background',
        clicked_text: 'default_image_8',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    DEFAULT_IMAGE9_CLICK: {
        event: 'virtual_background',
        clicked_text: 'default_image_9',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    DEFAULT_IMAGE10_CLICK: {
        event: 'virtual_background',
        clicked_text: 'default_image_10',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    DEFAULT_IMAGE11_CLICK: {
        event: 'virtual_background',
        clicked_text: 'default_image_11',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    DEFAULT_IMAGE12_CLICK: {
        event: 'virtual_background',
        clicked_text: 'default_image_12',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    DEFAULT_IMAGE13_CLICK: {
        event: 'virtual_background',
        clicked_text: 'default_image_13',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    DEFAULT_IMAGE14_CLICK: {
        event: 'virtual_background',
        clicked_text: 'default_image_14',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    DEFAULT_IMAGE15_CLICK: {
        event: 'virtual_background',
        clicked_text: 'default_image_15',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    INSIDE_CALL_SELF_VIEW_ENABLED: {
        event: 'button_click',
        clicked_text: 'mirror_your_own_video',
        current_page: 'inside_call',
        outcome: 'enabled'
    },
    INSIDE_CALL_SELF_VIEW_DISABLED: {
        event: 'button_click',
        clicked_text: 'mirror_your_own_video',
        current_page: 'inside_call',
        outcome: 'disabled'
    },
    INSIDE_CALL_HOST_CONTROLS: {
        event: 'button_click',
        clicked_text: 'host_controls',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_HOST_CONTROLS: {
        event: 'button_click',
        clicked_text: 'host_controls',
        current_page: 'breakout_room',
        outcome: 'succeeded'
    },
    INSIDE_CALL_GALLERY_VIEW: {
        event: 'layout',
        clicked_text: 'switch_to_gallery_mode',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_GALLERY_VIEW: {
        event: 'layout',
        clicked_text: 'switch_to_gallery_mode',
        current_page: 'breakout_room',
        outcome: 'succeeded'
    },
    INSIDE_CALL_SPEAKER_VIEW: {
        event: 'layout',
        clicked_text: 'switch_to_speaker_view',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    BREAKOUT_ROOM_SPEAKER_VIEW: {
        event: 'layout',
        clicked_text: 'switch_to_speaker_view',
        current_page: 'breakout_room',
        outcome: 'succeeded'
    },
    PLAN_A_MEETING_CLOSE: {
        event: 'button_click',
        clicked_text: 'close',
        current_page: 'plan_a_meeting',
        outcome: 'succeeded'
    },
    PLAN_A_MEETING_COPY_INVITATION: {
        event: 'button_click',
        clicked_text: 'copy_invitation',
        current_page: 'plan_a_meeting',
        outcome: 'succeeded'
    },
    PLAN_A_MEETING_JOIN_THROUGH_LINK: {
        event: 'button_click',
        clicked_text: 'join_through_link',
        current_page: 'plan_a_meeting',
        outcome: 'succeeded'
    },
    PLAN_A_MEETING_JOIN_THROUGH_SIP_DEVICE: {
        event: 'button_click',
        clicked_text: 'join_through_sip_device_url',
        current_page: 'plan_a_meeting',
        outcome: 'succeeded'
    },
    PLAN_A_MEETING_ADD_TO_CALENDAR: {
        event: 'button_click',
        clicked_text: 'add_to_calendar',
        current_page: 'plan_a_meeting',
        outcome: 'succeeded'
    },
    PLAN_A_MEETING_DOWNLOAD_ICS: {
        event: 'button_click',
        clicked_text: 'download_ics',
        current_page: 'plan_a_meeting',
        outcome: 'succeeded'
    },
    SETTINGS_STOP_SPEAKER: {
        event: 'button_click',
        clicked_text: 'stop_speaker',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    SETTINGS_STOP_MIC: {
        event: 'button_click',
        clicked_text: 'stop_mic',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    ONCLICK_VIDEO: {
        event: 'button_click',
        clicked_text: 'video',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    VIDEO_ON_WHILE_CALL: {
        event: 'button_click',
        clicked_text: 'turn_off_my_video_when_accepting_incoming_call',
        current_page: 'settings',
        outcome: 'enabled'
    },
    VIDEO_OFF_WHILE_CALL: {
        event: 'button_click',
        clicked_text: 'turn_off_my_video_when_accepting_incoming_call',
        current_page: 'settings',
        outcome: 'disabled'
    },
    ONCLICK_CHANGEBACKGROUND: {
        event: 'virtual_background',
        clicked_text: 'change_background',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    NONE_IMAGE_CLICK: {
        event: 'virtual_background',
        clicked_text: 'none',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    CUSTOM_IMAGE_CLICK: {
        event: 'virtual_background',
        clicked_text: 'custom',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    BLUR_IMAGE_CLICK: {
        event: 'virtual_background',
        clicked_text: 'blur',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    DEFAULT_IMAGE1_CLICK: {
        event: 'virtual_background',
        clicked_text: 'default_image_1',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    DEFAULT_IMAGE2_CLICK: {
        event: 'virtual_background',
        clicked_text: 'default_image_2',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    DEFAULT_IMAGE3_CLICK: {
        event: 'virtual_background',
        clicked_text: 'default_image_3',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    DEFAULT_IMAGE4_CLICK: {
        event: 'virtual_background',
        clicked_text: 'default_image_4',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    DEFAULT_IMAGE5_CLICK: {
        event: 'virtual_background',
        clicked_text: 'default_image_5',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    ONCLICK_AUDIO: {
        event: 'button_click',
        clicked_text: 'audio',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    AUDIO_ON_WHILE_CALL: {
        event: 'button_click',
        clicked_text: 'turn_off_my_microphone_when_accepting_incoming_call',
        current_page: 'settings',
        outcome: 'enabled'
    },
    AUDIO_OFF_WHILE_CALL: {
        event: 'button_click',
        clicked_text: 'turn_off_my_microphone_when_accepting_incoming_call',
        current_page: 'settings',
        outcome: 'disabled'
    },
    TEST_MIC: {
        event: 'button_click',
        clicked_text: 'test_mic',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    TEST_SPEAKER: {
        event: 'button_click',
        clicked_text: 'test_speaker',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    STOP_SPEAKER: {
        event: 'button_click',
        clicked_text: 'stop_speaker',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    ONCLICK_PROFILE: {
        event: 'button_click',
        clicked_text: 'profile',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    PROFILE_PICTURE_CHANGE: {
        event: 'button_click',
        clicked_text: 'change_profile_pic',
        current_page: 'settings',
        outcome: 'changed'
    },
    PROFILE_PICTURE_CHANGE_CANCEL: {
        event: 'button_click',
        clicked_text: 'change_profile_pic',
        current_page: 'settings',
        outcome: 'not_changed'
    },
    ONCLICK_PERSONAL_MEETING_ID: {
        event: 'button_click',
        clicked_text: 'personal_meeting_id',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    PMI_PARTICIPANT_MY_ORG_ENABLED: {
        event: 'button_click',
        clicked_text: 'allow_participants_only_from_my_organisation',
        current_page: 'settings',
        outcome: 'enabled'
    },
    PMI_PARTICIPANT_MY_ORG_DISABLED: {
        event: 'button_click',
        clicked_text: 'allow_participants_only_from_my_organisation',
        current_page: 'settings',
        outcome: 'disabled'
    },
    PMI_DONT_ALLOW_GUEST_USER_ENABLED: {
        event: 'button_click',
        clicked_text: 'dont_allow_guest_users',
        current_page: 'settings',
        outcome: 'enabled'
    },
    PMI_DONT_ALLOW_GUEST_USER_DISABLED: {
        event: 'button_click',
        clicked_text: 'dont_allow_guest_users',
        current_page: 'settings',
        outcome: 'disabled'
    },
    PMI_WAITING_ROOM_ENABLED: {
        event: 'button_click',
        clicked_text: 'enable_waiting_room',
        current_page: 'settings',
        outcome: 'enabled'
    },
    PMI_WAITING_ROOM_DISABLED: {
        event: 'button_click',
        clicked_text: 'enable_waiting_room',
        current_page: 'settings',
        outcome: 'disabled'
    },
    PMI_JOIN_BEFORE_HOST_ENABLED: {
        event: 'button_click',
        clicked_text: 'allow_join_before_host',
        current_page: 'settings',
        outcome: 'enabled'
    },
    PMI_JOIN_BEFORE_HOST_DISABLED: {
        event: 'button_click',
        clicked_text: 'allow_join_before_host',
        current_page: 'settings',
        outcome: 'disabled'
    },
    PMI_FORCE_MUTE_ENTRY_ENABLED: {
        event: 'button_click',
        clicked_text: 'force_mute_participants_on_entry',
        current_page: 'settings',
        outcome: 'enabled'
    },
    PMI_FORCE_MUTE_ENTRY_DISABLED: {
        event: 'button_click',
        clicked_text: 'force_mute_participants_on_entry',
        current_page: 'settings',
        outcome: 'disabled'
    },
    PMI_SOFT_MUTE_ENTRY_ENABLED: {
        event: 'button_click',
        clicked_text: 'soft_mute_participants_on_entry',
        current_page: 'settings',
        outcome: 'enabled'
    },
    PMI_SOFT_MUTE_ENTRY_DISABLED: {
        event: 'button_click',
        clicked_text: 'soft_mute_participants_on_entry',
        current_page: 'settings',
        outcome: 'disabled'
    },
    PMI_SAVE_BUTTON: {
        event: 'button_click',
        clicked_text: 'personal_meeting_id_settings_save',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    ONCLICK_NOTIFICATION: {
        event: 'button_click',
        clicked_text: 'notifications',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    EMAIL_NOTIFICATIONS_ENABLED: {
        event: 'button_click',
        clicked_text: 'email_notification',
        current_page: 'settings',
        outcome: 'enabled'
    },
    EMAIL_NOTIFICATIONS_DISABLED: {
        event: 'button_click',
        clicked_text: 'email_notification',
        current_page: 'settings',
        outcome: 'disabled'
    },
    SMS_NOTIFICATIONS_ENABLED: {
        event: 'button_click',
        clicked_text: 'sms_notification',
        current_page: 'settings',
        outcome: 'enabled'
    },
    SMS_NOTIFICATIONS_DISABLED: {
        event: 'button_click',
        clicked_text: 'sms_notification',
        current_page: 'settings',
        outcome: 'disabled'
    },
    WHATSAPP_NOTIFICATIONS_ENABLED: {
        event: 'button_click',
        clicked_text: 'whatsapp_notification',
        current_page: 'settings',
        outcome: 'enabled'
    },
    WHATSAPP_NOTIFICATIONS_DISABLED: {
        event: 'button_click',
        clicked_text: 'whatsapp_notification',
        current_page: 'settings',
        outcome: 'disabled'
    },
    WHATSAPP_SUBSCRIBE: {
        event: 'button_click',
        clicked_text: 'whatsapp_subscribe',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    INCOMING_CALL_SILENT: {
        event: 'button_click',
        clicked_text: 'incoming_meeting_calls',
        current_page: 'settings',
        outcome: 'silent'
    },
    INCOMING_CALL_JIOMEET_TUNE: {
        event: 'button_click',
        clicked_text: 'incoming_meeting_calls',
        current_page: 'settings',
        outcome: 'jiomeet_tune'
    },
    LANGUAGE_HINDI: {
        event: 'language',
        clicked_text: 'hindi',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    LANGUAGE_ENGLISH: {
        event: 'language',
        clicked_text: 'english',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    ONCLICK_LIVE_STREAM: {
        event: 'button_click',
        clicked_text: 'live_stream',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    ADD_LIVE_STREAM: {
        event: 'live_stream',
        clicked_text: 'add_new_stream',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    LIVESTREAM_YOUTUBE: {
        event: 'live_stream',
        clicked_text: 'platform',
        current_page: 'settings',
        outcome: 'youtube_live'
    },
    LIVESTREAM_FACEBOOK: {
        event: 'live_stream',
        clicked_text: 'platform',
        current_page: 'settings',
        outcome: 'facebook_live'
    },
    LIVESTREAM_CUSTOM: {
        event: 'live_stream',
        clicked_text: 'platform',
        current_page: 'settings',
        outcome: 'custom'
    },
    LIVESTREAM_CANCEL: {
        event: 'live_stream',
        clicked_text: 'cancel',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    LIVESTREAM_SAVE: {
        event: 'live_stream',
        clicked_text: 'save',
        current_page: 'settings',
        outcome: 'succeeded'
    },
    LIVESTREAM_SAVE_FAIL: {
        event: 'live_stream',
        clicked_text: 'save',
        current_page: 'settings',
        outcome: 'failed'
    },
    NETWORK_ICON: {
        event: 'button_click',
        clicked_text: 'network_icon',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    INFORMATION_ICON_INSIDE_CALL: {
        event: 'button_click',
        clicked_text: 'meeting_information_icon',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    COPY_LINK_INSIDE_CALL: {
        event: 'button_click',
        clicked_text: 'copy_link',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    COPY_INVITATION_INSIDE_CALL: {
        event: 'button_click',
        clicked_text: 'copy_invitation',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    MIC_MUTE_INSIDE_CALL: {
        event: 'mic',
        clicked_text: 'mute',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    MIC_MUTE_INSIDE_CALL_FAIL: {
        event: 'mic',
        clicked_text: 'mute',
        current_page: 'inside_call',
        outcome: 'failed'
    },
    MIC_UNMUTE_INSIDE_CALL: {
        event: 'mic',
        clicked_text: 'unmute',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    MIC_UNMUTE_INSIDE_CALL_FAIL: {
        event: 'mic',
        clicked_text: 'unmute',
        current_page: 'inside_call',
        outcome: 'failed'
    },
    VIDEO_MUTE_INSIDE_CALL: {
        event: 'camera',
        clicked_text: 'stop_video',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    VIDEO_MUTE_INSIDE_CALL_FAIL: {
        event: 'camera',
        clicked_text: 'stop_video',
        current_page: 'inside_call',
        outcome: 'failed'
    },
    VIDEO_UNMUTE_INSIDE_CALL: {
        event: 'camera',
        clicked_text: 'start_video',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    VIDEO_UNMUTE_INSIDE_CALL_FAIL: {
        event: 'camera',
        clicked_text: 'start_video',
        current_page: 'inside_call',
        outcome: 'failed'
    },
    START_WHITEBOARD_INSIDE_CALL: {
        event: 'screen_share',
        clicked_text: 'start_whiteboard_share',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    STOP_WHITEBOARD_INSIDE_CALL: {
        event: 'screen_share',
        clicked_text: 'stop_whiteboard_share',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    START_SCREENSHARE_INSIDE_CALL: {
        event: 'screen_share',
        clicked_text: 'start_screen_share',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    STOP_SCREENSHARE_INSIDE_CALL: {
        event: 'screen_share',
        clicked_text: 'stop_screen_share',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    STOP_SCREENSHARE_INSIDE_CALL_FAIL: {
        event: 'screen_share',
        clicked_text: 'start_screen_share',
        current_page: 'inside_call',
        outcome: 'failed'
    },
    RECORDING_START_INSIDE_CALL_SUCCESS: {
        event: 'recording',
        clicked_text: 'recording_start',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    RECORDING_START_INSIDE_CALL_FAIL: {
        event: 'recording',
        clicked_text: 'recording_start',
        current_page: 'inside_call',
        outcome: 'failed'
    },
    RECORDING_STOP_INSIDE_CALL_SUCCESS: {
        event: 'recording',
        clicked_text: 'recording_stop',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    RECORDING_STOP_INSIDE_CALL_FAIL: {
        event: 'recording',
        clicked_text: 'recording_stop',
        current_page: 'inside_call',
        outcome: 'failed'
    },
    RAISE_HAND_INSIDE_CALL_SUCCESS: {
        event: 'handraise',
        clicked_text: 'raise_hand',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    RAISE_HAND_INSIDE_CALL_FAIL: {
        event: 'handraise',
        clicked_text: 'raise_hand',
        current_page: 'inside_call',
        outcome: 'failed'
    },
    LOWER_HAND_INSIDE_CALL_SUCCESS: {
        event: 'handraise',
        clicked_text: 'lower_hand',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    LOWER_HAND_INSIDE_CALL_FAIL: {
        event: 'handraise',
        clicked_text: 'lower_hand',
        current_page: 'inside_call',
        outcome: 'failed'
    },
    LEAVE_MEETING_INSIDE_CALL_SUCCESS: {
        event: 'button_click',
        clicked_text: 'leave_meeting',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    END_MEETING_INSIDE_CALL_SUCCESS: {
        event: 'button_click',
        clicked_text: 'end_meeting',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    CHAT_TOGGLE_INSIDE_CALL: {
        event: 'chat',
        clicked_text: 'chat',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    CLOSE_CHAT: {
        event: 'chat',
        clicked_text: 'close_chat',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    POP_OUT_CHAT: {
        event: 'chat',
        clicked_text: 'pop_out_chat',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    SEND_MESSAGE_IN_CHAT: {
        event: 'chat',
        clicked_text: 'send_message',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    SEND_MESSAGE_IN_CHAT_FAIL: {
        event: 'chat',
        clicked_text: 'send_message',
        current_page: 'inside_call',
        outcome: 'failed'
    },
    FORCE_MUTE_ALL_INSIDE_CALL_APPLY: {
        event: 'button_click',
        clicked_text: 'force_mute_all',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    FORCE_MUTE_ALL_INSIDE_CALL_REMOVE: {
        event: 'button_click',
        clicked_text: 'undo_force_mute',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    SOFT_MUTE_ALL_INSIDE_CALL_APPLY: {
        event: 'button_click',
        clicked_text: 'mute_all',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    LOWER_ALL_HANDS_PARTICIPANT_PANEL: {
        event: 'button_click',
        clicked_text: 'lower_all_hands',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    SHARE_MEETING_PARTICIPANT_PANEL: {
        event: 'button_click',
        clicked_text: 'share_meeting',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    LOCK_MEETING_INSIDE_CALL: {
        event: 'button_click',
        clicked_text: 'lock_meeting',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    UNLOCK_MEETING_INSIDE_CALL: {
        event: 'button_click',
        clicked_text: 'unlock_meeting',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    START_RECORDING_INSIDE_CALL_SUCCESS: {
        event: 'recording',
        clicked_text: 'recording_start',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    START_RECORDING_INSIDE_CALL_FAIL: {
        event: 'recording',
        clicked_text: 'recording_start',
        current_page: 'inside_call_host_controls',
        outcome: 'failed'
    },
    STOP_RECORDING_INSIDE_CALL_SUCCESS: {
        event: 'recording',
        clicked_text: 'recording_stop',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    STOP_RECORDING_INSIDE_CALL_FAIL: {
        event: 'recording',
        clicked_text: 'recording_stop',
        current_page: 'inside_call_host_controls',
        outcome: 'failed'
    },
    ENTRY_EXIT_CHIME_ON_INSIDE_CALL: {
        event: 'button_click',
        clicked_text: 'play_entry-exit_chime',
        current_page: 'inside_call_host_controls',
        outcome: 'enabled'
    },
    ENTRY_EXIT_CHIME_OFF_INSIDE_CALL: {
        event: 'button_click',
        clicked_text: 'play_entry-exit_chime',
        current_page: 'inside_call_host_controls',
        outcome: 'disabled'
    },
    COPY_LINK_INSIDE_CALL_HOST_CONTROL: {
        event: 'button_click',
        clicked_text: 'copy_link',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    COPY_INVITATION_INSIDE_CALL_HOST_CONTROL: {
        event: 'button_click',
        clicked_text: 'copy_invitation',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    SHARE_MEETING_ON_GMAIL: {
        event: 'button_click',
        clicked_text: 'share_through_mail',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    SHARE_MEETING_ON_OUTLOOK: {
        event: 'button_click',
        clicked_text: 'share_through_outlook',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    SHARE_MEETING_ON_YAHOO: {
        event: 'button_click',
        clicked_text: 'share_through_yahoo',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    CLICK_MORE: {
        event: 'button_click',
        clicked_text: 'more',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    CHANGE_LAYOUT: {
        event: 'button_click',
        clicked_text: 'change_layout',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    INSIDE_CALL_REACTIONS_LIKE: {
        event: 'reactions',
        clicked_text: 'like',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    INSIDE_CALL_REACTIONS_APPLAUSE: {
        event: 'reactions',
        clicked_text: 'applause',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    INSIDE_CALL_REACTIONS_HEART: {
        event: 'reactions',
        clicked_text: 'heart',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    INSIDE_CALL_REACTIONS_CELEBRATIONS: {
        event: 'reactions',
        clicked_text: 'celebrations',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    INSIDE_CALL_REACTIONS_SMILE: {
        event: 'reactions',
        clicked_text: 'smile',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    INSIDE_CALL_REACTIONS_AWESOME: {
        event: 'reactions',
        clicked_text: 'awesome',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    INSIDE_CALL_REACTIONS_HIGHFIVE: {
        event: 'reactions',
        clicked_text: 'highfive',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    INSIDE_CALL_REACTIONS_SAD: {
        event: 'reactions',
        clicked_text: 'sad',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    LIVESTREAM__YOUTUBE: {
        event: 'live_stream',
        clicked_text: 'youtube',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    LIVESTREAM__FACEBOOK: {
        event: 'live_stream',
        clicked_text: 'facebook',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    LIVESTREAM__CUSTOM: {
        event: 'live_stream',
        clicked_text: 'custom',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    LIVESTREAM__SAVE: {
        event: 'live_stream',
        clicked_text: 'add',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    LIVESTREAM__SAVE_FAIL: {
        event: 'live_stream',
        clicked_text: 'add',
        current_page: 'inside_call_host_controls',
        outcome: 'failed'
    },
    LIVESTREAM__CANCEL: {
        event: 'live_stream',
        clicked_text: 'cancel',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    ADD_NEW_CHANNEL: {
        event: 'live_stream',
        clicked_text: 'add_new_channel',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    LIVESTREAM_START_INSIDE_CALL_SUCCESS: {
        event: 'live_stream',
        clicked_text: 'live_stream',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    CANCEL_LIVE_STREAM: {
        event: 'live_stream',
        clicked_text: 'cancel',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    GO_LIVE: {
        event: 'live_stream',
        clicked_text: 'go_live',
        current_page: 'inside_call_host_controls',
        outcome: 'succeeded'
    },
    GO_LIVE_FAIL: {
        event: 'live_stream',
        clicked_text: 'go_live',
        current_page: 'inside_call_host_controls',
        outcome: 'failed'
    },
    AUDIO_ONLY_MODE_INSIDE_CALL_ENABLED: {
        event: 'audio_only_mode',
        clicked_text: 'enable_audio_only_mode',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    AUDIO_ONLY_MODE_INSIDE_CALL_DISABLED: {
        event: 'audio_only_mode',
        clicked_text: 'disable_audio_only_mode',
        current_page: 'inside_call',
        outcome: 'succeeded'
    },
    ENABLE_HIDE_NONVIDEO_PARTICIPANTS: {
        event: 'layout',
        clicked_text: 'hide_non-video_participants',
        current_page: 'inside_call',
        outcome: 'enabled'
    },
    DISABLE_HIDE_NONVIDEO_PARTICIPANTS: {
        event: 'layout',
        clicked_text: 'hide_non-video_participants',
        current_page: 'inside_call',
        outcome: 'disabled'
    },
    OUTLOOK_ADD_IN: {
        event: 'button_click',
        clicked_text: 'outlook_add_in',
        current_page: 'products_jiomeetenterprise',
        outcome: 'succeeded'
    },
    PERSONALISED_VIRTUAL_BACKGROUND: {
        event: 'button_click',
        clicked_text: 'personalised_virtual_background',
        current_page: 'products_jiomeetenterprise',
        outcome: 'succeeded'
    },
    VIEW_YOUR_MEETING_RECORDING: {
        event: 'button_click',
        clicked_text: 'view_your_meeting_recording',
        current_page: 'products_jiomeetenterprise',
        outcome: 'succeeded'
    },
    CREATE_GROUPS_WITH_ALL_USER_TYPES: {
        event: 'button_click',
        clicked_text: 'create_groups_with_all_usertypes',
        current_page: 'products_jiomeetenterprise',
        outcome: 'succeeded'
    },
    CALL_CHAT_BY_NON_ENTERPRISE_USER: {
        event: 'button_click',
        clicked_text: 'call_chat_by_nonenterprise_users',
        current_page: 'products_jiomeetenterprise',
        outcome: 'succeeded'
    },
    SET_CUSTOM_RULES_FOR_FILE_SHARING: {
        event: 'button_click',
        clicked_text: 'set_custom_rules_for_file_sharing',
        current_page: 'products_jiomeetenterprise',
        outcome: 'succeeded'
    },
    LEGACY_VC_ROOM_THROUGH_VPN: {
        event: 'button_click',
        clicked_text: 'legacy_vc_room_through_vpn',
        current_page: 'products_jiomeetenterprise',
        outcome: 'succeeded'
    },
    MORE_MEETING_PARTICIPANTS: {
        event: 'button_click',
        clicked_text: 'more_meeting_participants',
        current_page: 'products_jiomeetenterprise',
        outcome: 'succeeded'
    },
    JIOMEET_BOT_FOR_MICROSOFT_TEAMS: {
        event: 'button_click',
        clicked_text: 'jiomeet_bot_for_microsoft_teams',
        current_page: 'products_jiomeetenterprise',
        outcome: 'succeeded'
    },
    WHITE_LABELLING: {
        event: 'button_click',
        clicked_text: 'white_labelling',
        current_page: 'products_jiomeetenterprise',
        outcome: 'succeeded'
    },
    EVENTS_ONLY_FOR_YOUR_ORGANISATION: {
        event: 'button_click',
        clicked_text: 'events_only_for_your_organisations',
        current_page: 'products_jiomeetenterprise',
        outcome: 'succeeded'
    },
    CPASS_OFFERING: {
        event: 'button_click',
        clicked_text: 'cpaas_offering',
        current_page: 'products_jiomeetenterprise',
        outcome: 'succeeded'
    },
    MEGHRAJ_CERTIFIED_DATACENTERS: {
        event: 'button_click',
        clicked_text: 'meghraj_certified_datacenters',
        current_page: 'products_jiomeetenterprise',
        outcome: 'succeeded'
    },
    STQC_CERTIFIED: {
        event: 'button_click',
        clicked_text: 'stqc_certified',
        current_page: 'products_jiomeetenterprise',
        outcome: 'succeeded'
    },
    RETAIL_SIGNUP_CLICKED_BANNER: {
        event: 'banner_sign_up',
        clicked_text: '',
        current_page: 'products_jiomeet',
        outcome: 'succeeded'
    },
    RETAIL_DOWNLOADAPP_CLICKED_BANNER: {
        event: 'banner_download_app',
        clicked_text: '',
        current_page: 'products_jiomeet',
        outcome: 'succeeded'
    },
    SIGNUP_CLICKED_COMMON: {
        event: 'bottom_sign_up',
        clicked_text: '',
        current_page: 'products_jiomeet',
        outcome: 'succeeded'
    },
    DOWNLOADAPP_CLICKED_COMMON: {
        event: 'banner_download_app',
        clicked_text: '',
        current_page: 'products_jiomeet',
        outcome: 'succeeded'
    },
    PRODUCT_ENTERPRISE_SUBSCRIBE: {
        event: 'subscribe',
        clicked_text: '',
        current_page: 'products_jiomeetenterprise',
        outcome: 'succeeded'
    },
    PRODUCT_JIOMEET_SUBSCRIBE: {
        event: 'subscribe',
        clicked_text: '',
        current_page: 'products_jiomeet',
        outcome: 'succeeded'
    },
    PRODUCT_ENTERPRISE_BOTTOM_CONTACT_SALES: {
        event: 'bottom_contact_sales',
        clicked_text: '',
        current_page: 'products_jiomeetenterprise',
        outcome: 'succeeded'
    },
    PRODUCT_JIOMEET_BOTTOM_SIGN_UP: {
        event: 'bottom_sign_up',
        clicked_text: '',
        current_page: 'products_jiomeet',
        outcome: 'succeeded'
    },
    PRODUCT_JIOMEET_BOTTOM_DOWNLOAD_APP: {
        event: 'bottom_download_app',
        clicked_text: '',
        current_page: 'products_jiomeet',
        outcome: 'succeeded'
    },
    PLAN_A_MEETING_CHAT_DISABLED: {
        event: 'jiomeet',
        clicked_text: 'disable_chat',
        current_page: 'plan_a_meeting',
        outcome: 'disabled'
    },
    PLAN_A_MEETING_CHAT_ENABLED: {
        event: 'jiomeet',
        clicked_text: 'disable_chat',
        current_page: 'plan_a_meeting',
        outcome: 'enabled'
    }
};
