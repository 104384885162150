import * as fromComponents from './components';
import { AuthGuard } from './core';
import { CheckForWebinarGuard } from './core/guards/check-for-webinar.guard';

// routes shared by other platforms

export const sharedRoutes = [
    {
        path: 'externallogin',
        component: fromComponents.ExternalloginComponent,
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'joinintv',
        component: fromComponents.JoinMeetingTvComponent,
        data: { showHeader: true, showFooter: true }
    },
    {
        path: 'app-download',
        component: fromComponents.DownloadComponent,
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'clustertest',
        component: fromComponents.ClusterLoadTestComponent,
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'help',
        loadChildren: () => import('./help/help.module').then((m) => m.HelpModule),
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'terms-condition-business',
        component: fromComponents.TncComponent,
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'privacy-policy-business',
        component: fromComponents.PrivacyPolicyComponent,
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'shortener',
        component: fromComponents.JoinCallComponent,
        data: { showHeader: true, showFooter: false, type: 'join' },
        canActivate: [CheckForWebinarGuard]
    },
    {
        path: 'openjiomeet',
        component: fromComponents.WebsiteComponent,
        data: { showHeader: true, showFooter: true, type: 'openjiomeet' },
        canActivate: []
    },
    {
        path: 'recents',
        component: fromComponents.DownloadsPageComponent,
        data: { showHeader: true, showFooter: false, type: 'recents' }
    },
    {
        path: 'addtojiomeet',
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        data: { showHeader: true, showFooter: false, type: 'add', redirectToLogin: true },
        CanActivate: [AuthGuard]
    },
    {
        path: 'authenticateurl',
        component: fromComponents.DownloadRecordingsComponent,
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'teams-bot',
        loadChildren: () => import('./teams-bot/teams-bot.module').then((m) => m.TeamsBotModule),
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'integrations',
        loadChildren: () => import('./integrations/integrations.module').then((m) => m.IntegrationsModule),
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'mst',
        loadChildren: () => import('./mst-integration/mst-integration.module').then((m) => m.MstIntegrationModule),
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'oauth2/authorize',
        component: fromComponents.OauthComponent,
        data: { showHeader: false, showFooter: false },
        canActivate: []
    },
    {
        path: 'oauth',
        component: fromComponents.OauthComponent,
        data: { showHeader: false, showFooter: false },
        canActivate: []
    },
    {
        path: 'outlook',
        loadChildren: () =>
            import('./outlook-integration/outlook-integration.module').then((m) => m.OutlookIntegrationModule),
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'activate',
        component: fromComponents.ActivateComponent,
        data: { showHeader: true }
    },
    {
        path: 'alreadyverified',
        component: fromComponents.AlreadyVerifiedComponent,
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'linkexpired',
        component: fromComponents.ActivationLinkExpiryComponent,
        data: { showHeader: true, showFooter: false }
    },
    {
        path: 'rc-chat',
        loadChildren: () => import('./rc-chat/chat.module').then((m) => m.ChatModule),
        data: { showHeader: false, showFooter: false }
    },
    {
        path: 'precalltesting',
        loadChildren: () => import('./pre-call-testing/pre-call-testing.module').then((m) => m.PreCallTestingModule),
        data: { showHeader: true, showFooter: false }
    }
];
