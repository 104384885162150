<div class="group-chat-conponent">
    <div
        class="header"
        [class.popout]="popout"
        [class.landscapeheader]="isLandscapeMode && isJMMeeting && isMobileDevice"
        [ngClass]="isMobileDevice ? 'header-mobile' : ''"
    >
        <div class="dropdown" *ngIf="!popout">
            <div
                class="dropdown-toggle"
                id="dropdownHeaderButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                *ngIf="meetingObj && !meetingObj.hideAllCallControls && !isMobileDevice"
            >
                <div class="icon-wrap">
                    <img alt="" class="arrow-gray" src="assets/img/arrow_down_icn.svg" />
                    <img alt="" class="arrow-white" src="assets/img/arrow_down_white_icn.svg" />
                </div>
            </div>
            <div class="mobile-back" *ngIf="isMobileDevice">
                <div class="icon-wrap">
                    <img alt="" src="assets/img/mobile_participants_back_btn.svg" (click)="closeView()" />
                </div>
            </div>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownHeaderButton">
                <div class="dropdown-item" (click)="closeView()">
                    <div class="text">{{ 'inside_call.chat.close' | translate }}</div>
                </div>
                <div class="dropdown-item" (click)="popoutChat()" *ngIf="!popout">
                    <div class="text">{{ 'inside_call.chat.pop_out' | translate }}</div>
                </div>
            </div>
        </div>
        <button *ngIf="popout" class="btn btn-primary" (click)="closeView()">
            {{ 'inside_call.chat.close' | translate }}
        </button>
        <div class="title" id="chatTitle" title="{{ chatTitle }}">{{ 'inside_call.chat.chat' | translate }}</div>

        <div
            class="disable-chat-block"
            (outside)="outsideChatDropdown()"
            (click)="toggleChatDropdown(isDropdownOpen)"
            *ngIf="isUserHostOrCohost && currentUser?.tenantId != null && !room?.advancedOptions?.isClassroomMode"
        >
            <div class="option-selected">
                <span class="chat-title">
                    <span class="disable-chat-text">
                        {{ 'inside_call.chat.disable_chat' | translate }}
                    </span>
                    <span class="selected-option">
                        {{ selectedChatOption }}
                    </span>
                </span>
                <span class="dropdown-arrow-down" *ngIf="!isDropdownOpen">
                    <img alt="" class="arrow-gray" src="assets/img/arrow_down_icn.svg" />
                </span>
                <span class="dropdown-arrow-up" *ngIf="isDropdownOpen">
                    <img alt="" class="arrow-gray" src="assets/images/arrow-up.svg" />
                </span>
            </div>
            <ul class="chat-disable-dropdown" *ngIf="isDropdownOpen">
                <li
                    *ngFor="let option of options"
                    [class.selected-option]="selectedChatOption === option.label"
                    (click)="selectOption(option.value)"
                >
                    {{ option.label }}
                </li>
            </ul>
        </div>
        <div (click)="switchToAttachmentSection()">
            <button
                id="fileBtn"
                class="fileCount btn btn-light"
                [class.disabled]="!(isAuthenticated && enableJioCloud)"
            >
                {{ 'inside_call.chat.files' | translate }} ({{ getAttachedFilesCount() }})
            </button>
        </div>
    </div>
    <div
        class="disabled-chat-toast"
        *ngIf="
            !roomStatus?.localParticipant?.chatStatus &&
            !showEnabledChatToast &&
            !room?.advancedOptions?.isClassroomMode
        "
    >
        {{ 'inside_call.chat.host-cohost-disabled-chat' | translate }}
    </div>
    <div
        class="enabled-chat-toast"
        id="mobile-chat-enable-toast"
        [style.display]="roomStatus?.localParticipant?.chatStatus && showEnabledChatToast ? 'block' : 'none'"
    ></div>
    <div
        class="messages full"
        #messages
        [ngClass]="{
            'messages-mobile': isMobileDevice,
            'add-top':
                (!roomStatus?.localParticipant?.chatStatus || showEnabledChatToast) &&
                !room?.advancedOptions?.isClassroomMode
        }"
    >
        <!-- [style.bottom]="bottomStyleReducer()" -->
        <div class="chat">
            <div class="chat-group" *ngFor="let group of chatGroupsByDate">
                <div class="date p-3">
                    <span [class.chatGroupDate]="isMobileDevice && isJMMeeting">{{ group.key | dateLabel }}</span>
                </div>
                <div *ngFor="let message of group?.value?.slice()?.reverse(); trackBy: trackMessages">
                    <div
                        class="chat-bubble"
                        *ngIf="!message?.messageComponent?.attachments?.length"
                        [class.right-aligned]="message.createdBy === getCurrentUserId()"
                    >
                        <div
                            class="content"
                            [class.landscapeContent]="isLandscapeMode && isJMMeeting && isMobileDevice"
                        >
                            <div class="avatar" *ngIf="message.createdBy !== getCurrentUserId()">
                                <span *ngIf="message?.createdBy.includes('gu')">
                                    <div
                                        appAvatarWithInitials
                                        [name]="message?.creatorsName"
                                        [class.isMobile]="isMobileDevice"
                                    ></div>
                                </span>
                                <img
                                    alt=""
                                    [src]="getProfilePic(message?.createdBy)"
                                    *ngIf="!message?.createdBy.includes('gu')"
                                />
                            </div>
                            <div class="block position-relative">
                                <div
                                    class="text px-3 pt-3 pb-3"
                                    [class.self]="getCurrentUserId() === message?.createdBy"
                                    [class.isMobileBlock]="isMobileDevice && isJMMeeting"
                                >
                                    <div class="name-wrap">
                                        <div class="name">
                                            <span class="text-in" *ngIf="getCurrentUserId() === message?.createdBy"
                                                >{{ 'inside_call.chat.you' | translate }}
                                            </span>
                                            <span class="text-in" *ngIf="getCurrentUserId() !== message?.createdBy">
                                                <div
                                                    class="text-truncate"
                                                    *ngIf="message?.createdBy?.includes('gu-')"
                                                    title="{{ message?.creatorsName }} {{ message?.creatorsLName }}"
                                                >
                                                    {{ message?.creatorsName }} {{ message?.creatorsLName }}
                                                </div>
                                                <div
                                                    class="text-truncate"
                                                    *ngIf="!message?.createdBy?.includes('gu-')"
                                                    title="{{ message?.creatorsName }} {{ message?.creatorsLName }}"
                                                >
                                                    {{ message?.creatorsName }} {{ message?.creatorsLName }}
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <!-- <div class="time">{{message?.cOn | date: 'HH:mm' | convert24to12format}}</div> -->
                                    <div
                                        [innerHTML]="message?.messageComponent?.text?.trim() | linkify | showdown"
                                        id="msg-area"
                                        class="msg-area"
                                    ></div>
                                </div>
                                <div
                                    [class.mobileTime]="
                                        isMobileDevice && isJMMeeting && getCurrentUserId() === message?.createdBy
                                    "
                                    class="time p-3"
                                >
                                    {{ message?.cOn | date : 'HH:mm' | convert24to12format }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="attachment-bubble"
                        *ngIf="message?.messageComponent?.attachments?.length"
                        [class.right-aligned]="message.createdBy === getCurrentUserId()"
                    >
                        <button id="downloadAnchor" #downloadAnchor style="display: none"></button>
                        <div class="content">
                            <div class="avatar" *ngIf="message.createdBy !== getCurrentUserId()">
                                <span *ngIf="message?.createdBy.includes('gu')">
                                    <div appAvatarWithInitials [name]="message?.creatorsName"></div>
                                </span>
                                <img
                                    alt=""
                                    [src]="getProfilePic(message?.createdBy)"
                                    *ngIf="!message?.createdBy.includes('gu')"
                                />
                            </div>
                            <div class="block position-relative">
                                <div
                                    class="text px-3 pt-3 pb-3"
                                    [class.self]="getCurrentUserId() === message?.createdBy"
                                >
                                    <div class="name-wrap">
                                        <div class="name">
                                            <span class="text-in" *ngIf="getCurrentUserId() === message?.createdBy"
                                                >You
                                            </span>
                                            <span class="text-in" *ngIf="getCurrentUserId() !== message?.createdBy">
                                                <div
                                                    class="text-truncate"
                                                    title="{{ message?.creatorsName }} {{ message?.creatorsLName }}"
                                                >
                                                    {{ message?.creatorsName }} {{ message?.creatorsLName }}
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <!-- <div class="time">{{message?.cOn | date: 'HH:mm' | convert24to12format }}</div> -->
                                    <div
                                        class="moreOptions pointer"
                                        *ngIf="isAuthenticated && enableDownloadPreview && !message?.msgDeleted"
                                        (click)="toggleMoreOptions(message)"
                                    >
                                        <div class="dropdown" outside (outside)="clickOutside(message)">
                                            <img alt="" src="assets/images/moreOptionsIcon.svg" />
                                            <div class="custom-dropdown-menu" *ngIf="message?.showMoreOptions">
                                                <div class="dropdown-item pointer" (click)="fileInfo(message)">
                                                    <img
                                                        alt=""
                                                        style="width: 20px; height: 20px; margin-right: 2px"
                                                        src="assets/images/file_info.svg"
                                                    />
                                                    {{ 'inside_call.chat.files_info' | translate }}
                                                </div>
                                                <div
                                                    class="dropdown-item pointer"
                                                    (click)="deleteItem(message)"
                                                    *ngIf="getCurrentUserId() === message?.createdBy"
                                                >
                                                    <img alt="" src="assets/images/deletIcon.svg" />
                                                    {{ 'inside_call.chat.delete' | translate }}
                                                </div>
                                                <div class="dropdown-item pointer" (click)="downloadFile(message)">
                                                    <img alt="" src="assets/images/downloadIcon.svg" />
                                                    {{ 'inside_call.chat.download' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="msg-area" [class.self]="getCurrentUserId() === message?.createdBy">
                                        <div *ngIf="message?.msgDeleted">
                                            <i id="msg-area" style="word-break: break-word">{{
                                                'inside_call.chat.this_message_has_been_deleted' | translate
                                            }}</i>
                                        </div>
                                        <div *ngIf="!message?.msgDeleted">
                                            <div
                                                [innerHTML]="
                                                    message?.messageComponent?.text?.trim() | linkify | showdown
                                                "
                                                id="msg-area"
                                                class="msg-area"
                                            ></div>
                                        </div>

                                        <div *ngIf="!message?.msgDeleted">
                                            <div
                                                *ngFor="
                                                    let attachmnt of message?.messageComponent?.attachments;
                                                    trackBy: trackByFuntion
                                                "
                                            >
                                                <app-chat-attachment
                                                    [message]="message"
                                                    [showAttachments]="canDownloadOrPreviewAttachments()"
                                                    [item]="attachmnt"
                                                    [jiomeetId]="message?.threadId"
                                                ></app-chat-attachment>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="time p-3">
                                    {{ message?.cOn | date : 'HH:mm' | convert24to12format }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="attachments"
        [ngClass]="isMobileDevice ? 'attachments-mobile' : ''"
        [class.disabled]="showDisabledChatToast && !room?.advancedOptions?.isClassroomMode"
        (resized)="onResized($event)"
        *ngIf="
            !(
                room?.advancedOptions?.isClassroomMode &&
                !(roomStatus?.localParticipant?.isHost || roomStatus?.localParticipant?.isCoHost)
            )
        "
    >
        <ng-container *ngIf="showPrivateChat">
            <div class="to-panel" *ngIf="roomStatus?.participants?.length > 2 && !(isJMMeeting && isLargeMeeting)">
                <strong>{{ 'inside_call.chat.to' | translate }}</strong>
                <div class="participants-dropdown pointer" outside (outside)="onOutsideClick($event)">
                    <div
                        class="selected position-relative"
                        (click)="toggleDropdown()"
                        (window:resize)="setDropdownHeight()"
                    >
                        <span>{{ 'inside_call.chat.all' | translate }}</span>
                        <img alt="" src="assets/img/revamp/down_arrow_icn.svg" alt="" />
                    </div>
                    <div class="dropdown" *ngIf="dropdown">
                        <div class="search-box">
                            <input
                                type="text"
                                name=""
                                id=""
                                placeholder="{{ 'inside_call.chat.search_participants' | translate }}"
                                #participantSearchInput
                            />
                            <img alt="" src="assets/images/search_icn.svg" />
                        </div>
                        <ng-container
                            *ngIf="
                                roomParticipants
                                    | filterContactByKey
                                        : participantSearchInput.value
                                        : ['participantName'] as participants
                            "
                        >
                            <div class="position-relative" *ngFor="let user of participants; trackBy: trackByFuntion">
                                <div
                                    class="participant-item selected"
                                    *ngIf="user.userId === null"
                                    (click)="dropdown = !dropdown"
                                >
                                    <span>{{ 'inside_call.chat.all' | translate }}</span>
                                    <img alt="" src="assets/img/revamp/tick_black.svg" alt="" style="opacity: 0.5" />
                                </div>
                                <div
                                    class="participant-item"
                                    *ngIf="
                                        user.userId !== null &&
                                        (this.currentUser?._id || this.guestUser?.guestUserId) !== user.userId
                                    "
                                    (click)="switchToPrivateChat({ _id: user.userId, name: user.participantName })"
                                >
                                    <span class="text-truncate" [title]="user.participantName">{{
                                        getParticipantName(user)
                                    }}</span>
                                    <div
                                        class="new-message"
                                        *ngIf="unreadChatParticipantIds.includes(user.userId)"
                                    ></div>
                                </div>
                            </div>
                            <div class="no-data" *ngIf="!participants.length">
                                <em>{{ 'inside_call.chat.no_participants_found' | translate }}</em>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="chatAttachments" [ngClass]="isMobileDevice ? 'chat-attachments-mobile' : ''">
            <app-chat-markdown
                [showMarkdown]="showMarkdown"
                [selectedEmoji]="selectedEmoji"
                (sendmessage)="sendMessage(null, null)"
            >
            </app-chat-markdown>
        </div>

        <div class="uploaded-docs">
            <div
                class="attached-files-container"
                *ngFor="let attachmentObj of uploadedItems | keyvalue; trackBy: trackByFuntion"
            >
                <app-upload-attachment
                    [item]="attachmentObj?.value"
                    (remove)="removeAttchment($event, attachmentObj?.value)"
                >
                </app-upload-attachment>
                <div
                    class="progres"
                    *ngIf="!attachmentObj?.value?.uploadSuccess"
                    [style.width.%]="attachmentObj?.value?.progress || 0"
                ></div>
            </div>
        </div>

        <div>
            <hr *ngIf="!isMobileDevice" />
        </div>
        <label for="markdown" class="pointer mt-2" *ngIf="enableMarkdown" (click)="toggleMarkdown()">
            <span *ngIf="!showMarkdown" [class.landscapeMarkdown]="isLandscapeMode && isJMMeeting && isMobileDevice"
                ><img class="inputMessageDeco" alt="" src="assets/img/mobile_chat_text_format.svg" />

                <span class="markdown-title" *ngIf="isLandscapeMode && isJMMeeting && isMobileDevice">Format Text</span>
            </span>
            <span
                *ngIf="showMarkdown"
                class="inputMessageDecoSelected"
                [class.landscapeMarkdown]="isLandscapeMode && isJMMeeting && isMobileDevice"
            >
                <img
                    class="inputMessageDeco inputMessageDecoSelected"
                    alt=""
                    src="assets/img/mobile_chat_text_format.svg"
                />
                <span class="markdown-title" *ngIf="isLandscapeMode && isJMMeeting && isMobileDevice">Format Text</span>
            </span>
        </label>
        <label for="upload" *ngIf="isAuthenticated && enableJioCloud" class="ml-3">
            <!-- <img alt="" title="Upload Files" src="assets/images/attachmentIcon.svg" id="attachmentIcon" #attachmentIcon aria-hidden="true" class="pointer"> -->
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.08"
                height="11.65"
                viewBox="0 0 13.08 11.65"
                id="attachmentIcon"
                #attachmentIcon
                aria-label="Upload Files"
                tabindex="0"
                role="button"
                aria-hidden="true"
                class="pointer"
            >
                <defs>
                    <style>
                        .a {
                            fill: #666666;
                        }
                    </style>
                </defs>
                <path
                    class="a"
                    d="M1669.861,7164.041l3.859-3.858a3.916,3.916,0,0,0-.145-5.657,4.192,4.192,0,0,0-2.9-1.294h-.047a3.809,3.809,0,0,0-2.7,1.149l-5.229,5.229a2.946,2.946,0,0,0,.048,4.306,3.067,3.067,0,0,0,2.183.966h.034a3.012,3.012,0,0,0,2.089-.918l3.974-3.975a2.186,2.186,0,0,0,.686-1.416,2.175,2.175,0,0,0-2.175-2.176,2.2,2.2,0,0,0-1.417.686l-2.6,2.6a.349.349,0,0,0,.247.6h0a.349.349,0,0,0,.246-.1l2.6-2.6a1.536,1.536,0,0,1,.964-.483,1.28,1.28,0,0,1,1,.434,1.3,1.3,0,0,1,.434,1.005,1.527,1.527,0,0,1-.483.962l-3.974,3.976a2.317,2.317,0,0,1-1.6.712h-.025a2.385,2.385,0,0,1-1.69-.762,2.223,2.223,0,0,1-.048-3.319l5.229-5.229a3.134,3.134,0,0,1,2.221-.944h.031a3.458,3.458,0,0,1,2.419,1.089,3.2,3.2,0,0,1,.144,4.671l-3.859,3.859a.348.348,0,0,0,0,.493A.348.348,0,0,0,1669.861,7164.041Z"
                    transform="translate(-1661.778 -7153.231)"
                />
            </svg>
            <input
                #uploadFiles
                type="file"
                id="upload"
                style="display: none"
                (change)="fileChangeEvent($event)"
                (click)="resetLastSelectedFileValue($event)"
            />
        </label>
        <label for="emoji" class="pointer ml-1 emoji-switch" [class.selected]="selectedEmoji" *ngIf="showEmojiBar">
            <span
                (click)="toggleEmojis($event)"
                [class.inputMessageDecoSelected]="selectedEmoji"
                [class.landscapeMarkdown]="isLandscapeMode && isJMMeeting && isMobileDevice"
            >
                <img
                    alt=""
                    src="assets/img/mobile_chat_default_emoji.svg"
                    alt=""
                    class="inputMessageDeco"
                    [class.inputMessageDecoSelected]="selectedEmoji"
                    *ngIf="isMobileDevice"
                />
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.45"
                    height="12.45"
                    viewBox="0 0 12.45 12.45"
                    *ngIf="!isMobileDevice"
                >
                    <defs>
                        <style>
                            .a {
                                fill: #666;
                            }

                            .b,
                            .c {
                                fill: none;
                                stroke: #666;
                                stroke-miterlimit: 10;
                                stroke-width: 0.8px;
                            }

                            .c {
                                stroke-linecap: round;
                            }
                        </style>
                    </defs>
                    <g transform="translate(-858.567 -674.6)">
                        <path
                            class="a"
                            d="M992.882,3394.735a.749.749,0,1,1-.749-.75.749.749,0,0,1,.749.75"
                            transform="translate(-129.074 -2715.52)"
                        />
                        <path
                            class="a"
                            d="M1016.429,3394.735a.749.749,0,1,1-.75-.75.749.749,0,0,1,.75.75"
                            transform="translate(-149.031 -2715.52)"
                        />
                        <circle class="b" cx="5.825" cy="5.825" r="5.825" transform="translate(858.967 675)" />
                        <path
                            class="c"
                            d="M993.347,3420.9a2.688,2.688,0,0,0,4.488,0"
                            transform="translate(-130.739 -2738.332)"
                        />
                    </g>
                </svg>
                <span class="markdown-title" *ngIf="isLandscapeMode && isJMMeeting && isMobileDevice">Emoji</span>
            </span>
            <!-- <img alt="" src="assets/img/emoji_select.svg" alt="" (click)="toggleEmojis($event)"> -->
        </label>

        <span
            class="sendBtn pointer"
            [ngClass]="isLandscapeMode && isJMMeeting && isMobileDevice ? 'mt-2' : ''"
            [class.landscapeMarkdown]="isLandscapeMode && isJMMeeting && isMobileDevice"
            (click)="sendMessage(null, messageToSend)"
        >
            <span class="markdown-title mr-1" *ngIf="isLandscapeMode && isJMMeeting && isMobileDevice">Send</span>
            <img class="sendBtn-img" alt="" src="assets/img/mobile_chat_send_new.svg" alt="" *ngIf="isMobileDevice" />
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.543"
                height="17.683"
                viewBox="0 0 14.543 12.683"
                *ngIf="!isMobileDevice"
            >
                <defs>
                    <style>
                        .a {
                            fill: #666;
                        }
                    </style>
                </defs>
                <g transform="translate(-268.5 -78.5)">
                    <path
                        class="a"
                        d="M2124.9,2226.041l10.362,4.533-12.054,5.277,1.466-4.91c0-.005,10.809,0,10.809,0a3.016,3.016,0,0,0,0-.367c-.015-.026-.46-.367-.46-.367s-10.348-.065-10.348-.065l-1.466-4.817m-.621-1.092a.4.4,0,0,0-.334.622l1.691,5.72c.093.134.093-.135,0,0l-1.691,5.719a.4.4,0,0,0,.334.622.43.43,0,0,0,.159-.032l13.727-5.942a.394.394,0,0,0,0-.735l-13.727-5.942a.421.421,0,0,0-.159-.032"
                        transform="translate(-1853.682 -2145.732)"
                    />
                </g>
            </svg>
        </span>
    </div>
    <div
        class="attachments"
        (resized)="onResized($event)"
        *ngIf="
            room?.advancedOptions?.isClassroomMode &&
            !(roomStatus?.localParticipant?.isHost || roomStatus?.localParticipant?.isCoHost)
        "
    >
        <div class="restriction-msg text-center">{{ 'inside_call.chat.restricted_chatting' | translate }}</div>
    </div>
</div>
<!-- <div class="chat-toast-container"> -->
<ng-template viewHost></ng-template>
<!-- </div> -->
