<div class="external-login">
    <div class="header">
        <div class="logo-wrap pl-3">
            <img alt="" src="assets/img/logo/jm-logo-blue.svg" />
        </div>
    </div>
    <div class="section">
        <div class="box">
            <h2 class="title">Login with {{ providerName | titlecase }}</h2>
            <div class="text pt-3 px-2">JioMeet should launch in few seconds. If not, please click button below.</div>
            <div class="action pt-5">
                <button type="button" class="btn btn-primary btn-lg pointer" (click)="launch()">Launch JioMeet</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="iosMacDeeplink">
    <iframe title="" ref="iframe" [src]="iosMacDeeplink | safe"></iframe>
</div>
