<div class="page container-fluid">
    <div class="row h-100">
        <div class="left-content col-xl-7 col-lg-7 col-md-12 col-sm-12">
            <div class="slider" #carousel>
                <div class="slide slide-1">
                    <div class="header text-center">{{ 'onboarding.plan_meetings' | translate }}</div>
                    <div class="img-wrap">
                        <app-lottie-renderer path="assets/lottie/Plan a meeting.json"></app-lottie-renderer>
                    </div>
                </div>
                <div class="slide slide-2">
                    <div class="header text-center">{{ 'onboarding.join_meetings' | translate }}</div>
                    <div class="img-wrap">
                        <app-lottie-renderer path="assets/lottie/Join a meeting animation.json"></app-lottie-renderer>
                    </div>
                </div>
                <div class="slide slide-3">
                    <div class="header text-center">{{ 'onboarding.add_invite' | translate }}</div>
                    <div class="img-wrap">
                        <app-lottie-renderer path="assets/lottie/Laptop Animation.json"></app-lottie-renderer>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-content col-xl-5 col-lg-5 col-md-12 col-sm-12 pt-5">
            <div class="component-wrap">
                <div class="form-wrap">
                    <section>
                        <h3 class="mb-3">
                            <div>
                                {{ 'onboarding.hi' | translate }} {{ currentUser?.name }} {{ currentUser?.lname || '' }}
                            </div>
                            <div>{{ 'onboarding.welcome' | translate }}</div>
                        </h3>
                        <div class="content text-center">
                            <div class="text text-center mb-3">{{ 'onboarding.your_high_quality' | translate }}</div>
                            <div class="action-btns">
                                <button type="button" class="custom-button bg-primary" (click)="navigateToDashboard()">
                                    {{ 'onboarding.go_to_dashboard' | translate }}
                                </button>
                            </div>
                            <div class="app-links">
                                <div class="download-text mb-3">{{ 'onboarding.experience_the_best' | translate }}</div>
                                <div class="link-group">
                                    <div
                                        class="link pointer"
                                        *ngIf="os === 'Android'"
                                        (click)="navigateToAppStore('android')"
                                    >
                                        <img alt="" src="assets/img/website/play-store.png" />
                                    </div>
                                    <div class="link pointer" *ngIf="os === 'iOS'" (click)="navigateToAppStore('ios')">
                                        <img alt="" src="assets/img/website/ios.png" />
                                    </div>
                                </div>
                                <div class="link-group">
                                    <div class="link pointer" *ngIf="os === 'Windows'" (click)="navigateToDownload()">
                                        <img alt="" src="assets/img/website/windows.png" />
                                    </div>
                                    <div class="link pointer" *ngIf="os === 'Mac OS'" (click)="navigateToMacAppStore()">
                                        <img alt="" src="assets/img/website/app-store.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div class="add-in" *ngIf="!isMobile && isOutlookAddInEnabled">
                    <div class="content">
                        <div class="icon-wrap">
                            <img alt="" src="assets/img/outlook-icon.png" />
                        </div>
                        <div class="text">
                            {{ 'onboarding.outlook_addin' | translate }}
                        </div>
                    </div>
                    <div class="link">
                        <a class="blue pointer underline" (click)="getOutlookAddIn()">{{
                            'onboarding.get_button' | translate
                        }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
