<div class="page container-fluid" *ngIf="loading || samlLoginInProgress">
    <app-loader></app-loader>
</div>
<div class="page container-fluid">
    <div class="row h-100">
        <div class="left-content">
            <div class="img-wrap">
                <div *ngIf="!ottLogin" class="renderer" (click)="enableDeveloperMode()">
                    <div class="head" tabindex="0">{{ 'signin_page.head' | translate }}</div>
                    <img
                        class="signin-banner"
                        *ngIf="!bannersConfigData?.showHomePageBanner"
                        src="assets/img/signin.svg"
                    />
                    <app-lottie-renderer
                        style="margin-left: 25%"
                        *ngIf="bannersConfigData?.showHomePageBanner"
                        [path]="bannersConfigData?.homePageBannerURL"
                    >
                    </app-lottie-renderer>
                </div>
                <app-lottie-renderer
                    *ngIf="ottLogin && bannersConfigData?.showHomePageBanner"
                    [class.banner-position]="bannersConfigData?.showHomePageBanner"
                    [path]="bannersConfigData?.homePageBannerURL"
                >
                </app-lottie-renderer>
                <app-lottie-renderer
                    *ngIf="ottLogin && !bannersConfigData?.showHomePageBanner"
                    path="assets/lottie/Wrong OTP animation.json"
                ></app-lottie-renderer>
            </div>
        </div>
        <div class="right-content">
            <div class="component-wrap pb-3">
                <div class="form-wrap" *ngIf="!loading && !samlLoginInProgress">
                    <section>
                        <h3
                            tabindex="0"
                            class="headingfocus"
                            aria-label="{{ 'signin_page.title' | translate }}"
                            role="group"
                        >
                            {{ 'signin_page.title' | translate }}
                        </h3>
                        <div class="close" (click)="close()">
                            <img alt="" src="assets/img/close_icon.svg" />
                        </div>
                        <form *ngIf="!emailLogin && !ottLogin" [formGroup]="usernameForm" class="custom-form">
                            <div class="form-group" tabindex="0">
                                <label>{{ 'signin_page.emailid/mobile' | translate }}</label>
                                <input
                                    #emailOrMobileNumberInput
                                    type="text"
                                    tabindex="0"
                                    aria-label="{{ 'signin_page.emailid/mobile' | translate }}"
                                    class="form-control main-focus"
                                    id="username"
                                    formControlName="username"
                                    aria-describedby="usernameHelp"
                                    [class.is-invalid]="errors.username || userNotFound"
                                    (keyup.enter)="submitUsername()"
                                />
                                <span class="d-inline-block mt-2 invalid-feedback" *ngIf="errors.username">{{
                                    errors.username
                                }}</span>
                                <span class="d-inline-block mt-2 text-error" *ngIf="errors.global">{{
                                    errors.global
                                }}</span>
                                <div class="no-user" *ngIf="userNotFound">
                                    <span tabindex="0" class="invalidemailerror text-error">{{
                                        'signin_page.wecouldnt' | translate
                                    }}</span>
                                    <a
                                        class="text-primary ml-2 pointer"
                                        role="link"
                                        type="link"
                                        (click)="navigateToSignUp()"
                                    >
                                    </a>
                                </div>
                            </div>
                            <div class="col p-0 w-100 mt-4">
                                <button
                                    type="button"
                                    id="proceedButton"
                                    tabindex="0"
                                    class="custom-button bg-primary"
                                    [class.disabled]="!enableProceedBtn"
                                    (click)="submitUsername()"
                                >
                                    {{ (loggingIn ? 'signin_page.pleasewait' : 'signin_page.proceed') | translate }}
                                </button>
                            </div>
                            <div
                                class="col p-0 w-100 mt-4"
                                *ngIf="
                                    desktopAppService?.isDesktopApp && appService.config.ShowEnvironmentSelectionPopup
                                "
                            >
                                <app-environment-selection></app-environment-selection>
                            </div>
                        </form>

                        <form
                            *ngIf="emailLogin"
                            [formGroup]="loginForm"
                            (submit)="submitEmailPassword()"
                            class="custom-form"
                        >
                            <div class="form-group">
                                <label for="email" [class.invalid]="errors.global">{{
                                    'signin_page.emailaddress' | translate
                                }}</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="email"
                                    formControlName="email"
                                    aria-describedby="emailHelp"
                                    [class.is-invalid]="errors.email || userNotFound"
                                    [class.invalid]="errors.global"
                                    placeholder="{{ 'signin_page.enteremail' | translate }}"
                                />
                                <span class="invalid-feedback mr-2">{{ errors.email }}</span>
                            </div>
                            <div class="form-group" *ngIf="!isDomainEmail">
                                <label for="password" [class.invalid]="errors.global">{{
                                    'signin_page.password' | translate
                                }}</label>
                                <div id="passwordfield">
                                    <input
                                        [type]="showPassword ? 'text' : 'password'"
                                        class="form-control"
                                        id="password"
                                        #password
                                        formControlName="password"
                                        [class.is-invalid]="errors.password"
                                        [class.invalid]="errors.global"
                                        placeholder="{{ 'signin_page.password' | translate }}"
                                        maxlength="20"
                                    />
                                    <div class="eye position-relative">
                                        <img
                                            tabindex="0"
                                            [attr.aria-label]="showPassword ? 'show password' : 'hide password'"
                                            (keydown.enter)="togglePassword()"
                                            alt=""
                                            src="{{
                                                showPassword
                                                    ? 'assets/img/show_password.svg'
                                                    : 'assets/img/hide_password.svg'
                                            }}"
                                            (click)="togglePassword()"
                                            class="eye"
                                        />
                                    </div>
                                    <div>
                                        <span
                                            class="d-inline-block mt-2 invalid-feedback mr-2"
                                            *ngIf="errors.password"
                                            >{{ errors.password }}</span
                                        >
                                        <span
                                            tabindex="0"
                                            class="errorfocus d-inline-block mt-2 text-error mr-2"
                                            *ngIf="errors.global"
                                            >{{ errors.global }}</span
                                        >
                                    </div>
                                    <a
                                        class="d-inline-block blue pointer mt-2"
                                        tabindex="0"
                                        aria-label="{{ 'signin_page.forgot' | translate }}"
                                        (keyup.enter)="navToForgotPassword()"
                                        (click)="navToForgotPassword()"
                                        >{{ 'signin_page.forgot' | translate }}</a
                                    >
                                </div>
                            </div>
                            <button
                                type="submit"
                                id="signinButton"
                                class="custom-button bg-primary my-4"
                                [class.disabled]="
                                    (!isDomainEmail && !loginForm.valid) ||
                                    (isDomainEmail && loginForm.controls.email.errors) ||
                                    loggingIn
                                "
                            >
                                {{ (loggingIn ? 'signin_page.pleasewait' : 'signin_page.signin') | translate }}
                            </button>
                        </form>

                        <form
                            *ngIf="ottLogin && !requestingOTP"
                            [formGroup]="ottForm"
                            (submit)="verifyOTP()"
                            class="custom-form"
                        >
                            <div class="form-group position-relative">
                                <label for="mobileno">
                                    {{ 'signin_page.mobileno' | translate }}
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="mobileno"
                                    formControlName="phoneNo"
                                    aria-describedby="phoneNoHelp"
                                    [class.is-invalid]="errors.phoneNo || userNotFound"
                                    placeholder="{{ 'signin_page.mobileno' | translate }}"
                                    disabled
                                />
                                <span class="invalid-feedback">{{ errors.phoneNo }}</span>
                            </div>
                            <div class="form-group">
                                <div id="passwordfield">
                                    <label for="otp" class="input-control">
                                        {{ 'signin_page.enterotp' | translate }}
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="otp"
                                        #password
                                        formControlName="otp"
                                        aria-live="assertive"
                                        [class.is-invalid]="errors.otp"
                                        placeholder="{{ 'signin_page.enterotp' | translate }}"
                                        maxlength="6"
                                    />
                                    <span
                                        tabindex="0"
                                        class="otperror d-inline-block mt-2 invalid-feedback resend-otp mr-2"
                                        *ngIf="errors.otp"
                                        >{{ errors.otp }}</span
                                    >
                                    <span class="d-inline-block mt-2 text-error mr-2" *ngIf="errors.global">{{
                                        errors.global
                                    }}</span>
                                    <div>
                                        <a
                                            tabindex="0"
                                            aria-label="{{ 'signin_page.resend' | translate }}"
                                            (keyup.enter)="getOTP()"
                                            class="pointer blue d-inline resend-otp"
                                            (click)="getOTP()"
                                            >{{ 'signin_page.resend' | translate }}</a
                                        >
                                    </div>
                                </div>
                            </div>
                            <button
                                type="submit"
                                id="verifyButton"
                                class="custom-button bg-primary my-4"
                                [class.disabled]="disableOttLoginForm()"
                            >
                                {{ (loggingIn ? 'signin_page.pleasewait' : 'signin_page.signin') | translate }}
                            </button>
                            <!-- <div class="sign-up-text mt-3 text-center" *ngIf="ottLogin">
                <span class="acc-info mr-1">{{ 'signin_page.dont_have_an_account' | translate }}
                </span>
                <span class="link">
                  <a class="text-primary" (click)="navigateToSignUp()">{{
                    'signin_page.signup' | translate
                    }}</a>
                </span> -->
                            <!-- <span>{{'signin_page.haventreceived' | translate}} </span> -->
                            <!-- <span> {{'signin_page.or' | translate}} </span> -->
                            <!-- <span><a class="pointer blue d-inline" (click)="userAnotherEmail()">{{'signin_page.usedifferent' | translate}}</a></span> -->
                            <!-- </div> -->
                        </form>

                        <form
                            *ngIf="ottLogin && requestingOTP"
                            [formGroup]="ottForm"
                            (submit)="getOTP()"
                            class="custom-form"
                        >
                            <div class="form-group position-relative">
                                <input
                                    type="text"
                                    id="phoneNo"
                                    class="form-control"
                                    id="email"
                                    formControlName="phoneNo"
                                    aria-describedby="phoneNoHelp"
                                    [class.is-invalid]="errors.phoneNo"
                                    placeholder="Mobile No"
                                />
                                <span class="d-inline-block mt-2 invalid-feedback" *ngIf="errors.phoneNo">{{
                                    errors.phoneNo
                                }}</span>
                                <span class="d-inline-block mt-2 text-error" *ngIf="errors.global">{{
                                    errors.global
                                }}</span>
                            </div>
                            <button
                                type="submit"
                                id="getOtpButton"
                                class="custom-button bg-primary my-4"
                                [class.disabled]="!ottForm.valid"
                            >
                                {{ (loggingIn ? 'signin_page.pleasewait' : 'signin_page.getotp') | translate }}
                            </button>
                        </form>

                        <div class="sign-up-text mt-4">
                            <div>
                                <span class="acc-info mr-1" tabindex="0"
                                    >{{ 'signin_page.dont_have_an_account' | translate }}
                                </span>
                                <span class="link">
                                    <a
                                        class="text-primary"
                                        role="link"
                                        type="link"
                                        (click)="navigateToSignUp()"
                                        (keyup.enter)="navigateToSignUp()"
                                        tabindex="0"
                                        aria-label="{{ 'signin_page.signup' | translate }}"
                                        ><u>{{ 'signin_page.signup' | translate }}</u></a
                                    >
                                </span>
                            </div>
                        </div>
                    </section>
                    <!-- <section class="social-login mt-4">
                        <div class="separator">
                            <span>{{ 'signin_page.or' | translate }}</span>
                        </div>
                        <div class="social-login-btns">
                            <div class="social-login-btn pointer" routerLink="/sso" style="display: none">
                                <div class="icon-wrap">
                                    <img alt="" src="assets/img/key_icn.png" />
                                </div>
                                <div class="text">{{ 'signin_page.sign_in_with_company_domain' | translate }}</div>
                            </div>
                            <div
                                class="social-login-btn pointer fb"
                                (click)="loginWithSSO('facebook')"
                                *ngIf="fbVisibility"
                            >
                                <button type="button" class="custom-button bg-primary logo">
                                    <img src="assets/img/fb-logo.svg" />
                                    <div>{{ 'signin_page.facebook' | translate }}</div>
                                </button>
                            </div>
                            <div
                                class="social-login-btn pointer"
                                (click)="loginWithSSO('google')"
                                *ngIf="googleVisibility"
                            >
                                <button type="button" class="custom-button bg-primary logo">
                                    <img src="assets/img/g-logo.svg" />
                                    <div>{{ 'signin_page.google' | translate }}</div>
                                </button>
                            </div> -->
                    <!-- </div> -->
                    <!-- <div class="disclaimer text-center">
              By signing up, I agree to the
              <a class="pointer blue" (click)="launchPrivacy()">privacy policy</a>
              and
              <a class="pointer blue" (click)="launchTnc()">terms of service</a>
            </div> -->
                    <!-- </section> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="loader-overlay" *ngIf="isLoading">
    <div class="loader">
        <img alt="" src="assets/img/website/website_logo_header.svg" />
        <div class="spinner-div">
            <div class="loading-spinner"></div>
        </div>
        <div class="loader-footer">
            <div class="loader-footer-text-top">{{ 'loader.redirecting' | translate }}</div>
            <div class="loader-footer-text-bottom">{{ 'loader.do_not_press_back' | translate }}</div>
        </div>
    </div>
</div>
<div class="loader-overlay" *ngIf="showPopup">
    <div class="address-loader">
        <img alt="" src="assets/img/website/website_logo_header.svg" />
        <div class="form-group autoFillDiv">
            <label class="label">{{ 'payment-page.name' | translate }}</label>
            <input type="text" class="text" placeholder="{{ userName }}" [disabled]="true" />
        </div>
        <div class="form-group autoFillDiv">
            <label class="label">{{ 'payment-page.emailId' | translate }}</label>
            <input type="text" class="text" placeholder="{{ userEmail }}" [disabled]="true" />
        </div>
        <div class="fillDiv">
            <div class="form-group pincode">
                <label class="label">{{ 'payment-page.pincode' | translate }}</label>
                <input
                    type="text"
                    class="text"
                    maxlength="6"
                    autofocus
                    placeholder="{{ pincode }}"
                    (blur)="onInputPincode($event.target.value)"
                    (keyup)="onInputPincode($event.target.value, $event)"
                />
            </div>
            <div class="form-group city">
                <label class="label">{{ 'payment-page.city' | translate }}</label>
                <input
                    type="text"
                    class="text"
                    [(ngModel)]="city"
                    (blur)="onInputCity($event.target.value)"
                    (keyup)="onInputCity($event.target.value)"
                />
            </div>
            <div class="form-group state">
                <label class="label">{{ 'payment-page.state' | translate }}</label>
                <input
                    type="text"
                    class="text"
                    [(ngModel)]="state"
                    (blur)="onInputState($event.target.value)"
                    (keyup)="onInputState($event.target.value)"
                />
            </div>
        </div>
        <div *ngIf="invalidPincode" class="label error-pincode">{{ 'payment-page.invalid_pincode' | translate }}</div>
        <div *ngIf="showPincodeError" class="label error-pincode">{{ 'payment-page.pincode_error' | translate }}</div>
        <div class="buttons">
            <button class="cancel-button" (click)="closePopupWindow()">{{ 'payment-page.cancel' | translate }}</button>
            <button
                [ngClass]="paymentButtonDisabled ? 'payment-button-disabled' : 'payment-button-enabled'"
                (click)="submitPayment()"
            >
                {{ 'payment-page.proceed_to_payment' | translate }}
            </button>
        </div>
    </div>
</div>
