import {
    AfterViewInit,
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { HostDirective } from 'src/app/shared/directives/host.directive';
import { AudioSettingsComponent } from './audio-settings/audio-settings.component';
import { VideoSettingsComponent } from './video-settings/video-settings.component';
import { ProfilesettingsComponent } from './profilesettings/profilesettings.component';
import { PmisettingsComponent } from './pmisettings/pmisettings.component';
import { LanguagesettingsComponent } from './languagesettings/languagesettings.component';
import { NotificationsSettingsComponent } from './notifications-settings/notifications-settings.component';
import {
    AppService,
    AuthService,
    ThirdPartyExternalIntegrationService,
    UtilService,
    UserService,
    RtcService,
    GoogleTagManagerService,
    SystemService,
    EventEmitterService
} from 'src/app/core';
import { LivestreamingSettingsComponent } from './livestreaming-settings/livestreaming-settings.component';
import { VirtualBackgroundComponent } from './virtual-background/virtual-background.component';
import { LiveStreamSettingsComponent } from './live-stream-settings/live-stream-settings.component';
import { GA_EVENTS } from './ga-events';
import { APP_EVENTS, KEYBOARD_CONSTANT } from 'src/app/constants';
import { GA_NEW } from '../../ga-new';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';

@Component({
    selector: 'app-call-settings',
    templateUrl: './call-settings.component.html',
    styleUrls: ['./call-settings.component.scss']
})
export class CallSettingsComponent implements AfterViewInit {
    @Input() selectedLabel: string;
    @ViewChild(HostDirective) viewHost: HostDirective;
    @Output() afterClose: EventEmitter<any> = new EventEmitter();
    public isMobileDevice;
    public isThirdPartyExternalIntegration;
    currentUser: any = {};
    isMyjioIntegration: boolean = false;
    inIFrame: boolean = false;
    isLiveStreamEnabled: boolean = false;
    isNameUpdated: boolean;
    isOTT: boolean;
    user;

    public factoryComponentRef: ComponentRef<
        | AudioSettingsComponent
        | VideoSettingsComponent
        | ProfilesettingsComponent
        | PmisettingsComponent
        | LanguagesettingsComponent
        | NotificationsSettingsComponent
        | LivestreamingSettingsComponent
        | LiveStreamSettingsComponent
    >;
    public tabs: any[] = [];
    private authenticatedTabs: any[] = [
        {
            name: 'Profile',
            label: 'profile',
            tkey: 'joinmeeting_page_or_joincall.profile',
            img: 'assets/images/profile_not_selected.svg',
            altImg: 'assets/images/profile_selected.svg',
            settingRef: (): ComponentFactory<ProfilesettingsComponent> =>
                this.compFactory.resolveComponentFactory(ProfilesettingsComponent)
        },
        {
            name: 'Personal Meeting ID',
            label: 'personal meeting id',
            tkey: 'joinmeeting_page_or_joincall.meetingId',
            img: 'assets/images/pmi_not_selected.svg',
            altImg: 'assets/images/pmi_selected.svg',
            settingRef: (): ComponentFactory<PmisettingsComponent> =>
                this.compFactory.resolveComponentFactory(PmisettingsComponent)
        }
        // {
        //   name: 'Live Streaming',
        //   label: 'live streaming',
        //   img: 'assets/images/pmi_not_selected.svg',
        //   altImg: 'assets/images/pmi_selected.svg',
        //   settingRef: (): ComponentFactory<LivestreamingSettingsComponent> =>
        //     this.compFactory.resolveComponentFactory(LivestreamingSettingsComponent)
        // }
    ];

    constructor(
        private compFactory: ComponentFactoryResolver,
        private authService: AuthService,
        private appService: AppService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        private utilService: UtilService,
        private userService: UserService,
        private rtcService: RtcService,
        private gTagService: GoogleTagManagerService,
        private systemService: SystemService,
        private eventEmitterService: EventEmitterService,
        private zone: NgZone
    ) {
        this.isThirdPartyExternalIntegration =
            this.thirdPartyExternalIntegrationService.getThirdPartyExternalIntegration();
        this.isMobileDevice = this.utilService.isMobileBrowser() || this.isThirdPartyExternalIntegration;
        this.currentUser = this.userService.getUserSync();
        this.isMyjioIntegration = this.thirdPartyExternalIntegrationService.getMyjioIntegration();
        this.inIFrame = this.utilService.inIframe();
        // Feature Check For Live Stream
        this.isLiveStreamEnabled =
            this.appService.getConfigVariable('isLiveStreamingEnabled') &&
            (this.currentUser.mediaEnginePreference === 'agora' ||
                this.currentUser.mediaEnginePreference === 'jm-media') &&
            !this.isThirdPartyExternalIntegration &&
            !this.isMobileDevice &&
            !this.isMyjioIntegration &&
            !this.inIFrame;
        this.tabs.push({
            name: 'General',
            label: 'general',
            tkey: 'joinmeeting_page_or_joincall.general',
            img: 'assets/images/settings_selected_icn.svg',
            altImg: 'assets/images/settings_selected_icn.svg',
            settingRef: (): ComponentFactory<GeneralSettingsComponent> =>
                this.compFactory.resolveComponentFactory(GeneralSettingsComponent)
        });
        this.tabs.push({
            name: 'Video',
            label: 'video',
            tkey: 'joinmeeting_page_or_joincall.video',
            img: 'assets/images/settings_video_not_selected.svg',
            altImg: 'assets/img/settings_video_white.svg',
            settingRef: (): ComponentFactory<VideoSettingsComponent> =>
                this.compFactory.resolveComponentFactory(VideoSettingsComponent)
        });

        if (
            (this.userService.getUserSync().mediaEnginePreference === 'agora' ||
                this.userService.getUserSync().mediaEnginePreference === 'jm-media') &&
            !this.isThirdPartyExternalIntegration &&
            this.appService.getConfigVariable('IS_VB_ENABLED') &&
            !this.systemService.isSafariBrowser()
        ) {
            if (window.location.pathname === '/conference/call') {
                if (this.rtcService.getIsAgoraOrJmMeeting()) {
                    this.tabs.push({
                        name: 'Background',
                        label: 'background',
                        tkey: 'inside_call.call_controls.change_background',
                        img: 'assets/images/Component 473 – 9.svg',
                        altImg: 'assets/images/Component 473 – 9.svg',
                        settingRef: (): ComponentFactory<VirtualBackgroundComponent> =>
                            this.compFactory.resolveComponentFactory(VirtualBackgroundComponent)
                    });
                }
            } else {
                this.tabs.push({
                    name: 'Background',
                    label: 'background',
                    tkey: 'inside_call.call_controls.change_background',
                    img: 'assets/images/Component 473 – 9.svg',
                    altImg: 'assets/images/Component 473 – 9.svg',
                    settingRef: (): ComponentFactory<VirtualBackgroundComponent> =>
                        this.compFactory.resolveComponentFactory(VirtualBackgroundComponent)
                });
            }
        } else if (
            !this.authService.getIsAuthenticated() &&
            !this.isThirdPartyExternalIntegration &&
            this.appService.getConfigVariable('IS_VB_ENABLED') &&
            !this.systemService.isSafariBrowser()
        ) {
            this.tabs.push({
                name: 'Background',
                label: 'background',
                tkey: 'inside_call.call_controls.change_background',
                img: 'assets/images/Component 473 – 9.svg',
                altImg: 'assets/images/Component 473 – 9 (1).svg',
                settingRef: (): ComponentFactory<VirtualBackgroundComponent> =>
                    this.compFactory.resolveComponentFactory(VirtualBackgroundComponent)
            });
        }

        this.tabs.push({
            name: 'Audio',
            label: 'audio',
            tkey: 'joinmeeting_page_or_joincall.audio',
            img: 'assets/images/settings_audio_not_selected.svg',
            altImg: 'assets/img/settings_audio_white.svg',
            settingRef: (): ComponentFactory<AudioSettingsComponent> =>
                this.compFactory.resolveComponentFactory(AudioSettingsComponent)
        });

        if (this.appService.getConfigVariable('whatsapp_config')?.enabled && !this.isThirdPartyExternalIntegration) {
            this.authenticatedTabs.push({
                name: 'Notifications',
                label: 'notifications',
                tkey: 'joinmeeting_page_or_joincall.notifications',
                img: 'assets/images/notification_bell.svg',
                altImg: 'assets/images/notification_bell_clicked.svg',
                settingRef: (): ComponentFactory<NotificationsSettingsComponent> =>
                    this.compFactory.resolveComponentFactory(NotificationsSettingsComponent)
            });
        }

        if (this.authService.getIsAuthenticated()) {
            this.tabs.push(...this.authenticatedTabs);
        }

        if (!this.isMobileDevice) {
            this.tabs.push({
                name: 'Language',
                label: 'language',
                tkey: 'joinmeeting_page_or_joincall.language',
                img: 'assets/images/language_not_selected.svg',
                altImg: 'assets/images/language_selected.svg',
                settingRef: (): ComponentFactory<LanguagesettingsComponent> =>
                    this.compFactory.resolveComponentFactory(LanguagesettingsComponent),
                hideTab: !this.appService.getConfigVariable('ENABLE_MULTI_LANG')
            });
        }
        if (this.isLiveStreamEnabled && this.currentUser?.featuresEnabled?.liveStream) {
            this.tabs.push({
                name: 'Live Stream',
                label: 'liveStream',
                tkey: 'joinmeeting_page_or_joincall.live_stream',
                img: 'assets/images/settings_livestream_not_selected.svg',
                altImg: 'assets/images/settings_livestream.svg',
                settingRef: (): ComponentFactory<LiveStreamSettingsComponent> =>
                    this.compFactory.resolveComponentFactory(LiveStreamSettingsComponent)
            });
        }
        this.eventEmitterService.subscribe((event) => {
            this.zone.run(() => {
                switch (event.type) {
                    case APP_EVENTS.CONFIRM_VIRTUAL_BACKGROUND:
                        this.close();
                        break;
                    case APP_EVENTS.CLOSE_SETTINGS_MODAL:
                        this.close();
                        break;
                    case KEYBOARD_CONSTANT.KEYBOARD_SHORTCUTS:
                        if (event.data === KEYBOARD_CONSTANT.CLOSE_WINDOW) {
                            this.close();
                        }
                        break;
                }
            });
        });
        this.selectedLabel = 'general';
    }

    ngOnInit() {
        this.userService.getUser().subscribe(
            (user) => {
                this.isNameUpdated = user.isNameUpdated;
            },
            (error) => {
                console.error('Error fetching user profile:', error);
            }
        );
    }

    removeAutocomplete() {
        const inputField = document.getElementById('phone-input') as HTMLInputElement;
        if (inputField) {
            inputField.blur(); // Remove focus from the input field
        }
    }

    get isMobile() {
        return window.innerWidth < 768;
    }

    get hideBackgroundOption() {
        return window.innerWidth < 1080;
    }

    ngAfterViewInit() {
        if (
            this.selectedLabel === 'background_only' &&
            this.appService.getConfigVariable('IS_VB_ENABLED') &&
            !this.systemService.isSafariBrowser() &&
            this.rtcService.getIsAgoraMeeting()
        ) {
            this.tabs = this.tabs.filter((obj) => obj.label === 'background');
            setTimeout(() => this.openSetting(this.tabs.find((tab) => tab.label === 'background')), 0);
            return;
        }
        if (this.selectedLabel === 'background' && this.appService.getConfigVariable('IS_VB_ENABLED')) {
            setTimeout(() => this.openSetting(this.tabs.find((tab) => tab.label === 'background')), 0);
            return;
        }

        this.selectedLabel = 'general';
        if (this.selectedLabel) {
            setTimeout(() => this.openSetting(this.tabs.find((tab) => tab.label === this.selectedLabel)), 0);
        }
    }
    handleClosefunction(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            const closebtn = event.target as HTMLElement;
            closebtn.click();
        }
    }
    openSetting(tab) {
        this.gTagService.sendEvent(GA_NEW.DASHBOARD_SETTINGS);
        const event = tab.label;
        switch (event) {
            case 'general': {
                setTimeout(() => {
                    const popupEle: any = document.getElementById('enablePopupcheck');
                    if (popupEle) {
                        popupEle?.focus();
                    }
                }, 100);
                break;
            }
            case 'video': {
                this.gTagService.sendEvent(GA_EVENTS.ONCLICK_VIDEO);
                this.gTagService.sendEvent(GA_NEW.ONCLICK_VIDEO);
                setTimeout(() => {
                    const cameraFocusEle: any = document.getElementsByClassName('camerafocus')[0];
                    if (cameraFocusEle) {
                        cameraFocusEle?.focus();
                    }
                }, 100);
                break;
            }
            case 'background': {
                this.gTagService.sendEvent(GA_EVENTS.ONCLICK_CHANGEBACKGROUND);
                this.gTagService.sendEvent(GA_NEW.ONCLICK_CHANGEBACKGROUND);
                setTimeout(() => {
                    const BGFocusEle: any = document.getElementById('bgfocus');
                    if (BGFocusEle) {
                        BGFocusEle?.focus();
                    }
                }, 1000);
                break;
            }
            case 'audio': {
                this.gTagService.sendEvent(GA_EVENTS.ONCLICK_AUDIO);
                this.gTagService.sendEvent(GA_NEW.ONCLICK_AUDIO);
                setTimeout(() => {
                    const SpeakerFocusEle: any = document.getElementsByClassName('speakerfocus')[0];
                    if (SpeakerFocusEle) {
                        SpeakerFocusEle?.focus();
                    }
                }, 100);
                break;
            }
            case 'profile': {
                this.gTagService.sendEvent(GA_EVENTS.ONCLICK_PROFILE);
                this.gTagService.sendEvent(GA_NEW.ONCLICK_PROFILE);
                setTimeout(() => {
                    const profileFocusEle: any = document.getElementsByClassName(' profilefocus')[0];
                    if (profileFocusEle) {
                        profileFocusEle?.focus();
                    }
                }, 100);
                break;
            }
            case 'personal meeting id': {
                this.gTagService.sendEvent(GA_EVENTS.ONCLICK_PERSONAL_MEETING_ID);
                this.gTagService.sendEvent(GA_NEW.ONCLICK_PERSONAL_MEETING_ID);
                setTimeout(() => {
                    const PMIfocusEle: any = document.getElementsByClassName('PMIfocus')[0];
                    if (PMIfocusEle) {
                        PMIfocusEle?.focus();
                    }
                }, 100);
                break;
            }
            case 'notifications': {
                this.gTagService.sendEvent(GA_EVENTS.ONCLICK_NOTIFICATION);
                this.gTagService.sendEvent(GA_NEW.ONCLICK_NOTIFICATION);
                setTimeout(() => {
                    const MeetingFocusEle: any = document.getElementsByClassName('Meetingnotifocus')[0];
                    if (MeetingFocusEle) {
                        MeetingFocusEle?.focus();
                    }
                }, 100);
                break;
            }

            case 'language': {
                setTimeout(() => {
                    const LangFocusEle: any = document.getElementsByClassName('langfoc')[0];
                    if (LangFocusEle) {
                        LangFocusEle?.focus();
                    }
                }, 100);
                break;
            }

            case 'liveStream': {
                this.gTagService.sendEvent(GA_EVENTS.ONCLICK_LIVE_STREAM);
                this.gTagService.sendEvent(GA_NEW.ONCLICK_LIVE_STREAM);
                setTimeout(() => {
                    const liveFocusEle: any = document.getElementsByClassName('livefocus')[0];
                    if (liveFocusEle) {
                        liveFocusEle?.focus();
                    }
                }, 100);
                break;
            }
        }

        this.factoryComponentRef?.destroy();
        this.selectedLabel = tab.label;
        const component = tab.settingRef();
        this.factoryComponentRef = this.viewHost.viewContainerRef.createComponent(component);
    }

    close() {
        this.afterClose.emit();
    }
}
