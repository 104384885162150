import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
    AppService,
    EventEmitterService,
    GoogleTagManagerService,
    LiveStreamService,
    LoggerService,
    UserService
} from 'src/app/core';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { APP_EVENTS } from 'src/app/constants';
import { GA_EVENTS } from '../../../ga-events';
import { GA_NEW } from 'src/app/shared/ga-new';

@Component({
    selector: 'app-live-stream-config',
    templateUrl: './live-stream-config.component.html',
    styleUrls: ['./live-stream-config.component.scss']
})
export class LiveStreamConfigComponent implements OnInit {
    @Output() close: EventEmitter<any> = new EventEmitter();
    @Input() isRenderFromCall;
    @Input() streamObj;
    showKey = false;
    showPageURL = false;
    errors: any = {};
    submitted = false;
    ifInsideMeeting = false;
    streamTypes: any = [
        { name: this.translate.instant('user_dashboard_settings.live_stream.youtube_live'), value: 'yt' },
        { name: this.translate.instant('user_dashboard_settings.live_stream.facebook_live'), value: 'fb' },
        { name: this.translate.instant('user_dashboard_settings.live_stream.others'), value: 'custom' }
    ];
    private subscriptions: Subscription[] = [];

    form;
    currentUser: any;
    isRILUser = false;
    constructor(
        private fb: FormBuilder,
        private translate: TranslateService,
        private liveStreamService: LiveStreamService,
        private loggerService: LoggerService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private eventEmitterService: EventEmitterService,
        private gTagService: GoogleTagManagerService,
        private userService: UserService,
        private appService: AppService
    ) {}

    ngOnInit(): void {
        this.ifInsideMeeting = window.location.href.includes('conference');
        this.currentUser = this.userService.getUserSync();
        this.isRILUser = this.currentUser.email ? this.currentUser.isRil : false;

        this.form = this.fb.group({
            streamName: [this.streamObj?.streamName ? this.streamObj.streamName : '', [Validators.required]],
            streamPlatform: [
                this.streamObj?.streamPlatform ? this.streamObj.streamPlatform : '',
                [Validators.required]
            ],
            streamKey: [this.streamObj?.streamKey ? this.streamObj.streamKey : '', [Validators.required]],
            streamingUrl: [this.streamObj?.streamingUrl ? this.streamObj.streamingUrl : '', [Validators.required]]
        });
        this.translate.onLangChange.subscribe(
            () =>
                (this.streamTypes = [
                    { name: this.translate.instant('user_dashboard_settings.live_stream.youtube_live'), value: 'yt' },
                    { name: this.translate.instant('user_dashboard_settings.live_stream.facebook_live'), value: 'fb' },
                    { name: this.translate.instant('user_dashboard_settings.live_stream.others'), value: 'custom' }
                ])
        );
    }

    validateStreamingUrl() {
        if (
            this.form.value.streamPlatform === 'yt' &&
            !this.form.value.streamingUrl.toLowerCase().includes('youtube')
        ) {
            return false;
        }
        if (
            this.form.value.streamPlatform === 'fb' &&
            !this.form.value.streamingUrl.toLowerCase().includes('facebook')
        ) {
            return false;
        }
        return true;
    }

    validateName() {
        if (this.form.value.streamName.trim().length === 0) {
            return false;
        }
        return true;
    }

    createLiveStream(streamPlatformVal) {
        this.errors = {};
        this.submitted = true;
        let streamObj: any = {};
        streamObj = this.form?.value;
        streamObj.streamPageUrl = 'jiomeetpro.jio.com';

        if (!this.validateName()) {
            this.errors.streamName = this.translateService.instant(
                'user_dashboard_settings.live_stream.live_stream_name_error'
            );
            this.submitted = false;
            this.gTagService.sendEvent(GA_EVENTS.LIVESTREAM_SAVE_FAIL);
            return;
        }

        if (!this.validateStreamingUrl()) {
            this.errors.streamingUrl = this.translateService.instant(
                'user_dashboard_settings.live_stream.live_stream_form_error'
            );
            this.submitted = false;
            this.gTagService.sendEvent(GA_EVENTS.LIVESTREAM_SAVE_FAIL);
            return;
        }

        this.subscriptions.push(
            this.liveStreamService.createLiveStream(streamObj).subscribe(
                (data) => {
                    this.appService.setLiveStreamId((data as any)?._id);
                    this.submitted = false;
                    this.toastrService.info(
                        this.translateService.instant('user_dashboard_settings.live_stream.create_success')
                    );
                    if (this.ifInsideMeeting) {
                        if (streamPlatformVal === 'yt') {
                            this.gTagService.sendEvent(GA_EVENTS.LIVESTREAM_YOUTUBE);
                            this.gTagService.sendEvent(GA_NEW.LIVESTREAM__YOUTUBE);
                        } else if (streamPlatformVal === 'fb') {
                            this.gTagService.sendEvent(GA_EVENTS.LIVESTREAM_FACEBOOK);
                            this.gTagService.sendEvent(GA_NEW.LIVESTREAM__FACEBOOK);
                        } else if (streamPlatformVal === 'custom') {
                            this.gTagService.sendEvent(GA_EVENTS.LIVESTREAM_CUSTOM);
                            this.gTagService.sendEvent(GA_NEW.LIVESTREAM__CUSTOM);
                        }
                        this.gTagService.sendEvent(GA_EVENTS.LIVESTREAM_SAVE);
                        this.gTagService.sendEvent(GA_NEW.LIVESTREAM__SAVE);
                    } else {
                        if (streamPlatformVal === 'yt') {
                            this.gTagService.sendEvent(GA_EVENTS.LIVESTREAM_YOUTUBE);
                            this.gTagService.sendEvent(GA_NEW.LIVESTREAM_YOUTUBE);
                        } else if (streamPlatformVal === 'fb') {
                            this.gTagService.sendEvent(GA_EVENTS.LIVESTREAM_FACEBOOK);
                            this.gTagService.sendEvent(GA_NEW.LIVESTREAM_FACEBOOK);
                        } else if (streamPlatformVal === 'custom') {
                            this.gTagService.sendEvent(GA_EVENTS.LIVESTREAM_CUSTOM);
                            this.gTagService.sendEvent(GA_NEW.LIVESTREAM_CUSTOM);
                        }
                        this.gTagService.sendEvent(GA_EVENTS.LIVESTREAM_SAVE);
                        this.gTagService.sendEvent(GA_NEW.LIVESTREAM_SAVE);
                    }
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.REFRESH_LIVE_STREAMS,
                        data: {}
                    });
                    this.closepage(true);
                },
                (err) => {
                    this.submitted = false;
                    let errorMeg = '';
                    if ([400, 412].includes(err?.error?.customCode)) {
                        if (err?.error?.errorsArray?.length) {
                            errorMeg = err.error.errorsArray[0]?.message;
                        } else if (err?.error?.errors) {
                            errorMeg = err.error.errors;
                        } else {
                            errorMeg = this.translateService.instant('tostrs.something_went_wrong_please_try_again');
                        }
                    }
                    if (this.ifInsideMeeting) {
                        this.gTagService.sendEvent(GA_NEW.LIVESTREAM__SAVE_FAIL);
                    } else {
                        this.gTagService.sendEvent(GA_NEW.LIVESTREAM_SAVE_FAIL);
                    }
                    this.gTagService.sendEvent(GA_EVENTS.LIVESTREAM_SAVE_FAIL);
                    this.toastrService.error(errorMeg);
                    this.loggerService.log('Unable to create live stream', err);
                }
            )
        );
    }

    updateLiveStream() {
        this.errors = {};
        this.submitted = true;
        let streamObj: any = {};
        streamObj = this.form?.value;

        if (!this.validateName()) {
            this.errors.streamName = this.translateService.instant(
                'user_dashboard_settings.live_stream.live_stream_name_error'
            );
            this.submitted = false;
            return;
        }

        if (!this.validateStreamingUrl()) {
            this.errors.streamingUrl = this.translateService.instant(
                'user_dashboard_settings.live_stream.live_stream_form_error'
            );
            this.submitted = false;
            return;
        }

        this.subscriptions.push(
            this.liveStreamService.updateLiveStream(this.streamObj?._id, streamObj).subscribe(
                (data) => {
                    this.submitted = false;
                    this.toastrService.info(
                        this.translateService.instant('user_dashboard_settings.live_stream.update_success')
                    );
                    this.closepage();
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.REFRESH_LIVE_STREAMS,
                        data: {}
                    });
                },
                (err) => {
                    this.submitted = false;
                    let errorMeg = '';
                    if ([400, 412].includes(err?.error?.customCode)) {
                        if (err?.error?.errorsArray?.length) {
                            errorMeg = err.error.errorsArray[0]?.message;
                        } else {
                            errorMeg = this.translateService.instant('tostrs.something_went_wrong_please_try_again');
                        }
                    }
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.UPDATE_STREAM_FAILURE,
                        data: { id: this.streamObj?._id }
                    });
                    this.loggerService.log('Unable to update live stream', err);
                    this.closepage();
                }
            )
        );
    }

    toggleKey() {
        this.showKey = !this.showKey;
    }

    closepage(isFromSubmit?) {
        if (!isFromSubmit) {
            if (this.ifInsideMeeting) {
                this.gTagService.sendEvent(GA_EVENTS.LIVESTREAM_CANCEL);
                this.gTagService.sendEvent(GA_NEW.LIVESTREAM__CANCEL);
            } else {
                this.gTagService.sendEvent(GA_EVENTS.LIVESTREAM_CANCEL);
                this.gTagService.sendEvent(GA_NEW.LIVESTREAM_CANCEL);
            }
        }
        this.streamObj = {};
        this.close.emit();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
