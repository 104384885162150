<div class="usecase-feature" *ngIf="isMobile" [class.padding-h-44]="useCaseFeature.padding0 !== true">
    <div class="heading-0" [class.padding-h-44]="useCaseFeature.padding0 === true">
        {{ useCaseFeature.ttitle | translate }}
    </div>
    <div class="img" *ngIf="useCaseFeature.imgMob && useCaseFeature.imgMob.push">
        <ng-container *ngFor="let img of useCaseFeature.imgMob">
            <img [src]="img" alt="" />
        </ng-container>
    </div>
    <div class="img" *ngIf="useCaseFeature.imgMob && !useCaseFeature.imgMob.push">
        <img [src]="useCaseFeature.imgMob" alt="" />
    </div>
    <div class="desc" [class.padding-h-44]="useCaseFeature.padding0 === true">
        <p
            class="subheading-2"
            *ngFor="let desc of useCaseFeature.desc"
            [class.highlight]="useCaseFeature.highlight === true || desc.highlight === true"
            [innerHTML]="desc.ttext | translate"
        ></p>
    </div>
    <ng-container *ngTemplateOutlet="contentBtn"></ng-container>
</div>

<div class="usecase-feature" *ngIf="isMobile === false" [class.row-reverse]="featureIndex % 2 === 0">
    <div class="img" *ngIf="useCaseFeature.imgDesktop">
        <img [src]="useCaseFeature.imgDesktop" [alt]="useCaseFeature.altText" />
    </div>
    <div class="feature-info">
        <div
            class="heading-0"
            [class.padding-h-44]="featureIndex === 0"
            tabindex="0"
            aria-label=" {{ useCaseFeature.ttitle | translate }}"
        >
            {{ useCaseFeature.ttitle | translate }}
        </div>
        <div class="desc">
            <p
                tabindex="0"
                class="subheading-2"
                *ngFor="let desc of useCaseFeature.desc"
                [class.highlight-text]="useCaseFeature.highlight === true"
                [innerHTML]="desc.ttext | translate"
                tabindex="0"
            ></p>
        </div>
        <ng-container *ngTemplateOutlet="contentBtn"></ng-container>
    </div>
</div>

<ng-template #contentBtn><ng-content></ng-content></ng-template>
