<div class="registration">
    <div class="header text-center py-4">
        {{ 'landing_or_home_page.section.access_enterprise_grade_features.header' | translate }}
    </div>
    <div class="content row">
        <div class="features text-center col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <h3 class="pb-3">
                {{ 'landing_or_home_page.section.access_enterprise_grade_features.title' | translate }}
            </h3>
            <div class="f-list">
                <ul>
                    <li>{{ 'landing_or_home_page.section.access_enterprise_grade_features.title' | translate }}</li>
                    <li>{{ 'landing_or_home_page.section.access_enterprise_grade_features.line1' | translate }}</li>
                    <li>{{ 'landing_or_home_page.section.access_enterprise_grade_features.line2' | translate }}</li>
                    <li>{{ 'landing_or_home_page.section.access_enterprise_grade_features.line3' | translate }}</li>
                    <li>{{ 'landing_or_home_page.section.access_enterprise_grade_features.line4' | translate }}</li>
                    <li>{{ 'landing_or_home_page.section.access_enterprise_grade_features.line5' | translate }}</li>
                    <li>{{ 'landing_or_home_page.section.access_enterprise_grade_features.line6' | translate }}</li>
                    <li>{{ 'landing_or_home_page.section.access_enterprise_grade_features.line7' | translate }}</li>
                </ul>
            </div>
        </div>
        <div class="form-content text-center col-xl-6 col-lg-6 col-md-12 col-sm-12 w-100 h-100">
            <div class="header text-center" *ngIf="!submitted">
                {{ 'landing_or_home_page.section.register_interest' | translate }}
            </div>
            <div class="form" [class.submitted]="submitted">
                <form [formGroup]="registrationForm" (submit)="register()">
                    <div class="form-field">
                        <input
                            type="text"
                            placeholder="*{{
                                'landing_or_home_page.section.register_form.company_org_name' | translate
                            }}"
                            formControlName="company"
                            (keypress)="checkCharLimit(1000, $event)"
                        />
                    </div>
                    <div class="form-field">
                        <input
                            type="text"
                            placeholder="*{{ 'landing_or_home_page.section.register_form.your_name' | translate }}"
                            formControlName="name"
                            (keypress)="checkCharLimit(200, $event)"
                        />
                    </div>
                    <div class="form-field">
                        <input
                            type="text"
                            placeholder="*{{ 'landing_or_home_page.section.register_form.designation' | translate }}"
                            formControlName="designation"
                            (keypress)="checkCharLimit(200, $event)"
                        />
                    </div>
                    <div class="form-field">
                        <input
                            type="tel"
                            placeholder="*{{ 'landing_or_home_page.section.register_form.contact_number' | translate }}"
                            formControlName="phone"
                            (keypress)="checkNumber($event)"
                        />
                    </div>
                    <div class="form-field">
                        <input
                            type="email"
                            placeholder="*{{ 'landing_or_home_page.section.register_form.email_id' | translate }}"
                            formControlName="email"
                        />
                    </div>
                    <div class="form-field">
                        <p-dropdown
                            [options]="companySizes"
                            panelStyleClass="minWidthOverride"
                            formControlName="companySize"
                            optionLabel="tvalue"
                            placeholder="*{{
                                'landing_or_home_page.section.register_form.company_org_size' | translate
                            }}"
                        ></p-dropdown>
                        <span>
                            <img alt="" src="/assets/images/dropdown_arrow_icn.svg" />
                        </span>
                    </div>
                    <div class="form-field">
                        <p-dropdown
                            [options]="sectors"
                            formControlName="sector"
                            optionLabel="tvalue"
                            placeholder="*{{ 'landing_or_home_page.section.register_form.sector' | translate }}"
                        >
                        </p-dropdown>
                        <span>
                            <img alt="" src="/assets/images/dropdown_arrow_icn.svg" />
                        </span>
                    </div>
                    <p class="tnc-text text-left">
                        {{ 'landing_or_home_page.section.register_form.by_registering' | translate }}
                        <a class="pointer blue" (click)="navigateToTnC()">{{
                            'landing_or_home_page.section.register_form.terms_and_conditions' | translate
                        }}</a>
                        {{ 'landing_or_home_page.section.register_form.of_usage' | translate }}
                    </p>
                    <div
                        class="err"
                        *ngIf="errors.name || errors.company || errors.designation || errors.phone || errors.email"
                    >
                        {{ errors.name || errors.company || errors.designation || errors.phone || errors.email }}
                    </div>
                    <div class="err" *ngIf="error">{{ error }}</div>
                    <div class="action text-lg-right text-sm-center pt-5">
                        <button type="submit" [class.disabled]="!registrationForm.valid">
                            {{ 'landing_or_home_page.section.register_form.register' | translate }}
                        </button>
                    </div>
                </form>
            </div>
            <div class="success w-100" *ngIf="submitted">
                <div class="logo-wrap">
                    <img alt="" src="assets/img/logo/jm-logo-blue.svg" />
                </div>
                <div class="text-1 px-2">
                    {{ 'landing_or_home_page.section.register_form.thank_you_for_interest' | translate }}
                </div>
                <div class="text-2">
                    {{ 'landing_or_home_page.section.register_form.we_will_be_in_touch' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>
