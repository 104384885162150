import { Component, OnDestroy, OnInit } from '@angular/core';
import { GaDiagnosticService } from 'src/app/core/services/ga-diagnostic.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    AppInfoService,
    AppService,
    EventData,
    EventEmitterService,
    GoogleTagManagerService,
    UserService,
    UtilService,
    ValidationService
} from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { APP_EVENTS } from 'src/app/constants';
import { GA4_EVENTS } from '../website/ga4-events';

@Component({
    selector: 'app-customer-feedback',
    templateUrl: './customer-feedback.component.html',
    styleUrls: ['./customer-feedback.component.scss']
})
export class CustomerFeedbackComponent implements OnInit, OnDestroy {
    customerFeedbackForm: FormGroup;
    error;
    errors: any = {};
    submitted = false;
    nameRegExp = /^[a-zA-Z0-9_\- ]{2,50}$/;
    companyRegExp = /^[a-zA-Z0-9_\@\-\.\, ]*$/;
    designationRegExp = /([a-zA-Z',.-]+( [a-zA-Z',.-]+)*){2,200}/;
    phoneRegExp = /^[6789]\d{9}$/;
    feedbackRegExp = /^[a-zA-Z0-9_\,\;\.\@\-\#\%\'\"\:\n ]{2,1000}$/;

    designations = [
        {
            value: 'education',
            name: 'Education',
            tkey: 'landing_or_home_page.section.register_form.education',
            tvalue: ''
        },
        {
            value: 'government',
            name: 'Government',
            tkey: 'landing_or_home_page.section.register_form.government',
            tvalue: ''
        },
        {
            value: 'healthcare',
            name: 'Healthcare',
            tkey: 'landing_or_home_page.section.register_form.healthcare',
            tvalue: ''
        },
        {
            value: 'erp',
            name: 'ERP',
            tkey: 'landing_or_home_page.section.register_form.erp',
            tvalue: ''
        },
        {
            value: 'collaboration',
            name: 'Collaboration',
            tkey: 'landing_or_home_page.section.register_form.collaboration',
            tvalue: ''
        },
        {
            value: 'financeAndBanking',
            name: 'Finance and Banking',
            tkey: 'landing_or_home_page.section.register_form.finance_and_banking',
            tvalue: ''
        },
        {
            value: 'productivity',
            name: 'Productivity',
            tkey: 'landing_or_home_page.section.register_form.productivity',
            tvalue: ''
        },
        {
            value: 'securityAndCompliance',
            name: 'Security and Compliance',
            tkey: 'landing_or_home_page.section.register_form.secure_and_comliance',
            tvalue: ''
        },
        {
            value: 'others',
            name: 'Others',
            tkey: 'landing_or_home_page.section.register_form.others',
            tvalue: ''
        }
    ];

    countries = [
        {
            value: 'education',
            name: 'Education',
            tkey: 'landing_or_home_page.section.register_form.education',
            tvalue: ''
        },
        {
            value: 'government',
            name: 'Government',
            tkey: 'landing_or_home_page.section.register_form.government',
            tvalue: ''
        },
        {
            value: 'healthcare',
            name: 'Healthcare',
            tkey: 'landing_or_home_page.section.register_form.healthcare',
            tvalue: ''
        },
        {
            value: 'erp',
            name: 'ERP',
            tkey: 'landing_or_home_page.section.register_form.erp',
            tvalue: ''
        },
        {
            value: 'collaboration',
            name: 'Collaboration',
            tkey: 'landing_or_home_page.section.register_form.collaboration',
            tvalue: ''
        },
        {
            value: 'financeAndBanking',
            name: 'Finance and Banking',
            tkey: 'landing_or_home_page.section.register_form.finance_and_banking',
            tvalue: ''
        },
        {
            value: 'productivity',
            name: 'Productivity',
            tkey: 'landing_or_home_page.section.register_form.productivity',
            tvalue: ''
        },
        {
            value: 'securityAndCompliance',
            name: 'Security and Compliance',
            tkey: 'landing_or_home_page.section.register_form.secure_and_comliance',
            tvalue: ''
        },
        {
            value: 'others',
            name: 'Others',
            tkey: 'landing_or_home_page.section.register_form.others',
            tvalue: ''
        }
    ];

    captchaDetails: any = {};
    isMobile;
    captchaId;
    feedbackSubmited = false;

    constructor(
        private gaService: GaDiagnosticService,
        private fb: FormBuilder,
        private appService: AppService,
        private validationService: ValidationService,
        private userService: UserService,
        private translate: TranslateService,
        private toastrService: ToastrService,
        private appInfoService: AppInfoService,
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private gTagService: GoogleTagManagerService
    ) {}

    ngOnInit() {
        this.isMobile = this.utilService.isMobileBrowser(1080);
        this.customerFeedbackForm = this.fb.group({
            userName: ['', [Validators.required]],
            phoneNo: ['', [this.validationService.isFormControlValueAPhoneNumber]],
            emailId: ['', [this.validationService.isFormControlValueAnEmail]],
            companyName: ['', [Validators.required]],
            // designation: [''],
            // country: ['', [Validators.required]],
            yourFeedback: ['', [Validators.required]],
            captchaText: ['', [Validators.required]]
        });

        this.translate
            .get([...this.designations.map((c) => c.tkey), ...this.countries.map((c) => c.tkey)])
            .subscribe((translations) => {
                this.designations.forEach((c) => {
                    c.tvalue = translations[c.tkey];
                });
                this.countries.forEach((s) => {
                    s.tvalue = translations[s.tkey];
                });
            });

        this.gaService.sendPageView({
            page_title: 'customer_feedback',
            page_path: '/customer-feedback'
        });

        this.refreshCaptcha();
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 1080 ? false : true;
            }
            if (event.type === APP_EVENTS.LANGUAGE_CHANGE) {
                if (this.errors.captchaText) {
                    this.errors.captchaText = this.translate.instant('tostrs.captcha_text');
                }
            }
        });
    }

    refreshCaptcha() {
        this.captchaId = new Date().getTime() + Math.floor(Math.random() * 100 + 1);
        this.appInfoService.getCaptchaDetail({ captchaId: this.captchaId }).subscribe((res) => {
            this.captchaDetails = res;
            this.customerFeedbackForm.get('captchaText').setValue('');
        });
    }

    verifyCaptcha(captchaText) {
        return new Promise<void>((resolve, reject) => {
            this.appInfoService.verifyCaptchaDetail({ captchaId: this.captchaId, captchaText }).subscribe(
                (res: any) => {
                    if (res.success) {
                        resolve();
                    } else {
                        this.errors.captchaText = this.translate.instant('tostrs.captcha_text');
                        reject();
                    }
                },
                (err) => {
                    reject();
                    this.errors.captchaText = this.translate.instant('tostrs.captcha_text');
                }
            );
        });
    }

    validate() {
        this.errors = {};
        if ((this.customerFeedbackForm.value.companyName || '').trim().length < 1) {
            this.errors.company = this.translate.instant('tostrs.company_name_should_be_at_least_one_character_long');
            return false;
        }
        if ((this.customerFeedbackForm.value.companyName || '').trim().length > 1000) {
            this.errors.company = this.translate.instant('tostrs.company_name_should_not_be_more_than_1000_characters');
            return false;
        }
        if ((this.customerFeedbackForm.value.userName || '').trim().length < 1) {
            this.errors.name = this.translate.instant('tostrs.name_should_be_at_least_one_character_long');
            return false;
        }
        if ((this.customerFeedbackForm.value.userName || '').trim().length > 200) {
            this.errors.name = this.translate.instant('tostrs.name_should_not_be_more_than_200_characters');
            return false;
        }

        if (!this.nameRegExp.test(this.customerFeedbackForm.value.userName)) {
            this.errors.name = this.translate.instant('tostrs.please_enter_a_valid_name');
            return false;
        }
        if (!this.companyRegExp.test(this.customerFeedbackForm.value.companyName)) {
            this.errors.company = this.translate.instant('customer-feedback.company_validation_error');
            return false;
        }
        if (/\d/.test(this.customerFeedbackForm?.value?.userName)) {
            this.errors.name = this.translate.instant('tostrs.name_should_contain_only_alphabets');
            return false;
        }

        if (!this.phoneRegExp.test(this.customerFeedbackForm.value.phoneNo)) {
            this.errors.phone = this.translate.instant('tostrs.please_enter_a_valid_phone_number');
            return false;
        }
        if (!this.validationService.isEmail(this.customerFeedbackForm.value.emailId)) {
            this.errors.email = this.translate.instant('tostrs.please_enter_a_valid_email_id');
            return false;
        }
        if (!this.feedbackRegExp.test(this.customerFeedbackForm.value.yourFeedback.trim())) {
            this.errors.yourFeedback = this.translate.instant('customer-feedback.feedback_validation_error');
            return false;
        }
        if ((this.customerFeedbackForm.value.captchaText || '').trim().length !== 6) {
            this.errors.captchaText = this.translate.instant('tostrs.captcha_text');
            return false;
        }

        if ((this.customerFeedbackForm.value.yourFeedback || '').trim().length === 0) {
            this.errors.yourFeedback = this.translate.instant('tostrs.feedback');
            return false;
        }

        return true;
    }

    async register() {
        this.submitted = false;
        if (!this.customerFeedbackForm.valid) {
            return;
        }
        this.errors = {};
        this.error = false;
        if (!this.validate()) {
            return;
        }
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translate.instant('tostrs.something_went_right'));
            return;
        }
        this.submitted = true;
        // first verify the captcha
        try {
            // submit feedback form
            this.submitFeedback();
        } catch (err) {
            this.submitted = false;
            this.error = err;
        }
    }

    submitFeedback() {
        const { ...payload } = this.customerFeedbackForm.value;

        this.submitted = true;
        payload.country = 'IND';
        payload.phoneNo = payload.phoneNo.toString();
        payload.captcha = { captchaId: this.captchaId?.toString(), captchaText: payload.captchaText };
        delete payload.captchaText;
        this.appInfoService.submitCustomerFeedback(payload).subscribe(
            (res) => {
                this.submitted = false;
                this.feedbackSubmited = true;
                this.gTagService.sendEvent(GA4_EVENTS.FEEDBACK_SUBMIT);
                window.scrollTo(0, 0);
                setTimeout(() => {
                    this.feedbackSubmited = false;
                    this.customerFeedbackForm.reset();
                }, 5000);
                this.refreshCaptcha();
            },
            (err) => {
                this.submitted = false;
                this.gTagService.sendEvent(GA4_EVENTS.FEEDBACK_SUBMIT_FAILED);
                if (err?.error?.message === 'INVALID_CAPTCHA') {
                    this.errors.captchaText = this.translate.instant('tostrs.captcha_text');
                } else {
                    this.error =
                        err && err.error && err.error.message
                            ? err.error.message === 'REGISTRATION_LIMIT_EXCEEDED'
                                ? 'You have already registered.'
                                : err.error.message
                            : 'Something went wrong, please try again later.';
                }
            }
        );
    }

    checkCharLimit(limit, event) {
        if (
            event.which !== 46 && // delete
            event.which !== 8 && // backspace
            event.target.value.length >= limit
        ) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    checkNumber(event) {
        // Allow only numbers on keypress/paste
        let value = event.srcElement.value;
        let numbers = value.replace(/[^0-9]/g, '');
        event.srcElement.value = numbers;
    }

    ngOnDestroy(): void {
        if (this.customerFeedbackForm.dirty) {
            this.gTagService.sendEvent(GA4_EVENTS.FEEDBACK_NOT_SUBMIT);
        }
    }
}
