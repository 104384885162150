import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { WEBSITE_UPCOMING_EVENTS } from '../websiteConstant';
import * as Flickity from 'flickity';
import { UtilService } from 'src/app/core';

@Component({
    selector: 'app-website-upcoming-events',
    templateUrl: './website-upcoming-events.component.html',
    styleUrls: ['./website-upcoming-events.component.scss']
})
export class WebsiteUpcomingEventsComponent implements OnInit, AfterViewInit {
    @ViewChild('eventsSliderRef') eventsSliderRef: ElementRef;
    @Input() upcomingEvents = WEBSITE_UPCOMING_EVENTS;
    isMobile = false;

    private eventsCarousel;

    eventsGroup = [];

    constructor(private utilService: UtilService) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser();
        let chunkSize = 3;
        if (this.isMobile) {
            chunkSize = 1;
        }

        const arr = this.upcomingEvents;
        this.eventsGroup = arr
            .map((e, i) => {
                return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null;
            })
            .filter((e) => {
                return e;
            });

        console.log(this.eventsGroup);
    }

    ngAfterViewInit() {
        this.initEventsSlider();
    }

    initEventsSlider() {
        this.eventsCarousel = new Flickity(this.eventsSliderRef.nativeElement, {
            prevNextButtons: true,
            autoPlay: this.isMobile ? 4000 : false,
            contain: false,
            cellAlign: 0,
            pageDots: true,
            selectedAttraction: 0.2,
            friction: 0.8,
            index: 0,
            resize: true
        });
    }
}
