import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { UtilService } from './util.service';
@Injectable({
    providedIn: 'root'
})
export class ProfilePhotoService {
    private intialsBackgroundColors = [
        '#67C3EF',
        '#BF745A',
        '#B5887D',
        '#AB6AA1',
        '#80ABBF',
        '#D89E41',
        '#5A6AA6',
        '#BD4740',
        '#D57039',
        '#B19F82',
        '#81A651',
        '#4C66B5',
        '#2A967A',
        '#C65270',
        '#6162AB',
        '#495DA6',
        '#DB9A4A',
        '#8A9AF2',
        '#F5CC47',
        '#59ABA5',
        '#7762A6',
        '#F9C74F',
        '#DD551B',
        '#D98B3F',
        '#668AF4',
        '#51A65D',
        '#CB76BE',
        '#A66274',
        '#5984AA',
        '#C65270',
        '#90BE6D',
        '#375EE6',
        '#6A8C50',
        '#A65641',
        '#A6A049',
        '#92B09D',
        '#73694F',
        '#E6B637',
        '#AB4215',
        '#51A58C'
    ];
    profilePhotoCheck = new Map();
    constructor(private appService: AppService, private utilService: UtilService) {}

    getProfilePic(userId) {
        return this.utilService.getProfilePic(userId);
    }

    getColorForInitials(name) {
        let colorPosition = name?.split('').reduce((acc, val) => {
            return acc + val.charCodeAt(0);
        }, 0);
        colorPosition = colorPosition % 40;
        return this.intialsBackgroundColors[colorPosition];
    }

    getImageAsBase64(url) {
        return fetch(url)
            .then((response) => {
                if (response?.status === 200) {
                    return response.blob();
                } else {
                    throw new Error('Image not Found!');
                }
            })
            .then(
                (blob) =>
                    new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve(reader.result);
                        reader.onerror = reject;
                        reader.readAsDataURL(blob);
                    })
            );
    }

    getAvatarInitials(name) {
        if (!name) {
            return;
        }
        name = name?.trim();
        const split = name?.split(' ');
        let result = '';
        let nameLength = split.length;
        if (name.includes('(Guest)')) {
            if (nameLength > 2) {
                // if guest user has more than 2 parts in the name ex. AAA BBB CCC (Guest)
                // then we will have the initials of first and last name 'AC' corresponding to 'AAA' and 'CCC'
                result = (split[0][0] + split[nameLength - 2][0]).toUpperCase();
            } else {
                // if only one part in name ex. AAA (Guest), then name will have first 2 characters, 'AA'
                result = name?.toUpperCase().substring(0, 2);
            }
        } else {
            if (nameLength >= 2) {
                // in case of logged in user, if there are more than or equal to 2 parts
                // then we take initials of first and last name
                result = (split[0][0] + split[nameLength - 1][0]).toUpperCase();
            } else {
                // in case of only one part in name, then first 2 characters of name
                result = name?.toUpperCase().substring(0, 2);
            }
        }
        return result;
    }
}
