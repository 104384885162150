import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AgoraService, EventEmitterService, LocalStorageService, UserService, VideoWrapperService } from 'src/app/core';

@Component({
    selector: 'app-general-settings',
    templateUrl: './general-settings.component.html',
    styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {
    isConfirmationEnabled = true;
    isOnBehalfEnabled = false;
    showAllowedUserList = false;
    subsciption: Subscription[] = [];
    private videoService;
    currentUser;
    usersList;
    constructor(
        private localStorageService: LocalStorageService,
        private videoServiceWrapper: VideoWrapperService,
        private eventEmitterService: EventEmitterService,
        private userService: UserService,
        public agoraService: AgoraService,
        private toasterService: ToastrService
    ) {
        this.videoService = this.videoServiceWrapper.getVideoServiceForPreview();
    }

    ngOnInit(): void {
        this.currentUser = this.userService.getUserSync();
        this.isConfirmationEnabled = this.localStorageService.getItem('enableConfirmationPopup');

        this.subsciption.push(
            this.videoService.getParticipantStatus().subscribe(async (status) => {
                if (!this.videoService?.AgoraRTC && this.videoService.cameras.length === 0) {
                    await this.agoraService.initializeAgoraRTC();
                    await this.videoService.updateAllDevices();
                }
            })
        );

        this.getOnBehalfUsersList();
    }

    selectedUserList(list) {
        this.usersList = list;
    }
    getOnBehalfUsersList() {
        this.userService.getOnBehalfUsersList().subscribe((onBehalfUsersList) => {
            this.setOnBehalfStatus(onBehalfUsersList.isEnabled ? true : false);
            this.usersList = onBehalfUsersList.usersOnBehalf;
            this.showAllowedUserListView();
        });
    }
    setOnBehalfStatus(isEnabled) {
        if (this.currentUser.tenantId != null) this.isOnBehalfEnabled = isEnabled;
        else this.isOnBehalfEnabled = false;
    }
    onConfirmationPopUpEnabled(event) {
        const el = event.target;
        this.isConfirmationEnabled = el.checked;
        this.localStorageService.addItem('enableConfirmationPopup', this.isConfirmationEnabled);
    }
    //accessability change
    onConfirmationCheckEntered() {
        this.isConfirmationEnabled = !this.isConfirmationEnabled;
    }
    onBehalfCheckEntered() {
        this.isOnBehalfEnabled = !this.isOnBehalfEnabled;
    }
    showAllowedUserListView() {
        if (!this.isOnBehalfEnabled && this.usersList.length == 0) {
            this.showAllowedUserList = false;
        } else {
            this.showAllowedUserList = true;
        }
    }
    onBehalfHostEnabled(event) {
        this.userService.enableOnBehalfSchedule(this.isOnBehalfEnabled).subscribe(
            (enableStatus: any) => {
                const el = event.target;
                this.isOnBehalfEnabled = el.checked;
                this.showAllowedUserListView();
            },
            (err) => {
                if (err?.error?.customCode === 400 || err?.error?.customCode === 412) {
                    this.isOnBehalfEnabled = false;
                    let toast_Class = ' ngx-toastr audiencemode-toast';
                    this.toasterService.clear();
                    this.toasterService.error(
                        err?.error?.errorsArray[0]?.message,
                        err?.error?.errorsArray[0]?.message,
                        {
                            toastClass: toast_Class
                        }
                    );
                }
            }
        );
    }

    setFocusOnElement() {
        setTimeout(() => {
            const element: any = document.getElementById('video');
            element.focus();
        }, 100);
    }
}
