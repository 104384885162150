import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { RtcService } from '../services';

@Injectable()
export class CanCallGuard implements CanActivate {
    constructor(
        private confirmationService: ConfirmationService,
        private rtcService: RtcService,
        private translateService: TranslateService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, snapshot: RouterStateSnapshot): Observable<boolean> {
        return new Observable((observer) => {
            if (this.rtcService.getIncomingCallMeeting()) {
                this.confirmationService.confirm({
                    message: this.translateService.instant('incoming_call.will_be_declined'),
                    header: this.translateService.instant('incoming_call.warning'),
                    acceptLabel: this.translateService.instant('incoming_call.yes'),
                    rejectLabel: this.translateService.instant('incoming_call.no'),
                    acceptButtonStyleClass: 'custom-button bg-primary',
                    rejectButtonStyleClass: 'custom-button bg-secondary',
                    accept: () => {
                        this.rtcService.sendCallRejectNotification().subscribe();
                        observer.next(true);
                        observer.complete();
                    },
                    reject: () => {
                        observer.next(false);
                        observer.complete();
                    }
                });
            } else {
                observer.next(true);
                observer.complete();
            }
        });
    }
}
