import { Directive, ViewChild, HostListener, Output, EventEmitter, ElementRef } from '@angular/core';

@Directive({
    selector: '[outside]'
})
export class OutsideClickerDirective {
    constructor(private elementRef: ElementRef) {}
    @HostListener('document:click', ['$event.target']) onMouseEnter(targetElement) {
        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.outside.emit(null);
        }
    }
    @Output() outside: EventEmitter<boolean> = new EventEmitter();
}
