import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from 'src/app/core';

@Component({
    selector: 'app-website-testimonials',
    templateUrl: './website-testimonials.component.html',
    styleUrls: ['./website-testimonials.component.scss']
})
export class WebsiteTestimonialsComponent implements OnInit {
    @Input() testimonial: any;
    isMobile = false;
    constructor(private utilService: UtilService) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser(720);
    }

    getRatingAriaLabel(): string {
        return `Rated count : ${this.testimonial.rating}`;
    }

    TestimonialCommentfun(): string {
        return `Testimonial Comment : ${this.testimonial.comment}`;
    }

    TestimonialNamefun(): string {
        return `Testimonial Name : ${this.testimonial.userName}`;
    }
}
