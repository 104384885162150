<div class="website-education website-usecases" id="website-top">
    <section class="education-top section-top" [class.padding-h-16]="isMobile">
        <div
            class="left-content First-Element-Focus"
            tabindex="0"
            aria-label="The JioMeet for Education Page has been opened! 
                         To navigate through the page, please utilize the tab key on your keyboard"
        >
            <div class="subheading-1" tabindex="0">
                {{ 'website_education.top-title-0' | translate }}
            </div>
            <div class="heading" tabindex="0">
                {{ 'website_education.top-title-1' | translate }}
            </div>
            <div class="desc subheading-0" tabindex="0">
                {{ 'website_education.top-desc' | translate }}
            </div>
            <div class="action-btns">
                <button
                    class="custom-action-btn"
                    tabindex="0"
                    (keydown.enter)="navigateToSignUp()"
                    (click)="navigateToSignUp()"
                >
                    {{ 'website_education.start_free' | translate }}
                </button>
                <button
                    class="custom-action-btn secondary"
                    tabindex="0"
                    (keydown.enter)="goToDownloadApp()"
                    (click)="goToDownloadApp()"
                >
                    {{ 'website_education.download' | translate }}
                </button>
            </div>
        </div>
        <div class="right-content">
            <img
                src="assets/img/education/education-top.png"
                *ngIf="!isMobile"
                alt="Hybrid learning environment for schools, colleges, coaching institutes and universities."
            />
            <img src="assets/img/education/education-top-mobile.png" *ngIf="isMobile" alt="" />
        </div>
    </section>

    <div *ngIf="!isMobile">
        <section class="sec-1 sec" *ngIf="!isMobile">
            <div class="img">
                <img [src]="educationFeatures[0].imgDesktop" [alt]="educationFeatures[0].altText" />
            </div>
            <div
                class="heading-0 padding-h-140-150"
                [class.padding-h-44]="featureIndex === 0"
                tabindex="0"
                aria-label=" {{ educationFeatures[0].ttitle | translate }}"
            >
                {{ educationFeatures[0].ttitle | translate }}
            </div>
            <div class="sec-info padding-h-140-150">
                <div>
                    <p
                        class="subheading-2"
                        tabindex="0"
                        aria-label="{{ educationFeatures[0].desc[0].ttext | translate }}"
                    >
                        {{ educationFeatures[0].desc[0].ttext | translate }}
                    </p>
                    <p
                        class="subheading-2"
                        tabindex="0"
                        aria-label="{{ educationFeatures[0].desc[1].ttext | translate }}"
                    >
                        {{ educationFeatures[0].desc[1].ttext | translate }}
                    </p>
                </div>
                <div class="highlight">
                    <p
                        class="subheading-2"
                        tabindex="0"
                        aria-label="{{ educationFeatures[0].desc[2].ttext | translate }}"
                    >
                        {{ educationFeatures[0].desc[2].ttext | translate }}
                    </p>
                </div>
            </div>
        </section>

        <section class="sec-2 sec padding-h-140-150">
            <app-usecase-features [useCaseFeature]="educationFeatures[1]" [featureIndex]="1"></app-usecase-features>
        </section>

        <section class="sec-3 sec padding-h-140-150">
            <app-usecase-features [useCaseFeature]="educationFeatures[2]" [featureIndex]="2"></app-usecase-features>
        </section>

        <section class="sec-4 sec padding-h-140-150">
            <div class="img-sec">
                <img src="assets/img/education/trading-aids.png" alt="" />
            </div>
            <div class="sec-info">
                <div
                    class="heading-0"
                    [class.padding-h-44]="featureIndex === 0"
                    tabindex="0"
                    aria-label="{{ educationFeatures[3].ttitle | translate }}"
                >
                    {{ educationFeatures[3].ttitle | translate }}
                </div>
                <p class="subheading-2" tabindex="0" aria-label=" {{ educationFeatures[3].desc[0].ttext | translate }}">
                    {{ educationFeatures[3].desc[0].ttext | translate }}
                </p>
            </div>
            <div class="teaching-solutions">
                <div *ngFor="let solutionInfo of teachingSolutions">
                    <app-website-solution [solutionInfo]="solutionInfo"></app-website-solution>
                </div>
            </div>
        </section>

        <section class="sec-5 sec padding-h-140-150">
            <app-usecase-features [useCaseFeature]="educationFeatures[4]" [featureIndex]="5"></app-usecase-features>
        </section>

        <section class="sec-6 sec padding-h-140-150">
            <app-usecase-features [useCaseFeature]="educationFeatures[5]" [featureIndex]="6"></app-usecase-features>
        </section>

        <section class="sec-7 sec padding-h-140-150">
            <app-usecase-features [useCaseFeature]="educationFeatures[6]" [featureIndex]="7"></app-usecase-features>
        </section>

        <section class="sec-8 sec padding-h-140-150">
            <app-usecase-features [useCaseFeature]="educationFeatures[7]" [featureIndex]="8"></app-usecase-features>
        </section>

        <section class="sec-1 sec-9 sec">
            <div class="img">
                <img src="assets/img/education/pl-learning.png" alt="" />
            </div>
            <div class="sec-info padding-h-140-150">
                <div>
                    <div class="heading-0" tabindex="0" aria-label="{{ educationFeatures[8].ttitle | translate }}">
                        {{ educationFeatures[8].ttitle | translate }}
                    </div>
                    <p
                        class="subheading-2"
                        tabindex="0"
                        aria-label=" {{ educationFeatures[8].desc[0].ttext | translate }}"
                    >
                        {{ educationFeatures[8].desc[0].ttext | translate }}
                    </p>
                </div>
                <div class="highlight"></div>
            </div>
        </section>

        <section class="sec-10 sec padding-h-140-150">
            <app-usecase-features [useCaseFeature]="educationFeatures[9]" [featureIndex]="9"></app-usecase-features>
        </section>
    </div>
    <ng-container *ngIf="isMobile">
        <section *ngFor="let feature of educationFeatures; let i = index" class="sec">
            <app-usecase-features [useCaseFeature]="feature" [featureIndex]="i"></app-usecase-features>
            <div class="teaching-solutions padding-h-16" *ngIf="i === 3">
                <div *ngFor="let solutionInfo of teachingSolutions">
                    <app-website-solution [solutionInfo]="solutionInfo"></app-website-solution>
                </div>
            </div>
        </section>
    </ng-container>

    <section class="got-questions" [class.padding-h-44]="isMobile">
        <app-explore-meet
            bottomButtons="education"
            title="{{ 'website_education.explore_title' | translate }}"
            desc="{{ 'website_education.explore_desc' | translate }}"
            btnText1="{{ 'website_education.start_free' | translate }}"
            btnText2="{{ 'website_home.download_now' | translate }}"
            btnText1Link="/signup"
            btnText2Link="/jiomeetDownload"
        ></app-explore-meet>
    </section>
</div>
