import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeepLinkService } from 'src/app/core/services/deep-link.service';
import { UtilService } from 'src/app/core';

@Injectable({
    providedIn: 'root'
})
export class DownloadAppService {
    constructor(
        private deepLinkService: DeepLinkService,
        private activatedRoute: ActivatedRoute,
        private utilService: UtilService
    ) {}

    downloadJioMeet() {
        const os = this.utilService.getOS();
        switch (os) {
            case 'Windows':
                this.utilService.downloadJioMeet();
                break;
            case 'Mac OS':
                {
                    this.deepLinkService.handleDeepLink(this.activatedRoute);
                    setTimeout(() => {
                        this.utilService.downloadJioMeet();
                    }, 1000);
                }
                break;
            case 'iOS':
            case 'Android':
                {
                    this.deepLinkService.handleDeepLink(this.activatedRoute);
                }
                break;
            default:
                this.utilService.downloadJioMeet();
                break;
        }
    }

    downloadJioMeetForOS(selectedOS = '') {
        const os = this.utilService.getOS();
        switch (selectedOS) {
            case 'Windows':
                this.utilService.downloadJioMeet(false, selectedOS);
                break;
            case 'Mac OS':
                {
                    if (os === selectedOS) {
                        this.deepLinkService.handleDeepLink(this.activatedRoute);
                    }
                    setTimeout(() => {
                        this.utilService.downloadJioMeet(false, selectedOS);
                    }, 1000);
                }
                break;
            case 'iOS':
            case 'Android':
                {
                    if (os === selectedOS) {
                        this.deepLinkService.handleDeepLink(this.activatedRoute);
                    } else {
                        this.utilService.downloadJioMeet(false, selectedOS);
                    }
                }
                break;
            default:
                this.utilService.downloadJioMeet();
                break;
        }
    }
}
