<nav
    class="navbar navbar-expand-lg navbar-light"
    [class.width-1366]="enableWidth_1366"
    (outside)="onOutsideClick($event)"
    [class.dark-head]="enableChatForAbove1080()"
    [class.dark]="activeTheme === 'themeDark'"
    id="navbar"
    *ngIf="!this.isMattermostEnabled"
>
    <div class="top-skip-container" [ngClass]="!showSkipcontentbtn ? 'hide-skipbtn' : ''">
        <button
            class="skip-main-content-btn"
            (click)="handleSkipButtonClick()"
            (keydown.enter)="handleSkipButtonClick()"
            tabindex="0"
            aria-label="Skip to main content"
        >
            <span> Skip to {{ skipButtonText }}</span>
        </button>
    </div>

    <div
        class="top-header"
        *ngIf="!isMobile && enableWidth_1366"
        [class.hidden]="topHeaderVisibility === false"
        [ngClass]="{ hideTopHeader: mstIntegration }"
    >
        <div class="collapse navbar-collapse" [class.show]="!!showMenu">
            <ul class="navbar-nav ml-auto my-2 my-lg-0">
                <li class="nav-item active">
                    <a
                        class="join-sm"
                        tabindex="0"
                        aria-label="JioMeet whatsapp contact: +918369100100"
                        href="{{ whatsAppBotURL }}"
                        target="_blank"
                        (click)="whatsAppClick()"
                        ><span class="icon" aria-hidden="true"
                            ><img src="assets/img/website/whatsapp.svg" alt="JioMeet WhatsApp No"
                        /></span>
                        <span aria-hidden="true">+91 8369 100 100</span></a
                    >
                </li>
                <div class="nav-item active" *ngIf="!desktopAppService?.isDesktopApp">
                    <li>
                        <a class="pointer" href="javascript:void(0)" (click)="handleDownloadApp()">{{
                            'website.download_app' | translate
                        }}</a>
                    </li>
                </div>
                <li class="nav-item active">
                    <a
                        class="pointer"
                        tabindex="0"
                        aria-label="help"
                        role="link"
                        (keyup.enter)="helpClick()"
                        (click)="helpClick()"
                        >{{ 'website_header.help' | translate }}</a
                    >
                </li>

                <li class="nav-item active" *ngIf="!isAuthenticated" aria-label="Sign In">
                    <a class="pointer" (click)="navigateToSignIn()" tabindex="0" (keyup.enter)="navigateToSignIn()">{{
                        'landing_or_home_page.header.sign_in' | translate
                    }}</a>
                </li>

                <li class="nav-item dropdown lang-menu caret">
                    <a
                        tabindex="0"
                        class="pointer"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        id="navbarUserDropdown"
                    >
                        <img
                            src="assets/img/glob-lang-dark.svg"
                            alt=""
                            *ngIf="activeTheme !== 'themeDark' || currentScrollTop > 690"
                        />
                        <img
                            src="assets/img/glob-lang-light.svg"
                            alt=""
                            *ngIf="activeTheme === 'themeDark' && currentScrollTop < 690"
                        />
                        <span *ngIf="activeLang === 'en'">English</span>
                        <span *ngIf="activeLang === 'hi'">हिंदी</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarUserDropdown">
                        <div
                            class="lang-link"
                            tabindex="0"
                            aria-label="English"
                            (click)="setLang('en')"
                            (keydown.enter)="setLang('en')"
                            [class.active]="activeLang === 'en'"
                        >
                            English
                        </div>
                        <div
                            class="lang-link"
                            tabindex="0"
                            aria-label="Hindi"
                            (click)="setLang('hi')"
                            (keydown.enter)="setLang('hi')"
                            [class.active]="activeLang === 'hi'"
                        >
                            हिंदी
                            <!-- <span class="coming-soon">{{ 'website.coming_soon' | translate }}</span> -->
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div
        class="main-header"
        [class.main-header-with-short]="enableChatForAbove1080()"
        [class.without-top-header]="!enableWidth_1366"
        [class.without-top-header-mac-app]="isMacElectronApp"
    >
        <a
            class="navbar-brand"
            tabindex="0"
            (keyup.enter)="navigateToJiomeet()"
            role="button"
            [attr.aria-label]="mainTabAccessibilityLabel"
            (click)="!isDesktopApp ? navigateToJiomeet() : null"
            [ngClass]="{ disableClick: mstIntegration }"
        >
            <div class="logo-wrap pointer">
                <img
                    src="assets/img/website/{{
                        !isMobile
                            ? enableChatForAbove1080()
                                ? 'website_logo_header_light.svg'
                                : 'website_logo_header.svg'
                            : 'website_logo_header_mob.svg'
                    }}"
                    *ngIf="activeTheme !== 'themeDark' || currentScrollTop > 690"
                    alt=""
                />
                <img
                    src="assets/img/website/{{
                        !isMobile ? 'website_logo_dark_header.svg' : 'website_logo_dark_header_mob.svg'
                    }}"
                    *ngIf="activeTheme === 'themeDark' && currentScrollTop < 690"
                    alt=""
                />
            </div>
        </a>
        <div class="dashboard" *ngIf="!isMobile && !enableWidth_1366">
            <img src="assets/img/dashboard-saperator.svg" alt="" *ngIf="!enableChatForAbove1080()" />
            <img src="assets/img/dashboard-separator-light.svg" alt="" *ngIf="enableChatForAbove1080()" />
        </div>
        <div
            class="dashboard link"
            [class.link]="enableChatForAbove1080()"
            [class.no-margin]="enableChatForAbove1080()"
            *ngIf="!isMobile && !enableWidth_1366"
        >
            <a
                class="home-focus"
                id="home-accessibility"
                *ngIf="enableChatForAbove1080()"
                tabindex="0"
                role="button"
                [attr.aria-label]="homeTabAccessibilityLabel"
                (keyup.enter)="navigateToHome()"
                (click)="navigateToHome()"
                [class.light-color]="!isActiveTab('home')"
            >
                <div class="header-options">
                    <img
                        src="assets/img/home-active.svg"
                        [ngStyle]="{ display: isActiveTab('home') ? 'block' : 'none' }"
                        alt=""
                    />
                    <img
                        src="assets/img/home-inactive.svg"
                        [ngStyle]="{ display: !isActiveTab('home') ? 'block' : 'none' }"
                        alt=""
                    />
                    <span class="header-text"> {{ 'website_header.home' | translate }} </span>
                </div>
            </a>
            <span class="active" *ngIf="isActiveTab('home')"></span>
        </div>
        <div class="dashboard link chat-margin" *ngIf="enableChatForAbove1080()">
            <a
                tabindex="0"
                id="chat-accessibility"
                role="button"
                (keyup.enter)="navigateToChat()"
                [attr.aria-label]="chatTabAccessibilityLabel"
                (click)="navigateToChat()"
                [class.light-color]="!isActiveTab('chat')"
            >
                <div class="header-options">
                    <img
                        src="assets/img/chat-active.svg"
                        [class.chat-icon-adjust]="appService.getUnreadCount() > 0"
                        [ngStyle]="{ display: isActiveTab('chat') ? 'inherit' : 'none' }"
                        alt=""
                    />
                    <img
                        src="assets/img/chat-inactive.svg"
                        [class.chat-icon-adjust]="appService.getUnreadCount() > 0"
                        [ngStyle]="{ display: !isActiveTab('chat') ? 'inherit' : 'none' }"
                        alt=""
                    />
                    <label class="sr-only">Unread chat count</label>
                    <span
                        class="unread-count"
                        tabindex="0"
                        [attr.aria-label]="
                            appService.getUnreadCount() > 0
                                ? appService.getUnreadCount() + ' unread messages'
                                : 'No unread messages'
                        "
                        *ngIf="appService.getUnreadCount() > 0"
                    >
                        {{ appService.getUnreadCount() }}
                    </span>
                    <span class="header-text"> {{ 'website_header.chats' | translate }} </span>
                </div>
            </a>
            <span class="active" *ngIf="isActiveTab('chat')"></span>
        </div>
        <div class="dashboard link" [class.link]="enableChatForAbove1080()" *ngIf="!isMobile && !enableWidth_1366">
            <span *ngIf="!enableChatForAbove1080()">{{ 'landing_or_home_page.header.my_dashboard' | translate }}</span>
            <a
                *ngIf="enableChatForAbove1080()"
                id="meeting-accessibility"
                (click)="navigateToDashboard()"
                tabindex="0"
                role="button"
                (keyup.enter)="navigateToDashboard()"
                [attr.aria-label]="meetingTabAccessibilityLabel"
                [class.light-color]="!isActiveTab('dashboard')"
            >
                <div class="header-options">
                    <img
                        src="assets/img/meetings-active.svg"
                        [ngStyle]="{ display: isActiveTab('dashboard') ? 'block' : 'none' }"
                        alt=""
                    />
                    <img
                        src="assets/img/meetings-inactive.svg"
                        [ngStyle]="{ display: !isActiveTab('dashboard') ? 'block' : 'none' }"
                        alt=""
                    />
                    <span class="header-text">
                        {{ 'website_header.meetings' | translate }}
                    </span>
                </div>
            </a>
            <span class="active" *ngIf="isActiveTab('dashboard')"></span>
        </div>
        <div class="dashboard link" *ngIf="enableContactsForAbove1080()">
            <a
                tabindex="0"
                role="button"
                id="contact-accessibility"
                (keyup.enter)="navigateToContacts()"
                [attr.aria-label]="contactTabAccessibilityLabel"
                (click)="navigateToContacts()"
                [class.light-color]="!isActiveTab('contacts')"
            >
                <div class="header-options">
                    <img
                        src="assets/img/contacts-active.svg"
                        [ngStyle]="{ display: isActiveTab('contacts') ? 'block' : 'none' }"
                        alt=""
                    />
                    <img
                        src="assets/img/contacts-inactive.svg"
                        [ngStyle]="{ display: !isActiveTab('contacts') ? 'block' : 'none' }"
                        alt=""
                    />
                    <span class="header-text"> {{ 'website_header.contacts' | translate }} </span>
                </div>
            </a>
            <span class="active" *ngIf="isActiveTab('contacts')"></span>
        </div>
        <span class="active" *ngIf="isActiveTab('contacts')"></span>
        <div class="navbar-toggler" *ngIf="isMobile" [ngClass]="{ hideToggler: mstIntegration }">
            <a class="pointer" href="/webhelp" target="_blank"
                ><span class="nav-item">{{ 'website_header.help' | translate }}</span></a
            >
            <div class="btn-wrap" [class.active]="showMenu" *ngIf="!isAuthenticated">
                <button type="button" (click)="toggleMenu()"></button>
            </div>
            <div class="btn-wrap" [class.active]="showMenu" *ngIf="isAuthenticated">
                <button type="button" (click)="toggleMenu()"></button>
            </div>
        </div>
        <div
            class="collapse navbar-collapse"
            [class.show]="!!showMenu"
            id="navbarToggler"
            [ngClass]="{ hideToggler: mstIntegration }"
        >
            <ul class="navbar-left navbar-nav ml-auto my-2 my-lg-0" *ngIf="!showMenu && showHomeMegaMenues">
                <li
                    *ngIf="!isDesktopApp"
                    class="nav-item caret"
                    tabindex="0"
                    id="products"
                    (keydown.enter)="activeHover ? handleMouseLeave($event) : handleMouseEnter($event, 'products')"
                    (keydown)="onKeyDown($event)"
                    (mouseenter)="handleMouseEnter($event, 'products')"
                    (mouseleave)="handleMouseLeave()"
                    [class.hover]="!!activeHover && activeHoverMenu === 'products'"
                >
                    <a
                        class="pointer"
                        id="headerProductButton"
                        role="menuitem"
                        aria-label="Navigate to Products Menu dropdown"
                    >
                        {{ 'website_header.products' | translate }}</a
                    >
                    <div class="dropdown-submenu">
                        <app-megamenu-submenu
                            [subMenuData]="megaMenuData?.products"
                            [hidden]="!this.activeHover"
                            parentMenu="products"
                        ></app-megamenu-submenu>
                    </div>
                </li>
                <li
                    *ngIf="!isDesktopApp"
                    class="nav-item caret"
                    id="useCases"
                    tabindex="0"
                    (keydown.enter)="activeHover ? handleMouseLeave($event) : handleMouseEnter($event, 'useCases')"
                    (keydown)="onKeyDown($event)"
                    (mouseenter)="handleMouseEnter($event, 'useCases')"
                    (mouseleave)="handleMouseLeave($event)"
                    [class.hover]="!!activeHover && activeHoverMenu === 'useCases'"
                >
                    <a
                        class="pointer"
                        id="headerProductButton"
                        role="menuitem"
                        aria-label="Navigate to Use cases menu dropdown"
                        >{{ 'website_header.use_cases' | translate }}
                    </a>
                    <div class="dropdown-submenu">
                        <app-megamenu-submenu
                            [subMenuData]="megaMenuData?.useCases"
                            parentMenu="useCases"
                            [hidden]="!this.activeHover"
                        ></app-megamenu-submenu>
                    </div>
                </li>
                <li *ngIf="!isDesktopApp" class="nav-item" [class.isActive]="isActiveTab('contactsales')">
                    <a
                        class="pointer"
                        id="headerProductButton"
                        tabindex="0"
                        (keydown.enter)="navigateTo('contactsales')"
                        (click)="navigateTo('contactsales')"
                        aria-label="Navigate to Sales page"
                    >
                        {{ 'website_header.sales' | translate }}
                    </a>
                </li>

                <li *ngIf="!isDesktopApp" class="nav-item" [class.isActive]="isActiveTab('pricing')">
                    <a
                        class="pointer"
                        tabindex="0"
                        id="headerProductButton"
                        (keydown.enter)="navigateToPricing()"
                        (click)="navigateToPricing()"
                        aria-label="Navigate to Pricing page"
                    >
                        {{ 'website_header.pricing' | translate }}
                    </a>
                </li>

                <li
                    *ngIf="!isDesktopApp"
                    class="nav-item caret"
                    id="more"
                    tabindex="0"
                    (keydown.enter)="activeHover ? handleMouseLeave($event) : handleMouseEnter($event, 'more')"
                    (keydown)="onKeyDown($event)"
                    (mouseenter)="handleMouseEnter($event, 'more')"
                    (mouseleave)="handleMouseLeave($event)"
                    [class.hover]="!!activeHover && activeHoverMenu === 'more'"
                >
                    <a
                        class="pointer"
                        role="menuitem"
                        aria-label="Navigate to more menu dropdown"
                        id="headerProductButton"
                        >{{ 'website_header.more' | translate }}</a
                    >
                    <div class="dropdown-submenu">
                        <app-megamenu-submenu
                            [subMenuData]="megaMenuData?.more"
                            [hidden]="!activeHover"
                            parentMenu="more"
                        ></app-megamenu-submenu>
                    </div>
                </li>
            </ul>

            <div class="navbar-left">
                <div class="profile-mobileview" *ngIf="isMobile && isAuthenticated">
                    <div class="user-info">
                        <ng-container *ngIf="!currentUser?.imageMeta?.path; else elseTemplate">
                            <div
                                class="gradientBorder"
                                [ngClass]="[
                                    currentUser.isOTT && currentUser.proStatus ? 'gradientBorder-pro' : '',
                                    !currentUser.isOTT && currentUser.tenantId != null ? 'gradientBorder-custom' : '',
                                    currentUser.isOTT && !currentUser.proStatus ? 'gradientBorder' : ''
                                ]"
                            >
                                <span
                                    class="textinitials"
                                    [class.yellow-border]="showProBadge"
                                    [ngStyle]="{ 'background-color': colorChosen }"
                                    >{{ participantInitials }}</span
                                >
                                <span class="crown-image-profile-dropdown-mobile">
                                    <img [src]="statusImage" />
                                </span>
                            </div>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <div>
                                <div
                                    class="img"
                                    [ngClass]="[
                                        currentUser.isOTT && currentUser.proStatus ? 'img-pro' : '',
                                        !currentUser.isOTT && currentUser.tenantId != null ? 'img-custom' : ''
                                    ]"
                                >
                                    <img
                                        [class.yellow-border]="showProBadge"
                                        [class.pro-user-popup-user]="showProBadge"
                                        [src]="
                                            profilePhotoService.getProfilePic(currentUser?._id) | imageSrcTransformer
                                        "
                                        alt=""
                                        *ngIf="profilePicPath"
                                    />
                                    <span class="crown-image-profile-dropdown-mobile">
                                        <img [src]="statusImage" />
                                    </span>
                                </div>
                            </div>
                        </ng-template>

                        <div class="username">
                            <span title="{{ currentUser.name + ' ' + currentUser.lname }}"
                                >{{ currentUser.name + ' ' + currentUser.lname }}
                            </span>
                            <span>{{ currentUser?.email || currentUser?.displayPhoneNo }}</span>
                        </div>
                    </div>
                    <div
                        *ngIf="currentUser.isOTT && currentUser.proStatus && subscriptionHoursLeft > 0"
                        class="enterpriseBadge"
                    >
                        <div *ngIf="showProBadge" class="box-container" tabindex="0" aria-label="Plus">
                            <img
                                *ngIf="currentUser?.planName.toLowerCase() === 'pro'"
                                src="assets/images/proCrown.svg"
                                alt=""
                            />
                            <img
                                *ngIf="currentUser?.planName.toLowerCase() === 'plus'"
                                src="assets/images/plusCrown.svg"
                                alt=""
                            />
                        </div>
                        <label
                            class="daysCountText"
                            [ngStyle]="{ color: subscriptionHoursLeft <= 30 * 24 ? '#F50031' : '#354773' }"
                        >
                            <span *ngIf="subscriptionHoursLeft > 24" tabindex="0">
                                {{ Math.floor(subscriptionHoursLeft / 24 || 0) }}
                                {{ 'website_header.days_left' | translate }}
                            </span>
                            <span *ngIf="subscriptionHoursLeft <= 24" tabindex="0">
                                {{ Math.floor(subscriptionHoursLeft || 0) }}
                                {{ 'website_header.hours_left' | translate }}
                            </span>
                        </label>
                    </div>
                    <!--Custom Badge-->
                    <div *ngIf="!currentUser.isOTT && currentUser.tenantId != null" class="CustomBadge">
                        <div class="box-container1" tabindex="0" aria-label="custom">
                            <img src="assets/images/custom-member.svg" alt="" />
                            <label class="badgeName">{{ 'website_header.custom' | translate }}</label>
                        </div>
                    </div>

                    <div
                        class="progress-bar"
                        *ngIf="currentUser.isOTT && currentUser.proStatus && subscriptionHoursLeft > 0"
                        [ngStyle]="{ 'background-color': subscriptionHoursLeft <= 30 * 24 ? '#FEECE9' : '#f6e9cd' }"
                    >
                        <div
                            class="progress"
                            [ngStyle]="{
                                width: daysInPercenatge + '%',
                                'background-color': subscriptionHoursLeft <= 30 * 24 ? '#F50031' : '#ddb141'
                            }"
                        ></div>
                    </div>
                    <div class="divider"></div>
                    <div class="details profile-options">
                        <app-profile-menu-options></app-profile-menu-options>
                    </div>
                    <div class="divider"></div>
                    <div class="profile-options">
                        <div class="dashboard-link pointer" [routerLink]="['/meetings']">
                            {{ 'landing_or_home_page.header.my_dashboard' | translate }}
                        </div>
                        <div class="jio-events-portal-link pointer" (click)="navigateToEventsPortal()">
                            {{ 'landing_or_home_page.header.jio_events_dashboard' | translate }}
                        </div>
                        <div
                            class="admin-portal-link pointer"
                            *ngIf="!currentUser?.isOTT && currentUser?.tenantId"
                            (click)="navigateToAdminPortal()"
                        >
                            {{ 'landing_or_home_page.header.admin_portal' | translate }}
                        </div>
                        <div (click)="showAppUpdatePopup()" *ngIf="desktopAppService?.isDesktopApp">
                            {{ 'landing_or_home_page.header.check_for_updates' | translate }}
                        </div>
                        <div *ngIf="desktopAppService?.electronAppVersion && desktopAppService?.isDesktopApp">
                            {{ 'landing_or_home_page.header.electron_app_version' | translate }}
                            {{ this.desktopAppService?.electronAppVersion }}
                        </div>
                    </div>
                    <div>
                        <app-menu-accordion
                            *ngIf="router.url != '/meetings'"
                            [accordionData]="mobileMegaMenuData"
                            menuPos="header"
                            (languageChangeClick)="setLang($event)"
                        ></app-menu-accordion>
                    </div>
                    <a class="join-sm" [class.tag-color]="isMobile" href="{{ whatsAppBotURL }}" target="_blank">
                        <span>{{ 'website_header.jiomeet_whatsapp_no' | translate }} </span>
                        <span>+91 8369 100 100</span>
                    </a>
                </div>
                <ng-container *ngIf="showMenu && showHomeMegaMenues && !isAuthenticated">
                    <app-menu-accordion
                        [accordionData]="mobileMegaMenuData"
                        menuPos="header"
                        (languageChangeClick)="setLang($event)"
                    ></app-menu-accordion>
                    <a class="join-sm" [class.tag-color]="isMobile" href="{{ whatsAppBotURL }}" target="_blank">
                        <span>{{ 'website_header.jiomeet_whatsapp_no' | translate }} </span>
                        <span>+91 8369 100 100</span>
                    </a>
                </ng-container>
            </div>

            <div class="search-block" *ngIf="showGlobalChatAbove1080()">
                <div class="nav-item global-search">
                    <div class="search-container" (outside)="onOutsideClick($event)">
                        <img class="search-icon" src="assets/img/chat-search-icon.svg" alt="" />
                        <input
                            type="text"
                            class="search"
                            #searchInput
                            placeholder="{{ 'chat.search_contacts_groups' | translate }}"
                            [(ngModel)]="searchStr"
                            (focus)="onFocus()"
                        />
                        <div class="users-container" *ngIf="showUsersPanel">
                            <div
                                class="no-results"
                                *ngIf="
                                    noUsersFound &&
                                    !isGlobalSearchEnabled &&
                                    (
                                        userService.localSearches
                                        | filterContactBySearchKey
                                            : searchStr
                                            : ['fullname', 'name', 'lname', 'phoneNo', 'email', 'title']
                                    ).length == 0
                                "
                            >
                                {{ 'chat.no_results_found' | translate }}
                            </div>
                            <div
                                class="no-results"
                                *ngIf="
                                    noUsersFound &&
                                    isGlobalSearchEnabled &&
                                    (
                                        userService.localSearches
                                        | filterContactBySearchKey
                                            : searchStr
                                            : ['fullname', 'name', 'lname', 'phoneNo', 'email', 'title']
                                    ).length == 0
                                "
                            >
                                {{ 'chat.no_results_found' | translate }}
                            </div>
                            <div
                                class="users"
                                *ngIf="
                                    suggestionsCount > 0 ||
                                    (isGlobalSearchEnabled && userService.localSearches?.length > 0)
                                "
                            >
                                <ng-container *ngIf="isGlobalSearchEnabled">
                                    <div
                                        class="user checkbox-wrapper"
                                        *ngFor="
                                            let user of userService.localSearches
                                                | filterContactBySearchKey
                                                    : searchStr
                                                    : ['fullname', 'lname', 'phoneNo', 'title', 'name', 'email']
                                        "
                                    >
                                        <ng-container
                                            *ngIf="
                                                !user?.title &&
                                                (user?._id?.substring(0, 2) === 'u-' ||
                                                    user?._id?.substring(0, 2) === 'c-')
                                            "
                                        >
                                            <label class="user-tile">
                                                <div class="checkbox-holder">
                                                    <input
                                                        type="checkbox"
                                                        [checked]="checkSelection(user?._id)"
                                                        (change)="addOrRemoveUser(user)"
                                                    />
                                                </div>
                                                <div class="avatar">
                                                    <span
                                                        appAvatarWithInitials
                                                        name="{{ user?.name }} {{ user?.lname }}"
                                                    ></span>
                                                </div>
                                                <span
                                                    class="user-status"
                                                    *ngIf="user?.status"
                                                    [class.online]="user?.status === 'online'"
                                                    [class.offline]="user?.status === 'offline'"
                                                    [class.busy]="user?.status === 'busy'"
                                                    [class.away]="user?.status === 'away'"
                                                ></span>
                                                <div class="info">
                                                    <div class="name" [class.pad-left-7]="!user?.status">
                                                        {{ user?.name }} {{ user?.lname }}
                                                    </div>
                                                    <div
                                                        class="email"
                                                        [class.pad-left-7]="
                                                            user?.phoneNo && !user?.email && !user?.status
                                                        "
                                                    >
                                                        {{ user?.email || user?.phoneNo }}
                                                    </div>
                                                </div>
                                            </label>
                                        </ng-container>
                                        <ng-container *ngIf="user?.title || user?._id.substring(0, 2) === 'g-'">
                                            <label class="user-tile" (click)="initGroupChat(user)">
                                                <div class="avatar"><span class="fa fa-users group-icon"></span></div>
                                                <div class="info">
                                                    <div class="name pad-left-7">{{ user?.title }}</div>
                                                    <div class="email pad-left-7">
                                                        {{ user?.members?.length }}
                                                        {{ 'chat.participants' | translate }}
                                                    </div>
                                                </div>
                                            </label>
                                        </ng-container>
                                        <ng-container *ngIf="user?._id?.substring(0, 2) === 'vc' && !user?.title">
                                            <label class="user-tile" (click)="openVCRoom(user)">
                                                <div class="avatar">
                                                    <span appVcroomAvatar name="{{ user?.name }}"></span>
                                                </div>
                                                <div class="info">
                                                    <div class="name pad-left-7">
                                                        {{ user?.name }}
                                                    </div>
                                                    <div class="email pad-left-7">
                                                        {{ user?.ipaddress }}
                                                    </div>
                                                </div>
                                            </label>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <div class="user checkbox-wrapper" *ngFor="let user of listOfUsers.allUsers">
                                    <label class="user-tile">
                                        <div class="checkbox-holder">
                                            <input
                                                type="checkbox"
                                                [checked]="checkSelection(user?._id)"
                                                (change)="addOrRemoveUser(user)"
                                            />
                                        </div>
                                        <div class="avatar">
                                            <span
                                                appAvatarWithInitials
                                                name="{{ user?.name }} {{ user?.lname }}"
                                            ></span>
                                        </div>
                                        <span
                                            class="user-status"
                                            *ngIf="user?.status"
                                            [class.online]="user?.status === 'online'"
                                            [class.offline]="user?.status === 'offline'"
                                            [class.busy]="user?.status === 'busy'"
                                            [class.away]="user?.status === 'away'"
                                        ></span>
                                        <div class="info">
                                            <div class="name" [class.pad-left-7]="!user?.status">
                                                {{ user?.name }} {{ user?.lname }}
                                            </div>
                                            <div
                                                class="email"
                                                [class.pad-left-7]="
                                                    user?.type == 'user' &&
                                                    ((user?.phoneNo && !user?.email) || (!user?.phoneNo && user?.email))
                                                "
                                            >
                                                {{ user?.email || user?.phoneNo }}
                                            </div>
                                        </div>
                                    </label>
                                </div>

                                <div class="user checkbox-wrapper" *ngFor="let group of listOfUsers.groups">
                                    <label class="user-tile" (click)="initGroupChat(group)">
                                        <div class="avatar"><span class="fa fa-users group-icon"></span></div>
                                        <div class="info">
                                            <div class="name pad-left-7">{{ group?.title }}</div>
                                            <div class="email pad-left-7">
                                                {{ group?.members?.length }} {{ 'chat.participants' | translate }}
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="user checkbox-wrapper" *ngFor="let vc of listOfUsers.vcs">
                                    <label class="user-tile" (click)="openVCRoom(vc)">
                                        <div class="avatar">
                                            <span appVcroomAvatar name="{{ vc?.name }}"></span>
                                        </div>
                                        <div class="info">
                                            <div class="name pad-left-7">
                                                {{ vc?.name }}
                                            </div>
                                            <div class="email pad-left-7">
                                                {{ vc?.ipaddress }}
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div
                                class="actions-container"
                                *ngIf="
                                    (!noUsersFound ||
                                        !(
                                            userService.localSearches
                                            | filterContactBySearchKey
                                                : searchStr
                                                : ['fullname', 'lname', 'phoneNo', 'name', 'email', 'title']
                                        ).length == 0) &&
                                    (selectedUsers.length > 0 ||
                                        suggestionsCount > 0 ||
                                        userService.localSearches?.length > 0)
                                "
                            >
                                <div class="list-of-users">
                                    <div class="user" *ngFor="let user of selectedUsers">
                                        <div class="user-btn">
                                            <div class="avatar">
                                                <span
                                                    appAvatarWithInitials
                                                    name="{{ user?.name }} {{ user?.lname }}"
                                                ></span>
                                            </div>
                                            <span
                                                class="user-status"
                                                [class.online]="user?.status === 'online'"
                                                [class.offline]="user?.status === 'offline'"
                                                [class.busy]="user?.status === 'busy'"
                                                [class.away]="user?.status === 'away'"
                                            ></span>
                                            <span class="username" [class.pad-left-7]="!user?.status">
                                                {{ user?.name }} {{ user?.lname }}
                                            </span>
                                            <img
                                                alt=""
                                                class="close-icon"
                                                (click)="addOrRemoveUser(user, $event)"
                                                src="assets/img/close-icon-light.svg"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button
                                        class="text-center chat-button"
                                        [ngClass]="selectedUsers?.length > 0 ? 'enable' : ''"
                                        [disabled]="selectedUsers?.length === 0"
                                        (click)="initChat()"
                                    >
                                        <span class="fa fa-comment"></span>
                                        <img src="assets/img/contacts/chat-white.svg" /> {{ 'chat.chat' | translate }}
                                    </button>
                                    <button
                                        class="text-center video-button"
                                        [ngClass]="selectedUsers?.length > 0 ? 'enable' : ''"
                                        [disabled]="selectedUsers?.length === 0"
                                        (click)="makeCall(selectedUsers, 'video', $event)"
                                    >
                                        <img
                                            src="assets/img/video-disabled.svg"
                                            [ngStyle]="{ display: selectedUsers?.length < 1 ? 'inherit' : 'none' }"
                                            alt=""
                                        />
                                        <img
                                            src="assets/img/contacts/video-blue.svg"
                                            [ngStyle]="{ display: selectedUsers?.length > 0 ? 'inherit' : 'none' }"
                                            alt=""
                                        />
                                        <img src="assets/img/contacts/video-white.svg" />
                                        {{ 'contacts.meet-button' | translate }}
                                    </button>
                                    <button
                                        class="text-center call-button"
                                        [disabled]="selectedUsers?.length === 0"
                                        [ngClass]="selectedUsers?.length > 0 ? 'enable' : ''"
                                        (click)="makeCall(selectedUsers, 'audio', $event)"
                                    >
                                        <img
                                            src="assets/img/call-disabled.svg"
                                            [ngStyle]="{ display: selectedUsers?.length < 1 ? 'inherit' : 'none' }"
                                            alt=""
                                        />
                                        <img
                                            src="assets/img/contacts/call-blue.svg"
                                            [ngStyle]="{ display: selectedUsers?.length > 0 ? 'inherit' : 'none' }"
                                            alt=""
                                        />
                                        <img src="assets/img/contacts/call-white.svg" />
                                        {{ 'contacts.call-button' | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ul class="navbar-nav ml-auto my-lg-0 navbar-right">
                <li class="nav-item active" *ngIf="!!enableWidth_1366 && !isMobile">
                    <a
                        class="pointer"
                        id="headerJoinMeetingButton"
                        (click)="navigateToJoinMeeting()"
                        (keyup.enter)="navigateToJoinMeeting()"
                        tabindex="0"
                        aria-label="{{ 'landing_or_home_page.header.join_meeting' | translate }}"
                        >{{ 'landing_or_home_page.header.join_meeting' | translate }}</a
                    >
                </li>
                <li class="nav-item mr-0 caret" *ngIf="isAuthenticated && enableWidth_1366">
                    <button
                        class="custom-action-btn btn-my-account"
                        id=""
                        (click)="navigateToHome()"
                        [class.secondary]="!isMobile"
                    >
                        {{ 'landing_or_home_page.header.my_account' | translate }}
                        <i class="fa fa-1px fa-chevron-right my-account-icon"></i>
                    </button>
                </li>

                <li class="nav-item active" *ngIf="isMobile && !isAuthenticated">
                    <button class="secondary signin-button" id="headerJoinMeetingButton" (click)="navigateToSignIn()">
                        {{ 'landing_or_home_page.header.sign_in' | translate }}
                    </button>
                </li>
                <li class="nav-item mr-0" *ngIf="!isAuthenticated">
                    <button
                        tabindex="0"
                        aria-label="Sign Up"
                        class="custom-action-btn auth-button"
                        id="headerSignInButton"
                        (click)="navigateToSignUp()"
                        (keydown.enter)="navigateToSignUp()"
                        [class.secondary]="!isMobile"
                    >
                        {{ 'landing_or_home_page.header.sign_up' | translate }}
                    </button>
                </li>

                <li class="nav-item mr-0" *ngIf="isMobile">
                    <button
                        class="custom-action-btn secondary auth-button"
                        id="headerSignInButton"
                        (click)="navigateToJoinMeeting()"
                    >
                        {{ 'landing_or_home_page.header.join_meeting' | translate }}
                    </button>
                </li>

                <li class="nav-item" *ngIf="isAuthenticated && isMobile">
                    <button class="custom-action-btn auth-button secondary" id="headerSignInButton" (click)="logout()">
                        {{ 'landing_or_home_page.header.sign_out' | translate }}
                    </button>
                </li>

                <li
                    class="nav-item profile-info"
                    *ngIf="!isMobile && isAuthenticated && !enableWidth_1366 && !enableChatForAbove1080()"
                >
                    <span title="{{ currentUser.name + ' ' + currentUser.lname }}" class="truncate-text">{{
                        currentUser.name + ' ' + currentUser.lname
                    }}</span>
                    <span>{{ currentUser?.email || currentUser?.displayPhoneNo }}</span>
                </li>
                <li
                    class="nav-item dropdown profile gradientBorder"
                    *ngIf="!isMobile && isAuthenticated && !enableWidth_1366"
                    [class.dashboard]="!enableWidth_1366 || topHeaderVisibility === false"
                    [class.pro-user-border]="showProBadge"
                    [ngClass]="[
                        currentUser.isOTT && currentUser.proStatus ? 'gradientBorder-pro' : '',
                        !currentUser.isOTT && currentUser.tenantId != null ? 'gradientBorder-custom' : '',
                        currentUser.isOTT && !currentUser.proStatus ? 'gradientBorder' : ''
                    ]"
                >
                    <div
                        tabindex="0"
                        aria-label="Profile info"
                        class="img-wrap pointer img-border"
                        role="button"
                        [ngClass]="currentUser.isOTT && !currentUser.proStatus ? '' : 'img-border'"
                        id="navbarUserDropdown"
                        (click)="clickProfileIcon()"
                        (keydown.enter)="clickProfileIcon()"
                        (outside)="clickOutsideProfileIcon()"
                    >
                        <!-- <img  [src]="profilePicPath | imageSrcTransformer" alt="" *ngIf="profilePicPath" /> -->
                        <ng-container *ngIf="!currentUser?.imageMeta?.path; else elseTemplate">
                            <span
                                class="textinitials"
                                [ngStyle]="{ 'background-color': colorChosen }"
                                appAvatarWithInitials
                                name="{{ currentUser.name }} {{ currentUser.lname }}"
                            ></span>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <div>
                                <div
                                    class="img"
                                    [class.margin-pro-user]="showProBadge"
                                    [ngClass]="[
                                        currentUser.isOTT && currentUser.proStatus ? 'img-pro' : '',
                                        !currentUser.isOTT && currentUser.tenantId != null ? 'img-custom' : '',
                                        currentUser.isOTT && !currentUser.proStatus ? 'img' : ''
                                    ]"
                                >
                                    <img
                                        [class.pro-user-popup-user]="showProBadge"
                                        [src]="profilePicPath | imageSrcTransformer"
                                        alt=""
                                        *ngIf="profilePicPath"
                                    />
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <span class="crown-image">
                        <img [src]="statusImage" />
                    </span>
                    <div
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="navbarUserDropdown"
                        [class.show]="showProfileMenu"
                    >
                        <div class="user-info" (click)="$event.stopPropagation()">
                            <div class="username">
                                <span
                                    class="truncate-text"
                                    tabindex="0"
                                    title="{{ currentUser.name + ' ' + currentUser.lname }}"
                                >
                                    {{ currentUser.name }} {{ currentUser.lname }}</span
                                >
                                <span tabindex="0">
                                    {{ currentUser?.email || currentUser?.displayPhoneNo }}
                                </span>
                            </div>

                            <!-- <div class="img">
                                <img  [src]="profilePicPath | imageSrcTransformer" alt="" *ngIf="profilePicPath" />
                            </div> -->
                            <ng-container *ngIf="!currentUser?.imageMeta?.path; else elseTemplate">
                                <div
                                    class="gradientBorder"
                                    [ngClass]="[
                                        currentUser.isOTT && currentUser.proStatus ? 'gradientBorder-pro' : '',
                                        !currentUser.isOTT && currentUser.tenantId != null
                                            ? 'gradientBorder-custom'
                                            : '',
                                        currentUser.isOTT && !currentUser.proStatus ? 'gradientBorder' : ''
                                    ]"
                                >
                                    <div
                                        class="textinitials"
                                        [class.pro-user-popup-user]="showProBadge"
                                        [ngStyle]="{ 'background-color': colorChosen }"
                                        appAvatarWithInitials
                                        name="{{ currentUser.name }} {{ currentUser.lname }}"
                                    ></div>
                                </div>
                            </ng-container>
                            <ng-template #elseTemplate>
                                <div>
                                    <div
                                        class="img"
                                        [ngClass]="[
                                            currentUser.isOTT && currentUser.proStatus ? 'img-pro' : '',
                                            !currentUser.isOTT && currentUser.tenantId != null ? 'img-custom' : '',
                                            currentUser.isOTT && !currentUser.proStatus ? 'img' : ''
                                        ]"
                                    >
                                        <img
                                            [class.pro-user-popup-user]="showProBadge"
                                            [src]="profilePicPath | imageSrcTransformer"
                                            alt=""
                                            *ngIf="profilePicPath"
                                        />
                                    </div>
                                </div>
                            </ng-template>
                            <span class="crown-image-profile-dropdown">
                                <img [src]="statusImage" />
                            </span>
                        </div>
                        <div
                            *ngIf="currentUser.isOTT && currentUser.proStatus && subscriptionHoursLeft > 0"
                            class="enterpriseBadge"
                        >
                            <div class="box-container" tabindex="0" aria-label="plus subscription User">
                                <!-- <img
                                    *ngIf="currentUser?.planName.toLowerCase() === 'pro'"
                                    src="assets/images/proCrown.svg"
                                    alt=""
                                />
                                <img
                                    *ngIf="currentUser?.planName.toLowerCase() === 'plus'"
                                    src="assets/images/plusCrown.svg"
                                    alt=""
                                /> -->
                                <img src="assets/images/crown.svg" alt="" />
                                <label class="badge-image" tabindex="0"
                                    >{{
                                        (currentUser?.planName.toLowerCase() === 'pro'
                                            ? 'website_header.pro'
                                            : 'website_header.plus'
                                        ) | translate
                                    }}
                                </label>
                            </div>
                            <label
                                class="daysCountText"
                                [ngStyle]="{ color: subscriptionHoursLeft <= 30 * 24 ? '#F50031' : '#354773' }"
                            >
                                <span *ngIf="subscriptionHoursLeft > 24" tabindex="0">
                                    {{ Math.floor(subscriptionHoursLeft / 24 || 0) }}
                                    {{ 'website_header.days_left' | translate }}
                                </span>
                                <span *ngIf="subscriptionHoursLeft <= 24" tabindex="0">
                                    {{ Math.floor(subscriptionHoursLeft || 0) }}
                                    {{ 'website_header.hours_left' | translate }}
                                </span></label
                            >
                        </div>
                        <!--Custom Badge-->
                        <div *ngIf="!currentUser.isOTT && currentUser.tenantId != null" class="CustomBadge">
                            <div class="box-container1" tabindex="0" aria-label="custom">
                                <img src="assets/images/custom-member.svg" alt="" />
                                <label class="badgeName">{{ 'website_header.custom' | translate }}</label>
                            </div>
                        </div>
                        <div
                            class="progress-bar"
                            *ngIf="currentUser.isOTT && currentUser.proStatus && subscriptionHoursLeft > 0"
                            [ngStyle]="{ 'background-color': subscriptionHoursLeft <= 30 * 24 ? '#FEECE9' : '#f6e9cd' }"
                        >
                            <div
                                class="progress"
                                [ngStyle]="{
                                    width: daysInPercenatge + '%',
                                    'background-color': subscriptionHoursLeft <= 30 * 24 ? '#F50031' : '#ddb141'
                                }"
                            ></div>
                        </div>
                        <div class="divider"></div>
                        <div class="details profile-options">
                            <app-profile-menu-options></app-profile-menu-options>
                        </div>
                        <div class="divider"></div>
                        <div class="details profile-options">
                            <div
                                class="jio-events-portal-link pointer"
                                tabindex="0"
                                aria-label="Navigate to Jio Events Dashboard page"
                                (keyup.enter)="navigateToEventsPortal()"
                                (click)="navigateToEventsPortal()"
                            >
                                {{ 'landing_or_home_page.header.jio_events_dashboard' | translate }}
                            </div>
                            <div
                                class="admin-portal-link pointer"
                                tabindex="0"
                                aria-label="Navigate to Admin Portal Page"
                                (keyup.enter)="navigateToAdminPortal()"
                                *ngIf="showProBadge"
                                (click)="navigateToAdminPortal()"
                            >
                                {{ 'landing_or_home_page.header.admin_portal' | translate }}
                            </div>
                            <div
                                class="check-for-updates pointer"
                                tabindex="0"
                                aria-label="Check for Updates"
                                (click)="showAppUpdatePopup()"
                                *ngIf="desktopAppService?.isDesktopApp && !desktopAppService?.appStoreDistributionBuild"
                            >
                                {{ 'landing_or_home_page.header.check_for_updates' | translate }}
                            </div>
                            <div *ngIf="desktopAppService?.isDesktopApp && desktopAppService?.electronAppVersion">
                                {{ 'landing_or_home_page.header.electron_app_version' | translate }}
                                {{ this.desktopAppService?.electronAppVersion }}
                            </div>
                        </div>
                        <div class="divider"></div>
                        <div class="dropdown-footer">
                            <div
                                class="logout"
                                (click)="logout()"
                                tabindex="0"
                                aria-label="Sigout from JioMeet"
                                (keyup.enter)="logout()"
                            >
                                {{ 'landing_or_home_page.header.sign_out' | translate }}
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>

<div *ngIf="!dashboardFloater && !autoJoin && !isDesktopApp" class="icon-bar" (outside)="onOutsideClickFloater($event)">
    <div class="sticky">
        <button
            id="sideBarCollapsed"
            tabindex="0"
            aria-label="Side bar, click to expand"
            (click)="sidebarToggle()"
            role="button"
        >
            <img aria-hidden="true" src="assets/img/enterprise/chat-communication-functional-filled.svg" /><br />
            <img aria-hidden="true" src="assets/img/enterprise/call-communication-functional-filled.svg" /><br />
            <img aria-hidden="true" src="assets/img/enterprise/whatsapp-functional-filled.svg" />
        </button>
        <button id="sideBarExpanded" style="display: none" (click)="sidebarToggle()">
            <div (click)="goToContactSales()">
                <img src="assets/img/enterprise/chat-communication-functional-filled.svg" />
                <span class="floater-option" tabindex="0" (keydown.enter)="goToContactSales()" role="button">
                    {{ 'customer_support_floater.chat_sales' | translate }}
                </span>
                <span class="arrow">></span>
            </div>
            <div (click)="openLetsChat()">
                <img src="assets/img/enterprise/call-communication-functional-filled.svg" />
                <span class="floater-option" tabindex="0" (keydown.enter)="openLetsChat()" role="button">
                    {{ 'customer_support_floater.contact_us' | translate }}
                </span>
                <span class="arrow">></span>
            </div>
            <button tabindex="1">
                <a href="{{ whatsAppBotURL }}" target="_blank" (click)="whatsapp_us()" tabindex="1">
                    <img src="assets/img/enterprise/whatsapp-functional-filled.svg" />
                    <span tabindex="0" (keydown.enter)="whatsapp_us()" role="button">+91 8369 100 100</span>
                </a>
            </button>
        </button>
    </div>
</div>

<div *ngIf="offerBanner && !bannersConfigData?.showHomePageStickyText && !isAuthenticated" class="offer-banner">
    <div class="banner-text">
        <span tabindex="0" class="offer">{{ 'dashboard_home.offer' | translate }}</span>
        <span tabindex="0" class="offer-text">{{ 'dashboard_home.offertext' | translate }} </span>
        <span (click)="goToContactSales()"
            ><u tabindex="0" role="link">{{ 'dashboard_home.clickhere' | translate }}</u></span
        >
    </div>
</div>
<div
    *ngIf="offerBanner && bannersConfigData?.showHomePageStickyText"
    [ngStyle]="{
        background: bannersConfigData?.showHomePageStickyText ? bannersConfigData?.homePageStickyTextGradient : ''
    }"
    [class.banner-position]="showFestivalUIChanges"
    class="offer-banner"
>
    <div class="banner-text" [style.color]="bannersConfigData?.homePageStickyTextColor">
        <span class="offer-text" *ngIf="activeLang === 'en'">
            {{ bannersConfigData?.homePageStickyTextContentEnglish }}
        </span>
        <span class="offer-text" *ngIf="activeLang === 'hi'">
            {{ bannersConfigData?.homePageStickyTextContentHindi }}
        </span>
    </div>
</div>

<ng-container *ngIf="desktopAppUpdaterService?.isDesktopApp">
    <app-electron-sticky-updater></app-electron-sticky-updater>
</ng-container>

<p-confirmDialog [transitionOptions]="'300ms'" acceptIcon="null" rejectIcon="null" appendTo="body">
    <ng-template pTemplate="header">
        <span class="p-dialog-title" tabindex="0" *ngIf="!desktopAppService?.showElectronMacStoreUpdatePopup">{{
            'sign_out_popup.title' | translate
        }}</span>
        <span class="p-dialog-title" tabindex="0" *ngIf="desktopAppService?.showElectronMacStoreUpdatePopup">{{
            'mac_store_update_popup.title' | translate
        }}</span>
    </ng-template>
</p-confirmDialog>

<app-call-dialer
    [userContact]="contactToCall"
    [type]="type"
    [event]="event"
    (disableCallLoader)="disableCallLoader($event)"
    *ngIf="enableCallLoader"
></app-call-dialer>
