<div class="testimonial" *ngIf="!isMobile">
    <div class="img-wrap">
        <img src="{{ testimonial.path }}" alt="" />
    </div>
    <div class="content">
        <div class="comment" tabindex="0" [attr.aria-label]="TestimonialCommentfun()">
            {{ testimonial.comment }}
        </div>
        <div class="name" tabindex="0" [attr.aria-label]="TestimonialNamefun()">{{ testimonial.userName }}</div>
        <div class="comment-info">
            <span class="rating" tabindex="0">
                <ng-container *ngFor="let rating of [1, 2, 3, 4, 5]; let i = index">
                    <span class="fa fa-star" [class.checked]="testimonial.rating > i"> </span>
                </ng-container>
                <span class="visually-hidden" [attr.aria-label]="getRatingAriaLabel()"></span>
            </span>
            <span tabindex="0" aria-label="{{ testimonial.date | date : 'dd MMM y' }}">{{
                testimonial.date | date : 'dd MMM y'
            }}</span>
        </div>
    </div>
</div>

<div class="testimonial" *ngIf="isMobile">
    <div class="comment">
        {{ testimonial.comment }}
    </div>

    <div class="content">
        <div class="img-wrap">
            <img src="{{ testimonial.path }}" alt="" />
        </div>
        <div class="user-info">
            <div class="name">{{ testimonial.userName }}</div>
            <div class="comment-info">
                <span class="rating">
                    <ng-container *ngFor="let rating of [1, 2, 3, 4, 5]; let i = index">
                        <span class="fa fa-star" [class.checked]="testimonial.rating > i"> </span>
                    </ng-container>
                </span>
                <span>{{ testimonial.date | date : 'dd MMM y' }}</span>
            </div>
        </div>
    </div>
</div>
