<div class="website-enterprise">
    <div class="heading-0 m-bot-45 text-center pricing_page" tabindex="0">
        {{ 'website_enterprise.compare_enterprise_retail' | translate }}
    </div>
    <div class="price-compare m-bot-45" #compareContainer></div>
    <div class="parent-container">
        <div class="dialogclass"><p-confirmDialog key="pricing" [class]="'custom-dialog'"></p-confirmDialog></div>
        <div class="card-container">
            <div class="standardcard" *ngFor="let cardDetail of pricingCard" [ngClass]="cardDetail?.class">
                <div class="card-body" tabindex="0">
                    <h5 class="card-title pricing_card_title" tabindex="0">
                        <ng-container *ngIf="isMiddleCard(cardDetail)">
                            {{ pricingData[0]?.comparisionTitle }}
                        </ng-container>
                        <ng-container *ngIf="!isMiddleCard(cardDetail)">
                            {{ cardDetail.title | translate }}
                        </ng-container>
                    </h5>
                    <span *ngIf="cardDetail.title != 'pro_plan.individual_plan'">
                        <p class="card-text cost" tabindex="0">
                            {{ cardDetail.cost | translate }}
                        </p>
                    </span>
                    <span *ngIf="cardDetail.title === 'pro_plan.individual_plan'">
                        <div *ngIf="!planType" class="price-group">
                            <div class="price-table" style="width: 100%; display: flex; align-items: center">
                                <div class="card-text cost" tabindex="0">
                                    <span class="price-card">₹{{ pricingData[0]?.price / 12 }}</span>
                                </div>
                                <div class="pricing-right" style="display: flex; align-items: center; gap: 10px">
                                    <div class="col-center-container" tabindex="0">
                                        <div class="gst">+GST</div>
                                        <div class="subscription">
                                            <div>{{ 'pro_plan.per_month' | translate }}</div>
                                            <div>{{ 'pro_plan.per_subscription' | translate }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="bill">{{ 'pro_plan.bill_criteria' | translate }}</div>
                        </div>
                        <div *ngIf="planType" class="price-group">
                            <table>
                                <tr class="price-table">
                                    <td rowspan="2" class="card-text cost" tabindex="0">
                                        ₹{{ pricingData[0]?.price }}/-
                                    </td>
                                    <td class="pricing-right">
                                        <div class="col-center-container" tabindex="0">
                                            {{ 'pro_plan.per_annual' | translate }}
                                        </div>
                                        <div class="col-center-container" tabindex="0">
                                            {{ 'pro_plan.per_subscription' | translate }}
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </span>

                    <button
                        class="blue-border-button"
                        *ngIf="cardDetail.title === 'standard_plan.individual_plan' && !isAuthenticated"
                        (click)="goToSignup()"
                        tabindex="0"
                    >
                        {{ cardDetail.button | translate }}
                    </button>
                    <button
                        class="blue-border-button blue-Bg-button"
                        *ngIf="cardDetail.title === 'pro_plan.individual_plan' && !exisitingPlusplan"
                        (click)="redirectToChargeIt()"
                        id="{{ cardDetail.title }}"
                    >
                        {{ cardDetail.button | translate }}
                    </button>
                    <button
                        class="blue-border-button current-plan-Bg-button"
                        *ngIf="cardDetail.title === 'pro_plan.individual_plan' && exisitingPlusplan"
                        id="{{ cardDetail.title }}"
                    >
                        {{ cardDetail.current | translate }}
                    </button>
                    <button
                        *ngIf="cardDetail.title === 'custom_pack.individual_plan'"
                        class="blue-border-button"
                        (click)="goToContactSales($event, 1)"
                    >
                        {{ cardDetail.button | translate }}
                    </button>
                    <p
                        class="card-text description"
                        [ngClass]="{ 'popular-heading': cardDetail?.isPopular }"
                        [innerHTML]="
                            isMiddleCard(cardDetail)
                                ? pricingData[0]?.description
                                : (cardDetail.description | translate)
                        "
                        tabindex="0"
                    ></p>

                    <p
                        class="card-text hashtags"
                        tabindex="0"
                        [innerHTML]="
                            isMiddleCard(cardDetail) ? pricingData[0]?.hashtags : (cardDetail.hashtags | translate)
                        "
                    ></p>

                    <p
                        class="card-text plan-oneLine"
                        tabindex="0"
                        [innerHTML]="
                            isMiddleCard(cardDetail)
                                ? pricingData[0]?.planIncludes
                                : (cardDetail.plan_includes | translate)
                        "
                    ></p>
                    <ul *ngIf="isMiddleCard(cardDetail)">
                        <li *ngFor="let item of pricingData[0]?.cardItems" class="card-list-items" tabindex="0">
                            {{ item }}
                        </li>
                    </ul>
                    <ul *ngIf="!isMiddleCard(cardDetail)">
                        <li *ngFor="let item of cardDetail?.card_items" class="card-list-items" tabindex="0">
                            {{ item | translate }}
                        </li>
                    </ul>
                    <div class="terms" *ngIf="isMiddleCard(cardDetail)">
                        <span>{{ 'website_enterprise.taxes' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="heading-0 m-bot-45 text-center">
        <button
            class="custom-action-btn secondary imgColor"
            tabindex="0"
            (keydown.enter)="togglePlanComparison()"
            style="width: -webkit-fill-available"
            (click)="togglePlanComparison()"
        >
            {{ 'website_enterprise.plan_compare' | translate }}
            <img
                [class.rotateImg]="isPlanComparisonOpen"
                src="assets/img/enterprise/arrow-drop-down-functional-outline-20px.svg"
                alt=""
            />
        </button>
    </div>
    <div class="collapse" id="collapseComparison">
        <div class="collapse-parent">
            <section class="sec-3 feature-bottom-margin">
                <div>
                    <div class="row">
                        <span class="col removeBorder"></span>
                        <span class="col heading-1 other-plan-curve-free" tabindex="0">
                            {{ 'website_enterprise.free' | translate }}
                        </span>
                        <span class="col heading-1 other-plan-curve-plus" tabindex="0">
                            {{ 'website_enterprise.pro_plan' | translate }}
                        </span>
                        <span class="col heading-1 other-plan-curve" tabindex="0">
                            {{ 'custom_pack.comparison_title' | translate }}
                        </span>
                    </div>
                    <div>
                        <div class="row header" [class.reset-wrap]="isMobile">
                            <span class="col" [ngClass]="{ removeBorder: !isMobile }"> </span>
                            <span class="col subheading">
                                <span
                                    ><button
                                        class="custom-action-btn secondary secondary-enterprise"
                                        (click)="goToSignup($event)"
                                        *ngIf="!isAuthenticated"
                                    >
                                        {{ 'website_enterprise.sign_up' | translate }}
                                    </button></span
                                >
                            </span>
                            <span class="col subheading position-rel">
                                <span
                                    ><button
                                        class="custom-action-btn secondary button-color"
                                        (click)="redirectToChargeIt()"
                                        title="pro_plan.individual_plan"
                                        *ngIf="!exisitingPlusplan"
                                    >
                                        {{ 'website_enterprise.buy_now' | translate }}
                                    </button>
                                    <button
                                        class="custom-action-btn secondary current-plan-color"
                                        title="pro_plan.individual_plan"
                                        *ngIf="exisitingPlusplan"
                                    >
                                        {{ 'website_enterprise.current' | translate }}
                                    </button></span
                                >
                            </span>
                            <span class="col subheading position-rel">
                                <span
                                    ><button
                                        class="custom-action-btn secondary secondary-custom footer"
                                        [class.mobile-btn]="isMobile"
                                        (click)="goToContactSales($event, 2)"
                                        title="custom_pack.individual_plan"
                                    >
                                        {{ 'website_enterprise.request_quote' | translate }}
                                    </button></span
                                >
                            </span>
                        </div>
                    </div>
                </div>
                <div class="info">
                    <app-compare-edition [featureDataInput]="websiteRetailEnterpriseCompare" class="info-item">
                    </app-compare-edition>
                </div>
            </section>
        </div>
    </div>
    <div class="container">
        <div class="loader-overlay" *ngIf="isLoading">
            <div class="loader">
                <img alt="" src="assets/img/website/website_logo_header.svg" />
                <div class="spinner-div">
                    <div class="newdiv">
                        <app-lottie-renderer path="assets/lottie/jiomeet_payment_loader.json"></app-lottie-renderer>
                    </div>
                </div>
                <div class="loader-footer">
                    <div class="loader-footer-text-top">{{ 'loader.redirecting' | translate }}</div>
                    <div class="loader-footer-text-bottom">{{ 'loader.do_not_press_back' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="loader-overlay" *ngIf="showPopup">
            <div class="address-loader">
                <img alt="" src="assets/img/website/website_logo_header.svg" />

                <div class="autoFillNameDiv">
                    <div class="form-group firstname">
                        <label class="label">{{ 'payment-page.first_name' | translate }}</label>
                        <input type="text" class="text custom-border" [(ngModel)]="currentUser.name" />
                    </div>
                    <div class="form-group lastname">
                        <label class="label">{{ 'payment-page.last_name' | translate }}</label>
                        <input type="text" class="text custom-border" [(ngModel)]="currentUser.lname" />
                    </div>
                </div>
                <div class="autoFillDiv">
                    <div class="form-group email">
                        <label class="label">{{ 'payment-page.emailId' | translate }}</label>
                        <input
                            type="text"
                            class="emailtext custom-border"
                            placeholder="{{ userEmail }}"
                            [disabled]="true"
                        />
                    </div>
                </div>
                <div class="fillDiv">
                    <div class="form-group pincode">
                        <label class="label">{{ 'payment-page.pincode' | translate }}</label>
                        <input
                            type="text"
                            class="text custom-border"
                            maxlength="6"
                            autofocus
                            [(ngModel)]="pincode"
                            (blur)="onInputPincode($event.target.value)"
                            (keyup)="onInputPincode($event.target.value, $event)"
                        />
                    </div>
                    <div class="form-group city">
                        <label class="label">{{ 'payment-page.city' | translate }}</label>
                        <input
                            type="text"
                            class="text custom-border"
                            placeholder="Your city"
                            [(ngModel)]="city"
                            (blur)="onInputCity($event.target.value)"
                            (keyup)="onInputCity($event.target.value)"
                            [disabled]="showPincodeError || invalidPincode || fieldsToShow"
                        />
                    </div>
                    <div class="form-group state">
                        <label class="label">{{ 'payment-page.state' | translate }}</label>
                        <input
                            type="text"
                            class="text custom-border"
                            placeholder="Your state"
                            [(ngModel)]="state"
                            (blur)="onInputState($event.target.value)"
                            (keyup)="onInputState($event.target.value)"
                            (ngModelChange)="state = formatStateInput($event)"
                            [disabled]="showPincodeError || invalidPincode || fieldsToShow"
                        />
                    </div>
                </div>
                <div *ngIf="invalidPincode" class="label error-pincode">
                    {{ 'payment-page.invalid_pincode' | translate }}
                </div>
                <div *ngIf="showPincodeError" class="label error-pincode">
                    {{ errorTxt }}
                </div>
                <div class="buttons">
                    <button class="cancel-button" (click)="closePopup()">
                        {{ 'payment-page.cancel' | translate }}
                    </button>
                    <button
                        [ngClass]="
                            paymentButtonDisabled || !currentUser.lname || !currentUser.name || showPincodeError
                                ? 'payment-button-disabled'
                                : 'payment-button-enabled'
                        "
                        (click)="submitPayment()"
                        [disabled]="!currentUser.name"
                        [disabled]="!currentUser.lname"
                        [disabled]="showPincodeError"
                    >
                        {{ 'payment-page.proceed_to_payment' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="heading-0 m-bot-45 text-center" tabindex="0">
        {{ 'add_ons_cloud.expand_add_ons' | translate }}
    </div>
    <div class="card-group add-ons">
        <div class="row row-cols-1 row-cols-md-2 g-4">
            <div style="margin-top: 30px" *ngFor="let addOn of addOns" class="col">
                <div class="card h-100">
                    <div class="header" tabindex="0">
                        <img class="add-ons-image" [src]="addOn.img" alt="add-ons" />
                        <h5 class="card-title">{{ addOn.title | translate }}</h5>
                    </div>
                    <div class="card-body card-body-bg" index="0">
                        <ul *ngFor="let item of addOn.card_items">
                            <li class="card-list-items">{{ item | translate }}</li>
                        </ul>
                    </div>
                    <div class="card-footer card-footer-bg card-item-center-btn">
                        <button
                            class="custom-action-btn blue-border-button"
                            (click)="goToContactSales($event, 3)"
                            id="{{ addOn.title }}"
                        >
                            {{ 'website_enterprise.request_quote' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
