export const MEGA_MENUES = {
    products: {
        menues: [
            {
                label: 'For Everyone',
                tlabel: 'website.for_everyone',
                data: [
                    {
                        title: 'JioMeet',
                        ttitle: 'website.jiomeet',
                        desc: 'High Quality, Secure Video,Meetings for Everyone, Free and Unlimited.',
                        tdesc: 'website.high_quality_secure',
                        icon: 'assets/img/website/menuIcons/jiomeet.svg',
                        path: '/jiomeet'
                    }
                ]
            },

            {
                label: 'For Businesses',
                tlabel: 'website.for_businesses',
                data: [
                    {
                        title: 'Enterprise',
                        ttitle: 'website.jiomeet_enterprise',
                        desc: 'Collaborate effectively with hybrid workforce.',
                        tdesc: 'website.collaborate_effectively',
                        icon: 'assets/img/website/menuIcons/jiomeet-enterprise.svg',
                        path: '/enterprise'
                    },
                    {
                        title: 'Platform',
                        ttitle: 'website.jiomeet_cpaas',
                        desc: 'Add an instant video conferencing dimension to your App.',
                        tdesc: 'website.add_video_conference',
                        type: '1',
                        icon: 'assets/img/website/menuIcons/jiomeet-platform.svg',
                        path: 'https://platform.jiomeet.com/home',
                        isExternal: true
                    }
                ]
            },
            {
                label: ' ',
                tlabel: 'website.for_webinars',
                data: [
                    {
                        title: 'JioEvents',
                        ttitle: 'website.jiomeet_conferences',
                        desc: 'Host delightful virtual and hybrid events-across platforms',
                        tdesc: 'website.host_delightful_events',

                        icon: 'assets/img/website/menuIcons/ps_jioevents_beta.svg',
                        path: 'https://jioevents.com/home',
                        isExternal: true
                    },
                    {
                        title: 'Discover',
                        ttitle: 'website.jiomeet_discover',
                        desc: 'Let’s bring the world closer! Know more people, reach, teach and learn.',
                        tdesc: 'website.bring_world_closer',
                        tag: 'Coming Soon',
                        ttag: 'website.coming_soon',
                        type: '2',
                        icon: 'assets/img/website/menuIcons/jiomeet-events.svg'
                    }
                ]
            }
        ],
        whatsNew: [
            {
                label: '',
                data: [
                    {
                        title: 'JioMeet is now on WhatsApp!',
                        ttitle: 'website.now_on_whatsapp',
                        desc: 'Now you can receive your meeting notifications on WhatsApp and chat with us! You can also Start, Plan or Join a Meeting directly through our WhatsApp number. Save our number and get started – 8369 100 100.',
                        tdesc: 'website.whats_new_whatsapp_desc'
                    },
                    {
                        title: 'Bigger Meeting for Enterprises',
                        ttitle: 'website.big_meet_enterprises',
                        desc: 'Bigger meetings means more real time collaboration fetching more productivity. Enterprise users can now host meetings up to 50 participants in a single meeting.',
                        tdesc: 'website.whats_new_big_meet_desc'
                    },
                    {
                        title: 'Join via VC rooms using SIP Gateway',
                        ttitle: 'website.vc_sip_gateway',
                        desc: 'All JioMeet enterprise users will now be able to connect to all legacy VC system through SIP gateway.',
                        tdesc: 'website.vc_sip_gateway_desc'
                    }
                ]
            }
        ]
    },
    useCases: {
        menues: [
            {
                label: '',
                data: [
                    {
                        title: 'Education',
                        ttitle: 'website.education',
                        desc: 'Hybrid learning environment for schools, colleges, coaching institutes and universities.',
                        tdesc: 'website.hybrid_learning',
                        icon: 'assets/img/website/menuIcons/icon-education.svg',
                        path: '/education',
                        index: 0
                    },
                    {
                        title: 'Hybrid Workplaces',
                        ttitle: 'website.hybrid_workplaces',
                        desc: 'Collaborate easy with your remote teammates. Feel your office space from anywhere, and miss out on nothing!',
                        tdesc: 'website.build_deeper_connections_realtime',
                        icon: 'assets/img/website/menuIcons/icon-hybridwp.svg',
                        path: '/hybridWorkplace',
                        index: 2
                    },
                    {
                        title: 'Government Sectors',
                        ttitle: 'website.government_sectors',
                        desc: 'Transforming in-office administration to its real time video conferencing finesse.',
                        tdesc: 'website.transform_in_office',
                        icon: 'assets/img/website/menuIcons/icon-govt-sector.svg',
                        path: '/govtSectors',
                        index: 4
                    },
                    {
                        title: 'Healthcare',
                        ttitle: 'website.healthcare',
                        desc: 'Helping patients, doctors and health institutions connect',
                        tdesc: 'website.helping_connect',
                        icon: 'assets/img/website/menuIcons/icon-healthcare.svg',
                        path: '/healthcare',
                        index: 1
                    },
                    {
                        title: 'Platform Services',
                        ttitle: 'website.platform_services',
                        desc: 'Integrate easy video conferencing solutions and let not distance hold you from connecting with the world.',
                        tdesc: 'website.build_deeper_connections_notime',
                        icon: 'assets/img/website/menuIcons/icon-plservice.svg',
                        path: '/platformServices',
                        index: 3
                    }
                ]
            }
        ],
        whatsNew: [
            {
                label: '',
                data: [
                    {
                        title: 'JioMeet is now on WhatsApp!',
                        ttitle: 'website.now_on_whatsapp',
                        desc: 'Now you can receive your meeting notifications on WhatsApp and chat with us! You can also Start, Plan or Join a Meeting directly through our WhatsApp number. Save our number and get started – 8369 100 100.',
                        tdesc: 'website.whats_new_whatsapp_desc'
                    },
                    {
                        title: 'Bigger Meeting for Enterprises',
                        ttitle: 'website.big_meet_enterprises',
                        desc: 'Bigger meetings means more real time collaboration fetching more productivity. Enterprise users can now host meetings up to 50 participants in a single meeting.',
                        tdesc: 'website.whats_new_big_meet_desc'
                    },
                    {
                        title: 'Join via VC rooms using SIP Gateway',
                        ttitle: 'website.vc_sip_gateway',
                        desc: 'All JioMeet enterprise users will now be able to connect to all legacy VC system through SIP gateway.',
                        tdesc: 'website.vc_sip_gateway_desc'
                    }
                ]
            }
        ]
    },
    more: {
        menues: [
            {
                label: 'Resources',
                tlabel: 'website.resources',
                data: [
                    {
                        title: 'Download JioMeet',
                        ttitle: 'website.download_jioMeet',
                        desc: 'Download JioMeet and experience your happy meetings from any device.',
                        tdesc: 'website.download_jioMeet_and_experience',
                        icon: 'assets/img/website/menuIcons/icon-download.svg',
                        path: '/jiomeetDownload'
                    },
                    {
                        title: 'JioMeet Dev Center',
                        ttitle: 'website.jioMeet_developers',
                        desc: 'Use the Communication Platform as a Service to build your apps/services',
                        tdesc: 'website.jioMeet_developers_desc',
                        icon: 'assets/img/website/menuIcons/jiomeet-developers.svg',
                        type: '2',
                        path: 'https://dev.jiomeet.com/',
                        isExternal: true
                    },
                    {
                        title: 'Developer Community',
                        ttitle: 'website.developer_community',
                        desc: "Interact with other developers using JioMeet's CPaaS platform",
                        tdesc: 'website.developer_community_desc',
                        icon: 'assets/img/website/menuIcons/developer-community.svg',
                        tag: 'Coming Soon',
                        ttag: 'website.coming_soon',
                        type: '2'
                    },
                    {
                        title: 'Blog',
                        ttitle: 'website.blog',
                        desc: 'Get an insight to amazing features of JioMeet.',
                        tdesc: 'website.blog_desc',
                        icon: 'assets/img/website/menuIcons/icon-blog.svg',
                        path: '/blog/',
                        isExternal: true
                    }
                ]
            },
            {
                label: 'Support',
                tlabel: 'website.support',
                data: [
                    {
                        title: 'Need Help?',
                        ttitle: 'website.need_help',
                        desc: 'Hey, we have got your back, just have a quick chat with us.',
                        tdesc: 'website.quick_chat',
                        type: '2',
                        icon: 'assets/img/website/menuIcons/icon-help.svg',
                        path: '/webhelp',
                        isExternal: true
                    },
                    {
                        title: 'Developer Support',
                        ttitle: 'website.developer_support',
                        desc: 'JioMeet Tech experts are right here for your support!',
                        tdesc: 'website.developer_support_desc',
                        tag: 'Coming Soon',
                        ttag: 'website.coming_soon',
                        type: '2',
                        icon: 'assets/img/website/menuIcons/developer-support.svg'
                    },
                    {
                        title: 'Provide Feedback',
                        ttitle: 'website.provide_feedback',
                        desc: '',

                        type: '2',
                        icon: 'assets/img/website/menuIcons/feedback.svg',
                        path: '/feedback'
                    },
                    {
                        title: 'Contact Us',
                        ttitle: 'website.contact_us',
                        desc: '',

                        type: '2',
                        icon: 'assets/img/website/menuIcons/contactus.svg',
                        path: '/contact-us'
                    }
                ]
            },
            {
                label: 'About',
                tlabel: 'website.about',
                data: [
                    {
                        title: 'Press Release',
                        ttitle: 'website.press_release',
                        desc: 'Keep up with JioMeet’s daily updates. Stay Tuned!',
                        tdesc: 'website.press_release_desc',
                        tag: 'Coming Soon',
                        ttag: 'website.coming_soon',
                        type: '2',
                        icon: 'assets/img/website/menuIcons/press-release.svg'
                    },
                    {
                        title: 'Customers',
                        ttitle: 'website.customers',
                        desc: 'Hear everything from JioMeet’s Happy Users – Success Stories!',
                        tdesc: 'website.customers_desc',
                        tag: 'Coming Soon',
                        ttag: 'website.coming_soon',
                        type: '2',
                        icon: 'assets/img/website/menuIcons/customers.svg'
                    },
                    {
                        title: 'Careers',
                        ttitle: 'website.careers',
                        desc: 'If you are a digital nomad, welcome to Team JioMeet!',
                        tdesc: 'website.careers_desc',
                        tag: 'Coming Soon',
                        ttag: 'website.coming_soon',
                        type: '2',
                        icon: 'assets/img/website/menuIcons/career.svg'
                    }
                ]
            }
        ],
        whatsNew: [
            {
                label: '',
                data: [
                    {
                        title: 'JioMeet is now on WhatsApp!',
                        ttitle: 'website.now_on_whatsapp',
                        desc: 'Now you can receive your meeting notifications on WhatsApp and chat with us! You can also Start, Plan or Join a Meeting directly through our WhatsApp number. Save our number and get started – 8369 100 100.',
                        tdesc: 'website.whats_new_whatsapp_desc'
                    },
                    {
                        title: 'Bigger Meeting for Enterprises',
                        ttitle: 'website.big_meet_enterprises',
                        desc: 'Bigger meetings means more real time collaboration fetching more productivity. Enterprise users can now host meetings up to 50 participants in a single meeting.',
                        tdesc: 'website.whats_new_big_meet_desc'
                    },
                    {
                        title: 'Join via VC rooms using SIP Gateway',
                        ttitle: 'website.vc_sip_gateway',
                        desc: 'All JioMeet enterprise users will now be able to connect to all legacy VC system through SIP gateway.',
                        tdesc: 'website.vc_sip_gateway_desc'
                    }
                ]
            }
        ]
    }
};

export const WEBSITE_SOLUTIONS = [
    {
        label: 'For Everyone',
        tlabel: 'website.for_everyone',
        color: '#FF7F21',
        data: [
            {
                title: 'JioMeet',
                ttitle: 'website.jiomeet',
                desc: 'High Quality, Secure Video, Meetings for Everyone, Free and Unlimited.',
                tdesc: 'website.high_quality_secure',
                icon: 'assets/img/website/solutions/jiomeet.svg',
                path: '/jiomeet'
            },
            {
                title: '',
                desc: '',
                icon: 'assets/img/website/solutions/jiomeet-people.svg'
            }
        ]
    },
    {
        label: 'For Entrepreneurs, SMBs and Enterprises',
        tlabel: 'website.for_businesses',
        color: '#507FFF',
        data: [
            {
                title: 'Enterprise',
                ttitle: 'website.jiomeet_enterprise',
                desc: 'Collaborate effectively with hybrid workforce.',
                tdesc: 'website.collaborate_effectively',
                icon: 'assets/img/website/solutions/jiomeet-enterprise.svg',
                path: '/enterprise'
            },
            {
                title: 'Platform',
                ttitle: 'website.jiomeet_cpaas',
                desc: 'Add video conferencing dimension to your app in no time.',
                tdesc: 'website.add_video_conference',
                icon: 'assets/img/website/solutions/jiomeet-cpass.svg',
                path: 'https://platform.jiomeet.com/home',
                isExternal: true
            }
        ]
    },
    {
        label: 'For Webinars',
        tlabel: 'website.for_webinars',
        color: '#B372FF',
        data: [
            {
                title: 'JioEvents',
                ttitle: 'website.jiomeet_conferences',
                desc: 'Host delightful virtual and hybrid events-across platforms',
                tdesc: 'website.host_delightful_events',
                icon: 'assets/img/website/solutions/jiomeet-conference.svg',
                path: 'https://jioevents.com/home',
                isExternal: true
            },
            {
                title: 'Discover',
                ttitle: 'website.jiomeet_discover',
                desc: 'Let’s bring the world closer! Know more people, reach, teach and learn.',
                tdesc: 'website.bring_world_closer',
                icon: 'assets/img/website/solutions/jiomeet-events.svg'
                //path: '/events'
            }
        ]
    }
];

export const WEBSITE_PRODUCT_FEATURES = [
    {
        label: 'Freedom <span class="freedom">from Limits</span>',
        tlabel: 'website.freedom_from_limits',
        desc: 'Say goodbye to limited usage. <br />Enjoy unlimited, free and premium video conferencing!',
        tdesc: 'website.no_quality_limit',
        type: 'freedom',
        data: [
            {
                title: '',
                desc: 'Unlimited Meeting Duration',
                tdesc: 'website.unlimited_meeting_duration',
                icon: 'assets/img/website/features/meeting-duration.svg',
                iconActive: 'assets/img/website/features/meeting-duration-active.svg',
                img: 'assets/img/website/features/freedom-limit-preview-v2.png'
            },
            {
                title: '',
                desc: 'Upto 1000 participants',
                tdesc: 'website.max_1000_people',
                icon: 'assets/img/website/features/participant.svg',
                iconActive: 'assets/img/website/features/participant-active.svg',
                img: 'assets/img/website/features/participant-preview-v2.png',
                imgMobile: 'assets/img/website/features/participant-preview-mobile.png'
            },
            {
                title: '',
                desc: 'High Definition Audio/Video',
                tdesc: 'website.high_definition_call',
                icon: 'assets/img/website/features/hd.svg',
                iconActive: 'assets/img/website/features/hd-active.svg',
                img: 'assets/img/website/features/hd-preview-v2.png'
            }
        ]
    },
    {
        label: 'Easy <span class="collaboration">Collaboration</span>',
        tlabel: 'website.easy_collaboration',
        desc: 'Do more together, inside and outside meetings. Collaborate online as you collaborate offline',
        tdesc: 'website.do_more_together',
        type: 'collaboration',
        data: [
            {
                title: '',
                desc: 'Whiteboard',
                tdesc: 'website.whiteboard',
                icon: 'assets/img/website/features/whiteboard.svg',
                iconActive: 'assets/img/website/features/whiteboard-active.svg',
                img: 'assets/img/website/features/collaboration-preview.png'
            },
            {
                title: '',
                desc: 'Breakout Rooms',
                tdesc: 'website.breakout_rooms',
                icon: 'assets/img/website/features/breakout-room.svg',
                iconActive: 'assets/img/website/features/breakout-room-active.svg',
                img: 'assets/img/website/features/breakout-room-preview.png'
            },
            {
                title: '',
                desc: 'Discussions, Chats, Groups & Channels',
                tdesc: 'website.discuss_chat_groups',
                icon: 'assets/img/website/features/chat.svg',
                iconActive: 'assets/img/website/features/chat-active.svg',
                img: 'assets/img/website/features/chat-preview.png'
            }
        ]
    },
    {
        label: 'Designed for <span class="convenience">Convenience</span>',
        tlabel: 'website.designed_for_convenience',
        desc: 'Simple and easy interface for Indians, designed by Indians',
        tdesc: 'website.simple_and_easy_interface',
        type: 'convenience',
        data: [
            {
                title: '',
                desc: 'Sign In through Mobile number',
                tdesc: 'website.signin_through_mobile',
                icon: 'assets/img/website/features/signin.svg',
                iconActive: 'assets/img/website/features/signin-active.svg',
                img: 'assets/img/website/features/signin-preview.png'
            },
            {
                title: '',
                desc: 'Supports Indian Languages',
                tdesc: 'website.support_indian_language',
                icon: 'assets/img/website/features/language.svg',
                iconActive: 'assets/img/website/features/language-active.svg',
                img: 'assets/img/website/features/language-preview.png'
            },
            {
                title: '',
                desc: 'Transfer Calls between Devices',
                tdesc: 'website.transfer_call',
                icon: 'assets/img/website/features/transfer-device.svg',
                iconActive: 'assets/img/website/features/transfer-device-active.svg',
                img: 'assets/img/website/features/transfer-device-preview.png'
            }
        ]
    }
];

export const WEBSITE_TESTIMONIALS = [
    {
        testimonial: '“Best service one must try”',
        ttestimonial: 'website.best_service',
        fullName: 'Vinayak Deshpande',
        bio: 'Asst. Professor, ISB, Hyderabad',
        profile_pic: 'assets/img/website/features/freedom-limit-preview.png'
    },
    {
        testimonial:
            '“Excellent application, helps to schedule the meetings within few seconds. Great UI/UX design too. Overall a very good experience.”',
        fullName: 'Nikita Chatur',
        bio: 'September 7,2021',
        profile_pic: 'assets/img/website/features/freedom-limit-preview.png'
    }
];

export const WEBSITE_CPAAS_TESTIMONIALS = [
    {
        testimonial: '“Best service one must try”',
        fullName: 'Dr. Santhosh Raina',
        bio: 'Heeranandhani Hospital, Maharashtra',
        profile_pic: 'assets/img/website/features/freedom-limit-preview.png'
    }
];

export const WEBSITE_UPCOMING_EVENTS = [
    {
        title: 'Reliance AGM 2022',
        desc: 'Lorem Ipsum has been the industry’s standard dummy ever since 1500s',
        img: 'assets/img/website/features/reliance.png'
    },
    {
        title: 'Node.JS HACKATHON ’22',
        desc: 'Lorem Ipsum has been the industry’s standard dummy ever since 1500s',
        img: 'assets/img/website/features/nodejs.png'
    },
    {
        title: 'Learning Classroom Mode',
        desc: 'Lorem Ipsum has been the industry’s standard dummy ever since 1500s',
        img: 'assets/img/website/features/classroom.png'
    }
];

export const WEBSITE_CPAAS_UPCOMING_EVENTS = [
    {
        title: 'Classroom Teaching',
        desc: 'TargetPEAK has on-boarded 7 lakh students and 10,000 teachers across Maharashtra for live classes',
        img: 'assets/img/cpaas/classroom-teaching.png'
    },
    {
        title: 'Health Consultation',
        desc: 'JioHealthHub has onboarded JioMeet for doctor consultations and for doctor interviews',
        img: 'assets/img/cpaas/health-consultation.png'
    },
    {
        title: 'Sales Assistance',
        desc: 'JioMart is launching retail expert support to help customers make better informed purchases',
        img: 'assets/img/cpaas/sales-assistance.png'
    }
];

export const WEBSITE_RETAIL_SOLUTIONS = [
    {
        title: '',
        desc: 'Unlimited Meetings',
        tdesc: 'website.unlimited_meeting',
        icon: 'assets/img/retail/unlimited-meet.svg'
    },
    {
        title: '',
        desc: 'Attend meetings from browser',
        tdesc: 'website.meeting_from_browser',
        icon: 'assets/img/retail/meet-browser.svg'
    },
    {
        title: '',
        desc: 'Classroom Mode for online education',
        tdesc: 'website.online_classroom',
        icon: 'assets/img/retail/online-education.svg'
    },
    {
        title: '',
        desc: 'Restricted Meeting',
        tdesc: 'website.restricted_meeting',
        icon: 'assets/img/retail/private-meet.svg'
    },
    {
        title: '',
        desc: 'Transfer Call between Devices',
        tdesc: 'website.transfer_call',
        icon: 'assets/img/retail/transfer-call.svg'
    },
    {
        title: '',
        desc: 'Join Meeting. with Multiple Devices',
        tdesc: 'website.meet_multiple_device',
        icon: 'assets/img/retail/multi-device-meet.svg'
    },
    {
        title: '',
        desc: 'Parallel Meetings on Different Devices',
        tdesc: 'website.parallel_meetings',
        icon: 'assets/img/retail/parallel-meet.svg'
    },
    {
        title: '',
        desc: 'Download list of Attendees',
        tdesc: 'website.download_attendee_list',
        icon: 'assets/img/retail/attendees-download.svg'
    },
    {
        title: '',
        desc: 'Group Calling',
        tdesc: 'website.group_calling',
        icon: 'assets/img/retail/group-calling.svg'
    },
    {
        title: '',
        desc: 'Group Chats',
        tdesc: 'website.group_chat',
        icon: 'assets/img/retail/group-chat.svg'
    },
    {
        title: '',
        desc: 'Favourite Contacts',
        tdesc: 'website.fav_contacts',
        icon: 'assets/img/retail/fav-contacts.svg'
    },
    {
        title: '',
        desc: 'File Sharing',
        tdesc: 'website.file_sharing',
        icon: 'assets/img/retail/file-sharing.svg'
    }
];

export const WEBSITE_ENTERPRISE_FEATURES = [
    {
        label: 'Drive employee happiness with <span class="highlight">better work-life balance</span>',
        tlabel: 'website.drive_employee_happiness',
        desc: 'Offer modern workspaces which helps keep work and personal life separate, and provides device location and time flexibility.',
        tdesc: 'website.offer_modern_workspace',
        type: 'highlight',
        data: [
            {
                title: '',
                desc: 'Outlook Add-in',
                tdesc: 'website.integrate_outlook_gc',
                icon: 'assets/img/enterprise/outlook-gc-int.svg',
                iconActive: 'assets/img/enterprise/outlook-gc-int-active.svg',
                altText: 'Outlook Add-in',
                img: 'assets/img/enterprise/outlook-gc-int-preview.png'
            },
            {
                title: '',
                desc: 'Personalised Virtual Background',
                tdesc: 'website.update_status',
                icon: 'assets/img/enterprise/personalised-vb.svg',
                iconActive: 'assets/img/enterprise/personalised-vb-active.svg',
                img: 'assets/img/enterprise/personalised-vb-preview.png'
            },
            {
                title: '',
                desc: 'View your meeting recording',
                tdesc: 'website.view_meeting_record',
                icon: 'assets/img/enterprise/meeting-recording.svg',
                iconActive: 'assets/img/enterprise/meeting-recording-active.svg',
                img: 'assets/img/enterprise/meeting-recording-preview.png'
            }
        ]
    },
    {
        label: '<span class="highlight">Seamless Collaboration</span> with External Users',
        tlabel: 'website.seemless_collaboration',
        desc: 'Enabling flawless external collaboration with Non-Enterprise users for active, secured, and customised communication.',
        tdesc: 'website.enable_external_collaboration',
        type: 'highlight',
        data: [
            {
                title: '',
                desc: 'Create groups with all user types',
                tdesc: 'website.create_group',
                icon: 'assets/img/enterprise/user-group.svg',
                iconActive: 'assets/img/enterprise/user-group-active.svg',
                altText: 'Creation of groups with all user types in jiomeet',
                img: 'assets/img/enterprise/user-group-preview.png'
            },
            {
                title: '',
                desc: 'Call/chat by Non- Enterprise users',
                tdesc: 'website.call_chat_users',
                icon: 'assets/img/enterprise/call-chat.svg',
                iconActive: 'assets/img/enterprise/call-chat-active.svg',
                img: 'assets/img/enterprise/call-chat-preview.png'
            },
            {
                title: '',
                desc: 'Set custom rules for file sharing',
                tdesc: 'website.set_custom_rules',
                icon: 'assets/img/enterprise/file-sharing.svg',
                iconActive: 'assets/img/enterprise/file-sharing-active.png',
                img: 'assets/img/enterprise/file-sharing-preview.png'
            }
        ]
    },
    {
        label: 'Build an agile workspace with <span class="highlight">minimum investments</span>',
        tlabel: 'website.build_agile_workspace',
        desc: 'Experience an agile workspace with the existing infrastructure.',
        tdesc: 'website.exp_agile_workspace',
        type: 'highlight',
        data: [
            {
                title: '',
                desc: 'Legacy VC Room through VPN',
                tdesc: 'website.legacy_vc_room',
                icon: 'assets/img/enterprise/vc-room.svg',
                iconActive: 'assets/img/enterprise/vc-room-active.svg',
                altText: 'Collaboration of people attending meeting',
                img: 'assets/img/enterprise/vc-room-preview.png'
            },
            {
                title: '',
                desc: 'More Meeting Participants',
                tdesc: 'website.more_meeting_participants',
                icon: 'assets/img/enterprise/more-participants.svg',
                iconActive: 'assets/img/enterprise/more-participants-active.svg',
                img: 'assets/img/enterprise/more-participants-preview.png'
            },
            {
                title: '',
                desc: 'JioMeet Bot for Microsoft Teams',
                tdesc: 'website.jiomeet_bot_mt',
                icon: 'assets/img/enterprise/meet-bot.svg',
                iconActive: 'assets/img/enterprise/meet-bot-active.svg',
                img: 'assets/img/enterprise/meet-bot-preview.png'
            }
        ]
    },
    {
        label: 'Easy <span class="highlight">Customizations</span>',
        tlabel: 'website.easy_customize',
        desc: 'Standard customisations, internal private events and CPaaS offering for your organisation',
        tdesc: 'website.standard_customisation',
        type: 'highlight',
        data: [
            {
                title: '',
                desc: 'White<br />Labelling',
                tdesc: 'website.white_labelling',
                icon: 'assets/img/enterprise/white-labelling.svg',
                iconActive: 'assets/img/enterprise/white-labelling-active.svg',
                altText: 'White labelling',
                img: 'assets/img/enterprise/white-labelling-preview.png'
            },
            {
                title: '',
                desc: 'Events only for your organisation',
                tdesc: 'website.events_for_you',
                icon: 'assets/img/enterprise/events.svg',
                iconActive: 'assets/img/enterprise/events-active.svg',
                img: 'assets/img/enterprise/events-preview.png'
            },
            {
                title: '',
                desc: 'CPaaS<br />Offering',
                tdesc: 'website.cpaas_offering',
                icon: 'assets/img/enterprise/cpass-offering.svg',
                iconActive: 'assets/img/enterprise/cpass-offering-active.svg',
                img: 'assets/img/enterprise/cpass-offering-preview.png'
            }
        ]
    },
    {
        label: '<span class="highlight">Certified</span> for Peace of Mind',
        tlabel: 'website.certified',
        desc: 'Sit back and relax, your data security is our priority. We have safeguarded you with a secure access of application and data.',
        tdesc: 'website.data_security_priority',
        type: 'highlight',
        data: [
            {
                title: '',
                desc: 'MeghRaj certified Data Centers',
                tdesc: 'website.meghraj_certified_center',
                icon: 'assets/img/enterprise/data-centers.svg',
                iconActive: 'assets/img/enterprise/data-centers-active.svg',
                altText: 'MeghRaj certified Data Centers',
                img: 'assets/img/enterprise/data-centers-preview.png'
            },
            {
                title: '',
                desc: 'STQC Certified',
                tdesc: 'website.stqc_certified',
                icon: 'assets/img/enterprise/stqc-certified.svg',
                iconActive: 'assets/img/enterprise/stqc-certified-active.svg',
                img: 'assets/img/enterprise/stqc-certified-preview.png'
            }
        ]
    }
];

export const WEBSITE_ENTERPRISE_RETAIL_COMPARISION = [
    {
        feature: 'Participants',
        tfeature: 'website_enterprise.participants',
        individual: '30 Participants',
        tindividual: 'website_enterprise.participants_30',
        pro: '100 Participants',
        tpro: 'website_enterprise.participants_100',
        custom: '100 Participants',
        tcustom: 'website_enterprise.participants_custom'
    },
    // {
    //     feature: 'Audience Support',
    //     tfeature: 'website_enterprise.audience',
    //     individual: '50 Audience',
    //     tindividual: 'website_enterprise.audience_50',
    //     pro: '100 Audience',
    //     tpro: 'website_enterprise.audience_0',
    //     custom: '>100 Audience: Support can be offered with Jio Events',
    //     tcustom: 'website_enterprise.audience_100_plus'
    // },
    {
        feature: 'Recording',
        tfeature: 'website_enterprise.meeting_recording_cloud',
        individual: 'Limited',
        tindividual: 'website_enterprise.limited',
        pro: 'Unlimited',
        tpro: 'website_enterprise.unlimited',
        custom: 'Unlimited',
        tcustom: 'website_enterprise.unlimited'
    },
    {
        feature: 'Breakout Rooms',
        tfeature: 'website.breakout_rooms',
        individual: 0,
        tindividual: 'website_enterprise.unlimited',
        pro: 'Unlimited',
        tpro: 'website_enterprise.unlimited',
        custom: 'Unlimited',
        tcustom: 'website_enterprise.unlimited'
    },
    {
        feature: 'Whiteboard',
        tfeature: 'website.whiteboard',
        individual: 'Yes (upto 2)',
        tindividual: 'website_enterprise.upto2',
        pro: 'Limited',
        tpro: 'website_enterprise.limited',
        custom: 'Unlimited Premium',
        tcustom: 'website_enterprise.unlimited_premium'
    },
    {
        feature: 'Chat - Groups & Individual',
        tfeature: 'website_enterprise.groups_enterprise_users',
        individual: 1,
        pro: 1,
        custom: 1
    },
    {
        feature: 'Remote Screen Control',
        tfeature: 'website_enterprise.remote_screen_control',
        individual: 1,
        pro: 1,
        custom: 1
    },
    {
        feature: 'Full HD (1080p)',
        tfeature: 'website_enterprise.full_HD',
        individual: 0,
        pro: 0,
        custom: 1
    },
    {
        feature: 'Customised Features',
        tfeature: 'website_enterprise.customised_features',
        individual: 0,
        pro: 0,
        custom: 'Support Available: Request for quote',
        tcustom: 'website_enterprise.white_labeling_custom'
    },
    {
        feature: 'Social Media Streaming',
        tfeature: 'website_enterprise.social_media_streaming',
        individual: 0,
        pro: 1,
        custom: 1,
        tcustom: 'website_enterprise.white_labeling_custom'
    },
    {
        feature: 'Single Sign ON (SSO)',
        tfeature: 'website.sigle_sign_on',
        individual: 0,
        pro: 0,
        custom: 1
    },
    {
        feature: 'White Labelling',
        tfeature: 'website.white_labelling',
        individual: 0,
        pro: 0,
        custom: 'Support Available: Request for quote',
        tcustom: 'website_enterprise.white_labeling_custom'
    },
    {
        feature: 'Class Room mode',
        tfeature: 'website.class_Room',
        individual: 0,
        pro: 1,
        custom: 1
    },
    /*{
        feature: 'Cloud Storage',
        tfeature: 'website_enterprise.cloud_storage',
        individual: '2 GB',
        tindividual: 'website_enterprise.2GB',
        pro: '10 GB',
        tpro: 'website_enterprise.10GB',
        custom: 'More storage available on demand',
        tcustom: 'website_enterprise.storage_on_demand'
    },*/
    {
        feature: 'SIP Support',
        tfeature: 'website_enterprise.sip_support',
        individual: 0,
        pro: 0,
        custom: 'Support Available: Request for quote',
        tcustom: 'website_enterprise.white_labeling_custom'
    },
    {
        feature: 'Admin Portal',
        tfeature: 'website_enterprise.admin_portal',
        individual: 0,
        pro: 0,
        custom: 1
    },
    {
        feature: 'Deployment',
        tfeature: 'website.deployment',
        individual: 'Cloud',
        tindividual: 'website_enterprise.cloud',
        pro: 'Cloud',
        tpro: 'website_enterprise.cloud',
        custom: 'Cloud and on prem',
        tcustom: 'website_enterprise.cloud_deployment_custom'
    },
    {
        feature: 'Deep Integration (API/SDK)',
        tfeature: 'website_enterprise.deep_integration',
        individual: 0,
        pro: 0,
        custom: 'Support Available: Request for quote',
        tcustom: 'website_enterprise.white_labeling_custom'
    },
    {
        feature: 'PSTN Support',
        tfeature: 'website.pstn_support',
        individual: 0,
        pro: 0,
        custom: 'On Demand',
        tcustom: 'website_enterprise.on_demand'
    },
    {
        feature: 'Advanced Security',
        tfeature: 'website_enterprise.advanced_security',
        individual: 1,
        pro: 1,
        custom: 1
    },
    {
        feature: '24x7 Premium Support',
        tfeature: 'website_enterprise.premium_support_24_7',
        individual: 0,
        pro: 1,
        custom: 1
    }
];

export const WEBSITE_CONFERENCE_FEATURES = [
    {
        label: '<span class="conf-highlight">Conferences</span> made easy',
        tlabel: 'website.conferences_made_easy',
        desc: 'Host upto 10k+ attendees',
        tdesc: 'website.easy_create_view',
        type: 'conf-highlight',
        data: [
            {
                title: '',
                desc: 'Host upto 10k+ attendees',
                tdesc: 'website.easy_create_view',
                icon: 'assets/img/conference/attendees.svg',
                iconActive: 'assets/img/conference/attendees-active.svg',
                img: 'assets/img/conference/attendees-preview.png'
            },
            {
                title: '',
                desc: 'Q&A’s, comments and reactions',
                tdesc: 'website.qna_comments',
                icon: 'assets/img/conference/qna.svg',
                iconActive: 'assets/img/conference/qna-active.svg',
                img: 'assets/img/conference/qna-preview.png'
            },
            {
                title: '',
                desc: 'Stream on Youtube and Facebook',
                tdesc: 'website.stream_youtube_fb',
                icon: 'assets/img/conference/stream.svg',
                iconActive: 'assets/img/conference/stream-active.svg',
                img: 'assets/img/conference/stream-preview.png'
            }
        ]
    },
    {
        label: '<span class="conf-highlight">Make it your own!</span>',
        tlabel: 'website.make_it_your_own',
        desc: 'Customise every aspect of your event and highlight your brand logos, themes, background image, watermark, and much more',
        tdesc: 'website.customise_event_all',
        type: 'conf-highlight',
        data: [
            {
                title: '',
                desc: 'Customise and brand events',
                tdesc: 'website.customise_brand_event',
                icon: 'assets/img/conference/customize-event.svg',
                iconActive: 'assets/img/conference/customize-event-active.svg',
                img: 'assets/img/conference/customize-event-preview.png'
            },
            {
                title: '',
                desc: 'Upload and play cloud video',
                tdesc: 'website.upload_play_video',
                icon: 'assets/img/conference/upload-preview.svg',
                iconActive: 'assets/img/conference/upload-preview-active.svg',
                img: 'assets/img/conference/upload-play-preview.png'
            },
            {
                title: '',
                desc: 'Download event report',
                tdesc: 'website.download_event_report',
                icon: 'assets/img/conference/download-report.svg',
                iconActive: 'assets/img/conference/download-report-active.svg',
                img: 'assets/img/conference/download-report-preview.png'
            }
        ]
    }
];

export const WEBSITE_EVENTS_FEATURES = [
    {
        label: 'Reach <span class="event-highlight">Larger Audience</span>',
        tlabel: 'website.reach_larger_audience',
        desc: 'Attend sessions held by individuals who can inspire and teach new things from their domain',
        tdesc: 'website.attend_sessions',
        type: 'event-highlight',
        data: [
            {
                title: '',
                desc: 'Tap into JioMeet customer base',
                tdesc: 'website.tab_customer_base',
                icon: 'assets/img/events/customer-base.svg',
                iconActive: 'assets/img/events/customer-base-active.svg',
                img: 'assets/img/events/customer-base-preview.png'
            },
            {
                title: '',
                desc: 'Host Live Events',
                tdesc: 'website.host_live_events',
                icon: 'assets/img/events/host-live-event.svg',
                iconActive: 'assets/img/events/host-live-event-active.svg',
                img: 'assets/img/events/host-live-event-preview.png'
            },
            {
                title: '',
                desc: 'Upload event recording',
                tdesc: 'website.upload_recordings',
                icon: 'assets/img/events/upload-recording.svg',
                iconActive: 'assets/img/events/upload-recording-active.svg',
                img: 'assets/img/events/upload-recording-preview.png'
            }
        ]
    },
    {
        label: 'Simplified <span class="event-highlight">Discovery</span>',
        tlabel: 'website.simplified_discovery',
        desc: 'Content is curated on basis of category and tags enabling easy discovery.',
        tdesc: 'website.content_easy_discovery',
        type: 'event-highlight',
        data: [
            {
                title: '',
                desc: 'Explore events by Categories',
                tdesc: 'website.explore_events',
                icon: 'assets/img/events/explore-category.svg',
                iconActive: 'assets/img/events/explore-category-active.svg',

                lottieDesktop: 'assets/lottie/explore-category-desktop.json',
                lottieMobile: 'assets/lottie/explore-category-mobile.json'
            },
            {
                title: '',
                desc: 'Easy search by title, tags and creator',
                tdesc: 'website.easy_search',
                icon: 'assets/img/events/search-filter.svg',
                iconActive: 'assets/img/events/search-filter-active.svg',
                img: 'assets/img/events/search-filter-preview.png'
            }
        ]
    },
    {
        label: 'Learn from <span class="event-highlight">Experts</span>',
        tlabel: 'website.learn_from_experts',
        desc: 'Attend sessions held by individuals who can inspire and teach new things from their domain',
        tdesc: 'website.attend_session_domain',
        type: 'event-highlight',
        data: [
            {
                title: '',
                desc: 'Register and Attend',
                tdesc: 'website.register_attend',
                icon: 'assets/img/events/register-attend.svg',
                iconActive: 'assets/img/events/register-attend-active.svg',
                img: 'assets/img/events/register-attend-preview.png'
            },
            {
                title: '',
                desc: 'View Recordings',
                tdesc: 'website.view_recording',
                icon: 'assets/img/events/event-recording.svg',
                iconActive: 'assets/img/events/event-recording-active.svg',
                img: 'assets/img/events/event-recording-preview.png'
            }
        ]
    },

    {
        label: 'Reach <span class="event-highlight">Right Audience</span>',
        tlabel: 'website.reach_right_audience',
        desc: 'Display content on basis of audience. Easy page building enables type and order of content to be shown.',
        tdesc: 'website.audience_basis_content_display',
        type: 'event-highlight',
        data: [
            {
                title: '',
                desc: 'Events only for your organisation',
                tdesc: 'website.events_only_for_org',
                icon: 'assets/img/events/events-for-org.svg',
                iconActive: 'assets/img/events/events-for-org-active.svg',
                img: 'assets/img/events/events-for-org-preview.png'
            },
            {
                title: '',
                desc: 'Admin access to CMS',
                tdesc: 'website.admin_Access_cms',
                icon: 'assets/img/events/admin-access-cms.svg',
                iconActive: 'assets/img/events/admin-access-cms-active.svg',
                img: 'assets/img/events/admin-access-cms-preview.png'
            },
            {
                title: '',
                desc: 'Customise pages based on content',
                tdesc: 'website.content_based_customize',
                icon: 'assets/img/events/content-based-customize.svg',
                iconActive: 'assets/img/events/content-based-customize-active.svg',
                img: 'assets/img/events/content-based-customize-preview.png'
            }
        ]
    }
];

export const WEBSITE_CPAAS_FEATURES = [
    {
        label: '<span class="cpaas-highlight">Go Live in</span> no time',
        tlabel: 'website.go_live_quick',
        desc: 'Our sample code, APIs and SDKs will get you started in no time. <br />You can even schedule meetings on your platform and conduct them on JioMeet within a day.',
        tdesc: 'website.easy_communication_platform',
        type: 'cpaas-highlight',
        img: 'assets/img/cpaas/golive.png',
        imgMob: 'assets/img/cpaas/golive-mob.png',
        data: [],
        featureList: [
            { label: 'Plan a meeting', tlabel: 'website_platforms.plan_meeting' },
            { label: 'Basic audio call', tlabel: 'website_platforms.basic_audio_call' },
            { label: 'Basic video call', tlabel: 'website_platforms.basic_video_call' },
            { label: 'Live video streaming', tlabel: 'website_platforms.live_video_stream' },
            { label: 'Live audio streaming', tlabel: 'website_platforms.live_audio_stream' },
            { label: 'Screen sharing', tlabel: 'website_platforms.screen_sharing' },
            { label: 'White board', tlabel: 'website_platforms.white_board' }
        ]
    },
    {
        label: '<span class="cpaas-highlight">Native Meeting</span> Experience',
        tlabel: 'website.native_meeting_exp',
        desc: 'Own your user experience with our Developer APIs and mobile SDKs.',
        tdesc: 'website.experience_devapi_sdk',
        type: 'cpaas-highlight',
        img: 'assets/img/cpaas/native-meeting.png',

        featureList: [
            {
                label: 'Supports C, C++, .NET, Js, Node.js',
                tlabel: 'website_platforms.language_supports'
            },
            { label: 'Full access to SDK and APIs', tlabel: 'website_platforms.full_access_sdk' },
            { label: 'Smart Analytics', tlabel: 'website_platforms.smart_analytics' }
        ]
    },
    {
        label: 'Customize<br /><span class="cpaas-highlight">Look and Feel</span>',
        tlabel: 'website.customize_look_feel',
        desc: 'Deliver a white-labeled video conferencing solution',
        tdesc: 'website.deliver_conf_solution',
        type: 'cpaas-highlight',
        img: 'assets/img/cpaas/customize-look-feel.png',
        imgMob: 'assets/img/cpaas/customize-look-feel-mob.png',
        data: [],
        featureList: [
            { label: 'Update to your brand logo', tlabel: 'website_platforms.update_brand_logo' },
            { label: 'Change font family', tlabel: 'website_platforms.change_font_family' },
            { label: 'Custom icons', tlabel: 'website_platforms.custom_icons' },
            { label: 'Custom color palette', tlabel: 'website_platforms.custom_color_palette' }
        ]
    },
    {
        label: 'Meeting <span class="cpaas-highlight">Analytics</span>',
        tlabel: 'website.meeting_analytics',
        desc: 'Have a detailed view of how users interact on your platform.',
        tdesc: 'website.have_detailed_view',
        type: 'cpaas-highlight',
        img: 'assets/img/cpaas/meeting-analytics.png',
        data: [],
        featureList: [
            { label: 'View meeting performance', tlabel: 'website_platforms.view_meeting_performance' },
            { label: 'Manage users and user role', tlabel: 'website_platforms.manage_user_role' },
            { label: 'Set file permissions', tlabel: 'website_platforms.set_file_permission' },
            { label: 'Export Reports', tlabel: 'website_platforms.export_report' }
        ]
    },
    {
        label: 'Enterprise <span class="cpaas-highlight">Support</span>',
        tlabel: 'website.enterprise_support',
        desc: 'Our technical support team is there to understand your requirements, and to enable you to build a great user experience.',
        tdesc: 'website.take_technical_support',
        type: 'cpaas-highlight',
        img: 'assets/img/cpaas/enterprise-support.png',
        imgMob: 'assets/img/cpaas/enterprise-support-mob.png',
        data: [],
        featureList: [
            { label: '24x7 support', tlabel: 'website_platforms.24_support' },
            { label: 'Quality of service reports', tlabel: 'website_platforms.quality_service_report' }
        ]
    },
    {
        label: 'Enriching connections for <span class="cpaas-highlight">niche use cases </span>',
        tlabel: 'website.enrich_connections',
        desc: 'JioMeet Platform integration enabled live classroom teaching for students and Doctor-patient consultations across India.',
        tdesc: 'website.cpaas_enabled_live_classroom',
        type: 'cpaas-highlight',
        featureList: [],
        data: [
            {
                desc: 'HealthCare Sector',
                tdesc: 'website_platforms.healthcare_sector',
                icon: 'assets/img/cpaas/health-sector.svg',
                iconActive: 'assets/img/cpaas/health-sector-active.svg',
                img: 'assets/img/cpaas/health-sector-preview.png'
            },
            {
                desc: 'Education Sector',
                tdesc: 'website_platforms.education_sector',
                icon: 'assets/img/cpaas/education-sector.svg',
                iconActive: 'assets/img/cpaas/education-sector-active.svg',
                img: 'assets/img/cpaas/education-sector-preview.png'
            }
        ]
    }
];

export const INCOMING_CALL_AUDIOS = [
    {
        name: 'JioMeet Ringtone',
        tname: 'user_dashboard_settings.notification.jiomeet_tone',
        audioPath: './assets/audio/call.mp3'
    },
    {
        name: 'JioMeet Ringtone1',
        tname: 'user_dashboard_settings.notification.ringtone1',
        audioPath: './assets/audio/JioMeetRingtone1.mp3'
    },
    {
        name: 'JioMeet Ringtone2',
        tname: 'user_dashboard_settings.notification.ringtone2',
        audioPath: './assets/audio/JioMeetRingtone2.mp3'
    },
    {
        name: 'Silent',
        tname: 'user_dashboard_settings.notification.silent',
        audioPath: ''
    }
];

export const TAILORED_USECASES = [
    {
        feature: 'Conference',
        tfeature: 'website_conference.conference_key',
        desc: 'Hold large conferences with unlimited access to features like lounges, backstage etc',

        tdesc: 'website_conference.conference_desc',
        icon: 'assets/img/conference/conference.svg'
    },
    {
        feature: 'Summits',
        tfeature: 'website_conference.summits',
        desc: 'The best quality video conf expereince at no charge for a large crowd',

        tdesc: 'website_conference.summits_desc',
        icon: 'assets/img/conference/summits.svg'
    },
    {
        feature: 'Product Launches',
        tfeature: 'website_conference.product_launches',
        desc: 'Streaming capabilites offer that allows you to reach a wider audience',

        tdesc: 'website_conference.product_launches_desc',
        icon: 'assets/img/conference/product_launches.svg'
    },
    {
        feature: 'Networking Events',
        tfeature: 'website_conference.networking_events',
        desc: 'Try our lounge and speed networking feature to make professional connections',

        tdesc: 'website_conference.networking_events_desc',
        icon: 'assets/img/conference/networking_events.svg'
    },
    {
        feature: 'Award Ceremonies',
        tfeature: 'website_conference.award_ceremonies',
        desc: 'Stream live the most memorable moments',

        tdesc: 'website_conference.award_ceremonies_desc',
        icon: 'assets/img/conference/award_ceremonies.svg'
    },
    {
        feature: 'Workshops',
        tfeature: 'website_conference.workshops',
        desc: 'Yoga or Cooking, pick up a new hobby with interactive audiences',

        tdesc: 'website_conference.workshops_desc',
        icon: 'assets/img/conference/workshops.svg'
    },
    {
        feature: 'Job Fairs',
        tfeature: 'website_conference.job_fairs',
        desc: 'Our exhibition booth area is convinient and easy',

        tdesc: 'website_conference.job_fairs_desc',
        icon: 'assets/img/conference/job_fairs.svg'
    },
    {
        feature: 'Online Courses',
        tfeature: 'website_conference.online_courses',
        desc: 'Learning made easy in this virtual environment.',

        tdesc: 'website_conference.online_courses_desc',
        icon: 'assets/img/conference/online_courses.svg'
    },
    {
        feature: 'Hackathons',
        tfeature: 'website_conference.hackathons',
        desc: 'Virtual meet for virtual events',

        tdesc: 'website_conference.hackathons_desc',
        icon: 'assets/img/conference/hackathons.svg'
    },
    {
        feature: 'Trade show',
        tfeature: 'website_conference.trade_show',
        desc: 'Exhibition booths, sponsors, branding we got it all.',

        tdesc: 'website_conference.trade_show_desc',
        icon: 'assets/img/conference/trade_show.svg'
    },
    {
        feature: 'Backstage',
        tfeature: 'website_conference.backstage',
        desc: 'Backstage ready for speakers, panels, hosts and event organizers',

        tdesc: 'website_conference.backstage_desc',
        icon: 'assets/img/conference/backstage.svg',
        hasFlagTag: 'Coming Soon',
        thasFlagTag: 'website_conference.coming_soon'
    },
    {
        feature: 'Parallel Sessions',
        tfeature: 'website_conference.parallel_sessions',
        desc: 'Conduct a full on workshop/ conference with as many sessions',

        tdesc: 'website_conference.parallel_sessions_desc',
        icon: 'assets/img/conference/parallel_sessions.svg',
        hasFlagTag: 'Coming Soon',
        thasFlagTag: 'website_conference.coming_soon'
    }
];

export const PRICING_CARD = [
    {
        isPopular: false,
        title: 'standard_plan.individual_plan',
        cost: 'standard_plan.free',
        description: 'standard_plan.description',
        hashtags: 'standard_plan.hashtags',
        plan_includes: 'standard_plan.plan_includes',
        card_items: [
            'standard_plan.cardItem1',
            'standard_plan.cardItem2',
            'standard_plan.cardItem3',
            'standard_plan.cardItem4',
            'standard_plan.cardItem5'
        ],
        button: 'standard_plan.button',
        current: 'pro_plan.current_button',
        class: 'standardcard'
    },
    {
        isPopular: true,
        title: 'pro_plan.individual_plan',
        cost: 'pro_plan.monthly',
        cost2: 'pro_plan.annual',
        description: 'pro_plan.description',
        hashtags: 'pro_plan.hashtags',
        plan_includes: 'pro_plan.plan_includes',
        card_items: [
            'pro_plan.cardItem1',
            'pro_plan.cardItem2',
            'pro_plan.cardItem3',
            'pro_plan.cardItem4',
            'pro_plan.cardItem5'
        ],
        button: 'pro_plan.buy_button',
        current: 'pro_plan.current_button',
        class: 'pluscard'
    },
    {
        isPopular: false,
        title: 'custom_pack.individual_plan',
        cost: 'custom_pack.free',
        description: 'custom_pack.description',
        hashtags: 'custom_pack.hashtags',
        plan_includes: 'custom_pack.plan_includes',
        card_items: [
            'custom_pack.cardItem1',
            'custom_pack.cardItem2',
            'custom_pack.cardItem3',
            'custom_pack.cardItem4',
            'custom_pack.cardItem5'
        ],
        button: 'custom_pack.button',
        current: 'pro_plan.current_button',
        class: 'customcard'
    }
];

export const ADD_ONS = [
    {
        img: 'assets/img/enterprise/pricing-cloud-storage.svg',
        title: 'add_ons_cloud.title',
        card_items: ['add_ons_cloud.listItem1', 'add_ons_cloud.listItem2']
    },
    {
        img: 'assets/img/enterprise/pricing-conference-icon.svg',
        title: 'add_ons_conference.title',
        card_items: ['add_ons_conference.listItem1', 'add_ons_conference.listItem2']
    },
    {
        img: 'assets/img/enterprise/pricing-100-participant.svg',
        title: 'add_ons_participants.title',
        card_items: ['add_ons_participants.listItem1', 'add_ons_participants.listItem2']
    },
    {
        img: 'assets/img/enterprise/pricing-live-streaming.svg',
        title: 'add_ons_live_streaming.title',
        card_items: ['add_ons_live_streaming.listItem1', 'add_ons_live_streaming.listItem2']
    }
];
