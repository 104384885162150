import { ToastrService } from 'ngx-toastr';
import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    AfterViewInit,
    Input,
    OnDestroy,
    ViewChild,
    NgZone,
    ElementRef,
    QueryList,
    ViewChildren
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GA_EVENTS } from '../../ga-events';
import { DatePipe } from '@angular/common';
import {
    addMinutes,
    isBefore,
    differenceInHours,
    differenceInMinutes,
    getDate,
    isLastDayOfMonth,
    addMonths,
    getDay,
    addDays,
    parseISO,
    setDate,
    subMinutes,
    isToday,
    differenceInMonths
} from 'date-fns';
import * as _ from 'lodash-es';
import { MsalService } from '@azure/msal-angular';
import { Client } from '@microsoft/microsoft-graph-client';
import { Calendar } from 'primeng/calendar';
import {
    UserService,
    MeetingService,
    UtilService,
    EventEmitterService,
    GoogleapiService,
    AppService,
    ExternalIntegrationService,
    ThirdPartyExternalIntegrationService,
    ValidationService,
    GaDiagnosticService,
    GoogleTagManagerService
} from 'src/app/core';
import { PadPipe } from 'src/app/shared';
import { APP_EVENTS, EXTERNAL_INTEGRATION, KEYBOARD_CONSTANT } from 'src/app/constants';
import { NavigationStart, Router } from '@angular/router';
import { GA_NEW } from '../../ga-new';
import { Tooltip } from 'primeng/tooltip';
import { LocalHelperServiceService } from 'src/app/contacts/local-helper-service.service';

declare const $: any;

@Component({
    selector: 'app-meeting-form',
    templateUrl: './meeting-form.component.html',
    styleUrls: ['./meeting-form.component.scss'],
    providers: [PadPipe]
})
export class MeetingFormComponent implements OnInit, AfterViewInit, OnDestroy {
    items = [];
    @ViewChild('calendar') startTimeCalendar: Calendar;
    @Input() meeting;
    @Input() declinedUsers;
    @Input() singleEdit;
    @Input() selectedDate;
    @Output() close: EventEmitter<any> = new EventEmitter();
    item: any;
    currentUser;
    routerPopstateSub;
    form: FormGroup;
    previousForm: any = {};
    disableSave = true;
    minDate = new Date();
    meetingStartTime = new Date();
    startTimeString = 'Select Time';
    durationString = 'Select Duration';
    error = null;
    defaultMeetingTopic;
    advancedOptionsCollapsed = false;
    submitting = false;
    scheduled = false;
    copiedInviteToClipboard = false;
    loading = true;
    minserror = null;
    divstarttime_firsttime = true;
    divduration_firsttime = true;
    hours = [...Array(13).keys()].slice(1);
    minutes = [...Array(60).keys()];
    durationHours = [...Array(24).keys()];
    durationMinutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
    durationMinutes1 = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
    durationMinutes2 = [0, 5, 10, 15, 20, 25, 30];
    isGoogleCalendar = false;
    isOutlookCalendar = false;
    isOtherCalendar = false;
    isOutlookAuthenticated = false;
    outlookOAuthSettings = {
        appId: EXTERNAL_INTEGRATION.EC_OUTLOOK_CLIENT_ID,
        redirectUri: EXTERNAL_INTEGRATION.EC_OUTLOOK_REDIRECT_URI,
        scopes: EXTERNAL_INTEGRATION.EC_OUTLOOK_SCOPES,
        prompt: 'consent'
    };
    todaysDayIndex = 0;
    isMyjioIntegration = false;
    isMeetingForSelf = true;
    isMeetingOnBehalf = false;
    private timeoutIds = [];
    classRoomModeEnabled;
    isThirdPartyExternalIntegration;
    isMobileDevice;
    thirdPartyEventSubscriptions = [];
    updateddate: any;
    date = new Date();
    index = 0;
    recurringSupportForAPI: boolean = false;
    @ViewChildren(Tooltip) tooltips!: QueryList<Tooltip>;

    recurStartTime;
    recurEndTime;
    currUserMaxParticipants = 150;
    isFreeUser = true;
    isEditandOnBehalfMeeting;
    selectedTime: string = null;
    showDropdown: boolean = false;
    times: { label: string; value: string }[] = [];

    selectedTimeIndex: number | null = null;
    hoveredTimeIndex: number | null = null;
    conCurrentKey;
    currentMeetingDetails;
    focusedIndex: number = -1;
    selectedStartTime: Date = new Date();
    constructor(
        private router: Router,
        private zone: NgZone,
        public localHelperServiceService: LocalHelperServiceService,
        private fb: FormBuilder,
        private toastrService: ToastrService,
        private userService: UserService,
        private meetingService: MeetingService,
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private padPipe: PadPipe,
        private googleApiService: GoogleapiService,
        private msalService: MsalService,
        private appService: AppService,
        private translateService: TranslateService,
        private externalIntegrationService: ExternalIntegrationService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        private validationService: ValidationService,
        private gaService: GaDiagnosticService,
        private gTagService: GoogleTagManagerService,
        private datePipe: DatePipe
    ) {}

    ngOnInit() {
        this.populateTimeArray();
        this.currentMeetingDetails = this.meeting;
        this.isThirdPartyExternalIntegration =
            this.thirdPartyExternalIntegrationService.getThirdPartyExternalIntegration();
        this.isMobileDevice = this.utilService.isMobileBrowser() || this.isThirdPartyExternalIntegration;
        this.isMyjioIntegration = this.thirdPartyExternalIntegrationService.getMyjioIntegration();
        this.routerPopstateSub = this.router.events.subscribe((event: NavigationStart) => {
            if (event.navigationTrigger == 'popstate') {
                this.cancel();
            }
        });

        this.setThirdPartyEventSubscriptions();
        this.classRoomModeEnabled = this.appService.getConfigVariable('classroom_mode');
        this.recurringSupportForAPI = this.appService.getConfigVariable('RECURRING_SUPPORT_FOR_API');
        this.currentUser = this.userService.getUserSync();

        this.defaultMeetingTopic = `${
            this.currentUser.lname ? this.currentUser.name + ' ' + this.currentUser.lname : this.currentUser?.name
        }${this.translateService.instant('planmeeting_popup.name')}`;
        this.translateService.onLangChange.subscribe(
            () =>
                (this.defaultMeetingTopic = `${
                    this.currentUser.lname
                        ? this.currentUser.name + ' ' + this.currentUser.lname
                        : this.currentUser?.name
                }${this.translateService.instant('planmeeting_popup.name')}`)
        );
        this.translateService.onLangChange.subscribe(() => this.createForm());
        this.conCurrentKey = this.meeting?.conCurrentKey;
        this.createForm();
        this.createPreviousForm();
        this.onChanges();
        this.date = this.selectedDate || new Date();
        if (!isToday(this.date) && this.date < new Date()) {
            this.date = new Date();
        }
        if (this.recurringSupportForAPI && this.form?.value?.repeatOptions?.repeat) {
            this.date = new Date(this.singleEdit ? this.meeting?.updatedStartTime : this.meeting?.startTime);
        }
        if (this.currentUser?.copyPasteMailLimit) {
            this.currUserMaxParticipants = this.currentUser?.copyPasteMailLimit;
        }
        this.isFreeUser = this.currentUser?.isOTT && this.currentUser?.proStatus == false;

        this.eventEmitterService.subscribe((event: any) => {
            if (
                event?.type === KEYBOARD_CONSTANT.KEYBOARD_SHORTCUTS &&
                event?.data === KEYBOARD_CONSTANT.CLOSE_WINDOW
            ) {
                this.cancel();
            }
        });
        this.isEditandOnBehalfMeeting = this.meeting && this.meeting?.isOnBehalfMeeting;
    }

    exceededCharacterLimit(event, max_limit) {
        this.localHelperServiceService.exceededCharacterLimitToast(event, max_limit);
    }

    setThirdPartyEventSubscriptions() {
        this.thirdPartyEventSubscriptions.push(this.routerPopstateSub);
    }

    createPreviousForm() {
        const participants = [...this.form.value.participants.keys()];
        this.previousForm = this.utilService.clone(this.form.value);
        this.previousForm.participants = participants;
    }

    getCopyArialLabel(): string {
        return this.copiedInviteToClipboard ? 'Invitation Copied' : 'Copy Invitation';
    }

    getInviteParticpantTooltip() {
        return this.translateService.instant('planmeeting_popup.participants_limit_tooltip', {
            value: this.currUserMaxParticipants
        });
    }
    ngAfterViewInit() {
        this.addEventListenerForTimeInput();
        this.addEventListenerStartTimeDropdown();

        const isGoogleLibraryLoaded = this.googleApiService.isGapiLibraryLoaded();

        if (!isGoogleLibraryLoaded) {
            this.externalIntegrationService.loadScript(EXTERNAL_INTEGRATION.EC_GOOGLE_AUTH_LIBRARY_URL).onload = () => {
                this.loadGoogleApi();
            };
        } else {
            this.loadGoogleApi();
        }
    }
    hideDivPopupMenu() {
        if (!this.divstarttime_firsttime && !this.divduration_firsttime) {
            document.getElementById('divStartTime').style.display = 'none';
            document.getElementById('divDuration').style.display = 'none';
        } else if (!this.divstarttime_firsttime && document.getElementById('divStartTime')?.style.display == 'block') {
            document.getElementById('divStartTime').style.display = 'none';
        } else if (document.getElementById('divStartTime')?.style.display == 'block') {
            this.divstarttime_firsttime = false;
        } else if (!this.divduration_firsttime && document.getElementById('divDuration')?.style.display == 'block') {
            document.getElementById('divDuration').style.display = 'none';
        } else if (document.getElementById('divDuration')?.style.display == 'block') {
            this.divduration_firsttime = false;
        }
    }
    showDivPopupMenu(event) {
        var id = event.target.attributes.id;
        if (id?.value === 'timeInputDropdownInput') {
            document.getElementById('divStartTime').style.display =
                document.getElementById('divStartTime').style.display == 'block' ? 'none' : 'block';
            if (document.getElementById('divDuration').style.display === 'block') {
                document.getElementById('divDuration').style.display = 'none';
            }
        } else if (id?.value === 'durationDropdownInput') {
            document.getElementById('divDuration').style.display =
                document.getElementById('divDuration').style.display == 'block' ? 'none' : 'block';
            if (document.getElementById('divStartTime').style.display === 'block') {
                document.getElementById('divStartTime').style.display = 'none';
            }
        }
    }
    private findTooltipById(searchId: string): Tooltip | undefined {
        return this.tooltips.find((tooltip) => tooltip?.el?.nativeElement?.id === searchId);
    }

    onClickPlusIcon(id: string) {
        const foundTooltip = this.findTooltipById(id);
        if (foundTooltip) {
            foundTooltip.activate();
        }
    }
    addEventListenerForTimeInput() {
        document.querySelectorAll('.time-input').forEach((node) =>
            node.addEventListener('click', (e) => {
                if (node.querySelector('.time-input .dropdown-menu').contains(e.target as any)) {
                    if (
                        (e.target as any).classList.contains('custom-button') ||
                        (e.target as any).classList.contains('dropdown-option')
                    ) {
                        return true;
                    } else {
                        e.stopImmediatePropagation();
                        e.stopPropagation();
                        return false;
                    }
                }
            })
        );
    }

    addEventListenerStartTimeDropdown() {
        document.querySelectorAll('.timestartTimeDropdown' || '.startTimeDropdown').forEach((node) => {
            $(node).on('show.bs.dropdown', () => {
                this.zone.run(() => {
                    if (this.startTimeCalendar.overlayVisible) {
                        this.startTimeCalendar.toggle();
                    }
                });
            });
        });
    }

    loadGoogleApi() {
        this.googleApiService.loadGoogleAPI(() => {
            this.googleApiService.initializeGoogleAPI(
                EXTERNAL_INTEGRATION.EC_GOOGLE_CLIENT_ID,
                EXTERNAL_INTEGRATION.EC_GOOGLE_DISCOVERY_DOCS,
                EXTERNAL_INTEGRATION.EC_GOOGLE_SCOPES
            );
        });
    }

    createForm() {
        this.setTodaysIndex();

        const startDate = this.meeting
            ? new Date(this.singleEdit ? this.meeting.updatedStartTime : this.meeting.startTime)
            : addMinutes(new Date(), this.calcNearestInterval());
        const endDate = this.meeting
            ? new Date(this.singleEdit ? this.meeting.updatedEndTime : this.meeting.endTime)
            : null;

        const hours = startDate.getHours();
        const minutes = startDate.getMinutes();

        let durationInHours = 0;
        let durationInMinutes = 30;
        let participants = new Map();
        let cohosts = new Map();
        let onBehalfhosts;

        if (this.meeting) {
            let { startTime, endTime, updatedStartTime, updatedEndTime } = this.meeting;
            startTime = new Date(this.singleEdit ? updatedStartTime : startTime);
            endTime = new Date(this.singleEdit ? updatedEndTime : endTime);

            durationInHours = this.meeting
                ? differenceInHours(
                      new Date(this.singleEdit ? this.meeting.updatedEndTime : this.meeting.endTime),
                      new Date(this.singleEdit ? this.meeting.updatedStartTime : this.meeting.startTime)
                  )
                : 0;
            durationInMinutes = this.meeting
                ? differenceInMinutes(
                      new Date(this.singleEdit ? this.meeting.updatedEndTime : this.meeting.endTime),
                      new Date(this.singleEdit ? this.meeting.updatedStartTime : this.meeting.startTime)
                  )
                : 0;

            this.durationMinutes = durationInHours.toString() === '23' ? this.durationMinutes2 : this.durationMinutes1;

            durationInMinutes -= durationInHours * 60;

            this.setCalenderType();

            (this.meeting.participants || [])
                .filter(
                    (participant) =>
                        participant.userId !== this.currentUser._id && !(participant?.isHost || participant?.isCoHost)
                )
                .forEach((participant) => participants.set(participant.email || participant.phoneNo, participant));
            (this.meeting.cohosts || [])
                .filter((cohost) => cohost.userId !== this.currentUser._id)
                .forEach((cohost) => cohosts.set(cohost.email || cohost.phoneNo, cohost));

            this.recurStartTime = this.meeting.startTime;
            this.recurEndTime = this.meeting.endTime;
            if (this.meeting?.isOnBehalfMeeting) {
                if (this.meeting?.hostInfo?._id != this.currentUser?._id) {
                    cohosts.set(this.currentUser.email, this.currentUser);
                }
                this.onBehalfHostClicked();
                this.item = this.meeting.hostInfo;
                this.items.push(this.item);
            } else {
                this.mySelfClicked();
            }
        }

        this.setFormValues(
            startDate,
            endDate,
            hours,
            minutes,
            durationInHours,
            durationInMinutes,
            participants,
            cohosts,
            onBehalfhosts
        );

        this.listenToFormValueChanges();

        this.setEndDate(this.form.value.startTime);
        this.selectedStartTime = this.form.value.startTime;
    }

    calcNearestInterval() {
        let startsInMinutes = 15;
        const timeNow = new Date();
        const minutesNow = timeNow.getMinutes();
        if (minutesNow >= 0 && minutesNow < 15) {
            startsInMinutes = 15 - minutesNow;
        } else if (minutesNow > 15 && minutesNow < 30) {
            startsInMinutes = 30 - minutesNow;
        } else if (minutesNow > 30 && minutesNow < 45) {
            startsInMinutes = 45 - minutesNow;
        } else if (minutesNow > 45 && minutesNow < 60) {
            startsInMinutes = 60 - minutesNow;
        }
        if (startsInMinutes === 1) {
            startsInMinutes = 16;
        } else if (startsInMinutes === 0) {
            startsInMinutes = 15;
        }
        return startsInMinutes;
    }

    setFormValues(
        startDate,
        endDate,
        hours,
        minutes,
        durationInHours,
        durationInMinutes,
        participants,
        cohosts,
        onBehalfhosts
    ) {
        this.form = this.fb.group({
            topic: [this.meeting?.topic || null, [Validators.maxLength(100)]],
            startTime: [startDate, [Validators.required]],
            endTime: [endDate, [Validators.required]],
            duration: this.setDurationInForm(hours, minutes, durationInHours, durationInMinutes),
            participants: [participants],
            cohosts: [cohosts],
            onBehalfhosts: [onBehalfhosts],
            advancedOptions: this.setAdvancedOptionsForm(),
            isDynamicRoom: [this.meeting?.isDynamicRoom || true],
            isPinEnabled: [this.meeting?.isPinEnabled || true],
            calendarOptions: this.setCalendarOptionsInForm(),
            repeatOptions: this.setRepeatOptionsInForm()
        });
    }

    setCalendarOptionsInForm() {
        return this.fb.group({
            calendar: this.meeting?.calendarType || 'other'
        });
    }

    setDurationInForm(hours, minutes, durationInHours, durationInMinutes) {
        return this.fb.group({
            hour: [`${hours > 12 ? hours - 12 : hours}`, [Validators.required]],
            minutes: [minutes.toString(), [Validators.required]],
            meridiem: [hours > 11 ? 'pm' : 'am', [Validators.required]],
            durationInHours: [durationInHours.toString(), [Validators.required]],
            durationInMinutes: [durationInMinutes.toString(), [Validators.required]]
        });
    }

    setAdvancedOptionsForm() {
        return this.fb.group({
            hostAudio: [this.meeting?.advancedOptions?.hostAudio || false],
            participantAudio: [
                typeof this.meeting?.advancedOptions?.participantAudio !== 'undefined'
                    ? !this.meeting?.advancedOptions?.participantAudio
                    : false
            ],
            hostVideo: [this.meeting?.advancedOptions?.hostVideo || false],
            participantVideo: [this.meeting?.advancedOptions?.participantVideo || true],
            joinBeforeHost: [
                typeof this.meeting?.advancedOptions?.joinBeforeHost !== 'undefined'
                    ? this.meeting?.advancedOptions?.joinBeforeHost
                    : true
            ],
            waitingRoom: [this.meeting?.advancedOptions?.waitingRoom || false],
            loggedInOnly: [this.meeting?.advancedOptions?.loggedInOnly || false],
            colleagueOnly: [this.meeting?.advancedOptions?.colleagueOnly || false],
            participantHardAudioMute: [this.meeting?.advancedOptions?.participantHardAudioMute || false],
            isRestrictedMeeting: [this.meeting?.advancedOptions?.isRestrictedMeeting || false],
            isTranslationEnabled: [this.meeting?.advancedOptions?.isTranslationEnabled || false],
            isClassroomMode: [this.meeting?.advancedOptions?.isClassroomMode || false],
            restrictChat: [this.handleRestrictChatValue()]
        });
    }

    handleRestrictChatValue() {
        return this.meeting?.advancedOptions?.restrictChat
            ? this.meeting?.advancedOptions?.restrictChat === 'NONE' ||
              this.meeting?.advancedOptions?.restrictChat === 'PARTICIPANTS'
                ? false
                : true
            : false;
    }

    setRepeatOptionsInForm() {
        return this.fb.group({
            repeat: [this.getRepeatMode()],
            interval: [this.meeting?.repeatOptions?.interval || 1],
            daysOfWeek: this.fb.array(
                this.meeting?.repeatOptions?.daysOfWeek &&
                    this.meeting?.repeatOptions?.daysOfWeek.filter((d) => d).length
                    ? this.deSerializeDays(this.meeting?.repeatOptions?.daysOfWeek)
                    : this.getDefaultSelectedDays()
            ),
            dateInMonth: [this.meeting?.repeatOptions?.dateInMonth || getDate(new Date())],
            lastDayOfMonth: [this.meeting?.repeatOptions?.lastDayOfMonth || isLastDayOfMonth(new Date())],
            weeksInMonth: this.fb.array(this.meeting?.repeatOptions?.weeksInMonth || [0, 0, 0, 0, 0]),
            endDate: [
                this.meeting?.repeatOptions?.repeat !== 'none' && this.meeting?.repeatOptions?.endDate
                    ? new Date(this.meeting?.repeatOptions?.endDate.substring(0, 10))
                    : this.getDefaultEndDate()
            ]
        });
    }

    deSerializeDays(days) {
        return days?.map((x) => {
            return !!x;
        });
    }

    setTodaysIndex() {
        this.todaysDayIndex = this.getDefaultSelectedDays().findIndex((i) => i);
    }

    setCalenderType() {
        if (this.meeting.calendarType) {
            this.isGoogleCalendar = this.meeting.calendarType === 'google';
            this.isOutlookCalendar = this.meeting.calendarType === 'outlook';
            this.isOtherCalendar = !(this.meeting.calendarType === 'google' || this.meeting.calendarType === 'outlook');
        }
    }

    onChanges(): void {
        this.form.valueChanges.subscribe((val) => {
            this.compWithPreviousForm(val);
        });

        this.form.get('participants').valueChanges.subscribe(() => this.checkIfUserAlreadyPresent('participants'));
        this.form.get('cohosts').valueChanges.subscribe(() => this.checkIfUserAlreadyPresent('cohosts'));
    }

    checkIfUserAlreadyPresent(section) {
        let cohosts = this.form.get('cohosts').value;
        let participants = this.form.get('participants').value;

        if (section === 'participants') {
            let alreadyPresent = [];
            for (let key of participants.keys()) {
                if (cohosts.has(key)) {
                    alreadyPresent.push(key);
                }
            }
            if (alreadyPresent.length > 0) {
                const toastMsg =
                    alreadyPresent.length > 1
                        ? this.translateService.instant('planmeeting_popup.one_or_more_is_already_added_multiple', {
                              value1: `${cohosts.get(alreadyPresent[0]).name} ${cohosts.get(alreadyPresent[0]).lname}`,
                              value2: alreadyPresent.length - 1
                          })
                        : this.translateService.instant('planmeeting_popup.one_or_more_is_already_added_single', {
                              value: `${cohosts.get(alreadyPresent[0]).name} ${cohosts.get(alreadyPresent[0]).lname}`
                          });
                this.toastrService.info(
                    toastMsg + ' ' + this.translateService.instant('planmeeting_popup.already_added_as_cohost')
                );
            }
        } else if (section === 'cohosts') {
            let alreadyPresent = [];
            for (let key of cohosts.keys()) {
                if (participants.has(key)) {
                    alreadyPresent.push(key);
                }
            }
            if (alreadyPresent.length > 0) {
                const toastMsg =
                    alreadyPresent.length > 1
                        ? this.translateService.instant('planmeeting_popup.one_or_more_is_already_added_multiple', {
                              value1: `${cohosts.get(alreadyPresent[0]).name} ${cohosts.get(alreadyPresent[0]).lname}`,
                              value2: alreadyPresent.length - 1
                          })
                        : this.translateService.instant('planmeeting_popup.one_or_more_is_already_added_single', {
                              value: `${cohosts.get(alreadyPresent[0]).name} ${cohosts.get(alreadyPresent[0]).lname}`
                          });
                this.toastrService.info(
                    toastMsg + ' ' + this.translateService.instant('planmeeting_popup.already_added_as_participant')
                );
            }
        }
    }

    compWithPreviousForm(val) {
        const participants = [...val.participants.keys()];
        const cohosts = [...val.cohosts.keys()];
        let clonedVal = this.utilService.clone(val);
        clonedVal.participants = participants;
        clonedVal.cohosts = cohosts;
        clonedVal.startTime = clonedVal.startTime.split('T')[0];
        let previousCloned = this.utilService.clone(this.previousForm);
        previousCloned.startTime = previousCloned.startTime.split('T')[0];
        clonedVal.endTime = clonedVal.endTime.split('T')[0];
        previousCloned.endTime = previousCloned.endTime.split('T')[0];

        clonedVal.repeatOptions.endDate = this.setRecurEndDateMinutes(
            new Date(clonedVal.repeatOptions.endDate),
            clonedVal.duration
        ).toISOString();

        clonedVal.repeatOptions.endDate = clonedVal.repeatOptions.endDate.split('T')[0];
        previousCloned.repeatOptions.endDate = previousCloned.repeatOptions.endDate.split('T')[0];
        if (JSON.stringify(previousCloned) === JSON.stringify(clonedVal)) {
            this.disableSave = true;
        } else {
            this.disableSave = false;
        }
    }

    onRecurUntillCalenderSelected() {
        this.meetingStartTime = addDays(new Date(this.form.value?.startTime), 1);
    }

    listenToFormValueChanges() {
        this.form.get('startTime').valueChanges.subscribe((startTime) => {
            this.setEndDate(startTime);
            this.form.get('repeatOptions').patchValue({
                endDate: this.getEndDate(),
                dateInMonth: getDate(startTime)
                // daysOfWeek: this.getDefaultSelectedDays(startTime)
            });
            this.todaysDayIndex = this.getDefaultSelectedDays(startTime).findIndex((i) => i);
        });

        this.listenToAdvancedOptions();

        this.checkMeetingDuration();

        this.enableSaveButton();
    }

    getEndDate() {
        return this.previousForm?.repeatOptions?.endDate && this.previousForm?.repeatOptions?.repeat
            ? this.setRecurEndDateMinutes(
                  new Date(this.previousForm?.repeatOptions?.endDate),
                  this.previousForm?.duration
              )
            : this.getDefaultEndDate(this.form.value?.repeatOptions?.repeat);
    }

    listenToAdvancedOptions() {
        this.listenToRestrictedMeetingOptions();
        this.listenToWaitingRoomOption();
        this.listenToJoinBeforeHostOption();
        this.listenToParticipantAudioOption();
        this.listenToParticipantHardAudioMuteOption();
        this.listenToColleagueOnlyOption();
        this.listenToLoggedInOnlyOption();
        this.listenToDisableChatOption();
        this.listenToClassroomOption();
    }

    listenToRestrictedMeetingOptions() {
        this.form
            .get('advancedOptions')
            .get('isRestrictedMeeting')
            .valueChanges.subscribe((isRestrictedMeeting) => {
                if (isRestrictedMeeting) {
                    this.form.get('advancedOptions').patchValue({ loggedInOnly: false, colleagueOnly: false });
                }
            });
    }

    listenToWaitingRoomOption() {
        this.form
            .get('advancedOptions')
            .get('waitingRoom')
            .valueChanges.subscribe((waitingRoom) => {
                if (waitingRoom) {
                    this.form.get('advancedOptions').patchValue({ joinBeforeHost: false });
                }
            });
    }

    listenToJoinBeforeHostOption() {
        this.form
            .get('advancedOptions')
            .get('joinBeforeHost')
            .valueChanges.subscribe((joinBeforeHost) => {
                if (joinBeforeHost) {
                    this.form.get('advancedOptions').patchValue({ waitingRoom: false });
                }
            });
    }

    listenToParticipantAudioOption() {
        this.form
            .get('advancedOptions')
            .get('participantAudio')
            .valueChanges.subscribe((participantAudio) => {
                if (participantAudio && this.form.value.advancedOptions.participantHardAudioMute) {
                    this.form.get('advancedOptions').patchValue({ participantHardAudioMute: false });
                }
            });
    }

    listenToParticipantHardAudioMuteOption() {
        this.form
            .get('advancedOptions')
            .get('participantHardAudioMute')
            .valueChanges.subscribe((participantHardAudioMute) => {
                if (participantHardAudioMute && this.form.value.advancedOptions.participantAudio) {
                    this.form.get('advancedOptions').patchValue({ participantAudio: false });
                }
            });
    }

    listenToColleagueOnlyOption() {
        this.form
            .get('advancedOptions')
            .get('colleagueOnly')
            .valueChanges.subscribe((colleagueOnly) => {
                if (colleagueOnly && this.form.value.advancedOptions.loggedInOnly) {
                    this.form.get('advancedOptions').patchValue({ loggedInOnly: false });
                }
            });
    }

    listenToLoggedInOnlyOption() {
        this.form
            .get('advancedOptions')
            .get('loggedInOnly')
            .valueChanges.subscribe((loggedInOnly) => {
                if (loggedInOnly && this.form.value.advancedOptions.colleagueOnly) {
                    this.form.get('advancedOptions').patchValue({ colleagueOnly: false });
                }
            });
    }

    listenToDisableChatOption() {
        this.form
            .get('advancedOptions')
            .get('restrictChat')
            .valueChanges.subscribe((restrictChat) => {
                if (restrictChat && this.form.value.advancedOptions.isClassroomMode) {
                    this.form.get('advancedOptions').patchValue({ isClassroomMode: false });
                }
            });
    }

    listenToClassroomOption() {
        this.form
            .get('advancedOptions')
            .get('isClassroomMode')
            .valueChanges.subscribe((isClassroomMode) => {
                if (isClassroomMode && this.form.value.advancedOptions.restrictChat) {
                    this.form.get('advancedOptions').patchValue({ restrictChat: false });
                }
            });
    }

    checkMeetingDuration() {
        this.form
            .get('duration')
            .get('durationInHours')
            .valueChanges.subscribe((durationInHours) => {
                if (durationInHours === '23') {
                    this.durationMinutes = this.durationMinutes2;
                    let currentMinutes = this.form.get('duration').get('durationInMinutes').value;
                    if (currentMinutes > '30') {
                        this.form.get('duration').get('durationInMinutes').setValue('30');
                    }
                } else {
                    this.durationMinutes = this.durationMinutes1;
                }
            });
    }

    getRepeatMode() {
        return this.meeting
            ? this.meeting.repeatOptions?.repeat !== 'none'
                ? this.meeting.repeatOptions?.repeat
                : false
            : false;
    }

    onRecurringToggle(event) {
        if (this.form?.value?.repeatOptions?.repeat) {
            this.form.get('repeatOptions').patchValue({
                repeat: 'daily',
                endDate:
                    this.meeting?.repeatOptions?.repeat !== 'none' && this.meeting?.repeatOptions?.endDate
                        ? new Date(this.meeting?.repeatOptions?.endDate)
                        : this.getDefaultEndDate('daily')
            });
            this.gTagService.sendEvent(GA_EVENTS.PLAN_A_MEETING_DAILY_RECURRING_CLICK);
        }
        if (!this.form?.value?.repeatOptions?.repeat) {
            this.form.get('repeatOptions').patchValue({
                repeat: false,
                interval: this.previousForm?.repeatOptions.interval,
                daysOfWeek: this.previousForm?.repeatOptions.daysOfWeek,
                dateInMonth: this.previousForm?.repeatOptions.dateInMonth,
                lastDayOfMonth: this.previousForm?.repeatOptions.lastDayOfMonth,
                weeksInMonth: this.previousForm?.repeatOptions.weeksInMonth,
                endDate: new Date(this.previousForm?.repeatOptions.endDate)
            });
        }
    }

    onRecurringEnter(event: KeyboardEvent): void {
        // Prevent the default behavior of the Enter key on the checkbox
        event.preventDefault();
        // Toggle the checkbox manually
        const checkbox = event.target as HTMLInputElement;
        checkbox.checked = !checkbox.checked;
        this.form.get('repeatOptions').patchValue({
            repeat: checkbox.checked
        });

        this.onRecurringToggle(event);
    }

    gTagRecurEvent(recurMode) {
        switch (recurMode.target.id) {
            case 'daily': {
                this.gTagService.sendEvent(GA_EVENTS.PLAN_A_MEETING_DAILY_RECURRING_CLICK);
                break;
            }
            case 'weekly': {
                this.gTagService.sendEvent(GA_EVENTS.PLAN_A_MEETING_WEEKLY_RECURRING_CLICK);
                break;
            }
            case 'monthly': {
                this.gTagService.sendEvent(GA_EVENTS.PLAN_A_MEETING_MONTHLY_RECURRING_CLICK);
                break;
            }
        }
    }

    onClickOfRecurOption(event) {
        this.form.get('repeatOptions').patchValue({
            endDate: this.getDefaultEndDate(event.target.value)
        });
    }

    getDefaultEndDate(option = null) {
        let monthsToBeAdded = option == 'monthly' ? 12 : option == 'weekly' ? 6 : 3;
        let endDate = addMonths(option == null ? new Date() : new Date(this.form.value.startTime), monthsToBeAdded);
        endDate.setHours(23, 59, 59, 999);
        return endDate;
    }

    getDefaultSelectedDays(startTime = null, values = null) {
        const array = Array(7).fill(false);
        array[getDay(startTime ? startTime : new Date())] = true;
        return array;
    }

    getSelectedWeekDays() {
        let allDays = this.form.get('repeatOptions')?.get('daysOfWeek').value;
        let selectedDays = '';
        for (let day = 0; day < 7; day++) {
            if (allDays[day] === true) {
                selectedDays += this.getDayFromIndex(day) + ' ';
            }
        }
        return selectedDays.trim().split(' ').join(', ');
        // return selectedDays;
    }

    getDayFromIndex(index) {
        switch (index) {
            case 0:
                return this.translateService.instant('planmeeting_popup.sun');
            case 1:
                return this.translateService.instant('planmeeting_popup.mon');
            case 2:
                return this.translateService.instant('planmeeting_popup.tue');
            case 3:
                return this.translateService.instant('planmeeting_popup.wed');
            case 4:
                return this.translateService.instant('planmeeting_popup.thu');
            case 5:
                return this.translateService.instant('planmeeting_popup.fri');
            case 6:
                return this.translateService.instant('planmeeting_popup.sat');
        }
    }

    // validation functions
    validate() {
        const { topic, startTime, endTime, repeatOptions } = this.form.value;
        if (!(topic || '').trim().length) {
            this.error = this.translateService.instant('planmeeting_popup.errors.topic_error');
            return false;
        }
        if ((topic || '').length > 150) {
            this.error = this.translateService.instant('planmeeting_popup.errors.character_error');
            return false;
        }
        if (isBefore(endTime, startTime)) {
            this.error = this.translateService.instant('planmeeting_popup.errors.endtime_error');
            return false;
        }
        if (differenceInMinutes(endTime, startTime) < 5) {
            this.error = this.translateService.instant('planmeeting_popup.errors.min_duration');
            return false;
        }
        if (differenceInHours(endTime, startTime) > 23) {
            this.error = this.translateService.instant('planmeeting_popup.errors.max_duration');
            return false;
        }
        if (differenceInMonths(repeatOptions.endDate, startTime) > 31) {
            this.error = this.translateService.instant('planmeeting_popup.errors.max_recur_duration');
            return false;
        }
        if (repeatOptions.repeat === 'weekly') {
            if (!repeatOptions.daysOfWeek.includes(true)) {
                this.error = this.translateService.instant('planmeeting_popup.errors.min_one_day');
                return false;
            }
            if (this.setStartTimeToLatestRecurrence() > repeatOptions.endDate) {
                this.error = this.translateService.instant('planmeeting_popup.errors.zero_meetings');
                return false;
            }
        }

        if (
            this.form.value?.advancedOptions?.isRestrictedMeeting &&
            this.form.value?.participants?.size === 0 &&
            this.form.value?.cohosts?.size === 0
        ) {
            this.error = this.translateService.instant('planmeeting_popup.errors.restricted_error');
            return false;
        }
        if (document.getElementsByClassName('participant-has-error').length > 0) {
            return false;
        }
        return true;
    }

    setRecurEndDateMinutes(endDate, duration) {
        endDate.setHours(23, 59, 59, 999);
        return endDate;
    }

    /**
     * Update jiomeet id as per the defined format: XXX XXX XXXX
     * @param meetingId Meeting Id
     */
    formatJiomeetId(meetingId) {
        let gap = 4;
        let count = 0;
        let result = '';
        for (let i = meetingId.length - 1; i >= 0; i--) {
            result = result + meetingId[i];
            count++;

            if (count === gap) {
                result += ' ';
                count = 0;

                if (gap === 4) {
                    gap = 3;
                }
            }
        }
        return result.split('').reverse().join('').trim();
    }

    handleOutlookCalendarMeeting() {
        const url = `${window.location.origin}/shortener?meetingId=${this.meeting.jiomeetId}&pwd=${this.meeting.pin}`;
        const duration = differenceInMinutes(new Date(this.meeting.endTime), new Date(this.meeting.startTime));
        const options: any = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        const description =
            `<br/><div><h3>${
                this.currentUser.name + ' ' + this.currentUser.lname
            } has invited you to a JioMeet meeting</h3></div>` +
            `<div><span>Topic : ${this.meeting.topic}</span><br/>` +
            `<span>Start Time : ${new Date(this.meeting.startTime).toLocaleDateString('en-IN', options)}</span><br/>` +
            `<span>Duration : ${duration} mins</span><br><br>` +
            `</div><div><span>Join the JioMeet meeting</span><br>` +
            `<a href="${url}">${url}</a><br><br>` +
            `</div><div><span>Meeting ID : ${this.formatJiomeetId(this.meeting.jiomeetId)}<span><br>` +
            `<span>Meeting Password : ${this.meeting.pin}</span><br><br>` +
            `</div><div><span>Add the meeting to JioMeet Calendar<span><br>` +
            `<span><a href="${this.meeting.addToJmtUrl}">${this.meeting.addToJmtUrl}</a></span><br><br></div>` +
            `<div><span>How to Join the Meeting?</span><br>` +
            `<span>&nbsp;&nbsp;&nbsp;&nbsp;Option 1 : Download JioMeet Mobile or Desktop App and use Meeting ID and Password to join the meeting</span><br>` +
            `<span>&nbsp;&nbsp;&nbsp;&nbsp;Option 2 : Open the Meeting URL on the chrome browser</span><br><span>&nbsp;&nbsp;&nbsp;&nbsp;` +
            `Option 3 : To join through SIP or H.323 device: Details will be shared 15 minutes prior to the meeting start time.</span><br><br>` +
            `</div><div>Regards ,<br>Team JioMeet</div>`;
        const subject = this.meeting.topic;

        const isUpdatedMeetingAndStillOutlookCal = this.isOutlookCalendar && this.meeting.calendarType === 'outlook';
        const isNewOutlookCalMeeting = this.isOutlookCalendar && this.meeting?.calendarType !== 'outlook';
        if (isNewOutlookCalMeeting) {
            this.createOutlookEvent(
                subject,
                url,
                description,
                new Date(this.meeting.startTime),
                new Date(this.meeting.endTime)
            ).then((event: any) => {
                this.handleCreateOutlookEvent(event);
            });
        } else if (isUpdatedMeetingAndStillOutlookCal) {
            this.updateOutlookEvent(
                this.meeting.externalCalendarEventId,
                subject,
                url,
                description,
                new Date(this.meeting.startTime),
                new Date(this.meeting.endTime)
            ).then((_event: any) => {
                // window.open(event.webLink, '_blank');
            });
        }
    }

    handleCreateOutlookEvent(event) {
        this.meetingService
            .updateExternalCalendarDetails(this.meeting._id, {
                calendarType: EXTERNAL_INTEGRATION.CALENDAR_TYPE_OUTLOOK,
                externalCalendarEventId: event.id
            })
            .subscribe(
                (res) => {},
                (err) => {
                    console.error(err);
                }
            );
        if (event.webLink) {
            window.open(event.webLink, '_blank');
        }
    }

    handleGoogleEventCreation(event) {
        console.error(event);
        if (event.htmlLink) {
            const htmlLink = event.htmlLink.replace(
                'https://www.google.com/calendar/event?eid=',
                'https://calendar.google.com/calendar/u/0/r/eventedit/'
            );
            this.meeting.calendarLink = htmlLink;
            this.scheduled = true;
            window.open(htmlLink, '_blank');
        }
        this.meetingService
            .updateExternalCalendarDetails(this.meeting._id, {
                calendarType: EXTERNAL_INTEGRATION.CALENDAR_TYPE_GOOGLE,
                externalCalendarEventId: event.id
            })
            .subscribe(
                (res) => {},
                (err) => {
                    console.error(err);
                    this.googleApiService.signOut();
                }
            );
    }

    handleGoogleCalendarMeeting(payload) {
        const isUpdatedMeetingAndStillGoogleCal =
            this.isGoogleCalendar &&
            this.meeting?.calendarType === EXTERNAL_INTEGRATION.CALENDAR_TYPE_GOOGLE &&
            this.meeting?.externalCalendarEventId;
        const attendees = payload.participants
            ? payload.participants
                  .map((participant) => (participant.email ? participant.email : participant))
                  .filter((email) => this.validationService.isEmail(email))
                  .map((participant) => ({ email: participant }))
            : [];
        const isNewGoogleCalMeeting = this.isGoogleCalendar && !isUpdatedMeetingAndStillGoogleCal;
        const url = `${window.location.origin}/shortener?meetingId=${this.meeting.jiomeetId}&pwd=${this.meeting.pin}`;
        const duration = differenceInMinutes(new Date(this.meeting.endTime), new Date(this.meeting.startTime));
        const options: any = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        const description =
            `<br/><div><h3>${
                this.currentUser.name + ' ' + this.currentUser.lname
            } has invited you to a JioMeet meeting</h3></div>` +
            `<div><span>Topic : ${this.meeting.topic}</span><br/>` +
            `<span>Start Time : ${new Date(this.meeting.startTime).toLocaleDateString('en-IN', options)}</span><br/>` +
            `<span>Duration : ${duration} mins</span><br><br>` +
            `</div><div><span>Join the JioMeet meeting</span><br>` +
            `<a href="${url}">${url}</a><br><br>` +
            `</div><div><span>Meeting ID : ${this.formatJiomeetId(this.meeting.jiomeetId)}<span><br>` +
            `<span>Meeting Password : ${this.meeting.pin}</span><br><br>` +
            `</div><div><span>Add the meeting to JioMeet Calendar<span><br>` +
            `<span><a href="${this.meeting.addToJmtUrl}">${this.meeting.addToJmtUrl}</a></span><br><br></div>` +
            `<div><span>How to Join the Meeting?</span><br>` +
            `<span>&nbsp;&nbsp;&nbsp;&nbsp;Option 1 : Download JioMeet Mobile or Desktop App and use Meeting ID and Password to join the meeting</span><br>` +
            `<span>&nbsp;&nbsp;&nbsp;&nbsp;Option 2 : Open the Meeting URL on the chrome browser</span><br><span>&nbsp;&nbsp;&nbsp;&nbsp;` +
            `Option 3 : To join through SIP or H.323 device: Details will be shared 15 minutes prior to the meeting start time.</span><br><br>` +
            `</div><div>Regards ,<br>Team JioMeet</div>`;
        const subject = this.meeting.topic;
        if (isNewGoogleCalMeeting) {
            this.meeting.calendarType = EXTERNAL_INTEGRATION.CALENDAR_TYPE_GOOGLE;
            this.createGoogleEvent(
                subject,
                url,
                description,
                new Date(this.meeting.startTime),
                new Date(this.meeting.endTime),
                payload?.repeatOptions,
                attendees
            )
                .then((event) => this.handleGoogleEventCreation(event))
                .catch((err) => {
                    if (err.code == 401) {
                        this.scheduled = true;
                        this.googleApiService.signOut().then(() => {
                            this.googleApiService
                                .signIn()
                                .then(() => {
                                    this.createGoogleEvent(
                                        subject,
                                        url,
                                        description,
                                        new Date(this.meeting.startTime),
                                        new Date(this.meeting.endTime),
                                        payload?.repeatOptions,
                                        attendees
                                    )
                                        .then((event) => this.handleGoogleEventCreation(event))
                                        .catch(console.error);
                                })
                                .catch(console.error);
                        });
                    } else {
                        console.error(err);
                    }
                });
        } else if (isUpdatedMeetingAndStillGoogleCal) {
            // Update the google calendar event
            this.updateGoogleEvent(
                this.meeting.externalCalendarEventId,
                subject,
                url,
                description,
                new Date(this.meeting.startTime),
                new Date(this.meeting.endTime),
                payload?.repeatOptions,
                attendees
            ).then((event: any) => {
                this.handleUpdateGoogleEvent(event);
            });
        }
    }

    handleUpdateGoogleEvent(event) {
        let htmlLink;
        if (event.code === 404) {
            htmlLink =
                'https://calendar.google.com/calendar/u/0/r?pli=1&msg=Could+not+find+the+requested+event.&msgtok=8c0c9622719986994bcfe33cae9e1a5e7e1430ae';
        } else {
            htmlLink = event.htmlLink.replace(
                'https://www.google.com/calendar/event?eid=',
                'https://calendar.google.com/calendar/u/0/r/eventedit/'
            );
        }
        this.scheduled = true;
        window.open(htmlLink, '_blank');
    }
    onHostSelected(item) {
        this.item = item;
        if (this.meeting && this.meeting?.hostInfo?._id != this.item?._id) {
            this.disableSave = false;
            this.form.markAsDirty();
        }
    }
    scheduleMeeting() {
        this.error = null;
        this.form.value.topic = this.form.value.topic || this.defaultMeetingTopic;

        if (!this.validate()) {
            return;
        }
        let cohosts = [];
        this.form.value.cohosts?.forEach((cohost) => {
            cohosts.push(cohost.userId || cohost._id);
        });
        const participants = [...this.form.value.participants.keys(), ...cohosts];
        let { duration, ...payload } = this.utilService.clone(this.form.value);
        let filteredParticipants = [];
        participants.forEach((value) => {
            if (!(value === 'Guest' || value === '' || value === undefined || value === null)) {
                filteredParticipants.push(value);
            }
        });
        payload.participants = filteredParticipants;
        payload.declinedUsers = this.declinedUsers;
        if (this.isMeetingOnBehalf) {
            if (!this.item) {
                this.error = this.translateService.instant('planmeeting_popup.errors.no_host_available');
                return;
            }
            payload.onBehalfOf = this.item?.userId || this.item?._id;
        }
        if (this.conCurrentKey != 'undefined') payload.conCurrentKey = this.conCurrentKey;
        payload.cohosts = cohosts;
        payload.advancedOptions.participantAudio = !this.form.value.advancedOptions.participantAudio;
        payload.advancedOptions.restrictChat = this.form.value.advancedOptions?.restrictChat ? 'ALL' : 'NONE';

        payload.repeatOptions.repeat = payload.repeatOptions.repeat || 'none';

        payload.calendarType =
            this.isGoogleCalendar && !this.meeting ? EXTERNAL_INTEGRATION.CALENDAR_TYPE_GOOGLE : payload.calendarType;

        payload.repeatOptions.endDate = this.setRecurEndDateMinutes(this.form.value.repeatOptions.endDate, duration);

        if (this.meeting && payload?.repeatOptions?.repeat !== 'none' && this.singleEdit) {
            payload.startTime = this.recurStartTime;
            payload.endTime = this.recurEndTime;
            const updatedStartTime = this.setStartTimeToLatestRecurrence();
            payload.updatedStartTime = this.datePipe.transform(updatedStartTime, 'yyyy-MM-ddTHH:mm:ss.SSSZ');
            const updatedEndTime = this.setEndTime(payload.updatedStartTime);
            payload.updatedEndTime = this.datePipe.transform(updatedEndTime, 'yyyy-MM-ddTHH:mm:ss.SSSZ');
            const updatedOn = new Date(this.meeting?.updatedStartTime);
            payload.updatedOn = this.datePipe.transform(updatedOn, 'yyyy-MM-dd');
        } else {
            payload.startTime = this.setStartTimeToLatestRecurrence();
            payload.endTime = this.setEndTime(payload.startTime);
        }

        // Todo single instance edit in 2nd Phase
        if (this.recurringSupportForAPI && this.form?.value?.repeatOptions?.repeat) {
            payload['update'] = this.singleEdit ? 'current' : 'all';
        }
        this.ScheduleOrUpdateMeetingWithPayload(payload);
    }

    ScheduleOrUpdateMeetingWithPayload(payload) {
        this.submitting = true;
        if (this.isMeetingOnBehalf) {
            (!this.meeting
                ? this.meetingService.scheduleOnBehlafMeeting(payload)
                : this.meetingService.updateonBehalfMeeting(
                      this.currentMeetingDetails._id,
                      Object.assign(payload, {
                          hostInfo: this.item,
                          privateIp: this.currentMeetingDetails.privateIp,
                          url: this.currentMeetingDetails.url,
                          vpin: this.currentMeetingDetails.vpin,
                          gatewayIp: this.currentMeetingDetails.gatewayIp,
                          participants: [...payload.participants, this.item._id],
                          declinedUsers: this.meeting?.declinedUsers,
                          cohosts: [...payload.cohosts]
                      })
                  )
            ).subscribe(
                (res: any) => {
                    this.submitting = false;
                    this.handleScheduleMeetingSuccessApiResponse(res, payload);
                },
                (err) => {
                    this.submitting = false;
                    this.handleScheduleMeetingApiErrorResponse(err);
                }
            );
        } else {
            (!this.meeting
                ? this.meetingService.scheduleMeeting(payload)
                : this.meetingService.updateMeeting(
                      this.meeting._id,
                      Object.assign(payload, {
                          hostInfo: this.meeting.hostInfo,
                          privateIp: this.meeting.privateIp,
                          url: this.meeting.url,
                          vpin: this.meeting.vpin,
                          gatewayIp: this.meeting.gatewayIp,
                          participants: [...payload.participants, this.currentUser?._id],
                          declinedUsers: this.meeting?.declinedUsers,
                          cohosts: [...payload.cohosts]
                      })
                  )
            ).subscribe(
                (res: any) => {
                    this.submitting = false;
                    this.handleScheduleMeetingSuccessApiResponse(res, payload);
                },
                (err) => {
                    this.submitting = false;
                    this.handleScheduleMeetingApiErrorResponse(err);
                }
            );
        }
    }

    handleScheduleMeetingSuccessApiResponse(res, payload) {
        if (payload.participants.length > 0) this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_ADD_PARTICIPANTS);
        if (payload.cohosts.length > 0) this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_ADD_COHOST);
        if (res?.meetingDetails?.repeatOptions?.repeat !== 'none') {
            if (res.meetingDetails?.repeatOptions?.repeat === 'daily')
                this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_DAILY_RECURRING_CLICK);
            if (res.meetingDetails?.repeatOptions?.repeat === 'weekly')
                this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_WEEKLY_RECURRING_CLICK);
            if (res.meetingDetails?.repeatOptions?.repeat === 'monthly')
                this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_MONTHLY_RECURRING_CLICK);
        }
        if (this.form.value?.advancedOptions?.colleagueOnly === true)
            this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_PARTICIPANT_MY_ORG_ENABLED);
        if (this.form.value?.advancedOptions?.isClassroomMode === true)
            this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_CLASSROOM_MODE_ENABLED);
        if (this.form.value?.advancedOptions?.isRestrictedMeeting === true)
            this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_RESTRICTED_ENABLED);
        if (this.form.value?.advancedOptions?.joinBeforeHost === true)
            this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_JOIN_BEFORE_HOST_ENABLED);
        if (this.form.value?.advancedOptions?.waitingRoom === true)
            this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_WAITING_ROOM_ENABLED);
        if (this.form.value?.advancedOptions?.loggedInOnly === true)
            this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_DONT_ALLOW_GUEST_USER_ENABLED);
        if (this.form.value?.advancedOptions?.participantHardAudioMute === true)
            this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_FORCE_MUTE_ENTRY_ENABLED);
        if (this.form.value?.advancedOptions?.participantAudio === true)
            this.eventEmitterService.emit({ type: APP_EVENTS.REFRESH_MEETINGS, data: null });
        if (this.form.value?.advancedOptions?.restrictChat === true)
            this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_CHAT_DISABLED);
        let isUpdate = false;
        if (this.meeting) {
            this.toastrService.success(this.translateService.instant('planmeeting_popup.errors.updated_text'));
            isUpdate = true;
            this.meeting = res;
            this.cancel(true);
        } else {
            this.meeting = res.meetingDetails;
            this.scheduled = true;
        }

        this.handleGoogleCalendarWithSignIn(payload);
    }

    handleScheduleMeetingApiErrorResponse(err) {
        if (err && err.error && err.error.errorsArray && err?.error?.errorsArray[0]) {
            this.error = err?.error?.errorsArray[0].message || err?.error?.errors;
        } else {
            this.error =
                err?.error?.errors?.errors ||
                err?.error?.errors[0]?.msg ||
                err?.error?.errorsArray ||
                err?.error?.errors ||
                err?.error?.message;
        }
    }

    handleGoogleCalendarWithSignIn(payload) {
        if (!this.isGoogleCalendar) return;
        if (!this.googleApiService.isSignedIn()) {
            this.googleApiService.signIn();
        }
        this.handleGoogleCalendarMeeting(payload);
    }

    populateTimeArray() {
        for (let hour = 0; hour <= 23; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const formattedTime = this.formatTime(hour, minute);
                this.times.push({ label: formattedTime, value: formattedTime });
            }
        }
    }

    formatTime(hour: number, minute: number): string {
        const formattedHour =
            hour % 12 === 0 ? (hour === 0 ? '12' : '12') : hour % 12 < 10 ? `0${hour % 12}` : `${hour % 12}`;
        const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`;
        const period = hour < 12 ? 'am' : 'pm';
        return `${formattedHour}:${formattedMinute} ${period}`;
    }

    timeToDate(formStartValue) {
        const oldTime = new Date(formStartValue);
        const ohours = oldTime.getHours();
        const ominutes = oldTime.getMinutes();
        return this.formatTime(ohours, ominutes);
    }

    onTimeInputChange(input: string) {
        if (this.startTimeString === this.timeToDate(this.form.value.startTime)) {
            return;
        }

        if (input.trim() === '' || !this.isValidTime(input)) {
            const currentTime = new Date();
            const hours = currentTime.getHours();
            const minutes = currentTime.getMinutes();
            const period = hours < 12 ? 'am' : 'pm';

            const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');

            input = `${formattedHours}:${formattedMinutes} ${period}`;
        } else if (!this.isValidTime(input)) {
            const currentTime = new Date();
            const hours = currentTime.getHours();
            const minutes = currentTime.getMinutes();
            const period = hours < 12 ? 'am' : 'pm';

            const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');

            input = `${formattedHours}:${formattedMinutes} ${period}`;
        }
        this.startTimeString = input;
        this.selectTime({ label: input, value: input });
    }

    isValidTime(input: string): boolean {
        const timePattern12 = /^(0[0-9]|1[0-2]):[0-5][0-9] [ap]m$/;
        const v = timePattern12.test(input);

        return timePattern12.test(input);
    }

    selectTime(time: { label: string; value: string }) {
        this.selectedTimeIndex = this.times.indexOf(time);
        this.startTimeString = time.label;

        const [hourStr, minuteStr, meridiem] = this.startTimeString.split(/[:\s]+/);
        const hour = parseInt(hourStr, 10);
        const minute = parseInt(minuteStr, 10);

        const currentStartTime: Date = this.form.value.startTime;

        const newStartTime: Date = new Date(currentStartTime);

        if (meridiem.toLowerCase() === 'am') {
            newStartTime.setHours(hour === 12 ? 0 : hour);
        } else if (meridiem.toLowerCase() === 'pm') {
            newStartTime.setHours(hour === 12 ? 12 : hour + 12);
        }

        newStartTime.setMinutes(minute);
        this.selectedStartTime = newStartTime;
        this.form.patchValue({
            startTime: newStartTime
        });
        this.startTimeString = this.formatTime(newStartTime.getHours(), newStartTime.getMinutes());
        this.showDropdown = false;
        this.disableSave = false;
        this.form.markAsDirty();
    }

    onTimeOptionHovered(index: number) {
        this.hoveredTimeIndex = index;
    }

    onTimeOptionUnhovered() {
        this.hoveredTimeIndex = null;
    }

    setEndDate(startTime) {
        let { endTime, duration } = this.form.value;
        let { hour, minutes, meridiem, durationInHours, durationInMinutes } = duration;

        const [selectedHour, selectedMinute, selectedMeridiem] = this.selectedStartTime
            .toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            })
            .split(/[:\s]+/);
        this.index = this.index + 1;
        if (this.index > 2) {
            hour = selectedHour;
            minutes = selectedMinute;
            meridiem = selectedMeridiem.toLowerCase();
        }
        this.minserror = null;
        minutes = minutes.padStart(2, '0');
        hour = +hour;
        if (hour === 0) {
            hour = hour + 12;
        }
        if (![startTime, hour, minutes, durationInHours, durationInMinutes].includes(null)) {
            startTime.setHours((meridiem === 'am') !== (hour === 12) ? hour : hour === 12 ? 0 : hour + 12);
            startTime.setMinutes(+minutes);
            endTime = addMinutes(new Date(startTime), +durationInHours * 60 + +durationInMinutes);

            this.form.get('endTime').setValue(endTime);
            let amOrPm =
                meridiem == 'pm'
                    ? this.translateService.instant('planmeeting_popup.pm')
                    : this.translateService.instant('planmeeting_popup.am');
            this.startTimeString = `${this.padPipe.transform(hour)}:${minutes} ${amOrPm}`;

            this.durationString = `${
                durationInHours !== '0'
                    ? this.padPipe.transform(durationInHours) +
                      ' ' +
                      this.translateService.instant('planmeeting_popup.hours') +
                      ' '
                    : ''
            }${this.padPipe.transform(durationInMinutes)} ${this.translateService.instant(
                'planmeeting_popup.minutes'
            )}`;
        }
        if (differenceInMinutes(endTime, startTime) < 5) {
            this.minserror = this.translateService.instant('planmeeting_popup.errors.mins_error');
        }
    }

    setStartTimeToLatestRecurrence() {
        if (this.form.value.repeatOptions?.repeat == 'none' || this.form.value.repeatOptions?.repeat !== 'weekly') {
            return this.form.value.startTime;
        }
        let daysInWeek = this.form.value.repeatOptions?.daysOfWeek;
        let selectedStartDay = getDay(this.form.value.startTime);
        let i;
        for (i = selectedStartDay; i < 7 + selectedStartDay; i++) {
            if (daysInWeek[i % 7]) break;
        }
        return addDays(this.form.value.startTime, (i - selectedStartDay) % 7);
    }

    setEndTime(start_time) {
        return addMinutes(
            new Date(start_time),
            +this.form.value.duration.durationInHours * 60 + +this.form.value.duration.durationInMinutes
        );
    }

    submit() {
        setTimeout(() => {
            const popUpElement: any = document.getElementsByClassName('restricted-focus')[0];
            if (popUpElement) {
                popUpElement?.focus();
            }
        }, 100);
        if (this.meeting) {
            this.gTagService.sendEvent(GA_NEW.DASHBOARD_EDIT_MEETING_SAVE);
        } else {
            this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_POPUP_SUBMIT);
        }
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translateService.instant('tostrs.something_went_right'));
            return;
        }
        this.scheduleMeeting();
        this.gTagService.sendEvent(GA_EVENTS.PLAN_A_MEETING_POPUP_SUBMIT);
    }

    cancel(meetingSubmitted?) {
        this.gTagService.sendEvent(GA_EVENTS.PLAN_A_MEETING_POPUP_CANCEL);
        if (this.meeting && !meetingSubmitted) {
            this.gTagService.sendEvent(GA_NEW.DASHBOARD_EDIT_MEETING_CANCEL);
            this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_CLOSE);
        }
        if (!this.meeting && !meetingSubmitted) {
            this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_POPUP_CANCEL);
        }
        this.scheduled = false;
        this.close.emit();
        this.gTagService.sendEvent(GA_EVENTS.PLAN_A_MEETING_POPUP_CANCEL);
    }

    toggleAdvancedOptions() {
        this.advancedOptionsCollapsed = !this.advancedOptionsCollapsed;
    }

    copyInvitation() {
        this.gTagService.sendEvent(GA_NEW.PLAN_A_MEETING_COPY_INVITATION);
        if (!this.copiedInviteToClipboard) {
            this.utilService.copyToClipboardFromHTMLElement('new-meeting-invitation');
            this.copiedInviteToClipboard = true;
            this.timeoutIds.push(
                setTimeout(() => {
                    this.copiedInviteToClipboard = false;
                }, 2000)
            );
        }
    }

    onClickOfGoogleOption(event) {
        this.isGoogleCalendar = true;
        this.isOtherCalendar = false;
        this.isOutlookCalendar = false;
    }

    onClickOfOutlookOption(event) {
        this.isGoogleCalendar = false;
        this.isOtherCalendar = false;
        this.isOutlookCalendar = true;
    }

    onClickOfOtherOption(event) {
        this.isGoogleCalendar = false;
        this.isOtherCalendar = true;
        this.isOutlookCalendar = false;
    }

    async createGoogleEvent(topic, location, description, startTime, endTime, repeatOptions, attendees = []) {
        const recurrence = this.googleApiService.convertRepeatOptionsToGoogleFormat(repeatOptions);

        const request = this.googleApiService.createEvent(
            topic,
            location,
            description,
            startTime,
            endTime,
            recurrence,
            attendees
        );
        return new Promise((resolve, reject) => {
            request.execute((event) => {
                if (event.code == 401) reject(event);
                else resolve(event);
            });
        });
    }

    async getGoogleEvent(externalCalendarEventId) {
        const isSignedIn = this.googleApiService.isSignedIn();
        if (!isSignedIn) {
            await this.googleApiService.signIn();
        }
        const request = this.googleApiService.getEvent(externalCalendarEventId);
        return new Promise((resolve, reject) => {
            request.execute((event) => {
                resolve(event);
            });
        });
    }

    async updateGoogleEvent(
        externalCalendarEventId,
        topic,
        location,
        description,
        startTime,
        endTime,
        repeatOptions,
        attendees
    ) {
        const isSignedIn = this.googleApiService.isSignedIn();
        if (!isSignedIn) {
            await this.googleApiService.signIn();
        }
        const recurrence = this.googleApiService.convertRepeatOptionsToGoogleFormat(repeatOptions);
        const request = this.googleApiService.updateEvent(
            externalCalendarEventId,
            topic,
            location,
            description,
            startTime,
            endTime,
            recurrence,
            attendees
        );

        return new Promise((resolve, reject) => {
            request.execute((event) => {
                resolve(event);
            });
        });
    }

    async signInPopup() {
        try {
            const result = await this.msalService.loginPopup(this.outlookOAuthSettings);

            if (result) {
                this.isOutlookAuthenticated = true;
            }
        } catch (err) {
            this.isOutlookAuthenticated = false;
            throw err;
        }
    }

    async getAccessToken(): Promise<string> {
        try {
            const result = await this.msalService.acquireTokenSilent({
                scopes: this.outlookOAuthSettings.scopes
            });

            if (result) {
                return result.accessToken;
            }

            // Couldn't get a token
            this.isOutlookAuthenticated = false;
            return '';
        } catch (err) {
            throw err;
        }
    }

    getIsAuthenticated() {
        return this.isOutlookAuthenticated;
    }

    async createOutlookEvent(topic, location, description, startTime, endTime) {
        try {
            const isAuthenticated = this.getIsAuthenticated();
            if (!isAuthenticated) {
                await this.signInPopup();
            }
            const accessToken = await this.getAccessToken();
            const graphClient = Client.init({
                // Initialize the Graph client with an auth
                // provider that requests the token from the
                // auth service
                authProvider: (done) => {
                    if (accessToken) {
                        done(null, accessToken);
                    } else {
                        done('Could not get an access token', null);
                    }
                }
            });
            const event = {
                subject: topic,
                body: {
                    contentType: 'HTML',
                    content: description
                },
                start: {
                    dateTime: new Date(startTime).toISOString(),
                    timeZone: 'Indian Standard Time'
                },
                end: {
                    dateTime: new Date(endTime).toISOString(),
                    timeZone: 'Indian Standard Time'
                },
                location: {
                    displayName: location
                }
            };
            const result = await graphClient.api('/me/events').post(event);

            return result;
        } catch (err) {
            throw err;
        }
    }

    async updateOutlookEvent(id, topic, location, description, startTime, endTime) {
        try {
            const isAuthenticated = this.getIsAuthenticated();
            if (!isAuthenticated) {
                await this.signInPopup();
            }
            const accessToken = await this.getAccessToken();
            const graphClient = Client.init({
                authProvider: (done) => {
                    if (accessToken) {
                        done(null, accessToken);
                    } else {
                        done('Could not get an access token', null);
                    }
                }
            });
            const event = {
                subject: topic,
                body: {
                    contentType: 'HTML',
                    content: description
                },
                start: {
                    dateTime: new Date(startTime).toISOString(),
                    timeZone: 'Indian Standard Time'
                },
                end: {
                    dateTime: new Date(endTime).toISOString(),
                    timeZone: 'Indian Standard Time'
                },
                location: {
                    displayName: location
                }
            };
            const result = await graphClient.api('/me/events/' + id).update(event);

            return result;
        } catch (err) {
            throw err;
        }
    }

    gTagSettingsSelected(event) {
        switch (event?.target?.id) {
            case 'defaultUnchecked1': {
                const isRestrictedMeeting = event.target.checked
                    ? GA_EVENTS.PLAN_A_MEETING_RESTRICTED_ENABLED
                    : GA_EVENTS.PLAN_A_MEETING_RESTRICTED_DISABLED;
                this.gTagService.sendEvent(isRestrictedMeeting);
                break;
            }
            case 'defaultUnchecked2': {
                const guestNotAllowed = event.target.checked
                    ? GA_EVENTS.PLAN_A_MEETING_DONT_ALLOW_GUEST_USER_ENABLED
                    : GA_EVENTS.PLAN_A_MEETING_DONT_ALLOW_GUEST_USER_DISABLED;
                this.gTagService.sendEvent(guestNotAllowed);
                break;
            }
            case 'defaultUnchecked3': {
                const isWaitingRoomEnabled = event.target.checked
                    ? GA_EVENTS.PLAN_A_MEETING_WAITING_ROOM_ENABLED
                    : GA_EVENTS.PLAN_A_MEETING_WAITING_ROOM_DISABLED;
                this.gTagService.sendEvent(isWaitingRoomEnabled);
                break;
            }
            case 'defaultUnchecked4': {
                const isParticipantMyOrgEnabled = event.target.checked
                    ? GA_EVENTS.PLAN_A_MEETING_PARTICIPANT_MY_ORG_ENABLED
                    : GA_EVENTS.PLAN_A_MEETING_PARTICIPANT_MY_ORG_DISABLED;
                this.gTagService.sendEvent(isParticipantMyOrgEnabled);
                break;
            }
            case 'defaultUnchecked5': {
                const isJoinBeforeHostEnabled = event.target.checked
                    ? GA_EVENTS.PLAN_A_MEETING_JOIN_BEFORE_HOST_ENABLED
                    : GA_EVENTS.PLAN_A_MEETING_JOIN_BEFORE_HOST_DISABLED;
                this.gTagService.sendEvent(isJoinBeforeHostEnabled);
                break;
            }
            case 'defaultUnchecked6': {
                const isForceMuteEntryEnabled = event.target.checked
                    ? GA_EVENTS.PLAN_A_MEETING_FORCE_MUTE_ENTRY_ENABLED
                    : GA_EVENTS.PLAN_A_MEETING_FORCE_MUTE_ENTRY_DISABLED;
                this.gTagService.sendEvent(isForceMuteEntryEnabled);
                break;
            }
            case 'defaultUnchecked9': {
                const isSoftMuteEntryEnabled = event.target.checked
                    ? GA_EVENTS.PLAN_A_MEETING_SOFT_MUTE_ENTRY_ENABLED
                    : GA_EVENTS.PLAN_A_MEETING_SOFT_MUTE_ENTRY_DISABLED;
                this.gTagService.sendEvent(isSoftMuteEntryEnabled);
                break;
            }
            case 'defaultUncheckedA': {
                const isClassroomEnabled = event.target.checked
                    ? GA_EVENTS.PLAN_A_MEETING_CLASSROOM_MODE_ENABLED
                    : GA_EVENTS.PLAN_A_MEETING_CLASSROOM_MODE_DISABLED;
                this.gTagService.sendEvent(isClassroomEnabled);
                break;
            }
            case 'defaultUnchecked10': {
                const isLargeParticipants = event.target.checked
                    ? GA_EVENTS.PLAN_A_MEETING_LARGE_MEETING_ENABLED
                    : GA_EVENTS.PLAN_A_MEETING_LARGE_MEETING_DISABLED;
                this.gTagService.sendEvent(isLargeParticipants);
                break;
            }
        }
    }

    onAdvancedMeetingOptionsEnterClicked(event) {
        event.preventDefault();
        // Toggle the checkbox manually
        const checkbox = event.target as HTMLInputElement;
        checkbox.checked = !checkbox.checked;

        switch (event?.target?.id) {
            case 'defaultUnchecked12': {
                const translationModeEnabled = checkbox.checked
                    ? GA_EVENTS.PLAN_A_MEETING_RESTRICTED_ENABLED
                    : GA_EVENTS.PLAN_A_MEETING_RESTRICTED_DISABLED;
                this.gTagService.sendEvent(translationModeEnabled);
                this.form.get('advancedOptions').patchValue({
                    isTranslationEnabled: checkbox.checked
                });
                break;
            }
            case 'defaultUnchecked1': {
                const isRestrictedMeeting = checkbox.checked
                    ? GA_EVENTS.PLAN_A_MEETING_RESTRICTED_ENABLED
                    : GA_EVENTS.PLAN_A_MEETING_RESTRICTED_DISABLED;
                this.gTagService.sendEvent(isRestrictedMeeting);
                this.form.get('advancedOptions').patchValue({
                    isRestrictedMeeting: checkbox.checked
                });
                break;
            }
            case 'defaultUnchecked2': {
                const guestNotAllowed = checkbox.checked
                    ? GA_EVENTS.PLAN_A_MEETING_DONT_ALLOW_GUEST_USER_ENABLED
                    : GA_EVENTS.PLAN_A_MEETING_DONT_ALLOW_GUEST_USER_DISABLED;
                this.gTagService.sendEvent(guestNotAllowed);
                this.form.get('advancedOptions').patchValue({
                    loggedInOnly: checkbox.checked
                });
                break;
            }
            case 'defaultUnchecked3': {
                const isWaitingRoomEnabled = checkbox.checked
                    ? GA_EVENTS.PLAN_A_MEETING_WAITING_ROOM_ENABLED
                    : GA_EVENTS.PLAN_A_MEETING_WAITING_ROOM_DISABLED;
                this.gTagService.sendEvent(isWaitingRoomEnabled);
                this.form.get('advancedOptions').patchValue({
                    waitingRoom: checkbox.checked
                });
                break;
            }
            case 'defaultUnchecked4': {
                const isParticipantMyOrgEnabled = checkbox.checked
                    ? GA_EVENTS.PLAN_A_MEETING_PARTICIPANT_MY_ORG_ENABLED
                    : GA_EVENTS.PLAN_A_MEETING_PARTICIPANT_MY_ORG_DISABLED;
                this.gTagService.sendEvent(isParticipantMyOrgEnabled);
                this.form.get('advancedOptions').patchValue({
                    colleagueOnly: checkbox.checked
                });
                break;
            }
            case 'defaultUnchecked5': {
                const isJoinBeforeHostEnabled = checkbox.checked
                    ? GA_EVENTS.PLAN_A_MEETING_JOIN_BEFORE_HOST_ENABLED
                    : GA_EVENTS.PLAN_A_MEETING_JOIN_BEFORE_HOST_DISABLED;
                this.gTagService.sendEvent(isJoinBeforeHostEnabled);
                this.form.get('advancedOptions').patchValue({
                    joinBeforeHost: checkbox.checked
                });
                break;
            }
            case 'defaultUnchecked6': {
                const isForceMuteEntryEnabled = checkbox.checked
                    ? GA_EVENTS.PLAN_A_MEETING_FORCE_MUTE_ENTRY_ENABLED
                    : GA_EVENTS.PLAN_A_MEETING_FORCE_MUTE_ENTRY_DISABLED;
                this.gTagService.sendEvent(isForceMuteEntryEnabled);
                this.form.get('advancedOptions').patchValue({
                    participantHardAudioMute: checkbox.checked
                });
                break;
            }
            case 'defaultUnchecked9': {
                const isSoftMuteEntryEnabled = checkbox.checked
                    ? GA_EVENTS.PLAN_A_MEETING_SOFT_MUTE_ENTRY_ENABLED
                    : GA_EVENTS.PLAN_A_MEETING_SOFT_MUTE_ENTRY_DISABLED;
                this.gTagService.sendEvent(isSoftMuteEntryEnabled);
                this.form.get('advancedOptions').patchValue({
                    participantAudio: checkbox.checked
                });
                break;
            }
            case 'defaultUncheckedA': {
                const isClassroomEnabled = checkbox.checked
                    ? GA_EVENTS.PLAN_A_MEETING_CLASSROOM_MODE_ENABLED
                    : GA_EVENTS.PLAN_A_MEETING_CLASSROOM_MODE_DISABLED;
                this.gTagService.sendEvent(isClassroomEnabled);
                this.form.get('advancedOptions').patchValue({
                    isClassroomMode: checkbox.checked
                });
                break;
            }
            case 'defaultUnchecked11': {
                this.form.get('advancedOptions').patchValue({
                    restrictChat: checkbox.checked
                });
                break;
            }
        }
    }

    enableSoftMuteAndGtagSettingsSelection(evt) {
        this.form.get('advancedOptions').patchValue({ participantAudio: evt.target.checked });
        this.gTagSettingsSelected(evt);
        let softMuteEvent = { target: { id: 'defaultUnchecked9', checked: evt.target.checked } };
        this.gTagSettingsSelected(softMuteEvent);
    }

    activateTabAccessibilityForCalender(classes) {
        classes.forEach((elementClass) => {
            const element = document.getElementsByClassName(elementClass);
            if (element.length > 0) {
                if (elementClass === 'p-datepicker-calendar') {
                    const tbody = element[element.length - 1].getElementsByTagName('tbody');
                    const rows = tbody[0].getElementsByTagName('tr');
                    for (let i = 0; i < rows.length; i++) {
                        const cells = rows[i].getElementsByTagName('td');
                        for (let j = 0; j < cells.length; j++) {
                            cells[j].setAttribute('tabindex', '0');
                        }
                    }
                } else {
                    element[element.length - 1].setAttribute('tabindex', '0');
                }
            }
        });

        const element: any = document.getElementsByClassName(classes[0]);
        if (element && element.length > 0) {
            element[element.length - 1]?.focus();
        }
    }

    onCalendarOpen(event) {
        setTimeout(() => {
            this.activateTabAccessibilityForCalender([
                'p-datepicker-prev',
                'p-datepicker-title',
                'p-datepicker-next',
                'p-datepicker-calendar'
            ]);
        }, 100);
    }
    onCalendarKeyDown(event: KeyboardEvent) {
        if (event.shiftKey && event.key === 'Tab') {
            return;
        }
        if (event.key === 'Tab') {
            setTimeout(() => {
                if (this.startTimeCalendar.overlayVisible) {
                    this.startTimeCalendar.toggle();
                }
                this.setFocusOnElement('timestartTimeDropdown');
            }, 100);
        } else if (event.key === 'Enter') {
            this.startTimeCalendar.toggle();
        }
    }

    onStartTimeKeyDown(event: KeyboardEvent) {
        if (event.shiftKey && event.key === 'Tab') {
            setTimeout(() => {
                if (this.startTimeCalendar.overlayVisible) {
                    this.startTimeCalendar.toggle();
                }
                this.setFocusOnElement('date-input');
            }, 100);
        }
    }

    onDropdownKeyDown(event: KeyboardEvent) {
        if ((event.shiftKey && event.key === 'Tab') || event.key === 'ArrowUp') {
            event.preventDefault();
            this.focusPreviousOption();
        } else if (event.key === 'Tab' || event.key === 'ArrowDown') {
            event.preventDefault();
            this.focusNextOption();
        } else if (event.key === 'Enter') {
            this.selectFocusedOption();
        }
    }

    changeFocusToDropdown() {
        const ele = document.getElementById('timeInputDropdownInput');
        setTimeout(() => {
            ele.click();
        });
        setTimeout(() => {
            const firstChild = document.getElementById('timeOption0');
            firstChild.focus();
        }, 100);
    }

    focusPreviousOption() {
        this.focusedIndex--;

        if (this.focusedIndex < 0) {
            this.setFocusOnElement('timestartTimeDropdown');
        }

        const optionId = `timeOption${this.focusedIndex}`;
        const optionElement = document.getElementById(optionId);

        if (optionElement) {
            const nextOptionId = `timeOption${this.focusedIndex + 1}`;
            const nextOptionElement = document.getElementById(nextOptionId);

            if (nextOptionElement) {
                nextOptionElement.classList.remove('focused-time');
            }

            optionElement.classList.add('focused-time');
            optionElement.focus();
            optionElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    }

    focusNextOption() {
        this.focusedIndex++;

        if (this.focusedIndex >= this.times.length) {
            this.setFocusOnElement('startTimeDropdown');
        }

        const prevOptionId = `timeOption${this.focusedIndex - 1}`;
        const prevOptionElement = document.getElementById(prevOptionId);

        if (prevOptionElement) {
            prevOptionElement.classList.remove('focused-time');
        }

        const optionId = `timeOption${this.focusedIndex}`;
        const optionElement = document.getElementById(optionId);

        if (optionElement) {
            optionElement.classList.add('focused-time');
            optionElement.focus();
            optionElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    }

    selectFocusedOption() {
        if (this.times[this.focusedIndex]) {
            this.selectTime(this.times[this.focusedIndex]);
        }
    }

    setFocusOnElement(focusElement) {
        setTimeout(() => {
            const element: any = document.getElementsByClassName(focusElement)[0];
            if (element) {
                element?.focus();
            }
        }, 100);
    }

    ngOnDestroy() {
        if (this.isThirdPartyExternalIntegration) {
            this.thirdPartyEventSubscriptions.forEach((subscription) => subscription.unsubscribe());
        }
        this.routerPopstateSub.unsubscribe();
        this.timeoutIds.forEach((id) => clearTimeout(id));
    }
    onBehalfHostClicked() {
        this.isMeetingForSelf = false;
        this.isMeetingOnBehalf = true;
    }
    mySelfClicked() {
        this.enableSaveButton();
        this.isMeetingForSelf = true;
        this.isMeetingOnBehalf = false;
        this.item = null;
    }
    enableSaveButton() {
        if (this.meeting && !this.isMeetingForSelf) {
            this.disableSave = false;
            this.form.markAsDirty();
        }
    }
}
