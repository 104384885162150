<div class="accordion-content">
    <div *ngFor="let menuGroup of accordionData; let i = index" class="accordion" [ngClass]="menuPos">
        <h3
            *ngIf="menuGroup?.label"
            (click)="toggleAccordion($event, i)"
            [class.active]="activePanel === i"
            [class.caret]="menuGroup.data.length"
        >
            <img [src]="menuGroup.icon" alt="" *ngIf="menuGroup.icon" />{{ menuGroup?.tlabel | translate }}
        </h3>
        <div class="panel" id="accordion-{{ i }}" [class.inactive]="activePanel !== i" *ngIf="menuGroup?.data.length">
            <ng-container *ngFor="let menuInfo of menuGroup?.data">
                <div class="sub-title" (click)="handleGoTo(menuInfo)" [class.not-active]="!menuInfo.path">
                    <span *ngIf="menuPos === 'header'" class="img"><img [src]="menuInfo.icon" alt="" /></span>
                    {{ menuInfo?.ttitle | translate }}
                    <span
                        class="btn btn-primary"
                        [class.coming-soon]="menuInfo.type == 2"
                        [class.new]="menuInfo.type == 1"
                        *ngIf="menuPos === 'header' && menuInfo.ttag"
                        >{{ menuInfo.ttag | translate }}</span
                    >
                </div>
            </ng-container>
        </div>
    </div>
</div>
