import { Component, OnInit, EventEmitter, Output } from '@angular/core';

import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AppService, AuthService } from 'src/app/core';
import { GaDiagnosticService } from 'src/app/core/services/ga-diagnostic.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    email;
    hash;

    changePasswordForm: FormGroup;
    errors: any = {};
    toggleUI = true;
    showPassword = false;
    txParams = {
        characters: { value: 8 },
        alphabets: { value: 1 },
        numbers: { value: 1 }
    };
    validPassword = false;
    @Output() close: EventEmitter<any> = new EventEmitter();
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder,
        private authService: AuthService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private appService: AppService
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.email = params.email;
            this.hash = params.hash;
        });
        this.changePasswordForm = this.fb.group({
            oldPassword: [null, [Validators.required]],
            password: [null, [Validators.required]],
            confirmPassword: [null, [Validators.required]]
        });
        this.changePasswordForm.get('password').valueChanges.subscribe(() => {
            this.validPassword = this.validatePassword(this.changePasswordForm.get('password').value);
        });
    }

    cancel() {
        this.close.emit();
    }

    validate() {
        this.errors = {};
        const password = (this.changePasswordForm.get('password').value || '').trim();
        const confirmPassword = (this.changePasswordForm.get('confirmPassword').value || '').trim();
        if (password !== confirmPassword) {
            this.errors.global = this.translateService.instant('tostrs.passwords_do_not_match_please_reenter');
            return false;
        }
        return true;
    }

    submit() {
        if (!this.appService.isOnLine) {
            this.toastrService.info(this.translateService.instant('tostrs.something_went_right'));
            return;
        }
        // this.errors = {};
        if (!this.changePasswordForm.valid || !this.validPassword) {
            return;
        }
        if (!this.validate()) {
            return;
        }

        this.authService
            .changePassword({
                oldPassword: this.changePasswordForm.value.oldPassword,
                newPassword: this.changePasswordForm.value.password
            })
            .subscribe(
                () => {
                    this.toastrService.success(this.translateService.instant('tostrs.passwords_changed'));
                    this.authService.cleanup();
                    this.router.navigate(['/login']);
                },
                (err) => {
                    if ([400, 412].includes(err?.error?.customCode)) {
                        if (err?.error?.errorsArray?.length) {
                            this.errors.global = err.error.errorsArray[0]?.message;
                        } else {
                            this.errors.global = err.error?.errors;
                        }
                    }
                    this.errors.global =
                        this.errors.global ||
                        'Change Password Failed! Please make sure you have entered correct details.';
                }
            );
    }

    validatePassword(password) {
        this.errors.password = '';
        const lengthRegex = new RegExp('(?=.{8,})');
        this.errors.password += lengthRegex.exec(password) ? '' : 'lengthError';

        const numberRegex = new RegExp('(?=.*?[0-9])');

        this.errors.password += numberRegex.exec(password) ? '' : 'numberError';

        const alphabetRegex = new RegExp('(?=.*[a-zA-z])');
        this.errors.password += alphabetRegex.exec(password) ? '' : 'alphabetError';

        const dualRegex = new RegExp('(?=.*?[A-Z])(?=.*?[a-z])');
        this.errors.password += dualRegex.exec(password) ? '' : 'dualError';

        const specialCharsRegex = new RegExp('[!@#$%^&*]');
        this.errors.password += specialCharsRegex.exec(password) ? '' : 'specialCharsError';

        // for non repeating case

        const unique = new Set(password.split(''));
        this.errors.password += unique.size === 1 ? 'repeatingError' : '';

        // for consecutive case
        const alphabet = 'abcdefghijklmnopqrstuvwxyz';
        const number = '0123456789';
        this.errors.password += alphabet.includes(password) || number.includes(password) ? 'consecutiveError' : '';
        return !!this.errors.password ? false : true;
    }
    togglePassword() {
        this.showPassword = !this.showPassword;
    }
}
