import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';

import { AuthService } from './../services/auth.service';
import { AppService } from '../services';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthGuardChat implements CanActivate {
    constructor(private router: Router, private authService: AuthService, private appService: AppService) {}
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        if (this.authService.getIsAuthenticated() && this.appService.getConfigVariable('ENABLE_WEBSITE_CHAT')) {
            return of(true);
        } else {
            this.router.navigateByUrl('/home');
            return of(false);
        }
    }
}
