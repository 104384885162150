<div *ngFor="let featureData of featureDataInput">
    <span class="row">
        <span
            class="col feature-heading"
            tabindex="0"
            [ngClass]="{ 'upper-curve': featureData.tfeature === 'Participant' }"
            *ngIf="isMobile"
        >
            {{ featureData.tfeature | translate }}
            <span *ngIf="featureData?.feature_has_flag" class="highlight m-left-8" tabindex="0">{{
                featureData?.tfeature_has_flag | translate
            }}</span>
        </span>
        <span
            class="col feature-heading"
            tabindex="0"
            [ngClass]="{
                'upper-curve': featureData.tfeature === 'website_enterprise.participants',
                'lower-curve': featureData.tfeature === 'website_enterprise.customised_features'
            }"
            *ngIf="!isMobile"
        >
            {{ featureData.tfeature | translate }}
            <span *ngIf="featureData?.feature_has_flag" class="highlight" tabindex="0">{{
                featureData?.tfeature_has_flag | translate
            }}</span>
        </span>

        <span
            [ngClass]="{ 'lower-curve': featureData.tfeature === 'website_enterprise.customised_features' }"
            class="col"
        >
            <span
                role="text"
                class="feature-standard-plan"
                tabindex="0"
                *ngIf="featureData.individual !== 0 && featureData.individual !== 1"
            >
                {{ featureData.tindividual | translate }}
            </span>
            <span class="feature-standard-plan" *ngIf="featureData.individual === 0"
                ><img
                    src="assets/img/enterprise/not_available.svg"
                    alt=""
                    tabindex="0"
                    role="img"
                    aria-label="Not Active sign image"
            /></span>
            <span class="feature-standard-plan" *ngIf="featureData.individual === 1"
                ><img
                    src="assets/img/enterprise/available.svg"
                    alt=""
                    tabindex="0"
                    role="img"
                    aria-label="Active sign image"
            /></span>
        </span>

        <span
            [ngClass]="{ 'lower-curve': featureData.tfeature === 'website_enterprise.customised_features' }"
            class="col feature-pro-plan"
        >
            <span tabindex="0" role="text" *ngIf="featureData.pro !== 0 && featureData.pro !== 1">{{
                featureData.tpro | translate
            }}</span>
            <span *ngIf="featureData.pro === 0"
                ><img
                    src="assets/img/enterprise/not_available.svg"
                    alt=""
                    tabindex="0"
                    role="img"
                    aria-label="Not Active sign image"
            /></span>
            <span *ngIf="featureData.pro === 1"
                ><img
                    src="assets/img/enterprise/available.svg"
                    alt=""
                    tabindex="0"
                    role="img"
                    aria-label="Active sign image"
                />
                <span *ngIf="featureData?.pro_has_flag" class="highlight m-left-8" tabindex="0">{{
                    featureData?.tpro_has_flag | translate
                }}</span>
            </span>
        </span>
        <span
            [ngClass]="{ 'lower-curve': featureData.tfeature === 'website_enterprise.customised_features' }"
            class="col"
        >
            <span tabindex="0" role="text" *ngIf="featureData.custom !== 0 && featureData.custom !== 1">{{
                featureData.tcustom | translate
            }}</span>
            <span *ngIf="featureData.custom === 0">
                <img
                    src="assets/img/enterprise/not_available.svg"
                    alt=""
                    tabindex="0"
                    role="img"
                    aria-label="Not Active sign image"
                />
            </span>
            <span *ngIf="featureData.custom === 1"
                ><img
                    src="assets/img/enterprise/available.svg"
                    alt=""
                    tabindex="0"
                    role="img"
                    aria-label="Active sign image"
                />
                <span *ngIf="featureData?.custom_has_flag" class="highlight m-left-8">{{
                    featureData?.tcustom_has_flag | translate
                }}</span>
            </span>
        </span>
    </span>
</div>
