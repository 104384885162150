import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    HostListener
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import Quill from 'quill';
import { ThirdPartyExternalIntegrationService, UtilService, AppService, UserService } from 'src/app/core';

declare var $: any;
declare const document: any;

@Component({
    selector: 'app-chat-markdown',
    templateUrl: './chat-markdown.component.html',
    styleUrls: ['./chat-markdown.component.scss']
})
export class ChatMarkdownComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('content') content: ElementRef;
    @Input() selectedEmoji;
    @Input() showMarkdown;
    @Input() disableSendButton;
    @Output() sendmessage = new EventEmitter();
    @Output() deletedImage = new EventEmitter();
    @Output() disablingSendButton = new EventEmitter<{ status: boolean }>();
    @Output() heightChange: EventEmitter<{ functionName: string; imageHeight: number }> = new EventEmitter<{
        functionName: string;
        imageHeight: number;
    }>();
    @Output() pastedImageFile = new EventEmitter<{ file: File; imageWrapper: HTMLElement }>();

    @Output() maxTableSizeError: EventEmitter<boolean> = new EventEmitter<boolean>();

    public quill: any;
    isThirdPartyExternalIntegration;
    isMobileDevice;
    translatorSub: Subscription;
    shouldEmitHeightChange: boolean = true;
    imagesCount: number = 0;
    isTextEnteredFirst: boolean = false;
    isImagePastedFirst: boolean = false;
    imagesUploadedFeature: boolean = false;
    isImageSelected: boolean = false;
    enteredText: string = '';
    overlay: any;
    maxTableSize: number = 21;
    isTablevalid: boolean;
    constructor(
        private translate: TranslateService,
        private thirdPartyExternalIntegrationService: ThirdPartyExternalIntegrationService,
        private utilService: UtilService,
        private appService: AppService,
        private toastrService: ToastrService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        this.isThirdPartyExternalIntegration =
            this.thirdPartyExternalIntegrationService.getThirdPartyExternalIntegration();
        this.isMobileDevice = this.utilService.isMobileBrowser();
        setTimeout(() => {
            this.initializeQuillEditor();
        }, 200);

        this.translatorSub = this.translate.onLangChange.subscribe(() => {
            this.content.nativeElement.childNodes[0].setAttribute(
                'data-placeholder',
                this.translate.instant('inside_call.chat.type_here')
            );
        });
        this.imagesUploadedFeature =
            this.appService.getConfigVariable('PASTE_CLIPBOARD_IMAGE') && this.userService.isJioCloudEnabled();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.showMarkdown) {
            const nodes = document.querySelectorAll('.ql-toolbar');
            nodes.forEach((toolBar) => {
                if (toolBar) {
                    toolBar.style.display = this.showMarkdown ? 'block' : 'none';
                }
            });
        }
    }

    initializeQuillEditor() {
        var options = {
            modules: {
                toolbar: [
                    ['bold', 'italic', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }]
                ],
                table: true
            },
            placeholder: this.translate.instant('inside_call.chat.type_here'),
            theme: 'snow',
            formats: ['bold', 'header', 'italic', 'strike', 'link', 'list', 'blockquote', 'indent', 'table']
        };
        this.quill = new Quill(this.content.nativeElement, options);

        this.quill.root.addEventListener('paste', (event) => {
            this.handlePasteEvent(event);
        });

        this.quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
            this.disableSendButton = true;
            setTimeout(() => {
                // this.disableSendButton = true;
            }, 0);

            return delta;
        });
        this.quill.keyboard.bindings[13].unshift({
            key: 13,
            handler: (range, context) => {
                if (!this.showMarkdown && !this.disableSendButton) {
                    this.sendmessage.next(true);
                    return false;
                }
                return true;
            }
        });
    }

    onDrop(event) {
        event.preventDefault();
        return false;
    }

    insertEmoji(event) {
        this.quill.focus();
        const caretPosition = this.quill.getSelection(true);
        this.quill.insertText(caretPosition, event.char);
    }

    clearMeessage() {
        this.quill.setContents([]);
        this.quill.setText('');
    }

    handlePasteEvent(event: ClipboardEvent) {
        const clipboardData = event.clipboardData;
        const pastedData = clipboardData.getData('text/plain');
        const isPastedDataTable = pastedData.includes('\t');
        if (isPastedDataTable) {
            const isPastedDataTableValid = this.validateTableData(pastedData);
            if (isPastedDataTableValid) {
                const pastedDataSize = pastedData.split('\n').length;
                if (pastedDataSize > this.maxTableSize) {
                    this.maxTableSizeError.emit(true);
                    this.toastrService.error(this.translate.instant('chat.table_size_limit_exceeded'));

                    event.preventDefault();
                } else {
                    this.maxTableSizeError.emit(false);
                }
            }
        }
    }
    validateTableData(data: string): boolean {
        const nestedTableRegex = /<table>.*<table>.*<\/table>.*<\/table>/;
        if (nestedTableRegex.test(data)) {
            this.isTablevalid = false;
            this.toastrService.error(this.translate.instant('chat.invalid_tabluar_data'));
            this.maxTableSizeError.emit(true);
            return false;
        }
        return true;
    }

    ngOnDestroy() {
        this.translatorSub.unsubscribe();
    }

    handleKeyPress(event: KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            this.sendMessageAndMoveFocus(event);
        }
    }

    sendMessageAndMoveFocus(event: KeyboardEvent) {
        // Move focus to the next element
        const nextElement = document.getElementById('emoji-popup');
        if (nextElement) {
            (nextElement as HTMLElement).focus();
            nextElement.focus();
        }
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        const image = document.getElementsByClassName('image-wrapper')[0] as HTMLElement;
        if (image && this.isImageSelected) {
            this.deleteImage(image);
            this.isImageSelected = false;
            this.isImagePastedFirst = false;
        }

        if (event.key.length === 1 && !this.isImagePastedFirst && event.key !== 'v' && event.key !== 'V') {
            this.isTextEnteredFirst = true;
            this.disablingSendButton.emit({ status: false });
            if (this.overlay) this.overlay.style.display = 'none';
        }
        if (event.ctrlKey && event.key === 'a') {
            const selectedText = this.quill.getText();
            if (selectedText.trim().length === 0) {
                this.clearMeessage();
                return;
            }
        }
        if (event.key === 'Enter' && !event.shiftKey) {
            this.sendmessage.next();
            return;
        }

        if (event.key === 'Backspace') {
            if (this.utilService.isTableData(this.quill.root.innerHTML) && this.quill.getText().trim() === '') {
                this.clearMeessage();
                return;
            }
            const caretPosition = this.quill.getSelection(true);
            if (caretPosition && caretPosition.index > 0) {
                const [item] = this.quill.getLeaf(caretPosition.index - 1);
            }
            if (caretPosition && caretPosition.index === 0) {
                const image = document.getElementsByClassName('image-wrapper')[0] as HTMLElement;
                if (image) {
                    image.focus();
                    image.style.filter = 'contrast(50%)';
                    if (image) this.isImageSelected = true;
                }
            }
        }
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        if (!this.imagesUploadedFeature) {
            return;
        }
        const items = Array.from(event.clipboardData?.items || []);
        this.processItems(items);
        if (!this.isTextEnteredFirst) {
            this.isImagePastedFirst = true;
        }
    }

    async processItems(items) {
        for (const item of items) {
            if (item.type.indexOf('image') !== -1) {
                const blob = item.getAsFile();
                if (!blob) {
                    return;
                }
                this.handleImagePasted(blob);
            } else if (item.type === 'text/plain') {
                const textPromise = new Promise<string>((resolve) => {
                    item.getAsString((text: string) => {
                        resolve(text);
                    });
                });
                this.enteredText = await textPromise;
                this.disablingSendButton.emit({ status: false });
                this.overlay.style.display = 'none';
                setTimeout(() => {
                    if (this.isBase64(this.enteredText)) {
                        const imageType = this.enteredText.includes('data:image/jpeg;base64')
                            ? 'jpeg'
                            : this.enteredText.includes('data:image/png;base64')
                            ? 'png'
                            : this.enteredText.includes('data:image/gif;base64')
                            ? 'gif'
                            : this.enteredText.includes('data:image/bmp;base64')
                            ? 'bmp'
                            : this.enteredText.includes('data:image/bmp;base64')
                            ? 'tiff'
                            : '';
                        const myBlob = this.base64ToBlob(this.enteredText, imageType);
                        this.handleImagePasted(myBlob, imageType);
                    } else {
                        this.handlePlainTextPasted(this.enteredText);
                    }
                }, 500);
            }
        }
    }

    private handleImagePasted(blob: Blob, imageType?: string) {
        var file;
        if (imageType) {
            const timestamp = new Date().getTime();
            const fileName = `${timestamp}.${imageType}`;
            file = new File([blob], fileName, { lastModified: timestamp });
        } else {
            file = blob;
        }
        const reader = new FileReader();
        reader.onload = (e: any) => {
            const imageWrapper = this.createImageWrapper(e.target.result);
            this.pastedImageFile.emit({ file, imageWrapper });
        };
        reader.readAsDataURL(blob);
        this.disablingSendButton.emit({ status: true });
    }

    private handlePlainTextPasted(text: string) {
        const lines = this.formatTextIntoLines(text);
        const fragment = this.createTextFragment(lines);
        this.insertTextFragment(fragment);
    }

    private createImageWrapper(src: string): HTMLDivElement {
        const imageWrapper = document.createElement('div');
        imageWrapper.classList.add('image-wrapper');
        const imageElement = new Image();
        imageElement.src = src;
        this.overlay = document.createElement('div');
        // this.overlay.classList.add('image-upload-overlay');
        // this.overlay.textContent = this.translate.instant('tostrs.upload_in_progress');
        imageElement.addEventListener('load', () => {
            this.adjustImageDimensions(imageElement);
            const deleteButton = document.createElement('span');
            deleteButton.classList.add('delete-button');
            const deleteIcon = new Image();
            deleteIcon.src = 'assets/images/delete_icon.png';
            deleteIcon.alt = 'Delete';
            deleteIcon.addEventListener('click', () => {
                this.deleteImage(imageWrapper);
            });

            deleteButton.appendChild(deleteIcon);
            imageWrapper.appendChild(imageElement);
            imageWrapper.appendChild(deleteButton);
            imageWrapper.appendChild(this.overlay);
            this.overlay.style.display = 'block';
            document.getElementsByClassName('ql-editor')[0].setAttribute('data-placeholder', '');
            this.isContentEmpty()
                ? this.content.nativeElement.insertBefore(imageWrapper, this.content.nativeElement.firstChild)
                : this.content.nativeElement.appendChild(imageWrapper);
        });
        imageElement.focus();
        return imageWrapper;
    }
    private createTextFragment(lines: string[]): DocumentFragment {
        const fragment = document.createDocumentFragment();
        for (const line of lines) {
            fragment.appendChild(document.createTextNode(line));
            fragment.appendChild(document.createElement('br'));
        }
        return fragment;
    }

    private insertTextFragment(fragment: DocumentFragment) {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            range.deleteContents();
            range.insertNode(fragment);
        }
    }

    formatTextIntoLines(text: string): string[] {
        const lines = [];
        const lineLength = 75;
        while (text.length > lineLength) {
            lines.push(text.substring(0, lineLength));
            text = text.substring(lineLength);
        }
        if (text.length > 0) {
            lines.push(text);
        }
        return lines;
    }

    isContentEmpty(): boolean {
        const contentElement = this.content.nativeElement;
        const nonEmptyChildNodes = Array.from(contentElement.childNodes).filter((node: Node) => {
            return (
                node.nodeType === Node.ELEMENT_NODE ||
                (node.nodeType === Node.TEXT_NODE && node.textContent.trim().length > 0)
            );
        });
        const containsText = nonEmptyChildNodes.some((node: Node) => {
            const element = node as Element;
            return element.className === 'ql-editor';
        });
        return nonEmptyChildNodes.length === 3 && !containsText;
    }

    deleteImage(imageWrapper: HTMLElement): void {
        imageWrapper.remove();
        if (this.isContentEmpty()) {
            document
                .getElementsByClassName('ql-editor')[0]
                .setAttribute('data-placeholder', this.translate.instant('inside_call.chat.type_here'));
        }
        this.shouldEmitHeightChange = true;
        this.imagesCount -= 1;
        if (this.imagesCount === 0) {
            this.disableSendButton = true;
        }
        if (this.quill?.container?.innerText?.trim() === '' && this.imagesCount === 0 && !this.isContentEmpty) {
            this.disableSendButton = true;
        }
        this.deletedImage.next();
    }

    adjustImageDimensions(imageElement: HTMLImageElement): void {
        const maxWidth = 400;
        const maxHeight = 300;
        let newWidth = imageElement.width;
        let newHeight = imageElement.height;
        let modified = false;
        if (newWidth > maxWidth) {
            newHeight *= maxWidth / newWidth;
            newWidth = maxWidth;
            modified = true;
        }
        if (newHeight > maxHeight) {
            newWidth *= maxHeight / newHeight;
            newHeight = maxHeight;
            modified = true;
        }
        if (!modified) {
            newWidth *= 200 / newHeight;
            newHeight = 200;
        }
        imageElement.style.width = newWidth + 'px';
        imageElement.style.height = newHeight + 'px';
        imageElement.style.paddingLeft = '12px';
        if (this.shouldEmitHeightChange) {
            this.heightChange.emit({ functionName: 'adjustImageDimensions', imageHeight: newHeight });
            this.shouldEmitHeightChange = false;
        }
        this.imagesCount += 1;
    }
    isBase64(str: string): boolean {
        return (
            str.includes('data:image/jpeg;base64') ||
            str.includes('data:image/png;base64') ||
            str.includes('data:image/gif;base64') ||
            str.includes('data:image/bmp;base64')
        );
    }

    base64ToBlob(base64: string, contentType: string = ''): Blob | null {
        try {
            const prefixToRemove = `<img src="data:image/${contentType};base64,`;
            base64 = base64.substring(prefixToRemove.length);
            base64 = base64.split(' ')[0];
            base64 = base64.slice(0, -1);
            const arrayBuffer = new Uint8Array(
                atob(base64)
                    .split('')
                    .map((char) => char.charCodeAt(0))
            );
            return new Blob([arrayBuffer]);
        } catch (error) {
            console.error('Error converting base64 to Blob:', error);
            return null;
        }
    }
}
