<div class="website-meta-component">
    <div class="container">
        <div class="layout">
            <div>
                <div class="row sec-top">
                    <div class="col-md-7 col-sm-12 header">
                        <div class="page-header">
                            <h1>Website Meta Data</h1>
                        </div>
                        <div class="header-info">
                            <p>Here, You can update website meta data.</p>
                        </div>
                    </div>
                </div>
                <div class="screen-tabs">
                    <ul class="nav nav-tabs">
                        <li (click)="activeTab = 1" [class.active]="activeTab === 1">
                            <a href="#1" data-toggle="tab">Home</a>
                        </li>
                        <li [class.active]="activeTab === 2" (click)="activeTab = 2">
                            <a href="#2" data-toggle="tab">Cpaas</a>
                        </li>
                    </ul>

                    <div class="tab-content">
                        <div class="tab-pane" id="1" *ngIf="activeTab === 1" [class.active]="activeTab === 1">
                            <form class="custom-form" [formGroup]="activeMetaForm">
                                <section class="section-props">
                                    <div class="header">
                                        <h3>Parners Update</h3>
                                        <img
                                            src="assets/img/delete_active_icn.svg"
                                            (click)="onAddFormGroup('partners')"
                                        />
                                    </div>
                                    <div class="props-container">
                                        <div
                                            class="row"
                                            [formGroup]="partner"
                                            *ngFor="let partner of partners.controls; let i = index"
                                        >
                                            <img
                                                src="assets/img/delete_active_icn.svg"
                                                (click)="onRemoveFormGroup('partners', i)"
                                            />
                                            <div class="col-md-6 col-sm-12 section">
                                                <div class="form-group">
                                                    <label for="inputCompany">Parnter Name <em>*</em></label>
                                                    <input
                                                        type="text"
                                                        class="form-control input"
                                                        id="name"
                                                        formControlName="name"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-12 section">
                                                <div class="form-group">
                                                    <label for="formFile" class="form-label"
                                                        >Parnter Logo<em>*</em></label
                                                    >
                                                    <input
                                                        type="file"
                                                        class="form-control input"
                                                        id="logo"
                                                        formControlName="logo"
                                                        (change)="onFileChange($event, i)"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="section-props">
                                    <h3>JioMeet MileStones</h3>
                                    <div class="row" [formGroup]="f.milestones">
                                        <div class="col-md-4 col-sm-12 section">
                                            <div class="form-group">
                                                <label for="inputCompany">Downloads <em>*</em></label>
                                                <input
                                                    type="number"
                                                    class="form-control input"
                                                    id="downloads"
                                                    formControlName="downloads"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-12 section">
                                            <div class="form-group">
                                                <label for="inputCompany">meetingMinutes <em>*</em></label>
                                                <input
                                                    type="number"
                                                    class="form-control input"
                                                    id="meetingMinutes"
                                                    formControlName="meetingMinutes"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-12 section">
                                            <div class="form-group">
                                                <label for="inputCompany">webinarMinutes <em>*</em></label>
                                                <input
                                                    type="number"
                                                    class="form-control input"
                                                    id="webinarMinutes"
                                                    formControlName="webinarMinutes"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="section-props">
                                    <div class="header">
                                        <h3>Store Comments Update</h3>
                                        <img
                                            src="assets/img/delete_active_icn.svg"
                                            (click)="onAddFormGroup('storeComments')"
                                        />
                                    </div>
                                    <div class="props-container">
                                        <div
                                            class="row"
                                            *ngFor="let storeComment of storeComments.controls; let i = index"
                                            [formGroup]="storeComment"
                                        >
                                            <img src="assets/img/delete_active_icn.svg" />
                                            <div class="col-md-6 col-sm-12 section">
                                                <div class="form-group">
                                                    <label for="inputCompany">Comment<em>*</em></label>
                                                    <input
                                                        type="text"
                                                        class="form-control input"
                                                        id="comment"
                                                        formControlName="comment"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-12 section">
                                                <div class="form-group">
                                                    <label for="formFile" class="form-label">Name<em>*</em></label>
                                                    <input
                                                        type="text"
                                                        class="form-control input"
                                                        id="name"
                                                        formControlName="name"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-12 section">
                                                <div class="form-group">
                                                    <label for="formFile" class="form-label">Rating<em>*</em></label>
                                                    <p-dropdown
                                                        [options]="[
                                                            { value: 1, name: 1 },
                                                            { value: 2, name: 2 },
                                                            { value: 3, name: 3 },
                                                            { value: 4, name: 4 },
                                                            { value: 5, name: 5 }
                                                        ]"
                                                        formControlName="rating"
                                                        optionLabel="value"
                                                    >
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-12 section">
                                                <div class="form-group">
                                                    <label for="formFile" class="form-label">Date<em>*</em></label>
                                                    <!-- <input
                          type="text"
                          class="form-control input"
                          id="date"
                          formControlName="date"
                        /> -->
                                                    <div class="date-input">
                                                        <p-calendar
                                                            formControlName="dateSource"
                                                            dateFormat="dd/mm/yy"
                                                            [maxDate]="calenderEndDate"
                                                            [showOtherMonths]="false"
                                                            [showIcon]="true"
                                                            icon="pi pi-angle-down"
                                                            [appendTo]="'body'"
                                                            [readonlyInput]="true"
                                                            (onSelect)="selectDate($event, i)"
                                                        >
                                                        </p-calendar>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div class="d-flex justify-content-end submit-btn">
                                        <button
                                            type="submit"
                                            class="custom-button bg-primary mr-2 bg-gradient schedule-btn"
                                            [class.disabled]="!activeMetaForm.valid"
                                            (click)="onSubmit()"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </section>
                            </form>
                        </div>
                        <div class="tab-pane" id="2" *ngIf="activeTab === 2" [class.active]="activeTab === 2">
                            <h3>Notice the gap between the content and tab after applying a background color</h3>
                        </div>
                    </div>
                </div>

                <!-- <form class="custom-form" [formGroup]="contactSalesForm">
          <div class="row">
            <div class="col-md-6 col-sm-12 section">
              <div class="form-group">
                <label for="inputCompany">Company or Organisation Name <em>*</em></label>
                <input type="text" class="form-control input" id="name" formControlName="name" />
              </div>
              <div class="form-group">
                <label for="inputSize">Company or Organisation Size<em>*</em></label>
                <p-dropdown
                  [options]="companySizes"
                  panelStyleClass="minWidthOverride"
                  formControlName="companySize"
                  optionLabel="tvalue"
                ></p-dropdown>
              </div>

              <div class="form-group">
                <label for="inputSector">Sector <em>*</em></label>
                <p-dropdown [options]="sectors" formControlName="sector" optionLabel="tvalue">
                </p-dropdown>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 section">
              <div class="form-group">
                <label for="inputName">Your Name <em>*</em></label>
                <input
                  type="text"
                  class="form-control input"
                  id="inputName"
                  formControlName="name"
                  (keypress)="checkCharLimit(200, $event)"
                />
              </div>
              <div class="form-group">
                <label for="inputNumber">Contact Number<em>*</em></label>
                <input
                  type="text"
                  class="form-control input"
                  id="inputNumber"
                  formControlName="phone"
                  (keypress)="checkNumber($event)"
                />
              </div>
              <div class="form-group">
                <label for="inputEmail">Email ID<em>*</em></label>
                <input
                  type="email"
                  class="form-control input"
                  id="inputEmail"
                  formControlName="email"
                />
              </div>
              <div class="policy">
                By submitting this form you confirm that you have read and agree with our
                <u (click)="navigateToTnC()">Privacy Policy</u>
              </div>
              <div
                class="err"
                *ngIf="errors.name || errors.company || errors.phone || errors.email"
              >
                {{ errors.name || errors.company || errors.phone || errors.email }}
              </div>
              <div class="err" *ngIf="error">{{ error }}</div>
              <div class="d-flex justify-content-end submit-btn">
                <button
                  type="submit"
                  class="custom-button bg-primary mr-2 bg-gradient schedule-btn"
                  [class.disabled]="!contactSalesForm.valid"
                  (click)="register()"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form> -->
            </div>
            <div *ngIf="toggleView">
                <div class="show">
                    <div class="box">
                        <div class="row">
                            <div class="col-md-7">
                                <div class="head">Thank you for your interest!</div>
                                <div class="content">Our sales team will get in touch with you in 3 days.</div>
                            </div>
                            <div class="col-md-5">
                                <div class="contact-info">
                                    <img class="img-responsive" src="assets/img/contacts.svg" alt="JioMeet Contact" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hide">
                    <div class="row sec">
                        <div class="col-sm-12">
                            <div class="title">Thank you for your interest!</div>
                            <div class="body">Our sales team will get in touch with you in 3 days.</div>
                        </div>
                        <div class="col-sm-12">
                            <div class="bg-box">
                                <div>
                                    <img class="contact-img" src="assets/img/contacts.svg" alt="JioMeet Contact" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
