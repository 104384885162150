import { Injectable } from '@angular/core';
import { isAfter } from 'date-fns';
import { addSeconds, differenceInSeconds, isBefore } from 'date-fns/esm';
import { map } from 'rxjs/operators';
import * as uuidv4 from 'src/assets/lib/uuid/uuid-v4.js';
import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';
import { RestService } from './rest.service';
import { UtilService } from './util.service';

declare const DeviceUUID: any;

@Injectable({
    providedIn: 'root'
})
export class SystemService {
    private deviceUUID;
    private sessionID;
    private clientDeviceType;
    constructor(
        private authService: AuthService,
        private localStorageService: LocalStorageService,
        private sessionStorageService: SessionStorageService,
        private restService: RestService,
        private utilService: UtilService
    ) {}

    getDeviceUUID() {
        if (this.authService.getIsAuthenticated()) {
            if (this.sessionStorageService.getItem('clientDeviceId')) {
                this.deviceUUID = this.sessionStorageService.getItem('clientDeviceId');
                return this.deviceUUID;
            } else {
                if (!this.sessionStorageService.getItem('userDeviceId')) {
                    this.deviceUUID = uuidv4();
                    this.sessionStorageService.addItem('userDeviceId', this.deviceUUID);
                }
                return this.sessionStorageService.getItem('userDeviceId') || this.deviceUUID;
            }
        } else {
            if (!this.deviceUUID) {
                this.deviceUUID = uuidv4();
            }
            return this.deviceUUID;
        }
    }

    getClientDeviceType() {
        if (this.localStorageService.getItem('clientDeviceType')) {
            this.clientDeviceType = this.localStorageService.getItem('clientDeviceType');
            return this.clientDeviceType;
        } else {
            this.clientDeviceType = this.utilService.fetchPlatformType();
            return this.clientDeviceType;
        }
    }

    getSessionID() {
        if (!this.sessionID) {
            if (this.localStorageService.getItem('clientDeviceId')) {
                this.sessionID = this.localStorageService.getItem('clientDeviceId');
            } else {
                this.sessionID = uuidv4();
            }
        }
        return this.sessionID;
    }

    getUniqueBrowserID() {
        const du = this.getSysytemInfo();
        const dua = [du.browser, du.os, du.version];
        return du.hashMD5(dua.join(':'));

        // return new DeviceUUID().get();
    }

    getSysytemInfo() {
        return new DeviceUUID().parse();
    }

    isSafariBrowser() {
        const { browser, version } = this.getSysytemInfo();
        return browser === 'Safari';
    }

    isSyncWithServerTime(offset) {
        return this.restService.get(`api/sync_time?t=${Date.now()}`).pipe(
            map((res: any) => {
                const now = new Date();
                const serverTime = new Date(res?.[0]);
                if (Math.abs(differenceInSeconds(serverTime, now)) > (offset || 30)) {
                    return false;
                }

                return true;
            })
        );
    }
}
