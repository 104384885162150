export const APP_CONSTANT = {
    CHAT_MESSAGE_LIMIT: 1200,
    ENTER_CHAR_KEY: 13,
    AVAILABLE: 'online',
    AWAY: 'away',
    BUSY: 'busy',
    OFFLINE: 'offline',
    FAQ: 'FAQ',
    PRIVACY: 'PrivacyPolicy',
    TERMSANDCONDITION: 'TermsandCondition'
};

export const USER_STATUS = [
    {
        title: 'landing_or_home_page.header.available',
        status: APP_CONSTANT.AVAILABLE,
        styleClass: 'available-dropdown'
    },
    {
        title: 'landing_or_home_page.header.away',
        status: APP_CONSTANT.AWAY,
        styleClass: 'away-dropdown'
    },
    {
        title: 'landing_or_home_page.header.busy',
        status: APP_CONSTANT.BUSY,
        styleClass: 'busy-dropdown'
    }
];

export const HELP_MENU = [
    {
        title: 'landing_or_home_page.header.faq',
        event: APP_CONSTANT.FAQ
    },
    {
        title: 'landing_or_home_page.header.privacy_policy',
        event: APP_CONSTANT.PRIVACY
    },
    {
        title: 'landing_or_home_page.header.terms_condition',
        event: APP_CONSTANT.TERMSANDCONDITION
    }
];

export const KEYBOARD_CONSTANT = {
    KEYBOARD_SHORTCUTS: 'KEYBOARD_SHORTCUTS',
    OPEN_JOIN_MEETING: 'OPEN_JOIN_MEETING',
    OPEN_START_MEETING: 'OPEN_START_MEETING',
    JOIN_MEETING: 'JOIN_MEETING',
    OPEN_SCHEDULE_MEETING: 'OPEN_SCHEDULE_MEETING',
    MIC_TOGGLE: 'MIC_TOGGLE',
    CAMERA_TOGGLE: 'CAMERA_TOGGLE',
    END_ALL_MEETING: 'END_ALL_MEETING',
    LEAVE_MEETING: 'LEAVE_MEETING',
    SHARE_SCREEN_TOGGLE: 'SHARE_SCREEN_TOGGLE',
    RECORDING_TOGGLE: 'RECORDING_TOGGLE',
    OPEN_PARTICPANT_PANEL: 'OPEN_PARTICPANT_PANEL',
    OPEN_CHAT_PANEL: 'OPEN_CHAT_PANEL',
    HAND_RAISE_TOGGLE: 'HAND_RAISE_TOGGLE',
    MINIMAL_WINDOW: 'MINIMAL_WINDOW',
    OPEN_INIVTE_POPUP: 'OPEN_INIVTE_POPUP',
    COPY_INIVTE: 'COPY_INIVTE',
    FULL_SCREEN: 'FULL_SCREEN',
    FORCE_MUTE: 'FORCE_MUTE',
    UNDO_FORCE_MUTE: 'UNDO_FORCE_MUTE',
    LAYOUT_CHANGE: 'LAYOUT_CHANGE',
    LAYOUT_NEXT_PAGE: 'LAYOUT_NEXT_PAGE',
    LAYOUT_PREV_PAGE: 'LAYOUT_PREV_PAGE',

    INCREASE_CHAT_SIZE: 'INCREASE_CHAT_SIZE',
    DECREASE_CHAT_SIZE: 'DECREASE_CHAT_SIZE',
    BACKWARD_CHAT_HISTORY: 'BACKWARD_CHAT_HISTORY',
    FORWARD_CHAT_HISTORY: 'FORWARD_CHAT_HISTORY',
    NEW_CHAT: 'NEW_CHAT',
    SEARCH_CHAT: 'SEARCH_CHAT',
    ADD_MEMBER_TO_GROUP: 'ADD_MEMBER_TO_GROUP',
    GOTO_FIRST_CHAT: 'GOTO_FIRST_CHAT',
    FOCUS_CHAT: 'FOCUS_CHAT',
    CALL_ACCEPT: 'CALL_ACCEPT',
    CALL_DECLINE: 'CALL_DECLINE',
    CLOSE_WINDOW: 'CLOSE_WINDOW',

    //macOS Shortcuts
    COMMAND_J: 'meta+j',
    COMMAND_SHIFT_J: 'meta+shift+j',
    COMMAND_CTRL_V: 'meta+ctrl+v',
    COMMAND_E: 'meta+e',
    COMMAND_L: 'meta+l',
    COMMAND_D: 'meta+d',
    COMMAND_SHIFT_A: 'meta+shift+a',
    COMMAND_CTRL_M: 'meta+ctrl+m',
    COMMAND_CTRL_U: 'meta+ctrl+u',
    COMMAND_SHIFT_V: 'meta+shift+v',
    COMMAND_SHIFT_N: 'meta+shift+n',
    COMMAND_SHIFT_S: 'meta+shift+s',
    COMMAND_SHIFT_T: 'meta+shift+t',
    COMMAND_SHIFT_C: 'meta+shift+c',
    COMMAND_SHIFT_P: 'meta+shift+p',
    COMMAND_SHIFT_W: 'meta+alt+v', // NEED KEY CHANGE
    COMMAND_P: 'meta+p',
    COMMAND_N: 'meta+alt+n', // NEED KEY CHANGE
    COMMAND_U: 'meta+u',
    COMMAND_SHIFT_H: 'meta+shift+h',
    COMMAND_I: 'meta+i',
    COMMAND_SHIFT_I: 'meta+shift+i',
    OPTION_Y: 'alt+y',
    COMMAND_CTRL_F: 'meta+ctrl+f',
    COMMAND_2: 'meta+2',
    COMMAND_PLUS_SIGN: 'meta++',
    COMMAND_MINUS_SIGN: 'meta+-',
    COMMAND_LEFTBRACKET: 'meta+[',
    COMMAND_RIGHTBRACKET: 'meta+]',
    COMMAND_O: 'meta+o',
    COMMAND_F: 'meta+f',
    COMMAND_OPTION_I: 'meta+alt+i',
    COMMAND_CTRL_N: 'meta+ctrl+n',
    COMMAND_SHIFT_U: 'meta+shift+u',
    COMMAND_A: 'meta+a',
    COMMAND_R: 'meta+r',
    COMMAND_OPTION_C: 'meta+alt+c',

    //Others Shortcuts
    CTRL_J: 'ctrl+j',
    CTRL_SHIFT_J: 'ctrl+shift+j',
    CTRL_ALT_V: 'ctrl+alt+v',
    CTRL_E: 'ctrl+e',
    CTRL_L: 'ctrl+l',
    CTRL_D: 'ctrl+d',
    CTRL_SHIFT_A: 'ctrl+shift+a',
    CTRL_ALT_M: 'ctrl+alt+m',
    CTRL_ALT_U: 'ctrl+alt+u',
    CTRL_SHIFT_V: 'ctrl+shift+v',
    CTRL_SHIFT_N: 'ctrl+shift+n',
    CTRL_SHIFT_S: 'ctrl+shift+s',
    CTRL_SHIFT_T: 'ctrl+shift+t',
    CTRL_SHIFT_C: 'ctrl+shift+c',
    CTRL_SHIFT_P: 'ctrl+shift+p',
    CTRL_ALT_G: 'ctrl+alt+g',
    CTRL_P: 'ctrl+p',
    CTRL_N: 'ctrl+alt+n', // NEED KEY CHANGE
    CTRL_U: 'ctrl+u',
    CTRL_SHIFT_H: 'ctrl+shift+h',
    CTRL_I: 'ctrl+i',
    CTRL_SHIFT_I: 'ctrl+shift+i',
    WINDOWS_Y: 'meta+y',
    CTRL_ALT_F: 'ctrl+shift+f',
    CTRL_2: 'ctrl+2',
    CTRL_PLUS_SIGN: 'ctrl++',
    CTRL_MINUS_SIGN: 'ctrl+-',
    CTRL_LEFTBRACKET: 'ctrl+[',
    CTRL_RIGHTBRACKET: 'ctrl+]',
    CTRL_O: 'ctrl+o',
    CTRL_F: 'ctrl+alt+f',
    CTRL_OPTION_I: 'ctrl+alt+i',
    CTRL_ALT_N: 'ctrl+alt+n',
    CTRL_SHIFT_U: 'ctrl+shift+u',
    CTRL_ALT_A: 'ctrl+alt+a',
    CTRL_ALT_D: 'ctrl+alt+d',
    CTRL_ALT_C: 'ctrl+alt+c',
    ALT_SPACE_N: 'alt+space+n'
};
