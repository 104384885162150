<div class="contact-sales-component">
    <div class="container">
        <div class="layout">
            <div *ngIf="!toggleView" class="form-container">
                <div class="top-container">
                    <div class="row sec-top">
                        <div class="col-md-7 col-sm-12 header contact_sales_page">
                            <div class="page-header contactsalesheading" tabindex="0">
                                <h1>{{ 'contact-sales.contact_sales' | translate }}</h1>
                            </div>
                            <div class="header-info" tabindex="0">
                                <p>
                                    {{ 'contact-sales.content' | translate }}
                                </p>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="contact-image">
                                <img
                                    class="img-responsive"
                                    src="assets/img/contact-sales.svg"
                                    alt="JioMeet Solution Overview"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <form class="custom-form" [formGroup]="contactSalesForm">
                    <div class="row">
                        <div class="col-md-6 col-sm-12 section">
                            <div class="form-group">
                                <label for="inputName">{{ 'contact-sales.your_name' | translate }}<em>*</em></label>
                                <input
                                    type="text"
                                    class="form-control input"
                                    id="inputName"
                                    formControlName="name"
                                    (keypress)="checkCharLimit(200, $event)"
                                    [class.err]="errors.name"
                                    autocomplete="disabled"
                                />
                                <div class="error" *ngIf="errors.name">
                                    {{ errors.name }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="inputNumber"
                                    >{{ 'contact-sales.contact_number' | translate }}<em>*</em></label
                                >
                                <div class="phone-number-container">
                                    <div class="phone-prefix">
                                        <input type="text" value="+91" readonly [class.err]="errors.phone" />
                                    </div>
                                    <div class="phone-number">
                                        <input
                                            type="text"
                                            id="inputNumber"
                                            formControlName="phone"
                                            (input)="checkNumber($event)"
                                            maxlength="10"
                                            [class.err]="errors.phone"
                                        />
                                    </div>
                                </div>
                                <div class="error" *ngIf="errors.phone">
                                    {{ errors.phone }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="inputEmail">{{ 'contact-sales.email' | translate }}<em>*</em></label>
                                <input
                                    type="email"
                                    class="form-control"
                                    id="inputEmail"
                                    formControlName="email"
                                    [class.err]="errors.email"
                                    autocomplete="disabled"
                                />
                                <div class="error" *ngIf="errors.email">
                                    {{ errors.email }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 section col-gap">
                            <div class="form-group">
                                <label for="inputCompany"
                                    >{{ 'contact-sales.company_or_organisation' | translate
                                    }}{{ 'contact-sales.name' | translate }}<em>*</em></label
                                >
                                <input
                                    type="text"
                                    class="form-control input"
                                    id="inputCompany"
                                    formControlName="company"
                                    (keypress)="checkCharLimit(1000, $event)"
                                    [class.err]="errors.company"
                                    autocomplete="disabled"
                                />
                                <div class="error" *ngIf="errors.company">
                                    {{ errors.company }}
                                </div>
                            </div>
                            <div
                                class="form-group"
                                aria-label="Please click tab again and press up and down arrows to explore dropdown names one by one"
                            >
                                <label id="labelInputSize" for="inputSize"
                                    >{{ 'contact-sales.company_or_organisation' | translate }}
                                    {{ 'contact-sales.size' | translate }}<em>*</em></label
                                >
                                <p-dropdown
                                    [options]="companySizes"
                                    panelStyleClass="minWidthOverride"
                                    formControlName="companySize"
                                    optionLabel="tvalue"
                                    autocomplete="disabled"
                                    tabindex="0"
                                    aria-labelledby="labelInputSize"
                                ></p-dropdown>
                            </div>

                            <div
                                class="form-group"
                                aria-label="Please click tab again and press up and down arrows to explore dropdown names one by one"
                            >
                                <label id="labelInputSector" for="inputSector"
                                    >{{ 'contact-sales.sector' | translate }}<em>*</em></label
                                >
                                <br />
                                <p-dropdown
                                    [options]="sectors"
                                    formControlName="sector"
                                    optionLabel="tvalue"
                                    autocomplete="disabled"
                                    tabindex="0"
                                    aria-labelledby="labelInputSector"
                                >
                                </p-dropdown>
                            </div>
                        </div>
                        <fieldset>
                            <div class="col-md-12 col-sm-12 section">
                                <div class="req-info" tabindex="0" role="text" aria-label="Products Interested *">
                                    <legend>
                                        {{ 'contact-sales.products_interested' | translate }}<em>*</em>
                                        <img
                                            class="info-icon"
                                            src="assets/img/info-icon.svg"
                                            alt=""
                                            pTooltip="{{ 'contact-sales.tooltip' | translate }}"
                                            tooltipPosition="{{ isMobile ? 'right' : 'bottom' }}"
                                            tooltipStyleClass="primary-bg"
                                            tabindex="0"
                                            aria-label="Jiomeet Products interested info icon"
                                            tooltipEvent="{{ isMobile ? 'focus' : 'hover' }}"
                                            aria-label="Please select the JioMeet product(s) in which you are interested. Based on your interest, we will enable the access for your login"
                                            style="outline: 0"
                                        />
                                    </legend>
                                </div>
                                <div class="checkbox-group">
                                    <div class="row">
                                        <div
                                            class="col-md-6 col-sm-12 checks"
                                            *ngFor="let product of interestedProducts"
                                        >
                                            <div
                                                class="custom-checkbox"
                                                tabindex="0"
                                                (keydown.enter)="onKeyDown($event, product.value)"
                                            >
                                                <input
                                                    class=""
                                                    type="checkbox"
                                                    [value]="product.value"
                                                    (change)="onCheckboxChange($event)"
                                                    [id]="product.value"
                                                    autocomplete="off"
                                                />
                                                <label class="" [for]="product.value">
                                                    <span>{{ 'contact-sales.' + product.tname | translate }}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <div class="col-md-12 col-sm-12 section" *ngIf="ShowWhiteLabel">
                            <div class="req-info" tabindex="0">
                                {{ 'contact-sales.white_label' | translate }}<em>*</em>
                                <img
                                    class="info-icon"
                                    src="assets/img/info-icon.svg"
                                    alt="JioMeet Solution Overview"
                                    pTooltip="{{ 'contact-sales.tooltip_wl' | translate }}"
                                    tooltipPosition="{{ isMobile ? 'right' : 'bottom' }}"
                                    tooltipStyleClass="primary-bg"
                                    tabindex="0"
                                    tooltipEvent="{{ isMobile ? 'focus' : 'hover' }}"
                                    style="outline: 0"
                                />
                            </div>
                            <div class="checkbox-group">
                                <div class="row white_label">
                                    <div class="col-md-6 col-sm-12 checks" *ngFor="let i of whiteLabel">
                                        <div class="custom-checkbox" tabindex="0">
                                            <input
                                                tabindex="0"
                                                class=""
                                                type="radio"
                                                [value]="i.value"
                                                (change)="onCheckWLboxChange($event)"
                                                [id]="i.value"
                                                formControlName="isWhiteLabelSolution"
                                                autocomplete="off"
                                            />
                                            <label class="" [for]="i.value">
                                                <span>{{ 'contact-sales.' + i.tname | translate }}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-md-12 col-sm-12 section yesSection"
                            *ngIf="ShowWhiteLabel && wlYes && showField"
                        >
                            <div class="form-group">
                                <label for="inputName">{{ 'contact-sales.app_name' | translate }}<em>*</em></label>
                                <input
                                    type="text"
                                    class="form-control input"
                                    id="inputName"
                                    formControlName="appName"
                                    (keypress)="checkCharLimit(200, $event)"
                                    [class.err]="errors.name"
                                    autocomplete="disabled"
                                />
                                <div class="error" *ngIf="errors.name">
                                    {{ errors.name }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="inputName"
                                    >{{ 'contact-sales.whitelisting_url' | translate }}<em>*</em></label
                                >
                                <input
                                    type="text"
                                    class="form-control input"
                                    id="inputName"
                                    formControlName="whiteListingUrl"
                                    (keypress)="checkCharLimit(200, $event)"
                                    [class.err]="errors.name"
                                    autocomplete="disabled"
                                />
                                <div class="error" *ngIf="errors.name">
                                    {{ errors.name }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="inputName"
                                    >{{ 'contact-sales.whitelisting_ip_address' | translate }}<em>*</em></label
                                >
                                <input
                                    type="text"
                                    class="form-control input"
                                    id="inputName"
                                    formControlName="whiteListingIp"
                                    (keypress)="checkCharLimit(200, $event)"
                                    [class.err]="errors.name"
                                    autocomplete="disabled"
                                />
                                <div class="error" *ngIf="errors.name">
                                    {{ errors.name }}
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-md-12 col-sm-12 section noSection"
                            *ngIf="ShowWhiteLabel && !wlYes && showField"
                        >
                            <div class="form-group" tabindex="0">
                                <label for="inputName">{{ 'contact-sales.app_name' | translate }}<em>*</em></label>
                                <input
                                    type="text"
                                    class="form-control input"
                                    id="inputName"
                                    formControlName="appName"
                                    (keypress)="checkCharLimit(200, $event)"
                                    [class.err]="errors.name"
                                    autocomplete="disabled"
                                />
                                <div class="error" *ngIf="errors.name">
                                    {{ errors.name }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="inputName">{{ 'contact-sales.logo_url' | translate }}<em>*</em></label>
                                <input
                                    type="text"
                                    class="form-control input"
                                    id="inputName"
                                    formControlName="logoUrl"
                                    (keypress)="checkCharLimit(200, $event)"
                                    [class.err]="errors.name"
                                    autocomplete="disabled"
                                />
                                <div class="error" *ngIf="errors.name">
                                    {{ errors.name }}
                                </div>
                            </div>
                            <div class="form-group" tabindex="0">
                                <label for="inputName">{{ 'contact-sales.policy_url' | translate }}<em>*</em></label>
                                <input
                                    type="text"
                                    class="form-control input"
                                    id="inputName"
                                    formControlName="policyUrl"
                                    (keypress)="checkCharLimit(200, $event)"
                                    [class.err]="errors.name"
                                    autocomplete="disabled"
                                />
                                <div class="error" *ngIf="errors.name">
                                    {{ errors.name }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="inputName">{{ 'contact-sales.redirect_url' | translate }}<em>*</em></label>
                                <input
                                    type="text"
                                    class="form-control input"
                                    id="inputName"
                                    formControlName="redirectUrl"
                                    (keypress)="checkCharLimit(200, $event)"
                                    [class.err]="errors.name"
                                    autocomplete="disabled"
                                />
                                <div class="error" *ngIf="errors.name">
                                    {{ errors.name }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 section">
                            <div class="d-flex mt-3 captcha-area justify-content-around flex-column">
                                <div class="form-group">
                                    <label for="captchaText"
                                        >{{ 'customer-feedback.security_check' | translate }}<em>*</em></label
                                    >
                                    <div
                                        class="captcha-container"
                                        [class.form-control]="!isMobile"
                                        [class.err]="errors.captchaText"
                                    >
                                        <input
                                            type="text"
                                            id="captchaText"
                                            placeholder="{{ 'customer-feedback.enter_displayed_text' | translate }}"
                                            formControlName="captchaText"
                                            [class.form-control]="isMobile"
                                            maxlength="6"
                                        />
                                        <div class="captcha-box d-flex" [class.form-control]="isMobile">
                                            <img
                                                [src]="captchaDetails?.captcha"
                                                class="captcha-img"
                                                tabindex="0"
                                                alt=""
                                                draggable="false"
                                            />
                                            <div class="reload-btn-section pointer">
                                                <img
                                                    tabindex="0"
                                                    aria-label="refresh button"
                                                    src="assets/img/reload_icn.svg"
                                                    alt=""
                                                    (click)="refreshCaptcha()"
                                                    (keydown.enter)="refreshCaptcha()"
                                                    draggable="false"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div tabindex="0" id="invalidCaptcha" class="error" *ngIf="errors.captchaText">
                                        {{ errors.captchaText }}
                                    </div>
                                </div>
                            </div>
                            <div
                                class="policy"
                                tabindex="0"
                                role="note"
                                type="text"
                                aria-label="By submitting this form you confirm that you have read and agree with our privacy policy"
                            >
                                {{ 'contact-sales.text' | translate }}

                                <u
                                    tabindex="0"
                                    role="link"
                                    aria-label="Privacy Policy"
                                    (keydown.enter)="navigateToPrivacyPolicy()"
                                    (click)="navigateToPrivacyPolicy()"
                                >
                                    {{ 'contact-sales.privacy_policy' | translate }}</u
                                >
                                {{ 'contact-sales.text-1' | translate }}
                            </div>

                            <div class="error mb-3" *ngIf="error">{{ error }}</div>
                            <div class="d-flex justify-content-end submit-btn">
                                <button
                                    type="submit"
                                    class="custom-button custom-action-btn bg-primary mr-2 bg-gradient schedule-btn"
                                    [class.disabled]="!contactSalesForm.valid || submitted"
                                    (click)="verifyCaptcha()"
                                    [tabindex]="!contactSalesForm.valid || submitted ? -1 : 0"
                                >
                                    {{ 'contact-sales.submit' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="toggleView" class="thanks-container">
                <div class="show">
                    <div class="box">
                        <div class="row">
                            <div class="col-md-7">
                                <div class="head">{{ 'contact-sales.thank_you' | translate }}</div>
                                <div class="content">{{ 'contact-sales.sales_team' | translate }}</div>
                            </div>
                            <div class="col-md-5">
                                <div class="contact-info">
                                    <img class="img-responsive" src="assets/img/contacts.svg" alt="JioMeet Contact" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hide">
                    <div class="row sec">
                        <div class="col-sm-12">
                            <div class="title">{{ 'contact-sales.thank_you' | translate }}</div>
                            <div class="body">{{ 'contact-sales.sales_team' | translate }}</div>
                        </div>
                        <div class="col-sm-12">
                            <div class="bg-box">
                                <div>
                                    <img class="contact-img" src="assets/img/contacts.svg" alt="JioMeet Contact" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
