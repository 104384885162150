<div class="container">
    <div class="row">
        <div class="col-mod-6 offset-md-3">
            <div class="loader-overlay" *ngIf="isSuccess">
                <div class="loader">
                    <img alt="" src="assets/img/website/website_logo_header.svg" />
                    <div class="section-1">
                        <div class="left-section">
                            <div class="left-section-1">{{ 'payment-page.plus' | translate }}</div>
                            <div class="left-section-2">
                                ₹{{ totalAmount }} {{ 'payment-page.amount_for_year' | translate }}
                            </div>
                            <div class="left-section-3">
                                {{ 'payment-page.valid_till' | translate }} {{ expiredDate }}
                            </div>
                        </div>
                        <img src="assets/images/green_tick.svg" class="img" />
                    </div>
                    <div class="section-2">{{ 'payment-page.payment_successful' | translate }}</div>
                    <div class="section-3">{{ 'payment-page.transaction_processed' | translate }}</div>
                    <div class="section-4">{{ currentDate }}</div>
                    <div class="section-3">{{ 'payment-page.details' | translate }}</div>
                    <hr />
                    <div class="section-1">
                        <div class="details-left-section">
                            <div class="details-left-content">{{ 'payment-page.account' | translate }}</div>
                            <div class="details-left-content">{{ 'payment-page.reference_number' | translate }}</div>
                            <div class="details-transaction-id">{{ 'payment-page.transaction_id' | translate }}</div>
                            <div class="details-left-content">{{ 'payment-page.payment_mode' | translate }}</div>
                        </div>
                        <div class="details-right-section">
                            <div class="details-right-content">{{ userName }}</div>
                            <div class="details-right-content">{{ referenceNumber }}</div>
                            <div class="details-right-content">{{ transactionId }}</div>
                            <div class="details-right-paymentmode">{{ paymentMode }}</div>
                        </div>
                    </div>
                    <div class="section-5-success">{{ 'payment-page.note' | translate }}</div>
                    <div class="section-6">
                        {{ 'payment-page.counter' | translate }} {{ counter }}{{ 'payment-page.seconds' | translate }}
                    </div>
                    <div class="section-7">
                        <button class="success-button" (click)="redirectToHome()">
                            {{ 'payment-page.go_to_my_account' | translate }}
                            <i class="fa fa-1px fa-chevron-right my-account-icon"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="loader-overlay" *ngIf="isFailure">
                <div class="loader">
                    <img alt="" src="assets/img/website/website_logo_header.svg" />
                    <div class="section-1">
                        <div class="left-section">
                            <div class="failure-left-section-1">{{ 'payment-page.plus' | translate }}</div>
                            <div class="left-section-2">{{ 'payment-page.payment_failed' | translate }}</div>
                        </div>
                        <img src="assets/images/red_cross.svg" />
                    </div>
                    <div class="section-3">{{ 'payment-page.transaction_failed' | translate }}</div>
                    <div class="section-4">{{ currentDate }}</div>
                    <div class="button-container">
                        <button class="failure-button" (click)="redirectToChargeIt()">
                            {{ 'payment-page.retry' | translate }}
                        </button>
                        <button class="failure-button" (click)="close()">{{ 'payment-page.close' | translate }}</button>
                    </div>
                    <div class="section-5">{{ need_support }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
