import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

import * as FileSaver from 'file-saver';

import { JiocloudService } from 'src/app/core/services/jiocloud.service';
import { TranslateService } from '@ngx-translate/core';

declare var require: any;
var clappr = require('clappr');
declare var $: any;

@Component({
    selector: 'app-video-clappr',
    templateUrl: './video-clappr.component.html',
    styleUrls: ['./video-clappr.component.scss']
})
export class VideoClapprComponent implements OnInit, OnDestroy {
    @Input() meetingImageTranscodeUrl;
    @Input() meetingPlaybackUrl;
    @Input() downloadUrl;
    @Input() isDataProtection;
    @Input() jiomeetId;
    @Input() fileName;
    @Output() close = new EventEmitter();
    @ViewChild('downloadAnchor', { static: true }) downloadAnchor: ElementRef;
    public showPreview: boolean = false;
    toastIds: any[] = [];
    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private jcService: JiocloudService,
        private toastrService: ToastrService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.showPreview = true;
        this.openClappr();
    }

    openClappr() {
        this.jcService.getPlaybackSourceUrl(this.meetingPlaybackUrl, this.jiomeetId).subscribe(
            (res) => {
                this.playVideoMedia(res);
            },
            (error) => {}
        );
    }

    closePreview(value: boolean) {
        this.close.emit(value);
    }

    playVideoMedia(sourceUrl) {
        return new clappr.Player({
            source: sourceUrl,
            parentId: '#video-media-content',
            persistConfig: true, // added
            allowUserInteraction: true,
            border: '2px solid yellow',
            autoSeekFromUrl: false,
            maxBufferLength: 5,
            playbackNotSupportedMessage: 'Please try on a different browser',
            poster: this.meetingImageTranscodeUrl,
            closedCaptionsConfig: {
                title: 'Subtitles',
                ariaLabel: 'Closed Captions',
                labelCallback: (track) => {
                    return track.name;
                }
            }
            // events: this.videoClapprEvents()
        });
    }

    videoClapprEvents() {
        // var top = this.jcService.videoPreviewUrl['top'] + 'px';
        // var width = this.jcService.videoPreviewUrl['imageWidth'] + 'px';
        // var height = this.jcService.videoPreviewUrl['imageHeight'] + 'px';
        // var left = this.jcService.videoPreviewUrl['left'] + 'px';
        return {
            onReady: function () {
                // $('#video-media-content').show();
                // $('#video-media-content [data-player]').css('top', top);
                // $('#video-media-content [data-player]').css('height', height);
                // $('#video-media-content [data-player]').css('width', width);
                // $('#video-media-content [data-player]').css('left', left);
            },
            onResize: function () {
                // $('#video-media-content [data-player]').css('top', top);
                // $('#video-media-content [data-player]').css('height', height);
                // $('#video-media-content [data-player]').css('width', width);
                // $('#video-media-content [data-player]').css('left', left);
            },
            onPlay: function () {
                // clevertap.event.push("User_Media_Played", {
                // "FILE_TYPE": "Video",
                // "LOCATION":cleverTapSource
                // });
            },
            onPause: function () {},
            onStop: function () {}
        };
    }

    downloadFile() {
        if (this.isDataProtection) {
            this.toastrService.error(this.translateService.instant('tostrs.downloading_a_file_is_not_allowed'));
            return;
        }
        this.toastIds.push(this.toastrService.success('Downloading in progress'));
        this.jcService
            .downloadFile(this.fileName, this.downloadUrl, this.jiomeetId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((resp) => {
                FileSaver.saveAs(resp, this.fileName);
                this.toastIds.push(this.toastrService.success('Download completed'));
            });
    }

    ngOnDestroy() {
        this.toastIds.forEach((toastr) => {
            this.toastrService.clear(toastr.toastId);
        });
        // This aborts all HTTP requests.
        this.ngUnsubscribe.next();
        // This completes the subject properlly.
        this.ngUnsubscribe.complete();
    }
}
