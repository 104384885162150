import { ErrorInterceptor } from './error.interceptor';
import { TokenInterceptor } from './token.interceptor';
import { errorHandler } from './error.handler';
import { DesktopAppApiInterceptor } from './desktop-app-api.interceptor';

export * from './error.interceptor';
export * from './token.interceptor';
export * from './error.handler';

export * from './error.interceptor';
export * from './token.interceptor';
export * from './desktop-app-api.interceptor';

export const interceptors = [ErrorInterceptor, TokenInterceptor, DesktopAppApiInterceptor, errorHandler];
