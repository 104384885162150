import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeepLinkService } from 'src/app/core/services/deep-link.service';

@Component({
    selector: 'app-mobile-download-page',
    templateUrl: './mobile-download-page.component.html',
    styleUrls: ['./mobile-download-page.component.scss']
})
export class MobileDownloadPageComponent implements OnInit {
    @Output() continueToMeeting: EventEmitter<any> = new EventEmitter();

    constructor(private deepLinkService: DeepLinkService, private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {}

    launchInBrowser() {
        this.continueToMeeting.emit(true);
    }

    downloadApp() {
        this.deepLinkService.handleDeepLink(this.activatedRoute);
    }
}
