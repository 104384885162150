<div class="backbutton" (click)="cancel()">
    <img alt="" src="assets/images/arrow_back_blue.svg" />{{
        'user_dashboard_settings.change_password.back' | translate
    }}
</div>
<div class="change-password-container">
    <h2 class="mt-5">{{ 'user_dashboard_settings.change_password.change' | translate }}</h2>
    <form class="custom-form" [formGroup]="changePasswordForm">
        <div class="input-field">
            <div class="input-label">{{ 'user_dashboard_settings.change_password.current' | translate }}</div>
            <div class="input-text">
                <input
                    type="password"
                    placeholder="{{ 'user_dashboard_settings.change_password.current_placeholder' | translate }}"
                    formControlName="oldPassword"
                    maxlength="20"
                />
            </div>
        </div>
        <div class="input-field">
            <div class="input-label">{{ 'user_dashboard_settings.change_password.new' | translate }}</div>
            <div class="passwordicon input-text">
                <input
                    [type]="showPassword ? 'text' : 'password'"
                    placeholder="{{ 'user_dashboard_settings.change_password.new_placeholder' | translate }}"
                    formControlName="password"
                    maxlength="20"
                />
                <img
                    alt=""
                    src="{{ showPassword ? 'assets/img/show_password.svg' : 'assets/img/hide_password.svg' }}"
                    (click)="togglePassword()"
                    class="eye"
                />
            </div>
        </div>
        <div class="password-info">
            <!-- <ng-container *ngIf="changePasswordForm.value.password"> -->
            <ng-container>
                <div class="password-hint">
                    <p class="password-info">
                        {{ 'password_criteria.must_have' | translate }}
                    </p>
                    <ul>
                        <li
                            [ngClass]="{
                                'is-invalid': errors?.password?.includes('lengthError'),
                                valid:
                                    changePasswordForm.get('password').value &&
                                    !errors?.password?.includes('lengthError')
                            }"
                        >
                            {{ 'password_criteria.characters' | translate: txParams?.characters }}
                        </li>
                        <li
                            [ngClass]="{
                                'is-invalid': errors?.password?.includes('alphabetError'),
                                valid:
                                    changePasswordForm.get('password').value &&
                                    !errors?.password?.includes('alphabetError')
                            }"
                        >
                            {{ 'password_criteria.letter' | translate: txParams?.alphabets }}
                        </li>
                        <li
                            [ngClass]="{
                                'is-invalid': errors?.password?.includes('numberError'),
                                valid:
                                    changePasswordForm.get('password').value &&
                                    !errors?.password?.includes('numberError')
                            }"
                        >
                            {{ 'password_criteria.number' | translate: txParams?.numbers }}
                        </li>
                        <li
                            [ngClass]="{
                                'is-invalid': errors?.password?.includes('dualError'),
                                valid:
                                    changePasswordForm.get('password').value && !errors?.password?.includes('dualError')
                            }"
                        >
                            {{ 'password_criteria.case' | translate }}
                        </li>
                        <li
                            [ngClass]="{
                                'is-invalid': errors?.password?.includes('specialCharsError'),
                                valid:
                                    changePasswordForm.get('password').value &&
                                    !errors?.password?.includes('specialCharsError')
                            }"
                        >
                            {{ 'password_criteria.symbol' | translate }}
                        </li>
                    </ul>
                </div>
            </ng-container>
        </div>
        <div class="input-field">
            <div class="input-label">{{ 'user_dashboard_settings.change_password.confirm' | translate }}</div>
            <div class="input-text">
                <input
                    type="password"
                    placeholder="{{ 'user_dashboard_settings.change_password.confirm_placeholder' | translate }}"
                    formControlName="confirmPassword"
                    maxlength="20"
                />
            </div>
        </div>
        <div class="mt-2 text-error" *ngIf="errors.global">{{ errors.global }}</div>
        <div class="button-group">
            <button
                type="submit"
                class="custom-button bg-primary mr-5"
                [class.disabled]="!changePasswordForm.valid"
                (click)="submit()"
            >
                {{ 'user_dashboard_settings.change_password.save' | translate }}
            </button>
            <button type="button" class="custom-button bg-secondary" (click)="cancel()">
                {{ 'user_dashboard_settings.change_password.cancel' | translate }}
            </button>
        </div>
    </form>
</div>
