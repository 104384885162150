<div class="limiter">
    <div class="container-login100">
        <div class="wrap-login100 p-b-20">
            <div class="login100-form validate-form">
                <span class="login100-form-avatar1">
                    <img alt="" src="assets/img/logo/jm-logo-blue.svg" alt="JioMeet" />
                </span>

                <div class="m-t-20" style="text-align: center; color: #ffffff; font-weight: bold">
                    <h4 style="font-size: 28px">Verified</h4>
                </div>

                <div style="text-align: center; font-size: 14px; color: #badbf3; padding: 20px">
                    <span
                        >Your account has been verified successfully! Please check your email for further
                        instructions.</span
                    >
                </div>
            </div>
        </div>
    </div>
</div>
