import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { AuthService } from './../services/auth.service';
import { Observable } from 'rxjs';
import { UserService, AppService, SystemService, DesktopAppService, UtilService } from '../services';
import { JiocloudAuthService } from '../services/jiocloud-auth.service';
import { GuestAuthService } from '../services/guest-auth.service';
import { TranslateService } from '@ngx-translate/core';
import { AppLoggerService } from '../services/app-logger.service';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private userService: UserService,
        private jiocloudAuthService: JiocloudAuthService,
        private guestAuthService: GuestAuthService,
        private systemService: SystemService,
        private translate: TranslateService,
        private appLoggerService: AppLoggerService,
        private desktopAppService: DesktopAppService,
        private utilService: UtilService,
        private appService: AppService
    ) {}

    varlidateApiUrl(request) {
        let ValidApiUrl = this.desktopAppService.isDesktopApp
            ? request.url.indexOf('/api') > -1 ||
              request.url.indexOf(`${'/'}${this.desktopAppService.selectedEnvironment?.value}${'_api'}`)
            : request.url.indexOf('/api') > -1;
        return ValidApiUrl;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.appLoggerService.isCloudLoggingUrl(request.url)) {
            return next.handle(request);
        }
        if (this.userService.isDeleteAccountUrl(request.url)) {
            return next.handle(request);
        }
        // jio cloud
        if (this.jiocloudAuthService.isJioCloudRequest(request)) {
            if (
                this.jiocloudAuthService.getAuthInfo() &&
                !['/security/users/logout', '/account/external/login', '/account/token/refresh'].filter(
                    (path) => request.url.indexOf(path) > -1
                ).length
            ) {
                request = request.clone({
                    headers: this.jiocloudAuthService.getCommonHeaders(
                        request.headers,
                        request.headers.get('X-Meeting-Id')
                    )
                });
            }
            return next.handle(request);
        }
        let ValidApiUrl = this.varlidateApiUrl(request);
        if (ValidApiUrl) {
            const authInfo = this.authService.getAuthInfo();
            if (authInfo) {
                var authUserheader = this.getCommonRequestHeaders();
                authUserheader['Authorization'] = authInfo.jwt;

                request = request.clone({
                    setHeaders: authUserheader
                });
                if (this.userService.getUserSync().email || this.userService.getUserSync().phoneNo) {
                    request = request.clone({
                        setHeaders: {
                            'X-Identity': this.userService.getUserSync().email || this.userService.getUserSync().phoneNo
                        }
                    });
                }
            }

            // Guest User
            if (this.guestAuthService.getAuthInfo() && this.guestAuthService.isGuestRequest(request)) {
                var guestHeader = this.getCommonRequestHeaders();
                (guestHeader['Authorization'] = this.guestAuthService.getAuthInfo().jwt),
                    (request = request.clone({
                        setHeaders: guestHeader
                    }));
                return next.handle(request);
            }

            var commonHeader = this.getCommonRequestHeaders();

            request = request.clone({
                setHeaders: commonHeader
            });

            const rilAuthInfo = this.authService.getRILAuthInfo();
            let rilJwt = null;
            if (rilAuthInfo && rilAuthInfo.jwt) {
                rilJwt = rilAuthInfo.jwt;
                if (
                    ['/api/verifyotp', '/api/authorize/google', '/api/authorize/facebook', '/api/sign/signup'].filter(
                        (path) => request.url.indexOf(path) > -1
                    ).length
                ) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: rilJwt
                        }
                    });
                }
            }
        }

        return next.handle(request);
    }

    getCommonRequestHeaders() {
        var commonHeader = {
            deviceid: this.systemService.getDeviceUUID(),
            deviceType: this.systemService.getClientDeviceType(),
            'accept-language': this.translate.currentLang || 'en'
        };

        if (this.desktopAppService.isDesktopApp) {
            commonHeader['x-platform'] = this.utilService.fetchPlatformType();
            commonHeader['x-version'] = this.appService.fetchBuildVersion();
        }

        return commonHeader;
    }
}
