import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TimerService {
    constructor() {}

    getNewTimer(duration, onTimerEndCallBack) {
        return setTimeout(() => {
            onTimerEndCallBack.call(onTimerEndCallBack);
        }, duration);
    }

    stop(timeoutId) {
        clearInterval(timeoutId);
    }
}
