import { Component, OnInit } from '@angular/core';
import { GaDiagnosticService } from 'src/app/core/services/ga-diagnostic.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    AppInfoService,
    AppService,
    EventData,
    EventEmitterService,
    GoogleTagManagerService,
    UserService,
    UtilService,
    ValidationService
} from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { APP_EVENTS } from 'src/app/constants';
import { GA_PAGEVIEW } from 'src/app/components/website/ga-pageview';
import { GA_EVENTS } from '../ga-events';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { GA_NEW } from 'src/app/shared/ga-new';

@Component({
    selector: 'app-contact-sales',
    templateUrl: './contact-sales.component.html',
    styleUrls: ['./contact-sales.component.scss']
})
export class ContactSalesComponent implements OnInit {
    toggleView: boolean = false;
    isMobile = false;
    showField: boolean = false;
    contactSalesForm: FormGroup;
    error;
    errors: any = {};
    submitted = false;
    nameRegExp = /^[a-zA-Z0-9_\- ]{2,50}$/;
    companyRegExp = /^[a-zA-Z0-9_\@\-\.\, ]*$/;
    designationRegExp = /([a-zA-Z',.-]+( [a-zA-Z',.-]+)*){2,200}/;
    // phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;
    phoneRegExp = /^[6789]\d{9}$/;
    companySizes = [
        {
            name: 'Less than 10',
            value: '<10',
            tkey: 'landing_or_home_page.section.register_form.less_than_10',
            tvalue: ''
        },
        { name: '11 - 100', value: '<100', tkey: '11 - 100', tvalue: '' },
        { name: '101 - 1000', value: '<1000', tkey: '101 - 1000', tvalue: '' },
        { name: '1000+', value: '>1000', tkey: '1000+', tvalue: '' }
    ];
    sectors = [
        {
            value: 'Education',
            name: 'Education',
            tkey: 'landing_or_home_page.section.register_form.education',
            tvalue: ''
        },
        {
            value: 'Government',
            name: 'Government',
            tkey: 'landing_or_home_page.section.register_form.government',
            tvalue: ''
        },
        {
            value: 'Healthcare',
            name: 'Healthcare',
            tkey: 'landing_or_home_page.section.register_form.healthcare',
            tvalue: ''
        },
        {
            value: 'ERP',
            name: 'ERP',
            tkey: 'landing_or_home_page.section.register_form.erp',
            tvalue: ''
        },
        {
            value: 'Collaboration',
            name: 'Collaboration',
            tkey: 'landing_or_home_page.section.register_form.collaboration',
            tvalue: ''
        },
        {
            value: 'Finance and Banking',
            name: 'Finance and Banking',
            tkey: 'landing_or_home_page.section.register_form.finance_and_banking',
            tvalue: ''
        },
        {
            value: 'Productivity',
            name: 'Productivity',
            tkey: 'landing_or_home_page.section.register_form.productivity',
            tvalue: ''
        },
        {
            value: 'Security and Compliance',
            name: 'Security and Compliance',
            tkey: 'landing_or_home_page.section.register_form.secure_and_comliance',
            tvalue: ''
        },
        {
            value: 'Others',
            name: 'Others',
            tkey: 'landing_or_home_page.section.register_form.others',
            tvalue: ''
        }
    ];
    wlYes: boolean = false;
    wlNo: boolean = false;

    interestedProducts = [
        { name: 'JioMeet Pro', value: 'jiomeetPro', tname: 'jiomeet_pro' },
        { name: 'JioMeet Enterprise', value: 'jiomeetEnterprise', tname: 'jiomeet_enterprise' },
        { name: 'Platform (CPaaS)', value: 'jiomeetPlatform', tname: 'jiomeet_cpaas' },
        { name: 'Jio Events', value: 'jiomeetEvents', tname: 'jiomeet_conference' },
        { name: 'Add On', value: 'addOn', tname: 'add_On' }
    ];

    whiteLabel = [
        { name: 'Yes', value: true, tname: 'yes_wl' },
        { name: 'No', value: false, tname: 'no_wl' }
    ];

    captchaDetails: any = {};
    captchaId;
    ShowWhiteLabel: boolean = false;
    routerSub: Subscription;
    submitInProgress = false;
    constructor(
        private router: Router,
        private fb: FormBuilder,
        private appService: AppService,
        private validationService: ValidationService,
        private userService: UserService,
        private translate: TranslateService,
        private toastrService: ToastrService,
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private appInfoService: AppInfoService,
        private gTagService: GoogleTagManagerService
    ) {}

    ngOnInit() {
        this.routerSub = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((e) => {
            if (this.toggleView) {
                this.initiateValidators();
                this.errors = {};
                this.toggleView = false;
            }
        });
        this.isMobile = this.utilService.isMobileBrowser(1080);
        this.initiateValidators();
        this.translate.onLangChange.subscribe((res) => {
            this.handleLanguageTranslate();
        });
        this.handleLanguageTranslate();
        this.gTagService.pushOnDataLayer(GA_PAGEVIEW.SALES_PAGE_VIEW);
    }
    initiateValidators() {
        this.refreshCaptcha();
        this.contactSalesForm = this.fb.group({
            company: ['', [Validators.required]],
            name: ['', [Validators.required]],
            phone: ['', [Validators.required, this.validationService.isFormControlValueAPhoneNumber]],
            email: ['', [Validators.required, this.validationService.isFormControlValueAnEmail]],
            companySize: [{ value: '<10' }, [Validators.required]],
            sector: [{ value: 'Education' }, [Validators.required]],
            interestedProducts: this.fb.array([], [Validators.required]),
            isWhiteLabelSolution: [''],
            appName: [''],
            whiteListingUrl: [''],
            whiteListingIp: [''],
            logoUrl: [''],
            redirectUrl: [''],
            policyUrl: [''],
            captchaText: ['', [Validators.required]]
        });

        this.contactSalesForm.get('interestedProducts').valueChanges.subscribe((res: any) => {
            this.ShowWhiteLabel = false;
            for (let i of res) {
                if (i === 'jiomeetPlatform') {
                    this.ShowWhiteLabel = true;
                    if (this.wlYes) {
                        this.showField = true;
                        this.contactSalesForm.setControl(
                            'isWhiteLabelSolution',
                            this.fb.control(true, [Validators.required])
                        );
                    } else if (this.wlNo) {
                        this.showField = true;
                        this.contactSalesForm.setControl(
                            'isWhiteLabelSolution',
                            this.fb.control(false, [Validators.required])
                        );
                    } else {
                        this.showField = false;
                        this.contactSalesForm.setControl(
                            'isWhiteLabelSolution',
                            this.fb.control('', [Validators.required])
                        );
                    }
                }
            }
            if (!this.ShowWhiteLabel) {
                this.contactSalesForm.setControl('isWhiteLabelSolution', this.fb.control(''));
                this.contactSalesForm.setControl('appName', this.fb.control(''));
                this.contactSalesForm.setControl('whiteListingUrl', this.fb.control(''));
                this.contactSalesForm.setControl('whiteListingIp', this.fb.control(''));
                this.contactSalesForm.setControl('logoUrl', this.fb.control(''));
                this.contactSalesForm.setControl('policyUrl', this.fb.control(''));
                this.contactSalesForm.setControl('redirectUrl', this.fb.control(''));
            }
        });
    }
    handleLanguageTranslate() {
        this.translate
            .get([...this.companySizes.map((c) => c.tkey), ...this.sectors.map((c) => c.tkey)])
            .subscribe((translations) => {
                this.companySizes.forEach((c) => {
                    c.tvalue = translations[c.tkey];
                });
                this.sectors.forEach((s) => {
                    s.tvalue = translations[s.tkey];
                });
                this.contactSalesForm
                    .get('companySize')
                    .setValue(this.contactSalesForm.get('companySize').value || '<10');
                this.contactSalesForm.get('sector').setValue(this.contactSalesForm.get('sector').value || 'Education');
            });
    }

    validate() {
        this.errors = {};
        if ((this.contactSalesForm.value.company || '').trim().length < 1) {
            this.errors.company = this.translate.instant('tostrs.company_name_should_be_at_least_one_character_long');
            return false;
        }
        if ((this.contactSalesForm.value.company || '').trim().length > 1000) {
            this.errors.company = this.translate.instant('tostrs.company_name_should_not_be_more_than_1000_characters');
            return false;
        }
        if ((this.contactSalesForm.value.name || '').trim().length < 1) {
            this.errors.name = this.translate.instant('tostrs.name_should_be_at_least_one_character_long');
            return false;
        }
        if ((this.contactSalesForm.value.name || '').trim().length > 200) {
            this.errors.name = this.translate.instant('tostrs.name_should_not_be_more_than_200_characters');
            return false;
        }

        if (!this.nameRegExp.test(this.contactSalesForm.value.name)) {
            this.errors.name = this.translate.instant('tostrs.please_enter_a_valid_name');
            return false;
        }
        if (!this.companyRegExp.test(this.contactSalesForm.value.company)) {
            this.errors.company = this.translate.instant('tostrs.please_enter_a_valid_company_name');
            return false;
        }

        if (!this.phoneRegExp.test(this.contactSalesForm.value.phone)) {
            this.errors.phone = this.translate.instant('tostrs.please_enter_a_valid_phone_number');
            return false;
        }
        if ((this.contactSalesForm.value.captchaText || '').trim().length !== 6) {
            this.errors.captchaText = this.translate.instant('tostrs.captcha_text');
            return false;
        }
        // if (!this.validationService.isPhoneNumber(this.contactSalesForm.value.phone)) {
        //   this.errors.phone = 'Please enter a valid phone number.';
        //   return false;
        // }
        if (!this.validationService.isEmail(this.contactSalesForm.value.email)) {
            this.errors.email = this.translate.instant('tostrs.please_enter_a_valid_email_id');
            return false;
        }
        return true;
    }

    verifyCaptcha() {
        if (this.submitInProgress) {
            return;
        }
        this.submitInProgress = true;
        const captchaText = this.contactSalesForm.value.captchaText;
        this.appInfoService.verifyCaptchaDetail({ captchaId: this.captchaId, captchaText }).subscribe(
            (res: any) => {
                if (res.success) {
                    this.register();
                } else {
                    this.submitInProgress = false;
                    this.refreshCaptcha();
                    this.errors.captchaText = this.translate.instant('tostrs.captcha_text');
                    this.gTagService.sendEvent(GA_EVENTS.CONTACT_SALES_SUBMIT_ERROR);
                    setTimeout(() => {
                        document.getElementById('invalidCaptcha').focus();
                    });
                }
            },
            (err) => {
                this.submitInProgress = false;
                this.gTagService.sendEvent(GA_EVENTS.CONTACT_SALES_SUBMIT_ERROR);
                this.errors.captchaText = this.translate.instant('tostrs.captcha_text');
                setTimeout(() => {
                    document.getElementById('invalidCaptcha').focus();
                });
            }
        );
    }

    register() {
        if (!this.contactSalesForm.valid) {
            return;
        }
        this.errors = {};
        this.error = false;
        if (!this.validate()) {
            this.submitInProgress = false;
            this.refreshCaptcha();
            this.gTagService.sendEvent(GA_EVENTS.CONTACT_SALES_SUBMIT_ERROR);
            return;
        }
        if (!this.appService.isOnLine) {
            this.submitInProgress = false;
            this.toastrService.info(this.translate.instant('tostrs.something_went_right'));
            return;
        }
        const { companySize, sector, ...payload } = this.contactSalesForm.value;

        payload.companySize = companySize.value;
        payload.sector = sector.value;
        payload.phone = payload.phone.toString();
        if (payload?.captchaText) {
            delete payload.captchaText;
        }
        if (payload?.isWhiteLabelSolution && this.ShowWhiteLabel) {
            delete payload.logoUrl;
            delete payload.policyUrl;
            delete payload.redirectUrl;
        }
        if (!payload?.isWhiteLabelSolution && this.ShowWhiteLabel) {
            delete payload.whiteListingUrl;
            delete payload.whiteListingIp;
        }

        if (!this.ShowWhiteLabel) {
            delete payload.whiteListingUrl;
            delete payload.whiteListingIp;
            delete payload.logoUrl;
            delete payload.policyUrl;
            delete payload.redirectUrl;
            delete payload.isWhiteLabelSolution;
        }

        this.submitted = true;
        this.userService.contactForSales(payload).subscribe(
            (res: any) => {
                if (res.success) {
                    this.toggleView = true;
                    document.documentElement.scrollTop = 0;
                    this.utilService.scrollToTop('app-container', 0);
                    this.gTagService.sendEvent(GA_EVENTS.CONTACT_SALES_SUBMIT_SUCCESS);
                    this.gTagService.sendEvent(GA_NEW.CONTACT_SALES_SUBMIT_SUCCESS);
                }
                this.submitInProgress = false;
                this.submitted = false;
            },
            (err) => {
                this.submitted = false;
                this.submitInProgress = false;
                this.error =
                    err && err.error && err.error.message
                        ? err.error.message === 'REGISTRATION_LIMIT_EXCEEDED'
                            ? 'You have already registered.'
                            : err.error.message
                        : 'Something went wrong, please try again later.';

                !(err && err.error && err.error.message)
                    ? this.gTagService.sendEvent(GA_EVENTS.CONTACT_SALES_SUBMIT_FAILURE)
                    : this.gTagService.sendEvent(GA_EVENTS.CONTACT_SALES_SUBMIT_ERROR);
                !(err && err.error && err.error.message)
                    ? this.gTagService.sendEvent(GA_NEW.CONTACT_SALES_SUBMIT_FAILURE)
                    : this.gTagService.sendEvent(GA_NEW.CONTACT_SALES_SUBMIT_ERROR);
            }
        );
    }

    checkCharLimit(limit, event) {
        if (
            event.which !== 46 && // delete
            event.which !== 8 && // backspace
            event.target.value.length >= limit
        ) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    checkNumber(event) {
        // Allow only numbers on keypress/paste
        let value = event.srcElement.value;
        let numbers = value.replace(/[^0-9]/g, '');
        event.srcElement.value = numbers;
    }

    onKeyDown(event: KeyboardEvent, checkBoxId) {
        // Toggle the checkbox's checked state
        const checkbox: HTMLInputElement = document.getElementById(checkBoxId) as HTMLInputElement;
        if (checkbox) {
            checkbox.checked = !checkbox.checked;
            this.toggleProductsCheckBox(checkbox.checked, checkBoxId);
        }
    }

    toggleProductsCheckBox(checked, productValue) {
        const interestedProducts: FormArray = this.contactSalesForm.get('interestedProducts') as FormArray;

        if (checked) {
            interestedProducts.push(new FormControl(productValue));
        } else {
            let i: number = 0;
            interestedProducts.controls.forEach((item: FormControl) => {
                if (item.value == productValue) {
                    interestedProducts.removeAt(i);
                    return;
                }
                i++;
            });
        }
    }

    onCheckboxChange(e) {
        const interestedProducts: FormArray = this.contactSalesForm.get('interestedProducts') as FormArray;
        this.toggleProductsCheckBox(e.target.checked, e.target.value);
    }

    onCheckWLboxChange(e) {
        this.showField = true;
        this.wlYes = this.contactSalesForm.get('isWhiteLabelSolution').value;
        this.wlNo = !this.wlYes;
        console.log(this.wlYes);
        if (this.wlYes) {
            this.contactSalesForm.setControl('appName', this.fb.control('', [Validators.required]));
            this.contactSalesForm.setControl('whiteListingUrl', this.fb.control('', [Validators.required]));
            this.contactSalesForm.setControl('whiteListingIp', this.fb.control('', [Validators.required]));
            this.contactSalesForm.setControl('logoUrl', this.fb.control(''));
            this.contactSalesForm.setControl('policyUrl', this.fb.control(''));
            this.contactSalesForm.setControl('redirectUrl', this.fb.control(''));
        } else {
            this.contactSalesForm.setControl('whiteListingUrl', this.fb.control(''));
            this.contactSalesForm.setControl('whiteListingIp', this.fb.control(''));
            this.contactSalesForm.setControl('appName', this.fb.control('', [Validators.required]));
            this.contactSalesForm.setControl('logoUrl', this.fb.control('', [Validators.required]));
            this.contactSalesForm.setControl('policyUrl', this.fb.control('', [Validators.required]));
            this.contactSalesForm.setControl('redirectUrl', this.fb.control('', [Validators.required]));
        }
    }

    refreshCaptcha() {
        this.captchaId = new Date().getTime() + Math.floor(Math.random() * 100 + 1);
        this.appInfoService.getCaptchaDetail({ captchaId: this.captchaId }).subscribe((res) => {
            this.captchaDetails = res;
            this.contactSalesForm.get('captchaText').setValue('');
        });
    }

    navigateToPrivacyPolicy() {
        this.gTagService.sendEvent(GA_NEW.CONTACT_SALES_PRIVACY_POLICY);
        window.open(this.appService.getEnvVariable('HOST_URL') + 'privacy-policy-business');
    }

    ngOnDestroy() {
        this.routerSub.unsubscribe();
        if (this.contactSalesForm.dirty) {
            this.gTagService.sendEvent(GA_EVENTS.CONTACT_SALES_FORM_NOT_SUBMITTED);
            this.gTagService.sendEvent(GA_NEW.CONTACT_SALES_FORM_NOT_SUBMITTED);
        }
    }
}
