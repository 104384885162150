<div class="page container-fluid" *ngIf="loading">
    <app-loader></app-loader>
</div>
<div class="page container-fluid" *ngIf="!mailSent && !loading">
    <div class="row h-100">
        <div [ngClass]="!isGuest ? 'left-signup-content' : 'guest-content'">
            <div class="img-wrap">
                <div
                    *ngIf="!ottLogin"
                    class="renderer"
                    [class.banner-height]="!isGuest && bannersConfigData?.showHomePageBanner"
                >
                    <div class="head" tabindex="0" *ngIf="!isGuest">{{ 'signup_page.head' | translate }}</div>
                    <div class="head-guest" *ngIf="isGuest">
                        {{ 'signup_page.thanks_for_using_jiomeet' | translate }}
                    </div>
                    <img
                        *ngIf="!isGuest && !bannersConfigData?.showHomePageBanner"
                        src="assets/img/signup.svg"
                        alt=""
                    />
                    <app-lottie-renderer
                        *ngIf="!isGuest && bannersConfigData?.showHomePageBanner"
                        [path]="bannersConfigData?.homePageBannerURL"
                        style="margin-left: 20%"
                        [class.]
                    >
                    </app-lottie-renderer>
                    <img
                        *ngIf="isGuest && !bannersConfigData?.showHomePageBanner"
                        class="guest-image"
                        src="assets/img/guest-image.svg"
                        alt=""
                    />
                    <app-lottie-renderer
                        *ngIf="isGuest && bannersConfigData?.showHomePageBanner"
                        [path]="bannersConfigData?.homePageBannerURL"
                        class="guest-image"
                    >
                    </app-lottie-renderer>
                </div>
                <app-lottie-renderer
                    *ngIf="ottLogin"
                    path="assets/lottie/Wrong OTP animation.json"
                ></app-lottie-renderer>
            </div>
            <div class="bottom" *ngIf="isGuest">
                <div class="bottom-exciting">
                    {{ 'signup_page.get_rewards' | translate }}
                </div>
                <div class="bottom-host">
                    {{ 'signup_page.host_unlimited_free_meetings' | translate }}
                </div>
                <div class="rating" *ngIf="!thankyou">
                    {{ 'signup_page.how_was_your_call' | translate }}
                    <div class="rating-stars" (click)="submitRating()">
                        <app-rating (rate)="handleRating($event)" [isGuestUser]="true"></app-rating>
                    </div>
                </div>
                <div *ngIf="thankyou" class="thankyou">{{ 'signup_page.thankyou_for_your_feedback' | translate }}</div>
            </div>
        </div>
        <div class="right-signup-content">
            <div class="component-wrap pb-3">
                <div class="form-wrap pb-5">
                    <section>
                        <h3
                            tabindex="0"
                            aria-label=" Sign up now, it's free"
                            *ngIf="!ottLogin && !isGuest"
                            class="signupheader"
                            role="form"
                        >
                            {{ 'signup_page.title' | translate }}
                        </h3>
                        <h3 *ngIf="isGuest">{{ 'signup_page.sign_up_now' | translate }}</h3>
                        <h3 *ngIf="ottLogin">
                            <div *ngIf="!newOTPSent" tabindex="0" class="OtpHeaderFocus">
                                {{ 'signup_page.otpsent' | translate }}
                            </div>
                            <div *ngIf="newOTPSent" tabindex="0" class="OtpHeaderFocus">
                                {{ 'signup_page.newotpsent' | translate }}
                            </div>
                            <div tabindex="0">"{{ signupForm.value.emailId }}"</div>
                            <div *ngIf="!newOTPSent" tabindex="0">{{ 'signup_page.otpsent1' | translate }}</div>
                            <div *ngIf="newOTPSent" tabindex="0">{{ 'signup_page.newotpsent1' | translate }}</div>
                        </h3>
                        <div class="signup-info" *ngIf="alreadyRegistered" tabindex="0">
                            {{ 'signup_page.allreadyregistered' | translate }}
                        </div>
                        <form [formGroup]="signupForm" *ngIf="!ottLogin" class="custom-form" id="signup-form">
                            <div class="form-group">
                                <label for="name" class="m-0 mb-2"
                                    >{{ 'signup_page.firstname' | translate }}<em>*</em></label
                                >
                                <input
                                    aria-label="Enter your First Name"
                                    tabindex="0"
                                    type="text"
                                    class="form-control main-focus"
                                    id="name"
                                    formControlName="name"
                                    aria-describedby="emailHelp"
                                    [class.is-invalid]="errors.name"
                                    minlength="1"
                                    maxlength="50"
                                    (keypress)="checkNameLength($event)"
                                />
                                <span class="text-error" *ngIf="errors.name">{{ errors.name }}</span>
                            </div>
                            <div class="form-group">
                                <label for="lname" class="m-0 mb-2"
                                    >{{ 'signup_page.lastname' | translate }}<em>*</em></label
                                >
                                <input
                                    aria-label="Enter your Last Name"
                                    tabindex="0"
                                    type="text"
                                    class="form-control"
                                    id="name"
                                    formControlName="lname"
                                    aria-describedby="emailHelp"
                                    [class.is-invalid]="errors.lname"
                                    minlength="1"
                                    maxlength="50"
                                    (keypress)="checkLastNameLength($event)"
                                />
                                <span class="text-error" *ngIf="errors.lname">{{ errors.lname }}</span>
                            </div>
                            <div class="form-group">
                                <label for="email" class="m-0 mb-2"
                                    >{{ 'signup_page.your_emailid/mobile' | translate }}<em>*</em></label
                                >
                                <input
                                    aria-label="Enter your Email ID or Mobile number"
                                    tabindex="0"
                                    type="text"
                                    class="form-control"
                                    id="email"
                                    formControlName="emailId"
                                    aria-describedby="emailHelp"
                                    [class.is-invalid]="errors.emailId"
                                    maxlength="74"
                                    (keypress)="checkEmailIdLength($event)"
                                />
                                <span
                                    tabindex="0"
                                    aria-label="{{ errors.emailId }}"
                                    aria-live="polite"
                                    class="text-error id-error-focus"
                                    *ngIf="errors.emailId"
                                    >{{ errors.emailId }}</span
                                >
                            </div>
                            <span
                                class="text-error global-error-focus"
                                *ngIf="errors.global || errors.maxOTPLimitReached"
                                tabindex="0"
                                aria-label="{{ errors.global || errors.maxOTPLimitReached }}"
                            >
                                {{ errors.global || errors.maxOTPLimitReached }}</span
                            >
                            <div class="col p-0 w-100 mt-4">
                                <button
                                    aria-label="Submit"
                                    [tabindex]="!signupForm.valid || signingUp ? -1 : 0"
                                    type="submit"
                                    class="custom-button bg-primary text-error ng-star-inserted"
                                    [class.disabled]="!signupForm.valid || signingUp"
                                    (click)="submit()"
                                    (keydown.enter)="submit()"
                                >
                                    {{ (signingUp ? 'signup_page.please_wait' : 'signup_page.submit') | translate }}
                                </button>
                            </div>
                        </form>
                        <form *ngIf="ottLogin" [formGroup]="ottForm" (submit)="verifyOTP()" class="custom-form">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-12" id="passwordfield">
                                        <label for="otp" class="m-0 mb-2" tabindex="0">{{
                                            'signup_page.enterotp' | translate
                                        }}</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            formControlName="otp"
                                            id="password"
                                            #password
                                            [class.is-invalid]="errors.otp"
                                            maxlength="6"
                                            tabindex="0"
                                        />

                                        <span tabindex="0" class="invalid-feedback incorrect-otp">{{
                                            errors.otp
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 w-100 mt-3">
                                <span class="text-error" *ngIf="errors.global">{{ errors.global }}</span>
                                <span class="text-error" *ngIf="errors.maxOTPLimitReached">{{
                                    errors.maxOTPLimitReached
                                }}</span>
                                <button
                                    type="submit"
                                    class="custom-button bg-primary mt-3"
                                    [class.disabled]="
                                        !ottForm.valid || verifyingOTP || disableOttLoginForm() || !ottForm.dirty
                                    "
                                >
                                    {{ 'signup_page.proceed' | translate }}
                                </button>
                            </div>
                        </form>
                        <div class="sign-up-text mt-4" *ngIf="!ottLogin">
                            <span class="acc-info mr-1">{{ 'signup_page.allready_have_an_account' | translate }}</span>
                            <span class="link">
                                <a
                                    class="text-primary"
                                    role="link"
                                    typr="link"
                                    (click)="navigateToLogin()"
                                    (Keydown.enter)="navigateToLogin()"
                                    tabindex="0"
                                    aria-label="Already have an account? sign in using this"
                                >
                                    <u>{{ 'signup_page.signin' | translate }}</u>
                                </a>
                            </span>
                        </div>
                        <div class="sign-up-text mt-3" *ngIf="ottLogin">
                            <span tabindex="0">{{ 'signup_page.haventreceived' | translate }} </span>
                            <span
                                ><a
                                    tabindex="0"
                                    class="pointer blue d-inline"
                                    (click)="getOTP(true)"
                                    type="link"
                                    role="link"
                                    [class.disabled]="disableResend"
                                    >{{ 'signup_page.resend' | translate }}</a
                                ></span
                            >
                            <span tabindex="0"> {{ 'signup_page.or' | translate }} </span>
                            <span
                                ><a
                                    tabindex="0"
                                    class="pointer blue d-inline"
                                    type="link"
                                    role="link"
                                    (click)="userAnotherEmail()"
                                    >{{ 'signup_page.usedifferent' | translate }}</a
                                ></span
                            >
                        </div>
                    </section>
                    <section class="social-login mt-4">
                        <div class="separator">
                            <span tabindex="0">{{ 'signin_page.or' | translate }}</span>
                        </div>
                        <div class="social-login-btns">
                            <div class="social-login-btn pointer" routerLink="/sso" style="display: none">
                                <div class="icon-wrap">
                                    <img alt="" src="assets/img/key_icn.png" />
                                </div>
                                <div class="text">{{ 'signup_page.domain' | translate }}</div>
                            </div>
                            <div
                                class="social-login-btn pointer fb"
                                (click)="loginWithSSO('facebook')"
                                *ngIf="fbVisibility"
                            >
                                <button
                                    type="button"
                                    class="custom-button bg-primary logo"
                                    tabindex="0"
                                    aria-label="Sign Up with Facebook"
                                >
                                    <img src="assets/img/fb-logo.svg" alt="Facebook Signup" />
                                    <div>{{ 'signup_page.facebook' | translate }}</div>
                                </button>
                            </div>
                            <div
                                class="social-login-btn pointer"
                                (click)="loginWithSSO('google')"
                                *ngIf="googleVisibility"
                            >
                                <button
                                    type="button"
                                    class="custom-button bg-primary logo"
                                    tabindex="0"
                                    aria-label="Sign Up with Google"
                                >
                                    <img src="assets/img/g-logo.svg" alt="Google Signup" />
                                    <div>{{ 'signup_page.google' | translate }}</div>
                                </button>
                            </div>
                        </div>
                        <div class="disclaimer" tabindex="0">
                            {{ 'signup_page.disclaimer_signup' | translate }}
                            <a
                                class="pointer"
                                tabindex="0"
                                role="link"
                                type="link"
                                (click)="launchTnc()"
                                (keydown.enter)="launchTnc()"
                                ><u>{{ 'signup_page.disclaimer_service' | translate }}</u></a
                            >{{ 'signup_page.disclaimer_signup_1' | translate }}
                            {{ 'signup_page.disclaimer_and' | translate }}
                            <a
                                class="pointer"
                                tabindex="0"
                                role="link"
                                type="link"
                                (click)="launchPrivacy()"
                                (keydown.enter)="launchPrivacy()"
                                ><u>{{ 'signup_page.disclaimer_privacypolicy' | translate }}</u></a
                            >
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="page container-fluid scrollable" *ngIf="mailSent && !loading">
    <div class="signup-component post-email">
        <div class="header email-verification-header" tabindex="0" *ngIf="!unVerifiedAccount">
            {{ 'email_verification.check' | translate }}
        </div>
        <div class="header" *ngIf="unVerifiedAccount">
            {{ 'email_verification.waiting' | translate }}
        </div>
        <div class="img-wrap" tabindex="0">
            <img src="assets/img/signup-email.svg" alt="Illustration of a mailbox for email sign-up" />
        </div>
        <div class="top-text py-4" tabindex="0">
            <div>
                <span *ngIf="unVerifiedAccount">{{ 'email_verification.looks_like' | translate }}</span>
                <span
                    >{{ 'email_verification.sent' | translate
                    }}<a class="email">
                        {{ signupForm.get('emailId').value }}{{ 'email_verification.tomail' | translate }}</a
                    >
                </span>
            </div>
            <div>{{ 'email_verification.click' | translate }}</div>
        </div>
        <div class="bottom-text">
            <div class="mail-bottom-text" tabindex="0">
                {{ 'email_verification.havent' | translate }}
                <a
                    class="pointer blue underline d-inline"
                    tabindex="0"
                    type="link"
                    role="link"
                    (click)="resendEmail()"
                    (keydown.enter)="resendEmail()"
                    aria-label="Resend"
                    >{{ 'email_verification.resend' | translate }}</a
                >
                or
                <a
                    class="pointer blue underline d-inline"
                    tabindex="0"
                    type="link"
                    role="link"
                    (click)="userAnotherEmail()"
                    (keydown.enter)="userAnotherEmail()"
                    aria-label="Use a different Email"
                    >{{ 'email_verification.use' | translate }}</a
                >
            </div>
        </div>
        <div class="sign-in">
            <a
                class="pointer blue underline"
                tabindex="0"
                type="link"
                role="link"
                (click)="navigateToLogin(true)"
                (keydown.enter)="navigateToLogin(true)"
                >{{ 'email_verification.signin' | translate }}</a
            >
        </div>
    </div>
</div>
