import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { GoogleTagManagerService } from 'src/app/core';

import * as Flickity from 'flickity';

import { AppService, UserService, UtilService } from 'src/app/core';
import { GA_EVENTS } from '../ga-events';

@Component({
    selector: 'app-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit, AfterViewInit {
    @ViewChild('carousel', { static: true }) carouselEle: ElementRef;

    currentUser;
    isMobile = false;
    isOutlookAddInEnabled = false;
    os;

    private carousel;

    constructor(
        private router: Router,
        private appService: AppService,
        private userService: UserService,
        private utilService: UtilService,
        private gTagService: GoogleTagManagerService
    ) {}

    ngOnInit() {
        this.currentUser = this.userService.getUserSync();
        this.isMobile = this.utilService.isMobileBrowser();
        this.os = this.utilService.getOS();
        this.isOutlookAddInEnabled = this.appService.getConfigVariable('OUTLOOK_ADD_IN_ENABLED');
    }

    ngAfterViewInit() {
        this.initCarousel();

        window.addEventListener('resize', () => {
            setTimeout(() => {
                this.carousel.destroy();
                this.initCarousel();
            }, 250);
        });
    }

    initCarousel() {
        this.carousel = new Flickity(this.carouselEle.nativeElement, {
            prevNextButtons: false,
            autoPlay: 3000,
            contain: false,
            cellAlign: 0,
            pageDots: true,
            selectedAttraction: 0.2,
            friction: 0.8,
            index: 0
        });
    }

    navigateToDashboard() {
        this.gTagService.sendEvent(GA_EVENTS.ONBOARDING_PAGE_GO_TO_DASHBOARD);
        this.router.navigate(['/home']);
    }

    navigateToAppStore(os) {
        let url;
        if (os === 'android') {
            url = this.appService.getConfigVariable('ANDROID_INSTALLER_URL');
        } else if (os === 'ios') {
            url = this.appService.getConfigVariable('IOS_INSTALLER_URL');
        }

        if (url) {
            const appQueryParams = this.appService.getAppQueryParams();
            const utmSource = appQueryParams.get('utm_source');
            const utmMedium = appQueryParams.get('utm_medium');
            const utmCampaign = appQueryParams.get('utm_campaign');
            if (utmSource) {
                url += `&utm_source=${utmSource}`;
            }
            if (utmMedium) {
                url += `&utm_medium=${utmMedium}`;
            }
            if (utmCampaign) {
                url += `&utm_campaign=${utmCampaign}`;
            }
            window.open(url, '_blank');
        }
    }

    navigateToMacAppStore() {
        window.open(this.appService.getConfigVariable('MAC_INSTALLER_URL'), '_blank');
    }

    navigateToDownload() {
        window.open(this.utilService.getWindowsClient());
    }

    getOutlookAddIn() {
        window.open(this.appService.getConfigVariable('GET_OUTLOOK_ADD_IN_URL'), '_blank');
    }
}
