<div class="electron-update-popup">
    <div class="popup-component">
        <div class="bg"></div>
        <div class="popup">
            <div class="popup-header">
                <h5 class="invite-title">{{ 'website.updates' | translate }}</h5>
                <div class="close" (click)="cancel()" *ngIf="!isDownloadInProgress">
                    <img alt="" src="assets/img/close_icon.svg" />
                </div>
            </div>
            <div class="popup-body" *ngIf="updateCheckStatus">
                <div class="electron-update-wrapper" *ngIf="!isDownloadInProgress">
                    <div *ngIf="showLoader">
                        <div class="loader-component">
                            <div class="lds-dual-ring"></div>
                        </div>
                    </div>
                    <div class="electron-updater-container" *ngIf="!showLoader && !isDownloadComplete">
                        <div class="electron-updater-info" *ngIf="!!isDownloadAvailable">
                            {{ 'website.new_update_available' | translate }}
                        </div>
                        <div class="electron-updater-info" *ngIf="!!isDownloadAvailable">
                            {{ 'website.newer_electron_version_message' | translate }}
                        </div>
                        <div class="electron-updater-video" *ngIf="!isDownloadAvailable">
                            {{ 'website.electron_upto_date' | translate }}
                        </div>
                    </div>
                </div>

                <div class="electron-progress-wrapper" *ngIf="isDownloadInProgress">
                    <div *ngIf="showProgressLoader && isDownloadInProgress">
                        <div class="loader-component">
                            <div class="lds-dual-ring"></div>
                        </div>
                    </div>
                    <div class="electron-updater-container">
                        {{ 'website.downloading_update' | translate }} {{ downloadProgress + ' %' }}
                    </div>
                </div>

                <div class="electron-progress-wrapper" *ngIf="isDownloadComplete">
                    <div class="electron-updater-container">
                        {{ 'website.download_complete' | translate }}
                    </div>
                    <br />
                    <div class="electron-updater-container">
                        {{ 'website.installing_application' | translate }}
                    </div>
                </div>

                <div class="electron-updater-action-wrapper">
                    <div
                        class="text-center mx-4 my-4"
                        *ngIf="!!isDownloadAvailable && !isDownloadInProgress && !isDownloadComplete"
                    >
                        <button class="btn btn-primary btn-lg pointer" (click)="download()">
                            {{ 'website.download_and_install' | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="popup-body" *ngIf="!updateCheckStatus">
                <div class="electron-update-wrapper">
                    <div class="electron-updater-container">
                        <div class="electron-updater-info">
                            {{ 'website.electron_update_failed' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
