<div
    class="initials"
    [ngClass]="{ screenSharingView: screenSharingView }"
    [ngStyle]="{ 'background-color': colorChosen }"
    [class.singleFeed]="singleFeed"
    [class.moreFeed]="moreFeed"
>
    <ng-container
        *ngIf="participant?.signIn === 'Guest' || (inActive && isJMMeeting) || imagePath === ''; else imageTemplate"
    >
        <span
            *ngIf="!(inActive && isJMMeeting)"
            class="textinitials"
            [class.singleFeed]="singleFeed"
            [class.moreFeed]="moreFeed"
        >
            {{ participantInitials }}
        </span>
        <img
            *ngIf="inActive && isJMMeeting"
            alt=""
            src="assets/img/participant_away_system.svg"
            class="ng-star-inserted profileimagebg"
            [class.isLandscapeMode]="isLandscapeMode"
            title="This Video is paused due to User in background"
        />
    </ng-container>
</div>
<ng-template #imageTemplate>
    <div class="profileimage" *ngIf="participant?.userId" style="background-image: url('{{ imagePath }}')">&nbsp;</div>
</ng-template>
