import { JiocloudAuthService, ThirdPartyExternalIntegrationService } from 'src/app/core';
import { Injectable } from '@angular/core';

import * as _ from 'lodash-es';

import { RestService } from './rest.service';
import { AuthService } from './auth.service';
import { BehaviorSubject, throwError, timer } from 'rxjs';
import { delayWhen, retryWhen, tap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { SystemService } from './system.service';
import { VideoWrapperService } from './video-wrapper.service';
import { genericRetryStrategy } from '../helpers/generic-retry-strategy';
import { AppService } from './app.service';
export interface PanelHighlightControl {
    chat: boolean;
    participants: boolean;
    new_message: boolean;
    popoutchat: boolean;
    qa: false;
    comment: boolean;
    hostControls: boolean;
    moreOptions: boolean;
    evaluation: boolean;
}
@Injectable({
    providedIn: 'root'
})
export class RoomConnectionService {
    private roomConnectionStatus$: BehaviorSubject<any> = new BehaviorSubject(null);
    private controlsHighlightResolver$: BehaviorSubject<PanelHighlightControl> = new BehaviorSubject({
        chat: false,
        participants: false,
        new_message: false,
        popoutchat: false,
        qa: false,
        comment: false,
        hostControls: false,
        moreOptions: false,
        evaluation: false
    });
    controlsHighlightResolver = {
        chat: false,
        participants: false,
        new_message: false,
        popoutchat: false,
        qa: false,
        comment: false,
        hostControls: false,
        moreOptions: false,
        evaluation: false
    };
    private roomInfo: any = {};
    private localParticipant: any = {};
    private sortKey;
    private videoService;
    ignoreHardMute = false;
    currentMeetingInfo: any;

    constructor(
        private restService: RestService,
        private systemService: SystemService,
        private authService: AuthService,
        private videoWrapperService: VideoWrapperService,
        private thirdpartyExternalIntegration: ThirdPartyExternalIntegrationService,
        private appService: AppService,
        private jioCloudAuthService: JiocloudAuthService
    ) {
        this.updateVideoServiceInstance();
    }

    updateVideoServiceInstance() {
        this.videoService = this.videoWrapperService.getVideoService();
    }

    updateConnectionStatus({
        jiomeetId,
        roomID,
        roomPin,
        historyId,
        status,
        participantUri,
        participantId,
        participantName,
        micStatus,
        cameraStatus,
        isHandRaise = false,
        deviceId = this.systemService.getDeviceUUID(),
        deviceType = this.appService.getRecorderUser() ? 'recorder' : this.systemService.getClientDeviceType(),
        participantType = 'speaker',
        isJMMediaWebpageStreamUser = true
    }) {
        console.log('roomconnectionstatus API hitting:', this.appService.getRecorderUser());
        const isAuthenticated =
            this.authService.getIsAuthenticated() && !this.thirdpartyExternalIntegration.isHostTokenUser;
        return this.restService.post(`api/roomconnectionstatus${!isAuthenticated ? '/guest' : ''}`, {
            jiomeetId,
            roomID,
            roomPin,
            historyId,
            status,
            deviceId,
            deviceType,
            participantUri,
            participantId,
            participantName,
            micStatus,
            cameraStatus,
            isHandRaise,
            participantType,
            isJMMediaWebpageStreamUser
        });
    }

    public updateHighlightController(object) {
        this.controlsHighlightResolver = object;
        this.controlsHighlightResolver$.next(object);
    }

    getRoomParticipants() {
        return this.roomInfo.participants;
    }

    public get highlightControl(): BehaviorSubject<any> {
        return this.controlsHighlightResolver$;
    }

    public getHightlightedControls() {
        return this.controlsHighlightResolver;
    }

    getMeetingParticipants(jiomeetId, roomID, roomPin) {
        const isAuthenticated =
            this.authService.getIsAuthenticated() && !this.thirdpartyExternalIntegration.isHostTokenUser;
        const payload = {
            jiomeetId,
            roomID,
            roomPin,
            ...(this.sortKey && { sortkey: this.sortKey })
        };
        return this.restService.post(`api/roomconnectionstatus/get${!isAuthenticated ? '/guest' : ''}`, payload).pipe(
            tap((res: any) => {
                this.appService.isRecordingInSync = true;
                if (!res || res?.success === false) {
                    throw res;
                }
                this.checkForAudioHardMute(res.isHardMute);
                this.roomInfo = res;
                this.setLocalParticipantAndSyncPrivacy();
                this.sendRoomConnectionStatus(true);
            }),
            retryWhen(genericRetryStrategy())
        );
    }

    toggleShareScreen(jiomeetId, participantUri, action: 'start' | 'stop') {
        const isAuthenticated =
            this.authService.getIsAuthenticated() && !this.thirdpartyExternalIntegration.isHostTokenUser;
        return this.restService.post(`api/roomconnectionstatus/sharescreen${!isAuthenticated ? '/guest' : ''}`, {
            jiomeetId,
            participantUri,
            action
        });
    }

    setAudio(jiomeetId, roomID, participantId, audio) {
        const isAuthenticated =
            this.authService.getIsAuthenticated() && !this.thirdpartyExternalIntegration.isHostTokenUser;
        return this.restService.post(`api/roomconnectionstatus/control/audio${!isAuthenticated ? '/guest' : ''}`, {
            jiomeetId,
            roomID,
            participantId,
            audio
        });
    }

    muteUnmuteAudioAll({ jiomeetId, roomID, hard, audio }) {
        return this.restService.post(`api/roomconnectionstatus/control/audioall`, {
            jiomeetId,
            roomID,
            hard,
            audio
        });
    }

    setVideo(jiomeetId, roomID, participantId, video) {
        const isAuthenticated =
            this.authService.getIsAuthenticated() && !this.thirdpartyExternalIntegration.isHostTokenUser;
        return this.restService.post(`api/roomconnectionstatus/control/video${!isAuthenticated ? '/guest' : ''}`, {
            jiomeetId,
            roomID,
            participantId,
            video
        });
    }

    muteUnmuteVideoAll({ jiomeetId, roomID, hard, video }) {
        return this.restService.post(`api/roomconnectionstatus/control/videoall`, {
            jiomeetId,
            roomID,
            hard,
            video
        });
    }

    hostActions({
        jiomeetId,
        roomID,
        roomPin,
        allHandsDown = false,
        coHostsRemoved = [],
        coHostsAdded = [],
        handsDownParticipantIds = []
    }) {
        return this.restService.post(`api/roomconnectionstatus/hosts`, {
            jiomeetId,
            roomID,
            roomPin,
            allHandsDown,
            coHostsRemoved,
            coHostsAdded,
            handsDownParticipantIds
        });
    }

    changeParticipantType({ jiomeetId, roomID, roomPin, participantIds = [], participantType }) {
        return this.restService.post(`api/roomconnectionstatus/control/participantType`, {
            jiomeetId,
            roomID,
            roomPin,
            participantIds,
            participantType
        });
    }

    removeParticipant(jiomeetId, participantID) {
        return this.restService.post(`api/portal/removeparticipant`, {
            jiomeetId,
            participantID
        });
    }

    updateParticipantsStatus(participantsArray) {
        if (typeof this.roomInfo?.participants === 'undefined') return;
        (participantsArray || []).forEach((deltaParticipant) => {
            let index = this.roomInfo?.participants?.findIndex(
                (p) => p?.participantUri === deltaParticipant?.participantUri
            );
            if (index !== -1) {
                this.roomInfo.participants[index] = deltaParticipant;
            } else {
                this.roomInfo?.participants?.push(deltaParticipant);
            }
        });
        this.roomInfo.participants = this.sortParticipantList(this.roomInfo.participants, this.sortKey);
        this.setLocalParticipantAndSyncPrivacy(
            participantsArray.length === 1 && this.localParticipant.participantId === participantsArray[0].participantId
        );
        this.sendRoomConnectionStatus();
    }

    getRoomConnectionStatus$() {
        return this.roomConnectionStatus$;
    }

    setSortKeys(key) {
        this.sortKey = key;
    }

    private setLocalParticipantAndSyncPrivacy(isSourceSocket = false) {
        const localVidyoParticipant = this.videoService.getLocalParticipant();
        this.roomInfo.localParticipant = (this.roomInfo.participants || []).filter(
            (p) => (localVidyoParticipant || {}).id == p.participantUri
        )[0];
        // this.checkForAudioSoftMute();

        if (this.roomInfo.localParticipant) {
            // this.syncMicPrivacyStatus(isSourceSocket);
            // Removed API sync
            // this.syncCameraPrivacyStatus(isSourceSocket);
            this.localParticipant = this.roomInfo.localParticipant;
        }
    }

    syncMicPrivacyStatus(isSourceSocket) {
        if (isSourceSocket && this.roomInfo.localParticipant.micStatus !== this.localParticipant.micStatus) {
            this.videoService.toggleMicPrivacy(!this.roomInfo.localParticipant.micStatus);
        } else if (
            this.localParticipant.micStatus &&
            ((this.roomInfo.isHardMute && !this.ignoreHardMute) || this.roomInfo.isSoftMute)
        ) {
            this.videoService.toggleMicPrivacy(true);
        } else if (this.roomInfo.localParticipant.micStatus !== this.localParticipant.micStatus) {
            this.setAudio(
                this.currentMeetingInfo.jiomeetId,
                this.currentMeetingInfo.room_id,
                this.localParticipant?.participantId,
                this.localParticipant.micStatus
            );
        }
        this.ignoreHardMuteForHosts(); // Also runs on joining call with hard mute
        // alternate: move this to 320 and also run this on first RCS response
    }

    checkForAudioHardMute(incomingHardMuteState) {
        if (!incomingHardMuteState && this.ignoreHardMute) {
            this.ignoreHardMute = false;
        }
    }

    ignoreHardMuteForHosts() {
        if (
            (this.roomInfo.localParticipant.isCoHost || this.roomInfo.localParticipant.isHost) &&
            this.roomInfo.isHardMute
        ) {
            this.ignoreHardMute = true;
        }
    }

    syncCameraPrivacyStatus(isSourceSocket) {
        if (isSourceSocket && this.roomInfo.localParticipant.cameraStatus !== this.localParticipant.cameraStatus) {
            this.videoService.toggleCameraPrivacy(!this.roomInfo.localParticipant.cameraStatus);
        } else if (
            this.localParticipant.cameraStatus &&
            (this.roomInfo.isHardVideoMute || this.roomInfo.isSoftVideoMute)
        ) {
            this.videoService.toggleCameraPrivacy(true);
        } else if (this.roomInfo.localParticipant.cameraStatus !== this.localParticipant.cameraStatus) {
            this.setVideo(
                this.currentMeetingInfo.jiomeetId,
                this.currentMeetingInfo.room_id,
                this.localParticipant?.participantId,
                this.localParticipant.cameraStatus
            );
        }
    }

    checkForAudioSoftMute() {
        if (this.roomInfo.isSoftMute) {
            this.disableAudioSoftMute();
        }
    }

    disableAudioSoftMute() {
        if (!this.roomInfo.localParticipant.isCoHost && !this.roomInfo.localParticipant.isHost) {
            return;
        }
        let payload = {
            jiomeetId: this.currentMeetingInfo.jiomeetId,
            roomID: this.currentMeetingInfo.room_id,
            audio: true,
            hard: false
        };
        this.muteUnmuteAudioAll(payload).subscribe(() => {});
    }

    getLocalParticipant() {
        return this.localParticipant;
    }

    private sendRoomConnectionStatus(recordSyncRequired = false) {
        this.roomInfo['recordSyncRequired'] = recordSyncRequired;
        this.roomConnectionStatus$.next(this.roomInfo);
    }

    updateConnectionStatusWithToken(
        {
            jiomeetId,
            roomID,
            roomPin,
            historyId,
            status,
            participantUri,
            participantId,
            participantName,
            micStatus,
            cameraStatus,
            isHandRaise = false,
            deviceId = this.systemService.getDeviceUUID(),
            deviceType = this.systemService.getClientDeviceType()
        },
        jwt
    ) {
        const isAuthenticated = this.authService.getIsAuthenticated();
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.set('Authorization', jwt);
        return this.restService.post(
            `api/roomconnectionstatus/guest`,
            {
                jiomeetId,
                roomID,
                roomPin,
                historyId,
                status,
                deviceId,
                deviceType,
                participantUri,
                participantId,
                participantName,
                micStatus,
                cameraStatus,
                isHandRaise
            },
            { headers }
        );
    }

    private sortParticipantList(arr, sortkey = 'atoz') {
        if (sortkey === 'atoz') {
            return _.orderBy(
                arr,
                ['isHost', 'isHandRaise', (name) => name.participantName.toLowerCase()],
                ['desc', 'desc', 'asc']
            );
        }
        if (sortkey === 'ztoa') {
            return _.orderBy(
                arr,
                ['isHost', 'isHandRaise', (name) => name.participantName.toLowerCase()],
                ['desc', 'desc', 'desc']
            );
        }
        if (sortkey === 'camera') {
            return _.orderBy(
                arr,
                ['isHost', 'isHandRaise', 'cameraStatus', (name) => name.participantName.toLowerCase()],
                ['desc', 'desc', 'desc', 'asc']
            );
        }
        if (sortkey === 'mic') {
            return _.orderBy(
                arr,
                ['isHost', 'isHandRaise', 'micStatus', (name) => name.participantName.toLowerCase()],
                ['desc', 'desc', 'desc', 'asc']
            );
        }
        if (sortkey === 'chat') {
            return _.orderBy(
                arr,
                ['isHost', 'isHandRaise', 'chatStatus', (name) => name.participantName.toLowerCase()],
                ['desc', 'desc', 'desc', 'asc']
            );
        }
        return _.orderBy(
            arr,
            ['isHost', 'isHandRaise', (name) => name.participantName.toLowerCase()],
            ['desc', 'desc', 'asc']
        );
    }

    sendAudioLevel({ jiomeetId, roomID, roomPin, participantId, audioLevel }) {
        audioLevel = Math.floor(audioLevel);
        const isAuthenticated =
            this.authService.getIsAuthenticated() && !this.thirdpartyExternalIntegration.isHostTokenUser;
        return this.restService.post(`api/roomconnectionstatus/audiolevel${!isAuthenticated ? '/guest' : ''}`, {
            jiomeetId,
            roomID,
            roomPin,
            participantId,
            audioLevel
        });
    }

    setChatStatusForIndividual({ jiomeetId, chatStatus, participantId }) {
        return this.restService.post(`api/roomconnectionstatus/control/chat/participant`, {
            jiomeetId,
            chatStatus,
            participantId
        });
    }

    setChatStatusForAllUsers({ jiomeetId, restrictChat }) {
        return this.restService.post(`api/roomconnectionstatus/control/chat`, {
            jiomeetId,
            restrictChat
        });
    }

    setMeetingInfo(meetingInfo) {
        this.currentMeetingInfo = meetingInfo;
    }

    getMeetingInfo() {
        return this.currentMeetingInfo;
    }

    setLocalWhiteboardState(state) {
        this.roomInfo.whiteboardshared = state;
        this.sendRoomConnectionStatus();
    }

    requestShareControl({ jiomeetId, controlParticipantId, shareParticipantId }) {
        return this.restService.post(`api/roomconnectionstatus/remoteControl/request`, {
            jiomeetId,
            controlParticipantId,
            shareParticipantId
        });
    }

    sendShareControl({
        jiomeetId,
        shareParticipantId,
        controlParticipantId,
        shareAccepted,
        height,
        width,
        scaleFactor
    }) {
        let headers: HttpHeaders = new HttpHeaders();
        let jwt = this.authService.getAuthInfo().jwt;
        headers = headers.set('Authorization', jwt);
        headers = headers.set('X-Device-Key', this.jioCloudAuthService.deviceID);
        return this.restService.post(
            `api/roomconnectionstatus/remoteControl/share`,
            {
                jiomeetId,
                shareParticipantId,
                controlParticipantId,
                shareAccepted,
                height,
                width,
                scaleFactor
            },
            { headers }
        );
    }

    stopShareControl({ jiomeetId, participantId, shareParticipantId }) {
        let headers: HttpHeaders = new HttpHeaders();
        let jwt = this.authService.getAuthInfo().jwt;
        headers = headers.set('Authorization', jwt);
        return this.restService.post(
            `api/roomconnectionstatus/remoteControl/stop`,
            {
                jiomeetId,
                participantId,
                shareParticipantId
            },
            { headers }
        );
    }
}
