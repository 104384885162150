import { Injectable, Inject, isDevMode } from '@angular/core';

import { LocalStorageService } from './local-storage.service';

import { environment } from './../../../environments/environment';
import 'url-search-params-polyfill';
import { BehaviorSubject, Subject } from 'rxjs';
import { DesktopAppService } from 'src/app/core';
import { APP_CONSTANT } from 'src/app/constants';

declare const window: any;

@Injectable()
export class AppService {
    private isWhiteBoardShared = false;
    private userInfo: any = {};
    private jmmediaStreamUserInfo: any = {};
    private appQueryParams: URLSearchParams;
    config: any = {};
    private currentJiomeetId = '';
    private apm: any;
    private recorderUser = false;
    private joinsFromDashboard = false;
    private joinsFromChat = false;
    isPageRefreshed = false;
    private initChatInfo = {
        name: '',
        lname: '',
        unreadCount: 0,
        isGroupThread: false,
        _id: '',
        isFavourite: false,
        lastUnReadMessageId: ''
    };
    private initContactsInfo = {
        name: '',
        lname: '',
        isGroupThread: false,
        _id: '',
        isFavourite: false
    };

    private isFavouritesUpdated = false;
    private unreadCount = 0;
    public audioOnlyMode = false;
    public isScreenShareInitiated = false;
    public showInCallBanner = false;
    public audioOnlyModeEnabledFromAlerts = false;
    public cameraOnBeforeAudioOnlyMode = false;
    public inCallBannerType = '';
    public isCallInitiatedByChat: boolean = false;
    public chatCallCameraState = false;
    public chatCallUsers = [];
    public chatCallGroupId = '';
    public speakerLayoutOnBeforeScreenShare: boolean;
    public isCallInitiatedByContacts: boolean = false;
    public contactCallUsers = [];
    public contactCallGroupId = '';
    public contactCallCameraState = false;
    public enabledNoiseSuppression = false;
    public isCallInitiatedByGlobalSearch: boolean = false;
    public globalSearchGroupId = '';
    public globalSearchCallUsers = [];
    public globalSearchCallCameraState = false;
    public isCallRecording = false;
    public isRecordingLoading = false;
    public isRecordingInSync = true;
    public historyId = '';
    public guestUserId = '';
    public callMemberId = '';
    public liveStreamId = '';
    public meetingId = '';
    public isInisideIframe: boolean = false;

    showSafariSpeakersToast = new Subject<boolean>();
    openSettingsView = new Subject<boolean>();
    changeMyStatus = new Subject<boolean>();
    closeProfileMenu = new Subject<boolean>();
    statusSelf = new Subject<boolean>();
    recordingStatus = new BehaviorSubject<boolean>(false);
    autoStopRecordingInfo = { enabled: false, elementId: 'autostoprecording' };

    constructor(private localStorageService: LocalStorageService, private desktopAppService: DesktopAppService) {
        this.isInisideIframe = window.self !== window.top;
    }

    setApm(x) {
        this.apm = x;
    }

    getApm() {
        return this.apm;
    }

    setRecorderUser(x) {
        this.recorderUser = x;
    }

    setAutoStopRecordingInfo(info: { enabled: boolean; elementId?: string }) {
        this.autoStopRecordingInfo = { ...this.autoStopRecordingInfo, ...info };
    }
    getAutoStopRecordingInfo() {
        return this.autoStopRecordingInfo;
    }

    getRecorderUser() {
        return this.recorderUser;
    }

    setUserInfo(info) {
        this.userInfo = info;
        this.localStorageService.addItem('userInfo', info);
    }

    getUserInfo() {
        return this.localStorageService.getItem('userInfo') || this.userInfo;
    }

    isJmStatsEnable() {
        if (this.isInisideIframe || !localStorage) {
            return false;
        }
        const value = localStorage.getItem('debug');
        const hostURL = this.getEnvVariable('HOST_URL');
        if (value === 'jio-media-client:*' || hostURL.includes('prestage')) {
            return true;
        } else {
            false;
        }
    }
    setIswhiteBoardShared(isShared) {
        this.isWhiteBoardShared = isShared;
    }
    getIswhiteBoardShared() {
        return this.isWhiteBoardShared;
    }
    setCallRecordingStatus(status) {
        if (status !== this.isCallRecording) {
            this.isCallRecording = status;
            this.recordingStatus.next(status);
        }
    }

    getEnvironment() {
        if (this.desktopAppService?.isDesktopApp && this.desktopAppService.selectedEnvironment) {
            if (this.desktopAppService.selectedEnvironment.value === 'rc') {
                return this.desktopAppService.rcEnvironmentConfig;
            } else if (this.desktopAppService.selectedEnvironment.value === 'prestage') {
                return this.desktopAppService.prestageEnvironmentConfig;
            } else if (this.desktopAppService.selectedEnvironment.value === 'prod') {
                return this.desktopAppService.prodEnvironmentConfig;
            }
        }
        return environment;
    }

    getEnvVariable(param, backendServer?) {
        if (!isDevMode()) {
            return this.getEnvironment()[param];
        }
        return environment[param];
    }

    setConfig(config) {
        this.config = config;
    }

    getConfigVariable(param) {
        return this.config[param];
    }

    setCurrentCallJiomeetID(jiomeetid) {
        this.currentJiomeetId = jiomeetid;
    }

    getCurrentCallJiomeetID() {
        return this.currentJiomeetId;
    }

    logout() {
        this.setUserInfo({});
    }

    setAppQueryParams(params: URLSearchParams) {
        this.appQueryParams = params;
    }

    getAppQueryParams(): URLSearchParams {
        return this.appQueryParams;
    }

    fetchBuildVersion() {
        if (this.desktopAppService.isDesktopApp) {
            return this.desktopAppService.electronAppVersion;
        }
    }

    getAndSaveUserPreferredLang(lang = null) {
        if (lang) {
            this.localStorageService.addItem('userLang', lang);
            return lang;
        }

        return this.localStorageService.getItem('userLang') || 'en';
    }

    get isOnLine() {
        return window.navigator.onLine;
    }

    setJoinedFromDashboard() {
        this.joinsFromDashboard = true;
    }

    getJoinedFromDashboard() {
        return this.joinsFromDashboard;
    }

    resetJoinedFromDashboard() {
        this.joinsFromDashboard = false;
    }

    setJoinedFromChat() {
        this.joinsFromChat = true;
    }

    getJoinedFromChat() {
        return this.joinsFromChat;
    }

    resetJoinedFromChat() {
        this.joinsFromChat = false;
    }

    setInitChatInfo(info) {
        this.initChatInfo = info;
    }

    getInitChatInfo() {
        return this.initChatInfo;
    }

    resetChatInfo() {
        this.initChatInfo = {
            name: '',
            lname: '',
            unreadCount: 0,
            isGroupThread: false,
            _id: '',
            isFavourite: false,
            lastUnReadMessageId: ''
        };
    }

    setFavouritesUpdated() {
        this.isFavouritesUpdated = true;
    }

    getFavouritesUpdated() {
        return this.isFavouritesUpdated;
    }

    resetFavouritesUpdated() {
        this.isFavouritesUpdated = false;
    }

    setUnreadCount(count) {
        this.unreadCount = count;
    }

    getUnreadCount() {
        return this.unreadCount;
    }

    resetChatCallInfo() {
        this.isCallInitiatedByChat = false;
        this.chatCallUsers = [];
        this.chatCallGroupId = '';
    }

    resetContactsCallInfo() {
        this.isCallInitiatedByContacts = false;
        this.contactCallUsers = [];
        this.contactCallGroupId = '';
    }

    resetContactsInfo() {
        this.initContactsInfo = {
            name: '',
            lname: '',
            isGroupThread: false,
            _id: '',
            isFavourite: false
        };
    }

    resetGlobalSearchCallInfo() {
        this.isCallInitiatedByGlobalSearch = false;
        this.globalSearchCallUsers = [];
        this.globalSearchGroupId = '';
    }

    setSafariSpeakersToast(state) {
        this.showSafariSpeakersToast.next(state);
    }

    set setIsNoiseSuppression(value: boolean) {
        this.enabledNoiseSuppression = value;
    }

    get getIsNoiseSuppression() {
        return this.enabledNoiseSuppression;
    }
    setJmmediaStreamUser(x) {
        this.jmmediaStreamUserInfo = x;
    }
    getJmmediaStreamUser() {
        return this.jmmediaStreamUserInfo;
    }
    isDesktopApp() {
        return this.desktopAppService.isDesktopApp;
    }
    reloadWebContent() {
        if (this.isDesktopApp) this.desktopAppService.reloadWebContent();
        else window.location.reload();
    }
    isDomain() {
        return this.desktopAppService.domainName != null;
    }

    setHistoryId(historyId) {
        this.historyId = historyId;
    }

    setGuestUserId(guestUserId) {
        this.guestUserId = guestUserId;
    }

    setCallMemberId(callMemberId) {
        this.callMemberId = callMemberId;
    }

    setLiveStreamId(liveStreamId) {
        this.liveStreamId = liveStreamId;
    }

    setMeetingId(meetingId) {
        this.meetingId = meetingId;
    }

    setUserAvailability(status) {
        this.localStorageService.addItem('userStatus', status);
        this.statusSelf.next(status);
    }

    getUserAvailability() {
        return this.localStorageService.getItem('userStatus') || APP_CONSTANT.AVAILABLE;
    }

    openSettings(state) {
        this.openSettingsView.next(state);
    }

    closeProfileSettingsMenu() {
        this.closeProfileMenu.next(true);
    }

    setAvailabilityStatusFromServer(status) {
        this.localStorageService.addItem('userStatus', status);
        this.changeMyStatus.next(status);
    }
}
