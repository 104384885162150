import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-address-slider',
    templateUrl: './address-slider.component.html',
    styleUrls: ['./address-slider.component.scss']
})
export class AddressSliderComponent implements OnInit {
    @Input() addressInfo: any;

    constructor() {}
    ngOnInit(): void {}
}
