import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    AfterViewInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

import { combineLatest, Subscription } from 'rxjs';
import { pluck, switchMap, tap } from 'rxjs/operators';

import * as FileSaver from 'file-saver';

import { ResizedEvent } from 'angular-resize-event';

import { ToastrService } from 'ngx-toastr';

import { ConfirmationService } from 'primeng/api';
import { find } from 'lodash-es';
import {
    AppService,
    ChatService,
    EventEmitterService,
    SocketEvent,
    ComponentFactoryService,
    JioCloudUtilService,
    JiocloudAuthService,
    JiocloudService,
    RoomConnectionService,
    UserService,
    UtilService,
    SocketService,
    GuestAuthService,
    RtcService,
    DesktopAppService
} from 'src/app/core';

import { APP_CONSTANT, APP_EVENTS, JIO_CLOUD } from 'src/app/constants';

import { HostDirective } from 'src/app/shared';

import { AttachmentListComponent } from '../attachment-list/attachment-list.component';
import { ChatMarkdownComponent } from '../chat-markdown/chat-markdown.component';
import { ChatToastComponent } from '../chat-toast/chat-toast.component';
import { TranslateService } from '@ngx-translate/core';
import * as sanitizeHtml from 'sanitize-html';

declare var $: any;
declare const TurndownService: any;

@Component({
    selector: 'app-private-chat-v2',
    templateUrl: './private-chat-v2.component.html',
    styleUrls: ['./private-chat-v2.component.scss']
})
export class PrivateChatV2Component implements OnInit, AfterViewInit, OnDestroy {
    @Input() recipient;
    @Input() senderId;
    @Input() popout;
    @Input() enabled;
    @Input() isAuthenticated;
    @Input() jiomeetId;

    @Output() afterClose: EventEmitter<any> = new EventEmitter();

    @ViewChild('messages', { static: true }) messagesEle: ElementRef;
    @ViewChild('downloadAnchor') downloadAnchor: ElementRef;
    @ViewChild('privateAttachmentIcon') attachmentIcon: ElementRef;
    @ViewChild('privateUpload') openFileMenu: ElementRef;
    @ViewChild(ChatMarkdownComponent) private chatMarkdownComponent: ChatMarkdownComponent;
    @ViewChild(HostDirective, { static: true }) viewHost: HostDirective;
    @ViewChild('box') input: ElementRef;

    selectedEmoji = false;
    messages = [];
    messageToSend;
    currentThreadId;
    showEmojiBar = this.appService.getConfigVariable('SHOW_EMOJI_BAR');
    enableMarkdown = this.appService.getConfigVariable('SHOW_MARKDOWN');

    currentUser: any = {};
    guestUser: any = {};
    chatGroupsByDate: any[] = [];
    attachmentList: any[] = [];
    showAttachments;
    uploadedItems: any = {};
    subscriptions: Subscription[] = [];
    loggerTrigger = false;
    loggerObject: { name: string; _id: string };
    eventMessageType;
    roomStatus: any;
    dropdown: boolean;
    roomParticipants: any[];
    unreadChatParticipantIds: any[] = [];
    enableJioCloud;
    enableDownloadPreview;
    showMarkdown = false;
    showMoreOptions = false;
    turnDownService = new TurndownService();

    private previousScrollHeight;
    private timeouts = [];
    room;
    isChatDisabled: boolean = false;
    deletedMessages: any[] = [];
    isMsgDeleted: boolean = false;
    messageId: any;
    show_options: boolean = false;
    mouse_hover_options: boolean = false;
    show_delete: boolean = false;
    currentTime: any;
    justDeleted: boolean = false;
    showWhiteDots: boolean = false;

    constructor(
        private appService: AppService,
        private chatService: ChatService,
        private jioCloudService: JiocloudService,
        private jioCloudUtilService: JioCloudUtilService,
        private eventEmitterService: EventEmitterService,
        private zone: NgZone,
        private toastrService: ToastrService,
        private jiocloudAuthService: JiocloudAuthService,
        private confirmationService: ConfirmationService,
        private host: ElementRef<HTMLElement>,
        private factoryService: ComponentFactoryService,
        private roomConnectionService: RoomConnectionService,
        private compFactory: ComponentFactoryResolver,
        private userService: UserService,
        private utilService: UtilService,
        private socketService: SocketService,
        private guestUserService: GuestAuthService,
        private rtcService: RtcService,
        private translateService: TranslateService
    ) {
        this.enableJioCloud = this.userService.isJioCloudEnabled();
        this.enableDownloadPreview = this.appService.isDesktopApp();
        this.addTurndownRules();
    }

    get container(): HTMLElement {
        return this.host.nativeElement.querySelector('.private-chat-container') as HTMLElement;
    }

    close() {
        this.container.style.animation = 'chatOut .3s';
    }
    toGroupChat() {
        this.afterClose.emit();
    }
    toAnotherPrivateChat(loggerObject, event?: MouseEvent) {
        if (loggerObject?._id === this.recipient._id) {
            event.preventDefault();
            return;
        }
        this.chatService.clearNotifiedParticipants(this.recipient?._id);
        this.chatService.clearNotifiedParticipants(loggerObject?._id);
        this.afterClose.emit(loggerObject);
    }

    ngOnInit() {
        this.room = this.rtcService.getRoomInfo();
        this.chatService.clearNotifiedParticipants(this.recipient?._id);
        this.currentUser = this.userService.getUserSync();
        this.guestUser = this.guestUserService.getAuthInfo();
        this.subscriptions.push(
            this.chatService.getNotifiedParticipants().subscribe((value) => {
                this.unreadChatParticipantIds = value;
            }),
            this.chatService
                .getPrivateChatThread([this.senderId, this.recipient._id], this.senderId, [this.senderId])
                .pipe(
                    pluck('_id'),
                    tap((id) => (this.currentThreadId = id)),
                    switchMap((id) =>
                        combineLatest([
                            this.chatService.getChatAttachments(this.currentThreadId),
                            this.chatService.getPrivateChatMessages(id)
                        ])
                    )
                )
                .subscribe(([attachments, threadData]) => {
                    this.attachmentList = (attachments.messages || []).filter((msg) => msg.type === 'message');
                    const chats = (threadData.messages || []).filter((msg) => msg.type === 'message');
                    this.chatGroupsByDate = this.chatService.groupChatMessagesByDate(chats);
                    this.scrollToBottom();
                }),
            this.socketService.dataEvents$.subscribe(this.handleMeetingEvents.bind(this))
        ),
            this.roomConnectionService.getRoomConnectionStatus$().subscribe((res) => {
                this.zone.run(() => {
                    if (res) {
                        this.roomStatus = res;
                        this.isChatDisabled = !this.roomStatus?.localParticipant?.chatStatus;
                        this.setRoomParticipats(this.roomStatus.participants);
                    }
                });
            });
        this.deletedMessages = [];
    }

    ngAfterViewInit() {
        this.previousScrollHeight = this.messagesEle.nativeElement.scrollTop;
    }

    addTurndownRules() {
        this.turnDownService.addRule('strikethrough', {
            filter: ['del', 's', 'strike'],
            replacement: (content) => {
                return '~~' + content + '~~';
            }
        });
        this.turnDownService.addRule('bold', {
            filter: ['b', 'strong'],
            replacement: (content) => {
                return '**' + content + '**';
            }
        });
        this.turnDownService.addRule('italic', {
            filter: ['i', 'em'],
            replacement: (content) => {
                return '*' + content + '*';
            }
        });
        this.turnDownService.addRule('underline', {
            filter: ['u', 'ins'],
            replacement: (content) => {
                return '__' + content + '__';
            }
        });
    }

    public get userID() {
        return this.currentUser?._id || this.guestUser?.guestUserId;
    }

    handleMeetingEvents(event: SocketEvent) {
        this.zone.run(() => {
            switch (event.event) {
                case 'chatMessage':
                    if (event?.data?.type === 'message' && event?.data?.jiomeetId === this.jiomeetId) {
                        if (event.data?.context?.startsWith('u-') || event.data?.context?.startsWith('gu-')) {
                            if (this.currentThreadId === event.data?.threadId) {
                                this.chatGroupsByDate = this.chatService.appendRecievedMessage(
                                    this.chatGroupsByDate,
                                    event.data
                                );
                                this.scrollToBottom(this.userID === event.data.createdBy);
                            } else {
                                this.chatService.updateNotifiedParticipants(event.data.createdBy);
                                this.loggerObject = {
                                    _id: event.data.createdBy,
                                    name: event.data.creatorsName
                                };
                                setTimeout(() => {
                                    this.generateToast(this.loggerObject, 'PRIVATE_CHAT');
                                }, 300);
                            }
                        } else {
                            this.generateToast({ _id: null, name: null }, 'GROUP_CHAT');
                        }
                    }
                    break;
                case 'chatMessageUpdate':
                    this.isMsgDeleted = true;
                    this.deletedMessages.push(event.data.messageIdsToBeUpdated[0]);
                    break;
            }
        });
    }

    getProfilePic(userId) {
        return this.utilService.getProfilePic(userId);
    }

    toggleDeleteImage() {
        this.showWhiteDots = true;
    }

    toggleDeleteImageAgain() {
        this.showWhiteDots = false;
    }

    toggleDropdown() {
        this.dropdown = !this.dropdown;
        setTimeout(() => {
            if (this.dropdown) {
                this.setDropdownHeight();
            }
            return;
        }, 50);
    }

    setDropdownHeight() {
        let heightDiff =
            (document.getElementsByClassName('private-chat-container')[0] as HTMLElement).offsetHeight -
            (document.getElementsByClassName('attachments')[0] as HTMLElement).offsetHeight;

        let elem = document
            .getElementsByClassName('participants-dropdown')[1]
            .getElementsByClassName('dropdown')[0] as HTMLElement;

        if (elem) {
            elem.style.maxHeight = `${heightDiff}px`;
        }
    }

    setRoomParticipats(participants) {
        let obj = {};
        for (let i = 0; i < participants.length; i++) {
            obj[participants[i].userId] = participants[i];
        }
        const usersArray = [];
        for (var key in obj) {
            usersArray.push(obj[key]);
        }
        this.roomParticipants = this.utilService.clone(usersArray);
        this.roomParticipants.sort((p1, p2) => {
            const p1UnreadSender = this.unreadChatParticipantIds.includes(p1.userId);
            const p2UnreadSender = this.unreadChatParticipantIds.includes(p2.userId);
            return p1UnreadSender === p2UnreadSender ? 0 : p1UnreadSender ? -1 : 1;
        });
        // const firstNonSenderIndex = this.roomParticipants.findIndex(
        //   (p) => !this.unreadChatParticipantIds.includes(p.userId)
        // );
        // this.roomParticipants.splice(firstNonSenderIndex, 0, { participantName: 'All', userId: null });
        this.roomParticipants.unshift({ participantName: 'All', userId: null });
    }

    getAttachments() {
        return this.chatService.getChatAttachments(this.currentThreadId).subscribe((data) => {
            this.attachmentList = (data.messages || []).filter((msg) => msg.type === 'message');
        });
    }

    deleteItem(message) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete this file?',
            header: 'Delete File',
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            acceptButtonStyleClass: 'custom-button bg-primary',
            rejectButtonStyleClass: 'custom-button bg-secondary',
            accept: () => {
                this.chatService
                    .deleteFromJioMeetAndJioCloud(
                        message.threadId,
                        message._id,
                        message.messageComponent.attachments[0].fileID,
                        this.currentThreadId
                    )
                    .subscribe((resp) => {
                        this.getAttachments();
                    });
            },
            reject: () => {}
        });
        this.utilService.setTimeOutForAccessibilityPopUp();
    }

    fileInfo(message) {
        this.eventEmitterService.emit({
            type: APP_EVENTS.OPEN_FILE_INFO,
            data: {
                name: message.messageComponent.attachments[0].fileName,
                size: message.messageComponent.attachments[0].fileSize,
                sharedBy: `${message.creatorsName} ${message.creatorsLName}`,
                createdOn: message.cOn
            }
        });
    }

    downloadFile(message, event?) {
        if (this.chatService.activeChatMembers?.length > 0) {
            let creator = find(this.chatService.activeChatMembers, ['_id', message?.createdBy]);
            if (this.utilService.canDownloadOrPreviewAttachments(creator, this.currentUser)) {
                this.toastrService.error(this.translateService.instant('tostrs.downloading_a_file_is_not_allowed'));
                if (event) {
                    event.stopPropagation();
                }
                return;
            }
        }
        this.toastrService.success(this.translateService.instant('tostrs.downloading_in_progress'));
        const files = message.messageComponent.attachments;
        (files || []).map((file) => {
            this.jioCloudService
                .downloadFile(file.fileName, file.downloadURL, this.currentThreadId)
                .subscribe((resp) => {
                    FileSaver.saveAs(resp, file.fileName);
                    this.toastrService.success(this.translateService.instant('tostrs.download_completed'));
                });
        });
    }

    onResized(event: ResizedEvent) {
        $('.messages').css('bottom', `${$('.private-attachments').height()}px`);
    }

    trackByFuntion(index, item) {
        return index;
    }

    trackMessages(index, item) {
        return item._id;
    }

    sendMessage(event: KeyboardEvent, message1) {
        if (this.isChatDisabled) {
            return;
        }
        let message = '';
        if (this.chatMarkdownComponent?.quill?.container?.innerText?.trim()) {
            message = this.turnDownService.turndown(this.chatMarkdownComponent?.quill?.container?.innerHTML);
            message = sanitizeHtml(message, {
                allowedTags: ['b', 'i', 'em', 'strong', 'a', 's', 'ul', 'ol', 'li']
            });
            message = message.replace('[](about:blank)', '').trim();
            message = message.replace(/\\_/g, '_');
            message = message.replace('\\>', '>');
        }
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }

        if (message?.length >= APP_CONSTANT.CHAT_MESSAGE_LIMIT) {
            this.toastrService.error(this.translateService.instant('tostrs.character_limit_exceeded'));
            return;
        }

        if (this.isUploadInProgress()) {
            this.toastrService.error(this.translateService.instant('tostrs.please_wait_uploading_is_in_progress'));
            return;
        }

        // const isInCall = this.roomStatus?.participants.find((p) => p.userId === this.recipient._id);
        // if (!isInCall) {
        //   this.toastrService.clear();
        //   this.toastrService.error(`${this.recipient?.name} is not available`, '', {
        //     positionClass: 'toast-center-center',
        //     toastClass: 'inside-call-chat-toast'
        //   });
        //   return;
        // }

        if (message?.trim().length || Object.keys(this.uploadedItems).length) {
            const attachments = Object.keys(this.uploadedItems).map((attachmentObj: any) => {
                return {
                    imageTrancodedURL: this.uploadedItems[attachmentObj].meetingImageTranscodeUrl || '',
                    streamingURL: this.uploadedItems[attachmentObj].meetingPlaybackUrl || '',
                    documentURL: this.uploadedItems[attachmentObj].meetingDocumentUrl || '',
                    downloadURL: this.uploadedItems[attachmentObj].meetingFileUrl || '',
                    fileName: this.uploadedItems[attachmentObj].objectName,
                    fileID: this.uploadedItems[attachmentObj].objectKey,
                    fileSize: '' + this.uploadedItems[attachmentObj].size
                };
            });
            this.chatService
                .sendPrivateMessage(this.currentThreadId, this.jiomeetId, message?.trim(), attachments)
                .subscribe((res: any) => {
                    this.isAuthenticated && this.enableAttachmentIcon();
                    if (res.success === false) {
                        this.toastrService.error(res.error.errors);
                        return;
                    }
                    if (res && res.messageComponent && res.messageComponent.attachments.length) {
                        this.attachmentList.unshift(res);
                    }
                    this.showMarkdown = false;
                    this.messageToSend = '';
                    this.chatMarkdownComponent.clearMeessage();
                    this.uploadedItems = {};
                    if (this.input) {
                        this.input.nativeElement.value = null;
                    }
                });
        } else {
            this.toastrService.error(this.translateService.instant('tostrs.please_type_a_message_to_continue'));
            this.chatMarkdownComponent.clearMeessage();
        }
    }

    switchToAttachmentSection() {
        const component = this.compFactory.resolveComponentFactory(AttachmentListComponent);
        const componentRef = this.viewHost.viewContainerRef.createComponent(component);
        componentRef.instance.threadId = this.currentThreadId;
        componentRef.instance.afterClose.subscribe((res) => {
            componentRef.destroy();
        });
    }

    disableAttachmentIcon() {
        if (this.attachmentIcon) {
            this.attachmentIcon?.nativeElement.setAttribute('class', 'disabled');
        }
        if (this.openFileMenu) {
            this.openFileMenu?.nativeElement.setAttribute('disabled', true);
        }
    }

    toggleEmojis(event) {
        this.selectedEmoji = !this.selectedEmoji;
    }

    toggleMoreOptions(message) {
        // this.showMoreOptions = !this.showMoreOptions;
        message.showMoreOptions = !message.showMoreOptions;
    }

    clickOutside(message) {
        message.showMoreOptions = false;
    }

    handleChatMartkdownOutput(event) {
        this.messageToSend = event;
    }

    enableAttachmentIcon() {
        if (this.attachmentIcon) {
            this.attachmentIcon?.nativeElement.setAttribute('class', '');
        }
        if (this.openFileMenu) {
            this.openFileMenu?.nativeElement.removeAttribute('disabled');
        }
    }

    isUploadInProgress() {
        return Object.keys(this.uploadedItems).filter(
            (attachmentObj: any) => !this.uploadedItems[attachmentObj].uploadSuccess
        ).length;
    }

    isFileSupported(file) {
        return this.jioCloudUtilService.getFileFormat(file.name) !== 'None';
    }

    fileChangeEvent(event) {
        if (!this.isFileSupported(event.target.files[0])) {
            this.confirmationService.confirm({
                message: 'File type not supported',
                header: 'Alert',
                acceptLabel: 'OK',
                rejectVisible: false,
                accept: () => {},
                reject: () => {}
            });
            this.utilService.setTimeOutForAccessibilityPopUp();
            return;
        }
        this.disableAttachmentIcon();
        if (event.target.files.length) {
            this.uploadFiles(event.target.files);
        }
    }
    uploadFiles(files) {
        const file = files[0];
        if (file.size <= JIO_CLOUD.MAX_FILE_SIZE_ALLOWED_FOR_UPLOAD) {
            file.unique = new Date().valueOf();
            file.totalSize = this.jioCloudUtilService.calculateSize(file.size);
            this.uploadedItems[file.unique] = file;
            if (file.size > JIO_CLOUD.SMALL_FILE_SIZE_LIMIT) {
                this.chunkUpload(file);
            } else {
                this.singleFileUpload(file);
            }
        } else {
            this.enableAttachmentIcon();
            this.showMaxFileSizeAlert();
        }
    }

    private async singleFileUpload(file) {
        try {
            file.hash = await this.jioCloudUtilService.getFileHash(file);
            this.jioCloudService
                .singleFileUpload({
                    file,
                    fileMetadata: this.getMetaData(file),
                    meetingId: this.currentThreadId
                })
                .subscribe(
                    (event: HttpEvent<any>) => {
                        switch (event.type) {
                            case HttpEventType.UploadProgress:
                                this.uploadedItems[file.unique].progress = Math.round(
                                    (event.loaded / event.total) * 100
                                );
                                this.uploadedItems[file.unique].uploadedBytes = this.jioCloudUtilService.calculateSize(
                                    event.loaded
                                );
                                break;
                            case HttpEventType.Response:
                                const metaData = this.jioCloudUtilService.setMeataDataForUploadedFile(event.body);
                                Object.assign(this.uploadedItems[file.unique], {
                                    meetingImageTranscodeUrl: metaData.meetingImageTranscodeUrl,
                                    meetingPlaybackUrl: metaData.meetingPlaybackUrl,
                                    meetingDocumentUrl: metaData.meetingDocumentUrl,
                                    meetingFileUrl: metaData.meetingFileUrl,
                                    objectName: metaData.objectName,
                                    objectKey: metaData.objectKey
                                });
                                this.uploadedItems[file.unique].uploadSuccess = true;
                                break;
                        }
                    },
                    (err) => {
                        this.uploadFailedNotification(err?.error);
                    }
                );
        } catch (err) {
            this.uploadFailedAlert();
        }
    }

    private async chunkUpload(file) {
        try {
            file.hash = await this.jioCloudUtilService.getFileHash(file);
            this.jioCloudService
                .initiateUpload({
                    fileMetadata: this.getMetaData(file),
                    meetingId: this.currentThreadId
                })
                .subscribe(
                    (event: any) => {
                        switch (event.type) {
                            case HttpEventType.Response:
                                if (event.status === 200) {
                                    let response = event.body;
                                    this.uploadedItems[file.unique].uploadedBytes =
                                        this.jioCloudUtilService.calculateSize(response.offset);
                                    this.startChunkUpload(
                                        file,
                                        response.offset,
                                        JIO_CLOUD.FIRST_CHUNK_SIZE,
                                        response.transactionId,
                                        null,
                                        true
                                    );
                                }
                                if (event.status === 201) {
                                    // upload done, process next file
                                    // this.uploadedItems[
                                    //   file.unique
                                    // ].info = this.jioCloudUtilService.setMeataDataForUploadedFile(event.body);
                                    const metaData = this.jioCloudUtilService.setMeataDataForUploadedFile(event.body);
                                    Object.assign(this.uploadedItems[file.unique], {
                                        meetingImageTranscodeUrl: metaData.meetingImageTranscodeUrl,
                                        meetingPlaybackUrl: metaData.meetingPlaybackUrl,
                                        meetingDocumentUrl: metaData.meetingDocumentUrl,
                                        meetingFileUrl: metaData.meetingFileUrl,
                                        objectName: metaData.objectName,
                                        objectKey: metaData.objectKey
                                    });
                                }
                                this.uploadedItems[file.unique].uploadSuccess = true;
                                break;
                        }
                    },
                    (err) => {
                        this.uploadFailedNotification(err?.error);
                    }
                );
        } catch (err) {
            this.uploadFailedAlert();
        }
    }

    uploadFailedNotification(error) {
        switch (error?.code) {
            case 'TEJUM409':
                this.toastrService.warning(
                    'You have exhausted the 5GB storage limit. Please delete the existing files to upload new one.'
                );
                break;
            default:
                this.toastrService.error(error?.error || 'Upload failed');
                break;
        }
    }

    private startChunkUpload(file, offset, chunkSize, transactionId, chunkUploadTime, isStart = false) {
        if (!isStart) {
            chunkSize = this.jioCloudUtilService.getChunkSize(chunkUploadTime, chunkSize, file.size, offset);
        }

        try {
            const chunkUploadStartTime = new Date().getTime();
            const chunk1 = File.prototype.slice.call(file, offset, offset + chunkSize);
            const reader = new FileReader();
            reader.onload = async (data: any) => {
                const chunk = data.target.result;
                const chunkChecksum = await this.jioCloudUtilService.getChunkCheckSum(chunk);
                this.jioCloudService
                    .uploadChunk(chunk, offset, chunkChecksum, transactionId, this.currentThreadId)
                    .subscribe(
                        (event: any) => {
                            switch (event.type) {
                                case HttpEventType.Response:
                                    if (event.status === 200) {
                                        const response = event.body;
                                        this.uploadedItems[file.unique].progress = Math.round(
                                            (response.offset / file.size) * 100
                                        );
                                        this.uploadedItems[file.unique].uploadedBytes =
                                            this.jioCloudUtilService.calculateSize(response.offset);
                                        this.startChunkUpload(
                                            file,
                                            response.offset,
                                            chunkSize,
                                            response.transactionId,
                                            new Date().getTime() - chunkUploadStartTime
                                        );
                                    }
                                    if (event.status === 201) {
                                        this.uploadedItems[file.unique].progress = 100;
                                        const metaData = this.jioCloudUtilService.setMeataDataForUploadedFile(
                                            event.body
                                        );
                                        Object.assign(this.uploadedItems[file.unique], {
                                            meetingImageTranscodeUrl: metaData.meetingImageTranscodeUrl,
                                            meetingPlaybackUrl: metaData.meetingPlaybackUrl,
                                            meetingDocumentUrl: metaData.meetingDocumentUrl,
                                            meetingFileUrl: metaData.meetingFileUrl,
                                            objectName: metaData.objectName,
                                            objectKey: metaData.objectKey
                                        });
                                    }
                                    this.uploadedItems[file.unique].uploadSuccess = true;
                                    break;
                            }
                        },
                        (err) => {
                            this.uploadFailedNotification(err?.error);
                        }
                    );
            };
            reader.onerror = (event) => {
                reader.abort();
            };
            reader.readAsArrayBuffer(chunk1);
        } catch (err) {
            throw err;
        }
    }
    resetLastSelectedFileValue(event) {
        event.target.value = '';
    }
    getMetaData(file) {
        return {
            name: file.name,
            size: file.size,
            hash: file.hash,
            folderKey: this.jiocloudAuthService.getAuthInfo().rootFolderKey
        };
    }
    showMaxFileSizeAlert() {
        this.confirmationService.confirm({
            message: this.translateService.instant('tostrs.maximum_file_size_allowed_is_30mb', {
                value: 30
            }),
            header: this.translateService.instant('tostrs.alert'),
            acceptLabel: this.translateService.instant('tostrs.ok'),
            rejectVisible: false,
            accept: () => {},
            reject: () => {}
        });
        this.utilService.setTimeOutForAccessibilityPopUp();
    }

    toggleAttachmentSection() {
        this.showAttachments = !this.showAttachments;
    }

    uploadFailedAlert() {
        this.confirmationService.confirm({
            message: this.translateService.instant('tostrs.file_uploading_failed'),
            header: this.translateService.instant('tostrs.alert'),
            acceptLabel: this.translateService.instant('tostrs.ok'),
            rejectVisible: false,
            accept: () => {},
            reject: () => {}
        });
        this.utilService.setTimeOutForAccessibilityPopUp();
    }

    handleRefreshListEvent(e) {
        this.getAttachments();
    }

    getAttachedFilesCount() {
        return [].concat.apply(
            [],
            this.attachmentList.map((msg) => msg.messageComponent.attachments)
        ).length;
    }

    removeAttchment(event, file) {
        delete this.uploadedItems[file.unique];
        this.enableAttachmentIcon();
    }
    generateToast(toastObject, type) {
        const component: ComponentRef<ChatToastComponent> = this.factoryService.generateComponent(
            this.viewHost,
            ChatToastComponent
        );
        component.instance.toastObject = toastObject;
        component.instance.messageType = type;
        component.instance.unreadParticipantCount = this.unreadChatParticipantIds.filter(
            (uId) => uId !== this.recipient?._id && uId !== this.senderId
        ).length;
        component.instance.closeEmitter.subscribe(() => component.destroy());
        component.instance.clickEmitter.subscribe((res) => {
            if (res.type === 'PRIVATE_CHAT') {
                this.toAnotherPrivateChat(this.loggerObject);
            } else if (res.type === 'GROUP_CHAT') {
                this.toGroupChat();
            } else if (res.type === 'NEW_MESSAGE') {
                this.scrollToBottom(true);
            }
            component.destroy();
        });
    }

    onOutsideClick(event) {
        if (this.dropdown) {
            this.dropdown = false;
        }
    }

    animationDone(event: AnimationEvent) {
        if (event.animationName === 'chatOut') {
            this.chatService.clearNotifiedParticipants(this.recipient?._id);
            this.afterClose.emit(true);
        }
    }

    // toggleMoreOptions() {
    //   this.showMoreOptions = !this.showMoreOptions;
    // }
    toggleMarkdown() {
        this.showMarkdown = !this.showMarkdown;
    }

    private scrollToBottom(force = false) {
        this.timeouts.push(
            setTimeout(() => {
                const ele = this.messagesEle.nativeElement;
                if (force) {
                    ele.scrollTop = ele.scrollHeight;
                } else {
                    if (this.previousScrollHeight - ele.scrollTop - ele.clientHeight < 10) {
                        ele.scrollTop = ele.scrollHeight;
                        this.previousScrollHeight = ele.scrollHeight;
                    } else {
                        this.generateToast({}, 'NEW_MESSAGE');
                    }
                }
            }, 300)
        );
    }

    getParticipantName(participant) {
        let name = participant.participantName;
        return participant.signIn === 'Guest'
            ? name.substring(0, name.length - 8) +
                  ' (' +
                  this.translateService.instant('inside_call.participants_controls.guest') +
                  ')'
            : participant.participantName;
    }

    getRecipientName(user) {
        let name = user.name;
        if (name.includes('Guest')) {
            return (
                name.substring(0, name.length - 8) +
                ' (' +
                this.translateService.instant('inside_call.participants_controls.guest') +
                ')'
            );
        } else {
            return user.name;
        }
    }

    isDesktopApp() {
        return this.appService.isDesktopApp();
    }

    showOptions(mode) {
        this.show_options = true;
        if (mode === 1) this.mouse_hover_options = true;
    }

    hideOptions(mode) {
        if (mode === 1) this.mouse_hover_options = false;
        if (!this.show_delete && !this.mouse_hover_options) {
            this.show_options = false;
            this.show_delete = false;
            this.messageId = '';
        }
    }
    show_Options(message) {
        if (!this.deletedMessages.includes(message._id) && message?._id !== this.messageId) {
            this.show_options = true;
            this.messageId = message._id;
            this.show_delete = false;
        }
    }

    resetSettings(message) {
        if (!this.mouse_hover_options && message._id === this.messageId) {
            this.show_options = false;
            this.show_delete = false;
            this.messageId = '';
        }
    }

    showDelete() {
        this.show_delete = true;
    }

    deleteMessagesFromChat(msg) {
        const threadId = msg?.threadId;
        const messageId = msg?._id;
        this.chatService.deleteMessagesFromChat(threadId, messageId).subscribe(
            (data) => {
                console.log('Message deletion success', data);
                this.deletedMessages.push(messageId);
                this.resetSettings(msg);
                this.isMsgDeleted = true;
                this.currentTime = new Date();
                this.justDeleted = true;
            },
            (err) => {
                console.error('Message deletion failure', err);
            }
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.timeouts.forEach((id) => clearTimeout(id));
    }
}
