import { Component, OnInit } from '@angular/core';

import { isBefore } from 'date-fns';

import { AppService } from 'src/app/core';

@Component({
    selector: 'app-agm-event',
    templateUrl: './agm-event.component.html',
    styleUrls: ['./agm-event.component.scss']
})
export class AgmEventComponent implements OnInit {
    showBanner = false;
    showJoin = true;
    constructor(private appService: AppService) {}

    ngOnInit() {
        if (this.checkLive()) {
            this.showJoin = true;
        }

        setInterval(() => {
            if (this.checkLive()) {
                this.showJoin = true;
            }
        });
    }

    checkLive() {
        // return new Date() >= new Date('15/07/2020 02:00:00 PM');
        return isBefore(new Date('2020-07-15 02:00:00 PM'), new Date());
    }

    close() {
        this.showBanner = false;
    }

    navToEvent() {
        window.open('https://jiomeet.jio.com/rilagm/');
    }
}
