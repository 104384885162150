import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { APP_EVENTS } from 'src/app/constants';
import {
    AppService,
    AuthService,
    EventData,
    EventEmitterService,
    UserService,
    UtilService,
    GoogleTagManagerService,
    SessionStorageService,
    LocalStorageService,
    RestService
} from 'src/app/core';
import {
    WEBSITE_ENTERPRISE_FEATURES,
    WEBSITE_ENTERPRISE_RETAIL_COMPARISION,
    PRICING_CARD,
    ADD_ONS
} from '../website/websiteConstant';
import { GA_EVENTS } from '../website/ga-events';
import { GA_PAGEVIEW } from '../website/ga-pageview';
import { ThisReceiver } from '@angular/compiler';
import { GA4_EVENTS } from '../website/ga4-events';
import { environment } from 'src/environments/environment';
import { data } from 'src/app/help/constants/help-sections';
import { ToastrService } from 'ngx-toastr';

//dynamic chargeIt
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { error } from 'console';
@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss'],
    providers: [ConfirmationService]
})
export class PricingComponent implements OnInit {
    @ViewChild('comparePriceHeader') comparePriceHeaderElement: ElementRef;
    @ViewChild('comparePriceHidden') comparePriceHiddenElement: ElementRef;
    @ViewChild('compareContainer') compareContainerElement: ElementRef;
    @ViewChild('scrollBodyContainer') scrollBodyContainerEle: ElementRef;

    isMobile = false;
    pricingCard = PRICING_CARD;
    addOns = ADD_ONS;
    websiteRetailEnterpriseCompare = WEBSITE_ENTERPRISE_RETAIL_COMPARISION;
    activateComparisonFixedHeader = false;
    priceHeaderFixedPos = 0;
    currentScrollDirection = 'down';
    prevScrollPos = 0;
    isAuthenticated = false;
    currentUser;
    planType = false;
    comboType = false;
    currentLang = '';
    isPlanComparisonOpen = false;
    planType_Monthly = true;
    activeLang: string;
    isLoading: boolean = false;
    exisitingPlusplan = true;
    newFlow: boolean = false;
    showPopup: boolean = false;
    userName: string = '';
    userEmail: string = '';
    paymentButtonDisabled: boolean = true;
    invalidPincode: boolean = false;
    city: string = '';
    state: string = '';
    pincode: string = '';
    showPincodeError: boolean = false;
    enableChargeItForAll: boolean = false;
    enableChargeIt: boolean = false;
    plusPlan: number;
    annualPlusPlan: number;
    pricingData = [];
    errorTxt = '';
    fieldsToShow = true;
    @Input() cardDetail: any;

    constructor(
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private authService: AuthService,
        private userService: UserService,
        private appService: AppService,
        private translateService: TranslateService,
        private translate: TranslateService,
        private router: Router,
        private config: PrimeNGConfig,
        private confirmationService: ConfirmationService,
        private gTagService: GoogleTagManagerService,
        private sessionStorageService: SessionStorageService,
        private activatedRoute: ActivatedRoute,
        private localStorageService: LocalStorageService,
        private restService: RestService,
        private http: HttpClient,
        private toastrService: ToastrService
    ) {}

    ngOnInit(): void {
        this.isMobile = this.utilService.isMobileBrowser(1080);
        let priceSecElem = null;
        let lastRowElement = null;
        this.activeLang = this.appService.getAndSaveUserPreferredLang();
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.PAGE_SCROLLED) {
                if (!priceSecElem) priceSecElem = document.getElementsByClassName('sec-3')[0];
                if (!lastRowElement) lastRowElement = document.getElementsByClassName('sec-divider')[18];
                const scrollTop = event.data?.target.scrollTop;
                this.currentScrollDirection = scrollTop > this.prevScrollPos ? 'down' : 'up';
                this.prevScrollPos = scrollTop;
                const priceSecElemY = priceSecElem?.getBoundingClientRect()?.y;
                const lastRowElementY = lastRowElement?.getBoundingClientRect()?.y;

                if (priceSecElemY < -63 && lastRowElementY > 115) {
                    this.fixPriceHeader(this.currentScrollDirection);
                } else {
                    this.removePosFixed({ status: true });
                }
            } else if (event.type === APP_EVENTS.PAGE_RESIZED) {
                this.isMobile = event.data.innerWidth >= 1080 ? false : true;
                this.handleResize();
            }
        });
        this.plusPlan = this.appService.getConfigVariable('PLUS_PLAN')
            ? this.appService.getConfigVariable('PLUS_PLAN')
            : 199;

        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
            this.currentUser = this.userService.getUserSync();
            this.userName = this.currentUser.name + ' ' + (this.currentUser.lname ? this.currentUser.lname : '');
            this.exisitingPlusplan =
                this.isAuthenticated &&
                this.currentUser.isOTT &&
                this.currentUser.proStatus &&
                this.currentUser.planName === 'plus';
        });
        this.currentLang = this.appService.getAndSaveUserPreferredLang();
        this.translateService.onLangChange.subscribe(() => {
            this.currentLang = this.appService.getAndSaveUserPreferredLang();
            this.activeLang = this.appService.getAndSaveUserPreferredLang();
            this.fetchPricingData();
        });
        this.gTagService.pushOnDataLayer(GA_PAGEVIEW.PRICING_PAGE_VIEW);
        this.enableChargeItForAll = this.appService.getConfigVariable('ENABLE_CHARGEIT_FOR_ALL')
            ? this.appService.getConfigVariable('ENABLE_CHARGEIT_FOR_ALL')
            : false;
        this.enableChargeIt = this.appService.getConfigVariable('ENABLE_CHARGEIT')
            ? this.appService.getConfigVariable('ENABLE_CHARGEIT')
            : false;
        this.newFlow =
            this.enableChargeIt && (this.activatedRoute.snapshot.queryParams?.newFlow || this.enableChargeItForAll);

        this.plusPlan = this.newFlow ? this.plusPlan : 199;
        this.annualPlusPlan = this.newFlow ? this.plusPlan * 12 : 2388;
        this.city = 'Your city';
        this.state = 'Your state';
        //dynamic chargeIt
        this.fetchPricingData();
    }

    handleResize() {
        const elem = this.comparePriceHeaderElement?.nativeElement as HTMLElement;
        const viewportElem = this.compareContainerElement?.nativeElement as HTMLElement;
        elem?.setAttribute('style', 'width: ' + viewportElem?.clientWidth + 'px');
    }

    togglePlanComparison() {
        try {
            this.isPlanComparisonOpen = !this.isPlanComparisonOpen;
            var x = document.getElementById('collapseComparison');
            if (x.style.display === 'none' || x.style.display === '') {
                x.style.display = 'block';
                this.gTagService.sendEvent(GA_EVENTS.PRICING_FULL_PLAN_SEE_COMPARISON_SUCCESS);
                this.gTagService.sendEvent(GA4_EVENTS.PRICING_FULL_PLAN_SEE_COMPARISON_SUCCESS);
            } else {
                x.style.display = 'none';
                this.gTagService.sendEvent(GA4_EVENTS.PRICING_FULL_PLAN_SEE_COMPARISON_SUCCESS);
            }
        } catch (err) {
            this.gTagService.sendEvent(GA_EVENTS.PRICING_FULL_PLAN_SEE_COMPARISON_FAILURE);
            this.gTagService.sendEvent(GA4_EVENTS.PRICING_FULL_PLAN_SEE_COMPARISON_FAILURE);
        }
    }

    fixPriceHeader(scrollDirection = 'down') {
        // check the top position of price element to be fixed
        const elem = this.comparePriceHeaderElement.nativeElement as HTMLElement;
        const clientY = elem.getBoundingClientRect().y;
        if (clientY <= 90) {
            const currViewPortWidth = elem.clientWidth;
            elem.classList.add('pos-fixed');
            elem.setAttribute('style', 'width: ' + currViewPortWidth + 'px');
            if (scrollDirection === 'up') elem.classList.add('scroll-up');
            this.activateComparisonFixedHeader = true;
            setTimeout(() => {
                const hiddenElem = this.comparePriceHiddenElement.nativeElement as HTMLElement;
                const clientY = hiddenElem.getBoundingClientRect().y;
                if (scrollDirection === 'down') this.priceHeaderFixedPos = clientY;
            }, 0);
        }
    }

    onScrollHeader(event) {
        this.scrollBodyContainerEle.nativeElement.scrollLeft = event?.srcElement.scrollLeft;
    }

    onScrollBody(event) {
        const elem = this.comparePriceHeaderElement.nativeElement as HTMLElement;
        this.comparePriceHeaderElement.nativeElement.scrollLeft = event?.srcElement.scrollLeft;
    }

    removePosFixed(data) {
        if (data.status) {
            const elem = this.comparePriceHeaderElement?.nativeElement as HTMLElement;
            elem?.classList.remove('pos-fixed');
            elem?.classList.remove('scroll-up');
            this.activateComparisonFixedHeader = false;
        }
    }

    changePlan() {
        this.planType_Monthly = !this.planType_Monthly;
        this.gTagService.sendEvent(GA_EVENTS.PRICING_CHANGE_PLAN);
    }

    togglePlanType() {
        this.planType = !this.planType;
    }

    goToSignup(event?) {
        this.router
            .navigate(['signup'])
            .then(() => {
                if (event == null) {
                    this.planType_Monthly
                        ? this.gTagService.sendEvent(GA_EVENTS.PRICING_SIGNUP_FOR_FREE_MONTHLY)
                        : this.gTagService.sendEvent(GA_EVENTS.PRICING_SIGNUP_FOR_FREE_ANNUALLY);
                    this.planType_Monthly
                        ? this.gTagService.sendEvent(GA4_EVENTS.PRICING_SIGNUP_FOR_FREE_MONTHLY)
                        : this.gTagService.sendEvent(GA4_EVENTS.PRICING_SIGNUP_FOR_FREE_ANNUALLY);
                } else {
                    this.gTagService.sendEvent(GA_EVENTS.PRICING_SIGNUP_FOR_FREE_SUCCESS);
                    this.gTagService.sendEvent(GA4_EVENTS.PRICING_SIGNUP_FOR_FREE_PLAN_COMPARISON);
                }
            })
            .catch((err) => {
                if (event != null) {
                    this.gTagService.sendEvent(GA_EVENTS.PRICING_SIGNUP_FOR_FREE_FAILURE);
                    this.planType_Monthly
                        ? this.gTagService.sendEvent(GA4_EVENTS.PRICING_SIGNUP_FOR_FREE_MONTHLY_FAILURE)
                        : this.gTagService.sendEvent(GA4_EVENTS.PRICING_SIGNUP_FOR_FREE_ANNUALLY_FAILURE);
                }
            });
    }

    goToContactUs() {
        this.router.navigate(['contact-us']);
    }

    redirectToTechJockey() {
        if (!this.isAuthenticated) {
            const returnUrl = '/pricing';
            this.router.navigate(['login'], { queryParams: { returnUrl } });
        } else {
            this.restService
                .get(`api/my_profile`, {
                    header: { Authorization: this.activatedRoute.snapshot.queryParams?.userToken }
                })
                .subscribe((currentUser) => {
                    this.userEmail = currentUser.email === undefined ? currentUser.phoneNo : currentUser.email;
                    window.open(`${environment.PAYMENT_GATEWAY_URL}?userKey=${this.userEmail}`, '_blank');
                });
        }
    }

    redirectToChargeIt() {
        if (!this.isAuthenticated) {
            const url = this.newFlow ? '/pricing?newFlow=true' : '/pricing';
            this.sessionStorageService.addItem('returnUrl', url);
            this.router.navigate(['login']);
            this.gTagService.sendEvent(GA4_EVENTS.BUY_NOW_COUNT_FAILED);
        } else {
            if (this.newFlow) {
                this.restService
                    .get(`api/my_profile`, {
                        header: { Authorization: this.activatedRoute.snapshot.queryParams?.userToken }
                    })
                    .subscribe((currentUser) => {
                        this.userName = currentUser.name + ' ' + (currentUser.lname ? currentUser.lname : '');
                        this.userEmail = currentUser.email === undefined ? currentUser.phoneNo : currentUser.email;
                        this.pincode = currentUser?.pincode;
                        this.city = currentUser?.city;
                        this.state = this.formatStateInput(this.currentUser?.state);
                        this.paymentButtonDisabled = this.pincode !== undefined ? false : true;
                        this.showPincodeError = false;
                        this.invalidPincode = false;
                        this.showPopup = true;
                        this.gTagService.sendEvent(GA4_EVENTS.BUY_NOW_COUNT_SUCCESS);
                    });
            } else {
                this.redirectToTechJockey();
            }
        }
    }

    goToContactSales(event, evtCode) {
        this.router
            .navigate(['contactsales'])
            .then(() => {
                if (evtCode == 1) {
                    this.choosePlanEvents(event);
                } else if (evtCode == 2) {
                    event?.target?.title === 'pro_plan.individual_plan'
                        ? this.gTagService.sendEvent(GA_EVENTS.PRICING_SMALL_BUSINESS_REQUEST_QUOTE_SUCCESS)
                        : event?.target?.title === 'enterprise_plan.individual_plan'
                        ? this.gTagService.sendEvent(GA_EVENTS.PRICING_ENTERPRISE_REQUEST_QUOTE_SUCCESS)
                        : this.gTagService.sendEvent(GA_EVENTS.PRICING_CUSTOM_REQUEST_QUOTE_SUCCESS);

                    event?.target?.title === 'pro_plan.individual_plan'
                        ? this.gTagService.sendEvent(GA4_EVENTS.PRICING_SMALL_BUSINESS_REQUEST_QUOTE_SUCCESS)
                        : event?.target?.title === 'enterprise_plan.individual_plan'
                        ? this.gTagService.sendEvent(GA4_EVENTS.PRICING_ENTERPRISE_REQUEST_QUOTE_SUCCESS)
                        : this.gTagService.sendEvent(GA4_EVENTS.PRICING_CUSTOM_REQUEST_QUOTE_SUCCESS);
                } else {
                    event?.target?.id === 'add_ons_cloud.title'
                        ? this.gTagService.sendEvent(GA_EVENTS.PRICING_CLOUD_STORAGE_REQUEST_SUCCESS)
                        : event?.target?.id === 'add_ons_conference.title'
                        ? this.gTagService.sendEvent(GA_EVENTS.PRICING_CONFERENCE_ROOMS_REQUEST_SUCCESS)
                        : event?.target?.id === 'add_ons_participants.title'
                        ? this.gTagService.sendEvent(GA_EVENTS.PRICING_100_PARTICIPANTS_REQUEST_SUCCESS)
                        : this.gTagService.sendEvent(GA_EVENTS.PRICING_LIVE_STREAMING_REQUEST_SUCCESS);

                    event?.target?.id === 'add_ons_cloud.title'
                        ? this.gTagService.sendEvent(GA4_EVENTS.PRICING_CLOUD_STORAGE_REQUEST_SUCCESS)
                        : event?.target?.id === 'add_ons_conference.title'
                        ? this.gTagService.sendEvent(GA4_EVENTS.PRICING_CONFERENCE_ROOMS_REQUEST_SUCCESS)
                        : event?.target?.id === 'add_ons_participants.title'
                        ? this.gTagService.sendEvent(GA4_EVENTS.PRICING_100_PARTICIPANTS_REQUEST_SUCCESS)
                        : this.gTagService.sendEvent(GA4_EVENTS.PRICING_LIVE_STREAMING_REQUEST_SUCCESS);
                }
            })
            .catch((err) => {
                if (evtCode == 2) {
                    event?.target?.title === 'pro_plan.individual_plan'
                        ? this.gTagService.sendEvent(GA_EVENTS.PRICING_SMALL_BUSINESS_REQUEST_QUOTE_FAILURE)
                        : event?.target?.title === 'enterprise_plan.individual_plan'
                        ? this.gTagService.sendEvent(GA_EVENTS.PRICING_ENTERPRISE_REQUEST_QUOTE_FAILURE)
                        : this.gTagService.sendEvent(GA_EVENTS.PRICING_CUSTOM_REQUEST_QUOTE_FAILURE);

                    event?.target?.title === 'pro_plan.individual_plan'
                        ? this.gTagService.sendEvent(GA4_EVENTS.PRICING_SMALL_BUSINESS_REQUEST_QUOTE_FAILURE)
                        : event?.target?.title === 'enterprise_plan.individual_plan'
                        ? this.gTagService.sendEvent(GA4_EVENTS.PRICING_ENTERPRISE_REQUEST_QUOTE_FAILURE)
                        : this.gTagService.sendEvent(GA4_EVENTS.PRICING_CUSTOM_REQUEST_QUOTE_FAILURE);
                } else if (evtCode == 3) {
                    event?.target?.id === 'add_ons_cloud.title'
                        ? this.gTagService.sendEvent(GA_EVENTS.PRICING_CLOUD_STORAGE_REQUEST_FAILURE)
                        : event?.target?.id === 'add_ons_conference.title'
                        ? this.gTagService.sendEvent(GA_EVENTS.PRICING_CONFERENCE_ROOMS_REQUEST_FAILURE)
                        : event?.target?.id === 'add_ons_participants.title'
                        ? this.gTagService.sendEvent(GA_EVENTS.PRICING_100_PARTICIPANTS_REQUEST_FAILURE)
                        : this.gTagService.sendEvent(GA_EVENTS.PRICING_LIVE_STREAMING_REQUEST_FAILURE);

                    event?.target?.id === 'add_ons_cloud.title'
                        ? this.gTagService.sendEvent(GA4_EVENTS.PRICING_CLOUD_STORAGE_REQUEST_FAILURE)
                        : event?.target?.id === 'add_ons_conference.title'
                        ? this.gTagService.sendEvent(GA4_EVENTS.PRICING_CONFERENCE_ROOMS_REQUEST_FAILURE)
                        : event?.target?.id === 'add_ons_participants.title'
                        ? this.gTagService.sendEvent(GA4_EVENTS.PRICING_100_PARTICIPANTS_REQUEST_FAILURE)
                        : this.gTagService.sendEvent(GA4_EVENTS.PRICING_LIVE_STREAMING_REQUEST_FAILURE);
                }
            });
    }

    choosePlanEvents(event) {
        event?.target?.id === 'pro_plan.individual_plan'
            ? this.planType_Monthly
                ? this.gTagService.sendEvent(GA_EVENTS.PRICING_SMALL_BUSINESS_REQUEST_QUOTE_MONTHLY)
                : this.gTagService.sendEvent(GA_EVENTS.PRICING_SMALL_BUSINESS_REQUEST_QUOTE_ANNUALLY)
            : event?.target?.id === 'enterprise_plan.individual_plan'
            ? this.planType_Monthly
                ? this.gTagService.sendEvent(GA_EVENTS.PRICING_ENTERPRISE_REQUEST_QUOTE_MONTHLY)
                : this.gTagService.sendEvent(GA_EVENTS.PRICING_ENTERPRISE_REQUEST_QUOTE_ANNUALLY)
            : this.planType_Monthly
            ? this.gTagService.sendEvent(GA_EVENTS.PRICING_CUSTOM_REQUEST_QUOTE_MONTHLY)
            : this.gTagService.sendEvent(GA_EVENTS.PRICING_CUSTOM_REQUEST_QUOTE_ANNUALLY);

        event?.target?.id === 'pro_plan.individual_plan'
            ? this.planType_Monthly
                ? this.gTagService.sendEvent(GA4_EVENTS.PRICING_SMALL_BUSINESS_REQUEST_QUOTE_MONTHLY)
                : this.gTagService.sendEvent(GA4_EVENTS.PRICING_SMALL_BUSINESS_REQUEST_QUOTE_ANNUALLY)
            : event?.target?.id === 'enterprise_plan.individual_plan'
            ? this.planType_Monthly
                ? this.gTagService.sendEvent(GA4_EVENTS.PRICING_ENTERPRISE_REQUEST_QUOTE_MONTHLY)
                : this.gTagService.sendEvent(GA4_EVENTS.PRICING_ENTERPRISE_REQUEST_QUOTE_ANNUALLY)
            : this.planType_Monthly
            ? this.gTagService.sendEvent(GA4_EVENTS.PRICING_CUSTOM_REQUEST_QUOTE_MONTHLY)
            : this.gTagService.sendEvent(GA4_EVENTS.PRICING_CUSTOM_REQUEST_QUOTE_ANNUALLY);
    }

    onInputPincode(pincode: string, event?: KeyboardEvent) {
        this.invalidPincode = false;
        this.showPincodeError = false;
        pincode.length < 6 ? this.fieldsToShow == true : this.fieldsToShow == false;
        if (pincode.length === 6) {
            const pincodeRegex = /^\d{6}$/;
            const result = pincodeRegex.test(pincode);
            if (result) {
                this.pincode = pincode;
                this.utilService.getAddress(pincode).subscribe(
                    (address: any) => {
                        this.city = address.city;
                        this.state = address.state;
                        this.showPincodeError = false;
                        this.invalidPincode = false;
                        if (this.city === undefined && this.state === undefined) {
                            this.invalidPincode = true;
                            this.showPincodeError = false;
                            this.paymentButtonDisabled = true;
                        } else this.paymentButtonDisabled = false;
                    },
                    (error) => {
                        console.error('invalid pincode error', error);
                        this.errorTxt = error.error.errors;
                        this.invalidPincode = false;
                        this.showPincodeError = true;
                    }
                );
            } else {
                this.invalidPincode = true;
                this.city = '';
                this.state = '';
            }
        } else if (event?.key === 'Enter') {
            this.invalidPincode = true;
        } else if (event?.key === 'Backspace' || event?.key === 'Delete') {
            this.invalidPincode = false;
            this.paymentButtonDisabled = true;
            this.pincode = '';
            this.city = '';
            this.state = '';
        }
    }

    onInputState(state: string) {
        this.paymentButtonDisabled =
            state.length === 0 || this.pincode.length === 0 || this.city.length === 0 ? true : false;
    }
    onInputCity(city: string) {
        this.paymentButtonDisabled =
            city.length === 0 || this.pincode.length === 0 || this.state.length === 0 ? true : false;
    }

    closePopup() {
        this.gTagService.sendEvent(GA4_EVENTS.PROCEED_TO_PAYMENT_FAILED);
        this.showPopup = false;
    }
    submitPayment() {
        if (!this.paymentButtonDisabled && this.currentUser.lname && this.currentUser.name) {
            this.showPopup = false;
            this.isLoading = true;
            const containsSpecialChars = (str) => /[^a-zA-Z0-9\s]/.test(str);
            const data = {
                pincode: this.pincode,
                city: this.city,
                state: this.state,
                name: this.currentUser.name,
                lname: this.currentUser.lname
            };
            if (containsSpecialChars(data.name) || containsSpecialChars(data.lname)) {
                this.toastrService.error(this.translateService.instant('pro_plan.special_char'));
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
                return;
            }
            this.userService.updateUserProfileWithAdditionalProps(data).subscribe(
                (res: any) => {
                    this.utilService.createOrder().subscribe(
                        (res: any) => {
                            if (res.success) {
                                this.gTagService.sendEvent(GA4_EVENTS.PROCEED_TO_PAYMENT_SUCCESS);
                                window.location.href = res.paymentUrl;
                            }
                            setTimeout(() => {
                                this.isLoading = false;
                            }, 500);
                        },
                        (error) => {
                            console.error('create order error', error);
                            this.toastrService.error(error.statusText);
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                        }
                    );
                },
                (error) => {
                    console.error('update profile error', error);
                    if (error.customCode != 200) {
                        this.toastrService.error(this.translateService.instant('pro_plan.check_details'));
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    }
                }
            );
        }
    }
    fetchPricingData(): void {
        this.pricingData = [];
        this.utilService.fetchPricingData().subscribe(
            (data) => {
                data.forEach((pricingPlan) => {
                    const planData = {
                        name: pricingPlan.name,
                        price: pricingPlan.price,
                        comparisionTitle: pricingPlan.comparision_title,
                        description: pricingPlan.description,
                        cardItems: pricingPlan.cardItems,
                        hashtags: pricingPlan.hashtags,
                        planIncludes: pricingPlan.plan_includes,
                        chargeitPlanId: pricingPlan.chargeitPlanId
                    };

                    this.pricingData.push(planData);
                });
            },
            (error: any) => {
                console.error('Error fetching the price', error);
            }
        );
    }
    // method for displaying "terms" only on the middle card
    isMiddleCard(cardDetail: any): boolean {
        const middleIndex = Math.floor(this.pricingCard.length / 2);
        return cardDetail === this.pricingCard[middleIndex];
    }
    formatStateInput(value: string): string {
        if (value) {
            return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        }
        return value;
    }
}
