import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-already-verified',
    templateUrl: './already-verified.component.html',
    styleUrls: ['./already-verified.component.scss']
})
export class AlreadyVerifiedComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
